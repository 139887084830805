import { Toast } from "components/ToastShow/ToastShow";
import { SelectBox } from "components/Select/Select";
import React, { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import "./style.scss";

interface Props {
  totalRecords?: any;
  pageNum: number;
  pageSize: number;
  HandlePrevPage: () => void;
  HandleNextPage: () => void;
  handlechangePageSize: (e) => void;
  first: () => void;
  end: () => void;
  handleGoPage?: (e) => void;
  selectedPersonsId?: string[]
}
export const PaginationNavbar = ({
  pageNum,
  pageSize,
  handleGoPage,
  first,
  end,
  HandlePrevPage,
  HandleNextPage,
  handlechangePageSize,
  selectedPersonsId,
  totalRecords
}: Props) => {
  const goPage = useRef<any>("");
  const validPageSize = [5,10,15, 20, 25, 30, 40, 50, 100, 200];
  const focos = useRef<any>(false);

  function kydown(event) {
    let forms = document.querySelectorAll("form ")
    let flag = true
    if (event.code === 'Enter'&& focos.current ){
      handleGoPage && handleGoPage(goPage.current)
    }else{

    
    Array.from(forms).map(item=>{
      if(item.className.includes("edit")||item.className.includes("create")){
        flag = false
      }
    })
      if(flag){
        if(event.key == "PageDown"){
          HandleNextPage()
    
          
        }else if(event.key == "PageUp"){
          HandlePrevPage()
       
        }else if(event.key == "Home"){
          end()
    
        }else if(event.key == "End"){
         first()
        } 

      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', kydown)
    return () => document.removeEventListener("keydown", kydown);
    

}, [])
  return (
    <>

      <div className="wrap-pagination">
        <div className="wrap-pagination__child d-flex justify-content-between">
          <div className="mx-3">
            <ul className="wrap-pagination__grand-child pagination">
              <li className="page-item">
                <button className="page-link pagination__page-link" role="button" onClick={selectedPersonsId && selectedPersonsId?.length > 0 ? () => { Toast(trans('pleaseUncheckSelectedPersons') , "warning") } : end}>
                  <span aria-hidden="true">«</span>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link pagination__page-link"
                  role="button"
                  onClick={selectedPersonsId && selectedPersonsId?.length > 0 ? () => { Toast(trans('pleaseUncheckSelectedPersons') , "warning") } : HandlePrevPage}
                >
                  <span aria-hidden="true">‹</span>
                </button>
              </li>
              <li className="page-item">
                <button className="page-link pagination__page-link" role="button" name="pageNum_Btn">
                  {pageNum}
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link pagination__page-link"
                  role="button"
                  onClick={selectedPersonsId && selectedPersonsId?.length > 0 ? () => { Toast(trans('pleaseUncheckSelectedPersons') , "warning") } : HandleNextPage}
                >
                  <span aria-hidden="true">›</span>
                </button>
              </li>
              <li className="page-item">
                <button className="page-link pagination__page-link" role="button" onClick={selectedPersonsId && selectedPersonsId?.length > 0 ? () => { Toast(trans('pleaseUncheckSelectedPersons') , "warning") } : first}>
                  <span aria-hidden="true">»</span>
                </button>
              </li>
            </ul>
          </div>
          {handleGoPage &&
            <form onSubmit={(e)=>{e.preventDefault() ;handleGoPage(goPage.current) }} className="wrap-pagination__grand-child d-flex justify-content-between align-items-center">
              <div className="">
                <span>{trans("Goto")}:</span>
              </div>
              <div className="mx-1">
                <input  onFocus={()=>focos.current = true}  onBlur={()=>focos.current = false} type="number" name="" id="" onChange={(e)=>{goPage.current = e.target.value}} className="pagination__input-number form-control-custom" min={1} max={totalRecords > pageSize ? Math.ceil(totalRecords / pageSize) : 1} />
              </div>

              <div className="">
                <button
                  className="form-control-custom pagination__go-btn"
                  type={"submit"}
                >
                  {trans('Go')}
                </button>
              </div>
            </form>}
        </div>
        <div className="wrap-pagination__child d-flex align-items-center justify-content-between mx-3 mt-1 mt-md-0">
          <div className="wrap-pagination__grand-child mx-1">
            <span>{trans("NumberOfRecordsPerPage")}:</span>
          </div>
          <div className="wrap-pagination__grand-child ">
            <SelectBox
              selectType="selectThin"
              options={validPageSize}
              val={validPageSize}
              onChange={handlechangePageSize}
              value={pageSize}
              name=""
            />
          </div>
        </div>

           <div className="wrap-pagination__grand-child mx-1">
            <span>{trans("TotalRecords")}:{totalRecords}</span>
          </div>
      
      </div>
    </>
  );
};
