import { Headerbar } from "components/Headerbar/Headerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { TableTree } from "components/TableTree/TableTree";
import { useEffect, useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { getReferencesActions } from "./api/api";
import { ModalForm } from "components/ModalForm/ModalForm";
import { ActionsOfReference } from "./ActionsOfReference";
interface ReferenceHistoryPropsType {
 
    mode?: any,
    serviceRequestId: any,
    onHide: () => void

}

export const ReferenceHistory = ({

    serviceRequestId,

}: ReferenceHistoryPropsType) => {
    const [response , setResponse]  = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>();
    const [messageType, setMessageType] = useState<any>("info");
    const [showActionOfReference, setShowActionOfReference] = useState(false);

async function ReferenceAndActionsasync(serviceRequestId) {
    setLoading(false)
    try {
        const res =  await getReferencesActions(serviceRequestId )
   
       setResponse(res.Data)
    } catch (error) {
        setResponse([])
        setMessageType("error")
    }
    setLoading(true)

}

    useEffect( () => {
        ReferenceAndActionsasync(serviceRequestId)
        
    }, [serviceRequestId]);

    return (
       

        <>
        <div  >
                  {
        loading ? (
               response ?
                 <TableTree
                   italic={"Reversed"}
                   params={"serviceRequestId"}
                   Data={[ response]}
                   DoubleClick={(e)=>{setSelectedRow(e ) ;setShowActionOfReference(true) }}
                   compressed={true}
                   theadData={ Object.keys(response)}
                   parentName={"ReferenceHistory"}
                   startIndex={4}
                   Childrenparams={"ReferenceList"}
                   defultShow={true}
                   /> 
                   : <Message message={"NoDataForPreview"} type={messageType} />       ) 

                : <Loading />
          }
        </div>        

            {showActionOfReference &&
                <ModalForm mode={"edit"} title={("Actions")} onHide={() => { setShowActionOfReference(false) }} show={showActionOfReference}>
                    <ActionsOfReference referenceId={selectedRow.Id} />
                </ModalForm>
            }
        
        
        </>





    );
}