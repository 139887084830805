import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { getCustomerPhoneDictionaryApi, getCustomerPhoneDictionaryListApi } from "./api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface CustomerPropsType {
  label?: string,
  hasBorder?: boolean,
  valueString?: string,
  personId?: string,

  resetForm?: boolean,
  Current?: boolean,
  required?: boolean,
  returnObj?: boolean,
  allowType?: boolean,
  value?: string,
  mode?: string,
  name: string,
  Change?: (e) => void,
  onChange?: (e) => void,
  type?:any

}

export const CustomerPhoneDictionary = ({ label,Current=false, hasBorder, value, valueString,personId ,allowType=false,mode,returnObj=false ,resetForm,required , name="", Change ,onChange,type}: CustomerPropsType) => {
  const [Customer, setCustomers] = useState<any>();
  const [valueName, setValueName] = useState<any>( );
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [validat , setValidat] = useState(false);
  const [rquird , setRquird] = useState(false);
  const [allowTyping , setAllowTyping] = useState(valueString&& allowType );
  const [count,setCount] = useState<number>(0);
  const inputRef = useRef<any>();
  const inputIdRef = useRef<any>();
  const [disabled, setDisabled] = useState(mode == "view" ? true : false);
  const countRef = useRef<any>(0)
  const [listBoxPosition,setListBoxPosition]=useState<any>()
  let options_Customer_keys, options_Customer_values;



  async function getCustomerListAsync(search?, id?) {
    try {
      if (id) {

        
        const res2 = await getCustomerPhoneDictionaryApi( id ,personId ,Current);

        
        if(Object.values(res2)[0] && Object.values(res2)[0] != undefined){
;

          setValeu( Object.values(res2)[0],  Object.keys(res2)[0])
          setValueName( Object.values(res2)[0])
          if(!returnObj)
            onChange && onChange(Object.values(res2)[0])
          else{

            onChange && onChange({Id:Object.keys(res2)[0] , Name:Object.values(res2)[0]})
          }
        }else{
          
        }
       
      } else {


        const res = await getCustomerPhoneDictionaryListApi(search, id ,personId ,Current,type);
        setCustomers(res)
        options_Customer_values = Object.values(res)
        options_Customer_keys = Object.keys(res)
        setShow(true)
      }
      setFlag(!flag)
    } catch (err: any) {
      
    }
  }


  const Auto = (e) => {
    setValidat(false)
    setRquird(false)
    setShow(true)
    Change && Change(true)
    getCustomerListAsync(e.target.value, "")
    setFlag(!flag)
  }

  function userSelecter(){
    if(options_Customer_values?.[countRef.current] && options_Customer_keys?.[countRef.current]){
      setValeu(options_Customer_values[countRef.current],options_Customer_keys[countRef.current])
      setShow(false)

    }

  //  setValeu(options_Customer_values[countRef.current],Object.keys(options_Customer_keys)[countRef.current])
  }

  const kydown = (event) => {  

    if((event.key === "ArrowDown")){
      if(countRef.current < options_Customer_values.length-1){
        countRef.current = countRef.current + 1;
      }else{
        countRef.current = 0;
      }
    }
    
    if(event.key === "ArrowUp"){
      if(countRef.current > 0){
        countRef.current = countRef.current -1;
      }else{
        countRef.current = options_Customer_values.length-1;
      }
    }
    document.getElementById("CustomerIndex"+countRef.current)?.scrollIntoView({block:'end',behavior:"smooth"})
    setCount(countRef.current)

    if(event.key === "Enter"){
      userSelecter()
    }

  }


  
  const CheckCustomer =async (e)=>{
    
    setTimeout(async() => {
      
      if(e.target.value.length == 0&& inputRef.current?.value?.length == 0 && e.target.value==""){
        inputIdRef.current.value = null
        if(required)
        setRquird(true)
    }else{
      setRquird(false)
      try{
        const res = await getCustomerPhoneDictionaryListApi(e.target.value,"" ,personId ,Current,type)
        if(Object.keys(res).length==0){
          setValidat(true)
        }
        if(Object.keys(res).length == 1){
          setValeu(Object.values(res)[0],  Object.keys(res)[0]   )
        }
      }catch(err:any){
        setValidat(true)
      }
    }
    }, 200);
    await delay(200)
    setShow(false)
  }

  const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));





  const setValeu = (value, id) => {

  
    if(inputIdRef != null && inputIdRef.current != null){
      setRquird(false)  
      inputRef.current.value = value?.split(":")[1] != undefined ?  value?.split(":")[1] :value?.split(":")[0];
      inputIdRef.current.value = id
      setCustomers(null)

      if(!returnObj)
      onChange && onChange(value)
    else
      onChange && onChange({Id:id , Name:value})

      setFlag(!flag)
    }
  }


  
  
  useEffect(() => {

    
    if (value) {

      
      getCustomerListAsync(null, value)
    }else if(valueString ){

      onChange && onChange({Id:" " , Name:valueString})
    }
  }, [value,valueString ,resetForm]);

  
  useEffect(() => {
      setDisabled(mode == "view" ? true : false)
    
  }, [mode]);


  if (Customer) options_Customer_values = Object.values(Customer).map(item => {
    return item
  })
  if (Customer) options_Customer_keys = Object.keys(Customer).map(item => {
    return item
  })

  function clickToclose(e){
    let input = document.querySelector("#browser"+name);
    if (e.target.id !== input?.id) {
      setShow(false);
    }
  }

  useEffect(() =>{
    if(show){
      document.addEventListener('keydown', kydown);
      document.body.addEventListener("click", clickToclose)

    } 
    return () => { document.removeEventListener("keydown", kydown);document.body.removeEventListener('click', clickToclose) }

    
  },[show ,options_Customer_values])


  useEffect(()=>{
   document.addEventListener('scroll',(e)=>setListBoxPosition(inputRef.current?.getBoundingClientRect()),true)
    document.addEventListener('resize',(e)=>setListBoxPosition(inputRef.current?.getBoundingClientRect()),true)

    return() => {
      document.removeEventListener('scroll',(e)=>setListBoxPosition(inputRef.current?.getBoundingClientRect()),true)
      document.removeEventListener('resize',(e)=>setListBoxPosition(inputRef.current?.getBoundingClientRect()),true)
    }
   },[inputRef.current,window,document])

   useEffect(()=>{
    setListBoxPosition(inputRef.current?.getBoundingClientRect())
   },[show])



  return (
    <>
      <div className="text-box">
        {label && 
      <OverlayTrigger
             key={label}
             placement="top"
             delay={{"show" : 600 , "hide":0}}
             overlay={
                <Tooltip className="tooltip " id={`button-tooltip-${"reserve"}`}>
                {trans(label)}
                </Tooltip>
                }>
                 <div className="d-flex align-items-center" style={{height:'22px'}}>
                   <label className="text-box__label showDots"  style={{maxWidth:'90%'}}>{trans(`${label}`)}</label>
                   {required && <span className='text-box__required-sign-for-label text-danger me-1'>*</span>}
                  {(allowType&&mode!="view")&&
                  <>
                  {
                    allowTyping ?
                    <FontAwesomeIcon onClick={()=>{setAllowTyping(false)}} style={{marginRight:"5px"}} icon={["fas", "magnifying-glass"]} className="text-primary pr-2"/>
                    :
                    <FontAwesomeIcon onClick={()=>{setAllowTyping(true)}} style={{marginRight:"5px"}} icon={["fas", "magnifying-glass-minus"]} className="text-danger  pr-2"/>

                  }
                  </>
                  }
                 </div>
          </OverlayTrigger> 
        }


        <input required={required} defaultValue={value ? value:valueString}   name={name} className="d-none" ref={inputIdRef} />
        
        {(!allowTyping||!allowType)?
        <>
        <input required={required} autoFocus={(!allowTyping&&valueName)} defaultValue={ !allowType ?valueString :valueName}  onBlur={CheckCustomer} autoComplete="off" onMouseDown={(e) => {e.stopPropagation(); Auto(e) }} onFocus={() => {getCustomerListAsync(!allowTyping ? valueName:null); setShow(true) }} ref={inputRef} list="CustomerList" name="browser" className={"text-box__box form-control" + " browser"+name} type={'text'} id={"browser"+name} onChange={Auto} disabled={disabled} />
        {
          show && 
          <div className={`text-box__sub-menu mt-1 glassBox border border-1`} tabIndex={-1} style={{position:'fixed',zIndex:'5',top:`${listBoxPosition?.top+listBoxPosition?.height}px`,left:`${listBoxPosition?.left}px`,width:`${listBoxPosition?.width}px`}}>    
            {options_Customer_values && options_Customer_values.map((item, index) => {
              return   <>    <div id={"CustomerIndex"+index} className={`border-bottom ${count == index ?"text-box__sub-menu-option-active" : "text-box__sub-menu-option"}`} onClick={(e) => { setValeu(item, options_Customer_keys[index]); }} key={item.Id} >
              <div id={"CustomerIndex"+index} className={`font-size-md `} >{item.split(":")[1]}</div>
              <div className="mt-1 font-size-sm text-muted">{item.split(":")[0]}</div>
            </div></>
              
            })}
          </div>
        }
        </>
        :
        <input required={required} defaultValue={valueString}  onBlur={(e)=>{onChange&&onChange( {Id:" " , Name:e.target.value});inputIdRef.current.value = e.target.value }} onChange={(e)=>{setValueName(e.target.value)}}  ref={inputRef}  name="browser" className={"text-box__box form-control" + " browser"+name} type={'text'} id={"browser"+name} disabled={disabled} />

        }
        <div className="text-box__msg-wrapper">
         {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
         {required && <span className={`${rquird ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsRequired", [""])}</span>}
        </div>    
 
      </div>
    </>
  )
}