import { Button } from "components/Button/Button";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { putAccept ,getAccept} from "./api";
import { Textbox } from "components/Textbox/Textbox";
import './style.scss'
import { InvitationStatus } from "types/enum";
import { trans } from "utils/helpers";
interface  InvitationFormPropsType {
    onHide?;
    Id?:string
    referenceId?:string
    jobId?:string
    mode?:any
}
export const  InvitationForm = ({ 
   
    onHide,
    Id,
    referenceId,
    jobId,
    mode,
}:  InvitationFormPropsType) => {
    const [resetForm, setResetForm] = useState(false);
    const [invitationStatus,setInvitationStatus] = useState<'Accepted'|'reject'|'unset'>('unset')
    const [response, setResponse] = useState<Response | any>(null);
    const layoutContext = useContext(LayoutContext);

    const [loading, setLoading] = useState<any>(false);




    async function getDataAsync() {
        setLoading(false)
        try{
            let res = await getAccept(jobId );
            if(res.Data.InvitationStatus == InvitationStatus.Accepted){
                setInvitationStatus("Accepted")
            }else if(res.Data.InvitationStatus == InvitationStatus.Rejected){
                setInvitationStatus("reject")
            }else{
                setInvitationStatus("unset")
            }
            setResponse(res.Data)
        }catch(err:any){

        }
        setLoading(true)

    }
    async function putAcceptAsync(data) {
        try {
       let res = await putAccept(data  );
        onHide && onHide();
    
        } catch (err: any) {


        }
      }
    const Submit = (event) => {
        event.preventDefault();
        let data:any={
            "JobId": jobId,
            "PositionId": layoutContext.currentUserPosition.PositionId,
            "InvitationStatus": invitationStatus=="Accepted"? InvitationStatus.Accepted : InvitationStatus.Rejected ,
          }
          if(invitationStatus=="Accepted")data.PresenceType = +event.target.PresenceType?.value;
         putAcceptAsync(data)

    }


   






    
     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);

     useEffect(() => {
          if ( referenceId ) {
            getDataAsync()
        }
      }, [referenceId]);


    return (
        <>
        <div className="form card">
            <div className="card-body">
                <form className="container-fluid row invitatinForm" onSubmit={Submit}>
                <div className="invitatinForm__infoBox">
                    <div className="invitatinForm__infoBox__Title font-size-md">
                       {trans("InvitationText")} : {response?.InvitationText}
                    </div>
                    <div className="me-3 my-2 font-size-sm text-muted">
                    {trans("NecessaryHoldingTools")} : {response?.NecessaryHoldingTools}
                    </div>
                </div>
                <div className={`invitatinForm__acceptBox ${invitationStatus == 'unset' ? 'invitatinForm__acceptBox__unset':invitationStatus == 'Accepted' ? 'invitatinForm__acceptBox__Accepted': 'invitatinForm__acceptBox__reject'} d-flex justify-content-between align-items-center p-1 mb-2 font-size-md`}>
                    <div>{invitationStatus == 'unset' ? 'آیا میخواهیداین دعوت را بپذیرید؟':invitationStatus == 'Accepted' ? 'این دعوت پذیرفته شد':'این دعوت رد شد' }</div>
                    <div>
                        {invitationStatus != 'Accepted' && <Button value="Accepted" btnType="submit" onClick={()=>setInvitationStatus('Accepted')}/>}
                        {invitationStatus != 'reject' && <Button btnType="cancel" value="reject" onClick={()=>setInvitationStatus('reject')}/>}
                        
                    </div>
                </div>
                {invitationStatus == 'Accepted' &&
                    <div className="w-25">
                        <EnumerationTypeSelect
                        label="PresenceType"
                        name="PresenceType"
                        value={response?.PresenceType ? response?.PresenceType : null}
                        mode={mode}
                        enumTypeSelect='PresenceType'
                        />
                    </div>
                }
                {/* <div className="col">
                        <EnumerationTypeSelect
                        label="InvitationStatus"
                        name="InvitationStatus"
                        value={response?.InvitationStatus ? response?.InvitationStatus : null}
                        mode={mode}
                        enumTypeSelect='InvitationStatus'
                    />
                    </div>     */}
                    {  mode !="view" && invitationStatus != 'unset'?
                            <div className="col-lg-12 mt-4 btns_form">
                                <Button value="Save" btnType="submit" />
                                <Button btnType="cancel" value="Cancel" onClick={()=>{onHide()}} />
                            </div>
                            :
                            <div className="col-lg-12 mt-4 btns_form">
                                <Button btnType="primary" value="close" onClick={()=>{onHide()}} />
                         </div>
                        }  
                </form>

            </div>
        </div>        
        </>

    );
}