import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import {  getFollowupPositionIdDictionaryListApi } from "./api";
interface FollowupPositionIdPropsType {
  label: string,
  hasBorder?: boolean,
  resetForm?: boolean,
  required?: boolean,
  value?: string,
  mode?: string,
  name: string,
  Change?: (e) => void,
}

export const FollowupPositionId = ({ label, hasBorder, value, mode ,resetForm,required , name, Change}: FollowupPositionIdPropsType) => {
  const [followupPositionIds, setFollowupPositionIds] = useState<any>();
  const [valueName, setValueName] = useState<any>();
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [validat , setValidat] = useState(false);
  const [count,setCount] = useState<number>(0);
  const inputRef = useRef<any>();
  const inputIdRef = useRef<any>();
  const FollowupPositionIdRef = useRef<any>(followupPositionIds);
  const validRef = useRef<any>(false);
  const countRef = useRef<any>(0)
  let optionList : any =[]


  async function getFollowupPositionIdListAsync(search, id) {
    try {
      const res = await getFollowupPositionIdDictionaryListApi(search, id);
      if (id){
        setValeu(res[id] , id)
        setValueName(res[id])
      }
      setFollowupPositionIds(res)     
      FollowupPositionIdRef.current = res
      setFlag(!flag)
    } catch (err: any) {

    }
  }

  const Auto = (e) => {
    validRef.current = false
    setValidat(false)
    setShow(true)
    getFollowupPositionIdListAsync(e.target.value, "")
    setFlag(!flag)
  }

  function userSelecter(){
    setValeu(optionList[countRef.current],Object.keys(followupPositionIds)[countRef.current])
    setShow(false)
  }
  
  const kydown = (event) => {    

    if((event.key === "ArrowDown")){
      if(countRef.current < optionList.length-1){
        countRef.current = countRef.current + 1;
      }else{
        countRef.current = 0;
      }
    }
    
    if(event.key === "ArrowUp"){
      if(countRef.current > 0){
        countRef.current = countRef.current -1;
      }else{
        countRef.current = optionList.length-1;
      }
    }
    document.getElementById("FollowupPositionIndex"+countRef.current)?.scrollIntoView({behavior:"smooth"})
    setCount(countRef.current)

    if(event.key === "Enter"){
      userSelecter()
    }
  }

  const CheckFollowupPositionId =async (e)=>{

      setTimeout(() => {
         
        if(!validRef.current){
          
          if(followupPositionIds!=undefined &&  Object.values(followupPositionIds).length == 1){
            setValeu(Object.values(followupPositionIds)[0] , Object.keys(followupPositionIds)[0])
            e.target.setCustomValidity("");
          }else{
            if(inputRef?.current){
            setValeu(null,null)
          }
            
            if(inputRef?.current?.value.length >0 && inputRef?.current?.value!=""){
              e.target.setCustomValidity(trans("Msg_Validation_ValueIsIncorrect"));
              setValidat(true);
              e.target.reportValidity();
            }
            setFollowupPositionIds(null);
            
        }
        }
      }, 200);
    await delay(200)
    setShow(false)
  }
  const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));


  const setValeu = (newValue, id) => {
    if(inputIdRef != null && inputIdRef.current != null){
      validRef.current = true
      inputRef.current.value = newValue
      inputIdRef.current.value = id
      FollowupPositionIdRef.current = null;
      ((value==null &&id)|| id != value) ? Change&&Change({[name] :newValue}):Change&&Change({[name]:null});
      setFollowupPositionIds(null)
      setFlag(!flag)
      setFollowupPositionIds({[inputIdRef.current.value]:inputRef.current.value})
      setValidat(false)
    }

  }
  useEffect(() => {
    if(value){
      getFollowupPositionIdListAsync(null,value)
     }else if(value == null){
      setValueName(null)
     }
  }, [value]);



  useEffect(() =>{
    if(show ){
      document.addEventListener('keydown', kydown);
      document.body.addEventListener("click", clickToclose)

    } 
     return () => { document.removeEventListener("keydown", kydown);document.body.removeEventListener('click', clickToclose) }

    
  },[show ,optionList])

  function clickToclose(e){
    let input = document.querySelector("#browser"+name);
    if (e.target.id !== input?.id) { 
      setShow(false);
    }
  }




  if(followupPositionIds){
    optionList = Object.values(followupPositionIds)
    
  }

  return (
    <>
    <div className="text-box">

    <label htmlFor={`browser${name}`} className="text-box__label">{trans(label)}</label>
    {required && <span className="text-box__required-sign-for-label text-danger">*</span>}
    <input required={required} defaultValue={value && value} name={name} className="d-none" ref={inputIdRef} />
    <input  required={required} onBlur={CheckFollowupPositionId} defaultValue={valueName} autoComplete="off" onFocus={(e)=>{   setShow(true);Auto(e)}}  ref={inputRef} list="CustomerPositionList" name="browser" className="text-box__box form-control "    id={"browser"+name} onMouseDown={(e)=>e.stopPropagation() } onChange={Auto} disabled={mode =="view" ? true : false} />
    {
       show && 
        <div tabIndex={-1} className={`text-box__sub-menu mt-1`} >
          {optionList && optionList.map((item , index)=>{
                 return  <div id={"FollowupPositionIndex"+index} className={`${count == index ?"text-box__sub-menu-option-active" :"text-box__sub-menu-option" }`} onClick={()=>{setValeu(item,Object.keys(followupPositionIds)[index]); inputRef.current.setCustomValidity("");}} key={Object.keys(followupPositionIds)[index]} >{item}</div>
           })}  
        </div>
     }  
      <div className="text-box__msg-wrapper">
       {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
       </div>    
    </div>
    </>
   )
}