import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";




export const getFollowupPositionIdDictionaryListApi = (async (search,id) => await http({
    url: ENDPOINTS.clientPortal.clientPortal.followupResponsible,
    method: 'get',
    params: {
        positionId: id,
        searchString:search
    }
}).then((res) => {
    return res.data.Data
}).catch((err) => {

    return Promise.reject( err);
})
)