import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import { addItemToBasket, getBasketData, roundOrTruncate } from "utils/helpers";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { Textbox } from "components/Textbox/Textbox";

interface ItemCountBtnProps {
  reload?: () => void;
  closePluse?: (e: boolean) => void;
  ItemData: any;
  defultValue?: number;
  priceIndex?: number;
  min?: number;
  max?: any;
  stock?: any;
  propertiesId?: any;
  multiCounter?: any;
  properties?: any;
  showSumCount?: boolean;
  classStyle?: any;
}
export const ItemCounterBtn = ({
  classStyle,
  reload,
  closePluse,
  showSumCount,
  stock,
  multiCounter = true,
  ItemData,
  defultValue,
  min,
  max,
  priceIndex,
  propertiesId,
  properties,
}: ItemCountBtnProps) => {
  const [count, setCount] = useState(defultValue ? defultValue : 1);
  const [startDel, setStartDel] = useState(false);
  const startDelRef = useRef(false);
  const CountRef = useRef<any>(0);
  const layoutContext = useContext(LayoutContext);
  const [round, setRound] = useState<any>();
  let basket = getBasketData();
  const [disabled,setDisabled]=useState(false)
  const stopDeletingRef = useRef(false);
  const {setEmptyBasket,emptyBasket } = useContext(LayoutContext);

  
  const handleDecrement = async () => {
    setDisabled(false)
    let OrderItemId = ItemData.ItemPriceListDetails[0].PriceDetails.find(
      (item) => item.ItemCode.includes(propertiesId.replaceAll(ItemData.Id, ""))
    );
    let newcount = roundOrTruncate(
      +CountRef.current.value,
      -ItemData?.ItemPriceListDetails[0]?.PriceDetails[0]
        ?.SalesUnitRoundingPlace,
      true
    );

    if (+newcount != 1) {
      if (min && +newcount < +min) {
        addItemToBasket(
          {
            ...ItemData,
            order: {
              ...ItemData.order,
              [propertiesId]: {
                OrderItemId: OrderItemId,
                properties: properties,
                count: +min,
                priceIndex: priceIndex,
                min: min,
                max: max ? +max : null,
              },
            },
          },
          [propertiesId]
        );
        newcount = +min;
        setCount(+min);
      } else {
        addItemToBasket(
          {
            ...ItemData,
            order: {
              ...ItemData.order,
              [propertiesId]: {
                OrderItemId: OrderItemId,
                properties: properties,
                count: +newcount - 1,
                priceIndex: priceIndex,
                min: min,
                max: max ? +max : null,
              },
            },
          },
          [propertiesId]
        );
        newcount = count - 1;

        if (count - 1 == 0) {
          setStartDel(true);
          startDelRef.current = true;
          await delay(1800);
          setCount(0);
          if (startDel || startDelRef.current) {
            await addItemToBasket({ ...ItemData, count: 0 }, [propertiesId]);
            reload && reload();
            startDelRef.current = false;

            setStartDel(false);
          } else {
            setCount(ItemData.count);
          }
        } else {
          if (count == 0) {
            setCount(0);
          } else {
            setCount(count - 1);
          }
        }
      }
    } else {
      handleDelete();
    }
    reload && reload();
  };

  const handleIncrement = () => {
   
    
    let OrderItemId = ItemData.ItemPriceListDetails[0].PriceDetails.find(
      (item) => item.ItemCode.includes(propertiesId.replaceAll(ItemData.Id, ""))
    );
    let newcount = roundOrTruncate(+CountRef.current.value, 1, true);

    if (multiCounter) {
      if (newcount < +stock) {
        if (max != null && newcount > +max) {
          addItemToBasket(
            {
              ...ItemData,
              order: {
                ...ItemData.order,
                [propertiesId]: {
                  OrderItemId: OrderItemId,
                  properties: properties,
                  count: +max,
                  priceIndex: priceIndex,
                  min: min,
                  max: +max,
                },
              },
            },
            [propertiesId]
          );
          newcount = max != null ? +max : null;
          setCount(+max);
        } else {
          addItemToBasket(
            {
              ...ItemData,
              order: {
                ...ItemData.order,
                [propertiesId]: {
                  OrderItemId: OrderItemId,
                  properties: properties,
                  count: +newcount + 1,
                  priceIndex: priceIndex,
                  min: min,
                  max: max != null ? +max : null,
                },
              },
            },
            [propertiesId]
          );
          // newcount = +newcount + 1;

            
          if(newcount==max-1){
            setDisabled(true)
            setCount(+max);
          }
          else if (newcount<max ){
         disabled && setDisabled(false)
         setCount(+newcount + 1); 
          }

        }
      }
    } else {
      if (+newcount < +stock) {
        addItemToBasket(
          {
            ...ItemData,
            order: {
              ...ItemData.order,
              [propertiesId]: {
                OrderItemId: OrderItemId,
                properties: properties,
                count: +newcount + 1,
                priceIndex: priceIndex,
                min: min,
                max: max != null ? +max : null,
              },
            },
          },
          [propertiesId]
        );
        // newcount = +newcount + 1;
       
        // setCount(+newcount + 1);
      } else {
        addItemToBasket(
          {
            ...ItemData,
            order: {
              ...ItemData.order,
              [propertiesId]: {
                OrderItemId: OrderItemId,
                properties: properties,
                count: +stock,
                priceIndex: priceIndex,
                min: min,
                max: max != null ? +max : null,
              },
            },
          },
          [propertiesId]
        );
        newcount = +stock;
        setCount(+stock);
      }
    }

    reload && reload();
  };
  const delay = (duration) =>
    new Promise((resolve) => setTimeout(resolve, duration));

  const handleDelete = async () => {
    let OrderItemId = ItemData.ItemPriceListDetails[0].PriceDetails.find(
      (item) => item.ItemCode.includes(propertiesId.replaceAll(ItemData.Id, ""))
    );

    CountRef.current.value = 0;
    setStartDel(true);
    startDelRef.current = true;
    stopDeletingRef.current = false; //
    if (reload) {
      await delay(1800);
      setCount(0);
      if (startDel || startDelRef.current) {
        await addItemToBasket(
          {
            ...ItemData,
            order: {
              ...ItemData.order,
              [propertiesId]: {
                OrderItemId: OrderItemId,
                properties: properties,
                count: 0,
                priceIndex: priceIndex,
                min: min,
                max: max != null ? +max : null,
              },
            },
          },
          [propertiesId]
        );
        setEmptyBasket&&  setEmptyBasket(emptyBasket)
        reload && reload();
        closePluse && closePluse(true);
        startDelRef.current = false;
        setEmptyBasket&&setEmptyBasket(true)
        setStartDel(false);
      } else {
        setCount(ItemData.order[propertiesId].count);
      }
    } else {
      await delay(1800);
      await addItemToBasket(
        {
          ...ItemData,
          order: {
            ...ItemData.order,
            [propertiesId]: {
              OrderItemId: OrderItemId,
              properties: properties,
              count: 0,
              priceIndex: priceIndex,
              min: min,
              max: +max,
            },
          },
        },
        [propertiesId]
      );
      setCount(0);
      closePluse && closePluse(true);
    }
  };

  const validation = (e) => {
    let OrderItemId = ItemData.ItemPriceListDetails[0].PriceDetails.find(
      (item) => item.ItemCode.includes(propertiesId.replaceAll(ItemData.Id, ""))
    );

    if (+stock && +e.target.value <= +stock) {
      // if(min && e.target.value < min){
      //     setCount(min)
      // }else if(max && e.target.value > max){
      //     setCount(max)
      // }else{
      //     setCount(e.target.value)
      // }
    } else {
      setCount(+stock);
      CountRef.current.value = +stock;
      addItemToBasket(
        {
          ...ItemData,
          order: {
            ...ItemData?.order,
            [propertiesId]: {
              OrderItemId: OrderItemId,
              properties: properties,
              priceIndex: priceIndex,
              min: min,
              max: max != null ? +max : null,
              count: stock,
            },
          },
        },
        [propertiesId]
      );
    }
  };




  const handleOnChange = (e) => {
    let OrderItemId = ItemData.ItemPriceListDetails[0].PriceDetails.find(
      (item) => item.ItemCode.includes(propertiesId.replaceAll(ItemData.Id, ""))
    );
    addItemToBasket(
      {
        ...ItemData,
        order: {
          ...ItemData?.order,
          [propertiesId]: {
            OrderItemId: OrderItemId,
            properties: properties,
            priceIndex: priceIndex,
            min: min,
            max: max != null ? +max : null,
            count:
              stock && stock >= e.target.value
                ? max
                  ? +max >= +e.target.value
                    ? +e.target.value
                    : max !== null
                    ? +max
                    : null
                  : +e.target.value
                : stock,
          },
        },
      },
      [propertiesId]
    );
   
    
    setCount(
      stock && stock >= +e.target.value
        ? max != null
          ? +max >= +e.target.value
            ? +e.target.value
            : +max
          : +e.target.value
        : stock
    );
    CountRef.current.value =
      stock && stock >= +e.target.value
        ? max != null
          ? +max >= +e.target.value
            ? +e.target.value
            : +max
          : +e.target.value
        : stock;
  };

  useEffect(() => {
    // CountRef.current.value = +ItemData?.order?.[propertiesId]?.count ? ItemData?.order?.[propertiesId]?.count : 1
    if (
      ItemData?.order?.[propertiesId]?.priceIndex != priceIndex &&
      ItemData?.order?.[propertiesId]?.priceIndex != undefined
    ) {
      addItemToBasket(
        {
          ...ItemData,
          order: {
            ...ItemData?.order,
            [propertiesId]: {
              ...ItemData.order?.[propertiesId],
              priceIndex: priceIndex,
            },
          },
        },
        [propertiesId]
      );
    }
  }, [priceIndex]);
  useEffect(() => {
    layoutContext.setBasket();
  }, [count]);

  useEffect(() => {
    let Basket: any = localStorage.getItem("Basket");
    if (Basket && ItemData.order) {
      Basket = JSON.parse(Basket);
      if (Basket[ItemData.Id]?.order[propertiesId]) {
        if (ItemData.order[propertiesId] == undefined) {
          ItemData.order[propertiesId] = {
            count: Basket[ItemData.Id]?.order?.[propertiesId]?.count,
          };
        } else {
          ItemData.order[propertiesId].count =
            Basket[ItemData.Id]?.order?.[propertiesId]?.count;
        }
        if (CountRef.current.value )
          CountRef.current.value =
            Basket[ItemData.Id]?.order?.[propertiesId]?.count;   
        setCount(Basket[ItemData.Id]?.order[propertiesId]?.count);
      } else {
        if (ItemData.Id) {
          setCount(0);
          // addItemToBasket({...ItemData , count:0})
          //  CountRef.current.value = 0
          closePluse && closePluse(true);
          reload && reload();
        }
      }
    } else {
      // reload&&reload()
      // onHide && onHide(false)
    }
  }, [layoutContext.basket]);

  useEffect(() => {
    setRound(
      ItemData?.ItemPriceListDetails?.[0]?.PriceDetails?.[0]
        ?.SalesUnitRoundingPlace
    );
  }, [ItemData]);

  return (
    <div
      className={`bg-customerPrimaryColor justify-content-between px-2 py-1 rounded-2 d-flex text-white ${
        classStyle === "cardsPage"
          ? "counter-btn-cards"
          : classStyle === "listsPage"
          ? "counter-btn-list"
          : classStyle === "totalBasket"
          ? "counter-btn-totalBasket"
          : ""
      }`}
      style={{ width: "156px" }}
    >
      <button
        onClick={handleIncrement}
        className="d-flex align-items-center bg-transparent text-white"
        style={{
          fontSize: "10px",
          cursor: "poinetr",
          border: "none",
          outline: "none",
        }}
        disabled={disabled || max==count}
        >
        <FontAwesomeIcon icon={"plus"} />
      </button>
      <input
        ref={CountRef}
        min={min}
        max={max}
        onBlur={(e) => validation(e)}
        onChange={(e) => {
          handleOnChange(e);
        }}
        type="text"
        value={
          showSumCount
            ? roundOrTruncate(defultValue, round ? round : null, true)
            : roundOrTruncate(count, round ? round : null, true)
        }
        style={{
          fontWeight: "100",
          fontSize: "14px",
          all: "unset",
          maxWidth: "44px",
          textAlign: "center",
        }}
      />
      <button
        onClick={handleDecrement}
        className="d-flex align-items-center bg-transparent text-white"
        style={{
          fontSize: "10px",
          cursor: "poinetr",
          border: "none",
          outline: "none",
        }}
      >
        <FontAwesomeIcon icon={"minus"} />
      </button>
      <button
        className="d-flex align-items-center bg-transparent text-white"
        style={{
          fontSize: "12px",
          cursor: "poinetr",
          border: "none",
          outline: "none",
        }}
        onClick={handleDelete}
      >
        <FontAwesomeIcon icon={["far", "trash-can"]} />
      </button>
      <div className="h-100">
        <span
          className="text-white "
          style={{ fontWeight: "100", fontSize: "10px", height: "100%" }}
        >
          {ItemData?.ItemPriceListDetails?.[0]?.PriceDetails[0]?.SalesUnit
            ? ItemData?.ItemPriceListDetails?.[0]?.PriceDetails[0]?.SalesUnit
            : ItemData.SalesUnit}
        </span>
      </div>

      {reload && startDel && startDelRef.current && (
        <FontAwesomeIcon
          onClick={() => {
            setStartDel(false);
            startDelRef.current = false;
            CountRef.current.value = ItemData.count;
            setCount(ItemData.count);
          }}
          icon={["fas", "clock-rotate-left"]}
          color="blue"
          size="sm"
          style={{cursor:'pointer',zIndex:'10'}}

        />
      )}
    </div>
  );
};
