import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Loading } from "components/Loading/loading"
import { SelectBox } from "components/Select/Select"
import { useEffect, useState } from "react"
import { getProcesIcon, getProcessIcons } from "./api"
import { Message } from "components/Message/Message"


interface props {
  sendOptionId: (id:string) => void,
  ProcessId:string
}

export const ProcessIcon = ({sendOptionId , ProcessId}: props) => {
    let items: any[]=[{Id:null , Name:null}]
    const [response, setResponse] = useState<any>();
    const [defaultIcon, setDefaultIcon] = useState<any>();
    const [erroreMsg, setErroreMsg] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);
    const [selectedOption, setSelectedOption] = useState<any>();

    

    const getProcessIconsAsync = async (IconId=null)=>{
      setLoading(false)
        try{
           const res = await getProcessIcons()
           res.Data.forEach(item => {
            if( IconId &&  item.Id ==IconId ){
              
              setSelectedOption(
                {
                  value: (item.Id),
                  label: (
                    <div className=" d-flex ">
                      <FontAwesomeIcon
                        icon={[
                          item.IconType,
                          item.IconName,
                        ]}
                        className="ml-1 " 
                        height="20px"
                        width="20px"
                      />
                      <div className="px-2">
                          {item.IconName}              
                      </div>            
                    </div>
                  )
                }

              )


            }
           });
           setResponse(res.Data)
        } catch(error){

          setErroreMsg(true)
        }
        setLoading(true)
    }
    const getProcesIconAsync = async ()=>{
      setLoading(false)
      try {
        const res = await getProcesIcon(ProcessId)
        getProcessIconsAsync(res.Data)
      } catch (error) {
        
        setErroreMsg(true)

      }
      


      setLoading(true)


    }


    const handleChange = (e) => {      
      setSelectedOption(e)
      sendOptionId(e.value)
  }



    if (response) {
        response.map(item => (
          items.push({
            value: (item.Id),
            label: (
              <div className=" d-flex ">
                <FontAwesomeIcon
                  icon={[
                    item.IconType,
                    item.IconName,
                  ]}
                  className="ml-1 " 
                  height="20px"
                  width="20px"
                />
                <div className="px-2">
                    {item.IconName}              
                </div>            
              </div>
            )
          })
        ))
    }
    

    useEffect(()=>{
        if(ProcessId){
          getProcesIconAsync()
        }

       

    },[])

    return (
      <>
      {loading ?
        <>
        {!erroreMsg ?
          <SelectBox
          selectType="selectImg"
          onChange={handleChange}
          options={items}
          val={items}
          value={selectedOption}
          name=""
          />
          : 
        <Message message={"NoDataForPreview"} type={"error"} />   
        }
          </>
        : <Loading/>
      }
      
      
      
      
      </>

    )
    
}