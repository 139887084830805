
import { DataGrid } from "components/DataGrid/DataGrid";
import { useEffect, useState } from "react";
import { Button } from "components/Button/Button";
import { ButtonArrow } from "components/ButtonArrow/ButtonArrow";
import { Toast } from "components/ToastShow/ToastShow";
import { Search } from "components/Search/Search";
import { getPositionList } from "../api";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";








interface Form{
    Title?:string,
    assignmentMethod?:string,
    Status?:number,
    showForm:(e) => void,
    accessDatahandler:(e) => void,
    modalWidth?:any
    archive?:any
    accessData?:any
    mode:any
}


export const PriceListAccess: React.FC<Form> = ({showForm,accessDatahandler ,accessData, modalWidth , mode,archive}) => {
    const [flag , setFlag] = useState(false)
    const [response , setResponse] = useState<any>([])
    const [assignmentResponse , setAssignmentResponse] = useState<any>([])
    const [selectList , setSelectList] = useState<any>([])
    const [assignedList , setAssignedList] = useState<any>([])
    const [savedData , setSavedData] = useState<any>([])
    const [assignedSelectList , setAssignedSelectList] = useState<any>([])
    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)
    const [positionstr , setPositionstr] = useState<any>()
    const [positionAssignmentstr , setPositionAssignmentstr] = useState<any>()
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [modalData, setModalData] = useState<{add: boolean, Delete: boolean, view: boolean, edit: boolean} | undefined>();
    const [submitted, setSubmitted] = useState<boolean>(false)
   
    const [res2,setRes2]=useState(null)



    const breakPoint1 = modalWidth < 50 || width < 750


    window.onresize = () => {
      setWidth(window.innerWidth)
  }

    let keys, Data:any = [], Dataassign:any = [];

    async function getPositionsListAsync(SearchString) {
   
            const Positionresponse = await getPositionList(SearchString);
            let list:any=[]
       
          Positionresponse.Data.forEach(item => {
            let obj:any =new Object
            obj.Id=item.Id
            obj.TU_RELATION=1
            obj.Title=item.Description
            list.push(obj) 
          });



if(!SearchString){
  setRes2(list)
}

         setResponse(list)
      
            

        
  }
    async function getPositionsListSearchingAsync(SearchString) {
       

            const Positionresponse = await getPositionList(SearchString); 
            let list:any=[]
            Positionresponse.Data.forEach(item => {
              let obj:any =new Object
              obj.Id=item.Id
              obj.TU_RELATION=1
              obj.Title=item.Description
              list.push(obj) 
            });

             setAssignmentResponse(list)   
        

  }



    async function getAccessDataAsync() {

      let makeData:any =[] 
 
      if(accessData){ 
        accessData.map(item=>{
          makeData.push({Id:item.GroupPositionId ? item.GroupPositionId :item.PositionId,TU_RELATION :item.GroupPositionId ? 2 :1  , Title:item.Description })
        })
        setSavedData(makeData)
        setAssignedList(makeData)
      }
    }


    function hide() {
      showForm(false)
    }




    function backward() {
      setAssignedSelectList([])
      setResetMultiSelect(!resetMultiSelect)


      const updatedData = selectList.map(item => {
        return {...item, ...modalData}
      })
      
      setAssignedList(assignedList.concat(updatedData))
      setFlag(!flag)
      setSelectList([])
      setSubmitted(false)
    }



    function forward() {
      setResetMultiSelect(!resetMultiSelect)
      let list:any =[]
      assignedList.forEach((item1,index1) => {
          let flag = true

          assignedSelectList.forEach((item2 , index2 ) => {
              if(item2.Id ==item1.Id ){
                flag = false
              }
          });
          if(flag){
            list.push(item1)
          }
        });

        setAssignedList(list)
    }



    const selectListCallBack =(list)=>{
        
      setSelectList(list[1])
    }

    const assignedselectListCallBack =(list)=>{
        setAssignedSelectList(list[1])
    }

    const referenceFormSubmit =(e)=>{
      e.preventDefault()
          e.preventDefault();
          let Data = assignedList.map(item=>{
            let obj ={
              [item.TU_RELATION == 1 ? "PositionId"  :"GroupPositionId" ] : item.Id,
              [item.TU_RELATION == 1 ? "PositionDescription"  :" " ] : item.Title,

            }
            return obj
          })
        accessDatahandler(Data?.length>0 ? Data : [] )
      
    }

      const searching =(e)=>{
        setPositionstr(e == "" ? null:e)
        getPositionsListAsync(e == "" ? null:e)
     
        
      }

      const assignmentsearching =(e)=>{
        setPositionAssignmentstr(e == "" ? null:e)
        getPositionsListSearchingAsync(e == "" ? null:e)
        
      }




    useEffect(() => {
        getPositionsListAsync(positionstr);     
        getPositionsListSearchingAsync(positionAssignmentstr)
    }, []);

    useEffect(() => {
      if(mode != "create"){

        getAccessDataAsync();
      }
    }, []);


    if(response && assignedList){
      let assignmentdata:any =[]

      assignedList.forEach(elm => {
      
          if(elm.TU_RELATION==1)assignmentdata.push(elm)
        
      });

      


      assignmentResponse.map(obj => {
        const isSameId = assignmentdata.some(item => item.Id === obj.Id)
        const currentItem = assignmentdata.find(item => item.Id === obj.Id)

        
        if(currentItem) { 
          const {add, view, edit, Delete} = currentItem;

          if(isSameId){
         
            Dataassign.push({...obj, add, view, edit, Delete})
            
          }
        }
      })



     // Dataassign=[]
      response.map(item1=>{
        let flag = true
        assignedList.forEach(item2 => {
          if(item1.Id ==item2.Id){
          //  Dataassign.push({...item2 , Title:item1.Title})

            
            flag=false
          }
        });
        
        if(flag){
          Data.push(item1) 
        }
      })
    


  }
    





    return ( <>

            <form onSubmit={referenceFormSubmit}>
                
                  <div style={{border:"none"}} className="card mt-3"> 
                    <div className="body">
                       
                  
                       <div style={{width:"100%" ,paddingBottom: "39px"}}  className="h-100">
                        <FormWithTitle title="AvailablePositions">
                        <div className="h-100">
                          <div className="d-flex justify-content-between align-items-center mb-1  ">
                            <Search onChange={(e)=>searching(e)} />
                           </div>
                          <div className="tableBoxInAccesses">
                              {response &&<DataGrid
                                    theadData={["Id" ,"Title"]}
                                    multiSelect={true}
                                    selectList={selectListCallBack}
                                    resetMultiSelect={resetMultiSelect}
                                    parentName={"referencePosition"}
                                    tbodyData={Data}
                                    minCellWidth={80}
                                    scrollHeight={"230px"}
                                    />
                                  
                            }                               
                          </div>
                        </div>
                        </FormWithTitle>
                       </div>
                                
                        <div className="col-lg-1 col-md-1 d-flex  flex-lg-column flex-md-column justify-content-center align-items-center">
                          <ButtonArrow type="backward" onClick={backward}/>
                          <ButtonArrow type="forward" onClick={()=>{forward()}}/>
                          
                        </div>

                        <div  style={{width:"100%" ,paddingBottom: "39px"}} className="h-100">
                          <FormWithTitle title="AssignedPositions">
                           <div className="h-100">
                        <div className="d-flex justify-content-between align-items-center mb-1 ">
                           
                          <Search onChange={(e)=>assignmentsearching(e)} />
                        </div>    
  
                          <div   className="tableBoxInAccesses">                        
                           <DataGrid
                                 theadData={["Id" ,"Title"]}
                                 multiSelect={true}
                                 parentName={"referencePositionAssigned"}
                                 tbodyData={Dataassign}
                                 selectList={assignedselectListCallBack}
                                 minCellWidth={80}
                                 resetMultiSelect={resetMultiSelect}
                                 scrollHeight={"230px"}
                             />  
    
                                
                          </div>
                           </div>
                           </FormWithTitle>  
                        </div>  
                    
                    </div>
                  
                </div>
                  <div className="col-lg-12 mt-3 btns_form">
                    <Button value="Save" btnType="submit" />
                    <Button
                      btnType="cancel"
                      value="Cancel"
                      onClick={()=>Toast("modalclosemessage" , "warning" , "alertWithoutClose" , hide )}
                    />
                </div>
              </form>
            </>
    );
}