import { MEETINGMANAGEMENT_URL } from "utils/constants";
import { ENDPOINTSMEETINGMANAGEMENT } from "utils/endpoints";
import { http } from "utils/http";


export const getMeetingById = (async (id) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.load,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {Id : id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const sendmeeting = (data , method ,processId ,referenceId) => http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.browse,
    method: method,
    params:{
        PrjUid: processId,
        referenceId:referenceId
    },
    data:data
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
})


export const updateMeetingMinutes = (data) => http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.putMinute,
    method: "put",
    data : data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

export const checkPlaceConflict = (data) => http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.conflict ,
    method: "put",
    data:data

}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


