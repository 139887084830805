import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";


export const getWarehouse = (id: any) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.browse,
    method: "get",
    params: {
        ParentId: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});



