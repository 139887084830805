import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { TableTree } from "components/TableTree/TableTree";
import { useEffect, useState } from "react";
import { getReferencesActions } from "./api";
import { ModalForm } from "components/ModalForm/ModalForm";
import { ActionsOfReference } from "pages/AfterSales/component/RefrenceHistory/ActionsOfReference";
interface ReferenceTreePropsType {
 
   
    referenceId: any,
    intab: any,

}

export const ReferenceTree = ({

    referenceId,
    intab

}: ReferenceTreePropsType) => {
    const [response , setResponse]  = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [showActionOfReference, setShowActionOfReference] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>();

async function ReferenceAndActionsasync(referenceId) {
    setLoading(false)
    try {
        const res =   await getReferencesActions(referenceId )
   
       setResponse(res.Data)
    } catch (error) {
        setResponse(null)
        setMessageType("error")
    }
    setLoading(true)

}

    useEffect( () => {
        if(intab)
            ReferenceAndActionsasync(referenceId)
        
    }, [referenceId ,intab]);

    return (
       

        <>
        <div  >
                  {
        loading ? (
            response ?
              <TableTree
                italic={"Reversed"}
                params={"referenceId"}
                Data={ response}
                compressed={true}
                theadData={ Object.keys(response[0])}
                DoubleClick={(e)=>{setSelectedRow(e ) ;setShowActionOfReference(true) }}
                parentName={"ReferenceTree"}
                startIndex={4}
                Childrenparams={"ReferenceList"}
                defultShow={true}
                /> 
                : <Message message={"NoDataForPreview"} type={messageType} />       ) 

             : <Loading />
          }
        </div>        
        
        {showActionOfReference &&
                <ModalForm mode={"edit"} title={("Actions")} onHide={() => { setShowActionOfReference(false) }} show={showActionOfReference}>
                    <ActionsOfReference referenceId={selectedRow.Id} />
                </ModalForm>
            }
        </>





    );
}