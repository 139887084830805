import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BellNotification } from "components/BellNotification/BellNotification";
import { Image } from "layouts/DashboardLayout/components/Header/component/flagImg/image";
import { listFlagLang } from "layouts/DashboardLayout/components/Header/component/flagImg/listFlagLang";
import { Profile } from "layouts/DashboardLayout/components/Header/component/profile/profile";
import { SettingMenu } from "layouts/DashboardLayout/components/Header/component/SettingMenu/SettingMenu";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import ksaFlag from "assets/image/ksaFlag.png";
import iranFlag from "assets/image/iranFlag.png";
import uflag from "assets/image/uflag.jpg";
import { useContext, useEffect, useState } from "react";
import { trans, useOutsideClick } from "utils/helpers";
import "./header.scss";
import { getResources } from "../Sidebar/api";
import { ICON_TYPE } from "utils/constants";
import { ChatNotification } from "components/ChatNotification/ChatNotification";
import { FormsNotification } from "components/FormsNotification/FormsNotification";
import { FullScreanButton } from "./component/fullScreanButton/FullScreanButton";

import { EventNotification } from "components/EventNotification/EventNotification";
import { BoardNotification } from "components/BoardNotification/BoardNotification";
import { Link, useNavigate } from "react-router-dom";
import { Toast } from "components/ToastShow/ToastShow";
import { ItemGroupSearch } from "pages/ClientPortal/ECommerceDEMO/components/ItemGroupSearch/ItemGroupSearch";
import { LeftSidebar } from "pages/ClientPortal/ECommerce/components/ItemBuyBtn/LeftSidebar";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { OrganizationType } from "types/enum";
export const Header = (props) => {

  const layoutContext = useContext(LayoutContext);
  const [lang, setLang] = useState<any>();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showFlagList, setShowFlagList] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);

  const navigate = useNavigate();

  const handleNavigate = (rout) => {
    
    if(sessionStorage.getItem("changingforms")+""=="true"){
      Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{navigate(rout);sessionStorage.setItem("changingforms" , "false");localStorage.removeItem("formsInChang")  });
    }else{
      navigate(rout)
    }
  }

  
  const toggleSettingMenu = () => {
    setShowMenu(false)
  }

  const clickedOutside = () => {
    setShowMenu(false)
  };



  let language: any = localStorage.getItem("lang");
  if (language == null) {
    language = 'fa'
  }

  useEffect(() => {
    // layoutContext.setLang(language);
    if (language == "fa") {
      setLang("fa")
      layoutContext.handleImgFlag(iranFlag)
    } else if (language == "en") {
      setLang("en")

      layoutContext.handleImgFlag(uflag)
    } else if (language == "ar") {
      setLang("ar")
      layoutContext.handleImgFlag(ksaFlag)
    }
    else {
      setLang("fa")
      layoutContext.handleImgFlag(iranFlag)
    }

    if (localStorage.getItem(language) == null) {
      // localStorage.setItem("lang","en");
      getResources().then(res => { if (res.data) localStorage.setItem(language, res.data) })
    }

  }, [localStorage.getItem('lang')]);



  const settingMenuBtn = useOutsideClick(clickedOutside)

  const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));

  const openSidebar=()=>{
    setOpen(!open)
    props.handleToggleSidebar()
  }

  const onBlurAsync = async ()=>{
    try {
      await delay(250); 
      setShowMenu(false);
    } catch (error:any) {

    }
  }


  return (
    <div className={`wrapHeader ${layoutContext.sidebarIsOpen ? "pe-0" : ""}`}>
      <div className="iconsNavbar d-flex align-items-center justify-content-between">
        <ul className=" d-flex align-items-center marginHeader">
        
        {!props.sidebarIsOpen &&  <li className="header-itemsIcons barsIcon">
            <span className="cursorPointer font-size-xl">
              <FontAwesomeIcon icon={['fas', 'bars']} onClick={openSidebar}  />
            </span>
          </li>}
          <li className="header-itemsIcons" onMouseLeave={() => { setShowFlagList(false) }}>
            <div className="parent-flag">
              <div onClick={() => { setShowFlagList(!showFlagList) }} onMouseEnter={() => { setShowFlagList(true) }} className="d-flex align-items-center"> 
                <img 
                  className=""
                  src={layoutContext.ImgFlag}
                  width="20"
                  height="15"
                />
              </div>
              {showFlagList && <div className="items-flag glassBox">
                {listFlagLang.map((item, index) => (
                  <a href="#" key={item.lang} onClick={() => { setShowFlagList(false) }}>
                    <Image image={item.img} lang={item.lang} />
                  </a>
                ))}
              </div>}
            </div>
          </li>
          <li className="header-itemsIcons">
          <OverlayTrigger
        placement="bottom"
        delay={{"show" : 300 , "hide":0}}
        overlay={
            <Tooltip className="tooltip" id={`button-tooltip-${"home"}`}>
                {trans('Home')}
            </Tooltip>
        }
        >
            <Link className="position-relative"  onClick={(e)=>{e.preventDefault();handleNavigate( "/")}} to={"/"}>
             <FontAwesomeIcon icon={[ICON_TYPE, "home"]} />
          </Link>
      </OverlayTrigger>
   
           
          </li>
          <li className="header-itemsIcons">
            <BellNotification />  
          </li>
          <li className="header-itemsIcons">
            <ChatNotification />
          </li>
            <EventNotification />
            <FormsNotification />
       
          <li className="header-itemsIcons">
            <BoardNotification />
          </li>
          <li className="header-itemsIcons">
            <FullScreanButton />
          </li>
  
         {window.location.href.includes("e-commerce") && layoutContext?.currentUserPosition?.OrganizationType ==OrganizationType.Customers &&
            <li>
            <div className="header-itemsIcons">
              <LeftSidebar/>
            </div>
          </li> }

          {/* {window.location.href.includes("e-commerce") &&
          <>          
            <li>
            <div className="header-itemsIcons">
              <ItemGroupSearch/>
            </div>
            </li>
          </>
          } */}
        </ul>
        <ul className="d-flex align-items-center">
          <li className="header-itemsIcons">
              <Profile />
          </li>
          <li className="header-itemsIcons ">
            <button className="settingIcon" ref={settingMenuBtn} onBlur={onBlurAsync} onFocus={() =>{ setShowMenu(true)}} >
              <FontAwesomeIcon
                icon={[ICON_TYPE, "sliders"]}
                />
            </button>
            <SettingMenu setShowMenu={toggleSettingMenu} showMenu={showMenu} />
          </li>
        </ul>
      </div>
    </div>
  );
};
