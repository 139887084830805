import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { TableTree } from "components/TableTree/TableTree";
import { useEffect, useState } from "react";

import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { Button } from "components/Button/Button";
import { Toast } from "components/ToastShow/ToastShow";
import { getPageSize, trans } from "utils/helpers";
import { SwitchBtn } from "components/SwitchBtn/SwitchBtn";
import { DataGrid } from "components/DataGrid/DataGrid";
import { getWarehouseItems, getWarehouseItemTree } from "./api";
import { getItemGroup } from "../ItemTree/api";
import { Toolbar } from "components/Toolbar/Toolbar";

interface Prop {
  itemCodeHandeler: (e, bool) => void;
  onHide: () => void;
  warehouseId?: any;
  warehouseDetailId?: any;
  priceListId?: any;
  showGrid?: any;
  isWarehouseItem?: boolean;
  isInpriceList?: boolean;
}

export const ItemTreeForm = ({
  itemCodeHandeler,
  isWarehouseItem,
  isInpriceList = false,
  showGrid = true,
  priceListId,
  warehouseId,
  warehouseDetailId,
  onHide,
}: Prop) => {
  const [data, setData] = useState<any>();
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const [selectedObj, setSelectedObj] = useState<any>();
  const [idCallback, setIdCallback] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<any>("NoDataForPreview");
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pages, setPages] = useState<number>(0);
  const [filterlist, setFilterlist] = useState<any>("");
  const [selectList, setSelectList] = useState<any>([]);
  const [showMode, setShowMode] = useState<any>(false);
  const [orderBy, setOrderBy] = useState<any>([]);
  const [search, setSearch] = useState(null);

  const setselecetrow = (e) => {
    setSelectedObj(e);
  };
  const sendTreeCode = (e, obj = selectedObj) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (obj) {
      itemCodeHandeler(obj, !showMode);
    } else {
      Toast(trans("Msg_SelectRow"), "warning");
    }
  };

  const searchHandler = (str) => {
    setPageNum(1);
    setSelectedObj(null);
    if (str === "") {
      setSearch(null);
      getItemTreeFormAsync(1);
    } else {
      setSearch(str);
      getItemTreeFormAsync(1);
    }
  };

  const handlechangePageSize = (e: any) => {
    setPageNum(1);
    setPageSize(e.currentTarget.value);
    getPageSize("ItemTreeForm2", e.currentTarget.value);
    getItemTreeFormAsync(1);
  };

  const handleGoPage = (value) => {
    if (value) {
      if (value < 1 || value > pages) {
      } else setPageNum(+value);
      getItemTreeFormAsync(+value);
    } else {
    }
  };
  const handleNextPage = () => {
    if (pageNum < pages) {
      setPageNum(pageNum + 1);
      getItemTreeFormAsync(pageNum + 1);
    }
  };
  const handlePrevPage = () => {
    if (pageNum > 1) {
      setPageNum((pageNum) => {
        return +pageNum - 1;
      });
      getItemTreeFormAsync(pageNum - 1);
    }
  };

  async function getItemTreeFormAsync(PageNum = pageNum) {
    setLoading(false);
    let pageSizeChangedByUser = getPageSize("ItemTreeForm2");
    setPageSize(pageSizeChangedByUser);
    try {
      if (showMode) {
        const respons = await getWarehouseItemTree(
          warehouseId == "allWarehouses" ? null : warehouseId,
          isWarehouseItem,
          warehouseDetailId,
          priceListId
        );
        setData(respons.Data);
      } else {
        if ((!priceListId && !warehouseId) || isInpriceList) {
          const respons = await getItemGroup(
            warehouseId == "allWarehouses" ? null : warehouseId,
            PageNum,
            pageSizeChangedByUser,
            orderBy.length == 0 ? null : orderBy.join(","),
            search,
            filterlist
          );
          setPages(
            respons.Data.TotalRecords > pageSizeChangedByUser
              ? Math.ceil(respons.Data.TotalRecords / pageSizeChangedByUser)
              : 1
          );
          setData(respons.Data);
        } else {
          const respons = await getWarehouseItems(
            warehouseId == "allWarehouses" ? null : warehouseId,
            isWarehouseItem,
            warehouseDetailId,
            priceListId,
            PageNum,
            pageSizeChangedByUser,
            orderBy.length == 0 ? null : orderBy.join(","),
            search,
            filterlist
          );
          setPages(
            respons.Data.TotalRecords > pageSizeChangedByUser
              ? Math.ceil(respons.Data.TotalRecords / pageSizeChangedByUser)
              : 1
          );
          setData(respons.Data);
        }
      }
    } catch (err: any) {
      setMessage(err?.data?.Message);
    }
    setLoading(true);
  }

  useEffect(() => {
    setData(null);
    getItemTreeFormAsync();
  }, [forceUpdate, showMode, search, filterlist, orderBy]);

  useEffect(() => {
    if (idCallback) {
      setIdCallback(null);
    }
  }, [idCallback]);

  return (
    <>
      <div className="row align-items-start justify-content-between">
        <div className="col ">
          <Toolbar
            more={false}
            excel={false}
            refresh={false}
            helpBtn={false}
            view={false}
            handleClickToolbar={() => {}}
            id={selectedObj}
            handleSearch={searchHandler}
            search={true}
            warehouse={false}
            emptyList={data?.Data?.length==0}
          />
        </div>
        <div className="col h-100 text-start d-flex justify-content-end ">
          <div style={{ maxWidth: "150px", height: "100%" }}>
            <SwitchBtn
              handleModeCard={setShowMode}
              setShape="circle"
              firstMode="درختی"
              secondMode="لیستی"
              cardStyle={showMode}
            />
          </div>
        </div>
      </div>

      <div
        style={{  overflowY: "auto", 
          height: "70vh",
           scrollbarWidth: "thin" }}
      >
        <div className="col-lg-12 mt-1 btns_form">
          <Button
            value="select"
            btnType="submit"
            onClick={(e) => {
              sendTreeCode(e);
            }}
          />
          <Button
            btnType="cancel"
            value="Cancel"
            onClick={(e) => {
              onHide();
            }}
          />
        </div>
        {loading ? (
          data ? (
            <>
              {!showMode ? (
                <div>
                  {data?.Data ? (
                    <DataGrid
                      parentName={"ItemTreeForm2"}
                      theadData={Object.keys(data.Data[0])}
                      tbodyData={data.Data}
                      minCellWidth={80}
                      DoubleClick={(e) => {
                        sendTreeCode(null, e);
                      }}
                      selectedRowObjectCallback={setselecetrow}
                      totalRecords={data.TotalRecords}
                      pageNum={pageNum}
                      orderFormat={setOrderBy}
                      orderFormatList={orderBy}
                      handlefiltering={(str) => {
                        setFilterlist(str);
                      }}
                      filterStr={filterlist}
                      pageSize={pageSize}
                      HandleNextPage={handleNextPage}
                      HandlePrevPage={handlePrevPage}
                      handlechangePageSize={handlechangePageSize}
                      first={() => {
                        getItemTreeFormAsync(pages);
                        setPageNum(pages);
                      }}
                      end={() => {
                        getItemTreeFormAsync(1);
                        setPageNum(1);
                      }}
                      handleGoPage={handleGoPage}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div>
                  {data && data?.length > 0 && (
                    <TableTree
                      baseUrl={INVENTORYANDSALES_URL}
                      selectedRowObjectCallback={setselecetrow}
                      selectedRowObject={idCallback}
                      DoubleClick={(e) => {
                        sendTreeCode(null, e);
                      }}
                      getChildrenUrl={
                        ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi
                          .PriceListDetailApis.itemsTree
                      }
                      theadData={[
                        "Id",
                        "ParentId",
                        "Children",
                        "Description",
                        "Code",
                      ]}
                      startIndex={2}
                      defultparams={{
                        warehouseId: warehouseId
                          ? warehouseId == "allWarehouses"
                            ? null
                            : warehouseId
                          : JSON.parse(localStorage.getItem("Inventory") + "")
                              ?.Id,
                      }}
                      Titleparams={"Description"}
                      Childrenparams={"Children"}
                      defultShow={false}
                      Data={data}
                      parentName={"ItemTreeForm"}
                    />
                  )}
                </div>
              )}
            </>
          ) : (
            <Message message={message} type={"error"} />
          )
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};
