import { ItemCard } from "./ItemCard";
import { FilterBox } from "../FilterBox/FilterBox";
import { ItemGroupLabelSlider } from "../ItemGroupMenu/ItemGroupLabelSlider";
import { useEffect, useState } from "react";

interface ItemCardsProps {
    data?: any,
    cardStyle?: any,
    dataGroup?:any,
    filterId?:any,


}

export const ItemCards = ({data,dataGroup,filterId ,cardStyle}:ItemCardsProps) => {
   
    const [filterObj , setfilterObj]= useState<any>("");
    
    useEffect(()=>{
        if(filterId == null){
        setfilterObj({})
        }    
      },[filterId])
      
      
    return ( 
        <>  

            <div className="row w-100" style={{ overflowY: "auto", 
                height: "75vh",
                 scrollbarWidth: "thin"}}>

                <div className="col-lg-2 d-lg-block d-none">
                    <FilterBox setfilterObj={(e)=>{setfilterObj({...filterObj,...e})}} filterData={{type:1,title:"بر اساس قیمت"}} />
                    {filterId=="E36E1489-83FC-EE11-AB53-00155DAA1053"&&
                    <>
                    <FilterBox setfilterObj={(e)=>{setfilterObj({...filterObj,...e})}} filterData={{type:2,data:["طبیعی","مصنوعی"],title:"بر اساس جنس چرم"}}/>
                    <FilterBox setfilterObj={(e)=>{if(filterObj.option2){setfilterObj({...filterObj,...{option2:null}})}else{setfilterObj({...filterObj,...e})}  }} filterData={{type:3,data:["ورزش"],title:"بر اساس کاربرد "}}/>
                   </>
                   }
                     {filterId=="084E4FDE-6D74-ED11-AB39-00155DAA1053"&&
                  <>
                    <FilterBox setfilterObj={(e)=>{setfilterObj({...filterObj,...e})}} filterData={{type:2,data:["100-240V AC","24-220V DC"],title:"بر اساس ولتاژ کاری"}}/>
                    <FilterBox setfilterObj={(e)=>{if(filterObj.option2){setfilterObj({...filterObj,...{option2:null}})}else{setfilterObj({...filterObj,...e})}  }} filterData={{type:3,data:["آب بندی شده"],title:"بر اساس آب بندی "}}/>
                    </>
                    }
               
                </div>
                <div className="col-lg-10 col-12">
                    <div className="row">
                        <ItemGroupLabelSlider children={dataGroup}/>
                    </div>
                    <div className="row p-3" >
                       
                     
                        {data&&data.map((item,index) =>(
                            (( (filterObj?.min ? +filterObj?.min< +item.ItemPriceListDetails[0].CoverPrice:true)&& (filterObj?.max ? +filterObj?.max> +item.ItemPriceListDetails[0].CoverPrice:true))&& (filterObj.option?item.option.includes(filterObj.option):true) &&(filterObj.option2?  item.option.includes(filterObj.option2):true))?
                           <>
                           <ItemCard key={index+'ItemCards'}  ItemData={item} cardStyle={cardStyle}/>
                           </>
                            :<></>
                            ))}
                    </div>
                </div>
            </div>

        </>
     );
}
 