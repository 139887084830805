import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";



export const sendBulletinBoard = (data , method ,SendSMS) => http({
    url: ENDPOINTS.bulletinBoard.browse,
    method: method,
    data:data,
    params:{
        sendMessage:SendSMS
    }
}).then(response => response.data)
    .catch(err => {
        return Promise.reject(err);
    })
export const deleteBord = (id) => http({
    url: ENDPOINTS.bulletinBoard.browse,
    method: "DELETE",
    params:{
        Id:id
    }
}).then(response => response.data)

.catch(err => {
        return Promise.reject(err);
    })


export const getBoardId = (id) => http({
    url: ENDPOINTS.bulletinBoard.id,
    method: "get",
    headers: { "Accept-Language": "en" },
    params:{
        Id:id,
    }

}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })
export const getBoardList = (pageNumber , pageSize, orderFormat: string = "", searchString?: any, filterlist?: any) => http({
    url: ENDPOINTS.bulletinBoard.load,
    method: "get",
    params:{
        PageNumber:pageNumber,
        PageSize:pageSize,
        OrderByString: orderFormat,
        SearchString: searchString,
        FilterString: filterlist
    }

}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })

export const updateBordEndDate = (id , endDate) => http({
    url: ENDPOINTS.bulletinBoard.endDate,
    method: "put",
   // headers: { "Accept-Language": "en" },
    params:{
        BulletinBoardId: id ,
        endDate:endDate
    }

}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })

export const sendBordAccess = (data , id) => http({
    url: ENDPOINTS.bulletinBoard.access,
    method: "put",
    data:data,
    params:{
        bulletinBoardId:id
    }

}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })
export const getBordAccess = (bulletinBoardId) => http({
    url: ENDPOINTS.bulletinBoard.access,
    method: "get",
    params:{
        bulletinBoardId:bulletinBoardId
    }

}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })


 //----------------------ACCESS---------------------
 
export const  getGroupPositionList =( async ( searchString) =>  await http({
    url: ENDPOINTS.BaseInformation.groupPosition.dictionary,
    method: "get",
    params:{
        SearchString : searchString
    }

    

})
.then((response) => (response.data)) 
.catch(err => {
    return Promise.reject(err);
})
)

export const  getPositionList =( async (searchString ) =>  await http({
    url: ENDPOINTS.BaseInformation.position.browse,
    method: "get",
    params : {
        searchString : searchString
    }

    

})
.then((response) => (response.data)) 
.catch(err => {
    return Promise.reject(err);
})
)


