import { Widget } from 'components/Widget/Widget'
import { getpersonalTask } from 'pages/DashboardPage/api/api'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'


interface props {
    id: string
}

export const WidgetPersonalTask = ({id}:props) => {
    const [tasks, setTask] = useState<any>(null)
    async function getEvetsAsync() {
        try {
        const res = await getpersonalTask()
        setTask(res)        
          
        } catch (error) {
            setTask([])
        }
    }
    useEffect(() => {
        getEvetsAsync()
    }, [])
    return (
        <>
        {(tasks&&tasks.length >0) &&
        <Widget title='PersonalTask' icon={['fas', 'calendar']} color='info' id={id}  >
            <div className='widget-calendar p-2'>
                <ul>
                    {tasks ?
                    <>
                    {tasks.length > 0 ?
                      <>
                         {
                             tasks.map((item, index) => (
                                 <div className={`widget-calendar__list d-flex justify-content-between `}>
                                  <Link to={'/personal-planing/personal-tasks'}>
                                          <li  style={{fontSize: "13px"}} className='mb-1' key={index}>{item.Description}</li>
                                  </Link>
                                  <Link to={'/personal-planing/personal-tasks'}>
                                          <p style={{fontSize: "13px"}}  className='mb-1' key={index}>{item.PersonalTaskDate}</p>
                                  </Link>

                              </div>
                          ))
                        }
                        </>
                        :
                        <></>
                        
                    }
                    </>
                        :
                        <div className="d-flex justify-content-center align-items-center text-info h-100">
                           <div className="spinner-border spinner-border-md" role="status"></div>
                        </div>
                    }
                </ul>
            </div>
        </Widget>
        }
     </>
    )
}