export const Data = [
    {
        Id:'[itemid1]',
        GroupId:'E36E1489-83FC-EE11-AB53-00155DAA1053',
        GroupDetailId:'E36E1489-83FC-EE11-AB53-001552561053',
        nameProductFa:'کلاه کپ مدل PZ114 ',
        nameProductEn:'Cap model PZ114',
        Stock:'80',
        rate:'4.1',
        like:true,
        Unit:'جعبه',
        notification:'',
        ItemPriceListDetails:[
            {
                Id:'[companyid1]',
                companyName:'پوشاک آتیش کش',
                UnitPrice:'100000',
                CoverPrice:'120000',
                detail:[
                    {
                        off:'10',
                        discountPrice:'90000',
                        min:'1',
                        max:'29'
                    },
                    {
                        off:'20',
                        discountPrice:'80000',
                        min:'30',
                        max:'49'
                    },
                    {
                        off:'22',
                        discountPrice:'78000',
                        min:'50',
                        max:null
                    }
                ]
            },
            {
                Id:'[companyid2]',
                companyName:'پوشاک امیر',
                UnitPrice:'950000',
                CoverPrice:'900000',
                detail:[
                    {       
                    off:null,
                    discountPrice:null,
                    min:null,
                    max:null,
                    }
                ]
            }
        ],
        increaserDecreaser:[
            {
                Id:'[increaserDecreaser1]',
                title:'بیمه پارسیان',
                price:'50000',
                off:null,
                discountPrice:null,
                discription:"جبران ۱۰۰ درصدی انواع صدمات فیزیکی مانند ضربه دیدگی، پارگی و آب‌خوردگی غیرعمدی",
                active:false,
            },
            {
                Id:'[increaserDecreaser2]',
                title:'ارسال از 1 روز کاری',
                price:'10000',
                off:null,
                discountPrice:null,
                discription:"",
                active:true,
            },       
            {
                Id:'[increaserDecreaser3]',
                title:' گارانتی اصالت و سلامت فیزیکی کالا ',
                price:'5000',
                off:null,
                discountPrice:null,
                discription:"",
                active:false,
            }
        ],

        FixedFeature:[
            {
                title:'نوع سرپوش',
                discription:'ساده',
            }
        ],
        option:[""],


        properties:{
            color:[{Id:'22',value:'#964B00'},{Id:'11',value:'#000000'},{Id:'33',value:'#E32227'},{Id:'44',value:'#0000D1'}],
            matrial:[{Id:'11',value:'الیاف نخی'},{Id:'22',value:'لی'},{Id:'33',value:'کتان'}]
        }

    },
    {
        Id:'[itemid6]',
        GroupId:'084E4FDE-6D74-ED11-AB39-00155DAA1053',
        GroupDetailId:'084E4FDE-6D74-ED11-AB39-0015596A1053',
        nameProductFa:'کنتاکتور پارس فانال 12A - 5.5KW مدل PFC12 , PFD12',
        nameProductEn:'Pars Fanal contactor 12A - 5.5KW model PFC12, PFD12',
        Stock:'86',
        rate:'4.1',
        Unit:'جعبه',
        option:["100-240V AC"],
        like:false,
        notification:'',
        ItemPriceListDetails:[
            {
                Id:'[companyid1]',
                companyName:'کالا صنعت',
                UnitPrice:'235000',
                CoverPrice:'255000',
                detail:[
                    {       
                        off:null,
                        discountPrice:null,
                        min:null,
                        max:null,
                    }
                ]
            },
        ],

        increaserDecreaser:[
            {
                Id:'[increaserDecreaser2]',
                title:'ارسال از 1 روز کاری',
                price:'10000',
                off:null,
                discountPrice:null,
                discription:"",
                active:true,
            },       
        ],
        FixedFeature:[
            {
                title:'ویژگی',
                discription:'کنتاکتور پارس فانال با رنج جریان 12 آمپر و توان 5.5 کیلووات',
            },
            {
                title:'تیپ ',
                discription:'AC3',
            }
        ],
        properties:{

        }
    },
    {
        Id:'[itemid2]',
        GroupId:'E36E1489-83FC-EE11-AB53-00155DAA1053',
        GroupDetailId:'E36E1489-83FC-EE11-AB53-001589631053',

        nameProductFa:'ست هدیه چرم عطارد مدل A13',
        nameProductEn:'Mercury leather gift set model A13',
        Stock:'10',
        rate:'4.8',
        Unit:'جعبه',
        option:["طبیعی"],

        like:false,
        notification:'',
        ItemPriceListDetails:[
            {
                Id:'[companyid2]',
                companyName:'چرم کرمان',
                UnitPrice:'850000',
                CoverPrice:'900000',
                detail:[
                    {       
                        off:null,
                        discountPrice:null,
                        min:null,
                        max:null,
                    }
                ]
            }
        ],
        increaserDecreaser:[
            {
                Id:'[increaserDecreaser1]',
                title:'بیمه پارسیان',
                price:'50000',
                off:'10',
                discountPrice:'40000',
                discription:"جبران ۱۰۰ درصدی انواع صدمات فیزیکی مانند ضربه دیدگی، پارگی و آب‌خوردگی غیرعمدی",
                active:false,
            },
            {
                Id:'[increaserDecreaser2]',
                title:'ارسال از 1 روز کاری',
                price:'',
                off:null,
                discountPrice:null,
                discription:"",
                active:true,
            },       
            {
                Id:'[increaserDecreaser3]',
                title:' گارانتی اصالت و سلامت فیزیکی کالا ',
                price:'5000',
                off:null,
                discountPrice:null,
                discription:"",
                active:false,
            }
        ],

        FixedFeature:[
            {
                title:'اقلام ست',
                discription:' جاکلیدی , جاکارتی',
            },            {
                title:'نگهداری',
                discription:'واکس بی رنگ مخصوص چرم طبیعی',
            },            {
                title:'کشور تولید کننده',
                discription:'ایران',
            },            {
                title:'جزئیات',
                discription:' تمام چرم طبیعی برند ثبت شده چرم عطارد  تولید شده توسط برند رسمی چرم عطارد   بسته بندی شکیل (وکیوم شده ) مناسب برای هدیه با حک لوگو و نوشته چرم عطارد ',
            },
        ],
        properties:{
            color:[{Id:'22',value:'#964B00'},{Id:'11',value:'#000000'}],
            size:[{Id:'11',value:'بزرگ'},{Id:'22',value:'متوسط'}],
            matrial:[{Id:'44',value:'چرم طبیعی'},{Id:'55',value:'چرم مصنوعی'}]
        }

    },
    {
        Id:'[itemid8]',
        GroupId:'084E4FDE-6D74-ED11-AB39-00155DAA1053',
        GroupDetailId:'084E4FDE-6D74-ED11-8953-00155DAA1053',
        nameProductFa:'کلید مینیاتوری تک پل/ تکفاز 50 آمپر هیوندای',
        nameProductEn:null,
        Stock:'129',
        rate:'3.5',
        Unit:'جعبه',
        option:["آب بندی شده"],
        like:false,
        notification:'',
        ItemPriceListDetails:[
            {
                Id:'[companyid1]',
                companyName:'کالا صنعت',
                UnitPrice:'65100',
                CoverPrice:'75000',
                detail:[
                    {       
                        off:null,
                        discountPrice:null,
                        min:null,
                        max:null,
                    }
                ]
            },
        ],

        increaserDecreaser:[
            {
                Id:'[increaserDecreaser2]',
                title:'ارسال از 1 روز کاری',
                price:'10000',
                off:null,
                discountPrice:null,
                discription:"",
                active:true,
            },       
        ],
        FixedFeature:[
            {
                title:'رنج جریان',
                discription:'50A',
            },
            {
                title:'قدرت قطع  ',
                discription:'6KA',
            },
            {
                title:'تیپ ',
                discription:'B,C',
            },
            {
                title:'کاربرد  ',
                discription:'موتوری – روشنایی ',
            }
        ],
        properties:{

        }
    },
    {
        Id:'[itemid3]',
        GroupId:'E36E1489-83FC-EE11-AB53-00155DAA1053',
        GroupDetailId:'E36E1489-83FC-EE11-AB53-001589631053',
        nameProductFa:'کیف رودوشی مردانه مدل BR-283 ',
        nameProductEn:'Mens shoulder bag, model BR-283',
        Stock:'253',
        rate:'3.8',
        Unit:'جعبه',
        option:["مصنوعی"],

        like:false,
        notification:'',
        ItemPriceListDetails:[
            {
                Id:'[companyid1]',
                companyName:'چرم مشهد',
                UnitPrice:'250000',
                CoverPrice:'275000',
                detail:[
                    {       
                        off:null,
                        discountPrice:null,
                        min:null,
                        max:null,
                    }
                ]
            }
        ],
        increaserDecreaser:[
            {
                Id:'[increaserDecreaser2]',
                title:'ارسال از 1 روز کاری',
                price:'',
                off:null,
                discountPrice:null,
                discription:"",
                active:true,
            },       
            {
                Id:'[increaserDecreaser3]',
                title:' گارانتی اصالت و سلامت فیزیکی کالا ',
                price:'5000',
                off:null,
                discountPrice:null,
                discription:"",
                active:false,
            }
        ],

        FixedFeature:[
            {
                title:'نحوه بسته شدن کیف و کوله',
                discription:'زیپ',
            },            {
                title:'بند و دستگیره',
                discription:'بند دوشی ',
            },            {
                title:'ابعاد',
                discription:' 22x6x18 سانتی‌متر ',
            },            {
                title:'مورد استفاده',
                discription:' اسپرت روزم اداری و رسمی مهمانی ',
            },
        ],
        properties:{
            color:[{Id:'22',value:'#964B00'},{Id:'11',value:'#000000'},{Id:'55',value:'#8A8A8A'}],
            size:[],
            matrial:[{Id:'55',value:'چرم مصنوعی'}]
        }

    },
    {
        Id:'[itemid4]',
        GroupId:'E36E1489-83FC-EE11-AB53-00155DAA1053',
        GroupDetailId:'E36E1489-83FC-EE11-AB53-001552561053',
        nameProductFa:'کلاه کپ مدل S600 ',
        nameProductEn:'Cap model S600',
        Stock:'167',
        rate:'2.5',
        Unit:'جعبه',
        option:["ورزش"],
        like:false,
        notification:'',
        ItemPriceListDetails:[
            {
                Id:'[companyid1]',
                companyName:'پوشاک آتیش کش',
                UnitPrice:'100000',
                CoverPrice:'1200000',
                detail:[
                    {
                        off:null,
                        discountPrice:null,
                        min:'1',
                        max:'29'
                    },
                    {
                        off:'10',
                        discountPrice:'90000',
                        min:'30',
                        max:null
                    },
                ]
            },
        ],
        increaserDecreaser:[
            {
                Id:'[increaserDecreaser1]',
                title:'بیمه پارسیان',
                price:'50000',
                off:null,
                discountPrice:null,
                discription:"جبران ۱۰۰ درصدی انواع صدمات فیزیکی مانند ضربه دیدگی، پارگی و آب‌خوردگی غیرعمدی",
                active:false,
            },
            {
                Id:'[increaserDecreaser2]',
                title:'ارسال از 1 روز کاری',
                price:'10000',
                off:null,
                discountPrice:null,
                discription:"",
                active:true,
            }
        ],

        FixedFeature:[
            {
                title:'نوع سرپوش',
                discription:' تزیینی آفتابگیر ورزشی ',
            }
        ],
        properties:{
            color:[{Id:'66',value:'#6500B0'},{Id:'11',value:'#000000'},{Id:'33',value:'#E32227'},{Id:'77',value:'#FFFFFF'},{Id:'88',value:'#000075'}],
            size:[{Id:'33',value:'xl'},{Id:'44',value:"l"},{Id:'55',value:'md'}],
            matrial:[{Id:'66',value:'اکریلیک'},{Id:'77',value:'نخ'}]
        }

    },
    {
        Id:'[itemid5]',
        GroupId:'084E4FDE-6D74-ED11-AB39-00155DAA1053',
        GroupDetailId:'084E4FDE-6D74-ED11-AB39-0015596A1053',
        nameProductFa:'کنتاکتور هیوندای 9A - 4KW',
        nameProductEn:'Contactor Hyundai 9A - 4KW',
        Stock:'367',
        rate:'3.6',
        Unit:'جعبه',
        option:["24-220V DC"],
        like:false,
        notification:'',
        ItemPriceListDetails:[
            {
                Id:'[companyid1]',
                companyName:'کالا صنعت',
                UnitPrice:'283900',
                CoverPrice:'295900',
                detail:[
                    {       
                        off:null,
                        discountPrice:null,
                        min:null,
                        max:null,
                    }
                ]
            },
        ],

        increaserDecreaser:[
            {
                Id:'[increaserDecreaser2]',
                title:'ارسال از 1 روز کاری',
                price:'10000',
                off:null,
                discountPrice:null,
                discription:"",
                active:true,
            },       
        ],
        FixedFeature:[
            {
                title:'ویژگی',
                discription:'کنتاکتور هیوندای با رنج جریان 9 آمپر و توان 4 کیلووات',
            },
            {
                title:'تیپ ',
                discription:'AC3',
            }
        ],
        properties:{

        }
    },

    {
        Id:'[itemid7]',
        GroupId:'084E4FDE-6D74-ED11-AB39-00155DAA1053',
        GroupDetailId:'084E4FDE-6D74-ED11-8953-00155DAA1053',
        nameProductFa:'کلید مینیاتوری تک پل/ تکفاز 2 آمپر هیوندای',
        nameProductEn:'Hyundai single bridge/single phase 2 amp miniature switch',
        Stock:'381',
        rate:'3.8',
        Unit:'جعبه',
        option:[""],
        like:false,
        notification:'',
        ItemPriceListDetails:[
            {
                Id:'[companyid1]',
                companyName:'کالا صنعت',
                UnitPrice:'63600',
                CoverPrice:'72300',
                detail:[
                    {       
                        off:null,
                        discountPrice:null,
                        min:null,
                        max:null,
                    }
                ]
            },
        ],

        increaserDecreaser:[
            {
                Id:'[increaserDecreaser2]',
                title:'ارسال از 1 روز کاری',
                price:'10000',
                off:null,
                discountPrice:null,
                discription:"",
                active:true,
            },       
        ],
        FixedFeature:[
            {
                title:'رنج جریان',
                discription:'2A',
            },
            {
                title:'قدرت قطع  ',
                discription:'6KA',
            },
            {
                title:'تیپ ',
                discription:'B,C',
            },
            {
                title:'کاربرد  ',
                discription:'موتوری – روشنایی ',
            }
        ],
        properties:{

        }
    }
]