import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getCalendaryear = (async () => await http({
    url: ENDPOINTS.calendar.calendar.year,
    method: 'get',

})
.then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
}));
