
import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { useEffect, useState } from "react";
import { Headerbar } from "components/Headerbar/Headerbar";
import { getCustomerInProgressRequests, getCustomerInfo } from "./api";
import { trans } from "utils/helpers";



interface CustomerInfoForm {
    customerPositionId?:any

}

export const CustomerInfo = ({ 
    customerPositionId,
}:CustomerInfoForm) => {
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<any>();
    const [responseRequests, setResponseRequests] = useState<any>();

    async function getCustomerInfoAsync() {
        setLoading(false)

        try {
            const res = await getCustomerInfo(customerPositionId)
            if(res.Data.length >0){
                setResponse(JSON.parse(JSON.parse(res.Data)))
            }
            const res2 = await getCustomerInProgressRequests(customerPositionId)
            if(res2.Data.length >0){
                setResponseRequests(res2.Data)
            }
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }


    useEffect(() => {
        getCustomerInfoAsync();
    }, []);

    return (
        <div className="card " >
            <div className="card card-body p-3">
                {loading ?
                    <>
                    {response && response.HeaderInfo && <Headerbar headerContents={response.HeaderInfo} hasBorder={true}  />}
                    <div className="card legendCard my-3">
                    <div className="legend">{trans("ContractsInfo")}</div>
                    {response && response.ContractsInfo.length > 0 ?
                            <div className="card-body p-0">
                                <>
                                    <DataGrid
                                        disabled={true}
                                        rowSelected=""
                                        parentName={"ContractsInfoList"}
                                        theadData={Object.keys(response.ContractsInfo[0])}
                                        tbodyData={response.ContractsInfo}
                                        minCellWidth={80}
                                        startIndex={0}
                                    />
                                </>
                            </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    }
                    </div>
                    <div className="card legendCard my-3">
                    <div className="legend ">{trans("LocksInfo")}</div>
                    {response && response.LocksInfo.length > 0 ?
                            <div className="card-body p-0">
                                <>
                                    <DataGrid
                                        disabled={true}
                                        rowSelected=""
                                        parentName={"LocksInfoList"}
                                        theadData={Object.keys(response.LocksInfo[0])}
                                        tbodyData={response.LocksInfo}
                                        minCellWidth={80}
                                        startIndex={0}
                                    />
                                </>
                            </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    }
                    </div>
                    
                    <div className="card legendCard my-3">
                     <div className="legend">{trans("customerinprogressrequests")}</div>
                        {responseRequests && responseRequests.length > 0 ?
                                <div className="card-body p-0">
                                    <>
                                        <DataGrid
                                            disabled={true}
                                            rowSelected=""
                                            parentName={"ContactsInfoList"}
                                            theadData={Object.keys(responseRequests[0])}
                                            tbodyData={responseRequests}
                                            minCellWidth={80}
                                            startIndex={1}
                                        />
                                    </>
                                </div>
                            : <Message message={"NoDataForPreview"} type={messageType} />
                        }
                    </div>
                    <div className="card legendCard my-3">
                <div className="legend">{trans("ContactsInfo")}</div>
                    {response && response.ContactsInfo.length > 0 ?
                            <div className="card-body p-0">
                                <>
                                    <DataGrid
                                        disabled={true}
                                        rowSelected=""
                                        parentName={"ContactsInfoList"}
                                        theadData={Object.keys(response.ContactsInfo[0])}
                                        tbodyData={response.ContactsInfo}
                                        minCellWidth={80}
                                        startIndex={0}
                                    />
                                </>
                            </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    }
                    </div>

                    { !response &&  <Message message={"NoDataForPreview"} type={messageType} />}
                    </>
                    : <Loading />
                }
            </div>
        </div>

    );
}