
import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";
import './style.scss';

export const getMyOwnRequests = (pageNumber: number, pageSize: number, SearchString, orderBy, filterlist, Type = null) => http({
    url: ENDPOINTS.clientPortal.clientPortal.list,
    method: 'get',
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString: SearchString,
        OrderByString: orderBy,
        FilterString: filterlist,
        jobStatus: Type,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getRequestDetails = (pageNumber: number, pageSize: number, searchStr, jobId) => http({
    url: ENDPOINTS.clientPortal.clientPortal.detail,
    method: 'get',
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString: searchStr,
        JobId: jobId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const postRequest = (PRJ_UID, data) => http({
    url: ENDPOINTS.clientPortal.clientPortal.browse,
    method: "post",
    params: {
        PRJ_UID: PRJ_UID,
    },
    data: data

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getFileList = (id, name) => http({
    url: ENDPOINTS.messageHub.browse,
    method: 'get',
    params: {
        filesId: id,
        fileName: name
    },
    responseType: "blob"
}).then((res) => {
    return res.data
}).catch((err) => {
    //  
    return Promise.reject(err);
});


export const getStartForm = (processId: any) => http({
    url: ENDPOINTS.clientPortal.startForm,
    method: 'get',
    params: {
        processId: processId

    }
}).then((response) => (response.data))
    .catch(err => {

    })




/* export const getReplyClassification = (async () => await http({
    url: ENDPOINTS.cartable.ReplyClassification.dictionary,
    method: 'get',
})
    .then((response) => (response.data))

) */

export const getReplyList = (pageNum, pageSize, orderBy, str, filterlist, personalTaskStatusFilter) => http({
    url: ENDPOINTS.clientPortal.clientPortal.load,
    method: 'get',
    params: {
        page: pageNum,
        pageSize: pageSize,
        orderBy: orderBy,
        searchString: str,
        FilterString: filterlist,
        personalTaskStatus: personalTaskStatusFilter == 0 ? null : personalTaskStatusFilter
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const postReply = (data: any, id) => http({
    url: ENDPOINTS.clientPortal.clientPortal.reply,
    method: 'post',
    params: {
        replyDescription: data,
        referenceId: id
    }


}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const cancelRequest = (JobId) => http({
    url: ENDPOINTS.clientPortal.clientPortal.cancel,
    method: 'post',
    params: {
        JobId: JobId
    }


}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const deletePersonalPlaning = (id: any) => http({
    url: ENDPOINTS.clientPortal.clientPortal.load,
    method: 'delete',
    params: {
        Id: id
    }

}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const getRequesterUserId = (JobId: any) => http({
    url: ENDPOINTS.clientPortal.Responsible,
    method: 'get',
    params: {
        JobId: JobId
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const resendReference = (JobId: any) => http({
    url: ENDPOINTS.clientPortal.clientPortal.resend,
    method: 'post',
    params: {
        jobId: JobId
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});