import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getListCustomerClassification = (async(searchString,orderByString,pageNumber,pageSize) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.CustomerClassification.browse,
    method: 'get',
    params:{
        OrderByString:orderByString,
        SearchString:searchString,
        PageNumber: pageNumber,
        PageSize: pageSize,
    }
}).then((res) =>{
    return res.data
}).catch((err) =>{
    return Promise.reject(err);
})

)

export const getCustomerClassification = (async (Id) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.CustomerClassification.load,
    method: 'get',
    params: {Id : Id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)


export const postCustomerClassificationApi = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.CustomerClassification.browse,
    method: "post",
    data:data,
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

export const putCustomerClassificationApi = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.CustomerClassification.browse,
    method: "put",
    data:data,
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const getCustomerClassificationDictionary = (async () => await http({
    baseURL:INVENTORYANDSALES_URL,
    url:ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.CustomerClassification.dictionary,
    method: 'get',

})
    .then((response) => (response.data))

)


export const deleteListCustomerClassification = (id) => http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.CustomerClassification.browse,
    method: 'delete',
    params:{
        id:id
    }
    
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
});