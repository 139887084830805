import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getSalesInvoice = (pageNumber: number, pageSize: number, searchString, orderBy, filterlist ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.load,
    method: 'get',
    
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString: searchString,
        OrderByString: orderBy,
        FilterString: filterlist
      
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getSalesInvoiceById = (Id ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.browse,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        Id:Id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const getTaxList = (Id ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemTax.browse,
    method: 'get',
    params: {
        id:Id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getPriceListDetail = (Id,priceListId ,code , warehouseId ,warehouseDetailId,personId ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListDetailApis.getId,
    method: 'get',
    params: {
        ItemId:Id,
        ItemCode:code , 
        PriceListId:priceListId,
        WarehouseId:warehouseId,
        CustomerPersonId:personId,

      //warehouseDetailId:warehouseDetailId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
    
});

export const postSalesInvoice = (data,SendSMS=false ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.load,
    method: 'post',
    data:data,
    params:{Confirm:false,SendSMS:SendSMS}
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getSalesInvoiceOrGenerate = (SourceData,SourceId,ReferenceId,JobId ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.getOrGenerate,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
        SourceData,
        ReferenceId,
        JobId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


export const postSalesInvoiceWorkFlow = (data ,referenceId ,increased,SendSMS=false ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice?.[increased ? "FromSourceWithIncrease" : "FromSource"],
    method: 'post',
    data:data,
    params:{
        confirm:false,
        referenceId:referenceId,
        sendSMS:SendSMS
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const putSalesInvoice = (data  ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.load,
    method: 'put',
    data:data
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const putSalesInvoiceworkflow = (data ,referenceId ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.workflow,
    method: 'put',
    params:{referenceId},
    data:data
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});






export const CalculateSalesInvoice = (data ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.Calculate,
    headers: { "Accept-Language": "en" },
    method: 'post',
    data:data,
    params:{Confirm:false}
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


export const getInvoiceConstraint = ( ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.constraint,
    method: 'get',

    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getDefaultPersonContact = (Id ) => http({

    url: ENDPOINTS.BaseInformation.PersonContact.default,
    method: 'get',
    params:{
        personId:Id
    }
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getPersonCustomer = (Id ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.load,
    method: 'get',
    params:{
        id:Id
    }
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getSources = (type,pageNumber=1,pageSize=10 ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: type==1? ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.browse:type==2?ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.load: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.load ,
    method: 'get',
params:{
    PageNumber: pageNumber,
    PageSize: pageSize,
}

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const GenerateFromSource = (SourceId, SourceData  ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.generate,
    method: 'get',
    params:{
        SourceData ,
        SourceId
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const optionalDiscountHandler = (data  ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.optionalDiscount,
    method: 'post',
    data:data

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const salesInvoiceViod = (id) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.void,
    method: 'delete',
    params:{
        Id:id
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});