import "./style.scss";


interface Prop {
    img: string,
    handleClickSlider: (e) => void,
    id: string,
    alone?: boolean,
    handleDownloadTrigger:(e)=>void
    fileName:string

}

export const CardBoxAttachment = ({ alone=false ,img, handleClickSlider, id,handleDownloadTrigger, fileName }: Prop) => {
  

    



    return (
        <>
        {alone ?
        <div onDoubleClick={handleDownloadTrigger} className={` attachCard Signactive `}  onClick={(e)=>{e.isPropagationStopped(); handleClickSlider(e)}} >
        <img width={"100%"} height={"100%"} src={img } id={id}  className={"attachImg"} />
  
       {/*  <div className={" attachCardDefault "} >
           {fileName}
        </div> */}
     </div>
     :
     <div onDoubleClick={handleDownloadTrigger} className={` attachCard Signactive `}  onClick={(e)=>{e.isPropagationStopped(); handleClickSlider(e)}} >
     <img width={"100%"} height={"100%"} src={img } id={id}  className={"attachImg"} />

    {/*  <div className={" attachCardDefault "} >
        {fileName}
     </div> */}
  </div>
    
    
    }
        </>
    )
}