
import { Button } from "components/Button/Button";
import { ButtonArrow } from "components/ButtonArrow/ButtonArrow";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Search } from "components/Search/Search";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getGroupPositionList, getPositionList, getSecretariatMembers, setSecretariatMembers } from "./api/api";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";



interface Form{
    Title?:string,
     Method?:string,
    Status?:number,
    SecretariatId?:any,
    showForm:(e) => void,
    modalWidth?:any
}




export const SecretariatMembers: React.FC<Form> = ({showForm,SecretariatId , Method, modalWidth}) => {
    const [mood , setMood] = useState("groups")
    const [Moodassign , setMoodassign] = useState("All")
    const [flag , setFlag] = useState(false)
    const [response , setResponse] = useState<any>([])
    const [assignmentResponse , setAssignmentResponse] = useState<any>([])
    const [selectList , setSelectList] = useState<any>([])
    const [assignedList , setAssignedList] = useState<any>([])
    const [assignedSelectList , setAssignedSelectList] = useState<any>([])
    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)
    const [massageType , setMassageType] = useState<any>("info")
    const [massageType2 , setMassageType2] = useState<any>("info")
    const [loading , setLoading] = useState<any>(false)
    const [loading2 , setLoading2] = useState<any>(false)
    const [positionstr , setPositionstr] = useState<any>()
    const [positionAssignmentstr , setPositionAssignmentstr] = useState<any>()
    const [width, setWidth] = useState<number>(window.innerWidth);


    const breakPoint1 = modalWidth < 50 || width < 750
    const breakPoint2 =  modalWidth < 80 || width < 1100

    window.onresize = () => {
      setWidth(window.innerWidth)
  }

    let Data:any = [], Dataassign:any = [];
    async function getPositionsListAsync(SearchString) {
      setLoading(false)
        try {
        if(mood=="groups"){
          const Groupresponse = await getGroupPositionList(SearchString); 
          let list:any=[]
          for (let x in Groupresponse.Data) {
              list.push({Id:x ,TU_RELATION:2 ,Title: trans(Groupresponse.Data[x])}) 
          }
          setResponse(list)
        }else if(mood=="users"){
         const Positionresponse = await getPositionList(SearchString);
         let list:any=[]
         list = Positionresponse.Data.map(item=>{
            return ({
              Id:item.Id ,
              TU_RELATION : 1 ,
              Title:item.Description,
            })
         })
         setResponse(list)    
        }else{
            const Groupresponse = await getGroupPositionList(SearchString); 
            let list2:any=[]
            for (let x in Groupresponse.Data) {
               let obj:any =new Object
               obj.Id=x
               obj.TU_RELATION=2
               obj.Title=trans(Groupresponse.Data[x])
               list2.push(obj) 
             }
            const Positionresponse = await getPositionList(SearchString); 
            let list:any=[]
            list = Positionresponse.Data.map(item=>{
               return ({
                 Id:item.Id ,
                 TU_RELATION : 1 ,
                 Title:item.Description,
               })
   
            })
            setResponse(list.concat(list2))   
        }
        } catch (error) {
          setMassageType("error")
        }

      setLoading(true)
  }
    async function getPositionsListSearchingAsync(SearchString) {

      const Groupresponse = await getGroupPositionList(SearchString); 
      let list2:any=[]

      for (let x in Groupresponse.Data) {
         let obj:any =new Object
         obj.Id=x
         obj.TU_RELATION=2
         obj.Title= trans(Groupresponse.Data[x]) 
         list2.push(obj) 
       }
      const Positionresponse = await getPositionList(SearchString); 
      let list:any=[]
      list = Positionresponse.Data.map(item=>{
        return ({
          Id:item.Id ,
          TU_RELATION : 1 ,
          Title:item.Description,
        })
  
     })
      setAssignmentResponse(list.concat(list2))   
  }
    async function setSecretariatMembersAsync(data ) {
        const response = await setSecretariatMembers(data  );    
        showForm(false)  
      }
    async function getTaskUserAsync() {
      setLoading2(false)
      try {
      const TaskUserresponse = await getSecretariatMembers(SecretariatId); 
      
      let makeData:any =[] 
      if(TaskUserresponse.Data){
          TaskUserresponse.Data.map(item=>{
            if(item.GroupPositionId){
              makeData.push({Id:item.GroupPositionId,TU_RELATION:2 , Title:item.Description })
            }else{
              makeData.push({Id:item.PositionId,TU_RELATION:1, Title:item.Description })

            }
        })   
        setAssignedList(makeData)
      }
      } catch (error) {
        setMassageType2("error")
      }
      setLoading2(true)
    }


    function changeDataGrid(e) {
        setMood(e)
      }
    function hide() {
      showForm(false)
      }

    function changeDataGridassign(e) {
        setMoodassign(e)
      }
    function backward() {
      setAssignedSelectList([])
        setResetMultiSelect(!resetMultiSelect)
        setAssignedList(assignedList.concat(selectList) )
        setFlag(!flag)
        setSelectList([])
      
      }
    function forward() {
      setResetMultiSelect(!resetMultiSelect)
        let list:any =[]
        assignedList.forEach((item1,index1) => {
          let flag = true
          assignedSelectList.forEach((item2 , index2 ) => {
              if(item2.Id ==item1.Id ){
                flag = false
              }
          });
          if(flag){
            list.push(item1)
          }
        });
        setAssignedList(list)
      
      }
      const selectListCallBack =(list)=>{          
        setSelectList(list[1])
      }
      const assignedselectListCallBack =(list)=>{
        setAssignedSelectList(list[1])
      }

      const referenceFormSubmit =(e)=>{
        e.preventDefault()
        if(assignedList.length==0){
          Toast("msg_assigneduserscannotempty" , "error")
        }else{
           e.preventDefault();
           let Data:any = []
           assignedList.map(item=>{
           
            
           let obj =  {

                "OwnerId": SecretariatId,
                [item.TU_RELATION == 1 ? "PositionId" : "GroupPositionId"]  : item.Id
             }
             if(item.Title.length > 0){
               Data.push(obj) 
             }
            })
            setSecretariatMembersAsync(Data.length>0 ? Data : [] )
        }

      }
      const searching =(e)=>{
        setPositionstr(e == "" ? null:e)
        getPositionsListAsync(e == "" ? null:e)

      }
      const assignmentsearching =(e)=>{
        setPositionAssignmentstr(e == "" ? null:e)
        getPositionsListSearchingAsync(e == "" ? null:e)

      }

    useEffect(() => {
        getPositionsListAsync(positionstr);     
        getPositionsListSearchingAsync(positionAssignmentstr)
      }, [mood]);

    useEffect(() => {
        getTaskUserAsync();
    }, []);


      if(response && assignedList ){
        let assignmentdata:any =[]

        
        assignedList.forEach(elm => {
          if(Moodassign=="groups"){
            if(elm.TU_RELATION==2)assignmentdata.push(elm)
          }else if(Moodassign=="users"){
            if(elm.TU_RELATION==1)assignmentdata.push(elm)
          }else{
            assignmentdata.push(elm)
          }
        });
        
        assignmentResponse.forEach(item1 => {
          let flag =false
          assignmentdata.forEach(item2 => {
            if(item1.Id == item2.Id){
              flag = true
            }
          });
          if(flag){
            Dataassign.push(item1)
          }
          
        });
        
         response.map(item1=>{
          let flag = true
          assignedList.forEach(item2 => {
            if(item1.Id ==item2.Id){
              flag=false
            }
          });
          if(flag){
            Data.push(item1) 
          }
     })
    }
    
    

    return ( 
          <form onSubmit={referenceFormSubmit}>
                   <div style={{border:"none"}} className="card"> 
                    <div className="body">
                       
                       <div style={{width:"100%" ,paddingBottom: "39px"}}  className="">
                       <FormWithTitle title="AvailablePositions">
                        <div className="h-100">
                          <div className="d-flex justify-content-between align-items-center mb-1  ">
                            
                            <Search onChange={searching} />
                           </div>
                          <div className={`${breakPoint1 ? 'flex-column justify-content-end' : 'flex-row justify-content-between align-items-center'} d-flex mt-2 mb-2`}>
                            <CheckableButton onclick={changeDataGrid} defaultChecked={mood=="groups"?true : false} checked={mood=="groups"?true : false} defaultValue={"groups"} labelText={trans("groupPositions")} inputName={"AvailableUsersList"} type={"radio"}/>
                            <CheckableButton width={"80%"}  onclick={changeDataGrid} checked={mood== "users"?true : false} defaultValue={"users"} labelText={trans("Positions")} inputName={"AvailableUsersList"} type={"radio"}/>
                          </div>   
                          <div className="tableBoxInAccesses">
                            {loading ?
                                 <>
                                    {response ? <DataGrid
                                          theadData={["Id" ,"Title"]}
                                          multiSelect={true}
                                          parentName={"referencePosition"}
                                          tbodyData={Data}
                                          selectList={selectListCallBack}
                                          minCellWidth={80}
                                          resetMultiSelect={resetMultiSelect}
                                          scrollHeight={"230px"}
                                          />
                                          : <Message message={"NoDataForPreview"} type={massageType} />
                                  }                               
                                 </>
                                 : <Loading/>
                            }

                          </div>
                        </div>
                        </FormWithTitle>
                       </div>
                                
                        <div className="col-lg-1 col-md-1 d-flex  flex-lg-column flex-md-column justify-content-center align-items-center">
                          <ButtonArrow type="backward" onClick={backward}/>
                          <ButtonArrow type="forward" onClick={()=>{forward()}}/>
                          
                        </div>

                        <div  style={{width:"100%" ,paddingBottom: "39px"}} className="">
                          <FormWithTitle title="AssignedPositions">
                           <div className="h-100">
                        <div className="d-flex justify-content-between align-items-center mb-1 ">
                           
                          <Search onChange={assignmentsearching} />
                        </div>    
                        <div className={`${breakPoint1 ? 'flex-column justify-content-end' : 'flex-row justify-content-between align-items-center'} d-flex mt-2 mb-2`}>
                          <CheckableButton  onclick={changeDataGridassign}  checked={Moodassign=="groups"?true : false} defaultValue={"groups"} labelText={trans("groupPositions")} inputName={"AssignedUsersList"} type={"radio"}/>
                          <CheckableButton width={"80%"} onclick={changeDataGridassign} checked={Moodassign== "users"?true : false} defaultValue={"users"} labelText={trans("Positions")} inputName={"AssignedUsersList"} type={"radio"}/>
                          <CheckableButton width={"34%"} onclick={changeDataGridassign} defaultChecked={Moodassign=="All"?true : false}  checked={Moodassign=="All"?true : false} defaultValue={"All"}  labelText={trans("All")}  inputName={"AssignedUsersList"} type={"radio"}/>

                        </div>    
                          <div   className="tableBoxInAccesses">
                              {loading2 ?
                                     <>
                                        {Dataassign ?                         
                                            <DataGrid
                                                  theadData={["Id" ,"Title"]}
                                                  multiSelect={true}
                                                  parentName={"referencePositionAssigned"}
                                                  tbodyData={Dataassign}
                                                  selectList={assignedselectListCallBack}
                                                  minCellWidth={80}
                                                  resetMultiSelect={resetMultiSelect}
                                                  scrollHeight={"230px"}
                                              />  
                                              : <Message message={"NoDataForPreview"} type={massageType2} />
                                      }                               
                                     </>
                                     : <Loading/>
                                }
                          </div>
                           </div> 
                           </FormWithTitle>
 
                        </div>  
                    
                    </div>
                </div>
                <div className="col-lg-12 mt-3 btns_form">
                <Button value="Save" btnType="submit" />
                <Button
                  btnType="cancel"
                  value="Cancel"
                  onClick={()=>Toast(trans("modalclosemessage") , "warning" , "alert" , hide )}
                />
              </div>
          </form>
    );
}