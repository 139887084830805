import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useRef, useState } from "react";
import { CartableType } from "types/enum";
import { getGridSetting, getPageSize } from "utils/helpers";
import { getCartable } from "./api";



interface propsSelectJobList {
    type?: any; // => type is CartableType
    curentTab?: any; 
    jobList?: any; 
    addToJobList?: (ans: any) => void;

}
export const SelectJobList = ({ type ,curentTab ,jobList,addToJobList}: propsSelectJobList) => {
    let keys, cartable = [];
    let Total_records, pages;
    let [response, setResponse] = useState<Response | any>(null);
    const [referenceId, setReferenceId] = useState<string | any>(null);
    const [rowObject, setRowObject] = useState<string | any>(null);
    const [jobId, setJobId] = useState<string | any>(null);
    const referenceIdRef = useRef(referenceId);
    let [selectedProcessFilterId, setSelectedProcessFilterId] = useState<string | any>(null);

    const [forceUpdate, setForceUpdate] = useState(false); // integer state
    const [reload, setReload] = useState(true); // integer state
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [orderFormat, setOrderFormat] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [filterlist, setFilterlist] = useState<any>("");
    const [GridSetting, setGridSetting] = useState<any>(false);
    const [typeOfDataGrid, setTypeOfDataGrid] = useState<any>(type);

    const layoutContext = useContext(LayoutContext);
    const [search, setsearch] = useState(null);




    const getWithOrder = (order: string[]) => {
        setOrderFormat(order)
    }

    const handlechangePageSize = (e: any) => {
        setPageSize(e.currentTarget.value);
        setPageNum(1);
        getPageSize("SelectJobList" + type + `${selectedProcessFilterId}`, e.currentTarget.value)
        getCartableAsync()
    };

    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else { setPageNum(value); getCartableAsync(value); }
        } else {

        }
    };
    const HandleNextPage = () => {
        if (pageNum < pages) {
            setPageNum(pageNum + 1);
            getCartableAsync(pageNum + 1);
        }
    };
    const HandlePrevPage = () => {
        if (pageNum > 1) {
            setPageNum((pageNum) => { return +pageNum - 1 });
            getCartableAsync(pageNum - 1);
        }
    };
    /* pagination area */




    const selectedRowCallback = (obj, index) => {
        referenceIdRef.current = obj.Id
        setReferenceId(obj?.Id);
        setRowObject(obj);
        setJobId(obj?.JobId)
    };

   
    useEffect(() => {
        let settings = getGridSetting("SelectJobList" + type + `${selectedProcessFilterId}`)
        getWithOrder(settings[1])
        referenceIdRef.current = null
        setReferenceId(null);
        setRowObject(null);
        setJobId(null)
        setFilterlist(settings[0])
        setGridSetting(true)
        setTypeOfDataGrid(type)
    }, [type ,selectedProcessFilterId]);
    async function getCartableAsync(pagNum = pageNum) {
        setLoading(false)
    let sizePage = getPageSize("SelectJobList" + type + `${selectedProcessFilterId}`)
        setPageSize(sizePage)

        try {
            if(curentTab == type){

                const res = await getCartable(pagNum, sizePage, type, selectedProcessFilterId, layoutContext.currentUserPosition.PositionId, orderFormat?.join(","), search, filterlist);
                setResponse(res?.Data);
                setMessageType("info")
            }
            
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }

    useEffect(() => {

        setResponse({ Data: [] })
       
            if(reload ){
                getCartableAsync();
                setTypeOfDataGrid(type)
                setReload(false)
            }
        
    }, [reload]);


    useEffect(() => {
        setResponse({ Data: [] })
        referenceIdRef.current = null
        setReferenceId(null);
        setRowObject(null);
        setJobId(null)
       getCartableAsync();  

    }, [ curentTab ,  orderFormat , search, filterlist , forceUpdate, selectedProcessFilterId]);

    useEffect(() => {
       // setReferenceId(null)
       // setRowObject(null)
        referenceIdRef.current = null
    }, [forceUpdate, pageNum, type, selectedProcessFilterId]);


/* 



    /* #region manage server data */
    //with pagination 
    if (response &&
        response.Data && response.Data[0]) {
        keys = Object.keys(response.Data[0]);
        cartable = response.Data;
        Total_records = response.TotalRecords;
    } else if (response && response?.Data?.length == 0) {
        keys = [];
        cartable = [];
    }
    pages = Total_records > pageSize ? Math.ceil(Total_records / pageSize) : 1;
    /* #endregion */

    // modal width from modal form


  

    return (
        <>
            <div style={{overflowY: "auto", 
          height: "50vh",
           scrollbarWidth: "thin"}} className="col-lg-12 mt-0">
                <div className="list-card card">
                    <div className="list-card__body">
                        {loading ?
                            (cartable.length > 0 && keys ?
                                <DataGrid
                                    // View={type == CartableType.Current}
                                    handlefiltering={(str) => {
                                        setFilterlist(str);
                                    }}
                                    multiSelect={true}
                                    filterStr={filterlist}
                                    orderFormat={getWithOrder}
                                    orderFormatList={orderFormat}
                                    startIndex={(typeOfDataGrid == CartableType.Current|| typeOfDataGrid == CartableType.Ongoing) ?10 :9}
                                    selectItem={(e) => {  }}
                                    parentName={"SelectJobList" + type + `${selectedProcessFilterId}`}
                                    theadData={keys}
                                    tbodyData={cartable}
                                    minCellWidth={80}
                                    selectList={(e)=>{addToJobList && addToJobList(e[1])}}
                                    selectedRowProp={jobList}
                                    multiSelecttype={3}
                                    
                                    selectedRowObjectCallback={selectedRowCallback}
                                    rowSelected={rowObject}
                                    totalRecords={Total_records}
                                    pageNum={pageNum}
                                    pageSize={pageSize}
                                    HandleNextPage={HandleNextPage}
                                    HandlePrevPage={HandlePrevPage}
                                    handlechangePageSize={handlechangePageSize}
                                    first={() => { getCartableAsync(pages); setPageNum(pages) }}
                                    end={() => { getCartableAsync(1); setPageNum(1) }}
                                    handleGoPage={handleGoPage}
                                />
                                : <Message message={"NoDataForPreview"} type={messageType} />)
                            : <Loading />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}