import { ItemCard } from "./ItemCard";
//import { FilterBox } from "../FilterBox/FilterBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { FilterBox } from "pages/ClientPortal/ECommerceDEMO/components/FilterBox/FilterBox";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ItemGroupLabelSlider } from "../ItemGroupMenu/ItemGroupLabelSlider";
import { count } from "console";
import { trans } from "utils/helpers";

interface ItemCardsProps {
  data?: any;
  cardStyle?: any;
  dataGroup?: any;
  filterId?: any;
  fetchData?: any;
  stopFetching?: boolean;
  itemGroupId?: any;
  loading?: any;
}

export const ItemCards = ({
  data,
  itemGroupId,
  loading,
  stopFetching,
  fetchData,
  dataGroup,
  filterId,
  cardStyle,
}: ItemCardsProps) => {
  const { sidebarIsOpen } = useContext(LayoutContext);

  const [page, setPage] = useState<any>(1); // For pagination tracking
  const [goTop, setGoTop] = useState(false);
  const count = useRef(400);
  const boxRef = useRef<any>(null);

  const scrollDown = () => {
    //setPage((prevPage) => prevPage + 1);
    boxRef.current?.scrollTo({
      top: count.current, //when go to bottom and fetch data
      behavior: "smooth",
      block: "end",
    });
    setTimeout(() => {
      const isAtBottom =
        boxRef.current?.scrollTop + boxRef.current?.clientHeight >=
        boxRef.current?.scrollHeight;
    }, 500);
    Math.floor(boxRef.current?.scrollHeight - boxRef.current?.scrollTop) ===
      Math.floor(boxRef.current?.clientHeight) &&
      stopFetching &&
      setGoTop(true);
    count.current += 400; //increse count to go to bottom every time
  };
  const scrollUp = () => {
    boxRef.current.scrollTo({ top: 0, behavior: "smooth", block: "start" });
    setGoTop(false);
    count.current = 400; //reset count when arrive at bottom of page and want to go to top
  };
  const handleScroll = useCallback((event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
      const tolerance = 2; 
    if(  Math.abs(scrollHeight - scrollTop - clientHeight) <= tolerance){
      if (!stopFetching) {
        setPage((prevPage) => prevPage + 1);
      } else {
        setGoTop(true);
      }
    }
  }, [stopFetching]);

  useEffect(() => {
    !stopFetching && fetchData && fetchData(page);
  }, [page]);

  useEffect(() => {
    // stopFetching && scrollDown();
  }, [stopFetching]);
  return (
    <>
      <div
        className="row w-100 item-cards"
        ref={boxRef}
        onScroll={handleScroll}
        // style={{ maxHeight: "75vh" ,height: "75vh" }}
      >
        <div className="col-lg-12 col-12">
          <div
            className="row h-10 me-0  w-100 p-0"
            style={{ backgroundColor: "#f4f6f9" }}
          >
            <ItemGroupLabelSlider children={dataGroup} />
          </div>
          <div
            id="scrollContainer"
            className= {`hij row p-3 pt-1 mb-3 gy-1 
              ${cardStyle?`row-cols-sm-12 row-cols-md-4 ${sidebarIsOpen ? "row-cols-lg-4" : "row-cols-lg-auto"}`:`col-12`} `
          }
            

            style={{ overflowY: "auto", maxHeight: "80vh", flexGrow: "1" }}
          >
          { !cardStyle && <div className="row">
              <div className="col pe-2">{trans('name')}</div>
              <div className="col pe-2 ">{trans("UnitPrice")}</div>
              <div className="col pe-2"> {trans("PriceAfterDiscount")}</div>
              <div className="col pe-2">{trans("PackingPrice")}</div>
              <div className="col pe-2"></div>

            </div>}
            {data &&
              data.map((item, index) => (
                <>
                  <ItemCard
                    key={index + "ItemCards"}
                    ItemData={item}
                    cardStyle={cardStyle}
                    itemGroupId={itemGroupId}
                  />
                </>
              ))}

            {boxRef.current?.scrollHeight > boxRef.current?.clientHeight &&
              !goTop && (
                <button
                  disabled={loading || stopFetching ? false : true}
                  onClick={() => {
                    scrollDown();
                  }}
                  className="position-absolute goTop rounded-circle    text-white d-flex justify-content-center align-items-center border-0 "
                  style={{
                    width: "40px",
                    height: "40px",
                    left: "40px",
                    zIndex: 10,
                    cursor: "pointer",
                    bottom: "50px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={["fas", "chevron-down"]}
                    className="text-dark"
                  />
                </button>
              )}

            {boxRef.current?.scrollHeight > boxRef.current?.clientHeight &&
              goTop && (
                <button
                  onClick={() => {
                    scrollUp();
                  }}
                  className="position-absolute goTop rounded-circle border-0    text-white d-flex justify-content-center align-items-center "
                  style={{
                    width: "40px",
                    height: "40px",
                    left: "40px",
                    zIndex: 10,
                    cursor: "pointer",
                    bottom: "50px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={["fas", "chevron-up"]}
                    className="text-dark"
                  />
                </button>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
