import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useRef, useState } from "react";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { UnitDictionary } from "pages/InventoryAndSales/Inventory/BaseDefinitionAndSetting/Unit/components/UnitDictionary";
import { Loading } from "components/Loading/loading";


interface PackingFormPropsType {
    id?: any,
    data?:any;
    mode?: any,
    inTab?: any,
    formRef?: any,
    submitPacking?: (data) => void;

}

export const PackingForm = ({
    id,
    data,
    mode,
    inTab,
    formRef,
    submitPacking
 
  
}: PackingFormPropsType) => {


    const [resetForm, setResetForm] = useState(false);
    const [hasPacking, setHasPacking] = useState(data?.HasPacking);
    const [hasSerial, setHasSerial] = useState(data?.HasSerial);


   
    async function formSubmit(event) {
        event.preventDefault();       
        let data = {
            NetWeight:  event.target?.NetWeight?.value==""?null:event.target.NetWeight.value ,
           ...(hasPacking&& {PackingUnitId: event.target?.PackingUnitId?.value==""?null:event.target.PackingUnitId.value ,
            PackingQty: event.target?.PackingQty?.value==""?null:event.target.PackingQty.value ,
            PackingWeight: event.target?.PackingWeight?.value==""?null:event.target.PackingWeight.value ,
            PackingVolume: event.target?.PackingVolume?.value==""?null:event.target.PackingVolume.value} ),
            HasPacking: event.target?.HasPacking?.checked,
            Dimensions:`${event.target?.length?.value}*${event.target?.width?.value}*${event.target?.height?.value}  ` , 

            HasProductTracking: event.target?.HasProductTracking?.checked,
            HasExpireDate: event.target?.HasExpireDate?.checked,
            HasSerial: event.target?.HasSerial?.checked,
            ...(hasSerial&&{
                IsSerialObligatory: event.target?.IsSerialObligatory?.checked,
                HasSerialQty: event.target?.HasSerialQty?.checked,
            })
        }

        submitPacking&&  submitPacking(data)
    }
    


    useEffect(() => {
        if (resetForm) {
          
            setResetForm(false);
        }

    }, [resetForm]);

    return (

        <form className="Packing-form py-2" ref={formRef} name="Packing" onSubmit={formSubmit}>
            <div className="card">
                { (mode != "create" && data)||(mode == "create") ?
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <Textbox
                                textboxType="number"
                                textboxName="NetWeight"
                                resetForm={resetForm}
                                defaultValue={data?.NetWeight ? data.NetWeight : null}
                                labelText="NetWeight"
                                mode={mode}
                                />
                        </div>

                        <div className="col">
                            <Textbox
                                textboxType="number"
                                textboxName="length"
                                resetForm={resetForm}
                                defaultValue={data?.Dimensions ? data?.Dimensions?.split("*")[0] : null}
                                labelText="length"
                                mode={mode}
                                />
                        </div>
                        <div className="col">
                            <Textbox
                                textboxType="number"
                                textboxName="width"
                                resetForm={resetForm}
                                defaultValue={data?.Dimensions ? data?.Dimensions?.split("*")[1] : null}
                                labelText="width"
                                mode={mode}
                                />
                        </div>
                        <div className="col">
                            <Textbox
                                textboxType="number"
                                textboxName="height"
                                resetForm={resetForm}
                                defaultValue={data?.Dimensions ? data?.Dimensions?.split("*")[2] : null}
                                labelText="height"
                                mode={mode}
                                />
                        </div>
                    </div>
                    <div className="row">
                    <div className="col mt-3">
                            
                            <CheckableButton
                               defaultChecked={data?.HasPacking }
                               defaultValue={data?.HasPacking }
                               labelText={"HasPacking"}
                               onchange={(e)=>{setHasPacking(e)}}
                               inputName={"HasPacking"}
                               type="checkbox"
                               mode={mode}
                            />   
                        </div>
                        {hasPacking&&
                        <>
                        <div className="col">
                        <UnitDictionary
                            value={data?.PackingUnitId ? data.PackingUnitId : null}
                            label="PackingUnit"
                            name="PackingUnitId"
                            mode={mode}
                            />
                        </div>

                        <div className="col">
                            <Textbox
                                textboxType="number"
                                textboxName="PackingWeight"
                                resetForm={resetForm}
                                defaultValue={data?.PackingWeight ? data.PackingWeight : null}
                                labelText="PackingWeight"
                                mode={mode}
                                />
                        </div>
                        <div className="col">
                            <Textbox
                                textboxType="number"
                                textboxName="PackingVolume"
                                resetForm={resetForm}
                                defaultValue={data?.PackingVolume ? data.PackingVolume : null}
                                labelText="PackingVolume"
                                mode={mode}
                                />
                        </div>
                        <div className="col">
                            <Textbox
                                textboxType="number"
                                textboxName="PackingQty"
                                resetForm={resetForm}
                                defaultValue={data?.PackingQty ? data.PackingQty : null}
                                labelText="PackingQty"
                                mode={mode}
                                />
                        </div>
                        </>
                        }
                    </div>
                    <div className="row-reverce">

                    <div className="col mt-1">
                            <CheckableButton
                               defaultChecked={data?.HasProductTracking ? data?.HasProductTracking : false}
                               defaultValue={data?.HasProductTracking ? data?.HasProductTracking : false}
                               labelText={"HasProductTracking"}
                               inputName={"HasProductTracking"}
                               type="checkbox"
                               mode={mode}
                            />   
                        </div>
                        <div className="col mt-2 ">
                            <CheckableButton
                               defaultChecked={data?.HasExpireDate ? data?.HasExpireDate : false}
                               defaultValue={data?.HasExpireDate ? data?.HasExpireDate : false}
                               labelText={"HasExpireDate"}
                               inputName={"HasExpireDate"}
                               type="checkbox"
                               mode={mode}
                            />   
                        </div>
                        <div className="col mt-2">
                            <CheckableButton
                               defaultChecked={data?.HasSerial ? data?.HasSerial : false}
                               defaultValue={data?.HasSerial ? data?.HasSerial : false}
                               onchange={(e)=>{setHasSerial(e)}}
                               labelText={"HasSerial"}
                               inputName={"HasSerial"}
                               type="checkbox"
                               mode={mode}
                            />   
                        </div>
                        {hasSerial&&
                        <>
                        
                        <div className="col mt-2 me-5">
                            <CheckableButton
                               defaultChecked={data?.IsSerialObligatory ? data?.IsSerialObligatory : false}
                               defaultValue={data?.IsSerialObligatory ? data?.IsSerialObligatory : false}
                               labelText={"IsSerialObligatory"}
                               inputName={"IsSerialObligatory"}
                               type="checkbox"
                               mode={mode}
                               />   
                        </div>
                        <div className="col mt-2 me-5">
                            <CheckableButton
                               defaultChecked={data?.HasSerialQty ? data?.HasSerialQty : false}
                               defaultValue={data?.HasSerialQty ? data?.HasSerialQty : false}
                               labelText={"HasSerialQty"}
                               inputName={"HasSerialQty"}
                               type="checkbox"
                               mode={mode}
                               />   
                        </div>
                        </>
                    }
                    </div>

                </div>
                :
                <Loading/>
                }
            </div>
            <button  type="submit"  className="d-none" id="hidebutton" name="hidebutton" >
 
            </button>
        </form>
    );
}