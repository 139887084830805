import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { useEffect, useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";

export const FormsNotification = () => {
  const [count, setCount] = useState<any>(false);
  const [flag, setFlag] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<any>(false);
  const deleteForm = (item) => {
    if(localStorage.getItem("formsInChang")){
      let forms = JSON.parse(localStorage.getItem("formsInChang")+"") 
      if(forms){
        delete forms[item]
        setCount(Object.keys(forms))
      }else{
        setCount([])
      }
      localStorage.setItem("formsInChang" ,forms==null ? "" : JSON.stringify(forms) )

    }
  }

  const enterNavigat = (event)=>{
    if(event.key == "Enter"){
      setShowForm(true)
    }
  }
  
  useEffect(()=>{

    if(flag == true){
      document.addEventListener("keydown",enterNavigat)
    }
    return () =>{document.removeEventListener("keydown",enterNavigat)}
  },[flag])

  useEffect(() => {
    if(localStorage.getItem("formsInChang")!=null){
        let formstring:any = {}
        if(localStorage.getItem("formsInChang")+""){
            formstring = localStorage.getItem("formsInChang")
        }
        let forms = JSON.parse(formstring) 
        if(forms){
          let formsTitles = Object.keys(forms)
          setCount(formsTitles)
        }
      }
  }, []);
  return (
    <>
    {showForm&&
        <ModalForm checkClose={false} mode={"edit"} modalProfileKey={"UnsavedForms"} title={trans("UnsavedForms") } onHide={() => { setShowForm(false) }} show={showForm}>
            <div className="card">
                <div className="card-body">
                    {count.length >0 ?count.map((item ,index)=>(
                        <div style={{width:"15%"}} className="group-person__selected-option form-control-custom " key={item + index}>
                            <div className="group-person__defult-selected-option" data-key={item}>    
                            </div>
                            <div className="me-3">
                              {trans(item)}
                            </div>
                            <div className="group-person__delete-selected-option" data-key={item} onClick={()=>{deleteForm(item)}}>
                                <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                            </div>
                        </div>
                      ))
                      :
                      <Message message={"NoDataForPreview"} type={"info"} />
                    }
                </div>
            </div>
        </ModalForm>
    }{
      (count.length >0)&&
      <li className="header-itemsIcons">

      <a href="#" onFocus={()=>setFlag(true)} onBlur={()=>setFlag(false)}>
        <div className="position-relative" onClick={(e)=>{setShowForm(true)}} >
          <FontAwesomeIcon icon={[ICON_TYPE, "retweet"]}  />
          {(count.length >0) ? <span className="badgeCartable">{count.length > 0 ? (count.length >99 ?"99+":count.length) : " "}</span>   :<></> }
        </div>
      </a>
      </li>

      }
    </>
  );
};
