import { useContext } from "react";
import "./style.scss";
import { trans } from "utils/helpers";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
interface FooterContent {
    key: string,
    value: string
}
interface FooterbarPropsType {
    footerContents: FooterContent[],
    hasBorder?: boolean
}
export const Footerbar = ({
    footerContents,
    hasBorder
}: FooterbarPropsType) => {

    return (
        <div className="footer-bar p-2">
            <div className={`card p-2 ${!hasBorder && "remove-border"}`}>
                <div className="row justify-content-end">
                    {
                        Object.keys(footerContents).map((key, i) => (
                            key.toLowerCase().includes("description") ?
                                <div key={i+"footer"} className="col-12 footer-bar__content">
                                    <span>{trans(key)}: </span>
                                    <span className="d-inline-block mb-1">{footerContents[key]}</span>
                                </div>
                                :
                                <div key={i+"footer"} className="col-12 col-md-4 footer-bar__content">
                                    <span>{trans(key)}: </span>
                                    <span className="d-inline-block mb-1">{footerContents[key]}</span>
                                </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}