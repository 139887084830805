import { Button } from "components/Button/Button";
import DatePicker from "components/DatePicker/DatePicker";
import { RangeSlider } from "components/RangeSlider/RangeSlider";
import { Textbox } from "components/Textbox/Textbox";
import { Time } from "components/Time/Time";
import { Toast } from "components/ToastShow/ToastShow";
import { Action } from "pages/OfficeAutomation/Cartable/types";
import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { getAction, sendAction } from "../../api/api";
import { Loading } from "components/Loading/loading";
interface ActionFormPropsType {
    id?: any,
    mode?: any,
    taskId: any,
    onHide: () => void
    reset: (ans: boolean) => void
}

export const ActionForm = ({
    id,
    mode,
    taskId,
    onHide,
    reset,
}: ActionFormPropsType) => {

    let data, method;
    const [action, setAction] = useState<Action | any>(undefined);
    const [fromDate, setFromDate] = useState<any>();
    const [untilDate, setUntilDate] = useState<any>();
    const [actualTimeAlert, setActualTimeAlert] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const formRef = useRef<any>("")
    const buttonRef = useRef()
    const [loading , setLoading] = useState(false)  
    const [leaveDaysNo, setLeaveDaysNo] = useState<any>();
    const [leaveHours, setleaveHours] = useState<any>(null);
    const [userChanges, setUserChanges] = useState({});
    const [flag,setFlag] = useState(false);



    //const buttonRef = createRef<any>()

    const validationDates = () =>{
        if(fromDate && untilDate && Date.parse(fromDate) > Date.parse(untilDate)){
            setFlag(true)
        }else{
            setFlag(false)
        }
    }

    async function formSubmit(event) {
        event.preventDefault();
        if(flag){
            Toast(trans("InvalidEndDate"),"error");
        }else{
        if (actualTimeAlert) { // هشدار به کاربر درباره بررسی مجدد فیلد زمان واقعی
            setActualTimeAlert(false);
        } else {
            if (id && mode !== "create") {
                data = {
                    Id: id,
                    PersonalTaskId: taskId,
                    Description: event.target.description.value,
                    StartTime: fromDate,
                    EndTime: untilDate,
                    ActualTime: +event.target.ActualTimeInput[0].value,
                    Progress: Number(event.target.progressbar.value),
                };
                method = "put";
            } else {
                data = {
                    PersonalTaskId: taskId,
                    Description: event.target.description.value,
                    StartTime: fromDate,
                    EndTime: untilDate,
                    ActualTime: +event.target.ActualTimeInput[0].value,
                    Progress: Number(event.target.progressbar.value),
                };
                method = "post";
            }        
            try {
                const res = await sendAction(data, method)
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                setResetForm(true);
                reset(true);
       
                onHide()

            } catch (error) {

            }
        }
    }
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                onHide()
            });
        }else{
            onHide()
        };


    }
    
    async function getDataAsync() {
        setLoading(false)
        try {
           const res = await getAction(id);   
           setAction(res.Data)
        } catch (error) {
            setAction(null); 
        }
        setLoading(true)

    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        if (id && mode !== "create"){
            getDataAsync()
        }else{
            setAction(null)
            setLoading(true)
            
        }
    }, [id , mode]);

    useEffect(() => {
        if ( mode === "create"){
            setResetForm(true);
        }
    }, [ mode]);

    useEffect(() => {
        if (resetForm) {
          
            setResetForm(false);
        }

    }, [resetForm]);



 
 useEffect(() => {
    if(untilDate && fromDate){
/*             if(new Date(fromDate).getDate()-new Date(untilDate).getDate() > 0){
            setLeaveDaysNo()
        }else{ */

            //setLeaveDaysNo(Math.abs(new Date(fromDate).getDate()-new Date(untilDate).getDate())+1)
            try {
                setleaveHours( Math.floor( Math.abs(new Date(fromDate).getTime()-new Date(untilDate).getTime())/60000) ==0 ? 1 :Math.floor( Math.abs(new Date(fromDate).getTime()-new Date(untilDate).getTime())/60000) )
            } catch (error) {
                setleaveHours( null)
                
            }
        /* } */
    }
}, [untilDate ,fromDate]);

useEffect(()=>{
    validationDates()
  },[untilDate,fromDate])


    return (

        <form  className="action-form py-2" ref={formRef} onSubmit={formSubmit}>
            {loading ? 
            <div className="card">
                <div className="card-body">
                    <div className="container">
                        <div className="row mb-1">
                            <div className="col-12">
                                <Textbox
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                    textboxType="textarea"
                                    textboxName="description"
                                    defaultValue={action?.Description}
                                    resetForm={resetForm}
                                    labelText="description"
                                    required={true}
                                    mode={mode}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-12 col-lg-4">
                                <DatePicker
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                    withTime={true}
                                    required={true}
                                    mode={mode}
                                    onOpenPickNewDate={true}
                                    resetForm={resetForm}
                                    lable="fromDate"
                                    setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                                    val={action !=undefined ?(action==null ? null : action?.StartTime ): null } //resetForm ? new Date().toISOString() : 
                                    errorFlag={flag}
                                />
                            </div>
                            <div className="col-12 col-lg-4 mt-2 mt-lg-0">
                                <DatePicker
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                    withTime={true}
                                    required={true}
                                    mode={mode}
                                    onOpenPickNewDate={true}
                                    resetForm={resetForm}
                                    lable="untilDate"
                                    setIsoTime={(isoTime: string) => { setUntilDate(isoTime) }}
                                    val={action !=undefined ?  (action==null ? null : action?.EndTime ): null } //resetForm ? new Date().toISOString() : 
                                    errorFlag={flag}
                                />
                            </div>
                            <div className="col-12 col-lg-4 mt-2 mt-lg-0">
                                <Time
                                    required={true}
                                    mode={mode}
                                    name="ActualTimeInput"
                                    IdString="ActualTimeInput"
                                    resetForm={resetForm}
                                    defaultValue={  leaveHours ?leaveHours:  action?.ActualTime}
                                    label="ActualTime"
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-12 mb-2">
                                <RangeSlider Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} mode={mode} label="Progress" defaultValue={action?.Progress} name="progressbar" resetForm={resetForm} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            {mode !== "view" ?
                                <>
                                    <Button ref={buttonRef}  value="Save" btnType="submit" />
                                    <Button

                                        btnType="cancel"
                                        value="Cancel"
                                        onClick={() => { cancel() }}
                                    />
                                </>
                                :
                                 <Button
                                    btnType="primary"
                                    value="Close"
                                    onClick={onHide}
                                  />
                            }
                        </div>
                    </div>
                </div>
            </div>     
                :<Loading/>
            }
        </form>
    );
}