import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import React, { useContext, useEffect, useState } from "react";
import { OrganizationType } from "types/enum";
import { getSalesInfo, getWidgetList } from "./api/api";
import { WidgetBoard } from "./components/Board/WidgetBoard";
import { WidgetCalendar } from "./components/Calendar/WidgetCalendar";
import { WidgetClock } from "./components/Clock/WidgetClock";
import { WidgetPositions } from "./components/Positions/WidgetPositions";
import { WidgetPersonalTask } from "./components/PersonalTask/WidgetPersonalTask";
import { WidgetQuote } from "./components/Quote/WidgetQuote";
import { MyOwnRequests } from "./components/MyOwnRequests/MyOwnRequests";
import "./style.scss";
import { WidgetSales } from "./components/SaleWidget/SaleWidget";

const widgets: { element: React.ReactNode; id: string }[] = [
  { element: <WidgetClock id="DayInfo" />, id: "DayInfo" },
  { element: <WidgetQuote id="DayMessage" />, id: "DayMessage" },
  { element: <WidgetPositions id="cartable" />, id: "cartable" },
  { element: <WidgetCalendar id="WeekEvents" />, id: "WeekEvents" },
  { element: <WidgetPersonalTask id="PersonalTasks" />, id: "PersonalTasks" },
  { element: <MyOwnRequests id="MyOwnRequests" />, id: "MyOwnRequests" },
];

export const DashboardPage = () => {
  const layoutContext = useContext(LayoutContext);

  const [widgetList, setWidgetList] = useState<any>(null);
  const [Board, setBoard] = useState<any>(false);

  async function getWidgetListAsync() {
    try {
      let obj = {};
      const res = await getWidgetList(
        layoutContext?.currentUserPosition?.OrganizationType ==
          OrganizationType.Customers
      );
      let list: any = [];
      let bordFlag = false;
      res.Data.forEach((id) => {
        if (id != "Board") {
          obj[id] = true;
          const item = widgets.find((item) => item.id === id);
          list.push(item);
        } else {
          setBoard(true);
          obj[id] = true;
          bordFlag = true;
        }
      });
      if (!bordFlag) {
        setBoard(false);
        obj["Board"] = false;
      }
      setWidgetList(list);
      localStorage.setItem("widgetList", JSON.stringify(obj));

      localStorage.removeItem("widgets");
      return res.Data;
    } catch (error) {}
  }



  useEffect(() => {
    if (
      localStorage.getItem("widgetList") == null &&
      layoutContext?.currentUserPosition?.OrganizationType != undefined
    ) {
      getWidgetListAsync();
    } else if (
      layoutContext?.currentUserPosition?.OrganizationType != undefined
    ) {
      let list: any = [];
      let localwidgetList: any = localStorage.getItem("widgetList");
      Object.keys(JSON.parse(localwidgetList)).forEach((id) => {
        if (id !== "Board" && JSON.parse(localwidgetList)[id]) {
          const item = widgets.find((item) => item.id === id);
          list.push(item);
        }
      });

      const item: any = Object.keys(JSON.parse(localwidgetList)).find(
        (item) => item == "Board"
      );
      if (JSON.parse(localwidgetList)[item]) {
        setBoard(true);
      } else {
        setBoard(false);
        if (
          layoutContext?.currentUserPosition?.OrganizationType ==
          OrganizationType.Customers
        ) {
          setBoard(true);
        } else {
        }
      }
      setWidgetList(list === null ? widgets : list ? list : []);
    }
  }, [
    layoutContext.widgetFormFlag,
    layoutContext?.currentUserPosition?.OrganizationType,
  ]);



  return (
    <div className="dashboard">
      {Board && (
        <div className="w-100 dashboard__board">
          <WidgetBoard id="Board" />
        </div>
      )}
      {widgetList && (
        <div
          className={`dashboard__widgets mx-auto fullscreen ${
         "justify-content-center"
          } row mb-2`}
        >
          {widgetList.map((item) => (
            <div className="col-12 p-0 col-md-4 col-lg-3">
              {item != undefined && <div key={item.id}>{item.element}</div>}
            </div>
          ))}

          {layoutContext?.currentUserPosition?.OrganizationType ==
            OrganizationType.Customers &&
         
           
                <WidgetSales
                  id={"WidgetSales"}
                />
          





                
            }
        </div>
      )}
    </div>
  );
};
