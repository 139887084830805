
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getJobActionList = (PageNumber: number, PageSize: number, ReferenceId: any) => http({
    url: ENDPOINTS.cartable.action.jobActions,
    method: 'get',
    params: {
        pageNumber: PageNumber,
        pageSize: PageSize,
        referenceId: ReferenceId,
    }
}).then((res) => {
    
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});