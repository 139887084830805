import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getPersonLegalList = (PageNumber: number, PageSize: number,orderFormat:string ="" ,filterlist , Search) => http({
    url: ENDPOINTS.BaseInformation.person.browse,
    method: "get",
    params: {
        PageNumber: PageNumber,
        PageSize: PageSize,
        personType: "personlegal",
        SearchString:Search,
        FilterString:filterlist,
        OrderByString : orderFormat
    }
}).then((res) => {
  
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const getPersonRealList = (PageNumber: number, PageSize: number ,orderFormat:string ="" , filterlist , Search) => http({
    url: ENDPOINTS.BaseInformation.person.browse,
    method: "get",
    params: {
        PageNumber: PageNumber,
        PageSize: PageSize,
        SearchString:Search,
        FilterString:filterlist,
        OrderByString : orderFormat,
        personType: "personreal",
    }
}).then((res) => {
 
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});



export const getPerson = (personId: any) => http({
    url: ENDPOINTS.BaseInformation.person.load,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        Id: personId
    }
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});



export const setPersonAsync = ( method , _objData ) => http({
    method: method,
    url: ENDPOINTS.BaseInformation.person.browse, //http://192.168.10.209:5001/api/1/
    data: _objData,
    
    headers: {
      "content-type": "multipart/form-data",
      Accept: "*/*",
     
    },

}).then((res) => {
     
    return res
}).catch((err) => {
    return Promise.reject( err);
})
export const deletePersonAsync = ( id ) => http({
    method: "delete",
    url: ENDPOINTS.BaseInformation.person.browse, //http://192.168.10.209:5001/api/1/
    params:{
        Id:id
    }
    

}).then((res) => {
     
    return res
}).catch((err) => {
    return Promise.reject( err);
})
export const putPicture = ( id  , data,isLegal ) => http({
    method: "put",
    url: ENDPOINTS.BaseInformation.person.updatePicture, 
    params:{
        personLegalorRealId:id,
        isLegal:isLegal
    },
    data:data
    

}).then((res) => {
     
    return res
}).catch((err) => {
    return Promise.reject( err);
})