import { MEETINGMANAGEMENT_URL } from "utils/constants";
import { ENDPOINTSMEETINGMANAGEMENT } from "utils/endpoints";
import { http } from "utils/http";

export const getMeetingApprovals = (async (meetingId) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.MeetingApproval.MeetingId,
    method: 'get',
    params: {
        meetingId
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getMeetingAttendees = (async (meetingId) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.AttendanceId,
    method: 'get',
    params: {
        Id:meetingId
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const sendMeetingForSign = (async (data) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.approvalForSign,
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const DeleteMeetingApproval = (async (id) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.MeetingApproval.List,
    method: 'get',
    params: {
      
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const sendMeetingApproval = (async (data , method) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.MeetingApproval.browse,
    method:method,
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const FinishMeetingAndStartApprovals = (meetingId) => http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.finishMeeting,
    method: "post",
    params:{
        prjUid:"566685613638ede210db845092569520",
        meetingId:meetingId
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})