
import { DataGrid } from "components/DataGrid/DataGrid";
import { Footerbar } from "components/Footerbar/Footerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toolbar } from "components/Toolbar/Toolbar";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { GUID_EMPTY } from "utils/constants";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { CustomerInfo } from "../component/CustomerInfo/CustomerInfo";
import { getCustomers } from "./api";
import { CustomersUserForm } from "./CustomersUserForm";



export const CustomerPositionList = () => {
    let footerData;
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<any>([]);
    const [selectedObj, setSelectedObj] = useState<string | any>(null);
    const [mode, setMode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const [search, setsearch] = useState(null);
    const [showCustomerInfo, setShowCustomerInfo] = useState(false);
    const [showCustomersUserForm, setShowCustomersUserForm] = useState(false);
    const [filterAndSort, setFilterAndSort] = useState<any>(null);
    const layoutContext = useContext(LayoutContext);
 
    const [pages, setPages] = useState<number>(0);

    let customFuction: any = [
        [(e) => { chatWithRequester(e) }, "paper-plane", "Chat"],
        [(e) => { setShowCustomersUserForm(true) }, "user", "createuserwithdetailcode"]

    ]


    async function chatWithRequester(e) {

        try {
          
            if(e.UserId && e.UserId != GUID_EMPTY){
            let item = {
                Id: e.UserId,
                name: e.Name
            }
            localStorage.setItem("JobRequester", JSON.stringify(item));
            window.open(`#/message`)
        }
        } catch (error) {

        }
    }


    const searchHandler = (str) => {
        setPageNum(1);
        setSelectedObj(null)
        if (str === "") {
            setsearch(null)
            getCustomerssAsync(1, null);
        } else {
            setsearch(str)
            getCustomerssAsync(1, str);
        }
    }
    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("CustomersList", e.currentTarget.value)
        getCustomerssAsync(1);
    };
    const handleGoPage = (value) => {
        if (value) {
            setPageNum(+value);
            getCustomerssAsync(+value);
        } else {
        }
    };
    const handleNextPage = () => {
        if (response.TotalRecords && ((response.TotalRecords / pageSize) > pageNum)) {
            setPageNum((pageNum) => { return +pageNum + 1 });
            getCustomerssAsync(pageNum + 1);

        }
    };
    const handlePrevPage = () => {
        if (pageNum > 1) {
            setPageNum((pageNum) => { return +pageNum - 1 });
            getCustomerssAsync(pageNum - 1);
        }
    };




    const selectedObjCallback = (obj, index) => {
        setSelectedObj(obj);
    }
    /* #endregion */





    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel": downloadCSV(response?.Data ,6 ,trans("CustomerPositionList")); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "info": if (selectedObj) setShowCustomerInfo(true); break;
            case "help": alert("call api help"); break;
            case "chat": if (selectedObj) chatWithRequester(selectedObj); break;
            case "user": if (selectedObj) setShowCustomersUserForm(true); break;

        }
    };




    async function getCustomerssAsync(pagNum = pageNum, searchSTR = search) {
        setLoading(false)
        try {
        let pageSizeChangedByUser = getPageSize("CustomersList")
        setPageSize(pageSizeChangedByUser)
        if(layoutContext.currentApplication!=undefined){

            const res = await getCustomers(pagNum, pageSizeChangedByUser, searchSTR, filterAndSort?.OrderFormat?.join(","), filterAndSort?.Filterlist)
            
            if (res.Data.Data.length > 0) {
                setResponse(res.Data)
                setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            }
        }
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)

    }

    useEffect(() => {
        setSelectedObj(null)
        setResponse(null)
        if(filterAndSort!=null&&layoutContext.currentApplication!=undefined){
            getCustomerssAsync();
        }
    }, [forceUpdate, filterAndSort ,layoutContext.currentApplication]);
    useEffect(() => {
        let settings = getGridSetting("CustomersList")
        setFilterAndSort({ Filterlist: settings[0], OrderFormat: settings[1] })

    }, []);



    return (
        <>
            <Toolbar user={true} info={true}  view={false}   chat={true}  handleSearch={searchHandler} id={selectedObj} emptyList={response?.TotalRecords == 0} search={true} handleClickToolbar={clickToolbarCallback} />
            <div style={{  overflowY: "auto", 
                height: "78vh",
                 scrollbarWidth: "thin" }} className="col-lg-12 mt-0">
                {loading ? <>{
                    response?.Data?.length > 0 ?
                        <div className="card">
                            <div className="card-body p-0">
                                <>

                                    <DataGrid
                                        customFuction={customFuction}
                                        handlefiltering={(str:any) => {str.Filterlist != undefined  ? setFilterAndSort(str) : setFilterAndSort({...filterAndSort, Filterlist:str})}}
                                        filterStr={filterAndSort.Filterlist}
                                        orderFormatList={filterAndSort.OrderFormat}
                                        orderFormat={(order) => { setFilterAndSort({ ...filterAndSort, OrderFormat: order }) }}
                                        parentName={"CustomersList"}
                                        theadData={Object.keys(response.Data[0])}
                                        tbodyData={response.Data}
                                        minCellWidth={80}
                                        selectedRowObjectCallback={selectedObjCallback}
                                        rowSelected={selectedObj}
                                        startIndex={3}
                                        totalRecords={response.TotalRecords}
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        HandleNextPage={handleNextPage}
                                        HandlePrevPage={handlePrevPage}
                                        handlechangePageSize={handleChangePageSize}
                                        first={() => { getCustomerssAsync(pages); setPageNum(pages) }}
                                        end={() => { getCustomerssAsync(1); setPageNum(1) }}
                                        handleGoPage={handleGoPage}
                                    />
                                    {footerData && <Footerbar footerContents={footerData} hasBorder={false} />}

                                </>
                            </div>
                        </div>
                        :<Message message={"NoDataForPreview"} type={messageType} />
                        
                }</>
                    : <Loading />
                }

            </div>


            {showCustomerInfo &&
                <ModalForm mode={mode} title={("CustomerInfo")} onHide={() => { setShowCustomerInfo(false) }} show={showCustomerInfo}>
                    <CustomerInfo customerPositionId={selectedObj.Id} />
                </ModalForm>
            }
            {showCustomersUserForm &&
                <ModalForm mode={mode} title={("UserForm")} onHide={() => { setShowCustomersUserForm(false) }} show={showCustomersUserForm}>
                    <CustomersUserForm personName={selectedObj.Name} personId={selectedObj.PersonId} hide={() => { setShowCustomersUserForm(false) }} mode={"edit"} reload={()=>{setForceUpdate(!forceUpdate)}} />
                </ModalForm>
            }
        </>

    );
}