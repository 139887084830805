import { useEffect, useState } from "react";
import './style.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImgBox } from "./ImgBox";

interface GalleryProps {
    showGallery:boolean,
    data?:any;
    selectImg?:any;
}

export const  Gallery= ({data,selectImg,showGallery}:GalleryProps) => {

    const [respons,setRespons] = useState<any>(data)
    const [index,setIndex] = useState<any>(0)

    const getGallery = ()=>{
        if(data){
            setRespons(data)
        }
        
    }
    
    const next = ()=>{
        if(index < Object.values(respons).length-1){
            setIndex(index+1)
        }else{
            setIndex(0)
        }
    }

    const previous = ()=>{
        if(index > 0){
            setIndex(index-1)
        }else{
            setIndex(Object.values(respons).length-1)
        }
    }
    useEffect(()=>{
        setIndex(selectImg)
    },[selectImg])

    useEffect(()=>{
        getGallery()
    },[data])

    return ( 
        <div className="row h-100 Gallery">
            <div className={`${showGallery ?'col-6' :'ps-4 pe-3'}`}>
                <div className="position-relative d-flex justify-content-center align-items-center">
                    <div onClick={previous} className="position-absolute end-0 top-50 font-size-md translate-middle-y rounded-circle p-2 text-center glassBox Gallery__btn"><FontAwesomeIcon icon={["fas", "chevron-right"]}></FontAwesomeIcon></div>
                    <div onClick={next} className="position-absolute start-0 top-50 font-size-md translate-middle-y rounded-circle p-2 text-center glassBox Gallery__btn"><FontAwesomeIcon icon={["fas", "chevron-left"]}></FontAwesomeIcon></div>
                    <ImgBox showGallery={false} attchId={respons[index]} />
{/*                    {(respons&&Object.values(respons)[index])&& <img  width='80%' height='80%' src={Object.values(respons)[index]+""}/> }
 */}                </div> 
            </div>
            {showGallery &&
            <div className="col-6">
                <div className="d-flex justify-content-start ">
                    { respons&&respons.map((e:any,i)=>(
                        <ImgBox showGallery={true} active={i == index} attchId={e}  onClick={()=>{setIndex(i)}}/>
/*                         <div key={i+'Gallery__imgBx'} onClick={()=>{setIndex(i)}} className={`m-1 rounded-1 Gallery__imgBx  ${i == index ?'Gallery__active' :''}`}><img className="w-100 h-100" src={e}/></div>
 */                        ))}
                </div>
            </div>
            }
        </div>
     );
}