import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Search } from "components/Search/Search";
import React, { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getPositionList } from "../../../OtherGroups/components/api/api";



interface AssignedPositionList{
  PositionId,
  reset,
  selectList?: (e) => void;
  data,
  hasMultiSelect,
}


export const AssignedPositionList: React.FC<AssignedPositionList> = ({
  PositionId,
  reset,
  selectList,
  data,
  hasMultiSelect
})=>{
    let BodyPositionList:any
    const [searchData, setSearchData] = useState<Response | any>(data);
    const [searchString, setSearchString] = useState<Response | any>("");
    const [selectedList, setSelectedList] = useState<Response | any>();
    const [loading, setLoading] = useState<boolean>(false);

    const selectListCallBack =(list)=>{
      setSelectedList(list[1])

      selectList && selectList(list)
  
    }
  useEffect(() => {
    selectListCallBack([])
    setSelectedList([])
    getDataAsync(searchString)
  }, [ reset]);


  useEffect(() => {
    getDataAsync(searchString)
  }, [ data]);

  
  const Searching = (str)=>{
    setSearchString(str)
    getDataAsync(str)
  }


  async function getDataAsync(SearchString) { 
    setLoading(false)
      try {
         const PositionListResponse = await getPositionList(SearchString); 
         let PositionListFiltered:any = []
        data.forEach(item1 => {
          PositionListResponse.Data.forEach(item2 => {

            if(item1?.PositionId ? item1.PositionId == item2.Id : item1.Id == item2.Id){
              PositionListFiltered.push(item1)
            }
            
          });
          
         });
         setSearchData(PositionListFiltered)
        } catch (error) {
        
        }
        setLoading(true)
      }  
     
   


  if (searchData && searchData.length>0  ) {
    let FilteredSeleced:any = []
    if(selectedList && selectedList.length > 0){   
      searchData.forEach(item1 => {
       let flag = false
       selectedList.forEach(item2 => {
         if(item1.Id == item2.Id){
           flag = true
         }      
       });
       if(!flag ){
         FilteredSeleced.push(item1)
       }
      
      });
      BodyPositionList = selectedList.concat(FilteredSeleced)
    }else{
      BodyPositionList = searchData
    }

}


    return(
        <>
        <div className="card">
            <div className="">
            <div className="d-flex justify-content-between align-items-center mb-1">
                <span className="text-break">{trans("assignedpositionlist")}</span>
                <Search onChange={Searching}/>
              </div>
              {loading ?(
              
              searchData?.length>0 ? (
                <>
                  <DataGrid
                    resetMultiSelect={reset}
                    multiSelect={hasMultiSelect && hasMultiSelect}
                    selectList={selectListCallBack }
                    theadData={["Id","PositionId","Description"]}
                    minCellWidth={80}
                    tbodyData={BodyPositionList}
                    startIndex={2}
                    scrollHeight={"300px"}
                  />
                </>
              ) : (
                <Message message={"NoDataForPreview"} type={searchData?.length==0  ?"info":"error"   } />
              ))
               : <Loading/>
              }
            </div>
          </div>
        </>
    )
}