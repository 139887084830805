import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getLetterSubjectApi = (async (searchString, id) => await http({
    url: ENDPOINTS.secretariat.letterSubject.dictionary,
    method: 'get',
    params:{
        searchString:searchString
    }

})
    .then((respons) => (respons.data.Data))
)
