import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints"
import { http } from "utils/http"


export const getPersonArchive = (id: any) => http({
    url: ENDPOINTS.archive.personalArchive.archives,
    method: "get", 
    params: {
        ParentId: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});









export const getJobFileArchive = (Id: any ,pageNumber,pageSize) => http({
    url: ENDPOINTS.archive.JobPersonalArchiveDetail.browse,
    method: "get",
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        PersonalArchiveDetailId: Id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const deleteJobFileArchive = (Id: any) => http({
    url: ENDPOINTS.archive.JobPersonalArchiveDetail.browse,
    method: "delete",
    params: {
        Id: Id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});




export const getViewForm = (processId: any, SelectedPosition?: any) => http({
    url: ENDPOINTS.bpmnApi.process.getViewForm,
    method: 'get',
    params: {
        processId: processId,
    }
}).then((response) => (response.data))
    .catch(err => {
        return Promise.reject(err);
    })
