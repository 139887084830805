import { ButtonToolbar } from "components/ButtonToolbar/ButtonToolbar";
import { ButtonToolbarCreateUser } from "components/ButtonToolbarCreateUser/ButtonToolbarCreateUser";
import { ButtonToolbarHolidaysType } from "components/ButtonToolbarHolidaysType/ButtonToolbarHolidaysType";
import { ButtonToolbarMyOwnRequest } from "components/ButtonToolbarMyOwnRequest/ButtonToolbarMyOwnRequest";
import { ButtonToolbarProcessFilter } from "components/ButtonToolbarProcessFilter/ButtonToolbarProcessFilter";
import { useEffect, useState } from "react";
import { Search } from "components/Search/Search";
import { ButtonToolbarProcessStarting } from "pages/Secretariats/LettersPage/components/Toolbar/ButtonToolbarProcessStarting/ButtonToolbarProcessStarting";
import { ButtonToolbarSecretariatFilter } from "pages/Secretariats/LettersPage/components/Toolbar/ButtonToolbarSecretariatFilter/ButtonToolbarSecretariatFilter";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { ButtonToolbarCreateArchive } from "components/ButtonToolbarCreateArchive/ButtonToolbarCreateArchive";
import { ButtonToolbarArchiveFile } from "components/ButtonToolbarArchiveFile/ButtonToolbarArchiveFile";
import { ButtonToolbarStartProcess } from "components/ButtonToolbarStartProcess/ButtonToolbarStartProcess";
import { ButtonToolbarShowDeletedFilter } from "components/ButtonToolbarShowDeletedFilter/ButtonToolbarShowDeletedFilter";
import { ButtonPrintToolbar } from "pages/Secretariats/LettersPage/components/Toolbar/ButtonPrintToolbar/ButtonPrintToolbar";
import { ButtonToolbarPersonType } from "components/ButtonToolbarPersonType/ButtonToolbarPersonType";
import { ButtonToolbarSearch } from "components/ButtonToolbarSearch/ButtonToolbarSearch";
import { ButtonToolbarEnumerationFilter } from "components/ButtonToolbarEnumerationFilter/ButtonToolbarEnumerationFilter";
import "../Toolbar/style.scss";

interface props {
    handleClickToolbar: (clickedButtonName: string) => void;
    handleSelectedFilterPersonType?:(clickedButtonName: any) => void;
    handleSelectedFilterProcess?: (clickedButtonName: any) => void;
    handleSelectedJobstatusFilter?: (clickedButtonName: any) => void;
    handleSelectedcreateUser?: (clickedButtonName: any) => void;
    handleSelectedProcessWithIdFilterToolbar?: (clickedButtonName: any) => void;
    handleSelectedFilterPersonalTaskStatus?: (clickedButtonName: any) => void;
    handleSelectedHolidaysType?: (clickedButtonName: any) => void;
    handleSelectedMyOwnRequestStartForm?: (clickedButtonName: any) => void;
    handleSelectedStartProcess?: (clickedButtonName: any) => void;
    handleSelectedStartingProcessToolbar?: (selectedStarting: string) => void;
    handleSelectedProcessFilterToolbar?: (selectedProcessFilter: any) => void;
    handleShowDeletedFilterToolbar?: (selectedProcessFilter: any) => void;
    handleCreateArchive?: (selectedProcessFilter: any) => void;
    handleSelectedArchive?: (selectedProcessFilter: any) => void;
    handleSearch?: (str: any) => void;
    handlePrint?: (str: any) => void;
    id?:string;
    emptyList?: boolean;
    reminder?: boolean;
    create?: boolean;
    view?: boolean;
    refresh?: boolean;
    edit?: boolean;
    search?: boolean;
    defultSerach?: any;
    remove?: boolean;
    attach?: boolean;
    members?: boolean;
    persons?: boolean;
    download?: boolean;
    upload?: boolean;
    reference?: boolean;
    specialreference?: boolean;
    assignRule?: boolean;
    action?: boolean;
    history?: boolean;
    related?: boolean;
    Tree?: boolean;
    assignApp?: boolean;
    assignIcon?: boolean;
    report?: boolean;
    finish?: boolean;
    move?: boolean;
    OrganizationTypeFilter?: boolean;
    reply?: boolean;
    cancel?: boolean;
    current?: boolean;
    archive?: boolean;
    reverse?: boolean;
    forceStop?: boolean;
    info?: boolean;
    chat?: boolean;
    copy?: boolean;
    paste?: boolean;
    calendar?: boolean;
    transfer?: boolean;
    pattern?: boolean;
    reserve?: boolean;
    Export?: boolean;
    Import?: boolean;
    Importprocesses?: boolean;
    Delivery?: boolean;
    excel?: boolean;
    objectTag?: string;
    
}
export const ToolbarInTab: React.FC<props> = ({
    handleClickToolbar,
    handleSelectedFilterPersonType,
    handleSelectedFilterProcess,
    handleSelectedJobstatusFilter,
    handleSelectedcreateUser,
    handleSelectedFilterPersonalTaskStatus,
    handleSelectedHolidaysType,
    handleSelectedMyOwnRequestStartForm,
    handleSelectedStartProcess,
    handleSelectedStartingProcessToolbar,
    handleSelectedProcessFilterToolbar,
    handleShowDeletedFilterToolbar,
    handleSelectedProcessWithIdFilterToolbar,
    handleCreateArchive,
    handleSearch,
    handlePrint,
    handleSelectedArchive,
    id,
    emptyList,
    create,
    action,
    reminder,
    view=true,
    refresh=true,
    edit,
    search,
    defultSerach=null,
    remove,
    attach,
    members,
    persons,
    download,
    upload,
    reference,
    specialreference,
    assignRule,
    related,
    history,
    Tree,
    assignApp,
    assignIcon,
    report,
    finish,
    move,
    OrganizationTypeFilter,
    reply,
    cancel,
    current,
    reverse,
    archive,
    forceStop,
    info,
    chat,
    copy,
    paste,
    calendar,
    transfer,
    pattern,
    reserve,
    Export,
    Import,
    Importprocesses,
    Delivery,
    excel=true,
    objectTag,
}) => {

    const [windowInnerWidth,setWindowInnerWidth]=useState(window.innerWidth);
    const [shortcutList, setShortcutList] = useState<any>({});

    const [openToolbar, setOpenToolbar] = useState<boolean>(false);
    const [isFocos, setIsFocos] = useState<boolean>(false);
    const toggleToolbar = () => {
      setOpenToolbar(!openToolbar)
    }


    const handleSelectedLetterProcessStarting = (id: string) => {
        handleSelectedStartingProcessToolbar && handleSelectedStartingProcessToolbar(id);
      }


      const handleSelectedLetterFilterProcess = (id: string) => {
        handleSelectedProcessFilterToolbar && handleSelectedProcessFilterToolbar(id);
      }


      useEffect(()=>{
        function handleWindowResize() {
            if(window.innerWidth> 782 ||window.innerWidth> 778 ||window.innerWidth> 993 ||window.innerWidth> 997  ){

            }
            setWindowInnerWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      },[])



    useEffect(() => {
      //  window.addEventListener("keydown" , keydown)
        let StorageShortcut = localStorage.getItem("Shortcut")
        let ShortcutList
        if (StorageShortcut == null) {
          setShortcutList({})
        } else {
            ShortcutList = JSON.parse(StorageShortcut)
            setShortcutList(ShortcutList)
      
        }
      
      }, [])
    return (
        <div style={{position:"relative"}}>
        <section className="py-2 "  style={ (windowInnerWidth >850 && windowInnerWidth < 995 )? {position:"absolute" ,left:"0",top:"-75px",zIndex:"4"} : windowInnerWidth >995 ? {position:"absolute" ,left:"0",top:"-60px",zIndex:"4"}:{}}>
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-2 mt-1 mt-sm-0 mb-2">
           {(search && windowInnerWidth <= 780) ? <Search setIsFocos={setIsFocos} defultSerach={defultSerach} isFocos={isFocos}  onChange={handleSearch && handleSearch}/> : null}
        </div>      
        <div className="toolbarXscrol toolbar-btns-container col-12 mb-md-1 mb-lg-0 col-lg-10">
          <div className={`toolbarXscrol toolbar-btns-container__sub-container ${openToolbar ? 'active' : ''} d-flex d-md-flex  w-100 ${windowInnerWidth < 780 ? ' justify-content-start' : ' justify-content-end'} ${windowInnerWidth < 780 && 'mt-2'}mt-lg-0`}>
          {windowInnerWidth <= 780 && <button onClick={toggleToolbar} className="toolbar-btns-container__toggle-btn btn btn-primary my-1">+</button>}
        {windowInnerWidth > 780 &&
                    <OverlayTrigger
                             key={"search"}
                             placement="top"
                             delay={{"show" : 600 , "hide":0}}
                             overlay={
                                 <Tooltip className="tooltip" id={`button-tooltip-${"search"}`}>
                                     {trans("search")}
                                 </Tooltip>
                             }
                         >
                            <div className="py-1 py-lg-0">
                            {search ? (
                                  <ButtonToolbarSearch 
                                   key={""} 
                                   setIsFocos={setIsFocos}
                                   isFocos={isFocos}
                                   onChange={handleSearch && handleSearch}
                                   id={id} 
                                   defultSerach={defultSerach}
                                   hotKey={"S"} 
                                   faIcon={[ICON_TYPE, "search"]} 
                                   text="search" 
                                />
                                 ) : null}
                            </div>                               
                    </OverlayTrigger>  
                    }
                    <OverlayTrigger
                             key={"Filter1"}
                             placement="top"
                             delay={{"show" : 600 , "hide":0}}
                             overlay={
                                 <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                                     {trans("Filter")}
                                 </Tooltip>
                             }
                         >

                            <div className="py-1 py-lg-0">
                            {handleSelectedFilterProcess ? (
                                  <ButtonToolbarEnumerationFilter
                                  enumerationType="OrganizationType" 
                                  key={""} 
                                  Callback={(e)=>{if(handleSelectedFilterProcess){handleSelectedFilterProcess(e)}} }
                                />
                                 ) : null}
                            </div>                               
                    </OverlayTrigger>  

                    <OverlayTrigger
                                    key={"Filter2"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                                            {trans("Filter")}
                                        </Tooltip>
                                    }
                                >

                            <div className="py-1 py-lg-0">
                            {handleSelectedJobstatusFilter ? (
                                  <ButtonToolbarEnumerationFilter
                                  enumerationType="jobstatus" 
                                  key={""} 
                                  Callback={(e)=>{if(handleSelectedJobstatusFilter){handleSelectedJobstatusFilter(e)}} }
                                />
                                 ) : null}
                            </div>                               
                         </OverlayTrigger>  
                    <OverlayTrigger
                                    key={"Filter3"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                                            {trans("Filter")}
                                        </Tooltip>
                                    }
                                >

                            <div className="py-1 py-lg-0">
                            {handleSelectedFilterPersonalTaskStatus ? (
                                  <ButtonToolbarEnumerationFilter
                                  enumerationType="personalTaskStatus" 
                                  key={""} 
                                  Callback={(e)=>{if(handleSelectedFilterPersonalTaskStatus){handleSelectedFilterPersonalTaskStatus(e)}} }
                                />
                                 ) : null}
                            </div>                               
                         </OverlayTrigger>  
                    <OverlayTrigger
                               key={"reserve"}
                               placement="top"
                               delay={{"show" : 600 , "hide":0}}
                               overlay={
                                   <Tooltip className="tooltip " id={`button-tooltip-${"reserve"}`}>
                                       {trans("reserve")}
                                   </Tooltip>
                               }
                           >
                           <div className="py-1 py-lg-0">
                               {reserve ? (
                                   <ButtonToolbar id={id} hotKey={shortcutList["reserve"]} faIcon={[ICON_TYPE, "calendar"]} text="reserve" onClick={() => { handleClickToolbar("reserve") }} />
                               ) : null}
                           </div>                                  
                    </OverlayTrigger>   
                    <OverlayTrigger
                               key={"transfer"}
                               placement="top"
                               delay={{"show" : 600 , "hide":0}}
                               overlay={
                                   <Tooltip className="tooltip " id={`button-tooltip-${"transfer"}`}>
                                       {trans("transfer")}
                                   </Tooltip>
                               }
                           >
                           <div className="py-1 py-lg-0">
                               {!emptyList && transfer  ? (
                                   <ButtonToolbar id={id} hotKey={shortcutList["transfer"]} faIcon={[ICON_TYPE, "arrow-right-arrow-left"]} text="transfer" onClick={() => { handleClickToolbar("transfer") }} />
                               ) : null}
                           </div>                                  
                    </OverlayTrigger>   
                    <OverlayTrigger
                               key={"paste"}
                               placement="top"
                               delay={{"show" : 600 , "hide":0}}
                               overlay={
                                   <Tooltip className="tooltip " id={`button-tooltip-${"paste"}`}>
                                       {trans("paste")}
                                   </Tooltip>
                               }
                           >
                           <div className="py-1 py-lg-0">
                               {paste ? (
                                   <ButtonToolbar id={id} hotKey={shortcutList["paste"]} faIcon={[ICON_TYPE, "paste"]} text="paste" onClick={() => { handleClickToolbar("paste") }} />
                               ) : null}
                           </div>                                  
                    </OverlayTrigger>   
                    <OverlayTrigger
                               key={"pattern"}
                               placement="top"
                               delay={{"show" : 600 , "hide":0}}
                               overlay={
                                   <Tooltip className="tooltip " id={`button-tooltip-${"pattern"}`}>
                                       {trans("pattern")}
                                   </Tooltip>
                               }
                           >
                           <div className="py-1 py-lg-0">
                               {pattern ? (
                                   <ButtonToolbar id={id} hotKey={shortcutList["pattern"]} faIcon={[ICON_TYPE, "paste"]} text="pattern" onClick={() => { handleClickToolbar("pattern") }} />
                               ) : null}
                           </div>                                  
                    </OverlayTrigger>   
                    <OverlayTrigger
                               key={"copy"}
                               placement="top"
                               delay={{"show" : 600 , "hide":0}}
                               overlay={
                                   <Tooltip className="tooltip " id={`button-tooltip-${"copy"}`}>
                                       {trans("copy")}
                                   </Tooltip>
                               }
                           >
                           <div className="py-1 py-lg-0">
                               {copy ? (
                                   <ButtonToolbar id={id} hotKey={shortcutList["copy"]} faIcon={[ICON_TYPE, "copy"]} text="copy" onClick={() => { handleClickToolbar("copy") }} />
                               ) : null}
                           </div>                                  
                    </OverlayTrigger>   
                    <OverlayTrigger
                               key={"accepted"}
                               placement="top"
                               delay={{"show" : 600 , "hide":0}}
                               overlay={
                                   <Tooltip className="tooltip " id={`button-tooltip-${"accepted"}`}>
                                       {trans("accepted")}
                                   </Tooltip>
                               }
                           >
                           <div className="py-1 py-lg-0">
                               {Import ? (
                                   <ButtonToolbar id={id} hotKey={shortcutList["Import"]} faIcon={[ICON_TYPE, "file-import"]} text="accepted" onClick={() => { handleClickToolbar("import") }} />
                               ) : null}
                           </div>                                  
                    </OverlayTrigger>   
                    <OverlayTrigger
                               key={"Import"}
                               placement="top"
                               delay={{"show" : 600 , "hide":0}}
                               overlay={
                                   <Tooltip className="tooltip " id={`button-tooltip-${"Import"}`}>
                                       {trans("importprocess")}
                                   </Tooltip>
                               }
                           >
                           <div className="py-1 py-lg-0">
                               {Importprocesses ? (
                                   <ButtonToolbar id={id} hotKey={shortcutList["Import"]} faIcon={[ICON_TYPE, "file-import"]} text="import" onClick={() => { handleClickToolbar("import") }} />
                               ) : null}
                           </div>                                  
                    </OverlayTrigger>   
                    <OverlayTrigger
                               key={"Delivery"}
                               placement="top"
                               delay={{"show" : 600 , "hide":0}}
                               overlay={
                                   <Tooltip className="tooltip " id={`button-tooltip-${"Delivery"}`}>
                                       {trans("Delivery")}
                                   </Tooltip>
                               }
                           >
                           <div className="py-1 py-lg-0">
                               {Delivery ? (
                                   <ButtonToolbar id={id} hotKey={shortcutList["Delivery"]} faIcon={[ICON_TYPE, "file-export"]} text="Delivery" onClick={() => { handleClickToolbar("Delivery") }} />
                               ) : null}
                           </div>                                  
                    </OverlayTrigger>   
                    <OverlayTrigger
                               key={"export"}
                               placement="top"
                               delay={{"show" : 600 , "hide":0}}
                               overlay={
                                   <Tooltip className="tooltip " id={`button-tooltip-${"export"}`}>
                                       {trans("exportprocess")}
                                   </Tooltip>                                   
                               }
                           >
                           <div className="py-1 py-lg-0">
                               {Export ? (
                                   <ButtonToolbar id={id} hotKey={shortcutList["export"]} faIcon={[ICON_TYPE, "file-export"]} text="export" onClick={() => { handleClickToolbar("export") }} />
                               ) : null}
                           </div>                                  
                    </OverlayTrigger>   
                    <OverlayTrigger
                               key={"print"}
                               placement="top"
                               delay={{"show" : 600 , "hide":0}}
                               overlay={
                                   <Tooltip className="tooltip " id={`button-tooltip-${"print"}`}>
                                       {trans("print")}
                                   </Tooltip>     
                               }
                           >
                           <div className="py-1 py-lg-0">
                               {!emptyList ? (
                                   <ButtonPrintToolbar objectTag={objectTag} Callback={(e) => {handlePrint&& handlePrint(e) }} />
                               ) : null}

                               
                           </div>                                  
                    </OverlayTrigger>   
                    <OverlayTrigger
                               key={"excel"}
                               placement="top"
                               delay={{"show" : 600 , "hide":0}}
                               overlay={
                                   <Tooltip className="toolbarTooltip" id={`button-tooltip-${"excel"}`}>
                                       {trans("excel")}
                                   </Tooltip>
                               }
                           >
                         <div className="py-1 py-lg-0">
                             {!emptyList &&excel ? (
                                 <ButtonToolbar id={id} hotKey={shortcutList["excel"]} faIcon={[ICON_TYPE, "file"]} text="Excel" onClick={() => { handleClickToolbar("excel") }} />
                             ) : null}
                         </div>                             
                    </OverlayTrigger>
                    <OverlayTrigger
                                    key={"info"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"info"}`}>
                                            {trans("info")}
                                        </Tooltip>
                                    }
                                >

                            <div className="py-1 py-lg-0">
                            {!emptyList && info ? (
                                   <ButtonToolbar id={id} key={"Ctrl+i"}
                                     faIcon={[ICON_TYPE, "info"]} text="info" hotKey={shortcutList["info"]} onClick={() => { handleClickToolbar("info") }} />
                                 ) : null}
                            </div>                               
                         </OverlayTrigger>  
                    <OverlayTrigger
                                    key={"forceStop"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"forceStop"}`}>
                                            {trans("forceStop")}
                                        </Tooltip>
                                    }
                                >

                            <div className="py-1 py-lg-0">
                            {!emptyList && forceStop ? (
                                   <ButtonToolbar id={id} key={"Ctrl+q"}
                                     faIcon={[ICON_TYPE, "stop-circle"]} text="stop" hotKey={shortcutList["stop"]} onClick={() => { handleClickToolbar("forceStop") }} />
                                 ) : null}
                            </div>                               
                         </OverlayTrigger>  
 
                    <OverlayTrigger
                                    key={"chat"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"chat"}`}>
                                            {trans("chat")}
                                        </Tooltip>
                                    }
                                >

                            <div className="py-1 py-lg-0">
                            {!emptyList && chat ? (
                                   <ButtonToolbar id={id} key={"Ctrl+g"}
                                     faIcon={[ICON_TYPE, "paper-plane"]} text="chat" hotKey={shortcutList["chat"]} onClick={() => { handleClickToolbar("chat") }} />
                                 ) : null}
                            </div>                               
                         </OverlayTrigger>  
 
                    <OverlayTrigger
                                    key={"report"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"report"}`}>
                                            {trans("report")}
                                        </Tooltip>
                                    }
                                >

                            <div className="py-1 py-lg-0">
                            {report ? (
                                    <ButtonToolbar id={id} key={"F9"}
                                      faIcon={[ICON_TYPE, "bolt"]} text="report" hotKey={shortcutList["report"]} onClick={() => { handleClickToolbar("report") }} />
                                  ) : null}
                            </div>                               
                       </OverlayTrigger>  
                           <OverlayTrigger
                                    key={"reminder"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"reminder"}`}>
                                            {trans("reminder")}
                                        </Tooltip>
                                    }
                                >

                             <div className="py-1 py-lg-0">
                               {reminder ? (
                                 <ButtonToolbar id={id} key={"F10"}
                                   faIcon={[ICON_TYPE, "bell"]} text="Reminder" hotKey={shortcutList["reminder"]} onClick={() => { handleClickToolbar("reminder") }} />
                               ) : null}
                             </div>                             
                         </OverlayTrigger>  
                       <OverlayTrigger
                                    key={"Upload"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"Upload"}`}>
                                            {trans("Upload")}
                                        </Tooltip>
                                    }
                                >
                        <div className="py-1 py-lg-0">
                            {upload ?
                                (<ButtonToolbar id={id} hotKey={shortcutList["Upload"]}  faIcon={[ICON_TYPE, "upload"]} text="Upload" onClick={() => { handleClickToolbar("Upload") }} />)
                                : null
                            }
                        </div>
                         </OverlayTrigger> 
                      <OverlayTrigger
                                    key={"action"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"action"}`}>
                                            {trans("action")}
                                        </Tooltip>
                                    }
                                >

                        <div className="py-1 py-lg-0">
                           {action ? (
                             <ButtonToolbar id={id} key={"F9"}
                               faIcon={[ICON_TYPE, "bolt"]} text="Action" hotKey={shortcutList["action"]} onClick={() => { handleClickToolbar("action") }} />
                           ) : null}
                         </div>
                         </OverlayTrigger> 

                      <OverlayTrigger
                                    key={"Filter5"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                                            {trans("Filter")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">

                            {handleSelectedProcessWithIdFilterToolbar ?        
                                    <div className="py-1 py-lg-0">
                                        <ButtonToolbarProcessFilter 
                                          key={""} 
                                          Callback={handleSelectedProcessWithIdFilterToolbar}
                                        />
                                    </div>
                                    :null
                        }

                            </div>
                         </OverlayTrigger> 
                         
                         <OverlayTrigger
                                    key={"download"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"download"}`}>
                                            {trans("Download")}
                                        </Tooltip>
                                    }
                                >
                        <div className="py-1 py-lg-0">
                            {!emptyList && download ?
                                (<ButtonToolbar id={id} hotKey={shortcutList["Download"]} faIcon={[ICON_TYPE, "download"]} text="Download" onClick={() => { handleClickToolbar("Download") }} />)
                                : null}
                        </div>                    
                         </OverlayTrigger>           

  

 
                         <OverlayTrigger
                                    key={"move"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"move"}`}>
                                            {trans("transfer")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">
                                {move ? (
                                    <ButtonToolbar id={id} hotKey={shortcutList["move"]} faIcon={[ICON_TYPE, "link"]} text="transfer" onClick={() => { handleClickToolbar("move") }} />
                                ) : null}
                            </div>            
                         </OverlayTrigger>  
 
                         <OverlayTrigger
                                    key={"members"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"members"}`}>
                                            {trans("members")}
                                        </Tooltip>
                                    }
                                >
                             <div className="py-1 py-lg-0">
                                 {members ? (
                                     <ButtonToolbar id={id}  hotKey={shortcutList["members"]} faIcon={[ICON_TYPE, "users"]} text="members" onClick={() => {  handleClickToolbar("members")  }} />
                                 ) : null}
                             </div>                  
                         </OverlayTrigger>  
                         <OverlayTrigger
                                    key={"persons"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"persons"}`}>
                                            {trans("persons")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">
                                {persons ? (
                                    <ButtonToolbar id={id} hotKey={shortcutList["persons"]}  faIcon={[ICON_TYPE, "users"]} text="persons" onClick={() => {  handleClickToolbar("persons")  }} />
                                ) : null}
                            </div>          
                         </OverlayTrigger>  

                         <OverlayTrigger
                                    key={"Rule"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"Rule"}`}>
                                            {trans("Role")}
                                        </Tooltip>
                                    }
                                >
                             <div className="py-1 py-lg-0">
                                 {assignRule ? (
                                     <ButtonToolbar id={id} hotKey={shortcutList["Rule"]}  faIcon={[ICON_TYPE, "users"]} text="Role" onClick={() => { handleClickToolbar("AssignRule") }} />
                                 ) : null}
                             </div>      
                         </OverlayTrigger>  
                         <OverlayTrigger
                                    key={"history"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"history"}`}>
                                            {trans("history")}
                                        </Tooltip>
                                    }
                                >

                            <div className="py-1 py-lg-0">
                                {history ? (
                                    <ButtonToolbar id={id} hotKey={shortcutList["history"]}  faIcon={[ICON_TYPE, "clipboard"]} text="history" onClick={() => { handleClickToolbar("history") }} />
                                ) : null}
                            </div>        
                         </OverlayTrigger>  
                         <OverlayTrigger
                                    key={"related"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"related"}`}>
                                            {trans("related")}
                                        </Tooltip>
                                    }
                                >
                             <div className="py-1 py-lg-0">
                                 {related ? (
                                     <ButtonToolbar id={id}  hotKey={shortcutList["related"]} faIcon={[ICON_TYPE, "link"]} text="related" onClick={() => { handleClickToolbar("related") }} />
                                 ) : null}
                             </div>         
                         </OverlayTrigger>  
                         <OverlayTrigger
                                    key={"Tree"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"Tree"}`}>
                                            {trans("Tree")}
                                        </Tooltip>
                                    }
                                >
                             <div className="py-1 py-lg-0">
                                 {Tree ? (
                                     <ButtonToolbar id={id}  hotKey={shortcutList["Tree"]} faIcon={[ICON_TYPE, "project-diagram"]} text="Tree" onClick={() => { handleClickToolbar("Tree") }} />
                                 ) : null}
                             </div>      
                         </OverlayTrigger>  
                         <OverlayTrigger
                                    key={"App"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"App"}`}>
                                            {trans("App")}
                                        </Tooltip>
                                    }
                                >

                             <div className="py-1 py-lg-0">
                                 {assignApp ? (
                                     <ButtonToolbar id={id}  hotKey={shortcutList["App"]} faIcon={[ICON_TYPE, "desktop-alt"]} text="App" onClick={() => { handleClickToolbar("assignApp") }} />
                                 ) : null}
                             </div>    
                         </OverlayTrigger>  
                         <OverlayTrigger
                                    key={"Icon"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"Icon"}`}>
                                            {trans("Icon")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">
                               {assignIcon ? (
                                 <ButtonToolbar id={id}  hotKey={shortcutList["Icon"]} faIcon={[ICON_TYPE, "icons"]} text="Icon" onClick={() => { handleClickToolbar("assignicon") }} />
                                 ) : null}
                            </div>           
                         </OverlayTrigger>  
 
                         <OverlayTrigger
                                    key={"reply"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"reply"}`}>
                                            {trans("reply")}
                                        </Tooltip>
                                    }
                                >

                        <div className="py-1 py-lg-0">
                             {reply ? (
                               <ButtonToolbar id={id} key={shortcutList["Archive"]}
                                 faIcon={[ICON_TYPE, "reply"]} text="Reply" hotKey={shortcutList["reply"]} onClick={() => { handleClickToolbar("reply") }} />
                             ) : null}
                           </div>                             
                         </OverlayTrigger>  


                         {!emptyList && attach ?
                         <OverlayTrigger
                             key={"attach"}
                             placement="top"
                             delay={{"show" : 600 , "hide":0}}
                             overlay={
                                 <Tooltip className="tooltip" id={`button-tooltip-${"attach"}`}>
                                     {trans("attach")}
                                 </Tooltip>
                             }
                         >
                            <div className="py-1 py-lg-0">
                                <ButtonToolbar id={id}hotKey={shortcutList["Attach"]}  faIcon={[ICON_TYPE, "paperclip"]} text="Attach" onClick={() =>  handleClickToolbar("attach")} />
                            </div>                                                                        
                           </OverlayTrigger>  
                            : null}

                             <OverlayTrigger
                                    key={"Filter4"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                                            {trans("Filter")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">
                            {handleSelectedProcessFilterToolbar ?        
                                    <div className="py-1 py-lg-0">
                                        <ButtonToolbarSecretariatFilter 
                                          key={""} 
                                          Callback={handleSelectedLetterFilterProcess}
                                        />
                                    </div>
                                    :null
                        }

                            </div>
                         </OverlayTrigger> 
                             <OverlayTrigger
                                    key={"Filter6"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                                            {trans("Filter")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">
                            {handleShowDeletedFilterToolbar ?        
                                    <div className="py-1 py-lg-0">
                                        <ButtonToolbarShowDeletedFilter 
                                          key={""} 
                                          Callback={handleShowDeletedFilterToolbar}
                                        />
                                    </div>
                                    :null
                        }

                            </div>
                         </OverlayTrigger> 

                         <OverlayTrigger
                                    key={"Filter7"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                                            {trans("PersonType")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">
                            {handleSelectedFilterPersonType ?        
                                    <div className="py-1 py-lg-0">
                                        <ButtonToolbarPersonType 
                                          key={""} 
                                          Callback={(e)=>{if(handleSelectedFilterPersonType){handleSelectedFilterPersonType(e)}}}
                                        />
                                    </div>
                                    :null
                        }

                            </div>
                         </OverlayTrigger>  

                         <OverlayTrigger
                                    key={"finish"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"finish"}`}>
                                            {trans("finish")}
                                        </Tooltip>
                                    }
                                >

                            <div className="py-1 py-lg-0">
                            {finish ? (
                                   <ButtonToolbar id={id} key={"F11"}
                                     faIcon={[ICON_TYPE, "flag-checkered"]} text="Finish" hotKey={shortcutList["finish"]} onClick={() => { handleClickToolbar("finish") }} />
                                 ) : null}
                            </div>                               
                         </OverlayTrigger>  

                         <OverlayTrigger
                                    key={"reverse"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"reverse"}`}>
                                            {trans("reverse")}
                                        </Tooltip>
                                    }
                                >

                        <div className="py-1 py-lg-0">
                             {reverse ? (
                               <ButtonToolbar id={id} key={shortcutList["Archive"]}
                                 faIcon={[ICON_TYPE, "reply"]} text="reverse" hotKey={shortcutList["reverse"]} onClick={() => { handleClickToolbar("reverse") }} />
                             ) : null}
                           </div>                             
                         </OverlayTrigger>
                         <OverlayTrigger
                                    key={"reference"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"reference"}`}>
                                            {trans("reference")}
                                        </Tooltip>
                                    }
                                >
                             <div className="py-1 py-lg-0">
                                 {reference ? (
                                     <ButtonToolbar id={id} hotKey={shortcutList["reference"]}  key={"F8"}
                                         faIcon={[ICON_TYPE, "share-square"]} text="Reference"  onClick={() =>{  handleClickToolbar("reference") } } />
                                 ) : null}
                             </div>            
                         </OverlayTrigger>    
                         <OverlayTrigger
                                    key={"specialreference"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"specialreference"}`}>
                                            {trans("specialreference")}
                                        </Tooltip>
                                    }
                                >
                             <div className="py-1 py-lg-0">
                                 {!emptyList && specialreference ? (
                                     <ButtonToolbar id={id} hotKey={shortcutList["specialreference"]}  key={"F8"}
                                         faIcon={[ICON_TYPE, "share-square"]} text="specialreference"  onClick={() =>{  handleClickToolbar("specialreference") } } />
                                 ) : null}
                             </div>            
                         </OverlayTrigger>    
                         <OverlayTrigger
                                    key={"cancel"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"cancel"}`}>
                                            {trans("cancel")}
                                        </Tooltip>
                                    }
                                >

                        <div className="py-1 py-lg-0">
                             {cancel ? (
                               <ButtonToolbar id={id} key={shortcutList["Archive"]}
                                 faIcon={[ICON_TYPE, "xmark"]} text="cancel" hotKey={shortcutList["cancel"]} onClick={() => { handleClickToolbar("cancel") }} />
                             ) : null}
                           </div>                             
                         </OverlayTrigger>  

                         <OverlayTrigger
                                    key={"Current"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"Current"}`}>
                                            {trans("Current")}
                                        </Tooltip>
                                    }
                                >
                          <div className="py-1 py-lg-0">
                           {current ? (
                             <ButtonToolbar id={id} key={"F11 "}
                               faIcon={[ICON_TYPE, "undo"]} text="Current" hotKey={shortcutList["current"]} onClick={() => { handleClickToolbar("current") }} />
                           ) : null}
                         </div>                        
                         </OverlayTrigger>  
                         <OverlayTrigger
                                    key={"Archive"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"Archive"}`}>
                                            {trans("Archive")}
                                        </Tooltip>
                                    }
                                >
                          <div className="py-1 py-lg-0">
                            {archive ? (
                              <ButtonToolbar id={id} key={shortcutList["Archive"]}
                                faIcon={[ICON_TYPE, "file-archive"]} text="Archive" hotKey={shortcutList["archive"]} onClick={() => { handleClickToolbar("archive") }} />
                            ) : null}
                          </div>                   
                         </OverlayTrigger>  
                         <OverlayTrigger
                               key={"archive2"}
                               placement="top"
                               delay={{"show" : 600 , "hide":0}}
                               overlay={
                                   <Tooltip className="tooltip" id={`button-tooltip-${"archive"}`}>
                                       {trans("archive")}
                                   </Tooltip>
                               }
                           >
                            <div className="py-1 py-lg-0">
                              {handleSelectedArchive ? (
                                <ButtonToolbarArchiveFile 
                                     Callback={(e)=>{handleSelectedArchive && handleSelectedArchive(e)}} />
                              ) : null}
                            </div>                   
                          </OverlayTrigger>   
                         <OverlayTrigger
                                    key={"delete"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"delete"}`}>
                                            {trans("delete")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">
                                {!emptyList && remove ? (
                                    <ButtonToolbar isFocos={isFocos} id={id} hotKey={shortcutList["delete"]} faIcon={[ICON_TYPE, 'trash']} text="delete" onClick={() => { handleClickToolbar("remove"); }} />
                                ) : null}
                            </div>        
                         </OverlayTrigger>  
                         <OverlayTrigger
                                    key={"refresh"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"refresh"}`}>
                                            {trans("refresh")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">
                                { refresh  ? (
                                    <ButtonToolbar id={id} hotKey={shortcutList["refresh"]} faIcon={[ICON_TYPE, "sync"]} text="Refresh" onClick={() => { handleClickToolbar("refresh") }} />
                                ) : null}
                            </div>      
                         </OverlayTrigger>  
                         <OverlayTrigger
                                    key={"create1"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"create1"}`}>
                                            {trans("create")}
                                        </Tooltip>
                                    }
                                >

                          <div className="py-1 py-lg-0">
                            {handleSelectedStartingProcessToolbar ? (
                              <ButtonToolbarProcessStarting key={"F4"}
                              Callback={handleSelectedLetterProcessStarting}
                              />
                            ) : null}
                          </div>
                         </OverlayTrigger> 
                         <>
                         </>
                         <OverlayTrigger
                                    key={"create2"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"create2"}`}>
                                            {trans("create")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">
                            {handleSelectedMyOwnRequestStartForm ? (
                                  <ButtonToolbarMyOwnRequest 
                                  key={""} 
                                  Callback={(e)=>{if(handleSelectedMyOwnRequestStartForm){handleSelectedMyOwnRequestStartForm(e)}} }
                                />
                                 ) : null}
                            </div>                               
                         </OverlayTrigger>  
                         <OverlayTrigger
                                    key={"create7"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"create2"}`}>
                                            {trans("create")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">
                            {handleSelectedStartProcess ? (
                                  <ButtonToolbarStartProcess 
                                  key={""} 
                                  Callback={(e)=>{if(handleSelectedStartProcess){handleSelectedStartProcess(e)}} }
                                />
                                 ) : null}
                            </div>                               
                         </OverlayTrigger>  
                         <></>
                    <OverlayTrigger
                                    key={"create3"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"create3"}`}>
                                            {trans("create")}
                                        </Tooltip>
                                    }
                                >

                            <div className="py-1 py-lg-0">
                            {handleSelectedHolidaysType ? (
                                  <ButtonToolbarHolidaysType 
                                  key={"create"} 
                                  Callback={(e)=>{if(handleSelectedHolidaysType){handleSelectedHolidaysType(e)}} }
                                />
                                 ) : null}
                            </div>                               
                         </OverlayTrigger> 
                         <></>
                         <OverlayTrigger
                                    key={"create4"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"create4"}`}>
                                            {trans("create")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">
                                {create ? (
                                    <ButtonToolbar id={id} hotKey={shortcutList["create"]} faIcon={[ICON_TYPE, "plus"]} text="Create" onClick={() => { handleClickToolbar("create") }} />
                                ) : null}
                            </div>            
                         </OverlayTrigger> 
                         <OverlayTrigger
                                    key={"create5"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"create5"}`}>
                                            {trans("create")}
                                        </Tooltip>
                                    }
                                >

                            <div className="py-1 py-lg-0">
                            {handleSelectedcreateUser ? (
                                  <ButtonToolbarCreateUser 
                                  key={"create"} 
                                  Callback={(e)=>{if(handleSelectedcreateUser){handleSelectedcreateUser(e)}} }
                                />
                                 ) : null}
                            </div>            
                         </OverlayTrigger> 
                         <OverlayTrigger
                                    key={"create6"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"create5"}`}>
                                            {trans("create")}
                                        </Tooltip>
                                    }
                                >

                            <div className="py-1 py-lg-0">
                            {handleCreateArchive ? (
                                  <ButtonToolbarCreateArchive 
                                  key={"create"} 
                                  Callback={(e)=>{if(handleCreateArchive){handleCreateArchive(e)}} }
                                />
                                 ) : null}
                            </div>            
                         </OverlayTrigger> 
                         <OverlayTrigger
                                    key={"view"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"view"}`}>
                                            {trans("view")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">
                                {!emptyList && view? (
                                    <ButtonToolbar id={id} hotKey={shortcutList["view"]} faIcon={[ICON_TYPE, "eye"]} text="View" onClick={() => {  handleClickToolbar("view")   }} />
                                ) : null}
                            </div>            
                         </OverlayTrigger>  
                         <OverlayTrigger
                                    key={"edit"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"edit"}`}>
                                            {trans("edit")}
                                        </Tooltip>
                                    }
                                >

                             <div className="py-1 py-lg-0">
                                 {!emptyList && edit ? (
                                     <ButtonToolbar id={id} hotKey={shortcutList["edit"]}  faIcon={[ICON_TYPE, "edit"]} text="Edit" onClick={() => {  handleClickToolbar("edit")   }} />
                                 ) : null}
                             </div>           
                         </OverlayTrigger> 
                         <OverlayTrigger
                                    key={"help"}
                                    placement="top"
                                    delay={{"show" : 600 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"help"}`}>
                                            {trans("help")}
                                        </Tooltip>
                                    }
                                >
                            <div className="py-1 py-lg-0">
                                <ButtonToolbar  id={id} hotKey={shortcutList["help"]} faIcon={[ICON_TYPE, "question"]} text="Help" onClick={() => { handleClickToolbar("help") }} />
                            </div>         
                         </OverlayTrigger>  

                    </div>
                </div>
            </div>

        </section>
        </div>
       
    );
}