import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getAllItemMainPropertyValues = (pageNum, pageSize , searchString, orderBy ,filterlist,id) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.ItemMainPropertyValues.list,
   method: "get",
   params: {
       SearchString: searchString,
       OrderByString: orderBy,
       FilterString:filterlist,
       PageSize: pageSize,
       PageNumber: pageNum,
       id:id
   }
}).then((res) => {
   return res.data
}).catch((err) => {
   return Promise.reject(err.response  );
});
