import React, { useEffect, useState } from "react";
import { SelectBox } from "components/Select/Select";
import { getCustomerGeneralRequestClassification } from "./api";


interface GeneralRequestsClassificationIdProp {
    value?: any
    mode?: string
    resetForm?: boolean
    name: string
    Change?: (e) => void,
}
export const GeneralRequestsClassificationId = ({ value, name , mode ,resetForm ,Change }:GeneralRequestsClassificationIdProp) => {
    const [GeneralRequestsClassification, setGeneralRequestsClassification] = useState();
    const [defaultval, setdefault] = useState(value);


    let job_classification_val, job_classification;
    useEffect(() => {
        getCustomerGeneralRequestClassification().then((response) => setGeneralRequestsClassification(response.Data));
        return () => {
     
            setdefault("")
          };
       
    }, []);
    useEffect(() => {
        setdefault(value)
    }, [resetForm]);
    if (GeneralRequestsClassification) job_classification = Object.values(GeneralRequestsClassification);
    if (GeneralRequestsClassification) job_classification_val = Object.keys(GeneralRequestsClassification);

    
    return (
        <>
        <SelectBox
            onChange={()=>{Change && Change(true)}}
            lable="requestclassification"
            selectType="select"
            options={job_classification}
            val={job_classification_val}
            name={name}
            value={value}
            resetForm={resetForm}
            mode={mode}
        />    
        <div className="text-box__msg-wrapper"></div>    
        </>

    );
};