import { DYNAMICFORMS_URL } from "utils/constants";
import { ENDPOINTSDYNAMICFORMS } from "utils/endpoints";
import { http } from "utils/http";
export const postDataCollection = (data,method) => http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.dataCollection,
    method: method,
 data
}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});

export const deleteDataCollection = (id) => http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.dataCollection,
    method: 'delete',
params:{
    id
}
}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});




export const deleteDataCollectionProperty = (id) => http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.dataCollectionProperties,
    method: 'delete',
params:{
    id
}
}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});


export const postPropertyDataCollection = (data,method) => http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.dataCollectionProperties,
    method: method,
 data
}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});



export const getDataCollection = (async (
    pageNum,  pageSize ,SearchString
) => await http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.dataCollection,
    method: 'get',
    params:{

         PageNumber: pageNum,
         PageSize: pageSize,
         SearchString
       
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)



export const getDataCollectionById = (async (
id) => await http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.dataCollection,
    method: 'get',
    params:{

         id
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getDataCollectionProperties = (async (
    pageNum,  pageSize ,DynamicFormPropertyId
) => await http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.dataCollectionProperties,
    method: 'get',
    params:{

         PageNumber: pageNum,
         PageSize: pageSize,
         DynamicFormPropertyId
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)