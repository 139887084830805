
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";
import { httpFile } from "utils/httpFile";

export const sendFileList = (data) => httpFile({
    baseURL:process.env.REACT_APP_CHAT_SERVICE_URL,
    url: ENDPOINTS.messageHub.load,
    method: 'post',
    data: data,
    headers: {
        "content-type": "multipart/form-data",
        Accept: "*/*",

    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

export const getFile = (id ) => httpFile({
    baseURL:process.env.REACT_APP_CHAT_SERVICE_URL,
    url: ENDPOINTS.messageHub.browse,   
    method: 'get',
    params:{
        fileId  : id
    },
    responseType: "blob"
  
}).then((res) => {
    return  blobToBase64(res.data)
}).catch((err) => {
    return Promise.reject(err);
});
export const getTokenFile = (id ) => httpFile({
    baseURL:process.env.REACT_APP_CHAT_SERVICE_URL,
    url: ENDPOINTS.messageHub.tokenFile ,   
    method: 'get',
    params:{
        fileId  : id
    },
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const getUsersasync = (str) => http({
    url: ENDPOINTS.BaseInformation.person.chatUserList,
    method: 'get',
    params: {
        pageSize: 50,
        searchString : str 
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getCustomersUsersasync = (str) => http({
    url: ENDPOINTS.clientPortal.chat,
    method: 'get',
    params: {
        pageSize: 50,
        searchString : str 
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
