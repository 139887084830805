
import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { getMyOwnInvoice } from "../MyOwnInvoice/api";
import { ModalForm } from "components/ModalForm/ModalForm";
import { CustomerSalesOrder } from "../ECommerce/components/Cart/components/CustomerSalesOrder";
import { SalesInvoiceForm } from "pages/InventoryAndSales/Sales/SalesInvoice/SalesInvoiceForm";
interface MyOwnInvoiceProp{
    curentTab?:string;
    Type?:any;
    tabName?:string;
  }

export const MyOwnInvoice = ({curentTab, Type ,tabName}:MyOwnInvoiceProp) => {

    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<any>([]);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [showForm, setShowForm] = useState(false);
    const [mode, setMode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const [jobstatusFilter, setJobstatusFilter] = useState<any>(0);
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [filterlist, setFilterlist] = useState<any>("");

    const [showAttachment, setShowAttachment] = useState(false);
    const [pages, setPages] = useState<number>(0);

 



    const searchHandler = (str) => {
        setPageNum(1);
        if (str == "") {
            setSearch(null)
            getMyOwnInvoiceAsync(1, null)
        } else {
            setSearch(str)
            getMyOwnInvoiceAsync(1 , str)
        }
    }
    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("MyOwnInvoice", e.currentTarget.value)
        getMyOwnInvoiceAsync(1)
    };
    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else {setPageNum(+value);getMyOwnInvoiceAsync(+value)}
        } 
    };
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getMyOwnInvoiceAsync(pageNum + 1);
        };
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getMyOwnInvoiceAsync(pageNum - 1);
        };
    };

    const selectedObjCallback = (obj) => {
        setSelectedObj(obj);
    }


    const handleCreate = () => {
        setMode("create")
        setShowForm(true)

    }
    const handleViewRow = () => {
        setMode("view");
        setShowForm(true)
    }
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel": downloadCSV(response ,4 ,trans("MyOwnInvoice")); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "view": if (selectedObj) handleViewRow(); break;
            case "help": alert("call api help"); break;
            case "create": handleCreate(); break;
            case "attach": if (selectedObj) {
                setMode("edit")
                setShowAttachment(true)
            } else {
                Toast(trans("Msg_SelectRow"), "warning")
            } break;
        }
    };

    async function getMyOwnInvoiceAsync(pagNum = pageNum ,searchSTR=search) {
        setLoading(false)

        let pageSizeChangedByUser = getPageSize("MyOwnInvoice")
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getMyOwnInvoice(pagNum, pageSizeChangedByUser, searchSTR, orderBy.length == 0 ? null : orderBy.join(","), filterlist, Type)
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            if (res.Data.Data.length > 0) {
                setResponse(res.Data)
            }
        } catch (error) {

        }
        setLoading(true)

    }

    useEffect(() => {
        //getDataAsync();setResponse
          if(tabName==curentTab){

              setSelectedObj(null)
              setResponse([])
              getMyOwnInvoiceAsync();
            }
    }, [ jobstatusFilter, orderBy, filterlist]);
    useEffect(() => {
        //getDataAsync();setResponse
          if(tabName==curentTab){

              setResponse([])
              getMyOwnInvoiceAsync();
            }
    }, [ forceUpdate]);

    useEffect(() => {
        if(tabName==curentTab){
            let settings = getGridSetting("MyOwnInvoice")
            setOrderBy(settings[1])
            setFilterlist(settings[0])
        }
    }, [curentTab ,tabName]);


    return (
        <>
        {tabName==curentTab&&
        <>

            { showForm &&
              <ModalForm title={"orderForm"} onHide={() => setShowForm(false)} show={showForm}>
                <SalesInvoiceForm  mode={mode} jobId={selectedObj?.JobId} onHide={() => { setShowForm(false); setForceUpdate(!forceUpdate); } } relod={()=>{} }  />
              </ModalForm>
            }



        <Toolbar create={false} defultSerach={search} attach={false} handleSearch={searchHandler}   id={selectedObj} emptyList={response?.TotalRecords == 0} search={true} handleClickToolbar={clickToolbarCallback} />
        <div style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}} className="col-lg-12 mt-0">
            {loading ?
                <>{
                    (response?.Data?.length > 0 ?
                        <div className="card">
                            <div className="card-body p-0">
                                <>
                                    <DataGrid
                                        DoubleClick={(obj) => { setSelectedObj(obj); clickToolbarCallback("view") }}
                                        View={true}
                                        ViewParametr={"NotSeen"}
                                        handlefiltering={(str) => { setFilterlist(str) }}
                                        filterStr={filterlist}
                                        orderFormat={setOrderBy}
                                        orderFormatList={orderBy}
                                        parentName={"MyOwnInvoice"}
                                        theadData={Object.keys(response.Data[0])}
                                        tbodyData={response.Data}
                                        minCellWidth={80}
                                        rowSelected={selectedObj}
                                        // handleAttachment={(e) => { setSelectedObj({ Id: e }); setMode("edit"); setShowAttachment(true) }}
                                        selectedRowObjectCallback={selectedObjCallback}
                                        startIndex={2}
                                        handleView={handleViewRow}
                                        totalRecords={response.TotalRecords}
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        HandleNextPage={handleNextPage}
                                        HandlePrevPage={handlePrevPage}
                                        handlechangePageSize={handleChangePageSize}
                                        first={() =>{getMyOwnInvoiceAsync(pages) ;setPageNum(pages)}}
                                        end={() => {getMyOwnInvoiceAsync(1) ;setPageNum(1)}}
                                        handleGoPage={handleGoPage}
                                    />
                                </>
                            </div>
                        </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    )

                }
                </>
                : <Loading />
            }

        </div>

        </>
        }
        
        </>

    );
}