import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import iranFlag from "assets/image/iranFlag.png";
// import { Person } from "pages/BaseInformationApi/Person/types";
import { Main } from "./components/Main/Main";
import { Sidebar } from "./components/Sidebar/Sidebar";
import { LayoutContext, LAYOUT_CONTEXT_INIT_STATE } from "./context/LayoutContext";
import { ApplicationDTO, UserPosition } from "types/types";
import './Styles.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



export const DashboardLayout: React.FC = ({ children }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(!window.location.href.includes("message")?(localStorage.getItem("openSidebar")!="false"):false);

  const [img, setImg] = useState(iranFlag);
  const [lang, setLang] = useState<any>("fa");
  const [currentUserPosition, setCurrentUserPosition] = useState<any>({ Description: "", Id: "" });
  const [UserPerson, setUserPerson] = useState<any>();
  const [notificount, setNotificount] = useState<number>(0);
  const [widgetFormFlag, setWidgetFormFlag] = useState<boolean>(false);
  const [chatNotificount, setChatNotificount] = useState<any>(0);
  const [resourceVersion, setResourceVersion] = useState<any>(0);
  const [tabId, setTabId] = useState<any>();
  const [currentApplication, setCurrentApplication] = useState<ApplicationDTO | any>();
  const [env, setEnv] = useState<any>();
  const [dragObj, setDragObj] = useState<any>();
  const [dropObj, setDropObj] = useState<any>();
  const [basket, setBasket] = useState<any>(false);
  const [dragBoxPo, setDragBoxPo] = useState<any>([]);
  const dragBox = useRef(null);
  const [err, setErr] = useState(false);
  let layoutContext = useContext(LayoutContext);
  const [emptyBasket,setEmptyBasket]=useState(false)


  const checkKeyDown = (e) => {
    if (e.key === 'Enter' && document.activeElement?.nodeName != "BUTTON" && document.activeElement?.nodeName != "TEXTAREA" && !document.activeElement?.className.includes("filterInput")){
     e.preventDefault();
  }else{
    }
  };

  useEffect(() => {
    let langueg = localStorage.getItem("lang");
    if (langueg == null) {
      localStorage.setItem("lang", lang);

    }
    if (langueg == "en") document.body.classList.add("ltr");
    else document.body.classList.remove("ltr");
  }, [localStorage.getItem("lang")]);

//-------------------drag & drop--------------------------
  useEffect(() => {
      if (dropObj?.Id && dragObj?.Id && (dropObj.Id !== dragObj.Id)) {
      } else {

        if (dragBox.current) {
          (dragBox.current as HTMLElement).style.display = "none"
        }
      }
      window.removeEventListener('mousemove', mouseDragMove);
      window.removeEventListener('mouseup', removeDragListeners);
    setDropObj(null)
    setDragObj(null)
  }, [dropObj]);
  
  const mouseDragMove = useCallback((e) => {
    e.stopImmediatePropagation();
      if (e && e.target && e.target.className && e.target.className.length > 0 && (!e.target.className.includes("table-tree") && !e.target.className.includes("dropable") )) {
        setErr(true)
      } else {
        setErr(false)
      }
      // Return an array of px values
      setDragBoxPo([e.pageX, e.pageY])
      if (dragObj?.Id) {
        // dragBox?.current?.style?.display = "block"
        if (dragBox.current) {
          (dragBox.current as HTMLElement).style.display = "flex"
        }
      }
      if(dragObj == null){
        window.removeEventListener('mousemove', mouseDragMove);
        window.removeEventListener('mouseup', removeDragListeners);
      }
    
  }, [dragObj]);

  const removeDragListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseDragMove);
    window.removeEventListener('mouseup', removeDragListeners);
  }, [mouseDragMove]);
  const mouseDragUp = useCallback((e) => {
    e.stopPropagation();
    if (dragBox.current) {
      (dragBox.current as HTMLElement).style.display = "none"
      setDropObj(null)
      setDragObj(null)
    }

    removeDragListeners();
  }, [setDragObj, removeDragListeners]);

  useEffect(() => {
    if (dragObj != null) {
      window.addEventListener('mousemove', mouseDragMove);
      window.addEventListener('mouseup', mouseDragUp);
    }
    return () => {

      removeDragListeners();

    }
  }, [mouseDragMove, mouseDragUp]);




















  return (
    <LayoutContext.Provider
      value={{
        ...LAYOUT_CONTEXT_INIT_STATE,
        lang: (localStorage.getItem("lang") as any) || "fa",
        setLang: (lang: any) => setLang(lang),
        sidebarIsOpen,
        toggleSidebar: () => {localStorage.setItem("openSidebar" , !sidebarIsOpen+"") ;setSidebarIsOpen(!sidebarIsOpen)},
        ImgFlag: img,
        handleImgFlag: (IMG: string) => setImg(IMG),
        currentUserPosition: currentUserPosition,
        setCurrentUserPosition: (UserPosition: UserPosition) => setCurrentUserPosition(UserPosition),
        setUserPerson: (id) => setUserPerson(id),
        UserPerson:UserPerson,
        notificount: notificount,
        widgetFormFlag: widgetFormFlag,
        tabId: tabId,
        setNotificount: (count) => setNotificount(count),
        setTabId: (count) => setTabId(count),
        setWidgetFormFlag: (count) => setWidgetFormFlag(count),
        chatNotificount: chatNotificount,
        setChatNotificount: (count) => setChatNotificount(count),
        setResourceVersion: (count) => setResourceVersion(count),
        resourceVersion: resourceVersion,
        currentApplication: currentApplication,
        setCurrentApplication: (application: ApplicationDTO) => setCurrentApplication(application),
        dragObj: dragObj,
        dropObj: dropObj,
        env: env,
        setEnv: (obj: any) => setEnv(obj),
        setDragObj: (obj: any) => setDragObj(obj),
        setDropObj: (obj: any) => setDropObj(obj),
        setBasket: () => setBasket(!basket),
        basket:basket,
        setEmptyBasket:(e)=>setEmptyBasket(e),
        emptyBasket:emptyBasket
      }}
    >
      <div   onDrop={(e)=>{return false}}  onKeyDown={(e) => checkKeyDown(e)} id="DashboardLayout" className="DashboardLayout">
        {(dragObj && dragObj.Id)&& 
          <div style={{ left: `${dragBoxPo[0] - 100}px`, top: `${dragBoxPo[1]-10 }px` , zIndex:99999 }} ref={dragBox} className="dragBox" > {(err || !dragObj?.accessDrop) ? <FontAwesomeIcon icon={['fas', "ban"]} className="text-danger mb-3" /> : <p> {(dragObj )&& dragObj.Description}</p>} </div>
        }

        <section className="d-flex" style={{ border: "0px solid black" }}>
           <Sidebar /> 
          
          <Main>
            {/* <Toolbar creat={true} info={true} refresh={true} search={true} /> */}
           {/*  <div className="mainContent"> */}
              {children}
             {/*  </div> */}
          </Main>
        </section>
      </div>
    </LayoutContext.Provider>
  );
};
