import { Button } from "components/Button/Button";
import { FileInput } from "components/FileInput/FileInput";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { sendPersonSignatureApi } from "../api";


export const PersonSignatureForm = ({ Cancelcallback, reset }) => {
  const layoutContext = useContext(LayoutContext);

  const [resetForm, setResetForm] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>();


  async function sendPostLevelAsync(Signature, method) {
    try {
      const res = await sendPersonSignatureApi(Signature, method);
      Cancelcallback(false);
      reset(true);
    } catch (error) {}
  }

  const makeSignature = (e) => {
    e.preventDefault();

    let Signature = {
      PersonId: layoutContext.UserPerson.PersonId,
      IsDefault: true,
      ActivityStatus: 1,
    };
    let _objData = new FormData();
    _objData.append("PersonSignature", JSON.stringify(Signature));
    _objData.append("Sign", selectedImage);
    sendPostLevelAsync(_objData, "post");
  };




  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  const changeHandlePic = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };



  return (
    <>
      <form className="card" onSubmit={makeSignature}>
        <div className="card-body">
          <div className="col-6 ">
            <FileInput
              // file={selectedImage ? {fileName:selectedImage.name, src:selectedImage64} : null}
              hideSrc={selectedImage ? false : true}
              lable="PersonSignatureForm"
              name="PersonSignatureForm"
              onChange={changeHandlePic}
              src={selectedImage ? URL.createObjectURL(selectedImage) : ""}
            />
          </div>

         
            <div className="col-lg-12 mt-1 btns_form">
              <Button value="Save" btnType="submit" />
              <Button
                btnType="cancel"
                value="Cancel"
                onClick={() => {
                  Cancelcallback(false);
                }}
              />
            </div>
          
        </div>
      </form>
    </>
  );
};
