import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext"
import { useContext, useEffect, useRef, useState } from "react"
import { ICON_TYPE } from "utils/constants"
import { trans } from "utils/helpers"
import { getProcessStarting } from "./api"
import "./style.scss"
interface ButtonToolbarProcessStartingProps {
    onClick?: () => void,
    Callback: (ans: any) => void,
    positionId?: string,
    text: string,
    hotKey?:string,
    id:any
}
export const ButtonToolbarProcessStarting: React.FC<ButtonToolbarProcessStartingProps> = ({id , onClick,hotKey,text, Callback, positionId }) => {
    const [posId, setPosId] = useState(positionId);
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    const buttonToolbarProcessStarting = useRef<any>(null);
    const listOption = useRef<any>()
    const [showSubMenu, setShowSubMenu] = useState(false);
    const layoutContext = useContext(LayoutContext);
    const [options, setOptions] = useState<any>()
    const[directionLiList,setDirectionLiList] = useState<any>(false)
    const buttonKey = useRef(hotKey);

    async function getDataAsync() {
        try {
            const response = await getProcessStarting( layoutContext?.currentUserPosition?.Id  );
            setOptions(response.Data);
        } catch (error) {
            setOptions(null)
        }

    }

    const [storage, setStorage] = useState<any[]>([]);

    // window.addEventListener('keydown', function (e) {
        
    //     e.stopPropagation();
    // });

    useEffect(() => {
        if (localStorage.Shortcut != null) {
            setStorage(JSON.parse(localStorage.Shortcut));
            Object.keys(JSON.parse(localStorage.Shortcut)).forEach(item=>{
                if(item == text.toLowerCase()){
                    buttonKey.current = JSON.parse(localStorage.Shortcut)[item]
                }
            })


        }

    }, [localStorage.Shortcut]);
   
    useEffect(() => {

    }, [storage]);
   

   useEffect(() => {
  
       let flag = true
       const callback = (event: KeyboardEvent) => {
            event.stopPropagation()
             if((buttonKey.current == event.key )||(text.toLowerCase()=="create"&&event.key=="Insert") ){              
                if(buttonToolbarProcessStarting.current){

                    buttonToolbarProcessStarting.current.focus()
                }
               event.returnValue = false;
               return false
     
             }  
           flag = true
       };
       document.addEventListener('keydown', callback);
       return () => {
           document.removeEventListener('keydown', callback);
       };
   }, [id]);
    useEffect(() => {
        getDataAsync();
    }, [layoutContext.currentUserPosition , layoutContext.currentApplication]);

    function showSubMenuFunction() {
        setShowSubMenu(true)
    }
    function hideSubMenuFunction() {
       // setShowSubMenu(false)
    }
    function selectboxValSetter(event) {
        event.stopPropagation()
        event.preventDefault()

        setShowSubMenu(false)
        Callback && Callback(event.currentTarget.getAttribute('data-value'));
        if (buttonToolbarProcessStarting.current) (buttonToolbarProcessStarting.current as HTMLButtonElement).blur();
    }
    function offset(){
        var rect = buttonToolbarProcessStarting.current.getBoundingClientRect()
        const windowInnerWidth  = window.innerWidth
        const listOptionWidth = listOption.current.getBoundingClientRect()
        if(listOptionWidth.width < windowInnerWidth - rect.right){
            setDirectionLiList(false)
        }else{
            setDirectionLiList(true)
        }
        buttonToolbarProcessStarting.current.focus()
    }

    function clickToclose(e) {
       if (!buttonToolbarProcessStarting.current?.contains(e.target)) {
           setShowSubMenu(false);
       }
      }
    useEffect(() => {
        if (showSubMenu) {
          document.body.addEventListener("click", clickToclose, true);
        }
        return () => {
          document.body.removeEventListener("click", clickToclose, true);
        };
      }, [showSubMenu]);
    return (
        <> {options && options?.length > 0 && <div className="toolbar-btn-wrapper">
            <button onClick={offset} ref={buttonToolbarProcessStarting} onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction} className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
                <div className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>F4</div>
                <FontAwesomeIcon icon={[ICON_TYPE, "plus"]} width="20px" height="20px" />
                <small>{trans("Create")}</small>
                <div ref={listOption} className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`} style={{left:directionLiList ?'-165px' :'0'}}>
                    {options && options.map((option, index) => (
                        <div key={index} className="toolbar-btn-sub-menu__option" data-value={option.Id}  onClick={selectboxValSetter}>{option.Description}</div>
                    ))}
                </div>
            </button>
        </div>
        }
        </>
    )
}