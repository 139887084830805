import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getLetterList = (async (pageNumber ,pageSize , searchString ,orderBy  , secretariatId="",filterlist) => await http({
    url: ENDPOINTS.secretariat.indicator.browse,
    method: 'get',
    params: {
        PageNumber : pageNumber,
        PageSize : pageSize,
        SearchString : searchString,
        OrderByString : orderBy,
        SecretariatId : secretariatId,
        FilterString: filterlist


    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getChooseRelatedList = (async (pageNumber ,pageSize , searchString ,orderBy  , letterId="",filterlist) => await http({
    url: ENDPOINTS.secretariat.indicator.chooseRelated,
    method: 'get',
    params: {
        PageNumber : pageNumber,
        PageSize : pageSize,
        SearchString : searchString,
        OrderByString : orderBy,
        letterId : letterId,
        FilterString: filterlist
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const getRelatedList = (async (pageNumber ,pageSize ,letterId) => await http({
    url: ENDPOINTS.secretariat.indicator.related,
    method: 'get',
    params: {
        PageNumber : pageNumber,
        PageSize : pageSize,
        LetterId : letterId

    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getLetter = (async (id) => await http({
    url: ENDPOINTS.secretariat.indicator.loadLetter,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
        LetterId : id

    }
}).then((res) => {
    return res.data
}).catch((err) => {

    return Promise.reject( err);
})

)

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

export const loadAttachmentViewerBase64 = (attachId ) => http({
    url:ENDPOINTS.cartable.attachmentFile,
    method: 'get',
    params: {
        attachId: attachId
    },
    responseType: "blob"
}).then(async(response) => {

    return blobToBase64(response.data)
}).catch((err) => {
  //   
    return Promise.reject( err);
});




export const getLetterByReferenceId = (async (referenceId) => await http({
    url: ENDPOINTS.secretariat.letter.reference,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
        referenceId : referenceId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
})

)

export const getDefaultFileAttachment = (letterId ) => http({
    url: ENDPOINTS.secretariat.letter.defaultFileAttachment,
    method: 'get',
    params: {
        ownerId : letterId,

    },
    responseType: "blob"

}).then(response => {    
    return response.data.stream().getReader().read().then(res => res.value);
}

).catch((err) => {

    return Promise.reject(err);
});


export const postLetter = (async (letter , processId ,referenceId) => await http({
    url: ENDPOINTS.secretariat.letter.letter, 
    method: "post",
    data:letter,
    params:{
        referenceId : referenceId,
        PRJ_UID:processId

    }

}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const putLetter = (async (letter ,referenceId) => await http({
    url: ENDPOINTS.secretariat.letter.letter,
    method: "put",
    data:letter,
    params:{
        referenceId :referenceId
    }


}).then((res) => {

    return res
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getSecretariatNo = (async (id ,letterType) => await http({
    url: ENDPOINTS.secretariat.indicator.secretariatNo,
    method: "get",
    headers: { "Accept-Language": "en" },
    params:{
        secretariatId : id,
        letterType :letterType
    }
}).then((res) => {

    return res
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const addToIndicator = (async (indicator ,method) => await http({
    url: ENDPOINTS.secretariat.indicator.indicator,
    method: method,
    data: indicator
}).then((res) => {

    return res
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getSignature = (async (positionId , jobId ) => await http({
    url: ENDPOINTS.secretariat.letterSignature.browse,
    method: "get",
    params:{
        positionId : positionId ,
        jobId : jobId
    }
}).then((res) => {

    return res
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const sendSignature = (async (data , method ) => await http({
    url: ENDPOINTS.secretariat.letterSignature.browse,
    method: method,
    data:data
}).then((res) => {

    return res
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getReceiverlist = (async (id ) => await http({
    url: ENDPOINTS.secretariat.letterReceiver.browse,
    method: "get",
    params:{
        letterId:id
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)



export const getReceivers = ( id) => http({
    url: ENDPOINTS.secretariat.letterReceiver.receiverDictionary,
    method: "get",
    params:{
        jobId:id
    }
    
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});

export const getSentHistory = ( id) => http({
    url: ENDPOINTS.secretariat.letterSent.browse,
    method: "get",
    params:{
        jobId:id
    }
    
}).then((res) => {
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});

export const getDataForPrint = ( id) => http({
    url: ENDPOINTS.secretariat.indicator.PrintData,
    method: "get",
    params:{
        letterId:id,
        useWarmSignature: true
    }
    
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});

