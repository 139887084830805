import { DataGrid } from "components/DataGrid/DataGrid";
import { Toast } from "components/ToastShow/ToastShow";
import { Footerbar } from "components/Footerbar/Footerbar";
import { Headerbar } from "components/Headerbar/Headerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { deleteAction, getActions } from "./api/api";
import { ActionForm } from "./components/ActionForm/ActionForm";
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment";


export const Actions = ({ taskId, onHide, handleShowReferenceStepper , styleCondition }: any) => {

    let gridColumns, gridData = [];
    let headerData, footerData;
    let Total_records, pages;
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedId, setSelectedId] = useState<string | any>(null);
    const [showActionForm, setShowActionForm] = useState(false);
    const [mode, setmode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [showattachment, setshowAttachment] = useState(false);
    const [endpoint, setEndpoint] = useState<any>();
    /* #endregion */
    /* #region pagination */

    const handlechangePageSize = (e: any) => {
        setPageSize(e.currentTarget.value);
        setPageNum(1);
        getPageSize("Actions" ,e.currentTarget.value)
        getReferenceActionsAsync();
    };
    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else setPageNum(value);
        } else {
        }
    };
    const HandleNextPage = () => {
        if (pageNum < pages) setPageNum((pageNum) => { return +pageNum + 1 });
    };
    const HandlePrevPage = () => {
        if (pageNum > 1) setPageNum((pageNum) => { return +pageNum - 1 });
    };
    /* #endregion */
    /* #region dataGridActions */
    const handleCreateAction = () => {
        setmode("create")
        setShowActionForm(true);
    }
    const handleEditRow = () => {
        setmode("edit")
        setShowActionForm(true);
    }
    const handleDeleteRow = async() => {
        try {
            const res  = await deleteAction(selectedId)
            setForceUpdate(!forceUpdate)
       
        } catch (error) {
            
        }
    }
    const handleViewRow = () => {
        setmode("view")
        setShowActionForm(true);
    }
    const handleAttachmentRow = (id) => {
        setSelectedId(id)
        setEndpoint( ENDPOINTS.personalPlanningApi.personalAction.Attachment)
        setmode("attach")    
        setshowAttachment(true)
    }

    const handleAttachment = () => {
        setEndpoint(ENDPOINTS.personalPlanningApi.personalAction.Attachment)
        setSelectedId(taskId)
        setmode("allattach")
        setshowAttachment(true)
    }
        
    const selectedIdCallback = (obj, index) => {
                
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedId(obj.Id);sessionStorage.setItem("changingforms" , "false")});
        }else{
            setSelectedId(obj.Id)         
        };
    }
    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {        
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response?.Data?.Data ,2 ,trans("Actions"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "view": if (selectedId) handleViewRow(); break;
            case "edit": if (selectedId) handleEditRow(); break;
            case "remove": if (selectedId)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "help": alert("call api help"); break;
            case "attach": handleAttachment(); break;
            case "reference":
                if (taskId) { handleShowReferenceStepper(taskId); onHide() }
                break;
        }
    };
    /* #endregion */

    async function getReferenceActionsAsync() {
        setLoading(false)
        let pageSizeChangedByUser = getPageSize("Actions")
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getActions(pageNum, pageSizeChangedByUser, taskId);     
            setResponse(res);
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
  
        setLoading(true)
    }
    useEffect(() => {
    setResponse(null)
   getReferenceActionsAsync()

    }, [forceUpdate , pageNum  ]);


 

    if (response &&
        response?.Data && response.Data?.Data?.length>0) {
        gridColumns = Object.keys(response.Data.Data[0]);
        gridData = response.Data.Data;
        headerData = response.Data.Header;
        footerData = response.Data.Footer;
        Total_records = response.Data.TotalRecords;
    }
    pages = Total_records > pageSize ? Math.ceil(Total_records / pageSize) : 1;
    return (
        <>
            <div className="cartable-action">
                <Toolbar remove={true}  id={selectedId}  emptyList={!gridData || gridData.length == 0}  create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback}  />
                {headerData && <Headerbar headerContents={headerData} hasBorder={true} startIndex={2} />}

                {showActionForm &&
                    <ActionForm
                        onHide={() => setShowActionForm(false)}
                        mode={mode}
                        reset={(e)=>{getReferenceActionsAsync()}}
                        id={selectedId}
                        taskId={taskId}
                    />
                }
                {loading ?
                    (response?.Data?.Data.length > 0 ?
                        <div className="card">
                            <div className=" p-0">
                                <>
                                    <DataGrid
                                        parentName={"Actions"}
                                        theadData={gridColumns}
                                        tbodyData={gridData}
                                        minCellWidth={80}
                                        selectedRowObjectCallback={selectedIdCallback}
                                        handleEdit={handleEditRow}
                                        handleDelete={()=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow)}}
                                        handleView={handleViewRow}
                                        handleAttachment={handleAttachmentRow}
                                        startIndex={2}
                                        totalRecords={Total_records}
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        HandleNextPage={HandleNextPage}
                                        HandlePrevPage={HandlePrevPage}
                                        handlechangePageSize={handlechangePageSize}
                                        first={() => setPageNum(pages)}
                                        end={() => setPageNum(1)}
                                        handleGoPage={handleGoPage}
                                        scrollHeight={"150px"}
                                    />
                                    {footerData && <Footerbar footerContents={footerData} hasBorder={false} />}
                                    {showattachment &&
                                     <ModalForm title="Attachment" onHide={() => setshowAttachment(false)} show={showattachment}>
                                        <Attachment haveVersion={true} onHide={() => setshowAttachment(false)} mood={mode} endpoint={ENDPOINTS.personalPlanningApi.personalAction.Attachment} ownerId={selectedId} />
                                    </ModalForm>
                                    }
                                </>
                            </div>
                        </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    )
                    : <Loading />
                }

            </div>
        </>

    );
}