import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { trans } from "utils/helpers"
interface ButtonToolbarShowDeletedFilter {
    onClick?: () => void,
    Callback: (id: any) => void,
    positionId?: string,
}
export const ButtonToolbarShowDeletedFilter: React.FC<ButtonToolbarShowDeletedFilter> = ({ onClick, Callback, positionId }) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    const buttonToolbarProcessStarting = useRef(null);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [options, setOptions] = useState<any>()
    const [selectoptions, setSelectOptions] = useState<any>()
    

    function showSubMenuFunction() {
        setShowSubMenu(true)
    }
   async function getDataAsync() {
       let option:any = []

        option.push({Id:1 ,Name :"showDeleted" })
       let selected =  localStorage.getItem("ShowDeletedFilter")
       if(selected){
        setSelectOptions(selected)
        Callback && Callback(selected);

       }
        setOptions(option)
    }
    function hideSubMenuFunction() {
        setShowSubMenu(false)
    }
    function selectboxValSetter(event) {
        if(selectoptions){
            setSelectOptions(null)
            localStorage.setItem("ShowDeletedFilter" , "0" )
            Callback && Callback("0");
            setShowSubMenu(false)
            if (buttonToolbarProcessStarting.current) (buttonToolbarProcessStarting.current as HTMLButtonElement).blur();
        

        }else{
            setSelectOptions(event.currentTarget.getAttribute('data-value'))
            localStorage.setItem("ShowDeletedFilter" ,event.currentTarget.getAttribute('data-value') )
            Callback && Callback(event.currentTarget.getAttribute('data-value'));
            setShowSubMenu(false)
            if (buttonToolbarProcessStarting.current) (buttonToolbarProcessStarting.current as HTMLButtonElement).blur();
        
        }
    }

    useEffect(() => {
        getDataAsync()

    }, []);

    return (
        <> {options && <div className="toolbar-btn-wrapper">
            <button ref={buttonToolbarProcessStarting} onClick={() => { }} onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction} className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
                <div className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>F6</div>
                <FontAwesomeIcon icon={['fas', 'filter']} width="20px" height="20px" />
                <small>{trans("Filter")}</small>
                <div className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`}>
                    {options && options.map((option, index) => (
                        selectoptions && option.Id ==selectoptions  ?
                        <div key={index} className="toolbar-btn-sub-menu__option selected"  data-value={`${option.Id}`} onClick={selectboxValSetter}>{trans(option.Name)}</div>
                       :
                        <div key={index} className="toolbar-btn-sub-menu__option" data-value={`${option.Id}`} onClick={selectboxValSetter}>{trans(option.Name)}</div>
                    ))}
                </div>
            </button>
        </div>
        }
        </>
    )
    }