import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { ItemGroupLabelSlider } from "pages/ClientPortal/ECommerce/components/ItemGroupMenu/ItemGroupLabelSlider";
import { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { trans } from "utils/helpers";
import { getDefaultSignature, getMeetingSignature, getPersonSignatureIdApi, getSignature, getSignatureHistory, sendSignature } from "./api";

interface  SignaturePropsType {
    onHide?;
    Name?;
    Id?:string
    referenceId?:string
    taskId?:string
    taskTitle?:any
    jobId?:string
    mode?:any
    SourceData?:any
    sourceId?:any
    ParentForm?:any
    inDynamicForms?:any
}

export const  Signature = ({ 
   
    onHide,
    Name,
    Id,
    referenceId,
    taskId,
    taskTitle,
    jobId,
    mode,
    SourceData,
    sourceId,
    ParentForm,
    inDynamicForms=false,
}:  SignaturePropsType) => {
    const [resetForm, setResetForm] = useState(false);
    const layoutContext = useContext(LayoutContext);
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [showSignList, setShowSignList] = useState<any>(false);
    const [response, setResponse] = useState<Response | any>([]);
    const [signature, setSignature] = useState<any>();
    const [signatureHistory, setSignatureHistory] = useState<any>();
    const [showstep, setShowstep] = useState<any>({});
    const [loading, setLoading] = useState<any>(false);
    const [disabled, setDisabled] = useState<any>(false);



    
    async function sendNodeAsync(data) {
        try{
            const res = await sendSignature((SourceData!=undefined &&SourceData.includes("Meeting"))?SourceData:"automation"   ,  data, "post" ,referenceId ,ParentForm)
            onHide &&  onHide()
        }catch(err:any){

          }
          
        }  
    async function getDataAsync() {
        setLoading(false)
         try{

            if(SourceData!=undefined && SourceData.includes("Meeting")){
                const res  = await getMeetingSignature(sourceId,layoutContext.currentUserPosition.PositionId)
                setSignature(res.Data)
                if(res.Data!= null) setDisabled(true)

                if(res.Data){  
                    const res2 = await getPersonSignatureIdApi(layoutContext.UserPerson.PersonId);
                    res2.Data.map(item=>{
                        if(item.Id ==  res.Data.SignatureId){
                            setSignId(item)
                        }
                    })
                }

            }else{

                const res3 = await getSignatureHistory(referenceId, ParentForm)
                const res  = await getSignature(referenceId,ParentForm)
                setSignatureHistory(res3.Data)
                setSignature(res.Data)
                if(res.Data){  
                    const res2 = await getPersonSignatureIdApi(layoutContext.UserPerson.PersonId);
                    res2.Data.map(item=>{
                        if(item.Id ==  res.Data.SignatureId){
                            setSignId(item)
                        }
                    })
                }
            }


          

        }catch(err:any){

        }
        setLoading(true)

    }

    async function putNodeAsync(data) {
        try{
            const res = await sendSignature((SourceData!=undefined &&SourceData.includes("Meeting"))?SourceData:"automation"    ,   data, "put",referenceId, ParentForm)
        }catch(err:any){

        }
    }
            
    async function SignCheck(e ,event) {        
        try{
            if(e){
                const res = await getDefaultSignature(layoutContext.UserPerson.PersonId)
                if(res.data.Data.length >0){
                    setSelectedImage(res.data.Data[0])
                }else{
                    Toast("Msg_AddYourSignatureFirst" , "error"  )
                    setSelectedImage(false)
                    event.preventDefault();
                }
            }else{
                setSelectedImage(null)
            }
        }catch(err:any){
    
        }
    }


    function setSignId(obj) {
             setSelectedImage(obj)
            }

    async function choseSign(e) {
        e.stopPropagation()
        if(!disabled){
            setShowSignList(true)  
            try {
                 const res = await getPersonSignatureIdApi(layoutContext.UserPerson.PersonId);
                 setResponse(res.Data);            
            } catch (error) {
                
            }

        }
      
     }
   
    const handlePersonContactFormSubmit = (event) => {
  
        event.preventDefault();
        if(mode=="edit"){
            if(signature?.Id){
                let data={
                    "Id": signature?.Id,
                    "OwnerId" : sourceId ? sourceId:null,
                    "TAS_UID":taskId,
                    "PositionId":layoutContext.currentUserPosition.PositionId,
                    "SignatureId":event.target.Signature[0].checked ? selectedImage?.Id : null ,
                    "SignatureDescription": event.target.SignatureDescription.value,
                    "SignatureTime": new Date(),
                    "IsSigned":selectedImage ? true:false
                }
                putNodeAsync(data)
            }else{
                let data={
                    "OwnerId" : sourceId ? sourceId:null,
                    "PositionId":layoutContext.currentUserPosition.PositionId,
                    "SignatureId":selectedImage?.Id,
                    "TAS_UID":taskId,
                    "SignatureDescription": event.target.SignatureDescription.value,
                    "SignatureTime": new Date(),
                    "IsSigned": selectedImage ? true:false
                  }
               sendNodeAsync(data )
            }
        
        }else{
            let data={
                "OwnerId" : sourceId ? sourceId:null,
                "PositionId":layoutContext.currentUserPosition.PositionId,
                "SignatureId":selectedImage.Id,
                "TAS_UID":taskId,
                "SignatureDescription": event.target.SignatureDescription.value,
                "SignatureTime": new Date(),
                "IsSigned": selectedImage ? true:false
              }
           sendNodeAsync(data )
          }
       
    }


    
     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);

     useEffect(() => {


          if ( referenceId ) {
            getDataAsync()
        }
      }, [referenceId]);



      
    return (
        <>
        { showSignList &&
            <ModalForm title={"SignList"} onHide={()=>{setShowSignList(false)}} show={showSignList}>
                <div  className="card"> 
                    {response?.length >0 &&  
                    <div style={{display:"flex" , flexDirection:"row" ,flexWrap :"wrap"}} className="card-body">
                        {
                            response.map((item , index)=>(
                             <div onClick={()=>{setSignId(item);setShowSignList(false) } } className={`col-lg-2 SignCard   ${item?.IsDefault ? "Signactive": "" }`}  >
                               <img   width={"100%"} height={"100%"} src={'data:image/png;base64,'+item.Sign } /> 
                                {
                                  item?.IsDefault && 
                                   <div  style={{width: "150px"}} className="SignCardDefault" >
                                      {trans("Default")}
                                   </div>
                                }  
                             </div>
                            ))
                        }
                    </div>                    
                    }                        
                    </div>
            </ModalForm>
        }
        {loading?
        <>
        {signatureHistory&& signatureHistory.length > 1 && 
        <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px" , justifyContent:"space-between" }} className="d-flex  py-1 col-12 pr-4"> 
            <div>{trans('Sign')} </div>      
        </div>
        }
        <ItemGroupLabelSlider >
            {signatureHistory && signatureHistory.map((item , index)=>(
            (signature && signature?.TAS_UID &&item.PositionId == layoutContext.currentUserPosition.PositionId && index == signatureHistory.length-1&&mode!="view") ?
                <></>: 
                <div className={` card ${mode == "create" && " card"}  `} style={{minWidth:'fit-content'}}>
                <div className="row">
                    {!showstep[index] &&   
                        <OverlayTrigger
                                key={"reserve"}
                                placement="top"
                                delay={{"show" : 600 , "hide":0}}
                                overlay={
                                    <Tooltip className="tooltip " id={`button-tooltip-${"reserve"}`}>
                                    {trans(item?.Description)}
                                    </Tooltip>
                                    }>
                                        {
                                            <div className="" style={{display: !showstep[index] ?"block" :"none"}}>                                    
                                            <OverlayTrigger
                                                key={"reserve"}
                                                placement="top"
                                                delay={{"show" : 600 , "hide":0}}
                                                overlay={
                                                    <Tooltip className="tooltip " id={`button-tooltip-${"reserve"}`}>
                                                    {trans(item.PositionDescription)}
                                                    </Tooltip>
                                                    }>
                                                        <>
                                                        {
                                                        
                                                        <div  style={{width: "100%"}} className="" >
                                                            <div className="px-1 py-0" style={{fontSize:'14px'}}>
                                                                {item?.PositionDescription?.split('-')[0]}
                                                            </div>
                                                            <div className="px-1 py-0" style={{fontSize:'12px'}}>
                                                                { item?.PositionDescription?.split('-')[1] }
                                                            </div>
                                                        </div>}</>
                                            </OverlayTrigger> 
                                            <img width="190" height="140" src={'data:image/png;base64,' + item.Sign} />

                                        </div>
                                        }
                    </OverlayTrigger> 
                    }
                </div>
                </div>
            ))
             }
        </ItemGroupLabelSlider >
        </>
        :<Loading/>
    
            }
        {mode !="view" &&
        <div className="person-contact-form card">
            <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px" , justifyContent:"space-between" }} className="d-flex  py-1 col-12 pr-4"> 
                 <div>{taskTitle} </div>      
                 <div  style={{marginLeft:"18px"}} ></div>
            </div>
            <div className="card-body">
                <div className="container-fluid">
                    <form className={mode} onSubmit={handlePersonContactFormSubmit}>
                        <div className=" row " >
                        <div className="col-sm-2 col-lg-4 mt-1 ">
                           <CheckableButton
                                onchange={SignCheck}
                                type='checkradio'
                                labelText={trans("Sign")}
                                labelFalseText={trans("decline")}
                                defaultChecked={selectedImage !=null }
                                checked={selectedImage !=null}
                                inputName='Signature'
                                mode={disabled?"view": mode}
                            />
                         <div onClick={choseSign} className="col-lg-3 col-sm-4 ">
                         <OverlayTrigger
                                    placement="top"
                                    delay={{"show" : 300 , "hide":0}}
                                    overlay={
                                        <Tooltip className="tooltip" id={`button-tooltip-${"choseSign"}`} >
                                            {trans("ClickToChangeSign")}
                                        </Tooltip>
                                    }
                                >
                            
                              {selectedImage ? (
                                <div  style={{width: "154px"}} className={`col-lg-2 SignCard   ${selectedImage?.IsDefault ? "Signactive": "" }`}  >
                                   <img width="150" height="150" src={'data:image/png;base64,' + selectedImage?.Sign} />
                                   {
                                       selectedImage?.IsDefault && 
                                        <div  style={{width: "150px"}} className="SignCardDefault" >
                                            {trans("Default")}
                                        </div>
                                   }
                                </div>
                              ) :
                                    <></>
                              }                                             
                        </OverlayTrigger> 
                         </div>  
                          </div>
                              <div className="col-sm-12 col-lg-6 mt-2 mr-5 ">
                                  <Textbox  
                                      minHeight={4}            
                                      textboxType="textarea"
                                      textboxName="SignatureDescription"
                                      labelText="Description"
                                      defaultValue={signature?.SignatureDescription ?signature?.SignatureDescription  : ""}
                                      mode={disabled ?"view" : mode}
                                  />
                              </div>                        
                        </div>
                        {  (mode !="view" && !disabled)&&
                            <div className="col-lg-12 mt-1 btns_form">
                                <Button value="Save" btnType="submit" />
                                {!inDynamicForms&&
                                <Button btnType="cancel" value="Cancel" onClick={()=>{ Toast(trans("modalclosemessage") , "warning" , "alert" , onHide )}} /> 
                                }
                            </div>
                        }
                    </form>
                </div>

            </div>
        </div>        
        }
        </>

    );
}