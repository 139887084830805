import { REPORT_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSREPORT } from "utils/endpoints";
import { http } from "utils/http";

export const getDatabasess = () => http({
    url: ENDPOINTSREPORT.Databases.dictionary,
    baseURL:REPORT_URL,
    method: 'get',

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});


