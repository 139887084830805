import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { ReceiverPosition } from "components/ReceiverPosition/ReceiverPosition";
import { Textbox } from "components/Textbox/Textbox";
import { useState } from "react";


interface ReceiverFormProps {
    letter: any;
    onHide: () => void;
    Receiverlistcallback: (list) => void;
  }

export const  ReceiverForm = ({letter,onHide ,Receiverlistcallback }:ReceiverFormProps) => {

    const [reload, setReload] = useState(false); // integer state
    const [mode, setMode] = useState<string>();
    const [receiver, setReceiver] = useState<any>()

    /* pagination area*/
    const makeReceiverList = (event) => {
        event.preventDefault();
        let ReceiverLetter:any ={
            Id:receiver.Id ,
            Name:receiver.Name ,
            Description	:event.target.Text?.value,
            IsCopy	:event.target.IsCopy?.checked,
        }
        Receiverlistcallback(ReceiverLetter)
       
    }
    return (
        <>     
         <form  onSubmit={makeReceiverList} >
                 <div className="list-card card">
                    <div className="row p-1">
                        <div className="col-12 my-1 my-lg-0 col-lg-6">
                            <ReceiverPosition
                                    allowType={true}
                                 required={true}
                                 returnObj={true}
                                 onChange={(e)=>{ setReceiver(e)}  }
                                 label="Receiver"
                                 name="Receiver"
                                 value={""}
                                 mode={"edit"}
                                 />
                        </div>
                        <div className="col-12 my-1 my-lg-0 col-lg-6">
                            <Textbox
                                required={true}
                                minHeight={3}
                                textboxType="textarea"
                                textboxName="Text"
                                defaultValue={""}
                                labelText="Text"
                                mode={"edit"}
                                />
                        </div>
                        <div className="col-12 my-1 my-lg-0 col-lg-4">
                            <CheckableButton
                                defaultValue={1}
                                type='checkbox'
                                labelText='IsCopy'
                                defaultChecked={ false}
                                inputName='IsCopy'
                                mode={mode}
                            />
                        </div>

                    </div>
                 </div>
                 <div className="col-12 d-flex justify-content-end mt-2">
                    <Button btnType="submit" value="save" />
                 </div>

        </form>

        </>
    )
}