import { InputLoad } from "components/InputLoading/inputLoading";
import { SelectBox } from "components/Select/Select";
import { useEffect, useState } from "react";
import { getDocsClassification } from "./api";
import { GUID_EMPTY } from "utils/constants";

interface DictionaryTypeSelectProps {
  value?: any;
  mode?: string;
  resetForm?: boolean;
  name: string;
  label: string;
  endPoint: string;
  baseUrl?: string;
  required?: boolean;
  transe?: boolean;
  params?: any;
  recordedData?: any;
  Change?: (e) => void;
  onChange?: (e: any, obj?: any) => void;
  useTrans?: boolean;
}
export const DocsClassification = ({
  recordedData,
  params,
  label,
  required = false,
  baseUrl,
  useTrans = false,
  endPoint,
  value,
  name,
  mode,
  resetForm,
  onChange,
  Change,
}: DictionaryTypeSelectProps) => {
  const [DocsClassification, setDocsClassification] = useState<any>();
  const [defaultval, setdefault] = useState(value);
  const [errcode, setErrcode] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getDocsClassificationAsync = async () => {
    setLoading(false);
    try {
      const res = await getDocsClassification(endPoint, baseUrl, params);
      if (res?.Data) {
        setDocsClassification(res?.Data);
        onChange &&
          onChange(
            Object.keys(res?.Data)[0],
            res?.Data[Object.keys(res?.Data)[0]]
          );
      } else {
        setDocsClassification(res);
        onChange &&
          onChange(Object.keys(res)[0], res?.Data[Object.keys(res?.Data)[0]]);
      }
    } catch (error: any) {
      setErrcode(error.response.status);
      setDocsClassification(null);
    }
    setLoading(true);
  };

  const handleOnChange = (e) => {
    onChange && onChange(e.target.value, DocsClassification[e.target.value]);
    Change && Change(true);

    if (
      e.target &&
      e.target.value != (value ? value : Object.keys(DocsClassification)[0])
    ) {
      Change && Change({ [name]: e.target.value });
    } else {
      Change && Change({ [name]: null });
    }
  };

  useEffect(() => {
    getDocsClassificationAsync();
    if (value) {
      onChange && onChange(value);
    }
  }, []);
  useEffect(() => {
    if (params) {
      getDocsClassificationAsync();
    }
  }, [params]);

  useEffect(() => {
    setdefault(value);
  }, [resetForm]);

  return (
    <>
      {DocsClassification && loading ? (
        <>
          <SelectBox
            onChange={(e) => {
              handleOnChange(e);
            }}
            lable={label}
            selectType={"select"}
            options={Object.values(DocsClassification)}
            val={Object.keys(DocsClassification)}
            name={name}
            value={value}
            resetForm={resetForm}
            mode={mode}
            required={required}
            useTrans={useTrans}
            recordedData={recordedData}
          />
          <div className="text-box__msg-wrapper"></div>
        </>
      ) : (
        <InputLoad
          msg={errcode}
          required={required}
          labelText={label}
          onClick={getDocsClassificationAsync}
        />
      )}
    </>
  );
};
