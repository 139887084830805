import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const  getPersonnelCartableDictionaryApi =( async () =>  await http({

    url: ENDPOINTS.cartable.PersonnelCartable.dictionary,
    method: 'get',

}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)