import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getCalendar = (async (year?, month?) => await http({
    url: ENDPOINTS.calendar.calendar.browse,
    method: 'get',
    params: {
        year: year,
        month: month
    }
})
    .then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err);
    }));

export const getCalendarDay = (async (year, month, day) => await http({
    url: ENDPOINTS.calendar.calendar.load,
    method: 'get',
    params: {
        year: year,
        month: month,
        day: day
    }
})
.then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
}));

export const sendEventApi = (data , method) => http({
    url: ENDPOINTS.calendar.OrganizationEvents.Calendar,
    method: method,
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})
    
    