import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { TableTree } from "components/TableTree/TableTree";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment";
import { useEffect, useState } from "react";
import { getPageSize, trans } from "utils/helpers";
import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { DeleteItemTree, DeleteItemTreeDetail, checkLevel, checkLevelItem, getItemGroup, getItemGroupRootsFormat, getItemGroupTree } from "./api";
import { ItemBarcode } from "./components/ItemBarcodes/ItemBarcodes";
import { ItemPropertiesValues } from "./components/ItemForm/components/ItemPropertiesValues";
import { ItemProperties } from "./components/ItemProperties/ItemProperties";
import { ItemTaxes } from "./components/ItemTaxes/ItemTaxes";
import { ItemBranch } from "./ItemGroup/ItemBranch/ItemBranch";
import { ItemGroupBranch } from "./ItemGroup/ItemGroupBranch/ItemGroupBranch";
import { ItemGroupLeaf } from "./ItemGroup/ItemGroupLeaf/ItemGroupLeaf";
import { ItemGroupRoot } from "./ItemGroup/ItemGroupRoot/ItemGroupRoot";
import { ItemLeafForm } from "./ItemGroup/ItemLeaf/ItemLeaf";
import { ItemVariationBranchForm } from "./ItemGroup/ItemVariationBranch/ItemVariationBranch";
import { ItemVariationLeafForm } from "./ItemGroup/ItemVariationLeaf/ItemVariationLeaf";
import { ItemGroupCodeSettingForm } from "./ItemGroupCodeSetting";
import { getItemGroupRoot } from "./ItemGroup/ItemGroupRoot/api";
import { getItemGroupLeaf } from "./ItemGroup/ItemGroupLeaf/api";
import { getItemGroupBranch } from "./ItemGroup/ItemGroupBranch/api";
import { getItemBranch } from "./ItemGroup/ItemBranch/api";
import { getItemLeaf } from "./ItemGroup/ItemLeaf/api";
import { getItemVariationBranch } from "./ItemGroup/ItemVariationBranch/api";
import { getItemVariationLeaf } from "./ItemGroup/ItemVariationLeaf/api";
import { DataGrid } from "components/DataGrid/DataGrid";


export const ItemTree = () => {
  const [data, setData] = useState<any>()
  const [forceUpdate, setForceUpdate] = useState<boolean>(false)
  const [selectedObj, setSelectedObj] = useState<any>()
  const [showProperties, setShowProperties] = useState<any>()
  const [showBarcode, setShowBarcode] = useState<any>()
  const [showTax, setShowTax] = useState<any>()
  const [idCallback, setIdCallback] = useState<any>(null)
  const [mode, setmode] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false);
  const [ShowItemTreeDetail, setShowItemTreeDetail] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<any>(0);
  const [checkData, setCheckData] = useState<any>(0);
  const [rootsFormat, setRootsFormat] = useState<any>(false);
  const [message, setMessage] = useState<any>("NoDataForPreview");
  const [showAttachment, setShowAttachment] = useState(false);
  const [showMode, setShowMode] = useState<any>(true);
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pages, setPages] = useState<number>(0);
  const [levels, setLevels] = useState("");
  const [orderBy, setOrderBy] = useState<any>([]);
  const [search, setSearch] = useState(null);
  const [filterlist, setFilterlist] = useState<any>("");

  const clickToolbarCallback = (clickedButtonName: string) => {

    switch (clickedButtonName.toLowerCase()) {
      case "reference":
        alert("call api reference with Id :");
        break;
      case "print":
        alert("print ");
        break;
      case "refresh":setForceUpdate(!forceUpdate);break;
      case "view":  if(selectedObj){setmode("view") ;handleOpenForm(selectedObj ,"view")}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "remove":  if(selectedObj){if(selectedObj.ParentId==null && selectedObj.RootId == null ){Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{DeleteHandler(2 )})}else{Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{DeleteHandler(1)})}}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "create": if(selectedObj){selectedCreateItemTreeCallback(1)}else Toast(trans("Msg_SelectRow"), "warning"); 
        break;
      case "copy": if(selectedObj){copyItemHandler(selectedObj)}else Toast(trans("Msg_SelectRow"), "warning"); 
        break;
      case "edit": if(selectedObj){ setmode("edit") ;handleOpenForm(selectedObj ,"edit")}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "properties": if(selectedObj){ setmode("edit") ;setShowProperties(true)}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "barcode": if(selectedObj){ setmode("edit") ;setShowBarcode(true)}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "tax": if(selectedObj){ setmode("edit") ;setShowTax(true)}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
        case "attach": if (selectedObj) {
          setmode("edit")
          setShowAttachment(true)
      } else {
          Toast(trans("Msg_SelectRow"), "warning")
      } break;
    }
  };  




  async function copyItemHandler(obj) {
    const lang = localStorage.getItem("lang");
    document.body.classList.add("loading-indicator-"+lang);

    try {
      const res = await checkLevelItem(obj.Id)
      let response:any= {}
      switch (res.Data?.ItemLevelType) {
        case 1:
          response = await getItemGroupRoot(obj.Id)
          break;
        case 2:
          response = await getItemGroupBranch(obj.Id)
          break;
        case 3:
          response = await getItemGroupLeaf(obj.Id)
          break;
        case 4:
          response = await getItemBranch(obj.Id)
          break;
        case 5:
          response = await getItemLeaf(obj.Id)
          break;
        case 6:
          response = await getItemVariationBranch(obj.Id)
          break;
        case 7:
          response = await getItemVariationLeaf(obj.Id)
          break;
      }

      navigator.clipboard.writeText( JSON.stringify({...response.Data , Code:res.Data.Code}) )
      Toast(trans("copy"), "success")
    } catch (error) {
      
    }
    document.body.classList.remove("loading-indicator-"+lang);
    
  }
  async function DeleteHandler(type) {
    try {

          if(type==1){
             await DeleteItemTreeDetail(selectedObj.Id ); 
          }else{
             await DeleteItemTree(selectedObj.Id ); 
          }
          if(selectedObj.ParentId != selectedObj.Id){
            setIdCallback(selectedObj.ParentId)
          }else{

            setForceUpdate(!forceUpdate);
          }
          setSelectedObj(null)

    } catch (error) {
      

    }

  }

  const selectedCreateItemTreeCallback =async (e) => {
    setmode("create")
    const lang = localStorage.getItem("lang");
    document.body.classList.add("loading-indicator-"+lang);
    if(e==1&& !selectedObj){
      Toast(trans("Msg_SelectRow"), "warning")
    }else{
      if(e==2){
        try {
          const res = await checkLevel()
          if(res.Data){
            setCheckData({...res.Data , ParentCode :selectedObj?.Code})
            setShowForm(true)

          }else{
            setRootsFormat(true)
          }
        } catch (error) {
        }
        setShowForm(e)
      }else{
          try {
              if(selectedObj.IsLeaf){
                Toast("msg_cantaddnewleveltoitem","error")
              }else if(selectedObj?.Code.length<levels.length){
                Toast("noaccess","error")

              }else{
                const res = await checkLevel(selectedObj.Id)
                setCheckData(res.Data )
                setShowForm(true)

              }
      
          } catch (error) {
            
          }
      }
    }
    document.body.classList.remove("loading-indicator-"+lang);

  }


  const handleOpenForm =async (e , type) => {
    const lang = localStorage.getItem("lang");
    document.body.classList.add("loading-indicator-"+lang);
    try {
      if(selectedObj?.Code.length<=levels.length && type!="view"){
        Toast("noaccess","error")
      }else{
        const res = await checkLevelItem(e.Id)
        setCheckData(res.Data)
        setShowForm(true)

      }
    
    } catch (error) {
    }
    document.body.classList.remove("loading-indicator-"+lang);
  }

  const setselecetrow = (e) => {
    setSelectedObj(e)
  }

  const ShowItemTreeDetailShow = () => {
    setIdCallback(selectedObj?.Id)
    setShowItemTreeDetail(false)
  }


  const handleShowForm = (e) => {
    if(!selectedObj ){
      setForceUpdate(!forceUpdate)
    }
    setIdCallback(selectedObj?.Id)
    if(!showMode){
      setForceUpdate(!forceUpdate)

    }
    setShowForm(0)
}

  async function getItemTreeAsync(PageNum = pageNum) {
    setLoading(false)
    let pageSizeChangedByUser = getPageSize("ItemTreeGrid" )
    setPageSize(pageSizeChangedByUser)
    try{
      if(showMode){
        const respons = await getItemGroupTree(null)
        setData(respons.Data)
      }else{
        let warehouseId=null
        const respons = await getItemGroup( warehouseId ,PageNum,  pageSizeChangedByUser ,orderBy.length == 0 ?null:  orderBy.join(",") ,search,filterlist)
        setPages(respons.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(respons.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
        setData(respons.Data)
    }

    }catch(err:any){
      setMessage(err?.data?.Message)
    }
    try{
      const ItemGroupRoots =await  getItemGroupRootsFormat()
      setLevels(ItemGroupRoots?.Data[0])
    }catch(err:any){
      setMessage(err?.data?.Message)
    }
    setLoading(true)
  }


  const handlechangePageSize = (e: any) => {
    setPageNum(1);
    setPageSize(e.currentTarget.value);
    getPageSize("ItemTreeGrid" ,e.currentTarget.value )
    getItemTreeAsync(1)
  };

const handleGoPage = (value) => {
  if (value) {
    if (value < 1 || value > pages) { }
    else setPageNum(+value);getItemTreeAsync(+value)
  } else {

  }
};
const handleNextPage = () => {
    if(pageNum < pages){
        setPageNum(pageNum + 1);
        getItemTreeAsync(pageNum + 1);
      };
};
const handlePrevPage = () => {
    if(pageNum > 1){
        setPageNum((pageNum)=>{return +pageNum - 1});
        getItemTreeAsync(pageNum - 1);
      };
};



  useEffect(() => {
    setSelectedObj(null)
    setData(null)
    getItemTreeAsync()
  }, [forceUpdate  , showMode, search , filterlist , orderBy]);


  useEffect(() => {
    if(idCallback){
      setIdCallback(null)
    }
  }, [idCallback]);



    return (
     <>
        {showAttachment &&
            <ModalForm mode={mode} title={trans("picture") +" : "+ selectedObj?.Description	} onHide={() => { setShowAttachment(false) }} show={showAttachment}>
                <Attachment onHide={() => { setShowAttachment(false)}} baseURL={INVENTORYANDSALES_URL} mood={mode}  endpoint={ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.attachment} ownerId={selectedObj?.Id} />
            </ModalForm>
        }
        {
            showTax &&
              <ModalForm mode={mode}  title="Tax" onHide={() => setShowTax(false)} show={showTax}>
               <ItemTaxes 
                onHide={() => setShowTax(false)} 
                IsIndependent={true}
                Id={selectedObj.Id} 
                mode={mode}  />
              </ModalForm>
        }
        {
            rootsFormat &&
              <ModalForm mode={mode} title="rootsFormat" onHide={() => setRootsFormat(false)} show={rootsFormat}>
                  <ItemGroupCodeSettingForm 
                  onHide={() => setRootsFormat(false)}
                  />
              </ModalForm>
        }
        {
            showBarcode &&
              <ModalForm mode={mode} title="Barcode" onHide={() => setShowBarcode(false)} show={showBarcode}>
               <ItemBarcode
                onHide={() => setShowBarcode(false)} 
                IsIndependent={true}
                Id={selectedObj.Id} 
                mode={mode}  />
              </ModalForm>
        }
        {
            showProperties &&
              <ModalForm mode={mode} title="itemproperties" onHide={() => setShowProperties(false)} show={showProperties}>
                {selectedObj.IsLeaf?
                <ItemPropertiesValues
                onHide={() => setShowProperties(false)}
                IsIndependent={true}
                Id={selectedObj.Id}
                mode={ mode}
                />
              
                  :
                 <ItemProperties
                 onHide={() => setShowProperties(false)}
                  selectedObj={selectedObj}
                  mode={mode} 
                  IsIndependent={true}
                  />
              }
              </ModalForm>
        }
        {showForm?<>
        {
            {
                1: (
                  <ModalForm mode={mode} key={"ItemGroupRoot"} title={trans("GroupRoot" )} onHide={() => setShowForm(false)} show={checkData.ItemLevelType==1}>
                  <ItemGroupRoot 
                    showForm={handleShowForm} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
                 </ModalForm>
                ),
                 2: (
                  <ModalForm mode={mode}key={"ItemGroupBranch"} title={trans("GroupBranch")+" : "+selectedObj?.ParentsList.join("/")+ (mode=="create"?"/":"")} onHide={() => setShowForm(false)} show={checkData.ItemLevelType==2}>
                  <ItemGroupBranch 
                    showForm={() =>{ShowItemTreeDetailShow(); setShowForm(false);}} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
              </ModalForm>
                ),
                 3: (
                  <ModalForm mode={mode} key={"ItemGroupLeaf"}title={trans("GroupLeaf")+" : "+selectedObj?.ParentsList.join("/")+ (mode=="create"?"/":"")}  onHide={() => setShowForm(false)} show={checkData.ItemLevelType==3}>
                  <ItemGroupLeaf 
                    showForm={handleShowForm} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
              </ModalForm>
                 ),
                 4: (
                  <ModalForm mode={mode} key={"ItemBranch"}title={trans("ItemBranch")+" : "+selectedObj?.ParentsList.join("/")+ (mode=="create"?"/":"")}  onHide={() => setShowForm(false)} show={checkData.ItemLevelType==4}>
                  <ItemBranch 
                    showForm={handleShowForm} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
                 </ModalForm>
                 ),
                 5: (
                  <ModalForm mode={mode}key={"ItemLeafForm"} title={trans("Item")+" : "+selectedObj?.ParentsList.join("/")+ (mode=="create"?"/":"")}  onHide={() => setShowForm(false)} show={checkData.ItemLevelType==5}>
                  <ItemLeafForm 
                    showForm={handleShowForm} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
                 </ModalForm>
                 ),
                 6: (
                  <ModalForm mode={mode}key={"ItemVariationBranchForm"} title={trans("itemvariationbranch")+" : "+selectedObj?.ParentsList.join("/")+ (mode=="create"?"/":"")}  onHide={() => setShowForm(false)} show={checkData.ItemLevelType==6}>
                  <ItemVariationBranchForm 
                    showForm={handleShowForm} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
                 </ModalForm>
                 ),
                 7: (
                  <ModalForm mode={mode}key={"ItemVariationLeafForm"} title={trans("itemvariationleaf")+" : "+selectedObj?.ParentsList.join("/")+ (mode=="create"?"/":"")}  onHide={() => setShowForm(false)} show={checkData.ItemLevelType==7}>
                  <ItemVariationLeafForm 
                    showForm={handleShowForm} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
                 </ModalForm>
                 ),
               
             }[checkData.ItemLevelType]
        
        }</>
        :<></>
      }

        <Toolbar 
          emptyList={data?.length == 0} 
          handleClickToolbar={clickToolbarCallback} 
          id={selectedObj} 
          edit={true} 
          properties={false} 
          attach={true}
          tax={selectedObj?.IsLeaf} 
          barcode={selectedObj?.IsLeaf&&false} 
          create={showMode} 
          search={false} 
          copy={showMode}
          remove={true}
          excel={false}
          setShowMode={(e)=>{setData(null) ;setShowMode(e); }}
          showMode={showMode}
          warehouse={false}
        />
        <div style={{ overflowY: "auto", 
                height: "75vh",
                 scrollbarWidth: "thin"}}>
            {
              loading ? (
                data ?
                <>
                 {showMode ?
                <div>
                  <TableTree
                    baseUrl={INVENTORYANDSALES_URL}
                    selectedRowObjectCallback={(e)=>{setselecetrow(e)}  }
                    makeParentsList={true}
                    selectedRowObject={idCallback}
                    getChildrenUrl={ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.getChildren}
                    theadData={["Id" , "ParentId", "Children" , "Description" ]}
                    startIndex={2}
                    defultparams={{rootId:selectedObj?.RootId ?selectedObj?.RootId :selectedObj?.Id}}
                    Titleparams={"Description"}
                    ComplementaryTitle={"Code"}
                    Childrenparams={'Children'}
                    defultShow={false}
                    Data={data}
                    parentName={"ItemTree"}
                  /> 
                </div>:
                        <div>
                        {data?.Data ?
                       <DataGrid
                           parentName={"ItemTreeGrid"}
                           theadData={Object.keys(data.Data[0])}
                           tbodyData={data.Data}
                           minCellWidth={80}
                           selectedRowObjectCallback={(e)=>{  setselecetrow({...e , ParentsList:[]})}}
                           totalRecords={data.TotalRecords}
                           pageNum={pageNum}
                           orderFormat={setOrderBy}
                           orderFormatList={orderBy}
                           handlefiltering={(str)=>{setFilterlist(str)  }}
                           filterStr={filterlist}
                           pageSize={pageSize}
                           HandleNextPage={handleNextPage}
                           HandlePrevPage={handlePrevPage}
                           handlechangePageSize={handlechangePageSize}
                           first={() => {getItemTreeAsync(pages);setPageNum(pages)}}
                           end={() => {getItemTreeAsync(1);setPageNum(1)}}
                           handleGoPage={handleGoPage}
                          />
                        :
                        <></>
                        }
                      </div>

}
                </>
                : <Message message={message} type={"error"} />)
                : <Loading />
            }
        </div>
      </>
    )
}




