import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import './assets/base.scss';
import './assets/base-ltr.scss';
import {  HashRouter } from 'react-router-dom';



library.add(far, fas);

//const root = createRoot( document.getElementById('root')); // createRoot(container!) if you use TypeScript
//root.render(  <React.StrictMode>
//  <HashRouter>
//    {/* <BrowserRouter>couse 403 error when refresh page */}
//
//    <App />
//    {/* </BrowserRouter> */}
//  </HashRouter>
//</React.StrictMode>);
declare global {
  interface Navigator {
    msSaveOrOpenBlob?: (blob: any, defaultName?: string) => boolean
  }
}

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      {/* <BrowserRouter>couse 403 error when refresh page */}
  
      <App />
      {/* </BrowserRouter> */}
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
