import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getPosition = (positionId: any) => http({
    url: ENDPOINTS.BaseInformation.position.dictionary,
    method: 'get',
    params: {
        id: positionId
    }
}).then((response) => (response.data))
    .catch(err => {

    })

export const getJobByReferenceId = (referenceId: any) => http({
    url: ENDPOINTS.cartable.job.load,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
        referenceId: referenceId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getReverseList = (referenceId: any) => http({
    url: ENDPOINTS.cartable.refer.reverseList,
    method: 'get',
    params: {
        referenceId: referenceId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


export const getCartable = (PageNumber: number, PageSize: number, CartableType, processId, SelectedPosition?: any, orderFormat: string = "", str?: any, filterlist?: any) => http({
    url: ENDPOINTS.cartable.browse,//must be replace by cartable endpoints
    method: "get",
    params: {
        pageNumber: PageNumber,
        pageSize: PageSize,
        processId: processId,
        cartableType: CartableType,
        OrderByString: orderFormat,
        SearchString: str,
        FilterString: filterlist
    }
}).then(response => response.data)
    .catch(err => {
        return Promise.reject(err);
    })


    
export const PostArchive = (async (data ) => await http({
    url: ENDPOINTS.archive.JobPersonalArchiveDetail.browse  ,
    method: 'post',
    data: data


})
.then(response => response.data)
    .catch(err => {
        return Promise.reject(err);
    }
    ))

