import { Button } from "components/Button/Button";
import DatePicker from "components/DatePicker/DatePicker";
import { FileInput } from "components/FileInput/FileInput";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { PersonContactList } from "pages/OfficeAutomation/BaseInformation/PersonsPage/components/PersonContactList/PersonContactList";
import { Persons } from "pages/OfficeAutomation/BaseInformation/PersonsPage/types";
import { useEffect, useRef, useState } from "react";
import { ContactType } from "types/enum";
import { changeDateFormat, trans } from "utils/helpers";
import { getPerson, putPicture, setPersonAsync } from "../../../api/api";
import { Address } from "components/Address/Address";
import { GUID_EMPTY } from "utils/constants";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { ENDPOINTS } from "utils/endpoints";

interface PersonRealFormPropsType {
  handleCancelForm: (cancelBtn: boolean) => void
  handleAddPerson: (ans: any) => void,
  idPersonReal?: any;
  showSaveClose?: any;
  ButtonInTop?: boolean;
  mode?: string;
}

export const PersonRealForm = ({
  handleCancelForm,
  handleAddPerson,
  showSaveClose=true,
  idPersonReal,
  ButtonInTop=false,
  mode
}: PersonRealFormPropsType) => {
  let personReal1, personData, personDataForSend, method, data;
  let personRealDTOAddress:any={}
  const [datePickerISO_TIME, setDatePickerISO_TIME] = useState(personReal1?.BirthDate ? personReal1?.BirthDate : null);
  const [marriageDate, setMarriageDate] = useState(personReal1?.MarriageDate ? personReal1?.MarriageDate : null);
  const [personResponse, setPersonResponse] = useState<Response | any>();
  const [modify, setModify] = useState<boolean>(false);
  const [personId, setPersonId] = useState<any>();

  const [resetForm, setResetForm] = useState(false);
  ////file upload
  const [selectedImage, setSelectedImage] = useState<any>();
  const [showModalContact, setShowModalContact] = useState<boolean>(false);
  const [personContactDataList, setPersonContactDataList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [save_Exit, setSave_Exit] = useState<boolean>(false);
  const [Total_records, setTotal_records] = useState<number>(5);
  const [userName, setUserName] = useState<any>("");
  const [userChanges, setUserChanges] = useState({});
  const [dataRecovered, setDataRecovered] = useState<any>({});

  const changeHandlePic = (e: any) => {
    if (e?.target?.files && e?.target?.files?.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
    // else{
    //   setSelectedImage(null);
    // }
  };
  const handleBtnContact = () => {
    setShowModalContact(true);

  }


  const datePickerIsoTimeCallback = (isoTime) => {
    setDatePickerISO_TIME(isoTime);
  };


  async function sendPersonAsync(method, _objData) {
    try {
      const res = await setPersonAsync(method, _objData);
      handleAddPerson(res.data)
      setResetForm(true);
      sessionStorage.setItem("changingforms" , "false");
      localStorage.removeItem("formsInChang")
      setUserChanges({})
      setDataRecovered({})
      if (save_Exit) {
        handleCancelForm(true);
      }
      if (mode == "edit") {
        handleCancelForm(true);
      }
    } catch (err: any) {
    }
  }
  async function sendpersonPictureAsync(id ,  _objData) {
    try {
      const res = await putPicture(id ,  _objData ,false);
  

    } catch (err: any) {
    }
  }

  function sendDataToServer(event) {
    event.preventDefault();
    data = {
      Sex: +event.target.Sex.value,
      PersonRealTitleId: event.target.Title.value,
      FirstName: event.target.FirstName.value,
      FirstNameEN: event.target.FirstNameEN.value,
      LastName: event.target.LastName.value,
      LastNameEN: event.target.LastNameEN.value,
      FatherName: event.target.FatherName.value,
      FatherNameEN: event.target.FatherNameEN.value,
      BirthCertNo: event.target.BirthCertNo.value,
      BirthDate: datePickerISO_TIME,
      MarriageDate: marriageDate,
      BirthPlace: event.target.BirthPlace.value,
      IssuePlace: event.target.IssuePlace.value,
      NationalCode: event.target.NationalCode.value,
      EconNo: event.target.EconNo.value,
      Description: event.target.Description.value,
    };
    let address = (event.target.Address.value||event.target.Plaque.value|| event.target.Unit.value||event.target.PostalCode.value)?   JSON.stringify({ address: event.target.Address.value, plaque: event.target.Plaque.value, unit: event.target.Unit.value, PostalCode: event.target.PostalCode.value , Longitude: event.target.Longitude.value,Latitude: event.target.Latitude.value }):null;
    let Contact: any = []
    personContactDataList.forEach(item => { if (item.Id != GUID_EMPTY) { Contact.push({ Value: item.Value, ContactType: +item.ContactTypeEnum, Description: item.Description }) } })

    let personInfo: Persons = {
      PersonRealDTO: {
        PersonReal: data,
        Email: event.target.Email.value,
        Address: address,
        Mobile: event.target.Mobile.value,
        Phone: event.target.Phone.value,

      },
      PersonContacts: Contact,
      UserName: event.target.UserName.value
    };
    if (personId) {//personContext.id
      personInfo.Id = personId
      personDataForSend = personInfo
      method = "put";
      let _objData = new FormData();
      _objData.append("PersonDTO", JSON.stringify(personDataForSend));
      
      let personPicture = new FormData();
      personPicture.append("personPicture", selectedImage);
      sendpersonPictureAsync( personResponse.PersonRealDTO.PersonReal.Id, personPicture)
      sendPersonAsync(method, _objData)
    } else {
      personDataForSend = personInfo;
      method = "post";
      let _objData = new FormData();
      _objData.append("PersonDTO", JSON.stringify(personDataForSend));
      _objData.append("PersonPicture", selectedImage);
      sendPersonAsync(method, _objData)
    }
  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  async function getDataAsync(Id) {
    
  
    
    setLoading(false)
    setSelectedImage(null)
    try {
      const response = await getPerson(Id);
      setDatePickerISO_TIME(response.data.Data?.PersonRealDTO.PersonReal?.BirthDate)
      setMarriageDate(response.data.Data?.PersonRealDTO.PersonReal?.MarriageDate)
      setPersonResponse(response.data.Data);
      let contact = response.data.Data?.PersonContacts.map(item => {
        return {
          Id: item.Id,
          ContactTypeEnum: item.ContactType ,
          Description: item.Description,
          ContactType: trans(ContactType[item.ContactType]) ,
          Value: item.Value
        }
      })
      setPersonContactDataList(contact);
      if (response.data.Data?.PersonRealDTO.PersonReal.PersonRealPicture) {
        dataURLtoFile('data:image/png;base64,' + response.data.Data?.PersonRealDTO.PersonReal.PersonRealPicture, "1.jpg")
      }
      sessionStorage.setItem("changingforms" , "false")
      localStorage.removeItem("formsInChang")
    setLoading(true)

    } catch (error) {

    }

    setLoading(true)
  }
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    if (sessionStorage.getItem("changingforms")=="true"&&localStorage.getItem("formsInChang")!=null){
      let forms = JSON.parse(localStorage.getItem("formsInChang")+"") 
      if(forms){
        setDataRecovered(forms["PersonReal"])
        setUserChanges(forms["PersonReal"])

      }
    }
    if (idPersonReal&&mode !== "create") {
      if (mode !== "create") {
        setPersonId(idPersonReal)
        getDataAsync(idPersonReal)
        
      }
    } else if (mode == "create") {
      setPersonId(null)
      setResetForm(true);
      setLoading(true)
    }else{
      setLoading(true)
    }
  }, [idPersonReal, mode]);

  
  
  useEffect(() => {
    if(userChanges!=undefined&& Object.values(userChanges).filter((item)=>item!=null).length>0){
      sessionStorage.setItem("changingforms" , "true")
      let forms:any=JSON.parse(localStorage.getItem("formsInChang" )+"")
      forms ={...forms ,  "PersonReal" :{...userChanges , Id:idPersonReal } }
      localStorage.setItem("formsInChang" , JSON.stringify(forms))
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }else{
      sessionStorage.setItem("changingforms" , "false")
      localStorage.removeItem("formsInChang")
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [userChanges]);
  
  useEffect(() => {
    if (resetForm) {
      setResetForm(false)
      setSelectedImage(null)
    }
  }, [resetForm])
  
  
  if (personId && personResponse) {
    personData = Object.assign({}, personResponse);
    personReal1 = personData?.PersonRealDTO.PersonReal;
    try {
      if (typeof (personData.PersonRealDTO?.Address) === "string")
        personRealDTOAddress = JSON.parse(personData.PersonRealDTO?.Address)
    } catch {
      personRealDTOAddress = personData.PersonRealDTO?.Address
    }
  } else {
    personReal1 = undefined;
  }
  
  const personContactDataListCallback = (ans) => {
    if(ans.length!=personResponse?.PersonContacts?.length){
      
      setUserChanges({...userChanges , ...{PersonContact: ans}})
    }else{
      setUserChanges({...userChanges , ...{PersonContact: null}})
    }
    setPersonContactDataList(ans);
  }
  const PersonRealForm = useRef<any>(null);
  

  return (
    <>
      <div ref={PersonRealForm} className="col-lg-12 mb-1 ">
        <div className=" border-top-0">
          <div className="card-body">
            {
              loading ?
                <form
                  id="personRealform"
                  name="personform"
                  onSubmit={sendDataToServer}
                  onChange={() => setModify(true)}
                >
                  {ButtonInTop&&
                  <div className="col-lg-12 mb-1 btns_form">
                    {mode !== "view" ? <>
                      {(!personId&&showSaveClose) && <Button
                        btnType="submit"
                        value="Save&Close"
                        onClick={() => { setSave_Exit(true) }}
                      />}
                      <Button form="personRealform" value="Save" btnType="submit" />
                      <Button
                        btnType="cancel"
                        value="Cancel"
                        onClick={() => {
                          if(sessionStorage.getItem("changingforms")+""=="true"){
                            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                                sessionStorage.setItem("changingforms" , "false");
                                setUserChanges({});
                                handleCancelForm(true)
                            });
                        }else{
                          handleCancelForm(true)
                         };
                          
                        }}
                      />
                    </>
                      :
                      <Button
                        btnType="primary"
                        value="Close"
                        onClick={() => handleCancelForm(true)}
                      />
                    }

                  </div>
                  }
                  <div className="card ">
                    <div className="row p-3">
                      <div className="col-lg-3 col-xxl-2 col-sm-4  mt-1">
                        <DictionarySelectBox
                         recordedData={personReal1?.PersonRealTitleId} 
                         Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                         mode={mode} 
                         name="Title"
                         label="Title" 
                         endPoint={ENDPOINTS.BaseInformation.PersonRealTitle.dictionary}
                         value={dataRecovered?.Title ? dataRecovered?.Title  :personReal1?.PersonRealTitleId} 
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <Textbox
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          mode={mode}
                          resetForm={resetForm}
                          textboxType="text"
                          textboxName="FirstName"
                          labelText={"FirstName"}
                          maxLength={50}
                          recordedData={personReal1?.FirstName}
                          defaultValue={ dataRecovered?.FirstName ? dataRecovered?.FirstName  : personReal1?.FirstName}
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <Textbox
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          mode={mode}
                          resetForm={resetForm}
                          textboxType="text"
                          textboxName="FirstNameEN"
                          labelText={"FirstNameEN"}
                          fixedLanguage={"en"}
                          maxLength={50}
                          recordedData={personReal1?.FirstNameEN}
                          defaultValue={ dataRecovered?.FirstNameEN ? dataRecovered?.FirstNameEN  : personReal1?.FirstNameEN}
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <Textbox
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          mode={mode}
                          resetForm={resetForm}
                          textboxType="text"
                          textboxName="LastName"
                          labelText={"LastName"}
                          required={true}
                          maxLength={100}
                          recordedData={personReal1?.LastName}
                          defaultValue={dataRecovered?.LastName ? dataRecovered?.LastName  :personReal1?.LastName}
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <Textbox
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          mode={mode}
                          resetForm={resetForm}
                          textboxType="text"
                          textboxName="LastNameEN"
                          labelText={"LastNameEN"}
                          fixedLanguage={"en"}
                          maxLength={100}
                          recordedData={personReal1?.LastNameEN	}
                          defaultValue={dataRecovered?.LastNameEN	 ? dataRecovered?.LastNameEN	  :personReal1?.LastNameEN	}
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <EnumerationTypeSelect
                         recordedData={personReal1?.Sex} 
                         Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                         required={true} 
                         mode={mode}
                         label="Sex"
                         enumTypeSelect="Sex" 
                         name="Sex" 
                         value={dataRecovered?.Sex ? dataRecovered?.Sex  :personReal1?.Sex} 
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <Textbox
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          mode={mode}
                          resetForm={resetForm}
                          textboxType="text"
                          textboxName="FatherName"
                          labelText={"FatherName"}
                          maxLength={50}
                          recordedData={personReal1?.FatherName}
                          defaultValue={dataRecovered?.FatherName ? dataRecovered?.FatherName  :personReal1?.FatherName}
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <Textbox
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          mode={mode}
                          resetForm={resetForm}
                          textboxType="text"
                          textboxName="FatherNameEN"
                          labelText={"FatherNameEN"}
                          fixedLanguage={"en"}
                          maxLength={50}
                          recordedData={personReal1?.FatherNameEN}
                          defaultValue={dataRecovered?.FatherNameEN ? dataRecovered?.FatherNameEN  :personReal1?.FatherNameEN}
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <Textbox
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}                          
                          mode={mode}
                          resetForm={resetForm}
                          textboxType="text"
                          textboxName="BirthPlace"
                          labelText="BirthPlace"
                          maxLength={50}
                          recordedData={personReal1?.BirthPlace}
                          defaultValue={dataRecovered?.BirthPlace ? dataRecovered?.BirthPlace  :personReal1?.BirthPlace}
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <Textbox
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          mode={mode}
                          resetForm={resetForm}
                          textboxType="text"
                          textboxName="IssuePlace"
                          labelText="IssuePlace"
                          maxLength={50}
                          recordedData={personReal1?.IssuePlace}
                          defaultValue={dataRecovered?.IssuePlace ? dataRecovered?.IssuePlace  :personReal1?.IssuePlace}
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <DatePicker
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}                          
                          mode={mode}
                          lable="BirthDate"
                          name="BirthDate"
                          resetForm={resetForm}

                          setIsoTime={datePickerIsoTimeCallback}
                          val={dataRecovered?.BirthDate ?changeDateFormat(dataRecovered?.BirthDate) : (personReal1?.BirthDate ? personReal1?.BirthDate : null)}
                          id={personReal1?.Id}
                          recordedData={personReal1?.BirthDate}
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <Textbox
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}                         
                          mode={mode}
                          resetForm={resetForm}
                          textboxType="birthCertNo"
                          textboxName="BirthCertNo"
                          labelText={"BirthCertNo"}
                          maxLength={10}
                          recordedData={personReal1?.BirthCertNo}
                          defaultValue={dataRecovered?.BirthCertNo ? dataRecovered?.BirthCertNo  :personReal1?.BirthCertNo}
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <Textbox
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          mode={mode}
                          resetForm={resetForm}
                          textboxType="nationalCode"
                          textboxName="NationalCode"
                          labelText="NationalCode"
                          maxLength={10}
                          recordedData={personReal1?.NationalCode}
                          defaultValue={dataRecovered?.NationalCode ? dataRecovered?.NationalCode  :personReal1?.NationalCode}
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <Textbox
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          mode={mode}
                          resetForm={resetForm}
                          textboxType="econNo"
                          textboxName="EconNo"
                          labelText="EconNo"
                          maxLength={14}
                          recordedData={personReal1?.EconNo}
                          defaultValue={dataRecovered?.EconNo ? dataRecovered?.EconNo  :personReal1?.EconNo}
                        />
                      </div>
                      <div className="col-lg-3 col-xxl-2 col-sm-4 mt-1">
                        <DatePicker
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}                          
                          mode={mode}
                          lable="MarriageDate"
                          name="MarriageDate"
                          resetForm={resetForm}
                          setIsoTime={setMarriageDate}
                          val={dataRecovered?.MarriageDate ?changeDateFormat(dataRecovered?.MarriageDate) : (personReal1?.MarriageDate ? personReal1?.MarriageDate : null)}
                          id={personReal1?.Id}
                          recordedData={personReal1?.MarriageDate}
                        />
                      </div>
                      <div className="col-lg-7   mb-3">
                        <Textbox
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          mode={mode}
                          resetForm={resetForm}
                          textboxType="textarea"
                          textboxName="Description"
                          labelText={"Description"}
                          maxLength={900}
                          recordedData={personReal1?.Description}
                          defaultValue={dataRecovered?.Description ? dataRecovered?.Description  :personReal1?.Description}
                        />
                      </div>
                      <div  className="col-lg-3 col-xxl-2 col-sm-4 mb-4 ">
                          <FileInput
                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                            showBox={true}
                            maxWidth={1000}
                            maxHeight={1000}
                            hideSrc={false}
                            maxSize={10000000000}
                            accept=".jpg,.png"
                            resetForm={resetForm}
                            mode={mode}
                            lable="PersonRealPicture"
                            name="PersonRealPicture"
                            defultFileName="PersonRealPicture.jpg"
                            onChange={changeHandlePic}
                            src={selectedImage ? ( window.URL.createObjectURL(selectedImage)) : (personReal1?.PersonRealPicture ? 'data:image/jpg;base64,' + personReal1.PersonRealPicture : '')}
                          />
                      </div>
                    </div>
                  </div>
                  <div className="card p-3">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <>
                          <Address personContact={mode=="create"? null : personRealDTOAddress } mode={mode}/>
                          </>
                          <div className="row">
                          <div className="col-lg-3 col-sm-4 mt-1">
                            <Textbox
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              mode={mode}
                              required={true}
                              textboxType="mobile"
                              textboxName="Mobile"
                              labelText="Mobile"
                              maxLength={11}
                              recordedData={personData?.PersonRealDTO?.Mobile}
                              defaultValue={dataRecovered?.Mobile ? dataRecovered?.Mobile  :   personData?.PersonRealDTO?.Mobile}
                              resetForm={resetForm}
                            />
                          </div>
                            <div className="col-lg-3 col-sm-4 mt-1">
                              <Textbox
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="text"
                                textboxName="Phone"
                                labelText="Phone"
                                recordedData={personData?.PersonRealDTO?.Phone}
                                defaultValue={dataRecovered?.Phone ? dataRecovered?.Phone  :personData?.PersonRealDTO?.Phone}
                                resetForm={resetForm}
                                  mode={mode}
                              />
                            </div> 
                          <div className="col-lg-3 col-xxl-2 col-sm-6 mt-1 ">
                            <Textbox
                              mode={personData?.UserName?.length>0 ? "view":mode}
                              recordedData={personData?.UserName}
                              defaultValue={dataRecovered?.UserName ? dataRecovered?.UserName  :personData?.UserName}
                              resetForm={resetForm}
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              onChange={(e) => { setUserName(e.target.value) }}
                              textboxType="userName"
                              textboxName="UserName"
                              labelText="UserName"
                            />
                          </div>
                          <div className="col-lg-3 col-xxl-2 col-sm-6 mt-1">
                            <Textbox
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              mode={personData?.PersonRealDTO?.Email?.length>0 ? "view":mode}
                              required={userName.length > 0}
                              textboxType="email"
                              textboxName="Email"
                              labelText="Email"
                              recordedData={personData?.PersonRealDTO?.Email}
                              defaultValue={dataRecovered?.Email ? dataRecovered?.Email  :personData?.PersonRealDTO?.Email}
                              resetForm={resetForm}
                            />
                          </div>
                          <div
                              className="col-lg-2 col-xxl-1 mt-lg-1 btn_contacts"
                              style={{ paddingTop: "21px",direction:"ltr" }}
                            >
                              <Button
                                btnType="primary"
                                value="Contacts"
                                onClick={handleBtnContact}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!ButtonInTop&&
                  <div className="col-lg-12 mt-1 btns_form">
                    {mode !== "view" ? <>
                      {(!personId&&showSaveClose) && <Button
                        btnType="submit"
                        value="Save&Close"
                        onClick={() => { setSave_Exit(true) }}
                      />}
                      <Button form="personRealform" value="Save" btnType="submit" />
                      <Button
                        btnType="cancel"
                        value="Cancel"
                        onClick={() => {
                          if(sessionStorage.getItem("changingforms")+""=="true"){
                            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                                sessionStorage.setItem("changingforms" , "false");
                                setUserChanges({});
                                handleCancelForm(true)
                            });
                        }else{
                          handleCancelForm(true)
                         };
                          
                        }}
                      />
                    </>
                      :
                      <Button
                        btnType="primary"
                        value="Close"
                        onClick={() => handleCancelForm(true)}
                      />
                    }

                  </div>
                  }
                </form>
                : <Loading />
            }

          </div>
        </div>
        {showModalContact &&
          <ModalForm
            checkClose={false}
            onHide={() => setShowModalContact(false)}
            show={showModalContact}
            title="PersonContact"
          >
            <PersonContactList
              mode={mode}
              personId={personId}
              TotalRecords={Total_records}
              personContactDataListProp={dataRecovered?.PersonContact ? dataRecovered?.PersonContact  :personContactDataList}
              personContactDataListCallback={personContactDataListCallback}
            />
            <div className="modal-msg__btn-wrapper mt-2"></div>
          </ModalForm>
        }
      </div>

    </>
  );
};
