
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { deleteListJobSend, getJobSend, getListJobSend, postJobSend, putJobSend } from "./api";
import { Message } from "components/Message/Message";
import { Loading } from "components/Loading/loading";
import { downloadCSV, trans } from "utils/helpers";
import { Toast } from "components/ToastShow/ToastShow";
import { TypeSettingForm } from "components/TypeSettingForm/TypeSettingForm";


interface Form {
    Title: string,
    IsDefault: boolean,
    Status: number
}
export const SendMethodSetting = () => {

    // let headerData, footerData;
    // let Total_records, pages;
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [flag, setFlag] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<Form | null>();
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [search, setSearch] = useState(null);
    const [orderBy, setOrderBy] = useState<any>([]);


    const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }

    const handleCreateAction = () => {
        setMode("create")
        setEditForm(null);
        setShowForm(true)


    }
    const handleEditRow = () => {
        setMode("edit");
        setShowForm(true)
      
    }
    const handleDeleteRow = async() => {
        try {
            const res = await deleteListJobSend(selectedObj.Id)
            setForceUpdate(!forceUpdate)

        } catch (error) {
            
        }
    }
    const handleViewRow = () => {
        setMode("view")
        setShowForm(true)

    }

    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false");setResetForm(true) });
        }else{
            setSelectedObj(obj)         
        };
    }

    const submit = (e) => {
        e.preventDefault();
        if (editForm == null) {
            postJobSend({ Title: e.target.JobSend.value,IsDefault: e.target.checkbox.checked 
         }).then(() => {  sessionStorage.setItem("changingforms" , "false");setResetForm(true); getSendMethodAsync(); });
        } else {
            putJobSend({IsDefault: e.target.checkbox.checked , Title: e.target.JobSend.value, ActivityStatus: +e.target.JobSendSelect.value, Id: selectedObj.Id }).then(() => { sessionStorage.setItem("changingforms" , "false");setResetForm(true); getSendMethodAsync(); setEditForm(null) })
        }
        setShowForm(false)

    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setEditForm(null);
                setShowForm(false)
            });
        }else{
            setEditForm(null);
            setShowForm(false)     
        };

    }
    async function getSendMethodAsync() {
        setLoading(false)
        try {
            const res = await getListJobSend(search , orderBy.length == 0 ? null : orderBy.join(","));
            setResponse(res.Data);
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
         
        }
        setLoading(true)
    }
    async function getJobSubjectt() {
        const data = await getJobSend(selectedObj?.Id);
        setEditForm({ Title: data.Data.Title, Status: data.Data.ActivityStatus , IsDefault:data.Data.IsDefault });

    }
    useEffect(() => {
        getSendMethodAsync();
    }, [forceUpdate, flag , search , orderBy]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);

    if (flag) {
        getSendMethodAsync()
        setFlag(false)

    }
    useEffect(() => {
        if(showForm && selectedObj && mode != "create"){
            getJobSubjectt()
        }
    }, [selectedObj,showForm,mode])

    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,1 ,trans("SendMethodSetting"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id) handleEditRow(); break;
            case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "help": alert("call api help"); break;
        }
    };
    /* #endregion */

    return (
        <>

            <Toolbar handleSearch={searchHandler} emptyList={response == null || response.length == 0} id={selectedObj?.Id} remove={true} create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
            {/* {headerData && <Headerbar headerContents={headerData} hasBorder={true} />} */}
            <div className="card">
                <div className="">
                    {loading ?
                        <div className="cartable-action">
                            {showForm &&
                            <TypeSettingForm mode={mode} cancel={cancel} handleSubmit={submit} TextboxValue={editForm?.Title} resetForm={resetForm}
                                SelectBoxValue={editForm?.Status} IsDefault={editForm?.IsDefault} TextBoxName="JobSend" SelectBoxName="JobSendSelect" />
                            }
                            {
                                response?.length > 0 ?
                                    <>
                                        <DataGrid
                                            parentName={"SendMethodSetting"}
                                            orderFormat={setOrderBy}
                                            orderFormatList={orderBy}
                                            startIndex={1}
                                            theadData={Object.keys(response[0])}
                                            tbodyData={response}
                                            minCellWidth={80}
                                            scrollHeight={showForm? "25vh": "67vh"}
                                            selectedRowObjectCallback={selectedIdCallback}
                                            handleEdit={handleEditRow}
                                            handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                            handleView={handleViewRow}
                                        />
                                    </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                            }
                        </div>
                        : <Loading />
                    }
                </div>
            </div>
        </>

    );
}