import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getListSalesInvoiceStatus = async (
  searchString,
  orderByString,
  pageNumber,
  pageSize
) =>
  await http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
      .SalesInvoiceStatus.browse,
    method: "get",
    params: {
      OrderByString: orderByString,
      SearchString: searchString,
      PageNumber: pageNumber,
      PageSize: pageSize,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getSalesInvoiceStatus = async (Id) =>
  await http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
      .SalesInvoiceStatus.load,
    method: "get",
    params: { Id: Id },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const postSalesInvoiceStatusApi = (data) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
      .SalesInvoiceStatus.browse,
    method: "post",
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const putSalesInvoiceStatusApi = (data) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
      .SalesInvoiceStatus.browse,
    method: "put",
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getSalesInvoiceStatusDictionary = async () =>
  await http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
      .SalesInvoiceStatus.dictionary,
    method: "get",
  }).then((response) => response.data);

export const deleteListSalesInvoiceStatus = (id) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
      .SalesInvoiceStatus.browse,
    method: "delete",
    params: {
      id: id,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
