import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "components/Input/Input";
import { useEffect, useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import "./style.scss";
import img from '../../assets/image/logo.png'
import word from '../../assets/image/word.png'
import excel from '../../assets/image/excel.png'
import pdf from '../../assets/image/pdf.png'
import powerpoint from '../../assets/image/powerpoint.png'
import { Fileviewer } from "components/Fileviewer/Fileviewer";
import { loadAttachmentViewerBase64 } from "pages/DashboardPage/components/Board/api";
import { RoleOperationType } from "types/enum";

interface Props {
  lable: any;
  name: any;
  src?: string;
  endpoint?: any;
  accept?: string;
  file?: any;
  onChange?: any;
  mode?: string;
  hideSrc?: boolean;
  resetForm?: boolean;
  multiple?: boolean;
  maxSize?: any;
  maxHeight?: any;
  maxWidth?: any;
  defultFileName?: any;
  Validity?: any;
  required?: any;
  id?: any;
  showBox?: any;
  useTrans?:boolean;
  Change?: (ans: any) => void,

}
export const FileInput = ({Change , multiple=false , lable,useTrans=true,endpoint=null,defultFileName,required,showBox=false,Validity, name, src, accept, onChange, mode, hideSrc=false , resetForm ,file ,maxSize=256000,id ,maxHeight ,maxWidth }: Props) => {



  let [MsgShow, setMsgShow] = useState<boolean>(false);
  let [MsgText , setMsgText] = useState<any>(false);
  const [fileName , setFileName] = useState<any>(defultFileName);
  const [selectedItem, setSelectedItem] = useState<any>([]);
  const [fileClick, setFileClick] = useState<any>(false);

  const [showFile, setShowFile] = useState(false);
  const [load, setLoad] = useState<any>();
  const [resetSrc,setResetSrc]=useState(false)
  const [buttonType,setButtonType]=useState<any>()

  


  const cheackFile = (file) => {
    if (!!file?.target?.files.length){
      Change && Change({["FileInput"] :" " })
      if(multiple){
        onChange(file)
        setSelectedItem( [...selectedItem ,...file?.target?.files])
        
  
      }else{
        
        onChange(file)
        let img = new Image()
        img.src = window?.URL.createObjectURL(file?.target?.files[0])
        setFileName(file?.target?.files[0].name)
        if (maxSize >0 && (file?.target?.files &&  file?.target?.files[0]?.size > maxSize)) {
          file.target.setCustomValidity(`File must not exceed ${maxSize >1024000 ? maxSize/1024000+"MB" : maxSize/1000+"KB"    }!`);
          setMsgText(`File must not exceed ${maxSize >1024000 ? maxSize/1024000+"MB" : maxSize/1000+"KB"  }!`);
          setMsgShow(true);
          file.target.value =""
          setFileName(null)
          file.target.reportValidity();
    
        } else if(maxHeight && maxWidth && (img.width > maxWidth ||  img.height > maxHeight) ){
                file.target.setCustomValidity(`File Dimensions must not exceed ${maxWidth}px * ${maxHeight}px  `);
                setMsgText(`File Dimensions must not exceed ${maxWidth}px * ${maxHeight}px  `);
                setMsgShow(true);
                file.target.value =""
                setFileName(null)
  
                file.target.reportValidity();
         } else if(!(file?.target?.files &&  file?.target?.files[0]?.size > 0)) {
          file.target.setCustomValidity(`The file cannot be empty`);
          setMsgText(`The file cannot be empty`);
          setMsgShow(true);
          file.target.value =""
          setFileName(null)
          file.target.reportValidity();
     
        } else  {
           if(!Validity){
            file.target.setCustomValidity("");
             setMsgText("");
             setMsgShow(false);
           }
     
        }
      }
      setResetSrc(false)
    }else{
      if(buttonType==RoleOperationType.Delete){
        setResetSrc(true)
      }
    }

  };

  function DelOption(e) {
    let list:any=[] 
    const ListSelected:any = [...selectedItem]
    ListSelected.map(item=>{
      if(item != e){
          list.push(item)
      }
    })
    setSelectedItem(list) 
  }
  async function loadAttachmentViewerAsync(e) {
    try {
        const res:any = await loadAttachmentViewerBase64(e ,endpoint);
        setLoad(res);
        return res
    } catch (error) {
        setLoad(" ")
        return " "
    }
}
function handleSlider() {
  if(!showFile)
      document.getElementById("DashboardLayout")?.insertAdjacentHTML("afterbegin", '<div id="backScreen" style="z-index:100 !important" class="fullScreen" ></div>')
  
  if(id){

    loadAttachmentViewerAsync(id);
  }
  setShowFile(true)
}
function fileIconFormat(item) {
  let format = item.split('.').pop().toLowerCase();
  if(format == "xlsx" ){
      return  <img  src={excel} alt={` `}   style={{ cursor: 'pointer', height: "100%" }}/>
  }else if(format == "docx") {
      return  <img  src={word} alt={` `}   style={{ cursor: 'pointer', height: "100%" }}/>
  } else if (format == "pdf") {
      return  <img  src={pdf} alt={` `}   style={{ cursor: 'pointer', height: "100%" }}/>
  } else if (format == "txt") {
  } else if (format == "pptx") {
      return  <img  src={powerpoint} alt={` `}  style={{ cursor: 'pointer', height: "100%" }}/>
  } else if (format == "mp3") {

  }else{
      return <FontAwesomeIcon icon={['fas', 'file']} color='grey'   style={{ cursor: 'pointer', height: "100%" }} />
  }
}
  useEffect(() => {
    if (Validity){
      setMsgText(Validity);
      setMsgShow(true);
    }else{
      setMsgText("")
      setMsgShow(false);
    }
        
}, [Validity])

  useEffect(() => {
    if (resetForm){
      onChange(src)
    }
        
}, [resetForm])




  return (
    <>

      {showFile &&
            <div className="" style={{position:"fixed" ,right:"2%" , top:"2px" ,width: "100%",zIndex:"999999"}}>
              <FontAwesomeIcon onClick={()=>{setShowFile(false);document.getElementById("backScreen")?.remove()}}  size="3x" color='white' icon={['fas', 'times']} style={{zIndex:"9999" , cursor: 'pointer' , position:"absolute" }} />
              <div style={{display: "flex" , height: "92vh", justifyContent:"center" ,zIndex:"999999"}} className='w-100'>
                <object data={`${load ? load:src}`} className={`fileviewer   ${showFile?" showFile ":"" } ${" showArchive " }`}></object>
              </div>          
            </div>
        }
      <Input
      showBox={showBox}
      setFileClick={setFileClick}
      fileClick={fileClick}
        required={required}
        accept={accept}
       size={1}
        type="file"
        lable={useTrans ? trans(lable) : lable}
        className="form-control-custom file-input"
        id="fileinputIdLegal"
        name={name}
        onChange={cheackFile}
        mode={mode}
        multiple={multiple}
      />
      <div className="text-box__msg-wrapper">
       <span className={`${MsgShow ? "show-msg" : "hide-msg"} text-box__regex-msg text-danger`}>
       {MsgText}
       </span>
   </div>
      {/* {selectedImage && ( */}

      {multiple ?
      <div className="group-person__selected-options-wrapper pt-0 ">
       {selectedItem.length >0 && [...selectedItem].map((item, index) => (
         item.name.length >0 &&
         <div className="group-person__selected-option form-control-custom" >
         {item.name}
         <div className="group-person__delete-selected-option" onClick={()=>DelOption(item)} >
             <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
         </div>      
       </div>
       ))}
       </div>
       :
       <>
       {!hideSrc && 
         <>
    
          {src && fileName && !resetSrc? (
            (fileName.includes("jpeg")||fileName.includes("jpg")||fileName.includes("png")||fileName.includes("jfif")) ?
            <>
            <div onClick={()=>{if(showBox && mode!="view"){setFileClick(true)}else{handleSlider()   }}} className="Wrap_img_inputFile">
             <img src={resetSrc?'':src} className="InputFile" />
             </div>
             {mode!="view"&&
            <div className="d-flex my-2 justify-content-center" style={{width:'150px'}} >
             <div onClick={()=>{cheackFile('');setButtonType(RoleOperationType.Delete)}} className="ms-2 text-center" style={{cursor: "pointer"}}>
            <FontAwesomeIcon icon={[ICON_TYPE, "trash"]} className="oprations-icon"  />
            </div>
            <div onClick={()=>{setFileClick(true);setButtonType(RoleOperationType.Edit)}} className="text-center" style={{cursor: "pointer"}}>
            <FontAwesomeIcon icon={[ICON_TYPE, "edit"]} className="oprations-icon"  />
            </div>
            </div>
            }
            </>
           :
            ((fileName.includes("pdf")||(fileName.includes("txt"))) ? <div className="Wrap_img_inputFile">
            <object  className="InputFile"  data={src} ></object>
            </div>  :<> 
            <div onClick={()=>{if(showBox && mode!="view")setFileClick(true)}} className="Wrap_img_inputFile">{fileIconFormat(fileName)}</div></> )
          ) :
          <div onClick={()=>{if(showBox && mode!="view")setFileClick(true)}} className="Wrap_img_inputFile">
            <div className={`uploadIcon  ${mode == "view" ?'uploadIcon-disable':''}` }>
              <FontAwesomeIcon icon={[ICON_TYPE, "cloud-upload-alt"]} className="color-icon-dark" />
            </div>
          </div>
          }
         </>
       }
       </>
      }
    </>
  );
};
