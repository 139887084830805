import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { RangeSlider } from "components/RangeSlider/RangeSlider";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { getRate, putCustomerFeedBack } from "./api";


interface props {
    mode?: any
    hide?: () => void
    reload?: () => void
    Id?:any

}

export const CustomerFeedBackForm = ({ mode="create", hide,reload  , Id}:props) => {
    const [serviceRequest, setServiceRequest] = useState<any>(new Date().toISOString());
    const [rateRange, setRateRange] = useState<any>();
    const [resetForm, setResetForm] = useState<boolean>(false);

    const handleSubmit = (event) => {
        event.preventDefault()
        if (mode == "edit") {
          let IsDelive = event.target.checkbox.checked ;
          let CustomerFeedBackRate  = event.target.progressbar.value ;
          let CustomerFeedBackDescription = event.target.Description.value ;
          sendDataAsync(Id,IsDelive,CustomerFeedBackRate,CustomerFeedBackDescription)
         //notWork

          } else {
            let IsDelive = event.target.checkbox.checked ;
            let CustomerFeedBackRate  = event.target.progressbar.value ;
            let CustomerFeedBackDescription = event.target.Description.value ;
            sendDataAsync(Id,IsDelive,CustomerFeedBackRate,CustomerFeedBackDescription)
        }

    }


    async function sendDataAsync(Id,IsDelive,CustomerFeedBackRate,CustomerFeedBackDescription) {
        try {

          let res = await putCustomerFeedBack(Id,IsDelive,CustomerFeedBackRate,CustomerFeedBackDescription );
          hide && hide();
          reload && reload()
        }catch (err: any) {


         }
      }

    async function getRateAsync() {
        try {
          const res = await getRate( );
          setRateRange(res?.Data)
          
        } catch (err: any) {


         }
      }

      useEffect(() => {
        getRateAsync();
        if (Id){
        } 
      }, [Id]);




    return (
     <form onSubmit={handleSubmit}>
       <div className="m-2">                   
            <div className="card">
                <div className="card-body">
                <div className="row">
                  <div className="col mt-4 mb-4" >
                    <CheckableButton
                      defaultChecked={mode == "create" ? true :  (serviceRequest?.IsDefault)}
                      defaultValue={mode == "create" ? true :  (serviceRequest?.IsDefault)}
                      labelText={"OK"}
                      inputName={"checkbox"}
                      type="checkbox"
                      mode={mode}
                    />                                
                  </div>
                  <div className="col mt-3 pb-4 pr-0">
                    <RangeSlider Range={rateRange} required={true} type={"icon"} mode={mode} label="Satisfaction" defaultValue={serviceRequest?.Progress} name="progressbar" resetForm={resetForm} />
                  </div>
                  </div>
                 <div className="row">
                    <div className="col-10 col-sm-10">
                        <Textbox
                            textboxType="textarea"
                            textboxName="Description"
                            labelText="Description"
                            mode={mode}
                            resetForm={resetForm}
                            defaultValue={serviceRequest.Description}
                        />
                    </div>
                 </div>
                    <div className="row">
                        {mode != "view" ? (
                          <div className="d-flex justify-content-end">
                                <Button btnType="submit" value="save" />
                                <Button
                                    btnType="cancel"
                                    value="cancel"
                                    onClick={() => {hide && hide()}}
                                    />
                            </div>
                            ) : (<></>)
                           }
                    </div>
                </div>
              </div>

             </div>
            </form>
    )
}