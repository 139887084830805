import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import DatePicker from "components/DatePicker/DatePicker";
import { Textbox } from "components/Textbox/Textbox";
import {  useEffect, useState } from "react";
import { getEvent, sendEventsApi } from "./api";
import { Loading } from "components/Loading/loading";
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";



interface CalendarEventsFormPropsType {
    selectedObj;
    showForm: (e:boolean) => void;
    mode;
    relod: () => void,

}
export const CalendarEventsForm = ({ 
    selectedObj, 
    showForm,
    mode,
    relod

}: CalendarEventsFormPropsType) => {
   
    const [resetForm, setResetForm] = useState(false);
    const [response, setResponse] = useState<any>();
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [untilDate, setUntilDate] = useState<string | null>(null);
    const [loading, setLoading] = useState<any>(false);
    const [userChanges, setUserChanges] = useState({});
    const [flag,setFlag] = useState(false);

    async function sendNodeAsync(data ,method) {
        try{
           const res = await sendEventsApi(data , method);

           sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
           relod()
           showForm(false)
        }catch(err:any){

          }
        }
  
    async function getEventAsync() {
        setLoading(false)
        try{
            if(selectedObj.Id){
                const res = await getEvent(selectedObj.Id);
                /*               setUntilDate(res.Data.ValidityUntilDate)
                setFromDate(res.Data.ValidityFromDate) */
                setResponse(res.Data)    
            }else{
                setResponse(null) 
            }    
        }catch(err:any){
            
        }
        setLoading(true)
    }

    const validationDates = () =>{
        if(fromDate && untilDate && fromDate?.toString().slice(0,10) > untilDate?.toString().slice(0,10)){
            setFlag(true)
        }else{
            setFlag(false)
        }
    }
    
    const handlePersonContactFormSubmit = (event) => {
        event.preventDefault();
        if(flag){
            Toast(trans("InvalidEndDate"),"error")
        }else{
        if(mode=="edit"){
            let data={
                  "Id": selectedObj.Id,      
                   "Description": event.target.Description.value,
                   "CalendarType": +event.target.CalendarType.value,
                   "CalendarEventMonth": +event.target.CalendarEventMonth.value,
                   "CalendarEventDay": +event.target.CalendarEventDay.value,
                   "ValidityFromDate": fromDate,
                   "ValidityUntilDate": untilDate,
                   "IsHoliday": event.target.checkbox.checked,
                      
            }
  
            sendNodeAsync(data,"put")
          }else{
            let data={    
                 "Description": event.target.Description.value,
                 "CalendarType": +event.target.CalendarType.value,
                 "CalendarEventMonth": +event.target.CalendarEventMonth.value,
                 "CalendarEventDay": +event.target.CalendarEventDay.value,
                 "ValidityFromDate": fromDate,
                 "ValidityUntilDate": untilDate,
                 "IsHoliday": event.target.checkbox.checked,
                    
          }
            sendNodeAsync(data,"post")

        }

    }

    }
  

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                showForm(false)
            });
        }else{
            showForm(false)     
        }
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };

    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);
    
     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);
    
     useEffect(() => {
        if(selectedObj && mode != "create"){
         getEventAsync()
        }else{      
              setLoading(true)
        }
        
      }, [selectedObj,mode]);

      useEffect(()=>{
        validationDates()
      },[untilDate,fromDate])

    return (
        <div className="person-contact-form card">
            <div className="card-body">
                {loading  ?
                    <div className="container-fluid">
                    <form onSubmit={handlePersonContactFormSubmit}>
                       <div className="row ">
                        
                       
                      <div className="col-lg-3 col-sm-4 mt-1">
                        <EnumerationTypeSelect
                           Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                           required={true}
                           mode={mode}
                           name="CalendarType"
                           label="CalendarType"
                           enumTypeSelect='CalendarType'
                           value={response?.CalendarType}
                         />
                      </div>
                      <div className="col-lg-3 col-sm-3 mt-1">
                           <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             required={true}
                             textboxType="number"
                             textboxName="CalendarEventMonth"
                             labelText="CalendarEventMonth"
                             min={1}
                             max={12}
                             defaultValue={response?.CalendarEventMonth}
                             resetForm={resetForm}
                             mode={mode}
                            />
                      </div>
                      <div className="col-lg-3 col-sm-3 mt-1">

                          <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             required={true}
                             textboxType="number"
                             textboxName="CalendarEventDay"
                             labelText="CalendarEventDay"
                             min={1}
                             max={31}
                             defaultValue={response?.CalendarEventDay}
                             resetForm={resetForm}
                             mode={mode}
                            />
                      </div>
                      <div className="col-lg-1 col-sm-1 mt-4 mb-4" >
                            <CheckableButton
                               Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                               defaultChecked={response?.IsHoliday}
                               defaultValue={response?.IsHoliday}
                               labelText={"IsHoliday"}
                               inputName={"checkbox"}
                               type="checkbox"
                               mode={mode}
                            />                                
                        </div>
                    </div> 
                    <div className="row" >
                        <div className="col-lg-4 col-sm-5 mt-3 ">
                           <DatePicker
                               Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                               mode={mode}
                              resetForm={resetForm}
                              lable="ValidityFromDate"
                              setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                              val={ response?.ValidityFromDate ? response?.ValidityFromDate  : null} //resetForm ? new Date().toISOString() :
                              errorFlag={flag} 
                            />                          
                        </div>
                        <div className="col-lg-4 col-sm-5 mt-3 ">
                            <DatePicker
                               Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                               mode={mode}
                                resetForm={resetForm}
                                lable="ValidityUntilDate"
                                setIsoTime={(isoTime: string) => { setUntilDate(isoTime) }}
                                val={ response?.ValidityUntilDate ? response?.ValidityUntilDate  : null} //resetForm ? new Date().toISOString() : 
                                errorFlag={flag}
                                />                                
                        </div>
                    </div>
                        <div className="col-lg-8 mt-1">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             required={true}
                             textboxType="textarea"
                             textboxName="Description"
                             labelText="Description"
                             maxLength={900}
                             defaultValue={response?.Description}
                             resetForm={resetForm}
                             mode={mode}
                            />
                        </div>
                        {  mode !="view" ?
                            <div className="col-lg-12 mt-1 btns_form">
                            <Button value="Save" btnType="submit" />
                            <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                            </div>
                            :
                            <div className="col-lg-12 mt-1 btns_form">
                            <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                         </div>
                        }                        
                    </form>
                    </div>
                    :
                    <Loading/>                    
            }
            </div>
        </div>
    );
}