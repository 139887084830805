import { DataGridEditable } from "components/DataGridEditable/DataGridEditable";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import {
  getEditablecolumns,
  getPriceListDetail,
  postPriceListDetail,
  putPriceListDetail,
  removePriceListDetail,
} from "./api";
import { ModalForm } from "components/ModalForm/ModalForm";
import { PriceListDetailBulkAddForm } from "./PriceListDetailBulkAdd";
import { PriceListGroupEditForm } from "./PriceListGroupEditForm";
interface props {
  PriceListObj: any;
}

export const PriceListDetail = ({ PriceListObj }: props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const EditType = { False:0,All: 1, SingleRow: 2,Columns:3 };
  const [forceUpdate, setForceUpdate] = useState(false);
  const [response, setResponse] = useState<Response | any>(null);
  const [messageType, setMessageType] = useState<any>("info");
  const [pageNum, setPageNum] = useState<any>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pages, setPages] = useState<any>(0);
  const [search, setSearch] = useState(null);
  const [selectedObj, setSelectedObj] = useState<object | any>(null);
  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState<string | any>();
  const [editAllGrid, setEditAllGrid] = useState<any>(false);
  const [showGroupEdit, setShowGroupEdit] = useState(false);
  const [editablecolumns, setEditablecolumns] = useState(false);
  const [showPriceListBulkAdd, setShowPriceListBulkAdd] = useState(false);
  const [changedDataObj, setChangedDataObj] = useState<any>(null);
  const [selectList, setSelectList] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<any>([]);

  const [resetMultiSelect, setResetMultiSelect] = useState<any>(false);

  const getEditablecolumnsAsync = async () => {
    try {
      const res = await getEditablecolumns();
      setEditablecolumns(res.Data);
    } catch (error) {}
  };
  const changeingData = (data) => {
    if (data && Object.keys(data).length > 0) {
      setChangedDataObj(data);
    } else {
      setChangedDataObj(null);
    }
  };
  const saveChangeingData = async () => {
    if (Object.keys(changedDataObj).length > 0) {
      let PostlistData: any = [];
      let PutlistData: any = [];

      Object.keys(changedDataObj).map((item) => {
        if (changedDataObj[item].Id == null) {
          let data = {
            ...changedDataObj[item],
            PriceListId: PriceListObj.Id,
          };
          delete data.Operation;
          delete data.SelectAll;
          PostlistData.push(data);
        } else {
          let data = {
            ...changedDataObj[item],
            PriceListId: PriceListObj.Id,
          };
          delete data.Operation;
          delete data.SelectAll;
          PutlistData.push(data);
        }
      });
      try {
        if (PostlistData.length > 0) {
    ;

          await postPriceListDetail(PostlistData);
        }
      } catch (error) {}
      if (PutlistData.length > 0) {
        await putPriceListDetail(PutlistData);
      }
      setForceUpdate(!forceUpdate);
      setChangedDataObj(null);

      setEditAllGrid(EditType.False);

      try {
      } catch (error) {}
    }
  };

  const selectedIdCallback = (obj) => {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        setSelectedObj(obj);
        sessionStorage.setItem("changingforms", "false");
      });
    } else {
      setSelectedObj(obj);
    }
  };

  const handleNextPage = () => {
    if (pageNum < pages) {
      setPageNum(pageNum + 1);
      getPriceListDetailAsync(pageNum + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNum > 1) {
      setPageNum((pageNum) => {
        return +pageNum - 1;
      });
      getPriceListDetailAsync(pageNum - 1);
    }
  };
  const handleChangePageSize = (e: any) => {
    setPageNum(1);
    setPageSize(+e.currentTarget.value);
    getPageSize("PriceListDetail", e.currentTarget.value);
    getPriceListDetailAsync(1);
  };

  const handleGoPage = (value) => {
    if (value) {
      if (value < 1 || value > pages) {
      } else {
        setPageNum(+value);
        getPriceListDetailAsync(+value);
      }
    } else {
    }
  };

  const searchHandler = (str) => {
    setPageNum(1);
    if (str == "") {
      setSearch(null);
    } else {
      setSearch(str);
    }
  };

  const handleCreate = () => {
    setMode("create");
    setShowPriceListBulkAdd(true);
  };
  const handleEditRow = () => {
    setMode("edit");
    setShowForm(true);
  };

  async function getPriceListDetailAsync(PageNum = pageNum) {
    setLoading(false);
    setResponse(null);
    let pageSizeChangedByUser = getPageSize("PriceListDetail");
    setPageSize(pageSizeChangedByUser);
    try {
      await getEditablecolumnsAsync();
      const res = await getPriceListDetail(
        PriceListObj.Id,
        PageNum,
        pageSizeChangedByUser,
        orderBy.length == 0 ?null:  orderBy.join(","),
        search,
      );
      setPages(
        res.Data.TotalRecords > pageSizeChangedByUser
          ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser)
          : 1
      );
      setResponse(res.Data);
      setMessageType("info");
    } catch (error) {
      setMessageType("error");
    }
    setLoading(true);
  }
  const handleRemove = async (id = "") => {
    if (id) {
      setLoading(false);
      try {
        const res = await removePriceListDetail([id]);
        setForceUpdate(!forceUpdate);
      } catch (error) {}
      setLoading(true);
    } else {
      let removeListId = selectList.map((item) => {
        return item.Id;
      });
      setLoading(false);
      try {
        const res = await removePriceListDetail(removeListId);
        setForceUpdate(!forceUpdate);
      } catch (error) {}
      setLoading(true);
    }
  };
  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "print":
        alert("print");
        break;
      case "excel":
        downloadCSV(response?.Data, 1, trans("PriceListDetail"));
        break;
      case "refresh":
        setForceUpdate(!forceUpdate);
        setEditAllGrid(EditType.False)
        break;
      case "edit":
        if (selectedObj) {
          setEditAllGrid(EditType.SingleRow);
        }
        break;
      case "create":
        handleCreate();
        break;
      case "editgrid":
        setEditAllGrid(EditType.All);
        break;
      case "groupedit":
        setShowGroupEdit(true);
        break;
      case "help":
        alert("call api help");
        break;
      case "save":
        saveChangeingData();
        break;
      case "remove":
        if (selectList.length > 0) {
          Toast(trans("msg_deleteconfirm"), "warning", "alert", handleRemove);
        } else {
          Toast(trans("msg_deleteconfirm"), "warning", "alert", () =>
            handleRemove(selectedObj?.Id)
          );
        }
        break;
      case "cancel":
        setChangedDataObj(null);
        setEditAllGrid(EditType?.False);break;
    }
  };
  const selectListCallBack = (list) => {
    setSelectList(list[1]);
  };

  useEffect(() => {
    if (PriceListObj) getPriceListDetailAsync();
  }, [forceUpdate, search , orderBy]);

  return (
    <>
      {showPriceListBulkAdd && (
        <ModalForm
          mode={mode}
          title={trans("PriceListDetail") + " : " + PriceListObj?.Title}
          onHide={() => setShowPriceListBulkAdd(false)}
          show={showPriceListBulkAdd}
        >
          <PriceListDetailBulkAddForm
            saveWarehouseInPriceList={PriceListObj?.SaveWarehouseId}
            haveDetail={response.Data.length > 0 && response.TotalRecords > 0}
            reload={() => {
              setForceUpdate(!forceUpdate);
              setShowPriceListBulkAdd(false);
            }}
            mode={mode}
            onHide={() => {
              setShowPriceListBulkAdd(false);
            }}
            PriceListObj={PriceListObj}
          />
        </ModalForm>
      )}
      {showGroupEdit && (
        <ModalForm
          mode={mode}
          title={trans("GroupEdit") + " : " + PriceListObj?.Title}
          onHide={() => setShowGroupEdit(false)}
          show={showGroupEdit}
        >
          <PriceListGroupEditForm
            reload={() => {
              setForceUpdate(!forceUpdate);
              setShowGroupEdit(false);
            }}
            mode={mode}
            onHide={() => {
              setShowGroupEdit(false);
            }}
            PriceListObj={PriceListObj}
          />
        </ModalForm>
      )}

      <Toolbar
        editInGrid={true}
        search={changedDataObj != null ? false : true}
        save={changedDataObj != null ? true : false}
        emptyList={response?.TotalRecords == 0}
        handleSearch={searchHandler}
        id={selectedObj}
        view={false}
        create={true}
        remove={true}
        edit={true}
        cancel={editAllGrid}
        handleClickToolbar={clickToolbarCallback}
      />
      <div>
        <div className="card">
          <div className="p-0">
            {loading ? (
              response?.Data?.length > 0 ? (
                <DataGridEditable
                  RowKey={"Id"}
                  rowSelected={selectedObj}
                  changeingDataCallback={changeingData}
                  orderFormat={changedDataObj==null ? (order)=>{setOrderBy(order)  }:undefined}
                  orderFormatList={orderBy}
                  editAllGrid={editAllGrid}
                  editablecolumns={editablecolumns}
                  setEditAllGrid={setEditAllGrid}
                  multiSelect={true}
                  selectList={selectListCallBack}
                  resetMultiSelect={resetMultiSelect}
                  mode={mode}
                  handleDelete={handleRemove}
                  showForm={showForm}
                  defaultValueOption={{ "-1": trans("CallForPrice") }}
                  columnsWithOption={["Price"]}
                  parentName={"BulletinPriceListDetail"}
                  startIndex={6}
                  theadData={Object.keys(response.Data[0])}
                  tbodyData={response.Data}
                  minCellWidth={80}
                  selectedRowObjectCallback={selectedIdCallback}
                  totalRecords={response.TotalRecords}
                  pageNum={pageNum}
                  pageSize={pageSize}
                  HandleNextPage={handleNextPage}
                  HandlePrevPage={handlePrevPage}
                  handlechangePageSize={handleChangePageSize}
                  first={() => {
                    getPriceListDetailAsync(pages);
                    setPageNum(pages);
                  }}
                  end={() => {
                    getPriceListDetailAsync(1);
                    setPageNum(1);
                  }}
                  handleGoPage={handleGoPage}
                />
              ) : (
                <Message message={"NoDataForPreview"} type={messageType} />
              )
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
