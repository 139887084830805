import { INVENTORYANDSALES_URL } from "utils/constants";
import {  ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const AddNodeToParent = (data:any) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.GeographicalClassification.load,
    method: "post",
    data:data
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});



   
export const editNode = (data:any) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.GeographicalClassification.edit,
    method: "put",
    data : data
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

   
export const getNode = (id) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.GeographicalClassification.getId,
    method: "get",
    params : {
        Id : id
    }

}).then((res) => {

       return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const getFully = () => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.GeographicalClassification.browse,
    method: "get",

}).then((res) => {
   // Toast("Information received successfully" , "success")      
      return res.data
}).catch((err) => {
    return Promise.reject( err);
});
   
export const deleteNode = (id) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.GeographicalClassification.deleteNode,
    method: "delete",
    params : {
        Id : id
    }

}).then((res) => {

       return res.data
}).catch((err) => {
    return Promise.reject( err);
});
   