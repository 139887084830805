import { Widget } from "components/Widget/Widget";
import { getSalesInfo } from "pages/DashboardPage/api/api";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";

import { trans } from "utils/helpers";

interface props {
  id: string;

}

export const WidgetSales = ({ id }: props) => {

  const [response, setResponse] = useState<any>();
  const [progress1, setProgress1] = useState<any>(0);
  const [progress2, setProgress2] = useState<any>(0);
  


  async function getSalesInfoAsync() {
    try {
      const res = await getSalesInfo();
      setResponse(res.Data);

      //show 2 float number without rounding
      let monthly =
        ((res.Data.MonthlySale / res.Data.MonthlySaleTarget) * 100)
          .toString()
          .split(".")[0] +
        "." +
        ((res.Data.MonthlySale / res.Data.MonthlySaleTarget) * 100)
          .toString()
          .split(".")[1]
          .slice(0, 2);
      let yearly =
        ((res.Data.YearlySale / res.Data.YearlySaleTarget) * 100)
          .toString()
          .split(".")[0] +
        "." +
        ((res.Data.MonthlySale / res.Data.MonthlySaleTarget) * 100)
          .toString()
          .split(".")[1]
          .slice(0, 2);
      setProgress1(monthly);
      setProgress2(yearly);
    } catch (error) {
      // setResponse({});
    }
  }

  useEffect(()=>{
    getSalesInfoAsync()
  },[])

  return (
    <>
    {
    (  response?.YearlySaleTarget != null &&
      response?.MonthlySaleTarget != null) && 
      <div
      className="col-12 col-md-8 "
      key={"WidgetSales"}
      style={{ height: "277px" }}
    >
    <Widget
      removable={false}
      title="SalesTarget"
      icon={["fas", "money-bill"]}
      id={id}
      color="customerPrimaryColor"
    >
      {response ? (
        <>
        <div className="row px-2 my-2">
          <div className="col-6">
            {trans('monthlysale')}
          </div>
          <div className="col-6">{response?.MonthlySale}</div>
        </div>
        <div className="row px-2 ">
          <div className="col-6">
            {trans('monthlysaleCount')}
          </div>
          <div className="col-6 ">{response?.MonthlyInvoiceCount}</div>
        </div>
         {response?.MonthlySaleTarget && 
         <OverlayTrigger
            placement="bottom"
            delay={{ show: 300, hide: 0 }}
            overlay={
              <Tooltip className="tooltip" id={`button-tooltip-${"monthly"}`}>
                {response?.MonthlyTitle}
              </Tooltip>
            }
          >
            <div className="p-2">
              <small className="p-1 mb-1">
                {trans("monthlysaletargetpercent")}
              </small>
              <ProgressBar
                variant={
                  progress1 <= 50
                    ? "danger"
                    : progress1 > 50 && progress1 <= 75
                    ? "warning"
                    : progress1 > 75 && progress1 <= 100
                    ? "primary"
                    : "success"
                }
                label={progress1+'%'}
                now={+progress1}
              />
            </div>
          </OverlayTrigger>
      }


          <div className="row px-2 mb-2 mt-3">
          <div className="col-6">
            {trans('Yearlysale')}
          </div>
          <div className="col-6">{response?.YearlySale}</div>
        </div>
        <div className="row px-2 ">
          <div className="col-6">
            {trans('YearlysaleCount')}
          </div>
          <div className="col-6 ">{response?.YearlyInvoiceCount}</div>
        </div>

       { response?.YearlySaleTarget &&
         <OverlayTrigger
            placement="bottom"
            delay={{ show: 300, hide: 0 }}
            overlay={
              <Tooltip className="tooltip" id={`button-tooltip-${"yearly"}`}>
                {response.YearlyTitle}
              </Tooltip>
            }
          >
            <div className="p-2">
              <small className="p-1 mb-1">
                {trans("yearlysaletargetpercent")}
              </small>
              <ProgressBar
                variant={
                  progress2 <= 50
                    ? "danger"
                    : progress2 > 50 && progress1 <= 75
                    ? "warning"
                    : progress2 > 75 && progress1 <= 100
                    ? "primary"
                    : "success"
                }
                label={progress2+'%'}

                now={+progress2}
              />
            </div>
          </OverlayTrigger>
      }
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center text-info h-100">
          <div className="spinner-border spinner-border-md" role="status"></div>
        </div>
      )}
    </Widget>
    </div>
    }
    
    </>

  );
};
