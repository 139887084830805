import { Textbox } from "components/Textbox/Textbox"
import { Button } from 'components/Button/Button';
import "./style.scss";
import { useEffect, useState } from "react";
import { Response } from "types/types";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
interface Prop {
    handleSubmit: (e) => void,
    TextboxValue: string | undefined,
    resetForm: boolean,
    SelectBoxValue: number | undefined,
    cancel: () => void,
    TextBoxName: string,
    SelectBoxName: string
    Enum?: string
    IsDefault?: boolean
    mode?: any
    SystemKey?: any
    SystemKeyValue?: any
}
export const TypeSettingForm = ({ handleSubmit, SystemKey,IsDefault,SystemKeyValue,TextboxValue, resetForm, SelectBoxValue, cancel, TextBoxName, SelectBoxName, mode , Enum}: Prop) => {

    const [userChanges, setUserChanges] = useState({});



    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);



    // useEffect(() => {
    //     getEnum()
    //     return () => {
     
    //         setErrcode("")
    //       };
    // }, [TextboxValue]);

    useEffect(()=>{
        if(resetForm){
            setUserChanges({})

        }
    },[resetForm])


    return (
        <>                            
            <div className="card mb-4">
                    <form className="TypeSettingForm row card-body " onSubmit={handleSubmit}>
                    <div className="col-md-4 col-sm-12">
                        <Textbox  Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={TextBoxName} textboxType="text" resetForm={resetForm} labelText={"Description"} mode={mode}  defaultValue={TextboxValue} />
                    </div>  
                    {SystemKey &&
                       <div className="col-md-4 col-sm-12">
                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"systemKey"} textboxType="systemKey" resetForm={resetForm} labelText={"systemKey"} mode={mode}  defaultValue={SystemKeyValue} />
                      </div> 
            
                    }   

                    <div className="col-md-4 col-sm-12">
                    {mode != "create" && 
                         (<EnumerationTypeSelect
                          onChange={(e)=>{SelectBoxValue!= e.target.value? setUserChanges({...userChanges , ...{[SelectBoxName]:e.target.value}}) :  setUserChanges({...userChanges , ...{[SelectBoxName]:null}}) }}
                          name={SelectBoxName} 
                          label="status"
                          enumTypeSelect={Enum ? Enum : 'ActivityStatus'} 
                          mode={mode}  
                          value={SelectBoxValue} 
                          />)
                          }
                    </div>
                                <div className="col-lg-2 mt-4 mb-4" >
                    <CheckableButton
                       Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                       defaultChecked={IsDefault}
                       defaultValue={IsDefault}
                       labelText={"isdefault"}
                       inputName={"checkbox"}
                       type="checkbox"
                       mode={mode}
                    />                                
                    </div> 
                    {  mode !="view" ?
                          <div className="col-lg-12 mt-1 btns_form">
                          <Button value="Save" btnType="submit" />
                          <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                          </div>
                          :
                          <div className="col-lg-12 mt-1 btns_form">
                          <Button btnType="primary" value="Close" onClick={()=>{cancel()}}/>
                          </div>
                      }                        
                </form>

            </div >            
        </>
    )
}