

import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { downloadCSV, trans } from "utils/helpers";
import { useEffect, useState } from "react";

import { deletePaymentCoverageType, getAllPaymentCoverageType, getPaymentCoverageTypen, postPaymentCoverageType, putPaymentCoverageType } from "./api/api";
import { Message } from "components/Message/Message";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { Button } from "components/Button/Button";
import { Toast } from "components/ToastShow/ToastShow";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";


interface Form {
    Title: string,
    Status: number
}
export const PaymentCoverageTypeSetting = ({ }: Form) => {


    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [flag, setFlag] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [formLoading , setFormLoading] = useState<boolean>(false)
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [userChanges, setUserChanges] = useState({});





    const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }
    const handleCreateAction = () => {
        setUserChanges({})
        setEditForm(null)
        setMode("create")
        setShowForm(true)

    }
    const handleEditRow = () => {
        setMode("edit");
        setShowForm(true)

       
    }
    const handleDeleteRow = async(id: string = selectedObj.Id) => {
        try {
            
            const res = await deletePaymentCoverageType(id)
            setForceUpdate(!forceUpdate)

        } catch (error) {
            
        }
    }
    const handleViewRow = () => {
        setMode("view")
        setShowForm(true)

      
    }

    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false"); setUserChanges({}) });
        }else{
            setSelectedObj(obj)         
        };
    }

    const submit = (e) => {
        e.preventDefault();
        if (editForm == null) {
            postPaymentCoverageType({                     
                IsDefault: e.target.checkbox.checked,
                HasPayment: e.target.HasPayment.checked,
                Title: e.target.PaymentCoverageType.value, 
                SystemKey: e.target.systemKey.value, 
            
            }).then(() => {sessionStorage.setItem("changingforms" , "false");setUserChanges({}); getPaymentCoverageTypeAsync();cancel() });
        } else {
            putPaymentCoverageType({
                  Title: e.target.PaymentCoverageType.value,
                  ActivityStatus: +e.target.PaymentCoverageTypeSelect.value , 
                  systemKey: e.target.systemKey.value,
                  IsDefault: e.target.checkbox.checked,
                  HasPayment: e.target.HasPayment.checked,
                  Id: selectedObj?.Id,
             }).then(() => {sessionStorage.setItem("changingforms" , "false");setUserChanges({}); getPaymentCoverageTypeAsync();cancel() })
        }
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                setEditForm(null);
                setShowForm(false)
            });
        }else{
            setEditForm(null);
            setShowForm(false)     
        };
    }
    async function getPaymentCoverageTypeAsync() {
        setLoading(false)
        try {
            const res = await getAllPaymentCoverageType(search ,orderBy.length == 0 ?null:  orderBy.join(","));
            setResponse(res.Data);
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)
    }
    async function getPaymentCoverageType() {
        try {
            
            const data = await getPaymentCoverageTypen(selectedObj.Id);
            setEditForm({ Title: data.Data.Title, Status: data.Data.ActivityStatus,systemKey: data.Data.SystemKey,  IsDefault: data.Data.IsDefault,HasPayment: data.Data.HasPayment,
            });
        } catch (error) {
            
        }
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){
             sessionStorage.setItem("changingforms" , "true")
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
              window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, [userChanges]);

    useEffect(() => {
        getPaymentCoverageTypeAsync();
    }, [forceUpdate, flag  ,search , orderBy]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);
    useEffect(() => {
        if(showForm &&selectedObj && mode != "create"){
           getPaymentCoverageType()
        }else{
            setFormLoading(true)
        }
       
    }, [selectedObj ,showForm ,mode]);




    if (flag) {
        getPaymentCoverageTypeAsync()
        setFlag(false)

    }

    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,1 ,trans("PaymentCoverageTypeSetting"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id)selectedObj.SystemReserved ? Toast(trans("Msg_CanNotEditSystemReservedData") , "error") : handleEditRow(); break;
            case "help": alert("call api help"); break;
            case "remove":if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow ); break;

        }
    };
    /* #endregion */

    return (
        <>
            <div className="cartable-action">

                <Toolbar handleSearch={searchHandler}  id={selectedObj?.Id} emptyList={response == null || response?.length == 0} remove={true} create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card">
                    <div className="">
                                {showForm&& <>
                                     <>                                
                                     <div className="card mb-4">
                                    {formLoading ?
                                     <form className="TypeSettingForm  card-body " onSubmit={submit}>
                                         <div className="row">
                                
                                         <div className="col-md-4 col-sm-12">
                                             <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"PaymentCoverageType"} textboxType="text" resetForm={resetForm} labelText={"Description"} mode={mode}  defaultValue={editForm?.Title} />
                                         </div>     
                                         <div className="col-md-4 col-sm-12">
                                             <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"systemKey"} textboxType="systemKey" resetForm={resetForm} labelText={"systemKey"} mode={mode}  defaultValue={editForm?.systemKey} />
                                         </div> 
                                         {mode != "create" &&          
                                          <div className="col-lg-4 ">
                                          <EnumerationTypeSelect
                                           Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                                           name="PaymentCoverageTypeSelect"
                                           enumTypeSelect="ActivityStatus"  
                                           mode={mode} 
                                           label="Status"
                                           value={editForm?.Status} 
                                           />
                                          </div>                            
                                          }
                                      </div>
                                        <div className="row">
                                         <div className="col-lg-2 mt-4 mb-4" >
                                        <CheckableButton
                                           Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                           defaultChecked={editForm?.IsDefault}
                                           defaultValue={editForm?.IsDefault}
                                           labelText={"isdefault"}
                                           inputName={"checkbox"}
                                           type="checkbox"
                                           mode={mode}
                                        />                                
                                        </div>    
                                        <div className="col-lg-2  mt-4 mb-4" >
                                       <CheckableButton
                                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                          defaultChecked={editForm?.HasPayment}
                                          defaultValue={editForm?.HasPayment}
                                          labelText={"HasPayment"}
                                          inputName={"HasPayment"}
                                          type="checkbox"
                                          mode={mode}
                                       />                                
                                       </div>
                                        </div>    
                                        {  mode !="view" ?
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button value="Save" btnType="submit" />
                                              <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                                              </div>
                                              :
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                                              </div>
                                          }                        
                                        </form>
                                      : 
                                      <Loading/>}
                                      </div >
                                    </>
                                
                                </>
                                }
                                {loading ?
                                <>
                                {
                                response?.length > 0 ?
                                    <>
                                        <DataGrid
                                            parentName={"PaymentCoverageTypeSetting"}
                                            orderFormat={setOrderBy}
                                            orderFormatList={orderBy}
                                            startIndex={1}
                                            theadData={Object.keys(response[0])}
                                            tbodyData={response}
                                            minCellWidth={80}
                                            scrollHeight={showForm? "25vh": "67vh"}
                                            selectedRowObjectCallback={selectedIdCallback}
                                            handleEdit={handleEditRow}
                                            handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,()=> handleDeleteRow(e))}}
                                            handleView={handleViewRow}
                                        />
                                    </>
                                    : <Message message={catchMessage.msg} type={catchMessage.type} />
                                }
                                </>
                                 : 
                                <Loading/>}
                                
                    </div>
                </div>
            </div>
        </>

    );
}