import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { trans } from "utils/helpers"
import { getProcess } from "./api"
import "./style.scss"
import { ICON_TYPE } from "utils/constants"
interface ButtonToolbarSecretariatFilter {
    onClick?: () => void,
    Callback: (id: any) => void,
    positionId?: string,
}
export const ButtonToolbarSecretariatFilter: React.FC<ButtonToolbarSecretariatFilter> = ({ onClick, Callback, positionId }) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    const buttonToolbarProcessStarting = useRef(null);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [options, setOptions] = useState<any>()
    const [selectoptions, setSelectOptions] = useState<any>()
    const [loading, setLoading] = useState<any>()
    const [rotate , setRotate] = useState<boolean>(false);

    function showSubMenuFunction() {
        setShowSubMenu(true)
    }
   async function getDataAsync() {
    setLoading(false)

        try {
            
            const res = await getProcess()
            let option:any = []
            if (res.Data){
     
                 option = Object.keys(res.Data).map(item=>{
                    return {Id:item ,Name : res.Data[item] }
                })
            }
             option.push({Id:"" ,Name :"All" })
            let selected =  localStorage.getItem("SecretariatFilter")
            if(selected){
             setSelectOptions(selected)
     
            }
             setOptions(option)
        } catch (error) {
            
        }
        setLoading(true)

    }
    function hideSubMenuFunction() {
        setShowSubMenu(false)
    }
    function selectboxValSetter(event) {
        setSelectOptions(event.currentTarget.getAttribute('data-value'))
        localStorage.setItem("SecretariatFilter" ,event.currentTarget.getAttribute('data-value') )
        Callback && Callback(event.currentTarget.getAttribute('data-value'));
        setShowSubMenu(false)
        if (buttonToolbarProcessStarting.current) (buttonToolbarProcessStarting.current as HTMLButtonElement).blur();
    }
    useEffect(() => {
        if(showSubMenu &&options==null){
            getDataAsync()
        }
    }, [showSubMenu]);


    return (
        <>  
        <div className="toolbar-btn-wrapper">
            <button ref={buttonToolbarProcessStarting} onClick={() => { }} onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction} className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
                <div className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>F6</div>
                <FontAwesomeIcon icon={['fas', 'filter']} width="20px" height="20px" />
                <small>{trans("Secretariat")}</small>
                <div 
                style={{
                    width: "max-content",
                    maxWidth: "inherit",
                    maxHeight: "62vh",
                    overflowY: "scroll"
                }}
                className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`}>
                {loading?
                    <>
                    {options && options.map((option, index) => (
                        selectoptions && option.Id ==selectoptions  ?
                        <div key={index} className="toolbar-btn-sub-menu__option selected"  data-value={`${option.Id}`} onClick={selectboxValSetter}>{trans(option.Name)}</div>
                       :
                        <div key={index} className="toolbar-btn-sub-menu__option" data-value={`${option.Id}`} onClick={selectboxValSetter}>{trans(option.Name)}</div>
                    ))}

                    </>
                        :
                        <div  className="sync-box-icon mt-4" onClick={()=>{setRotate(true);  setTimeout(()=>{ setRotate(false) }, 1500)}}   >       
                           <FontAwesomeIcon icon={[ICON_TYPE , "sync"] } className={`text-primary  ${rotate ? "Rotate" : "" } `}  />  
                        </div>  
                    }
                </div>
            </button>
        </div>
        
        </>
    )
    }