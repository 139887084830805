import { Widget } from "components/Widget/Widget";
import { getProfile } from "layouts/DashboardLayout/components/Header/component/profile/api";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProfileDTO } from "types/types";
import './style.scss';

interface props {
    id: string
}

export const WidgetPositions = ({ id }: props) => {
    const [userPositions, setUserPositions] = useState<any>([]);
    let layoutContext = useContext(LayoutContext);

    async function getProfileAsync() {
        try {
        const res: ProfileDTO = await getProfile();
        setUserPositions(res.ProfileList)
            
        } catch (error) {
            
        }
    }

    function changeCurrentUserPosition(item) {
        layoutContext.setCurrentUserPosition(item);
        localStorage.removeItem("CurrentApplication");

        sessionStorage.setItem("accept-data", item.PositionId);
    }

    useEffect(() => {
        getProfileAsync()
    }, []);

    return (
        <Widget  title="Cartable" icon={['fas', 'user']} color='info' id={id}>
            {userPositions?.length > 0 ?
                <div className="widget-positions p-2">
                    <ul>
                        {userPositions && userPositions.map(item => (
                            <li key={item.Id} className="mb-1">
                                <Link onClick={() => { changeCurrentUserPosition(item) }} to={"/cartable/current"}>
                                    <span style={{fontSize: "13px"}}>{item.Description}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div> :
                <div className="d-flex justify-content-center align-items-center text-info h-100">
                    <div className="spinner-border spinner-border-md" role="status"></div>
                </div>
            }
        </Widget>
    )
}