import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getAllBranch = (async (orderFormat:string ="" , search)=> await http({
    url: ENDPOINTS.Management.Branch.browse,
    method: 'get',
    params: {
        searchString:search,
        orderBy : orderFormat,
        }
        
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)

export const getBranch = (async (id) => await http({
    url: ENDPOINTS.Management.Branch.load,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: { Id: id }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)


export const PostBranch = (data) => http({
    url: ENDPOINTS.Management.Branch.browse,
    method: 'post',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})



export const PutBranch = (data) => http({
    url: ENDPOINTS.Management.Branch.browse,
    method: 'put',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})


export const DeleteBranch = (id) => http({
    url: ENDPOINTS.Management.Branch.browse,
    method: 'DELETE',
    params: {
       id :id
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})
