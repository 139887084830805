
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";

import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { downloadCSV, trans } from "utils/helpers";
import { ItemTaxesForm } from "./ItemTaxesForm";
import { getAllItemTaxes, postTax, removeItemTaxes } from "./api";


interface ItemTaxesPropsType {
    onHide?: () => void;
    getItemTaxes?: (data) => void;
    mode:any;
    inTab?:any;
    IsIndependent?:any;
    Id?:any;
    
}

export const  ItemTaxes = ({getItemTaxes,IsIndependent=false , Id ,mode,inTab ,onHide}:ItemTaxesPropsType) => {


    const [response, setResponse] = useState<Response | any>(null);
    const [ItemTaxesId, setItemTaxesId] = useState<string | any>(null);
    const [ItemTaxesmode, setItemTaxesMode] = useState<string>();
    const [orderFormat, setOrderFormat] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [showItemTaxesesForm, setShowItemTaxesesForm] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [ItemTaxeslist, setItemTaxeslist] = useState<any>([]);
    const [selectList , setSelectList] = useState<any>([])
    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)
    const [forceUpdate, setForceUpdate] = useState(false);

    let customFuction: any = []


    
    const clickToolbarCallback = (clickedButtonName: string) => {

        switch (clickedButtonName.toLowerCase()) {
            case "finish": alert("call api finish " ); break;
            case "current": alert("call api current " ); break;
            case "archive": alert("call api archive " ); break;
            case "refresh": setForceUpdate(!forceUpdate); break;

            case "view": if (ItemTaxesId) { setItemTaxesMode("view");  } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "print": alert("print ");break;
            case "excel": downloadCSV(ItemTaxeslist ,1 ,trans("ItemTaxes"));break;
            case "create": setShowItemTaxesesForm(true);break;
            case "remove":if(selectList.length >0){Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleRemove)}else{Toast("Msg_SelectRow" , "warning")}  ;break;

        }
    };

    const addItemTaxestoList =async (newItems) => {        
        if(IsIndependent){
          
            let tax={
                "ItemId": Id,
                "TaxTypeItem": +newItems.TaxTypeItemEnum,
                "TaxRate": newItems.TaxRate
            }
            sendItemTaxAsync(tax)


        }else{

            let newItemTaxesList = ItemTaxeslist
            
            setLoading(false)     
            
            if(ItemTaxeslist.length == 0){
                setItemTaxeslist([newItems])
                setResetMultiSelect(!resetMultiSelect)
                setSelectList([])
                getItemTaxes&& getItemTaxes([newItems])
            }else{         
                let checkList= ItemTaxeslist.find(p => p.Id == newItems.Id)
                    if(!checkList){
                        newItemTaxesList = [...newItemTaxesList,newItems]
                    }else{
                        Toast("msg_delete_useddata","error")
                    }
                 setItemTaxeslist(newItemTaxesList)
                 setResetMultiSelect(!resetMultiSelect)
                 setSelectList([])            
                 getItemTaxes&& getItemTaxes(newItemTaxesList)
            } 
            setShowItemTaxesesForm(false)
            setLoading(true)
        }
    }

    const handleRemove =async()=>{
        setLoading(false)
        let removeListId=selectList.map(item=>{return item.Id})
        if(IsIndependent){
            setLoading(false)
            try {
              const res = await removeItemTaxes(removeListId)
              setForceUpdate(!forceUpdate)
            } catch (error) {
              
            }
            setLoading(true)
        }else{
            let newList:any = []
            ItemTaxeslist.map(item=>{
                if(!removeListId.includes(item.Id)){
                    newList.push(item)
                }
            })
            setResetMultiSelect(!resetMultiSelect)
            setSelectList([])
            setItemTaxeslist(newList)
            getItemTaxes&&getItemTaxes(newList)
        }
        setLoading(true)

      
   
  }
    const getWithOrder = (order: string[]) => {
        setOrderFormat(order.join(","))
    }
    const selectedRowCallback = (obj, index) => {
        setItemTaxesId(obj?.Id);
    };
    const selectListCallBack =(list)=>{        
        setSelectList(list[1])
      }

      const sendItemTaxAsync =async (item) => {
        setLoading(false)
        try {
          const res = await postTax(item)
          setShowItemTaxesesForm(false)
          setForceUpdate(!forceUpdate)
        } catch (error) {
          
        }
        setLoading(true)
    }



    
      
    async function getItemTaxeslistAsync() {
        setLoading(false)
        try {                                                     
            const res = await getAllItemTaxes(Id)
            setItemTaxeslist(res.Data)
           setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }
    useEffect(() => {
        setResponse([])
        if((inTab ==="ItemTaxes"||IsIndependent)&& mode!="create"){
            getItemTaxeslistAsync()
        
        }
        
    }, [forceUpdate ,inTab]);
    
    useEffect(() => {

        if(inTab ==="ItemTaxes"){
        if(ItemTaxeslist?.length >0){ 
          let newList:any = []          
          ItemTaxeslist.map(item=>{
                    if(item != undefined){
                        newList.push(item) 

                    }
            })
            setItemTaxeslist(newList)
            getItemTaxes&& getItemTaxes(newList)
        }
        setLoading(true)
        }
    }, [ inTab]);
    

   
    /* #endregion */

    return (
        < >
        {(inTab ==="ItemTaxes"||IsIndependent) && 
        <div className="p-2">

        {mode != "view" &&
          <Toolbar warehouse={false}   create={mode != "view"} remove={mode!= "view"}  emptyList={ItemTaxeslist == null || ItemTaxeslist?.length == 0} id={ItemTaxesId}   search={false} edit={false} refresh={IsIndependent}  view={false}  handleClickToolbar={clickToolbarCallback} />
        }   
         {loading ?
            <div className="col-lg-12">
                <div className="list-card card">
                    <div className="list-card__body">
                        {
                            (ItemTaxeslist?.length > 0 ?
                                <DataGrid
                                    customFuction={customFuction}
                                    selectItem={(e) => {   }}
                                    orderFormat={getWithOrder}
                                    multiSelect={mode!="view"}
                                    selectList={selectListCallBack}
                                    resetMultiSelect={resetMultiSelect}
                                    parentName={"ItemTaxes" }
                                    startIndex={IsIndependent?1:mode=="view"?1:2}
                                    theadData={Object.keys(ItemTaxeslist[0])}
                                    scrollHeight={"350px"}
                                    tbodyData={ItemTaxeslist}
                                    minCellWidth={80}
                                    selectedRowObjectCallback={selectedRowCallback}
                                
                                />
                                : <Message message={"NoDataForPreview"} type={messageType} />)
                        }
                    </div>
                </div>
            </div>
             : <Loading />
         }
             {showItemTaxesesForm &&
             <ModalForm mode={ItemTaxesmode} title="Tax" onHide={() => setShowItemTaxesesForm(false)} show={showItemTaxesesForm}>
                 <ItemTaxesForm ItemTaxeslistcallback={(e)=>{addItemTaxestoList(e)}}  onHide={() => setShowItemTaxesesForm(false)} />
             </ModalForm>
             }
        </div>
        }
        </>
    )
}