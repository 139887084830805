import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { TableTree } from "components/TableTree/TableTree";
import { useEffect, useState } from "react";
import {  JobEntireTree } from "./api/api";
interface ReferenceHistoryPropsType {
    jobId: any,
}

export const ReferenceHistory = ({
    jobId,
  
}: ReferenceHistoryPropsType) => {

    const [response , setResponse]  = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");

    async function jobEntireTreeAsync(jobId) {
        setLoading(false)
        try {
            const res =  await JobEntireTree(jobId  )
           setResponse(res.Data)
        } catch (error) {
            setResponse([])
            setMessageType("error")
        }
        setLoading(true)
    }

    useEffect( () => {
        jobEntireTreeAsync(jobId)
    }, [jobId]);
    return (
    <>
        <div className=" mx-4" >
        {
        loading ? (
            response ?
            <TableTree
                italic={"Reversed"}
                params={"Id"}
                Data={[response]}
                theadData={Object.keys(response)}
                parentName={"JobReferenceHistory"}
                startIndex={4}
                Childrenparams={"ReferenceList"}
                defultShow={true}
                /> 
                : <Message message={"NoDataForPreview"} type={messageType} />       ) 

            : <Loading />
          }
        </div>        
    </>
    );
}