import { Toast } from "components/ToastShow/ToastShow";
import { REPORT_URL } from "utils/constants";
import { ENDPOINTSREPORT } from "utils/endpoints";
import { http } from "utils/http";

export const getReports = (scope) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.tree,
    method: 'get',
     params: {
        scope: scope
     }
}).then((response) => (response.data))

export const deleteReport = (id) => http({
   baseURL:REPORT_URL ,
   url:ENDPOINTSREPORT.Report.report,
   method: 'delete',
    params: {
       id: id
    }
}).then((response) => (response.data))