import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getIndicatorNoDictionaryApi = (async (letterType, secretariatId ,search) => await http({

    url: ENDPOINTS.secretariat.indicator.dictionaryReserved,
    method: 'get',
    params: {
        letterType: letterType,
        secretariatId: secretariatId,
        searchString:search
    }
})
    .then((respons) => (respons.data))
)
