
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";



export const  getGroupPositionList =( async ( SearchString) =>  await http({
    url: ENDPOINTS.BaseInformation.groupPosition.dictionary,
    method: "get",
    params:{
        SearchString : SearchString
    }

    

})
.then((respons) => (respons.data))
)
export const  getPositionList =( async (SearchString ) =>  await http({
    url: ENDPOINTS.BaseInformation.position.browse,
    method: "get",
    params : {
        searchString : SearchString
    }

    

})
.then((respons) => (respons.data))
)


export const  getorganizationalArchiveAccess =( async (id) =>  await http({
    url: ENDPOINTS.archive.organizationalArchiveAccess.browse,
    method: "get",
    params:{
        OrganizationalArchiveId : id
    }
})
.then((respons) => (respons.data))
)
export const  sendorganizationalArchiveAccess =( async (data  ,method) =>  await http({
    url: ENDPOINTS.archive.organizationalArchiveAccess.browse,
    method: method,
    data:data,
})
.then((respons) => (respons.data))
)

export const  DeleteOrganizationalArchive =( async (id) =>  await http({
    url: ENDPOINTS.archive.organizationalArchive.browse,
    method: "delete",
    params:{
        Id : id
    }
})
.then((respons) => (respons.data))
)

export const  DeleteOrganizationalArchiveDetail =( async (id) =>  await http({
    url: ENDPOINTS.archive.organizationalArchiveDetail.browse,
    method: "delete",
    params:{
        Id : id
    }
})
.then((respons) => (respons.data))
)





export const  getTaskUser =( async (OrganizationalArchiveId ) =>  await http({
    url: ENDPOINTS.archive.organizationalArchiveAccess.browse,
    method: "get",
    params:{
        OrganizationalArchiveId:OrganizationalArchiveId 
    }
   
  
})
.then((respons) => (respons.data))
)
