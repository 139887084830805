import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const  getInvitedPersonType =( async () =>  await http({
    url: ENDPOINTS.enumeration,
    method: 'get',
    params: {
        enumerationType :"InvitedPersonType"
    }
})
.then((respons) => (respons.data))
)
