
import { http } from "utils/http";

import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { ENDPOINTS } from "utils/endpoints";


export const sendPriceList = (data , method) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListApis.browse,
    method: method,
    data:data
}).then(response => response.data)
    .catch(err => {
        return Promise.reject(err);
    })
export const clonePriceList = (data ,sourcePriceListId) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListApis.clone,
    method: "post",
    params:{
        sourcePriceListId
    },
    data:data
}).then(response => response.data)
    .catch(err => {
        return Promise.reject(err);
    })
export const deletePrice = (id) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListApis.browse,
    method: "DELETE",
    params:{
        id:id
    }
}).then(response => response.data)

.catch(err => {
        return Promise.reject(err);
    })


export const getPriceListId = (id) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListApis.id,
    method: "get",
    headers: { "Accept-Language": "en" },
    params:{
        Id:id,
    }

}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })
export const getPriceListConstraint = (id) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListApis.constraint,
    method: "get",
    params:{
        Id:id,
    }

}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })
export const getPriceList= (pageNumber , pageSize, orderFormat: string = "", searchString?: any, filterlist?: any) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListApis.browse,
    method: "get",
    params:{
        PageNumber:pageNumber,
        PageSize:pageSize,
        OrderByString: orderFormat,
        SearchString: searchString,
        FilterString: filterlist
    }

}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })



export const sendPriceAccess = (data , id) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListApis.access,
    method: "put",
    data:data,
    params:{
        PriceListId:id
    }

}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })
export const getPriceAccess = (PriceListId) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListApis.access,
    method: "get",
    params:{
        PriceListId:PriceListId
    }

}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })


 //----------------------ACCESS---------------------
 
export const  getGroupPositionList =( async ( searchString) =>  await http({
    url: ENDPOINTS.BaseInformation.groupPosition.dictionary,
    method: "get",
    params:{
        SearchString : searchString
    }

    

})
.then((response) => (response.data)) 
.catch(err => {
    return Promise.reject(err);
})
)

export const  getPositionList =( async (searchString ) =>  await http({
    url: ENDPOINTS.BaseInformation.position.browse,
    method: "get",
    params : {
        searchString : searchString
    }

    

})
.then((response) => (response.data)) 
.catch(err => {
    return Promise.reject(err);
})
)


export const getPriceListDictionary = (async (value=null) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListApis.listDictionary,
    method: 'get',
   params:{
    Id:value
   }
   
})
    .then((response) => (response.data))

)

