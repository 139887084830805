import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { Toast } from "components/ToastShow/ToastShow";
import { GroupPositionForm } from "./components/GroupPositionForm/GroupPositionForm";
import { PositionAssigningToGroup } from "../PositionAssigningToGroup/PositionAssigningToGroup";
import { deleteGroupPosition, getOtherGroups } from "./components/api/api";

export const OtherGroups = () => {
  const [forceUpdate, setForceUpdate] = useState(true); // integer state
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<Response | any>();
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [showFormData, setShowFormData] = useState<boolean>(false);
  const [showFormMembers, setShowFormMembers] = useState<boolean>(false);
  const [selectedObj, setSelectedObj] = useState<any>();
  const [mode, setMode] = useState<any>()
  const [messageType, setMessageType] = useState<any>("info")
  const [orderBy, setOrderBy] = useState<any>([]);
  const [filterlist, setFilterlist] = useState<any>("");
  const [search, setSearch] = useState(null);
  const [pages, setPages] = useState<number>(0);


  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "reference":alert("call api reference with Id :");break;
      case "view": if (selectedObj?.Id) handleViewRow(); break;
      case "edit": if (selectedObj?.Id && !selectedObj?.SystemReserved ) handleEditRow();break;
      case "print":alert("print ");break;
      case "excel":downloadCSV(response.Data ,1 ,trans("OtherGroups"));break;
      case "remove": if (selectedObj?.Id) Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
      case "refresh":setForceUpdate(!forceUpdate);break;
      case "members": if (selectedObj?.Id){setShowFormData(false); setShowFormMembers(!showFormMembers); setMode("members")}; break;
      case "create":
        setMode("create");
        setShowFormData(true);
        break;
    }
  };


  

  const searchHandler = (str) => {
    setPageNum(1);
    if(str =="" ){
        setSearch(null)
    }else{
        setSearch(str)
    }
  }
  async function getOtherGroupsAsync(PageNum = pageNum) {
    setLoading(false);
    setResponse(null);
    let pageSizeChangedByUser = getPageSize("OtherGroups" );
    setPageSize(pageSizeChangedByUser)
    try {
      const res = await getOtherGroups(PageNum,  pageSizeChangedByUser,orderBy?.join(",") ,search ,filterlist);
      setResponse(res.Data);
      setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
      setMessageType("info")
    } catch (error) {
      setMessageType("error")
    }
    setLoading(true);
  }


  const handleChangePageSize = (e: any) => {
    setPageNum(1);
    setPageSize(e.currentTarget.value);
    getPageSize("OtherGroups" ,e.currentTarget.value )
    getOtherGroupsAsync(1)
  };
  const handleGoPage = (value) => {
    if (value) {
      if (value < 1 || value > pages) { }
      else setPageNum(+value);getOtherGroupsAsync(+value);
    } else {

    }
  };
  const handleNextPage = () => {
    if(pageNum < pages){
      setPageNum(pageNum + 1);
      getOtherGroupsAsync(pageNum + 1);
    }
  };
  const handlePrevPage = () => {
    if(pageNum > 1){
      setPageNum((pageNum)=>{return +pageNum - 1});
      getOtherGroupsAsync(pageNum - 1);
    }
  };

  const groupPositionIdCallback = (obj, index) => {
    if(sessionStorage.getItem("changingforms")+""=="true"){
      Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{   
      if(mode=="edit"&& obj.SystemReserved){
        setShowFormData(false)
      }
      setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false")});
  }else{
    if(mode=="edit"&& obj.SystemReserved){
      setShowFormData(false)
    }
      setSelectedObj(obj)         
  };
  }

  const handleViewRow = () => {
    setMode("view");
    setShowFormMembers(true)
  }

  const handleEditRow = () => {
    setMode("edit")
    setShowFormData(true);
  }

  const handleViewModalForm = () => {
    setShowFormMembers(false)
    setShowFormData(false)
  }
  const handleCancelForm = (clickedCancelBtn) => {
    setShowFormData(false);

  }
  const handleDeleteRow = async() => {
    try {
        await deleteGroupPosition(selectedObj?.Id)
         setForceUpdate(!forceUpdate)
    } catch (error) {
        
    }
}
  
  useEffect(() => {
    getOtherGroupsAsync();
    setForceUpdate(true)
  }, [forceUpdate,search,orderBy,filterlist]);

  return (
    <>

      <Toolbar
        id={selectedObj?.Id} handleSearch={searchHandler} handleClickToolbar={clickToolbarCallback} emptyList={response?.TotalRecords == 0}
        search
        remove
        members
        create
        edit
      />
      {showFormData ?
      <ModalForm modalHeight={30} mode={mode} onHide={() =>{setShowFormData(false)}} show={showFormData} title={"GroupPositionForm" } ><GroupPositionForm mode={mode} reload={()=>setForceUpdate(!forceUpdate)} idGroupPosition={selectedObj} handleCancelForm={handleCancelForm} /></ModalForm>: null}
      {showFormMembers ? <ModalForm modalHeight={80} mode={mode} onHide={() =>{setShowFormMembers(false)}} modalWidth={mode == "view" ? 75 : ""} show={showFormMembers} modalProfileKey={"Users"}    title={trans("Users")+ " : " +selectedObj?.Title } > <PositionAssigningToGroup PositionId={selectedObj} mode={ mode} showModal={setShowFormMembers} setUpdate={setForceUpdate} /></ModalForm> : null}
      <div className="col-lg-12 mt-0" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
        <div className="card">
            {loading ? (
              <>
                { response?.Data?.length > 0 ? (
                  <DataGrid
                    orderFormat={setOrderBy}
                    orderFormatList={orderBy}
                    handlefiltering={setFilterlist}
                    filterStr={filterlist}
                    parentName={"OtherGroups"}
                    theadData={Object.keys(response.Data[0])}
                    minCellWidth={80}
                    tbodyData={ response.Data}
                    selectedRowObjectCallback={groupPositionIdCallback}
                    handleView={handleViewRow}
                    handleEdit={handleEditRow}
                    handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                    totalRecords={response.TotalRecords}
                    pageNum={pageNum}
                    pageSize={pageSize}
                    HandleNextPage={handleNextPage}
                    HandlePrevPage={handlePrevPage}
                    handlechangePageSize={handleChangePageSize}
                    first={() =>{ getOtherGroupsAsync(pages);setPageNum(pages)}}
                    end={() => {getOtherGroupsAsync(1);setPageNum(1)}}
                    handleGoPage={handleGoPage}
                  />
                ) : <Message message={"NoDataForPreview"} type={messageType} />}
              </>
            ) : (
              <Loading />
            )}
          </div>
          

          <Message message={"OtherGroupsdescription"} type={"info"} />
          <Message message={"SystemReservedGroupDescription"} type={"info"} />
      </div>
    </>
  );
};
