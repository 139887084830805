import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { sendFeedApi } from "./api";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { REPORT_URL } from "utils/constants";
import { ENDPOINTSREPORT } from "utils/endpoints";

interface FeedApiFormPropsType {
    id?: any,
    mode?: any,
    onHide: () => void
    reset: (ans: boolean) => void
}

export const FeedApiForm = ({
    id,
    mode,
    onHide,
    reset,
}: FeedApiFormPropsType) => {

    let data, method;
    const [FeedApi, setFeedApi] = useState<any>(undefined);

    const [resetForm, setResetForm] = useState(false);
    const formRef = useRef<any>("")
    const buttonRef = useRef()
    const [loading , setLoading] = useState(false)  



    async function formSubmit(event) {
        event.preventDefault();

        let url = formRef.current.FeedApiUrl.value.includes("api/") ? "#"+ formRef.current.Service.value +"#/"+ formRef.current.FeedApiUrl.value :"#"+ formRef.current.Service.value +"#api/"+ formRef.current.FeedApiUrl.value  
            if (id && mode !== "create") {
                data = {
                    Id: id,
                    Title: formRef.current.Title.value,
                    SystemKey: formRef.current.SystemKey.value,
                    FeedApiUrl:url,
                };
                method = "put";
            } else {
                data = {

                    Title: formRef.current.Title.value,
                    SystemKey: formRef.current.SystemKey.value,
                    FeedApiUrl: url,
                };
                method = "post";
                
            }        
            try {
               await sendFeedApi(data, method);
               setResetForm(true);
               reset(true);
               onHide();

            } catch (error) {

            }
        
    
    }
    
    async function getDataAsync() {
        setLoading(false)
        try {
         //  const res  = await getFeedApi(id); 
         //  setFeedApi(res.Data);  
         //  setleaveHours( res.Data.ActualDuration)
            } catch (error) {
            // setFeedApi(null); 
        }
        setLoading(true)

    }

    function cancel() {
            onHide()
        
    }


    useEffect(() => {
        if (id && mode !== "create"){
            getDataAsync()
        }else{
            setFeedApi(null)
            setLoading(true)
            
        }
    }, [id , mode]);

    useEffect(() => {
        if ( mode === "create"){
            setResetForm(true);
        }
    }, [ mode]);

    useEffect(() => {
        if (resetForm) {
          
            setResetForm(false);
        }

    }, [resetForm]);


    return (
        <form  className="" ref={formRef} >
            {loading ? 
            <div className="card">
                <div className="card-body">
                    <div className="container">
                        <div  className="row">
                            <div className="col-4">
                                <Textbox
                                    autoFocus={true}
                                    textboxType="text"
                                    textboxName="Title"
                                    defaultValue={FeedApi?.Title}
                                    resetForm={resetForm}
                                    labelText="Title"
                                    required={true}
                                    mode={mode}
                                />
                            </div>
                            <div className="col-4">
                                <Textbox
                                    textboxType="systemKey"
                                    textboxName="SystemKey"
                                    defaultValue={FeedApi?.SystemKey}
                                    resetForm={resetForm}
                                    labelText="SystemKey"
                                    required={true}
                                    mode={mode}
                                />
                            </div>
                            <div className="col-4">
                                <DictionarySelectBox
                                mode={mode} 
                                revert={true}
                                value={FeedApi?.FeedApiUrl} 
                                name="Service"
                                label="Service"
                                baseUrl={REPORT_URL}
                                endPoint={ENDPOINTSREPORT.ReportFeed.service}
                               />
                            </div>
                            <div className="col-4">
                                <Textbox
                                    textboxType="text"
                                    textboxName="FeedApiUrl"
                                    placeholder="api/"
                                    fixedLanguage={"en"}
                                    defaultValue={FeedApi?.FeedApiUrl}
                                    resetForm={resetForm}
                                    labelText="FeedApiUrl"
                                    required={true}
                                    mode={mode}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-end mt-4 pt-2">
                            {mode !== "view" ?
                                <>
                                    <Button ref={buttonRef} onClick={formSubmit}  value="Save" btnType="submit" />
                                    <Button
                                        btnType="cancel"
                                        value="Cancel"
                                        onClick={() => {cancel()}}
                                    />
                                </>
                                :
                                 <Button
                                    btnType="primary"
                                    value="Close"
                                    onClick={() =>{cancel()}}
                                  />
                            }
                        </div>
                    </div>
                </div>
            </div>     
                :<Loading/>
            }
        </form>
    );
}