import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getCustomers = (pageNumber: number, pageSize: number, searchString, orderBy, filterlist ) => http({
    url: ENDPOINTS.afterSalesApi.customerList,
    method: 'get',
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString: searchString,
        OrderByString: orderBy,
        FilterString: filterlist,

    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const getRequesterUserId = (jobId: any) => http({
    url: ENDPOINTS.afterSalesApi.customerList,
    method: 'get',
    params: {
        JobId: jobId
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


export const getPersonDetailCode = (personId: any) => http({
    url: ENDPOINTS.afterSalesApi.detailCode ,
    method: 'get',
    params: {
        personId: personId
    }
}).then((res) => {
    //  
    return res
}).catch((err) => {
    return Promise.reject(err);
});

export const postPerson = (personId, userData) => http({
    url: ENDPOINTS.afterSalesApi.addUserWithDetailCode ,
    method: "post",
    params: {
        PersonId: personId,
    },
    data :userData

}).then((res) => {
    return res.data
}).catch((err) => { 
    return Promise.reject(err);
});