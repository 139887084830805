import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from 'components/Button/Button';
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { ItemTreeForm } from "./ItemTreeForm";
import { postWarehouseItemApi } from "./api";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
import { WarehouseDictionary } from "pages/InventoryAndSales/Sales/SalesInvoice/components/WarehouseDictionary/WarehouseDictionary";

interface Prop {
    resetForm?: boolean,
    onHide: () => void,
    selectedObj?: any,
    mode:string,
    reload:()=>void,
}

export const WarehouseItemForm = ({ reload,onHide, mode ,selectedObj}: Prop) => {
    const [resetForm, setResetForm] = useState(false);
    const [userChanges, setUserChanges] = useState({});
    const [loading,setLoading] = useState<any>(true)
    const [all,setAll] = useState<any>(false)
    const [showTree,setShowTree] = useState<any>()
    const [treeType,setTreeType] = useState<any>()
    const [itemCodeData,setItemCodeData] = useState<any>({To:null,From:null})
    const [inventory, setInventory] = useState< any>(null);
    const [showAmount,setShowAmount]=useState<boolean>(false)

    const itemCodeHandeler = (obj) => {
        setLoading(false)
        setItemCodeData({...itemCodeData , [treeType]:obj.Code})
        setShowTree(false)
        setLoading(true)

      };
    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };

      async function SignCheck(e ,event) {        
        try{
            setAll(e)
        }catch(err:any){
    
        }
    }
    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);



    const submit = async(event) => {
        event.preventDefault();
            let data ={
                To:itemCodeData.To,
                From:itemCodeData.From,
                WarehouseId: inventory.Id  ,
                OrderQty :event?.target?.OrderQty?.value?
                 event.target.OrderQty?.value=="" ?
                 null  :
                 event.target. OrderQty.value:null ,
                MinQty : event.target.MinQty.value=="" ?null  :event.target.MinQty.value ,
                MaxQty : event.target.MaxQty.value=="" ?null  :event.target.MaxQty.value ,
                OrderPoint :event?.target?.OrderPoint?.value? event.target.OrderPoint?.value=="" ?null  :event.target.OrderPoint?.value:null ,
                HasOrderPoint : event.target.HasOrderPoint.checked,
                CabinetCode : event.target.CabinetCode.value ==""? null : event.target.CabinetCode.value,
            }
            try {
                await postWarehouseItemApi(data)
                setUserChanges({});
                reload()
                if(inventory?.Id!=JSON.parse(localStorage.getItem("Inventory")+"")?.Id){
                    localStorage.setItem("Inventory" ,JSON.stringify({Id:inventory?.Id , Name:inventory?.Name.Description}) )
                  }
            } catch (error) {
                
            }
        
    }

    function addOption(e) {
        setTreeType(e)
        setShowTree(true)

      }

      useEffect(()=>{
        if(inventory==null){
            setInventory({Id:JSON.parse(localStorage.getItem("Inventory")+"")?.Id})
        }
    },[])

    useEffect(()=>{
        if(resetForm){
            setUserChanges({})
        }
    },[resetForm])



    return (
        <>    
            {showTree&& 
                <ModalForm mode={mode} title="ItemTree"  onHide={() => setShowTree(false)} show={showTree}>
                     <ItemTreeForm onHide={()=>{setShowTree(false)}} itemCodeHandeler={itemCodeHandeler} />
                </ModalForm>
            
            }


        {loading ?            
                <>         
                <div className="" >
                    <FormWithTitle title="item">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-11 col-md-4">
                            <WarehouseDictionary 
                             name="WarehouseId"
                             label="Warehouse"
                             value={JSON.parse(localStorage.getItem("Inventory")+"")?.Id}
                             onChange={(e ,obj)=>setInventory(obj)}                          
                             mode={"create"}
                             required={true}/>
                            </div>
                        </div>
                    
                        <div className="col-4 mt-0">
                    <CheckableButton
                                onchange={SignCheck}
                                type='checkradio'
                                labelText={trans("All")}
                                labelFalseText={trans("limiteditems")}
                                defaultChecked={all }
                                checked={all }
                                inputName='Signature'
                                mode={mode}
                                />
                    </div>
                    {!all&&
                        <div className="row">
                        <div  className="col-md-4 col-sm-12" style={{display:"flex", alignItems:'center' , flexDirection:"row"}}  >
                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} onChange={(e)=>{setItemCodeData({...itemCodeData, From:e.target.value})}}   textboxName={"from"} textboxType="text" resetForm={resetForm} labelText={"from"} mode={mode}  defaultValue={ itemCodeData.From} />
                            <div  style={{marginTop:"4px", width:"10%"}}>
                                <Button Icon={true} value={<button onClick={(e)=>addOption("From")} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "diagram-project"]}  size="1x"  /></button>}  btnType="submit"onClick={(e)=>addOption("From")}   />
                            </div>             
                        </div>  
                        <div  className="col-md-4 col-sm-12" style={{display:"flex", alignItems:'center' , flexDirection:"row"}}  >
                        <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}}  onChange={(e)=>{setItemCodeData({...itemCodeData, To:e.target.value})}} textboxName={"To"} textboxType="text" resetForm={resetForm} labelText={"To"} mode={mode}  defaultValue={ itemCodeData.To} />
                            <div  style={{marginTop:"4px", width:"10%"}}>
                                <Button Icon={true} value={<button onClick={(e)=>addOption("To")} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "diagram-project"]}  size="1x"  /></button>}  btnType="submit" onClick={(e)=>addOption("To")} />
                            </div>             
                        </div>  
                        </div>
                    
                    }
                    </div>
                    </FormWithTitle>      
                </div>
            <form className=" " onSubmit={submit}>
                <div className=" mt-3">
                    <FormWithTitle title='itemCode'>
                    <div className="card-body">
                    <div className="row">
                
                        <div className="col">
                            <Textbox
                                textboxType="number"
                                textboxName="MinQty"
                                resetForm={resetForm}
                                defaultValue={""}
                                labelText="MinQty"
                                mode={mode}
                                />
                        </div>
                        <div className="col">
                            <Textbox
                                textboxType="number"
                                textboxName="MaxQty"
                                resetForm={resetForm}
                                defaultValue={""}
                                labelText="MaxQty"
                                mode={mode}
                                />
                        </div>
                        <div className="col">
                            <Textbox
                                textboxType="text"
                                textboxName="CabinetCode"
                                resetForm={resetForm}
                                defaultValue={""}
                                labelText="CabinetCode"
                                mode={mode}
                                />
                        </div>
                    </div>
                    <div className="row">
                    <div className="col mt-4">
                            <CheckableButton
                               defaultChecked={false}
                               defaultValue={false}
                               labelText={"HasOrderPoint"}
                               inputName={"HasOrderPoint"}
                               type="checkbox"
                               mode={mode}
                               onchange={(e)=>setShowAmount(!showAmount)
                            
                            }
                            />   
                        </div>
                  {showAmount &&<>  <div className="col">
                            <Textbox
                                textboxType="number"
                                textboxName="OrderQty"
                                resetForm={resetForm}
                                defaultValue={""}
                                labelText="OrderQty"
                                mode={mode}
                                />
                        </div>
                        <div className="col ">
                            <Textbox
                                textboxType="number"
                                textboxName="OrderPoint"
                                resetForm={resetForm}
                                defaultValue={""}
                                labelText="OrderPoint"
                                mode={mode}
                                />
                        </div>
                        </>
                        }
                     
                    </div></div>
                </FormWithTitle>
            </div>
                {  mode !="view" ?
                          <div className="col-lg-12 mt-1 btns_form">
                          <Button value="Save" btnType="submit" />
                          <Button btnType="cancel" value="Cancel" onClick={onHide} />
                          </div>
                          :
                          <div className="col-lg-12 mt-1 btns_form">
                          <Button btnType="primary" value="Close" onClick={onHide}/>
                          </div>
                      }     
            </form >   
                </>   
                :
                <Loading/>
                }         
        </>
    )
}