import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";



export const getCartable = (pageNumber: number, pageSize: number, cartableType, processId, selectedPosition?: any, orderFormat: string = "", searchString?: any, filterlist?: any) => http({
    url: ENDPOINTS.cartable.browse,//must be replace by cartable endpoints
    method: "get",
    params: {
        isArchivist:true , 
        pageNumber: pageNumber,
        pageSize: pageSize,
        processId: processId,
        cartableType: cartableType,
        OrderByString: orderFormat,
        SearchString: searchString,
        FilterString: filterlist
    }
}).then(response => response.data)
    .catch(err => {
        return Promise.reject(err);
    })


    
export const PostArchive = (async (data ) => await http({
    url: ENDPOINTS.archive.JobOrganizationalArchiveDetail.browse  ,
    method: 'post',
    data: data


})
.then(response => response.data)
.catch(err => {
        return Promise.reject(err);
 }
 ))

