import { OrganizationType } from "types/enum";
import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";




export const getCurrencyDictionaryApi = (async ( id ,personId) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Currency.dictionary,
    method: 'get',
    params: {
        personId:personId,
        id: id,
    
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)
