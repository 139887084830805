import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getAllOrganizationEvents = (async (pageNum,  pageSize  ,searchString ,orderBy ,filterlist) => await http({
    url: ENDPOINTS.calendar.OrganizationEvents.browse,
    method: 'get',
    params:{
        PageNumber: pageNum,
        PageSize: pageSize,
        OrderByString:orderBy,
        SearchString : searchString ,
        FilterString:filterlist,
        }
    
}).then((res) => {
    
    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getCountOrganizationEvents = (async () => await http({
    url: ENDPOINTS.calendar.OrganizationEvents.Count,
    method: 'get'
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const deleteOrganizationEvent = (async (id) => await http({
    url: ENDPOINTS.calendar.OrganizationEvents.browse,
    method: 'delete',
    params:{
        id:id
    }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const getOrganizationEventsn = (async (id) => await http({
    url: ENDPOINTS.calendar.OrganizationEvents.load,
    method: 'get',
    params: {Id : id},
    headers: {
        "Accept-Language": "en",
        
       
      },
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)


export const callHolidaysApi= (weekends,transferSolarOrganizationEvents,transferLunarOrganizationEvents,transferGregorianOrganizationEvents,transferCalendarEvents) => http({
    url: ENDPOINTS.calendar.OrganizationEvents.Transfer,
    method: 'post',
    data:{
        weekends:weekends,
        transferSolarHolidays:transferSolarOrganizationEvents,
        transferLunarHolidays:transferLunarOrganizationEvents,
        transferGregorianHolidays:transferGregorianOrganizationEvents,
        transferCalendarEvents:transferCalendarEvents,
        year:1402

    }
}).then((res) => {
    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

export const postOrganizationEvents = (data) => http({
    url: ENDPOINTS.calendar.OrganizationEvents.browse,
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const putOrganizationEvents = (data) => http({
    url: ENDPOINTS.calendar.OrganizationEvents.browse,
    method: 'put',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const sendOrganizationEventsApi = (data , method) => http({
    url: ENDPOINTS.calendar.OrganizationEvents.browse,
    method: method,
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


