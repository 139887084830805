import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";





export const getAttachmentViewers = (ownerId ) => http({
    url: ENDPOINTS.archive.JobOrganizationalArchiveDetail.fileList,
    method: 'get',
    params: {
        ownerId: ownerId,
        returenThumbnail:true
    }
}).then(response => response.data)
    .catch(err => {
       return Promise.reject( err);
    })

export const DeleteAttachmentViewers= (attachId ,endpoints) => http({
    url: endpoints,
    method: 'delete',
    params: {
        attachId: attachId
    }

}).then(response => {
    return response.data
}

)

export const GetAttachmentText= (attachId ,PreprocessingOptions) => http({
    url: ENDPOINTS.archive.JobOrganizationalArchiveDetail.attachmentText,
    method: 'get',
    params: {
        AttachId: attachId ,
        ...PreprocessingOptions
    }

}).then(response => {
    return response.data
}

)
export const GetHelp= () => http({
    url: ENDPOINTS.archive.JobOrganizationalArchiveDetail.help,
    method: 'get',
}).then(response => {
    return response.data
}

)

export const PostAttachmentViewers = (data, ownerId , endpoints ,Name) =>
    http({
        url: endpoints,
        method: 'post',
        params: {
            OwnerId: ownerId,
            fileName:Name
        },
        data: data,
        headers: {
            "content-type": "multipart/form-data",
            Accept: "*/*",
        }
    }).then((res) => {
        return res.data
    }).catch((err) => {
         
        return Promise.reject( err);
    });
    
export const PutAttachmentViewers = (data, ownerId , endpoints ,id) =>
    http({
        url: endpoints+"Id",
        method: 'put',
        params: {
            Id: id
        },
        data: data,
        headers: {
            "content-type": "multipart/form-data",
            Accept: "*/*",
        }
    }).then((res) => {
        return res.data
    }).catch((err) => {
         
        return Promise.reject( err);
    });
    


function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

export const loadAttachmentViewerBase64 = (attachId ) => http({
    url: ENDPOINTS.archive.JobOrganizationalArchiveDetail.file,
    method: 'get',
    params: {
        attachId: attachId
    },
    responseType: "blob"
}).then(async(response) => {

    return blobToBase64(response.data)
}).catch((err) => {
  //   
    return Promise.reject( err);
});

export const loadAttachmentViewer = (attachId ) => http({
    url: ENDPOINTS.archive.JobOrganizationalArchiveDetail.file,
    method: 'get',
    params: {
        attachId: attachId
    },
    responseType: "blob"
}).then(response => {
    return response.data
}).catch((err) => {
  //   
    return Promise.reject( err);
});



export const UpdateAttachmentDetails = (data ) => http({
    url: ENDPOINTS.archive.JobOrganizationalArchiveDetail.attachment,
    method: 'put',
    data:data
}).then(response => {
    return response.data;
}).catch((err) => {
    return Promise.reject( err);
});



export const getAttachmentDetail = (Id ) => http({
    url: ENDPOINTS.archive.JobOrganizationalArchiveDetail.attachment,
    method: 'get',
    params:{
        attachId : Id
    }
}).then(response => {
    return response.data;
}).catch((err) => {
    return Promise.reject( err);
});



