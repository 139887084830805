import { DataGrid } from "components/DataGrid/DataGrid";
import { Toast } from "components/ToastShow/ToastShow";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { useEffect, useState } from "react";
import { Response } from "types/types";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { deletePersonAsync, getPersonRealList } from "../api/api";
import { PersonRealForm } from "./components/PersonRealForm/PersonRealForm";
import "./style.scss";
import { ModalForm } from "components/ModalForm/ModalForm";
import { ToolbarInTab } from "components/ToolbarInTab/ToolbarInTab";

interface PersonRealListProp{
  curentTab?:string;
}
export const PersonRealList = ({    curentTab}:PersonRealListProp) => {

  let [responsePerson, setResponsePerson] = useState<Response | any>();
  let personRealList = [];
  let HeadPersonReal: string[] = [];
  let Total_records, pages;
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNum, setPageNum] = useState<number>(1);
  const [orderBy, setorderBy] = useState<any>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [person, setPerson] = useState();
  const [messageType, setMessageType] = useState<any>("info");
  const [filterlist, setFilterlist] = useState<any>("");
  const [idPersonReal, setIdPersonReal] = useState<any>(null);
  const [showForm, setShowForm] = useState<any>(null);
  const [forceUpdate, setForceUpdate] = useState(true); // integer state
  const [mode, setMode] = useState<any>()
  const [selectedList, setSelectedList] = useState<any>()
  const [search, setSearch] = useState(null);

//  let filterlist =""
  

const clickToolbarCallback = (clickedButtonName: string) => {
  switch (clickedButtonName.toLowerCase()) {
    case "reference":alert("call api reference with Id :");break;
    case "view": if (idPersonReal){setMode("view"); setShowForm(true)}; break;
    case "edit": if(idPersonReal){setMode("edit"); setShowForm(true)};break;
    case "print":alert("print ");break;
    case "excel":downloadCSV(responsePerson?.Data ,1 ,trans("PersonRealList"));break;
    case "remove": if (idPersonReal)handleDeletePersonReal(idPersonReal); break;
    case "refresh":setForceUpdate(!forceUpdate);break;
    case "create":
      setMode("create")
      setShowForm(true)
      // setIdPersonReal(null);
      break;
  }
};



const Searchhandleer = (str) => {
  setPageNum(1);
  if(str =="" ){
      setSearch(null)
  }else{
      setSearch(str)
  }
}









  const getWithOrder = (order: string[]) => {
    setorderBy(order)
  }


  const personIdCallback = (obj , index) => {
    if(sessionStorage.getItem("changingforms")+""=="true"){
      Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
        sessionStorage.setItem("changingforms" , "false");
        setIdPersonReal(obj.Id);
        localStorage.removeItem("formsInChang");
        });
    }else{
      setIdPersonReal(obj.Id);
    }
  }

  const handleEditPersonReal = (id: any) => {
    setMode("edit")
    setIdPersonReal(id)
    setShowForm(true)
  }

  async function deletePerson(id){
    try {
         const response = await  deletePersonAsync(id)
         getPersonRealListAsync();
    } catch (err: any) {
     
    }
  
  }
  
  const handleDeletePersonReal = (id: string) => {
    Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{deletePerson(id)}) 
    setIdPersonReal(id)    


  }
  const handleViewPersonReal = (id: string) => {
    setMode("view")
    setIdPersonReal(id)    
    setShowForm(true)
  }

  const handlechangePageSize = (e: any) => {
    setPageSize(e.currentTarget.value);
    setPageNum(1);
    getPageSize("PersonReal" ,e.currentTarget.value  )
    getPersonRealListAsync(e.currentTarget.value)
  };

  const handleGoPage = (value) => {
    if (value) {
      if (value < 1 || value > pages) {}
      else setPageNum(value);
    } else {
 
    }
  };
  const HandleNextPage = () => {
    if (pageNum < pages) setPageNum((pageNum) => { return +pageNum + 1 });
  };
  const HandlePrevPage = () => {
    
    if (pageNum > 1) setPageNum((pageNum) => { return +pageNum - 1 });
  };

  const handleAddPerson =(ans)=>{
    setPerson(ans);
  }
 

  

  async function getPersonRealListAsync(size=pageSize) {
    setLoading(false)
    let pageSizeChangedByUser = getPageSize("PersonReal" )
    setPageSize(pageSizeChangedByUser)
    try {
      const response = await getPersonRealList(pageNum, pageSizeChangedByUser ?pageSizeChangedByUser : size, orderBy.join(",") , filterlist ,search);
      setResponsePerson(response.Data)
          } catch (error) {
      setMessageType("error")
    }
    setLoading(true);
  }

  useEffect(() => {
    let settings = getGridSetting("PersonReal")
    setorderBy(settings[1])
    setFilterlist(settings[0])
    if(sessionStorage.getItem("changingforms")=="true"||localStorage.getItem("formsInChang")!=null){
      let forms = JSON.parse(localStorage.getItem("formsInChang")+"") 
      if(forms){
        if(forms["PersonReal"] ){
         Toast(trans("AreYouSureToRecoverUnsavedData"),"warning","alertWithoutClose",()=>{
           forms["PersonReal"]?.Id ? setMode("edit"):setMode("create")
           sessionStorage.setItem("changingforms" , "true");
             setIdPersonReal(forms["PersonReal"]?.Id)
             setShowForm(true)
         },"","",()=>{
             sessionStorage.setItem("changingforms" , "false");
             localStorage.removeItem("formsInChang");
         })
        }    

      }
       
    }
  }, []);

  useEffect(() => {
    if(curentTab == "PersonReal"){
      getPersonRealListAsync();
      setIdPersonReal(null)
    }
  }, [pageNum, person ,forceUpdate, orderBy  , filterlist ,search,curentTab]);

  if (responsePerson && responsePerson?.Data.length > 0) {
    HeadPersonReal = Object.keys(responsePerson.Data[0]);
    // HeadPersonReal.splice(1, 0, "operations"); // در اینجا به آپریشنز را به عنوان ستون دوم قرار می دهیم
    personRealList = responsePerson.Data;
    Total_records = responsePerson.TotalRecords;
  }
  pages = Total_records > pageSize ? Math.ceil(Total_records / pageSize) : 1;


  return (
    <>
    
      {showForm  ?
             <ModalForm modalHeight={90} mode={mode} onHide={()=>{setShowForm(false)}} show={showForm} modalProfileKey={"PersonRealForm"}    title={trans("PersonRealForm") } >

               {!idPersonReal ? <PersonRealForm
                 mode={mode}
                 handleCancelForm={(e)=>{setShowForm(false)}}
                 handleAddPerson={handleAddPerson}
               />
               :
               <PersonRealForm
                 mode={mode}
                 handleCancelForm={(e)=>{setShowForm(false)}}
                 idPersonReal={idPersonReal}
                 handleAddPerson={handleAddPerson}
               />
      }


             </ModalForm> 

        // (personContext.id ? <PersonRealForm id={personContext.id} /> : <PersonRealForm />)idPersonReal
        : null}
       <ToolbarInTab excel={false} handleSearch={Searchhandleer} id={idPersonReal} create={true} remove={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />

      <div style={{overflowY: "auto", 
          height: "63vh",
           scrollbarWidth: "thin"}} className=" col-lg-12 mt-1">
        <div className={`person-card`}>
          <div className="person-card__body ">
            {loading ? (<>
              {responsePerson && responsePerson?.Data.length>0 ? //{responsePerson != responsePersonPre ? <> //uncomment when using DataGridAg => prevent seconde render
                <DataGrid
               
                  handlefiltering={(str)=>{setFilterlist(str)}}
                  filterStr={filterlist}
                  DoubleClick={(e)=>{setIdPersonReal(e.Id) ;clickToolbarCallback("view")}}
                  orderFormat={getWithOrder}
                  orderFormatList={orderBy}
                  selectList={setSelectedList}
                  parentName={"PersonReal"}
                  theadData={HeadPersonReal}
                  minCellWidth={80}
                  tbodyData={personRealList}
                  selectedRowObjectCallback={personIdCallback}
                  handleEdit={handleEditPersonReal}
                  handleDelete={handleDeletePersonReal}
                  handleView={handleViewPersonReal}
                  totalRecords={Total_records}
                  pageNum={pageNum}
                  pageSize={pageSize}
                  HandleNextPage={HandleNextPage}
                  HandlePrevPage={HandlePrevPage}
                  handlechangePageSize={handlechangePageSize}
                  first={() => setPageNum(pages)}
                  end={() => setPageNum(1)}
                  handleGoPage={handleGoPage}
                  multiSelect={false}
                />
                :<Message message={"NoDataForPreview"} type={messageType} />
              }
            </>) :
              <Loading />}
          </div>
        </div>
      </div>
    </>
  );
};
