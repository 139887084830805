import { Button } from "components/Button/Button";

import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";

import { getTreasurySubjectsById, postTreasurySubjects } from "./api";

interface TreasurySubjectFormPropsType {
  selectedObj;
  showForm: (e: boolean) => void;
  mode;
  relod: () => void;
}
export const TreasurySubjectForm = ({
  selectedObj,
  showForm,
  mode,
  relod,
}: TreasurySubjectFormPropsType) => {
  const [resetForm, setResetForm] = useState(false);
  const [response, setResponse] = useState<any>();

  const [loading, setLoading] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});

  async function getTreasurySubjectsByIdAsync() {
    setLoading(false);

    try {
      if (selectedObj.Id) {
        const res = await getTreasurySubjectsById(selectedObj.Id);
        setResponse(res.Data);
      } else {
        setResponse(null);
      }
    } catch (err: any) {}
    setLoading(true);
  }

  const handleTreasurySubjectsFormSubmit = async (event) => {
    event.preventDefault();
    let data = {
      IsDefault: event.target.checkbox?.checked,
      Title: event.target.Title?.value,
      TitleEN: event.target.TitleEN?.value,
      SubjectType: +event.target.SubjectType?.value,
      SystemKey: event.target.SystemKey?.value,
    };

    try {
      if (mode == "edit") {
        await postTreasurySubjects("put", { ...data, Id: selectedObj.Id,ActivityStatus: +event.target.ActivityStatus?.value });
      } else {
        await postTreasurySubjects("post", data);
      }
      relod();
    } catch (error) {}
    cancel();
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        showForm(false);
      });
    } else {
      showForm(false);
    }
  }

  const pasteHandler = async () => {
    const text = JSON.parse(await navigator.clipboard.readText());
    setResponse(text);
    try {
    } catch (error) {}
  };

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (selectedObj && mode != "create") {
      getTreasurySubjectsByIdAsync();
    } else {
      setLoading(true);
    }
  }, [selectedObj]);

  return (
    <div className="person-contact-form card">
      <div className="card-body">
        {loading ? (
          <div className="container-fluid">
            <form onSubmit={handleTreasurySubjectsFormSubmit}>
              <div className="row ">
                <div className="col-lg-2 col-sm-3 mt-1">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="Title"
                    labelText="Title"
                    required={true}
                    defaultValue={response?.Title ? response?.Title : ""}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className=" col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="TitleEN"
                    labelText="TitleEN"
                    required={true}
                    defaultValue={response?.TitleEN ? response?.TitleEN : ""}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="SystemKey"
                    labelText="SystemKey"
                    required
                    defaultValue={
                      response?.SystemKey ? response?.SystemKey : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 ">
                  <EnumerationTypeSelect
                    value={response?.SubjectType}
                    name="SubjectType"
                    mode={mode}
                    label="SubjectType"
                    enumTypeSelect="SubjectType"
                  />
                </div>
                {mode !== "create" && (
                <div className="col-sm-3 col-lg-2">
                  <EnumerationTypeSelect
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    name="ActivityStatus"
                    enumTypeSelect="ActivityStatus"
                    mode={mode}
                    label="Status"
                    value={response?.Status}
                  />
                </div>
              )}

                <div className=" mt-4 mb-4">
                  <CheckableButton
                    defaultChecked={
                      response?.IsDefault ? response?.IsDefault : false
                    }
                    defaultValue={
                      response?.IsDefault ? response?.IsDefault : false
                    }
                    labelText={"Isdefault"}
                    inputName={"checkbox"}
                    type="checkbox"
                    mode={mode}
                  />
                </div>
              </div>

              {mode != "view" ? (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button value="Save" btnType="submit" />
                  <Button
                    btnType="cancel"
                    value="Cancel"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              ) : (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button
                    btnType="primary"
                    value="close"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              )}
            </form>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};
