import { SelectBox } from "components/Select/Select";
import { InputHTMLAttributes, useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getReferenceDictionarys } from "./api";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { InputLoad } from "components/InputLoading/inputLoading";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { ICON_TYPE } from "utils/constants";
interface ReferenceDictionaryProp extends InputHTMLAttributes<HTMLInputElement> {
  type: "SelectBox" | "Radio" | "CheckBox";
  label?: string;
  name: string;
  value?: any;
  mode?: any;
  endpoint?: any;
  JobId?: any;
  required?: boolean;
  selectedList?: (e) => void,
}
export const ReferenceDictionary = ({endpoint, type, label, name, value, mode ,JobId,required,selectedList }: ReferenceDictionaryProp) => {
  const [optionsReferenceDictionary, setOptionsReferenceDictionary] = useState<any>();
  const [errcode, setErrcode] = useState<any>();
  const [app, setApp] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [selectedItem, setSelectedItem] = useState<any>([]);
  const layoutContext = useContext(LayoutContext);
  let Default:any = value
  let options_ReferenceDictionary_values:any =[];
  let options_ReferenceDictionary_keys:any =[];

  const ReferenceDictionaryApiAsync = async ()=>{
    setLoading(false)
    try {
      const res = await getReferenceDictionarys(JobId ,endpoint)
       setOptionsReferenceDictionary(res.Data)
    } catch (error:any) {
      setErrcode(error.response.status)

    }
    setLoading(true)

  }
  function addOption(e) {
    e.stopPropagation()
    e.preventDefault()
    let Name 

    if(app){
     let PerformerPositionId = app.target.value
     let flag = false
     selectedItem.forEach(item => {
         if(item.PerformerPositionId ==PerformerPositionId ){
             flag =true
            }
     });
     if(!flag){
        let obj:any ={}
        optionsReferenceDictionary.map(item=>{
            if(item.PerformerPositionId ==PerformerPositionId ){
                obj ={
                    PerformerPositionDescription :  item.PerformerPositionDescription,
                    PerformerPositionId :  item.PerformerPositionId,
                    ReferenceId   :  item.ReferenceId  ,
                }
            }
        })
        
         setSelectedItem([...selectedItem,obj])
         selectedList&&  selectedList([...selectedItem,obj])
     }

    }

  }

  function deleteaOption(e) {
    let newSelectedList:any =[] 
    selectedItem.forEach(item=>{
        if(item.PerformerPositionId !=e.PerformerPositionId ){
          newSelectedList.push(item) 
        }
    })
    
     setSelectedItem(newSelectedList)
     selectedList&&  selectedList(newSelectedList)
  }





  useEffect(() => {
  ReferenceDictionaryApiAsync()

   
  }, [layoutContext.currentUserPosition.PositionId ]);

  if (optionsReferenceDictionary) options_ReferenceDictionary_values =optionsReferenceDictionary.map(item => {
    return item.PerformerPositionDescription 
  })
  if (optionsReferenceDictionary) options_ReferenceDictionary_keys = optionsReferenceDictionary.map(item => {
    return item.PerformerPositionId  
  })
  if (value && optionsReferenceDictionary && options_ReferenceDictionary_keys.indexOf(value) != -1) {
    Default = value
  } else if (optionsReferenceDictionary && options_ReferenceDictionary_values.indexOf(value) != -1) {
    Default = options_ReferenceDictionary_values.indexOf(value) + 1
  }
  
  

  return (
    <>
     {label ? <label className="ReferenceDictionary__label">{trans(label)}</label> : null}
      {loading ?
            <>
            <form style={{display:"flex" , flexDirection:"row",width:"100%"}}  >
              <div style={{width:"60%"}} >
              <SelectBox  
               onChange={setApp} 
                required={required}
                mode={Object.values(optionsReferenceDictionary).length >1 ? mode : "view"}
                lable="referrer"
                selectType="select"
                value={Default}
                options={options_ReferenceDictionary_values}
                val={options_ReferenceDictionary_keys}
                name={name}
              ></SelectBox>                  
              </div>
            {(optionsReferenceDictionary && Object.values(optionsReferenceDictionary).length >1) &&
              <div  style={{marginTop:"13px" , width:"10%"}}>
             <Button Icon={true}  value={<button onClick={addOption} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "check"]}   size="2x"  /></button>} btnType="submit"  />
              </div>
            
            }
             </form>
              <div className="group-person__selected-options-wrapper">
                
              { selectedItem.map((item, index) => (
            <div className="group-person__selected-option form-control-custom" key={item.PerformerPositionId + index}>
                <div style={{overflow: "hidden"}}>
                 {trans(item.PerformerPositionDescription) } 
                </div>
                
                <div style={{left: "3px"}} className="group-person__delete-selected-option" data-key={item.PerformerPositionId} onClick={()=>{deleteaOption(item)}}>
                    <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                </div>
            </div>
             ))}
           </div>

            </>
        :<InputLoad  msg={errcode}  required={required}  labelText={label}  onClick={ReferenceDictionaryApiAsync}/> 
      }
      <div className="text-box__msg-wrapper">

        
      </div>
    </>
  );
};
