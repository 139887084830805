import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { SelectBox } from "components/Select/Select";
import { useEffect, useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { getPageSize, trans } from "utils/helpers";
import { getSources } from "../../SalesInvoice/api";
import { GenerateFromSource } from "../api";


interface DictionaryTypeSelectProps {
    value?: any;
    mode?: string;
    resetForm?: boolean;
    dataChange?: boolean;
    Change?: (e) => void;
    SourceObjCallBack: (e: any , obj?:any) => void,
    setHaveSource?:any



}
export const SalesPreInvoiceSourceId = ({value,dataChange  ,mode ,resetForm ,SourceObjCallBack ,Change,setHaveSource }:DictionaryTypeSelectProps) => {
    const [defaultval, setdefault] = useState(value);
    const [sourceType, setSourceType] = useState<any>();
    const [showSources, setShowSources] = useState<any>();
    const [selectedObj, setSelectedObj] = useState<any>()
    const [loading, setLoading] = useState<any>(false)
    const [data, setData] = useState<any>()
    const sourceData =["WithoutSource" ,"SalesOrders" ]
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const [pages, setPages] = useState<number>(0);

    async function getSourcesAsync(pagNum = pageNum) {
        setLoading(false)
            try {
                let pageSizeChangedByUser = getPageSize("SalesPreInvoiceSourceId")
                setPageSize(pageSizeChangedByUser)
                const res = await getSources(sourceType,pagNum,pageSizeChangedByUser)
                setData(res.Data.Data)
                setShowSources(true)
                if (res.Data.Data.length > 0) {
                    setData(res.Data)
                    
                    setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
                    
                }
            } catch (error) {

            }
        setLoading(true)
    }
    async function GenerateFromSourceAsync() {
        setLoading(false)
        try {
            const res = await GenerateFromSource(selectedObj.Id ,sourceData[+sourceType ])
            SourceObjCallBack(res.Data ,sourceType  )
            setShowSources(false)
        } catch (error) {

        }
        setLoading(true)
    }

    const handleGoPage = (value) => {
        if (value) {
            setPageNum(+value);
            getSourcesAsync(+value);
        } else {
        }
    };
    const handleNextPage = () => {
        if (data.TotalRecords && ((data.TotalRecords / pageSize) > pageNum)) {
            setPageNum((pageNum) => { return +pageNum + 1 });
            getSourcesAsync(pageNum + 1);

        }
    };
    const handlePrevPage = () => {
        if (pageNum > 1) {
            setPageNum((pageNum) => { return +pageNum - 1 });
            getSourcesAsync(pageNum - 1);
        }
    };


    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("SalesPreInvoiceSourceId", e.currentTarget.value)
        getSourcesAsync(1);
    };

    useEffect(() => {
        setdefault(value)
    }, [resetForm]);


    return (
        <>

        <>
        {showSources&&
            <ModalForm modalHeight={49} onHide={()=>{setShowSources(false)}} mode={mode} show={showSources } title={"Source" } >
                
                <div className="card">
                    {loading ?
                    <div className="card-body">
                    <DataGrid
                    DoubleClick={(e)=>{setSelectedObj(e);GenerateFromSourceAsync()}}
                        parentName={"Sources"}
                        theadData={Object.keys(data.Data[0])}
                        tbodyData={data.Data}
                        startIndex={2}
                        minCellWidth={80}
                        selectedRowObjectCallback={setSelectedObj}
                        totalRecords={data.TotalRecords}
                        pageNum={pageNum}
                        pageSize={pageSize}
                        HandleNextPage={handleNextPage}
                        HandlePrevPage={handlePrevPage}
                        handlechangePageSize={handleChangePageSize}
                        first={() => { getSourcesAsync(pages); setPageNum(pages) }}
                        end={() => { getSourcesAsync(1); setPageNum(1) }}
                        handleGoPage={handleGoPage}

                    />
            <div className="col-lg-12 mt-1 btns_">
              <Button value="select" btnType="submit" onClick={(e)=>{GenerateFromSourceAsync()}}/>
              <Button btnType="cancel" value="Cancel"  onClick={()=>setShowSources(false)}/>
            </div>
                    </div>
                        :<Loading/>
                }

                </div>
            </ModalForm>
        
        }

        </>
        <div className="row">
            <div className="col-12">
            <SelectBox
                isDisabled={mode!=='create'}
                onChange={(e)=>{setSourceType(e.target.value);
                    if(e.target.value=='بدون منبع')
                    setHaveSource(false)
                }}
                lable={"Source"}
                selectType={'select'}
                options={["WithoutSource" ,"SalesOrder" ]}
                val={[null , "1"]}
                name={"SourceId"}
                value={sourceData.indexOf(value)+"" }
                resetForm={resetForm}
                mode={dataChange&&( sourceType==trans("withoutSource")||sourceType==null)? "view" : mode}
            />    
            </div>
            {(sourceType !=null&& sourceType!= trans("WithoutSource") )&&
            <div  className="col-2" style={{marginTop:"25px" , width:"1%" , marginRight:"-25px"}}>
                <Button disabled={dataChange&& ( sourceType==trans("withoutSource")||sourceType==null)} Icon={true}  value={<button onClick={(e)=>{e.preventDefault() ;getSourcesAsync()}} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "search"]}    size="2x"  /></button>} btnType="primary"  />
            </div>
            }

        </div>
        </>
        

    );
};