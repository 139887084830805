
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getJobs = (pageNumber: number, pageSize: number, searchString, orderBy, filterlist, processId) => http({
    url: ENDPOINTS.Management.JobManagement.jobList,
    method: 'get',
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString: searchString,
        OrderByString: orderBy,
        FilterString: filterlist,
        processId:processId,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});




export const forceStop = (id: any, description) => http({
    url: ENDPOINTS.Management.JobManagement.stop,
    method: 'put',
    params: {
        Id: id,
        forceStopDescription: description
    }

}).then((res) => {
     
    return res.data
})
.catch((err) => {
     
    return Promise.reject(err);
});
export const setJobInProgress = (id: any) => http({
    url: ENDPOINTS.cartable.setJobInProgress,
    method: 'post',
    params: {
        jobId: id,
    }

}).then((res) => {
     
    return res.data
})
.catch((err) => {
     
    return Promise.reject(err);
});

export const getViewForm = (processId: any, selectedPosition?: any) => http({
    url: ENDPOINTS.bpmnApi.process.getViewForm,
    method: 'get',
    params: {
        processId: processId,
    }
}).then((response) => (response.data))
    .catch(err => {
        return Promise.reject(err);
    })