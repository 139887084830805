import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";
import { ENDPOINTS } from "utils/endpoints";

export const getSystemSettingGroupTitle = async () =>
  await http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
      .SalesSystemSetting.groupTitle,
    method: "get",
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getSystemSetting = async (groupTitle,searchStr) =>
  await http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.SalesSystemSetting.browse,
    method: "get",
    params: {
      searchString:searchStr,
      groupTitle:searchStr?null : groupTitle,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const putSystemSetting = (data) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
      .SalesSystemSetting.browse,
    method: "put",
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
