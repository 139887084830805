import { CheckableButtonList } from "components/CheckableButtonList/CheckableButtonList";
import { SelectBox } from "components/Select/Select";
import { InputHTMLAttributes, useEffect, useState } from "react";
import { Enumeration } from "types/types";
import { trans } from "utils/helpers";
import { getIndicatorPatternsApi } from "./api";

import { InputLoad } from "components/InputLoading/inputLoading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { IndicatorPatternForm } from "./IndicatorPatternForm";

interface IndicatorPatternsProp extends InputHTMLAttributes<HTMLInputElement> {
  type: "SelectBox" | "Radio" | "CheckBox";
  label?: string;
  name: string;
  value?: any;
  mode?: string;
  required?:boolean 
  UpdateState?:boolean 
  handleUpdate?: () => void,
  Change?: (e) => void,
}
export const IndicatorPatternsDictionary = ({ type, label, name, value ,mode , required ,Change ,handleUpdate ,UpdateState}: IndicatorPatternsProp) => {
  const [optionsIndicatorPatterns, setOptionsIndicatorPatterns] = useState<any>();
  const [errcode, setErrcode] = useState<any>();
  const [showForm, setShowForm] = useState<any>();
  const [newId, setNewId] = useState<any>();
  let options_IndicatorPatterns_values:any =[];
  let options_IndicatorPatterns_keys:any =[];
  const getIndicatorPatternsAsync = async ()=>{
    const res = await getIndicatorPatternsApi()
    if(res?.Data ==null){
      setOptionsIndicatorPatterns({"null":""  ,...res?.Data,AddNewOption:"create"   })
    }else{
      setOptionsIndicatorPatterns({...res?.Data,AddNewOption:"create"   })

    }
    try {
    } catch (error:any) {
      setErrcode(error.response.status)

    }
  }
  useEffect(() => {
    getIndicatorPatternsAsync()
    return () => {
     
      setErrcode("")
    };
   
  }, [UpdateState]);

  if (optionsIndicatorPatterns) options_IndicatorPatterns_values = Object.values(optionsIndicatorPatterns as Enumeration);
  if (optionsIndicatorPatterns) options_IndicatorPatterns_keys = Object.keys(optionsIndicatorPatterns as Enumeration);
  return (
    <>
    {showForm &&
    
    <>
        <ModalForm modalHeight={38} title={"IndicatorPattern"}  onHide={() => {setShowForm(false) ;setOptionsIndicatorPatterns(null) ;getIndicatorPatternsAsync()}} show={showForm}>
                <IndicatorPatternForm setNewId={setNewId} onHide={()=>{setShowForm(false) ;handleUpdate && handleUpdate()  } }/>
        </ModalForm>
    </>
    
    }


      {label ? <label className="IndicatorPatterns__label">{trans(label)}</label> : null}
        {optionsIndicatorPatterns ?
        {
          Radio: (
            <>
              {options_IndicatorPatterns_values && (
                <CheckableButtonList
                  checkableBtnLabelList={options_IndicatorPatterns_values}
                  checkbaleType="radio"
                  verticalDirection={false}
                  checkableButtonName="IndicatorPatterns"
                  checkableListLabel="IndicatorPatterns"
                />
              )}
            </>
          ),
          SelectBox: (
            <div style={{display:"flex" , }} >
              <div style={{width:"100%"}}>
              <SelectBox
                onChange={(e)=>{  if(e.target.value=="AddNewOption"){setShowForm(true) }  ;e.target.value !=(value?value:options_IndicatorPatterns_keys[0]) ?Change&&Change({[name] :e.target.value}):Change&&Change({[name] :null}) }}
                required={required}
                mode={mode}
                lable="indicatorstatic"
                selectType="select"
                value={newId ?newId : (options_IndicatorPatterns_keys.length>2 ? value:null)}
                options={options_IndicatorPatterns_values}
                val={options_IndicatorPatterns_keys}
                name={name}
              ></SelectBox>
              </div>
            </div>
          ),
          CheckBox: (
            <>
              {options_IndicatorPatterns_values && (
                <CheckableButtonList
                  checkableBtnLabelList={options_IndicatorPatterns_values}
                  checkbaleType="checkbox"
                  verticalDirection={false}
                  checkableListLabel={trans("IndicatorPatterns")}
                />
              )}
            </>
          ),
        }[type as string]
        :<InputLoad   msg={errcode} required={required}  labelText={"IndicatorPatterns"}  onClick={getIndicatorPatternsAsync}/> 

      }
        <div className="text-box__msg-wrapper">
        </div>
    </>
  );
};
