import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getMessagePattern, sendMessagePatternsApi } from "./api";
import { ENDPOINTS, ENDPOINTSNOTIFYING } from "utils/endpoints";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { NOTIFYING_URL } from "utils/constants";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";

interface MessagePatternFormPropsType {
  selectedObj;
  showForm: (e: boolean) => void;
  mode;
  relod: () => void;
}
export const MessagePatternForm = ({
  selectedObj,
  showForm,
  mode,
  relod,
}: MessagePatternFormPropsType) => {
  const [resetForm, setResetForm] = useState(false);
  const [response, setResponse] = useState<any>();
  const [physicalQuantityId, setPhysicalQuantityId] = useState<any>();
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [untilDate, setUntilDate] = useState<string | null>(null);
  const [loading, setLoading] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});
  const [flag, setFlag] = useState(false);

  async function sendNodeAsync(data, method) {
    try {
      const res = await sendMessagePatternsApi(data, method);

      sessionStorage.setItem("changingforms", "false");
      setUserChanges({});
      relod();
      showForm(false);
    } catch (err: any) {}
  }

  async function getMessagePatternAsync() {
    setLoading(false);
    try {
      if (selectedObj.Id) {
        const res = await getMessagePattern(selectedObj.Id);
        /*               setUntilDate(res.Data.ValidityUntilDate)
                setFromDate(res.Data.ValidityFromDate) */
        setResponse(res.Data);
      } else {
        setResponse(null);
      }
    } catch (err: any) {}
    setLoading(true);
  }

  const validationDates = () => {
    if (
      fromDate &&
      untilDate &&
      fromDate?.toString().slice(0, 10) > untilDate?.toString().slice(0, 10)
    ) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  };



  const handlePersonContactFormSubmit = (MessagePattern) => {
    MessagePattern.preventDefault();
    if (flag) {
      Toast(trans("InvalidEndDate"), "error");
    } else {
      if (mode == "edit") {
        let data = {
          Id: selectedObj.Id,
          MessageSettingId: selectedObj.MessageSettingId,
          PatternCode: MessagePattern.target.PatternCode.value,
          PatternSubject: MessagePattern.target.PatternSubject.value,
          PatternText: MessagePattern.target.PatternText.value,
          PatternParameters: MessagePattern.target.PatternParameters.value,
          PatternSeparators: MessagePattern.target.PatternSeparators.value,
        };
        sendNodeAsync({...data,Id:selectedObj.Id,ActivityStatus:+MessagePattern.target.ActivityStatus?.value}, "put");
      } else {
        let data = {
          MessageSettingId: selectedObj.MessageSettingId,
          PatternCode: MessagePattern.target.PatternCode.value,
          PatternSubject: MessagePattern.target.PatternSubject.value,
          PatternText: MessagePattern.target.PatternText.value,
          PatternParameters: MessagePattern.target.PatternParameters.value,
          PatternSeparators: MessagePattern.target.PatternSeparators.value,
        };

        sendNodeAsync(
          {
            MessagePattern: data,
            ApplicationId: MessagePattern.target.ApplicationId.value,
            PolicyKey: MessagePattern.target.PolicyKey.value,
          },
          "post"
        );
      }
    }
  };
  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(
        trans("modalcloseMessagePattern"),
        "warning",
        "alertWithoutClose",
        () => {
          sessionStorage.setItem("changingforms", "false");
          setUserChanges({});
          showForm(false);
        }
      );
    } else {
      showForm(false);
    }
  }
  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (selectedObj && mode != "create") {
      getMessagePatternAsync();
    } else {
      setLoading(true);
    }
  }, [selectedObj]);

  useEffect(() => {
    validationDates();
  }, [untilDate, fromDate]);

  return (
    <div className="person-contact-form card">
      <div className="card-body">
        {loading ? (
          <div className="container-fluid">
            <form onSubmit={handlePersonContactFormSubmit}>
              <div className="row ">
                <div className="col-lg-3 col-sm-4 mt-1">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="PolicyKey"
                    labelText="PolicyKey"
                    required={true}
                    maxLength={900}
                    defaultValue={response?.PolicyKey}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 mt-1">
                  <DictionarySelectBox
                    label="Application"
                    name="ApplicationId"
                    endPoint={ENDPOINTS.application.dictionary}
                    required={true}
                    value={response?.ApplicationId}
                    mode={mode}
                    useTrans={true}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 mt-1">
                  <DictionarySelectBox
                    name="MessageSettingId"
                    label="MessageSetting"
                    baseUrl={NOTIFYING_URL}
                    endPoint={
                      ENDPOINTSNOTIFYING.messageSettingsApis.messageSetting
                        .dictionary
                    }
                    required={true}
                    value={response?.MessagePattern?.MessageSettingId}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 mt-1">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="PatternCode"
                    labelText="PatternCode"
                    required={true}
                    maxLength={900}
                    defaultValue={response?.MessagePattern?.PatternCode}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 mt-1">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    required={true}
                    textboxType="text"
                    textboxName="PatternSubject"
                    labelText="PatternSubject"
                    defaultValue={response?.MessagePattern?.PatternSubject}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-3 col-sm-4 mt-1">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    required={true}
                    textboxType="text"
                    textboxName="PatternParameters"
                    labelText="PatternParameters"
                    defaultValue={response?.MessagePattern?.PatternParameters}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 mt-1">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    required={true}
                    textboxType="text"
                    textboxName="PatternSeparators"
                    labelText="PatternSeperators"
                    defaultValue={response?.MessagePattern?.PatternSeparators}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 mt-1">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    required={true}
                    textboxType="textarea"
                    textboxName="PatternText"
                    labelText="PatternText"
                    defaultValue={response?.MessagePattern?.PatternText}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                {mode != "create" && (
                  <div className="col-md-4 col-sm-12">
                    <EnumerationTypeSelect
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      name="ActivityStatus"
                      label="ActivityStatus"
                      enumTypeSelect="ActivityStatus"
                      value={response?.MessagePattern?.ActivityStatus}
                      mode={mode}
                    />
                  </div>
                )}
              </div>

              {mode != "view" ? (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button value="Save" btnType="submit" />
                  <Button
                    btnType="cancel"
                    value="Cancel"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              ) : (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button
                    btnType="primary"
                    value="close"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              )}
            </form>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};
