import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getReferencesActions = (referenceId ) => http({
    url: ENDPOINTS.cartable.ReferencesTree,
    method: 'get',
    params: {
        referenceId:referenceId ,
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});



