
import { TabBar } from "components/Tabbar/Tabbar";
import { useState } from "react";
import { OtherGroups } from "./components/OtherGroups/OtherGroups";
import { DefaultGroups } from "./components/DefaultGroups/DefaultGroups";
import { trans } from "utils/helpers";


export const GroupPositionList = ({   }) => {


    const [curentTab, setCurentTab] = useState<any>("OtherGroups")
    const [defaultTab, setDefaultTab] = useState<any>("OtherGroups")


     

        const tabChangeHandle = (tab) => {
            setCurentTab(tab)
        }
    
        const content = [
          { title: "OtherGroups", body: (<OtherGroups />), },

          { title: "DefaultGroups", body: (<DefaultGroups  />), }

        ]






    return (
        <>
            <div className="cartable-action mt-4">
                <TabBar tabChange={tabChangeHandle} content={content} curentTab={curentTab} defaultTab={defaultTab} />
            </div>
        </>

    );
}