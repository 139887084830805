import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getListLetterSubject = (async (searchString, orderBy) => await http({
    url: ENDPOINTS.secretariat.letterSubject.browse,
    method: 'get',
    params:{
        searchString: searchString,
        orderBy: orderBy,
    }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getLetterSubject = (async (id) => await http({
    url: ENDPOINTS.secretariat.letterSubject.load,
    method: 'get',
    params: {Id : id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const deleteLetterSubject =  (id) =>  http({
    url: ENDPOINTS.secretariat.letterSubject.browse,
    method: 'delete',
    params: {Id : id}

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
})


export const postLetterSubject = (data) => http({
    url: ENDPOINTS.secretariat.letterSubject.browse,
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const putLetterSubject = (data) => http({
    url: ENDPOINTS.secretariat.letterSubject.browse,
    method: 'put',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

