import { ENDPOINTS } from "utils/endpoints"
import { http } from "utils/http"


export const getPersonalArchive = (id: any) => http({
    url: ENDPOINTS.archive.personalArchive.archives,
    method: "get",
    params: {
        ParentId: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


