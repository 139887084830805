import { Button } from "components/Button/Button";
import { Textbox } from "components/Textbox/Textbox";
import { PersonContact } from "pages/OfficeAutomation/BaseInformation/PersonsPage/types";
import { useEffect, useState } from "react";
import { convertAddress, trans, uuidv4 } from "utils/helpers";
import { ContactType } from "types/enum";
import { ModalForm } from "components/ModalForm/ModalForm";
import { MapLocation } from "./component/MapLocation";
import { Address } from "components/Address/Address";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
interface PersonContactFormPropsType {
    personContact?: any,
    mode?: any,
    showPersonContactFormCallback: (ans: boolean,relod?:boolean) => void,
    newPersonContactDataCallback: (ans: PersonContact, exist) => void,
}
export const PersonContactForm = ({ personContact, mode, showPersonContactFormCallback, newPersonContactDataCallback }: PersonContactFormPropsType) => {

    let data: any;
    //  const [contactType_, setContactType_] = useState(personContact?.ContactFixType || "Phone");
    const [resetForm, setResetForm] = useState(false);
    const [type, setType] = useState<any>(mode =="create"?1  : personContact?.ContactTypeEnum)
   
    const [save_Exit, setSave_Exit] = useState<boolean>(false);
    const [showMap, setShowMap] = useState<boolean>(false);
    const [personLocation, setpersonLocation] = useState<any>();

    const handlePersonContactFormSubmit = (event) => {
        event.preventDefault();
        let Value:any = ""
        if(event.target.ContactType.value == "4"){
            Value =JSON.stringify({
                   address: event.target.Address.value,
                   plaque: event.target.Plaque.value,
                   unit: event.target.Unit.value,
                   altitude: event.target.Altitude?.value,
                   longitude: event.target.Longitude.value,
                   latitude: event.target.Latitude.value ,
                   PostalCode: event.target.PostalCode.value,
                 })
        }else if(event.target.ContactType.value == "8"){
            Value =JSON.stringify({ 
                altitude: event.target.Altitude?.value,
                 longitude: event.target.Longitude.value,
                  latitude: event.target.Latitude.value 
                })
        }else{
            Value =  event.target.value.value
        }
        if (personContact&&mode=="edit") {
            data = {
                Id: personContact?.Id,
                ContactTypeEnum: +event.target.ContactType.value  ,
                ContactType: trans(ContactType[event.target.ContactType.value])  ,
                Value: Value,
                Description: event.target.Description.value,

            }
        } else {
            data = {
                Id: uuidv4(),
                ContactTypeEnum: +event.target.ContactType.value,
                ContactType: trans(ContactType[event.target.ContactType.value]),
                Value: Value,
                Description: event.target.Description.value,

            }
        }

        newPersonContactDataCallback(data, mode !="create");

        if (save_Exit) {
            showPersonContactFormCallback(false ,true)
        }
        

    }

    const handleContactType = (e) => {
        setType(+e)
    };
    const sendLocationCallback = (e) => {
       
        setpersonLocation({

            address:e.address,
            latitude:e.geom.coordinates[1],
            longitude:e.geom.coordinates[0],

        })

        setShowMap(false)
    };


    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);

    return (
        <div className="person-contact-form card">

        {showMap &&
          <ModalForm
            checkClose={false}
            onHide={() => setShowMap(false)}
            show={showMap}
            title="PersonContact"
          >
            <MapLocation sendLocationCallback={sendLocationCallback} />
          </ModalForm>
        }

            <div className="card-body">
                <div className="container-fluid">
                    <form className="row " onSubmit={handlePersonContactFormSubmit}>
                        <div className="col-lg-3 mt-1">
                            <EnumerationTypeSelect
                             label="ContactType"
                             enumTypeSelect="ContactType"
                             mode={mode} 
                             required={true} 
                             name="ContactType" 
                             onChange={handleContactType} 
                             value={type} 
                            />
                        </div>
                        {
                            {
                                1: (
                                    <div className="col-lg-3 mt-1">
                                        <Textbox
                                            mode={mode}
                                            required={true}
                                            defaultValue={personContact?.Value}
                                            textboxType="tel"
                                            textboxName="value"
                                            labelText="Phone"
                                            resetForm={resetForm}
                                            maxLength={15}
                                        />
                                    </div>
                                ),
                                2: (
                                    <div className="col-lg-3 mt-1">
                                        <Textbox
                                        required={true}
                                            mode={mode}
                                            defaultValue={personContact?.Value}
                                            textboxType="mobile"
                                            textboxName="value"
                                            labelText="mobile"
                                            resetForm={resetForm}
                                            //maxLength={11}
                                        />
                                    </div>
                                ),
                                3: (
                                    <div className="col-lg-3 mt-1">
                                        <Textbox
                                        required={true}
                                            mode={mode}
                                            defaultValue={personContact?.Value}
                                            textboxType="email"
                                            textboxName="value"
                                            labelText="email"
                                            resetForm={resetForm}
                                        />
                                    </div>
                                ),

                                4: (
                                    <>
                                        <Address personContact={personContact?.Value ? convertAddress(personContact?.Value):null } mode={mode}/>
                                    </>
                                ),
                                5:(
                                <div className="col-lg-3 mt-1">
                                    <Textbox
                                        required={true}
                                        mode={mode}
                                        defaultValue={personContact?.Value}
                                        textboxType="tel"
                                        textboxName="value"
                                        labelText="Fax"
                                        resetForm={resetForm}
                                        maxLength={15}
                                    />
                                </div>
                                ),
                                6:(
                                <div className="col-lg-3 mt-1">
                                    <Textbox
                                        required={true}
                                        mode={mode}
                                        defaultValue={personContact?.Value}
                                        textboxType="tel"
                                        textboxName="value"
                                        labelText="Extension"
                                        resetForm={resetForm}
                                        maxLength={10}
                                    />
                                </div>
                                ),
                                7:(
<></>
                                ),
                                8: (
                                    <>

                                    </>
                                ),
                                9: (
                                    <div className="col-lg-3 mt-1">
                                        <Textbox
                                        required={true}
                                            mode={mode}
                                            defaultValue={personContact?.Value}
                                            textboxType="text"
                                            textboxName="value"
                                            labelText="ECEOrganizationCode"
                                            resetForm={resetForm}
                                        />
                                    </div>
                                ),

                            }[type]
                        }

                        <div className="col-lg-6 mt-1">
                            <Textbox
                                mode={mode}
                                textboxType="textarea"
                                textboxName="Description"
                                labelText="Description"
                                maxLength={900}
                                defaultValue={personContact?.Description}
                                resetForm={resetForm}
                            />
                        </div>
                        {mode !== "view" ?
                            <div className="col-lg-12 mt-1 btns_form">
                                <Button btnType="submit" value="Save&Close" onClick={() => { setSave_Exit(true) }} />
                                <Button value="Save" btnType="submit" />
                                <Button btnType="cancel" value="Cancel" onClick={() => showPersonContactFormCallback(false)} />
                            </div>
                            :
                            <div className="col-lg-12 mt-1 btns_form">
                                <Button btnType="primary" value="close" onClick={() => showPersonContactFormCallback(false)} />
                            </div>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
}





