import axios from "axios";
import { AUTH_TOKEN_URL, AUTH_URL_CALL } from "./constants";
import { urlParams } from "./helpers";
import { promises } from "dns";
export let UserTabs: any = [];

const getToken = (code: any, code_verifier: any) => {
    //require('dotenv').config();
    axios({
        url: AUTH_TOKEN_URL,
        method: 'post',
        headers: {
            "Accept": "*/*",
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Basic " + process.env.REACT_APP_AUTH,//ReactClientId:bae8ef2b-b621-f3b1-00ae-e48a4e49ed19
        },
        timeout: 40000,
        data:
            "grant_type=authorization_code" +
            "&code=" + code +
            "&client_id=" + process.env.REACT_APP_AUTH_CLIENT_ID +
            "&client_secret=" + process.env.REACT_APP_AUTH_SECRET +
            "&redirect_uri=" + process.env.REACT_APP_AUTH_REDIRECT_URL +
            "&code_verifier=" + code_verifier +
            "&scope=" + process.env.REACT_APP_AUTH_CLIENT_SCOPE
    }).then((response) => {
            localStorage.removeItem('codeverfier');
            localStorage.setItem("token", response.data.access_token);
            localStorage.setItem("id_token", response.data.id_token);
            localStorage.setItem("refresh_token", response.data.refresh_token);
            localStorage.setItem("token_expire", response.data.expires_in);
            var exp = Date.now() + (response.data.expires_in * 1000);
            localStorage.setItem("token_expire_date", exp.toString());
            document.cookie = "logedIn=true"
            window.location.href ="/"
            return response
    }).catch((error) => {
            localStorage.removeItem('codeverfier');
            attempt()
            return Promise.reject(error)
            
    });
}



async function refreshToken() {
    let res = true;
    var expire: any = localStorage.getItem("token_expire_date");

    if (Date.now() >= expire) {
 await   axios({
        url: AUTH_TOKEN_URL,
        method: 'post',
        headers: {
            "Accept": "*/*",
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Basic " + process.env.REACT_APP_AUTH,
        },
        timeout: 40000,
        data:
            "grant_type=refresh_token" +
            "&refresh_token=" + localStorage.getItem("refresh_token") +
            "&scope=offline_access" +
            "&client_id=" + process.env.REACT_APP_AUTH_CLIENT_ID +
            "&client_secret=" + process.env.REACT_APP_AUTH_SECRET +
            "&redirect_uri=" + process.env.REACT_APP_AUTH_REDIRECT_URL
    }).then((response) => {
        localStorage.setItem("id_token", response.data.id_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("token_expire", response.data.expires_in);
        var exp = Date.now() + (response.data.expires_in * 1000);
        document.cookie = "logedIn=true"
        localStorage.setItem("token_expire_date", exp.toString());
    }).catch((error) => {
        localStorage.setItem("catch-token",JSON.stringify(error) );
        const Idtoken = localStorage.getItem("id_token");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("token_expire");
        localStorage.removeItem("CurrentApplication");
  
          if (UserTabs.length == 0) {
            const openedlogoutWindow = window.open(
              `${process.env.REACT_APP_AUTH_URL}/endsession?id_token_hint=${Idtoken}&post_logout_redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URL}`
            );
  
            UserTabs = [...UserTabs, openedlogoutWindow];
          }
  
          UserTabs?.forEach((item) => {
            item?.close();
          });
          UserTabs = [];
  
          window.location.href = "/";
        res = false;
     
    });
   
   }
    return res;
}

function authentication() {
    const pkceChallenge = require("pkce-challenge");
    const challenge = pkceChallenge(43);
    localStorage.setItem('codeverfier', challenge.code_verifier);
    window.location.href = AUTH_URL_CALL + challenge.code_challenge;
    //window.location.href = process.env.REACT_APP_CURRENT_URL +"/"
    
}
function isTokenExpired() {
    var expire: any = localStorage.getItem("token_expire_date");
    if (Date.now() >= expire) {
        localStorage.removeItem("token_expire")
        return true;
    }else{
        document.cookie = "logedIn=true"
        return false;
    }
}
  async function checkTokenExpire() {
    var res = true
    if (isTokenExpired()) {
        res =await refreshToken();
    }else{
        document.cookie = "logedIn=true"
    }
    return res
}
function attempt() {
    let res:any = true
    if (localStorage.getItem("token")) {
        // check expiration
        res = checkTokenExpire();
    }
    else {
        if (localStorage.getItem('codeverfier') && urlParams().code != undefined) {
            var verifier_ = localStorage.getItem('codeverfier');
            var code = urlParams().code;
            // setResult(true);
            getToken(code, verifier_);
        }
        else  {
            authentication();
        }
    }
     return res
    

}

export const auth = {
    attempt,
    getToken,
    refreshToken,
    checkTokenExpire,
}