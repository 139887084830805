

import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { Toast } from "components/ToastShow/ToastShow";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { getAllOrganizationEvents, getCountOrganizationEvents ,deleteOrganizationEvent} from "./api";
import { SelectDay } from "./components/SelectDay";
import { OrganizationEventsForm } from "./OrganizationEventsForm";



interface Form {
    Title: string,
    Status: number
}
export const OrganizationEvents = ({ }: Form) => {
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showEventForm , setShowEventForm] = useState(false)
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pages, setPages] = useState<number>(0);
    const [endpoit, setEndpoit] = useState<any>();
    const [showSelectDay, setShowSelectDay] = useState<boolean>(false);
    const [filterlist, setFilterlist] = useState<any>("");


    const handlechangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("OrganizationEvents" ,e.currentTarget.value )
        getOrganizationEventsAsync(1)
      };

    const handleGoPage = (value) => {
      if (value) {
        if (value < 1 || value > pages) { }
        else setPageNum(+value);getOrganizationEventsAsync(+value)
      } else {

      }
    };
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getOrganizationEventsAsync(pageNum + 1);
          };
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getOrganizationEventsAsync(pageNum - 1);
          };
    };
    const handleCreateAction = () => {
        // setSelectedObj(null)
        setMode("create")
        setShowEventForm(true)

    }
    const handleEditRow = () => {
        setMode("edit");
        setShowEventForm(true)
       
    }
    const handleDeleteRow =async () => {
        try {
            await deleteOrganizationEvent(selectedObj.Id)
            setForceUpdate(!forceUpdate)

        } catch (error) {
            
        }

    }
    const handleViewRow = () => {
        setMode("view")
        setShowEventForm(true)
      
    }

    const selectedObjCallback = (obj, index) => {
        setSelectedObj(obj);
    }
    const searchHandler = (str) => {
        setPageNum(1);
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }
    async function getOrganizationEventsAsync(PageNum = pageNum) {
        setLoading(false);
        setResponse(null);
        let pageSizeChangedByUser = getPageSize("OrganizationEvents" )
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getAllOrganizationEvents(PageNum,  pageSizeChangedByUser ,search ,orderBy.length == 0 ?null:  orderBy.join(","),filterlist);
            setResponse(res.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
         
        }
        setLoading(true)
    }



    async function fristShow() {
        setLoading(false)
        try {
            const res:any = await getCountOrganizationEvents();
            let isFrist = true
            if(res){

                Object.values(res).forEach((item:any) => {
                    if(item && item >0){
                        isFrist =false
                    }
                }); 
            }
            if(isFrist){
                setShowSelectDay(true);
            }else{
                Toast("Msg_ConfirmRemoveCurrentDataAndTransferFromCalendarEvents" , "warning" , "alert" , ()=>{ setShowSelectDay(true)} )
            }
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)
    }

    useEffect(() => {
        getOrganizationEventsAsync();
    }, [forceUpdate ,search , orderBy , filterlist]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
            getOrganizationEventsAsync();
        }
    }, [resetForm]);



    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("OrganizationEvents"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "move": fristShow();  break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id) handleEditRow(); break;
            case "remove": if (selectedObj?.Id) Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "help": alert("call api help"); break;
        }
    };
    /* #endregion */

    return (
        <>   
         {showEventForm ?<ModalForm modalHeight={50} mode={mode} onHide={()=>{setShowEventForm(false)}} show={showEventForm} title={"organizationEvents" } ><OrganizationEventsForm mode={mode} relod={()=>{setResetForm(true)}}   selectedObj={selectedObj}  showForm={()=>{setShowEventForm(false)}} /> </ModalForm>  : null}
         {showSelectDay ?<ModalForm modalHeight={58}  mode={mode} onHide={()=>{setShowSelectDay(false)}} show={showSelectDay} title={"transfer" } ><SelectDay endpoit={endpoit} relod={()=>{setResetForm(true);setEndpoit(null)}}    showForm={()=>{setShowSelectDay(false)}} /> </ModalForm>  : null}
            <div className="cartable-action">
                <Toolbar remove={true}  move={true} handleSearch={searchHandler} id={selectedObj?.Id} emptyList={response?.TotalRecords == 0} create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card mx-1" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                    <div className="">
                        {loading ?
                            <>
                                {
                                    response?.Data?.length > 0 ?
                                        <>
                                            <DataGrid
                                                parentName={"OrganizationEvents"}
                                                orderFormat={setOrderBy}
                                                orderFormatList={orderBy}
                                                handlefiltering={(str)=>{setFilterlist(str)  }}
                                                filterStr={filterlist}
                                                startIndex={1}
                                                theadData={Object.keys(response.Data[0])}
                                                tbodyData={response.Data}
                                                minCellWidth={80}
                                                selectedRowObjectCallback={selectedObjCallback}
                                                handleEdit={handleEditRow}
                                                handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                                handleView={handleViewRow}
                                                totalRecords={response.TotalRecords}
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                HandleNextPage={handleNextPage}
                                                HandlePrevPage={handlePrevPage}
                                                handlechangePageSize={handlechangePageSize}
                                                first={() => {getOrganizationEventsAsync(pages);setPageNum(pages)}}
                                                end={() => {getOrganizationEventsAsync(1);setPageNum(1)}}
                                                handleGoPage={handleGoPage}
                                                                                            />
                                        </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                            }
                            </>

                            : <Loading />
                        }
                    </div>
                </div>
            </div>
        </>

    );
}