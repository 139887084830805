import { Loading } from 'components/Loading/loading';
import { useEffect, useState } from 'react';
import { AttachmentViewer } from './AttachmentViewer';
import { getAttachmentViewers } from './api';
import './style.scss';


interface props {
    bordData: any
}


export const WidgetBoardDetail = ({bordData}:props) => {

    const [loading, setLoading] = useState<any>(false);
    const [response, setResponse] = useState<any>();

    async function getAttachmentViewersAsync() {
        setLoading(false)
        try {
            const res = await getAttachmentViewers(bordData.Id )
            setResponse(res.Data ) 
        } catch (error:any) {
            
        }
        setLoading(true)
    }

    useEffect(() => {
        if(bordData?.Id){
            getAttachmentViewersAsync()
        }
    }, [ bordData]);


    return (
        <>
        {loading ?
            <>
            <div key={bordData.Id} className='card p-2  mb-2'>
                <h6 className='mb-3'>{bordData.Title}</h6>
                <p style={{display:"flex" , flexDirection:"row",height: "75%" }} data-toggle="tooltip" data-placement="top" title={bordData.Title}  >
                    <div style={{width: "100%" ,display: "flex",flexDirection: "column",justifyContent: "space-between",height: "100%"}}>
                       <div style={{height: "98%",maxHeight:'98%' , overflow: "hidden"}} dangerouslySetInnerHTML={{__html:response.Description}}> 
                       </div>
                    </div>
                </p>
               {response?.Link && 
                <a target='_blank' href={response?.Link} className='bordLink' style={{cursor:"pointer" , direction : "ltr"}} >{response?.Link}</a>
               }
            </div>
                {response &&response?.BulletinBoardAttachments?.length >0  ?
                    <>
                        <AttachmentViewer listAttachment={response?.BulletinBoardAttachments} ownerId={bordData.Id}  />
                    </>
                    :<></> 
                 }
            </>
            : <Loading />
            }
        </>
    )
}