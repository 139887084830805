import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const GetJobAndReference = (referenceId ) => http({
    url: ENDPOINTS.cartable.PersonnelCartable.JobAndReference,
    method: 'get',
    params: {
        referenceId:referenceId 
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
