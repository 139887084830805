import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { trans } from "utils/helpers";
import { http } from "utils/http";



export const  getGroupPositionList =( async ( searchString) =>  await http({
    url: ENDPOINTS.BaseInformation.groupPosition.dictionary,
    method: "get",
    params:{
        SearchString : searchString
    }
})
.then((respons) => (respons.data))    
.catch(err => {
    return Promise.reject( err);
 })

)


export const  getPositionList =( async (searchString ) =>  await http({
    url: ENDPOINTS.BaseInformation.position.browse,
    method: "get",
    params : {
        searchString : searchString
    }

    

})
.then((respons) => (respons.data))
.catch(err => {
    return Promise.reject( err);
 })

)


export const  setSecretariatMembers =( async (data  ) =>  await http({
    url: ENDPOINTS.secretariat.secretariatAccess.browse,
    method: "post",
    data:data,
})
.then((respons) => (respons.data))
.catch(err => {
    return Promise.reject( err);
 })

)
export const  getSecretariatMembers =( async (secretariatId ) =>  await http({
    url: ENDPOINTS.secretariat.secretariatAccess.browse,
    method: "get",
    params:{
        SecretariatId:secretariatId 
    }
   
  
})
.then((respons) => (respons.data))
.catch(err => {
    return Promise.reject( err);
 })

)




    
export const AddNewPattern = (data:any) => http({
    url: ENDPOINTS.secretariat.SecretariatIndicatorPattern.browse,
    method: "post",
    data : data
}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject( err);
});

   

export const editPattern = (data:any) => http({
    url: ENDPOINTS.secretariat.SecretariatIndicatorPattern.browse,
    method: "put",
    data : data
}).then((res) => { 
    return res.data
}).catch((err) => {
     
    return Promise.reject( err);
});



   

export const deletePattern = (id) => http({
    url: ENDPOINTS.secretariat.SecretariatIndicatorPattern.browse,
    method: "delete",
    params:{
        Id:id
    }
}).then((res) => { 
    Toast(trans("msg_deleteddatasuccessfully") , "success")
    return res.data
}).catch((err) => {
     
    return Promise.reject( err);
});


export const getSecretariatIndicatorPatternList = (id) => http({
    url: ENDPOINTS.secretariat.SecretariatIndicatorPattern.browse,
    method: "get",
    params: {
        SecretariatId:id,

        }
    }).then((res) => {
        return res.data
    }).catch((err) => {
         
        return Promise.reject( err);
    });
   
    export const getPattern = (id) => http({
        url: ENDPOINTS.secretariat.SecretariatIndicatorPattern.load,
        method: "get",
        headers: { "Accept-Language": "en" },
        params: {
            Id:id
    
            }
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return Promise.reject( err);
        });
       
       