import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";




export const getWarehouseDictionaryApi = (async ( ) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.dictionaryHasAccess,
    method: 'get',
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)
