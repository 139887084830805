import { Toast } from "components/ToastShow/ToastShow"
import { ENDPOINTS } from "utils/endpoints"
import { http } from "utils/http"

export const getOtherGroups = (PageNumber: number, PageSize: number , orderFormat: string = "", SearchString?:any ,filterlist?:any) => http({
    url: ENDPOINTS.BaseInformation.groupPosition.browse,
    method: "get",
    params: {
        PageNumber: PageNumber,
        PageSize: PageSize,
        OrderByString: orderFormat,
        SearchString: SearchString,
        FilterString:filterlist
    }
}).then((res) => {
    return res.data
}).catch((err) => {

    return Promise.reject( err);
});

export const deleteGroupPosition = (id) => http({
    url: ENDPOINTS.BaseInformation.groupPosition.browse,
    method: "delete",
    params: {
        id:id
    }
}).then((res) => {
    return res.data
}).catch((err) => {

    return Promise.reject( err);
});



export const getGroupPositionId = (id: string) => http({
    url: ENDPOINTS.BaseInformation.groupPosition.load,
    method: "get",
    params: {
        Id: id

    }
}).then((res) => {
    return res.data
}).catch((err) => {

    return Promise.reject( err);
});


export const setGroupPositionList = (data) => http({
    url: ENDPOINTS.BaseInformation.groupPosition.details,
    method: "put",
    data: data
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});



export const getGroupPositionDetail = (groupPositionId ) => http({
    url:  ENDPOINTS.BaseInformation.groupPosition.details,
    method: "get",
    params: {
        groupPositionId: groupPositionId
    }
}).then((res) => {
    return res.data
}).catch((err) => {

    return Promise.reject( err);
});


export const getPositionList = (SearchString=null) => http({
    url: ENDPOINTS.BaseInformation.position.browse,
    method: "get",
    params:{
        searchString : SearchString
    }

}).then((res) => {
    return res.data
}).catch((err) => {

    return Promise.reject( err);
});


// export const getGroupPosition = (GroupPositionId: any) => http({ //TODO سمت بک اند تکمیل نیست
//     url: ENDPOINTS.BaseInformation.GroupPosition.load,
//     method: 'get',
//     params: {
//         personId: GroupPositionId
//     }
// }).then((respons) => (respons))






export const sendGroupPosition = (data: string, method) => http({
    method: method,
    url: ENDPOINTS.BaseInformation.groupPosition.browse,
    data : data


}).then((res) => {
     
    return res
}).catch((err) => {
    return  Promise.reject( err);
});
export const getGroupPosition = (id) => http({
    method: "get",
    url: ENDPOINTS.BaseInformation.groupPosition.load,
    params:{
        Id : id
    }


}).then((res) => {
     
    return res
}).catch((err) => {
    return  Promise.reject( err);
});