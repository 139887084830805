import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const  getIndicatorPatternsApi =( async () =>  await http({
    url: ENDPOINTS.secretariat.indicatorPatterns.dictionary,
    method: 'get',
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)
export const PostIndicatorPattern = (data) => http({
    url: ENDPOINTS.secretariat.indicatorPatterns.browse,
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})
