import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";
import { ENDPOINTS } from "utils/endpoints";

export const getDocsClassification = async (Id) =>
  await http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi
      .InventoryDocsClassification.load,
    method: "get",
    params: { Id: Id },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const postDocsClassificationApi = (data) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi
      .InventoryDocsClassification.browse,
    method: "post",
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const putDocsClassificationApi = (data) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi
      .InventoryDocsClassification.browse,
    method: "put",
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getDocsClassificationDictionary = async () =>
  await http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi
      .InventoryDocsClassification.dictionary,
    method: "get",
  }).then((response) => response.data);

export const deleteListDocsClassification = (id) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi
      .InventoryDocsClassification.browse,
    method: "delete",
    params: {
      id: id,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getListDocsClassification = async (
  searchString,
  orderBy,
  pageNum,
  pageSize,
  applicationId
) =>
  await http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi
      .InventoryDocsClassification.browse,
    method: "get",
    params: {
      searchString: searchString,
      orderBy: orderBy,
      PageSize: pageSize,
      PageNumber: pageNum,
      ApplicationId: applicationId,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
