
import { ButtonArrow } from "components/ButtonArrow/ButtonArrow";
import { DataGrid } from "components/DataGrid/DataGrid";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
import { Search } from "components/Search/Search";
import { Toast } from "components/ToastShow/ToastShow";
import { getPositionList } from "pages/OfficeAutomation/Features/BulletinBoard/api";
import { useEffect, useState } from "react";





interface Form{
    Title?:string,
    Status?:number,
    showForm:(e) => void,
    InvitedListhandler:(e) => void,
    modalWidth?:any
    InvitedList?:any
    usedListId?:any
    InvitedPersonType?:any
    defaultPeopleList?:any
    inTab?:any
    mode:any
}


export const InvitPeopleForm: React.FC<Form> = ({showForm,InvitedListhandler,defaultPeopleList,InvitedPersonType ,usedListId=[],inTab,InvitedList, modalWidth , mode}) => {
    const [mood , setMood] = useState("users")
    const [Moodassign , setMoodassign] = useState("users")
    const [flag , setFlag] = useState(false)
    const [response , setResponse] = useState<any>([])
    const [assignmentResponse , setAssignmentResponse] = useState<any>([])
    const [selectList , setSelectList] = useState<any>([])
    const [assignedList , setAssignedList] = useState<any>([])
    const [savedData , setSavedData] = useState<any>([])
    const [assignedSelectList , setAssignedSelectList] = useState<any>([])
    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)
    const [positionstr , setPositionstr] = useState<any>()
    const [positionAssignmentstr , setPositionAssignmentstr] = useState<any>()
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [modalData, setModalData] = useState<{add: boolean, Delete: boolean, view: boolean, edit: boolean} | undefined>();
    const [submitted, setSubmitted] = useState<boolean>(false)


    window.onresize = () => {
      setWidth(window.innerWidth)
  }

    let keys, Data:any = [], Dataassign:any = [];

    async function getPositionsListAsync(SearchString) {
            const Positionresponse = await getPositionList(SearchString);
            let list:any=[]
       
          Positionresponse.Data.forEach(item => {
            if(!usedListId.includes(item.Id)){

            let obj:any =new Object
            obj.Id=item.Id
            obj.TU_RELATION=1
            obj.InvitedPersonType=InvitedPersonType
            obj.Title=item.Description
            list.push(obj) 
          }
          });


         setResponse(list)   

        
  }
    async function getPositionsListSearchingAsync(SearchString) {      
            const Positionresponse = await getPositionList(SearchString); 
            let list:any=[]
            Positionresponse.Data.forEach(item => {
                let obj:any =new Object
                obj.Id=item.Id
                obj.TU_RELATION=1
                obj.InvitedPersonType=InvitedPersonType
                obj.Title=item.Description
                list.push(obj) 
              
            });
             setAssignmentResponse(list)    
  }



    async function getInvitedListAsync() {

      let makeData:any =[] 

      if(InvitedList){
        InvitedList.map(item=>{
          makeData.push({Id:item.GroupPositionId ? item.GroupPositionId :item.PositionId,InvitedPersonType:InvitedPersonType  ,TU_RELATION :item.GroupPositionId ? 2 :1  , Title:item.Description })
        })
        setSavedData(makeData)
        setAssignedList(makeData)
      }else if(defaultPeopleList){
        defaultPeopleList.map(item=>{
          makeData.push({Id:item.PositionId,InvitedPersonType:1  ,TU_RELATION :1  , Title:item.Description })
        })
        setSavedData(makeData)
        setAssignedList(makeData)

      }
    }

    function backward() {
      setAssignedSelectList([])
      setResetMultiSelect(!resetMultiSelect)


      const updatedData = selectList.map(item => {
        return {...item, ...modalData}
      })
      
      setAssignedList(assignedList.concat(updatedData))
      setFlag(!flag)
      setSelectList([])
      setSubmitted(false)
    }



    function forward() {
      setResetMultiSelect(!resetMultiSelect)
      let list:any =[]
      assignedList.forEach((item1,index1) => {
          let flag = true

          assignedSelectList.forEach((item2 , index2 ) => {
              if(item2.Id ==item1.Id ){
                flag = false
              }
          });
          if(flag){
            list.push(item1)
          }
        });

        setAssignedList(list)
    }



    const selectListCallBack =(list)=>{
        
      setSelectList(list[1])
    }

    const assignedselectListCallBack =(list)=>{
        setAssignedSelectList(list[1])
    }

    const referenceFormSubmit =(e)=>{
      e.preventDefault()

      if(assignedList.length==0){
        Toast("msg_assigneduserscannotempty" , "error")
      }else{
          e.preventDefault();
          let Data = assignedList.map(item=>{
         
            let obj ={
              [item.TU_RELATION == 1 ? "PositionId"  :"GroupPositionId" ] : item.Id,
              [item.TU_RELATION == 1 ? "PositionDescription"  :"GroupPositionDescription" ] : item.Title,
              InvitedPersonType:InvitedPersonType

            }
            return obj
          })

        InvitedListhandler(Data.length>0 ? Data : [] )
      }
    }

      const searching =(e)=>{
        setPositionstr(e == "" ? null:e)
        getPositionsListAsync(e == "" ? null:e)
      }

      const assignmentsearching =(e)=>{
        setPositionAssignmentstr(e == "" ? null:e)
        getPositionsListSearchingAsync(e == "" ? null:e)
      }




    useEffect(() => {
      if(inTab){

        getPositionsListAsync(positionstr);     
        getPositionsListSearchingAsync(positionAssignmentstr)
      }
    }, [mood ,usedListId ,inTab]);

    useEffect(() => {
      if(defaultPeopleList){
        getInvitedListAsync();
      }
    }, [defaultPeopleList]);
    useEffect(() => {

        if(inTab ){
          let Data = assignedList.map(item=>{
         
            let obj ={
              [item.TU_RELATION == 1 ? "PositionId"  :"GroupPositionId" ] : item.Id,
              [item.TU_RELATION == 1 ? "PositionDescription"  :"GroupPositionDescription" ] : item.Title,
              InvitedPersonType:InvitedPersonType
            }
            return obj
          })

      
        InvitedListhandler(Data.length>0 ? Data : [] )

        }
      

    }, [inTab ,assignedList]);


    if(response && assignedList){
      let assignmentdata:any =[]

      assignedList.forEach(elm => {
        if(Moodassign=="users"){
          if(elm.TU_RELATION==1)assignmentdata.push(elm)
        }else{
          assignmentdata.push(elm)
        }
      });
      assignmentResponse.map(obj => {
        const isSameId = assignmentdata.some(item => item.Id === obj.Id)
        const currentItem = assignmentdata.find(item => item.Id === obj.Id)
        if(currentItem) { 
          const {add, view, edit, Delete} = currentItem;
          if(isSameId){
            Dataassign.push({...obj, add, view, edit, Delete})
          }
        }
      })
      response.map(item1=>{
        let flag = true
        assignedList.forEach(item2 => {
          if(item1.Id ==item2.Id){
            flag=false
          }
        });
        if(flag){
          Data.push(item1) 
        }
      })
  }
    
    return ( <>
            {inTab  &&
            <form onSubmit={referenceFormSubmit}>
                  <div style={{border:"none"}} className="card mt-3"> 
                    <div className="body">
                       <div style={{width:"100%" ,paddingBottom: "39px"}}  className="">
                       <FormWithTitle title="AvailablePositions">
                        <div className="">
                          <div className="d-flex justify-content-between align-items-center mb-1  ">
                            <Search defultSerach={positionstr} checkStop={false} onChange={searching} />
                          </div>
                          <div className="tableBoxInAccesses">
                              {response &&<DataGrid
                                    theadData={["Id" ,"Title"]}
                                    multiSelect={true}
                                    selectList={selectListCallBack}
                                    resetMultiSelect={resetMultiSelect}
                                    parentName={"referencePosition"}
                                    tbodyData={Data}
                                    minCellWidth={80}
                                    scrollHeight={"230px"}
                                    />
                              }                               
                          </div>
                        </div>
                        </FormWithTitle>
                       </div>
                                
                        <div className="col-lg-1 col-md-1 d-flex  flex-lg-column flex-md-column justify-content-center align-items-center">
                          <ButtonArrow type="backward" onClick={backward}/>
                          <ButtonArrow type="forward" onClick={()=>{forward()}}/>
                          
                        </div>

                        <div  style={{width:"100%" ,paddingBottom: "39px"}} className="">
                          <FormWithTitle title="AssignedPositions">
                           <div className="  ">
                        <div className="d-flex justify-content-between align-items-center mb-1 ">
                          <Search defultSerach={positionAssignmentstr}  checkStop={false} onChange={assignmentsearching} />
                        </div>     
                          <div   className="tableBoxInAccesses">                        
                           <DataGrid
                                 theadData={["Id" ,"Title"]}
                                 multiSelect={true}
                                 parentName={"referencePositionAssigned"}
                                 tbodyData={Dataassign}
                                 selectList={assignedselectListCallBack}
                                 minCellWidth={80}
                                 resetMultiSelect={resetMultiSelect}
                                 scrollHeight={"230px"}
                             />  
    
                                
                          </div>
                           </div>  
                           </FormWithTitle>
                        </div>  
                    
                    </div>
                  
                </div>
{/*                   <div className="col-lg-12 mt-3 btns_form">
                    <Button value="Save" btnType="submit" />
                    <Button
                      btnType="cancel"
                      value="Cancel"
                      onClick={()=>Toast("modalclosemessage" , "warning" , "alertWithoutClose" , hide )}
                    />
                </div> */}
              </form>
            }
            </>
    );
}