import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getDictionarySelectBox = (async (endPoint,baseUrl?,params?) => await http({
    baseURL:baseUrl,
    url: endPoint,
    method: 'get',
    params : {
        ...params
    }
})
    .then((response) => (response.data))

)