import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const sendSettings = (async ( data) => await http({
    baseURL:process.env.REACT_APP_CHAT_SERVICE_URL,
    url: ENDPOINTS.chatWeb.appSettings.load,
    method: 'post',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const ResetCache = (async ( ) => await http({
    baseURL:process.env.REACT_APP_CHAT_SERVICE_URL,
    url: ENDPOINTS.chatWeb.appSettings.resetCache,
    method: 'get',
  
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)



