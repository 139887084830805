import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getListSharingCenterArticle = (async (searchString, orderBy,pageNum ,pageSize) => await http({
    url: ENDPOINTS.SharingCenterApi.SharingCenterArticleClassification.browse,
    method: 'get',
    params: {
        searchString: searchString,
        orderBy: orderBy,
        PageSize: pageSize,
        PageNumber: pageNum,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
})

)


export const getSharingCenterArticle = (async (id) => await http({
    url: ENDPOINTS.SharingCenterApi.SharingCenterArticleClassification.load,
    method: 'get',
    params: {Id : id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)


export const deleteSharingCenterArticle =  (id) =>  http({
    url: ENDPOINTS.SharingCenterApi.SharingCenterArticleClassification.browse,
    method: 'delete',
    params: {Id : id}

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
})


export const postSharingCenterArticle = (data) => http({
    url: ENDPOINTS.SharingCenterApi.SharingCenterArticleClassification.browse,
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const putSharingCenterArticle = (data) => http({
    url: ENDPOINTS.SharingCenterApi.SharingCenterArticleClassification.browse,
    method: 'put',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
    return Promise.reject( err);
})

