import { NOTIFYING_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSNOTIFYING } from "utils/endpoints";
import { http } from "utils/http";

export const getMessages = (async (pageNum,  pageSize  ,searchString ,orderBy,filterlist) => await http({
    baseURL:NOTIFYING_URL,
    url: ENDPOINTSNOTIFYING.messageSettingsApis.messageSetting.browse,
    method: 'get',
    params:{
        PageNumber: pageNum,
        PageSize: pageSize,
        OrderByString:orderBy,
        SearchString : searchString ,
        FilterString:filterlist,
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const getMessage = (async (id) => await http({
    baseURL:NOTIFYING_URL,
    url: ENDPOINTSNOTIFYING.messageSettingsApis.messageSetting.load,
    method: 'get',
    params: {Id : id},
    headers: {"Accept-Language": "en"},
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const deleteMessage = (id) => http({
    baseURL:NOTIFYING_URL,
    url: ENDPOINTSNOTIFYING.messageSettingsApis.messageSetting.browse,
    method: 'delete',
    params:{
        id:id
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const sendMessagesApi = (data , method) => http({
    baseURL:NOTIFYING_URL,
    url: ENDPOINTSNOTIFYING.messageSettingsApis.messageSetting.browse,
    method: method,
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


