import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { SelectBox } from "components/Select/Select";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { GUID_EMPTY, ICON_TYPE } from "utils/constants";
import { getPageSize } from "utils/helpers";
import { GenerateFromSource, getSources } from "../api";

interface DictionaryTypeSelectProps {
  value?: any;
  mode?: string;
  resetForm?: boolean;
  dataChange?: boolean;

  Change?: (e) => void;
  SourceObjCallBack: (e: any, obj?: any) => void;
  SalesInvoiceDetailCallBack?: any;
  invoiceConstraint?: any;
  setShowForm?: any;
}
export const SalesReturnInvoiceSourceId = ({
  value,
  mode,
  dataChange,
  resetForm,
  SourceObjCallBack,
  Change,
  SalesInvoiceDetailCallBack,
  invoiceConstraint,
  setShowForm,
}: DictionaryTypeSelectProps) => {
  const [defaultval, setdefault] = useState(value);
  const [sourceType, setSourceType] = useState<any>(null);
  const [showSources, setShowSources] = useState<any>();
  const [selectedObj, setSelectedObj] = useState<any>();


  const [selectList, setSelectList] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [data, setData] = useState<any>();
  const sourceData = ["WithoutSource", "SalesOrders", "SalesPreInvoices"];
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [pages, setPages] = useState<number>(0);
  const [response, setResponse] = useState<any>();
  const [search, setsearch] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);

  async function getSourcesAsync(pagNum = pageNum, searchSTR = search) {
    setLoading(false);
    try {
      let pageSizeChangedByUser = getPageSize("SalesReturnInvoiceSourceId");
      setPageSize(pageSizeChangedByUser);
      const res = await getSources(pagNum, 5, searchSTR);

      setData(res.Data);
      setShowSources(true);

      if (
        res.Data.Data.length <= 1 &&
        (res.Data.Data[0].Id == GUID_EMPTY || res.Data.Data[0].Id == null)
      ) {
        setLoadingDetails(true);
      } else {
        setSelectedObj(res.Data.Data[0]);
      }

      if (res.Data.Data.length > 0) {
        setData(res.Data);

        setPages(
          res.Data.TotalRecords > pageSizeChangedByUser
            ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser)
            : 1
        );
      }
    } catch (error) {}

    setLoading(true);
  }

  async function GenerateFromSourceAsync() {
    setLoadingDetails(false);
    try {
      const res = await GenerateFromSource(selectedObj.Id, "SalesInvoices");

      SourceObjCallBack(res.Data, "SalesInvoices");
      setResponse(res.Data.SalesReturnInvoiceDetailDTOList);
    } catch (error) {}
    setLoadingDetails(true);
  }

  const handleGoPage = (value) => {
    if (value) {
      setPageNum(+value);
      getSourcesAsync(+value);
    } else {
    }
  };
  const handleNextPage = () => {
    if (data.TotalRecords && data.TotalRecords / pageSize > pageNum) {
      setPageNum((pageNum) => {
        return +pageNum + 1;
      });
      getSourcesAsync(pageNum + 1);
    }
  };
  const handlePrevPage = () => {
    if (pageNum > 1) {
      setPageNum((pageNum) => {
        return +pageNum - 1;
      });
      getSourcesAsync(pageNum - 1);
    }
  };

  const handleChangePageSize = (e: any) => {
    setPageNum(1);
    setPageSize(e.currentTarget.value);
    getSourcesAsync(1);
    getPageSize("SalesReturnInvoiceSourceId", e.currentTarget.value);
  };

  useEffect(() => {
    setdefault(value);
  }, [resetForm]);

  const selectedObjCallback = (obj, index) => {
    setSelectedObj(obj);
    GenerateFromSourceAsync();
  };

  const selectListCallBack = (list) => {
    setSelectList(list[1]);
  };
  const searchHandler = (str) => {
    setPageNum(1);
    setSelectedObj(null);
    if (str === "") {
      setsearch(null);
      getSourcesAsync(1, null);
    } else {
      setsearch(str);
      getSourcesAsync(1, str);
    }
  };

  return (
    <>
      <>
        {showSources && (
          <ModalForm
            modalHeight={49}
            onHide={() => {
              setShowSources(false);
            }}
            mode={mode}
            show={showSources}
            title={"Source"}
          >
            <div className="card">
              {loading ? (
                <div className="card-body">
                  <div
                    className="p-2"
                    style={{ height: "65%", overflowY: "auto" }}
                  >
                    <Toolbar
                      helpBtn={false}
                      current={false}
                      search={true}
                      excel={false}
                      view={false}
                      create={false}
                      refresh={false}
                      handleSearch={searchHandler}
                      handleClickToolbar={() => {}}
                      emptyList={response?.length == 0}
                    />
                    <DataGrid
                      parentName={"Sources"}
                      theadData={Object.keys(data.Data[0])}
                      tbodyData={data.Data}
                      startIndex={2}
                      minCellWidth={80}
                      selectedRowObjectCallback={selectedObjCallback}
                      rowSelected={selectedObj}
                      totalRecords={data.TotalRecords}
                      pageNum={pageNum}
                      pageSize={pageSize}
                      HandleNextPage={handleNextPage}
                      HandlePrevPage={handlePrevPage}
                      handlechangePageSize={handleChangePageSize}
                      first={() => {
                        getSourcesAsync(pages);
                        setPageNum(pages);
                      }}
                      end={() => {
                        getSourcesAsync(1);
                        setPageNum(1);
                      }}
                      handleGoPage={handleGoPage}
                    />
                  </div>

                  <div
                    className="details mt-5 p-2"
                    style={{ height: "40%", overflowY: "auto" }}
                  >
                    {loadingDetails ? (
                      response?.length >= 0 ? (
                        <>
                          <DataGrid
                            parentName={"saleReturn1InvoiceDetail1"}
                            multiSelect={true}
                            selectList={selectListCallBack}
                            startIndex={17}
                            theadData={Object.keys(
                              invoiceConstraint?.DetailDTO
                            )}
                            tbodyData={
                              response?.length > 0
                                ? response
                                : [invoiceConstraint?.DetailDTO]
                            }
                            scrollHeight={"67vh"}
                            minCellWidth={80}
                          />
                        </>
                      ) : (
                        <Message message={"NoDataForPreview"} type={"info"} />
                      )
                    ) : (
                      <Loading />
                    )}
                  </div>

                  <div className="col-lg-12 mt-1 btns_">
                    <Button
                      value="select"
                      btnType="submit"
                      disabled={!selectList.length}
                      onClick={(e) => {
                        SalesInvoiceDetailCallBack(selectList);
                        setShowSources(false);
                      }}
                    />
                    <Button
                      btnType="cancel"
                      value="Cancel"
                      onClick={() => setShowSources(false)}
                    />
                  </div>
                </div>
              ) : (
                <Loading />
              )}
            </div>
          </ModalForm>
        )}
      </>
      <div className="row">
        <div className="col-12">
          <SelectBox
            onChange={(e) => {
              setSourceType(e.target.value);
            }}
            lable={"Source"}
            selectType={"select"}
            options={["SalesInvoice"]}
            val={[null, "1", "2"]}
            name={"SourceId"}
            value={value}
            resetForm={resetForm}
            mode={mode}
          />
        </div>

        {!selectList.length && (
          <div
            className="col-2"
            style={{ marginTop: "25px", width: "1%", marginRight: "-25px" }}
          >
            <Button
              Icon={true}
              value={
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    getSourcesAsync();
                  }}
                  className="checkButton"
                >
                  {" "}
                  <FontAwesomeIcon icon={[ICON_TYPE, "plus"]} size="2x" />
                </button>
              }
              btnType="primary"
            />
          </div>
        )}
      </div>
    </>
  );
};
