

import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";

import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { deleteItemMainPropertyValues, getListItemMainPropertyValues } from "../api";
import { ItemMainPropertyValueForm } from "./ItemMainPropertyValueForm";


interface Form {
    ItemGroupKeyObj;
    onHide: (e:boolean) => void;
    mode;
    relod: () => void,
}
export const ItemMainPropertyValues = ({ 
    ItemGroupKeyObj,
    // mode
}: Form) => {

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [flag, setFlag] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [userChanges, setUserChanges] = useState({});
    const [mode,setMode] = useState<any>()

    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pages, setPages] = useState<number>(0);




    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("ItemMainPropertyValues" ,e.currentTarget.value )
        getListItemMainPropertyValuesAsync(1)
      };


      function handleGoPage(value): void {
        if (value) {
            if (value < 1 || value > pages) { }
            else {setPageNum(+value);getListItemMainPropertyValuesAsync(+value)}
        } else {
        }
    }
    
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getListItemMainPropertyValuesAsync(pageNum + 1);
          };
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getListItemMainPropertyValuesAsync(pageNum - 1);
          };
    };



    const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }
    const  handleCreateRow = () => {
        setSelectedObj(null)
       setMode("create");
        setShowForm(true)
    }
    const handleEditRow = () => {
       setMode("edit");
        setShowForm(true)
    }
    const handleDeleteRow = async() => {
        try {
            const res = await deleteItemMainPropertyValues(selectedObj.Id)
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }
    }
    const handleViewRow = () => {
       setMode("view")
        setShowForm(true)
    }

    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false"); setUserChanges({}) });
        }else{
            setSelectedObj(obj)         
        };
    }



    async function getListItemMainPropertyValuesAsync(PageNum =pageNum) {
        setLoading(false)
        let pageSizeChangedByUser = getPageSize("ItemMainPropertyValues" )
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getListItemMainPropertyValues(search ,orderBy.length == 0 ?null:  orderBy.join(","),ItemGroupKeyObj.Id ,PageNum,pageSizeChangedByUser);
            setResponse(res.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) 
           
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }

        setLoading(true)
    }


    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        if(ItemGroupKeyObj){
            getListItemMainPropertyValuesAsync();

        }
    }, [forceUpdate, flag  ,search , orderBy,ItemGroupKeyObj]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);

    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,1 ,trans("ItemMainPropertyValues"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create":  handleCreateRow(); break;
            case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id)selectedObj.SystemReserved ? Toast(trans("Msg_CanNotEditSystemReservedData") , "error") : handleEditRow(); break;
            case "help": alert("call api help"); break;
        }
    };
    /* #endregion */

    return (
        <>  

            {showForm&&
                <ModalForm modalHeight={49} mode={mode} onHide={()=>{setShowForm(false)}} show={showForm} title={"itemmainpropertyvalueslist" }  >
                    <ItemMainPropertyValueForm mode={mode} colorValue={ItemGroupKeyObj.SystemKey.toLowerCase().includes("color")} selectObj={selectedObj} propertyId={ItemGroupKeyObj?.Id} reload={()=>setForceUpdate(!forceUpdate)}   onHide={()=>{setShowForm(false)}} /> 
                </ModalForm>  
            } 
            <div className="cartable-action">
                <Toolbar handleSearch={searchHandler} warehouse={false}   id={selectedObj?.Id} emptyList={response?.TotalRecords == 0} view={true}edit={true} remove={true} create={true}search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card">
                    <div className="">
                            <>
                            {
                                    loading ?
                                    response?.Data?.length > 0 ?
                                        <>
                                            <DataGrid
                                                orderFormat={setOrderBy}
                                                orderFormatList={orderBy}
                                                parentName={"ItemMainPropertyValues"}
                                                startIndex={1}
                                                theadData={Object.keys(response.Data[0])}
                                                tbodyData={response.Data}
                                                minCellWidth={80}
                                                scrollHeight={showForm? "25vh": "67vh"}
                                                selectedRowObjectCallback={selectedIdCallback}
/*                                                 handleEdit={handleEditRow}
                                                handleView={handleViewRow} */
                                                handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                                totalRecords={response.TotalRecords}
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                HandleNextPage={handleNextPage}
                                                HandlePrevPage={handlePrevPage}
                                                handlechangePageSize={handleChangePageSize}
                                                first={() =>{getListItemMainPropertyValuesAsync(pages); setPageNum(pages)}}
                                                end={() =>{getListItemMainPropertyValuesAsync(1); setPageNum(1)}}
                                                handleGoPage={handleGoPage}
                                            />
                                        </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                                :<Loading/>
                                }
                            </>

                    </div>
                </div>
            </div>
        </>

    );
}