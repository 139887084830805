
import { DataGrid } from "components/DataGrid/DataGrid";
import { useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { Button } from "components/Button/Button";
import { ButtonArrow } from "components/ButtonArrow/ButtonArrow";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Toast } from "components/ToastShow/ToastShow";
import { Search } from "components/Search/Search";
import { Application } from "components/Application/Application";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { getCustomersUsersasync, getUsersasync } from "../api/api";
import { OrganizationType } from "types/enum";



interface Form{
    Title?:string,
     Method?:string,
    Status?:number,
    Task?:any,
    showForm:(e) => void,
    addUser:(e,list) => void,
    modalWidth?:any
    userList?:any
    GroupMembers?:any
    contactName?:any
}


export const GroupMembersAssign: React.FC<Form> = ({showForm,Task ,addUser, Method, modalWidth ,contactName ,GroupMembers ,userList}) => {
    const assignmentMethod = Method && Method.trim().length > 1 ? Method : "MANUAL"
    const [mood , setMood] = useState("groups")
    const [Moodassign , setMoodassign] = useState("All")
    const [flag , setFlag] = useState(false)
    const [response , setResponse] = useState<any>([])
    const [assignmentResponse , setAssignmentResponse] = useState<any>([])
    const [selectList , setSelectList] = useState<any>([])
    const [assignedList , setAssignedList] = useState<any>([])
    const [savedData , setSavedData] = useState<any>([])
    const [assignedSelectList , setAssignedSelectList] = useState<any>([])
    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)
    const [massageType , setMassageType] = useState<any>("info")
    const [massageType2 , setMassageType2] = useState<any>("info")
    const [loading , setLoading] = useState<any>(false)
    const [loading2 , setLoading2] = useState<any>(false)
    const [positionstr , setPositionstr] = useState<any>()
    const [positionAssignmentstr , setPositionAssignmentstr] = useState<any>()
    const [width, setWidth] = useState<number>(window.innerWidth);

    const layoutContext = useContext(LayoutContext);

    window.onresize = () => {
      setWidth(window.innerWidth)
  }

    let keys, Data:any = [], Dataassign:any = [];

  async function getPositionsListAsync(SearchString) {
      setLoading(false)
      let list:any = []
      if(layoutContext?.currentUserPosition?.OrganizationType == OrganizationType.Customers)
          list = await getCustomersUsersasync(SearchString)
      else
          list = await getUsersasync(SearchString)
        let newlist = list.Data.Data.map(item=>{
            return{Id:item.Id , Name:item.Description}
        })
      setResponse(newlist)   
      setLoading(true)
  }
    async function getPositionsListSearchingAsync(SearchString) {




    setAssignmentResponse([])   
        


        
  }



    async function getTaskUserAsync() {
      setLoading2(false)
      try {
      let makeData:any =[] 
      if(GroupMembers){
          GroupMembers.map(item=>{
              makeData.push({Id:item.userId, Name:item.userName })
            })
            
        setSavedData(makeData)
        setAssignedList(makeData)
      }


        
      } catch (error) {
        setMassageType2("error")
      }
      setLoading2(true)
  
      }



    function changeDataGrid(e) {
        setMood(e)

      }
    function hide() {
      showForm(false)

      }
    function changeDataGridassign(e) {
        setMoodassign(e)

      
      }
    function backward() {
      setAssignedSelectList([])
        setResetMultiSelect(!resetMultiSelect)
        setAssignedList(assignedList.concat(selectList) )
        setFlag(!flag)
        setSelectList([])
      
      }
    function forward() {
      setResetMultiSelect(!resetMultiSelect)
        let list:any =[]
        assignedList.forEach((item1,index1) => {
          let flag = true
          assignedSelectList.forEach((item2 , index2 ) => {
              if(item2.Id ==item1.Id ){
                flag = false
              }


          });
          if(flag){
            list.push(item1)
          }
        });
        setAssignedList(list)
      
      }
      const selectListCallBack =(list)=>{          
       setSelectList(list[1])
      }
      const assignedselectListCallBack =(list)=>{
          setAssignedSelectList(list[1])
     
    
      }
      const referenceFormSubmit =(e)=>{
        addUser(e,assignedList )
     


      }

      const searching =(e)=>{
        setPositionstr(e == "" ? null:e)
        getPositionsListAsync(e == "" ? null:e)

      }
      const assignmentsearching =(e)=>{
        setPositionAssignmentstr(e == "" ? null:e)
        getPositionsListSearchingAsync(e == "" ? null:e)

      }








    useEffect(() => {
        getPositionsListAsync(positionstr);     
        getPositionsListSearchingAsync(positionAssignmentstr)
      }, [mood]);

    useEffect(() => {
        getTaskUserAsync();
    
       
 }, []);


      if(response && assignedList ){
        let assignmentdata:any =[]

        
        response.forEach(item1 => {
          let flag =false
          assignedList.forEach(item2 => {
            if(item1.Id == item2.Id){
              flag = true
            }
            
          });
          if(flag){
            Dataassign.push(item1)
          }
          
        });
        
        
        
        response.map(item1=>{
            let flag = true
            assignedList.forEach(item2 => {
                if(item1.Id ==item2.Id){
                    flag=false
                }
            });
            if(flag){
                Data.push(item1) 
            }
        })
        

    }
    
    

    return ( 
            <form onSubmit={referenceFormSubmit}>
                   <div className="card"> 
                    <div className="">
                       <div className="body"> 
                        <div className="col-lg-5 col-md-5 mb-3 ">
                         <div className="d-flex justify-content-between align-items-center mb-1  ">
                            <span>{trans("users ")}</span>
                            <Search onChange={searching} />
                           </div>
                          <div className="tableBox">
                            {loading ?
                                 <>
                                    {response ? <DataGrid
                                          theadData={["Id" ,"Name"]}
                                          multiSelect={true}
                                          parentName={"referencePosition"}
                                          tbodyData={Data}
                                          selectList={selectListCallBack}
                                          minCellWidth={80}
                                          resetMultiSelect={resetMultiSelect}
                                          scrollHeight={"230px"}
                                          />
                                          : <Message message={"NoDataForPreview"} type={massageType} />
                                  }                               
                                 </>
                                 : <Loading/>
                            }

                          </div>
                        </div>
                                
                        <div className="col-lg-1 col-md-1 d-flex  flex-lg-column flex-md-column justify-content-center align-items-center">
                          <ButtonArrow type="backward" onClick={backward}/>
                          <ButtonArrow type="forward" onClick={()=>{forward()}}/>
                          
                        </div>

                        <div className="col-lg-5 col-md-5  ">
                        <div className="d-flex justify-content-between align-items-center mb-1 ">
                           <span>{trans("اعضای گروه")}</span>
                          <Search onChange={assignmentsearching} />
                        </div>      
                          <div   className="tableBox">
                              {loading2 ?
                                     <>
                                        {Dataassign ?                         
                                            <DataGrid
                                                  theadData={["Id" ,"Name"]}
                                                  multiSelect={true}
                                                  parentName={"referencePositionAssigned"}
                                                  tbodyData={Dataassign}
                                                  selectList={assignedselectListCallBack}
                                                  minCellWidth={80}
                                                  resetMultiSelect={resetMultiSelect}
                                                  scrollHeight={"230px"}
                                              />  
                                              : <Message message={"NoDataForPreview"} type={massageType2} />
                                      }                               
                                     </>
                                     : <Loading/>
                                }
                          </div>
                        </div>  
                      </div>
 
                    </div>
                </div>
                <div className="col-lg-12 mt-3 btns_form">
                <Button value="Save" btnType="submit" />
                <Button
                  btnType="cancel"
                  value="Cancel"
                  onClick={()=>Toast(trans("modalclosemessage") , "warning" , "alert" , hide )}
                />
              </div>
              </form>

    );
}