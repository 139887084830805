import { INVENTORYANDSALES_URL,STORAGE_URL } from "utils/constants";
import { ENDPOINTS } from "utils/endpoints";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getItemStoreListApi = (async (rootId ,pagenum,fromPrice=null,toPrice=null ,size) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.CustomersStoreApi.CustomersStore.browse,
    method: 'get',
    params:{
        groupLeafId:rootId,
        PageNumber:pagenum,
        PageSize:size?size:10,
        fromPrice,
        toPrice
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getItemGroupLabels = (async () => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.CustomersStoreApi.CustomersStore.groupLeaves,
    method: 'get',
    params:{

        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

export const loadCardImage = (async (attachId) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.attachThumbnail,
    method: 'get',
    params:{
        attachId:attachId
        },
        
}).then((res) => {

    return "data:image/png;base64,"+ res.data.Data	
}).catch((err) => {
 
    return Promise.reject( err);
})

)


export const loadCardImageWithSize = (async (attachId,size='256') => await http({
    baseURL:process.env.REACT_APP_FILEDOWNLOAD_SERVICE_URL,
    url:"file/Thumbnail/"+attachId+"?size="+size,
    method: 'get',

         responseType: "blob"
}).then((res) => {

    return blobToBase64( res.data)	
}).catch((err) => {
 
    return Promise.reject( err);
})

)