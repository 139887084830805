import { Button } from "components/Button/Button"
import { CheckableButton } from "components/CheckableButton/CheckableButton"
import { Toast } from "components/ToastShow/ToastShow"
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext"
import React, { useContext, useEffect, useState } from "react"
import { OrganizationType } from "types/enum"


interface props {
    setShowNotifSettingForm: React.Dispatch<React.SetStateAction<boolean>>
}

export const NotifSettingForm = ({setShowNotifSettingForm}: props) => {
    const layoutContext = useContext(LayoutContext);
    const savedNotifSettings = JSON.parse(localStorage.getItem('NotifSettingList') as string) 
    const [checkboxList, setCheckboxList] = useState<any>();
    

   

    const handleSubmit = (event) => {

        event.preventDefault()

        const data:any = [ ]
        checkboxList.map(item=>{
            if(!event.target[item]?.checked ){
                data.push(item)            
            }
            

        })


        localStorage.setItem('NotifSettingList', JSON.stringify(data))
        Toast('تنظیمات ثبت شد', 'success')
        setShowNotifSettingForm(false)
    }


    async function getNotifSettingListAsync() {
        try {
          let obj = {}
        //  const res = await  getNotifSettingList(layoutContext?.currentUserPosition?.OrganizationType == OrganizationType.Customers)
        //  let list:any = []
          setCheckboxList(["call" , "chat" , "cartable"])

        } catch (error) {
          
        }
      }
    
    useEffect(() => {    
          getNotifSettingListAsync()
    }, [ ]);
    
    

    return (
        <form onSubmit={handleSubmit}>
            <div className="card p-2">
                <div className="row mt-3">
                    {
                       checkboxList && checkboxList.map(item => (
                            <div className="col-2">
                                <CheckableButton 
                                    labelText={item}
                                    type="checkbox"
                                    inputName={item}
                                    defaultValue={item}
                                    defaultChecked={ savedNotifSettings ? !savedNotifSettings?.includes(item) : true }
                                />
                            </div>
                        ))
                    }
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <Button btnType="submit" value={'submit'} />
                    </div>
                </div>
            </div>
        </form>
    )
}