import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import Datepicker from "components/DatePicker/DatePicker";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import { Response } from "types/types";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { PostReminder, getPerson } from "./api";
import "./style.scss";
interface GroupPositionPropsType {
    onHide: () => void
    label?: string,
    hasBorder?: boolean,
    TextBoxName: string,
    value: string,
    SourceId: string
}
interface ResultData {
    key: string,
    text: string,
}
interface PersonId {
    PersonId: string
}
export const GroupPerson = ({ label, hasBorder, TextBoxName, value, SourceId , onHide}: GroupPositionPropsType) => {
    let key, Value;
    const [response, setResponse] = useState<Response | any>();
    const [reminder , setReminder] = useState<Response|any>();
    const [resetForm, setResetForm] = useState(false);
    const [resultData, setResultData] = useState<ResultData[]>([]);
    const [isoTime, setIsoTime] = useState(new Date().toISOString());
    const [personId, setPersonId] = useState<PersonId[]>([]);
    const [showWrapper, setShowWrapper] = useState(false);
    const [selectedItem, setSelectedItem] = useState<ResultData[]>([]);
    const debounceOnAutoCompleteChange = debounce(updateAutoComplete, 600);
    const autoCompleteInputRef = useRef(null);
    function updateAutoComplete(event) {
        get(event.target.value);
    }
    function setAsAutoCompleteValue(event) {
        setSelectedItem((old) => [...old, { key: event.target.getAttribute('data-key'), text: event.target.innerText }])
    }
    function deleteItemFromSelectedData(event) {
        let filteredDataList = selectedItem.filter((item) => {
            return item.key !== event.currentTarget.getAttribute("data-key");
        })
        for (let i = 0; i < filteredDataList.length; i++) {
            setPersonId([{ PersonId: filteredDataList[i].key }]);
        }
        if (filteredDataList.length == 0) {
            setPersonId([]);

        }
        setSelectedItem(filteredDataList);
    }


    if (showWrapper == true) {

        document.body.addEventListener("click", (e) => {
            let wraper = document.querySelector(".group-person__auto-complete-result-wrapper");
            let input = document.querySelector(".group-person__auto-complete-input");
            if (e.target !== wraper && e.target !== input) {
                setShowWrapper(false);
            }
        })
    }

    useEffect(() => {
        setResultData([]);
        if (response) {
            key = Object.keys(response);
            Value = Object.values(response);
            for (let j = 0; j < key.length; j++) {
                setResultData((old) => [...old, { key: key[j], text: Value[j] }]);
            }
        }
    }, [response]);

    let a: any[] = [];
    useEffect(() => {
        if (selectedItem.length > personId.length) {

            for (let i = 0; i < selectedItem.length; i++) {
                setPersonId([...personId, { PersonId: selectedItem[i].key }])
            }
        }

    }, [selectedItem])
    async function get(val) {
        try {
            const res = await getPerson(val)
            
            setResponse(res);
        } catch (error) {
            
        }
    }


   async function submit(e) {
        e.preventDefault();
        try {
            let a = {
                "Reminder": {
                    "Title": e.currentTarget.CartableReminder.value,
                    "ReminderTime": isoTime,
                    "SourceId": SourceId,
                    "SourceData" : "reference"
                },
                "PersonReminders": personId
            }
    
            await PostReminder(a);
            onHide()
            setResetForm(true);
            setSelectedItem([]);
            setPersonId([]);
            
        } catch (error) {
            
        }

    }

    return (
        <>
    {/*        <Toolbar create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
            {headerData && <Headerbar headerContents={headerData} hasBorder={true} />} */}
            
                <form className="card" onSubmit={submit}>
                     <div className="card-body" >
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                        <Textbox textboxName={TextBoxName} textboxType="text" resetForm={resetForm} labelText={trans("title")} required={true} defaultValue={reminder?.Title} />
                            </div>
        
                            <div className={`group-person card  col-md-4 col-sm-12 pt-0 ${!hasBorder && "remove-border"}`}>
                        <div className="group-person__auto-complete-container">
                            <label className="group-person__label m-0 px-1" htmlFor="group-person-auto-complete">{label}</label>
                            <div className="group-person__auto-complete">
                                <input ref={autoCompleteInputRef} className="group-person__auto-complete-input form-control-custom " onFocus={() => setShowWrapper(true)} onChange={debounceOnAutoCompleteChange} name="group-person-auto-complete" id="" autoComplete="off" />
                                {showWrapper && resultData.length !== 0 &&
                                    <div className={`group-person__auto-complete-result-wrapper card`}>
                                        {resultData.map((item, index) => (
                                            <div className="group-person__auto-complete-result" data-key={item.key} key={index + item.key} onMouseDown={setAsAutoCompleteValue}>
                                                {item.text}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="group-person__selected-options-wrapper">
                            {selectedItem.map((selectedItem, index) => (
                                <div className="group-person__selected-option form-control-custom" key={selectedItem.key + index}>
                                    {selectedItem.text}
                                    <div className="group-person__delete-selected-option" data-key={selectedItem.key} onClick={deleteItemFromSelectedData}>
                                        <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                                    </div>
                                </div>
                            ))}
                        </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                        <Datepicker withTime={true} val={reminder?.ReminderTime?reminder.ReminderTime:null} setIsoTime={(isoTime) => setIsoTime(isoTime)} lable={trans("calendarReminder")} type={"date"} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button value="submit" btnType="submit" />
                            <Button value="cancel" btnType="cancel" onClick={()=>{Toast(trans("modalclosemessage") , "warning" , "alert" , onHide )}} />
                        </div>     
                    </div>   
                </form>

        </>
    )
}