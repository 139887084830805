import { Button } from "components/Button/Button";
import { useState } from "react";
import { trans } from "utils/helpers";
import './style.scss'

interface AcceptSelfServiceProps {
    handleAcceptReferenceAsync? : () =>void;
    onHide? : (e:any) => void;
   
}

export const AcceptSelfService = ({onHide,handleAcceptReferenceAsync}:AcceptSelfServiceProps) => {

    return ( 
      <div className="AcceptContext mb-3">
        <div className="AcceptTitle" >
             <button onClick={(e)=>{onHide && onHide(e)}} type="button" className="closeBtn border-none alert-primary">
                 <span aria-hidden="true">&times;</span>
             </button>

             <div className="AcceptMassage">
                 {trans("Msg_DoYouWantToAcceptTheTask")}
             </div>
         </div>
         <div>
             <Button
              btnType="primaryFill"
              value="Accept"
              onClick={() =>{handleAcceptReferenceAsync && handleAcceptReferenceAsync()}}
             />
         </div>
      </div>
     );
}