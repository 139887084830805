import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { Message } from "components/Message/Message";
import { Loading } from "components/Loading/loading";
import { Toast } from "components/ToastShow/ToastShow";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { getListSalesInvoiceStatus, deleteListSalesInvoiceStatus } from "./api";
import { SalesInvoiceStatusForm } from "./SalesInvoiceStatusForm";

export const SalesInvoiceStatus = () => {
  const [response, setResponse] = useState<Response | any>(null);
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [mode, setMode] = useState("create");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [flag, setFlag] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [catchMessage, setCatchMessage] = useState<any>({
    type: "info",
    msg: "NoDataForPreview",
  });
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState<any>([]);
  const [search, setSearch] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [filterlist, setFilterlist] = useState<any>("");
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [pages, setPages] = useState<number>(0);

  const searchHandler = (str) => {
    if (str == "") {
      setSearch(null);
    } else {
      setSearch(str);
    }
  };

  const handleCreateAction = () => {
    setMode("create");
    setShowForm(true);
  };

  const handleEditRow = () => {
    setMode("edit");
    setShowForm(true);
  };

  const handleDeleteRow = async () => {
    try {
      await deleteListSalesInvoiceStatus(selectedObj?.Id);
      setForceUpdate(!forceUpdate);
    } catch (error) {}
  };
  const handleViewRow = () => {
    setMode("view");
    setShowForm(true);
  };

  const selectedIdCallback = (obj, index) => {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setSelectedObj(obj);
        setResetForm(true);
      });
    } else {
      setSelectedObj(obj);
    }
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setShowForm(false);
      });
    } else {
      setShowForm(false);
    }
  }
  async function getSalesInvoiceStatusAsync(pagNum = pageNum) {
    setLoading(false);
    setResponse(null);
    try {
      let pageSizeChangedByUser = getPageSize("SalesInvoiceStatus");
      setPageSize(pageSizeChangedByUser);
      const res = await getListSalesInvoiceStatus(
        search,
        orderBy.length == 0 ? null : orderBy.join(","),
        pagNum,
        pageSizeChangedByUser
      );
      setResponse(res);
      setCatchMessage({ type: "info", msg: "NoDataForPreview" });
      if (res.Data.Data.length > 0) {
        setResponse(res.Data);

        setPages(
          res.Data.TotalRecords > pageSizeChangedByUser
            ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser)
            : 1
        );
      }
    } catch (error: any) {
      setCatchMessage({ type: "error", msg: error?.response?.data?.Message });
    }
    setLoading(true);
  }

  const handleGoPage = (value) => {
    if (value) {
      setPageNum(+value);
      getSalesInvoiceStatusAsync(+value);
    } else {
    }
  };
  const handleNextPage = () => {
    if (response.TotalRecords && response.TotalRecords / pageSize > pageNum) {
      setPageNum((pageNum) => {
        return +pageNum + 1;
      });
      getSalesInvoiceStatusAsync(pageNum + 1);
    }
  };
  const handlePrevPage = () => {
    if (pageNum > 1) {
      setPageNum((pageNum) => {
        return +pageNum - 1;
      });
      getSalesInvoiceStatusAsync(pageNum - 1);
    }
  };

  const handleChangePageSize = (e: any) => {
    setPageNum(1);
    setPageSize(e.currentTarget.value);
    getPageSize("SalesInvoiceStatus", e.currentTarget.value);
    getSalesInvoiceStatusAsync(1);
  };

  useEffect(() => {
    setResponse(null);
    getSalesInvoiceStatusAsync();
  }, [forceUpdate, search, filterlist, orderBy, flag, forceUpdate]);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  /* #endregion */

  /* #region toolbarActions */
  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "print":
        alert("print ");
        break;
      case "excel":
        downloadCSV(response?.Data, 1, trans("SalesInvoiceStatus"));
        break;
      case "refresh":
        setForceUpdate(!forceUpdate);
        break;
      case "create":
        handleCreateAction();
        break;
      case "view":
        if (selectedObj?.Id) handleViewRow();
        break;
      case "edit":
        if (selectedObj?.Id) handleEditRow();
        break;
      case "remove":
        if (selectedObj?.Id)
          Toast(
            trans("msg_deleteconfirm"),
            "warning",
            "alert",
            handleDeleteRow
          );
        break;
      case "help":
        alert("call api help");
        break;
    }
  };
  /* #endregion */

  return (
    <>
      <div className="cartable-action">
        <Toolbar
          handleSearch={searchHandler}
          emptyList={response == null || response?.length == 0}
          id={selectedObj?.Id}
          remove={true}
          create={true}
          edit={true}
          search={true}
          handleClickToolbar={clickToolbarCallback}
        />
        <div
          className="card"
          style={{
            overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",
          }}
        >
          <div className="">
            {showForm && (
              <SalesInvoiceStatusForm
                reload={() => {
                  setForceUpdate(!forceUpdate);
                }}
                mode={mode}
                onHide={cancel}
                selectedObj={selectedObj}
              />
            )}

            {loading ? (
              response?.Data?.length > 0 ? (
                <>
                  <DataGrid
                    parentName={"SalesInvoiceStatus"}
                    orderFormat={setOrderBy}
                    orderFormatList={orderBy}
                    startIndex={1}
                    theadData={Object.keys(response?.Data[0])}
                    tbodyData={response?.Data}
                    minCellWidth={80}
                    selectedRowObjectCallback={selectedIdCallback}
                    handleEdit={handleEditRow}
                    handleDelete={(e) => {
                      Toast(
                        trans("msg_deleteconfirm"),
                        "warning",
                        "alert",
                        handleDeleteRow
                      );
                    }}
                    handleView={handleViewRow}
                    totalRecords={response?.TotalRecords}
                    pageNum={pageNum}
                    pageSize={pageSize}
                    HandleNextPage={handleNextPage}
                    HandlePrevPage={handlePrevPage}
                    handlechangePageSize={handleChangePageSize}
                    first={() => {
                      getSalesInvoiceStatusAsync(pages);
                      setPageNum(pages);
                    }}
                    end={() => {
                      getSalesInvoiceStatusAsync(1);
                      setPageNum(1);
                    }}
                    handleGoPage={handleGoPage}
                  />
                </>
              ) : (
                <Message message={catchMessage.msg} type={catchMessage.type} />
              )
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
