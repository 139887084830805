import { TREASURY_AND_ACCOUNTING_URL } from "utils/constants";
import { ENDPOINTSTREASURYANDACCOUNTING } from "utils/endpoints";
import { http } from "utils/http";

export const getBankAccountType = async (
  pageNum,
  pageSize,
  searchString,
  orderBy,
  filterlist
) =>
  await http({
    baseURL: TREASURY_AND_ACCOUNTING_URL,
    url: ENDPOINTSTREASURYANDACCOUNTING.Treasury.BaseDefinitionAndSettingApi.BankAccountType
      .brows,
    method: "get",
    params: {
      PageNumber: pageNum,
      PageSize: pageSize,
      OrderByString: orderBy,
      SearchString: searchString,
      FilterString: filterlist,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getBankAccountTypeById = async (id) =>
  await http({
    baseURL: TREASURY_AND_ACCOUNTING_URL,
    url: ENDPOINTSTREASURYANDACCOUNTING.Treasury.BaseDefinitionAndSettingApi.BankAccountType
      .load,
    method: "get",
    params: { Id: id },
    headers: { "Accept-Language": "en" },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const postBankAccountType = async (method, data) =>
  await http({
    baseURL: TREASURY_AND_ACCOUNTING_URL,
    url: ENDPOINTSTREASURYANDACCOUNTING.Treasury.BaseDefinitionAndSettingApi.BankAccountType
      .brows,
    method: method,
    data: data,
    headers: { "Accept-Language": "en" },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const deleteBankAccountType = (id) =>
  http({
    baseURL: TREASURY_AND_ACCOUNTING_URL,
    url: ENDPOINTSTREASURYANDACCOUNTING.Treasury.BaseDefinitionAndSettingApi.BankAccountType
      .brows,
    method: "delete",
    params: {
      id: id,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
