import { BreadCrumb } from "layouts/DashboardLayout/components/BreadCrumb/BreadCrumb";
import { DashboardLayout } from "layouts/DashboardLayout/DashboardLayout";
import {useContext, useEffect, useState} from "react";
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from "utils/routes";
import { renderRoutes, trans } from "utils/helpers";
import { auth } from "utils/auth";
import "./App.scss";
import { Loading } from "components/Loading/loading";
import { getProfile, getUserInfo } from "layouts/DashboardLayout/components/Header/component/profile/api";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { ToastShow } from "components/ToastShow/ToastShow";
import { getResources } from "layouts/DashboardLayout/components/Sidebar/api";





function App() {


  let layoutContext = useContext(LayoutContext);


  const [loading, setLoading] = useState<any>(false);
  const [demoUserInfo, setDemoUserInfo] = useState<any>();
  const [currentProfileOption, setCurrentProfileOption] = useState<any>();
  const LOCATION = useLocation();
  const PATH_SNIPPETS = LOCATION.pathname.split("/").filter((i) => i);



async function login() {
  try {
    await auth.attempt();
  } catch (error) {
    await auth.attempt();
  }

  if(localStorage.getItem("token") != null ){
    getProfileAsync()
  }
  
}

const delay = (duration) =>
new Promise(resolve => setTimeout(resolve, duration));

async function handleExit(e) {
  document.cookie = "logedIn=true"
  const Idtoken = localStorage.getItem("id_token")
  sessionStorage.removeItem("accept-data");
  localStorage.removeItem("token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("token_expire");
  localStorage.removeItem("CurrentApplication");
  const openedWindow:any = window.open(`${process.env.REACT_APP_AUTH_URL}/endsession?id_token_hint=${Idtoken}&post_logout_redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URL}`);

  if(!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') 
  { 
    window.location.href = "/"

  }else{
    await delay(250)
    openedWindow.close();
    window.location.href = "/"
  }
 }

  function handleGoToChat(e){
    let profile  = localStorage.getItem("profile") 
    if(profile == null){
        setCurrentProfileOption(demoUserInfo);
    }else{
      if(JSON.parse(profile).UserId ==demoUserInfo.Id ){
        let obj = {...demoUserInfo ,Description: JSON.parse(profile).Description }
        setCurrentProfileOption(obj);
        layoutContext.setCurrentUserPosition(obj);
      }else{
        localStorage.setItem("profile" ,JSON.stringify({Description:demoUserInfo.Description ,UserId:demoUserInfo.Id }) )

      }
    }
  }



async function getProfileAsync() {
  setLoading(false)
  try {
    sessionStorage.removeItem("accept-data")
    const res = await getProfile();
    localStorage.setItem("profile" , JSON.stringify({Description:res.ProfileList[0].Description ,UserId:res.ProfileList[0].UserId }))
    layoutContext.setUserPerson({ PersonId: res.ProfileList[0].PersonId, UserId: res.ProfileList[0].UserId })

   if(trans("r_version") ){
     if( (trans("r_version") !== res.ResourceVersion)  ){
       let language: any = localStorage.getItem("lang");
       if (language == null) {
         language = 'fa'
       }
       try {
        const res = await  getResources()
        if(res.data){
            localStorage.setItem(language, res.data)   
        }
       } catch (error) {

       }
  
     }


   }else{
    let language: any = localStorage.getItem("lang");
    if (language == null) {
      language = 'fa'
    }
    try {
      const res = await  getResources()
      if(res.data){
        localStorage.setItem(language, res.data)   
      }
    } catch (error) {
        
    }
    layoutContext.setResourceVersion(trans("ResourceVersion"))
   
   }
    if (layoutContext?.currentUserPosition?.PositionId?.length > 0) {
      setCurrentProfileOption(layoutContext.currentUserPosition);
      sessionStorage.setItem("accept-data", layoutContext.currentUserPosition.PositionId);
    } else {
      if (res.ProfileList.length > 0) {
        setCurrentProfileOption(res.ProfileList[0]);
        layoutContext.setCurrentUserPosition(res.ProfileList[0]);
        sessionStorage.setItem("accept-data", res.ProfileList[0].PositionId);
      }
    }
  } catch (err) {
    const res = await getUserInfo();
    layoutContext.setUserPerson({ UserId: res.UserId })
    setDemoUserInfo({Id:res.UserId  , Description : res.UserName})
  }
  setLoading(true)
}
/* useEffect(() => {
  const disableContextMenu = (event) => {
    event.preventDefault();
  };

  window.addEventListener('contextmenu', disableContextMenu);

  return () => {
    window.removeEventListener('contextmenu', disableContextMenu);
  };
}, []); */
  useEffect(() => {
 
    var logedIn = document.cookie.includes("logedIn");
    if(logedIn){
       login()
    }else{
    let token = localStorage.getItem("token");
    if(token){
     handleExit("")
    }else{
      login()
    }
    }
  }, [ ])


  return (
    <> 
   
    {
      localStorage.getItem("token")?
      <>
      <ToastShow /> 
      {loading ?
      currentProfileOption ?
      <DashboardLayout  >
        {!window.location.href.includes("e-commerce") &&
         <div className="breadCrumbBox">  <BreadCrumb pathSnippets={PATH_SNIPPETS} /></div>         
        }
         {renderRoutes(ROUTES as any ,layoutContext.currentUserPosition.PositionId )}
      </DashboardLayout>  
        : 
        <div className="d-flex justify-content-center h-100 w-100 flex-row userSelectNone ">
          <div className="d-flex justify-content-center flex-column h-50 w-25"  style={{marginTop:"20%"}}>
            <div className="d-flex justify-content-center w-100 fs-1">&#9940;</div>
            <div className="d-flex justify-content-center w-100 my-1">{trans("Msg_ProfileNotRetrieved")}</div>
            <div className="d-flex justify-content-center w-100 text-primary cursorPointer my-1"><a onClick={handleExit}> {trans("ClickExit")}</a></div>
            <div className="d-flex justify-content-center w-100 text-primary cursorPointer my-1"><Link to={"/message"} onClick={handleGoToChat}>{trans("ClickMessenger")}</Link></div>           
          </div>
        </div>    
        :<Loading/>

      }
      </>
    : <Loading/>
    } 
    </>
  );
}

export default App;





