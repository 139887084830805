
import { DataGrid } from "components/DataGrid/DataGrid";
import { DynamicComponentHOC } from "components/DynamicComponentHOC/DynamicComponentHOC";
import { Footerbar } from "components/Footerbar/Footerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { TabBar } from "components/Tabbar/Tabbar";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment";
import { useContext, useEffect, useState } from "react";
import { DynamicForm } from "types/types";
import { DYNAMICFORMS } from "utils/dynamicForms";
import { ENDPOINTS } from "utils/endpoints";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { getPersonnelCartable, getViewForm } from "./api";
import { JobAndReference } from "./components/JobAndReference/JobAndReference";



export const PersonnelCartable = () => {
    let footerData;
    const [loading, setLoading] = useState(false);
    const [pages, setPages] = useState<number>(1);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [mode, setMode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const [processFilter, setProcessFilter] = useState<any>(0);
    const layoutContext = useContext(LayoutContext);
    const [search, setsearch] = useState(null);
    const [selectedStartingProcessId, setSelectedStartingProcessId] = useState<string | any>(null);
    let [dynamicForm, setDynamicForm] = useState<DynamicForm | any>("GeneralRequestForm");
    const [showDynamicForm, setShowDynamicForm] = useState<boolean>(false);

    const [showAttachment, setShowAttachment] = useState(false);
    const [ShowDynamicFormLoading, setShowDynamicFormLoading] = useState(false);
    const [userChanges, setUserChanges] = useState({});


    const [jobId, setJobId] = useState<string | any>(null);
    const [PersonnelObj, setPersonnelObj] = useState<string | any>(null);
    const [showHistory, setShowHistory] = useState(false);
    const [filterAndSort, setFilterAndSort] = useState<any>(null);
    const [curentTab, setCurentTab] = useState<any>("request")

    let customFuction: any = [
        [(e) => { setJobId(e.Id); setShowHistory(true) }, "clipboard", "History"],
    ]
    const processViewCallback = (id: string) => {
        setMode("view");
        setSelectedStartingProcessId(id);
        getFormViewAsync(id);
    };

    async function getFormViewAsync(id: string) {
        setShowDynamicForm(true);
        setShowDynamicFormLoading(false)
        try {
            setMode("create");
            const res = await getViewForm(id, layoutContext.currentUserPosition.PositionId);
            if (res.Data)
                setDynamicForm(res.Data);
            else
                setDynamicForm(undefined);
            if (dynamicForm) {
              
            }
            else { setShowDynamicForm(false) }

        } catch (error) {
            setDynamicForm(undefined);
            setShowDynamicForm(false)

        }
        setShowDynamicFormLoading(true)

    }

    const tabChangeHandle = (tab) => {
        setCurentTab(tab)
      } 
    const searchHandler = (str) => {
        setPageNum(1);
        setSelectedObj(null)
        if (str === "") {
            setsearch(null)
            getPersonnelCartablesAsync(1 ,null)
        } else {
            setsearch(str)
            getPersonnelCartablesAsync(1 ,str)
        }
    }
    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("PersonnelCartable", e.currentTarget.value)
        getPersonnelCartablesAsync(1);
        // getDataAsync();
    };
    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else setPageNum(+value);getPersonnelCartablesAsync(+value);
        } else {
        }
    };
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getPersonnelCartablesAsync(pageNum + 1);
          }
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum(pageNum - 1);
            getPersonnelCartablesAsync(pageNum - 1);
          }
    };
 
    const selectedIdCallback = (obj, index) => {
        setSelectedObj(obj);
    }
    /* #endregion */
    const handleViewRow = () => {
        setMode("view");
        setShowDynamicForm(true)
    }
    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel": downloadCSV(response?.Data ,6 ,trans("PersonnelCartable")); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create":  break;
            case "view": if (selectedObj?.Id) { processViewCallback(selectedObj?.ProcessId);} else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "history": if (selectedObj?.Id){ setJobId(selectedObj.Id); setShowHistory(true);}; break;
            case "help": alert("call api help"); break;
            case "attach": if (selectedObj.Id) {
                setMode("edit")
                setShowAttachment(true)
            } else {
                Toast(trans("Msg_SelectRow") , "warning")
            } break;
        }
    };
    /* #endregion */

    async function getPersonnelCartablesAsync(PageNum = pageNum ,searchSTR = search) {
        setLoading(false);
        setResponse(null);
        let pageSizeChangedByUser = getPageSize("PersonnelCartable");
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getPersonnelCartable(PageNum, pageSizeChangedByUser , searchSTR,  filterAndSort.OrderFormat?.join(","),  filterAndSort.Filterlist ,processFilter ,PersonnelObj.Id)
            if (res.Data.Data.length > 0) {
                setResponse(res.Data)
                setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            }  
        } catch (error) {
        }
        setLoading(true)
    }


    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);


    useEffect(() => {
        setSelectedObj(null)
        if(PersonnelObj){
           if(PersonnelObj?.Id){
               getPersonnelCartablesAsync();
           }else{
                setLoading(true)
           }
        }
    }, [forceUpdate,  processFilter,filterAndSort ,PersonnelObj]);
    
    useEffect(() => {
        setResponse(null)
        let settings = getGridSetting("PersonnelCartable" )
        setFilterAndSort({Filterlist:settings[0],OrderFormat:settings[1] })
      }, []);

      let tabContent = [

        { title: "request" ,body: (<>
                    <DynamicComponentHOC  onHide={() => {localStorage.setItem("ShowNewReferene", "null"); setShowDynamicForm(false); sessionStorage.setItem("changingforms", "false")  ;setLoading(false)  }} componentName={DYNAMICFORMS[dynamicForm.Name] ?null:dynamicForm.Name} component={DYNAMICFORMS[dynamicForm.Name] ?DYNAMICFORMS[dynamicForm.Name].component:null}
                        reload={()=>{}} Name={dynamicForm.Name} modeParams={"view"} componentParams={dynamicForm.Parameters} processId={selectedStartingProcessId}
                        referenceId={selectedObj?.Id} jobId={selectedObj?.JobId} handleCancelForm={() => {localStorage.setItem("ShowNewReferene", "null");setShowDynamicForm(false)}}  />
        </>), },
        { title: "referenceandactionshistory" ,body: ( <JobAndReference inTab={curentTab}rowObject={selectedObj} referenceId={selectedObj?.Id}/>   ), },
        ];


    return (
        <>
            <Toolbar PersonnelCartable={true} refresh={response!=null} handleChangePersonnelCartable={setPersonnelObj}  history={response!=null} info={false} attach={true}    view={true} handleSearch={searchHandler}    id={selectedObj?.Id} emptyList={response?.TotalRecords ==0||response==null}  search={PersonnelObj!=null} handleClickToolbar={clickToolbarCallback} />
            <div  style={{ overflowY: "auto", 
                height: "78vh",
                 scrollbarWidth: "thin"}} className="col-lg-12 mt-0">
                {loading ?
                    (response?.Data?.length > 0 ?
                        <div className="card">
                            <div className="card-body p-0">
                                <>
                                    <DataGrid 
                                        customFuction={customFuction}
                                        DoubleClick={(obj)=>{setSelectedObj(obj) ;clickToolbarCallback("view")}}
                                        View={true}
                                        ViewParametr={"NotSeen"}
                                        handlefiltering={(str:any) => {str.Filterlist != undefined  ? setFilterAndSort(str) : setFilterAndSort({...filterAndSort, Filterlist:str})}}
                                        filterStr={filterAndSort.Filterlist}
                                        orderFormatList={filterAndSort.OrderFormat}
                                        orderFormat={(order)=>{setFilterAndSort({...filterAndSort, OrderFormat:order})  }}
                                        parentName={"PersonnelCartable"}
                                        theadData={Object.keys(response.Data[0])}
                                        tbodyData={response.Data}
                                        minCellWidth={80}
                                        handleAttachment={(e)=>{setSelectedObj({Id:e}) ;setMode("edit"); setShowAttachment(true)}}
                                        selectedRowObjectCallback={selectedIdCallback}
                                        rowSelected={selectedObj}
                                        startIndex={12}
                                        handleView={handleViewRow}
                                        totalRecords={response.TotalRecords}
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        HandleNextPage={handleNextPage}
                                        HandlePrevPage={handlePrevPage}
                                        handlechangePageSize={handleChangePageSize}
                                        first={() => { getPersonnelCartablesAsync(pages); setPageNum(pages) }}
                                        end={() => { getPersonnelCartablesAsync(1); setPageNum(1) }}
                                        handleGoPage={handleGoPage}
                                    />
                                    {footerData && <Footerbar footerContents={footerData} hasBorder={false} />}
                                </>
                            </div>
                        </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    )
                    : <Loading />
                }
            </div>
             {showAttachment &&
                    <ModalForm mode={mode} title={("Attachment")} onHide={() => { setShowAttachment(false) }} show={showAttachment}>
                       <Attachment showInGrid={true} params ={{"selectedPositionId" :PersonnelObj.Id }} onHide={() => { setShowAttachment(false); setForceUpdate(!forceUpdate) }} mood={"view"} endpoint={ENDPOINTS.cartable.PersonnelCartable.attachment} ownerId={selectedObj?.Id} />

                    </ModalForm>
                }

                {showDynamicForm &&
                    <ModalForm mode={mode} title={ShowDynamicFormLoading ?(dynamicForm.Name) :""} onHide={() => { setShowDynamicForm(false); }} show={showDynamicForm}>                       
                        {ShowDynamicFormLoading ? 
                            <>
                            <TabBar  tabChange={tabChangeHandle} content={tabContent} curentTab={curentTab} defaultTab={"request"} />
                             </>
                          : <Loading/>
                        }
                    </ModalForm>  
                }
                {showHistory &&
                    <ModalForm mode={mode} title={("ReferenceHistory")} onHide={() => {setJobId(null); setShowHistory(false) }} show={showHistory}>
                         <JobAndReference
                            rowObject={selectedObj}
                            referenceId={selectedObj.Id}
                        />
                    </ModalForm>
                }


        </>

    );
}


