import { Button } from "components/Button/Button";
import DatePicker from "components/DatePicker/DatePicker";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { getOrganizationEventsn, sendOrganizationEventsApi } from "./api";
import { Loading } from "components/Loading/loading";
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";


interface OrganizationEventsFormPropsType {
    selectedObj?;
    showForm: (e:boolean) => void;
    mode?;
    relod: () => void,

}
export const OrganizationEventsForm = ({ 
    selectedObj, 
    showForm,
    mode,
    relod

}: OrganizationEventsFormPropsType) => {
   
    const [resetForm, setResetForm] = useState(false);
    const [response, setResponse] = useState<any>();
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [loading, setLoading] = useState<any>(false);
    const [userChanges, setUserChanges] = useState({}); 

    async function sendNodeAsync(data ,method) {
        try{
          await sendOrganizationEventsApi(data , method);
          sessionStorage.setItem("changingforms" , "false");
          setUserChanges({});
          relod()
          showForm(false)
        }catch(err:any){

        }
        }

    async function getOrganizationEvents() {
      setLoading(false)
        try{
            if(selectedObj.Id){
              const res = await getOrganizationEventsn(selectedObj.Id);
              setResponse(res.Data)    
            }else{
              setResponse(null) 
            }    
        }catch(err:any){

          }
          setLoading(true)

        }

    const handlePersonContactFormSubmit = (event) => {
        event.preventDefault();
        if(mode=="edit"){
            let data={
              "Id": selectedObj.Id,     
              "EventTime": fromDate,
              "CalendarEventType":  +event.target.CalendarEventType.value,
              "Description": event.target.Description.value,            
            }
            sendNodeAsync(data,"put")
          }else{
            let data={    
              "EventTime": fromDate,
              "CalendarEventType":  +event.target.CalendarEventType.value,
              "Description": event.target.Description.value,     
          }
            sendNodeAsync(data,"post")
        }
    }

      

    function cancel() {
      if(sessionStorage.getItem("changingforms")+""=="true"){
          Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
              sessionStorage.setItem("changingforms" , "false");
              setUserChanges({});
              showForm(false)
          });
      }else{
          showForm(false)     
      }
  }

  
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

useEffect(() => {
    if(Object.values(userChanges).filter((item)=>item!=null).length>0){

        sessionStorage.setItem("changingforms" , "true")
          window.addEventListener('beforeunload', handleBeforeUnload);
          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
    }else{
        sessionStorage.setItem("changingforms" , "false")
        window.removeEventListener('beforeunload', handleBeforeUnload);

    }
}, [userChanges]);

     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);
    
     useEffect(() => {
        if(selectedObj && mode != "create"){
         getOrganizationEvents()
        }else{
          setLoading(true)
        }
      }, [selectedObj]);

      
    return (
        <div className="person-contact-form card">
            <div className="card-body">
              {loading ? 
                <div className="container-fluid">
                    <form onSubmit={handlePersonContactFormSubmit}>
                       <div className="row ">
                        <div className="col-lg-4 col-sm-5 mt-3 ">
                           <DatePicker
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              mode={mode}
                              resetForm={resetForm}
                              lable="Day"
                              setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                              val={ response?.EventTime ? response?.EventTime :null} 
                              withTime ={true} 
                            />                          
                        </div>
                       <div className="col-lg-4 col-sm-5 mt-3">
                        <EnumerationTypeSelect
                         Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                         required={true} 
                         mode={mode} 
                         label="CalendarEventType" 
                         name="CalendarEventType" 
                         value={response?.CalendarEventType} 
                         enumTypeSelect="CalendarEventType"
                         />
                       </div>
                     </div> 
                        <div className="col-lg-8 mt-1">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             required={true}
                             textboxType="textarea"
                             textboxName="Description"
                             labelText="Description"
                             maxLength={900}
                             defaultValue={response?.Description}
                             resetForm={resetForm}
                             mode={mode}
                            />
                        </div>
                        {  mode !="view" ?
                            <div className="col-lg-12 mt-1 btns_form">
                            <Button value="Save" btnType="submit" />
                            <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                            </div>
                            :
                            <div className="col-lg-12 mt-1 btns_form">
                            <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                         </div>
                        }                        
                    </form>
                </div>  
            : 
              <Loading/>
            }
            </div>
        </div>
    );
}