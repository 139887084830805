import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { getProcessListDictionary } from "../../api/api";
import { SelectBox } from "components/Select/Select";
import { InputLoad } from "components/InputLoading/inputLoading";
import { GUID_EMPTY } from "utils/constants";
interface ProcessDictionaryPropsType {
  label: string;
  hasBorder?: boolean;
  resetForm?: boolean;
  required?: boolean;
  value?: string;
  mode?: string;
  name: string;
  Change?: (e) => void;
  onChange?: (e, obj?) => void;
}

export const ProcessDictionary = ({
  label,
  hasBorder,
  value,
  mode,
  resetForm,
  required,
  name = "",
  Change,
  onChange,
}: ProcessDictionaryPropsType) => {
  const [ProcessListDictionary, setProcessListDictionary] = useState<any>();
  const [defaultval, setdefault] = useState(value);
  const [errcode, setErrcode] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getProcessListDictionaryAsync = async () => {
    setLoading(false);
    try {
      const res = await getProcessListDictionary();

      if (!!res.length) {
        setProcessListDictionary(res);
        if (value) {
          res.map((item) => {
            if (item.Id == value) {
              onChange && onChange(item);
            }
          });
        } else {
          onChange && onChange(res[0]);
        }
      }
    } catch (error: any) {
      setErrcode(error.response.status);
      setProcessListDictionary(null);
    }
    setLoading(true);
  };

  const handleOnChange = (e) => {
    ProcessListDictionary.map((item) => {
      if (item.Id == e.target.value) onChange && onChange(item);
      Change && Change(true);
    });

    if (
      e.target &&
      e.target.value != (value ? value : ProcessListDictionary[0].Id)
    ) {
      Change && Change({ [name]: e.target.value });
    } else {
      Change && Change({ [name]: null });
    }
  };

  useEffect(() => {
    getProcessListDictionaryAsync();
  }, []);

  useEffect(() => {
    if (value && ProcessListDictionary) {
      ProcessListDictionary?.map((item) => {
        if (item.Id == value) onChange && onChange(item);
      });
    }
  }, [value, ProcessListDictionary]);

  useEffect(() => {
    setdefault(value);
  }, [resetForm]);

  return (
    <>
      {ProcessListDictionary && loading ? (
        <>
          <SelectBox
            onChange={(e) => {
              handleOnChange(e);
            }}
            lable={label}
            selectType={"select"}
            options={ProcessListDictionary.map((item: any) => {
              return item.Description;
            })}
            val={ProcessListDictionary.map((item: any) => {
              return item.Id;
            })}
            name={name}
            value={value}
            resetForm={resetForm}
            mode={mode}
            required={required}
          />
          <div className="text-box__msg-wrapper"></div>
        </>
      ) : (
        <InputLoad
          msg={errcode}
          required={required}
          labelText={label}
          onClick={getProcessListDictionaryAsync}
        />
      )}
    </>
  );
};
