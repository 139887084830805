import { Textbox } from "components/Textbox/Textbox";
import { Button } from "components/Button/Button";
import { useEffect, useState } from "react";
import { Response } from "types/types";
import { Loading } from "components/Loading/loading";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import {
  postPeymentMethodApi,
  putPeymentMethodApi,
  getPeymentMethod,
} from "./api";
import { ListFormat } from "typescript";
interface PeymentMethodFormProps{
  reload:()=>void,
  mode:string,
  onHide:()=>any,
  selectedObj:any
}

export const PeymentMethodForm = ({ reload, mode, onHide, selectedObj }) => {
  const [resetForm, setResetForm] = useState(false);
  const [response, setResponse] = useState<Response | any>();
  const [userChanges, setUserChanges] = useState({});
  const [loading, setLoading] = useState<any>(true);
  const applicationId = JSON.parse(
    localStorage?.getItem("CurrentApplication") as string
  ).Id;

  const submit =async (e) => {
    e.preventDefault();
    try {
      
      if (mode === "create") {
    await    postPeymentMethodApi({
          IsDefault: e.target.IsDefault.checked,
          Title: e.target.PeymentMethodTitle.value,
          TitleEN: e.target.PeymentMethodTitleEN.value,
          SystemKey: e.target.PeymentMethodSystemKey.value,
          ActivityStatus: +e.target.ActivityStatus.value,
        }).then(() => {
          sessionStorage.setItem("changingforms", "false");
          setUserChanges({});
          reload();
          onHide();
        });
      } else {
   await     putPeymentMethodApi({
          Id: selectedObj.Id,
          IsDefault: e.target.IsDefault.checked,
          Title: e.target.PeymentMethodTitle.value,
          TitleEN: e.target.PeymentMethodTitleEN.value,
          SystemKey: e.target.PeymentMethodSystemKey.value,
          ActivityStatus: +e.target.ActivityStatus.value,
        }).then(() => {
          sessionStorage.setItem("changingforms", "false");
          setResponse(null);
          setUserChanges({});
          reload();
          onHide();
        });
      }
    } catch (error) {
      
    }
  };

  async function getPeymentMethodId() {
    setLoading(false);
    try {
      const data = await getPeymentMethod(selectedObj?.Id);
      setResponse(data.Data);
    } catch (error) {}
    setLoading(true);
  }

  useEffect(() => {
    if (selectedObj && mode !== "create") {
      getPeymentMethodId();
    } else {
      setResponse(null);
      setLoading(true);
    }
  }, [mode, selectedObj]);

  return (
    <div className="card mb-4">
      {loading ? (
        <form
          className="TypeSettingForm row card-body align-items-center "
          onSubmit={submit}
        >
          <div className="col-md-4 col-sm-12">
            <Textbox
              Change={(e) => {
                setUserChanges({ ...userChanges, ...e });
              }}
              required={true}
              textboxName={"PeymentMethodTitle"}
              textboxType="text"
              resetForm={resetForm}
              labelText={"Description"}
              mode={mode}
              defaultValue={response?.Title}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <Textbox
              Change={(e) => {
                setUserChanges({ ...userChanges, ...e });
              }}
              required={true}
              textboxName={"PeymentMethodTitleEN"}
              textboxType="text"
              resetForm={resetForm}
              labelText={"TitleEN"}
              mode={mode}
              defaultValue={response?.TitleEN}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <Textbox
              Change={(e) => {
                setUserChanges({ ...userChanges, ...e });
              }}
              required={true}
              textboxName={"PeymentMethodSystemKey"}
              textboxType="text"
              resetForm={resetForm}
              labelText={"SystemKey"}
              mode={mode}
              defaultValue={response?.SystemKey}
            />
          </div>
          {mode != "create" &&          
                   <div className="col-lg-4 mt-1">
                    <EnumerationTypeSelect
                     Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                     name="ActivityStatus"
                     mode={mode}
                     label="Status"
                     enumTypeSelect="ActivityStatus"
                     value={response?.ActivityStatus}
                    />
                   </div>                            
                   }
          <div className="col-md-4 col-sm-12">
            <div className="row align-items-center mt-1">
              <div className="col-6">
                <CheckableButton
                  Change={(e) => {
                    setUserChanges({ ...userChanges, ...e });
                  }}
                  defaultChecked={response?.IsDefault}
                  defaultValue={response?.IsDefault}
                  labelText={"IsDefault"}
                  inputName={"IsDefault"}
                  type="checkbox"
                  mode={mode}
                />
              </div>
            </div>
          </div>

          {mode != "view" ? (
            <div className="col-lg-12 mt-1 btns_form">
              <Button value="Save" btnType="submit" />
              <Button
                btnType="cancel"
                value="Cancel"
                onClick={() => {
                  onHide();
                }}
              />
            </div>
          ) : (
            <div className="col-lg-12 mt-1 btns_form">
              <Button
                btnType="primary"
                value="close"
                onClick={() => {
                  onHide();
                }}
              />
            </div>
          )}
        </form>
      ) : (
        <Loading />
      )}
    </div>
  );
};
