
import { useLocation } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import './style.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ItemCountSeter } from "../ItemCard/ItemCountSeter";
import { trans } from "utils/helpers";
import { ItemGroupLebal } from "../ItemGroupMenu/ItemGroupLabel";
import { ItemGroupLabelSlider } from "../ItemGroupMenu/ItemGroupLabelSlider";
import { ModalForm } from "components/ModalForm/ModalForm";
import { IncreaserDecreaser } from "./components/IncreaserDecreaser/IncreaserDecreaser";
import { Gallery } from "./components/Gallary/Gallery";
import { GroupingPrice } from "components/GroupingPrice/GroupingPrice";
import { Data } from "../data/data";
import { ItemBuyImg } from "../ItemBuyBtn/ItemBuyImg";
import { DataImg } from "../data/dataImg";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";

interface Form {
    Title: string,
    Status: number,
    reload:boolean,
}
export const ItemDetailPage2 = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const itemId = queryParams.get('item');

    const [imgSelected,setImgSelected] = useState<any>(null)
    const [propertiesKeys, setPropertiesKeys] = useState<any>({});
    const [propertiesValue,setPropertiesValue] = useState<any>({})
    const [activeIndex,setActiveIndex] = useState<any>(null)
    const [showIncreaseDecrease,setShowIncreaseDecrease] = useState<any>(false)
    const [showGallary,setShowGallary] = useState<any>(false)
    const [selectedIncreaseDecrease,setSelectedIncreaseDecrease] = useState<any>(null)
    const [checkBoxId,setCheckBox] = useState<any>(null)
    const [company,setCompany] = useState<number>(0)
    const [like,setLike] = useState<boolean>(false)
    const [itemNotif,setItemNotif] = useState<number>(5)
    const [dataLocal,setDataLocal] = useState<any>()
    const [basketData,setBasketData] = useState<any>()
    const [forceUpdate,setForceUpdate] = useState<boolean>(false)
    const detailActiveRef = useRef<any>(null)

    const layoutContext = useContext(LayoutContext);



    const previes = () =>{
        if(company > 0){
            setCompany(company-1)
        }else{
            setCompany(dataLocal.ItemPriceListDetails.length-1)
        }
    }

    const next = () =>{
        if(dataLocal.ItemPriceListDetails.length > company+1){
            setCompany(company+1)
        }else{
            setCompany(0)
        }
    }

    const deleteItem = (pValue) =>{

        let Basket:any =   localStorage.getItem("Basket")
        Basket = JSON.parse(Basket)

       Object.keys(basketData.order).map((selectItemId)=>{
        if(basketData.order[selectItemId].properties == pValue){
            if(itemId){
                delete Basket[itemId].order[selectItemId]
                localStorage.setItem("Basket",JSON.stringify(Basket))


                if(Object.values(basketData.order).length > 0){
                    let fristOrder:any
                    if(Object.values(basketData.order).length > 1){
                        fristOrder = Object.values(basketData.order)
                        funSetPropertiesKeys(basketData.properties,fristOrder[JSON.stringify(fristOrder[0].properties) == JSON.stringify(pValue) ? 1 : 0].properties)
                    }else{
                        setForceUpdate(!forceUpdate)
                    }
                }

            }
        }
        
    })
    }

    const funSetPropertiesKeys = (properties,pValue) =>{
        let obj
        Object.keys(pValue).map((properteName) =>{
            // properties[properteName]
            properties[properteName].map((q) => {
                if(q.value == pValue[properteName]){
                    obj = { ...obj ,[properteName] : q.Id}
                } 
            })
        })

        setPropertiesValue(pValue)
        setPropertiesKeys(obj)
    }

    useEffect(()=>{
        layoutContext.setBasket()
        if(basketData){
            Object.values(basketData.properties['color']).map((color:any) =>{
            if(color['value'] == propertiesValue['color']){
                setImgSelected(color)
            }
        })
        }
        // propertiesValue['color']
    },[propertiesKeys])

    useEffect(()=>{
       // let Basket:any =   localStorage.getItem("Basket")
       // Basket = JSON.parse(Basket)
       // if(Basket){
       //     setBasketData(Object.values(Basket).find((item:any)=> item.Id == itemId))
       // }
        detailActiveRef?.current?.scrollIntoView(false)
    },[layoutContext.basket])

    useEffect(()=>{
        if(basketData && Object.keys(propertiesKeys).length ==0){
            let item :any= Object.values(basketData.order)[0]
            if( item?.properties){
                funSetPropertiesKeys(basketData.properties,item?.properties)
            }
            
        }
    },[basketData])

    useEffect(()=>{

        let item:any = Data.find(item => item.Id == itemId)
        let defaultPropertyId:any = {}
        let defaultPropertyValue:any = {}
       setDataLocal(item)
         Object.keys(item.properties).map((propertyKey:any,index)=>{
            defaultPropertyId[propertyKey] = item.properties[propertyKey][0]?.Id
            defaultPropertyValue[propertyKey] = item.properties[propertyKey][0]?.value
        })
        setPropertiesKeys(defaultPropertyId)
        setPropertiesValue(defaultPropertyValue) 
    },[itemId,forceUpdate])
    /* #endregion */
    return (
        <>
        {
        showIncreaseDecrease &&(
        <ModalForm modalHeight={80} title={selectedIncreaseDecrease.title} onHide={() => { setShowIncreaseDecrease(false) }} show={showIncreaseDecrease}>
            <IncreaserDecreaser onHide={()=>{setShowIncreaseDecrease(false) }} object={selectedIncreaseDecrease} checkBoxHandle={setCheckBox}/>
        </ModalForm>
            )
        }

        {
        showGallary && itemId &&(
        <ModalForm modalHeight={80} title={dataLocal?.nameProductFa} onHide={() => {setShowGallary(false) }} show={showGallary}>
            <Gallery selectImg={imgSelected ? Object.keys(DataImg[itemId]).indexOf(imgSelected?.value) : 0} data={DataImg[itemId]} showGallery={true}/>
        </ModalForm>
        )
        }

        <div  className="itemDetail p-0" style={{userSelect:'none'}}>
            <div className="row h-100">
                <div className="col-lg-9 ps-0" >
                    <div className="glassBox h-100 p-2 ">
                        <div className="row h-100">
                            {/* moblieTitleBx */}
                                <div className="d-block d-md-none itemDetail__titleBx">
                                    <div className="itemDetail__titleBx__nameFa showDotsTowLine my-1" style={{userSelect:'text'}}>
                                        {dataLocal?.nameProductFa}
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="itemDetail__titleBx__ratingBx ms-2 ">
                                            <div><FontAwesomeIcon icon={["fas", "star"]} style={{color:"gold" ,marginLeft:'5px'}}/><span>{dataLocal?.rate}</span></div>
                                        </div>
                                        <div className="itemDetail__titleBx__nameEN text-muted showDots" style={{userSelect:'text'}}>
                                            {dataLocal?.nameProductEn}
                                        </div>
                                    </div>
                                </div>
                            <div className="col-md-5">
                            <div className="d-md-none m-auto itemDetail__mobileGallery">
                                {itemId &&
                                 <Gallery selectImg={imgSelected ? Object.keys(DataImg[itemId]).indexOf(imgSelected?.value) : 0} data={DataImg[itemId]} showGallery={false}/>
                                }
                                </div>
                                <div className="itemDetail__imgSelected d-md-flex d-none w-100">
                                    <ul className="d-flex flex-column align-items-center justify-content-center h-100 ps-1 itemDetail__imgSelected__svgFontAs">
                                        {false ?
                                        <li onClick={()=>setLike(!like)}><FontAwesomeIcon icon={["fas", "heart"]} className="text-customerPrimaryColor"/></li>
                                        :
                                        <li onClick={()=>setLike(!like)}><FontAwesomeIcon icon={["far", "heart"]}/></li>
                                        }
                                        {false ?
                                        <li className="position-relative"><div className="position-absolute d-flex align-items-center justify-content-center bg-customerPrimaryColor itemDetail__imgSelected__itemNotif"> {itemNotif > 99? '99+' :itemNotif}</div><FontAwesomeIcon icon={["far", "bell"]}/></li>
                                        :     
                                        <li><FontAwesomeIcon icon={["far", "bell"]}/></li>
                                        }
                                        <li className="mt-2"><FontAwesomeIcon icon={["fas", "share-nodes"]}/></li>
                                    </ul>
                                    <div className="itemDetail__imgSelected__imgBx w-100 h-100" onClick={()=>{setShowGallary(true)}}>
                                        {/* <img draggable='false' src={data[0].img} className="w-100 h-100"/> */}
                                         {itemId &&
                                        <ItemBuyImg  attchId={imgSelected ? DataImg[itemId][imgSelected?.value] : Object.values(DataImg[itemId])[0]} />
                                        } 

                                    </div>
                                </div>
                                <div className="itemDetail__gallery d-none d-md-block w-100">
                                    <ul className={`d-flex position-relative ${itemId && Object.values(DataImg[itemId]).length == 4 ? 'justify-content-between' : 'justify-content-start' } align-items-center w-100 h-100`}>
                                        {itemId&&DataImg[itemId] && Object.values(DataImg[itemId]).map((e:any,index) =>{
                                           if(Object.values(DataImg[itemId]).length > 1 ){

                                               if(index <= 3){
                                                   
                                                   if(Object.values(DataImg[itemId]).length > 4 && index == 3){
                                                       return(<li key={index+'itemDetail__gallery'} onClick={()=>{setShowGallary(true)}} className="d-flex align-items-center  justify-content-center blurClass"><FontAwesomeIcon icon={["fas", "ellipsis"]} style={{zIndex:2,position:'absolute',fontSize:'25px'}}></FontAwesomeIcon><img draggable='false' src={e}/></li>)
                                                    }else{
                                                        return(<li key={index+'itemDetail__gallery'} onClick={()=>{setShowGallary(true)}} className="d-flex align-items-center ms-2 justify-content-center"><img draggable='false' src={e}/></li>)
                                                    }
                                                }
                                            }else{
                                                <></>
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                                    {/* deskTopTitleBx */}
                            <div className="col-md-7 h-100 detailBx">

                                <div className="d-md-block d-none">
                                    <div className="itemDetail__titleBx__nameFa showDotsTowLine my-1" style={{userSelect:'text'}}>
                                        {dataLocal?.nameProductFa}
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <div className="itemDetail__titleBx__ratingBx ms-2 ">
                                            <div className="font-size-sm"><FontAwesomeIcon icon={["fas", "star"]} color='gold' style={{marginLeft:'5px'}}/><span>{dataLocal?.rate}</span></div>
                                        </div>
                                        <div className="itemDetail__titleBx__nameEN ps-1 showDots text-muted" style={{userSelect:'text'}}>
                                            {dataLocal?.nameProductEn}
                                        </div>
                                    </div>
                                </div>
                                <div className="itemDetail__fixPropertiesBx d-flex flex-column">
                                    {dataLocal?.properties && Object.keys(dataLocal?.properties).length > 0  &&
                                    <div className="itemDetail__fixPropertiesBx__propertiesItem glassBox my-2 p-2">
                                        {Object.keys(dataLocal?.properties).map((property,index)=>(
                                            <>
                                            {dataLocal?.properties[property].length > 0 && 
                                            <div key={index+'itemDetail__fixPropertiesBx__propertiesItem'} className="d-sm-flex align-items-center "> 
                                            <div className="font-size-lg fw-6 showDots" style={{minWidth:'80px',paddingLeft:"5px"}}>{trans(property)} : </div>
                                            <div style={{overflowX:'auto'}}>
                                            <ItemGroupLabelSlider children={
                                                dataLocal?.properties[property].map((e,i) =>(
                                                    <>
                                                    <ItemGroupLebal id={e.Id} key={i+'propertiesItemDetail'} shape={property == "color" ? 'circle' : 'Foursquare'} IsActive={Object.keys(propertiesKeys).length != 0 ?  propertiesKeys[property] == e.Id ? true : false : i == 0 && true}  onClick={(event)=>{event.stopPropagation();setPropertiesKeys({...propertiesKeys ,[property]:e.Id });setPropertiesValue({...propertiesValue , [property]:e.value});if(property == "color"){setImgSelected(e)} }} title={e.value}/>
                                                    </>
                                                    ))
                                                    
                                                }/> 
                                                </div>
                                            </div>
                                            }
                                            </>
                                        ))}
                                    </div>
                                    }     
                                     {
                                        dataLocal?.ItemPriceListDetails?.map((e,index) =>(
                                        company == index &&
                                        <div key={index+'company'} className="glassBox mt-2 py-1 px-2 itemDetail__fixPropertiesBx__company">
                                            <div className="fw-semibold font-size-lg">{e.companyName}</div>
                                            <div className="d-flex justify-content-between" style={{height:'80%'}}>
                                                {dataLocal?.ItemPriceListDetails.length > 1 && <div className="ms-2 d-flex align-items-center" onClick={next}><FontAwesomeIcon icon={["fas", "chevron-right"]} style={{cursor:'pointer'}}></FontAwesomeIcon></div>}                    
                                                <div className="itemDetail__fixPropertiesBx__priceBx w-100 position-relative">
                                                    <div className="row">
                                                            {   
                                                                e.PriceDetails.map((p,i) => (
                                                               <>                                             
                                                                <div key={i+'companyItems'} ref={activeIndex == i ?detailActiveRef  :null} className={`col-12  ${e?.PriceDetails.length != 1 ? 'col-md-6' : ''} mb-2`}>
                                                                <div className={`border glassBox position-relative p-1 ${activeIndex == i ? 'itemDetail__fixPropertiesBx__company__itemBoxActive' : ''}`}>
                                                                    <div className="mt-1 mx-2 font-size-md">
                                                                        {e?.CoverPrice != e?.UnitPrice &&
                                                                                    
                                                                            <div className="d-flex text-muted">
                                                                                <div> {trans('packingprice')}:</div>
                                                                                <div className="text-muted me-1"><GroupingPrice value={e?.CoverPrice}/></div>
                                                                            </div>
                                                                        }
                                                                        <div className="text mt-1 d-flex">
                                                                            {p?.max != null ? 
                                                                            <>
                                                                                <div style={{fontWeight:'500'}}>{trans('from')} {p.min} {trans('to')} {p.max} {dataLocal?.Unit}</div>
                                                                                <span className={`fw-bolder ${p?.off != null && 'text-muted text-decoration-line-through'} me-2`} ><GroupingPrice value={e?.UnitPrice}/></span>
                                                                            </>
                                                                            :
                                                                            p?.min != null ? 
                                                                            <>
                                                                                <div className="text"><span>{trans('greaterthan')} {p?.min}  {dataLocal?.Unit}</span></div>
                                                                                <span className={`fw-bolder ${p.off != null && 'text-muted text-decoration-line-through'} me-2`}><GroupingPrice value={e.UnitPrice}/></span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                            <div></div>
                                                                            <div className="text"><span> قیمت {dataLocal.Unit}</span></div>
                                                                            <span className={`fw-bolder ${p.off != null && 'text-muted text-decoration-line-through'} me-2`}><GroupingPrice value={e.UnitPrice}/></span>
                                                                            </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex mt-1 mx-1">
                                                                        {p.off &&
                                                                        <>
                                                                        {/* translate */}
                                                                            <div> با تخفیف :</div>
                                                                            <div className="text-customerPrimaryColor me-1" >{p.off}<span className="font-size-xs">%</span></div>
                                                                        </>
                                                                        }
                                                                        {p.discountPrice &&
                                                                            <span className=" fw-bolder me-1"><GroupingPrice value={p.discountPrice}/></span>
                                                                        }
                                                                    </div>
                                                                    <div className={`${p.off != null ? 'mt-2' : 'mt-4' } mx-2 d-flex justify-content-end `}>
                                                                        <div className ='itemDetail__fixPropertiesBx__priceBx__btnBx mx-2 d-flex justify-content-end'>
                                                                            <ItemCountSeter ItemData={dataLocal} properties={propertiesValue} propertiesId={itemId + (Object.keys(propertiesKeys).length == 0 ? '' : Object.values(propertiesKeys).join(""))} min={p?.min} max={p?.max} stock={+dataLocal.Stock} defultValue={p?.min} priceIndex={i} activeBtn={activeIndex} setActiveBtn={setActiveIndex} pluseInRight={false} positionRelative={true}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            
                                                                </>
                                                                ))
                                                            }
                                                        
                                                    </div> 
                                                </div>
                                                {dataLocal?.ItemPriceListDetails.length > 1 && <div className="me-2 d-flex align-items-center" onClick={previes}><FontAwesomeIcon icon={["fas", "chevron-left"]} style={{cursor:'pointer'}}></FontAwesomeIcon></div>}
                                            </div>
                                        </div>

                                        ))
                                     }       
                                    {true &&    
                                        <div className="itemDetail__fixPropertiesBx__increaserDecreaser mt-3">
                                            <div className="row d-flex align-items-center justify-content-between px-2">
                                            {dataLocal?.increaserDecreaser.map((e,i) => (
                                                <div key={i+'itemDetail__fixPropertiesBx__increaserDecreaser'} className={`${dataLocal?.increaserDecreaser?.length > 3 ? 'col-md-6 px-1':'col-md-12'} col-12`}>

                                                    <div className="d-flex align-items-center justify-content-between glassBox my-1 p-2" style={{height:'50px'}}>
                                                    <div className="d-flex" style={{width:'80%'}}>
                                                        <div className="border-start d-flex align-items-center justify-content-center ps-2 ms-2">
                                                            {/* {e.Id != checkBoxId ? */}
                                                                <input type="checkbox"/>
                                                             {/* :
                                                                 <input type="checkbox" checked/>
                                                             } */}
                                                        </div>
                                                        <div style={{maxWidth:'85%',minWidth:'85%'}}>
                                                            <div className="showDots font-size-md"  style={{width:'90%'}}>{e.title}</div>
                                                            {/* <div><GroupingPrice value={e.price}/></div> */}
                                                        </div>
                                                    </div>
                                                    <div className="font-size-md"><a className="text-customerPrimaryColor fw-bolder" style={{cursor:"pointer"}} onClick={()=>{setShowIncreaseDecrease(true);setSelectedIncreaseDecrease(e)}}>{trans('details')}</a></div>
                                                </div>
                                            </div>
                                            ))}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
                <div className="col-lg-3">
                    <div className="itemDetail__propertyBx d-flex flex-column h-100 glassBox">
                        <div className="order-1 order-md-0">
                            <div className="font-size-lg fw-6" style={{padding:'15px'}}>{trans("itemproperties")} :</div>
                            <ul style={{padding:'15px',paddingTop:'0'}}>
                                {dataLocal?.FixedFeature.map((p,i) => (
                                    <li key={i+'fixProperty'} className="font-size-md  me-3 my-2">
                                        <span className="text-muted">{p.title}</span> : <span>{p.discription}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                            {
                            basketData && basketData.order && Object.values(basketData?.order).length > 0 &&
                            <div className="order-0 order-md-1">
                                <div className="font-size-lg fw-6" style={{paddingRight:'15px'}}>تنوع های انتخاب شده در سبد خرید :</div>
                                <ul id="listItem">
                                    {
                                        basketData &&
                                        Object.values(basketData.order).map((item:any,index) =>(
                                        
                                        <li className={`border glassBox my-1 mx-2 p-2 ${JSON.stringify(item.properties) == JSON.stringify(propertiesValue) ? ' active ' :''}`} onClick={()=>{funSetPropertiesKeys(basketData.properties,item.properties)}}>
                                            <div className="d-flex align-items-start justify-content-between">
                                                <div>
                                                    {
                                                    Object.keys(item.properties).map((p)=>(
                                                        <div className="d-flex align-items-center">
                                                            <span>{p} : </span>
                                                            {p != 'color' ?
                                                            <span>{item.properties[p]}</span>
                                                            :
                                                            <div style={{backgroundColor:item.properties[p],width:'10px',height:'10px',borderRadius:'0.25rem'}}></div>
                                                            }
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                                <div onClick={(e)=>{e.stopPropagation();deleteItem(item.properties)}}>
                                                    <FontAwesomeIcon  icon={["fas", "trash-can"]}/>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <span>
                                                        <div>قیمت کل  :</div>
                                                        <div className="d-flex">
                                                            <span className="fw-bolder ms-1"><GroupingPrice value={basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].discountPrice ? +basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].discountPrice * +item.count : +basketData.ItemPriceListDetails[0].UnitPrice * +item.count}/></span>
                                                            {
                                                            basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].off && (<span className="ms-1 font-size-xs text-customerPrimaryColor">(<span>%</span><span>{basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].off}</span>)</span>)
                                                            }
                                                        </div>
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <div>تعداد  :</div>
                                                        <div className="ms-1">{item.count}</div>   
                                                    </span>
                                                </div>
                                                <div>
                                                    <div>فی  :</div>
                                                    <div><GroupingPrice value={basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].discountPrice ? basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].discountPrice : basketData.ItemPriceListDetails[0].UnitPrice}/></div>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                    }
                                </ul>
                            </div>
                            }
                    </div>
                </div>

            </div>
        </div>  

        </>
    );

}
