
import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS } from "utils/endpoints";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";



export const  getGroupPositionList =( async ( SearchString) =>  await http({
    url: ENDPOINTS.BaseInformation.groupPosition.dictionary,
    method: "get",
    params:{
        SearchString : SearchString
    }

    

})
.then((respons) => (respons.data))
)
export const  getPositionList =( async (SearchString ) =>  await http({
    url: ENDPOINTS.BaseInformation.position.browse,
    method: "get",
    params : {
        searchString : SearchString
    }

    

})
.then((respons) => (respons.data))
)


export const  getWarehouseAccess =( async (id) =>  await http({
     baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.access,
    method: "get",
    params:{
         WarehouseId : id
    }
})
.then((respons) => (respons.data))
)
export const  sendWarehouseAccess =( async (data  ,method ,warehouseId) =>  await http({
     baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.access,
    method: 'put',
    params:{
        warehouseId:warehouseId
    },
    data:data,
})
.then((respons) => (respons.data))
)

export const  DeleteWarehouse =( async (id) =>  await http({
     baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.browse,
    method: "delete",
    params:{
        Id : id
    }
})
.then((respons) => (respons.data))
)

export const  DeleteWarehouseDetail =( async (id) =>  await http({
     baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseDetail.browse,
    method: "delete",
    params:{
        Id : id
    }
})
.then((respons) => (respons.data))
)





// export const  getWarehouse =( async ( WarehouseId ) =>  await http({
//      baseURL:INVENTORYANDSALES_URL,
//     url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.access,
//     method: "get",
//     params:{
//          WarehouseId: WarehouseId 
//     }
   
  
// })
// .then((respons) => (respons.data))
// )
