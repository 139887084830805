import { Button } from "components/Button/Button";
import { Headerbar } from "components/Headerbar/Headerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { ModalStepper } from "components/ModalStepper/ModalStepper";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { DynamicForm } from "types/types";
import { ENDPOINTS } from "utils/endpoints";
import { GetReportWithJsonData, trans } from "utils/helpers";
import { ReferReverse ,acceptReference,getDataForPrint } from "../../api/api";
import { ReferenceStepper } from "../ReferenceStepper/ReferenceStepper";
import { ReverseHistory } from "../ReverseHistory/ReverseHistory";
import { TerminatedForm } from "../Terminated/Terminated";
import { getDynamicForm } from "./api/api";
import { Actions } from "./components/Actions/Actions";
import { Attachment } from "./components/Attachment/Attachment";
import { ReferencesActions } from "./components/RefrenceAndActionsForm/RefrenceAndActions";
import "./style.scss";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { ArchiveForm } from "../ArchiveForm/ArchiveForm";
import { AssignTypes } from "../../types";
import { JobActionList } from "./components/JobActionList/JobActionList";
import { TabBar } from "components/Tabbar/Tabbar";
import { AcceptSelfService } from "../AcceptSelfService/AcceptSelfService";
import { ReferenceTree } from "./components/ReferencesTree/ReferencesTree";
import { ModalFormList } from "components/ModalStepper/ModalFormList";


export const ActionList = ({ haveReverse , referenceId, onHide, handleShowReferenceStepper , styleCondition ,jobId,rowObject ,handleFinishedReference }: any) => {
    let headerData;
    const [dynamicForm, setDynamicForm] = useState<DynamicForm | any>("GeneralRequestForm");
    let [showDynamicForm, setShowDynamicForm] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [selectedId, setSelectedId] = useState<string | any>(null);
    const [showReferenceAndActions, setShowReferenceAndActions] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [mode, setmode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [showattachment, setshowAttachment] = useState(false);
    const [showReference, setShowReference] = useState(false);
    const [currentModalWidth, setCurrentModalWidth] = useState(70);
    const [ShowReferDescriptionForm, setShowReferDescriptionForm] = useState(false);
    const [showTerminatedForm, setShowTerminatedForm] = useState<boolean>(false);
    const [showAccept, setShowAccept] = useState<any>(true);
    const [showArchiveForm, setShowArchiveForm] = useState<any>(0);
    const [curentTab, setCurentTab] = useState<any>("request")


    const tabChangeHandle = (tab) => {
        setCurentTab(tab)
      } 

    const getDataForPrintAsync =async (id) => {

        if(jobId){
            if(id.includes("Design")){
                const res = await getDataForPrint(jobId)
                GetReportWithJsonData( id.replace("Design" , ""), res.Data ,2)
            }else if(id.includes("Default")){
                const res = await getDataForPrint(jobId)
                GetReportWithJsonData( id.replace("Default" , ""), res.Data ,2)
                
            }else{
                const res = await getDataForPrint(jobId)
               GetReportWithJsonData( id, res.Data ,1 )
                
            }
            
        }
        try {

        } catch (error) {
            
        }
    };

    const handleAttachment = () => {
        setSelectedId(referenceId)
        setmode("allattach")
        setshowAttachment(true)
    }
    /* #endregion */
    const selectedArchiveCallback = () => {
        setShowArchiveForm(1)
    };
    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            // case "excel": alert("excel "); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "archive": if (referenceId) { selectedArchiveCallback() } break;
            case "report": setShowActions(true); break;
            case "reverse":  setShowReferDescriptionForm(true) ; break;
            case "help": alert("call api help"); break;
            case "attach": handleAttachment(); break;
            case "history": if (referenceId) setShowReferenceAndActions(true); break;
            case "finish": if (referenceId) { setShowTerminatedForm(true) } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "reference":
                if (referenceId) { handleShowReferenceStepper(referenceId); onHide() }
                break;
        }
    };
    /* #endregion */

    async function getDynamicFormAsync(id: string) {
        setLoading(false)
        try {
        const res = await getDynamicForm(id);
        if (res.Data){

            let data :any= []
            res.Data.map((item, index)=>{
                if(item.Name != "SignatureForm"){
                    if(res.Data?.[index+1] &&  res.Data?.[index+1]?.Name =="SignatureForm" ){
                        data.push({...item , 
                            forms :[res.Data[index+1]]
                        })
                    }else{
                        data.push(item)
                    }
                }
    
            })
            setDynamicForm(data);
        } else
            setDynamicForm(undefined);
        if (dynamicForm) {

            setShowDynamicForm(true);
        }
        else { setShowDynamicForm(false) }            
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }
    async function ReferReverseAsync(e) {
        e.preventDefault()
        let ReverseDescription = e.target.ReverseDescription.value
        if (ReverseDescription.length > 0) {
            try {
                await ReferReverse(referenceId, ReverseDescription,e?.target?.sendMessageToPerformer.checked);
                setShowReferDescriptionForm(false)
                setForceUpdate(!forceUpdate)
                onHide()
            } catch (error) {
            }
            setShowReferDescriptionForm(false)
        }
    }
    async function acceptReferenceAsync(referenceId) {
        try {
            await acceptReference(referenceId);
            setShowAccept(false)
        } catch (error) {

        }
        

    }
    useEffect(() => {       
        getDynamicFormAsync(referenceId);
    }, [ forceUpdate ]);



    let tabContent = [

        { title: "request" ,body: (<>
            <div className="cartable-action">
                <Toolbar archive={true} excel={false}  handlePrint={getDataForPrintAsync} objectTag={dynamicForm[0]?.Name } reverse={true} refresh={false} view={false} report={true} finish={true} id={selectedId} history={false}  reference={true}  search={false} handleClickToolbar={clickToolbarCallback} attach={true} />

                {haveReverse  ?
                 <ReverseHistory  referenceId={referenceId} />
                  : <></>
                }
                {((rowObject?.AssignType == AssignTypes.SELF_SERVICE&&rowObject?.AcceptTime==null) && showAccept)&&
                        <AcceptSelfService  handleAcceptReferenceAsync={()=>{acceptReferenceAsync(rowObject?.Id)}} onHide={(e)=>{e.stopPropagation();  setShowAccept(false)}}/>
                 }  
                {headerData && <Headerbar headerContents={headerData} hasBorder={true} startIndex={2} />}
                {loading ? 
                <>
                {showDynamicForm &&
                    (dynamicForm ? (
                        <>
                        <ModalStepper
                            sourceId={rowObject?.SourceId}
                            RequesterPositionId={rowObject?.RequesterPositionId}
                            SourceData={rowObject?.SourceData}
                            closeForm={()=>{onHide()}}
                            JobNo={rowObject?.JobNo}
                            disable={((rowObject?.AssignType == AssignTypes.SELF_SERVICE&&rowObject?.AcceptTime==null) && showAccept)}
                            inDynamicForms={true}
                            jobId={jobId}
                            reload={setForceUpdate}
                            onHide={() => { setShowDynamicForm(false); setForceUpdate(true);setShowDynamicForm(true); }}
                            Id={rowObject?.Id}
                            taskTitle={rowObject?.TaskTitle}
                            formList={dynamicForm}
                            styleCondition={styleCondition}/>
                            <JobActionList referenceId={referenceId} showActions={showActions} />
                            </>
                    ) : (<Message message={"NoFormForPreview"} type={messageType} />))
                }                
                </>
                  :<Loading/>      
                }

            </div>

        </>), },
        { title: "referenceandactionshistory" ,body: ( <ReferencesActions rowObject={rowObject} inTab={curentTab}  referenceId={referenceId}/>   ), },
        { title: "referencestree" ,body: ( <ReferenceTree intab={curentTab=="referencestree"} referenceId={referenceId}/>   ), },
        ];
    return (
        <>

                {showActions &&
                    <ModalForm mode={mode} title="Actions" onHide={() => setShowActions(false)} show={showActions}>
                        <Actions
                            rowObject={rowObject}
                            handleShowReferenceStepper={(e)=>{handleShowReferenceStepper(e);onHide()}}
                            onHide={() => setShowActions(false)}
                            mode={mode}
                            referenceId={referenceId}
                        />
                    </ModalForm>
                }
                {showTerminatedForm &&
                    <ModalForm mode={mode} title={("Terminate")} onHide={() => { setShowTerminatedForm(false) }} show={showTerminatedForm} >
                        <TerminatedForm rowObject={rowObject} submit={handleFinishedReference}   onHide={() => setShowTerminatedForm(false)} />
                    </ModalForm>
                }   
                 {showArchiveForm>0 &&
                    <ModalForm mode={mode} title={("ArchiveForm")} onHide={() => setShowArchiveForm(0)} show={showArchiveForm>0}>
                        <ArchiveForm jobId={rowObject?.JobId}Id={rowObject?.Id}  type={showArchiveForm} onHide={() => setShowArchiveForm(0)} />
                    </ModalForm>
                }
                {showReferenceAndActions &&
                    <ModalForm mode={mode} title="ReferenceAndActionsHistory" onHide={() => setShowReferenceAndActions(false)} show={showReferenceAndActions}>
                        <ReferencesActions
                            referenceId={referenceId}
                        />
                    </ModalForm>
                }
                  {showReference &&
                   <ModalForm modalHeight={74} mode={mode} title={"ReferenceStepper"} onHide={() => { setShowReference(false) }} show={showReference} getModalWidth={setCurrentModalWidth}>
                        <ReferenceStepper   onHide={() => { setShowReference(false) }} referenceId={referenceId} styleCondition={currentModalWidth} />
                   </ModalForm>
                }
                    {ShowReferDescriptionForm &&
                    <ModalForm mode={mode} title="Reverse" onHide={() => setShowReferDescriptionForm(false)} show={ShowReferDescriptionForm}>
                        <form onSubmit={ReferReverseAsync}>
                            <div className="row mb-1">
                                <div className="col-12">
                                    <Textbox
                                        textboxType="textarea"
                                        textboxName="ReverseDescription"
                                        labelText="ReverseDescription"
                                        required={true}
                                        mode={mode}
                                    />
                                </div>
                                <div className="col-4 my-1 my-lg-0 col-lg-4 pt-4">
                                <CheckableButton
                                       defaultValue={false}
                                       labelText={"sendMessageToPerformer"}
                                       inputName={"sendMessageToPerformer"}
                                       type="checkbox"
                                       mode={"edit"}
                                    />    
                            </div>
                            </div>
                            <Button value="send" btnType="submit" />
                            <Button
                                btnType="cancel"
                                value="Cancel"
                                onClick={() => { Toast(trans("modalclosemessage"), "warning", "alert", () => setShowReferDescriptionForm(false)) }}
                            />
                        </form>
                    </ModalForm>
                }
                {showattachment &&
                    <ModalForm mode={mode} title={("Attachment")} onHide={() => { setshowAttachment(false) }} show={showattachment}>
                        <Attachment haveVersion={true}showInGrid={true} onHide={() => { setshowAttachment(false); }} mood={mode}  ownerId={ referenceId} endpoint={ENDPOINTS.cartable.reference.attachments} getListEndpoint={ ENDPOINTS.cartable.attachment }getListOwnerId={referenceId}    />
                    </ModalForm>
                }
            <div className="mb-1">
                <Headerbar headerContents={Object.fromEntries(Object.entries(rowObject).filter(([key, value]) => (key.includes("Refer")||key.includes("Task"))))} hasBorder={true}  />       
            </div>
            <TabBar  tabChange={tabChangeHandle} content={tabContent} curentTab={curentTab} defaultTab={"request"} />

        </>

    );
}

