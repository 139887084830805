import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


let CurrentApplication:any =localStorage.getItem("CurrentApplication")

export const getStartProcess = ( currentApplication) => http({
    url: ENDPOINTS.bpmnApi.process.getStarting,
    method: 'get',
    params:{
        ApplicationId :  currentApplication
    }

}).then((response) => (response.data))
    .catch(err => {
        return Promise.reject(err)
    })

