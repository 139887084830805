
import { DataGrid } from "components/DataGrid/DataGrid";
import { DynamicComponentHOC } from "components/DynamicComponentHOC/DynamicComponentHOC";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { ToolbarInTab } from "components/ToolbarInTab/ToolbarInTab";
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment";
import { useEffect, useState } from "react";
import { JobStatus } from "types/enum";
import { DynamicForm } from "types/types";
import { GUID_EMPTY } from "utils/constants";
import { DYNAMICFORMS } from "utils/dynamicForms";
import { ENDPOINTS } from "utils/endpoints";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { CustomerFeedBackForm } from "../CustomerFeedBack/CustomerFeedBack";
import { cancelRequest, getMyOwnRequests, getRequesterUserId, getStartForm } from "./api";
import { RequestDetails } from "./components/RequestDetails";
interface MyOwnRequestsListProp{
    curentTab?:string;
    Type?:any;
    tabName?:string;
  }

export const MyOwnRequestsList = ({curentTab, Type ,tabName}:MyOwnRequestsListProp) => {

    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<any>([]);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [showForm, setShowForm] = useState(false);
    const [showCustomerFeedBackForm, setShowCustomerFeedBackForm] = useState(false);
    const [mode, setMode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const [jobstatusFilter, setJobstatusFilter] = useState<any>(0);
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [filterlist, setFilterlist] = useState<any>("");
    const [dynamicForm, setDynamicForm] = useState<DynamicForm | any>("GeneralRequestForm");
    const [showDynamicForm, setShowDynamicForm] = useState<boolean>(false);
    const [showAttachment, setShowAttachment] = useState(false);
    const [pages, setPages] = useState<number>(0);
    const lang = localStorage.getItem("lang");

    let customFuction: any = [
        [(e) => { setShowCustomerFeedBackForm(true); setSelectedObj(e) }, "clipboard", "FeedBack", "JobStatusEnum" , JobStatus.FinishedAndWaitingForEvaluatio],
        [(e) => { chatWithFollowupResponsible(e) }, "paper-plane", "Chat"]
    ]

    async function chatWithFollowupResponsible(e) {
        try {
            const res = await getRequesterUserId(e.Id);
            if(res?.Data.Id && res?.Data.Id != GUID_EMPTY){
            let item = {
                Id: res?.Data.Id,
                name: res?.Data.Description
            }
            localStorage.setItem("JobRequester", JSON.stringify(item));
            window.open(`#/message`)
          }
        } catch (error) {

        }
    }

    const searchHandler = (str) => {
        setPageNum(1);
        if (str == "") {
            setSearch(null)
            getMyOwnRequestsAsync(1, null)
        } else {
            setSearch(str)
            getMyOwnRequestsAsync(1 , str)
        }
    }
    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("MyOwnRequestsList", e.currentTarget.value)
        getMyOwnRequestsAsync(1)
    };
    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else {setPageNum(+value);getMyOwnRequestsAsync(+value)}
        } 
    };
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getMyOwnRequestsAsync(pageNum + 1);
        };
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getMyOwnRequestsAsync(pageNum - 1);
        };
    };

    const selectedObjCallback = (obj) => {
        setSelectedObj(obj);
    }

    const handleCreateRequest = (obj) => {
        setMode("create");
        getStartFormAsync(obj.Id);
    }

    async function getStartFormAsync(id: string) {
        document.body.classList.add("loading-indicator-"+lang);
        try {
            setMode("create");
            const res = await getStartForm(id);
            if (res.Data){
                setDynamicForm(res.Data);
                setShowDynamicForm(true);
            }
            else{
                setDynamicForm(undefined);
                setShowDynamicForm(false) 
            } 
        } catch (error) {
            setDynamicForm(undefined)
            setShowDynamicForm(false)
        }
        document.body.classList.remove("loading-indicator-"+lang);  
      }
    const handleCancelRequest = async (id) => {
        try {
            await cancelRequest(selectedObj?.Id)
            setForceUpdate(!forceUpdate)
        } catch (error) {
        }
    }
    const handleViewRow = () => {
        setMode("view");
        setShowForm(true)
    }
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel": downloadCSV(response ,4 ,trans("MyOwnRequestsList")); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "cancel": if (selectedObj) Toast(trans("Msg_CancelConfirm"), "warning", "alert", handleCancelRequest); break;
            case "view": if (selectedObj) handleViewRow(); break;
            case "help": alert("call api help"); break;
            case "attach": if (selectedObj) {
                setMode("edit")
                setShowAttachment(true)
            } else {
                Toast(trans("Msg_SelectRow"), "warning")
            } break;
        }
    };

    async function getMyOwnRequestsAsync(pagNum = pageNum ,searchSTR=search) {
        setLoading(false)

        let pageSizeChangedByUser = getPageSize("MyOwnRequestsList")
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getMyOwnRequests(pagNum, pageSizeChangedByUser, searchSTR, orderBy.length == 0 ? null : orderBy.join(","), filterlist, Type)
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            if (res.Data.Data.length > 0) {
                setResponse(res.Data)
            }
        } catch (error) {

        }
        setLoading(true)

    }

    useEffect(() => {
        //getDataAsync();setResponse
          if(tabName==curentTab){

              setSelectedObj(null)
              setResponse([])
              getMyOwnRequestsAsync();
            }
    }, [ jobstatusFilter, orderBy, filterlist]);
    useEffect(() => {
        //getDataAsync();setResponse
          if(tabName==curentTab){

              setResponse([])
              getMyOwnRequestsAsync();
            }
    }, [ forceUpdate]);

    useEffect(() => {
        if(tabName==curentTab){
            let settings = getGridSetting("MyOwnRequestsList")
            setOrderBy(settings[1])
            setFilterlist(settings[0])
        }
    }, [curentTab ,tabName]);


    return (
        <>
        {tabName==curentTab&&
        <>
        
        <ToolbarInTab cancel={Type==2||Type==null} defultSerach={search} attach={true} handleSearch={searchHandler} handleSelectedMyOwnRequestStartForm={(Type==2||Type==null)?handleCreateRequest:undefined}  id={selectedObj} emptyList={response?.TotalRecords == 0} search={true} handleClickToolbar={clickToolbarCallback} />
        <div style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}} className="col-lg-12 mt-0">
            {loading ?
                <>{
                    (response?.Data?.length > 0 ?
                        <div className="card">
                            <div className="card-body p-0">
                                <>
                                    <DataGrid
                                        DoubleClick={(obj) => { setSelectedObj(obj); clickToolbarCallback("view") }}
                                        View={true}
                                        ViewParametr={"NotSeen"}
                                        customFuction={customFuction}
                                        handlefiltering={(str) => { setFilterlist(str) }}
                                        filterStr={filterlist}
                                        orderFormat={setOrderBy}
                                        orderFormatList={orderBy}
                                        parentName={"MyOwnRequestsList"}
                                        theadData={Object.keys(response.Data[0])}
                                        tbodyData={response.Data}
                                        minCellWidth={80}
                                        rowSelected={selectedObj}
                                        handleAttachment={(e) => { setSelectedObj({ Id: e }); setMode("edit"); setShowAttachment(true) }}
                                        selectedRowObjectCallback={selectedObjCallback}
                                        startIndex={5}
                                        handleView={handleViewRow}
                                        totalRecords={response.TotalRecords}
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        HandleNextPage={handleNextPage}
                                        HandlePrevPage={handlePrevPage}
                                        handlechangePageSize={handleChangePageSize}
                                        first={() =>{getMyOwnRequestsAsync(pages) ;setPageNum(pages)}}
                                        end={() => {getMyOwnRequestsAsync(1) ;setPageNum(1)}}
                                        handleGoPage={handleGoPage}
                                    />
                                </>
                            </div>
                        </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    )

                }
                    <Message message={trans("Help_ClientPortal_Default_1")} type={"info"} />
                    <Message message={trans("Help_ClientPortal_Default_2")} type={"info"} />

                </>
                : <Loading />
            }

        </div>
        {showAttachment &&
            <ModalForm mode={mode} title={("Attachment")} onHide={() => { setShowAttachment(false) }} show={showAttachment}>
                <Attachment onHide={() => { setShowAttachment(false);  }} mood={mode} endpoint={ENDPOINTS.clientPortal.clientPortal.attachment} ownerId={selectedObj?.Id} />
            </ModalForm>
        }
        {showDynamicForm &&
            <ModalForm modalHeight={63} mode={mode} title={dynamicForm?.Name} onHide={() => { setShowDynamicForm(false);  }} show={showDynamicForm}>
                <>
                {dynamicForm?.Name!="OrderForm"&&
                    <div className="" >
                        <Message message={trans("Help_ClientPortal_Default_1")} type={"info"} />
                        <Message message={trans("Help_ClientPortal_Default_2")} type={"info"} />
                        {dynamicForm?.Name=="CustomerGeneralRequestForm"&&
                        <Message message={trans("Help_ClientPortal_Default_3")} type={"info"} />
                        
                        }
                    </div>
                }
                    <DynamicComponentHOC onHide={() => { setShowDynamicForm(false); setForceUpdate(!forceUpdate) }} 
                     componentName={DYNAMICFORMS[dynamicForm.Name] ?null:dynamicForm.Name} component={DYNAMICFORMS[dynamicForm.Name] ?DYNAMICFORMS[dynamicForm.Name].component:null}
                        reload={() => { setForceUpdate(!forceUpdate) }} componentParams={dynamicForm.Parameters} processId={dynamicForm?.ProjectId  }
                        handleCancelForm={() => setShowDynamicForm(false)} referenceId={undefined} />

                </>
            </ModalForm>
        }

        {showForm &&
            <ModalForm modalHeight={50} mode={mode} title="Details" onHide={() => {setShowForm(false);setForceUpdate(!forceUpdate)}} show={showForm}>
                <RequestDetails Id={selectedObj?.SourceId} JobNo={selectedObj?.JobNo} hide={() => { setShowForm(false) ;setForceUpdate(!forceUpdate)}}  jobId={selectedObj?.Id} processId={selectedObj?.PRJ_UID} mode={mode} />
         </ModalForm>
        }
        {showCustomerFeedBackForm &&
            <ModalForm modalHeight={50} mode={mode} title="CustomerFeedBack" onHide={() => setShowCustomerFeedBackForm(false)} show={showCustomerFeedBackForm} >
                <CustomerFeedBackForm reload={() => { setForceUpdate(!forceUpdate) }} hide={() => { setShowCustomerFeedBackForm(false) }} Id={selectedObj?.Id} mode={"edit"} />
            </ModalForm>
        }
        
        </>
        }
        
        </>

    );
}