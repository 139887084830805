import { Button } from "components/Button/Button";

import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { AddNodeToParent, editNode, getNode } from "../api";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";

interface GeographicalClassificationFormPropsType {
    selectedObj;
    showForm;
    mode;
    onHide?:()=>void,

}
export const GeographicalClassificationForm = ({ 
    selectedObj,
    showForm,
    mode,
    onHide
}: GeographicalClassificationFormPropsType) => {
   
    let  Description,condition , Qualification :any;
    const [resetForm, setResetForm] = useState(false);
    const [node, setNode] = useState<any>();
    const [userChanges, setUserChanges] = useState({});

    if (mode == "edit" ||mode == "view" ) {
        Description = selectedObj.Description
    }
    async function sendNodeAsync(data) {
        try{
           const res = await AddNodeToParent(data);
           sessionStorage.setItem("changingforms" , "false");
           setUserChanges({});
           showForm()
           
        }catch(err:any){

          }
        }

    async function getNodeAsync() {
        try{
           const res = await getNode(selectedObj.Id);
           setNode(res.Data)


        }catch(err:any){

          }
    }

    async function putNodeAsync(data) {
            try{
               const res = await editNode(data);
               sessionStorage.setItem("changingforms" , "false");
               setUserChanges({});
               showForm()
               
            }catch(err:any){
    
              }
            }


            function cancel() {
                if(sessionStorage.getItem("changingforms")+""=="true"){
                    Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                        sessionStorage.setItem("changingforms" , "false");
                        setUserChanges({});
                        showForm(false)
                    });
                }else{
                    showForm(false)    
                };
        
        
            }
            
    const handlePersonContactFormSubmit = (event) => {
        event.preventDefault();
        if(mode=="edit"){
            let data={
                "id":selectedObj.Id,
                "ParentId": selectedObj.ParentId,
                "ActivityStatus": +event.target.ActivityStatus.value,
                "Description": event.target.Description.value,
                "DescriptionEN": event.target.DescriptionEN.value,
                "LevelLimitation": +event.target.LevelLimitation.value,
                "Code": event.target?.Code?.value
            }
          putNodeAsync(data)
          onHide && onHide()
        
        }else{
            let data={
                "ParentId": selectedObj?.Id ? selectedObj.Id :null,
                "Description": event.target.Description.value,
                "DescriptionEN": event.target.DescriptionEN.value,
                "LevelLimitation": +event.target.LevelLimitation.value,
                "Code": event.target?.Code?.value

            }
           sendNodeAsync(data)
           onHide && onHide()
          }
       
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);

     useEffect(() => {
          if (mode == "edit" ||mode == "view" ) {
            getNodeAsync()
        }
      }, [selectedObj]);


      
    return (
        <>
        <div className="person-contact-form card">
            <div className="card-body">
                <div className="container-fluid">
                    <form className="row " onSubmit={handlePersonContactFormSubmit}>
                        <div className="col-lg-6 mt-1">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                required={true}
                                textboxType="text"
                                textboxName="Description"
                                labelText="Description"
                                maxLength={900}
                                defaultValue={node ? node?.Description : Description}
                                resetForm={resetForm}
                                mode={mode}
                            />
                        </div>
                        <div className="col-lg-6 mt-1">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="text"
                                textboxName="DescriptionEN"
                                labelText="DescriptionEN"
                                maxLength={900}
                                defaultValue={node ? node?.DescriptionEN : Description}
                                resetForm={resetForm}
                                mode={mode}
                            />
                        </div>
                        <div className="col-lg-6 mt-1">
                            <Textbox
                                textboxType="number"
                                textboxName="LevelLimitation"
                                resetForm={resetForm}
                                defaultValue={node ? node?.LevelLimitation:""}
                                labelText="LevelLimitation"
                                mode={mode}
                                />
                        </div>
                        <div className="col-lg-6 mt-1">
                            <Textbox
                                textboxType="text"
                                textboxName="Code"
                                resetForm={resetForm}
                                defaultValue={node ? node?.Code:""}
                                labelText="Code"
                                mode={mode}
                                />
                        </div>
                        {mode != "create" && 
                        <div className="col-lg-6 mt-1">
                            <EnumerationTypeSelect
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              required={true}
                              label="ActivityStatus"
                              name="ActivityStatus"
                              enumTypeSelect="ActivityStatus"
                              value ={node ? node?.ActivityStatus :condition }
                              mode={mode}
                            />
                        </div>                        
                        }



                        {  mode !="view" ?
                            <div className="col-lg-12 mt-1 btns_form">
                            <Button value="Save" btnType="submit" />
                            <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                      </div>
                      :
                      <div className="row my-2">
                      <div className="col-12 d-flex justify-content-end">
                          <Button btnType="primary" value={'close'} onClick={() => { cancel() }} />
                      </div>
                  </div>
                        }
                    </form>
                </div>
            </div>
        </div>        
        </>

    );
}