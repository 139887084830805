import { useEffect, useState } from "react";
import { putWarehouseData, postWarehouseData, getWarehouseDataWithId } from "./api";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { Button } from "components/Button/Button";
import { ModalForm } from "components/ModalForm/ModalForm";
import { WarehouseAccess } from "./WarehouseAccesses";
import { trans } from "utils/helpers";
import { Toast } from "components/ToastShow/ToastShow";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";

interface props {
    showForm: (e:boolean) => void
    selectedObj: any
    mode: any

}


export const WarehouseForm = ({showForm, selectedObj, mode}: props) => {
    const [resetForm, setResetForm] = useState(false);
    const [EditForm, setEditForm] = useState<any | null>(null);
    const [loading, setLoading] = useState(false);
    const [showAccess, setShowAccess] = useState<any>(false);
    const [boardData, setBoardData] = useState<any>(false);
    const [userChanges, setUserChanges] = useState({});



    const handleSubmit = async(e) => {
        e.preventDefault()        
     if(mode == "create"){
    const data = {
        Name: e.target.Name.value,
        NameEN: e.target.NameEN.value,
        Code:e.target.Code.value,
        PricingMethod:+e.target.PricingMethod.value,
    }
        setBoardData(data)
        setShowAccess(true)
    }else{
    const data = {
        Id: selectedObj.Id,
        Name: e.target.Name.value,
        NameEN: e.target.NameEN.value,
        Code:e.target.Code.value, 
        PricingMethod:+e.target.PricingMethod.value, 
        ActivityStatus: parseInt(e.target.ActivityStatus.value),
    }
  
    try {
        const res = await putWarehouseData(data)
        getWarehouseDataAsync(); 
        setResetForm(true); 
        sessionStorage.setItem("changingforms" , "false");
        setUserChanges({});
        setEditForm(null) 
        showForm(true)
        } catch (error) {    }

    } 
}




async function accessDatahandler(data) {
    if(mode == "create"){
        let formdata:any ={}
        formdata.Warehouse = boardData
        formdata.WarehouseAccesses = data ;
        try {
            const res = await postWarehouseData(formdata)
            getWarehouseDataAsync(); 
            setResetForm(true);
            sessionStorage.setItem("changingforms" , "false");
            showForm(true)
        } catch (error) {
            sessionStorage.setItem("changingforms" , "false");

        }
    }
}

function cancel() {
    if(sessionStorage.getItem("changingforms")+""=="true"){
        Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
            showForm(false)
        });
    }else{
        showForm(false)     
    };


}

    async function getWarehouseDataAsync() {
        setLoading(false)
        try {
           const res = await getWarehouseDataWithId(selectedObj.Id);
           setEditForm(res.Data);
      
        } catch (error) {
        }
        setLoading(true)
    }
    

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        if (resetForm) setResetForm(false);
    }, [resetForm]);

    useEffect(() => {
        if(selectedObj?.Id && mode !="create"){
            getWarehouseDataAsync()
        }else{
            setLoading(true)
        }
    }, [selectedObj]);

    return (
        <>
            { showAccess &&
              <ModalForm modalHeight={60} mode={mode}modalProfileKey={"Users"} title={trans("Users")+" : " +boardData?.Name} onHide={() => setShowAccess(false)} show={showAccess}>
                  <WarehouseAccess
                    Warehouse={selectedObj} 
                    showForm={setShowAccess} 
                    accessDatahandler={accessDatahandler} 
                    accessData={[]}
                    mode={mode}
                  />
              </ModalForm>
            }
            <div className="card">
                <div className="">




              
                    {loading ?
                        <form className="TypeSettingForm row card-body " onSubmit={handleSubmit}>
                            <div className="col-md-4 col-sm-12">
                                <Textbox
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                     required={true} 
                                     textboxName={"Name"}
                                     textboxType="text" 
                                     resetForm={resetForm}
                                     labelText={"Name"} 
                                     mode={mode}
                                     defaultValue={EditForm?.Name} 
                                 />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <Textbox
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                     textboxName={"NameEN"}
                                     textboxType="text" 
                                     resetForm={resetForm}
                                     labelText={"NameEN"} 
                                     mode={mode}
                                     defaultValue={EditForm?.NameEN} 
                                 />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <Textbox
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                     required={true} 
                                     textboxName={"Code"}
                                     textboxType="wareHouseCode" 
                                     resetForm={resetForm}
                                     labelText={"Code"} 
                                     mode={mode}
                                     maxLength={3}
                                     defaultValue={EditForm?.Code} 
                                 />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <EnumerationTypeSelect
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                     required={true} 
                                     label="PricingMethod"
                                     enumTypeSelect="PricingMethod"
                                     name="PricingMethod"
                                     mode={mode }
                                     value={EditForm?.PricingMethod} 
                                 />
                            </div>
                            {mode != "create" && 
                             <div className="col-md-4 col-sm-12">
                                <EnumerationTypeSelect
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                                    name="ActivityStatus"
                                    label="ActivityStatus"
                                    enumTypeSelect="ActivityStatus"
                                    value={EditForm?.ActivityStatus}
                                    mode={mode === "view" ? "view" : null}
                                />
                             </div>
                            }
                            
                            {mode !== 'view' ?
                                 <div className="d-flex justify-content-end my-2">
                                     <Button value="submit" btnType="submit"  />
                                     <Button value="cancel" btnType="cancel" onClick={() => cancel()}  />
                                 </div>
                                 :
                                 <div className="col-lg-12 mt-1 btns_form">
                                 <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                                 </div>
                            }
                        </form>
                        : <Loading />
                    }
                </div>
            </div>
        </>
    );
}