import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { trans } from "utils/helpers"
import { getProcess } from "./api"
import "./style.scss"
import { ICON_TYPE } from "utils/constants"
interface ButtonToolbarProcessFilter {
    onClick?: () => void,
    Callback: (id: string) => void,
}
export const ButtonToolbarProcessFilter: React.FC<ButtonToolbarProcessFilter> = ({ onClick, Callback }) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    const buttonToolbarProcessStarting = useRef<any>();
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [options, setOptions] = useState<any>(null)
    const [selectoptions, setSelectOptions] = useState<any>()
    const [loading, setLoading] = useState<any>()
    const [rotate , setRotate] = useState<boolean>(false);
    const listOption = useRef<any>()
    const[directionLiList,setDirectionLiList] = useState<any>(false)

    function showSubMenuFunction() {
        setShowSubMenu(true)
    }
    function hideSubMenuFunction() {
        setShowSubMenu(false)
    }
    async function getProcessAsync() {
        setLoading(false)
        try {
            const res = await getProcess()
            setOptions(res) 
        } catch (error) {
            
        }
        setLoading(true)
    }
    function selectboxValSetter(event) {
        setSelectOptions(event.currentTarget.getAttribute('data-value'))
        Callback && Callback(event.currentTarget.getAttribute('data-value'));
        setShowSubMenu(false)
        if (buttonToolbarProcessStarting.current) (buttonToolbarProcessStarting.current as HTMLButtonElement).blur();
    }

    useEffect(() => {
        if(showSubMenu &&options==null){
            getProcessAsync()
        }
    }, [showSubMenu]);

    function offset(){
        var rect = buttonToolbarProcessStarting.current.getBoundingClientRect()
        const windowInnerWidth  = window.innerWidth
        const listOptionWidth = listOption.current.getBoundingClientRect()
        if(listOptionWidth.width < windowInnerWidth - rect.right){
            setDirectionLiList(false)
        }else{
            setDirectionLiList(true)
        }
    }

    return (
        <>
         <div className="toolbar-btn-wrapper">
            <button  onClick={offset} ref={buttonToolbarProcessStarting}  onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction} className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
                <div className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>Ctrl+f</div>
                {(selectoptions &&selectoptions!="00000000000000000000000000000000") && <div className={`toolbar-btn__count  ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}></div>}
                <FontAwesomeIcon icon={['fas', 'filter']} width="20px" height="20px" />
                <small>{trans("Process")}</small>
                <div ref={listOption} className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`} style={{left:directionLiList ?'-165px' :'0'}}>
                    {loading?
                    <>
                        {options && options.map((option, index) => (
                            selectoptions && option.Id ==selectoptions  ?
                            <div key={index} className="toolbar-btn-sub-menu__option selected"  data-value={`${option.Id}`} onClick={selectboxValSetter}>{trans(option.Description)}</div>
                           :
                            <div key={index} className="toolbar-btn-sub-menu__option" data-value={`${option.Id}`} onClick={selectboxValSetter}>{trans(option.Description)}</div>
                        ))}
                    </>
                        :
                        <div  className="sync-box-icon mt-4" onClick={()=>{setRotate(true);  setTimeout(()=>{ setRotate(false) }, 1500)}}   >       
                           <FontAwesomeIcon icon={[ICON_TYPE , "sync"] } className={`text-primary  ${rotate ? "Rotate" : "" } `}  />  
                        </div>  
                    }

                </div>
            </button>
        </div>
        
        </>
    )
    }