import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const sendReserve = (async ( secretariatId,  letterType, fromNumber, toNumber) => await http({
    url: ENDPOINTS.secretariat.indicator.reserved,
    method: 'post',
    params: {
        secretariatId : secretariatId,
        letterType : letterType,
        fromNumber : fromNumber,
        toNumber : toNumber,
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getSecretariat = (async ( secretariatId) => await http({
    url: ENDPOINTS.secretariat.secretariat.load,
    method: 'get',
    params: {
        Id : secretariatId,
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)