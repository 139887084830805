import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ICON_TYPE } from "utils/constants";
import "./style.scss";
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const ChatNotification = () => {
  const layoutContext = useContext(LayoutContext);

  const [count, setCount] = useState<any>(false);
  const [flag, setFlag] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleNavigate = (rout) => {
    
    if(sessionStorage.getItem("changingforms")+""=="true"){
      Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{navigate(rout);sessionStorage.setItem("changingforms" , "false");localStorage.removeItem("formsInChang")  });
    }else{
      navigate(rout)
    }
  }

  const enterNavigat = (event)=>{
    if(event.key == "Enter"){
      handleNavigate("/message")
    }
  }
  
  useEffect(()=>{
    if(flag == true){
      document.addEventListener("keydown",enterNavigat)
    }
    return () =>{document.removeEventListener("keydown",enterNavigat)}
  },[flag])

  useEffect(() => {
    setCount(layoutContext.chatNotificount)
  }, [layoutContext.chatNotificount]);


  return (
    <>


<OverlayTrigger
        placement="bottom"
        delay={{"show" : 300 , "hide":0}}
        overlay={
            <Tooltip className="tooltip" id={`button-tooltip-${"message"}`}>
                {trans('message')}
            </Tooltip>
        }
        >
         <Link className="position-relative" to={"/message"} onFocus={()=>setFlag(true)} onBlur={()=>setFlag(false)} onClick={(e)=>{e.preventDefault();handleNavigate("/message")}} >
        <FontAwesomeIcon icon={[ICON_TYPE, "message"]} />
        {count  ? <div className="badgeChat"></div>   :<></>
                    
         }

      </Link>
      </OverlayTrigger>
   
    </>
  );
};
