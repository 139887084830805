import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";

interface ItemCardsProps {
    setfilterObj: (data: any) => void,
    filterData?: any,
    handleFilter?:any
}


export const FilterBox = ({setfilterObj,filterData,handleFilter}:ItemCardsProps) => {
    const [showStep, setShowStep] = useState<any>({3:true});
    const [values,setValues]=useState<any>({})



  
      
    return ( 
        <div>
            <div className={`card mb-3 `}>

                <div style={{ backgroundColor: "#f97316",color:'#fff', paddingRight: "20px", justifyContent: "space-between" }} className="d-flex  py-1 col-12 pr-4">
                    <div style={{whiteSpace:'nowrap'}}>{filterData.title}</div>
                    <div style={{ marginLeft: "18px" }} onClick={() => { showStep[1] ? setShowStep({ ...showStep, 1: false }) : setShowStep({ ...showStep, 1: true }) }} >{showStep[1] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" /> : <FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                </div>
                <div style={{display: !showStep[1] ?"block" :"none"}} className="card-body" >
                {
                    {      
                        1: (
                            <>
                            <Textbox
                            withLabel={false}
                            useGroupingPrice
                            textboxType="text"
                            textboxName="min"
                           
                            placeholder={ "از قیمت"}
                            onChange={(e)=>{setfilterObj({min:e.target.value})}}
                            mode={"edit"}
                            customClass='arrow-none'
                            />
                            <Textbox
                            withLabel={false}
                            useGroupingPrice
                            textboxType="text"
                            textboxName="max"
                            placeholder={ "تا قیمت"}
                            onChange={(e)=>{setfilterObj({max:e.target.value})}}
                            mode={"edit"}
                           
                            customClass='arrow-none'

                            />
                            <div className="text-start " style={{paddingLeft:'0.8rem'}}>

                            <Button className="btn-customerPrimaryColor border-0 btn-sm row" onClick={handleFilter}>
                          <span>
                            
                             {trans('filter')}
                            </span> 
                            <span>

                            <FontAwesomeIcon icon={["fas", "filter"]} className="text-white"/>
                            </span>
                            </Button>
                            </div>

                            </>
                        ),
                         2: (
                            <>
                            {
                            filterData?.data?.map((item , index)=>(
                            <>
                                <input type="radio" onClick={(e)=>{setfilterObj({option:item})}} id={item+index} name={filterData.title} value={item} />
                                <label className="mx-1" htmlFor={item}>{item}</label><br></br>
                              
                            </>
                                 ))}
                                  <input type="radio" onClick={(e)=>{setfilterObj({option:null})}} id="alll"  name={filterData.title} value={"همه"} />
                                <label className="mx-1" htmlFor={"alll"}>همه</label><br></br>
                            </>
                        ),
                         3: (
                            <>
                            {
                            filterData?.data?.map((item , index)=>(
                            <div className="d-flex">
                                <input type="checkbox" id={item+index} onClick={(e)=>{setfilterObj({option2:item})}} name={filterData.title} value={item} />
                                <label className="mx-1" htmlFor={item}>{item}</label><br></br>
                              
                            </div>
                                 ))}
                            
                            </>
                         ),

                     }[filterData.type]}

                </div>
            </div>
        </div>
     );
}
 
