import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react";
import { getFileList } from "../api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { trans } from "utils/helpers";


interface props {
    index: any
    user: any
    reply: any
    message: string
    sent_Date: string
    submitted?: boolean
    received_message: boolean
    contactName: string
    sendRepliesUp: (data: any) => void
    handleReplyOnclick?: () => void
    handleAttachmentOnclick?: () => void
    reply_data: { message: string, sent_Date: string }
    messageValue: string
    attached_file: any
    item: any
}

export const RequestsMessageBox = ({ item ,index, message, attached_file = [],handleAttachmentOnclick , handleReplyOnclick, messageValue, received_message, sent_Date, reply_data, reply, sendRepliesUp, submitted, user, contactName }: props) => {
    const formTextbox = useRef<any>();

    const lang = localStorage.getItem('lang')
    function elementInViewport(el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top >= window.pageYOffset &&
            left >= window.pageXOffset &&
            (top + height) <= (window.pageYOffset + window.innerHeight) &&
            (left + width) <= (window.pageXOffset + window.innerWidth)
        );
    }




    async function getFiles(id, name) {

        try {

            const list = await getFileList(id, name);




            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = URL.createObjectURL(list)
            a.download = name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);






        } catch (error) {

        }

    }

  
    useEffect(() => {
        if (formTextbox.current) {
            elementInViewport(formTextbox.current)

        }
    }, [])


    return (
        <div ref={formTextbox} className={`Requests__chatbox  d-flex flex-column w-100 my-2 text-end ${received_message ? 'align-items-end' : 'align-items-start'}`}>
            <div className={`d-flex align-items-start ${received_message ? 'flex-row-reverse' : 'flex-row'}`}>

                <div className="d-flex flex-column">
                    <div style={{ whiteSpace: "pre-wrap" }} className={`border d-flex flex-column justify-content-end rounded p-2 ${received_message ? 'Requests__chatbox-sender' : ''}`}>
                        <div className={`d-flex ${received_message && 'flex-row justify-content-between'}  ${lang === 'fa' ? 'flex-row' : 'flex-row-reverse'}`}>
                            <div>
                                <FontAwesomeIcon icon={['fas', 'user-circle']} color='grey' className="mx-2 mt-1 Requests__contact-list-avatar" />
                                <span className="text-secondary fw-lighter mb-2 text-nowrap userName">{!received_message ? user : contactName}</span>
                            </div>
                            <div className="text-secondary d-flex justify-content-end">
                                <span className="ms-1">زمان:</span>
                                <span>{sent_Date}</span>
                            </div>

                        </div>
                        {attached_file[1][0] ?
                            <div className={` ${attached_file[1][0].length > 4 ? 'd-flex flex-row flex-wrap' : ''}`}>
                                {attached_file[1][0].map((item: any) => (
                                    <div className="d-flex flex-column " style={{ width: attached_file[1][0].length > 4 ? "30%" : "100%" }}>
                                        <div onClick={() => { getFiles(attached_file[0][0], item?.fileName ? item?.fileName : item?.FileName) }}  >
                                            <div className="Requests__chatbox-attachments d-flex align-items-center mt-2">
                                                <img src={'data:image/png;base64,' + (item.Thumbnail ? item.Thumbnail : item.thumbnail)} className={`FileOption `} />
                                                <span className="text-muted">{item?.fileName ? item?.fileName : item?.FileName}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}


                            </div>
                            :
                            <>
                                <div className={`d-flex my-1 ${lang === 'fa' ? 'flex-row' : 'flex-row-reverse'}`} >  {trans("ReferenceSubject") }: {item.ReferenceSubject}</div>
                                <div className={`d-flex  ${lang === 'fa' ? 'flex-row' : 'flex-row-reverse'}`} > {trans("Description")}: {message}</div>
                                <div className={`d-flex justify-content-end mt-2 w-100   ${lang === 'fa' ? 'flex-row' : 'flex-row-reverse'}`}>  
                                 {item.PaperclipIcon.length > 0 && 
                                    <FontAwesomeIcon onClick={ handleAttachmentOnclick} icon={['fas', 'paperclip']} className="Requests__chatbox-reply-btn mx-1"  flip={lang === 'fa' ? undefined : 'horizontal'} color={`grey`} />
                                  } 
                                  {reply && 
                                              <OverlayTrigger
                                              key={"replyBox"}
                                              placement="top"
                                              delay={{"show" : 600 , "hide":0}}
                                              overlay={
                                                  <Tooltip className="tooltip" id={`button-tooltip-${"replyBox"}`}>
                                                      {trans("reply")}
                                                  </Tooltip>
                                              }
                                          >
                                            <div onClick={handleReplyOnclick} className="btn btn-primary btn-sm d-flex align-items-center justify-content-between " style={{width:'70px'}}>
                                              <span className=" font-size-md ">{trans('reply')}</span>
                                              <FontAwesomeIcon size="xs" icon={['fas', 'reply']} className="color_text_light d-flex Requests__chatbox-reply-btn mx-1"  flip={lang === 'fa' ? undefined : 'horizontal'} color='white' />
                                            </div>
                         
                                          </OverlayTrigger>  
                                  } 
                                </div>
                            </>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}
