import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Button } from "components/Button/Button"
import { CheckableButton } from "components/CheckableButton/CheckableButton"
import Datepicker from "components/DatePicker/DatePicker"
import { FileInput } from "components/FileInput/FileInput"
import { Loading } from "components/Loading/loading"
import { ModalForm } from "components/ModalForm/ModalForm"
import { TabBar } from "components/Tabbar/Tabbar"
import { Textbox } from "components/Textbox/Textbox"
import { Toast } from "components/ToastShow/ToastShow"
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment"
import { useEffect, useRef, useState } from "react"
import { ENDPOINTS } from "utils/endpoints"
import { trans } from "utils/helpers"
import { getBoardId, sendBulletinBoard } from "../../api"
import { BoardAccess } from "./BoardAccess"
import './style.scss'
import { EnumerationTypeSelect } from 'components/EnumerationTypeSelect/EnumerationTypeSelect'

interface props {
    mode: any
    showForm: (e:boolean) => void
    getDataForShow: (data: any) => void
    selectedObj: any
}

export const BoardForm = ({  mode, showForm, getDataForShow, selectedObj }: props) => {

    const [fromDate, setFromDate] = useState<string | null>(null);
    const [untilDate, setUntilDate] = useState<string | null>(null);
    const [editor, setEditor] = useState<any>();
    const [editorData, setEditorData] = useState<any>();
    const [resetForm, setResetForm] = useState(false);
    const [response, setResponse] = useState<any>();
    const [showAccess, setShowAccess] = useState<any>(false);
    const [boardData, setBoardData] = useState<any>(false);
    const [converted, setConverted] = useState<any>(false);
    const [boardType, setBoardType] = useState<any>();
    const [selectedImage, setSelectedImage] = useState<any>();
    const [loading , setLoading] = useState(mode=="create");
    const [userChanges, setUserChanges] = useState({});
    const [curentTab, setCurentTab] = useState<any>("BoardForm");
    const [flag,setFlag] = useState(false);
    const [canSendSMS,setCanSendSMS] = useState(false);
    const [SendSMS,setSendSMS] = useState(false);


    const myform = useRef<any>(null);
    const changeHandlePic = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files);
        }
    };

    async function sendDataAsync(data, method) {
        try {
            const res = await sendBulletinBoard(data, method ,SendSMS);
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
            showForm(true)
        } catch (err: any) {

        }
    }

    async function getDataAsync(id) {
        setLoading(false)
        try {
            const res = await getBoardId(id);
            setResponse(res.Data)
            setSelectedImage(res.Data.BulletinBoard.Thumbnail == undefined ? null : res.BulletinBoard.Thumbnail)
            setEditorData(res.Data.BulletinBoard.Description)
        } catch (err: any) {

        }
        setLoading(true)

    }

    
    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                showForm(false)
            });
        }else{
            showForm(false)     
        };


    }

    function accessDatahandler(data) {
        if (mode == "create") {
            let formdata: any = {}
            formdata.BulletinBoard = boardData
            formdata.BulletinBoardAccesses = data;
            let _objData = new FormData

            _objData.append("BulletinBoardDTO", JSON.stringify(formdata));


            if (selectedImage) {
                const ListSelected:any = [...selectedImage]
                ListSelected.map(item=>{
                    _objData.append("files", item);
              
                  })
            }

            sendDataAsync(_objData, "post")
        } else {
            let BulletinBoard = {
                BulletinBoard: boardData,
                BulletinBoardAccesses: data
            }
            sendDataAsync(BulletinBoard, "put")
        }
    }

    const validationDates = () =>{
        if(fromDate && untilDate && fromDate?.toString().slice(0,10) > untilDate?.toString().slice(0,10)){
            setFlag(true)
        }else{
            setFlag(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if(flag){
            Toast(trans("InvalidEndDate"),"error");
        }else{
        setConverted(true)

        if (mode == "create") {
            const data = {
                Title: event.target.title.value,
                Description: editorData,
                NotificationType: +event.target.NotificationType.value,
                StartDate: fromDate,
                EndDate: untilDate,
                IsPublic: event.target.IsPublic.checked,
                Link: event.target.linkAddress.value,
                ActivityStatus: 1
            }
            if (event.target.IsPublic.checked) {
                let formdata: any = {}
                formdata.BulletinBoard = data
                let _objData: any = new FormData
                _objData.append("BulletinBoardDTO", JSON.stringify(formdata));
                if (selectedImage) {
                    const ListSelected:any = [...selectedImage]
                    ListSelected.map(item=>{
                        _objData.append("files", item);
                  
                      })
                }

                sendDataAsync(_objData, "post")
            } else {
                setBoardData(data)
                setShowAccess(true)
            }
        } else {

            const data = {
                Id: selectedObj.Id,
                Title: event.target.title.value,
                Description: editorData,
                NotificationType: +event.target.NotificationType.value,
                StartDate: fromDate,
                Link: event.target.linkAddress.value,
                EndDate: untilDate,
                IsPublic: event.target.IsPublic.checked,
                ActivityStatus: 1
            }

            if (event.target.IsPublic.checked) {
                let BulletinBoard = {
                    BulletinBoard: data
                }
                sendDataAsync(BulletinBoard, "put")
            } else {
                setBoardData(data)
                setShowAccess(true)
            }

        }
    }
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


      const tabChangeHandle = (tab) => {
        setCurentTab(tab)
      }
      
    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){
            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);

    useEffect(() => {
        if (selectedObj && mode != "create") {
            getDataAsync(selectedObj.Id)
        }
    }, [selectedObj]);

    useEffect(() => {
        const data = {
            Title: myform?.current?.title?.value,
            Description: editorData,
            BulletinBoardType: +myform?.current?.NotificationType?.value,
            StartDate: fromDate,
            EndDate: untilDate,
            Link: myform?.current?.linkAddress?.value,
            ActivityStatus: 1,
            selectedImage: selectedImage,
            IsPublic: false
        }
        getDataForShow(data)
    }, [curentTab]);

    useEffect(()=>{
        validationDates()
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1; 
        const day = date.getDate();
        const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
        if(fromDate?.slice(0,10)==formattedDate){
            setCanSendSMS(true)
        }else{
            setCanSendSMS(false)
            setSendSMS(false)
        }
      },[untilDate,fromDate])



    let content = [
        { title: "BoardForm", body: (    <>
        <div className="p-2">
            {showAccess &&
                <ModalForm mode={mode} modalProfileKey={"Users"} title={trans("Users")+" : " +boardData.Title } onHide={() => setShowAccess(false)} show={showAccess}>
                    <BoardAccess
                        archive={selectedObj}
                        showForm={setShowAccess}
                        accessDatahandler={accessDatahandler}
                        accessData={response?.BulletinBoardAccesses}
                        mode={mode}
                    />
                </ModalForm>
            }
            {loading ?
            <form className={mode} ref={myform} onSubmit={handleSubmit}>
                <div className="row">
                    <div className="row mb-3">
                        <div className="col-12 col-md-6 col-lg-3">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="text"
                                labelText={boardType == "2" ? "footer" : "title"}
                                textboxName="title"
                                maxLength={50}
                                resetForm={resetForm}
                                defaultValue={response?.BulletinBoard?.Title}
                                mode={mode}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
                            <EnumerationTypeSelect
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}  
                             onChange={setBoardType}
                             required={true} 
                             mode={mode} 
                             label='NotificationType' 
                             name="NotificationType" 
                             value={response?.BulletinBoard?.NotificationType}
                             enumTypeSelect="NotificationType"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <Datepicker
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                required={true}
                                mode={mode}
                                resetForm={resetForm}
                                lable="FromDate"
                                setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                                val={response?.BulletinBoard?.StartDate ? response?.BulletinBoard?.StartDate : null}
                                errorFlag={flag}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <Datepicker
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                required={true}
                                lable="untildate"
                                setIsoTime={(isoTime: string) => { setUntilDate(isoTime) }}
                                val={response?.BulletinBoard?.EndDate ? response?.BulletinBoard?.EndDate : null}
                                mode={mode}
                                resetForm={resetForm}
                                errorFlag={flag}
                            />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12">
                            <span>{trans('text')}</span>
                            <CKEditor
                                data={editorData}
                                disabled={mode == "view" && "true"}
                                onReady={editor => {
                                    editor.ui.getEditableElement().parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.getEditableElement()
                                    );
                                    setEditor(editor)
                                }}
                                onError={(error, { willEditorRestart }) => {
                                    if (willEditorRestart) {
                                        editor.ui.view.toolbar.element.remove();
                                    }
                                }}
                                onOk={(editor) => {
                                    editor.insertHtml(editorData)
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEditorData(data)
                                }}
                                editor={DecoupledEditor}
                                config={{
                                    removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-3 col-md-6">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="text"
                                labelText="linkAddress"
                                textboxName="linkAddress"
                                defaultValue={response?.BulletinBoard?.Link}
                                mode={mode}
                                resetForm={resetForm}
                            />
                        </div>
                        <div className="col-3 col-md-3 mt-3 pt-2">
                            <CheckableButton
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                defaultChecked={response?.BulletinBoard?.IsPublic}
                                defaultValue={response?.BulletinBoard?.IsPublic}
                                labelText={"IsPublic"}
                                inputName={"IsPublic"}
                                type="checkbox"
                                mode={mode}
                            />
                        </div>
                        {
                            (mode=="create"&&canSendSMS)&&
                        <div className="col-3 col-md-3 mt-3 pt-2">
                            <CheckableButton
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                defaultChecked={SendSMS}
                                defaultValue={SendSMS}
                                readOnly={!canSendSMS}
                                checked={SendSMS}
                                onchange={setSendSMS}
                                labelText={"NotifyBySMS"}
                                inputName={"NotifyBySMS"}
                                type="checkbox"
                                mode={mode}
                                />
                        </div>
                        }
                    </div>
                    <div className="row mb-1">
                        {(boardType != "2"&&mode=="create") &&
                            <div className="col-12 col-md-6 mb-3 mb-md-0">

                            <FileInput
                                hideSrc={selectedImage ?false :true}
                                multiple={true}
                                mode={ mode}
                                lable="picture"
                                name="picture"
                                onChange={changeHandlePic}
                                src={selectedImage  ? (URL.createObjectURL(selectedImage[0] )) :""}
                                />
                            </div>

                        }
                    </div>
                    {mode !== "view" ?
                        <div className="row my-2">
                            <div className="col-12 d-flex justify-content-end">
                                <Button btnType="submit" value={'submit'} />
                                <Button btnType="cancel" value={'cancel'} onClick={() => { cancel() }} />
                            </div>
                        </div>
                        :
                        <div className="row my-2">
                            <div className="col-12 d-flex justify-content-end">
                                <Button btnType="primary" value={'close'} onClick={() => { cancel() }} />
                            </div>
                        </div>
                    }
                </div>
            </form>
            : <Loading/>
            }
        </div>

          </>) },
        mode !="create"&&{title: "picture", body: (<Attachment onHide={()=>{cancel()}}  inTab={curentTab=="picture"?"Attachment":curentTab} mood={mode} endpoint={ENDPOINTS.bulletinBoard.attachment} ownerId={selectedObj.Id} />) },
     
    ];
  








    

    return (
        <>
        <TabBar  tabChange={tabChangeHandle} content={content} defaultTab="BoardForm" />
        </>
    )
}