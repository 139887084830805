import { MEETINGMANAGEMENT_URL } from "utils/constants";
import { ENDPOINTSMEETINGMANAGEMENT } from "utils/endpoints";
import { http } from "utils/http";

export const putAccept = ( (data) =>  http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.accept ,
    method: 'put',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getAccept = ( (jobId) =>  http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.Invitation ,
    method: 'get',
    params: {
        jobId
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)