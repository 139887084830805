import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getDeviceDeliverie = (pageNumber: number, pageSize: number, searchString, orderBy, filterlist ) => http({
    url: ENDPOINTS.afterSalesApi.DeviceDelivery.browse,
    method: 'get',
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString: searchString,
        OrderByString: orderBy,
        FilterString: filterlist,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getDeviceDeliveryById = (id) => http({
    url: ENDPOINTS.afterSalesApi.DeviceDelivery.load,
    method: 'get',
    headers: { "Accept-Language": "en" },

    params: {
        Id:id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const postDeviceDelivery = (data ,method) => http({
    url: ENDPOINTS.afterSalesApi.DeviceDelivery.browse,
    method: method,
    data:data
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const getAllDeviceProperties = (async (deviceDeliveryId) => await http({
    url: ENDPOINTS.afterSalesApi.deviceProperty.list,
    method: 'get',
    params:{
        deviceDeliveryId:deviceDeliveryId
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
})
)
