import { useRef, useState } from "react";
import Mapir from "mapir-react-component";
import "./style.scss";

const MapInfo = Mapir.setToken({
  transformRequest: url => {
    return {
      url: url,
      headers: {
        "x-api-key": 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImJkOTlkOTQ2NDg5NjVmZjk5NmVlZTEyMzJhYWY1ZDI3YWViOTEzMmY3Y2M5MjE2YmExZDIwMTc0YWNlOTE0Y2UyMzU2YTg4YmZmMjc2ZWRmIn0.eyJhdWQiOiIxNzI5NiIsImp0aSI6ImJkOTlkOTQ2NDg5NjVmZjk5NmVlZTEyMzJhYWY1ZDI3YWViOTEzMmY3Y2M5MjE2YmExZDIwMTc0YWNlOTE0Y2UyMzU2YTg4YmZmMjc2ZWRmIiwiaWF0IjoxNjQ2NTc0ODMzLCJuYmYiOjE2NDY1NzQ4MzMsImV4cCI6MTY0ODk5MDQzMywic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.Z8zTkbwSQEaa2TPdTqNiUpS-wCq-koBYmovv4e9_YUj3qHMOAjmk4md3u6izRG68aR8Paq27gY4iJ54nDJcQq5988V2q92cedg7nAafTjreCmYhWWt2FuRPZ3UCW9LBxUnDYnyNxrN3HP55PuCtr3M7V3RZzP6JoMIePjPqJgSaX9U30cVyVpRKdqvmeG_6kFyl4iBicFBTiX_bFkTNYcIJ57Z77AIoYYjWjVuzAjydMYag62t5f_fiKbjf8tgfyUowFzSX8jDX7Za48ZGjHtuytt7ZQyiE_8TaE-BzpXNHrNxLS4pfQyqY8D-y6YK6LIHUB28aMernGLb2nXHxr7Q', //Mapir api key
        "Mapir-SDK": "reactjs"
      }
    };
  }
});
interface MapLocationPropsType {

  sendLocationCallback: (ans: any) => void,

}
export const MapLocation = ({sendLocationCallback}:MapLocationPropsType) => {
  const [markerArray, setMarkerArray] = useState([]);
  const [coord, setCoord] = useState([59.52, 36.33]);
  const [point, setPoint] = useState<any>();
  const [loading, setLoading] = useState<any>();
  const pointRef = useRef<any>(null);

  function reverseFunction(map, e) {
    var url = `https://map.ir/reverse/no?lat=${e.lngLat.lat}&lon=${
      e.lngLat.lng
    }`;
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImJkOTlkOTQ2NDg5NjVmZjk5NmVlZTEyMzJhYWY1ZDI3YWViOTEzMmY3Y2M5MjE2YmExZDIwMTc0YWNlOTE0Y2UyMzU2YTg4YmZmMjc2ZWRmIn0.eyJhdWQiOiIxNzI5NiIsImp0aSI6ImJkOTlkOTQ2NDg5NjVmZjk5NmVlZTEyMzJhYWY1ZDI3YWViOTEzMmY3Y2M5MjE2YmExZDIwMTc0YWNlOTE0Y2UyMzU2YTg4YmZmMjc2ZWRmIiwiaWF0IjoxNjQ2NTc0ODMzLCJuYmYiOjE2NDY1NzQ4MzMsImV4cCI6MTY0ODk5MDQzMywic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.Z8zTkbwSQEaa2TPdTqNiUpS-wCq-koBYmovv4e9_YUj3qHMOAjmk4md3u6izRG68aR8Paq27gY4iJ54nDJcQq5988V2q92cedg7nAafTjreCmYhWWt2FuRPZ3UCW9LBxUnDYnyNxrN3HP55PuCtr3M7V3RZzP6JoMIePjPqJgSaX9U30cVyVpRKdqvmeG_6kFyl4iBicFBTiX_bFkTNYcIJ57Z77AIoYYjWjVuzAjydMYag62t5f_fiKbjf8tgfyUowFzSX8jDX7Za48ZGjHtuytt7ZQyiE_8TaE-BzpXNHrNxLS4pfQyqY8D-y6YK6LIHUB28aMernGLb2nXHxr7Q"
      }
    })
      .then(response => response.json())
      .then(data => {setPoint(data) ;pointRef.current = data});
    const array:any = [];
    
    array.push(
      <Mapir.Marker
        coordinates={[e.lngLat.lng, e.lngLat.lat]}
        className="mapboxgl-marker"
        onClick={(e)=>{if(pointRef.current){sendLocationCallback(pointRef.current)}}}
        anchor="bottom"
      />
    );
    setMarkerArray(array);
  }



  return (
    <div className="map" style={{width:"100%" , height:"100%", position: "fixed", top: 0,right: 0}}>
      <Mapir onDataLoading={(e)=>{setLoading(true)}}onStyleLoad={(e)=>{setLoading(false)}}  center={coord} Map={MapInfo} onClick={reverseFunction}  userLocation >
        {markerArray}
      </Mapir>
      
    </div>
  );
};
