import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ICON_TYPE } from "utils/constants";

interface ProgresBarTaskProps {
    taskList:any,
    selectAtLeastOneTask?:boolean,
    counter:number,
    passList:any,
    progress:number,
    styleCondition:boolean,
    handleCircleClick?:(index:number)=>void
}

const ProgresBarTask = ({taskList,styleCondition,progress,passList,counter,selectAtLeastOneTask,handleCircleClick}:ProgresBarTaskProps) => {
    return (
    <div className="ProgresBarTask">
        <div className={`position-relative px-1 ${styleCondition ? 'ProgresBarTask-mb-scroll':'ProgresBarTask-scroll'}`}>
        {taskList.map((item: any, index) =>{

            return(
                <OverlayTrigger
                    placement="top"
                    delay={{ "show": 600, "hide": 0 }}
                    overlay={
                        <Tooltip className="tooltip"  >
                            {item.TaskTitle}
                        </Tooltip>
                    }
                >
                 <div className={`p-0 d-flex justify-content-around ${styleCondition ? 'flex-column ProgresBarTask-mb-scroll__infoBox' : 'ProgresBarTask-scroll__infoBox'} ${styleCondition ? 'align-items-center':index == 0 ? 'align-items-start':index +1 == taskList.length ? 'align-items-end mb-4': 'align-items-center'} `}>
                    <div  className={`ms-1 position-relative ${styleCondition?'order-1' :'order-2'} d-flex justify-content-center align-items-center ProgresBarTask-circle ${(!selectAtLeastOneTask ?  index <= counter :index == counter) ? ' activeStep':''}`} onClick={()=>handleCircleClick && handleCircleClick(index)}>
                        {passList && passList[index] &&   
                            <FontAwesomeIcon icon={[ICON_TYPE, "check"]} size="sm" color={'white'} />
                        }
                    {index == 0 && !selectAtLeastOneTask ?
                    <>
                        <div className="position-absolute w-100 h-100 bg-primary d-flex justify-content-center align-items-center rounded-circle" style={{zIndex:'3'}}>
                            {passList && passList[index] && index == 0 &&
                                <FontAwesomeIcon icon={[ICON_TYPE, "check"]} size="sm" color={'white'} />
                            }
                        </div>
                        <div className={`position-absolute ${styleCondition?'ProgresBarTask-ProgresBar__mb':'ProgresBarTask-ProgresBar'}`} style={{width:styleCondition?(((taskList.length -1 ) * 152))+'px':'6px',height:styleCondition ?'6px':(((taskList.length) * 50)+(10 * (taskList.length-1)))+'px'}}>
                            <div className="position-absolute bg-primary" style={{width:styleCondition?progress+'%':'6px  ',height:styleCondition? '6px':progress+'%',borderRadius:'4px'}}></div>
                        </div>
                    </>
                     : 
                    <></>
                    }
                    </div>
                    <div className={`${styleCondition?'mb-3':'w-75'}  showDotsTowLine ${styleCondition ? 'order-2':'order-1'} font-size-md ${(index == counter)?"shaking_animation":"" }`}style={{cursor:'pointer'}} onClick={()=>handleCircleClick && handleCircleClick(index)} key={index}>{item.TaskTitle}</div>
            </div>
            </OverlayTrigger>
            )
        })}
        </div>
    </div>
    );
}
 
export default ProgresBarTask;