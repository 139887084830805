import { Button } from "components/Button/Button"
import { Toast } from "components/ToastShow/ToastShow"
import { Textbox } from "components/Textbox/Textbox"
import { useEffect, useState } from "react"
import { trans } from "utils/helpers"
import { sendNode, getFirstNode, getSubNode } from "./api"
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect"


interface props {
    showForm: (e:boolean) => void
    selectedObj: any
    mode: any

}

interface PersonalalArchiveDetail  {
    RootId: string   // id  اولین سطر
    ParentId: string   // id سطر انتخابی
    Description: string
    Code: string
    Format: string
    LevelLimitation: number
    ActivityStatus: number 
}

export const ArchiveFolderForm = ({showForm, selectedObj, mode}: props) => {
    const [nodeData, setNodeData] = useState<any>()
    const [userChanges, setUserChanges] = useState({});


    const getNodeDataAsync = async() => {
        try {
            const res = await getSubNode(selectedObj.Id)
            setNodeData(res.Data)           
        } catch (error) {
        
        }
    }

    const sendNodeDataAsync = async(method , data) => {
        try {
            const res = await sendNode(data ,method )
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
            showForm(false);    
        } catch (error) {
            
        }
        
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        if(mode === 'create'){
            if( (selectedObj?.RootId == null) ){
                const data = {
                    RootId: selectedObj?.Id ,  
                    ParentId: null, 
                    Description: event.target.Description.value,
               //   Securitylevel : +event.target.Securitylevel.value
                }         
                sendNodeDataAsync( "post", data)
            }else{
                const data = {
                    RootId: selectedObj.RootId ,  
                    ParentId: selectedObj?.Id, 
                    Description: event.target.Description.value,
               //   Securitylevel : +event.target.Securitylevel.value
                }         
                sendNodeDataAsync( "post", data)
            }
        }else if (mode === 'edit'){
            const data = {
                Id: selectedObj.Id,
                ParentId: nodeData.ParentId, 
                RootId:  nodeData.RootId,  
                ActivityStatus: parseInt(event.target.ActivityStatus.value),  
                Description: event.target.Description.value,
        //        Securitylevel : +event.target.Securitylevel.value
            }
            sendNodeDataAsync("put" , data)
        }
    }  

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                showForm(false)
            });
        }else{
            showForm(false)     
        };


    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);
    
    useEffect(()=>{
        if(selectedObj && mode != "create"){
            getNodeDataAsync()
        }
    },[])


    return (
        <div className="card">
            <div className="card-body">
                <div className="container-fluid">
                    <form className="row" onSubmit={handleSubmit}>
                        <div className="col-lg-6 mt-1">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="text"
                                textboxName="Description"
                                labelText="Description"
                                maxLength={900}
                                mode={mode}
                                required={true}
                                defaultValue={mode === 'edit' || mode === 'view' ? nodeData &&  nodeData?.Title || nodeData?.Description : ''}
                            />
                        </div>        
                        {mode!="create" && 
                        <div className="col-lg-6 mt-1">
                            <EnumerationTypeSelect 
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                name="ActivityStatus"
                                label="ActivityStatus"
                                enumTypeSelect="ActivityStatus"
                                value={mode === 'edit' || mode === 'view' ? nodeData &&  nodeData?.ActivityStatus : ''}
                                mode={mode}
                            />
                        </div>                      
                        }                
                        {mode !=="view" &&
                            <div className="col-lg-12 mt-4 btns_form">
                                <Button value="Save" btnType="submit" />
                                <Button btnType="cancel" value="Cancel" onClick={()=>cancel()}/>
                            </div>
                        }
                    </form>
                </div>
            </div>

        </div>
    )
}