import { DefaultHttpClient } from '@microsoft/signalr';
import { auth } from 'utils/auth';




const getAuthHeaders = () => {
        
    const token = localStorage.getItem("token") 
    return {
      Authorization: "Bearer " +token
    };
  };
  
 export class signalRHttp extends DefaultHttpClient {
    constructor() {
      super(console); // the base class wants a signalR.ILogger
    }
    public async send( request: signalR.HttpRequest
    ): Promise<signalR.HttpResponse> {
      const token = localStorage.getItem("token")  
      let res = true
      if (token) {
        res =await auth.checkTokenExpire();
      } else {
        auth.attempt();
      }
      if (res) {
  
      } else {
        auth.attempt()
      }
      try {
        const authHeaders = getAuthHeaders();
        if(request != undefined){
          request.headers = { ...request.headers, ...authHeaders };
        }
        const response = await super.send(request);
        return response;
      } catch (er) {
        if (er) {
          const error = er as signalR.HttpError;
          if (error.statusCode == 401) {
  
            //token expired - trying a refresh via refresh token
           /*  await auth.attempt(); */
           /*  const authHeaders = getAuthHeaders(); */
           /*  request.headers = { ...request.headers, ...authHeaders }; */
          }
        } else {
          throw er;
        }
      }
      //re try the request
      return super.send(request);
    }
  }