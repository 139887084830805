import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loading } from "components/Loading/loading";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ICON_TYPE } from "utils/constants";
import { trans, useOutsideClick } from "utils/helpers";
import { getMenus } from "./api";
import { TopSidebar } from "./component/topSidebar/topSidebar";
import { MenuDTO } from "./types";
import "./style.scss";
import { Toast } from "components/ToastShow/ToastShow";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { REGEX } from "utils/regex";
import { ModalForm } from "components/ModalForm/ModalForm";
import { FilterReportForm } from "./component/FilterReportForm";


export const Sidebar = () => {
  const [idCurrentMenu, setIdCurrentMenu] = useState<any>({});
  const [sidebarMenu, setSidebarMenu] = useState<MenuDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const openSidebarRef = useRef(false);
  const [hasErr, setHasErr] = useState<boolean>(false);
  const [filterReportId, setFilterReportId] = useState<any>(null);
  const layoutContext = useContext(LayoutContext);    
  const lang = localStorage.getItem('lang')
  const touchstartX = useRef(0); 
  const touchendX = useRef(0);


  const [longPressTriggered, setLongPressTriggered] = useState<any>(false);
  const timeout = useRef<any>();
  const target = useRef<any>();
  const navigate = useNavigate();

  const clickedOutside = () => {
    setOpenSidebar(false)
 //   setIdCurrentMenu({})
  };

  const sidebarRef = useOutsideClick(clickedOutside);

  function checkDirection() {
    if ((touchendX.current < touchstartX.current-15) && (lang === 'fa')) {
      if( openSidebarRef.current){
        layoutContext.toggleSidebar();
        setOpenSidebar(false)
        openSidebarRef.current =false

      }else{
        setOpenSidebar(true)
        openSidebarRef.current =true


      }
    } else if ((touchendX.current > touchstartX.current+15) && (lang === 'en')) {
      if( openSidebarRef.current){
        layoutContext.toggleSidebar();
        setOpenSidebar(false)
        openSidebarRef.current =false

      }else{
        setOpenSidebar(true)
        openSidebarRef.current =true

      }
    }
  }


  document.addEventListener('touchend', e => {
    e.stopPropagation()
    touchendX.current = e.changedTouches[0].screenX
    checkDirection()
  })


  const handleIsOpenSubmenu =async (id: any) => {
    if(layoutContext.sidebarIsOpen){
      if (idCurrentMenu && idCurrentMenu[id] ) {
        let menu:any = JSON.parse(JSON.stringify(idCurrentMenu)) 
        menu[id] = false
        setIdCurrentMenu(menu);
      } else {
        let menu:any = JSON.parse(JSON.stringify(idCurrentMenu)) 
        menu[id] = true
        setIdCurrentMenu(menu);
      }
    }else{
      let menu:any = {}
      menu[id] = true
      setIdCurrentMenu(menu);
    }
  };


  const onLongPress = (e ,item) => {
    e.stopPropagation()
    layoutContext.setDragObj(item)
  }
  const handleNavigate = (rout ,name="") => {
    
    var re = new RegExp(REGEX.Guid.regex)
    
   if(sessionStorage.getItem("changingforms")+""=="true"){
     Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
      if(re.test(rout.replaceAll("/",""))){
        setFilterReportId({rout:rout.replaceAll("/",""),name:name})

       //window.open(
       //  `${process.env.REACT_APP_REPORTING_SERVICE_URL+ "/ReportViewer/View/"}${rout.replaceAll("/","") }`,"_blank" );
     
        }else{
        navigate(rout);
        sessionStorage.setItem("changingforms" , "false");
        localStorage.removeItem("formsInChang")  

      }
    });
   }else{
    if(re.test(rout.replaceAll("/",""))){
      setFilterReportId({rout:rout.replaceAll("/",""),name:name})
      
      //window.open(
      //  `${process.env.REACT_APP_REPORTING_SERVICE_URL+ "/ReportViewer/View/"}${rout.replaceAll("/","") }`,"_blank" );
   
    }else{
      navigate(rout)

    }
   }
  }

  const start = useCallback(
    (event , item) => {
        if ( event.target) {
                event.target.addEventListener("onMouseUp", {
                passive: false
            });
            target.current = event.target;
        }
        timeout.current = setTimeout(() => {
            onLongPress(event ,item);
            setLongPressTriggered(true);
        }, 1000);
    },
    [onLongPress]
);


const clear = useCallback(
  (event, item, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);    
      setLongPressTriggered(false);
      if ( target.current) {
          target.current.removeEventListener("touchend" ,{
              passive: false
          });
      }
  },
  [ longPressTriggered]
);

  async function getDataAsync(ApplicationId) {
    setLoading(false)
    try {
      if(ApplicationId ){
        const response = await getMenus(ApplicationId);        
        setSidebarMenu(response.Data);
        setHasErr(false)
      }
    } catch (error) {
      setHasErr(true)
    }
    setLoading(true)
  }

  useEffect(() => {

    setIdCurrentMenu({}) 
  }, [openSidebar, layoutContext.sidebarIsOpen]);
  return (
    <section className={`section-menu  ${layoutContext.sidebarIsOpen && 'openSidebar'}`} style={{width:layoutContext.sidebarIsOpen? '20%' : '35px' }}>
            {
        filterReportId &&
          <ModalForm mode={"edit"} title={trans("Report")+" : " +filterReportId?.name}onHide={() => setFilterReportId(null)} show={filterReportId} modalHeight={60}>
              <FilterReportForm Id={filterReportId?.rout}  onHide={() => setFilterReportId(null)} />
          </ModalForm>
      }
      
      
      
      
      <div className={`menu ${layoutContext.sidebarIsOpen ? "openSidebar" : ""} ${openSidebar ? "swipeSidebar" : ""}`}
      ref={sidebarRef} >
     {layoutContext.currentUserPosition.PositionId &&
      <>
      <TopSidebar
      getDataAsync={getDataAsync}
      className={`${layoutContext.sidebarIsOpen ? "" : "hide_logo"}`}
      />
    
      {loading ? (
        sidebarMenu.length > 0 ?

          <>
            <ul className="sidebar">
              {!hasErr && sidebarMenu.map((parentItem,index) => (
                  <li
                    key={parentItem.Id}
                    className={`parent ${(idCurrentMenu && idCurrentMenu[parentItem.Id])  ? "activeMenu text-white" : ""}`}
                    {...(!layoutContext.sidebarIsOpen ? { onClick: (e) => {handleIsOpenSubmenu(parentItem.Id) } } : {})}
                  >
                    <div  className="parentIcon text-white 1">
                      {parentItem.IconName && (
                        <>
                          {parentItem.SubMenuList?.length ? (
                            <OverlayTrigger
                            key={"SubMenu"+parentItem.IconName}
                            placement="top"
                            delay={{"show" : 600 , "hide":0}}
                            overlay={
                                <Tooltip className="tooltip" id={`button-tooltip-${"SubMenu"+parentItem.IconName}`}>
                                    {trans( parentItem.Title)}
                                </Tooltip>
                            }
                            >
                             <span   tabIndex={-1}>
                              <FontAwesomeIcon
                                icon={[ ICON_TYPE, parentItem.IconName]}
                                style={{width:'15px',height:'15px'}}
                                className="mt-1"
                              />
                            </span> 
                            </OverlayTrigger> 
                          ) : (
                            <OverlayTrigger
                            key={"SubMenu"+parentItem.IconName}
                            placement="top"
                            delay={{"show" : 600 , "hide":0}}
                            overlay={
                              <Tooltip className="tooltip" id={`button-tooltip-${"SubMenu"+parentItem.IconName}`}>
                              {trans( parentItem.Title)}
                                </Tooltip>
                            }
                            >
                            <Link to={parentItem.Route }>
                              <span  onClick={()=>{ if(window.screen.width < 770){layoutContext.toggleSidebar()};  }} className="parentIcon-icon ">
                                <FontAwesomeIcon
                                  icon={[ICON_TYPE, parentItem.IconName]}
                                  style={{width:'15px',height:'15px'}}
                                  className="mt-1"
                                />
                              </span>
                            </Link>
                            </OverlayTrigger> 
                          )}
                        </>
                      )}
                    </div>

                    <div className="parentMenu" onClick={() => handleIsOpenSubmenu(parentItem.Id)}>
                      {parentItem.SubMenuList?.length ? (
                        <span className="">{trans( parentItem.Title)}</span>
                      ) : (
                        <Link  onDragStart={(e)=>{e.preventDefault();}}    onMouseUp={ e => clear(e,parentItem)}  onMouseDown={(e)=>start(e,{Route:parentItem.Route ,Name:parentItem.Title , Description :parentItem.Title, Id :parentItem.Title ,applicationId : layoutContext.currentApplication.Id,applicationName : layoutContext.currentApplication.Name })}onClick={(e)=>{e.preventDefault();handleNavigate(parentItem.Route ,parentItem.BriefTitle		)  }} to={parentItem.Route }>
                          <span className="">{trans( parentItem.Title)}</span>
                        </Link>
                      )}
                      <span tabIndex={-1} className={`${parentItem.SubMenuList?.length ? "showIcon" : "hideIcon"}`}>
                        <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} />
                      </span>
                    </div>
                      {(idCurrentMenu && idCurrentMenu[parentItem.Id])&&
                    <ul style={{top:(index+2)*35+10}} className={`${!layoutContext.sidebarIsOpen && parentItem.SubMenuList?.length != undefined && parentItem.SubMenuList?.length > 0  && `glassBox float-sidebar-menu    ${  (idCurrentMenu && idCurrentMenu[parentItem.Id] ) ? "active"  : ""}`}`}>
                      <div className="d-none float-sidebar-menu__title">{trans( parentItem.Title)}</div>
                      {( idCurrentMenu && idCurrentMenu[parentItem.Id] && parentItem.SubMenuList)
                        ? <>{
                        parentItem.SubMenuList.map((SubMenuItem, indx) => (
                          <li onDragStart={(e)=>{e.preventDefault();}} key={SubMenuItem.Id}    onMouseUp={ e => clear(e,parentItem)}  onMouseDown={(e)=>start(e,{Route:`/${parentItem.Route}/${SubMenuItem.Route}` ,Name:SubMenuItem.Title , Description :SubMenuItem.Title, Id :SubMenuItem.Title ,applicationId : layoutContext.currentApplication.Id,applicationName : layoutContext.currentApplication.Name })} >
                            {parentItem.Route ? (
                                <Link onClick={(e)=>{e.preventDefault();if(!layoutContext.sidebarIsOpen){e.stopPropagation();setIdCurrentMenu(null)};handleNavigate(`/${parentItem.Route}/${SubMenuItem.Route}` , SubMenuItem.Title	);if(window.screen.width < 950){layoutContext.toggleSidebar();setOpenSidebar(false)}}}
                                  to={`/${parentItem.Route}/${SubMenuItem.Route}`}>
                                  {trans( SubMenuItem.Title)}
                                </Link>
                            ) : (
                                <Link  onClick={(e)=>{e.stopPropagation();e.preventDefault();handleNavigate(`/${SubMenuItem.Route}` , SubMenuItem.Title	); if(window.screen.width < 950){layoutContext.toggleSidebar();setOpenSidebar(false)}  }} 
                                to={`/${SubMenuItem.Route}`}>
                                  {" "}
                                  {trans( SubMenuItem.Title)}
                                </Link>
                            )}
                          </li>
                        ))}</>
                        : <></>}
                    </ul>
                      
                      }
                  </li>
                
              ))}
            </ul>
          </>
          : <></>
      ) : (
        <Loading />
      )}
    </>
     } 
     </div>
    </section>
  );
};
