import { Button } from "components/Button/Button"
import { Textbox } from "components/Textbox/Textbox"
import { Time } from "components/Time/Time"
import { Toast } from "components/ToastShow/ToastShow"
import { useEffect, useState } from "react"
import { CalendarType } from "types/enum"
import { trans } from "utils/helpers"
import { sendHolidaysApi } from "../../api"

interface Props {
    selectedDays: any[],
    year:any ,
    month:any ,
    sendEventMessageUp: (eventData: {dayId: number, eventMessage: string, id: number}[]) => void
    setShowEventForm: React.Dispatch<React.SetStateAction<boolean>>
    sendFormSubmissionUp: (submitted: boolean) => void
    setSelectedDays: React.Dispatch<React.SetStateAction<number[]>>
}

export const EventForm = ({year , month ,    selectedDays, sendEventMessageUp, setShowEventForm, sendFormSubmissionUp, setSelectedDays}:Props) => {

    const [days, setDays] = useState<any>([]);
    const [userChanges, setUserChanges] = useState({});


    async function sendNodeAsync(data ,method) {
        try{
           const res = await sendHolidaysApi(data , method);

  
        }catch(err:any){

          }
        }

    const onSubmit = (e) => {
        e.preventDefault()
        const data = e.target.eventMessage.value
        const id = Math.floor(Math.random() * 100000)
        if(!data.length){
            Toast(trans("Msg_EnterMessage"), "warning")
            return
        } 
        const eventData = selectedDays.map(num => ({
            CalendarEventType:0,
            dayId: num.day,
            eventMessage: data,
            id
        })) 

        let lang = localStorage.getItem('lang')=="fa" ? "Solar" :"Lunar" 
  
        const eventDataSend = selectedDays.map(num => ({
            MinuteNo:+e.target.Time[0].value % 60,
            HourNo: (+e.target.Time[0].value-(+e.target.Time[0].value % 60))/60,
             YearNo:  num.Year ,
             MonthNo:num.month,
             DayNo:  num.day ,
             Description: e.target.eventMessage.value ,
             CalendarType: +CalendarType[lang] ,
             CalendarEventType:0,
        }))

        eventDataSend.forEach(item=>{
            sendNodeAsync(item,"post")
        })
        sendEventMessageUp(eventData)
        sendFormSubmissionUp(true)
        sessionStorage.setItem("changingforms" , "false");
        setUserChanges({});
        setSelectedDays([])
        setShowEventForm(false)
    }


    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        let dayStr = selectedDays.map(item=>{
          return `${item.Year}/${item.month}/${item.day}`
        })
        
        setDays(dayStr)
  
      }, []);
  
    return (
        <form onSubmit={onSubmit}className="card" >
            <div className="card-body">
                <div className="row">
                    <div className={`col-${selectedDays.length+1} mt-3`}>
                        <Textbox
                            mode={"view"}
                            textboxName="Day" 
                            textboxType="text" 
                            labelText="Day" 
                            defaultValue={days.join(" , ")}
                        />
                    </div>
                    <div className="col-lg-4 col-sm-5 mt-3">
                       <Time    
                            type={"dateTime"}
                            mode={"create"}
                            name="Time"
                            IdString="Time"
                            label="Time"
                        />
                    </div>
                </div>
                <div className="row">
                 <div className="col-lg-8 mt-1">
                     <Textbox
                     Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                      required={true}
                      textboxType="textarea"
                      textboxName="eventMessage"
                      labelText="Description"
                      maxLength={900}
                      defaultValue={""}
                      mode={"create"}
                     />
                 </div>
                </div>
                <div className="col-12 d-flex flex-row-reverse">
                    <Button btnType="submit" value={"submit"} />
                </div>
            </div>
        </form>
    )
}