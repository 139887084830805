import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getArchiveData = () => http({
    url: ENDPOINTS.archive.personalArchive.dictionary,
    method: 'get',
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
})


export const postArchiveData = (data) => http({
    url: ENDPOINTS.archive.personalArchive.browse,
    method: 'post',
    data: data
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
})


export const putArchiveData = (data) => http({
    url: ENDPOINTS.archive.personalArchive.browse,
    method: 'put',
    data: data
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
})



export const getArchiveDataWithId = (id) => http({
    url: ENDPOINTS.archive.personalArchive.getId,
    method: 'get',
    params:{
        Id:id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
})



