import { trans } from 'utils/helpers';
import { textData } from '../../data/imgData';
import { GroupingPrice } from 'components/GroupingPrice/GroupingPrice';


interface increaseDecreaseProps {
    object:any,
    checkBoxHandle:(id:string)=>void,
    onHide:()=>void,
}

export const IncreaserDecreaser = ({object,checkBoxHandle,onHide}:increaseDecreaseProps) => {
    return ( 
        <>
        <div className='d-flex justify-content-between align-items-center p-2 userSelectNone'>
            <div className='fw-bold font-size-xl'>{object.title}</div>
            <div className='font-size-lg text-start' ><GroupingPrice value={object.price}/></div>
        </div>
        <div className='border border-muted p-1 m-1' style={{borderRadius:'3px'}}>
            <p className='text-muted p-1 lh-lg'>{object.discription}</p>
        </div>
        <div className='text-start mt-2'>
            <button className="btn btn-sm btn-success mx-1" onClick={()=>checkBoxHandle(object)}>{trans('add')}</button>
            <button onClick={onHide} className="btn btn-sm btn-outline-danger mx-1">{trans('cancel')}</button>
        </div>
        </>
     );
}
