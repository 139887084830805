import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getBasketData, trans } from "utils/helpers";
import { Cart } from "./components/Cart";
import "./style.scss";

export const LeftSidebar = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [priceInfo, setPriceInfo] = useState<any>({
    itemsPrice: 0,
    cartSubtotal: 0,
  });
  const [basketData, setBasketData] = useState<any>([]);
  const [countBadge, setCountBadge] = useState<any>();
  const [showCartsubtotal, setShowCartsubtotal] = useState<boolean>(false);
  const [cardStyle, setCardStyle] = useState<boolean>(true);
  const {setEmptyBasket}=useContext(LayoutContext)

  const layoutContext = useContext(LayoutContext);
  const settingMenuBtn = useRef<any>();

  const navigate = useNavigate();

  const handleNavigate = (rout) => {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        navigate(rout);
        sessionStorage.setItem("changingforms", "false");
        localStorage.removeItem("formsInChang");
      });
    } else {
      navigate(rout);
    }
  };

  function clickToclose(e) {
    if (
      (typeof e.target?.className == "string" &&
        e.target?.className?.includes("fullscreen")) ||
      !settingMenuBtn.current?.contains(e.target)
    ) {
      setShow(false);
    }
  }

  const priceUpdate = () => {
    let newItemsPrice = 0;
    let newCartSubtotal = 0;
    let basketData = getBasketData();
    if(!!basketData.length){
      setEmptyBasket&& setEmptyBasket(false)
    }else{
      setEmptyBasket&& setEmptyBasket(true)
    }
    setLoading(false);
    setBasketData(basketData);

    basketData.map((items) => {
      Object.values(items.order).map((orders: any) => {
        newItemsPrice =
          newItemsPrice +
          +items?.ItemPriceListDetails[0]?.PriceDetails[orders.priceIndex]
            ?.SalesUnitPrice *
            +orders.count;
        newCartSubtotal =
          newCartSubtotal +
          (items?.ItemPriceListDetails[0]?.PriceDetails[orders.priceIndex]
            ?.SalesUnitDiscount != null
            ? +items?.ItemPriceListDetails[0]?.PriceDetails[orders.priceIndex]
                ?.SalesUnitPrice -
              +items?.ItemPriceListDetails[0]?.PriceDetails[orders.priceIndex]
                ?.SalesUnitDiscount
            : +items?.ItemPriceListDetails[0]?.PriceDetails[orders.priceIndex]
                ?.SalesUnitPrice) *
            +orders.count;
        setPriceInfo({
          itemsPrice: newItemsPrice,
          cartSubtotal: newCartSubtotal,
        });
      });
    });

    if (basketData == undefined || Object.keys(basketData).length == 0) {
      setShowCartsubtotal(false);
      setCountBadge(null);
    } else {
      setShowCartsubtotal(true);
    }
    setPriceInfo({ itemsPrice: newItemsPrice, cartSubtotal: newCartSubtotal });
    setLoading(true);
  };

  useEffect(() => {
    if (show) {
      priceUpdate();
    } else {
      setBasketData([]);
    }
  }, [show]);

  useEffect(() => {
    let badge = 0;
    let basketData = getBasketData();

    // change ?

    if (basketData && basketData.length > 0) {
      {
        basketData.map((item, index) => {
          if (item?.order) {
            Object.values(item?.order).map((e: any) => {
              badge = badge + 1;
            });
          }
        });
      }
      setCountBadge(badge);
    } else {
      setCountBadge(null);
    }
    if (show) {
      priceUpdate();
    }
  }, [layoutContext.basket]);

  useEffect(() => {
    if (show) {
      document.body.addEventListener("click", clickToclose, true);
    }
    return () => {
      document.body.removeEventListener("click", clickToclose, true);
    };
  }, [show]);

  return (
    <div className="buyBtn border-0 userSelectNone ">
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 300, hide: 0 }}
        overlay={
          <Tooltip
            className="tooltip"
            id={`button-tooltip-${"basket-shopping"}`}
          >
            {trans("ShoppingCart")}
          </Tooltip>
        }
      >
        <button
          className="buyBtn border-0 position-relative p-0"
          onClick={(e) => {
            e.preventDefault();
            setShow(true);
            handleNavigate("/e-commerce/Items");
          }}
        >
          <FontAwesomeIcon
            icon={["fas", "basket-shopping"]}
            style={{ color: "gray" }}
          />
          {countBadge > 0 && (
            <span className="badgeCartable bg-warning" style={{ right: "9px" }}>
              {countBadge > 99 ? "99+" : countBadge}
            </span>
          )}
        </button>
      </OverlayTrigger>

      {/* d-flex justify-content-between align-items-center  */}
      {show && (
        <>
          <div className="fullscreen-bg-modal" />
          <div
            className="d-flex flex-column justify-content-stretch align-items-center position-absolute top-0 start-0 buyBtn__listItem"
            ref={settingMenuBtn}
          >
            <Cart
              showCardStyle={true}
              setShow={setShow}
              loading={loading}
              basketData={basketData}
              showCartsubtotal={showCartsubtotal}
              priceInfo={priceInfo}
              priceUpdate={priceUpdate}
              withClose={true}
              clearBasket={()=>{
                Toast(trans("Msg_ConfirmClearShoppingCart"), "warning", "alertWithoutClose", () => {
                  localStorage.removeItem('Basket');
                   setBasketData([])
                   setEmptyBasket &&setEmptyBasket(true)
                   
                });
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
