import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { deletePrice, getPriceAccess, getPriceList, sendPriceAccess } from "./api";
import { PriceListAccess } from "./components/PriceListAccess";
import { PriceListDetail } from "./components/PriceListDetail/PriceListDetail";
import { PriceListForm } from "./components/PriceListForm";


export const PriceList = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showForm, setShowForm] = useState(false);
    const [mode, setMode] = useState<string | any>();
    const [selectedObj, setSelectedObj] = useState<object | any>(null);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [response, setResponse] = useState<Response | any>(null);
    const [messageType, setMessageType] = useState<any>("info");
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pages, setPages] = useState<any>(0)
    const [orderFormat, setOrderFormat] = useState<any>([]);
    const [filterlist, setFilterlist] = useState<any>("");
    const [search, setSearch] = useState(null);
    const [showExtensionForm, setShowExtensionForm] = useState<any>(null);
    const [showAccess, setShowAccess] = useState<any>(false);
    const [showPriceListDetail, setShowPriceListDetail] = useState<any>(false);
    const [accessDataList, setAccessDataList] = useState<any>();
    let customFuction: any = [[(e) => { setSelectedObj(e); setShowExtensionForm(true);setMode("edit")}, "calendar", "PostponeExpirationDate"]]

 
    async function accessDatahandler(data) {
        const lang = localStorage.getItem("lang");

        try {
          const accessList = data.map(item=>{
            item.OwnerId = selectedObj.Id 
              return item
          })
          await sendPriceAccess(accessList , selectedObj.Id); 
          setAccessDataList(null)
          setShowAccess(false)
        
      } catch (error) {
        
      }
      document.body.classList.remove("loading-indicator-"+lang);

    }
    async function getaccessListAsync() {
        try {
            const accessList = await getPriceAccess(selectedObj.Id ); 
            setAccessDataList(accessList.Data)
            setShowAccess(true)
        } catch (error) {
          
        }
    
      }
    const selectedIdCallback = (obj) => {
                
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false")});
        }else{
            setSelectedObj(obj)         
        };
    }

    const handleShowForm = (e) => {
        if(e == true)
            setForceUpdate(!forceUpdate)
        setShowForm(false)
    }

    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getPriceListAsync(pageNum + 1);
        }
    };

    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getPriceListAsync(pageNum - 1);
          }
    };
    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(+e.currentTarget.value);
        getPageSize("PriceList",e.currentTarget.value)
        getPriceListAsync(1)
    };

    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else { setPageNum(+value); getPriceListAsync(+value); }
        } else {
        }
    };
    
    const getWithOrder = (order: string[]) => {
        setOrderFormat(order)
    }

    const searchHandler = (str) => {
        setPageNum(1);
        if (str == "") {
            setSearch(null)
        } else {
            setSearch(str)
        }
    }
    async function handleDeleteRow() {
        try{
           await deletePrice(selectedObj.Id);
           setForceUpdate(!forceUpdate)
        }catch(err:any){

        }
    }

    const handleViewRow = () => {
        setMode("view"); 
        setShowForm(true);
    }
    const handleEditRow =  () => {
        setMode("edit");
         setShowForm(true);
    }
    async function getPriceListAsync(PageNum = pageNum) {
        setLoading(false);
        setResponse(null);
        let pageSizeChangedByUser = getPageSize("PriceList");
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getPriceList(PageNum, pageSizeChangedByUser , orderFormat?.join(","), search, filterlist);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1)
            setResponse(res.Data);
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }


    

    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print"); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("PriceList"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": setMode("create"); setShowForm(true); break;
            case "copy":if (selectedObj) setMode("copy"); setShowForm(true); break;
            case "view": if (selectedObj) handleViewRow(); break;
            case "edit": if (selectedObj) handleEditRow(); break;
            case "price": if (!selectedObj.IsManual) setShowPriceListDetail(true);else{Toast("Msg_CannotSetPriceForManualPriceList" ,"warning" )}; break;
            case "remove": if (selectedObj) Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "help": alert("call api help"); break;
            case "assignrule": if (selectedObj){if(!selectedObj.IsPublic){ setMode("edit"); getaccessListAsync()}else{ Toast("Msg_CanNotSetAccessForPublicPriceList" ,"warning" )}}; break;

        }
    };
    useEffect(() => {
        getPriceListAsync();
    }, [forceUpdate, search, orderFormat, filterlist]);

    return (
        <>
            { showAccess &&
              <ModalForm mode={mode}   modalProfileKey={"Users"}   title={trans("Users")+" : " +selectedObj.Title } onHide={() => setShowAccess(false)} show={showAccess}>
                  <PriceListAccess
                    showForm={setShowAccess} 
                    accessDatahandler={accessDatahandler} 
                    accessData={accessDataList}
                    mode={mode}
                  />
              </ModalForm>
            }
            { showPriceListDetail &&
              <ModalForm mode={mode} title={trans("PriceListDetail")+(mode!="create" ?" : " +selectedObj.Title:"") } onHide={() => setShowPriceListDetail(false)} show={showPriceListDetail}>
                  <PriceListDetail
                    PriceListObj={selectedObj}
                  />
              </ModalForm>
            }

            <Toolbar price={true} copy={true} assignRule={true}  emptyList={response?.TotalRecords == 0}   handleSearch={searchHandler} id={selectedObj} view={true} create={true} remove={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
            <div >
                <div className="row">
                    <div className="col-12">
                        {showForm &&
                        <ModalForm modalHeight={60} mode={mode} title={trans("PriceList") +(mode!="create" ?" : " +selectedObj.Title:"")} onHide={() => setShowForm(false)} show={showForm}>
                        <div className="">
                            <PriceListForm    showForm={handleShowForm} selectedObj={selectedObj} mode={mode} />
                        </div>
                        </ModalForm>
}              
      </div>
                </div>
                <div className="card" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                    <div className="p-0">
                        {loading ?
                            response?.Data?.length > 0 ?
                                <DataGrid
                                    handlefiltering={(str) => {
                                        setFilterlist(str);
                                    }}
                                    filterStr={filterlist}
                                    orderFormat={getWithOrder}
                                    orderFormatList={orderFormat}
                                    parentName={"BulletinPriceList"}
                                    
                                    startIndex={1}
                                    theadData={Object.keys(response.Data[0])}
                                    tbodyData={response.Data}
                                    minCellWidth={80}
                                    selectedRowObjectCallback={selectedIdCallback}
                                    totalRecords={response.TotalRecords}
                                    pageNum={pageNum}
                                    pageSize={pageSize}
                                    handleEdit={handleEditRow}
                                    handleView={handleViewRow}
                                    handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow)}}
                                    HandleNextPage={handleNextPage}
                                    HandlePrevPage={handlePrevPage}
                                    handlechangePageSize={handleChangePageSize}
                                    first={() => { getPriceListAsync(pages); setPageNum(pages) }}
                                    end={() => { getPriceListAsync(1); setPageNum(1) }}
                                    handleGoPage={handleGoPage}
                                /> : <Message message={"NoDataForPreview"} type={messageType} />
                            :
                            <Loading />}
                    </div>
                </div>

            </div>
        </>

    )
}