import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import user from "assets/image/user-transformed.png";
import { ModalForm } from 'components/ModalForm/ModalForm';
import { Toast } from 'components/ToastShow/ToastShow';
import { LayoutContext } from 'layouts/DashboardLayout/context/LayoutContext';
import { PersonSignaturePage } from 'pages/OfficeAutomation/BaseInformation/PersonSignature/PersonSignature';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileDTO, ProfileListItemDTO } from "types/types";
import { callConnection } from 'utils/callHub';
import { ChatConnection } from 'utils/chatHub';
import { ICON_TYPE } from 'utils/constants';
import { trans } from 'utils/helpers';
import { NotificationConnection } from 'utils/notificationHub';
import { getCurrentNotSeenCountDictionary, getProfile } from './api';
import { ChangePasswordForm } from './components/ChangePasswordForm';
import { ContactData } from './components/ContactData';
import { SubstituteForm } from './components/SubstituteForm';
import { UserInfoForm } from './components/UserInfoForm';
import './style.scss';
import { OrganizationType } from 'types/enum';


export const Profile = () => {
  let layoutContext = useContext(LayoutContext);
  const profileBtnRef = useRef(null);
  const [showProfile, setShowProfile] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [showSubstituteForm, setShowSubstituteForm] = useState(false);
  const [showPersonSignature, setShowPersonSignature] = useState(false);
  const [profileOptions, setProfileOptions] = useState<ProfileListItemDTO[]>([]);
  const [person, setPerson] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [currentProfileOption, setCurrentProfileOption] = useState<any>();
  const [connection, setConnection] = useState<any>(null);
  const [NotificationConnectionState, setNotificationConnectionState] = useState<any>(null);
  const [callConnectionState, setcallConnectionState] = useState<any>(null);
  const [userInfoForm, setUserInfoForm] = useState<any>(false);
  const [newReference, setNewReference] = useState<any>(false);
  const [callerNum, setCallerNum] = useState<any>();
  const [showDataForm, setShowDataForm] = useState<any>();
  const [activeIndex,setActiveIndex] = useState<any>()
  const activeIndexRef = useRef<any>(0);
  const userNameRef = useRef<any>(null);
  userNameRef.current = layoutContext.currentUserPosition.PositionId
  const currentProfileRef = useRef<any>(currentProfileOption);
  const profileOptionsRef = useRef<any>(profileOptions);
  const notificountRef = useRef<any>(layoutContext.notificount);
  
  const userInTab = useRef<any>(null);
  const tabId = useRef<any>(0);
  const profile  = localStorage.getItem("profile")+"" 
  let navigate = useNavigate();


 async function updatePositionsNotSeenCount(){
      try {
        console.log("currentProfileOption?.OrganizationType" ,currentProfileOption?.OrganizationType)
        if(currentProfileOption?.OrganizationType!=OrganizationType.Customers){
          const res = await getCurrentNotSeenCountDictionary()
          let newList =profileOptionsRef.current?.map((item, index)=>{
              let profileOption:any
              res.map(countList=>{
              if(item.PositionId ==countList.PositionId ){
                if(countList.NotSeenCount >0 && item.PositionId != currentProfileRef.current.PositionId ){
                  setNewReference(true)
                  profileOption = {...item,newReference:countList.NotSeenCount};
                }else{
                  profileOption = item
                }
              }
            })
            return profileOption ?profileOption:item
          })
            setProfileOptions(newList)
        }
      } catch (error) {
        
      }
  }



  function handleOptionClicked(option: ProfileListItemDTO){
    sessionStorage.setItem("accept-data", option.PositionId);
    setCurrentProfileOption(option);
    currentProfileRef.current = option
    layoutContext.setCurrentUserPosition(option);
    localStorage.removeItem("CurrentApplication");
    if(profileBtnRef.current !== null)
    (profileBtnRef.current as HTMLButtonElement).blur();
    setShowProfile(false);

    navigate("/cartable/current");
    setNewReference(false)

    updatePositionsNotSeenCount()
  }
  const delay = (duration) =>
    new Promise(resolve => setTimeout(resolve, duration));

  function clearSessionStorage() {
    sessionStorage.clear();
    localStorage.setItem('clearSessionStorage', Date.now()+"");
}

window.addEventListener('storage', (event) => {
    if (event.key === 'clearSessionStorage') {
        sessionStorage.clear();
        window.location.href = "/"
    }
});
  async function handleExit() {
    document.cookie = "logedIn=false"
    const Idtoken = localStorage.getItem("id_token")
    sessionStorage.removeItem("accept-data");
    localStorage.removeItem("token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("token_expire");
    localStorage.removeItem("CurrentApplication");
    localStorage.removeItem("tabs");

    try {
      const openedWindow = window.open(`${process.env.REACT_APP_AUTH_URL}/endsession?id_token_hint=${Idtoken}&post_logout_redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URL}`);
  

     if(!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') 
      { 
        window.location.href = "/"
        
      }else{
        clearSessionStorage()
        await delay(250)
        openedWindow.close();
        window.location.href = "/"
      }
    } catch (error) {

    }
  }

  async function getProfileAsync() {
    setLoading(false)
    try {
      const res: ProfileDTO = await getProfile();
      setPerson(res)
      setProfileOptions(res.ProfileList);
      profileOptionsRef.current = res.ProfileList
      layoutContext.setUserPerson({ PersonId: res.ProfileList[0].PersonId, UserId: res.ProfileList[0].UserId })
      if (layoutContext?.currentUserPosition?.PositionId?.length > 0) {
        setCurrentProfileOption(layoutContext.currentUserPosition);
        currentProfileRef.current =layoutContext.currentUserPosition
        sessionStorage.setItem("accept-data", layoutContext.currentUserPosition.PositionId);
        if(layoutContext.currentUserPosition?.OrganizationType!=OrganizationType.Customers)
          await updatePositionsNotSeenCount()
      }else{
        if (res.ProfileList.length > 0){
          setCurrentProfileOption(res.ProfileList[0]);
          currentProfileRef.current =res.ProfileList[0]
          layoutContext.setCurrentUserPosition(res.ProfileList[0]);
          sessionStorage.setItem("accept-data", res.ProfileList[0].PositionId);
        }
        if(res.ProfileList[0]?.OrganizationType!=OrganizationType.Customers)
            await updatePositionsNotSeenCount()
      }
    } catch (err) {
      //setProfileOptions([])
    }
    setLoading(true)

  }
  const local = {
    help: "F1",
    edit: "F2",
    view: "F3",
    create: "F4",
    refresh: "F5",
    delete: "Delete",
    print: "Ctrl+p",
    excel: "Ctrl+e",
    reference: "F7",
    reverse: "F8",
    finish: "F9",
    Attach: "F10",
    reminder: "F11",
    action: "F6",
    report: "Ctrl+r",
    history: "Ctrl+h",
    chat: "Ctrl+g",
    stop: "Ctrl+q",
    info: "Ctrl+i",
    

  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  const onFocus = () => {
    userInTab.current = true
};

 const onBlur = () => {
   userInTab.current = false
};



  const isFocused = () =>{ 
    return typeof document.hidden !== `undefined` ? !document.hidden : null
}

  function notifyMe(title, mas , onClick) {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
        let tabs = JSON.parse(localStorage.getItem('tabs')|| "");
        var index = tabs.indexOf(tabId.current);
        if (index == 0){
          const notification = new Notification(title , {body:mas});
          notification.onclick =onClick
      }

    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission]
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          

            let tabs = JSON.parse(localStorage.getItem('tabs')|| "[]");
            var index = tabs.indexOf(tabId.current);
            if (index == 0){
              const notification = new Notification(title , {body:mas});
              notification.onclick =onClick
            }
        }
      });
    }
  }

  const testSpeed = async () => {
    const url = "https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_1280.jpg"; // Replace with a real file
    const startTime = new Date().getTime();
    
    try {
      await fetch(url);
      const endTime = new Date().getTime();
      const duration = (endTime - startTime) / 1000; // seconds
      const fileSize = 516; // File size in kilobytes (update accordingly)
      const speedMbps = ((fileSize * 8) / duration / 1024).toFixed(2); // Mbps
      console.error("speedMbps", speedMbps);
      return +speedMbps
    } catch (error) {
      console.error("Error testing speed:", error);
      return 60
    }
  };

  useEffect(() => {
    getProfileAsync()
    
    if (localStorage.Shortcut == null || localStorage.Shortcut == undefined || Object.keys(localStorage.Shortcut).length !=Object.keys(local).length  ) {
      localStorage.setItem("Shortcut", JSON.stringify(local));
    }
    
    if(localStorage.getItem("formsInChang")!=null && (window.location.hash =="#/"||window.location.hash =="/"||window.location.hash =="")){
      let forms = JSON.parse(localStorage.getItem("formsInChang")+"") 
      Object.keys(forms).forEach(item=>{
        Toast(`در ${trans(item) } دیتای درحال تغییر وجود دارد` ,"warning")

      })

    }
    sessionStorage.setItem("changingforms" , "false")
  }, []);

  useEffect(() => {
  }, [currentProfileOption]);
  useEffect(() => {
    if (layoutContext.currentUserPosition.PositionId) {
      setCurrentProfileOption(layoutContext.currentUserPosition)
      currentProfileRef.current = layoutContext.currentUserPosition

    }

  }, [layoutContext]);




  useEffect(() => {
     tabId.current = Math.random();
    let tabs = JSON.parse(localStorage.getItem('tabs') || "[]");
    tabs.unshift(tabId.current);
    if(tabs.length > 10){
      tabs.splice(7,tabs.length-7);
    }
    localStorage.setItem('tabs', JSON.stringify(tabs));
     window.addEventListener('beforeunload', async function(e)
     {
       // e.preventDefault()
        tabs = JSON.parse(localStorage.getItem('tabs')|| "[]");
        let index = tabs.indexOf(tabId.current);
        if (index != -1)
           tabs.splice(index, 1);
        if(tabs.length == 0){
           localStorage.setItem('tabs', JSON.stringify(tabs));
        // await handleExit()
          return "null"
            }else{
          localStorage.setItem('tabs', JSON.stringify(tabs));
          return "null"

          }

     });
     
    setConnection(ChatConnection);
    setNotificationConnectionState(NotificationConnection);
    setcallConnectionState(callConnection);
    
  }, []);

  useEffect(() => {
    notificountRef.current =layoutContext.notificount ;
  }, [layoutContext.notificount]);


  useEffect(() => {
    if (connection ) {
      connection
        .start()
        .then((result) => {
          connection.on('SendMessage', (messageItem) => {
           //ChatConnection.invoke("SetMessagesAsDeliverd", [messageItem.id]);
            if (messageItem.chatMessage.senderId&& (messageItem.chatMessage.senderId!= userNameRef.current.Id) ) {
              if(!window.location.href.includes("message")||!isFocused()){
                layoutContext.setChatNotificount(true )
                const savedNotifSettings = JSON.parse(localStorage.getItem('NotifSettingList') as string) 
                if((savedNotifSettings==null)||!savedNotifSettings.includes("chat")){
                  notifyMe(messageItem.sendUser ,messageItem.chatMessage.message , ()=>{
                    let item = {
                     Id:messageItem.chatMessage.senderId,
                     name: messageItem.sendUser
                   }
                   localStorage.setItem("JobRequester",JSON.stringify(item) );
                   navigate("/message", { replace: true });
                  })
                }
               }
            }
          });
/*           connection.disconneted(function() {
            connection.log('Connection closed. Retrying...');
            alert("'Connection closed. Retrying...'")
            //setTimeout(function() { connection.start(); }, 5000);
         }); */
          connection.on('InformAsync', async (method, message) => {  
            try {
              if (method.includes("HasUnread") ) {
                  if(message !="False"){
                    layoutContext.setChatNotificount(true )
                  }else{
                    layoutContext.setChatNotificount(false )

                  }
                }
            } catch (error) {
            }
        });

      })
      .catch((e) => console.log('Connection chattt failed: ', e));
    }

  }, [connection]);

  const arrowInMenu = (event)=>{
    event.stopPropagation()
    if(event.key == "ArrowDown"){
      if(activeIndexRef.current > 0){
        activeIndexRef.current = activeIndexRef.current - 1;
      }else{
        activeIndexRef.current = profileOptions?.length + 4;
      }
    }
    if(event.key == "ArrowUp"){
      if(activeIndexRef.current +1 > profileOptions?.length + 4){
        activeIndexRef.current = 0;
      }else{
        activeIndexRef.current = activeIndexRef.current + 1;
      }
    }
    setActiveIndex(activeIndexRef.current)
    if(event.key == "Enter"){
      if(activeIndexRef.current == 0) handleExit();
      if(activeIndexRef.current == 1) setShowPersonSignature(true);
      if(activeIndexRef.current == 2) setUserInfoForm(true);
      if(activeIndexRef.current == 3) setShowPasswordForm(true);
      if(activeIndexRef.current == 4) setShowSubstituteForm(true);
      if(activeIndexRef.current > 4) handleOptionClicked(profileOptions[Math.abs(activeIndexRef.current-(profileOptions?.length + 4))])
    }
  }


  useEffect(()=>{
    if(showProfile == true){
      if(!activeIndexRef.current){
        activeIndexRef.current = profileOptions?.length + 4
        setActiveIndex(activeIndexRef.current)
      }
      document.addEventListener("keydown",arrowInMenu,true)
    }
    return () =>{document.removeEventListener("keydown",arrowInMenu,true)}
  },[showProfile])


  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    onFocus();
    return () => {
        window.removeEventListener("focus", onFocus);
        window.removeEventListener("blur", onBlur);
    };
}, []);


  useEffect(() => {
    if (NotificationConnectionState ) {
            NotificationConnectionState.on('SendNewCartableNotification',(jobId ,  PRJ_UID,refrenceId , positionId , massage) => {
            let positionHaveReference:any = {}
            profileOptionsRef.current?.forEach(item=>{
              if(item.PositionId == positionId){
                positionHaveReference = item
              }
            })
            const savedNotifSettings = JSON.parse(localStorage.getItem('NotifSettingList') as string) 
            if((savedNotifSettings==null)||!savedNotifSettings.includes("cartable")){
              if(currentProfileRef.current.PositionId ==positionHaveReference.PositionId ){
                notifyMe(positionHaveReference?.Description ,massage ,()=>{})
                layoutContext.setNotificount(+notificountRef.current + 1 )
              }else{
                notifyMe(positionHaveReference?.Description ,massage ,()=>{})
              /*   let newList =profileOptionsRef.current?.map(item=>{
                  if(item.PositionId ==positionHaveReference.PositionId ){
                    setNewReference(true)
                    return({...item,newReference:true})
                  }else{
                    return item
                  }
                })
                setProfileOptions(newList) */
                updatePositionsNotSeenCount()
              }
              }
          });
    }
  }, [NotificationConnectionState]);
  useEffect(() => {
    if (callConnectionState ) {
      callConnectionState.on('ReceiveCallNotification',(item) => {
        const obj = JSON.parse(item)
        const savedNotifSettings = JSON.parse(localStorage.getItem('NotifSettingList') as string) 
        if((savedNotifSettings==null)||!savedNotifSettings.includes("call")){
          if(obj.CallerNumber.includes("unknown")){
            notifyMe(obj.CallerNumber , ("Call_"+obj.Status).toLocaleLowerCase(),()=>{setCallerNum(obj.CallerNumber); setShowDataForm(true)})
          }else{
            notifyMe(obj.CallerNumber+" "+obj.CallerName , ("Call_"+obj.Status).toLocaleLowerCase(),()=>{setCallerNum(obj.CallerNumber); setShowDataForm(true)})
          }
        }
      });
    }
  }, [callConnectionState]);


  useEffect(() => {
    if (callConnectionState && process.env.REACT_APP_ENABLE_CALLCONNECTION == "true") {
      callConnectionState
      .start()
      .then((result) => {
      })
      .catch((e) => console.log('Connection failed callConnectionState: ', e));
    }
  }, [callConnectionState]);
  useEffect(() => {
    if (NotificationConnectionState ) {
      NotificationConnectionState
        .start()
        .then((result) => {
          NotificationConnectionState.on('NewEvent',(text) => {  
            if(text=="BulletinBoard") {

              notifyMe( trans("Board") , trans("NewBulletinBoardNotification"),()=>{})
            }  
      });
        })
        .catch((e) => console.log('Connection failed: ', e));
    }
  }, [NotificationConnectionState]);

  return (
    <>

      {userInfoForm &&
        <ModalForm title={'editUserInfo'} onHide={() => { setUserInfoForm(false) }} show={userInfoForm}>
          <UserInfoForm Id={""} hide={() => { setUserInfoForm(false) }} mode={"edit"} />
        </ModalForm>

      }
      {showPasswordForm &&
        <ModalForm title={'ChangePasswordForm'} onHide={() => { setShowPasswordForm(false) }} show={showPasswordForm}>
          <ChangePasswordForm Id={""} hide={() => { setShowPasswordForm(false) }} mode={"edit"} />
        </ModalForm>
      }
      {showPersonSignature &&
        <ModalForm title={'PersonSignature'} onHide={() => { setShowPersonSignature(false) }} show={showPersonSignature}>
          <PersonSignaturePage />
        </ModalForm>
      }
      {showSubstituteForm &&
        <ModalForm title={trans('SubstituteDefinition')+" : " +currentProfileOption?.Description  } onHide={() => { setShowSubstituteForm(false) }} show={showSubstituteForm}>
          <SubstituteForm onHide={() => { setShowSubstituteForm(false) }}   />
        </ModalForm>

      }
      {showDataForm &&
        <ModalForm title={'اطلاعات مخاطب'} onHide={() => { setShowDataForm(false) }} show={showDataForm}>
          <ContactData onHide={() => { setShowDataForm(false) }} CallerNum={callerNum}  />
        </ModalForm>

      }
      {(loading ) ?
        <>
          {(profileOptions 
         &&layoutContext.currentUserPosition.PositionId 
          ) ?
            <button
              ref={profileBtnRef}
              style={{ alignItems: "center", border: "none", backgroundColor: "transparent", }}
              className="profile-btn"
              onFocus={() => setShowProfile(true)}
              onBlur={() =>{ setShowProfile(false);}}>
              {
                profileOptions.length > 0 ?
                  <>
                    <div style={{position:"relative"}} className="profile-btn__content profile-btn__content-responsive">
                      {newReference&&<span style={{position:"absolute" ,top:"-21px",right:"-12px", width:"10px" , height:"10px", borderRadius:"50%", marginTop:"15px"}} className=" bg-warning ">{" "}</span>   }
                      <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} />
                    </div>
                    <div className="profile-btn__content profile-btn__content-responsive">
                      <span className="profileTitleText">{currentProfileOption?.Description}</span>
                      {
                        currentProfileOption?.CompanyInfo &&
                        <span style={{ fontSize: "10px" }} className="profileText d-none d-md-block">{currentProfileOption?.CompanyInfo}</span>
                      }
                    </div>
                    <div className="profile-btn__content">

                      {person.PersonPicture ?
                        <img height={"30px"} width={"23px"} src={URL.createObjectURL(dataURLtoFile('data:image/png;base64,' + person.PersonPicture, "1.jpg"))} className="profile-btn__img-profile" />
                        :
                        <FontAwesomeIcon style={{width:"32px" }}   icon={['fas', 'user-circle']} color='grey' className="profile-btn__img-profile" />
                      }
                    </div>
                  </>
                  :
                  <>
                    <div className="profile-btn__content profile-btn__content-responsive">
                      <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} />
                    </div>
                    <div className="profile-btn__content profile-btn__content-responsive">
                      <span className="">{"  "}</span>
                    </div>
                    <div className="profile-btn__content">
                      <img src={user} className="profile-btn__img-profile" /> {/*تصویر پروفایل باید با یک درخواست دیگر از سرور دریافت شود*/}
                    </div>
                  </>
              }
              {showProfile && profileOptions &&
                <section className='profile-menu'>
                    {profileOptions.length > 0 && profileOptions.map((option, index) =>(           
                      <div className={ `profile-menu__option ${activeIndexRef.current == profileOptions.length-index+4?"active-menu":""}`} id={`position`+index}>
                      <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <div  data-value={option.PositionId} onClick={() =>{ handleOptionClicked(option);setActiveIndex(profileOptions.length-index+4) ;activeIndexRef.current = profileOptions.length-index+4}}>{option.Description}</div>
                        {option?.newReference&&<span style={{width:"10px" , height:"10px", borderRadius:"50%"}} className="badgeProfile bg-warning ">{option?.newReference}</span>   }
                      </div>
                    </div>
                    ))}
                  <div className={ `profile-menu__option ${activeIndexRef.current == 4?"active-menu":""}`} onClick={() => { setShowSubstituteForm(true) }}>{trans("SubstituteDefinition")}</div>
                  <div className={ `profile-menu__option ${activeIndexRef.current == 3?"active-menu":""}`} onClick={() => { setShowPasswordForm(true) }}>{trans("changePassword")}</div>
                  <div className={ `profile-menu__option ${activeIndexRef.current == 2?"active-menu":""}`} onClick={() => { setUserInfoForm(true) }}>{trans("editUserInfo")}</div>
                  <div className={ `profile-menu__option ${activeIndexRef.current == 1?"active-menu":""}`} onClick={() => { setShowPersonSignature(true) }}>{trans("PersonSignature")}</div>
                  <div className={ `profile-menu__option ${activeIndexRef.current == 0?"active-menu":""}`} onClick={() => { handleExit() }}>{trans("exit")}</div>
                </section>
              }
            </button>
            :
            <div className="profile-btn">
              <div className="profile-btn__content profile-btn__content-responsive">
                <span className="profileTitleText">{JSON.parse(profile)?.Description}</span>
              </div>
                <div className="profile-btn__content">
                  <FontAwesomeIcon style={{width:"32px" }}   icon={['fas', 'user-circle']} color='grey' className="profile-btn__img-profile" />
                </div>
            </div>
             
          }

        </>
        : <div></div>
      }
    </>
  )
}






























