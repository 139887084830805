import { InputLoad } from "components/InputLoading/inputLoading";
import { Textbox } from "components/Textbox/Textbox";
import { InputHTMLAttributes, useContext, useEffect, useState } from "react";
import { getLeaveDaysRemainsApi } from "./api";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";

interface RemainingDayCountProp extends InputHTMLAttributes<HTMLInputElement> {
  value?: any;
  mode?: string;
  required?: boolean;
  RequesterPositionId?: string;
}

export const RemainingDayCount = ({   value, mode, required,RequesterPositionId }: RemainingDayCountProp) => {
  const [optionsRemainingDayCount, setOptionsRemainingDayCount] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errcode, setErrcode] = useState<any>();
  const layoutContext = useContext(LayoutContext);    

  const getRemainingDayCountAsync = async () => {
    setLoading(false)
    try {
      const res = await getLeaveDaysRemainsApi((RequesterPositionId&&mode!="create"&&value==null ) ?RequesterPositionId  : layoutContext.currentUserPosition.PositionId)
      setOptionsRemainingDayCount(res?.Data )

    } catch (error: any) {
     
      setErrcode(error?.response?.status)
    }
    setLoading(true)
  }

  useEffect(() => {
    if (mode == "create"&&(value==null||value==undefined)) {
      getRemainingDayCountAsync()
    } else {
      setLoading(true)
    }
    if(value||value==0){
      setOptionsRemainingDayCount(value)
    }
  }, []);
  return (
    <> 
    
      {(optionsRemainingDayCount||optionsRemainingDayCount==0)&& loading ?
            <div className=" ">
               <Textbox
                  readonly={true}
                  mode={"view"}
                  required={true}
                  textboxType="number"
                  textboxName="RemainingDayCount"
                  labelText="RemainingDayCount"
                  maxLength={3}
                  defaultValue={value ?value :optionsRemainingDayCount+""}
               />
             </div>
        : <>
          <InputLoad msg={errcode} required={required} labelText={"RemainingDayCount"} onClick={getRemainingDayCountAsync} />
        </>
      }
    </>
  );
};
