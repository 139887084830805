import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getCustomerGeneralRequestClassification = (async () => await http({
    url: ENDPOINTS.afterSalesApi.CustomerGeneralRequestClassification.dictionary,
    method: 'get',
})
    .then((response) => (response.data))

)