import { useState } from 'react';
import './style.scss'

interface SwitchBtnProps{
    handleModeCard : (e:boolean)=>void
    cardStyle:boolean,
    setColor?: 'defaultColor' | 'customerColor',
    setShape?: 'Square' | 'circle',
    firstMode:string,
    secondMode:string,
}

export const SwitchBtn = ({handleModeCard,cardStyle,firstMode,secondMode,setColor='defaultColor',setShape='Square'}:SwitchBtnProps) => {
    
    return ( 
        <div style={{maxWidth:'175px'}} className='d-flex justify-content-between align-items-center p-1' >
            <div className='ms-2 userSelectNone'>{firstMode}</div>
            <div className="switchBtn" onClick={()=>{handleModeCard(!cardStyle)}}>
                <input type="checkbox" checked={cardStyle ? true : false} onChange={(e)=>{}}/>
                <div className={`switchBtn__slider ${setShape == "circle" ? 'circleShape':'squareShape'} ${setColor == 'defaultColor' ? 'defaultColor' : 'customerColor'}`}></div>
            </div>
            
            <div className='me-2 userSelectNone'>{secondMode}</div>
        </div>
     );
}