import { Button } from "components/Button/Button";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useRef, useState } from "react";
import { getSecretariatNo } from "../../api/api";
import { getSecretariat, sendReserve } from "./api";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { ENDPOINTS } from "utils/endpoints";

interface ReserveFormPropsType {
    id?: any,
    mode?: any,
    onHide: () => void
}

export const ReserveForm = ({
    id,
    mode,
    onHide,
  
}: ReserveFormPropsType) => {


    const [resetForm, setResetForm] = useState(false);
    const [secretariatNo, setSecretariatNo] = useState<any>();
    const [secretariatId, setSecretariatId] = useState<any>();
    const [letterType, setLetterType] = useState<any>();
    const [singleIndicator, setSingleIndicator] = useState<any>(true);

    const formRef = useRef<any>("")
    const buttonRef = useRef()
    //const buttonRef = createRef<any>()

    async function formSubmit(event) {
        event.preventDefault();       
            try {
                await sendReserve(secretariatId ,  letterType ?letterType:0 , event.target.FromNumber.value ,event.target.ToNumber.value  )
                setResetForm(true)
                onHide()

            } catch (error) {

            }
        
    }
    
    async function getDataAsync() {
        try {
       //    return await getReserve(id);   
        } catch (error) {
            
        }
    }
    async function getSecretariatNoAsync(letterId) {
        try {
           const res = await getSecretariatNo(letterId ,!singleIndicator ?  letterType :0); 
           setSecretariatNo(+Object.keys(res.data)[0] ) 

        } catch (error) {
            
        }
      }
    async function getSecretariatAsync(id) {
        try {
            setLetterType(null)
            setLetterType(null)
           const res = await getSecretariat(id ); 
           setSingleIndicator(res.Data.Secretariat.SingleIndicator)
           setSecretariatId(id);
        } catch (error) {
            
        }
      }
    useEffect(() => {
        if (id && mode !== "create")
            getDataAsync().then((res) => {
              //  setReserve(res.Data);
            }).catch((err) => {
               // setReserve(undefined);
            });
    }, [id , mode]);

    useEffect(() => {
        if ( mode === "create"){
            setResetForm(true);
        }
    }, [ mode]);

    useEffect(() => {
        if (resetForm) {
          
            setResetForm(false);
        }

    }, [resetForm]);


    useEffect( () => {
        if(secretariatId && (letterType||singleIndicator)){
          getSecretariatNoAsync(secretariatId)
        }
    }, [secretariatId,letterType]);
    return (

        <form className="Reserve-form py-2" ref={formRef} onSubmit={formSubmit}>
            <div className="card">
                <div className="card-body">
                    <div className="container">
                        <div className="row ">
                            <div className="col">
                                <DictionarySelectBox
                                  onChange={(e)=>{getSecretariatAsync(e) }}
                                  mode={"edit"}
                                  label={"Secretariat"}
                                  name="Secretariat"
                                  value={""}
                                  endPoint={ENDPOINTS.secretariat.secretariat.dictionary}
                                />  
                            </div>
                            {!singleIndicator&&
                            <div className="col">
                                {/* DV */}
                                <EnumerationTypeSelect
                                  onChange={(e)=>{ setLetterType(e)}}
                                  mode={"edit"}
                                  label="LetterType"
                                  name="LetterType"
                                  enumTypeSelect="LetterType"
                                  value={""}
                                  />  
                            </div>
                            }
                            {secretariatNo && 
                            <div className="col">
                                <Textbox
                                    required={true}
                                    textboxType="number"
                                    min={+secretariatNo}
                                    textboxName="FromNumber"
                                    resetForm={resetForm}
                                    defaultValue={secretariatNo}
                                    labelText="FromNumber"
                                    mode={"edit" }
                                />
                            </div>
                            }
                            <div className="col">
                                <Textbox
                                    required={true}
                                    textboxType="number"
                                    textboxName="ToNumber"
                                    min={+secretariatNo}
                                    resetForm={resetForm}
                                    defaultValue={""}
                                    labelText="ToNumber"
                                    mode={"edit" }
                                    />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            {mode !== "view" ?
                                <>
                                    <Button ref={buttonRef}  value="Save" btnType="submit" />
                                    <Button

                                        btnType="cancel"
                                        value="Cancel"
                                        onClick={onHide}
                                    />
                                </>
                                :
                                 <Button
                                    btnType="primary"
                                    value="Close"
                                    onClick={onHide}
                                  />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}