import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Message } from "components/Message/Message";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";

interface SalesReturnInvoiceFormProps {
  SalesInvoiceDetailCallBack: (e: any) => void;
  salesInvoiceDetails: any;
  invoiceConstraint: any;
  mode: string;
  setShowForm: any;
}

export const SalesReturnInvoiceDetailsList = ({
  salesInvoiceDetails,
  setShowForm,
  SalesInvoiceDetailCallBack,
  invoiceConstraint,
  mode,
}: SalesReturnInvoiceFormProps) => {
  const [selectedObj, setSelectedObj] = useState<any>();
  const [selectList, setSelectList] = useState<any>([]);

  const selectedObjCallback = (obj, index) => {
    setSelectedObj(obj.ItemId);
  };

  const selectListCallBack = (list) => {
    setSelectList(list[1]);
  };

  useEffect(() => {
    if (!!selectList?.length) {
      SalesInvoiceDetailCallBack(selectList);
    }
  }, [selectList.length]);

  return (
    <>
      <div className="card">
        {salesInvoiceDetails?.length >= 0 ? (
          <>
            <DataGrid
              parentName={"saleReturnInvoiceDetail"}
              multiSelect={true}
              selectList={selectListCallBack}
              startIndex={17}
              theadData={Object.keys(invoiceConstraint?.DetailDTO)}
              tbodyData={
                salesInvoiceDetails?.length > 0
                  ? salesInvoiceDetails
                  : [invoiceConstraint?.DetailDTO]
              }
              scrollHeight={"67vh"}
              minCellWidth={80}
            />
          </>
        ) : (
          <Message message={"NoDataForPreview"} type={"info"} />
        )}
      </div>
      <div className="col-12 d-flex justify-content-end mt-3">
        <div
          onClick={() => {
            setShowForm(false);
            SalesInvoiceDetailCallBack(selectList);
          }}
          className="col-2 mb-1 btn btn-success btn-sm d-flex align-items-center justify-content-between "
          style={{ width: "70px", maxHeight: "30px", marginRight: "20px" }}
        >
          <span className=" font-size-md ms-2 ">{trans("select")}</span>
        </div>
      </div>
    </>
  );
};
