import { ButtonToolbar } from "components/ButtonToolbar/ButtonToolbar";
import { ButtonToolbarProcessFilter } from "components/ButtonToolbarProcessFilter/ButtonToolbarProcessFilter";
import { ButtonToolbarProcessStarting } from "components/ButtonToolbarProcessStarting/ButtonToolbarProcessStarting";
import { Search } from "components/Search/Search";
import React , {useEffect, useState} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import "../../../../../components/Toolbar/style.scss"

interface props {
  handleClickToolbar: (clickedButtonName: string) => void;
  handleSelectedStartingProcessToolbar?: (selectedStarting: string) => void;
  handleSelectedProcessFilterToolbar?: (selectedProcessFilter: string) => void;
  handleSearch?: (str: any) => void;
  reference?: boolean;
  action?: boolean;
  reminder?: boolean;
  reverse?: boolean;
  undo?: boolean;
  finish?: boolean;
  current?: boolean;
  archive?: boolean;
  personalArchive?: boolean;
  excel: boolean;
  print: boolean;
  refresh: boolean;
  create?: boolean;
  edit?: boolean;
  attach?: boolean;
  attachHover?: string;
  view: boolean;
  help: boolean;
  search: boolean;
  remove?: boolean;
  Send?: boolean;
  report?: boolean;
  Updated?: boolean;
  RefreshWithNotif?: boolean;
  id?: boolean;
}
export const Toolbar: React.FC<props> = ({
  handleClickToolbar,
  handleSelectedStartingProcessToolbar,
  handleSelectedProcessFilterToolbar,
  handleSearch,
  reference,
  action,
  reminder,
  reverse,
  undo,
  finish,
  current,
  archive,
  personalArchive,
  excel,
  print,
  refresh,
  create,
  edit,
  attach,
  attachHover,
  view,
  help,
  search,
  remove,
  RefreshWithNotif,
  Send,
  report,
  Updated,
  id,
}) => {
  const [shortcutList, setShortcutList] = useState<any>({});
  const [openToolbar, setOpenToolbar] = useState<boolean>(false);

  const toggleToolbar = () => {
    setOpenToolbar(!openToolbar)
  }

  const handleSelectedProcessStarting = (id: string) => {
    handleSelectedStartingProcessToolbar && handleSelectedStartingProcessToolbar(id);
  }
  const handleSelectedFilterProcess = (id: string) => {
    handleSelectedProcessFilterToolbar && handleSelectedProcessFilterToolbar(id);
  }
      
useEffect(() => {
// window.addEventListener("keydown" , keydown , true)
  let StorageShortcut = localStorage.getItem("Shortcut")
  let ShortcutList
  if (StorageShortcut == null) {
    setShortcutList({})
  } else {
      ShortcutList = JSON.parse(StorageShortcut)
      setShortcutList(ShortcutList)

  }

}, [])

  

  return (
    <section className="py-2 mb-2">
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-2 mt-1 mt-sm-0">
           {search ? <Search onChange={handleSearch && handleSearch}/> : null}
        </div>      
        <div className="toolbarXscrol toolbar-btns-container col-12 col-lg-10">
          <div className={`toolbarXscrol toolbar-btns-container__sub-container ${openToolbar ? 'active' : ''} d-flex d-md-flex justify-content-md-start justify-content-lg-end w-100 mt-2 mt-lg-0`}>
          <button onClick={toggleToolbar} className="toolbar-btns-container__toggle-btn btn btn-primary my-1">{openToolbar ? "-" : "+"}</button>
 
             <OverlayTrigger
                  key={"Filter"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                         {trans("Filtercsacasc")+" "+ trans("Process")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {handleSelectedProcessFilterToolbar&&
                <ButtonToolbarProcessFilter 
                  key={""} 
                  Callback={handleSelectedFilterProcess}
                />              
              }
            </div>                      
             </OverlayTrigger>  
          <OverlayTrigger
                  key={"excel"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"excel"}`}>
                          {trans("excel")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {excel ? (
              <ButtonToolbar id={id} hotKey={shortcutList["excel"]} faIcon={[ICON_TYPE, "file-excel"]} text="Excel" onClick={() => { handleClickToolbar("excel") }} />

              ) : null}
            </div>        
          </OverlayTrigger>   
          <OverlayTrigger
                  key={"Print"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"Print"}`}>
                          {trans("Print")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {print ? (
                <ButtonToolbar id={id} key={"F6"}
                  faIcon={[ICON_TYPE, "print"]} hotKey={shortcutList["print"]} text="Print" onClick={() => { handleClickToolbar("print") }} />
              ) : null}
            </div>        
             </OverlayTrigger> 
             <OverlayTrigger
                  key={"delete"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"delete"}`}>
                          {trans("delete")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
                 { remove ? (
                     <ButtonToolbar id={id} hotKey={shortcutList["delete"]} faIcon={[ICON_TYPE, 'trash']} text="delete" onClick={() => { handleClickToolbar("remove"); }} />
                 ) : null}
             </div>          
             </OverlayTrigger>   
  
          <OverlayTrigger
                  key={"undo"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"undo"}`}>
                          {trans("undo")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {undo ? (
                <ButtonToolbar id={id} key={shortcutList["Archive"]}
                  faIcon={[ICON_TYPE, "undo"]} text="Undo" hotKey={shortcutList["undo"]} onClick={() => { handleClickToolbar("undo") }} />
              ) : null}
            </div>                  
             </OverlayTrigger>   
               

  
          <OverlayTrigger
                  key={"Send"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"Send"}`}>
                          {trans("Send")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {Send ? (
                <ButtonToolbar id={id} 
                  faIcon={[ICON_TYPE, 'paper-plane']} text="Send" hotKey={shortcutList["Send"]} onClick={() => { handleClickToolbar("Send") }} />
              ) : null}
            </div>             
             </OverlayTrigger>  
          <OverlayTrigger
                  key={"Current"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"Current"}`}>
                          {trans("Current")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {current ? (
                <ButtonToolbar id={id} key={"F11 "}
                  faIcon={[ICON_TYPE, "undo"]} text="Current" hotKey={shortcutList["current"]} onClick={() => { handleClickToolbar("current") }} />
              ) : null}
            </div>             
             </OverlayTrigger>  
             <OverlayTrigger
                  key={"report"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"report"}`}>
                          {trans("report")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {report ? (
                <ButtonToolbar id={id} key={"F10"}
                  faIcon={[ICON_TYPE, "bell"]} text="report" hotKey={shortcutList["report"]} onClick={() => { handleClickToolbar("report") }} />
              ) : null}
            </div>           
             </OverlayTrigger>   
             <OverlayTrigger
                  key={"archive"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"archive"}`}>
                          {personalArchive ? trans("personalArchive") :  trans("archive")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
               {archive ? (
                <ButtonToolbar id={id} key={"F12"}
                  faIcon={[ICON_TYPE, "file-archive"]} text="archive" hotKey={shortcutList["archive"]} onClick={() => { handleClickToolbar("archive") }} />
              ) : null}
            </div>                   
             </OverlayTrigger> 

          <OverlayTrigger
                  key={"reminder"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"reminder"}`}>
                          {trans("reminder")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {reminder ? (
                <ButtonToolbar id={id} key={"F10"}
                  faIcon={[ICON_TYPE, "bell"]} text="Reminder" hotKey={shortcutList["reminder"]} onClick={() => { handleClickToolbar("reminder") }} />
              ) : null}
            </div>           
             </OverlayTrigger>   
 
 
          <OverlayTrigger
                  key={"Attach"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"Attach"}`}>
                          {attachHover? trans("Attach")+" "+trans(attachHover) : trans("Attach")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
            {attach ? 
                <ButtonToolbar id={id} key={"F10"} hotKey={shortcutList["Attach"]} faIcon={[ICON_TYPE, "paperclip"]} text="Attach" onClick={() => { handleClickToolbar("attach") }} />
            : null}
            </div>         
             </OverlayTrigger>  
             <OverlayTrigger
                  key={"Finish"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"Finish"}`}>
                          {window.location.href.includes("cartable") ? trans("executivetermination") : trans("Finish")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {finish ? (
                <ButtonToolbar id={id} key={"F11"}
                  faIcon={[ICON_TYPE, "flag-checkered"]} text="Finish" hotKey={shortcutList["finish"]} onClick={() => { handleClickToolbar("finish") }} />
              ) : null}
            </div>             
             </OverlayTrigger>  
             <OverlayTrigger
                  key={"reverse"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"reverse"}`}>
                          {trans("reversetoreferer")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {reverse ? (
                <ButtonToolbar id={id} key={shortcutList["reverse"]}
                  faIcon={[ICON_TYPE, "reply"]} text="reverse" hotKey={shortcutList["reverse"]} onClick={() => { handleClickToolbar("reverse") }} />
              ) : null}
            </div>                 
             </OverlayTrigger>   
             <OverlayTrigger
                  key={"Reference"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"Reference"}`}>
                          {trans("Reference")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {reference ? (
                <ButtonToolbar id={id} key={"F8"}
                  faIcon={[ICON_TYPE, "share-from-square"]} text="Reference" hotKey={shortcutList["reference"]} onClick={() => handleClickToolbar("reference")} />
              ) : null}
            </div>         
             </OverlayTrigger>  
             <OverlayTrigger
                  key={"Action"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"Action"}`}>
                          { trans("view")+" "+ trans("Action")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {action ? (
                <ButtonToolbar id={id} key={"F9"}
                  faIcon={[ICON_TYPE, "bolt"]} text="Action" hotKey={shortcutList["action"]} onClick={() => { handleClickToolbar("action") }} />
              ) : null}
            </div>          
             </OverlayTrigger>  
          <OverlayTrigger
                  key={"refresh"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"refresh"}`}>
                          {trans("refresh")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {refresh ? (
                <ButtonToolbar id={id} key={"F5"} RefreshWithNotif={RefreshWithNotif}Updated={Updated}
                  faIcon={[ICON_TYPE, "sync"]} hotKey={shortcutList["refresh"]} text="Refresh" onClick={() => { handleClickToolbar("refresh") }} />
              ) : null}
            </div>         
             </OverlayTrigger>   

          <OverlayTrigger
                  key={"create"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"create"}`}>
                          {trans("create")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {create ? (
                <ButtonToolbarProcessStarting key={"F4"}
                  text={"create"}
                  hotKey={"F4"}
                  id={id} 
                  Callback={handleSelectedProcessStarting}
                />
              ) : null}
            </div>        
          </OverlayTrigger>   
          <OverlayTrigger
                  key={"view"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"view"}`}>
                          {trans("view")}
                      </Tooltip>
                  }
              >

              <div className="py-1 py-lg-0">
              {view ? (
                <ButtonToolbar id={id} key={"F3"}
                  faIcon={[ICON_TYPE, "eye"]} text="View" hotKey={shortcutList["view"]} onClick={() => { handleClickToolbar("view") }} />
              ) : null}
            </div>      
             </OverlayTrigger>
          <OverlayTrigger
                  key={"edit"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"edit"}`}>
                          {trans("edit")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {edit ? (
                  <ButtonToolbar id={id} faIcon={[ICON_TYPE, "edit"] } hotKey={shortcutList["edit"]} text="Edit" onClick={() => { handleClickToolbar("edit") }} />
              ) : null}
          </div>     
          </OverlayTrigger>   
          <OverlayTrigger
                  key={"Help"}
                  placement="top"
                  delay={{"show" : 600 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"Help"}`}>
                          {trans("Help")}
                      </Tooltip>
                  }
              >
            <div className="py-1 py-lg-0">
              {help ? (
                <ButtonToolbar id={id} key={"F1"}
                  faIcon={[ICON_TYPE, "question"]} text="Help" hotKey={shortcutList["help"]} onClick={() => { handleClickToolbar("help") }} />
              ) : null}
            </div>  
             </OverlayTrigger>   


          </div>
        </div>
      </div>
    </section >
  );
};
