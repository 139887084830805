import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const importProcessMakerFiles = (fileData) => http({
    url: ENDPOINTS.bpmnApi.process.postProcessesFiles,
    method: 'post',
    data: fileData,
    headers: {
        "content-type": "multipart/form-data",
        Accept: "*/*",

    }
}).then((res) => {
    return res.data
}).catch((err) => {
    
    return Promise.reject(err);
});




export const getUsers = (pageNumber: number, pageSize: number, organizationType ,searchString ,orderBy ,filterlist) => http({
    url: ENDPOINTS.userApi.userList,
    method: 'get',
    params: {
        PageNumber: pageNumber,
        pageSize: pageSize,
        organizationType: organizationType,
        searchString : searchString ,
        OrderByString:orderBy,
        FilterString:filterlist,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});



export const postPerson = (personId, userData) => http({
    url: ENDPOINTS.userApi.userId,
    method: "post",
    params: {
        PersonId: personId,
    },
    data :userData

}).then((res) => {
    return res.data
}).catch((err) => { 
    return Promise.reject(err);
});
export const postUsersGrouply = (id) => http({
    url: ENDPOINTS.userApi.users,
    method: "post",
    params: {
        groupPositionId: id,
    },
}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});


export const removeUser = (Id) => http({
    url: ENDPOINTS.userApi.userList,
    method: "DELETE",
    params: {
        Id: Id,
 
    }

}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});
export const resetPasswordAsync = (userId) => http({
    url: ENDPOINTS.userApi.reset,
    method: "post",
    params: {
        Id: userId, 
 
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const resetLockoutAsync = (userId) => http({
    url: ENDPOINTS.userApi.lockout,
    method: "post",
    params: {
        Id: userId, 
 
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


export const postChangePassword = (data) => http({
    url:  ENDPOINTS.profileApi.ChangePassword,
    method: 'post',
    data: data
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});







export const getRolesList = (async () => await http({
    url: ENDPOINTS.tadbirAuthorizeApi.dictionary,
    method: 'get',
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)
export const getDictionaryRolesList = (async (positionId:any) => await http({
    url: ENDPOINTS.tadbirAuthorizeApi.positionRole.dictionary,
    method: 'get',
    params: {
        positionId: positionId
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)



export const getPersonEmail = (personId: any) => http({
    url: ENDPOINTS.BaseInformation.person.email,
    method: 'get',
    params: {
        Id: personId
    }
}).then((res) => {
    //  
    return res
}).catch((err) => {
    return Promise.reject(err);
});



export const getUserPositions = (personId) => http({
    url: ENDPOINTS.BaseInformation.position.getPosition,
    method: 'get',
    params: {
        personId: personId
    }
}).then((res) => {
    //  
    return res?.data
}).catch((err) => {
    
    return Promise.reject(err);
});


export const getPositionRole = (positionId: any) => http({
    url: ENDPOINTS.tadbirAuthorizeApi.positionRole.browse,
    method: 'get',
    params: {
        positionId: positionId
    }
}).then((res) => {
    return res.data.Data
}).catch((err) => {
    return Promise.reject(err);
});
export const addPositionRole = (positionId: any, data) => http({
    url: ENDPOINTS.tadbirAuthorizeApi.positionRole.load,
    method: 'post',
    params: {
        positionId: positionId
    },
    data: data
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const delPositionRole = (positionId: any, data) => http({
    url: ENDPOINTS.tadbirAuthorizeApi.positionRole.load,
    method: 'DELETE',
    params: {
        positionId: positionId
    },
    data: data
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
