
import { Application } from "components/Application/Application";
import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { TableTree } from "components/TableTree/TableTree";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { ImportProcessesForm } from "pages/SystemManagement/Settings/ImportProcessesForm";
import { useEffect, useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { trans } from "utils/helpers";
import { exportProcess, getProcessById } from './api/api';
import { AssignmentRules } from "./components/AssignmentRules/AssignmentRules";
import { ProcessIconModal } from "./components/ProcessIconModal/ProcessIconModal";

interface propsProcess {

}
export const ProcessSetting = () => {
  const [data, setData] = useState<any>()
  const [Fulldata, setFullData] = useState<any>()
  const [forceUpdate, setForceUpdate] = useState(false)
  const [selectedObj, setSelectedObj] = useState<any>()
  const [ReferenceRule, setReferenceRules] = useState(false);
  const [assignApp, setassignApp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState<any>("info");
  const [defaultShow, setDefaultShow] = useState(false)
  const [assignIcon, setAssignIcon] = useState(false)
  const [userChanges, setUserChanges] = useState({});
  const [search, setsearch] = useState(null);
  const [idCallback, setIdCallback] = useState<any>("2")

  const [showImportProcessesForm, setShowImportProcessesForm] = useState(false)
  let theadData: any = []
  const clickToolbarCallback = (clickedButtonName: string) => {

    switch (clickedButtonName.toLowerCase()) {
      case "reference":alert("call api reference with Id :");break;
      case "print":alert("print ");break;
      case "remove":break;
      // case "excel":downloadCSV(data ,10 ,trans("GeneralRequestSetting"));break;
      case "refresh":setForceUpdate(!forceUpdate);break;
      case "assignrule": if (selectedObj && selectedObj.ParentId) setReferenceRules(!ReferenceRule); break;
      case "export": if (selectedObj && selectedObj.ParentId == null)exportAsync(selectedObj); break;
      case "assignapp": if (selectedObj && selectedObj.ParentId == null) setassignApp(!assignApp); break;
      case "assignicon": if (selectedObj && selectedObj.ParentId == null){setAssignIcon(!assignIcon)}; break;
      case "import": setShowImportProcessesForm(true); break;
    }
  };

  const setselecetrow = (e) => {
    setSelectedObj(e)
  }
const Searchhandleer = (str) => {
  if (str === "") {
      setsearch(null)
  }else{
    setsearch(str)
  } 
  } 
  async function exportAsync(obj) {
    try {
    const respons = await exportProcess(obj.Id)
    const filename = obj.Description+".pmx"
    var pom = document.createElement('a');
    var bb = new Blob([respons.data], {type: 'text/plain'}); 
    pom.setAttribute('href', window.URL.createObjectURL(bb));
    pom.setAttribute('download', filename);
    pom.click();
    } catch (error) {
      setMessageType("error")
    }

  }
  async function getMenuAsync(id) {
    setLoading(false)
    try {
      const respons = await getProcessById(id)

      setData(respons.Data)
    } catch (error) {
      setMessageType("error")
    }
    setLoading(true)

  }



  function cancel() {
    if(sessionStorage.getItem("changingforms")+""=="true"){
        Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
            setassignApp(false)
        });
    }else{
      setassignApp(false)    
    };


}


  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };


useEffect(() => {
    if(Object.values(userChanges).filter((item)=>item!=null).length>0){

        sessionStorage.setItem("changingforms" , "true")
          window.addEventListener('beforeunload', handleBeforeUnload);
          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
    }else{
        sessionStorage.setItem("changingforms" , "false")
        window.removeEventListener('beforeunload', handleBeforeUnload);

    }
}, [userChanges]);

  useEffect(() => {
    setData(null)
    getMenuAsync(null)
  }, [forceUpdate]);
  useEffect(() => {
    if(idCallback){
      setIdCallback(null)
    }
  }, [idCallback]);


  if (defaultShow) {
    if (Fulldata?.length > 0) {
      theadData = Object.keys(Fulldata[0])
    }
  } else {
    if (data?.length > 0) {
      theadData = Object.keys(data[0])
    }
  }

  return (
    <>
      {
        ReferenceRule &&
        <ModalForm modalHeight={80} modalProfileKey={"Users"} title={trans("Users")+" : " + trans(selectedObj.Description)} onHide={() => setReferenceRules(false)} show={ReferenceRule}>
          <AssignmentRules showForm={() => { setReferenceRules(false);setIdCallback(selectedObj.ParentId) }} Task={selectedObj} Method={selectedObj.Code} />
        </ModalForm>
      }
      {
        showImportProcessesForm &&
        <ModalForm modalHeight={80} modalProfileKey={"Users"} title={"ImportProcesses"} onHide={() => setShowImportProcessesForm(false)} show={showImportProcessesForm}>
          <ImportProcessesForm reload={()=>{ setShowImportProcessesForm(false) ;setForceUpdate(!forceUpdate);}}  showForm={() => { setShowImportProcessesForm(false) }} />
        </ModalForm>
      }
      {
        assignIcon &&
        <ModalForm modalHeight={30} title={"AssignIcon"} onHide={() => setAssignIcon(false)} show={assignIcon}>
          <ProcessIconModal showForm={() => { setAssignIcon(false) }} rowId={selectedObj.Id} />
        </ModalForm>
      }
      {
        assignApp &&
        <ModalForm modalHeight={33} title={"AssignApplication"} onHide={() => setassignApp(false)}   show={assignApp}>
          <div className="card p-3">
            <div className="row col-lg-12">
              <Application
                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                PRJ_UID={selectedObj.Id}
                type="SelectBox"
                name="ApplicationId"
              />
            </div>
            <div className="col-lg-12 btns_form">
              <Button
                btnType="primary"
                value="Close"
                onClick={() => cancel()}
              />
            </div>
          </div>
        </ModalForm>
      }

      <Toolbar excel={false} Export={true} Importprocesses={true} view={false} assignApp={true} edit={false} emptyList={data?.length == 0} id={selectedObj} Tree={false} assignIcon={true} assignRule={true} search={true}handleSearch={Searchhandleer} handleClickToolbar={clickToolbarCallback} />
      <div style={{overflowY: "auto", 
          height: "72vh",
           scrollbarWidth: "thin"}} className="col-lg-12 mt-3 ">
        <div className="list-card card ">
          {
            loading ? (
              data?.length > 0 ?
                <TableTree
                  search={search}
                  maxLevel={1}
                  params={"processId"}
                  selectedRowObjectCallback={setselecetrow}
                  selectedRowObject={idCallback}
                  Data={data}
                  theadData={theadData}
                  parentName={"Process"}
                  startIndex={9}
                  defultShow={defaultShow}
                  getChildrenUrl={ENDPOINTS.bpmnApi.process.getProcessTasks}
                /> : <Message message={"NoDataForPreview"} type={messageType} />)

              : <Loading />
          }
        </div>
      </div>
    </>
  )
}