
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useContext, useEffect, useState } from "react";

import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm, UserContext } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { downloadCSV, trans } from "utils/helpers";
import { ReceiverForm } from "./component/ReceiverForm";
import { getReceiverlist } from "pages/Secretariats/LettersPage/api/api";

interface LetterReceiverPropsType {
    onHide?: () => void;
    getReceiverLetters: (data) => void;
    letterId:string;
    defaultReceiver:any;
    mode:any;
    inTab:any;
}

export const  LetterReceiver = ({getReceiverLetters,defaultReceiver ,letterId ,mode,inTab }:LetterReceiverPropsType) => {


    let [response, setResponse] = useState<Response | any>(null);
    const [LetterReceiverId, setLetterReceiverId] = useState<string | any>(null);
    const [Total_records, setTotal_records] = useState<any>();
    const [Receivermode, setReceiverMode] = useState<string>();
    const [orderFormat, setOrderFormat] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [showReceiverForm, setShowReceiverForm] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [Receiverlist, setReceiverlist] = useState<any>([]);
    const [selectList , setSelectList] = useState<any>([])
    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)

    let customFuction: any = []

    /* pagination area*/


    const addReceivertoList = (newItem) => {
        setLoading(false)     
        let ListId=Receiverlist.map(item=>{return item.Id})
        if(!ListId.includes(newItem.Id)|| newItem.Id==" "){
            setReceiverlist([...Receiverlist ,newItem ])
            setTotal_records([...Receiverlist ,newItem ].length)
            getReceiverLetters([...Receiverlist ,newItem ])
            setShowReceiverForm(false)
        }else{
            Toast("msg_delete_useddata","error")
        }
        setLoading(true)
    }

    const getWithOrder = (order: string[]) => {
        setOrderFormat(order.join(","))
    }
    const selectedRowCallback = (obj, index) => {
        setLetterReceiverId(obj?.Id);
    };
    const selectListCallBack =(list)=>{
          
        setSelectList(list[1])
      }
    const handleRemove =()=>{
        setLoading(false)
        let removeListId=selectList.map(item=>{return item.Id})
        let newList:any = []
        Receiverlist.map(item=>{
            if(!removeListId.includes(item.Id)){
                newList.push(item)
            }
        })
        setResetMultiSelect(!resetMultiSelect)
        setSelectList([])
        setReceiverlist(newList)
        setTotal_records(newList.length)
        getReceiverLetters(newList)
        setLoading(true)

      
   
  }
    const clickToolbarCallback = (clickedButtonName: string) => {

        switch (clickedButtonName.toLowerCase()) {
            case "finish": alert("call api finish " + letterId); break;
            case "current": alert("call api current " + letterId); break;
            case "archive": alert("call api archive " + letterId); break;
            case "view": if (LetterReceiverId) { setReceiverMode("view");  } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "print": alert("print ");break;
            case "excel": downloadCSV(Receiverlist ,1 ,trans("LetterReceiver"));break;
            case "create": setShowReceiverForm(true);break;
            case "remove":if(selectList.length >0){Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleRemove)}else{Toast("Msg_SelectRow" , "warning")}  ;break;

        }
    };

    async function getReceiverlistAsync() {
        setLoading(false)
        try {                     
           const res = await getReceiverlist( letterId);
           let newList = res.Data.map(item=>{
             
            let data ={

                Id:item.GroupPositionId ?  item.GroupPositionId  : item.PositionId  ,
                Name:item.Receiver ,
                Description	:item.Description,
                IsCopy	:item.IsCopy,
                IsDefault	:item.IsDefault,

            }
            return data.Id ==null ?{...data,Id:" "} :data
           })
           setReceiverlist( newList)
           getReceiverLetters(newList)
           setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)
    }
    useEffect(() => {
        setResponse([])
        if(inTab ==="LetterReceivers"){
        if((Receiverlist.length ===0&&defaultReceiver)||(Receiverlist?.[0]?.Id !=defaultReceiver?.Id)){
            setReceiverlist([defaultReceiver])
            getReceiverLetters([defaultReceiver])
            if(letterId){
                getReceiverlistAsync()  
            }
        }
        setLoading(true)
        }
        
    }, [inTab ,defaultReceiver]);
    
    useEffect(() => {

        if(inTab ==="LetterReceivers"){
        if(Receiverlist.length >0){ 
          let newList:any = []          
          Receiverlist.map(item=>{
                    if(item != undefined){
                        if(item.IsDefault && defaultReceiver){
                            newList.push(defaultReceiver)  
                        }else
                        newList.push(item) 

                    }
            })
            setReceiverlist(newList)
            getReceiverLetters(newList)
        }
        setLoading(true)
        }
    }, [defaultReceiver , inTab]);
    
   
    /* #endregion */

    return (
        < >
        {inTab == "LetterReceivers" && 
        <div className="p-2">
        {mode != "view" &&
          <Toolbar  create={mode != "view"} remove={mode!= "view"}  emptyList={Receiverlist.length == 0} id={LetterReceiverId}   search={false} edit={false} refresh={false}  view={false}  handleClickToolbar={clickToolbarCallback} />
        }   
         {loading ?
            <div className="col-lg-12">
                <div className="list-card card">
                    <div className="list-card__body">
                        {
                            (Receiverlist.length > 0  && Receiverlist[0]?.Id!== undefined ?
                                <DataGrid
                                    customFuction={customFuction}
                                    selectItem={(e) => {   }}
                                    multiSelect={true}
                                    selectList={selectListCallBack}
                                    orderFormat={getWithOrder}
                                    resetMultiSelect={resetMultiSelect}
                                    parentName={"LetterReceiver" }
                                    startIndex={1}
                                    theadData={["Id" , "Name" ,"Description" ,"IsCopy" , "IsDefault"]}
                                    scrollHeight={"350px"}
                                    tbodyData={Receiverlist}
                                    minCellWidth={80}
                                    selectedRowObjectCallback={selectedRowCallback}
                                    totalRecords={Total_records}
                                />
                                : <Message message={catchMessage.msg} type={catchMessage.type} />)
                        }
                    </div>
                </div>
            </div>
             : <Loading />
         }
             {showReceiverForm &&
             <ModalForm mode={Receivermode} title="ReceiverForm" onHide={() => setShowReceiverForm(false)} show={showReceiverForm}>
                 <ReceiverForm letter={letterId} Receiverlistcallback={(e)=>{ addReceivertoList(e)}} onHide={() => setShowReceiverForm(false)} />
             </ModalForm>
             }
        </div>
        }
        </>
    )
}