import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRef, useState } from "react"
import { ICON_TYPE } from "utils/constants"
import { trans } from "utils/helpers"
interface ButtonToolbarArchiveFileProps {
    onClick?: () => void,
    Callback: (ans) => void,
    positionId?: string,
}
export const ButtonToolbarArchiveFile: React.FC<ButtonToolbarArchiveFileProps> = ({ onClick, Callback, positionId }) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    const ButtonToolbarArchiveFile = useRef(null);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [options, setOptions] = useState<any>([{Id:"0" , Name : "Jobs"},{Id:"1" , Name : "Documents"}])


    function showSubMenuFunction() {
        setShowSubMenu(true)
    }
    function hideSubMenuFunction() {
        setShowSubMenu(false)
    }
    function selectboxValSetter(event) {
        Callback && Callback(event);
        setShowSubMenu(false)
        if (ButtonToolbarArchiveFile.current) (ButtonToolbarArchiveFile.current as HTMLButtonElement).blur();
    }
    return (
        <> {options && options?.length > 0 && <div className="toolbar-btn-wrapper">
            <button ref={ButtonToolbarArchiveFile} onClick={() => { }} onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction} className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
                <div className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>F12</div>
                <FontAwesomeIcon icon={[ICON_TYPE, "file-archive"]} width="20px" height="20px" />
                <small>{trans("Archive")}</small>
                <div className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`}>
                    {options && options.map((option, index) => (
                        <div key={index} className="toolbar-btn-sub-menu__option" data-value={option.Id} onClick={()=>selectboxValSetter(option.Id)}>{trans( option.Name)}</div>
                    ))}
                </div>
            </button>
        </div>
        }
        </>
    )
}