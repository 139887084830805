import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getCartableNotificationCount = () => http({
    method: 'get',
    url: ENDPOINTS.cartable.notifications,

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});