import { Button } from "components/Button/Button";
import DatePicker from "components/DatePicker/DatePicker";
import { Loading } from "components/Loading/loading";
import { UserContext } from "components/ModalForm/ModalForm";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { Time } from "components/Time/Time";
import { Toast } from "components/ToastShow/ToastShow";
import { useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { sendMeetingApproval } from "./api";

interface MeetingApprovalFormPropsType {
  onHide: () => void;
  handleShowReferenceStepper?: (referenceId: string) => void;
  referenceId?: string;
  MeetingId?: string;
  Description?: string;
  ApprovalId?: string;
  reset;
  mode;
}
export const MeetingApprovalForm = ({
  reset,
  referenceId,
  MeetingId,
  ApprovalId,
  Description,
  mode,
  onHide,
  handleShowReferenceStepper,
}: MeetingApprovalFormPropsType) => {
  let MeetingApprovalDTO:  any;

  const [showReferenceStepper, setShowReferenceStepper] = useState<boolean>(false);

  const [resetForm, setResetForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [response, setResponse] = useState<any>();
  const [position, setPosition] = useState<any>();

  const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);

  const [fromDate, setFromDate] = useState<string>();

  async function getMeetingApprovalAsync() {
    setLoading(false);
    try {
      
        if(ApprovalId){


        }else if(referenceId){


        }
      

      
    } catch (error){ }
    setLoading(true);
  }


  useEffect(() => {
    if(mode != "create" || (ApprovalId&&ApprovalId?.length>0)){
      if ((referenceId||ApprovalId) && !showReferenceStepper) getMeetingApprovalAsync();

    }
  }, [referenceId ,ApprovalId]);

  const handleonHideMeetingApprovalFormSubmit = (event) => {
    event.preventDefault();
    if (mode == "edit") {
      let data  = {
        Id: response.Data.Id,
        Description: event.target.Description.value,
        RequesterPositionId: event.target.RequesterPosition.value,
        Applicant: event.target.applicant.value,
      };
      sendDataAsync(data, "put"  );
    } else {
      let data  = {
        MeetingId:MeetingId,
        "PerformerPositionId":  position?.Id,
        "PerformerPositionDescription":  position?.Name,
        Description: event.target?.Description?.value,
        "TaskDescription":  event.target.TaskDescription.value,
        EstimateTime: +event.target.estimateTime[0].value,
        DeadLineDate: fromDate,


       
      };
      sendDataAsync(data, "post" );
    }
  };


  async function sendDataAsync(data, method ) {
    try {
        const res = await sendMeetingApproval(data , method)
        reset()
        onHide()
    } catch (err: any) { }

  }
 

  return (

    <>
   
        <div>
          {loading ? (
              <form action="" onSubmit={handleonHideMeetingApprovalFormSubmit} className={"p-2 "}>
                <div className="card ">
                  <div className="card-body">
                    <div className="row">  
                     <div className="col-12">
                        <Textbox
                          required={true}
                          minHeight={mode == "view" ? 3 : 4}
                          Change={setUserMadeChanges}
                          textboxType="textarea"
                          labelText="Description"
                          resetForm={resetForm}
                          textboxName="Description"
                          defaultValue={Description ? Description : MeetingApprovalDTO?.onHideMeetingApproval?.Description}
                          mode={mode}
                        />
                     </div>
                    </div>
                    <div className="row">
                    <div className="col-12 col-sm-4  mb-sm-0">
                    <Position
                      name="PerformerPositionId"
                      Change={setUserMadeChanges}
                      label="Performer"
                      resetForm={resetForm}
                      returnObj={true}
                      onChange={(e)=>{setPosition(e)}}
                      value={
                        response?.PerformerPositionId
                          ? response?.PerformerPositionId
                          : null
                      }
                      mode={mode}
                    />
                     
                    </div>
                    <div className="col-4 my-1 my-lg-0 col-lg-3">
                          <DatePicker
                              onOpenPickNewDate={false}
                              lable='DeadLineDate'
                              resetForm={resetForm}
                              type='date'
                              setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                              val={MeetingApprovalDTO?.DeadLineDate  ? MeetingApprovalDTO.DeadLineDate :null}
                          />
                      </div>
                      
                      <div className="col-4 my-1 my-lg-0 col-lg-3">
                          <Time
                              name="estimateTime"
                              IdString="estimateTime"
                              resetForm={resetForm}
                              defaultValue={MeetingApprovalDTO?.EstimateTime}
                              label="estimateTime"
             
                          />
                      </div>
                    <div className="col-12">
                        <Textbox
                          minHeight={mode == "view" ? 3 : 4}
                          Change={setUserMadeChanges}
                          textboxType="textarea"
                          labelText="TaskDescription"
                          resetForm={resetForm}
                          textboxName="TaskDescription"
                          defaultValue={Description ? Description : MeetingApprovalDTO?.TaskDescription}
                          mode={mode}
                        />
                      </div>
                    </div>
                  </div>
                </div>

              {mode != "view" ? (
                <div className="d-flex justify-content-end">
                  {!Description &&  
                  <Button btnType="submit" value="save" />
                  }
                  {handleShowReferenceStepper && (
                    <Button
                      btnType="submit"
                      value="SaveAndReference"
                      onClick={() => setShowReferenceStepper(true)}
                    />
                  )}
                  <Button
                    btnType="cancel"
                    value="cancel"
                    onClick={() => {
                      userMadeChanges ? Toast(trans("modalclosemessage"), "warning", "alert", onHide)
                        : onHide()
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </form>
          ) : (
            <Loading />
          )}
        </div>
    
    
    </>
  );
};
