import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import DatePicker from "components/DatePicker/DatePicker";
import { Toast } from "components/ToastShow/ToastShow";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { UserContext } from "components/ModalForm/ModalForm";

import { Toolbar } from "components/Toolbar/Toolbar";
import {useEffect, useState } from "react";
import { downloadCSV, trans } from "utils/helpers";
import { PostPeriod, DeletePeriod, PutPeriod, getPeriod, getAllPeriod } from "./api";
import { Textbox } from "components/Textbox/Textbox";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";


interface PeriodFormPropsType {
    onHide?: () => void


}
export const PeriodForm = ({ 
    onHide,
  
}: PeriodFormPropsType) => {
     
    const [resetForm, setResetForm] = useState(false);
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [untilDate, setUntilDate] = useState<string | null>(null);
    const [response , setResponse ] = useState<Response | any>(null);
    const [editForm , setEditForm ] = useState< any>();
    const [selectedObj , setSelectedObj ] = useState< any>();
    const [mode , setmode ] = useState< any>();
    const [showForm , setShowForm ] = useState< any>(false);
    const [loading , setLoading] = useState(false)
    const [formLoading , setFormLoading] = useState<boolean>(false)
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});

    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setsearch] = useState(null);
    const [flag,setFlag] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [userChanges, setUserChanges] = useState({});


    const clickToolbarCallback = (clickedButtonName: string) => {
        
      switch (clickedButtonName.toLowerCase()) {
          case "view":if(selectedObj?.Id) handleViewRow() ;break;
          case "edit":if(selectedObj?.Id) handleEditRow(); break;
          case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
          case "print":alert("print ");break;
          case "excel":downloadCSV(response ,1 ,trans("PeriodForm"));break;
          case "refresh":setForceUpdate(!forceUpdate); break;
          case "create":handleCreateAction();break;
        }
      };
    

      const Searchhandleer = (str) => {
        if(str =="" ){
            setsearch(null)
        }else{
            setsearch(str)
        }
      }

      const handleViewRow = () => {
        setmode("view")
        setShowForm(true)
    }

    const handleEditRow = () => {
      setmode("edit");
      setShowForm(true)
  }

  const handleCreateAction = () => {
    setEditForm(null)
    setmode("create")
    setUserChanges({})
    setShowForm(true)
    setResetForm(true)

}

      const handleDeleteRow = async(Id =selectedObj.Id) => {
        try {
            const res  = await DeletePeriod(Id)
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }
    }

    const selectedIdCallback = (obj, index) => {
      if(sessionStorage.getItem("changingforms")+""=="true"){
          Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false"); setUserChanges({}) });
      }else{
        setSelectedObj(obj)         
      };
    }
      

    async function sendNodeAsync(data) {
        try{
           const res = await PostPeriod(data);
           setShowForm(false)
        }catch(err:any){

        }
        setResetForm(true);
        getPeriodorganizationIdAsync()

    }


    async function putNodeAsync(data) {
        try{
           const res = await PutPeriod(data);
           setShowForm(false)
        }catch(err:any){

        }
        setResetForm(true);
        getPeriodorganizationIdAsync()
    }

    async function getPeriodorganizationIdAsync() {
      setLoading(false)
        try{
           const res = await getAllPeriod( orderBy.join(",") , search);
           setResponse(res.Data)
           setCatchMessage({type:"info",msg:"NoDataForPreview"})
          } catch (error:any) {
              setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
          }
          setLoading(true)
    }

    const defaultFormData = async ()=>{
      setFormLoading(false)
      try {
        const res = await getPeriod(selectedObj.Id); 
        setEditForm({Id: res.Data.Id, Name: res.Data.Name, StartDate: res.Data.StartDate, EndDate: res.Data.EndDate})     
        setCatchMessage({type:"info",msg:"NoDataForPreview"})
      } catch (error:any) {
          setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
      }
      setFormLoading(true)
    }
    
    const validationDates = () =>{
      if(fromDate && untilDate && fromDate?.toString().slice(0,10) > untilDate?.toString().slice(0,10)){
          setFlag(true)
      }else{
          setFlag(false)
      }
  }
    const handleSubmit = (event) => {
        event.preventDefault();
        if(flag){
          Toast(trans("InvalidEndDate"),"error");
      }else{
        let data:any = {}
        if (editForm == null) {
            sendNodeAsync({
              startDate:fromDate? fromDate : null  ,
              endDate:untilDate? untilDate : null ,
              name:event.target.Name.value,
            }).then(() => {sessionStorage.setItem("changingforms" , "false");setUserChanges({}) ; getPeriodorganizationIdAsync();cancel()})
        } else {
            putNodeAsync({
              startDate:fromDate? fromDate : null  ,
              endDate:untilDate? untilDate : null ,
              name:event.target.Name.value,
              ActivityStatus:+event.target.PeriodStatus.value,
              Id:selectedObj.Id,
            }).then(() => {sessionStorage.setItem("changingforms" , "false");setUserChanges({}) ; getPeriodorganizationIdAsync();cancel()  })

        }
      }
    }

    function cancel() {
      if(sessionStorage.getItem("changingforms")+""=="true"){
          Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
              sessionStorage.setItem("changingforms" , "false");
              setUserChanges({});
              setEditForm(null);
              setShowForm(false)
          });
      }else{
          setEditForm(null);
          setShowForm(false)     
      };
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };
  
    
  useEffect(() => {
    if(Object.values(userChanges).filter((item)=>item!=null).length>0){

        sessionStorage.setItem("changingforms" , "true")
          window.addEventListener('beforeunload', handleBeforeUnload);
          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
    }else{
        sessionStorage.setItem("changingforms" , "false")
        window.removeEventListener('beforeunload', handleBeforeUnload);

    }
}, [userChanges]);

     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);


     useEffect(() => {
        getPeriodorganizationIdAsync()
      }, [forceUpdate, orderBy  ,search]);

     useEffect(() => {

      if(mode != "create" && showForm ){
          defaultFormData()
      }else{
        setFormLoading(true)
      }
    }, [selectedObj ,showForm,mode]);



    useEffect(()=>{
      validationDates()
    },[untilDate,fromDate])
    return (
        <div className="person-contact-form card p-3">
            <div className="">
            <Toolbar handleSearch={Searchhandleer} emptyList={response == null || response?.length == 0} id={selectedObj?.Id} create={true} remove={true} edit={true} search={true}   handleClickToolbar={clickToolbarCallback} />
                    <div className="">
                      {showForm && <>
                                {formLoading? 
                                <>
                                   <div className="card mb-4">
                                   <form name="TypeSettingForm" className="TypeSettingForm row card-body " onSubmit={handleSubmit}>
                                   <div className="row my-1">
                                   <div className="col-12 col-sm-4">
                                   <Textbox
                                         defaultValue={editForm?.Name}
                                         required={true}
                                         mode={mode}
                                         textboxType="text"
                                         textboxName="Name"
                                         labelText="Name"
                                         resetForm={resetForm}
                                     />
                                     </div>
                                     <div className="col-12 col-sm-4">
                                     <DatePicker
                                      //  Change={setUserMadeChanges}
                                       mode={mode}
                                       required={true}
                                       parentName={"TypeSetting"}
                                       resetForm={resetForm}
                                       lable="fromDate"
                                       setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                                       val={ editForm?.StartDate ? editForm?.StartDate  : null} //resetForm ? new Date().toISOString() : 
                                       errorFlag={flag}
                                     />
                                    </div>
                                    <div className="col-12 col-sm-4">
                                     <DatePicker
                                        // Change={setUserMadeChanges}
                                        mode={mode}
                                        required={true}
                                        resetForm={resetForm}
                                        parentName={"TypeSetting"}
                                        lable="untilDate"
                                        setIsoTime={(isoTime: string) => { setUntilDate(isoTime) }}
                                        val={editForm?.EndDate ? editForm?.EndDate : null} //resetForm ? new Date().toISOString() : 
                                        errorFlag={flag}
                                     />     
                                    </div>
                                    {mode != "create" &&          
                                       <div className="col-lg-4 mt-1">
                                        <EnumerationTypeSelect
                                          value={editForm?.ActivityStatus ? editForm?.ActivityStatus  : null}  
                                          name="PeriodStatus"  
                                          mode={mode} 
                                          label="Status"
                                          enumTypeSelect="ActivityStatus"
                                        />
                                       </div>                            
                                       }
                                    </div>
                                       {  mode !="view" ?
                                             <div className="col-lg-12 mt-1 btns_form">
                                             <Button value="Save" btnType="submit" />
                                             <Button btnType="cancel" value="Cancel" onClick={()=>{setShowForm(false)}} />
                                             </div>
                                             :
                                             <div className="col-lg-12 mt-1 btns_form">
                                             <Button btnType="primary" value="close" onClick={()=>{setShowForm(false)}} />
                                             </div>
                                        }                        
                                   </form>
                                 </div >
                                   </>
                                 :<Loading />
                                }
                                </>
                            }
                                
                      {loading ?
                        <>
                       {  
                        response?.length > 0 ?
                          <div className="mt-4">
                           <DataGrid
                              parentName={"PeriodForm"}
                              orderFormatList={orderBy}                          
                              orderFormat={setOrderBy}
                              startIndex={1}
                              theadData={Object.keys(response[0])}
                              tbodyData={response}
                              minCellWidth={80}
                              scrollHeight={"300px"}
                              handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow)}}
                              selectedRowObjectCallback={selectedIdCallback}
                              handleView={handleViewRow}
                              handleEdit={handleEditRow}           
                            /> 
                          </div> 
                          : <Message message={catchMessage.msg} type={catchMessage.type} />    }                      
                          </>
                        : <Loading />
                      }
                    </div>
                </div>
        </div>
    );
}