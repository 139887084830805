import { DataGrid } from "components/DataGrid/DataGrid";
import { Toast } from "components/ToastShow/ToastShow";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { deletePersonalPlaning, getPersonalPlanningList,changeTaskStatus } from "./api";
import { Actions } from "./components/ActionList/Actions";
import { PersonalPlaningForm } from "./components/PersonalPlaningForm";
import { ENDPOINTS } from "utils/endpoints";
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment";

export const PersonalPlaning = () => {
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showActionForm, setShowActionForm] = useState<boolean>(false);
    const [showPersonalPlaningForm, setShowPersonalPlaningForm] = useState<boolean>(false);
    const [forceUpdate, setForceUpdate] = useState(true); // integer state
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [mode, setMode] = useState<string>();
    const [showAttachment, setShowAttachment] = useState(false);
    const [loading, setLoading] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [filterlist, setFilterlist] = useState<any>("");
    const [pages, setPages] = useState<number>(1);
    const [personalTaskStatusFilter, setpersonalTaskStatusFilter] = useState<number>(0);
    
    let customFuction: any = [
        [(e) => { changeTaskStatuAsync(e.Id,1)}, "paper-plane", "active"],
        [(e) => { changeTaskStatuAsync(e.Id,2)}, "clipboard", "cancellation"],
        [(e) => { changeTaskStatuAsync(e.Id,3)}, "stop-circle", "finish"],
        [(e) => { changeTaskStatuAsync(e.Id,4)}, "resend", "suspended"],

    ]
        
        const changeTaskStatuAsync =async (p,e)=>{
                try {
                 const res = await changeTaskStatus(p,e)
                 setForceUpdate(!forceUpdate) 
                } catch (error) {
                    
                }

        }
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("PersonalPlaning"));break;
            case "refresh": setForceUpdate(!forceUpdate) ;break;
            case "create":handleCreateAction(); break;
            case "remove": if(selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "view": if(selectedObj?.Id) handleViewRow(); break;
            case "edit": if(selectedObj?.Id) handleEditRow(); break;
            case "action":if(selectedObj?.Id){setMode("edit");setShowActionForm(true)}; break;
            case "help": alert("call api help"); break;
            case "download":  break;
            case "attach": if (selectedObj?.Id) {
                setMode("edit")
                setShowAttachment(true)
            } else {
                Toast(trans("Msg_SelectRow"), "warning")
            } break;
          
        }
    };
    
    
 
    const searchHandler = (str) => {
        setPageNum(1);
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }

    const handleGoPage = (value) => {
        if (value) {
          if (value < 1 || value > pages) { }
          else setPageNum(+value);getDataAsync(+value);
        } else {
    
        }
      };

    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getDataAsync(pageNum + 1);
          };
      };
      const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getDataAsync(pageNum - 1);
          };
      };


    async function getDataAsync(PageNum =pageNum) {
        setLoading(false);
        setResponse(null)
        let pageSizeChangedByUser = getPageSize("PersonalPlaning" )
        setPageSize(pageSizeChangedByUser)
        try {
          const res = await getPersonalPlanningList(PageNum, pageSizeChangedByUser ,orderBy.length == 0 ?null:  orderBy.join(",") ,search ,filterlist ,personalTaskStatusFilter);
          setResponse(res.Data);
          setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
          setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        
         }
        setLoading(true);
      }
    
    const handleCreateAction = () => {
        setMode("create")
        setShowPersonalPlaningForm(true)
    }
    const handleEditRow = () => {
        setMode("edit");
        setShowPersonalPlaningForm(true)
       
    }
    const handleDeleteRow = async() => {
        try {
            await deletePersonalPlaning(selectedObj.Id)
            setForceUpdate(!forceUpdate)       
        } catch (error) {
            
        }
    }

    const handleViewRow = () => {
        setMode("view")
        setShowPersonalPlaningForm(true)
    }

    const selectedObjCallback = (obj, index) => {
        setSelectedObj(obj);
    }

    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("PersonalPlaning" ,e.currentTarget.value )
        getDataAsync(1)
      };

      const handleShowForm = (e) => {
        if(e == true){
            setShowPersonalPlaningForm(false) ;
            setForceUpdate(!forceUpdate)
        }else{
            setShowPersonalPlaningForm(false)
        }
    }
      
    useEffect(() => {
        getDataAsync();
      }, [forceUpdate , search ,orderBy ,filterlist  ,personalTaskStatusFilter]);


    return (
        <div className="card p-3">
            <Toolbar
                id={selectedObj?.Id}
                handleClickToolbar={clickToolbarCallback}
                handleSearch={searchHandler}
                handleSelectedFilterPersonalTaskStatus={setpersonalTaskStatusFilter}
                emptyList={response?.TotalRecords == 0}
                create={true}
                edit={true}
                view={true}
                remove={true}
                refresh={true}
                attach={true}
                search={true} 
                action={response?.TotalRecords > 0} 
                /> 
            <div className="col-lg-12 mt-0" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                <div className="list-card card">
                    <div className="list-card__body">
                        {loading ?
                            response?.Data?.length > 0 ?
                                 <>
                                     <DataGrid
                                         customFuction={customFuction}
                                         handlefiltering={(str)=>{setFilterlist(str)  }}
                                         filterStr={filterlist}
                                         orderFormat={setOrderBy}
                                         orderFormatList={orderBy}
                                         parentName={"PersonalPlaning"}
                                         startIndex={1}
                                         theadData={Object.keys(response.Data[0])}
                                         tbodyData={response.Data}
                                         minCellWidth={80}
                                         selectedRowObjectCallback={selectedObjCallback}
                                         handleEdit={handleEditRow}
                                         handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                         handleView={handleViewRow}
                                         totalRecords={response.TotalRecords}
                                         pageNum={pageNum}
                                         pageSize={pageSize}
                                         HandleNextPage={handleNextPage}
                                         HandlePrevPage={handlePrevPage}
                                         handlechangePageSize={handleChangePageSize}
                                         first={() => {getDataAsync(pages) ; setPageNum(pages)}}
                                         end={() => {getDataAsync(1) ; setPageNum(1)}}
                                         handleGoPage={handleGoPage}
                                     />
                                 </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                                : <Loading />
                        }
                    </div>
                </div>

                {showPersonalPlaningForm &&
                    <ModalForm mode={mode} modalHeight={50} title="personaltask" onHide={() => { setShowPersonalPlaningForm(false)}} show={showPersonalPlaningForm} >
                        <PersonalPlaningForm mode={mode} onHide={handleShowForm} taskId={mode == "create" ? null :selectedObj?.Id}  />
                    </ModalForm>

                }
                {showActionForm &&
                    <ModalForm mode={mode} title="Actions" onHide={() => { setShowActionForm(false)}} show={showActionForm} >
                       <Actions
                            onHide={() => setShowActionForm(false)}
                            mode={mode}
                            taskId={selectedObj?.Id} 
                        />
                    </ModalForm>
                }
                {showAttachment &&
                    <ModalForm modalHeight={60} mode={mode} title="Attachment" onHide={() => { setShowAttachment(false) }} show={showAttachment}>
                        <Attachment onHide={() => { setShowAttachment(false); setForceUpdate(!forceUpdate) }} mood={mode} endpoint={ENDPOINTS.personalPlanningApi.PersonalTask.Attachment} ownerId={selectedObj.Id} />
                    </ModalForm>
                }

            </div>

        </div>
    )
}