import React, { useEffect, useState } from "react";
import { SelectBox } from "components/Select/Select";
import { getDictionarySelectBox } from "./api";
import { InputLoad } from "components/InputLoading/inputLoading";

interface DictionaryTypeSelectProps {
  value?: any;
  mode?: string;
  resetForm?: boolean;
  revert?: boolean;
  name: string;
  label: string;
  endPoint: string;
  baseUrl?: string;
  required?: boolean;
  transe?: boolean;
  params?: any;
  recordedData?: any;
  Change?: (e) => void;
  onChange?: (e: any, obj?: any) => void;
  useTrans?: boolean;
}
export const DictionarySelectBox = ({
  recordedData,
  revert = false,
  params,
  label,
  required = false,
  baseUrl,
  useTrans = false,
  endPoint,
  value,
  name,
  mode,
  resetForm,
  onChange,
  Change,
}: DictionaryTypeSelectProps) => {
  const [dictionarySelectBox, setDictionarySelectBox] = useState<any>();
  const [defaultval, setdefault] = useState(value);
  const [errcode, setErrcode] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getDictionarySelectBoxAsync = async () => {
    setLoading(false);
    try {
      const res = await getDictionarySelectBox(endPoint, baseUrl, params);
      if (res?.Data == null) {
        setDictionarySelectBox({});
      } else {
        if (res?.Data) {
          setDictionarySelectBox(res?.Data);
          if (!value) {
            onChange &&
              onChange(Object.keys(res?.Data)[0], {
                Id: Object.keys(res?.Data)[0],
                Name: Object.values(res?.Data)[0],
              });
          } else {
            if (value) {
              onChange && onChange(value);
            }
            onChange &&
              onChange(value, {
                Id: value,
                Name: Object.values(res?.Data)[value],
              });
          }
        } else {
          setDictionarySelectBox(res);
          onChange &&
            onChange(Object.keys(res)[0], {
              Id: Object.keys(res)[0],
              Name: Object.values(res)[0],
            });
        }
      }
    } catch (error: any) {
      setErrcode(error.response.status);
      setDictionarySelectBox(null);
    }
    setLoading(true);
  };

  const handleOnChange = (e) => {
    onChange &&
      onChange(e.target.value, {
        Id: e.target.value,
        Name: dictionarySelectBox[e.target.value],
      });
    Change && Change(true);

    if (
      e.target &&
      e.target.value != (value ? value : Object.keys(dictionarySelectBox)[0])
    ) {
      Change && Change({ [name]: e.target.value });
    } else {
      Change && Change({ [name]: null });
    }
  };

  useEffect(() => {
    getDictionarySelectBoxAsync();
  }, []);
  useEffect(() => {
    if (params) {
      //getDictionarySelectBoxAsync()
    }
  }, [params]);

  useEffect(() => {
    setdefault(value);
  }, [resetForm]);

  return (
    <>
      {dictionarySelectBox && loading ? (
        <>
          <SelectBox
            onChange={(e) => {
              handleOnChange(e);
            }}
            lable={label}
            selectType={"select"}
            options={
              revert
                ? Object.keys(dictionarySelectBox)
                : Object.values(dictionarySelectBox)
            }
            val={Object.keys(dictionarySelectBox)}
            name={name}
            value={value}
            resetForm={resetForm}
            mode={mode}
            required={required}
            useTrans={useTrans}
            recordedData={recordedData}
          />
          <div className="text-box__msg-wrapper"></div>
        </>
      ) : (
        <InputLoad
          msg={errcode}
          required={required}
          labelText={label}
          onClick={getDictionarySelectBoxAsync}
        />
      )}
    </>
  );
};
