import { TabBar } from "components/Tabbar/Tabbar";
import { Toast } from "components/ToastShow/ToastShow";
import { useRef, useState } from "react";
import { trans } from "utils/helpers";

import { MyOwnRequestsList } from "./MyOwnRequestsList";




export const MyOwnRequestsPage = () => {

  const [curentTab, setCurentTab] = useState<any>("current")
  const refcurentTab = useRef(curentTab);
  const [user, setUser] = useState(false);
  const refuser = useRef(user);
  const tabChangeHandle = (tab) => {
   refcurentTab.current = tab ;
   setCurentTab(tab)
  }


  let content = [
    { title: "current", body: (<MyOwnRequestsList  curentTab={curentTab} tabName={"current"} Type={2}    />), },
    { title: "finished", body: (<MyOwnRequestsList curentTab={curentTab} tabName={"finished"} Type={3} />) },
    { title: "rejected", body: (<MyOwnRequestsList curentTab={curentTab} tabName={"rejected"} Type={4} />) },
    { title: "canceled", body: (<MyOwnRequestsList curentTab={curentTab} tabName={"canceled"} Type={5} />) },
    { title: "all", body: (<MyOwnRequestsList curentTab={curentTab} tabName={"all"} Type={null} />) },
    // { title: "MyOwnRequestRealTest", body: (<MyOwnRequestRealListTest />), },
  ];



  return (
    <>
    <div className="mt-1">
      <TabBar Click={(e)=>{Toast(trans("modalclosemessage") , "warning" , "alert" , ()=>{setUser(false); refuser.current = false})} }  tabChange={tabChangeHandle} content={content} defaultTab="current" />
    </div>
    </>
  );
};
