import { DYNAMICFORMS_URL } from "utils/constants";
import { ENDPOINTSDYNAMICFORMS } from "utils/endpoints";
import { http } from "utils/http";

export const  postForm =(  (data) =>   http({
   baseURL:DYNAMICFORMS_URL,
   url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.dynamicForm,
   method: "post",
   data:data
})

.then((respons) => (respons.data))
.catch((err) => {

    return Promise.reject( err);
})
)

export const  deleteForm =(  (formKey) =>   http({
    baseURL:DYNAMICFORMS_URL ,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.dynamicForm,
    method: 'delete',
    params: {
        formKey:formKey
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)

export const putForm = (data) => http({
    baseURL:DYNAMICFORMS_URL ,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.dynamicForm,
    method: 'put',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

export const getFormByKey = (formKey) => http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.formKey,
    method: 'get',
    params: {
        formKey: formKey,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});


export const getAllForms = () => http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.allForms,
    method: 'get',

}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});
export const getDictionaryAvailable = () => http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.dataCollection,
    method: 'get',

}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});



export const checkForm = (formKey) => http({
    baseURL:DYNAMICFORMS_URL ,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.checkEmpty,
    method: 'get',
    params:{
        formKey: formKey
    }

}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

export const CheckExist = (FormKey,FormTitle,Id?) => http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.CheckExist,
    method: 'get',
    params: {
        Id:Id,
        FormKey:FormKey,
        FormTitle:FormTitle,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});

export const getDynamicDictionaryDictionaryApi = (async (
   DynamicFormPropertyId
) => await http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.dataCollectionPropertiesDictionary,
    method: 'get',
    params:{

         DynamicFormPropertyId
        }
    
}).then((res) => {

    return res.data.Data
}).catch((err) => {
 
    return Promise.reject( err);
})

)