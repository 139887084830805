
import { ModalForm } from 'components/ModalForm/ModalForm'
import { useEffect, useState } from 'react'
import { trans } from 'utils/helpers'
import './style.scss'
export const Help = ({modalWidth}) => {
    
    const [show, setShow] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [text, setText] = useState("");
    const [com, setCom] = useState("");
    const [Storage, setStorage] = useState<any>([]);
    function showModal(e) {
        setCom(e);
        setShow(true);
    }
   
    const breakPoint = modalWidth


    
    const storageLengthDividedByTwo= Math.ceil(Object.keys(Storage).length / 2)
    const dataArr1 = Object.keys(Storage).slice(0, storageLengthDividedByTwo)
    const dataArr2 = Object.keys(Storage).slice(storageLengthDividedByTwo)
    const halfStorageDataArray = [dataArr1, dataArr2]    

    
    window.onresize = () => {
      setWidth(window.innerWidth)
    }
    
    
    function set(event) {
        event.preventDefault();

        let shortcut = '';
        if (event.ctrlKey) {
            shortcut += 'ctrl +';
        }
        else if (event.altKey) {
            shortcut += 'alt +';
        }
        else if (event.shiftKey) {
            shortcut += 'shift +';
        }
        if(event.key == "Delete"){
            shortcut = shortcut + " Del" ;
        }else{
         shortcut = shortcut + " " + event.key;           
        }

        // var code = shortcut.charCodeAt(0);

        setText(shortcut);
        let storage = JSON.parse(localStorage.Shortcut);
/*         let index = storage.findIndex(item => item.command === com);
        storage[index] = Object.assign({}, storage[index], { key: shortcut }); */
        storage[com] = shortcut
        localStorage.setItem("Shortcut", JSON.stringify(storage));
        document.querySelector(".help__input")?.addEventListener("keyup", () => {
            setShow(false);
            setText("");
        })

    }
    useEffect(() => {
        setStorage(JSON.parse(localStorage.Shortcut));
    }, [text])
    return ( 
    <div className='help w-100'>
            <div className='d-flex w-100'>
                {breakPoint ? (
                    <div className='w-100 mx-4'>
                        <div className='help__header d-flex mx-auto'>
                            <h6>دستور</h6>
                            <h6>کلید</h6>
                        </div>
                        {Object.keys(Storage).map(data => (
                            <div onClick={showModal} className='help__item mx-auto'>
                                <p className='command'>{(data)}</p>
                                <span>{Storage[data]}</span>
                            </div>
                        ))}
                    </div> 
                    ):
                    (halfStorageDataArray.map(item => (
                        <div className='w-100 mx-4'>
                            <div className='help__header d-flex mx-auto'>
                                <h6>دستور</h6>
                                <h6>کلید</h6>
                            </div>
                            {item.map(data => (
                                <div onClick={()=>{showModal(data)}} className='help__item mx-auto'>
                                <p className='command'>{trans(data)}</p>
                                <span>{Storage[data]}</span>
                                </div>
                            ))}
                        </div>
                    ))) 
                }
                {show&&
                
                <ModalForm title={"کلید ترکیبی دلخواه را وارد کنید"} onHide={() => setShow(false)} show={show}>
                    <div className='help__input  mx-auto'>
                    <input type="text" readOnly onKeyDown={set} value={text} />
                    </div>
                </ModalForm>
                }
            </div>
        </div>
    )
}