
import { useEffect, useRef, useState } from "react";
import { GroupingPriceFunction, trans } from "utils/helpers";





interface EditableInputPropsType {
  onBlurCallBack: (e?: any) => void;
  defaultValue:any;
  type:any;
  defaultValueOption:any;
  columnsWithOption:any;
  column:any;
}

export const EditableInput = ({ onBlurCallBack,columnsWithOption,column, defaultValue, type, defaultValueOption }: EditableInputPropsType) => {
  const [valueName, setValueName] = useState<any>(defaultValue);
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [validat , setValidat] = useState(false);
  const [count,setCount] = useState<number>(0);
  const inputRef = useRef<any>();
  const inputIdRef = useRef<any>();
  const validRef = useRef<any>(false);
  const countRef = useRef<any>(0)
  const [checkedInput,setCheckedInput]=useState<any>(false)

  let optionList : any =defaultValueOption


  const Auto = (e) => {
    validRef.current = false
    if(e.target.value.length ==0&&columnsWithOption.includes(column)){
      inputRef.current.value = trans("CallForPrice")
      inputIdRef.current.value = -1;
    }else{
      let regex = /^[0-9,.]+$/
      if(type=="Decimal"){
        if( regex.test(e.target.value)|| e.target.value.length==0){
          inputIdRef.current.value = e.target.value
        }else inputRef.current.value = inputIdRef.current.value
      }else inputIdRef.current.value = e.target.value 
    }
    setValidat(false)
    setShow(true)
    setFlag(!flag)

  }

  function userSelecter(){
   if(optionList.length){
     setValeu(optionList[countRef.current],-1)
     setShow(false)

   }
  }

  const kydown = (event) => {    
    if((event.key === "ArrowDown")){
      if(countRef.current < optionList?.length-1){
        countRef.current = countRef.current + 1;
      }else{
        countRef.current = 0;
      }
    }
    
    if(event.key === "ArrowUp"){
      if(countRef.current > 0){
        countRef.current = countRef?.current -1;
      }else{
        countRef.current = optionList?.length-1;
      }
    }
    document.getElementById("EditableInput"+countRef.current)?.scrollIntoView({behavior:"smooth"})
    setCount(countRef.current)

    if(event.key === "Enter"){
      userSelecter()
    }
  }
  const CheckPriceList =async (e)=>{
    if(type!=='Boolean'){
      if(!(e.target.value.length>0) && optionList){
        inputRef.current.value =trans("CallForPrice")
        inputIdRef.current.value = -1;
        onBlurCallBack( -1)
      }else
      if(inputRef.current.value<0){
        inputRef.current.value=0
      }
      onBlurCallBack( inputIdRef.current.value.replaceAll(",", ""))
    }else{
      onBlurCallBack(inputIdRef.current.checked)

    }

  }

  const setValeu = (newValue, id) => {
    if(inputIdRef != null && inputIdRef.current != null){
      validRef.current = true
      inputRef.current.value = newValue
      inputIdRef.current.value = id;
      setFlag(!flag)
      setValidat(false)
    }
  }

  useEffect(() =>{
    if(show ){
      document.addEventListener('keydown', kydown);
      document.body.addEventListener("click", clickToclose)
    }
     return () => { document.removeEventListener("keydown", kydown);document.body.removeEventListener('click', clickToclose) }
  },[show ,optionList])

  useEffect(() =>{
    if(defaultValue==-1){
      inputRef.current.value =trans("CallForPrice")
      inputIdRef.current.value = -1;
    }
  },[])

  function clickToclose(e){
    let input = document.querySelector("#browser");
    if (e.target.id !== input?.id) { 
      setShow(false);
    }
  }



  return (
    <>

    {
      type!=="Boolean" ?
      <>
      <input className="d-none" ref={inputIdRef} />
      <input style={{borderRadius:"0"}} onBlur={CheckPriceList} defaultValue={valueName} value={type=="Decimal"?GroupingPriceFunction(inputRef.current?.value?.replaceAll(",", "")) :inputRef.current?.value } autoComplete="off" onFocus={(e)=>{  setShow(true);Auto(e)}}  ref={inputRef} list="EditableInput" name="browser" className="text-box__box form-control " type={type=="Decimal"?'text':'text'}    id={"browser"} onMouseDown={(e)=>e.stopPropagation() } onChange={Auto}  />
      </>
      :
      <>
     <input  type="checkbox"  ref={inputIdRef} defaultChecked={defaultValue}  onChange={(e)=>CheckPriceList(e)}/>
 

     
 </>
}
     
    
    </>
   )
}