import {  useState, useRef, useEffect } from "react";
import DatePicker from "components/DatePicker/DatePicker";
interface DateFilterPropsType {
  id?: string,
  defaultValue?: any,
  hasBorder?: boolean,
  resetForm?: boolean,
  required?: boolean,
  value?: string,
  mode?: string,
  name?: string,
  Change?: (e) => void,
  onChange?: (e) => void,
}

export const DateFilter = ({defaultValue, id, value ,required , name="", Change ,onChange}: DateFilterPropsType) => {
  const [date, setDate] = useState<any>(defaultValue);
  const inputIdRef = useRef<any>();
  
  useEffect(() => {

    if (defaultValue  &&   defaultValue!= undefined &&  defaultValue.length > 0) {
        setDate(defaultValue.split("&"))
    }
  }, [defaultValue]);

  return (
    <>
        <input required={required} defaultValue={defaultValue} value={(date!=undefined && date.length ==2 )? date[0].split("T")[0]+"&"+date[1].split("T")[0] : ""} id={id} name={"filter"}  className="d-none" ref={inputIdRef} />
        <div className="filterInput">
        <DatePicker range={true} portal={true} fixMainPosition={true} className={"form-control rmdp-mobile"}
         setIsoTime={(IsoTime)=>{setDate(IsoTime)}} val={date }  />

        </div>

    </>
  )
}