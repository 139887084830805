import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";




export const getReceiverPositionDictionaryApi = (async (id) => await http({
    url: ENDPOINTS.secretariat.letterReceiver.dictionaryId,
    method: 'get',
    params: {
        receiverId: id
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)
export const getReceiverPositionDictionaryListApi = (async (search,id) => await http({
    url: ENDPOINTS.secretariat.letterReceiver.dictionary,
    method: 'get',
    params: {
        searchString:search
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)