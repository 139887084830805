import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Address } from "components/Address/Address";
import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import Datepicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
import { Headerbar } from "components/Headerbar/Headerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useRef, useState } from "react";
import { ContactType, MessageType } from "types/enum";
import { GUID_EMPTY, ICON_TYPE, INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { convertAddress, GroupingPriceFunction, trans } from "utils/helpers";
import { CustomerTabform } from "../BaseDefinitionAndSetting/Customers/CustomerTabForm";
import { CurrencyDictionary } from "../PriceList/components/CurrencyDictionary/CurrencyDictionary";
import { PriceListDictionary } from "../PriceList/components/PriceListDictionary/PriceListDictionary";
import { SalesInvoiceDetailForm } from "./SalesInvoiceDetailForm";
import {
  CalculateSalesInvoice,
  getDefaultPersonContact,
  getInvoiceConstraint,
  getPersonCustomer,
  getSalesInvoiceById,
  getSalesInvoiceOrGenerate,
  optionalDiscountHandler,
  postSalesInvoice,
  postSalesInvoiceWorkFlow,
  putSalesInvoice,
  putSalesInvoiceworkflow,
} from "./api";
import { AddressBox } from "./components/AddressBox/AddressBox";
import { CustomerDictionary } from "./components/CustomerDictionary/CustomerDictionary";
import { CustomerPhoneDictionary } from "./components/CustomerPhoneDictionary/CustomerPhoneDictionary";
import { CustomerPhoneList } from "./components/CustomerPhones/CustomerPhoneList";
import { DocsClassification } from "./components/DocsClassification/DocsClassification";
import { getSalesInvoiceNo } from "./components/SalesInvoiceNo/api";
import { SalesInvoiceSourceId } from "./components/SalesInvoiceSourceId";
import { Person } from "components/Person/Person";

interface SalesInvoiceFormProps {
  onHide?: () => void;
  relod: () => void;
  jobId?: any;
  JobNo?: any;
  processId?: any;
  referenceId?: any;
  mode: any;
  step?: any;
  rowObject?: any;
  taskId?: any;
  SourceData?: any;
  sourceDataParam?: any;
  useGenerate?: any;
  sourceId?: any;
}

export const SalesInvoiceForm = ({
  mode,
  relod,
  onHide,
  rowObject,
  step,
  referenceId,
  taskId,
  jobId,
  JobNo,
  processId,
  sourceDataParam,
  SourceData,
  useGenerate=true,
  sourceId,
}: SalesInvoiceFormProps) => {
  const [resetForm, setResetForm] = useState(false);
  const [haveSource, setHaveSource] = useState(null);
  const [response, setResponse] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});
  const [listPosition, setListPosition] = useState<any>({});
  const [customerInfo, setCustomerInfo] = useState<any>(null);
  const [customer, setCustomer] = useState<any>(null);
  const [salesInvoiceDate, setSalesInvoiceDate] = useState<any>();
  const [cottageDate, setCottageDate] = useState<any>();
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [showForm, setShowForm] = useState(false);
  const [showAddressBox, setShowAddressBox] = useState<
    "createMode" | "selectMode" | null
  >(null);
  const [showCustomerPhoneForm, setShowCustomerPhoneForm] = useState(false);
  const [showCustomerform, setShowCustomerform] = useState<boolean>(false);
  const [priceList, setPriceList] = useState<any>(null);
  const [salesInvoiceDetail, setSalesInvoiceDetail] = useState<any>([]);
  const [dataGridLoading, setDataGridLoading] = useState<any>(true);
  const [lastWarehouse, setLastWarehouse] = useState<any>(null);
  const [invoiceConstraint, setInvoiceConstraint] = useState<any>({});
  const [responseList, setResponseList] = useState<any>(null);

  const [docsClassification, setDocsClassification] = useState<any>(null);
  const [totalRows, setTotalRows] = useState<any>({
    ItemCount: 0,
    TotalTax: 0,
    TotalDiscount: 0,
    SubTotalPrice: 0,
  });
  const [payment, setPayment] = useState<any>({
    totalprice: 0,
    Addition: 0,
    Deduction: 0,
    Prepayment: 0,
  });
  const [receive, setReceive] = useState<any>({
    GrandTotalPrice: 0,
    Prepayment: 0,
    DebitPayment: 0,
    NonDebitPayment: 0,
  });
  const [todayDate, settodayDate] = useState<string | null>(
    new Date().toISOString()
  );
  const [optionalDiscount, setOptionalDiscount] = useState<any>(null);
  const [dataChange, setDataChange] = useState<any>(false);
  const [detailMode, setDetailMode] = useState<"create" | "edit" | "view">(mode);
  const [increased, setIncreased] = useState<any>(false);
  const [currencyInfo, setCurrencyInfo] = useState<any>();
  const [changeAddress, setChangeAddress] = useState<any>();
  const [typePhoneContact, setTypePhonContact] = useState<any>();
  const [SendSMS, setSendSMS] = useState(false);
  const [nextInvoiceNumber,setNextInvoiceNumber]=useState<any>()
  const [person, setPerson] = useState<any>(null);

  const invoiceRef = useRef<any>(null);

  const lang = localStorage.getItem("lang");


  

  function setSalesInvoiceDetailHandler(data) {
    setDataGridLoading(false);
    setSalesInvoiceDetail(data);
    setDataGridLoading(true);
  }
  const handleSalesInvoiceDetailFormSubmit = async (event) => {
    event.preventDefault();
    let SubTotalPrice: any = 0;
    let newsalesInvoiceDetail = JSON.parse(JSON.stringify(salesInvoiceDetail)).map((e: any) => {
      SubTotalPrice = +e.TotalPrice + SubTotalPrice;
      delete e.Id;
      return e;
    });

    let data: any = {
      RecipientPersonDescription:invoiceConstraint?.IsRecipientSpecified? person?.Name:null,
      RecipientPersonId:invoiceConstraint?.IsRecipientSpecified? person?.Id:null,
      CurrencyId: event.target?.CurrencyId?.value,
      SalesInvoiceNo: event.target.salesInvoiceNo.value,
      SalesInvoiceDate: salesInvoiceDate,
      DocsClassificationId: event.target.docsClassificationId.value!==GUID_EMPTY?event.target.docsClassificationId.value:null,
      PriceListId: event.target.PriceList?.value,
      PaymentMethodId: event.target.PaymentMethodId?.value,
      CustomerId: customerInfo.Id,
      CustomerName: customer.Name,
      CustomerPhone:customer.SundryCustomer?event.target?.CustomerPhone?.value: customer?.CustomerPhone?.Name.split(":")[1]?customer?.CustomerPhone?.Name.split(":")[1] :customer?.CustomerPhone?.Name??null,
      CustomerMobilePhone:customer.SundryCustomer?event.target?.CustomerMobilePhone?.value: customer?.CustomerMobilePhone?.Name.split(":")[1]?customer?.CustomerMobilePhone?.Name.split(":")[1] :customer?.CustomerMobilePhone?.Name??null,
      CustomerAddress: event.target.Address?.value??null,
      CustomerEconNo: event.target.CustomerEconNo?.value,
      CustomerNationalCode: event.target.CustomerNationalCode?.value,
      SalesManagerPositionId: listPosition?.SalesManagerPosition?.Id,
      SalesManagerPositionDescription: listPosition?.SalesManagerPosition?.Name,
      SalesManagerPositionDescriptionEN:
        event.target.SalesManagerPositionDescriptionEN?.value,
      VisitorPositionId: listPosition?.VisitorPosition?.Id,
      VisitorPositionDescription: listPosition?.VisitorPosition?.Name,
      VisitorPositionDescriptionEN:
        event.target.VisitorPositionDescriptionEN?.value,
      DistributorPositionId: listPosition?.DistributorPosition?.Id,
      DistributorPositionDescription: listPosition?.DistributorPosition?.Name,
      DistributorPositionDescriptionEN:
        event.target.DistributorPositionDescriptionEN?.value,
      CottageDate: cottageDate,
      DeliveryAddress: event.target.DeliveryAddress?.value,
      CottageNo: event.target.CottageNo?.value,
      PaymentRemark: event.target.PaymentRemark?.value,
      IsCurrancyInvoice: event.target.IsCurrancyInvoice?.checked,
     // CurrencyPrepayment: +event.target.CurrencyPrepayment?.value,
      Description: event.target.Description?.value,
      SourceId: response?.SourceId,
      SourceData: haveSource?haveSource:response?.SourceData,
      IsCurrency: !currencyInfo?.IsDefault,
      CurrencyRate: currencyInfo?.CurrencyRate,
      Addition: +event.target.Addition?.value.replaceAll(",", ""),
      Deduction: +event.target.Deduction?.value.replaceAll(",", ""),
      TotalDiscount: +totalRows?.TotalDiscount,
      TotalPrice: +payment.totalprice,
      TotalTax: (docsClassification?.CalculateTax ||(docsClassification?.CalculateTax==null&& invoiceConstraint.IsDocTaxConfirmed)) ?    +totalRows?.TotalTax: 0,

      GrandTotalPrice:
        +totalRows?.SubTotalPrice -
        +totalRows?.TotalDiscount +
        +totalRows?.TotalTax +
        +event.target.Addition?.value.replaceAll(",", "") -
        +event.target.Deduction?.value.replaceAll(",", ""),
      DebitPayment: +event.target.DebitPayment?.value.replaceAll(",", ""),
      NonDebitPayment: +event.target.NonDebitPayment?.value.replaceAll(",", ""),
      CreditPayment: +event.target.CreditPayment?.value.replaceAll(",", ""),
      Prepayment: +event.target.Prepayment?.value.replaceAll(",", ""),
      SubTotalPrice: +totalRows?.SubTotalPrice,
      SalesInvoiceDetailDTOList: newsalesInvoiceDetail,
    };
    try {
      if (mode == "create") {
        const res = await postSalesInvoice(data, SendSMS);
        if (res.MessageType == MessageType.success) {
          onHide && onHide();
          relod();
        }
      } else {
        if (jobId) {
          if (response.Id == GUID_EMPTY) {
            const res = await postSalesInvoiceWorkFlow(
              {
                ...data,
                JobId: jobId,
                JobNo: response.JobNo ? response.JobNo : JobNo,
                TAS_UID: taskId,
                Id: response.Id,
              },
              referenceId,
              increased,
              SendSMS
            );
            getInvoiceAsync();
          } else {
            const res = await putSalesInvoiceworkflow(
              {
                ...data,
                JobId: jobId,
                JobNo: response.JobNo ? response.JobNo : JobNo,
                TAS_UID: taskId,
                Id: response?.Id,
              },
              referenceId
            );
          }
        } else {
          const res = await putSalesInvoice({
            ...data,
            JobId: response.JobId,
            JobNo: response.JobNo ? response.JobNo : JobNo,
            TAS_UID: taskId,
            Id: response?.Id,
          });
        }
        //  await   putSalesInvoiceDetail(data)
      }
    } catch (error) {}
    document.body.classList.remove("loading-indicator-" + lang);
  };

  const sendDataForCalcAsync = async (newPriceList ,Classification=null) => {
  //  setResponseList(null)
    let SubTotalPrice: any = 0;
    let newsalesInvoiceDetail = JSON.parse(
      JSON.stringify(salesInvoiceDetail)
    ).map((e: any) => {
      SubTotalPrice = +e.TotalPrice + SubTotalPrice;
      
      return e;
    });
    let data: any = {
      RecipientPersonDescription:invoiceConstraint?.IsRecipientSpecified? person?.Name:null,
      RecipientPersonId:invoiceConstraint?.IsRecipientSpecified? person?.Id:null,
      CurrencyId: invoiceRef.current?.CurrencyId?.value,
      SalesInvoiceNo: invoiceRef.current?.salesInvoiceNo?.value,
      SalesInvoiceDate: salesInvoiceDate,
      DocsClassificationId:invoiceRef.current?.docsClassificationId?.value!==GUID_EMPTY?invoiceRef.current?.docsClassificationId?.value:null,
      PriceListId: invoiceRef.current?.PriceList?.value,
      PaymentMethodId: invoiceRef.current?.PaymentMethodId?.value,
      CustomerId: customerInfo?.Id,
      CustomerName: customer?.Name,
      CustomerPhone:customer.SundryCustomer?invoiceRef.current?.CustomerPhone?.value: customer?.CustomerPhone?.Name.split(":")[1]?customer?.CustomerPhone?.Name.split(":")[1] :customer?.CustomerPhone?.Name??null,
      CustomerMobilePhone:customer.SundryCustomer?invoiceRef.current?.CustomerMobilePhone?.value: customer?.CustomerMobilePhone?.Name.split(":")[1]?customer?.CustomerMobilePhone?.Name.split(":")[1] :customer?.CustomerMobilePhone?.Name??null,
      CustomerAddress: invoiceRef.current.Address?.value??null,
      CustomerEconNo: invoiceRef.current?.CustomerEconNo?.value,
      CustomerNationalCode: invoiceRef.current?.CustomerNationalCode?.value,
      SalesManagerPositionId: listPosition?.SalesManagerPosition?.Id,
      SalesManagerPositionDescription: listPosition?.SalesManagerPosition?.Name,
      SalesManagerPositionDescriptionEN:
        invoiceRef.current?.SalesManagerPositionDescriptionEN?.value,
      VisitorPositionId: listPosition?.VisitorPosition?.Id,
      VisitorPositionDescription: listPosition?.VisitorPosition?.Name,
      VisitorPositionDescriptionEN:
        invoiceRef.current?.VisitorPositionDescriptionEN?.value,
      DistributorPositionId: listPosition?.DistributorPosition?.Id,
      DistributorPositionDescription: listPosition?.DistributorPosition?.Name,
      DistributorPositionDescriptionEN:
        invoiceRef.current?.DistributorPositionDescriptionEN?.value,
      CottageDate: cottageDate,
      DeliveryAddress: invoiceRef.current?.DeliveryAddress?.value,
      CottageNo: invoiceRef.current?.CottageNo?.value,
      PaymentRemark: invoiceRef.current?.PaymentRemark?.value,
      IsCurrancyInvoice: invoiceRef.current?.IsCurrancyInvoice?.checked,
      //CurrencyPrepayment: +invoiceRef.current?.CurrencyPrepayment?.value,
      Description: invoiceRef.current?.Description?.value,
      SourceId: response?.SourceId,
      SourceData: haveSource?haveSource:response?.SourceData,
      IsCurrency: !currencyInfo?.IsDefault,
      CurrencyRate: currencyInfo?.CurrencyRate,
      Addition: +invoiceRef.current?.Addition?.value.replaceAll(",", ""),
      Deduction: +invoiceRef.current?.Deduction?.value.replaceAll(",", ""),
      TotalDiscount: +totalRows?.TotalDiscount,
      TotalPrice: +payment.totalprice,
      TotalTax: (docsClassification?.CalculateTax ||(docsClassification?.CalculateTax==null&& invoiceConstraint.IsDocTaxConfirmed)) ?    +totalRows?.TotalTax: 0,
      GrandTotalPrice:
        +totalRows?.SubTotalPrice -
        +totalRows?.TotalDiscount +
        +totalRows?.TotalTax +
        +invoiceRef.current?.Addition?.value.replaceAll(",", "") -
        +invoiceRef.current?.Deduction?.value.replaceAll(",", ""),
      DebitPayment: +invoiceRef.current?.DebitPayment?.value.replaceAll(",", ""),
      NonDebitPayment: +invoiceRef.current?.NonDebitPayment?.value.replaceAll(",", ""),
      CreditPayment: +invoiceRef.current?.CreditPayment?.value.replaceAll(",", ""),
      Prepayment: +invoiceRef.current?.Prepayment?.value.replaceAll(",", ""),
      SubTotalPrice: +totalRows?.SubTotalPrice,
      SalesInvoiceDetailDTOList: newsalesInvoiceDetail,
      JobId: jobId,
      JobNo: response.JobNo ? response.JobNo : JobNo,
      TAS_UID: taskId,
      Id: response.Id,
    };
    
    
    try {
      
      if ((mode !== "view"&& priceList.Id != newPriceList?.Id)||newPriceList==null) {
        const res = await CalculateSalesInvoice(data);
        setResponse(res.Data);


        setSalesInvoiceDetail(res.Data?.SalesInvoiceDetailDTOList	);
        let newItemCount = 0;
        res.Data?.SalesInvoiceDetailDTOList.map((item) => {
          newItemCount += item.Qty1;
        });
        setTotalRows({
          ItemCount: newItemCount,
          TotalTax:(docsClassification?.CalculateTax ||(docsClassification?.CalculateTax==null&& invoiceConstraint.IsDocTaxConfirmed))? res.Data.TotalTax:0,
          TotalDiscount: res.Data?.TotalDiscount,
          SubTotalPrice: res.Data?.SubTotalPrice,
        });
        setPayment({
          totalprice: res.Data?.totalprice,
          Addition: res.Data?.Addition,
          Deduction: res.Data?.Deduction,
          Prepayment: res.Data?.Prepayment,
        });
        setReceive({
          GrandTotalPrice: res.Data?.GrandTotalPrice,
          Prepayment: res.Data?.Prepayment,
          DebitPayment: res.Data?.DebitPayment,
          NonDebitPayment: res.Data?.NonDebitPayment,
        });
        setResponseList(null)

      }
    } catch (error:any) {
      if(error.response.data.Data[0]&&error.response.data.Data[0]?.Message){
        let obj = {}
        error.response.data.Data.map(item=>{
          obj[item.Data] = item
        })
        setResponseList(obj)
      }
    } 
    if(newPriceList) setPriceList(newPriceList)
    if(Classification) setDocsClassification(Classification)
  };


  async function getDefaultPersonContactAsync(personId) {

    
    if (personId != customerInfo?.Id || customerInfo == null)
      try {

        const res2 = await getPersonCustomer(personId);

        setCustomer((pre) => {
          return {
            ...pre,
            Id: res2.Data.Customer.Id,
            Name: res2.Data.Customer.CustomerPersonDescription,
            SundryCustomer: res2.Data.Customer.Id == invoiceConstraint?.SundryCustomerId,
          };
        });
        if (res2.Data.Customer.Id == invoiceConstraint?.SundryCustomerId	) {
          setCustomerInfo({  ...res2?.Data?.Customer });
        } else {
          const res = await getDefaultPersonContact(
            res2?.Data?.Customer?.CustomerPersonId
          );
          setCustomerInfo({ ...res.Data, ...res2?.Data?.Customer });
        }



        setListPosition({
          DistributorPosition: {
            Id: res2?.Data?.Customer.DistributorPositionId,
            Name: res2?.Data?.Customer.DistributorPositionDescription,
          },
          VisitorPosition: {
            Id: res2?.Data?.Customer.VisitorPositionId,
            Name: res2?.Data?.Customer.VisitorPositionDescription,
          },
        });
      } catch (error) {}
  }
  async function getInvoiceConstraintAsync(withLoading = false) {
    if (withLoading) setLoading(false);
    try {
      const res = await getInvoiceConstraint();
      setInvoiceConstraint(res.Data);
      setNextInvoiceNumber(res.Data?.DocNo)
    if (withLoading) setLoading(true);
      return res.Data
    } catch (error) {}
    if (withLoading) setLoading(true);
  }

  async function getInvoiceAsync() {
    setLoading(false);
   const constraint= await getInvoiceConstraintAsync();
    try {
      if (jobId) {
        let res:any
        if(useGenerate &&(!(!sourceDataParam && mode=='view')) ){
             res = await getSalesInvoiceOrGenerate(
            sourceDataParam ,
            sourceId,
            referenceId,
            jobId
          );

        }else{
           res = await getSalesInvoiceById(jobId);
        }
          if(res.Data.Id !=GUID_EMPTY) setNextInvoiceNumber(res.Data.SalesInvoiceNo)
          setResponse(res.Data);
          let count = 0;
          res.Data.SalesInvoiceDetailDTOList.map((item) => {
            count += +item?.Qty1;
          });
          setHaveSource(res.Data.SourceData);
          setSalesInvoiceDetail(res.Data.SalesInvoiceDetailDTOList);
          setTotalRows({
            ItemCount: count,
            TotalTax: constraint?.IsDocTaxConfirmed? res.Data.TotalTax:0,
            TotalDiscount: res.Data?.TotalDiscount,
            SubTotalPrice: res.Data?.SubTotalPrice,
          });
          setPayment({
            totalprice: res.Data?.totalprice,
            Addition: res.Data?.Addition,
            Deduction: res.Data?.Deduction,
            Prepayment: res.Data?.Prepayment,
          });

          setReceive({
            GrandTotalPrice: res.Data?.GrandTotalPrice,
            Prepayment: res.Data?.Prepayment,
            DebitPayment: res.Data?.DebitPayment,
            NonDebitPayment: res.Data?.NonDebitPayment,
          });
        
      } else {
        const res = await getSalesInvoiceById(rowObject.Id);
        setResponse(res.Data);
        let count = 0;
        res.Data.SalesInvoiceDetailDTOList.map((item) => {
          count += +item?.Qty1;
        });
        setSalesInvoiceDetail(res.Data.SalesInvoiceDetailDTOList);
        setTotalRows({
          ItemCount: count,
          TotalTax:constraint?.IsDocTaxConfirmed? res.Data.TotalTax:0,
          TotalDiscount: res.Data?.TotalDiscount,
          SubTotalPrice: res.Data?.SubTotalPrice,
        });
        setPayment({
          totalprice: res.Data?.totalprice,
          Addition: res.Data?.Addition,
          Deduction: res.Data?.Deduction,
          Prepayment: res.Data?.Prepayment,
        });
        setReceive({
          GrandTotalPrice: res.Data?.GrandTotalPrice,
          Prepayment: res.Data?.Prepayment,
          DebitPayment: res.Data?.DebitPayment,
          NonDebitPayment: res.Data?.NonDebitPayment,
        });
      }
    } catch (error) {}
    setLoading(true);
  }
  async function optionalDiscountHandlerAsync() {
    let data = {
      CurrencyRoundingPlace: currencyInfo?.CurrencyRoundingPlace,
      OptionalDiscount: +optionalDiscount,
      Addition: +payment.Addition,
      Deduction: +payment.Deduction,
      DetailDTOList: salesInvoiceDetail,
    };
    try {
      const res = await optionalDiscountHandler(data);
      setTotalRows({
        ItemCount: res.Data.ItemCount,
        TotalTax:(docsClassification?.CalculateTax ||(docsClassification?.CalculateTax==null&& invoiceConstraint.IsDocTaxConfirmed))? res.Data.TotalTax:0,
        TotalDiscount: res.Data.TotalDiscount,
        SubTotalPrice: res.Data.SubTotalPrice,
      });

      
      setSalesInvoiceDetailHandler(res.Data.SalesInvoiceDetailDTOList);
    } catch (error) {}
  }



  const handleOpenDetailForm=()=>{
      if (priceList) {
        if(customerInfo?.CustomerPersonId|| customer?.SundryCustomer){
          setShowForm(true);
          setDetailMode("create");
        }else{
        Toast("please select customer", "error");
        }
      } else {
        Toast("please select priceList", "error");
      }
    
}

  const handlesetPriceListDetail = (newObj) => {
    if (detailMode == "create") setIncreased(true);
    if (invoiceConstraint.AllowDuplicateItems || detailMode == "edit") {
      setTotalRows({
        ItemCount:
          detailMode == "create"
            ? totalRows.ItemCount + +newObj.Qty1
            : totalRows.ItemCount + (+newObj.Qty1 - +selectedObj.Qty1),
        TotalTax:
          detailMode == "create"
            ? totalRows.TotalTax + +newObj.Tax
            : totalRows.TotalTax + (+newObj.Tax - +selectedObj.Tax),
        TotalDiscount:
          detailMode == "create"
            ? totalRows.TotalDiscount + +newObj.Discount
            : totalRows.TotalDiscount +
              (+newObj.Discount - +selectedObj.Discount),
        SubTotalPrice:
          detailMode == "create"
            ? totalRows.SubTotalPrice + +newObj.SubTotalPrice
            : totalRows.SubTotalPrice +
              (+newObj.SubTotalPrice - +selectedObj.SubTotalPrice),
      });

      
      if (detailMode == "create") {
        setSalesInvoiceDetailHandler([...salesInvoiceDetail, newObj]);
      } else {
        let newItemes = salesInvoiceDetail?.map((item: any) => {
          if (item.Id == newObj.Id) {
            return newObj;
          } else return item;
        });
        setSalesInvoiceDetailHandler(newItemes);
      }
      setDataChange(true);
      return true
    } else {
    
      let Dupli = salesInvoiceDetail?.find(
        (e: any) => e.ItemId == newObj.ItemId
      );
      if (!Dupli || salesInvoiceDetail.length == 0) {
        setTotalRows({
          ItemCount:
            detailMode == "create"
              ? totalRows.ItemCount + newObj.Qty1
              : totalRows.ItemCount + (+newObj.Qty1 - +selectedObj.Qty1),
          TotalTax:
            detailMode == "create"
              ? totalRows.TotalTax + +newObj.Tax
              : totalRows.TotalTax + (+newObj.Tax - +selectedObj.Tax),
          TotalDiscount:
            detailMode == "create"
              ? totalRows.TotalDiscount + +newObj.Discount
              : totalRows.TotalDiscount +
                (+newObj.Discount - +selectedObj.Discount),
          SubTotalPrice:
            detailMode == "create"
              ? totalRows.SubTotalPrice + +newObj.SubTotalPrice
              : totalRows.SubTotalPrice +
                (+newObj.SubTotalPrice - +selectedObj.SubTotalPrice),
        });
        if (detailMode == "create") {
          setSalesInvoiceDetailHandler([...salesInvoiceDetail, newObj]);
        } else {
          let newItemes = salesInvoiceDetail?.map((item: any) => {
            if (item.Id == newObj.Id) {
              return newObj;
            } else return item;
          });
          setSalesInvoiceDetailHandler(newItemes);
        }
        setDataChange(true);
        Toast("msg_saveddatasuccessfully", "success");
        return true
      } else {
        Toast("item cant be Duplicate", "error");
        return false
      }
    }
  };
  const selectedObjCallback = (obj, index) => {
    setSelectedObj(obj);
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        onHide && onHide();
      });
    } else {
      onHide && onHide();
    }
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  const handleDeleteRow = async (id: string = selectedObj.Id) => {
    let newPriceListDetail: any = [...salesInvoiceDetail];
    let obj = newPriceListDetail.filter((e) => e.Id == id)[0];

    setTotalRows({
      ItemCount: totalRows.ItemCount - +obj.Qty1,
      TotalTax: totalRows.TotalTax - +obj.Tax,
      TotalDiscount: totalRows.TotalDiscount - +obj.Discount,
      SubTotalPrice: totalRows.SubTotalPrice - +obj.SubTotalPrice,
    });
    setSalesInvoiceDetailHandler(newPriceListDetail.filter((e) => e.Id != id));
  };

  const handleSourceData = (obj, type) => {
    setResponse(obj);
    setHaveSource(type);
    setPriceList({ ...priceList, Id: obj.PriceListId });
    setSalesInvoiceDetailHandler(obj.SalesInvoiceDetailDTOList);
    setTotalRows({
      ItemCount: obj?.ItemCount,
      TotalTax: obj?.TotalTax,
      TotalDiscount: obj?.TotalDiscount,
      SubTotalPrice: obj?.SubTotalPrice,
    });
    setCustomer((prev)=>{return{ ...prev,Id: obj?.CustomerId, Name: obj?.CustomerName }});
  };
  useEffect(() => {
    if (Object.values(userChanges).filter((item) => item != null).length > 0) {
      sessionStorage.setItem("changingforms", "true");
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    } else {
      sessionStorage.setItem("changingforms", "false");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [userChanges]);

  useEffect(() => {
    if (mode != "create") {
      getInvoiceAsync();
    } else {
      getInvoiceConstraintAsync(true);
    }
  }, []);
  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);
  useEffect(() => {
    setPayment((prev) => {
      return {
        ...prev,
        totalprice: +totalRows?.SubTotalPrice - +totalRows?.TotalDiscount,
      };
    });
  }, [totalRows]);
  useEffect(() => {
    setReceive((prev) => {
      return {
        ...prev,
        GrandTotalPrice:
          +payment.totalprice +
          +payment?.Addition +
          +totalRows?.TotalTax -
          +payment?.Deduction,
      };
    });
  }, [payment]);

  const getSalesInvoiceNoAsync = async ()=>{

    try {
    const res = await getSalesInvoiceNo(docsClassification?.Id!=GUID_EMPTY ?docsClassification?.Id:null)
    setNextInvoiceNumber(res.Data)
    
    } catch (error:any) {

    }

  }

useEffect(() => {
  if((mode!="View") && docsClassification?.Id!=GUID_EMPTY && !invoiceConstraint.IsClassificationIgnored ){
    getSalesInvoiceNoAsync()

  }
   
  }, [docsClassification]);




  return (
    <div className=" ">
      {showForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowForm(false);
          }}
          show={showForm}
          title={"SalesInvoiceDetailForm"}
        >
          <SalesInvoiceDetailForm
            setLastWarehouse={setLastWarehouse}
            lastWarehouse={lastWarehouse}
            mode={detailMode}
            onHide={() => {
              setShowForm(false);
            }}
            docsClassification={docsClassification}
            priceList={priceList}
            invoiceConstraint={invoiceConstraint}
            selectedObj={selectedObj}
            SalesInvoiceDetailCallBack={(e) => handlesetPriceListDetail(e)}
            personId={customerInfo?.CustomerPersonId}
            currency={currencyInfo}
          />
        </ModalForm>
      ) : (
        <></>
      )}
      {showCustomerPhoneForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowCustomerPhoneForm(false);
          }}
          show={showCustomerPhoneForm}
          title={"CustomerPhoneForm"}
        >
          <CustomerPhoneList
            personId={
              response?.CustomerPersonId
                ? response?.CustomerPersonId
                : customerInfo?.CustomerPersonId
            }
            onHide={() => {
              setShowCustomerPhoneForm(false);
            }}
            type={typePhoneContact}
          />
        </ModalForm>
      ) : (
        <></>
      )}

      {showAddressBox ? (
        <ModalForm
          modalHeight={49}
          onHide={() => {
            setShowAddressBox(null);
          }}
          mode={mode}
          show={showAddressBox && true}
          title={"Address"}
        >
          <AddressBox
            personId={
              response?.CustomerPersonId
                ? response?.CustomerPersonId
                : customerInfo?.CustomerPersonId
            }
            onHide={() => {
              setShowAddressBox(null);
            }}
            status={showAddressBox}
            AddressHandeler={(e) => {
              setChangeAddress(JSON.stringify(e).toLowerCase());
              setCustomerInfo({
                ...customerInfo,
                Address: JSON.stringify(e).toLowerCase(),
              });
              setShowAddressBox(null);
            }}
          />
        </ModalForm>
      ) : (
        <></>
      )}

      {showCustomerform ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowCustomerform(false);
          }}
          show={showCustomerform}
          title={"Customer"}
        >
          <CustomerTabform
            selectedObj={customer}
            mode={mode}
            onHide={() => {
              setShowCustomerform(false);
            }}
          />
        </ModalForm>
      ) : (
        <></>
      )}

      {loading ? (
        <form onSubmit={handleSalesInvoiceDetailFormSubmit} ref={invoiceRef}>
          
         
          <FormWithTitle
            title={trans("SalesInvoice")}
            defualtClose={true}
            showFristRow={true}
            closeOption={true}
            dynamicTitle={{
              CustomerName: customer?.Name,
              number: nextInvoiceNumber,
            }}
          >
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-lg-1 col-11">
                  <SalesInvoiceSourceId
                    SourceObjCallBack={(e, type) => {
                      handleSourceData(e, type);
                    }}
                    value={haveSource ? haveSource : "undefined"}
                    resetForm={resetForm}
                    dataChange={dataChange}
                    mode={mode == "view" ? "view" : mode}
                  />
                </div>

                {invoiceConstraint?.IsDocClassified && (
                  <div className="col-lg-2 col-11 me-lg-3 mt-3 mt-lg-0">
                    <DocsClassification
                      label="Classification"
                      name="docsClassificationId"
                      baseUrl={INVENTORYANDSALES_URL}
                      IsDocClassified={invoiceConstraint?.IsDocClassified}
                      endPoint={
                        ENDPOINTSINVENTORYANDSALES.Sales
                          .BaseDefinitionAndSetting.SalesDocsClassification
                          .dictionary
                      }
                      resetForm={resetForm}
                      value={docsClassification?docsClassification?.Id: response?.DocsClassificationId}
                      mode={ mode}
                      onChange={(e,obj) => {
                        console.log("docsClassification?.CalculateTax!=e?.CalculateTax" ,docsClassification,obj,(docsClassification?.CalculateTax!=obj?.CalculateTax)&&docsClassification!=null)
                        if((docsClassification?.CalculateTax!=obj?.CalculateTax)&&docsClassification!=null){sendDataForCalcAsync(null , obj) }else    setDocsClassification(obj);
                      }}
                      
                    />
                  </div>
                )}
                {(invoiceConstraint.IsDocClassified ||
                  docsClassification) && (
                  <div className="col-lg-1 col-11">
                         <Textbox
                           textboxType="text"
                           textboxName="salesInvoiceNo"
                           labelText="number"
                           defaultValue={nextInvoiceNumber}
                           resetForm={resetForm}
                           readonly={!invoiceConstraint?.IsDocNoEditable || mode!=='create' ?true:false}
                           onChange={(e)=>{setNextInvoiceNumber(e.target.value);}}
                           mode={mode}
                          />
                  </div>
                )}
                <div className="col-lg-2 col-11">
                  <Datepicker
                    mode={
                      mode == "view" || invoiceConstraint?.IsNewDocCurrentDate
                        ? "view"
                        : mode
                    }
                    lable="Date"
                    required={true}
                    setIsoTime={(isoTime: string) => {
                      setSalesInvoiceDate(isoTime);
                    }}
                    val={
                      response?.SalesInvoiceDate
                        ? response?.SalesInvoiceDate
                        : invoiceConstraint?.IsNewDocCurrentDate
                        ? todayDate
                        : null
                    } //resetForm ? new Date().toISOString() :
                    errorFlag={false}
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                  />
                </div>

                <div className="col-lg-2 col-11 d-flex">
                  <div className="flex-grow-1">

                  <CustomerDictionary
                    name="CustomerName"
                    label="CustomerName"
                    resetForm={resetForm}
                    value={response?.CustomerId}
                    mode={(mode == 'view'||haveSource)?"view": mode}
                    returnObj={true}
                    required={true}
                    CustomerName={response?.CustomerName	}
                    onChange={(e) => {
                      setCustomer((prev)=>{return{...prev,...e}});
                      getDefaultPersonContactAsync(e.Id);
                    }}
                    disabled={mode!=='create'?true:false}
                  />
                  </div>
              {(mode!=='view'&&!haveSource) &&  <div
                  style={{
                    marginTop: "25px",
                    width: "1%",
                    // marginRight: "-25px",
                  }}
                >
                  <Button
                    Icon={true}
                    value={
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setShowCustomerform(true);
                        }}
                        className="checkButton d-flex align-items-center justify-content-center"
                      >
                        {" "}
                        <FontAwesomeIcon icon={[ICON_TYPE, "plus"]} size="2x" />
                      </button>
                    }
                    btnType="primary"
                  />
                </div>}
                </div>


                <div className="col-lg-2 col-11 me-lg-4">
                  <PriceListDictionary
                    name="PriceList"
                    label="PriceList"
                    required={true}
                    resetForm={resetForm}
                    value={
                      priceList == null ||
                      !priceList ||
                      priceList.Id == GUID_EMPTY
                        ? response?.PriceListId
                          ? response?.PriceListId
                          : customerInfo?.PriceListId
                        : customerInfo?.PriceListId??priceList.Id
                    }
                    mode={invoiceConstraint?.UseCustomerPriceList && customerInfo?.PriceListId ?'view':mode}
                    onChange={(e) => {
                     salesInvoiceDetail.length>0&&e.Id != GUID_EMPTY &&(response?.PriceListId != e.Id || (priceList&&e.Id!= priceList?.Id)) ? sendDataForCalcAsync(e) : setPriceList(e);
                    }}
                  />
                </div>
                <div className="col-lg-1 col-11">
                  <CurrencyDictionary
                    onChange={(obj, id) => {
                      setCurrencyInfo(obj);
                    }}
                    name="CurrencyId"
                    label="Currency"
                    resetForm={resetForm}
                    value={
                      priceList?.CurrencyId
                        ? priceList?.CurrencyId
                        : response?.CurrencyId
                    }
                    mode={priceList?.CurrencyId ? "view" : mode}
                    required={true}
                  />
                </div>

                {/* {mode=='create' && <div className="col-lg-2 col-11 d-flex align-items-center mb-1 mb-lg-0">
                           <CheckableButton
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                defaultChecked={SendSMS}
                                defaultValue={SendSMS}
                                readOnly={mode==='view'}
                                checked={SendSMS}
                                onchange={setSendSMS}
                                labelText={"NotifyBySMS"}
                                inputName={"NotifyBySMS"}
                                type="checkbox"
                                mode={mode}
                                />
                            </div>} */}

                <div className="col-lg-2 col-11">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="CustomerEconNo"
                    labelText="CustomerEconNo"
                    defaultValue={
                      response?.CustomerEconNo
                        ? response?.CustomerEconNo
                        : customerInfo?.EconNo
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                {!customer?.SundryCustomer ? (
                <div className="col-lg-2 col-11 d-flex">
                <div className="flex-grow-1">
                <CustomerPhoneDictionary
                  type={ContactType.Phone}
                  name="CustomerPhone"
                  label="CustomerPhone"
                  resetForm={resetForm}
                  valueString={
                    response?.CustomerPhone || mode!=='create'
                      ? response?.CustomerPhone
                      : customerInfo?.PhoneNumber
                  }
                  mode={mode == "view" ? "view" : mode}
                  returnObj={true}
                  personId={
                    response?.CustomerPersonId
                      ? response?.CustomerPersonId
                      : customerInfo?.CustomerPersonId
                  }
                  onChange={(e) => {
                    setCustomer((prev)=>{return{ ...prev, CustomerPhone: e }});

                  }}
                />
                </div>
         { mode!=='view' &&    <div
                style={{
                  marginTop: "25px",
                  width: "17px",
                  // marginRight: "-25px",
                }}
                // className="ms-4"
              >
                <Button
                  Icon={true}
                  value={
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setShowCustomerPhoneForm(true);
                        setTypePhonContact(ContactType.Phone);
                      }}
                      className="checkButton d-flex align-items-center justify-content-center"
                    >
                      {" "}
                      <FontAwesomeIcon icon={[ICON_TYPE, "plus"]} size="2x" />
                    </button>
                  }
                  btnType="primary"
                />
              </div>}
              </div>
                ) : (
                  <div className="col-11 col-lg-2 d-flex">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxType="text"
                      textboxName="CustomerPhone"
                      labelText="CustomerPhone"
                      defaultValue={
                        response?.CustomerPhone ? response?.CustomerPhone : ""
                      }
                      resetForm={resetForm}
                      mode={mode}
                    />
                  </div>
                )}



{!customer?.SundryCustomer ? (
                <div className="col-lg-2 col-11 d-flex">
                <div className="flex-grow-1">
                <CustomerPhoneDictionary
                  type={ContactType.Mobile}
                  name="CustomerMobilePhone"
                  label="CustomerMobilePhone"
                  resetForm={resetForm}
                  valueString={ response?.CustomerMobilePhone || mode!=='create'
                    ? response?.CustomerMobilePhone
                    : customerInfo?.MobilePhone}
                  mode={mode == "view" ? "view" : mode}
                  returnObj={true}
                  personId={
                    response?.CustomerPersonId
                      ? response?.CustomerPersonId
                      : customerInfo?.CustomerPersonId
                  }
                  onChange={(e) => {
                    setCustomer((prev)=>{return{ ...prev, CustomerMobilePhone: e }});
                  }}
                />
                </div>
            { mode!=='view' && <div
                style={{
                  marginTop: "25px",
                  width: "17px",
                  // marginRight: "-25px",
                }}
                // className="ms-4"

              >
                <Button
                  Icon={true}
                  value={
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setShowCustomerPhoneForm(true);
                        setTypePhonContact(ContactType.Mobile);
                      }}
                      className="checkButton d-flex align-items-center justify-content-center"
                    >
                      {" "}
                      <FontAwesomeIcon icon={[ICON_TYPE, "plus"]} size="2x" />
                    </button>
                  }
                  btnType="primary"
                />
              </div>}
              </div>
                ) : (
                  <div className="col-11 col-lg-2 d-flex">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxType="text"
                      textboxName="CustomerMobilePhone"
                      labelText="CustomerMobilePhone"
                      defaultValue={
                        response?.CustomerMobilePhone
                          ? response?.CustomerMobilePhone
                          : ""
                      }
                      resetForm={resetForm}
                      mode={mode}
                    />
                  </div>
                )}


                <div className="col-lg-2 me-lg-4 col-11">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxName="CustomerNationalCode"
                    textboxType="nationalCode"
                    labelText="CustomerNationalCode"
                    defaultValue={
                      response?.CustomerNationalCode
                        ? response?.CustomerNationalCode
                        : customerInfo?.NationalId
                    }
                    resetForm={resetForm}
                    mode={mode == "view" ? "view" : mode}
                  />
                </div>
           {invoiceConstraint?.IsRecipientSpecified &&     <div className="col-lg-3 col-11">
                  <Person
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    value={
                      response?.RecipientPersonId
                        ? response?.RecipientPersonId
                        : customerInfo?.RecipientPersonId
                    }
                    resetForm={resetForm}
                    mode={mode}
                    WithSearch={false}
                    setPerson={setPerson}
                    returnObj={true}
                    name={"RecipientPersonDescription"}
                    label="RecipientDescription"
                  />
                </div>}

                {!customer?.SundryCustomer ? (
                <div className="col-lg-5 col-11 d-flex" style={{ marginRight: "2px" }}>
                <div className="flex-grow-1">
                <Address
                  onChange={(e) => {
                    setShowAddressBox("selectMode");
                    return false;
                  }}
                  withMap={false}
                  hideDetail={true}
                  personContact={
                    changeAddress
                      ? convertAddress(changeAddress)
                      : response?.CustomerAddress
                      ? response?.CustomerAddress
                      : convertAddress(customerInfo?.Address)
                  }
                  mode={"view"}
                />
                </div>
           {mode!=='view' &&   <div className=" mt-4 text-end p-0">
                <Button
                  Icon={true}
                  value={
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setShowAddressBox("createMode");
                      }}
                      className="checkButton d-flex align-items-center justify-content-center"
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={[ICON_TYPE, "plus"]}
                        size="2x"
                      />
                    </button>
                  }
                  btnType="primary"
                />
              </div>}
            
            {mode!=='view' &&  <div className=" mt-4 text-end p-0">
                <Button
                  Icon={true}
                  value={
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setShowAddressBox("selectMode");
                      }}
                      className="checkButton d-flex align-items-center justify-content-center "
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={[ICON_TYPE, "search"]}
                        size="2x"
                      />
                    </button>
                  }
                  btnType="primary"
                />
              </div>}
            </div>
                ) : (
                  <div className="col-lg-5 col-11 d-flex ">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Address"
                      textboxType="text"
                      labelText="Address"
                      defaultValue={
                        response?.CustomerAddress
                          ? response?.CustomerAddress
                          : ""
                      }
                      resetForm={resetForm}
                      mode={mode == "view" ? "view" : mode}
                    />
                  </div>
                )}

          

                {/*                         <div className="col-lg-2">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             textboxName="Prepayment"
                             textboxType="number"
                             labelText="Prepayment"
                             defaultValue={response?.Prepayment}
                             resetForm={resetForm}
                             mode={mode == 'view'?"view": mode}
                             required={true}
                            />
                        </div> */}
                {/*                         <div className="col-lg-2">
                            <Textbox
                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                            textboxName="VisitorPositionDescriptionEN"
                            textboxType="text"
                            labelText="VisitorPositionDescriptionEN"
                            defaultValue={response?.VisitorPositionDescriptionEN}
                            resetForm={resetForm}
                            mode={mode == 'view'?"view": mode}
                            />
                            </div> */}
                {/*  <div className="col-lg-2">
                                 <Textbox
                                  Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                  textboxName="DistributorPositionDescriptionEN"
                                  textboxType="text"
                                  labelText="DistributorPositionDescriptionEN"
                                  defaultValue={response?.DistributorPositionDescriptionEN}
                                  resetForm={resetForm}
                                  mode={mode == 'view'?"view": mode}
                                 />
                                </div> */}
                {/*                         <div className="col-lg-2">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             textboxName="SalesManagerPositionDescriptionEN"
                             textboxType="text"
                             labelText="SalesManagerPositionDescriptionEN"
                             defaultValue={response?.SalesManagerPositionDescriptionEN}
                             resetForm={resetForm}
                             mode={mode == 'view'?"view": mode}
                            />
                           </div> */}

                <div className="col-lg-2 col-11 mr-2">
                  <Position
                    name="VisitorPositionId"
                    label="VisitorPosition"
                    resetForm={resetForm}
                    value={
                      customerInfo?.VisitorPositionId
                        ? customerInfo?.VisitorPositionId
                        : response?.VisitorPositionId
                    }
                    mode={mode == "view" ? "view" : mode}
                    returnObj={true}
                    onChange={(e) => {
                      setListPosition({ ...listPosition, VisitorPosition: e });
                    }}
                  />
                </div>
                <div className="col-lg-2 col-11">
                  <Position
                    name="DistributorPositionId"
                    label="DistributorPosition"
                    resetForm={resetForm}
                    value={
                      customerInfo?.DistributorPositionId
                        ? customerInfo?.DistributorPositionId
                        : response?.DistributorPositionId
                    }
                    mode={mode == "view" ? "view" : mode}
                    returnObj={true}
                    onChange={(e) => {
                      setListPosition({
                        ...listPosition,
                        DistributorPosition: e,
                      });
                    }}
                  />
                </div>

                <div className="col-lg-2 col-11">
                  <Position
                    name="SalesManagerPositionId"
                    label="salesmanagerpositiondescription"
                    resetForm={resetForm}
                    value={
                      customerInfo?.SalesInvoiceStatusId
                        ? customerInfo?.SalesInvoiceStatusId
                        : response?.SalesManagerPositionId
                    }
                    mode={mode == "view" ? "view" : mode}
                    returnObj={true}
                    onChange={(e) => {
                      setListPosition({
                        ...listPosition,
                        SalesManagerPosition: e,
                      });
                    }}
                  />
                </div>

                <div className="col-lg-5 col-11">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxName="Description"
                    textboxType="textarea"
                    labelText="Description"
                    minHeight={1}
                    defaultValue={response?.Description}
                    resetForm={resetForm}
                    mode={mode == "view" ? "view" : mode}
                    maxLength={900}
                    letterCounter={true}
                  />
                </div>
              </div>
            </div>
          </FormWithTitle>
          <div className="mt-3">
            <FormWithTitle title={trans("Details")}>
              <div>
                <div className="row align-items-center justify-content-between">
                  {mode !== "view" && (
                    <div className="col-2">
                      <div
                        onClick={handleOpenDetailForm}
                        className="col-2 mb-1 btn btn-success btn-sm d-flex align-items-center justify-content-between "
                        style={{
                          width: "70px",
                          maxHeight: "30px",
                          marginRight: "20px",
                          minWidth:'70px',
                        }}
                      >
                        <span className=" font-size-md ms-2 ">
                          {trans("add")}
                        </span>
                        <FontAwesomeIcon
                          size="xs"
                          icon={["fas", "plus"]}
                          className="color_text_light d-flex Requests__chatbox-reply-btn mx-1 p-1"
                          color="white"
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-10 row" style={{ justifyContent: "end" }}>
                    <div className="d-flex justify-content-end align-items-center">
                      {mode!="view"&&
                      <div className="d-flex align-items-center justify-content-center mb-1 flex-wrap">
                        <input
                          style={{ width: "30%" }}
                          onChange={(e) => {
                            setOptionalDiscount(
                              e.target.value.replaceAll(",", "")
                            );
                          }}
                          value={
                            optionalDiscount > 0
                              ? GroupingPriceFunction(+optionalDiscount)
                              : null
                          }
                          className="text-box__box form-control col-2 me-1 "
                          placeholder={trans("Discount")}
                          disabled={
                            salesInvoiceDetail.length == 0 ||
                            !priceList?.IsManual
                              ? true
                              : false
                          }
                        />
                        <div
                          onClick={(e) => {
                            if (
                              salesInvoiceDetail.length > 0 &&
                              priceList?.IsManual
                            ) {
                              e.preventDefault();
                              e.stopPropagation();
                              optionalDiscountHandlerAsync();
                            }
                          }}
                          className={`btn   ${
                            salesInvoiceDetail.length == 0 ||
                            !priceList?.IsManual
                              ? "btn-outline-secondary disabled"
                              : "btn-primary"
                          } btn-sm d-flex align-items-center justify-content-between  me-1 `}
                        >
                          <span className=" font-size-md ">
                            {trans("sharediscount")}
                          </span>
                          <FontAwesomeIcon
                            size="xs"
                            icon={[ICON_TYPE, "share"]}
                            className=" d-flex Requests__chatbox-reply-btn mx-1 p-1"
                            style={{
                              color:
                                salesInvoiceDetail.length == 0 ||
                                !priceList?.IsManual
                                  ? "gray"
                                  : "white",
                            }}
                          />
                        </div>
                      </div>
                      }
                      <div className={`${mode!="view"? "col-7" : "col-12"}  row justify-content-end mb-1`}>
                        <Headerbar
                          withPading={false}
                          headerContents={totalRows}
                          hasBorder={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {dataGridLoading ? (
                  <>
                    {salesInvoiceDetail?.length >= 0 ? (
                      <>
                        <DataGrid
                        responseList={responseList}
                          convertDateColNames={["ExpireDate"]}
                          DoubleClick={(e) => {
                            setSelectedObj(e);
                            setShowForm(true);
                            setDetailMode('view')
                          }}
                          parentName={"SalesInvoiceDetailList"}
                          startIndex={19}
                          theadData={Object.keys(invoiceConstraint?.DetailDTO)}
                          tbodyData={
                            salesInvoiceDetail?.length > 0
                              ? salesInvoiceDetail
                              : [invoiceConstraint?.DetailDTO]
                          }
                          totalRecords={
                            salesInvoiceDetail?.length > 0 ? undefined : 0
                          }
                          scrollHeight={"67vh"}
                          minCellWidth={80}
                          selectedRowObjectCallback={selectedObjCallback}
                          handleEdit={
                            mode != "view"
                              ? (e) => {
                                  setSelectedObj(e);
                                  setDetailMode("edit");
                                  setShowForm(true);
                                }
                              : undefined
                          }
                          rowSelected={selectedObj}
                          handleDelete={
                            mode != "view"
                              ? (e) => {
                                  Toast(
                                    trans("msg_deleteconfirm"),
                                    "warning",
                                    "alert",
                                    () => handleDeleteRow(e)
                                  );
                                }
                              : undefined
                          }
                          handleView={(e) => {
                            setSelectedObj(e);
                            setDetailMode("view");
                            setShowForm(true);
                          }}
                        />
                      </>
                    ) : (
                      <Message message={"NoDataForPreview"} type={"info"} />
                    )}
                  </>
                ) : (
                  <Loading />
                )}
              </div>
            </FormWithTitle>
          </div>

          <div className="card">
            <div className="card-body ">
              <div className="row">
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="totalprice"
                    textboxType="text"
                    labelText="totalprice"
                    decimals={currencyInfo?.CurrencyRoundingPlace}
                    round={invoiceConstraint.FloorTaxDuty}
                    defaultValue={
                      payment.totalprice != 0
                        ? payment.totalprice + ""
                        : response?.TotalPrice
                        ? response?.TotalPrice + ""
                        : payment.totalprice
                    }
                    resetForm={resetForm}
                    mode={"view"}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="Addition"
                    textboxType="text"
                    labelText="Addition"
                    defaultValue={
                      payment.Addition != 0
                        ? payment.Addition + ""
                        : response?.Addition
                        ? response?.Addition + ""
                        : payment.Addition
                    }
                    onChange={(e) => {
                      setPayment({
                        ...payment,
                        Addition: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="Deduction"
                    textboxType="text"
                    labelText="Deduction"
                    defaultValue={
                      payment.Deduction != 0
                        ? payment.Deduction + ""
                        : response?.Deduction
                        ? response?.Deduction + ""
                        : payment.Deduction
                    }
                    onChange={(e) => {
                      setPayment({
                        ...payment,
                        Deduction: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="GrandTotalPrice"
                    decimals={currencyInfo?.CurrencyRoundingPlace}
                    round={invoiceConstraint.FloorTaxDuty}
                    textboxType="text"
                    labelText="GrandTotalPrice"
                    defaultValue={
                      receive?.GrandTotalPrice != 0
                        ? receive?.GrandTotalPrice + ""
                        : response?.GrandTotalPrice
                        ? response?.GrandTotalPrice + ""
                        : receive?.GrandTotalPrice
                    }
                    resetForm={resetForm}
                    mode={"view"}
                  />
                </div>
                <div className="col-lg-1">
                  <DictionarySelectBox
                    label="PaymentMethod"
                    name="PaymentMethodId"
                    baseUrl={INVENTORYANDSALES_URL}
                    endPoint={
                      ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
                        .PaymentMethod.dictionary
                    }
                    resetForm={resetForm}
                    value={response?.PaymentMethodId}
                    mode={mode}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2">
                  <Textbox
                    textboxName="Prepayment"
                    textboxType="text"
                    labelText="Prepayment"
                    defaultValue={
                      receive?.Prepayment != 0
                        ? receive?.Prepayment + ""
                        : response?.Prepayment
                        ? response?.Prepayment + ""
                        : receive?.Prepayment
                    }
                    onChange={(e) => {
                      setReceive({
                        ...receive,
                        Prepayment: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={mode}
                    useGroupingPrice={true}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    textboxName="DebitPayment"
                    textboxType="text"
                    labelText="DebitPayment"
                    defaultValue={
                      receive?.DebitPayment != 0
                        ? receive?.DebitPayment + ""
                        : response?.DebitPayment
                        ? response?.DebitPayment + ""
                        : receive?.DebitPayment
                    }
                    onChange={(e) => {
                      setReceive({
                        ...receive,
                        DebitPayment: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={mode}
                    useGroupingPrice={true}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    textboxName="NonDebitPayment"
                    textboxType="text"
                    labelText="NonDebitPayment"
                    defaultValue={
                      receive?.NonDebitPayment != 0
                        ? receive?.NonDebitPayment + ""
                        : response?.NonDebitPayment
                        ? response?.NonDebitPayment + ""
                        : receive?.NonDebitPayment
                    }
                    onChange={(e) => {
                      setReceive({
                        ...receive,
                        NonDebitPayment: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={mode}
                    useGroupingPrice={true}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    textboxName="CreditPayment"
                    textboxType="text"
                    labelText="CreditPayment"
                    defaultValue={
                      receive?.GrandTotalPrice -
                      (+receive?.NonDebitPayment +
                        +receive?.DebitPayment +
                        +receive?.Prepayment) +
                      ""
                    }
                    onChange={(e) => {
                      setReceive({
                        ...receive,
                        CreditPayment: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={"view"}
                    useGroupingPrice={true}
                  />
                </div>
                <div className="col-lg-4 d-flex justify-content-end">
                  
                  {mode != "view" ? (
                    <div className="col-lg-12 mt-4  btns_form">
                      <Button outline={false} disabled={responseList} value="save" btnType="submit" />
                      {!jobId && (
                      <Button
                        btnType="cancel"
                        value="Cancel"
                        onClick={() => {
                          cancel();
                        }}
                      />
                        )}
                    </div>
                  ) : (
                    <div className="col-lg-12  mt-4  btns_form">
                      {!jobId && (
                        <Button
                          btnType="primary"
                          value="close"
                          onClick={() => {
                            cancel();
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <Loading />
      )}
    </div>
  );
};
