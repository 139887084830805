

import { useEffect, useState } from "react";
import { trans, uuidv4 } from "utils/helpers";

import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";

import DatePicker from "components/DatePicker/DatePicker";
import { KeyWord } from "components/KeyWord/KeyWord";

import { Time } from "components/Time/Time";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
interface Form {
    onHide: () => void;
    ItemBarcodelistcallback?: (list) => void;
    reload?: () => void;
}
export const ItemBarcodeForm = ({ItemBarcodelistcallback, onHide ,reload}: Form) => {



    const [mode, setMode] = useState("create");
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<any>();
    const [ItemBarcodeType, setItemBarcodeType] = useState(1);
    const [title, setTitle] = useState<any>();
    const [keyWords, setKeyWords] = useState<any>([]);
    const [fromDate, setFromDate] = useState<any>(null);
    const [userChanges, setUserChanges] = useState({});





    async function sendBarcodes(data) {
        try {
                await sendBarcodes(data)
                sessionStorage.setItem("changingforms" , "false")
                reload &&  reload()
                onHide()
        } catch (error) {
            
        }
    }


    const submit = (e) => {
        e.preventDefault();
        let DefaultValue:any = ""
        
        const date = new DateObject(fromDate).convert(persian, persian_fa)
        switch (+ItemBarcodeType) {
            case 1 : DefaultValue= e.target.DefaultValue.value ; break;
            case 2 : DefaultValue= e.target.DefaultValue.value   ; break;
            case 3 : DefaultValue= keyWords.join(",") ; break;
            case 4 : DefaultValue= e.target.DefaultValue.checked+"" ; break;
            case 6 : DefaultValue=date.format( ) ; break;
            case 7 : DefaultValue= e.target.DefaultValue.value ; break;

        }
        let data ={
   
            ItemBarcodeName: e.target.ItemBarcodeName.value,
            DefaultValue:DefaultValue, 
            ItemBarcodeType: +e.target.ItemBarcodeType.value,
        }
        if(ItemBarcodeType==3 && keyWords.length ==0){
            Toast("Msg_Validation_ValueIsRequired" ,"warning")

        }else{
            sendBarcodes(data)
            ItemBarcodelistcallback&& ItemBarcodelistcallback(data)

        }
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                setEditForm(null);
                onHide()
            });
        }else{
            setEditForm(null);
            onHide()    
        };

    }


    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);



    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        
        }
    }, [resetForm]);








    return (
        <>
            <div className="cartable-action">
                 <>
                                 <div className="card mb-4">

                                    <form className="TypeSettingForm row card-body " onSubmit={submit}>
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} onChange={(e)=>{setTitle(e.target.value) }} required={true} textboxName={"ItemBarcodeName"} textboxType="text" resetForm={resetForm} labelText={"Description"} mode={mode}  defaultValue={editForm?.ItemBarcodeName} />
                                        </div>     
                                         <div className="col-lg-4 ">
                                        <EnumerationTypeSelect
                                          value={editForm?.BarcodeType} 
                                          Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                                          name="BarcodeType"  
                                          label="BarcodeType"
                                          enumTypeSelect="BarcodeType"
                                          mode={mode} 
                                          />
                                         </div>                            
                                        <div className="col-md-4 col-sm-12">
                                        {
                                            {
                                                1: (
                                                     <Textbox
                                                         Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                                         required={true}
                                                         defaultValue={editForm?.DefaultValue}
                                                         textboxType="text"
                                                         textboxName="DefaultValue"
                                                         labelText={ title ? title :"text"}
                                                         resetForm={resetForm}
                                                         mode={mode}
                                                         
                                                         />
                                                         ),
                                                         2: (
                                                             <Textbox
                                                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                                           required={true}
                                                           mode={mode}
                                                           defaultValue={editForm?.DefaultValue}
                                                           textboxType="number"
                                                           textboxName="DefaultValue"
                                                           labelText={ title ? title :"number"}
                                                           resetForm={resetForm}
                                                        />
                                                        ),
                                                 3: (
                                                    <KeyWord
                                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                                    required={true}
                                                    label={ title ? title :"List"}
                                                    setKeyWords={setKeyWords}
                                                    keyWords={keyWords}
                                                    recordedData={editForm?.DefaultValue.split(",")}
                                                    mode={mode}
                                                    name="DefaultValue"
                                                   />
                                                 ),
                                                 4: (
                                                    <div className="mt-4">
                                                        <CheckableButton
                                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                                        defaultChecked={editForm?.DefaultValue == "true"}
                                                        defaultValue={editForm?.DefaultValue == "true"}
                                                        labelText={ title ? title :"checkbox"}
                                                        inputName={"DefaultValue"}
                                                        type="checkbox"
                                                        mode={mode}
                                                     />   
                                                    </div>
                                                 ),
                                                 6: (
                                                    <DatePicker
                                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                                    mode={mode}
                                                    resetForm={resetForm}
                                                    lable={ title ? title :"Date"}
                                                    setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                                                    val={  null} //resetForm ? new Date().toISOString() : 
                                                  />
                                                 ),
                                                 7: (
                                                    <Time
                                                    required={true}
                                                    mode={mode}
                                                    name="DefaultValue"
                                                    IdString="DefaultValue"
                                                    resetForm={resetForm}
                                                    label={ title ? title :"time"}
                                                />
                                                 ),
                                             }[ItemBarcodeType]
             
                                        }
                                        </div>   
                                        {mode !="create" &&
                                         <div className="col-md-4">
                                                <EnumerationTypeSelect
                                                 Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                                                 name="ActivityStatus"
                                                 label="ActivityStatus"
                                                 enumTypeSelect="ActivityStatus"
                                                 value={editForm?.ActivityStatus}
                                                 mode={mode}
                                                />
                                         </div>
                                        }
                                        {  mode !="view"?
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button value="Save" btnType="submit" />
                                              <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                                              </div>
                                              :
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                                              </div>

                                          }                        
                                    </form>

                                </div >
                    </>

            </div>
        </>

    );
}