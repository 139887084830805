import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { useEffect, useState } from "react";
import { weekdays_en, weekdays_fa } from "utils/constants";
import { trans } from "utils/helpers";
import { callHolidaysApi } from "../api";
import { Toast } from "components/ToastShow/ToastShow";


interface SelectDayPropsType {
    showForm: (e:boolean)=>void;
    endpoit;
    relod: () => void,

}
export const SelectDay = ({ 
    showForm, 
    endpoit, 
    relod

}: SelectDayPropsType) => {
   
    const [resetForm, setResetForm] = useState(false);
    const [flag, setflag] = useState(false);
    const [allWeekend, setAllWeekend] = useState<any>(true);
    const [transferEvents, setTransferEvents] = useState<any>(false);
    const [transferHolidaysList, setTransferHolidaysList] = useState<any>(["LunarHolidays" , "GregorianHolidays"]);
    const [userChanges, setUserChanges] = useState({}); 


    const lang = localStorage.getItem('lang');
    const weekdays = lang == "en" ? weekdays_en : weekdays_fa;
    const [selectDay, setSelectDay] = useState<any>({[lang == "en" ? "Sunday"  :"Friday"]:true });

    async function sendNodeAsync(data ,endpot) {
        try{

        let DayList:any =[]
        Object.values(selectDay).forEach((item , index)=>{
           if(item){

               DayList.push(Object.keys(selectDay)[index])
           }
       })
        const res = await callHolidaysApi( DayList ,transferHolidaysList.includes("SolarHolidays"),transferHolidaysList.includes("LunarHolidays"),transferHolidaysList.includes("GregorianHolidays"), transferEvents);
        sessionStorage.setItem("changingforms" , "false");
        setUserChanges({});
        relod()
        showForm(false)
        }catch(err:any){
              }
    }
    async function addDay(data) {
        let obj =JSON.parse(JSON.stringify(selectDay)) 
        if(obj[data] ){
            obj[data] = false
        }else{
            obj[data] = true
        }
        if(Object.values(obj).includes(true)){
            setAllWeekend(true)
        }else{
            setAllWeekend(false)
        }
        setSelectDay(obj)
    }
  
    function addTransferHolidays(data) {
        let newList = transferHolidaysList
       if(transferHolidaysList.includes(data)){        
         newList.splice(newList.indexOf(data), 1)
       }else{
        newList.push(data)
       }
    setTransferHolidaysList(newList)
    setflag(flag)
    }



    const handlePersonContactFormSubmit = (event) => {
        event.preventDefault();
        let DayList:any =[]
         Object.values(selectDay).forEach((item , index)=>{
            if(item){
                DayList.push(Object.keys(selectDay)[index])
            }
        })
        sendNodeAsync(DayList,endpoit)
    }

    
    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                showForm(false)
            });
        }else{
            showForm(false)     
        }
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };
    
    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){
    
            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);
    
        }
    }, [userChanges]);

     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);
    
 

    return (
        <div className="person-contact-form card">
            <div className="card-body">
                <div className="container-fluid">
                    <form onSubmit={handlePersonContactFormSubmit}>
                       <div className="card ">
                        <div className="card-body p-3" >
                         <div className="row ">
                         <CheckableButton Change={(e)=>{setUserChanges({...userChanges , ...e})}}  onchange={(e)=>{e.target.checked ? setTransferHolidaysList(["SolarHolidays" ,"LunarHolidays" , "GregorianHolidays" ]):setTransferHolidaysList([]) }}  checked={transferHolidaysList.length==3} defaultChecked={false} defaultValue={"day"} labelText={ trans("TransferHolidays")} inputName={"weekdays"} type={"checkbox"} />
                        </div>
                        <div className="row d-flex mt-3">
                        <CheckableButton Change={(e)=>{setUserChanges({...userChanges , ...e})}} onclick={()=>{addTransferHolidays("SolarHolidays")}} width={"10%"}  defaultChecked={transferHolidaysList.includes("SolarHolidays")}  defaultValue={"day"} labelText={ trans("Solar")} inputName={"weekdays"} type={"checkbox"} />
                        <CheckableButton Change={(e)=>{setUserChanges({...userChanges , ...e})}} onclick={()=>{addTransferHolidays("LunarHolidays")}} width={"10%"}  defaultChecked={transferHolidaysList.includes("LunarHolidays")}  defaultValue={"day"} labelText={ trans("Lunar")} inputName={"weekdays"} type={"checkbox"} />
                        <CheckableButton Change={(e)=>{setUserChanges({...userChanges , ...e})}} onclick={()=>{addTransferHolidays("GregorianHolidays")}} width={"10%"} defaultChecked={transferHolidaysList.includes("GregorianHolidays")}  defaultValue={"day"} labelText={ trans("Gregorian")} inputName={"weekdays"} type={"checkbox"} />
                        </div>
                      </div>
                     </div> 
                       <div className="card ">
                        <div className="card-body p-3">
                         <div className="row mb-3">
                          <CheckableButton Change={(e)=>{setUserChanges({...userChanges , ...e})}}  checked={allWeekend} readOnly={true}  defaultChecked={false} defaultValue={"day"} labelText={ trans("transfernationalweekendholidays")} inputName={"weekdays"} type={"checkbox"} />
                         </div>
                         <div className="row ">
                           {
                               weekdays.map(item=>(
                                   <CheckableButton Change={(e)=>{setUserChanges({...userChanges , ...e})}} width={"13%"} onclick={()=>{addDay(item)}} checked={selectDay[item]} defaultChecked={selectDay[item]} defaultValue={"day"} labelText={ trans(item)} inputName={"weekdays"} type={"checkbox"} />
                               ))
                           }
                         </div>
                        </div>
                     </div> 
                       <div className="card ">
                        <div className="card-body">
                           <div className="row ">
                           <CheckableButton Change={(e)=>{setUserChanges({...userChanges , ...e})}}  onclick={()=>{setTransferEvents(true)}} defaultChecked={false} defaultValue={"day"} labelText={  trans("transferorganizationalevents")} inputName={"weekdays"} type={"checkbox"} />
                          </div>
                        </div>
                     </div> 
                     <div className="col-lg-12 mt-1 btns_form">
                       <Button value="transfer" btnType="submit" />
                       <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                     </div>
                    </form>
                </div>
            </div>
        </div>
    );
}