import "./style.scss"
import { useEffect, useState } from "react";
import { loadCardImage } from "../../api";

interface ItemCardImgProps {
    attchId?: any,

}

export const ItemCardImg = ({attchId}:ItemCardImgProps) => {

    const [src, setSrc] = useState<any>(null);

    async function loadImageAsync() {
        try {
            const res:any = await loadCardImage(attchId );
            setSrc(res?.replace("data:application/json;base64," , ""));
      
        } catch (error) {

        }
    }
    useEffect(() => {
    if(!attchId?.includes("0000")){
       // loadImageAsync()
    }

    }, []);

    return (
     <>

     {/*    {(attchId?.includes("0000")||!src) ?
            <div style={{height:"100%",borderRadius: "50%"}} className="card-img-top">
        
            </div>
        :
        <img  className="card-img-top" src={"data:application/json;base64,"+src} draggable="false"/>
        }
 */}
<img  className="card-img-top" src={attchId} draggable="false"/>

    </>
     );
}
