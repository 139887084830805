
import { Loading } from "components/Loading/loading";
import { getItemGroupLabels, getItemStoreListApi } from "./api";
import { ItemCards } from "./components/ItemCard/ItemCards";
import { ItemGroupCards } from "./components/ItemGroupCard/ItemGroupCards";
import { ItemGroupLabelSlider } from "./components/ItemGroupMenu/ItemGroupLabelSlider";
import { useEffect, useState } from "react";
import { ItemGroupLebal } from "./components/ItemGroupMenu/ItemGroupLabel";
import {Data} from "./components/data/data"
import { ItemCardsHeader } from "./components/ItemCardsHeader/ItemCardsHeader";
import { ItemCode } from "components/ItemCode/ItemCode";


interface Form {
    Title: string,
    Status: number
}
export const ECommerceDEMO = ({match}) => {

    const [forceUpdate, setForceUpdate] = useState(false);
    const [response, setResponse] = useState<any>();
    const [filterObj , setfilterObj]= useState<any>("");

    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);

    const [messageType, setMessageType] = useState<any>("info");
    const [itemGroupId, setItemGroupId] = useState<any>(null);
    const [dataGroup, setDataGroup] = useState<any>([]);
    const [cardStyle,setCardStyle] = useState<boolean>(true)
    const [breadCrumb ,setBreadCrumb] = useState<any>([{id:null,name:"صفحه اصلی"}])
    
    async function getECommerceAsync(e=null) {
        setLoading(false)
        try {
           // const res = await getItemStoreListApi(e);
           if(e!=null){
               let filteredList :any=[]
                Data.map(item=>{
                   if(item.GroupId ==e||item.GroupDetailId ==e){
                       filteredList.push(item)
                   }
                })
   
               setResponse(filteredList);  

           }else{
            setResponse(Data);  
           }
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
        
    }

    function clickBreadCrumb(Bread){
        setItemGroupId(Bread.id)
        let newBread :any= []
        let flag = false
        breadCrumb.map(item=>{
            if(!flag){
                newBread.push(item)
            }
            if(item.id==Bread.id){
                flag =true
            }
        })
        setBreadCrumb(newBread)
        
    }
    
    const getDataAsync = async(id= null) =>{
        try {
            //const res = await getItemGroupLabels()
            const res = {Data:{
                "E36E1489-83FC-EE11-AB53-00155DAA1053":"پوشاک",
                "084E4FDE-6D74-ED11-AB39-00155DAA1053":"کالای الکتریکی",
            }}
            if(id==null){
                let content =(res.Data&& Object.keys(res.Data).map((item,index) =>(<ItemGroupLebal key={index+'contentECommerce'} shape="FoursquareRadio" IsActive={itemGroupId==item} onClick={(e)=>{e.stopPropagation()  ;setItemGroupId(item);setBreadCrumb([...breadCrumb , {id: item,name:res.Data[item]}])}} title={res.Data[item]}/>)))
               setDataGroup(content)
               
            }else{
               
                const res2 = {Data:{
                    "E36E1489-83FC-EE11-AB53-00155DAA1053":{
                        "E36E1489-83FC-EE11-AB53-001552561053": "کلاه",
                        "E36E1489-83FC-EE11-AB53-001589631053": "کالای چرم",
                    },
                    "084E4FDE-6D74-ED11-AB39-00155DAA1053":{
                        "084E4FDE-6D74-ED11-AB39-0015596A1053": "برق صنعتی",
                        "084E4FDE-6D74-ED11-8953-00155DAA1053": " برق خانگی",
                    }
    
                }}
                let content =(res2.Data[id]&& Object.keys(res2.Data[id]).map((item,index) =>(<ItemGroupLebal key={index+'contentECommerce'} shape="FoursquareRadio" IsActive={itemGroupId==item} onClick={(e)=>{e.stopPropagation()  ;setItemGroupId(item);setBreadCrumb([...breadCrumb , {id: item,name:res2.Data[id][item]}])}} title={res2.Data[id][item]}/>)))
               setDataGroup(content)

            }

        } catch (error) {
            
        }
}

    useEffect(() => {
        getECommerceAsync();
    }, [forceUpdate ]);
    useEffect(() => {
        getECommerceAsync(itemGroupId);
        getDataAsync(itemGroupId)
    }, [itemGroupId]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);


    useEffect(()=>{
        getDataAsync()
        setResponse(Data)
    },[])

    /* #endregion */

    return (

        <>
        <div className="d-flex align-items-center w-100" style={{zIndex:"5", flexDirection:"column"}}>
            <div className="w-100 m-2 d-flex" style={{flexDirection:"row", justifyContent:"space-between"}}>
                <div className=" col-8">
                
                {breadCrumb.map((breadCrumbsItem, index) => (
                  <span key={index} onClick={()=>{clickBreadCrumb(breadCrumbsItem)}} style={{cursor:"pointer"}}>
                    {breadCrumbsItem.name}
                    {index < breadCrumb.length - 1 ? <span key={index} className="bread-crumb__separator">/</span> : null}
                  </span>
                ))}
                
                </div>
                <ItemCardsHeader data={response} cardStyle={cardStyle} handleModeCard={()=>{setCardStyle(!cardStyle)}}/>
          
            </div>

        </div>
        <div  className="card-group">
            {/* <ItemGroupCards /> */}
            {
                loading ? 
                <ItemCards filterId={itemGroupId} cardStyle={cardStyle} dataGroup={dataGroup} data={response}/>
                :<Loading/>
            }
        </div>

        </>
    );
}