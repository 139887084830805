import { ENDPOINTS } from "utils/endpoints"
import { http } from "utils/http"





   
   
   

   
    export const getFully = () => http({
        url: ENDPOINTS.BaseInformation.organization.browse,
        method: "get",
    
    }).then((res) => {
          return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });
   
       
    



    export const getOrganizationRolesList = (async (organizationId:any ,SearchString ) => await http({
        url: ENDPOINTS.tadbirAuthorizeApi.organizationRole.dictionary,
        method: 'get',
        params:{
            SearchString: SearchString? SearchString :null,
            organizationId :organizationId
        }
    }).then((res) => {
    
        return res.data
    }).catch((err) => {
     
        return Promise.reject( err);
    })
    
    )
    
    export const getExceptOrganizationRolesList = (async (organizationId ,SearchString ) => await http({
        url: ENDPOINTS.tadbirAuthorizeApi.organizationRole.browse,
        method: 'get',
        params:{
            SearchString: SearchString? SearchString :null,
            organizationId :organizationId
        }
    }).then((res) => {
    
        return res.data
    }).catch((err) => {
     
        return Promise.reject( err);
    })
    )
    
    export const addNewRolesAsync = (async (organizationId ,data ) => await http({
        url: ENDPOINTS.tadbirAuthorizeApi.organizationRole.load,
        method: 'post',
        params:{
            organizationId :organizationId
        },
        data:data
    }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject( err);
    })
    )

    export const deletRolesAsync = (async (organizationId ,data ) => await http({
        url: ENDPOINTS.tadbirAuthorizeApi.organizationRole.load,
        method: 'DELETE',
        params:{
            organizationId :organizationId
        },
        data:data
    }).then((res) => {
    
        return res.data
    }).catch((err) => {
     
        return Promise.reject( err);
    })
    
    )
    