import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from 'components/Loading/loading';
import { ModalForm } from 'components/ModalForm/ModalForm';
import { Toast } from 'components/ToastShow/ToastShow';
import { Toolbar } from 'components/Toolbar/Toolbar';
import { useEffect, useState } from 'react';
import { ICON_TYPE } from 'utils/constants';
import { trans } from 'utils/helpers';
import { getCalendar, getCalendarDay } from './api';
import { CalendarDay } from './component/CalendarDay/CalendarDay';
import { EventForm } from './component/EventForm/EventForm';
import './style.scss';

// fake data for test
//const weekdays_fa = [trans("Saturday"), trans("Sunday"), trans("Monday"), trans("Tuesday"), trans("Wednesday"), trans("Thursday"), trans("Friday")];
const weekdays_fa = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
const weekdays_en = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const PersonalCalendar = () => {
   // let days: React.ReactElement[] = [];
    let startDay,  dayValues: any[] = [];
    const lang = localStorage.getItem('lang');
    const [dayDictionary, setDayDictionary] = useState<any>();
    const [weekdays, setWeekdays] = useState(lang == "en" ? weekdays_en : weekdays_fa);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>();
    const [ days, setDays] = useState<any>([]);
    const [ eventDays, setEventDays] = useState<any>();
    const [ emptyDays, setEmptyDays] = useState<any>();
    const [calendarEvents, setCalendarEvents] = useState<any>([]);
    const [selectedMonthNo, setSelectedMonthNo] = useState<number>(0);
    const [selectedYearNo, setSelectedYearNo] = useState<number>(0);
    const [selectedDayNo, setSelectedDayNo] = useState<number>(0);
    const [showEventForm, setShowEventForm] = useState<boolean>(false)
    const [selectedDays, setSelectedDays] = useState<any>([])
    const [eventList, setEventList] = useState<{ dayId: number, eventMessage: string, id: number }[]>([])
    const [firstDay, setFirstDay] = useState<number[]>([]) // first days of submitted events
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
    const [errcode, setErrcode] = useState<any>();
    const [forceUpdate, setForceUpdate] = useState(false);

    let customFuction: any = []

    customFuction.push([(e) => { EventFormShowFunction() }, "calendar", "event"]);
    customFuction.push([(e) => { HolidayFormShowFunction() }, "calendar", "holiday"]);

    const EventFormShowFunction = () => {
        if (selectedDays.length) {
            setShowEventForm(true)
            setFormSubmitted(false)
        } else Toast("ChooseADay", 'warning')
    };
    const HolidayFormShowFunction = () => {
        if (selectedDays.length) {
            setShowEventForm(true)
            setFormSubmitted(false)
        } else Toast("ChooseADay", 'warning')
    };
    const clickNextMonth = () => {
        
        if (selectedMonthNo === 0) {
            data ? setSelectedMonthNo(data.MonthNo + 1) : setSelectedMonthNo(new Date().getMonth());
        } else {
            if (selectedMonthNo === 12) {
                setSelectedMonthNo(1);
                data ? setSelectedYearNo(data.YearOfMonth + 1) : setSelectedYearNo(new Date().getFullYear());
            } else
                setSelectedMonthNo(selectedMonthNo + 1);
        }
    };
    const clickPreMonth = () => {
        if (selectedMonthNo === 0) {
            data ? setSelectedMonthNo(data.MonthNo - 1) : setSelectedMonthNo(new Date().getMonth());
        } else {
            if (selectedMonthNo === 1) {
                setSelectedMonthNo(12);
                data ? setSelectedYearNo(data.YearOfMonth - 1) : setSelectedYearNo(new Date().getFullYear());
            } else
                setSelectedMonthNo(selectedMonthNo - 1);
        }
    };
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            // case "excel": alert("excel "); break;
            case "refresh":setForceUpdate(!forceUpdate); break;
            case "create": {
                if (selectedDays.length) {
                    setShowEventForm(true)
                    setFormSubmitted(false)
                } else Toast("ChooseADay", 'warning')
                break;
            }
        }
    };


    const sendSelectedDaysUp = (selectedDay: {  day: number, Year: number,month: number, checked: boolean }) => {
        const { day, checked , Year ,month  } = selectedDay;
        
        
        if (!checked) {
            setSelectedDays(state => [...state, {...selectedDay ,checked:!selectedDay.checked }])
             
        } else {
            const filteredDays = selectedDays.filter(item => (item.day != selectedDay.day ||item.month != selectedDay.month ||item.Year != selectedDay.Year  ))
            setSelectedDays(filteredDays)
        }
    }

    const sendEventMessageUp = (eventData: { dayId: number, eventMessage: string, id: number }[]) => {
        setEventList(eventData)
        
        const sortedDay = eventData.map(item => item.dayId).sort(function (a, b) { return b - a })


        for (let i = 0; i < sortedDay.length; i++) {
            if (sortedDay[i] - sortedDay[i + 1] > 1) setFirstDay(state => [...state, sortedDay[i], sortedDay[sortedDay.length - 1]])
            else if (sortedDay[i] - sortedDay[i + 1] === 1) setFirstDay(state => [...state, sortedDay[sortedDay.length - 1]])
            else if (sortedDay.length === 1) setFirstDay(state => [...state, sortedDay[i]])
        }
        manageShowData()
    }

    let removedDuplicate = firstDay.filter((day, index) => {
        return firstDay.indexOf(day) === index;
    });

    const sendFormSubmissionUp = (submitted) => {
        setFormSubmitted(submitted)
    }
    const manageShowData = (days=data , Events:any=data?.CalendarEvents ) => {
        if (dayDictionary) {
            dayValues = Object.values(dayDictionary);
        }
        if (days && Events) {
            startDay = weekdays.findIndex(day => day === days?.FirstDayOfWeek);

            let day:any = [];
            let empty:any = [];
            let eventDay = Events.map(item=>{
                return item?.DayNo
            })
            for (let i = 0; i < startDay; i++) {
                empty.push(<div className={`eventcalendar__tbody-cell border`} />)
            }
            for (let i = 0; i < days.MonthDaysRange; i++) {
                day.push(i)
            }
            
            setEmptyDays(empty)
            setEventDays(eventDay)
            setDays(day)
        }
    }
    const getCalendarDayAsync = async (day) => {
        setSelectedDayNo(day)
        if(day>0){
        //setLoading(false);
        try {
            const res = await getCalendarDay(data?.YearOfMonth, data?.MonthNo, day)
            let dayDic = Object.keys(res.Data).map(item=>{
                return [item , res.Data[item]]
            })
            setDayDictionary(dayDic);
            if (res.Data) {
                dayValues = Object.values(res.Data);
            }

        } catch (error: any) {
            setErrcode(error.response.status)
        }
    }
        //setLoading(true);
    }
    const getCalendarAsync = async () => {
        setLoading(false);
        try {  
            setEventList([])
            setDays([])
            const res = await getCalendar(selectedYearNo > 0 ? selectedYearNo : undefined, selectedMonthNo > 0 ? selectedMonthNo : undefined)
            const eventData:any = [...res.Data?.CalendarEvents , ...res.Data?.PersonalEvents].map(item => ({             
                CalendarEventType:+item.CalendarEventType,
                dayId: item.DayNo,
                eventMessage:  item.Description ,
            })) 
            //setEventList(eventData)
            setCalendarEvents([...res.Data?.CalendarEvents , ...res.Data?.PersonalEvents])
            sendEventMessageUp(eventData)
            manageShowData(res.Data , [...res.Data?.CalendarEvents , ...res.Data?.PersonalEvents]);
            setData(res.Data);
        } catch (error: any) {
//            setErrcode(error.response.status)
        }
       setLoading(true);
    }
    useEffect(() => {
        getCalendarAsync();
    }, [forceUpdate]);

    useEffect(() => {
        getCalendarAsync();
    }, [selectedMonthNo, selectedYearNo ]);


 
    return (
        <div className='container'>
            <Toolbar excel={false} reminder={false} view={false} create={true} search={true} handleClickToolbar={clickToolbarCallback} />
            {
                loading && days?

            <div className="eventcalendar card mt-1 d-flex flex-colmun justify-content-center pt-1 p-md-1 p-lg-3 ">
                <div className='eventcalendar__header d-flex text-center mb-1 justify-content-between'>
                    {dayDictionary && 
                       <div style={{width:"30%"}} className='eventcalendar  justify-content-end justify-content-lg-end mt-2 mt-lg-0'>
                            <div className='eventcalendar w-75 justify-content-center justify-content-lg-end mt-2 mt-lg-0'>
                            <div className='eventcalendar mb-1 mx-2'>
                                { <span className='h7 mx-2'>{trans(dayDictionary[0][0])}</span>}
                                <span className='text-nowrap h7 w-100'>{ dayDictionary[0][1]}</span>
                            </div>
                            <div className='eventcalendar mb-1  mx-2'>
                                { <span className='h7 mx-2'>{trans(dayDictionary[1][0])}</span>}
                                <span className='text-nowrap h7 w-100'>{dayDictionary[1][1]}</span>

                            </div>
                            <div className='eventcalendar mb-1  mx-2'>
                                { <span className='h7 mx-2'>{trans(dayDictionary[2][0])}</span>}
                                <span className='text-nowrap h7 w-100'>{dayDictionary[2][1]}</span>

                            </div>
                            </div>
                        </div>
                    }
                    <div className='text-center d-flex w-25 align-items-center mb-1 justify-content-end justify-content-sm-start'>
                    <FontAwesomeIcon
                        icon={['fas', 'arrow-right']}
                        style={{ cursor: "pointer" }}
                        flip={lang === 'en' ? 'horizontal' : undefined}
                        onClick={clickNextMonth}
                    />
                    <div className='eventcalendar__header-title mx-3'>
                        <span className='h5'>{data?.YearOfMonth}</span>
                        <span className='text-nowrap h4 w-100'>{data?.MonthName}</span>
                    </div>
                    <FontAwesomeIcon
                        icon={['fas', 'arrow-left']}
                        style={{ cursor: "pointer" }}
                        flip={lang === 'en' ? 'horizontal' : undefined}
                        onClick={clickPreMonth}

                    />
                    </div>
                    <div style={{width: "30%",display: "flex",justifyContent: "end" ,flexWrap: "wrap"}}  className=' '>
                   {selectedDays.map(item=>{
                   return <div className="selectedDays" >
                        <div className="DayDate" onClick={()=>{  setSelectedMonthNo(item.month);}}>
                        {item.Year}/{item.month}/{item.day}
                        </div>
                        <div className="group-person__delete-selected-option"  onClick={()=>{sendSelectedDaysUp(item)}}>
                            <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                        </div>
                    </div>

                   })}
                    </div>
                </div>

                <div className='eventcalendar__thead d-flex flex-wrap text-truncate'>
                    {weekdays.map(day => (
                        <div key={day} className="eventcalendar__thead-cell border text-white text-center">{trans(day)}</div>
                    ))
                    }
                </div>
                <div className="eventcalendar__tbody d-flex flex-wrap">
                    {emptyDays}
                    {days.map((item , index)=>(
                         eventDays.includes(index+1) ?
                            <CalendarDay
                            selectedDay={selectedDayNo}
                            sendClickDaysUp={(e)=>{ getCalendarDayAsync(e.day)}}
                            key={index+1}
                            dayNum={index+1}
                            month={data?.MonthNo}
                            YearOfMonth={data?.YearOfMonth}
                            sendSelectedDaysUp={sendSelectedDaysUp}
                            selectedDays={selectedDays}
                            eventList={eventList}
                            holiday={days.OrganizationEvents}
                            firstDay={removedDuplicate}
                            currentDayNumber={data.CurrentDayOfMonth}
                            formSubmitted={formSubmitted}
                            CalendarEvent={[...data?.CalendarEvents , ...data?.PersonalEvents][eventDays.indexOf(index+1)]?.CalendarEventType }
                        />
                            :
                        <CalendarDay
                           selectedDay={selectedDayNo}
                           sendClickDaysUp={(e)=>{getCalendarDayAsync(e.day)}}
                            key={index+1}
                            dayNum={index+1}
                            month={data?.MonthNo}
                            YearOfMonth={data?.YearOfMonth}
                            sendSelectedDaysUp={sendSelectedDaysUp}
                            selectedDays={selectedDays}
                            eventList={eventList}
                            holiday={days.OrganizationEvents}
                            firstDay={removedDuplicate}
                            currentDayNumber={data.CurrentDayOfMonth}
                            formSubmitted={formSubmitted}
                            CalendarEvent={data?.CalendarEvents[eventDays.indexOf(index+1)]?.CalendarEventType}
                        />


                    ))}
                </div>
                {dayDictionary && 
                    <div className='eventcalendar__footer mt-2 mb-3'>
                        {dayDictionary[3][0] == "Holiday" && <p  style={{whiteSpace: "pre-wrap"}} className=' text-danger w-100' dangerouslySetInnerHTML={{__html:dayDictionary[3][1].replaceAll('\\n' , '\n')}}></p>}
                        {dayDictionary[3][0] == "NotHoliday" && <p style={{whiteSpace: "pre-wrap"}}  className=' w-100' dangerouslySetInnerHTML={{__html:dayDictionary[3][1].replaceAll('\\n' , '\n')}}></p>}

                    </div>
                }
            </div>

            : <Loading />
            }
            {/* : <Loading /> */}
            {
                showEventForm && (
                    <ModalForm title="Event" modalHeight={50} onHide={() => { setShowEventForm(false) }} show={showEventForm}>
                        <EventForm
                            year={data?.YearOfMonth}
                            month={data?.MonthNo}
                            sendFormSubmissionUp={sendFormSubmissionUp}
                            selectedDays={selectedDays}
                            setSelectedDays={setSelectedDays}
                            sendEventMessageUp={(e)=>{setForceUpdate(!forceUpdate)}}
                            setShowEventForm={setShowEventForm}
                        />
                    </ModalForm>)
            }
        </div >
    )
}