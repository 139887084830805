import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getListItemMainProperty = (async (searchString, orderBy ,pageNum,pageSize ) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.ItemMainProperty.browse,
    method: 'get',
    params: {
        searchString: searchString,
        orderBy: orderBy,
        PageSize: pageSize,
        PageNumber: pageNum,
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const deleteItemMainProperty =  (id) =>  http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.ItemMainProperty.browse,
    method: 'delete',
    params: {Id : id}

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
})


export const getItemMainProperty = (async (id) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.ItemMainProperty.load,
     method: 'get',
    params: {Id : id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const postItemMainProperty = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.ItemMainProperty.browse,    
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const putItemMainProperty = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.ItemMainProperty.browse,
    method: 'put',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})








export const getListItemMainPropertyValues = (async (searchString, orderBy ,ItemGroupKeyId ,pageNum ,pageSize) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.ItemMainPropertyValues.list,
    method: 'get',
    params: {
        SearchString: searchString,
        OrderByString: orderBy,
        id:ItemGroupKeyId,
        PageSize: pageSize,
        PageNumber: pageNum,
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const deleteItemMainPropertyValues =  (id) =>  http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.ItemMainPropertyValues.browse,
    method: 'delete',
    params: {Id : id}

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
})


export const getItemMainPropertyValues = (async (id) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.ItemMainPropertyValues.load,
     method: 'get',
    params: {Id : id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const postItemMainPropertyValues = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.ItemMainPropertyValues.browse,    
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const putItemMainPropertyValues = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.ItemMainPropertyValues.browse,
    method: 'put',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

