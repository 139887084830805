import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { useEffect, useState } from "react";
import { getFormByKey } from "../../api";
import { InputDetector } from "../InputDetector";
import { getFormInformationByJobId,getFormInformationById, postDataForm, putInformationForm } from "./api";
import './style.scss';
import { Textbox } from "components/Textbox/Textbox";
import { Message } from "components/Message/Message";
import { MessageType } from "types/enum";

interface DynamicFormDataEntryPropes {
    formKey:string;
    defaultInputList?:any;
    formBuilderMode?:boolean;
    id?:string;
    jobId?:string;
    PRJ_UID?:string;
    mode:'create' | 'view' | 'edit';
    copyPermission?:boolean;
    EnableDisplacement?:boolean;
    firstAutoValue?:boolean;
    step?:any,
    onHide?:(e:boolean)=>void;
    handleCopy?:(formKey:any)=>void;
    handleShowReferenceStepper?:(referenceId:any)=>void;
}

export const DynamicFormDataEntry = ({formKey,firstAutoValue=true,step=null, EnableDisplacement=false,formBuilderMode=false,defaultInputList,copyPermission=false,id,jobId,PRJ_UID,handleCopy,onHide,mode ,handleShowReferenceStepper}:DynamicFormDataEntryPropes) => {
    const [inputs,setInputs] = useState<any>([])
    const [data,setData] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(true)
    const [times,setTimes] = useState<any>({})
    const [openStepStatus,setOpenStepStatus] = useState<any>({})
    const [showReferenceStepper, setShowReferenceStepper] = useState<boolean>(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});

    const handleSubmit = async(event) => {  
        event.preventDefault()
        const obj :any= {}
        inputs.map((input)=>{
                if((step == input.StepIndex||step==null)&&input?.systemKey !=undefined &&input.typeName != 'step'  ){
                    if(input.typeName == 'textArea' ||input.typeName == 'text' || input.typeName == 'price' ||  input.typeName == 'RadioButton'||  input.typeName == 'radio' || input.typeName == 'PositionDictionary'){
                    obj[input.systemKey] = (input.typeName == 'text' && input?.InputType == 6 ) ? event.target[input.systemKey].value  : event.target[input.systemKey].value  
                }else if(input.typeName == 'timeLength'){
                    obj[input.systemKey] = event.target[input.systemKey][0].value 
                }else if(input.typeName == 'checkBox'){
                    obj[input.systemKey] = event.target[input.systemKey].checked+""
                }else if(input.typeName == 'date' ||input.typeName == 'time' ){
                    obj[input.systemKey] = times[input.systemKey]    
                }else if(input.typeName == 'Position' ||input.typeName == 'Person' ){
                    obj[input.systemKey+"Description"] =  event.target[input.systemKey+"Description"].value
                    obj[input.systemKey] = event.target[input.systemKey].value    
                }
                else if(input.typeName == 'slider'){
                    obj[input.systemKey] = +event.target[input.systemKey].value  
                }
            }
        })
        try {
            if(mode == 'edit'){
                await  putInformationForm(data.Id,formKey,` ${JSON.stringify(obj)} `)
                onHide && onHide(true) 
            }else if(mode == 'create'){
               const res = await postDataForm(formKey,` ${JSON.stringify({JsonData :obj ,PRJ_UID: PRJ_UID })} `)

                if (showReferenceStepper) {
                    handleShowReferenceStepper && handleShowReferenceStepper(!(res.MessageType ==MessageType.success) ? false:res.Data);
                } else {
                  onHide && onHide(true) 
                }

            }
           
        } catch (error) {
            
        }
    }

    async function getFormByKeyAsync(formKey) {
        setLoading(false)
        try{
          const respons = await getFormByKey(formKey)
          setInputs(JSON.parse( respons.Data?.DynamicForms.FormFields))
          setCatchMessage({type:"info",msg:"NoDataForPreview"})

        }catch(err:any){
            setCatchMessage({type:"error" , msg:err?.response?.data?.Message})

        }
        setLoading(true)
      }


    async function getFormInformationAsync() {
        setLoading(false)
        try{
            if(jobId){
                const respons = await getFormInformationByJobId((jobId),formKey)
                setData(respons?.Data)
            }else{
                const respons = await getFormInformationById((id),formKey)
                setData(respons?.Data)
            }
          setCatchMessage({type:"info",msg:"NoDataForPreview"})
          }catch(err:any){
            setCatchMessage({type:"error" , msg:err?.response?.data?.Message})

          }
          setLoading(true)
    }
    
     function OpenStephandler(isOpen , index) {
      
        setOpenStepStatus( { ...openStepStatus,   [index]:!isOpen})

    }

    useEffect(()=>{
        if(mode != 'create'&&(jobId ? jobId:id)) {
            getFormInformationAsync()
        }
        if(defaultInputList){
            setInputs(defaultInputList)
        }else{
            getFormByKeyAsync(formKey)
        }
    },[defaultInputList])


    return ( 
        <>
        {loading ?
           <>
           {(inputs&& data)?
            <form action="" onSubmit={(e)=>{handleSubmit(e)}}>
                <div className="card mx-2 mt-1 formBuilder__formBx" style={{height:'fit-content'}}>
                    <div className="row">
                       { inputs?.map((Obj,index)=>{

            
                            return  <>
                            {
                             <>
                                {(step >= Obj.StepIndex||step==null)  ?
                                    <>{
                                     ((openStepStatus[Obj.StepIndex]   && Obj.typeName != "Step") ||(step==1&&Obj.typeName == "Step")) ?<></>
                                         :
                                     <div id={"inputsId"+index}  className={`d-flex align-items-start formBuilder__formBx__active col-${Obj.size} formBoxDr in`} >
                                         <div className={`d-flex ${Obj.typeName == 'checkBox' ?'align-items-center' :'align-items-start'} h-100 justify-content-between w-100 formBoxDr iwn`} id={index}>
                                             <InputDetector handleOpenStep={(e)=>{OpenStephandler(e , Obj.StepIndex)}}  firstAutoValue={firstAutoValue} formBuilderMode={formBuilderMode} EnableDisplacement={EnableDisplacement} mode={step > Obj.StepIndex ?"view"  : mode} typeName={Obj.typeName} property={{...Obj , defaultValue : data?.[Obj.systemKey]}} handleTime={(e)=>{setTimes({...times,[Obj.systemKey]:e})}}/>  
                                         </div>
                                         </div>
                                    }</>
                                    :
                                    <> </>
                                }
                            </>
                            }
                            </>
                        })}
                    </div>
                <div className="col-lg-12 btns_form">
                    {mode != 'view' ?
                        <div> 
                          
                         {(handleShowReferenceStepper &&     (step==1 || mode == "create")) && (
                            <Button
                              btnType="submit"
                              value="SaveAndReference"
                              onClick={() => setShowReferenceStepper(true)}
                            />
                          )}
                            <Button btnType="submit" value="Save" />
                            <Button btnType="cancel" value="cancel" onClick={()=>{onHide && onHide(false)}} /> 
                        </div>
                    :
                    <div className="m-1">
                        <Button btnType="primary" value="copy" onClick={()=>{handleCopy && handleCopy(formKey)}} />
                        <Button btnType="primary" value="close" onClick={()=>{onHide && onHide(false)}} /> 
                    </div>
                    }
                </div> 
            </div>
            </form>
           : <Message message={catchMessage.msg} type={catchMessage.type} />
           }
           </>
           :
           <Loading />
        }
        </>
     );
}




