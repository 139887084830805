import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";




export const getPositionDictionaryApi = (async (id) => await http({
    url: ENDPOINTS.BaseInformation.position.dictionary,
    method: 'get',
    params: {
        id: id
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)
export const getPositionDictionaryListApi = (async (search,id) => await http({
    url: ENDPOINTS.BaseInformation.position.dictionaryList,
    method: 'get',
    params: {
        searchStr:search
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)