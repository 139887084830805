import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {  useEffect, useRef, useState } from "react"
import { ICON_TYPE } from "utils/constants"
import { trans } from "utils/helpers"


interface ButtonToolbarHolidaysType {
    onClick?: () => void,
    Callback: (id: any) => void,

}
export const ButtonToolbarHolidaysType: React.FC<ButtonToolbarHolidaysType> = ({ onClick, Callback }) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    const buttonToolbarProcessStarting = useRef(null);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [options, setOptions] = useState<any>([{Id:"SolarHolidays"  , Name :"TransferSolarHolidays" },{Id:"LunarHolidays"  , Name :"TransferLunarHolidays" },{Id:"GregorianHolidays" , Name :"TransferGregorianHolidays" },{Id:"NationalWeekendHolidays"  , Name :"NationalWeekendHolidays" },{Id:"OrganizationalWeekendHolidays"  , Name :"OrganizationalWeekendHolidays" },{Id:"create"  , Name :"create" }])
    const [selectoptions, setSelectOptions] = useState<any>()
    const [loading, setLoading] = useState<any>()
    const [rotate , setRotate] = useState<boolean>(false);
    function showSubMenuFunction() {
        setShowSubMenu(true)
    }
    function hideSubMenuFunction() {
        setShowSubMenu(false)
    }
    function selectboxValSetter(event) {
        setSelectOptions(event.currentTarget.getAttribute('data-value'))
        Callback && Callback(event.currentTarget.getAttribute('data-value'));
        setShowSubMenu(false)
        if (buttonToolbarProcessStarting.current) (buttonToolbarProcessStarting.current as HTMLButtonElement).blur();
    }
    async function getOrganizationTypeAsync() {

        try {
  
            
        } catch (error) {
            
        }
        
    }
    useEffect(() => {
        if(showSubMenu &&options==null){
            getOrganizationTypeAsync()
        }
    }, [showSubMenu]);


    return (
        <>  <div className="toolbar-btn-wrapper">
            <button ref={buttonToolbarProcessStarting} onClick={() => { }} onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction} className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
                <div className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>F12</div>
                <FontAwesomeIcon icon={['fas', 'plus']} width="20px" height="20px" />
                <small>{trans("create")}</small>
                <div className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`}>
                {loading?
                    <>
                    {options && options.map((option, index) => (
                        selectoptions && option.Id ==selectoptions  ?
                        <div key={index} className="toolbar-btn-sub-menu__option selected"  data-value={`${option.Id}`} onClick={selectboxValSetter}>{trans(option.Name)}</div>
                       :
                        <div key={index} className="toolbar-btn-sub-menu__option" data-value={`${option.Id}`} onClick={selectboxValSetter}>{trans(option.Name)}</div>
                    ))}

                    </>
                        :
                        <div  className="sync-box-icon mt-4" onClick={()=>{setRotate(true);  setTimeout(()=>{ setRotate(false) }, 1500)}}   >       
                           <FontAwesomeIcon icon={[ICON_TYPE , "sync"] } className={`text-primary  ${rotate ? "Rotate" : "" } `}  />  
                        </div>  
                    }

                </div>
            </button>
        </div>
        
        </>
    )
    }