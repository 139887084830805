
import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { trans } from "utils/helpers";
import { http } from "utils/http";


export const getCartable = (PageNumber: number, PageSize: number, CartableType, processId, SelectedPosition?: any, orderFormat: string = "", str?: any, filterlist?: any) => http({
    url: ENDPOINTS.cartable.browse,//must be replace by cartable endpoints
    method: "get",
    params: {
        pageNumber: PageNumber,
        pageSize: PageSize,
        processId: processId,
        cartableType: CartableType,
        OrderByString: orderFormat,
        SearchString: str,
        FilterString: filterlist
    }
}).then(response => {return response})
    .catch(err => {
        return Promise.reject(err);
    })

export const getJobRequester = (jobId) => http({
    url: ENDPOINTS.cartable.requester,
    method: 'get',
    params: {
        jobId: jobId,
    }
}).then((response) => (response.data))
    .catch(err => {

    })
export const getJobReferrer = (referenceId) => http({
    url: ENDPOINTS.cartable.JobReferrer,
    method: 'get',
    params: {
        referenceId: referenceId,
    }
}).then((response) => (response.data))
    .catch(err => {

    })
export const getStartForm = (processId: any, SelectedPosition?: any) => http({
    url: ENDPOINTS.bpmnApi.process.getStartForm,
    method: 'get',
    params: {
        processId: processId,
    }
}).then((response) => (response.data))
    .catch(err => {

    })
export const getViewForm = (processId: any, SelectedPosition?: any) => http({
    url: ENDPOINTS.bpmnApi.process.getViewForm,
    method: 'get',
    params: {
        processId: processId,
    }
}).then((response) => (response.data))
    .catch(err => {

    })

export const ReferReverse = (referenceId: string, ReferDescription: string,sendMsg) => http({
    method: 'post',
    url: ENDPOINTS.cartable.refer.reverse,
    params: {
        referenceId: referenceId,
        ReferDescription: ReferDescription,
        sendMessageToPerformer:sendMsg
    }
}).then((res) => {
}).catch((err) => {
    return Promise.reject(err);
});

export const getDataForPrint = ( Id) => http({
    url: ENDPOINTS.cartable.dailyLeave.PrintData,
    method: "get",
    params:{
        JobId:Id,
        useWarmSignature: true 
    }
    
}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


// export const returnReference = (referenceId: string) => http({
//     method: 'post',
//     url: ENDPOINTS.cartable.undo,
//     params: {
//         referenceId: referenceId,

//     }
// }).then((res) => {
//      

// }).catch((err) => {
//     Toast(err.massage, 'error')
//     return Promise.reject(err);
// });
export const UnderFollowup = (referenceId: string, status) => http({
    method: 'post',
    url: ENDPOINTS.cartable.hasFollowUp,
    params: {
        referenceId: referenceId,
        hasFollowUp: status

    }
}).then((res) => {

}).catch((err) => {
    return Promise.reject(err);
});
export const ongoingReference = (referenceId: string, status) => http({
    method: 'post',
    url: ENDPOINTS.cartable.ongoing,
    params: {
        referenceId: referenceId,
        ongoing: status

    }
}).then((res) => {

}).catch((err) => {
    return Promise.reject(err);
});
export const acceptReference = (referenceId: string) => http({
    method: 'post',
    url: ENDPOINTS.cartable.accept,
    params: {
        referenceId: referenceId,

    }
}).then((res) => {
     

}).catch((err) => {
    return Promise.reject(err);
});
export const undoReference = (referenceId: string) => http({
    method: 'post',
    url: ENDPOINTS.cartable.undo,
    params: {
        referenceId: referenceId,

    }
}).then((res) => {

}).catch((err) => {
    return Promise.reject(err);
});
export const DeleteReference = (ReferenceId: string) => http({
    method: 'DELETE',
    url: ENDPOINTS.cartable.delete,
    params: {
        referenceId: ReferenceId,

    }
}).then((res) => {

}).catch((err) => {
    return Promise.reject(err);
});
export const FinishedReference = (ReferenceId: string,sendMsg ,text) => http({
    method: 'post',
    url: ENDPOINTS.cartable.terminated,
    params: {
        referenceId: ReferenceId,
        sendMessageToRequester:sendMsg,
        
    },
    data:text

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const CancelFinishedReference = (ReferenceId: string) => http({
    method: 'post',
    url: ENDPOINTS.cartable.cancelTerminated,
    params: {
        referenceId: ReferenceId,

    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const RejectReference = (ReferenceId: string ,text,sendMsg) => http({
    method: 'post',
    url: ENDPOINTS.cartable.reject,
    params: {
        referenceId: ReferenceId,
        rejectDescription:text,
        sendMessageToRequester:sendMsg
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const getReference = (referenceId: any) => http({
    url: ENDPOINTS.cartable.refer.load,
    method: 'get',
    params: {
        referenceId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


export const postReferences = (referenceList: any ) => http({
    method: 'post',
    url: ENDPOINTS.cartable.reference.browse,

    data: referenceList,
}).then((res) => {
   //  
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});

export const getAttachmentsByReferenceId = (referenceId) => http({
    url: ENDPOINTS.cartable.reference.attachments,
    method: 'get',
    params: {
        ownerId: referenceId
    }
}).then(response => response.data)
    .catch(err => {
        return Promise.reject(err);
    })





export const sendInvoiceData = (data, method) => http({
    method: method,
    url: ENDPOINTS.cartable.jobInvoice.browse,
    data: data,
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});


export const getInvoiceData = (jobId) => http({
    url: ENDPOINTS.cartable.jobInvoice.browse,
    method: "get",
    params:{
        jobId:jobId
    }
    
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});




