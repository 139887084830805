import { Toast } from "components/ToastShow/ToastShow";
import { REPORT_URL } from "utils/constants";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getReport = (objectTag) => http({
    baseURL:REPORT_URL ,
    url: ENDPOINTS.reportST.report.dictionary,
    method: 'get',
     params: {
        objectTag: objectTag
     }
}).then((response) => (response.data))




export const  postReport =( async (data) =>  await http({
    baseURL:REPORT_URL ,
    url: ENDPOINTS.reportST.report.browsTemplate,
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
    Toast(err.response.data.Message , "error")
    return Promise.reject( err);
})
)



