import { useEffect, useState } from "react";

import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { SelectBox } from "components/Select/Select";
import { Textbox } from "components/Textbox/Textbox";
import { getAllDeviceProperties } from "../api";

interface DevicePropertyValueListPropsType {
    mode: any,
    deviceDeliveryId: any,
    DevicePropertyValueDataListProp: [],
    DevicePropertyValueDataListCallback: (ans: []) => void,
    onHide: () => void,
}
export const DevicePropertyValueList = ({mode, deviceDeliveryId=null,onHide,DevicePropertyValueDataListCallback, DevicePropertyValueDataListProp }: DevicePropertyValueListPropsType) => {

    const [response, setResponse] = useState<Response | any>(null);
    const [resetForm, setResetForm] = useState(false);

    const [loading, setLoading] = useState(false);

 
    const submitHandler =(e)=>{
        e.preventDefault()
        let data:any =[]
        response.map((item , index)=>{
            data.push({
                "Sequence": index,
                "DevicePropertyId": item.Id,
                "PropertyValue": item.PropertyType==4 ? e.target[item.Id]?.checked+"": e.target[item.Id]?.value    
            })


        })
        DevicePropertyValueDataListCallback(data)

        onHide()
    }

    async function getAllDevicePropertiesAsync() {
        setLoading(false)
        try {
            const res = await getAllDeviceProperties(deviceDeliveryId);
            setResponse(res.Data);
           // setMessageType("info")
        } catch (error) {
           // setMessageType("error")
        }
        setLoading(true)
    }

    useEffect(() => {
        getAllDevicePropertiesAsync()
    }, [ deviceDeliveryId]);

    return (
        <div className="person-contact-list">
        <form className="card" onSubmit={submitHandler }>
        {loading ? 
         <div className="card-body row">
         {response?.map(item=>(
         
         <div className="col-4">

         
         {
             {
                 1: (
                    <Textbox
                    defaultValue={item.DefaultValue}      
                     textboxType="text"
                    textboxName={item.Id}
                    labelText={item.PropertyName}
                    resetForm={resetForm}
                    mode={mode}

                />
                 ),
                 2: (
                    <Textbox
                    defaultValue={item.DefaultValue}      
                    textboxType="number"
                    textboxName={item.Id}
                    mode={mode}
                    labelText={item.PropertyName}
                    resetForm={resetForm}
                />
                 ),
                 3: (                   
                    <SelectBox
                    mode={mode}
                    lable={item.PropertyName}
                    selectType="select"
                    value={null}
                    options={item.DefaultValue.split(",")}
                    val={item.DefaultValue.split(",")}
                    name={item.Id}
                  ></SelectBox>
                     
                 ),
                 4: (
                     <>
                        <div className="mt-4">
                            <CheckableButton
                            defaultChecked={item.DefaultValue=="true"}
                            defaultValue={item.DefaultValue=="true"}
                            labelText={item.PropertyName}
                            inputName={item.Id}
                            type="checkbox"
                            mode={mode}
                         />   
                        </div>
                     </>
                 )
             }[item.PropertyType]
             
         }</div>

        
        )) }
         </div>
        :<Loading/>
        }  
        {  mode !="view" &&
            <div className="col-lg-12 mt-1 btns_form">
            <Button value="Save" btnType="submit" />
            <Button btnType="cancel" value="Cancel" onClick={()=>{onHide()}} />
            </div>
        }        
        
        </form>

        </div>
    );
}