import { MEETINGMANAGEMENT_URL } from "utils/constants";
import { ENDPOINTSMEETINGMANAGEMENT } from "utils/endpoints";
import { http } from "utils/http";


export const getInvitedPeopleListById = (async (searchString  , Id) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.InvitedId,
    method: 'get',
    params: {

        SearchString : searchString ,

        Id:Id
        }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)