import { SelectBox } from "components/Select/Select";
import { InputHTMLAttributes, useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { InputLoad } from "components/InputLoading/inputLoading";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { ICON_TYPE } from "utils/constants";
import { getDatabasess } from "./api";
interface DatabasesProp extends InputHTMLAttributes<HTMLInputElement> {
  type: "SelectBox" | "Radio" | "CheckBox";
  label?: string;
  name: string;
  value?: any;
  mode?: any;
  valueList?: any;
  required?: boolean;
  Change?: (e) => void,
  DatabasesListCallback?: (e) => void,
}
export const Databases = ({ type, label, name, value,valueList ,  mode ,required,Change,DatabasesListCallback }: DatabasesProp) => {
  const [optionsDatabases, setOptionsDatabases] = useState<any>({});
  const [errcode, setErrcode] = useState<any>();
  const [app, setApp] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<any>([]);
  const layoutContext = useContext(LayoutContext);
  let Default:any = value
  let options_Databases_values:any =[];
  let options_Databases_keys:any =[];

  const DatabasesApiAsync = async ()=>{
    try {
      const res = await getDatabasess()
      setOptionsDatabases(res)


    } catch (error:any) {
      setErrcode(error.response.status)

    }
  }
  function addOption(e) {
    e.stopPropagation()
    e.preventDefault()
    
    if(app || options_Databases_keys[0]){
     let Id = app?.target?.value ? app?.target?.value :options_Databases_keys[0]
     let query =  {
         DatabasesId:Id ,
         
    }
    let Name = optionsDatabases[Id]
    if(DatabasesListCallback){
      let selectList = JSON.parse(JSON.stringify(selectedItem))
      let listId = selectList.map(item=>{
         return item.Id
      })
      DatabasesListCallback([...listId, Id])
      setSelectedItem(selectList.concat({Id:Id , Name:Name}))
    }
    }

  }



  async function deleteaOption(obj) {
    if(DatabasesListCallback){
     
    let selectList:any  = [ ]
      selectedItem.map((e)=>{
        if(e.Id != obj.Id){
          selectList.push(e)
        }
      })
      setSelectedItem(selectList)
    }
}


  useEffect(() => {
  DatabasesApiAsync()
  }, [layoutContext.currentUserPosition.PositionId ]);



  return (
    <>
      {true ?
            <>
            <div style={{display:"flex" , alignItems:'end' ,flexDirection:"row",width:"100%"}}  >
                     <div style={{width:"90%"}} >
                {optionsDatabases&&
                     <SelectBox  
                     onChange={(e) => {setApp(e);e.target.value !=(value?value:options_Databases_keys[0]) ?Change&&Change({[name] :e.target.value}):Change&&Change({[name] :null})} } 
                     required={required}
                     mode={mode}
                     lable="Databases"
                     selectType="select"
                     value={Default}
                     options={Object.values(optionsDatabases)}
                     val={Object.keys(optionsDatabases)}
                     name={name}
                     ></SelectBox>                  
                    }
                     </div>
              {mode!="view"&&
                <div  style={{marginTop:"4px" , width:"10%"}}>
                  <Button Icon={true}  value={<button onClick={addOption} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "check"]}    size="2x"  /></button>} btnType="submit"  />
                </div>
                }
             </div>
              <div className="group-person__selected-options-wrapper">
              { selectedItem.map((item, index) => (
            <div className="group-person__selected-option form-control-custom" key={item.Id + index}>

                <div style={{marginRight:'0'}}>
                 {trans(item.Name) } 
                </div>
                
                <div className="group-person__delete-selected-option" data-key={item.Id} onClick={()=>{deleteaOption(item)}}>
                    <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                </div>
            </div>
             ))}
           </div>

            </>
        :<InputLoad  msg={errcode}  required={required}  labelText={label}  onClick={DatabasesApiAsync}/> 
      }
      <div className="text-box__msg-wrapper">

        
      </div>
    </>
  );
};
