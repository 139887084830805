import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICON_TYPE } from 'utils/constants';
import { trans } from 'utils/helpers';
import "./style.scss";
export const Fileviewer = ({ File,showDownLoad=true, src ,downloadFileAsync=()=>{}, showArchive=false ,showFile=false}) => {


    
    return (
        <>
            <object style={{backgroundColor:"white"}}  data={src+""} className={`fileviewer  ${(File.includes("pdf")||File.includes("txt"))?" pdf ":"" }  ${showFile?" showFile ":"" } ${showArchive?" showArchive ":"" }`}>
                <><div className={`xlsx   ${showFile?" showFile ":"" } ${showArchive?" showArchive ":"" }`}>{trans("msg_cannotshowfile")}</div></>
            </object>{showDownLoad&& <a href={`${src}`} className="downloadfile" download={File} target="_blank"><span>{trans("download")}</span><FontAwesomeIcon icon={[ICON_TYPE, "download"]} /></a>}

  {/*           {(format == "jpeg"||format == "png"||format == "jpg"||format == "heic") && <> <img src={`data:image/jpg;base64,${src}`}  className={`fileviewer   ${showFile?" showFile ":"" } ${showArchive?" showArchive ":"" }`} /> {showDownLoad&&<a href={`${src}`} className="downloadfile" download={File} target="_blank"><span>{trans("download")}</span><FontAwesomeIcon icon={[ICON_TYPE, "download"]} /></a> }</>}
            {format == "webp" && <> <img src={`data:image/webp;base64,${src}`}  className={`fileviewer   ${showFile?" showFile ":"" } ${showArchive?" showArchive ":"" }`}/>{showDownLoad&&<a href={`${src}`} className="downloadfile" download={File} target="_blank"><span>{trans("download")}</span><FontAwesomeIcon icon={[ICON_TYPE, "download"]} /></a>}</>}
            {format == "txt" && <><div className={`fileviewer txt  ${showFile?" showFile ":"" } ${showArchive?" showArchive ":"" }`} ><div className='textx'>{Src} <br/></div></div>{showDownLoad&&<a href={`${src}`} target="_blank" className="downloadfile" download={File}><span>{trans("download")}</span><FontAwesomeIcon icon={[ICON_TYPE, "download"]} /></a>}</>}
            {!support && <><div className={`xlsx   ${showFile?" showFile ":"" } ${showArchive?" showArchive ":"" }`}> {trans("msg_cannotshowfile")}</div>{showDownLoad&&<a href={`${src}`} target="_blank" className="downloadfile" onClick={downloadFileAsync} download={File}><span>{trans("download")}</span><FontAwesomeIcon icon={[ICON_TYPE, "download"]} /></a> }</>}
 */}
        </>
    );    
}







