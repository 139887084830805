import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { Button } from "components/Button/Button"
import { CheckableButton } from "components/CheckableButton/CheckableButton"
import { FileInput } from "components/FileInput/FileInput"
import { Loading } from "components/Loading/loading"
import { ModalForm } from "components/ModalForm/ModalForm"
import { TabBar } from "components/Tabbar/Tabbar"
import { Textbox } from "components/Textbox/Textbox"
import { Toast } from "components/ToastShow/ToastShow"
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment"
import { useEffect, useRef, useState } from "react"
import { ENDPOINTS } from "utils/endpoints"
import { trans } from "utils/helpers"
import { getSharingCenterId, sendSharingCenter } from "../../api"
import { SharingCenterAccess } from "./SharingCenteresAccess"
import './style.scss'
import { DictionarySelectBox } from 'components/DictionaryTypeSelect/DictionaryTypeSelect'

interface props {
    mode: any
    showForm: (e:boolean) => void
    selectedObj: any
}

export const SharingCenterForm = ({  mode, showForm, selectedObj }: props) => {

    const [editor, setEditor] = useState<any>();
    const [editorData, setEditorData] = useState<any>();
    const [resetForm, setResetForm] = useState(false);
    const [response, setResponse] = useState<any>();
    const [showAccess, setShowAccess] = useState<any>(false);
    const [SharingCenterData, setSharingCenterData] = useState<any>(false);
    const [converted, setConverted] = useState<any>(false);
    const [SharingCenterType, setSharingCenterType] = useState<any>();
    const [selectedImage, setSelectedImage] = useState<any>();
    const [loading , setLoading] = useState(mode=="create");
    const [userChanges, setUserChanges] = useState({});
    const [curentTab, setCurentTab] = useState<any>("sharingcenterarticle");
    const [flag,setFlag] = useState(false);
    const [canSendSMS,setCanSendSMS] = useState(false);
    const [SendSMS,setSendSMS] = useState(false);


    const myform = useRef<any>(null);
    const changeHandlePic = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files);
        }
    };

    async function sendDataAsync(data, method) {
        try {
            const res = await sendSharingCenter(data, method ,SendSMS);
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
            showForm(true)
        } catch (err: any) {

        }
    }

    async function getDataAsync(id) {
        setLoading(false)
        try {
            const res = await getSharingCenterId(id);
            setResponse(res.Data)
            setSelectedImage(res.Data.Article.Thumbnail == undefined ? null : res.Article.Thumbnail)
            setEditorData(res.Data.Article.Description)
        } catch (err: any) {

        }
        setLoading(true)

    }

    
    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                showForm(false)
            });
        }else{
            showForm(false)     
        };


    }

    function accessDatahandler(data) {
        if (mode == "create") {
            let formdata: any = {}
            formdata.Article = SharingCenterData
            formdata.Accesses = data;
            let _objData = new FormData

            _objData.append("SharingCenterArticleDTO", JSON.stringify(formdata));


            if (selectedImage) {
                const ListSelected:any = [...selectedImage]
                ListSelected.map(item=>{
                    _objData.append("files", item);
              
                  })
            }

            sendDataAsync(_objData, "post")
        } else {
            let Article = {
                Article: SharingCenterData,
                Accesses: data
            }
            sendDataAsync(Article, "put")
        }
    }



    const handleSubmit = (event) => {
        event.preventDefault()
        if(flag){
            Toast(trans("InvalidEndDate"),"error");
        }else{
        setConverted(true)

        if (mode == "create") {
            const data = {
                Title: event.target.title.value,
                Description: editorData,
                ClassificationId: event.target.ClassificationId.value,
                IsPublic: event.target.IsPublic.checked,
                Link: event.target.linkAddress.value,
                ActivityStatus: 1
            }
            if (event.target.IsPublic.checked) {
                let formdata: any = {}
                formdata.Article = data
                let _objData: any = new FormData
                _objData.append("SharingCenterArticleDTO", JSON.stringify(formdata));
                if (selectedImage) {
                    const ListSelected:any = [...selectedImage]
                    ListSelected.map(item=>{
                        _objData.append("files", item);
                  
                      })
                }

                sendDataAsync(_objData, "post")
            } else {
                setSharingCenterData(data)
                setShowAccess(true)
            }
        } else {

            const data = {
                Id: selectedObj.Id,
                Title: event.target.title.value,
                Description: editorData,
                ClassificationId: event.target.ClassificationId.value,
                Link: event.target.linkAddress.value,
                IsPublic: event.target.IsPublic.checked,
                ActivityStatus: 1
            }

            if (event.target.IsPublic.checked) {
                let Article = {
                    Article: data
                }
                sendDataAsync(Article, "put")
            } else {
                setSharingCenterData(data)
                setShowAccess(true)
            }

        }
    }
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


      const tabChangeHandle = (tab) => {
        setCurentTab(tab)
      }
      
    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){
            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);

    useEffect(() => {
        if (selectedObj && mode != "create") {
            getDataAsync(selectedObj.Id)
        }
    }, [selectedObj]);




    let content = [
        { title: "sharingcenterarticle", body: (    <>
        <div className="p-2">
            {showAccess &&
                <ModalForm mode={mode} modalProfileKey={"Users"} title={trans("Users")+" : " +SharingCenterData.Title } onHide={() => setShowAccess(false)} show={showAccess}>
                    <SharingCenterAccess
                        archive={selectedObj}
                        showForm={setShowAccess}
                        accessDatahandler={accessDatahandler}
                        accessData={response?.Accesses}
                        mode={mode}
                    />
                </ModalForm>
            }
            {loading ?
            <form className={mode} ref={myform} onSubmit={handleSubmit}>
                <div className="row">
                    <div className="row mb-3">
                        <div className="col-12 col-md-6 col-lg-3">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="text"
                                labelText={SharingCenterType == "2" ? "footer" : "title"}
                                textboxName="title"
                                resetForm={resetForm}
                                defaultValue={response?.Article?.Title}
                                mode={mode}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
                            <DictionarySelectBox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             mode={mode}
                             name="ClassificationId"
                             label='sharingcenterarticleclassification'
                             endPoint={ENDPOINTS.SharingCenterApi.SharingCenterArticleClassification.dictionary}
                             value={response?.Article?.ClassificationId}
                            />
                        </div>
                        <div className="col-3 col-lg-3  col-md-6">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="text"
                                labelText="linkAddress"
                                textboxName="linkAddress"
                                defaultValue={response?.Article?.Link}
                                mode={mode}
                                resetForm={resetForm}
                            />
                        </div>
                        <div className="col-3 col-md-3 mt-3 pt-2">
                            <CheckableButton
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                defaultChecked={response?.Article?.IsPublic}
                                defaultValue={response?.Article?.IsPublic}
                                labelText={"IsPublic"}
                                inputName={"IsPublic"}
                                type="checkbox"
                                mode={mode}
                            />
                        </div>
                        {
                            (mode=="create"&&canSendSMS)&&
                        <div className="col-3 col-md-3 mt-3 pt-2">
                            <CheckableButton
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                defaultChecked={SendSMS}
                                defaultValue={SendSMS}
                                readOnly={!canSendSMS}
                                checked={SendSMS}
                                onchange={setSendSMS}
                                labelText={"NotifyBySMS"}
                                inputName={"NotifyBySMS"}
                                type="checkbox"
                                mode={mode}
                                />
                        </div>
                        }
                    </div>
                    <div className="row mb-5">
                        <div className="col-12">
                            <span>{trans('text')}</span>
                            <CKEditor
                                data={editorData}
                                disabled={mode == "view" && "true"}
                                onReady={editor => {
                                    editor.ui.getEditableElement().parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.getEditableElement()
                                    );
                                    setEditor(editor)
                                }}
                                onError={(error, { willEditorRestart }) => {
                                    if (willEditorRestart) {
                                        editor.ui.view.toolbar.element.remove();
                                    }
                                }}
                                onOk={(editor) => {
                                    editor.insertHtml(editorData)
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEditorData(data)
                                }}
                                editor={DecoupledEditor}
                                config={{
                                    removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                                }}
                            />
                        </div>
                    </div>

                    <div className="row mb-1">
                        {(SharingCenterType != "2"&&mode=="create") &&
                            <div className="col-12 col-md-6 mb-3 mb-md-0">

                            <FileInput
                                hideSrc={selectedImage ?false :true}
                                multiple={true}
                                mode={ mode}
                                lable="picture"
                                name="picture"
                                onChange={changeHandlePic}
                                src={selectedImage  ? (URL.createObjectURL(selectedImage[0] )) :""}
                                />
                            </div>

                        }
                    </div>
                    {mode !== "view" ?
                        <div className="row my-2">
                            <div className="col-12 d-flex justify-content-end">
                                <Button btnType="submit" value={'submit'} />
                                <Button btnType="cancel" value={'cancel'} onClick={() => { cancel() }} />
                            </div>
                        </div>
                        :
                        <div className="row my-2">
                            <div className="col-12 d-flex justify-content-end">
                                <Button btnType="primary" value={'close'} onClick={() => { cancel() }} />
                            </div>
                        </div>
                    }
                </div>
            </form>
            : <Loading/>
            }
        </div>

          </>) },
        mode !="create"&&{title: "picture", body: (<Attachment onHide={()=>{cancel()}}  inTab={curentTab=="picture"?"Attachment":curentTab} mood={mode} endpoint={ENDPOINTS.SharingCenterApi.SharingCenter.attachment} ownerId={selectedObj.Id} />) },
     
    ];
  








    

    return (
        <>
        <TabBar  tabChange={tabChangeHandle} content={content} defaultTab="sharingcenterarticle" />
        </>
    )
}