import { BaseUrlsKey, MEETINGMANAGEMENT_URL } from "utils/constants";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

import { ENDPOINTSMEETINGMANAGEMENT } from "utils/endpoints";



export const getDefaultSignature = (async (personId ) => await http({
    url: ENDPOINTS.BaseInformation.PersonSignature.load,
    method: "get",
    params:{
        personId :personId ,
       
    }
}).then((res) => {

    return res
}).catch((err) => {
 
    return Promise.reject( err);
})

)


export const  getPersonSignatureIdApi =( async ( Id ) =>  await http({
    url: ENDPOINTS.BaseInformation.PersonSignature.list,
    method: "get",
    params:{
        personId:Id
    }

})
.then((respons) => { 
   return respons.data

}).catch((err) => {
    return Promise.reject( err);
})

)

export const getSignature = (async ( referenceId ,parentFrom ) => await http({
    url: ENDPOINTS.cartable.signature.load,
    method: "get",
    params:{
        parentFrom:parentFrom,
        referenceId : referenceId
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getMeetingSignature = ( ( MeetingId ,PositionId ) =>  http({
    baseURL:MEETINGMANAGEMENT_URL , 
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.Signature,
    method: "get",
    params:{
        MeetingId:MeetingId,
        PositionId : PositionId
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getSignatureHistory = (async ( referenceId ,parentFrom ) => await http({
    url: ENDPOINTS.cartable.signature.browse,
    method: "get",
    params:{
        referenceId : referenceId,
        parentFrom:parentFrom
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const sendSignature = (async (baseUrlKey  ,data , method ,referenceId,ParentForm) => await http({
    baseURL : BaseUrlsKey[baseUrlKey] , 
    url: baseUrlKey=="automation" ?  ENDPOINTS.cartable.signature.browse :ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.Signature ,
     method: method,
     params:{
        referenceId:referenceId,
        parentFrom:ParentForm
     },
    data:data
}).then((res) => {

    return res
}).catch((err) => {
 
    return Promise.reject( err);
})

)
