import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getItemBranch, sendItemBranch } from "./api";
import { ItemMainPropertyVariation } from "../../components/ItemMainPropertyVariation/ItemMainPropertyVariation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface ItemBranchType {
    mode?: any,
    checkData?: any,
    showForm: (e:boolean) => void
    selectedObj:any;
}

export const ItemBranch = ({mode,selectedObj,checkData,showForm}:ItemBranchType) => {
    const [resetForm, setResetForm] = useState(false);
    const [response,setResponse] = useState<any>()
    const [loading,setLoading] = useState<boolean>(false)
    const [canPaste,setCanPaste] = useState<any>()
    const [selectedItemMainPropertyValues, setSelectedItemMainPropertyValues] = useState<any>();
    const [descriptions, setDescriptions] = useState<any>({});

    const sendItemGroupAsync = async(method , data) => {
        try {
            const res = await sendItemBranch(data ,method )
            showForm(true)       
        } catch (error) {
            
        }  
    }

    async function formSubmit(event) {
        event.preventDefault();    
            let data ={
             "GroupLeafId": checkData?.GroupLeafId,
              ParentId: selectedObj.Id,
              Code: ((checkData?.ParentCode ? checkData?.ParentCode+ event.target?.Code?.value :checkData?.ParentCode+ event.target?.Code?.value).replace("undefined","")),
              SummaryDescription : event.target.SummaryDescription.value,
              SummaryDescriptionEN : event.target.SummaryDescriptionEN.value, 
              "Description": event.target.Description.value,
              "DescriptionEN":event.target.DescriptionEN.value,
              "ParentLayerLeafId": checkData.ParentLayerLeafId,
              "ItemLayerType": checkData.ItemLayerType,     
              "SelectedItemMainProperty": checkData.ItemMainPropertyId,
              "SelectedItemMainPropertyValues": selectedItemMainPropertyValues ? selectedItemMainPropertyValues.map(item=>(item.Id)):null
             }
             if(mode=="create"){
                 sendItemGroupAsync("post" ,data)
             }else{
                sendItemGroupAsync("put" ,{...data , Id: response.Id ,ParentId:response.ParentId ,IsLeaf:response.IsLeaf})

             }
    }
    
    async function getDataAsync() {
        setLoading(false)
        try {
           const res = await getItemBranch(selectedObj.Id);
           setResponse(res.Data);
      
        } catch (error) {
        }
        setLoading(true)
    }
    async function checkPaste() {
        try {
            
            let data =await navigator.clipboard.readText()
            if(data && JSON.parse(data)){
                setCanPaste(true)
            }
        } catch (error) {
            setCanPaste(false)

        }
    }
    const pasteHandler = async() => {
       const text =JSON.parse( await navigator.clipboard.readText());
          setResponse(text)
        try {
        } catch (error) {
            
        }  
    }
    useEffect(() => {
        if(selectedObj?.Id && mode !="create"){
            getDataAsync()
        }else{
            setLoading(true)
        }
        checkPaste()
    }, [selectedObj]);

    return (
<>
{
    loading ? 

        <form className="Main-form py-2" id={"ItemForm"} name={"main"} onSubmit={formSubmit}>
            <div className="card">
            { (mode != "create" && response)||(mode == "create") ?
                <div className="card-body">
                    <div className="row">
                    {(canPaste&&mode!="view")&&
                        <div onClick={()=>{pasteHandler()}} className="col-2  btn btn-primary btn-sm d-flex align-items-center justify-content-between " style={{width:'89px' , maxHeight:"30px", marginRight:"20px" , position:"absolute" , left:"5px"}}>
                            <span className=" font-size-md ">{trans('paste')}</span>
                            <FontAwesomeIcon size="xs" icon={['fas', 'paste']} className="color_text_light d-flex Requests__chatbox-reply-btn mx-1"   color='white' />
                        </div>                    
                     }
                     {(!checkData.ItemMainPropertyId||mode=="view")?

                        <div className="col-2">
                            <Textbox
                                textboxType="text"
                                textboxName="Code"
                                UsepadWithZeros={true}
                                maxLength={+checkData.CodeLength}
                                defaultValue={response?.Code ? response?.Code.replace(checkData?.ParentCode,"") : checkData?.Code + ""}
                                fixedlabel={checkData ? checkData?.ParentCode+"":checkData?.ParentCode}
                                resetForm={resetForm}
                                required={!checkData.ItemMainPropertyId }
                                labelText={trans("itemcode") + ( mode !== "create" ? "" : `  (${"طول مجاز"} : ${checkData.CodeLength})`)}
                                mode={checkData.ItemMainPropertyId?"view" :  mode}
                                />
                        </div>
                        :<></>

                     }
                        <div className="col-2">
                            <Textbox
                                textboxType="text"
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,summaryDescription:e })}}
                                textboxName="SummaryDescription"
                                resetForm={resetForm}
                                required={!checkData.ItemMainPropertyId }
                                defaultValue={response?.SummaryDescription	 ? response.SummaryDescription	 : (!checkData.ItemMainPropertyId ?checkData?.ItemMainPropertyDescription :null) }
                                labelText="itemSummaryDescription"
                                mode={mode}
                            />
                        </div>
                        <div className="col-4">
                            <Textbox
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,Description:e })}}
                                required={!checkData.ItemMainPropertyId }
                                textboxType="text"
                                textboxName="Description"
                                resetForm={resetForm}
                                defaultValue={ response?.Description ? response?.Description : ((descriptions.Description ? descriptions.Description: descriptions.summaryDescription))}
                                labelText="itemDescription"
                                mode={mode}
                            />
                        </div>
                        
                    </div>  
                    <div className="row">
                        <div className="col-3">
                            <Textbox
                                 onBlurHandler={(e)=>{setDescriptions({...descriptions ,SummaryDescriptionEN:e })}}

                                textboxType="text"
                                textboxName="SummaryDescriptionEN"
                                resetForm={resetForm}
                                defaultValue={response?.SummaryDescriptionEN? response?.SummaryDescriptionEN : checkData?.summaryDescriptionEN}
                                labelText="itemSummaryDescriptionEN"
                                mode={mode}
                            />
                        </div>
                        <div className="col-4">
                            <Textbox
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,DescriptionEN:e })}}
                                textboxType="text"
                                textboxName="DescriptionEN"
                                resetForm={resetForm}
                                defaultValue={response?.DescriptionEN ? response?.DescriptionEN :  (descriptions.DescriptionEN ? descriptions.DescriptionEN: descriptions.SummaryDescriptionEN)}
                                labelText="itemDescriptionEN"
                                mode={mode}
                                />
                        </div>

                    </div>
                    {checkData.ItemMainPropertyId ?
                            <ItemMainPropertyVariation  SelectedItemMainPropertyValues={setSelectedItemMainPropertyValues} ItemMainPropertyId={checkData.ItemMainPropertyId}ItemMainPropertyDescription={checkData.ItemMainPropertyDescription}  />

                            :
                            <>
                            </>
                    }
                    <div className="d-flex justify-content-end mt-2">
                    {mode !== "view" ?
                                <>
                                    <Button    value="Save" btnType="submit" />
                                    <Button
                                        btnType="cancel"
                                        value="Cancel"
                                        onClick={() => { showForm(true)}}
                                    />
                                </>
                                :
                                 <Button
                                    btnType="primary"
                                    value="Close"
                                    onClick={() =>{ showForm(true)}}
                                  />
                            } 
                    </div>
                </div>
                :
                <Loading/>
                }
            </div>

        </form>
    :
        <Loading />
    }
    </>
    );
    
}
 