import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { acceptReference } from "pages/OfficeAutomation/Cartable/api/api";
import { useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getDocuments, getDocumentsByReferenceId, getDynaFormProcessId, getReverseList, postDocuments, putDocuments } from "./api";
import { KeyWord } from "components/KeyWord/KeyWord";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { FileInput } from "components/FileInput/FileInput";
import DatePicker from "components/DatePicker/DatePicker";
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment";
import { ENDPOINTS } from "utils/endpoints";
import { TabBar } from "components/Tabbar/Tabbar";
import { Message } from "components/Message/Message";
import { TreeView } from "components/TreeView/TreeView";
import { ArchiveFolderForm } from "pages/Secretariats/Archive/ArchiveStructure/components/ArchiveFolderForm/ArchiveFolderForm";
import { getOrganizationArchive } from "pages/OfficeAutomation/Cartable/components/ArchiveForm/api";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { AcceptSelfService } from "pages/OfficeAutomation/Cartable/components/AcceptSelfService/AcceptSelfService";
import { MessageType } from "types/enum";

interface DocumentsFormPropsType {
  onHide: () => void;
  handleCancelForm: (ans: boolean) => void;
  handleShowReferenceStepper?: (referenceId: string) => void;
  referenceId?: string;
  ArchiveList?: any;
  Id?: string;
  mode;
  inDynamicForms?: any;

}
export const DocumentArchiveForm = ({
  handleCancelForm,
  referenceId,
  ArchiveList,
  Id,
  mode,
  onHide,
  handleShowReferenceStepper,
  inDynamicForms=false
}: DocumentsFormPropsType) => {

  let references: any;

  const [showReferenceStepper, setShowReferenceStepper] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const layoutContext = useContext(LayoutContext);
  const [response, setResponse] = useState<any>();
  const [processId, setProcessId] = useState<any>();
  const [reverseResponse, setReverseResponse] = useState<any>();
  const [showAccept, setShowAccept] = useState<any>(true);

  const [keyWords, setKeyWords] = useState<any>([]);
  const [selectedImages, setSelectedImages] = useState<any>();
  const [isPhysical, setIsPhysical] = useState<any>();
  const [curentTab, setCurentTab] = useState<any>("Documents")
  const [message, setMessage] = useState<any>("invalid position");
  const [messageType, setMessageType] = useState<any>(null);
  const [userChanges, setUserChanges] = useState({});
  const [showTree, setShowTree] = useState(false);
  const [documentArchiveRequestData, setDocumentArchiveRequestData] = useState<any>();



  const [selectedList, setSelectedList] = useState<any>([])
  const [idCallback, setIdCallback] = useState<any>("2")
  const [parent, setParent] = useState<any>(null)
  const [data, setData] = useState<any>()
  const [showForm, setShowForm] = useState(false)
  const [selected, setSelected] = useState<any>()
  const [ListResponse, setListResponse] = useState<any>()

  let tbodyData: any = [];
  let theadData: any = []
  async function getDocumentsAsync() {
    setLoading(false);
    try {   
      
        if(Id){
          const res = await getDocuments(Id );
          setResponse(res.Data);
          setKeyWords(res.Data?.Keywords.replaceAll("'" , "").split(","))
          setIsPhysical(res.Data?.IsPhysical)
        }else if(referenceId){
          const res = await getDocumentsByReferenceId(referenceId);
          setResponse(res);
          setKeyWords(res.Data?.Keywords.replaceAll("'" , "").split(","))
          setIsPhysical(res.Data?.IsPhysical)
        }
    } catch (error:any){

      setMessage(error.data.Message)
      setMessageType("error")
     }
    
    setLoading(true)
  }
  async function getDynaFormProcessIdAsync() {
    try {
          const res = await getDynaFormProcessId("DocumentArchiveForm" );
          setProcessId(res.Data)
    } catch (error){ }
  }


  function cancel() {
    if(sessionStorage.getItem("changingforms")+""=="true"){
        Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
            onHide()
        });
    }else{
      onHide()
    };


}

const handleBeforeUnload = (event) => {
  event.preventDefault();
  event.returnValue = '';
};


useEffect(() => {
  if(Object.values(userChanges).filter((item)=>item!=null).length>0){

      sessionStorage.setItem("changingforms" , "true")
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
  }else{
      sessionStorage.setItem("changingforms" , "false")
      window.removeEventListener('beforeunload', handleBeforeUnload);

  }
}, [userChanges]);


  useEffect(() => {
    if(mode != "create" ){
      if ((referenceId||Id) && !showReferenceStepper) getDocumentsAsync();

    }else{
        getDynaFormProcessIdAsync()
    }
  }, [referenceId ,Id]);

  const handleDocumentsFormSubmit =async (event) => {
    event.preventDefault();
    let addList:any = [];

    let DocumentArchiveRequest:any = {}
    if (mode == "edit") {
      DocumentArchiveRequest = {
        Id: Id,
        Description: event.target.Description.value,
        RequesterPositionId: event.target.RequesterPosition.value,
        Applicant: event.target.applicant.value,
        IsPhysical: event.target.IsPhysical.checked,
        DocumentArchiveRequestClassificationId :event.target.IsPhysical.checked ? event.target.DocumentArchiveClassificationId.value:null  ,
        IsCopy: event.target.IsCopy.checked,
        Keywords:"'"+keyWords.join("','")+"'"
      };
      sendDataAsync(DocumentArchiveRequest, "put" );
    } else {
      if(selectedImages){
      DocumentArchiveRequest = {
        Description: event.target.Description.value,
        RequesterPositionId: event.target.RequesterPosition.value,
        Applicant: event.target.applicant.value,
        IsPhysical: event.target.IsPhysical.checked,
        IsCopy: event.target.IsCopy.checked,
        DocumentArchiveRequestClassificationId :event.target.IsPhysical.checked ? event.target.DocumentArchiveClassificationId.value:null  ,
        Keywords:"'"+keyWords.join("','")+"'"
      };
      if(ArchiveList){

        ArchiveList.forEach((item , index )=> {
          addList.push(item.Id)
        });
        let data = {
          "DocumentArchiveRequest":DocumentArchiveRequest,
          "OrganizationalArchiveDetailIdList": addList
        }

        let _objData = new FormData
  
        _objData.append("DocumentArchiveDTO", JSON.stringify(data));
        
        const ListSelected:any =[...selectedImages]  
        ListSelected.map(item=>{
          _objData.append("files", item);
          
        })
        sendDataAsync(_objData, "post" );
  
        
      }else{
        setDocumentArchiveRequestData(DocumentArchiveRequest)
        setShowTree(true)

        const respons = await getOrganizationArchive(null)
        if(respons.Data.length>0){
          setData(respons.Data)
        }

      }

    }else{
      
      Toast("Msg_LetterAttachmentIsRequired","error")
    }
    }
  };

  async function handaleSelectedArchives(e) {
      e.preventDefault()
      let addList:any = []
      if(selectedList.length > 0){
        selectedList.forEach((item , index )=> {
          addList.push(item.Id)
          
        });
        let data = {
          "DocumentArchiveRequest":documentArchiveRequestData,
          "OrganizationalArchiveDetailIdList": addList
        }
        let _objData = new FormData
        
        _objData.append("DocumentArchiveDTO", JSON.stringify(data));
        const ListSelected:any = [...selectedImages]
        ListSelected.map(item=>{
        _objData.append("files", item);
        
      })
      sendDataAsync(_objData, "post" );
    }else{
      Toast(trans("msg_chooselocationforarchive"), "warning")
    }
  }
  async function acceptReferenceAsync() {
    try {
        const res = await acceptReference(references.Id);
        setShowAccept(false)
    } catch (error) {

    }
 
}


function getBase64(file) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
  };
  reader.onerror = function (error) {
  };
}

const changeHandlePic = (e: any) => {
  if (e.target.files && e.target.files.length > 0) {
    getBase64(e.target.files[0])
    setSelectedImages(e.target.files);
  }
};


async function sendDataAsync(data, method ) {
    try {
      let res;
      if (method === "post") res = await postDocuments(data, true  , processId);
      else res = await putDocuments(data);
      sessionStorage.setItem("changingforms" , "false");
      setUserChanges({});
      if (showReferenceStepper) {
        handleShowReferenceStepper && handleShowReferenceStepper(!(res.MessageType ==MessageType.success) ? false:res.Data);
      } else {
        handleCancelForm(false);
        onHide();
        setDocumentArchiveRequestData(null)
        setShowForm(false)
      }
    } catch (err: any) { }
}

const tabChangeHandle = (tab) => {
  setCurentTab(tab)
}





  let content = [
      { title: "Documents", body: (    <>
        {(references?.AssignType == 2 && showAccept)&&
         <AcceptSelfService  handleAcceptReferenceAsync={()=>{acceptReferenceAsync()}} onHide={(e)=>{e.stopPropagation();  setShowAccept(false)}}/>

        }
        <div className={``}>
              {loading ? (
                  <>{messageType ==null?
                    <div className="list-card card  mb-2">
                      <div className="list-card__body">
                          <form action="" onSubmit={handleDocumentsFormSubmit} className={"p-2 "+mode}>
                  <div className="row">
                    { mode != "create" && 
                    <>
                    <div className="col-12 col-sm-4">
                      <Textbox
                        textboxType="number"
                        resetForm={resetForm}
                        textboxName="RequestNo"
                        labelText="RequestNo"
                        defaultValue={response?.RequestNo}
                        mode={"view"}
                        readonly={true}
                      />
                    </div>
                    <div className="col-12 col-sm-4 mb-2 mb-sm-0">
                      <DatePicker
                          required={true}
                          lable="RequestDate"
                          setIsoTime={(isoTime: string) => {  }}
                          val={response?.RequestDate}
                          resetForm={resetForm}
                          mode={"view"}
                      />
                    </div>
                    </>
                    }
    
                    <div className="col-12 col-sm-4 mb-2 mb-sm-0">
                      {
                        <Position
                          name="RequesterPosition"
                          required={true}
                          label="asker"
                          resetForm={resetForm}
                          value={
                            response
                              ? response.RequesterPositionId
                              : layoutContext.currentUserPosition.PositionId
                          }
                          mode={"view"}
    
                        />
                      }
                    </div>
                    <div className="col-12 col-sm-4 mb-2 mb-sm-0">
                      <Textbox
                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                        textboxType="text"
                        resetForm={resetForm}
                        textboxName="applicant"
                        labelText="applicant"
                        defaultValue={response?.Applicant}
                        mode={mode}
                      />
                    </div>
                    <div className="col-2 mt-3 pt-3 my-lg-0 col-lg-1">
                        <CheckableButton
                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                            defaultValue={1}
                            onchange={setIsPhysical}
                            type='checkbox'
                            labelText='IsPhysical'
                            defaultChecked={ response?.IsPhysical}
                            inputName='IsPhysical'
                            mode={mode}
                        />
                    </div>
                    <div className="col-2 mt-3 pt-3 my-lg-0 col-lg-1">
                        <CheckableButton
                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                            defaultValue={1}
                            type='checkbox'
                            labelText='IsCopy'
                            defaultChecked={ response?.IsCopy}
                            inputName='IsCopy'
                            mode={mode}
                        />
                    </div>
    
                    {isPhysical && 
                      <div className="col-12 col-sm-4 mb-2 mb-sm-0">
                      <DictionarySelectBox
                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                        name="DocumentArchiveClassificationId"
                        label="documentarchiverequestclassification"
                        endPoint={ENDPOINTS.archive.documentArchiveRequestClassification.dictionary}
                        value={response?.DocumentArchiveRequestClassificationId}
                        mode={mode}
                      />
                    </div>
                    }
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <Textbox
                        required={true}
                        minHeight={mode == "view" ? 3 : 4}
                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                        textboxType="textarea"
                        labelText="Description"
                        resetForm={resetForm}
                        textboxName="Description"
                        defaultValue={response?.Description}
                        mode={mode}
                      />
                    </div>
                    <div className="col-6">
                        <KeyWord
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          recordedData={response?.Keywords?.replaceAll("'" , "").split(",")}
                          setKeyWords={setKeyWords}
                          keyWords={keyWords}
                          mode={ mode}
                          name="KeyWords"
                         />
                        </div>
                  </div>
                  {mode == "create" && 
                  <div className="row">
                  <div className="col-5">
                    <FileInput
                     // file={selectedImage ? {fileName:selectedImage.name, src:selectedImage64} : null}
                      hideSrc={selectedImages ?false :true}
                      multiple={true}
                      required={true}
                      mode={ mode}
                      lable="picture"
                      name="defaultAttachment"
                      onChange={changeHandlePic}
                      src={selectedImages  ? (URL.createObjectURL(selectedImages[0] )) :""}
                    />
                  </div>
                  </div>
                  }
                  {mode != "view" ? (
                    <div className="d-flex justify-content-end">
                      <Button btnType="submit" value="save" />
                      <Button
                        btnType="cancel"
                        value="cancel"
                        onClick={() => {cancel()}}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                          </form>
                      </div>
                    </div>
                :
                <Message  message={trans(message)} type={"error"} />
                }</>
              ) : (
                <Loading />
              )}
        </div>
        </>) },
      mode !="create"&&{title: "Attachment", body: (<Attachment haveVersion={true}  onHide={() => {cancel()}}  inTab={curentTab} mood={mode} endpoint={ENDPOINTS.archive.documentArchive.attachment} ownerId={Id} />) },
   
  ];










    if (data ) {
        theadData = Object.keys(data[0])
        tbodyData = data;
    }
    
  return (


    <>
    <TabBar  tabChange={tabChangeHandle} content={content} defaultTab="Documents" />
    {
            showForm &&
              <ModalForm mode={"create"} title="File" onHide={() => setShowForm(false)} show={showForm}>
                  <ArchiveFolderForm 
                    showForm={() =>{ setIdCallback(parent.Id)  ;setShowForm(false);}} 
                    selectedObj={parent} 
                    mode={"create"} 
                  />
              </ModalForm>
        }
    {showTree&& 
              <ModalForm mode={"create"}modalProfileKey={"Archive"}  title={`${trans("Archive")}(${trans("Msg_ChooseLocationForArchive")})`} onHide={() => setShowTree(false)} show={showTree}>
              <div className="card">
                <div className="card-body">
                  <>
                    {
                      loading ? (
                        data ?
                          <TreeView
                            addNewNode={(id)=>{setParent(id) ; setShowForm(true)}}
                            IsArchive={true}
                            multiselect={true}
                            selectedRowObjectCallback={setSelected}
                            selectedRowObject={idCallback}
                            getChildrenUrl={ ENDPOINTS.archive.organizationalArchive.archives}
                            theadData={["Id" , "ParentId", "DetailList" , "Title" ]}
                            startIndex={2}
                            Titleparams={"Title"}
                            Childrenparams={'DetailList'}
                            scrollHeight={"370px"}
                            defultShow={false}
                            Data={data} 
                            parentName={"ArchiveFiles"}
                            selectedRowsCallback={setSelectedList}
                            ListResponse={ListResponse}
        
                          />
                          : <Message message={"NoDataForPreview"} type={messageType} />
                      )
                        : <Loading />
                    }
                  </>
                </div>
                
                <div className="col-lg-12 mt-1 btns_form">
                  <Button value="archive" btnType="submit" onClick={(e)=>{handaleSelectedArchives(e)}} />
                  {!inDynamicForms&&
                  <Button
                  btnType="cancel"
                  value="Cancel"
                  onClick={() => {setShowTree(false)}}
                  />
                }
        
                </div>
              </div>
              </ModalForm>
    
    }
    
    </>

  );
};