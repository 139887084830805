import { SwitchBtn } from "components/SwitchBtn/SwitchBtn";

interface ItemCardsHeaderProps {
    handleModeCard : (e:boolean)=>void,
    cardStyle: boolean,
    data?:any,
}


export const ItemCardsHeader = ({handleModeCard,cardStyle,data}:ItemCardsHeaderProps) => {
    return ( 
        <nav className="d-md-flex justify-content-end ms-4 col-2">
            <div style={{maxWidth:'25%',minWidth:'fit-content'}}>
                <SwitchBtn firstMode="کارتی" secondMode="لیستی" setColor="customerColor" setShape="circle" cardStyle={cardStyle} handleModeCard={handleModeCard}/>
            </div>
        </nav>
     );
}