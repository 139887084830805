import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";
//export const getApplications = () =>http.get(ENDPOINTS.application).then(respons => respons.data)

// export const getSidebarMenus = () =>
//     http.get(ENDPOINTS.menu).then(respons => respons.data)
//         .catch(err => {
//             //TODO باید همه خطایا یه جا مدیریت بشه
//         })

export const getApplications = () => http({
    url: ENDPOINTS.application.browse,
    method: 'get',

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});





export const getResources = () => http({
    url: ENDPOINTS.resources,
    method: 'get',

}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject(err);
});



export const getMenus = (applicationId: any) => http({
    url: ENDPOINTS.menu,
    method: 'get',
    params: {
        applicationId: applicationId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

