import { TabBar } from "components/Tabbar/Tabbar";
import { useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { SelectJobList } from "./SelectJobList";
import { CartableType } from "types/enum";
import { Button } from "components/Button/Button";
import { Toast } from "components/ToastShow/ToastShow";
import { PostArchive } from "./api";


interface JobListPropsType {
  ArchiveList:any ;
  onHide: () => void;
  reload?: (ans: boolean) => void;
  selectedIdList?: (ans: any) => void;

}
export const JobList = ({
  ArchiveList,
  reload,
  onHide,
}: JobListPropsType) => {


  const [curentTab, setCurentTab] = useState<any>("Current")
  const [jobList, setJobList] = useState<any>({Current:[] , Referred:[] ,Finished:[] })
  




  const addToJobList = (list  , type) => {
    let newObj = JSON.parse(JSON.stringify(jobList))
    newObj[type] = list
    setJobList(newObj)

        
}
  
  const onSubmit =async (e) => {
    let addList:any = []
    ArchiveList.forEach((item , index )=> {
      addList.push(item.Id)
      
    });
  let joblistId = [...jobList.Current , ...jobList.Referred, ...jobList.Finished].map(item=>{
    return item.JobId
  })
    
    let data = {
      "JobIdList":joblistId,
      "OrganizationalArchiveDetailIdList": addList
    }
    try {
      await PostArchive(data  );
      onHide()
        
    } catch (error) {
        
    }



        
}
  const tabChangeHandle = (tab) => {
    setCurentTab(tab)
  }

  let viewContent = [
    {
      title: "Current", body: (<SelectJobList type={2} jobList={jobList["Current"]} addToJobList={(e)=>{addToJobList(e,"Current")}} curentTab={CartableType[curentTab]}/>),
    },
    {
      title: "Referred", body: (<SelectJobList type={3} jobList={jobList["Referred"]} addToJobList={(e)=>{addToJobList(e,"Referred")}} curentTab={CartableType[curentTab]}/>),
    },
    {
      title: "Finished", body: (<SelectJobList type={4} jobList={jobList["Finished"]} addToJobList={(e)=>{addToJobList(e,"Finished")}} curentTab={CartableType[curentTab]}/>),
    },
  ];
  return (
    <>
      <TabBar  tabChange={tabChangeHandle} content={viewContent} defaultTab="current" curentTab={curentTab} />
      <div className="col-lg-12 mt-3 btns_form">
        <Button value="Save" btnType="submit" onClick={onSubmit} />
        <Button
          btnType="cancel"
          value="Cancel"
          onClick={()=>Toast("modalclosemessage" , "warning" , "alert" , onHide )}
        />
      </div>
    </>
  );
};
