import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fileviewer } from "components/Fileviewer/Fileviewer";
import { useContext, useEffect, useState } from "react";

import { CardBoxAttachment } from "pages/Secretariats/Archive/ArchiveTree/components/AttachmentViewer/CardBoxAttachment/CardBoxAttachment";
import { getDownloadUrlAttachmentAsync, loadAttachmentViewer, loadAttachmentViewerBase64 } from "./api";
import { Loading } from "components/Loading/loading";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";

interface props {
    ownerId?: string
    listAttachment?: any
    endpoint?: any
  
}
export const AttachmentViewer = ({ ownerId ,listAttachment,endpoint=null}: props) => {


    const [load, setLoad] = useState<any>();
    const [fileName, setFileName] = useState< any>();
    const [index, setIndex] = useState<number | any>();
    const [showFile, setShowFile] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [showAloneAttach, setShowAloneAttach] = useState(false);
    const [file, setFile] = useState<any>();

    const layoutContext = useContext(LayoutContext);    


    async function getFileAsync(id , name) {
        try {   
               let res = await getDownloadUrlAttachmentAsync(id);

                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = res
                a.download = name;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
           } catch (error) {
              
           }
       }



    function handleSlider(obj , i) {
        setIndex(i);
        setFileName(obj.FileName);
        setFile(obj);
        loadAttachmentViewerAsync(obj.Id);
        if(layoutContext.sidebarIsOpen){
            layoutContext.toggleSidebar();

        }
        setShowFile(true)
    }


    async function loadAttachmentViewerAsync(e) {
        setLoadingFile(false)
        try {
            const res:any = await loadAttachmentViewerBase64(e ,endpoint);
            setLoad(res);
            if(listAttachment.length ==1)setShowAloneAttach(true)
            setLoadingFile(true)
            return res

        } catch (error) {
            setLoadingFile(true)
            setLoad(" ")
            return " "
        }

    }

    function next() {
        setShowFile(false)
        if(index+1 ==listAttachment?.length ){
            handleSlider(listAttachment[0] , 0)
        }else{
            handleSlider(listAttachment[index+1] , index+1)
        }
        setShowFile(true)
    }

    function prev() {
        setShowFile(false)
        if(index ==0 ){
            handleSlider(listAttachment[listAttachment?.length-1] , listAttachment?.length-1)
        }else{
            handleSlider(listAttachment[index-1] , index-1)
        }
        setShowFile(true)
    }

     useEffect(() => {
        if(listAttachment.length ==1){
            setFileName(listAttachment[0].FileName);
            setFile(listAttachment[0]);
            loadAttachmentViewerAsync(listAttachment[0]?.Id);
        }
    }, [ listAttachment]);

    return (
        <>  


        {showFile &&
        <>
        <div className="fullscreen-bg-modal" />
            <div className="" style={{position:"fixed" ,left:"0" , top:"2px" ,width: "100%",zIndex:"999999"}}>
                <div className="d-flex align-items-center mt-4 w-100 me-5 group-btn">
                    <FontAwesomeIcon  onClick={()=>{setShowFile(false);document.getElementById("backScreen")?.remove()}}  size="3x" color='white' icon={['fas', 'times']} style={{zIndex:"9999" , cursor: 'pointer' , position:"absolute" }}/>
                    <FontAwesomeIcon  onClick={()=>{getFileAsync(file.Id ,file.FileName )}}  size="2x" color='white' icon={['fas', 'download']} style={{zIndex:"9999" , cursor: 'pointer' , position:"absolute" ,marginRight:"50px" }}/>
                </div>
              <div style={{display: "flex" , height: "92vh",alignItems:'center', justifyContent:"center" ,zIndex:"999999"}} className='w-100'>
                <div onClick={()=>{next()}} style={{right:"0", height:"100vh", position: "absolute" , flexDirection: "column" , display:"flex" , justifyContent:"center" , margin:"30px"}}><a href="#"><span className="arrow right"></span></a></div>
                  <Fileviewer File={fileName } showDownLoad={false} src={load} showArchive={true} showFile={true} />
                <div onClick={()=>{prev()}} style={{left:"0", height:"100vh", position: "absolute", flexDirection: "column" , display:"flex" , justifyContent:"center" , margin:"30px"}}><a href="#"><span className="arrow left"></span></a></div>
              </div>          
            </div>
        </>
        }
        <div className={showAloneAttach ? "" :"card"}>
            <div className={showAloneAttach ? "" :`card-body `} style={{height:"115px"  }}>
                <div style={{marginTop:"-13px"}} className="row">
                        {listAttachment && listAttachment?.length > 1 ?
                            <>
                                <div className="AttachmentViewer d-flex flex-nowrap">
                                    {listAttachment.map((item , index) => (
                                        <CardBoxAttachment img={`data:image/jpg;base64,${item.Thumbnail}`}  handleClickSlider={()=>{handleSlider(item , index)} } id={item.Id}
                                           fileName={item.FileName} handleDownloadTrigger={(e)=>{e.preventDefault() ; getFileAsync(item.Id ,"" )} }/>
                                    ))}
                                </div>  
                            </>
                            :<> 
                            {showAloneAttach&&
                                <>
                                {loadingFile?
                                <div style={{display: "flex" , height: "10vh", justifyContent:"center" }} className='w-100'>
                                    <Fileviewer File={fileName } showDownLoad={false} src={load} showArchive={true} showFile={false} />
                                </div>  
                                :
                                    <Loading/>
                            }
                                </>
                                } 
                             </> 
                        }
                         
                        
                </div>
            </div>  
        </div>
        </>
    );
}




