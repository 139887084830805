export enum CartableType {
  Draft = 1,
  Current = 2,
  Referred = 3,
  Finished = 4,
  Followup = 5,
  Ongoing = 6,
}

export enum ActivityStatus {
  Deleted = 0,
  Active = 1,
  Inactive = 2,
}

export enum MeetingStatus {
  Notified = 1,
  PendingToAccept = 2,
  PendingToHold = 3,
  InProgress = 4,
  Finished = 5,
  Canceled = 6,
}

export enum MeetingStatus {
  Offline = 1,
  Online = 2,
  Mix = 3,
}

export enum JobStatus {
  UnderReview = 1,
  InProgress = 2,
  Finished = 3,
  Rejected = 4,
  Canceled = 5,
  ForceStop = 6,
  FinishedAndWaitingForEvaluatio = 7,
}
export enum ReferenceStatus {
  Draft = 1,
  Current = 2,
  Referred = 3,
  Finished = 4,
  ReferredReply = 5,
  ReferredUndo = 6,
}

export enum Priority {
  Normal = 1,
  Important = 2,
  Emergency = 3,
}
export enum AssignTypes {
  MANUAL,
  BALANCED,
  SELF_SERVICE,
  EVALUATE,
  REPORT_TO,
}
//endregion
//region CalendarEnums
//region BaseInformationEnums
export enum Sex {
  Male = 1,
  Female = 2,
}
export enum LegalType {
  Company = 1,
  Organization = 2,
  Office = 3,
}
export enum ContactType {
  Phone = 1,
  Mobile = 2,
  Email = 3,
  Address = 4,
  Fax = 5,
  Extension = 6,
  PostalCode = 7,
  Location = 8,
  ECEOrganizationCode = 9,
}

export enum OrganizationType {
  Employees = 1,
  Shareholders = 2,
  Customers = 3,
  ShoppingCenter = 4,
  Contractors = 5,
  Others = 6,
}
//endregion
//region CalendarEnums
export enum HolidayType {
  National = 1,
  Organizational = 2,
}
export enum CalendarType {
  Solar = 1,
  Lunar = 2,
  Gregorian = 3,
}
export enum CalendarEventType {
  PersonalEvent = 0,
  NationalEvent = 1,
  NationalHoliday = 2,
  NationalWeekend = 3,
  OrganizationalHoliday = 4,
  OrganizationalEvent = 5,
  OrganizationalWeekend = 6,
}
export enum ReminderStatus {
  InProgress = 1,
  Postponed = 2,
  Done = 3,
  Canceled = 4,
}
export enum PersonalCalendarEventStatus {
  NotSet = 1,
  Done = 2,
  Canceled = 3,
}
export enum TaskStatus {
  Active = 1,
  Canceled = 2,
  Finished = 3,
  Suspended = 4,
  Deleted = 5,
}
/*export enum CalendarEventStatus
{
    NotSeen = 1,
    Seen = 2,
    InProgress = 3,
    Done = 4
}*/

export enum LetterRelationType {
  Reply = 1,
  Following = 2,
  According = 3,
}
export enum LetterSecurity {
  Normal = 1,
  Confidential = 2,
  StrictlyConfidential = 3,
}
export enum LetterType {
  incoming = 1,
  outcoming = 2,
  internal = 3,
}

export enum LetterUrgency {
  Normal = 1,
  Urgent = 2,
  StrictlyUrgent = 3,
  Immediate = 4,
}
export enum TaxTypeItem {
  VAT = 0,
  OtherTaxes = 1,
}

export enum MessageType {
  "info" = 0,
  "success" = 1,
  "error" = 2,
  "warning" = 3,
  "confirm" = 4,
  "noDialog" = 5,
}

export enum InvitationStatus {
  Received = 1,
  Seen = 2,
  Accepted = 3,
  Rejected = 4,
}

export enum InputTypes {
  text = 0,
  date = 1,
  timeLength = 2,
  dropDown = 3,
  RadioButton = 4,
  checkBox = 5,
  fileUpdate = 6,
  ProgressBar = 7,
  price = 8,
  emptySpace = 9,
  label = 10,
  Message = 11,
  time = 12,
  PositionDictionary = 13,
  Step = 14,
}

export enum InvitedPersonType {
  Main = 1,
  Ordinary = 2,
  Guest = 3,
}
export enum PropertyType {
  Text = 1,
  Number = 2,
  List = 3,
  CheckBox = 4,
  Date = 5,
  Time = 6,
  Price = 7,
  Dictanory = 8,
}
export enum RoleOperationType {
  Add = 0,
  Edit = 1,
  View = 2,
  Delete = 3,

}


export const InputTextType = {
  // textType
  0: "text",
  1: "textarea",
  2: "email",
  3: "userName",
  // 4:"password",
  5: "systemKey",
  // numberType
  6: "number",
  7: "tel",
  8: "mobile",
  9: "nationalCode",
  10: "birthCertNo",
  11: "econNo",
  12: "nationalIdentifier",
  13: "PostalCode",
};

export const InputTextTypePlaceholder = {
  // textType
  email: "ABCD@gmail.com",
  // numberType
  number: "1234",
  tel: "1234",
  mobile: "091234",
  nationalCode: "1234",
  birthCertNo: "1234",
  econNo: "1234",
  nationalIdentifier: "1234",
  PostalCode: "1234",
};
//endregion SecretariatEnums
export const KeyCodesObject = {
  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  G: 71,
  H: 72,
  I: 73,
  J: 74,
  K: 75,
  L: 76,
  M: 77,
  N: 78,
  O: 79,
  P: 80,
  Q: 81,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  V: 86,
  W: 87,
  X: 88,
  Y: 89,
  Z: 90,
};
