import { ContactType } from "types/enum";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getCustomerPhoneListApi = (async (personId ,Current,type=1) => await http({
    url: Current  ? ENDPOINTS.BaseInformation.CurrentPersonContact.phones  :  type===ContactType.Phone?
    ENDPOINTS.BaseInformation.PersonContact.landLine
    :ENDPOINTS.BaseInformation.PersonContact.mobile,
    method: 'get',
    params: {
        personId:personId,
    }
}).then((res) => {

    return res.data.Data
}).catch((err) => {

    return Promise.reject( err);
})
)

export const postCustomerPhoneListApi = (data  ,Current) => http({
    url: Current  ? ENDPOINTS.BaseInformation.CurrentPersonContact.Load  :  ENDPOINTS.BaseInformation.PersonContact.brows,
    method: 'post',
    data:data
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});