

import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";

import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Toast } from "components/ToastShow/ToastShow";
import { getAllItemList, postItemList } from "./api";
import { Button } from "components/Button/Button";

interface Form {
    onHide: () => void;
    reload: ()=> void;
    warehouseId?:any;
    formRefs?:any;

}
export const ItemList = ({warehouseId, onHide ,reload}: Form) => {


    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [filterlist, setFilterlist] = useState<any>("");
    const [pages, setPages] = useState<number>(0);
    const [userChanges, setUserChanges] = useState({});
    const [selectList , setSelectList] = useState<any>()



    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("ItemList" ,e.currentTarget.value )
        getAllItemListAsync(1)
      };

      function handleGoPage(value): void {
        if (value) {
            if (value < 1 || value > pages) { }
            else {setPageNum(+value);getAllItemListAsync(+value)}
        } else {
        }
    }
    
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getAllItemListAsync(pageNum + 1);
          };
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getAllItemListAsync(pageNum - 1);
          };
    };

    const searchHandler = (str) => {
        setPageNum(1);
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }



    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false"); setUserChanges({}) });
        }else{
            setSelectedObj(obj)         
        };
    }



    async function getAllItemListAsync(PageNum =pageNum) {
        setLoading(false)
        let pageSizeChangedByUser = getPageSize("ItemList" )
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getAllItemList(PageNum,  pageSizeChangedByUser ,orderBy.length == 0 ?null:  orderBy.join(",")  ,search ,filterlist);
            setResponse(res.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) 
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }


    const selectListCallBack =(list)=>{
          
        setSelectList(list[1])
      }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


      const submit = async () => {
        setLoading(false)
        try {
            let data = {
                ItemGroupIds: selectList.map(item =>{return item.Id}) ,
                WarehouseId: warehouseId , 
            }
            await postItemList(data)
            onHide()
        } catch (error) {
            
        }
        reload()
        setLoading(true)
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                onHide()
            });
        }else{
            onHide()    
        };

    }

    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        getAllItemListAsync();
    }, [forceUpdate ,search , orderBy , filterlist , pageNum]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
            getAllItemListAsync();
        }
    }, [resetForm]);



    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("ItemList"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "help": alert("call api help"); break;


        }
    };
    /* #endregion */


    return (
        <>
            <div className="cartable-action">

                <Toolbar handleSearch={searchHandler} warehouse={false} view={false} remove={false}  id={selectedObj?.Id} emptyList={response?.Data?.length == 0} create={false} edit={false} search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                    <div className="">
                            <>
                                {loading ?
                                    response?.Data?.length > 0 ?
                                        <>
                                            <DataGrid
                                                 selectItem={(e) => {   }}
                                                 multiSelect={true}
                                                 selectList={selectListCallBack}
                                                parentName={"ItemList"}
                                                handlefiltering={(str)=>{setFilterlist(str)  }}
                                                filterStr={filterlist}
                                                orderFormat={setOrderBy}
                                                orderFormatList={orderBy}
                                                startIndex={1}
                                                theadData={Object.keys(response.Data[0])}
                                                tbodyData={response.Data}
                                                minCellWidth={80}
                                                selectedRowObjectCallback={selectedIdCallback}
                                                totalRecords={response.TotalRecords}
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                HandleNextPage={handleNextPage}
                                                HandlePrevPage={handlePrevPage}
                                                scrollHeight={"35vh"}  
                                                // handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                                handlechangePageSize={handleChangePageSize}
                                                first={() =>{getAllItemListAsync(pages); setPageNum(pages)}}
                                                end={() =>{getAllItemListAsync(1); setPageNum(1)}}
                                                handleGoPage={handleGoPage}
                                            />
                                        </>
                                        : <Message message={"NoDataForPreview"} type={messageType} />
                                        :<Loading/>
                                }
                                        {  mode !="view"?
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button onClick={submit} value="Save" btnType="submit" />
                                              <Button btnType="cancel" value="Cancel" onClick={()=>{onHide()}} />
                                              </div>
                                              :
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button btnType="primary" value="close" onClick={()=>{onHide()}} />
                                              </div>

                                          }   
                            </>
                    </div>
                </div>
            </div>
        </>

    );
}