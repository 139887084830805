import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getAllServiceRequestClassifications = (async (searchString, orderBy) => await http({
    url: ENDPOINTS.afterSalesApi.customerServiceRequestClassification.browse,
    method: 'get',
    params: {
        searchString: searchString,
        orderBy: orderBy
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
})
)

export const getServiceRequestClassificationsn = (async (id) => await http({
    url: ENDPOINTS.afterSalesApi.customerServiceRequestClassification.load,
    method: 'get',
    params: { Id: id }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)


export const postServiceRequestClassifications = (data) => http({
    url: ENDPOINTS.afterSalesApi.customerServiceRequestClassification.browse,
    method: 'post',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})



export const putServiceRequestClassifications = (data) => http({
    url: ENDPOINTS.afterSalesApi.customerServiceRequestClassification.browse,
    method: 'put',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

export const deleteServiceRequestClassification = (id) => http({
    url: ENDPOINTS.afterSalesApi.customerServiceRequestClassification.browse,
    method: 'delete',
    params: {
        Id: id
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})


