import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import DatePicker from "components/DatePicker/DatePicker";
import { Textbox } from "components/Textbox/Textbox";
import { Time } from "components/Time/Time";
import { AssignTypes } from "pages/OfficeAutomation/Cartable/types";
import React, { useEffect, useState } from "react";
import { trans, uuidv4 } from "utils/helpers";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { ENDPOINTS } from "utils/endpoints";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";


export const ReferenceForm: React.FC<any> = ({taskMode=false,PositionTitle , taskTitle, jobId, TAS_UID, Id ,PerformerPositionId,ReferrerPositionId,ParentReferenceId,
    handleSubmitedData, allFormData, setShowForm, showForm, isAllForms, handleSubmitedDataForAllForms ,assignType }) => {
    let data
    const [resetForm, setResetForm] = useState(false);
    const [fromDate, setFromDate] = useState<string>();
    const [actualTimeInputValue, setActualTimeInputValue] = useState<string>("00:00");
   const [formData, setFormData] = useState<any>();
    const currentFormData =  allFormData.find(obj => (obj.Id == Id && obj.TAS_UID == TAS_UID)) 



    const formSubmit = async (event) => {
        event.preventDefault()
        let array = actualTimeInputValue.split(":");


        if (!isAllForms) {
            data = {
                AssignType : AssignTypes[assignType],
                ParentReferenceId:ParentReferenceId,
                Id: Id?Id: uuidv4(),
                TAS_UID,
                JobId: jobId,
                PerformerPositionId,
                ReferrerPositionId,
                ReferenceSubjectId: event.target.referenceSubject.value,
                Position: event.target.position.value,
                Description: event.target.description.value,
                ReferenceSubject: event.target.referenceSubject.key,
                Priority: +event.target.priority.value,
                EstimateTime: +event.target.estimateTime[0].value,
                DeadLineDate: fromDate,
                HasFollowUp: event.target.hasFollowUp.checked,
                SecretReference: event.target.secretReference.checked,
                SendMessageToPerformer: event.target.sendMessageToPerformer.checked,
            }
            await setFormData(data)
        } else {
            data = {
                AssignType : AssignTypes[assignType],
                ParentReferenceId:ParentReferenceId,
                Id: undefined,
                TAS_UID,
                JobId: jobId,
                PerformerPositionId: undefined,
                ReferrerPositionId,
                ReferenceSubjectId: event.target.referenceSubject.value,
                Position: undefined,
                Description: event.target.description.value,
                ReferenceSubject: event.target.referenceSubject.key,
                Priority: +event.target.priority.value,
                EstimateTime: +event.target.estimateTime[0].value,
                DeadLineDate: fromDate,
                HasFollowUp: event.target.hasFollowUp.checked,
                SecretReference: event.target.secretReference.checked,
                SendMessageToPerformer: event.target.sendMessageToPerformer.checked,
            }

            await setFormData(data)
        }


        await setShowForm(false)
       
    }


    const isSubmitted = (formData !== undefined || null) && (isAllForms === false)

    const isAllformSubmitted = (formData !== undefined || null) && (isAllForms === true)


    useEffect(() => {
        if (!isAllForms) { handleSubmitedData(formData, TAS_UID) }
    }, [isSubmitted]);

    useEffect(() => {
        if (isAllForms) { handleSubmitedDataForAllForms(formData, TAS_UID) }
    }, [isAllformSubmitted]);


    return (
        <form onSubmit={formSubmit}>
            <div className={`${taskMode ? "list-card mt-3" :  " card" } `  }>
                <FormWithTitle showTitle={taskMode} textAnimation={'shaking_animation'} title={trans("receiver")+" " +trans("referenceForm") +" : " +PositionTitle}>
                <div className="card-body">
                    <div className="container">
                        <div className="row my-1">
                            <div className="col-12 my-1 my-lg-0 col-lg-3">
                                <DictionarySelectBox
                                    required={true}
                                    label="referenceSubject"
                                    name='referenceSubject'
                                    endPoint={ENDPOINTS.cartable.referenceSubject.dictionary}
                                    value={currentFormData ? currentFormData.ReferenceSubjectId : ''}
                                />

                            </div>
                            <div className="col-12 my-1 my-lg-0 col-lg-3">
                                <EnumerationTypeSelect
                                    required={true}
                                    label="priority"
                                    name='priority'
                                    enumTypeSelect="priority"
                                    value={currentFormData ? currentFormData.Priority : null}
                                />
                            </div>
                            <div className="col-4 my-1 my-lg-0 col-lg-3">
                                <DatePicker
                                    onOpenPickNewDate={false}
                                    lable='DeadLineDate'
                                    resetForm={resetForm}
                                    type='date'
                                    setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                                    val={currentFormData?.DeadLineDate  ? currentFormData.DeadLineDate :null}
                                />
                            </div>
                            
                            <div className="col-4 my-1 my-lg-0 col-lg-3">
                                <Time
                                    name="estimateTime"
                                    IdString="estimateTime"
                                    resetForm={resetForm}
                                    defaultValue={currentFormData?.EstimateTime}
                                    label="estimateTime"
             
                                />
                            </div>
                        </div>
                        <div className="row my-1">

                            <div className="col-4 my-1 my-lg-0 col-lg-2 pt-4">
                                <CheckableButton
                                    type='checkbox'
                                    labelText='HasFollowUp'
                                    defaultChecked={currentFormData ? currentFormData.HasFollowUp : false}
                                    inputName='hasFollowUp'
                                />
                            </div>
                            <div className="col-4 my-1 my-lg-0 col-lg-2 pt-4">
                                <CheckableButton
                                    type='checkbox'
                                    labelText='SecretReference'
                                    defaultChecked={currentFormData ? currentFormData.SecretReference : false}
                                    inputName='secretReference'
                                />
                            </div>
                            <div className="col-4 my-1 my-lg-0 col-lg-4 pt-4">
                                <CheckableButton
                                       defaultValue={false}
                                       labelText={"sendMessageToPerformer"}
                                       inputName={"sendMessageToPerformer"}
                                       type="checkbox"
                                       mode={"edit"}
                                    />    
                            </div>

                           
                        </div>

                        <div className="row mt-3 mb-1">
                            <div className="col-12">
                                <Textbox
                                    textboxType="textarea"
                                    textboxName="description"
                                    resetForm={resetForm}
                                    defaultValue={currentFormData?.Description.length >0? currentFormData.Description : ( taskTitle ?taskTitle  :"")}
                                    labelText="Description"
                                />
                            </div>
                        </div>
                        <div className="row my-1">
                            {!isAllForms && <div className="d-none col-12 my-1 my-lg-0 col-lg-6">
                                <Textbox
                                    required={true}
                                    textboxType="text"
                                    textboxName="position"
                                    resetForm={resetForm}
                                    defaultValue={currentFormData ? currentFormData.Position : ""}
                                    labelText="Position"
                                    mode={'view'}
                                />
                            </div>}
                        </div>

                        <div className="row mt-3 mb-4">
                        </div>
                        <div className="row mt-3 d-flex flex-row-reverse">
                            <div className="col-2 d-flex align-items-center justify-content-end">
                                <Button btnType="submit" value={ taskMode ?"Reference": 'submit'} />
                            </div>
                        </div>
                    </div>
                </div>
                </FormWithTitle>
            </div>
        </form>
    )
}