import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";






export const getCustomerPositionDictionaryApi =  (search , id, isCoustomer) =>  http({
    url:isCoustomer ?  ENDPOINTS.clientPortal.clientPortal.CustomerPositionId   : ENDPOINTS.clientPortal.clientPortal.CustomerPositionList,
    method: 'get',
    params: {
        positionId:  id,
        searchStr:search

    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})

export const getUserPositions = (personId) => http({
    url: ENDPOINTS.BaseInformation.position.getPosition,
    method: 'get',
    params: {
        personId: personId
    }
}).then((res) => {
    //  
    return res?.data
}).catch((err) => {
    
    return Promise.reject(err);
});
