import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss"
import { useEffect, useState } from "react";

interface ItemGroupLebalPropes{
    id?:any,
    title?:string,
    onClick?:(e:any)=>void,
    IsActive?:boolean,
    shape: "circle" | "Foursquare" | "FoursquareRadio",
}

export const ItemGroupLebal = ({title,id,onClick,IsActive,shape}:ItemGroupLebalPropes) => {
    const [checkColor,setCheckColor] = useState<boolean>(false)
    useEffect(()=>{
        const hex_color = title
        if(hex_color){
            let r =parseInt(hex_color.substring(1, 3), 16)
            let g =parseInt(hex_color.substring(3, 5), 16)
            let b =parseInt(hex_color.substring(5, 7), 16)
            if(r > 128 && g > 128 && b > 128){
                setCheckColor(false)
            }else{
                setCheckColor(true)
            }
        }
    },[title])

    return (
        <>
            <div onClick={onClick} id={id} className= {`rounded-2
            ${shape == 'FoursquareRadio' && `${IsActive ? 'itemSlider__activeGroupLebal' :''} d-flex justify-content-center align-items-center itemSlider__itemGroupMenu col-4 col-sm-3 col-md-1`}
            ${shape == 'circle' && `${IsActive ? 'itemSlider__activeGroupLebal' :''} position-relative itemSlider__color`}
            ${shape == 'Foursquare' && `${IsActive ? 'itemSlider__activeGroupLebal' :''} itemSlider__size`}
            costomize-item-slider` } >
                {title && 
                    <div id={id}  className={`font-size-md showDots d-flex justify-content-center align-items-center ${shape == 'circle' && 'position-absolute top-50 start-50 translate-middle'}`}  style={{width:shape == 'circle'?'85%':'100%',height:shape == 'circle'?'85%':'100%',backgroundColor:title ?title:''}}>
                        {shape != 'circle' ?
                            title
                        :
                            <FontAwesomeIcon className={`${IsActive ? 'd-block' : 'd-none'} position-absolute top-50 start-50 translate-middle font-size-xl fw-bold`}  icon={["fas", "check"]} color={checkColor ? '#fff':'#000'}/>
                        }
                    </div>
                }
            </div>
        </> 
     );
}


