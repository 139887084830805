import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ICON_TYPE } from "utils/constants";
import { getCartableNotificationCount } from "./api";
import { trans } from "utils/helpers";
import { Toast } from "components/ToastShow/ToastShow";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { OrganizationType } from "types/enum";

export const BellNotification = () => {
  const layoutContext = useContext(LayoutContext);

  const [count, setCount] = useState<any>(0);
  const [flag, setFlag] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleNavigate = (rout) => {
    
    if(sessionStorage.getItem("changingforms")+""=="true"){
      Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{navigate(rout);sessionStorage.setItem("changingforms" , "false");localStorage.removeItem("formsInChang")  });
    }else{
      navigate(rout)
    }
  }
  async function getNotificationCount() {
    try {
        const data = await getCartableNotificationCount();
        layoutContext.setNotificount(data.Data)
    } catch (error) {
      setCount(null)
    }
  }

  const enterNavigat = (event)=>{
    if(event.key == "Enter"){
      handleNavigate(layoutContext.currentApplication?.Name=="ClientPortal"?"/my-own-requests" : "/cartable/current")
    }
  }
  
  useEffect(()=>{
    if(flag == true){
      document.addEventListener("keydown",enterNavigat)
    }
    return () =>{document.removeEventListener("keydown",enterNavigat)}
  },[flag])

  useEffect(() => {
    if(layoutContext?.currentUserPosition?.OrganizationType!=OrganizationType.Customers && layoutContext?.currentUserPosition?.OrganizationType!=undefined){
      getNotificationCount();
    }
  }, [layoutContext.currentUserPosition]);



  return (
    <>
    <OverlayTrigger
        placement="bottom"
        delay={{"show" : 300 , "hide":0}}
        overlay={
            <Tooltip className="tooltip" id={`button-tooltip-${"bell"}`}>
                {trans('cartable')}
            </Tooltip>
        }
        >
         <Link className="position-relative" onFocus={()=>setFlag(true)} onBlur={()=>setFlag(false)} onClick={(e)=>{e.preventDefault();handleNavigate(layoutContext.currentApplication?.Name=="ClientPortal"?"/my-own-requests" : "/cartable/current")}} to={layoutContext.currentApplication?.Name=="ClientPortal"?"/my-own-requests" : "/cartable/current"}>
        <FontAwesomeIcon icon={[ICON_TYPE, "bell"]} />
        {(layoutContext.notificount >0) ? <span className="badgeCartable">{layoutContext.notificount > 0 ? (layoutContext.notificount >99 ?"99+":layoutContext.notificount) : " "}</span>   :<></> }
      
      </Link>
      </OverlayTrigger>
   
     
    </>
  );
};
