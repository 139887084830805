import { DataGrid } from "components/DataGrid/DataGrid";
import { DynamicComponentHOC } from "components/DynamicComponentHOC/DynamicComponentHOC";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { TreeView } from "components/TreeView/TreeView";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { ReferenceHistory } from "pages/SystemManagement/Jobs/components/RefrenceHistory/ReferenceHistory";
import { useContext, useEffect, useState } from "react";
import { GUID_EMPTY } from "utils/constants";
import { DYNAMICFORMS } from "utils/dynamicForms";
import { ENDPOINTS } from "utils/endpoints";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { deleteJobFileArchive, getArchivedJob, getDataWithSerch, getJobFileArchive, getOrganizationArchive, getViewForm } from "./api/api";
import { AttachmentViewer } from "./components/AttachmentViewer/AttachmentViewer";
import { DocumentArchiveForm } from "./components/DocumentsForm/DocumentArchiveForm";
import { JobList } from "./components/JobList/JobList";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";


  

export const ArchiveTree = () => {

  const [data, setData] = useState<any>()
  const [forceUpdate, setForceUpdate] = useState<boolean>(false)
  const [selectedObj, setSelectedObj] = useState<any>()
  const [idCallback, setIdCallback] = useState<any>(null)
  const [mode, setmode] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false);
  const [gridLoading, setGridLoading] = useState<boolean>(false);
  const [organizationArchiveForm, setOrganizationArchiveForm] = useState<boolean>(false);
  const [archiveFiles, setArchiveFiles] = useState<any>();
  const [selectedList, setSelectedList] = useState<any>([])
  const [showArchiveForm, setShowArchiveForm] = useState<any>(0);
  const [resetCheckBox, setResetCheckBox] = useState<any>(false);
  const [jobSelected, setJobSelected] = useState<any>(null);
  const [showHistory, setShowHistory] = useState(false);  
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [pages, setPages] = useState<number>(0);
  let [showDynamicFormView, setShowDynamicFormView] = useState<boolean>   (false);
  const [ShowDynamicFormViewLoading, setShowDynamicFormViewLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [archivedList, setArchivedList] = useState<any>()
  const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});

  let [dynamicForm, setDynamicForm] = useState< any>("GeneralRequestForm");

  const layoutContext = useContext(LayoutContext);    


  let customFuction: any = [
    [(e) => { setJobSelected(e); setShowHistory(true) }, "clipboard", "History"],
    [(e) => { handleEditRow(e) }, "edit", "edit"],
    [(e) => { handleViewRow(e) }, "eye", "View"],
]


const processViewCallback = async (id: string) => {
  try {
  getFormViewAsync(id);
  if(jobSelected){
      const archive = await getArchivedJob(jobSelected?.JobId)
      setArchivedList(archive.Data)          
  }
    } catch (error) {
      setArchivedList([])  
    }

};



const handlechangePageSize = (e: any) => {
  setPageNum(1);
  setPageSize(e.currentTarget.value);
  getPageSize("ArchiveTree", e.currentTarget.value)
  if(search){
    getDataWithSerchAsync(1)

  }else{
   getJobFileArchiveAsync(selectedObj,1);
  }
};
const handleGoPage = (value) => {
  if (value) {
    if (value < 1 || value > pages) { }
    else{ setPageNum(+value);
      if(search){
        getDataWithSerchAsync(+value)
      }else{
        getJobFileArchiveAsync(selectedObj,+value);}
      }
  } else {
  }
};
const HandleNextPage = () => {
  if(pageNum < pages){
      setPageNum(pageNum + 1);
      if(search){
        getDataWithSerchAsync(pageNum + 1)
      }else{
        getJobFileArchiveAsync(selectedObj,pageNum + 1);
      }

  }
};
const HandlePrevPage = () => {
  if (pageNum > 1){
      setPageNum((pageNum) => { return +pageNum - 1 });
      if(search){
        getDataWithSerchAsync(pageNum - 1)
      }else{
        getJobFileArchiveAsync(selectedObj,pageNum - 1);
      }
  }
};

async function getFormViewAsync(id: string) {
  setShowDynamicFormView(true);
  setShowDynamicFormViewLoading(false)
  try {
      const res = await getViewForm(id, layoutContext.currentUserPosition.PositionId);
      if (res.Data)
          setDynamicForm(res.Data);
      else
          setDynamicForm(undefined);
  } catch (error) {
      setDynamicForm(undefined);
      setShowDynamicFormView(false)
  }
  setShowDynamicFormViewLoading(true)

}

const showDynamicFormViewCallback = (ans: boolean) => {
  setShowDynamicFormView(ans);
}

  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "reference":alert("call api reference with Id :");break;
      case "print":alert("print ");break;
      case "remove":break;
      case "excel":downloadCSV(archiveFiles?.Data ,5 ,trans("ArchiveTree"));break;
      case "refresh":setForceUpdate(!forceUpdate);break;
      case "view": if(selectedObj){setmode("view");setOrganizationArchiveForm(!organizationArchiveForm)}else Toast(trans("Msg_SelectRow"), "warning"); break;
      case "create": if(selectedObj){setmode("create");setOrganizationArchiveForm(!organizationArchiveForm);}else Toast(trans("Msg_SelectRow"), "warning"); break;
      case "edit": if (selectedObj ){setmode("edit");setOrganizationArchiveForm(!organizationArchiveForm);}else Toast(trans("Msg_SelectRow"), "warning");  break;
    }
  };

  
  const searchHandler = (str) => {
    setPageNum(1)
    if(str =="" ){
        setSearch(null)
    }else{
        setSearch(str)
    }
  }

  async function DeleteRow(id){
    try {
        await  deleteJobFileArchive(id)
        if(search){
          getDataWithSerchAsync()
      
        }else{
  
          getJobFileArchiveAsync(selectedObj);
        }
    } catch (err: any) {
     
    } 
  }
 
  const handleDeleteRow = (id: string) => {
    Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{DeleteRow(id)}) 
  }
  const handleEditRow = (obj) => {
    if(obj.Editable){
      setmode("edit")
      setShowArchiveForm(2)
      setJobSelected(obj)
    }else{
      Toast(trans("Msg_ArchiveDocumentsCanBeEditedOnly"), "error" ) 
    }

  }
  const handleViewRow = (obj) => {
    processViewCallback(obj.PRJ_UID)

  }

  const setselecetrow = (e) => {
    getJobFileArchiveAsync(e)
    setSelectedObj(e)
  }


  const selectedArchiveCallback = (id: string) => {
    setmode("create")
    if(!selectedObj ||selectedList.length == 0 ){
      Toast(trans("Msg_SelectRow"), "warning")
    }else{
      setShowArchiveForm(+id+1)
    }
  };

  async function getArchiveTreeAsync() {
    setLoading(false)
    try {

      const respons = await getOrganizationArchive(null)
      setData(respons.Data)
      getJobFileArchiveAsync(respons.Data[0])
      setCatchMessage({type:"info",msg:"NoDataForPreview"})
    } catch (error:any) {
      setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
    }
    setLoading(true)
  }

  async function getJobFileArchiveAsync(e ,pagNum = pageNum ) {
    setGridLoading(false)
    try {
      if(e.RootId !=null || e.ParentId !=null){
        let pageSizeChangedByUser = getPageSize("ArchiveTree")
        setPageSize(pageSizeChangedByUser)
        const respons = await getJobFileArchive(e.Id ,pagNum,pageSizeChangedByUser)
        setArchiveFiles(respons.Data)
        if(respons.Data.Data[0].JobId !=GUID_EMPTY){
          setJobSelected(respons.Data.Data[0])
        }
        setPages(respons.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(respons.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
      }
      
    } catch (error) {
      
    }
    setGridLoading(true)
  }
  async function getDataWithSerchAsync( pagNum = pageNum ) {
    setGridLoading(false)
    try {
        let pageSizeChangedByUser = getPageSize("ArchiveTree")
        setPageSize(pageSizeChangedByUser)
        const respons = await getDataWithSerch(selectedList.map(item=>(item.Id)) ,pagNum,pageSizeChangedByUser ,search)
        setArchiveFiles(respons.Data)
        setPages(respons.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(respons.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
    } catch (error) {
      
    }
    setGridLoading(true)
  }

  async function reloadHandler() {
   let list =  selectedList.length > 0 ?selectedList :[ selectedObj]
   let flag = false
   list.forEach(item => {
     if(item.Id == selectedObj.Id){
       flag = true
      }
   });
   if(flag){
    try {
      let pageSizeChangedByUser = getPageSize("ArchiveTree")
      setPageSize(pageSizeChangedByUser)
      const respons = await getJobFileArchive(selectedObj.Id,pageNum,pageSizeChangedByUser)
      setArchiveFiles(respons.Data)
      setPages(respons.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(respons.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
    
    } catch (error) {
      
    }
  }
   setResetCheckBox(!resetCheckBox)
  }


  useEffect(() => {
    setData(null)
    setArchiveFiles(null)
    if(search){
      getDataWithSerchAsync()
 
    }else{
     getArchiveTreeAsync()
    }
  }, [forceUpdate]);


  useEffect(() => {
    if(idCallback){
      setIdCallback(null)
    }
  }, [idCallback]);
  useEffect(() => {
   if(search){
     getDataWithSerchAsync()

   }
  }, [search]);



    return (
     <>
        <Toolbar 
          emptyList={data?.length == 0} 
          handleClickToolbar={clickToolbarCallback} 
          handleSelectedArchive={search ? null : selectedArchiveCallback}
          warehouse={false}
          id={selectedObj} 
          view={false}
          create={false} 
          search={true} 
          handleSearch={searchHandler}
          
        />
      <div>


          
          {search ?
           <>
                      {gridLoading ? 
                        <>
                         {archiveFiles &&
                            <DataGrid 
                              parentName={"ArchiveTree"}
                              customFuction={customFuction}
                              startIndex={5}
                              totalRecords={archiveFiles?.TotalRecords}
                              theadData={Object.keys(archiveFiles?.Data[0])}
                              tbodyData={archiveFiles?.Data}
                              scrollHeight={"46vh"}
                              handleDelete={handleDeleteRow}
                              minCellWidth={90}
                              selectedRowObjectCallback={(obj)=>{setJobSelected(obj)}}
                              pageNum={pageNum}
                              pageSize={pageSize}
                              HandleNextPage={HandleNextPage}
                              HandlePrevPage={HandlePrevPage}
                              handlechangePageSize={handlechangePageSize}
                              first={() => { getDataWithSerchAsync(pages); setPageNum(pages) }}
                              end={() =>{getDataWithSerchAsync(1);setPageNum(1) }}
                              handleGoPage={handleGoPage}
                             />
                            }
                         </>
                        :
                        <Loading />
                        }
          </>
          :
          <>
          </>
          }
        <div style={{display:search?"none":"flex" , flexDirection:"row" , maxHeight:"50vh" , height:"50vh" }}  className="">

            {
              loading ? (
                data ?
                <div   style={{maxWidth:archiveFiles ? "30% ":"unset"  }} >
                  <TreeView
                    maxWidth={true}
                    scrollHeight={"51vh"}
                    resetCheckBox={resetCheckBox}
                    IsArchive={false}
                    multiselect={true}
                    selectedRowObjectCallback={setselecetrow}
                    selectedRowObject={idCallback}
                    getChildrenUrl={ENDPOINTS.archive.organizationalArchive.archives}
                    theadData={["Id" , "ParentId", "DetailList" , "ArchiveTree" ]}
                    startIndex={2}
                    Titleparams={"Title"}
                    Childrenparams={'DetailList'}
                    defultShow={false}
                    Data={data} 
                    parentName={"organizationalArchive"}
                    selectedRowsCallback={setSelectedList}
                  />
                </div>: <Message message={catchMessage.msg} type={catchMessage.type} />)
                : <Loading />
            }
            {gridLoading ? 
            <>
             {archiveFiles &&
               <div style={{overflowX: "auto" , backgroundColor:"white" , height:"54vh"}}>
                <DataGrid 
                  parentName={"jobArchive"}
                  customFuction={customFuction}
                  startIndex={5}
                  totalRecords={archiveFiles?.TotalRecords}
                  theadData={Object.keys(archiveFiles?.Data[0])}
                  tbodyData={archiveFiles?.Data}
                  scrollHeight={"46vh"}
                  handleDelete={handleDeleteRow}
                  minCellWidth={90}
                  selectedRowObjectCallback={(obj)=>{ setJobSelected(obj)}}
                  pageNum={pageNum}
                  pageSize={pageSize}
                  HandleNextPage={HandleNextPage}
                  HandlePrevPage={HandlePrevPage}
                  handlechangePageSize={handlechangePageSize}
                  first={() => { getJobFileArchiveAsync(selectedObj,pages); setPageNum(pages) }}
                  end={() =>{getJobFileArchiveAsync(selectedObj,1); setPageNum(1) }}
                  handleGoPage={handleGoPage}
                 />
                 </div>
                }
             </>
            :
            <Loading />
            }
          
          
          </div>
          

        
          {(jobSelected?.JobId&&archiveFiles?.TotalRecords>0) && 
          <AttachmentViewer ownerId={jobSelected?.JobId} />
          }
          {showDynamicFormView &&
                  <ModalForm mode={mode} title={ShowDynamicFormViewLoading ?(dynamicForm.Name) :""} onHide={() => { setShowDynamicFormView(false); }} show={showDynamicFormView}>                       
                      {ShowDynamicFormViewLoading ? 
                          <>
                      
                       <DynamicComponentHOC  onHide={() => { setShowDynamicFormView(false) }} 
                        componentName={DYNAMICFORMS[dynamicForm.Name] ?null:dynamicForm.Name} component={DYNAMICFORMS[dynamicForm.Name] ?DYNAMICFORMS[dynamicForm.Name].component:null}
                           reload={(e)=>{setForceUpdate(!forceUpdate)}} Name={dynamicForm.Name} modeParams={"view"} componentParams={dynamicForm.Parameters} processId={jobSelected.Prg}
                           Id={jobSelected.SourceId}handleCancelForm={() => showDynamicFormViewCallback(false)} />
                                                    {(archivedList&&archivedList.length >0 )&& 
                                 <div className="mt-2">
                                  <FormWithTitle title="ArchivedIn">
                                    <div className="card-body ">
                                    <div style={{display:"flex" , flexDirection:"column" , }} className="">
                                     {archivedList.map(item=>(
                                       <div>
                                         {item}
                                       </div>
                                     ))}
                                    </div>
                                     </div >
                                     </FormWithTitle>
                                 </div>
                                 
                                 }
                           </>
                        : <Loading/>
                      }
                  </ModalForm>
          }

</div>

{showHistory &&
            <ModalForm mode={mode} title={("ReferenceHistory")} onHide={() => {setJobSelected(null); setShowHistory(false) }} show={showHistory}>
                <ReferenceHistory  jobId={jobSelected?.JobId} />
            </ModalForm>
        }

        {
          showArchiveForm ==1 &&
            <ModalForm mode={mode} title="JobList" onHide={() => setShowArchiveForm(0)} show={showArchiveForm ==1}>
                <JobList
                  ArchiveList ={selectedList.length > 0 ?selectedList :[ selectedObj] }
                  onHide={()=>{reloadHandler(); setShowArchiveForm(0)}} 
                />
            </ModalForm>
        }
        {
          showArchiveForm ==2 &&
            <ModalForm mode={mode} title="Documents" onHide={() => setShowArchiveForm(0)} show={showArchiveForm ==2}>
                <DocumentArchiveForm
                  Id={jobSelected?.SourceId}
                  mode={mode}
                  ArchiveList ={selectedList.length > 0 ?selectedList :[ selectedObj] }
                  onHide={()=>{reloadHandler(); setShowArchiveForm(0)}} 
                  handleCancelForm={()=>{reloadHandler(); setShowArchiveForm(0)}} 

                />
            </ModalForm>
        }
        
      </>
    )
}




