import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getAllEvents = (async (pageNum,  pageSize  ,searchString ,orderBy,filterlist) => await http({
    url: ENDPOINTS.calendar.event.browse,
    method: 'get',
    params:{

        PageNumber: pageNum,
        PageSize: pageSize,
        OrderByString:orderBy,
        SearchString : searchString ,
        FilterString:filterlist,
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const deleteCalendarEvent = (id) => http({
    url: ENDPOINTS.calendar.event.browse,
    method: 'delete',
    params:{
        id:id
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

export const getEvent = (async (id) => await http({
    url: ENDPOINTS.calendar.event.load,
    method: 'get',
    params: {Id : id},
    headers: {"Accept-Language": "en"},
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})    

)


export const sendEventsApi = (data , method) => http({
    url: ENDPOINTS.calendar.event.browse,
    method: method,
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


