import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";




export const getPersonalPlanningList = (pageNum , pageSize   , orderBy ,searchString ,filterlist ,personalTaskStatusFilter ) => http({
    url: ENDPOINTS.personalPlanningApi.PersonalTask.browse,
    method: 'get',
    params: {
        PageNumber: pageNum,
        pageSize: pageSize,
        OrderByString:orderBy,
        FilterString:filterlist,
        SearchString : searchString ,
        personalTaskStatus:personalTaskStatusFilter ==0 ?null :personalTaskStatusFilter
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const getPersonalPlanning = (id: any) => http({
    url: ENDPOINTS.personalPlanningApi.PersonalTask.load,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        Id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const postPersonalPlanning = (data: any) => http({
    url: ENDPOINTS.personalPlanningApi.PersonalTask.browse,
    method: 'post',
    data: data,


}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const changeTaskStatus = (id ,personalTaskStatus) => http({
    url: ENDPOINTS.personalPlanningApi.PersonalTask.status,
    method: 'put',
    params: {
        id:id,
        personalTaskStatus:personalTaskStatus
    },


}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const putPersonalPlanning = (data: any) => http({
    url: ENDPOINTS.personalPlanningApi.PersonalTask.browse,
    method: 'put',
    data: data

}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const deletePersonalPlaning = (id: any) => http({
    url: ENDPOINTS.personalPlanningApi.PersonalTask.browse,
    method: 'delete',
    params:{
    Id:id
   }

}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
