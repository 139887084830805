import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getItemsApi = (serialId ,customerPositionId=null) => http({
    url: ENDPOINTS.clientPortal.clientPortal.items,
    method: 'get',
    params: {
        serial: serialId ,
        customerPositionId:customerPositionId
    }
}).then((response) => (response.data))
    .catch(err => {

    })

