import { Button } from "components/Button/Button"
import Datepicker from "components/DatePicker/DatePicker"
import { Textbox } from "components/Textbox/Textbox"
import { useEffect, useRef, useState } from "react"
import { trans } from "utils/helpers"

import { CheckableButton } from "components/CheckableButton/CheckableButton"
import { Loading } from "components/Loading/loading"
import { ModalForm } from "components/ModalForm/ModalForm"
import { Toast } from "components/ToastShow/ToastShow"
import { PriceListAccess } from "./PriceListAccess"

import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect"
import { clonePriceList, getPriceListConstraint, getPriceListId, sendPriceList } from "../api"
import { CustomerDictionary } from "../../SalesInvoice/components/CustomerDictionary/CustomerDictionary"
import { CurrencyDictionary } from "./CurrencyDictionary/CurrencyDictionary"
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect"

interface props {
    mode: any
    showForm: (e:boolean) => void
    selectedObj: any
}

export const PriceListForm = ({  mode, showForm, selectedObj }: props) => {

    const [fromDate, setFromDate] = useState<string | null>(null);
    const [untilDate, setUntilDate] = useState<string | null>(null);
    const [resetForm, setResetForm] = useState(false);
    const [response, setResponse] = useState<any>();
    const [showAccess, setShowAccess] = useState<any>(false);
    const [PriceListData, setPriceListData] = useState<any>(false);
    const [converted, setConverted] = useState<any>(false);
    const [loading , setLoading] = useState(mode=="create");
    const [userChanges, setUserChanges] = useState({});
    const [constraint, setConstraint] = useState<any>({});
    const [flag,setFlag] = useState(false);


    const myform = useRef<any>(null);


    async function sendDataAsync(data, method) {
        try {
            if(mode=="copy"){
                delete data.PriceList.Id
                delete data.PriceList.ActivityStatus	
                const res = await clonePriceList(data, selectedObj.Id);
            }else{
                const res = await sendPriceList(data, method);
            }
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
            showForm(true)
        } catch (err: any) {

        }
    }

    
    async function getDataAsync(id) {
        setLoading(false)
        try {
            const resConstraint = await getPriceListConstraint(id);
            setConstraint(resConstraint.Data)
            const res = await getPriceListId(id);

            if(mode=="copy"){
                setResponse({...res.Data ,Title:trans("copy")+" "+ res.Data.Title , TitleEN:trans("copy")+" "+ res.Data.TitleEN })
            }else{
                setResponse(res.Data)
            }
        } catch (err: any) {

        }
        setLoading(true)

    }

    
    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                showForm(false)
            });
        }else{
            showForm(false)     
        };


    }

    function accessDatahandler(data) {
        let formdata: any = {

            PriceList : PriceListData,
            PriceListAccesses : data
        }
        if (mode == "create") {


            sendDataAsync(formdata, "post")
        } else {
            if(mode=="copy"){
                sendDataAsync(formdata, "put")

            }else{

                sendDataAsync(PriceListData, "put")
            }
        }
    }

    const validationDates = () =>{
        if(fromDate && untilDate && fromDate?.toString().slice(0,10) > untilDate?.toString().slice(0,10)){
            setFlag(true)
        }else{
            setFlag(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if(flag){
            Toast(trans("InvalidEndDate"),"error");
        }else{
        setConverted(true)

        if (mode == "create") {
            const data = {
                TitleEN: event.target.TitleEN.value,
                Title: event.target.Title.value,
                Description:  event.target.Description.value,
                CurrencyId:  event.target.CurrencyId.value,
                StartDate: fromDate,
                EndDate: untilDate,
                IsDefault: event.target.IsDefault.checked,
                SaveWarehouseId: event.target.SaveWarehouseId.checked,
                IsPublic: event.target.IsPublic.checked,
                HasDetailRange: event.target.HasDetailRange.checked,
            }
            if (event.target.IsPublic.checked) {
                 

                 sendDataAsync({
                    PriceList : data,
                    PriceListAccesses : []
                }, "post")
              
            } else {
                setPriceListData(data)
                setShowAccess(true)
            }

            
        } else {

            const data = {
                Id: selectedObj.Id,
                Title: event.target.Title.value,
                TitleEN: event.target.TitleEN.value,
                Description:  event.target.Description.value,
                CurrencyId:  event.target.CurrencyId.value,
                StartDate: fromDate,
                EndDate: untilDate,
                IsDefault: event.target.IsDefault.checked,
                SaveWarehouseId: event.target.SaveWarehouseId.checked,
                IsPublic: event.target.IsPublic.checked,
                ActivityStatus: +event.target.ActivityStatus.value,
                HasDetailRange: event.target.HasDetailRange.checked,
            }
         
                if (event.target.IsPublic.checked) {
                    if(mode=="copy"){

                        sendDataAsync({
                            PriceList : data,
                            PriceListAccesses : []
                        }, "put")
                    }else{
                        sendDataAsync(data, "put")

                    }
                } else {
                    setPriceListData(data)
                    setShowAccess(true)  
                }
        }
    }
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };



    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);

    useEffect(() => {
        if (selectedObj && mode != "create") {
            getDataAsync(selectedObj.Id)
        }
    }, [selectedObj]);



    useEffect(()=>{
        validationDates()
      },[untilDate,fromDate])



    

    return (
        <>
        <div className="card">
            {showAccess &&
                <ModalForm mode={mode} modalProfileKey={"Users"} title={trans("Users")+" : " +PriceListData?.Title } onHide={() => setShowAccess(false)} show={showAccess}>
                    <PriceListAccess
                        archive={selectedObj}
                        showForm={setShowAccess}
                        accessDatahandler={accessDatahandler}
                        accessData={response?.PriceListAccesses}
                        mode={mode}
                    />
                </ModalForm>
            }
            {loading ?
            <form className={"card-body"} ref={myform} onSubmit={handleSubmit}>
                <div className="row">
                    <div className="row mb-1">
                        <div className="col-12 col-md-6 col-lg-3">
                            <Textbox
                                required={true}
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="text"
                                labelText="Title"
                                textboxName="Title"
                                resetForm={resetForm}
                                defaultValue={response?.Title}
                                mode={mode}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="text"
                                labelText="TitleEN"
                                textboxName="TitleEN"
                                resetForm={resetForm}
                                defaultValue={response?.TitleEN}
                                mode={mode}
                            />
                        </div>
                        <div className="col-lg-2">
                          <CurrencyDictionary
                                name="CurrencyId"
                                label="Currency"
                                disabled={constraint?.HasRelation}
                                resetForm={resetForm}
                                value={response?.CurrencyId}
                                mode={mode == 'view' ? "view" : mode}
                                required={true} 
                            />
                           </div>

                        <div className="col-12 col-md-6 col-lg-2">
                            <Datepicker
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                mode={mode}
                                resetForm={resetForm}
                                lable="FromDate"
                                maxDate={constraint?.FirstUsedDate}
                                setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                                val={response?.StartDate ? response?.StartDate : null}
                                errorFlag={flag}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-2">
                            <Datepicker
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                lable="untildate"
                                setIsoTime={(isoTime: string) => { setUntilDate(isoTime) }}
                                val={response?.EndDate ? response?.EndDate : null}
                                mode={mode}
                                minDate={constraint?.LastUsedDate}
                                resetForm={resetForm}
                                errorFlag={flag}
                            />
                        </div>
                        {mode !="create" &&
                             <div className="col-md-4">
                                <EnumerationTypeSelect
                                 Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                                 name="ActivityStatus"
                                 label="ActivityStatus"
                                 enumTypeSelect="ActivityStatus"
                                 value={response?.ActivityStatus}
                                 mode={mode}
                                />
                             </div>
                            }   
                    </div>
                    <div className="row">
                    <div className="col-12 ">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="textarea"
                                labelText="Description"
                                textboxName="Description"
                                resetForm={resetForm}
                                defaultValue={response?.Description}
                                mode={mode}
                            />
                        </div>
                    </div>
                    <div className="row-reverse ">
                    <div className="col-3 col-md-4 ">
                            <CheckableButton
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                defaultChecked={response ? response?.IsManual : false}
                                defaultValue={response ? response?.IsManual : false}
                                labelText={trans("IsManual") }
                                inputName={"IsManual"}
                                readOnly={constraint?.HasRelation ||constraint?.HasDetail  }
                                type="checkbox"
                                mode={mode}
                            />
                        </div>
                    <div className="col-3 col-md-4 ">
                            <CheckableButton
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                defaultChecked={response ? response?.IsPublic : true}
                                defaultValue={response ? response?.IsPublic : true}
                                labelText={trans("role")+" "+ trans("IsPublic") }
                                inputName={"IsPublic"}
                                type="checkbox"
                                mode={mode}
                            />
                        </div>

                        <div className="col-3 col-md-4 ">
                            <CheckableButton
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                defaultChecked={response?.IsDefault}
                                defaultValue={response?.IsDefault}
                                labelText={"IsDefault"}
                                inputName={"IsDefault"}
                                type="checkbox"
                                mode={mode}
                            />
                        </div>
                        

                        <div className="col-3 col-md-4 ">
                            <CheckableButton
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                defaultChecked={response?.SaveWarehouseId}
                                defaultValue={response?.SaveWarehouseId}
                                labelText={"saveWarehouseInPriceList"}
                                inputName={"SaveWarehouseId"}
                                type="checkbox"
                                mode={mode=="create"?"create" : "view"}
                            />
                        </div>
                        <div className="col-3 col-md-4 ">
                            <CheckableButton
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                defaultChecked={response ? response?.HasDetailRange : false}
                                defaultValue={response ? response?.HasDetailRange : false}
                                labelText={trans("HasDetailRange") }
                                inputName={"HasDetailRange"}
                                type="checkbox"
                                mode={mode}
                            />
                        </div>
                        
                    </div>
                    {mode !== "view" ?
                        <div className="row my-2">
                            <div className="col-12 d-flex justify-content-end">
                                <Button btnType="submit" value={'submit'} />
                                <Button btnType="cancel" value={'cancel'} onClick={() => { cancel() }} />
                            </div>
                        </div>
                        :
                        <div className="row my-2">
                            <div className="col-12 d-flex justify-content-end">
                                <Button btnType="primary" value={'close'} onClick={() => { cancel() }} />
                            </div>
                        </div>
                    }
                </div>
            </form>

                    : <Loading/>
            }
        </div>        </>
    )
}