import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const  getGroupPositionList =( async ( searchString) =>  await http({
    url: ENDPOINTS.BaseInformation.groupPosition.dictionary,
    method: "get",
    params:{
        SearchString : searchString
    }

    

})
.then((respons) => (respons.data))
.catch((err) => {

    return Promise.reject(err);
})
)
export const  getPositionList =( async (searchString ) =>  await http({
    url: ENDPOINTS.BaseInformation.position.browse,
    method: "get",
    params : {
        searchString : searchString
    }

    

})
.then((respons) => (respons.data))
.catch((err) => {

    return Promise.reject(err);
})
)
export const  getRadioList =( async ( ) =>  await http({
    url: ENDPOINTS.enumeration,
    method: "get",
    params : {
        enumerationType : "AssignTypes"
    }

    

})
.then((respons) => (respons.data))
.catch((err) => {

    return Promise.reject(err);
})
)


export const  setTaskUser =( async (data  , PRJ_UID) =>  await http({
    url: ENDPOINTS.bpmnApi.TaskUser.browse,
    method: "post",
    
    data:data,
})
.then((respons) => (respons.data))
.catch((err) => {

    return Promise.reject(err);
})
)
export const  getTaskUser =( async (TAS_UID ) =>  await http({
    url: ENDPOINTS.bpmnApi.TaskUser.browse,
    method: "get",
    params:{
        TAS_UID:TAS_UID 
    }
   
  
})
.then((respons) => (respons.data))
.catch((err) => {

    return Promise.reject(err);
})
)
