import { Loading } from "components/Loading/loading";
import { Toast } from "components/ToastShow/ToastShow";

import { useEffect, useState } from "react";
import { trans } from "utils/helpers";

import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { CityDictionary } from "pages/OfficeAutomation/Settings/City/components/CityDictionary";
import { ProvinceDictionary } from "pages/OfficeAutomation/Settings/Province/components/ProvinceDictionary";
import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { CountryDictionary } from "pages/OfficeAutomation/Settings/Country/components/CountryDictionary";




interface CustomerTaxFormPropsType {
    onHide: () => void
    submit: (e) => void
    mode: any,
    formRef?: any,

    CustomerId?: string,
    CustomerTax: any,
    reload?:()=>void,
}
export const CustomerTaxForm = ({ 
    onHide,
    submit,
    mode,
    formRef,
    CustomerId,
    CustomerTax,
    reload
}: CustomerTaxFormPropsType) => {
     
    const [resetForm, setResetForm] = useState(false);
    const [loading2 , setLoading2] = useState(true)
    const [userChanges, setUserChanges] = useState({});

    const [province, setProvince] = useState<any>(CustomerTax?.ProvinceId);
    const [country, setCountry] = useState<any>(null);
    const handleSubmit = (event) => {
        event.preventDefault();

        let data:any = {}
        if (CustomerTax == null) {
            data = {
              CustomerId:CustomerId,
              CustomerCharacteristic:+ event.target.CustomerCharacteristic.value,
              CustomerTaxType: +event.target.CustomerTaxType.value,
              BusinessActivityTypeId: event.target.BusinessActivityTypeId.value==""? null :event.target.BusinessActivityTypeId.value ,
              CityId: event.target.CityId.value ? event.target.CityId.value :null,
              ProvinceId: event.target.ProvinceId.value ? event.target.ProvinceId.value:null,
            }
            submit(data)
        } else {

            data = {
              CustomerId:CustomerId,
              CustomerCharacteristic:+ event.target.CustomerCharacteristic.value,
              CustomerTaxType: +event.target.CustomerTaxType.value,
              BusinessActivityTypeId: event.target.BusinessActivityTypeId.value==""? null :event.target.BusinessActivityTypeId.value ,
              CityId: event.target.CityId.value ? event.target.CityId.value :null,
              ProvinceId: event.target.ProvinceId.value ? event.target.ProvinceId.value:null,
              Id: CustomerTax?.Id,
            }
            submit(data)
           // putNodeAsync(data)    
      }
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                onHide()
              });
        }else{
          onHide()
        };


    }


    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);
     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);
    return (

        <>
        {loading2? 
            <>
                <form ref={formRef} name="Tax" className="TypeSettingForm row" onSubmit={handleSubmit}>
                  <div className="row my-1">
                    <div className="col-12 col-sm-3 ">
                      <EnumerationTypeSelect
                       mode={mode}
                       label="CustomerTaxType"
                       enumTypeSelect="CustomerTaxType"
                       name="CustomerTaxType"
                       value={CustomerTax?.CustomerTaxType ? CustomerTax?.CustomerTaxType  :""}
                       />
                    </div>
                    <div className="col-12 col-sm-3 ">
                      <EnumerationTypeSelect
                         mode={mode}
                         label="CustomerCharacteristic"
                         name="CustomerCharacteristic"
                         enumTypeSelect="CustomerCharacteristic"
                         value={CustomerTax?.CustomerCharacteristic ? CustomerTax?.CustomerCharacteristic  :""}
                         />
                    </div>
                    <div className="col-12 col-sm-4">
                      <DictionarySelectBox
                          name="BusinessActivityTypeId"
                          label="BusinessActivityType"
                          baseUrl={INVENTORYANDSALES_URL}
                          endPoint={ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.BusinessActivityType.dictionary}
                          value={CustomerTax?.BusinessActivityTypeId}
                          resetForm={resetForm}
                          mode={mode}
                      />
                   </div>

                   <div className="col-md-4 col-sm-12">
                      <ProvinceDictionary
                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                        onChange={(e)=> setProvince(e?.Id ?e?.Id  :null)}
                        label={"Province"}
                        resetForm={resetForm}
                        name={"ProvinceId"}
                        mode={mode}
                        // countryId={country?.Id}
                        value={CustomerTax?.ProvinceId }/>
                   </div>
                   <div className="col-md-4 col-sm-12">
                      <CityDictionary
                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                        label={"City"}
                        resetForm={resetForm}
                        name={"CityId"}
                        provinceId={province}
                        mode={mode}
                        value={CustomerTax?.CityId }/>
                   </div>
                   {mode != "create" &&          
                      <div className="col-lg-4 mt-1">
                      <EnumerationTypeSelect
                        Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                        value={CustomerTax?.ActivityStatus ? CustomerTax?.ActivityStatus  : null}  
                        name="CustomerTaxStatus" 
                        enumTypeSelect="ActivityStatus"
                        mode={mode} 
                        label="Status" 
                      />
                      </div>                            
                      }
                   </div>  
                   <button  type="submit"  className="d-none" id="hidebutton" name="hidebutton" >
                   </button>                    
                </form>
            </>
            :<Loading />
        }
        </>

    );
}