import { Button } from "components/Button/Button";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { Loading } from "components/Loading/loading";
import { trans } from "utils/helpers";
import { AddNodeToParent, editNode, getNode } from "../../api/api";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";

interface OrganizationFormPropsType {
    selectedObj;
    showForm;
    mode;

}
export const OrganizationForm = ({ 
    selectedObj,
    showForm,
    mode
}: OrganizationFormPropsType) => {
   
    let  Title , Qualification :any;
    const [resetForm, setResetForm] = useState(false);
    const [node, setNode] = useState<any>();
    const [userChanges, setUserChanges] = useState({});
    const [loading, setLoading] = useState<boolean>(false);

    if (mode == "edit" ||mode == "view" ) {
        Title = selectedObj.Description
    }
    async function sendNodeAsync(data) {
        try{
           const res = await AddNodeToParent(data);
           sessionStorage.setItem("changingforms" , "false");
           setUserChanges({});
           showForm()
           
        }catch(err:any){

          }
        }

    async function getNodeAsync() {
    setLoading(false);

        try{
           const res = await getNode(selectedObj.Id);
           setNode(res.Data)


        }catch(err:any){

          }
    setLoading(true);

    }

    async function putNodeAsync(data) {
            try{
               const res = await editNode(data);
               sessionStorage.setItem("changingforms" , "false");
               setUserChanges({});
               showForm()
               
            }catch(err:any){
    
              }
            }


            function cancel() {
                if(sessionStorage.getItem("changingforms")+""=="true"){
                    Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                        sessionStorage.setItem("changingforms" , "false");
                        setUserChanges({});
                        showForm(false)
                    });
                }else{
                    showForm(false)    
                };
        
        
            }
            
    const handlePersonContactFormSubmit = (event) => {
        event.preventDefault();
        if(mode=="edit"){
            let data={
                "id":selectedObj.Id,
                "ParentId": selectedObj.ParentId,
                "Count": 0,
                "ActivityStatus": +event.target.ActivityStatus.value,
                "OrganizationType": +event.target.OrganizationType.value,
                "Description": event.target.Title.value,
                "Qualification":event.target.Qualification.value
            }
          putNodeAsync(data)
        
        }else{
            let data={
                "ParentId": selectedObj.Id,
                "OrganizationType": +event.target.OrganizationType.value,
                "Description": event.target.Title.value,
                "Qualification":event.target.Qualification.value
            }
           sendNodeAsync(data)
          }
       
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);

     useEffect(() => {
          if (mode == "edit" ||mode == "view" ) {
            getNodeAsync()
        }else{
            setLoading(true)
        }
      }, [selectedObj]);

      
    return (
        <>
        <div className="person-contact-form card">
            <div className="card-body">
                <div className="container-fluid">
                    {loading ?
                    (<form className="row " onSubmit={handlePersonContactFormSubmit}>
                        <div className="col-lg-6 mt-1">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                required={true}
                                textboxType="text"
                                textboxName="Title"
                                labelText="Title"
                                maxLength={900}
                                defaultValue={node ? node?.Description : Title}
                                resetForm={resetForm}
                                mode={mode}
                            />
                        </div>


                        <div className="col-lg-6 mt-1">
                            <Textbox
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              textboxType="text"
                              textboxName="Qualification"
                              labelText="Qualification"
                              maxLength={900}
                              defaultValue={node ? node?.Qualification : Qualification}
                              resetForm={resetForm}
                              mode={mode}
                            />
                        </div>
                        <div className="col-lg-6 mt-1 mb-3">
                            <EnumerationTypeSelect   
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}                                                  
                              required={true}
                              label="OrganizationType"
                              name="OrganizationType"
                              value ={ node?.OrganizationType}
                              mode={mode}                 
                              enumTypeSelect="OrganizationType"
                            />
                        </div>
                        {mode != "create" && 
                        <div className="col-lg-4 mt-1">
                            <EnumerationTypeSelect
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              required={true}
                              label="ActivityStatus"
                              name="ActivityStatus"
                              value ={node?.ActivityStatus }
                              mode={mode}
                              enumTypeSelect="ActivityStatus"
                            />
                        </div>                        
                        }



                        {  mode !="view" ?
                            <div className="col-lg-12 mt-1 btns_form">
                            <Button value="Save" btnType="submit" />
                            <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                      </div>
                      :
                      <div className="row my-2">
                      <div className="col-12 d-flex justify-content-end">
                          <Button btnType="primary" value={'close'} onClick={() => { cancel() }} />
                      </div>
                  </div>
                        }
                    </form>)
                     :
                    (<Loading />)
                    }
                </div>
            </div>
        </div>        
        </>

    );
}