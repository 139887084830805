import { useEffect, useState, useRef } from "react";
import { trans } from "utils/helpers";
interface SubMenuPropsType {
    options?: any,
    onClick: (value, key) => void,
    onHide: () => void,
}

export const SubMenu = ({ options , onClick ,onHide}: SubMenuPropsType) => {
    const [activeId , setactiveId] = useState<any>();
    const SubMenuRef = useRef<any>(options);
    const activeIdRef = useRef<any>(activeId);
    let options_PostLevel_keys:any = [] 
     let options_PostLevel_values:any =[] ;





    const keydown = (event) => {
      event.stopPropagation()
        if (event.key == "ArrowDown") {
            if(activeId==null &&activeIdRef.current==null ){
                setactiveId(0)
                activeIdRef.current = 0
        }else{
          activeIdRef.current +=1
          if(SubMenuRef.current && document.getElementById(Object.keys(SubMenuRef.current)[activeIdRef.current])){
            document.getElementById(Object.keys(SubMenuRef.current)[activeIdRef.current])?.scrollIntoView({behavior:"smooth"})
            setactiveId(activeIdRef.current )

          }
        }

      }else if(event.key == "ArrowUp"){
        event.stopPropagation()
        if(activeId==null &&activeIdRef.current==null ){
          setactiveId(0)
          activeIdRef.current = 0
        }else{
          activeIdRef.current -=1
          if(SubMenuRef.current && document.getElementById(Object.keys(SubMenuRef.current)[activeIdRef.current])){
          document.getElementById(Object.keys(SubMenuRef.current)[activeIdRef.current])?.scrollIntoView({behavior:"smooth"})
          setactiveId(activeIdRef.current )
        }
    }


      }else if(event.key == "Enter"){
        event.stopPropagation()
        if(activeId==null &&activeIdRef.current==null ){

        }else{
          if(SubMenuRef.current && document.getElementById(Object.keys(SubMenuRef.current)[activeIdRef.current])){
            onClick(Object.values(SubMenuRef.current)[activeIdRef.current],Object.keys(SubMenuRef.current)[activeIdRef.current])
          }

        }
      }


    }
      useEffect(() => {
          document.addEventListener('keydown', keydown  );
          return () => { document.removeEventListener("keydown", keydown ); }
        
    }, [])
      useEffect(() => {
        SubMenuRef.current = options
        
    }, [options])


        if(options){
            options_PostLevel_values = Object.values(options)
            options_PostLevel_keys  = Object.keys(options)
        }
    
    return (
     <>
        {options && 
         <div className={`text-box__sub-menu mt-1`} tabIndex={-1} >
           {options && options_PostLevel_values.map((item , index)=>{

                  return <div  id={options_PostLevel_keys[index]} className={`text-box__sub-menu-option${index ==  activeId || index == activeIdRef.current ? "-active":""}`} onClick={()=>{onClick(item,options_PostLevel_keys[index])}} key={options_PostLevel_keys[index]} >{item}</div>
            })}  
         </div>  
              
        }
     </>
    )
}