

import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";

import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { getListItemMainProperty, getItemMainProperty, postItemMainProperty, putItemMainProperty, deleteItemMainProperty } from "./api";
import { ModalForm } from "components/ModalForm/ModalForm";
import { ItemMainPropertyValues } from "./ItemMainPropertyValues/ItemMainPropertyValues";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";


interface Form {

}
export const ItemMainProperty = ({ }: Form) => {

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [showPropertiesForm, setShowPropertiesForm] = useState(false);
    const [flag, setFlag] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [userChanges, setUserChanges] = useState({});

    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pages, setPages] = useState<number>(0);




    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("ItemMainProperty" ,e.currentTarget.value )
        getItemMainPropertiesAsync(1)
      };


      function handleGoPage(value): void {
        if (value) {
            if (value < 1 || value > pages) { }
            else {setPageNum(+value);getItemMainPropertiesAsync(+value)}
        } else {
        }
    }
    
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getItemMainPropertiesAsync(pageNum + 1);
          };
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getItemMainPropertiesAsync(pageNum - 1);
          };
    };



    const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }
    const handleCreateAction = () => {
        // setSelectedObj(null);
        setEditForm(null)
        setMode("create")
        setShowForm(true)
    }
    const handleEditRow = () => {
        setMode("edit");
        setShowForm(true)
    }
    const handleDeleteRow = async() => {
        try {
            const res = await deleteItemMainProperty(selectedObj.Id)
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }
    }
    const handleViewRow = () => {
        setMode("view")
        setShowForm(true)
    }

    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false"); setUserChanges({}) });
        }else{
            setSelectedObj(obj)         
        };
    }

    const submit = (e) => {
        e.preventDefault();
        if (editForm == null) {
            postItemMainProperty({                     

                Title: e.target.ItemMainProperty.value,
                TitleEN: e.target.TitleEN.value,
                SystemKey: e.target.systemKey.value, 
                CodeLength:  e.target.CodeLength.value, 
                Description: e.target.Description.value, 
         
            
            }).then(() => {sessionStorage.setItem("changingforms" , "false");setUserChanges({});getItemMainPropertiesAsync();cancel()  });
        } else {
            putItemMainProperty({
                ActivityStatus: +e.target.ItemMainPropertySelect.value , 
                Title: e.target.ItemMainProperty.value,
                TitleEN: e.target.TitleEN.value,
                SystemKey: e.target.systemKey.value, 
                CodeLength:  e.target.CodeLength.value, 
                Description: e.target.Description.value, 
                Id: selectedObj.Id,


       
             }).then(() => {sessionStorage.setItem("changingforms" , "false");setUserChanges({}); getItemMainPropertiesAsync();cancel();  })
        }
        setShowForm(false)

    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                setEditForm(null);
                setShowForm(false)
            });
        }else{
            setEditForm(null);
            setUserChanges({});

            setShowForm(false)     
        };
    }
    async function getItemMainPropertiesAsync(PageNum =pageNum) {
        setLoading(false)

        let pageSizeChangedByUser = getPageSize("ItemMainProperty" )
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getListItemMainProperty(search ,orderBy.length == 0 ?null:  orderBy.join(",") ,PageNum,pageSizeChangedByUser);
            setResponse(res.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) 
           
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }

        setLoading(true)
    }
    async function getItemMainPropertyasync() {
        setFormLoading(false)
        try {
            const data = await getItemMainProperty(selectedObj?.Id);
            setEditForm({ 
                Title: data.Data.Title,
                systemKey: data.Data.SystemKey,
                ActivityStatus: data.Data.ActivityStatus,
                CodeLength:  data.Data.CodeLength,
                Description: data.Data.Description,
                Id: data.Data.Id,
            });
        } catch (error) {
            
        }
        setFormLoading(true)
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        getItemMainPropertiesAsync();
    }, [forceUpdate, flag  ,search , orderBy]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);
    useEffect(() => {
        if(showForm &&selectedObj && mode != "create"){
           getItemMainPropertyasync()
        }else{
            setFormLoading(true)
        }
       
    }, [selectedObj ,showForm ,mode]);

    if (flag) {
        getItemMainPropertiesAsync()
        setFlag(false)

    }

    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,1 ,trans("ItemMainProperty"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "values": if (selectedObj?.Id) setShowPropertiesForm(true); break;
            case "edit": if (selectedObj?.Id)selectedObj.SystemReserved ? Toast(trans("Msg_CanNotEditSystemReservedData") , "error") : handleEditRow(); break;
            case "help": alert("call api help"); break;
        }
    };
    /* #endregion */

    return (
        <>  

            {showPropertiesForm&&
                    <ModalForm modalHeight={49} mode={mode} onHide={()=>{setShowPropertiesForm(false)}} show={showPropertiesForm} title={"itemmainpropertyvalueslist" } >
                        <ItemMainPropertyValues mode={mode}  ItemGroupKeyObj={selectedObj} relod={()=>setForceUpdate(!forceUpdate)}   onHide={()=>{setShowPropertiesForm(false)}} /> 
                    </ModalForm>  

            }
            <div className="cartable-action">
                <Toolbar warehouse={false} handleSearch={searchHandler}  values={true}  id={selectedObj?.Id} emptyList={response?.TotalRecords ==0||response?.Data==null} remove={true} create={true} view={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card">
                    <div className="">

                            <>
                                {showForm&& 
                                <>                            
                                    <div className="card mb-4">
                                        {formLoading ? 
                                        <form className="TypeSettingForm row card-body " onSubmit={submit}>
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"ItemMainProperty"} textboxType="text" resetForm={resetForm} labelText={"Title"} mode={mode}  defaultValue={editForm?.Title} />
                                        </div>     
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}}  textboxName={"TitleEN"} textboxType="text" resetForm={resetForm} labelText={"TitleEN"} mode={mode}  defaultValue={editForm?.TitleEN} />
                                        </div>     
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"systemKey"} textboxType="systemKey" resetForm={resetForm} labelText={"systemKey"} mode={mode}  defaultValue={editForm?.systemKey} />
                                        </div> 
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"CodeLength"} textboxType="number" resetForm={resetForm} labelText={"CodeLength"} mode={mode}  defaultValue={editForm?.CodeLength} />
                                        </div> 
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} textboxName={"Description"} textboxType="textarea" resetForm={resetForm} labelText={"Description"} mode={mode}  defaultValue={editForm?.Description} />
                                        </div> 
                                        {mode != "create" &&          
                                        <div className="col-lg-4 mt-1">
                                            <EnumerationTypeSelect
                                             value={editForm?.Status} 
                                             Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                                             name="ItemMainPropertySelect"  
                                             enumTypeSelect="ActivityStatus"
                                             mode={mode} 
                                             label="Status" 
                                            />
                                        </div>                            
                                         }  
                                        {  mode !="view" ?
                                            <div className="col-lg-12 mt-1 btns_form">
                                              <Button value="Save" btnType="submit" />
                                              <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                                            </div>
                                            :
                                            <div className="col-lg-12 mt-1 btns_form">
                                              <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                                            </div>
                                          }                        
                                    </form> :<Loading/>}

                                  </div >
                                   </>
                                }
                {
                                    loading ?
                                    response?.Data?.length > 0 ?
                                        <>
                                            <DataGrid
                                            DoubleClick={()=>handleViewRow()}
                                                orderFormat={setOrderBy}
                                                orderFormatList={orderBy}
                                                parentName={"ItemMainProperty"}
                                                startIndex={1}
                                                theadData={Object.keys(response.Data[0])}
                                                tbodyData={response.Data}
                                                minCellWidth={80}
                                                scrollHeight={showForm? "25vh": "67vh"}
                                                selectedRowObjectCallback={selectedIdCallback}
                                                handleEdit={handleEditRow}
/*                                                
                                                handleView={handleViewRow} */
                                                handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                                totalRecords={response.TotalRecords}
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                HandleNextPage={handleNextPage}
                                                HandlePrevPage={handlePrevPage}
                                                handlechangePageSize={handleChangePageSize}
                                                first={() =>{getItemMainPropertiesAsync(pages); setPageNum(pages)}}
                                                end={() =>{getItemMainPropertiesAsync(1); setPageNum(1)}}
                                                handleGoPage={handleGoPage}
                                            />
                                        </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                                :<Loading/>
                                }

                            </>

                    </div>
                </div>
            </div>
        </>

    );
}