
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getCustomerInfo = (requesterPositionId) => http({
    url: ENDPOINTS.afterSalesApi.customerInfo,
    method: 'get',
    params: {
        requesterPositionId:requesterPositionId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getCustomerInProgressRequests = (CustomerId) => http({
    url: ENDPOINTS.afterSalesApi.customerInProgressRequests,
    method: 'get',
    params: {
        customerPositionId :CustomerId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});