import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";

import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { deleteBankPOS, getBankPOS } from "./api";
import { BankPOSForm } from "./BankPOSForm";

interface Form {
  Title: string;
  Status: number;
}
export const BankPOS = ({}: Form) => {
  const [response, setResponse] = useState<Response | any>(null);
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [mode, setMode] = useState("create");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState<any>("info");
  const [orderBy, setOrderBy] = useState<any>([]);
  const [search, setSearch] = useState(null);
  const [showEventForm, setShowEventForm] = useState(false);
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pages, setPages] = useState<number>(0);
  const [filterlist, setFilterlist] = useState<any>("");

  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "print":
        alert("print ");
        break;
      case "excel":
        downloadCSV(response?.Data, 1, trans("BankPOS"));
        break;
      case "refresh":
        setForceUpdate(!forceUpdate);
        break;
      case "create":
        handleCreateAction();
        break;
      case "view":
        if (selectedObj?.Id) handleViewRow();
        break;
      case "edit":
        if (selectedObj?.Id)
          selectedObj.SystemReserved
            ? Toast(trans("Msg_CanNotEditSystemReservedData"), "error")
            : handleEditRow();
        break;
      case "help":
        alert("call api help");
        break;
      case "remove":
        if (selectedObj?.Id)
          Toast(
            trans("msg_deleteconfirm"),
            "warning",
            "alert",
            handleDeleteRow
          );
        break;
    }
  };

  const handleChangePageSize = (e: any) => {
    setPageNum(1);
    setPageSize(e.currentTarget.value);
    getPageSize("BankPOS", e.currentTarget.value);
    getBankPOSAsync(1);
  };

  const handleGoPage = (value) => {
    if (value) {
      if (value < 1 || value > pages) {
      } else setPageNum(+value);
      getBankPOSAsync(+value);
    } else {
    }
  };
  const handleNextPage = () => {
    if (pageNum < pages) {
      setPageNum(pageNum + 1);
      getBankPOSAsync(pageNum + 1);
    }
  };
  const handlePrevPage = () => {
    if (pageNum > 1) {
      setPageNum((pageNum) => {
        return +pageNum - 1;
      });
      getBankPOSAsync(pageNum - 1);
    }
  };
  const handleCreateAction = () => {
    // setSelectedObj(null)
    setMode("create");
    setShowEventForm(true);
  };
  const handleEditRow = () => {
    setMode("edit");
    setShowEventForm(true);
  };
  const handleDeleteRow = async () => {
    try {
      await deleteBankPOS(selectedObj.Id);
      setForceUpdate(!forceUpdate);
    } catch (error) {}
  };
  const handleViewRow = () => {
    setMode("view");
    setShowEventForm(true);
  };

  const selectedObjCallback = (obj, index) => {
    setSelectedObj(obj);
  };
  const SearchHandler = (str) => {
    setPageNum(1);
    if (str == "") {
      setSearch(null);
    } else {
      setSearch(str);
    }
  };

  async function getBankPOSAsync(PageNum = pageNum) {
    setLoading(false);
    setResponse(null);
    let pageSizeChangedByUser = getPageSize("BankPOS");
    setPageSize(pageSizeChangedByUser);
    try {
      const res = await getBankPOS(
        PageNum,
        pageSizeChangedByUser,
        search,
        orderBy.length == 0 ? null : orderBy.join(","),
        filterlist
      );
      setResponse(res);
      setPages(
        res.TotalRecords > pageSizeChangedByUser
          ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser)
          : 1
      );
      setMessageType("info");
    } catch (error) {
      setMessageType("error");
    }
    setLoading(true);
  }

  useEffect(() => {
    getBankPOSAsync();
  }, [forceUpdate, search, orderBy, filterlist]);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
      getBankPOSAsync();
    }
  }, [resetForm]);

  return (
    <>
      {showEventForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowEventForm(false);
          }}
          show={showEventForm}
          title={"BankPOSForm"}
        >
          <BankPOSForm
            mode={mode}
            selectedObj={selectedObj}
            relod={() => setForceUpdate(!forceUpdate)}
            showForm={() => {
              setShowEventForm(false);
            }}
          />
        </ModalForm>
      ) : null}
      <div className="cartable-action">
        <Toolbar
          warehouse={false}
          remove={true}
          handleSearch={SearchHandler}
          id={selectedObj?.Id}
          emptyList={response?.TotalRecords == 0}
          create={true}
          edit={true}
          search={true}
          handleClickToolbar={clickToolbarCallback}
        />
        <div
          className="card"
          style={{
            overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",
          }}
        >
          <div className="">
            {loading ? (
              <>
                {response?.Data?.length > 0 ? (
                  <>
                    <DataGrid
                      parentName={"BankPOS"}
                      handlefiltering={(str) => {
                        setFilterlist(str);
                      }}
                      filterStr={filterlist}
                      orderFormat={setOrderBy}
                      orderFormatList={orderBy}
                      startIndex={1}
                      theadData={Object.keys(response.Data[0])}
                      tbodyData={response.Data}
                      minCellWidth={80}
                      selectedRowObjectCallback={selectedObjCallback}
                      handleEdit={handleEditRow}
                      handleDelete={(e) => {
                        Toast(
                          trans("msg_deleteconfirm"),
                          "warning",
                          "alert",
                          handleDeleteRow
                        );
                      }}
                      handleView={handleViewRow}
                      totalRecords={response.TotalRecords}
                      pageNum={pageNum}
                      pageSize={pageSize}
                      HandleNextPage={handleNextPage}
                      HandlePrevPage={handlePrevPage}
                      handlechangePageSize={handleChangePageSize}
                      first={() => {
                        getBankPOSAsync(pages);
                        setPageNum(pages);
                      }}
                      end={() => {
                        getBankPOSAsync(1);
                        setPageNum(1);
                      }}
                      handleGoPage={handleGoPage}
                    />
                  </>
                ) : (
                  <Message message={"NoDataForPreview"} type={messageType} />
                )}
              </>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
