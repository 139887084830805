import { useEffect, useState, useRef } from "react";
import { trans } from "utils/helpers";
import { getLetterSubjectApi } from "./api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
interface LetterSubjectPropsType {
    label?: string,
    hasBorder?: boolean,
    required?: boolean,
    value?:string,
    name:any,
    mode?:string,
    resetForm?:any,
    Change?: (e) => void,
    setLetterSubject?: (e) => void,
}

export const LetterSubject = ({ label, name,hasBorder,value,mode ,required , resetForm ,Change , setLetterSubject}: LetterSubjectPropsType) => {
    const [LetterSubjectsList, setLetterSubjectsList] = useState<any>();
    const [valueName, setValueName] = useState<any>();
    const [flag , setFlag] = useState(false);
    const [show , setShow] = useState(false);
    const [validat , setValidat] = useState(false);
    const [rquird , setRquird] = useState(false);
    const [count,setCount] = useState<number>(0);
    const inputRef = useRef<any>();
    const LetterSubjectRef = useRef<any>(LetterSubjectsList);
    const validRef = useRef<any>(false);
    const countRef = useRef<any>(0)

    let optionList :any = []


    async function getLetterSubjectListAsync(search,id) {
        try{
           const res = await getLetterSubjectApi(search,id);
           if (id){
            setValeu(res[id] , id)
            setValueName(res[id])
            }
            setLetterSubjectsList({ ...res })     
            LetterSubjectRef.current = { ...res }
            setFlag(!flag)
        }catch(err:any){
        
        }
      }
    
    const Auto = (e)=>{
      validRef.current = false
      setValidat(false)
      setRquird(false)
      setShow(true)
      getLetterSubjectListAsync(e.target.value,"")
      setFlag(!flag)
    }

    function userSelecter(){
      setValeu(optionList[countRef.current],Object.keys(LetterSubjectsList)[countRef.current])
      setShow(false)
    }
    const kydown = (event) => {    

      if((event.key === "ArrowDown")){
        if(countRef.current < optionList.length-1){
          countRef.current = countRef.current + 1;
        }else{
          countRef.current = 0;
        }
      }
      
      if(event.key === "ArrowUp"){
        if(countRef.current > 0){
          countRef.current = countRef.current -1;
        }else{
          countRef.current = optionList.length-1;
        }
      }
      document.getElementById("LetterSubjectsListIndex"+countRef.current)?.scrollIntoView({block:'end',behavior:"smooth"})
      setCount(countRef.current)
      if(event.key === "Enter"){
        userSelecter()
      }
    }
  




    const setValeu = (newValue , id)=>{
     
        validRef.current = true
        inputRef.current.value=newValue;
        LetterSubjectRef.current = null;
        ((value==null &&id)|| id != value) ? Change&&Change({[name] :newValue}):Change&&Change({[name]:null});
  
        setLetterSubjectsList(null)
        setFlag(!flag)
        inputRef.current.setCustomValidity("")
        setLetterSubject && setLetterSubject(inputRef.current.value)
        setValidat(false)
      
    }


      useEffect(() =>{
        if(show ){
          document.addEventListener('keydown', kydown);
          document.body.addEventListener("click", clickToclose)
          
        } 
     return () => { document.removeEventListener("keydown", kydown);document.body.removeEventListener('click', clickToclose) }
    
        
      },[show ,optionList])
    
      function clickToclose(e){
        let input = document.querySelector("#browser"+name);
        if (e.target.id !== input?.id) { 
          setShow(false);
        }
      }
      

      if(LetterSubjectsList){
        optionList = Object.values(LetterSubjectsList)
      }
      return (
     <>
        <div className="text-box">
        <OverlayTrigger
        key={label}
        placement="top"
        delay={{"show" : 300 , "hide":0}}
        overlay={
            <Tooltip className="tooltip" id={`button-tooltip-${"checkable"}`}>
                {trans(label ? label :"ChooseLetterSubject")}
            </Tooltip>
        }
        >
      <div>
        {label ? (
          <div className="d-flex align-items-start" style={{height:'22px'}}>
            <label htmlFor={`browser${name}`}  className="text-box__label showDots">
            {trans(label ? label :"ChooseLetterSubject")}
            </label>
             {required ? <span className="text-box__required-sign-for-label text-danger">*</span> : ""}
          </div>
        ) : null}
      </div>
      </OverlayTrigger>

     <input required={required}defaultValue={ value} name={name}  autoComplete="off" onFocus={(e)=>{setShow(true);Auto(e)}}  ref={inputRef} list="LetterSubjectList"  className="text-box__box form-control "    id={"browser"+name} onMouseDown={(e)=>e.stopPropagation() } onChange={Auto} disabled={mode =="view" ? true : false} />
     {/* <div style={{position:"absolute",backgroundColor:"red",height:"500px"}}>Test</div>  */}
      {
        show && 
         <div tabIndex={-1} className={`text-box__sub-menu mt-1`} >
           {optionList && optionList.map((item , index)=>{
                  return  <div id={"LetterSubjectsListIndex"+index} className={`${count == index ?"text-box__sub-menu-option-active" :"text-box__sub-menu-option"}`} onClick={()=>{setValeu(item,Object.keys(LetterSubjectsList)[index])}} key={Object.keys(LetterSubjectsList)[index]} >{item}</div>
            })}  
         </div>
      }  
       <div className="text-box__msg-wrapper">
        {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
        {required && <span className={`${rquird ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsRequired", [""])}</span>}
        </div>    
        </div>
     </>
    )
}