import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { CHAT_URL } from "./constants";
import { signalRHttp } from "./SignalRHttp";


const lang = localStorage.getItem("lang")+"";
export  const ChatConnection: any = new HubConnectionBuilder()
.withUrl(CHAT_URL, { headers: { "cookie": " ","Accept-Language" : lang }, httpClient: new signalRHttp(), accessTokenFactory: () => { return localStorage.getItem("token")??'' } })
.withAutomaticReconnect({
  nextRetryDelayInMilliseconds: retryContext => {
      if (retryContext.elapsedMilliseconds < 60000) {
          // If we've been reconnecting for less than 60 seconds so far,
          // wait between 0 and 10 seconds before the next reconnect attempt.
          return Math.random() * 10000;
      } else {
          // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.

          return null;
      }
  }
})
.configureLogging(LogLevel.Warning)
.build();
