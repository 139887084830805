import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import Datepicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { UnitDictionary } from "pages/InventoryAndSales/Inventory/BaseDefinitionAndSetting/Unit/components/UnitDictionary";
import { ItemTreeForm } from "pages/InventoryAndSales/Inventory/WarehouseItem/ItemTreeForm";
import { useEffect, useState } from "react";
import { ICON_TYPE, INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import {
  roundOrTruncate,
  trans,
  uuidv4
} from "utils/helpers";
import { PriceDetails } from "../components/PriceDetails";
import {
  getPriceListDetail,
  getTaxList
} from "./api";
import { WarehouseDictionary } from "./components/WarehouseDictionary/WarehouseDictionary";
import { WarehouseTreeForm } from "./components/WarehouseTreeForm/WarehouseTreeForm";
import { SalesInvoiceDetailType } from "./type";

interface SalesInvoiceFormProps {
  onHide: () => void;
  SalesInvoiceDetailCallBack?: (e: any) => void;
  selectedObj?: any;
  docsClassification?: any;
  mode: any;
  priceList?: any;
  lastWarehouse?: any;
  invoiceConstraint?: any;
  setLastWarehouse: (e: any) => void;
  personId?:any;
  currency?:any
}

export const SalesInvoiceDetailForm = ({
  lastWarehouse = null,
  invoiceConstraint,
  setLastWarehouse,
  priceList,
  selectedObj,
  docsClassification,
  mode,
  onHide,
  SalesInvoiceDetailCallBack,
  personId,
  currency
}: SalesInvoiceFormProps) => {
  const [resetForm, setResetForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userChanges, setUserChanges] = useState({});
  const [expireDate, setExpireDate] = useState<any>(null);
  const [showTree, setShowTree] = useState(false);
  const [showWarehouseTree, setShowWarehouseTree] = useState(false);
  const [priceListDetail, setPriceListDetail] = useState<any>([]);
  const [detailPriceData, setDetailPriceData] = useState<any>({
    Price: 0,
    Discount: '',
    SubTotalPrice: 0,
    Qty1: mode !== 'create' ? selectedObj?.Qty1 : 1,
    Qty2: mode !== 'create' ? selectedObj?.Qty2 : 1,
  });
  const [itemData, setItemData] = useState<any>(null);
  const [totalTaxRate, setTotalTaxRate] = useState<any>(null);
  const [warehouseData, setWarehouseData] = useState(lastWarehouse);
  const [dictionaryName, setDictionaryName] = useState<any>({});
  const [saveExit, setSaveExit] = useState(false);
  const [rate, setRate] = useState<any>(1);
  const [fristQty, setFristQty] = useState<any>(null);
  const [numberOfSalesUnit, setNumberOfSalesUnit] = useState(1);
  const [showPriceListDetail,setShowPriceListDetail]=useState<any>(false)

  const handleSalesInvoiceFormSubmit = async (event) => {
    event.preventDefault();

    const data: SalesInvoiceDetailType = {
      Id: mode !== 'create' ? selectedObj?.Id : uuidv4(),
      SalesInvoiceId: selectedObj?.SalesInvoiceId,
      SourceId: selectedObj?.SourceId,
      SourceData: selectedObj?.SourceData,
      IsAbsoluteUnitConversionRatio	: selectedObj?.IsAbsoluteUnitConversionRatio	,
      ItemId: itemData?.ItemId,
      WarehouseId: warehouseData?.WarehouseId,
      WarehouseDetailId: warehouseData?.WarehouseDetailId,
      Unit1Id: event.target.Unit1Id?.value || null,
      Unit2Id: event.target.Unit2Id?.value || null,
      SalesUnitId: detailPriceData?.SalesUnitId,
      ItemCode: itemData?.Code?itemData?.Code:itemData?.ItemCode,
      Item: itemData?.Item ,
      Qty1: +event.target.Qty1?.value,
      Unit1: dictionaryName?.Unit1 || null,
      Qty2: +event.target.Qty2?.value,
      Unit2: dictionaryName?.Unit2 || null,
      SalesUnitPrice: detailPriceData?.SalesUnitPrice,
      ConversionRatio: rate,
      UnitPrice:event.target.UnitPrice?.value? parseFloat(event.target.UnitPrice?.value.replace(/,/g, '')):0,
      SubTotalPrice:event.target.SubTotalPrice?.value? parseFloat(event.target.SubTotalPrice?.value.replace(/,/g, '')):0,
      DiscountPercent:parseFloat(event.target.DiscountPercent?.value.replace(/,/g, '')),
      Discount: parseFloat(event.target.Discount?.value.replace(/,/g, '')),
      Price: event.target.Price.value ? parseFloat(event.target.Price?.value.replace(/,/g, '')):0,
      Tax: docsClassification?.CalculateTax ?parseFloat(event.target.Tax?.value.replace(/,/g, '')):   ((docsClassification?.CalculateTax ||(docsClassification?.CalculateTax==null&& invoiceConstraint.IsDocTaxConfirmed)) ? parseFloat(event.target.Tax?.value.replace(/,/g, '')): 0),

      ItemTechnicalCode: event.target.ItemTechnicalCode?.value,
      PartyNo: event.target?.PartyNo?.value,
      ExpireDate: expireDate,
      CargoWeight: +event.target?.CargoWeight?.value || 0,
      Description: event.target.Description?.value,
      Warehouse: warehouseData.WarehouseTitle,
      WarehouseDetail: warehouseData?.WarehouseDetail,
    };

    SalesInvoiceDetailCallBack?.(data) && resetFormIfNeeded()
    setLastWarehouse(warehouseData);
    // SalesInvoiceDetailCallBack?.(data) ;
  };

  const resetFormIfNeeded = () => {
    if (mode === 'create') {
      setItemData(null);
      setResetForm(true);
      setUserChanges({});
      setDetailPriceData({ Price: 0, Discount: 0, SubTotalPrice: 0, Qty1: 1, Qty2: 1 });
      setPriceListDetail([]);
    }
    sessionStorage.setItem('changingforms', 'false');
    if (saveExit) onHide();
  };

  const handleItemCode = (obj, isLeafSelected) => {
    if (obj.IsLeaf || isLeafSelected) {
      setItemData(obj);
      fetchPriceListDetail(obj.Id);
      setShowTree(false);
      if(mode==='edit'){
        setShowPriceListDetail(true)
      }
    } else {
      Toast('msg_itemleafisnotselected', 'error');
    }
  };

  const handleWarehouseSelection = (obj) => {
    setWarehouseData(obj);
    setShowWarehouseTree(false);
  };

  const updateQuantity =async (newQty, priceListArray = [], taxRate = null) => {
    let newPriceData =await calculatePriceData(newQty, priceListArray);
    if(fristQty==null && !!priceListDetail.length &&priceList?.HasDetailRange && mode === 'create'){
      setFristQty(selectedObj?.Qty1)
      fetchPriceListDetail(itemData?.Id,selectedObj?.ItemId)}//in create mode uses itemData and edit,view mode uses selectedObj?.ItemId

      
    setDetailPriceData({
      ...newPriceData,
      TaxRate: taxRate || detailPriceData.TaxRate,
      Qty1: numberOfSalesUnit === 1 ? newQty : newQty * priceListDetail[0]?.ConversionRatio,
      Qty2: numberOfSalesUnit === 2 ? newQty : newQty / priceListDetail[0]?.ConversionRatio,
    });
  };

  const calculatePriceData = (newQty, priceListArray) => {
    const currentPriceList = priceListArray.length ? priceListArray : priceListDetail;
    let matchedPriceData = findMatchingPriceData(currentPriceList, newQty);
 

    return {
      ...matchedPriceData,
      UnitPrice: matchedPriceData?.UnitPrice ?matchedPriceData?.UnitPrice : matchedPriceData?.SalesUnitId === matchedPriceData?.Unit1Id ? matchedPriceData?.Unit2Price : matchedPriceData?.Unit1Price,
      Price: calculateDiscountedPrice(newQty, matchedPriceData),
      Discount: calculateDiscount(newQty, matchedPriceData),
      SubTotalPrice: newQty * matchedPriceData?.SalesUnitPrice,
    };
  };

  
  const findMatchingPriceData = (priceList, qty) =>
    priceList
      .reverse()
      .find(
        (e) =>
          (e.SalesUnitMaxOrderQty == null || +e.SalesUnitMaxOrderQty >= qty) &&
          +e.SalesUnitMinOrderQty <= qty
      );

  const calculateDiscountedPrice = (qty, priceData) =>
    roundOrTruncate(
      qty * ((priceData?.SalesUnitPrice * (100 - priceData?.DiscountPercent)) / 100),
      currency?.PriceRoundingPlace,
      false
    );

  const calculateDiscount = (qty, priceData) =>
    (qty * priceData?.SalesUnitPrice * priceData?.DiscountPercent) / 100;

  const fetchTaxRate = async (itemId) => {
    try {
      const { Data } = await getTaxList(itemId);
      return Data.reduce((sum, tax) => sum + tax.TaxRate, 0);
    } catch {
      return 0;
    }
  };

  const fetchPriceListDetail = async (itemId = null, code = null) => {
    try {
      const { Data } = await getPriceListDetail(
       itemId,
       priceList?.IsManual ? null : priceList?.Id,
       code,
       warehouseData?.WarehouseId?warehouseData?.WarehouseId:selectedObj?.WarehouseId,
       warehouseData?.WarehouseDetailId,
       personId
     );

     
     updatePriceListDetails(Data);
   } catch {}
  };

  const updatePriceListDetails = async (data ,withPriceListDetail=true ) => {
    const [firstItem] = data;
    setNumberOfSalesUnit(firstItem.SalesUnitId === firstItem.Unit1Id ? 1 : 2);
    const tax=await fetchTaxRate(firstItem?.ItemId);

    (docsClassification?.CalculateTax ||(docsClassification?.CalculateTax==null&& invoiceConstraint.IsDocTaxConfirmed)) ? setTotalTaxRate(tax):setTotalTaxRate(0)

    setRate(firstItem?.ConversionRatio);
    if(withPriceListDetail) setPriceListDetail(data);
    setItemData({
      ...itemData,
      Item: firstItem?.Item ?firstItem?.Item : firstItem?.ItemDescription	,
      ItemId: firstItem?.ItemId,
      Code: firstItem?.ItemCode ?firstItem?.ItemCode : firstItem?.Code,
    });
  };

  const cancel = () => {
    if (sessionStorage.getItem('changingforms') === 'true') {
      Toast('modalclosemessage', 'warning', 'alertWithoutClose', () => {
        sessionStorage.setItem('changingforms', 'false');
        setUserChanges({});
        onHide();
      });
    } else {
      onHide();
    }
  };

  useEffect(() => {
    if (resetForm) setResetForm(false);
  }, [resetForm]);

  


  const initializeFormWithSelectedObject = () => {
    setItemData({selectedObj});
    setWarehouseData({
      Id: selectedObj.WarehouseDetailId || selectedObj.Id,
      Title: selectedObj.WarehouseDetail
      ? `${selectedObj.Warehouse},${selectedObj.WarehouseDetail}`
      : selectedObj.Warehouse,
    });

    
    setDetailPriceData({...selectedObj , UnitPrice:selectedObj.SalesUnitId === selectedObj.Unit1Id ? selectedObj?.Unit2Price : selectedObj?.Unit1Price})
    updatePriceListDetails([{...selectedObj,
      SalesUnitMinOrderQty:	0,
      SalesUnitMaxOrderQty:	null
    }] , true)
    // fetchPriceListDetail(selectedObj.ItemId);
  };
  useEffect(() => {
    if (selectedObj && mode !== 'create') {
      initializeFormWithSelectedObject();
    }
  }, []);

  useEffect(() => {
    if (mode==='create' ) {
      updateQuantity(1,[],totalTaxRate);
    }
    
    if (mode !== 'create') {
      updateQuantity(+selectedObj?.['Qty' + numberOfSalesUnit],[],totalTaxRate);
    }
  }, [priceListDetail]);

console.log({currency});


  return (
    <div className="card " style={{ flexDirection: "row" }}>
      {showTree && (
        <ModalForm
          mode={mode}
          title="ItemTree"
          onHide={() => setShowTree(false)}
          show={showTree}
        >
          <ItemTreeForm
            isWarehouseItem={true}
            priceListId={priceList?.Id}
            warehouseId={warehouseData?.WarehouseId}
            warehouseDetailId={warehouseData?.WarehouseDetailId}
            onHide={() => {
              setShowTree(false);
            }}
            itemCodeHandeler={handleItemCode}
          />
        </ModalForm>
      )}
      {showWarehouseTree && (
        <ModalForm
          mode={mode}
          title="warehouses"
          onHide={() => setShowWarehouseTree(false)}
          show={showWarehouseTree}
        >
          <WarehouseTreeForm
            onHide={() => {
              setShowWarehouseTree(false);
            }}
            WarehouseIdHandeler={handleWarehouseSelection}
          />
        </ModalForm>
      )}
      <div style={{flexWrap:"nowrap"}} className="card-body row">
        <div
          className={`card-body ${
            !!priceListDetail.length
              ? "col-12 col-md-7 col-lg-8 col-xl-9"
              : "col-12"
          }`}
        >
          {loading ? (
            <div className="container-fluid">
              <form onSubmit={handleSalesInvoiceFormSubmit}>
                <div className="row">
                  <div className="col-lg-1">
                    <WarehouseDictionary
                      name="Warehouse"
                      label="Warehouse"
                      resetForm={resetForm}
                      value={
                        warehouseData?.WarehouseId
                          ? warehouseData?.WarehouseId
                          : undefined
                      }
                      onChange={(e, obj) => {
                        setWarehouseData({
                          WarehouseId: e,
                          WarehouseTitle: obj?.Name?.Description,
                          WarehouseDetailsCount:
                            obj?.Name?.WarehouseDetailsCount,
                        });
                      }}
                      mode={mode}
                      required={true}
                    />
                  </div>
                  {warehouseData?.WarehouseDetailsCount > 0 && (
                    <div className="col-lg-2">
                      <DictionarySelectBox
                        name="WarehouseDetail"
                        label="WarehouseDetail"
                        params={{ warehouseId: warehouseData?.WarehouseId }}
                        onChange={(e, obj) => {
                          setWarehouseData((prev)=>{return{
                            ...prev,
                            WarehouseDetailId: e,
                            WarehouseDetail: obj?.Name,
                          }});
                        }}
                        baseUrl={INVENTORYANDSALES_URL}
                        endPoint={
                          ENDPOINTSINVENTORYANDSALES.Inventory
                            .BaseInformationApi.WarehouseDetail.dictionary
                        }
                        value={warehouseData?.WarehouseDetailId}
                        resetForm={resetForm}
                        mode={mode}
                      />
                    </div>
                  )}
                  {/*                     <div  className="col-md-4 col-sm-12" style={{display:"flex", alignItems:'center' , flexDirection:"row"}}  >
                        <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}}   textboxName={"WarehouseId"} textboxType="text"  labelText={"Warehouse"} mode={"view"} value={warehouseData?.Title} defaultValue={warehouseData?.Title} />
                        <div  style={{marginTop:"4px", width:"10%"}}>
                            <Button Icon={true} value={<button onClick={(e)=>setShowWarehouseTree(true)} type="button" className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "diagram-project"]}  size="1x"  /></button>}  btnType="primary"onClick={(e)=>setShowWarehouseTree(true)}   />
                        </div>             
                    </div>  */}
                  {warehouseData && (
                    <div
                      className="col-lg-3 col-sm-12"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Textbox
                        required
                        onBlurHandler={(e) => {
                          if (e && e != itemData?.Code) {
                            fetchPriceListDetail(null, e);
                          }
                        }}
                        Change={(e) => {
                          setUserChanges({ ...userChanges, ...e });
                        }}
                        textboxName={"itemCode"}
                        textboxType="text"
                        resetForm={resetForm}
                        labelText={"itemCode"}
                        mode={mode}
                        value={itemData?.Code}
                        defaultValue={itemData?.Code}
                        onChange={(e) => {
                          if (
                            e?.target.value === null ||
                            e?.target.value === ""
                          ) {
                            setDetailPriceData({
                              Price: 0,
                              Discount: 0,
                              SubTotalPrice: 0,
                              Qty1: mode != "create" ? selectedObj?.Qty1 : 1,
                              Qty2: mode != "create" ? selectedObj?.Qty2 : 1,
                            });
                            setItemData({});
                            setRate(null);
                          }
                        }}
                      />
                      <div style={{ marginTop: "4px", width: "10%" }}>
                        <Button
                          Icon={true}
                          value={
                            <button
                              onClick={(e) => setShowTree(true)}
                              type="button"
                              className="checkButton d-flex align-items-center justify-content-center"
                            >
                             
                              <FontAwesomeIcon
                                icon={[ICON_TYPE, "diagram-project"]}
                                size="1x"
                              />
                            </button>
                          }
                          btnType="primary"
                          onClick={(e) => setShowTree(true)}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-lg-4">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Item"
                      textboxType="text"
                      labelText="ItemDescription"
                      value={itemData?.Item}
                      defaultValue={itemData?.Item}
                      resetForm={resetForm}
                      mode={"view"}
                      required={false}
                    />
                  </div>

                  <div className="col-lg-2">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="ItemTechnicalCode"
                      textboxType="text"
                      labelText="TechnicalCode"
                      value={detailPriceData.ItemTechnicalCode + ""}
                      defaultValue={
                        detailPriceData?.ItemTechnicalCode
                          ? detailPriceData?.ItemTechnicalCode
                          : null
                      }
                      resetForm={resetForm}
                      mode="view"
                      useGroupingPrice={true}
                      required={false}
                    />
                  </div>
                </div>

                <div className="row ">
                  <div className="col-lg-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Qty1"
                      textboxType="number"
                      labelText="Qty1"
                      //   add
                      readonly={numberOfSalesUnit != 1}
                      value={
                        detailPriceData?.Qty1
                          ? detailPriceData.Qty1
                          : priceListDetail.length > 0
                          ? priceListDetail[0]?.[
                              numberOfSalesUnit == 1
                                ? "SalesUnitMinOrderQty"
                                : "MinOrderQty"
                            ]
                          : 1
                      }
                      defaultValue={
                        detailPriceData?.Qty1
                          ? detailPriceData.Qty1
                          : priceListDetail.length > 0
                          ? priceListDetail[0]?.[
                              numberOfSalesUnit == 1
                                ? "SalesUnitMinOrderQty"
                                : "MinOrderQty"
                            ]
                          : 1
                      }
                      min={
                        priceListDetail.length > 0
                          ? priceListDetail[0]?.[
                              numberOfSalesUnit == 1
                                ? "SalesUnitMinOrderQty"
                                : "MinOrderQty"
                            ]
                          : 1
                      }
                      max={
                        priceListDetail.length > 0
                          ? priceListDetail[priceListDetail.length - 1]
                              ?.MaxOrderQty == null
                            ? undefined
                            : priceListDetail[priceListDetail.length - 1]
                                ?.MaxOrderQty
                          : undefined
                      }
                      resetForm={resetForm}
                      mode={mode == "view" ? "view" : mode}
                      required={true}
                      onChange={(e) => {
                        if (
                          numberOfSalesUnit == 1 &&
                          +e.target.value >= e.target.min &&
                          (e.target.max
                            ? +e.target.value <= e.target.max
                            : true)
                        )
                        updateQuantity(+e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <UnitDictionary
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      value={detailPriceData?.Unit1Id}
                      label="Unit1"
                      name="Unit1Id"
                      required={false}
                      mode={"view"}
                      resetForm={resetForm}
                      setUnitDictionary={(e) => {
                        setDictionaryName((pre) => {
                          return { ...pre, Unit1: e.Value };
                        });
                      }}
                    />
                  </div>
                  <div className="col-lg-3 col-11">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName={
                        numberOfSalesUnit == 1 ? "UnitPrice" : "UnitPrice1"
                      }
                      textboxType="text"
                      labelText="UnitPrice"
                      value={detailPriceData?.UnitPrice}
                      decimals={currency?.PriceRoundingPlace}
                      defaultValue={
                        detailPriceData?.[
                          numberOfSalesUnit == 1
                            ? "SalesUnitPrice"
                            : "UnitPrice"
                        ]
                      }
                      resetForm={resetForm}
                      mode={((priceList.IsManual&&numberOfSalesUnit == 1) &&mode!="view"  ) ? "create" : "view"}
                      useGroupingPrice={true}
                      onChange={(e) => {
                        if (numberOfSalesUnit == 1)
                          setDetailPriceData({
                            ...detailPriceData,
                            UnitPrice: +e.target.value.replaceAll(",", ""),
                            SubTotalPrice:
                              +e.target.value.replaceAll(",", "") *
                              detailPriceData.Qty1,
                          });
                      }}
                      required={false}
                    />
                  </div>
               <div className="col-lg-3">
                  {numberOfSalesUnit == 1 && (
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="SubTotalPrice"
                      textboxType="text"
                      labelText="مبلغ"
                      value={detailPriceData?.SubTotalPrice}
                      decimals={currency?.PriceRoundingPlace}
                      round={false}
                      defaultValue={
                        detailPriceData?.SubTotalPrice
                          ? detailPriceData?.SubTotalPrice
                          : null
                      }
                      resetForm={resetForm}
                      mode={(priceList.IsManual&&mode!="view" ) ? "create" : "view"}
                      useGroupingPrice={true}
                      required={false}
                    />
                  )}

</div>
                </div>
                <div className="row">
                  {detailPriceData?.Unit2Id ? (
                    <>
                      <div className="col-lg-3">
                        <Textbox
                          Change={(e) => {
                            setUserChanges({ ...userChanges, ...e });
                          }}
                          textboxName="Qty2"
                          textboxType="number"
                          labelText="Qty2"
                          readonly={numberOfSalesUnit != 2}
                          defaultValue={
                            mode != "create"
                              ? selectedObj?.Qty2+""
                              : detailPriceData.Qty2+""
                          }
                          min={Math.ceil(
                            priceListDetail.length > 0
                              ? priceListDetail[0]?.[
                                  numberOfSalesUnit == 2
                                    ? "SalesUnitMinOrderQty"
                                    : "MinOrderQty"
                                ]!=0?priceListDetail[0]?.[
                                  numberOfSalesUnit == 2
                                    ? "SalesUnitMinOrderQty"
                                    : "MinOrderQty"
                                ]:1
                              : 1
                          )}
                          max={
                            priceListDetail.length > 0
                              ? priceListDetail[priceListDetail.length - 1]
                                  ?.MaxOrderQty == null
                                ? undefined
                                : Math.ceil(
                                    priceListDetail[
                                      priceListDetail.length - 1
                                    ]?.[
                                      numberOfSalesUnit == 2
                                        ? "SalesUnitMaxOrderQty"
                                        : "MaxOrderQty"
                                    ]
                                  )
                              : undefined
                          }
                          resetForm={resetForm}
                          mode={mode}
                          required={false}
                          onChange={(e) => {
                            if (
                              numberOfSalesUnit == 2 &&
                             ( +e.target.value >= e.target.min ||
                              (e.target.max  ? +e.target.value <= e.target.max : true))
                            )
                            updateQuantity(+e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-3">
                        <UnitDictionary
                          Change={(e) => {
                            setUserChanges({ ...userChanges, ...e });
                          }}
                          value={
                            mode != "create"
                              ? selectedObj?.Unit2Id
                              : detailPriceData?.Unit2Id
                          }
                          label="Unit2"
                          name="Unit2Id"
                          required={false}
                          mode={"view"}
                          resetForm={resetForm}
                          setUnitDictionary={(e) =>
                            setDictionaryName((pre) => {
                              return { ...pre, Unit2: e.Value };
                            })
                          }
                        />
                      </div>
                      <div className="col-lg-3 col-11">
                        <Textbox
                          Change={(e) => {
                            setUserChanges({ ...userChanges, ...e });
                          }}
                          textboxName={
                            numberOfSalesUnit == 2 ? "UnitPrice" : "UnitPrice1"
                          }
                          textboxType="text"
                          labelText="UnitPrice"
                          value={
                            detailPriceData?.[
                              numberOfSalesUnit == 2
                                ? "SalesUnitPrice"
                                : "UnitPrice"
                            ]
                          }
                          decimals={currency?.PriceRoundingPlace}
                          defaultValue={
                            detailPriceData?.[
                              numberOfSalesUnit == 2
                                ? "SalesUnitPrice"
                                : "UnitPrice"
                            ]
                          }
                          resetForm={resetForm}
                          mode={(priceList.IsManual&&mode!="view" ) ? "create" : "view"}
                          useGroupingPrice={true}
                          onChange={(e) => {
                            if (numberOfSalesUnit == 2){

                              setDetailPriceData({
                                ...detailPriceData,
                                 SalesUnitPrice: +e.target.value.replaceAll(",", ""),
                                UnitPrice: +e.target.value.replaceAll(",", "")/ rate,
                                SubTotalPrice:
                                  +e.target.value.replaceAll(",", "") *
                                  detailPriceData.Qty2,
                              });
                              setPriceListDetail(pre=>{return [{
                                ...pre[0],
                                SalesUnitPrice: +e.target.value.replaceAll(",", ""),
                                UnitPrice: +e.target.value.replaceAll(",", "")/ rate,
                                }]});
                            }
                          }}
                          required={false}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="col-lg-2">
                    {numberOfSalesUnit == 2 && (
                      <Textbox
                        Change={(e) => {
                          setUserChanges({ ...userChanges, ...e });
                        }}
                        textboxName="SubTotalPrice"
                        textboxType="text"
                        labelText="مبلغ"
                        value={detailPriceData?.SubTotalPrice}
                        decimals={currency?.PriceRoundingPlace}
                        round={false}
                        defaultValue={
                          detailPriceData?.SubTotalPrice
                            ? detailPriceData?.SubTotalPrice
                            : null
                        }
                        resetForm={resetForm}
                        mode={(priceList.IsManual&&mode!="view" ) ? "create" : "view"}
                        useGroupingPrice={true}
                        required={false}
                      />
                    )}
                  </div>
                </div>

                <div className="row justify-content-end">
                  <div className="col-lg-2">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="DiscountPercent"
                      textboxType="text"
                      labelText="DiscountPercent"
                      value={detailPriceData?.DiscountPercent + ""}
                      onChange={(e) =>
                        setDetailPriceData({
                          ...detailPriceData,
                          DiscountPercent: +e.target.value,
                          Discount:
                            (+e.target.value * detailPriceData?.SubTotalPrice) /
                            100,
                          Price:
                            detailPriceData?.SubTotalPrice -
                            (+e.target.value * detailPriceData?.SubTotalPrice) /
                              100,
                        })
                      }
                
                        defaultValue={
                          detailPriceData?.DiscountPercent
                          ?detailPriceData?.DiscountPercent+'':
                          detailPriceData?.DiscountPercent==0 ?'0':
                          ''}
                      
                      resetForm={resetForm}
                      mode={(priceList.IsManual&&mode!="view" ) ? "create" : "view"}
                      useGroupingPrice={true}
                      required={false}
                      max={100}
                    />
                  </div>
                  <div className="col-lg-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Discount"
                      textboxType="text"
                      labelText="Discount"
                      value={detailPriceData?.Discount}
                      defaultValue={
                        detailPriceData?.Discount
                        ?detailPriceData?.Discount+'':
                        detailPriceData?.Discount==0 ?'0':
                        ''}
                      resetForm={resetForm}
                      decimals={currency?.PriceRoundingPlace}
                      round={false}
                      onChange={(e) => {
                        setDetailPriceData({
                          ...detailPriceData,
                          Price:
                            detailPriceData?.SubTotalPrice -
                            +e.target.value.replaceAll(",", ""),
                          Discount: +e.target.value.replaceAll(",", ""),
                          DiscountPercent:
                            (+e.target.value.replaceAll(",", "") /
                              detailPriceData?.SubTotalPrice) *
                            100,
                        });
                      }}
                      mode={(priceList.IsManual&&mode!="view" ) ? "create" : "view"}
                      useGroupingPrice={true}
                      required={false}
                    />
                  </div>
                  
                </div>
                <div className="row justify-content-end">
                <div className="col-lg-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Price"
                      textboxType="text"
                      labelText="مبلغ  کل"
                      value={detailPriceData.Price + ""}
                      defaultValue={detailPriceData?.Price}
                      resetForm={resetForm}
                      decimals={currency.PriceRoundingPlace}
                      round={false}
                      mode={(priceList.IsManual&&mode!="view" ) ? "create" : "view"}
                      useGroupingPrice={true}
                      required={false}
                    />
                  </div></div>
          { (docsClassification?.CalculateTax ||(docsClassification?.CalculateTax==null&& invoiceConstraint.IsDocTaxConfirmed)) &&    <div className="row justify-content-end">
              
                  <div className="col-lg-3">
                    <Textbox
                    useGroupingPrice
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Tax"
                      textboxType="text"
                      labelText="Tax"
                      decimals={currency?.PriceRoundingPlace}
                      defaultValue={
                        detailPriceData?.TaxRate
                          ? (+detailPriceData?.TaxRate *
                              +detailPriceData?.Price) /
                              100 +
                            ""
                          : "0"
                      }
                      value={
                        detailPriceData?.TaxRate
                          ? (+detailPriceData?.TaxRate *
                              +detailPriceData?.Price) /
                              100 +
                            ""
                          : "0"
                      }
                      resetForm={resetForm}
                      mode={(priceList?.IsManual&& mode!=='view')?'create':"view"}
                      required={false}
                    />
                  </div>
                </div>}
                <div className="row justify-content-end">
                <div className="col-lg-3">

                <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="GrandTotalPrice"
                      textboxType="text"
                      labelText="مبلغ قابل پرداخت"
                      value={detailPriceData?.TaxRate? +detailPriceData.Price+(+detailPriceData?.TaxRate *
                        +detailPriceData?.Price) /
                      100 + "":detailPriceData?.Price?detailPriceData?.Price+'':''}
                      defaultValue={detailPriceData?.TaxRate? +detailPriceData.Price+(+detailPriceData?.TaxRate *
                        +detailPriceData?.Price) /
                      100 + "":detailPriceData?.Price?detailPriceData?.Price+'':''}
                      resetForm={resetForm}
                      decimals={currency?.PriceRoundingPlace}
                      round={false}
                      mode={(priceList.IsManual&&mode!="view" ) ? "create" : "view"}
                      useGroupingPrice={true}
                      required={false}
                    />
                </div>
                </div>

                <div className="row justify-content-end">
                  {detailPriceData?.HasProductTracking && (
                    <>
                      <div className="col-lg-2">
                        <Textbox
                          Change={(e) => {
                            setUserChanges({ ...userChanges, ...e });
                          }}
                          textboxType="text"
                          textboxName="PartyNo"
                          labelText="PartyNo"
                          defaultValue={
                            mode != "create" ? selectedObj?.PartyNo : null
                          }
                          resetForm={resetForm}
                          mode={mode == "view" ? "view" : mode}
                          required={false}
                        />
                      </div>
                      <div className="col-lg-2">
                        <Textbox
                          Change={(e) => {
                            setUserChanges({ ...userChanges, ...e });
                          }}
                          textboxName="CargoWeight"
                          textboxType="number"
                          labelText="CargoWeight"
                          defaultValue={
                            mode != "create" ? selectedObj?.CargoWeight : null
                          }
                          resetForm={resetForm}
                          mode={mode == "view" ? "view" : mode}
                          required={false}
                        />
                      </div>
                    </>
                  )}
                  {detailPriceData?.HasExpireDate && (
                    <div className="col-lg-2">
                      <Datepicker
                        mode={mode == "view" ? "view" : mode}
                        lable="ExpireDate"
                        setIsoTime={(isoTime: string) => {
                          setExpireDate(isoTime);
                        }}
                        val={mode != "create" ? selectedObj?.ExpireDate : null} //resetForm ? new Date().toISOString() :
                        errorFlag={false}
                        Change={(e) => {
                          setUserChanges({ ...userChanges, ...e });
                        }}
                        resetForm={resetForm}
                        required={false}
                      />
                    </div>
                  )}

                  <div className="col">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxType="textarea"
                      textboxName="Description"
                      minHeight={1}
                      labelText="Remarks"
                      defaultValue={
                        mode != "create" ? selectedObj?.Description : null
                      }
                      resetForm={resetForm}
                      mode={mode == "view" ? "view" : mode}
                      required={false}
                      maxLength={150}
                    letterCounter={true}
                    />
                  </div>
                </div>
                <>
                  {mode != "view" ? (
                    <div className="col-lg-12 mt-1 btns_form">
                  {  mode ==='create' &&  <Button value="save" btnType="submit" />}
                      <Button
                        value="save&close"
                        btnType="submit"
                        onClick={() => {
                          setSaveExit(true);
                        }}
                      />
                      <Button
                        btnType="cancel"
                        value="Cancel"
                        onClick={() => {
                          cancel();
                        }}
                      />
                    </div>
                  ) : (
                    <div className="col-lg-12 mt-1 btns_form">
                      <Button
                        btnType="primary"
                        value="close"
                        onClick={() => {
                          cancel();
                        }}
                      />
                    </div>
                  )}
                </>
              </form>
            </div>
          ) : (
            <Loading />
          )}
        </div>
        {(!!priceListDetail.length && (priceList?.HasDetailRange &&(mode==='create' || (mode==='edit' &&showPriceListDetail))) ) &&(
          <PriceDetails priceListDetail={priceListDetail} />
        )}
      </div>
    </div>
  );
};
