import { useEffect, useState } from "react";
import { putArchiveData, postArchiveData, getArchiveDataWithId } from "./api";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { Button } from "components/Button/Button";
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";


interface props {
    showForm: () => void
    selectedObj: any
    mode: any

}


export const ArchiveForm = ({showForm, selectedObj, mode}: props) => {
    let gridColumns: any[] = []; let gridData: any[] = [];
    const [response, setResponse] = useState<Response | any>(null);

    const [resetForm, setResetForm] = useState(false);
    const [EditForm, setEditForm] = useState<any | null>(null);
    const [loading, setLoading] = useState(false);
    const [userChanges, setUserChanges] = useState({});

    

    const handleSubmit = (e) => {
        e.preventDefault();
        if (mode === 'create') {
            postArchiveData({
                Title: e.target.Title.value,
            })
            .then(() => {sessionStorage.setItem("changingforms" , "false");setUserChanges({}); setResetForm(true);showForm() });
        } else if (mode === 'edit') {
            putArchiveData({ 
                Id: selectedObj.Id,
                Title: e.target.Title.value, 
                ActivityStatus: parseInt(e.target.ActivityStatus.value),
            })
            .then(() => { sessionStorage.setItem("changingforms" , "false");setUserChanges({}) ; setResetForm(true); setEditForm(null) ;showForm()}) 
     
        }

    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                setEditForm(null);
                showForm()
            });
        }else{
            setEditForm(null);
            showForm() 
        };
    }


    async function getArchiveDataAsync() {
        setLoading(false)
        try {
           const res = await getArchiveDataWithId(selectedObj.Id);
           setEditForm(res.Data);
      
        } catch (error) {
        }
        setLoading(true)
    }
    

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        if (resetForm) setResetForm(false);
    }, [resetForm]);


    useEffect(() => {
        if(mode!="create")
            getArchiveDataAsync()
        else setLoading(true)

    }, []);







    if (response?.length > 0) {
        gridColumns = Object.keys(response[0]);
        gridData = response;
    }


    return (
        <>
            <div className="card">
                <div className="">
                    {loading ?

                               <form className="TypeSettingForm row card-body " onSubmit={handleSubmit}>
                                   <div className="col-md-4 col-sm-12">
                                       <Textbox
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            required={true} 
                                            textboxName={"Title"}
                                            textboxType="text" 
                                            resetForm={resetForm}
                                            labelText={"Title"} 
                                            mode={mode === "view" ? "view" : null}
                                            defaultValue={EditForm?.Title} 
                                        />
                                   </div>
                                   {mode!="create" && 
                                   <div className="col-md-4 col-sm-12">
                                        <EnumerationTypeSelect 
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            name="ActivityStatus"
                                            label="ActivityStatus"
                                            enumTypeSelect="ActivityStatus"
                                            value={EditForm?.ActivityStatus}
                                            mode={mode === "view" ? "view" : null}
                                        />
                                    </div>
                                   }
                                   {mode !== 'view' &&
                                        <div className="d-flex justify-content-end my-2">
                                            <Button value="submit" btnType="submit"  />
                                            <Button value="cancel" btnType="cancel" onClick={()=>cancel()}  />
                                        </div>
                                   }
                               </form>
                        : <Loading />
                    }
                </div>
            </div>
        </>
    );
}