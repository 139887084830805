import { MEETINGMANAGEMENT_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSMEETINGMANAGEMENT } from "utils/endpoints";
import { http } from "utils/http";

export const getMeetingClassificationList = (async (searchString, orderBy,pageNum ,pageSize) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.BaseDefinitionAndSettingsApi.MeetingClassification.list,
    method: 'get',
    params: {
        searchString: searchString,
        orderBy: orderBy,
        PageSize: pageSize,
        PageNumber: pageNum,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
})

)


export const getMeetingClassification = (async (id) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.BaseDefinitionAndSettingsApi.MeetingClassification.load,
    method: 'get',
    params: {Id : id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)


export const deleteMeetingClassification =  (id) =>  http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.BaseDefinitionAndSettingsApi.MeetingClassification.browse,
    method: 'delete',
    params: {Id : id}

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
})


export const postMeetingClassification = (data) => http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.BaseDefinitionAndSettingsApi.MeetingClassification.browse,
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const putMeetingClassification = (data) => http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.BaseDefinitionAndSettingsApi.MeetingClassification.browse,
    method: 'put',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
    return Promise.reject( err);
})

