import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";
import { ENDPOINTS } from "utils/endpoints";

export const getPeymentMethod = async (Id) =>
  await http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.PaymentMethod
      .load,
    method: "get",
    params: { Id: Id },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const postPeymentMethodApi = (data) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.PaymentMethod
      .browse,
    method: "post",
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const putPeymentMethodApi = (data) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.PaymentMethod
      .browse,
    method: "put",
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getPeymentMethodDictionary = async () =>
  await http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.PaymentMethod
      .dictionary,
    method: "get",
  }).then((response) => response.data);

export const deleteListPeymentMethod = (id) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.PaymentMethod
      .browse,
    method: "delete",
    params: {
      id: id,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getListPeymentMethod = async (
  searchString,
  orderBy,
  pageNum,
  pageSize
) =>
  await http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.PaymentMethod
      .browse,
    method: "get",
    params: {
      searchString: searchString,
      orderBy: orderBy,
      PageSize: pageSize,
      PageNumber: pageNum,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
