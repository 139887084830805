import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useRef, useState } from "react";
import { OrganizationType } from "types/enum";
import { trans } from "utils/helpers";
import { getCustomerPositionDictionaryApi, getUserPositions } from "./api";
import { ModalForm } from "components/ModalForm/ModalForm";
import { PersonGrid } from "components/Person/PersonGrid";
import { Button } from "components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ICON_TYPE } from "utils/constants";

interface CustomerPositionPropsType {
  label?: string,
  hasBorder?: boolean,
  resetForm?: boolean,
  required?: boolean,
  value?: string,
  mode?: string,
  name: string,
  Change?: (e) => void,
  onChange?: (e) => void,
}

export const CustomerPositionDictionary = ({ label, hasBorder, value, mode ,resetForm,required , name="", Change ,onChange}: CustomerPositionPropsType) => {
  const [CustomerPosition, setCustomerPositions] = useState<any>();
  const [valueName, setValueName] = useState<any>();
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [validat , setValidat] = useState(false);
  const [count,setCount] = useState<number>(0);
  const inputRef = useRef<any>();
  const inputIdRef = useRef<any>();
  const CustomerPositionRef = useRef<any>(CustomerPosition);
  const validRef = useRef<any>(false);
  const countRef = useRef<any>(0)
  const [showSerch , setShowSerch] = useState(false);
  const [personsList, setPersonsList] = useState<any>();
  const PersonRef = useRef<any>(personsList);

  let optionList : any =[]

  const layoutContext = useContext(LayoutContext);

  const personIdCallBack =async (e)=>{
    const lang = localStorage.getItem("lang");
    try {
      document.body.classList.add("loading-indicator-"+lang);
      const res = await getUserPositions(e)

      setShowSerch(false)
      inputIdRef.current.value=res.Data?.[0]?.Id
      getCustomerPositionListAsync(null,res.Data?.[0]?.Id)
      document.body.classList.remove("loading-indicator-"+lang);
    } catch (error) {
      document.body.classList.remove("loading-indicator-"+lang);
    }

  }





  async function getCustomerPositionListAsync(search, id) {
    
    try {
    const res = await getCustomerPositionDictionaryApi(search, id,(layoutContext.currentUserPosition.OrganizationType == OrganizationType.Customers||mode=="view"));
    if(Object.keys(res).length>0){
      if (id) {
        setValeu(res[id] , id)
        setValueName(res[id])
        onChange && onChange({Name:res[id] , Id : id})
      }

    }else{
      onChange && onChange(null)
    }
    setCustomerPositions(res)     
    CustomerPositionRef.current = res
    setFlag(!flag)
  } catch (err: any) {
    onChange && onChange(null)

    }

  }

  const Auto = (e) => {
    validRef.current = false
    setValidat(false)
    setShow(true)
    getCustomerPositionListAsync(e.target.value, "")
    setFlag(!flag)

  }

  function userSelecter(){
    setValeu(optionList[countRef.current],Object.keys(CustomerPosition)[countRef.current])
    setShow(false)
  }

  const kydown = (event) => {    
    if((event.key === "ArrowDown")){
      if(countRef.current < optionList.length-1){
        countRef.current = countRef.current + 1;
      }else{
        countRef.current = 0;
      }
    }
    
    if(event.key === "ArrowUp"){
      if(countRef.current > 0){
        countRef.current = countRef.current -1;
      }else{
        countRef.current = optionList.length-1;
      }
    }
    document.getElementById("CustomerPositionIndex"+countRef.current)?.scrollIntoView({block:'end',behavior:"smooth"})
    setCount(countRef.current)

    if(event.key === "Enter"){
      userSelecter()
    }
  }
  const CheckCustomerPosition =async (e)=>{
    
    setTimeout(() => {
       
      if(!validRef.current){
        
        if(CustomerPosition!=undefined &&  Object.values(CustomerPosition).length == 1){
          setValeu(Object.values(CustomerPosition)[0] , Object.keys(CustomerPosition)[0])
          e.target.setCustomValidity("");
        }else{
          if(inputRef?.current){
            onChange && onChange(null)
         // setValeu(null,null)
          }
          if(inputRef?.current?.value.length >0 && inputRef?.current?.value!=""){
          //e.target.setCustomValidity(trans("Msg_Validation_ValueIsIncorrect"));
          //setValidat(true);
          //e.target.reportValidity();
        }
        setCustomerPositions(null);
        }
      }
    }, 200);
    await delay(200)
    setShow(false)
  }
  const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));




  const setValeu = (newValue, id) => {
    if(inputIdRef != null && inputIdRef.current != null){
      validRef.current = true
      inputRef.current.value = newValue
      inputIdRef.current.value = id
      CustomerPositionRef.current = null;
      ((value==null &&id)|| id != value) ? Change&&Change({[name] :id}):Change&&Change({[name]:null});
      onChange && onChange({Name:newValue , Id : id})
      setCustomerPositions(null)
      setFlag(!flag)
      setCustomerPositions({[inputIdRef.current.value]:inputRef.current.value})
      setValidat(false)
    }
  }
  useEffect(() => {
    if(value){
      getCustomerPositionListAsync(null,value)
     }else if(value == null){
      setValueName(null)


     }
  }, [value,resetForm]);


  useEffect(() =>{
    if(show ){
      document.addEventListener('keydown', kydown);
      document.body.addEventListener("click", clickToclose)

    } 
     return () => { document.removeEventListener("keydown", kydown);document.body.removeEventListener('click', clickToclose) }

    
  },[show ,optionList])

  
  function clickToclose(e){
    let input = document.querySelector("#browser"+name);
    if (e.target.id !== input?.id) { 
      setShow(false);
    }
  }



  if(CustomerPosition){
    optionList = Object.values(CustomerPosition)
  }
  return (
    <>

          {showSerch &&
          <ModalForm modalHeight={49} mode={"view"} onHide={()=>{setShowSerch(false)}} show={showSerch} title={"person" } >
                <PersonGrid personIdCallBack={personIdCallBack} serch={inputRef.current.value} />
            </ModalForm>  
          }
    <div className="text-box">
      <OverlayTrigger
             key={label}
             placement="top"
             delay={{"show" : 600 , "hide":0}}
             overlay={
                <Tooltip className="tooltip " id={`button-tooltip-${"reserve"}`}>
               {trans(label ? label : "CustomerPosition")}
                </Tooltip>
                }>

                 <div className="d-flex align-items-start" style={{height:'22px'}}>
                   <label  htmlFor={`browser${name}`}  className="text-box__label showDots"  style={{maxWidth:'95%'}}>{trans(label ? label : "CustomerPosition")}</label>
                   {required && <span className='text-box__required-sign-for-label text-danger me-1'>*</span>}
                 </div>
          </OverlayTrigger> 
    <input required={required} defaultValue={value && value} name={name} className="d-none" ref={inputIdRef} />
    <div  className="d-flex align-items-center" >
    <input  required={required} onBlur={CheckCustomerPosition} defaultValue={valueName} autoComplete="off" onFocus={(e)=>{  setShow(true);Auto(e)}}  ref={inputRef} list="CustomerPositionList" name="browser" className="text-box__box form-control " type={'text'}    id={"browser"+name} onMouseDown={(e)=>e.stopPropagation() } onChange={Auto} disabled={mode =="view" ? true : false} />

        {mode != 'view' &&
          <Button Icon={true} value={<button type="button" onClick={(e)=>{setShowSerch(true)}} className="checkButton mt-0 d-flex align-items-center justify-content-center" > <FontAwesomeIcon icon={[ICON_TYPE, "search"]} style={{transform:"rotateY(180deg)",fontSize:"15px"}}  size="1x"  /></button>}  btnType="primary"onClick={(e)=>{}}   />
        }
      </div> 
   
    {
       show && 
        <div tabIndex={-1} className={`text-box__sub-menu mt-1`} >
          {optionList && optionList.map((item , index)=>{
                 return  <div id={"CustomerPositionIndex"+index} className={`${count == index ?"text-box__sub-menu-option-active" : "text-box__sub-menu-option"}`} onClick={()=>{setValeu(item,Object.keys(CustomerPosition)[index])}} key={Object.keys(CustomerPosition)[index]} >{item}</div>
           })}  
        </div>
     }  
      <div className="text-box__msg-wrapper">
       {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
       </div>    
    </div>
    </>
   )
}