import { Widget } from 'components/Widget/Widget'
import { getEvets } from 'pages/DashboardPage/api/api'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'
import { LayoutContext } from 'layouts/DashboardLayout/context/LayoutContext'
import { OrganizationType } from 'types/enum'


interface props {
    id: string
}

export const WidgetCalendar = ({id}:props) => {
    const [events, setevents] = useState<any>()
    const layoutContext = useContext(LayoutContext);

    async function getEvetsAsync() {
        try {
        const res = await getEvets()
        setevents(res.Data)            
        } catch (error) {
            setevents([])
        }
    }
    useEffect(() => {
        if(layoutContext?.currentUserPosition?.OrganizationType!=OrganizationType.Customers)
        getEvetsAsync()
    }, [])
    return (
        <>
        {(events&&events.length > 0)&&

        <Widget title='events' icon={['fas', 'calendar']} color='danger' id={id}  >
            <div className='widget-calendar p-2'>
                <ul>
                    {events ?
                    <>
                    {events.length > 0 ?
                      <>
                         {
                          events.map((item, index) => (
                              <div className={`widget-calendar__list d-flex justify-content-between ${item.CalendarEventType==0 ?"text-warning" : item.CalendarEventType==4 ?"text-danger": ""}`}>
                                  <Link to={'/personal-planing/personal-calendar'}>
                                          <li  style={{fontSize: "13px"}} className='mb-1' key={index}>{item.Description}</li>
                                  </Link>
                                  <Link to={'/personal-planing/personal-calendar'}>
                                          <p style={{fontSize: "13px"}}  className='mb-1' key={index}>{item.CalendarEventTime}</p>
                                  </Link>

                              </div>
                          ))
                        }
                        </>
                        :
                        <></>

                    }
                    </>
                        :
                        <div className="d-flex justify-content-center align-items-center text-info h-100">
                           <div className="spinner-border spinner-border-md" role="status"></div>
                        </div>
                    }
                </ul>
            </div>
        </Widget>
                }
                </>
    )
}