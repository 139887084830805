import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getRate = () => http({
    url: ENDPOINTS.enumeration,
    method: 'get',
    params: {
        enumerationType: "Rate",
        
    },
}).then((res) => {
    return res.data
}).catch((err) => {
    //  
    return Promise.reject(err);
});
export const sendDeliverForm = (data ,method  ) => http({
    url: ENDPOINTS.cartable.jobDelivery.load,
    method: method,
    data:data
}).then((res) => {
    return res.data
}).catch((err) => {
    //  
    return Promise.reject(err);
});
export const getDeliverForm = (Id , taskId ) => http({
    url: ENDPOINTS.cartable.jobDelivery.load,
    headers: { "Accept-Language": "en" },

    method: "get",
    params:{
        TAS_UID:taskId ,
        jobId : Id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    //  
    return Promise.reject(err);
});
