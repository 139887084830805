
import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toast } from "components/ToastShow/ToastShow";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { SelectBox } from "components/Select/Select";
import { Textbox } from "components/Textbox/Textbox";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { deletePersonTitlesApi, getPersonTitlesApi, getTitleId, sendPersonTitlesApi } from "./api";
import { downloadCSV, trans } from "utils/helpers";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";




interface Form {
    Title: string,
    Status: number
}
export const PersonTitlesPage = ({ Cancelcallback }) => {


    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState<object | any>(null);
    const [mode, setMode] = useState<string | any>("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [PersonTitle, setPersonTitle] = useState<any>();
    const [PersonType, setPersonType] = useState("PersonLegalTitle");
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [userChanges, setUserChanges] = useState({});
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});



    const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }

    const selectedIdCallback = (obj) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false"); setUserChanges({}) });
        }else{
            setSelectedObj(obj)         
        };
    }

    async function getPersonTitlesAsync() {
        setLoading(false)
        try {
            const res = await getPersonTitlesApi(PersonType ,search ,orderBy.length == 0 ?null:  orderBy.join(","));
            setResponse(res.Data);
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
            
        }
      setLoading(true)
    }
    async function getPersonTitlebyId() {
        setFormLoading(false)
        try {
            const res = await getTitleId(PersonType , selectedObj?.Id);
            setPersonTitle(res.Data)           
        } catch (error) {
            
        }
        setFormLoading(true)
    }

    const handleDeleteRow = async() => {
        try {
            await deletePersonTitlesApi(PersonType , selectedObj?.Id  )

            setForceUpdate(!forceUpdate)   
       
        } catch (error) {
            
        }

    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                setShowForm(false)
            });
        }else{
            setShowForm(false)     
        }
    }


    async function sendTitlesAsync(url ,data,method ) {
        try {
      const res = await sendPersonTitlesApi(url ,data,method );

        setResetForm(true)        
            
        sessionStorage.setItem("changingforms" , "false");
        setUserChanges({});
        setShowForm(false);
        } catch (error) {
            

        }

    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };

    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        setResponse(null)
        getPersonTitlesAsync();
    }, [PersonType ,resetForm ,forceUpdate  ,search , orderBy]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm ]);
    useEffect(() => {
        if (showForm) {
            if(selectedObj && mode != "create"){
                getPersonTitlebyId()  
            }else{
                setFormLoading(true)
            }
         
        }
    }, [showForm ,selectedObj,mode]);


    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,1 ,trans("PersonTitlesPage"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": setMode("create");setResetForm(true); setShowForm(true);setPersonTitle(null); break;
            case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "view": if (selectedObj?.Id) { handleViewRow() }; break;
            case "edit": if (selectedObj?.Id) { handleEditRow() }; break;
            case "help": alert("call api help"); break;
        }
    };

    const handleViewRow = ()=>{
        setMode("view"); 
        setShowForm(true);
    }

    const handleEditRow = ()=>{
        setMode("edit"); 
        setShowForm(true); 
    }

    /* #endregion */
    const handlePersonTitleFormSubmit = (event) => {
        event.preventDefault();

        if(mode=="edit"){
            let data={
                    "Id": selectedObj.Id,
                    "Title": event.target.Title.value,
                    "ActivityStatus": +event.target.ActivityStatus.value
            }
            sendTitlesAsync(PersonType , data,"put")
            
          }else{
            let data={
                "Title": event.target.Title.value,
               
            }
            sendTitlesAsync(PersonType , data,"post")

        }
    }

    return (
        <>
            <Toolbar remove={true} handleSearch={searchHandler} emptyList={response == null || response?.length == 0} id={selectedObj?.Id} create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} handleSelectedFilterPersonType={setPersonType}/>
            <div className="card">
                <div className="">
                    {showForm &&
                        <div className="person-contact-form card">
                        <div className="card-body">
                            <div className="container-fluid">
                                {formLoading ? 
                                    <form className="row " onSubmit={handlePersonTitleFormSubmit}>
                                    <div className="col-lg-6 mt-1">
                                    <SelectBox
                                        onChange={(e)=>{setPersonType(e.target.value)}}
                                         selectType="select"
                                         options={["PersonLegal" ,"PersonReal"]}
                                         val={["PersonLegalTitle" ,"PersonRealTitle"]}
                                         value={PersonType}
                                         name={"Person"}
                                         lable={"PersonTitles"}
                                         resetForm={resetForm}
                                         mode={mode=="edit" ?"view":mode}
                                       ></SelectBox>
                                    </div>
                                    <div className="col-lg-6 mt-1">
                                        <Textbox
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        required={true}
                                            textboxType="text"
                                            textboxName="Title"
                                            labelText="Title"
                                            maxLength={900}
                                            defaultValue={PersonTitle?.Title ? PersonTitle?.Title : "" }
                                            resetForm={resetForm}
                                            mode={mode}
                                        />
                                    </div>
                                    <div className="col-lg-6 mt-1">
                                        {mode != "create" &&
                                            <EnumerationTypeSelect
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            required={true}
                                            name="ActivityStatus"
                                            label="Status"
                                            enumTypeSelect="ActivityStatus"
                                            value={PersonTitle?.ActivityStatus ? PersonTitle?.ActivityStatus : ""}
                                            mode={mode}
                                            />
                                        }
                                    </div>
                            
                                    {  mode !="view" ?
                                        <div className="col-lg-12 mt-1 btns_form">
                                        <Button value="Save" btnType="submit" />
                                        <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                                        </div>
                                        :
                                        <div className="col-lg-12 mt-1 btns_form">
                                        <Button btnType="primary" value="Close" onClick={()=>{cancel()}}/>
                                        </div>
                                    }                        
                                </form>
                                :
                                <Loading/>
                                }

                            </div>
                        </div>
                    </div>

                    }
                     {loading ?
                            <>

                                {
                                    loading ?
                                    response?.length > 0 ?
                                        <>
                                           <DataGrid
                                               parentName={"PersonTitlesPage"}
                                               orderFormat={setOrderBy}
                                               orderFormatList={orderBy}                            
                                               startIndex={1}
                                               theadData={Object.keys(response[0])}
                                               tbodyData={response}
                                               minCellWidth={80}
                                               scrollHeight={showForm? "25vh": "67vh"}
                                               selectedRowObjectCallback={selectedIdCallback}
                                               handleEdit={handleEditRow}
                                               handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,()=> handleDeleteRow())}}
                                               handleView={handleViewRow}
                                           />
                                        </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                                :<Loading/>



                                }
                            </>

                            : <Loading />
                        }
                </div>
            </div>
        </>

    );
}