import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  GroupingPriceFunction,
  padWithZeros,
  roundOrTruncate,
  trans,
} from "utils/helpers";
import { REGEX } from "utils/regex";
import "./style.scss";
interface TextboxPropsType {
  labelText?: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  minHeight?: number;
  min?: number;
  max?: number;
  textboxName: string;
  textboxType:
    | "number"
    | "text"
    | "textarea"
    | "email"
    | "tel"
    | "mobile"
    | "userName"
    | "password"
    | "systemKey"
    | "formKey"
    | "nationalCode"
    | "birthCertNo"
    | "econNo"
    | "RegistryNumber"
    | "nationalIdentifier"
    | "PostalCode"
    | "format"
    | "unitCode"
    | "wareHouseCode"
    | "CodeFormat"
    | "Condition";
  defaultValue?: string;
  value?: any;
  ExtraDescription?: any;
  mode?: any;
  readonly?: boolean;
  Change?: (ans: any) => void;
  onChange?: (ans: any) => void;
  setValid?: (ans: any) => void;
  onBlurHandler?: (ans: any) => void;
  resetForm?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  recordedData?: any;
  fixedlabel?: any;
  decimals?: any;
  round?: any;
  useTrans?: boolean;
  useGroupingPrice?: boolean;
  UsepadWithZeros?: boolean;
  capsLock?: boolean;
  fixedLanguage?: "en" | "fa" | null;
  withLabel?: any;
  customClass?:any;
  letterCounter?:any
}
export const Textbox = ({
  withLabel = true,
  fixedLanguage = null,
  useGroupingPrice,
  UsepadWithZeros = false,
  decimals = null,
  round = true,
  capsLock = false,
  onBlurHandler,
  fixedlabel,
  autoFocus,
  useTrans = true,
  recordedData,
  ExtraDescription,
  min,
  max,
  labelText,
  required,
  textboxType,
  minHeight,
  minLength = 0,
  maxLength = 900,
  textboxName,
  value,
  defaultValue = "",
  Change,
  onChange,
  mode,
  readonly = false,
  resetForm,
  setValid,
  placeholder,
  customClass,
  letterCounter
}: TextboxPropsType) => {
  const [TEXTBOX_VAL, setTEXTBOX_VAL] = useState(defaultValue);
  const disabled = mode == "view" ? true : readonly ? true : false;
  let [regexMsgShow, setRegexMsgShow] = useState<boolean>(false);
  let [maxLengthMsgShow, setMaxLengthMsgShow] = useState<boolean>(false);
  let [requiredMsgShow, setRequiredMsgShow] = useState<boolean>(false); // check and delete unnecessary state
  let [count,setCount]=useState(defaultValue?defaultValue.length:0)

  function Checkrequired(event) {
    maxLengthCheck(event);
    if (event.target.value.length == 0 && required) {
      setRequiredMsgShow(true);
    }
    onBlurHandler && onBlurHandler(event.target.value);
    if (UsepadWithZeros && maxLength) {
      setTEXTBOX_VAL(
        padWithZeros(maxLength, event.target.value ? event.target.value : "")
      );
    }
    if (decimals != null && event.target.value) {
      setTEXTBOX_VAL(
        roundOrTruncate(
          +event.target.value.replaceAll(",", "")
            ? +event.target.value.replaceAll(",", "")
            : "",
          decimals,
          round
        ) + ""
      );
    }
    // setTEXTBOX_VAL(event);
  }

  function checkRegexValidation(event) {
    setRequiredMsgShow(false);
    if(letterCounter){
      setCount(event.target.value.length)
    }
    if (event.target.value != defaultValue) {
      Change &&
        Change({
          [textboxName]:
            event.target.value.length == 0 ? " " : event.target.value,
        });
    } else {
      Change && Change({ [textboxName]: null });
    }
    if (capsLock) {
      event.target.value = event.target.value.toUpperCase();
    }
    let valditaionRegex;
    let validationRegexResult;
    if (textboxType === "number")
      valditaionRegex = new RegExp(REGEX.number.regex);
    else if (textboxType === "text")
      valditaionRegex = new RegExp(REGEX.text.regex);
    else if (textboxType === "password")
      valditaionRegex = new RegExp(REGEX.password.regex);
    else if (textboxType === "textarea")
      valditaionRegex = new RegExp(REGEX.textarea.regex);
    else if (textboxType === "email")
      valditaionRegex = new RegExp(REGEX.email.regex);
    else if (textboxType === "tel")
      valditaionRegex = new RegExp(REGEX.tel.regex);
    else if (textboxType === "mobile")
      valditaionRegex = new RegExp(REGEX.mobile.regex);
    else if (textboxType === "PostalCode")
      valditaionRegex = new RegExp(REGEX.PostalCode.regex);
    else if (textboxType === "systemKey")
      valditaionRegex = new RegExp(REGEX.systemKey.regex);
    else if (textboxType === "formKey")
      valditaionRegex = new RegExp(REGEX.formKey.regex);
    else if (textboxType === "userName")
      valditaionRegex = new RegExp(REGEX.userName.regex);
    else if (textboxType === "nationalCode")
      valditaionRegex = new RegExp(REGEX.nationalCode.regex);
    else if (textboxType === "nationalIdentifier")
      valditaionRegex = new RegExp(REGEX.nationalIdentifier.regex);
    else if (textboxType === "birthCertNo")
      valditaionRegex = new RegExp(REGEX.birthCertNo.regex);
    else if (textboxType === "econNo")
      valditaionRegex = new RegExp(REGEX.econNo.regex);
    else if (textboxType === "RegistryNumber")
      valditaionRegex = new RegExp(REGEX.RegistryNumber.regex);
    else if (textboxType === "format")
      valditaionRegex = new RegExp(REGEX.format.regex);
    else if (textboxType === "unitCode")
      valditaionRegex = new RegExp(REGEX.unitCode.regex);
    else if (textboxType === "wareHouseCode")
      valditaionRegex = new RegExp(REGEX.wareHouseCode.regex);
    else if (textboxType === "CodeFormat")
      valditaionRegex = new RegExp(REGEX.CodeFormat.regex);
    else if (textboxType === "Condition")
      valditaionRegex = new RegExp(REGEX.Condition.regex);
    validationRegexResult = valditaionRegex.test(event.target.value);
    if (!validationRegexResult) {
      setRegexMsgShow(true);
    } else if (validationRegexResult) {
      setRegexMsgShow(false);
    }

    setTEXTBOX_VAL(
      useGroupingPrice
        ? event.target.value.replaceAll(",", "")
        : event.target.value
    );
    onChange && onChange(event);
  }

  function maxLengthCheck(e) {
    e.stopPropagation();
    if (maxLength !== undefined) {
      if (TEXTBOX_VAL && TEXTBOX_VAL?.length > maxLength) {
        // چون ست کردن یوز استیت یک قدم عقب تر است شرط == جواب میدهد
        setMaxLengthMsgShow(true);
        let id = setTimeout(() => {
          setMaxLengthMsgShow(false);
        }, 2000); // هشدار حداکثر طول بعد از 3 ثانیه ناپدید می شود
      }
    }
  }
  useEffect(() => {
    if (UsepadWithZeros && maxLength && defaultValue) {
      setTEXTBOX_VAL(padWithZeros(maxLength, defaultValue));
    } else {
      if (decimals != null && defaultValue) {
        setTEXTBOX_VAL(roundOrTruncate(+defaultValue, decimals, round) + "");
      } else { 
        setTEXTBOX_VAL(defaultValue);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (resetForm) setTEXTBOX_VAL(defaultValue);
  }, [resetForm]);

  useEffect(() => {
    window.addEventListener(
      "keydown",
      (e) => {
        const target = e.target as HTMLElement;
        if (e.key === "Enter" && target.tagName !== "TEXTAREA") {
          e.preventDefault();
        }
      },
      true
    );
    return () => {
      window.removeEventListener(
        "keydown",
        (e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        },
        true
      );
    };
  }, []);
  return (
    <div className="text-box w-100">
      <div className="d-flex w-100 justify-content-between">
        {withLabel && (
          <OverlayTrigger
            key={labelText}
            placement="top"
            delay={{ show: 100, hide: 0 }}
            overlay={
              <Tooltip className="tooltip" id={`button-tooltip-${"checkable"}`}>
                {trans(labelText + "")}
              </Tooltip>
            }
          >
            <div
              className="d-flex  align-items-center"
              style={{ height: "22px", width: "80%" }}
            >
              {labelText && (
                <label className="text-box__label showDots">
                  {useTrans ? trans(labelText) : labelText}
                </label>
              )}
              {required && labelText && (
                <span className="text-box__required-sign-for-label text-danger">
                  *
                </span>
              )}
            </div>
          </OverlayTrigger>
        )}
        {(ExtraDescription ||
          (mode == "edit" &&
            recordedData != undefined &&
            recordedData != TEXTBOX_VAL) ||
          (mode == "create" &&
            recordedData != TEXTBOX_VAL &&
            TEXTBOX_VAL != undefined &&
            TEXTBOX_VAL.length > 0 &&
            recordedData != undefined)) && (
          <>
            <OverlayTrigger
              key={"reserve"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  style={{ direction: "rtl" }}
                  className="tooltip "
                  id={`button-tooltip-${"reserve"}`}
                >
                  {trans("PreviousValue") + " : " + recordedData}
                </Tooltip>
              }
            >
              <span className="d-flex " style={{ maxWidth: "18%" }}>
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={["fas", "circle-info"]}
                  className="text-primary pr-2"
                />
                {!ExtraDescription && (
                  <FontAwesomeIcon
                    onClick={() => {
                      setTEXTBOX_VAL(recordedData);
                      onChange && onChange({ recordedData });
                    }}
                    style={{ marginRight: "5px" }}
                    icon={["fas", "undo"]}
                    className="text-primary pr-2"
                  />
                )}
              </span>
            </OverlayTrigger>
          </>
        )}
      </div>
      <OverlayTrigger
        key={labelText + " " + "value"}
        placement="top"
        offset={[5, 10]}
        delay={{ show: 100, hide: 0 }}
        overlay={
          mode == "view" &&
          TEXTBOX_VAL &&
          TEXTBOX_VAL?.length > 0 &&
          !ExtraDescription ? (
            <Tooltip className="tooltip" id={`button-tooltip-${labelText}`}>
              {TEXTBOX_VAL ? TEXTBOX_VAL : ""}
            </Tooltip>
          ) : (
            <></>
          )
        }
      >
        {textboxType === "textarea" ? (
          <textarea
            autoFocus={autoFocus}
            rows={minHeight ? minHeight : 2}
            onBlur={Checkrequired}
            name={textboxName}
            value={TEXTBOX_VAL ? TEXTBOX_VAL : ""}
            onMouseDown={(e) => e.stopPropagation()}
            className={`text-box__textarea form-control  ${
              required && " required-input"
            } `}
            onChange={checkRegexValidation}
            maxLength={maxLength ? maxLength : 900}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
          ></textarea>
        ) : (
          <input
            autoFocus={autoFocus}
            id={textboxName + "Id"}
            placeholder={placeholder}
            min={min}
            max={max}
            pattern={REGEX[textboxType].regex}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onBlur={Checkrequired}
            autoComplete="off"
            name={textboxName}
            onMouseDown={(e) => e.stopPropagation()}
            value={
              TEXTBOX_VAL
                ? useGroupingPrice
                  ? GroupingPriceFunction(TEXTBOX_VAL)
                  : TEXTBOX_VAL
                : ""
            }
            type={textboxType}
            className={` text-box__box ${
              textboxType == "number" ||
              textboxType == "tel" ||
              useGroupingPrice
                ? " form-control NumberFontSize "
                : "form-control" 
            }   ${required && " required-input"} ${customClass?customClass:''}`}
            onChange={checkRegexValidation}
            maxLength={maxLength}
            minLength={minLength}
            required={required}
            disabled={disabled}
            style={{
              padding: fixedlabel
                ? `5px 5px 5px ${fixedlabel.length * 12}px`
                : "",
              direction:
                textboxType == "email" ||
                textboxType == "systemKey" ||
                textboxType == "formKey" ||
                // textboxType == "number" ||
                fixedlabel ||
                fixedLanguage == "en" ||
                labelText?.includes("EN")
                  ? "ltr"
                  : "unset",
            }}
          />
        )}
      </OverlayTrigger>
      {fixedlabel && (
        <label htmlFor={textboxName + "Id"} className="static-value text-muted">
          {fixedlabel}
        </label>
      )}
      <div className="text-box__msg-wrapper">
        {required && (
          <span
            className={`${
              requiredMsgShow ? "show-msg" : "hide-msg"
            } text-box__required-msg text-danger`}
          >
            {trans("Msg_Validation_ValueIsRequired", [""])}
          </span>
        )}


{
  letterCounter &&
  <span
  className={`${count===maxLength?'text-danger':'text-dark'} show-msg text-box__required-msg `}
>
  {count+'/'+maxLength}&nbsp;{trans('character')}   
</span>
}

        <span
          className={`${
            regexMsgShow ? "show-msg" : "hide-msg"
          } text-box__regex-msg text-danger`}
        >
          {textboxType == "text" && TEXTBOX_VAL
            ? REGEX.text.valditionMsg
            : textboxType == "textarea" && TEXTBOX_VAL
            ? REGEX.textarea.valditionMsg
            : textboxType == "email" && TEXTBOX_VAL !== ""
            ? REGEX.email.valditionMsg
            : textboxType == "tel" && TEXTBOX_VAL !== ""
            ? REGEX.tel.valditionMsg
            : textboxType == "mobile" && TEXTBOX_VAL !== ""
            ? REGEX.mobile.valditionMsg
            : textboxType == "PostalCode" && TEXTBOX_VAL !== ""
            ? REGEX.PostalCode.valditionMsg
            : textboxType == "systemKey" && TEXTBOX_VAL !== ""
            ? REGEX.systemKey.valditionMsg
            : textboxType == "formKey" && TEXTBOX_VAL !== ""
            ? REGEX.formKey.valditionMsg
            : textboxType == "number" && TEXTBOX_VAL !== ""
            ? REGEX.number.valditionMsg
            : textboxType == "userName" && TEXTBOX_VAL !== ""
            ? REGEX.userName.valditionMsg
            : textboxType == "nationalCode" && TEXTBOX_VAL !== ""
            ? REGEX.nationalCode.valditionMsg
            : textboxType == "birthCertNo" && TEXTBOX_VAL !== ""
            ? REGEX.birthCertNo.valditionMsg
            : textboxType == "econNo" && TEXTBOX_VAL !== ""
            ? REGEX.econNo.valditionMsg
            : textboxType == "RegistryNumber" && TEXTBOX_VAL !== ""
            ? REGEX.RegistryNumber.valditionMsg
            : textboxType == "nationalIdentifier" && TEXTBOX_VAL !== ""
            ? REGEX.nationalIdentifier.valditionMsg
            : textboxType == "password" && TEXTBOX_VAL !== ""
            ? REGEX.userName.valditionMsg
            : textboxType == "format" && TEXTBOX_VAL !== ""
            ? REGEX.format.ValidityState
            : textboxType == "unitCode" && TEXTBOX_VAL !== ""
            ? REGEX.unitCode.ValidityState
            : textboxType == "CodeFormat" && TEXTBOX_VAL !== ""
            ? REGEX.CodeFormat.ValidityState
            : textboxType == "Condition" && TEXTBOX_VAL !== ""
            ? REGEX.Condition.ValidityState
            : textboxType == "wareHouseCode" && TEXTBOX_VAL !== ""
            ? REGEX.wareHouseCode.ValidityState
            : null}
        </span>
        {maxLength ? (
          <span
            className={`${
              maxLengthMsgShow ? "show-msg" : "hide-msg"
            } text-box__max-length-msg text-danger`}
          >{`${trans("Msg_Validation_InvalidMaxLength")}`}</span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
