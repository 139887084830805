import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";

interface ItemGroupLabelSliderProps{   
    children?: React.ReactNode | any;
}

export const ItemGroupLabelSlider = ({children}:ItemGroupLabelSliderProps) => {
    var size = 0
    const BoxRef = useRef<any>(null)
    const [showScroll, setShowScroll] = useState(true);
    const [cloase,setClose] = useState<'left'|'right'|'none'>('none')

    const handleRight = () =>{
        size = size + 20
        const { scrollLeft, scrollWidth, clientWidth }:any = BoxRef.current
        if(scrollLeft<0){
            BoxRef.current?.scrollTo({left :scrollLeft+150,top:0,  behavior: "smooth"})
        }
    }

    
    const handleLeft = () =>{
        size = size - 20
        const { scrollLeft, scrollWidth, clientWidth }:any = BoxRef.current
            if(scrollWidth+scrollLeft > clientWidth){
            BoxRef.current?.scrollTo({left :scrollLeft-150,top:0,  behavior: "smooth"})
        }
    }
    
    const chackArrow = () =>{
        const { scrollLeft, scrollWidth, clientWidth }:any = BoxRef.current
        if(scrollWidth + scrollLeft <= clientWidth){
            setClose('left')
        }else if(scrollLeft >= 0){
            setClose('right')
        }else{
            setClose('none')
        }
    }

    useEffect(() => {
        const { scrollLeft, scrollWidth, clientWidth }:any = BoxRef.current
        if(scrollWidth <= clientWidth){
            setShowScroll(false)
        }
        // if(scrollWidth == clientWidth){
            // setShowScroll(false)
        // }
        chackArrow()

    }, [ ]);    

    return ( 
        <div className="itemSlider d-flex align-items-center w-100">
            {showScroll&& cloase != 'right' &&
            <button className="itemSlider__arrowBtn"  onClick={handleRight}>
                <FontAwesomeIcon icon={["fas", "chevron-right"]}/>
            </button>
            }
            <div ref={BoxRef} onScroll={()=>chackArrow()} className="pb-2 itemSlider__scrollBx d-flex w-100">
                <>    
                 {children}
                </>
            </div>  
            {showScroll&& cloase != 'left' &&
            <button className="itemSlider__arrowBtn"  onClick={handleLeft}>
                 <FontAwesomeIcon icon={["fas", "chevron-left"]} />
            </button>
            }
        </div>
     );
}
 
