import React from "react";
import { Navigate, RouteProps } from "react-router-dom";

export interface ComponentHOCProps extends RouteProps {
  component: any,
  type?: any,
  mode?: any,
  link?: any
}

export const ComponentHOC = ({ component: Component, type, mode, link }: ComponentHOCProps ) => {
  return (
    link ? <Navigate to={link} /> : (
      type ? <Component type={type} /> : (mode ? <Component mode={mode} /> : <Component />)
    )
  )
};
