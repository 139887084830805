import { getCalendaryear } from "./api";
import { useContext, useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import './style.scss';
import { Link, useNavigate } from "react-router-dom";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { ICON_TYPE } from "utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Toast } from "components/ToastShow/ToastShow";

export const Footer = () => {
  const [data, setData] = useState<any>();

  const [lang, setLang] = useState<any>("fa");
  const [shortcutList, setShortcutList] = useState<any>([]);
  const [inEdit, setInEdit] = useState<any>(false);
  const [cloase,setClose] = useState<'left'|'right'|'none'>('none')
  const layoutContext = useContext(LayoutContext);    
  const [showScroll, setShowScroll] = useState(true);
  const [checkResize,setCheckResize] = useState(false);
  const BoxRef = useRef<any>(null);
  const timeout = useRef<any>(null);
  var size = 0

  const navigate = useNavigate();

  const handleNavigate = (item) => {
    
    if(sessionStorage.getItem("changingforms")+""=="true"){
      Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{changeApplication(item);navigate(item.Route);sessionStorage.setItem("changingforms" , "false");localStorage.removeItem("formsInChang")  });
    }else{
      changeApplication(item)
      navigate(item.Route)
    }
  }

  const getCalendarAsync = async () => {
    try {
      const res = await getCalendaryear()
      setData(res.Data);
    } catch (error: any) {
      setLang("en")
      //            setErrcode(error.response.status)
    }
  }


  const changeApplication =  (obj) => {
    layoutContext.setCurrentApplication({Id:obj.applicationId ,Name:obj.applicationName });
    localStorage.setItem("CurrentApplication" ,JSON.stringify({Id:obj.applicationId ,Name:obj.applicationName })  )

  }

  const Drop = async (index = 0) => {
    if(index != 0){
      //alert(index)
    }
    let shortcuts:any = localStorage.getItem("shortcutmenu");
    if (shortcuts == null) {
      setShortcutList([])
      shortcuts = JSON.stringify([])
    } else {
      if(layoutContext.dragObj?.Name){
        let flag = false
        JSON.parse(shortcuts).forEach(obj=>{
          if(obj.Route == layoutContext.dragObj.Route){
            flag = true
          }
        })
        if(!flag){
          setShortcutList([...JSON.parse(shortcuts) , {Name : layoutContext.dragObj.Name , Route :layoutContext.dragObj.Route , applicationId : layoutContext.dragObj.applicationId, applicationName : layoutContext.dragObj.applicationName }] )
        }
      }
      
    }
    if(layoutContext.dragObj?.Name) {
      let flag = false
      JSON.parse(shortcuts).forEach(obj=>{
        if(obj.Route == layoutContext.dragObj.Route){
          flag = true
        }
      })
      if(!flag){
        setShortcutList([...JSON.parse(shortcuts) , {Name : layoutContext.dragObj.Name , Route :layoutContext.dragObj.Route , applicationId : layoutContext.dragObj.applicationId, applicationName : layoutContext.dragObj.applicationName }])
        localStorage.setItem("shortcutmenu" , JSON.stringify( [...JSON.parse(shortcuts) , {Name : layoutContext.dragObj.Name , Route :layoutContext.dragObj.Route , applicationId : layoutContext.dragObj.applicationId, applicationName : layoutContext.dragObj.applicationName }] ) );
      }

    }
  }

  const handleRight = () =>{
    size = size + 20
    if(BoxRef.current != null){
      const { scrollLeft, scrollWidth, clientWidth }:any = BoxRef.current
      if(scrollLeft<0){
          BoxRef.current?.scrollTo({left :scrollLeft+150,top:0,  behavior: "smooth"})
      }

    }

}


const handleLeft = () =>{
    size = size - 20
    if(BoxRef.current != null){
      const { scrollLeft, scrollWidth, clientWidth }:any = BoxRef.current
      if(scrollWidth+scrollLeft > clientWidth){
        BoxRef.current?.scrollTo({left :scrollLeft-150,top:0,  behavior: "smooth"})
      }
    }
  }

  const chackArrow = () =>{
    if(BoxRef.current != null){

      const { scrollLeft, scrollWidth, clientWidth }:any = BoxRef.current
      
      if(scrollWidth + scrollLeft <= clientWidth){
        setClose('left')
      }else if(scrollLeft >= 0){
        setClose('right')
      }else{
        setClose('none')
      }
    }
  }

  const remove =  (item) => {

    let shortcuts:any = localStorage.getItem("shortcutmenu");
    if (shortcuts == null) {
      setShortcutList([])
      shortcuts = JSON.stringify([])
    } else {
      let newList:any = []
      JSON.parse(shortcuts).forEach(obj=>{
        if(obj.Route != item.Route){
          newList.push(obj)
        }
      })
      setShortcutList(newList)
      localStorage.setItem("shortcutmenu" , JSON.stringify( newList ) );
    }

  }

  window.onresize = ()=>{
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      setCheckResize(!checkResize)
  }, 500);
  }

  useEffect(() => {

    let langueg = localStorage.getItem("lang");
    if (langueg == null) {
      localStorage.setItem("lang", lang);
    } else {
      setLang(langueg)
    }
    getCalendarAsync();


  }, [localStorage.getItem("lang")]);

  useEffect(() => {
    let shortcuts = localStorage.getItem("shortcutmenu");
    if (shortcuts == null) {
      setShortcutList([])
    } else {
      setShortcutList(JSON.parse(shortcuts) )
    }
  }, []);
  useEffect(() => {
    if(BoxRef.current != null){
      chackArrow()
      if( BoxRef.current.scrollWidth> BoxRef.current.clientWidth ){
        setShowScroll(true)
        
      }else{
        setShowScroll(false)
        
      }
    }else{
      setShowScroll(false)
    }
    }, [shortcutList,checkResize]);
  
  return (
    <>{layoutContext.currentUserPosition.PositionId&&
    <footer className="main-footer dropable" onMouseEnter={()=>{if(layoutContext.dragObj!=null) layoutContext.setDragObj({...layoutContext.dragObj , accessDrop : true}) }}>
      {((shortcutList.length >0 ||layoutContext?.dragObj?.Route?.length >0) ) &&
      <div style={{display:"flex" ,flexDirection: "row", justifyContent: "space-between" }}   className="shortCutBox shortCutContext  dropable" onMouseEnter={()=>{if(layoutContext.dragObj!=null)layoutContext.setDragObj({...layoutContext.dragObj , accessDrop : true}) }}  onMouseUp={() => { Drop() }}>
        <div style={{width:"30px" ,marginTop:"3px" , position:"absolute" , left:"0",top:'0'}} onClick={()=>{setInEdit(!inEdit)}}>
          { inEdit ?
            <FontAwesomeIcon icon={[ICON_TYPE, "save"]} color={"white"} onClick={()=>{setInEdit(true)}} width="20px" height="20px" />
            :
            <FontAwesomeIcon icon={[ICON_TYPE, "edit"]} color={"white"} onClick={()=>{setInEdit(false)}} width="20px" height="20px" />
          }
        </div>
        {showScroll&& cloase != 'right' &&
            <button className="itemSlider__arrowBtn" style={{zIndex:'2',position:'absolute',top:'0',right:'0',backgroundColor:'#343a40',color:'#ced4da'}}  onClick={handleRight}>
                <FontAwesomeIcon icon={["fas", "chevron-right"]}/>
            </button>
            }
        <div className="shortCutBox dropable ms-3"   ref={BoxRef} onScroll={chackArrow}>
          {shortcutList.map((item , index)=>(
              <OverlayTrigger
              key={item.Name}
              placement="top"
              delay={{"show" : 600 , "hide":0}}
              overlay={
                  <Tooltip className="tooltip" id={`button-tooltip-${"Name"}`}>
                      {item.Name} 
                  </Tooltip>
              }
              >
                <div className="shortCutCard dropabl-100" >
                  <Link  
                  onClick={(e)=>{e.preventDefault();handleNavigate(item)}}
                  
                      to={item.Route}>
                  {item.Name}        
                  </Link>
                  {
                    inEdit && 
                    <div onClick={()=>{remove(item)}} className="h-100" style={{display:'inline',marginTop: "3px"}}> 
                      <FontAwesomeIcon icon={[ICON_TYPE, "trash"]} color={"white"} onClick={()=>{setInEdit(true)}} width="20px" height="20px" />
                    </div>
                  }
                </div>
              </OverlayTrigger>  
          ))}
        </div>
        {showScroll&& cloase != 'left' &&
            <button className="itemSlider__arrowBtn" style={{zIndex:'2',position:'absolute',top:'0',left:'30px',backgroundColor:'#343a40',color:'#ced4da'}}  onClick={handleLeft}>
                 <FontAwesomeIcon icon={["fas", "chevron-left"]} />
            </button>
            }

      </div>
      }
      <div style={{width : (shortcutList.length >0 ||layoutContext?.dragObj?.Route?.length >0) ?"20%" : "100%"}}  className="d-flex align-items-center justify-content-center footerText ">
        {data && <>
          {lang == "fa" ?
            <strong> {trans("CopyRightText")} © {data}-1400 </strong>
            :
            <strong> {trans("CopyRightText")} © 2021-{data} </strong>

          }

        </>

        }
      </div>
    </footer>
    }</>
  );
};


