import { useEffect, useState } from "react";

import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { SelectBox } from "components/Select/Select";
import { Textbox } from "components/Textbox/Textbox";
import { getAllDeviceProperties, sendDevicePropertiesValue } from "../api";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";

interface ItemPropertiesValuesListPropsType {
    mode: any,
    Id: any,
    formRef?: any,
    inTab?: any,
    IsIndependent?:boolean
    ItemPropertiesValuesDataListProp?: [],
    ItemPropertiesValuesDataListCallback?: (ans: []) => void,
    onHide?: () => void;

}
export const ItemPropertiesValues = ({mode, Id=null,IsIndependent=false,inTab ,formRef,ItemPropertiesValuesDataListCallback,onHide ,ItemPropertiesValuesDataListProp }: ItemPropertiesValuesListPropsType) => {

    const [response, setResponse] = useState<Response | any>([]);
    const [resetForm, setResetForm] = useState(false);

    const [loading, setLoading] = useState(false);

 
    const submitHandler =(e)=>{
        e.preventDefault()
        let data:any =[]
        if(IsIndependent){
            response.DeterminedItemProperties.map((item , index)=>{
                data.push({
                    "Id":item.ItemOtherPropertyValueId,
                    "ItemId":Id,
                    "Sequence": index,
                    "ItemOtherPropertyId": item.ItemOtherPropertyId,
                    "PropertyValue": item.PropertyType==4 ? e.target[item.ItemOtherPropertyId]?.checked+"": e.target[item.ItemOtherPropertyId]?.value    
                })
            })
            sendDevicePropertiesValueAsync(data,"put")
        }else{
            response.UndeterminedItemProperties.map((item , index)=>{
                data.push({
                    "Sequence": index,
                    "ItemOtherPropertyId": item.ItemOtherPropertyId,
                    "PropertyValue": item.PropertyType==4 ? e.target[item.ItemOtherPropertyId]?.checked+"": e.target[item.ItemOtherPropertyId]?.value    
                })
            })
            ItemPropertiesValuesDataListCallback&& ItemPropertiesValuesDataListCallback(data)
        }
    }
    const submitPostHandler =(e)=>{
        e.preventDefault()
        let data:any =[]
        response.UndeterminedItemProperties.map((item , index)=>{
            data.push({
                "ItemId":Id,
                "Sequence": index,
                "ItemOtherPropertyId": item.ItemOtherPropertyId,
                "PropertyValue": item.PropertyType==4 ? e.target[item.ItemOtherPropertyId]?.checked+"": e.target[item.ItemOtherPropertyId]?.value    
            })
        })
        sendDevicePropertiesValueAsync(data,"post")
    }
    const submitPutHandler =(e)=>{
        e.preventDefault()
        let data:any =[]
        response.DeterminedItemProperties.map((item , index)=>{
            data.push({
                "Id":item.ItemOtherPropertyValueId,
                "ItemId":Id,
                "Sequence": index,
                "ItemOtherPropertyId": item.ItemOtherPropertyId,
                "PropertyValue": item.PropertyType==4 ? e.target[item.ItemOtherPropertyId]?.checked+"": e.target[item.ItemOtherPropertyId]?.value    
            })
        })
        sendDevicePropertiesValueAsync(data,"put")
    }

    async function getAllDevicePropertiesAsync() {
        setLoading(false)
        try {
            const res = await getAllDeviceProperties(Id , (IsIndependent||mode!="create"));
            setResponse(res.Data);
           // setMessageType("info")
        } catch (error) {
           // setMessageType("error")
        }
        setLoading(true)
    }
    async function sendDevicePropertiesValueAsync(list,metode) {
        setLoading(false)
        try {
            const res = await sendDevicePropertiesValue(list,metode);
            setResetForm(!resetForm)
           // setMessageType("info")
        } catch (error) {
           // setMessageType("error")
        }
        setLoading(true)
    }


 

    useEffect(() => {
        if(IsIndependent||inTab ==="ItemProperties"){
            getAllDevicePropertiesAsync();
        }
    }, [inTab ,resetForm]);


    return (
    <> 
    <div className="person-contact-list">
        {loading ? 
        <div className="">
            {(response.UndeterminedItemProperties.length>0&&IsIndependent) ?
            <>
        <form className="" onSubmit={submitPostHandler }>
            <FormWithTitle title="UndeterminedItemProperties">
            <div className="card-body row">
            {response.UndeterminedItemProperties.length >0&& response.UndeterminedItemProperties?.map(item=>(
         
         <div className="col-4">

         
         {
             {
                 1: (
                    <Textbox
                    defaultValue={item.PropertyValue}      
                     textboxType="text"
                    textboxName={item.ItemOtherPropertyId}
                    labelText={item.PropertyName}
                    resetForm={resetForm}
                    mode={mode}

                />
                 ),
                 2: (
                    <Textbox
                    defaultValue={item.PropertyValue}      
                    textboxType="number"
                    textboxName={item.ItemOtherPropertyId}
                    mode={mode}
                    labelText={item.PropertyName}
                    resetForm={resetForm}
                />
                 ),
                 3: (                   
                    <SelectBox
                    mode={mode}
                    lable={item.PropertyName}
                    selectType="select"
                    value={null}
                    options={item.PropertyValue.split(",")}
                    val={item.PropertyValue.split(",")}
                    name={item.ItemOtherPropertyId}
                  ></SelectBox>
                     
                 ),
                 4: (
                     <>
                        <div className="mt-4">
                            <CheckableButton
                            defaultChecked={item.PropertyValue=="true"}
                            defaultValue={item.PropertyValue=="true"}
                            labelText={item.PropertyName}
                            inputName={item.ItemOtherPropertyId}
                            type="checkbox"
                            mode={mode}
                         />   
                        </div>
                     </>
                 )
             }[item.PropertyType]
             
         }</div>

        
        )) }
            </div>
            </FormWithTitle>   
            <div className="d-flex justify-content-end my-2">
                <Button value="submit" btnType="submit"  />
            </div>                 
        </form> 
        <form onSubmit={submitPutHandler }>
            <FormWithTitle title="DeterminedItemProperties">

            
                <div className="card-body row">
                {response.DeterminedItemProperties.length >0&& response.DeterminedItemProperties?.map(item=>(
         
         <div className="col-4">

         
         {
             {
                 1: (
                    <Textbox
                    defaultValue={item.PropertyValue}      
                     textboxType="text"
                    textboxName={item.ItemOtherPropertyId}
                    labelText={item.PropertyName}
                    resetForm={resetForm}
                    mode={mode}

                />
                 ),
                 2: (
                    <Textbox
                    defaultValue={item.PropertyValue}      
                    textboxType="number"
                    textboxName={item.ItemOtherPropertyId}
                    mode={mode}
                    labelText={item.PropertyName}
                    resetForm={resetForm}
                />
                 ),
                 3: (                   
                    <SelectBox
                    mode={mode}
                    lable={item.PropertyName}
                    selectType="select"
                    value={null}
                    options={item.PropertyValue.split(",")}
                    val={item.PropertyValue.split(",")}
                    name={item.ItemOtherPropertyId}
                  ></SelectBox>
                     
                 ),
                 4: (
                     <>
                        <div className="mt-4">
                            <CheckableButton
                            defaultChecked={item.PropertyValue=="true"}
                            defaultValue={item.PropertyValue=="true"}
                            labelText={item.PropertyName}
                            inputName={item.ItemOtherPropertyId}
                            type="checkbox"
                            mode={mode}
                         />   
                        </div>
                     </>
                 )
             }[item.PropertyType]
             
         }</div>

        
                )) }
                </div>
                </FormWithTitle>    
                <div className="d-flex justify-content-end my-2">
                    <Button value="submit" btnType="submit"  />
                </div>                
         </form> 
             </>
             :
             <>
         <form className="card" ref={formRef} onSubmit={submitHandler }>
            <div className="card-body row">
                {IsIndependent ?
                <>
                {response.DeterminedItemProperties.length >0&& response.DeterminedItemProperties?.map(item=>(
                <div className="col-4">
    
         
         {
             {
                 1: (
                    <Textbox
                    defaultValue={item.PropertyValue}      
                     textboxType="text"
                    textboxName={item.ItemOtherPropertyId}
                    labelText={item.PropertyName}
                    resetForm={resetForm}
                    mode={mode}
    
                />
                 ),
                 2: (
                    <Textbox
                    defaultValue={item.PropertyValue}      
                    textboxType="number"
                    textboxName={item.ItemOtherPropertyId}
                    mode={mode}
                    labelText={item.PropertyName}
                    resetForm={resetForm}
                />
                 ),
                 3: (                   
                    <SelectBox
                    mode={mode}
                    lable={item.PropertyName}
                    selectType="select"
                    value={null}
                    options={item.PropertyValue.split(",")}
                    val={item.PropertyValue.split(",")}
                    name={item.ItemOtherPropertyId}
                  ></SelectBox>
                     
                 ),
                 4: (
                     <>
                        <div className="mt-4">
                            <CheckableButton
                            defaultChecked={item.PropertyValue=="true"}
                            defaultValue={item.PropertyValue=="true"}
                            labelText={item.PropertyName}
                            inputName={item.ItemOtherPropertyId}
                            type="checkbox"
                            mode={mode}
                         />   
                        </div>
                     </>
                 )
             }[item.PropertyType]
             
            }
                </div>
                )) }
                
                </>  
                :
                <>
                {response.UndeterminedItemProperties.length >0&& response.UndeterminedItemProperties?.map(item=>(
                <div className="col-4">
                    {
             {
                 1: (
                    <Textbox
                    defaultValue={item.PropertyValue}      
                     textboxType="text"
                    textboxName={item.ItemOtherPropertyId}
                    labelText={item.PropertyName}
                    resetForm={resetForm}
                    mode={mode}
    
                />
                 ),
                 2: (
                    <Textbox
                    defaultValue={item.PropertyValue}      
                    textboxType="number"
                    textboxName={item.ItemOtherPropertyId}
                    mode={mode}
                    labelText={item.PropertyName}
                    resetForm={resetForm}
                />
                 ),
                 3: (                   
                    <SelectBox
                    mode={mode}
                    lable={item.PropertyName}
                    selectType="select"
                    value={null}
                    options={item.PropertyValue.split(",")}
                    val={item.PropertyValue.split(",")}
                    name={item.ItemOtherPropertyId}
                  ></SelectBox>
                     
                 ),
                 4: (
                     <>
                        <div className="mt-4">
                            <CheckableButton
                            defaultChecked={item.PropertyValue=="true"}
                            defaultValue={item.PropertyValue=="true"}
                            labelText={item.PropertyName}
                            inputName={item.ItemOtherPropertyId}
                            type="checkbox"
                            mode={mode}
                         />   
                        </div>
                     </>
                 )
                     }[item.PropertyType]

                    }
                </div>
                )) }
                </>  
            
            }
            </div>
            {IsIndependent ?
            <div className="d-flex justify-content-end my-2">
                <Button value="submit" btnType="submit"  />
                <Button value="cancel" btnType="cancel" onClick={() =>onHide&& onHide()}  />
            </div>
            :
            <button  type="submit"  className="d-none" id="hidebutton" name="hidebutton" >
            </button> 
            }
         </form>
             </>
            }
        </div>
        :<Loading/>
        }  
    </div>
    </>
    );
}