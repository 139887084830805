import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getProcessStarting = ( ApplicationId,positionId: any) => http({
    url: ENDPOINTS.bpmnApi.process.getStarting,
    method: 'get',
     params: {
        
         applicationId : ApplicationId
     }
}).then((response) => (response.data))
    .catch(err => {
        return Promise.reject(err)
    })

