
import { useEffect, useState } from "react";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { TableTree } from "components/TableTree/TableTree";
import { Button } from "components/Button/Button";
import { addPositionRole, getDictionaryRolesList } from "pages/SystemManagement/Settings/Users/api";
import { trans } from "utils/helpers";
import { Search } from "components/Search/Search";

interface propsOrganizationalStructure {
  selectedObj:any;
  onHide: (mod) => void;
  addNewPositionRoles: (mod) => void;
  reload: () => void;
}
export const PositionRoleForm = ({selectedObj ,onHide,reload ,addNewPositionRoles} :propsOrganizationalStructure )=> {
  const [data, setData] = useState<any>()
  const [ListResponse, setListResponse] = useState<any>()
  const [relad, setReload] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<any>()
  const [selectedList, setSelectedList] = useState<any>([])
  const [idCallback, setIdCallback] = useState<any>("2")
  const [messageType, setMessageType] = useState<any>("info")
  //   const [theadData , setTheadData] = useState<any>()
  const [search, setsearch] = useState(null);
  const [defultShow, setDefultShow] = useState(false);


  let theadData: any = []
  let obj: any = {}

  function set(name, value) {
    var obj2 = obj;  
    var nameList = name.split('.');
    var len = nameList.length;
    for(var i = 0; i <len-1; i++) {

        var elem = nameList[i];
        if( !obj2[elem] ) obj2[elem] = {}
        obj2 = obj2[elem];
    }
  obj2[nameList[len-1]] = value;
}




  function setTree(obj2 , parentId) {
    let list:any = Object.keys(obj2)
    let tree:any = []
    if(typeof obj2 != "string"){

    list.forEach(item=>{
      tree.push({
        Id :  parentId+item ,
        parentId : parentId ,
        Children : setTree(obj2[item] , parentId+item ) ,
        Description : item
      })
    })

    }
  return tree
}


async function getListAsync() {
  setLoading(false)
try {
  const response = await getDictionaryRolesList(selectedObj.Id)

  if(response.Data){
  setListResponse(response.Data)

    Object.keys(response.Data).forEach(item => {
      set( response.Data[item],  item);
    });
    let tabletr =  setTree(obj , "")
    if(tabletr.length >0){
      setData(tabletr)
    }
  }else{
    setData(null)
  }
  
  } catch (err: any) {
    setMessageType("error")
  }
  setLoading(true)
}

const delay = (duration) =>
new Promise(resolve => setTimeout(resolve, duration));

const Searchhandleer =async (str) => {
  setLoading(false)
   obj = {}
  
   setData(null)
  if (str == "") {
      setsearch(null)
      setDefultShow(false)
  }else{
    setDefultShow(true)
    setsearch(str)
  }
      if(ListResponse && ListResponse != undefined){
        Object.keys(ListResponse).forEach(item => {
          if(str != "" ){
            let flag = true
            ListResponse[item].split('.').forEach(word=>{
              if(flag ){
                if(str.split(" ").length > 1){
                  str.split(" ").forEach(serchWord=>{
                    if(trans(word).includes(serchWord)){
                      flag = false
                      return ;
                    }
                  })

                }else{
                  if(trans(word).includes(str)){
                    flag = false
                    return ;
                  }

                }

              }
            })
            if(!flag){
              set( ListResponse[item],  item);
            }
          }else{
            set( ListResponse[item],  item);
          }
        });
        let tabletr =  setTree(obj , "")
        if(tabletr.length >0){

          await delay(5)

          setData(JSON.parse(JSON.stringify(tabletr)))
        }
        setLoading(true)
  
      }
  
}



async function addNewRoles() {
 
  let addList:any = []
  let backList:any = []
  Object.values(ListResponse).forEach((item , index )=> {
    if(selectedList.includes(item) ){
      addList.push(Object.keys(ListResponse)[index])
      backList.push({
        RoleId:Object.keys(ListResponse)[index] ,
        Name : item ,
        HasAccess : true
      })
    }
    
    
  });

    
    try {
      const response = await addPositionRole(selectedObj.Id, addList)
        addNewPositionRoles(backList)
        onHide(false)
    } catch (err: any) {
        
    }
    
}




  useEffect(() => {
    getListAsync()
  }, [relad]);

  if (data) {
    theadData = Object.keys(data[0])
  }



  return (
    <div className="p-2">
        <div className="col-lg-2 mb-3">
         <Search  onChange={Searchhandleer}/>
        </div>
      <div className="col-lg-12 mt-0">
        <div className="list-card card">
          <>
            {
              loading ? (
                data ?
                  <TableTree
                    search={search}
                    multiselect={true}
                    selectedRowObjectCallback={setSelected}
                    selectedRowObject={idCallback}
                    //getChildrenUrl={ENDPOINTS.BaseInformation.organization.getNode}
                    Data={data}
                    openLevel={1}
                    theadData={theadData}
                    parentName={"UsersAccess"}
                    startIndex={2}
                    defultShow={defultShow}
                    selectedRowsCallback={setSelectedList}
                    ListResponse={ListResponse}

                  />
                  : <Message message={"NoDataForPreview"} type={messageType} />
              )
                : <Loading />
            }
          </>
        </div>
        
        <div className="col-lg-12 mt-1 btns_form">
                      <Button value="Save" btnType="submit" onClick={addNewRoles} />
                      <Button
                        btnType="cancel"
                        value="Cancel"
                        onClick={() => {onHide(false)}}
                      />

        </div>
      </div>
    </div>
  )
}