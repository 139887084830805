import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";


export const getAllItemList = (pageNum, pageSize , orderBy,searchString ,filterlist) => http({
    baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.ItemGroupList,
   method: "get",
   params: {
        PageNumber: pageNum,
        PageSize: pageSize,
        OrderByString: orderBy,
        SearchString: searchString,
        FilterString:filterlist,
   }
}).then((res) => {
   return res.data
}).catch((err) => {
   return Promise.reject(err.response  );
});


export const getItemListId = (async (pageNum,pageSize,orderBy,searchString,filterlist,Id) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.warhouseItemGroup.browse,
    method: 'get',
    params: {
        PageNumber: pageNum,
        PageSize: pageSize,
        OrderByString: orderBy,
        SearchString: searchString,
        FilterString:filterlist,
        warehouseId : Id
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

);

export const postItemList = (async (data) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.warhouseItemGroup.browse,
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

);


export const deleteItemList = (data,warehouseId) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.warhouseItemGroup.browse,
    method: 'delete',
    params:{
        warehouseId:warehouseId
    },
    data: data
    
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

