import { DataGrid } from "components/DataGrid/DataGrid";
import { Footerbar } from "components/Footerbar/Footerbar";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { getPageSize, trans } from "utils/helpers";
import { ActionForm } from "../ActionForm/ActionForm";
import { Attachment } from "../Attachment/Attachment";
import { getJobActionList } from "./api";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";


export const JobActionList = ({ referenceId ,showActions }: any) => {

    let gridColumns, gridData = [];
    let headerData, footerData;
    let Total_records, pages;
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedId, setSelectedId] = useState<string | any>(null);
    const [showActionForm, setShowActionForm] = useState(false);
    const [mode, setmode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [showattachment, setshowAttachment] = useState(false);
    const [endpoint, setEndpoint] = useState<any>();

    /* #endregion */
    /* #region pagination */

    const handlechangePageSize = (e: any) => {
        setPageSize(e.currentTarget.value);
        setPageNum(1);
        getPageSize("JobActionList" ,e.currentTarget.value)
        getReferenceJobActionListAsync();
    };
    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else setPageNum(value);
        } else {
        }
    };
    const HandleNextPage = () => {
        if (pageNum < pages) setPageNum((pageNum) => { return +pageNum + 1 });
    };
    const HandlePrevPage = () => {
        if (pageNum > 1) setPageNum((pageNum) => { return +pageNum - 1 });
    };
    /* #endregion */
    /* #region dataGridJobActionList */

    const handleEditRow = () => {
        setmode("edit")
        setShowActionForm(true);
    }
 
    const handleViewRow = () => {
        setmode("view")
        setShowActionForm(true);
    }
    const handleAttachmentRow = (id) => {
        setSelectedId(id)
        setEndpoint( ENDPOINTS.cartable.action.attachments)
        setmode("attach")    
        setshowAttachment(true)
    }


    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true" && showActionForm){
            Toast(trans("modalclosemessage"),"warning","alert", ()=>{setSelectedId(obj.Id) ;sessionStorage.setItem("changingforms" , "false");});
        }else{
            setSelectedId(obj.Id);
        };
    }

    async function getReferenceJobActionListAsync() {
        setLoading(false)
        let sizePage = getPageSize("JobActionList")
        setPageSize(sizePage)
        try {
            const res = await getJobActionList(pageNum, sizePage, referenceId);     
            setResponse(res);
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
  
        setLoading(true)
    }
    useEffect(() => {
   getReferenceJobActionListAsync()

    }, [pageNum, forceUpdate,showActions ]);


 

    if (response &&
        response?.Data && response.Data?.Data?.length>0) {
        gridColumns = Object.keys(response.Data.Data[0]);
        gridData = response.Data.Data;
        headerData = response.Data.Header;
        footerData = response.Data.Footer;
        Total_records = response.Data.TotalRecords;
    }
    pages = Total_records > pageSize ? Math.ceil(Total_records / pageSize) : 1;
    return (
        <>
            <div className="cartable-action">
                {showActionForm &&
                    <ActionForm
                        onHide={() => setShowActionForm(false)}
                        mode={mode}
                        reset={(e)=>{getReferenceJobActionListAsync()}}
                        id={selectedId}
                        referenceId={referenceId}
                    />
                }
                {loading ?
                    ((response?.Data?.Data.length > 0&& response?.Data.TotalRecords>0 )?
                        <div className=" mt-4">
                            <FormWithTitle title="reports">
                            
                            <div className="p-0">
                                <>
                                    <DataGrid
                                        parentName={"JobActionList"}
                                        theadData={gridColumns}
                                        tbodyData={gridData}
                                        minCellWidth={80}
                                        selectedRowObjectCallback={selectedIdCallback}
                                        handleEdit={handleEditRow}
                                        handleView={handleViewRow}
                                        handleAttachment={handleAttachmentRow}
                                        totalRecords={Total_records}
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        HandleNextPage={HandleNextPage}
                                        HandlePrevPage={HandlePrevPage}
                                        handlechangePageSize={handlechangePageSize}
                                        first={() => setPageNum(pages)}
                                        end={() => setPageNum(1)}
                                        handleGoPage={handleGoPage}
                                        scrollHeight={"150px"}
                                    />
                                    {footerData && <Footerbar footerContents={footerData} hasBorder={false} />}
                                    {showattachment &&
                                     <ModalForm title="Attachment" onHide={() => setshowAttachment(false)} show={showattachment}>
                                        <Attachment haveVersion={true}  onHide={() => setshowAttachment(false)} mood={mode} endpoint={endpoint} ownerId={selectedId} />
                                    </ModalForm>
                                    }
                                </>
                            </div>
                            </FormWithTitle>
                        </div>
                        : <></>
                    )
                    : <Loading />
                }

            </div>
        </>

    );
}