import { useEffect, useRef, useState } from "react";
import { setTimeout } from "timers";
import { trans } from "utils/helpers";
import { getPersonApi } from "./api";
import "./style.scss";
import { Button } from "components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_TYPE } from "utils/constants";
import { ModalForm } from "components/ModalForm/ModalForm";
import { PersonGrid } from "./PersonGrid";
interface PersonPropsType {
    required?: boolean,
    value?:string,
    label?:string,
    mode?:string,
    WithSearch?:boolean,
    name:string,
    DescriptionKey?:string,
    resetForm?:boolean,
    returnObj?:boolean,
    Change?: (e) => void,
    setPerson?: (e) => void,
}

export const Person = ({ DescriptionKey ,value,mode,name,required,label="person",resetForm,WithSearch=true,returnObj=false  ,Change , setPerson}: PersonPropsType) => {
    const [personsList, setPersonsList] = useState<any>();
    const [valueName, setValueName] = useState<any>();
    const [flag , setFlag] = useState(false);
    const [show , setShow] = useState(false);
    const [validat , setValidat] = useState(false);
    const [rquird , setRquird] = useState(false);
    const [showSerch , setShowSerch] = useState(false);
    const [count,setCount] = useState<number>(0);
    const inputRef = useRef<any>();
    const inputIdRef = useRef<any>();
    const PersonRef = useRef<any>(personsList);
   const validRef = useRef<any>(false);
   const countRef = useRef<any>(0)

  
let optionList :any = []
    async function getPersonListAsync(search,id) {
        try{
           const res = await getPersonApi(search ,id)
           if (id){
            setValeu(res[id] , id)
            setValueName(res[id])
            if(!returnObj)
              setPerson && setPerson(Object.values(res)[0])
            else
            setPerson && setPerson({Id:Object.keys(res)[0] , Name:Object.values(res)[0]})
          } 
          setPersonsList({ ...res})     
          PersonRef.current = { ...res}
          setFlag(!flag)
        }catch(err:any){
        
          }
      }
    
    const personIdCallBack = (e)=>{
      setShowSerch(false)
      inputIdRef.current.value=e
      getPersonListAsync(null,e)
    }
    const Auto = (e)=>{
      validRef.current = false
      setValidat(false)
      setRquird(false)
      setShow(true)
      getPersonListAsync(e,"")
      setFlag(!flag)
    }

    function userSelecter(){
      setValeu(optionList[countRef.current],Object.keys(personsList)[countRef.current])
      setShow(false)
    }

    const kydown = (event) => {    

      if((event.key === "ArrowDown")){
        if(countRef.current < optionList.length-1){
          countRef.current = countRef.current + 1;
        }else{
          countRef.current = 0;
        }
      }
      
      if(event.key === "ArrowUp"){
        if(countRef.current > 0){
          countRef.current = countRef.current -1;
        }else{
          countRef.current = optionList.length-1;
        }
      }
      document.getElementById("personsListIndex"+countRef.current)?.scrollIntoView({behavior:"smooth"})
      setCount(countRef.current)
  
      if(event.key === "Enter"){
        userSelecter()
      }
    }

    const CheckPerson =async (e)=>{
      setTimeout(() => {
       
        if(!validRef.current){
          
          if(personsList!=undefined &&  Object.values(personsList).length == 1){
            setValeu(Object.values(personsList)[0] , Object.keys(personsList)[0])
            e.target.setCustomValidity("");
          }else{
            if(inputRef.current){
          //  setValeu(null,null)
          }
           e.target.setCustomValidity(trans("Msg_Validation_ValueIsIncorrect"));
           setValidat(true);
           if(inputRef?.current?.value.length >0 && inputRef?.current?.value!=""){
            // e.target.setCustomValidity(trans("Msg_Validation_ValueIsIncorrect"));
            // setValidat(true);
            // e.target.reportValidity();
            }
            setPerson && setPerson(null);
        }

        }
      }, 200);
    await delay(200)
    setShow(false)
  }
  const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));

    const setValeu = (newValue , id)=>{
      if(inputIdRef != null && inputIdRef.current != null){

      validRef.current = true
      inputRef.current.value=newValue
      inputIdRef.current.value=id
      PersonRef.current = null;
      ((value==null &&id)|| id != value) ? Change&&Change({[name] :newValue}):Change&&Change({[name]:null});
      if(!returnObj)
        setPerson && setPerson(value)
      else
        setPerson && setPerson({Id:id , Name:newValue})
      
      if(mode != "view" && !returnObj){
        setPersonsList(null)
        setFlag(!flag)
        inputRef.current.setCustomValidity("")
        setPerson && setPerson(inputIdRef.current.value)
        setValidat(false)

      }
    }
    }

    useEffect(() =>{
      if(show ){
        document.addEventListener('keydown', kydown);
        document.body.addEventListener("click", clickToclose)
  
      } 
       return () => { document.removeEventListener("keydown", kydown);document.body.removeEventListener('click', clickToclose) }
  
      
    },[show ,optionList])

    function clickToclose(e){
      let input = document.querySelector("#browser"+name);
      if (e.target.id !== input?.id) { 
        setShow(false);
      }
    }

    useEffect(() => {
       if(value){
        getPersonListAsync(null,value)
       }else if(value == null){
        setValueName(null)
       }
      }, [value]);
    
    useEffect(()=>{
      if(resetForm == true){
        inputRef.current.value=null
        inputIdRef.current.value=null
      }
    },[resetForm])


    if(personsList){
      optionList = Object.values(personsList)
    }
    return (
     <>



      {showSerch &&
      <ModalForm modalHeight={49} mode={mode} onHide={()=>{setShowSerch(false)}} show={showSerch} title={"person" } >
            <PersonGrid personIdCallBack={personIdCallBack} serch={inputRef.current.value} />
        </ModalForm>  
      }
     <div className="text-box">

     <label htmlFor={`browser${name}`} className="text-box__label">{trans(label)}</label>
     {required && <span className="text-box__required-sign-for-label text-danger">*</span>}
     <input required={required} defaultValue={value && value} name={name} className="d-none" ref={inputIdRef} />
     
      <div  className="d-flex align-items-center" >
          <input  required={required} onBlur={CheckPerson} className="text-box__box form-control"   defaultValue={valueName} autoComplete="off" onFocus={(e)=>{ Auto(e.target.value); setShow(true);}}  ref={inputRef} list="PersonList" name={DescriptionKey?DescriptionKey:"browser"}   id={"browser"+name} onMouseDown={(e)=>e.stopPropagation() } onChange={(e)=>{Auto(e.target.value)}} disabled={mode =="view" ? true : false} />
     {(WithSearch&&mode != 'view') &&
          <Button Icon={true} value={<button type="button" onClick={(e)=>{setShowSerch(true)}} className="checkButton mt-0 d-flex align-items-center justify-content-center" > <FontAwesomeIcon icon={[ICON_TYPE, "search"]} style={{transform:"rotateY(180deg)",fontSize:"15px"}}  size="1x"  /></button>}  btnType="primary"onClick={(e)=>{}}   />
        }
      </div> 
     {
        show && 
         <div tabIndex={-1} className={`text-box__sub-menu mt-1`} >
           {optionList && optionList.map((item , index)=>{
                  return  <div id={"personsListIndex"+index} className={`${count == index ?"text-box__sub-menu-option-active" : "text-box__sub-menu-option"}`}  onClick={(e)=>{setValeu(item,Object.keys(personsList)[index]); inputRef.current.setCustomValidity("");}} key={Object.keys(personsList)[index]} >{item}</div>
            })}  
         </div>
      }  
 
       <div className="text-box__msg-wrapper">
        {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
        {required && <span className={`${rquird ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsRequired", [""])}</span>}
        </div>    
     </div>
     </>
    )
}
