import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import { ICON_TYPE } from "utils/constants";
import "./style.scss"
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";

interface BrowseFileProp {
    onChange?: (event) => void;
    accept?:any;
    multiple?: any;
    disabled?: any;
    fileClick?:any
    showBox?:any
    required?:any
    setFileClick?: (event) => void;
}
export const BrowseFile = ({setFileClick ,fileClick,showBox,disabled ,required,accept, onChange ,multiple=false, ...rest}: BrowseFileProp) => {
    const uploadRef = useRef<HTMLInputElement>(null)
    let lang = localStorage.getItem("lang");
    const handleUploadOnclick = () => {
        uploadRef.current!.click()
    }
    useEffect(() => {
      if (fileClick){
        handleUploadOnclick()
        setFileClick && setFileClick(false)
      }
          
  }, [fileClick])

  return (
    <div className=" fileBrowse mt-0">
        <input 
          required={required}
          accept={accept}
          {...rest}
          style={{ display:"none"}}
          type='file'
          ref={uploadRef}
          disabled={disabled}
          onChange={onChange}
          multiple={multiple}
        />   
        
        
        { !showBox&& {
    "fa":(
        <>
        {!multiple &&
            <input
            disabled={disabled}

            required={required}
              placeholder={trans("NoFileSelected")}
              readOnly={true}
              value={ uploadRef?.current?.files?.[0]?.name ?uploadRef?.current?.files[0]?.name: ""}
              style={{ fontSize:"18px" , width:"unset"}}
              type="text"
              className={`fileBrowseInputFa ${disabled&&"disabled"} text-box__box` }
             />
        }
        <button  disabled={disabled}style={{ boxShadow: "none",width:"90px"  , height:"32px"}}  type={`button`} className={`btn-primary button-custom mx-1 btn `} onClick={handleUploadOnclick}>
            {trans("SelectFile") }
        </button>
        </>              
              
          ),
     "en": (
        <>
        {!multiple &&
            <input
            disabled={disabled}

            required={required}
              placeholder={trans("NoFileSelected")}
              readOnly={true}
              value={ uploadRef?.current?.files?.[0]?.name ?uploadRef?.current?.files[0]?.name: ""}
              style={{ fontSize:"18px" , width:"unset"}}
              type="text"
              className={`fileBrowseInputEn ${disabled&&"disabled"} text-box__box` }
            />
        }
        <button    disabled={disabled} style={{ boxShadow: "none",width:"90px" }}  type={`button`} className={`btn-primary button-custom mx-1 btn `} onClick={handleUploadOnclick}>
              {trans("SelectFile") }
        </button>
        </>
     )
    }[lang as string]}
    </div>
  );
};
