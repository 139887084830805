import { MEETINGMANAGEMENT_URL } from "utils/constants";
import { ENDPOINTSMEETINGMANAGEMENT } from "utils/endpoints";
import { http } from "utils/http";

export const getAttendeesList = (async (id) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.AttendanceId,
    method: 'get',
    params: {Id : id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getInvitedList = (async (id) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.InvitedId,
    method: 'get',
    params: {Id : id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const sendAttendeesList = (async (id , list) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.Meeting.PresenceType,
    method: 'put',
    params: {meetingId : id},
    data:list
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)