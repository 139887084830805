

import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";


interface props {

  title?: string
  property?: any
  handleOpenStep?: (e:any)=>void;
}

export const StepInForms: React.FC<props> = ({title ,property ,handleOpenStep }) => {  
    const [isOpen, setIsOpen] = useState<boolean>(true);


  return (


        <div  style={{ backgroundColor: "#cfe2ff", paddingRight: "20px", justifyContent: "space-between" }} className="d-flex  py-1 col-12 pr-4 w-100">
          <div style={{width:'fit-content'}}>{property?.label ?(property?.label.includes("Step") ? trans('step') + " "+property?.label.replace("Step","") :property?.label ) :  trans('step')}</div>
          <div style={{width:'fit-content', marginLeft: "18px",cursor:"pointer" }} onClick={() => {handleOpenStep && handleOpenStep(!isOpen); setIsOpen(!isOpen)}} >{!isOpen ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" />:<FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
      </div>
  );
}; 