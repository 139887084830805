import { Button } from "components/Button/Button";
import { ButtonPrintIcon } from "components/ButtonPrintIcon/ButtonPrintIcon";
import DatePicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { Loading } from "components/Loading/loading";
import { UserContext } from "components/ModalForm/ModalForm";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { Person } from "layouts/DashboardLayout/components/Header/component/profile/components/person";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { GetReportWithJsonData, trans } from "utils/helpers";
import { getDataForPrint } from "../../api/api";
import { getDailyLeaveData, getDailyLeaveDataByreferenceId, postDailyLeaveData, putDailyLeaveData } from "./api";
import { RemainingDayCount } from "./RemainingDayCount";
import { MessageType } from "types/enum";

interface DailyLeaveFormPropsType {
    mode?: any,
    Name?: any,
    DailyLeaveId?: any,
    jobId?: any,
    processId?: any,
    taskId,
    referenceId?: any,
    step:any,
    Id?: any,
    onHide: () => void,
    handleCancelForm: () => void,
    handleShowReferenceStepper?: (referenceId: string) => void;
    inDynamicForms?: any;

}

export const DailyLeaveForm = ({
    mode,
    jobId,
    Name,
    DailyLeaveId,
    processId,
    taskId,
    Id,
    referenceId,
    step=1,
    onHide,
    handleCancelForm,
    handleShowReferenceStepper,
    inDynamicForms=false,


}: DailyLeaveFormPropsType) => {
    const [fromDate, setFromDate] = useState<any>();
    const [untilDate, setUntilDate] = useState<any>();
    const [leaveDaysNo, setLeaveDaysNo] = useState<any>();
    const [requestDate, setRequestDate] = useState<any>();
    const [response, setResponse] = useState<any>();
    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);
    const [showstep, setShowstep] = useState<any>({});
    const [localMode, setLocalMode] = useState<any>("create");
    const layoutContext = useContext(LayoutContext);
    const [showReferenceStepper, setShowReferenceStepper] = useState<boolean>(false);
    const [flag,setFlag] = useState(false);
    const [todayDate, settodayDate] = useState<string | null>(new Date().toISOString());

    const validationDates = () =>{
        if(fromDate && untilDate && fromDate?.toString().slice(0,10) > untilDate?.toString().slice(0,10)){
            setFlag(true)
        }else{
            setFlag(false)
        }
    }


    async function formSubmit(e) {
        e.preventDefault()
        let data
        let method
        if(flag){
            Toast(trans("InvalidEndDate"),"error");
        }else{
        if(response?.Id ){
            method = "put"
            if(step > 1){
                data = {
                    "Id" : response?.Id ,
                    "RequesterPositionId": response?.RequesterPositionId,
                    "RequestDate":response?.RequestDate,
                    "DailyLeaveRequestClassificationId":response?.DailyLeaveRequestClassificationId,
                    "StartDate": fromDate,
                    "EndDate":untilDate,
                    "Description": response?.Description,
                    "SubstitutePersonId": response?.SubstitutePersonId,
                    "RemainingDayCount" :  e.target?.RemainingDayCount?.value,
                    "LeaveDaysNo" :  e.target.LeaveDaysNo.value,
                }
            }else{
                data = {
                    "Id" : response?.Id ,
                    "RequesterPositionId": response?.RequesterPositionId,
                    "RequestDate":response?.RequestDate,
                    "DailyLeaveRequestClassificationId":e.target.DailyLeaveRequestClassification.value,
                    "StartDate": fromDate,
                    "EndDate":untilDate,
                    "Description": e.target.Description.value,
                    "SubstitutePersonId": e.target.SubstitutePersonId.value ? e.target.SubstitutePersonId.value:null,
                    "LeaveDaysNo" :  e.target.LeaveDaysNo.value,
                    "RemainingDayCount" :  e.target?.RemainingDayCount?.value,
                }
            }
        try {
            const res = await putDailyLeaveData(data ,referenceId)
            setResetForm(true)
            onHide()
        }catch (error) {

        }

        }else{
            method = "post"
              data = {
                "RequesterPositionId":  e.target.RequesterPositionId.value,
                "RequestDate": requestDate,
                "DailyLeaveRequestClassificationId":  e.target.DailyLeaveRequestClassification.value,
                "StartDate":fromDate,
                "EndDate": untilDate,
                "Description":  e.target.Description.value,
                "SubstitutePersonId": e.target.SubstitutePersonId.value ? e.target.SubstitutePersonId.value:null,
                "LeaveDaysNo" :  e.target.LeaveDaysNo.value,
                "RemainingDayCount" :  e.target?.RemainingDayCount?.value,
              }
            try {
                const res = await postDailyLeaveData(data,processId)
                if (showReferenceStepper) {
                    handleShowReferenceStepper && handleShowReferenceStepper(!(res.MessageType ==MessageType.success) ? false:res.Data);
                  } else {
                    setResetForm(true)
                    onHide()
                  }

            }catch (error) {
  
          }
        }
    }
}

    async function getDailyLeaveAsync() {
        setLoading(false)
         try {
            if(Id){
                const res = await getDailyLeaveData(Id )
                setResponse(res.Data)

            }else if(referenceId){
                const res = await getDailyLeaveDataByreferenceId(referenceId )
                setResponse(res.Data)
            }
        } catch (error) {
            setResponse({})
        }
        setLoading(true)
    }
    const getDataForPrintAsync =async (id) => {

        if(jobId){
            if(id.includes("Design")){
                const res = await getDataForPrint(jobId)
                GetReportWithJsonData( id.replace("Design" , ""), res.Data ,2)
            }else if(id.includes("Default")){
                const res = await getDataForPrint(jobId)
                GetReportWithJsonData( id.replace("Default" , ""), res.Data ,2)
                
            }else{
                const res = await getDataForPrint(jobId)
               GetReportWithJsonData( id, res.Data ,1 )
            }
        }
        try {

        } catch (error) {
            
        }
    };
    
    useEffect(() => {
        if (resetForm) {  
            setResetForm(false);
        }
    }, [resetForm]);

    useEffect(() => {
        if ((mode == "edit" && step ==1)  ) {
            setLocalMode("create")
        }else if(step ==3 ||mode == "edit"){
            setLocalMode("edit")    
        }else{
            setLocalMode(mode)
        }
    }, [mode]);

    useEffect(() => {
        if( step >=1 || mode != "create"||Id){
            getDailyLeaveAsync()
        }else{
            setLoading(true)
            setResponse({})
        }
    }, [Id]);
    useEffect(() => {
        if(untilDate && fromDate){
            setLeaveDaysNo((Math.round(Math.abs(new Date(fromDate).getTime()-new Date(untilDate).getTime())/(1000 * 3600 * 24)))+1)
        }
    }, [untilDate ,fromDate]);
    
    useEffect(()=>{
        validationDates()
      },[untilDate,fromDate])

    return (
        <div>
           {loading ?
        <div>
         <form className={"action-form  "+mode} onSubmit={formSubmit}>
            <div className={` card  `}>
                <div className="row " style={{flexDirection: "row-reverse"}}>
                <ButtonPrintIcon reportDomain={"OfficeAutomation"}  objectTag={"DailyLeaveForm"} Callback={(e) => {getDataForPrintAsync(e)}} />
                </div>

                <div style={{display: !showstep[1] ?"block" :"none"}} className="card-body pt-0">                      
                        <div className="row mb-2">
                         <div className="col-lg-4 col-sm-4 mt-1">
                         <Position
                            name="RequesterPositionId"
                            label="RequesterPosition"
                            mode={"view"}
                            value={mode == "create" ? layoutContext.currentUserPosition.PositionId : response?.RequesterPositionId}
                            resetForm={resetForm}
                            />
                         </div>
                         <div className="col-lg-4 col-sm-4 mt-1">
                         <Person
                            name={"SubstitutePersonId"}
                            label="Substitute"
                            Change={setUserMadeChanges}
                            resetForm={resetForm}   
                            value={response?.SubstitutePersonId ? response?.SubstitutePersonId  : null}
                            mode={step >1 ? "view" : mode}
                            />
                         </div>
                         <div className="col-lg-4 col-sm-4 mt-1">
                            <DictionarySelectBox
                             name="DailyLeaveRequestClassification"
                             label="DailyLeaveRequestClassification"
                             endPoint={ENDPOINTS.cartable.DailyLeaveRequestClassification.dictionary}
                             value={response?.DailyLeaveRequestClassificationId}
                             resetForm={resetForm}
                             mode={step >1 ? "view" : mode}
                             />
                         </div>
                        </div>   
                        <div className="row mb-2">
                            <div className="col-12 col-lg-3">
                                <DatePicker
                                    Change={setUserMadeChanges}
                                    required={true}
                                    mode={step >1 ? "view" : mode}
                                    resetForm={resetForm}
                                    lable="fromDate"
                                    setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                                    val={response?.StartDate ? response?.StartDate : todayDate} //resetForm ? new Date().toISOString() : 
                                    errorFlag={flag}
                                />
                            </div>
                            <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                                <DatePicker
                                    Change={setUserMadeChanges}
                                    required={true}
                                    mode={step >1 ? "view" : mode}
                                    resetForm={resetForm}
                                    lable="untilDate"
                                    setIsoTime={(isoTime: string) => { setUntilDate(isoTime) }}
                                    val={response?.EndDate ? response?.EndDate : null} //resetForm ? new Date().toISOString() : 
                                    errorFlag={flag}
                                />
                            </div>
                            <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                             <Textbox
                                mode={step >1 ? "view" : mode}
                                resetForm={resetForm}
                                textboxType="number"
                                textboxName="LeaveDaysNo"
                                labelText="forTheDuration"
                                maxLength={50}                        
                                defaultValue={response?.LeaveDaysNo ?response?.LeaveDaysNo :leaveDaysNo}
                             />
                            </div>
                            <div className="col-12 col-lg-3 mt-2 mt-lg-0">
                               <RemainingDayCount 
                                RequesterPositionId={response?.RequesterPositionId}
                                mode={localMode}
                                value={response?.RemainingDayCount}
                               />
                            </div>
                        </div>              
                        <div className="row mb-2">
                            <div className="col-lg-8 col-sm-6 ">
                               <Textbox
                                  readonly={(step >1 || mode=="view") ? true: false}
                                  mode={step >1 ? "view" : mode}
                                  resetForm={resetForm}
                                  textboxType="textarea"
                                  textboxName="Description"
                                  labelText="Description"
                                  
                                  defaultValue={response?.Description}
                               />
                            </div>
                        </div>    
        
                </div>
            </div>

           



            <div className="d-flex justify-content-end mt-2">
                            {localMode !== "view" ?
                                <>
                                    <Button value="Save" btnType="submit" />
                                    {(handleShowReferenceStepper &&     (step==1 || mode == "create")) && (
                                       <Button
                                         btnType="submit"
                                         value="SaveAndReference"
                                         onClick={() => setShowReferenceStepper(true)}
                                       />
                                     )}
                                     {!inDynamicForms&&
                                    <Button
                                    btnType="cancel"
                                    value="Cancel"
                                    onClick={() => { Toast(trans("modalclosemessage"), "warning", "alert", handleCancelForm) }}
                                    />
                                }
                                </> 
                                :
                                <></>
                            }
            </div>               

        </form>
            </div>
            : <Loading/>


           
           }

        

        </div>
    );
}