import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getMyOwnOrders = (pageNumber: number, pageSize: number, SearchString, orderBy, filterlist, Type = null) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.CustomerSalesOrderApis.CustomerSalesOrder.browse,
    method: 'get',
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString: SearchString,
        OrderByString: orderBy,
        FilterString: filterlist,
        //jobStatus: Type,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});