import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getDefaultPersonContact = ( ) => http({

    url: ENDPOINTS.BaseInformation.CurrentPersonContact.default,
    method: 'get',

    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const sendBasketAsync = (data , method ) => http({

    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.CustomerSalesOrderApis.CustomerSalesOrder.browse,
    method: method,
    data
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});