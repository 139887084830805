import { Widget } from "components/Widget/Widget"
import { dayMessageAsync } from "pages/DashboardPage/api/api";
import { useContext, useEffect, useState } from "react";
import './style.scss'
import { OrganizationType } from "types/enum";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";

interface props {
    id: string
}

export const WidgetQuote = ({id}:props) => {

    const [dayMessage, setDayMessage] = useState<any>([]);
    const layoutContext = useContext(LayoutContext);

    
    async function getDayMessageAsync() {
        try {
            const res = await dayMessageAsync();
            if(res.Data){

                setDayMessage(res.Data)           
            }else{

                setDayMessage({}) 
            }

        } catch (error) {
            setDayMessage({}) 
        }

        
    }



    useEffect(() => {
        if(layoutContext?.currentUserPosition?.OrganizationType!=OrganizationType.Customers)
             getDayMessageAsync()
    }, []);



    return (
        <>
        {dayMessage?.Title &&  
        <Widget title="dailyQoute" icon={['fas','bullhorn']} color='success' id={id}>
            {dayMessage ? 
            
            <div className="quote d-flex flex-column align-items-center justify-content-start p-2 w-100 h-100 text-center">
                <span className="mb-2" style={{fontSize: "13px" , width:"100%" , height:"60%" , overflow:"auto"}} dangerouslySetInnerHTML={{__html:dayMessage.Description}}></span>
                 <span className="text-bold">{dayMessage.Title}</span>
             </div>
             :
                <div className="d-flex justify-content-center align-items-center text-info h-100">
                    <div className="spinner-border spinner-border-md" role="status"></div>
                </div>
        }
        </Widget>        
        }
        </>
    )
}