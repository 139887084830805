import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const sendItemVariationBranch = (async (data) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.itemVariationBranch,
    method: 'post',
    data:data
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const putItemVariationBranch = (async (data) => await http({
   baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.itemVariationBranch,
   method: 'put',
   data:data
   
}).then((res) => {

   return res.data
}).catch((err) => {

   return Promise.reject( err);
})

)


export const getItemVariationBranch = (id) => http({
    baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.itemVariationBranch,
   method: "get",
   params:{
    Id:id
   }
}).then((res) => {
   return res.data
}).catch((err) => {
   return Promise.reject(err.response  );
});
export const getNewItemVariation = (id) => http({
    baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.newItemVariation,
   method: "get",
   params:{
      parentId:id
   }
}).then((res) => {
   return res.data
}).catch((err) => {
   return Promise.reject(err.response  );
});