import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { getReceiverPositionDictionaryApi, getReceiverPositionDictionaryListApi } from "./api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface ReceiverPositionPropsType {
  label?: string,
  valueString?: string,
  hasBorder?: boolean,
  resetForm?: boolean,
  required?: boolean,
  returnObj?: boolean,
  allowType?: boolean,
  value?: string,
  mode?: string,
  name: string,
  Change?: (e) => void,
  onChange?: (e) => void,
}

export const ReceiverPosition = ({valueString, label, hasBorder, value, mode,returnObj=false ,allowType,resetForm,required , name="", Change ,onChange}: ReceiverPositionPropsType) => {
  const [ReceiverPosition, setReceiverPositions] = useState<any>();
  const [valueName, setValueName] = useState<any>();
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [validat , setValidat] = useState(false);
  const [rquird , setRquird] = useState(false);
  const [count,setCount] = useState<number>(0);
  const inputRef = useRef<any>();
  const inputIdRef = useRef<any>();
  const [disabled, setDisabled] = useState(mode == "view" ? true : false);
  const countRef = useRef<any>(0)
  const [allowTyping , setAllowTyping] = useState(valueString&& allowType );

  let options_PostLevel_keys, options_PostLevel_values;


  async function getReceiverPositionListAsync(search?, id?) {
    try {
      if (id) {
        const res2 = await getReceiverPositionDictionaryApi( id);
        if(Object.values(res2.Data)[0] && Object.values(res2.Data)[0] != undefined){

          setValeu( Object.values(res2.Data)[0],  Object.keys(res2.Data)[0])
          setValueName( Object.values(res2.Data)[0])
          if(!returnObj)
            onChange && onChange(Object.values(res2.Data)[0])
          else
            onChange && onChange({Id: Object.keys(res2.Data)[0] , Name:Object.values(res2.Data)[0]})
        }else{
          
        }
      } else {
        const res = await getReceiverPositionDictionaryListApi(search, id);
        setReceiverPositions(res.Data)
        options_PostLevel_values = Object.values(res.Data)
        options_PostLevel_keys = Object.keys(res.Data)
        setShow(true)
      }
      setFlag(!flag)
    } catch (err: any) {

    }
  }

  const Auto = (e) => {
    setValidat(false)
    setRquird(false)
    setShow(true)
    Change && Change(true)
    getReceiverPositionListAsync(e.target.value, "")
    setFlag(!flag)

  }
  function userSelecter(){
    setValeu(options_PostLevel_values[countRef.current],options_PostLevel_keys[countRef.current])
  //  setValeu(options_PostLevel_values[countRef.current],Object.keys(options_PostLevel_keys)[countRef.current])

    setShow(false)
  }
  const kydown = (event) => {    
    if((event.key === "ArrowDown")){
      if(countRef.current < options_PostLevel_values.length-1){
        countRef.current = countRef.current + 1;
      }else{
        countRef.current = 0;
      }
    }
    if(event.key === "ArrowUp"){
      if(countRef.current > 0){
        countRef.current = countRef.current -1;
      }else{
        countRef.current = options_PostLevel_values.length-1;
      }
    }
    document.getElementById("ReceiverPositionIndex"+countRef.current)?.scrollIntoView({block:'end',behavior:"smooth"})
    setCount(countRef.current)

    if(event.key === "Enter"){
      userSelecter()
    }
    
  }
  
  const CheckReceiverPosition =async (e)=>{
    setTimeout(async() => {
  
      if(e.target.value.length == 0&& inputRef.current?.value?.length == 0 && e.target.value==""){
        inputIdRef.current.value = null
        if(required)
        setRquird(true)
      }else{
        setRquird(false)
      try{
        const res = await getReceiverPositionDictionaryListApi(e.target.value,"")
        if(Object.keys(res.Data).length==0){
          setValidat(true)
        }
        if(Object.keys(res.Data).length == 1){
          setValeu(Object.values(res.Data)[0] , Object.keys(res.Data)[0]  )
        }

       }catch(err:any){
      setValidat(true)
       }
      }
    }, 200);
    await delay(200)
    setShow(false)
  }
  const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));






  const setValeu = (value, id) => {
    if(inputIdRef != null && inputIdRef.current != null){

      setRquird(false)
      inputRef.current.value = value
      inputIdRef.current.value = id
      setReceiverPositions(null)

    if(!returnObj)
      onChange && onChange(value)
    else
      onChange && onChange({Id:id , Name:value})

      setFlag(!flag)

  }
}
  useEffect(() => {
    if (value) {
      getReceiverPositionListAsync(null, value)

    }else if(valueString&&allowType){
      onChange && onChange({Id:" " , Name:valueString})
    }
  }, [value ,resetForm,valueString]);

  useEffect(() => {
      setDisabled(mode == "view" ? true : false)
    
  }, [mode]);


  if (ReceiverPosition) options_PostLevel_values = Object.values(ReceiverPosition).map(item => {
    return item
  })
  if (ReceiverPosition) options_PostLevel_keys = Object.keys(ReceiverPosition).map(item => {
    return item
  })

  function clickToclose(e){
    let input = document.querySelector("#browser"+name);
    if (e.target.id !== input?.id) {
      setShow(false);
    }
  }

  useEffect(() =>{
    if(show){
      document.addEventListener('keydown', kydown);
      document.body.addEventListener("click", clickToclose)

    } 
     return () => { document.removeEventListener("keydown", kydown);document.body.removeEventListener('click', clickToclose) }

    
  },[show ,options_PostLevel_values])
  return (
    <>
      <div className="text-box">
        <label htmlFor="browser" className="text-box__label">{trans(label ? label : "ReceiverPosition")}</label>
        {required && <span className="text-box__required-sign-for-label text-danger">*</span>}
        {(allowType&&mode!="view")&&
        <>
        {
          allowTyping ?
          <FontAwesomeIcon onClick={()=>{setAllowTyping(false)}} style={{marginRight:"5px"}} icon={["fas", "magnifying-glass"]} className="text-primary pr-2"/>
          :
          <FontAwesomeIcon onClick={()=>{setAllowTyping(true)}} style={{marginRight:"5px"}} icon={["fas", "magnifying-glass-minus"]} className="text-danger  pr-2"/>

        }
        </>
        }
        <input required={required} defaultValue={value ? value:valueString}  name={name} className="d-none" ref={inputIdRef} />
        {(!allowTyping||!allowType)?
        <>
        <input required={required} autoFocus={(!allowTyping&&valueName)} defaultValue={valueName}  onBlur={CheckReceiverPosition} autoComplete="off" onMouseDown={(e) => {e.stopPropagation(); Auto(e) }} onFocus={() => {getReceiverPositionListAsync(!allowTyping ? valueName:null); setShow(true) }} ref={inputRef} list="ReceiverPositionList" name="browser" className={"text-box__box form-control" + " browser"+name} type={'text'} id={"browser"+name} onChange={Auto} disabled={disabled} />
        {
          show &&
          <div className={`text-box__sub-menu mt-1`} tabIndex={-1} >    
            {options_PostLevel_values && options_PostLevel_values.map((item, index) => {
              return <div id={"ReceiverPositionIndex"+index} className={`${count == index ?"text-box__sub-menu-option-active" : "text-box__sub-menu-option"}`} onClick={(e) => { setValeu(item, options_PostLevel_keys[index]) }} key={options_PostLevel_keys[index]} >{item}</div>
            })}
          </div>
        }
        </>
        :
        <input required={required} defaultValue={valueString} onBlur={(e)=>{onChange&&onChange( {Id:" " , Name:e.target.value});inputIdRef.current.value = e.target.value }} onChange={(e)=>{setValueName(e.target.value)}}   ref={inputRef}  name="browser" className={"text-box__box form-control" + " browser"+name} type={'text'} id={"browser"+name} disabled={disabled} />
        }
       
        <div className="text-box__msg-wrapper">
         {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
         {required && <span className={`${rquird ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsRequired", [""])}</span>}
        </div>    
 
      </div>
    </>
  )
}