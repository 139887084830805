import { trans } from "utils/helpers";
import { CheckableButton } from "../CheckableButton/CheckableButton"
import "./style.scss"
interface CheckableButtonListPropsType {
    checkableBtnLabelList: string[],
    checkableListLabel?: string,
    value?: string,
    verticalDirection: boolean,
    checkableButtonName?: string, // اگر چک باکس باشد نیازی به نام ندارد
    checkbaleType: "checkbox" | "radio",
    useTrans?:boolean,
    disable?:boolean,
    required?:boolean,
    mode?:string,
}
export const CheckableButtonList = ({mode,disable=false,required=false,value,useTrans=true, checkableBtnLabelList, checkableListLabel, verticalDirection, checkableButtonName, checkbaleType }: CheckableButtonListPropsType) => {
    return (
        <div className="checkable-btn-list-wrapper">
            
            {checkableListLabel ? <label className="checkable-btn-list-wrapper__label">{useTrans ? trans(checkableListLabel) : checkableListLabel}</label> : null}
            {required && <span className="text-danger me-1">*</span>}
            <div className={`checkable-btn-list ${verticalDirection == true ? "checkable-btn-list--vertical" : ""}`} >
                <div style={{display: "flex",justifyContent: "space-between", width: "100%", marginTop: "-6px"}}>

                {checkableBtnLabelList.map((label, index) => (
                    <CheckableButton defaultChecked={value==label} checked={value==label} readOnly={disable ? true : false}  useTrans={useTrans} value={label} mode={mode} labelText={label} inputName={checkableButtonName} type={checkbaleType} key={label + index} />
                    /*                     <CheckableButton  defaultChecked={index ==0} useTrans={useTrans} value={label} mode={mode} labelText={label} inputName={checkableButtonName} type={checkbaleType} key={label + index} />
                    */
                ))}
                </div>
            </div>
        </div>
    );
}