import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getListReferenceSubject = (async (searchString ,orderBy) => await http({
    url: ENDPOINTS.cartable.referenceSubject.browse,
    method: 'get',
    params:{
        searchString : searchString ,
        orderBy:orderBy
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getReferenceSubject = (async (Id) => await http({
    url: ENDPOINTS.cartable.referenceSubject.load,
    method: 'get',
    params: {Id : Id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const postReferenceSubject = (data) => http({
    url: ENDPOINTS.cartable.referenceSubject.browse,
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const putReferenceSubject = (data) => http({
    url: ENDPOINTS.cartable.referenceSubject.browse,
    method: 'put',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})
export const deleteReferenceSubject = (id) => http({
    url: ENDPOINTS.cartable.referenceSubject.browse,
    method: 'delete',
    params:{
        id:id
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})
