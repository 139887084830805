import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { getAllEvents } from "./api";
import './style.scss';
import { NotificationConnection } from "utils/notificationHub";
import { TabBar } from "components/Tabbar/Tabbar";
import {TabEventNotification} from './components/TabEventNotification';
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";

export const EventNotification = () => {
  
  const [response, setResponse] = useState<any>(null);
  let content = [

  { title: "calendarEvents",disable:false , icon: (<FontAwesomeIcon icon={[ICON_TYPE, "list-check"]} style={{width:"15px",height:"15px"}}/>),body: (<TabEventNotification onHide={()=>handleClose()}  data={response?.calendarEvents}/>), },
  { title: "organizationEvent",disable:false , icon: (<FontAwesomeIcon icon={[ICON_TYPE, "calendar-days"]} style={{width:"15px",height:"15px"}}/>),body: (<TabEventNotification onHide={()=>handleClose()} data={response?.organizationEvent}/>), },
  { title: "reminders",disable:false , icon: (<FontAwesomeIcon icon={[ICON_TYPE, "bell"]} style={{width:"15px",height:"15px"}}/>),body: (<TabEventNotification onHide={()=>handleClose()} data={response?.reminders}/>), },
  ];
  const [showList, setShowList] = useState<boolean>(false);
  const [NotificationConnectionState, setNotificationConnectionState] = useState<any>(null);
  const [curentTab, setCurentTab] = useState<any>("personalEvent")
  const [responseContent,setResponseContent] = useState<any>(content)


  const handleClose = ()=>{
    setShowList(false)
  }

  const getAllEventsAsync =async ()=>{
    try {
            const res = await getAllEvents()
            setResponse(res)
        } catch (error) {
            
        }
  }
  

  useEffect(() => {
    getAllEventsAsync()
    setNotificationConnectionState(NotificationConnection);
  }, []);


  useEffect(() => {
    if(response){
       content.reverse().map(item=>{
        if( response[item.title] ==undefined || response[item.title].length==0){
          item.disable = true
        }else{
          setCurentTab(item.title)
        }
      })
      setResponseContent(content.reverse())
    }
  }, [response]);

  useEffect(() => {
    if (NotificationConnectionState ) {
            NotificationConnectionState.on('NewEvent',(text ) => { 
              if(text!="BulletinBoard") {
                getAllEventsAsync()
            }      
          });
    }
  }, [NotificationConnectionState]);


  
  const tabChangeHandle = (tab) => {
    setCurentTab(tab)
  } 

  const handleNotif = (e) =>{
    e.preventDefault();
    if([...response?.reminders , ...response?.calendarEvents].length <= 0){
      Toast(trans("msg_notfound"), "warning")
    }else{
      setShowList(true);
    }
  }

  return (
    <>
{ (response&&[...response?.reminders , ...response?.calendarEvents].length > 0) &&
<li className="header-itemsIcons">

      <button  
        className="Event-btn"
        onFocus={() => setShowList(true)}
       >
        {
        <a className="position-relative"  onClick={(e)=>{handleNotif(e)}} >
            <FontAwesomeIcon icon={[ICON_TYPE, "calendar-days"]} />
            {(response&&[...response?.reminders , ...response?.calendarEvents].length > 0) ? <span className="badgeCartable">{response?.reminders.length +response?.calendarEvents.length }</span>   :<></> }
        </a>       
        }
    {(showList && response&&[...response?.reminders , ...response?.calendarEvents].length > 0) &&
    <>
      <div className="fullscreen-bg-modal" />
      <section className='Event-menu '>
          <TabBar showWithIcon={true} addDisable={false} tabChange={tabChangeHandle} content={responseContent} curentTab={curentTab} defaultTab={"personalEvent"} />
      </section>
    </>
    }
      </button>
      </li>}
    </>
  );
};
