import { Button } from 'components/Button/Button';
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { SelectBox } from "components/Select/Select";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { PriceInput } from "./PriceInput";
import { putPriceListDetailBatchApi } from './api';
import { FormWithTitle } from 'components/FormWithTitle/FormWithTitle';
import { Message } from 'components/Message/Message';

interface Prop {
    resetForm?: boolean,
    onHide: () => void,
    PriceListObj?: any,
    mode:string,
    reload:()=>void,
}

export const PriceListGroupEditForm = ({ reload,onHide, mode ,PriceListObj}: Prop) => {
    const [resetForm, setResetForm] = useState(false);
    const [userChanges, setUserChanges] = useState({});
    const [Price,setPrice] = useState<any>(false)
    const [needToCall,setNeedToCall] = useState<any>(false)



    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);



    const submit = async(event) => {
        event.preventDefault();
            let data:any ={
                "PriceListId": PriceListObj.Id,
                [Price ? "UnitPrice":"Percent"] : Price ? +event.target.Price.value :event.target.Percent.value,
                "DiscountPercent":(  event.target.DiscountPercent.value =="" ) ? null : +event.target.DiscountPercent.value  ,
                 "RoundingPlace": +event.target?.RoundingPlace?.value,
                "MinOrderQty": +event.target.MinOrderQty.value,
                MaxOrderQty: +event.target.MaxOrderQty.value,

            }
            if(needToCall) delete data.RoundingPlace
            
            try {
                await putPriceListDetailBatchApi(data)
                reload()
                onHide()
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
            } catch (error) {
                onHide()
            }
    }


    useEffect(()=>{
        if(resetForm){
            setUserChanges({})
        }
    },[resetForm])
    return (
        <>    
 
               
        <> 
         <form className=" " onSubmit={submit}>        
         <FormWithTitle title="filter">
            <div className='row '>
                <div  className="col-md-3 mr-2 col-sm-12" style={{display:"flex" , flexDirection:"row"}}  >
                    <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}}   textboxName={"MinOrderQty"} textboxType="number" resetForm={resetForm} labelText={"MinOrderQty"} mode={mode}  defaultValue={ ""} />       
                </div>  
                <div  className="col-md-3 col-sm-12" style={{display:"flex" , flexDirection:"row"}}  >
                    <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}}   textboxName={"MaxOrderQty"} textboxType="number" resetForm={resetForm} labelText={"MaxOrderQty"} mode={mode}  defaultValue={""} />       
                </div>  
            </div>
         </FormWithTitle> 
         <FormWithTitle title="price">
         <Message message={ `${trans("Help_PricePercentUnmodified")}
                            ${trans("Help_PriceDecreaseNegative")}
                            ${Price? trans("Help_FinalPriceFixed"):trans("Help_FinalPricePercent")} 
         ` } type={"instruction"} />
            <div className="card" >
                     <div className="card-body">
                        <div className="col-4 mt-0">
                        <CheckableButton
                            onchange={setPrice}
                            type={'checkradio'}
                            labelText={ trans("Price")}
                            labelFalseText={ trans("Percent")}
                            defaultChecked={Price }
                            checked={Price }
                            inputName='Discount'
                            mode={mode}
                            />
                        </div>
                        
             <div className="row">
                {(Price)?
                    <>
                        <div  className="col-md-3 col-sm-12" style={{display:"flex" , flexDirection:"row"}}  >
                            <PriceInput onChange={(e)=>{if(e?.Id==-1){setNeedToCall(true)}else{setNeedToCall(false)} }} Change={(e)=>{setUserChanges({...userChanges , ...e})}}   name={"Price"}  resetForm={resetForm} label={"Price"} mode={mode}  value={ ""} />       
                        </div>
                        <div  className="col-md-3 col-sm-12" style={{display:"flex" , flexDirection:"row"}}  >
                        <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}}   textboxName={"DiscountPercent"} textboxType="number" resetForm={resetForm} labelText={"DiscountPercent"} mode={mode}  defaultValue={""} />          
                    </div> 
                    {!needToCall &&
                    
                    <div className="col-lg-2 col-xxl-2 col-sm-3 mt-1">
                       
                        <EnumerationTypeSelect
                           mode={mode} 
                           type="SelectBox" 
                           label="PriceRoundingPlace"
                           name="RoundingPlace"
                           enumTypeSelect="PriceRoundingPlace"
                           value={""} />
                    </div> 
                    
                    } 

                    </>
                    :
                    <>
                    <div  className="col-md-3 col-sm-12" style={{display:"flex" , flexDirection:"row"}}  >
                        <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}}   textboxName={"Percent"} textboxType="number" resetForm={resetForm} labelText={"Percent"} mode={mode}  defaultValue={""} />          
                    </div>  
                        <div  className="col-md-3 col-sm-12" style={{display:"flex" , flexDirection:"row"}}  >
                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}}   textboxName={"DiscountPercent"} textboxType="number" resetForm={resetForm} labelText={"DiscountPercent"} mode={mode}  defaultValue={ ""} />          
                        </div>  
                    <div className="col-lg-2 col-xxl-2 col-sm-3 mt-1">
                        <EnumerationTypeSelect
                           mode={mode} 
                           type="SelectBox" 
                           label="PriceRoundingPlace"
                           name="RoundingPlace"
                           enumTypeSelect="PriceRoundingPlace"
                           value={""} />
                    </div>
                       </>
                }


                </div>
                    </div>
                     
                </div>   
         </FormWithTitle> 

                {  mode !="view" ?
                          <div className="col-lg-12 mt-1 btns_form">
                          <Button value="Save" btnType="submit" />
                          <Button btnType="cancel" value="Cancel" onClick={onHide} />
                          </div>
                          :
                          <div className="col-lg-12 mt-1 btns_form">
                          <Button btnType="primary" value="Close" onClick={onHide}/>
                          </div>
                      }     
            </form >   
          </>   
        
        </>
    )
}