import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";


export const getReturnSalesInvoiceNo = (async (docsClassificationId) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesReturnInvoice.nextInvoiceNo,
    method: 'get',
    params : {
        docsClassificationId:docsClassificationId
    }
})
    .then((response) => (response.data))

)