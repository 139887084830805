import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { SharingCenterArticleUserForm } from "./SharingCenterArticleUserForm";
import { deleteSharingCenterArticleUser, getSharingCenterArticleUserList } from "./api";

export const SharingCenterArticleUser = () => {
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showActionForm, setShowActionForm] = useState<boolean>(false);
    const [showSharingCenterArticleUserForm, setShowSharingCenterArticleUserForm] = useState<boolean>(false);
    const [forceUpdate, setForceUpdate] = useState(true); // integer state
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [mode, setMode] = useState<string>();
    const [showAttachment, setShowAttachment] = useState(false);
    const [loading, setLoading] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [filterlist, setFilterlist] = useState<any>("");
    const [pages, setPages] = useState<number>(1);
    

    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("SharingCenterArticleUser"));break;
            case "refresh": setForceUpdate(!forceUpdate) ;break;
            case "create":handleCreateAction(); break;
            case "remove": if(selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "view": if(selectedObj?.Id) handleViewRow(); break;
            case "edit": if(selectedObj?.Id) handleEditRow(); break;
            case "action":if(selectedObj?.Id){setMode("edit");setShowActionForm(true)}; break;
            case "help": alert("call api help"); break;
            case "download":  break;
            case "attach": if (selectedObj?.Id) {
                setMode("edit")
                setShowAttachment(true)
            } else {
                Toast(trans("Msg_SelectRow"), "warning")
            } break;
          
        }
    };
    
    
 
    const searchHandler = (str) => {
        setPageNum(1);
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }

    const handleGoPage = (value) => {
        if (value) {
          if (value < 1 || value > pages) { }
          else setPageNum(+value);getDataAsync(+value);
        } else {
    
        }
      };

    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getDataAsync(pageNum + 1);
          };
      };
      const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getDataAsync(pageNum - 1);
          };
      };


    async function getDataAsync(PageNum =pageNum) {
        setLoading(false);
        setResponse(null)
        let pageSizeChangedByUser = getPageSize("SharingCenterArticleUser" )
        setPageSize(pageSizeChangedByUser)
        try {
          const res = await getSharingCenterArticleUserList(PageNum, pageSizeChangedByUser ,orderBy.length == 0 ?null:  orderBy.join(",") ,search ,filterlist );
          setResponse(res.Data);
          setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
          setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        
         }
        setLoading(true);
      }
    
    const handleCreateAction = () => {
        setMode("create")
        setShowSharingCenterArticleUserForm(true)
    }
    const handleEditRow = () => {
        setMode("edit");
        setShowSharingCenterArticleUserForm(true)
       
    }
    const handleDeleteRow = async() => {
        try {
            await deleteSharingCenterArticleUser(selectedObj.Id)
            setForceUpdate(!forceUpdate)       
        } catch (error) {
            
        }
    }

    const handleViewRow = () => {
        setMode("view")
        setShowSharingCenterArticleUserForm(true)
    }

    const selectedObjCallback = (obj, index) => {
        setSelectedObj(obj);
    }

    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("SharingCenterArticleUser" ,e.currentTarget.value )
        getDataAsync(1)
      };

      const handleShowForm = (e) => {
        if(e == true){
            setShowSharingCenterArticleUserForm(false) ;
            setForceUpdate(!forceUpdate)
        }else{
            setShowSharingCenterArticleUserForm(false)
        }
    }
      
    useEffect(() => {
        getDataAsync();
      }, [forceUpdate , search ,orderBy ,filterlist  ]);


    return (
        <div className="card p-3">
            <Toolbar
                id={selectedObj?.Id}
                handleClickToolbar={clickToolbarCallback}
                handleSearch={searchHandler}
                emptyList={response?.TotalRecords == 0}
                view={true}
                refresh={true}
                attach={false}
                search={true} 

                /> 
            <div className="col-lg-12 mt-0" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                <div className="list-card card">
                    <div className="list-card__body">
                        {loading ?
                            response?.Data?.length > 0 ?
                                 <>
                                     <DataGrid

                                         handlefiltering={(str)=>{setFilterlist(str)  }}
                                         filterStr={filterlist}
                                         parentName={"SharingCenterArticleUser"}
                                         orderFormat={setOrderBy}
                                         orderFormatList={orderBy}
                                         startIndex={1}
                                         theadData={Object.keys(response.Data[0])}
                                         tbodyData={response.Data}
                                         minCellWidth={80}
                                         selectedRowObjectCallback={selectedObjCallback}
                                         handleEdit={handleEditRow}
                                         handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                         handleView={handleViewRow}
                                         totalRecords={response.TotalRecords}
                                         pageNum={pageNum}
                                         pageSize={pageSize}
                                         HandleNextPage={handleNextPage}
                                         HandlePrevPage={handlePrevPage}
                                         handlechangePageSize={handleChangePageSize}
                                         first={() => {getDataAsync(pages) ; setPageNum(pages)}}
                                         end={() => {getDataAsync(1) ; setPageNum(1)}}
                                         handleGoPage={handleGoPage}
                                     />
                                 </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                                : <Loading />
                        }
                    </div>
                </div>

                {showSharingCenterArticleUserForm &&
                    <ModalForm mode={mode} title={selectedObj?.Title} onHide={() => { setShowSharingCenterArticleUserForm(false)}} show={showSharingCenterArticleUserForm} >
                        <SharingCenterArticleUserForm SharingCenterArticleData={selectedObj}  />
                    </ModalForm>
                }


            </div>

        </div>
    )
}