import { SelectBox } from "components/Select/Select";
import { InputHTMLAttributes, useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getApplications } from "./api";

import { InputLoad } from "components/InputLoading/inputLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { deleteProcessApplication, getProcessApplication, postProcessApplication } from "pages/OfficeAutomation/Settings/ProcessSetting/api/api";
import "./style.scss";
import { ICON_TYPE } from "utils/constants";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
interface ApplicationProp extends InputHTMLAttributes<HTMLInputElement> {
  type: "SelectBox" | "Radio" | "CheckBox";
  label?: string;
  PRJ_UID?: string;
  name: string;
  value?: any;
  mode?: any;
  valueList?: any;
  required?: boolean;
  Change?: (e) => void,
  ApplicationListCallback?: (e) => void,
  returnObj?:any
}
export const Application = ({ type,returnObj=false, label,PRJ_UID, name, value,valueList ,  mode ,required,Change,ApplicationListCallback }: ApplicationProp) => {
  const [optionsApplication, setOptionsApplication] = useState<any>();
  const [errcode, setErrcode] = useState<any>();
  const [app, setApp] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<any>([]);
  const layoutContext = useContext(LayoutContext);
  let Default:any = value
  let options_Application_values:any =[];
  let options_Application_keys:any =[];

  const ApplicationApiAsync = async ()=>{
    try {
      const res = await getApplications()

      setOptionsApplication(res.Data)
      if(PRJ_UID){
        try {
        const response = await getProcessApplication(PRJ_UID)
        if(response.Data != null){
       let Apps = Object.keys(response.Data).map((item , index)=>{
            return {Id:item , Name:trans(response.Data[item])}
        })

        setSelectedItem(Apps)               
          }
        } catch (error) {
        setSelectedItem([])               
          
        }

      }else if(valueList && valueList.length>0) {
        let Apps:any= []
        
        res.Data.map(item=>{
          if(valueList.includes(item.Id)){
              Apps.push({Id:item , Name:item.Name})
          }
        })
        setSelectedItem(Apps) 
      }else{
        setSelectedItem([])               

      }

    } catch (error:any) {
      setErrcode(error.response.status)

    }
  }


  function addOption(e) {
    e.stopPropagation()
    e.preventDefault()
    let Name 

    if(app || options_Application_keys[0]){
     let Id = app?.target?.value ? app?.target?.value :options_Application_keys[0]
     let query =  {
      applicationId:Id ,
      PRJ_UID : PRJ_UID 
    }
     
    optionsApplication.forEach(item => {
      if(item.Id == Id){
        Name = item.Name 
      }
    });

    let obj={}
    if(ApplicationListCallback){
      let selectList = JSON.parse(JSON.stringify(selectedItem))
      let listId = selectList.map(item=>{
        return  returnObj?
        obj={
...obj,
          [item.Id]:item.Name
        }
        :  item.Id
      })
   
      
      ApplicationListCallback(returnObj?
        [{...obj, [Id]:Name}]
        :[...listId, Id])
      
      
      setSelectedItem(selectList.concat({Id:Id , Name:Name}))

    }else{ sendDataasync(query ,{Id:Id , Name:Name} )}
    }

  }

  const sendDataasync = async (e , obj)=>{
    try {
      const respons = await postProcessApplication(e)
      let selectList = JSON.parse(JSON.stringify(selectedItem))
      setSelectedItem(selectList.concat([obj]))
    } catch (error) {
     
    }


  }

  async function deleteaOption(obj) {
    if(ApplicationListCallback){
     
    let selectList:any  = [ ]
      selectedItem.map((e)=>{
        if(e.Id != obj.Id){
          selectList.push(e)
        }
      })
      setSelectedItem(selectList)
    }else{
      let query ={
        applicationId: obj.Id,
        PRJ_UID : PRJ_UID
     }
  
      try {
        const response = await deleteProcessApplication(query)
        ApplicationApiAsync()
      } catch (error) {
        
      }
    }
}


  useEffect(() => {
  ApplicationApiAsync()
  }, [layoutContext.currentUserPosition.PositionId ,layoutContext.currentApplication]);

  if (optionsApplication) options_Application_values = optionsApplication.map(item => {
    return item.Name
  })
  if (optionsApplication) options_Application_keys = optionsApplication.map(item => {
    return item.Id
  })
  if (value && optionsApplication && options_Application_keys.indexOf(value) != -1) {
    Default = value
  } else if (optionsApplication && options_Application_values.indexOf(value) != -1) {
    Default = options_Application_values.indexOf(value) + 1
  }
  
  

  return (
    <>
     {label ? <label className="Application__label">{trans(label)}</label> : null}
      {true ?
            <>
            <form style={{display:"flex" , alignItems:'center' ,flexDirection:"row",width:"100%"}}  >
              <div style={{width:"90%"}} >
              <SelectBox  
                onChange={(e) => {setApp(e);e.target.value !=(value?value:options_Application_keys[0]) ?Change&&Change({[name] :e.target.value}):Change&&Change({[name] :null})} } 
                required={required}
                mode={mode}
                lable="Application"
                selectType="select"
                value={Default}
                options={options_Application_values}
                val={options_Application_keys}
                name={name}
              ></SelectBox>                  
              </div>
              {mode!="view"&&
                <div  style={{marginTop:"22px" , width:"10%"}}>
                  <Button Icon={true}  value={<button onClick={addOption} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "check"]}    size="2x"  /></button>} btnType="submit"  />
                </div>
                }
             </form>
              <div className="group-person__selected-options-wrapper">
              { selectedItem.map((item, index) => (
            <div className="group-person__selected-option form-control-custom" key={item.Id + index}>
                <div className="group-person__defult-selected-option" data-key={item.Id}>
                  {(!ApplicationListCallback) ? 
                    item.IsDefult ?
                    <FontAwesomeIcon icon={[ICON_TYPE, "star"]} className="text-primary" />
                    :
                   <FontAwesomeIcon icon={["far", "star"]} className="text-primary"/>
                   :
                   <></>
                  }
                   
                </div>
                <div style={{marginRight:(ApplicationListCallback)?'0':"13px"}}>
                 {trans(item.Name) } 
                </div>
                
                <div className="group-person__delete-selected-option" data-key={item.Id} onClick={()=>{deleteaOption(item)}}>
                    <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                </div>
            </div>
             ))}
           </div>

            </>
        :<InputLoad  msg={errcode}  required={required}  labelText={label}  onClick={ApplicationApiAsync}/> 
      }
      <div className="text-box__msg-wrapper">

        
      </div>
    </>
  );
};


