import { Button } from "components/Button/Button";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getPersonDetailCode, postPerson } from "./api";
import { Loading } from "components/Loading/loading";

export const CustomersUserForm = ({hide, mode ,personId,personName ,reload}) => {
    const [email, setEmail] = useState<any>();
    const [userName, setUserName] = useState<any>();
    const [loading, setLoading] = useState<any>(false);
    const [userChanges, setUserChanges] = useState({});



    async function findDetailCode(e) {
        try {
            setLoading(false)
        if(e){
            const personDetailCode = await getPersonDetailCode(e);
            setEmail(personDetailCode.data.Data.Email)
            setUserName(personDetailCode.data.Data.UserName)
        }

        } catch (error:any) {
            setUserName(error.response.data.Data)
            setEmail(null)

        }
        setLoading(true)
    }

    const sendDataAsync = async (userData, personId ,UserName) => {
        try {
        await postPerson(personId,userData);
        sessionStorage.setItem("changingforms" , "false");
        setUserChanges({});
        hide();
        reload();
        } catch (error2) {
        }
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                hide()
            });
        }else{
            hide()
        };


    }

    const formSubmit = (event) => {
        event.preventDefault()
            const userData = {
                UserName: event.target.UserName.value,
                Email: email  
            }
            sendDataAsync(userData,personId , event.target.UserName.value );
        
    }
    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){
            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);
 

    useEffect(() => {
        if (mode != "create") {
            setUserChanges({});
        }
        findDetailCode(personId)
      }, [personId]);

    return (
        <div className="card p-3">
            {loading ?
            <form onSubmit={formSubmit}>
                <div className="container-fluid">
                    <>
                    <div className="row my-1">
                        <div className="col-12 col-sm-6 my-1">
                        <Textbox
                            textboxType="text"
                            textboxName="Name"
                            labelText="person"
                            mode={"view"}
                            defaultValue={personName}
                            />
                        </div>  
                        <div className="col-12 col-sm-6 my-1">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="userName"
                                textboxName="UserName"
                                labelText="UserName"
                                mode={"view"}
                                defaultValue={userName}
                                required={true}
                     
                            />
                        </div>
                    </div>         
                    </>
                    <div className="row my-1 d-flex justify-content-end">
                        {(mode != "view"&&email) ?
                            <div className="col-4 d-flex justify-content-end">
                                <Button btnType="submit" value={"create"} />
                                <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                            </div>
                            :
                            <div className="col-4 d-flex justify-content-end">
                                <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                            </div>
                        }
                    </div>
                </div>
            </form>

            : <Loading/>
            }
        </div>
    )
}





