import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { TableTree } from "components/TableTree/TableTree";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { trans } from "utils/helpers";
import { getOrganizationArchive } from "./api/api";
import { ArchiveFolderForm } from "./components/ArchiveFolderForm/ArchiveFolderForm";
import { ArchiveForm } from "./components/ArchiveForm/ArchiveForm";
import { DeleteOrganizationalArchive, DeleteOrganizationalArchiveDetail, getorganizationalArchiveAccess, sendorganizationalArchiveAccess } from "./components/ArchiveRules/api";
import { BoardAccess } from "pages/OfficeAutomation/Features/BulletinBoard/Boards/components/BoardAccess";

// reload Dont work
export const ArchiveStructure = () => {
  const [data, setData] = useState<any>()
  const [forceUpdate, setForceUpdate] = useState<boolean>(false)
  const [selectedObj, setSelectedObj] = useState<any>()
  const [idCallback, setIdCallback] = useState<any>(null)
  const [mode, setmode] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false);
  const [ShowArchiveFolder, setShowArchiveFolder] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<any>(0);
  const [showAccess, setShowAccess] = useState<any>(false);
  const [accessDataList, setAccessDataList] = useState<any>();
  const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});


  
  const clickToolbarCallback = (clickedButtonName: string) => {

    switch (clickedButtonName.toLowerCase()) {
      case "reference":
        alert("call api reference with Id :");
        break;
      case "print":
        alert("print ");
        break;
      // case "excel":
      //   alert("excel ");
      //   break;
      case "refresh":setForceUpdate(!forceUpdate);break;
      case "view":  if(selectedObj){if(selectedObj.ParentId==null && selectedObj.RootId == null ){setmode("view");setShowForm(2);}else{setmode("view");setShowForm(1);}}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "remove":  if(selectedObj){if(selectedObj.ParentId==null && selectedObj.RootId == null ){Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{DeleteHandler(2 )})}else{Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{DeleteHandler(1)})}}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "create": if(selectedObj){setmode("create");setShowArchiveFolder(!ShowArchiveFolder);}else Toast(trans("Msg_SelectRow"), "warning"); 
        break;
      case "edit": if(selectedObj){if(selectedObj.ParentId==null && selectedObj.RootId == null ){setmode("edit");setShowForm(2);}else{setmode("edit");setShowForm(1);}}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "assignrule": if (selectedObj.ParentId==null && selectedObj.RootId == null) getaccessListAsync(); break;
    }
  };  

  async function getaccessListAsync() {
    try {
      
          const accessList = await getorganizationalArchiveAccess(selectedObj.Id ); 
          setAccessDataList(accessList.Data)
          setShowAccess(true)
      
    } catch (error) {
      
    }

  }
  async function DeleteHandler(type) {
    try {

          if(type==1){
            const accessList = await DeleteOrganizationalArchiveDetail(selectedObj.Id ); 
          }else{
            const accessList = await DeleteOrganizationalArchive(selectedObj.Id ); 
          }
          if(selectedObj.ParentId != selectedObj.Id){
            setIdCallback(selectedObj.ParentId)
          }
          setForceUpdate(!forceUpdate);

    } catch (error) {
      

    }

  }



 async function accessDatahandler(data) {
    try {
      const accessList = data.map(item=>{
        item.OwnerId = selectedObj.Id 
          return item
      })
      await sendorganizationalArchiveAccess(accessList , "post"); 
      setAccessDataList(null)
      setShowAccess(false)
    
  } catch (error) {
    
  }
}


  const selectedCreateArchiveCallback = (e) => {
    setmode("create")
    setShowForm(e)
  }
  const setselecetrow = (e) => {
    setSelectedObj(e)
  }

  const ShowArchiveFolderShow = () => {
    setIdCallback(selectedObj?.Id)
    setShowArchiveFolder(false)
   
  }


  const handleShowForm = (e) => {
    if(e == true){
        setShowForm(0)
        setForceUpdate(!forceUpdate)
    }else{
        setShowForm(0)
    }
}

  async function getarchiveStructureAsync() {
    setLoading(false)
    try{

      const respons = await getOrganizationArchive(null)
      setData(respons.Data)
      setCatchMessage({type:"info",msg:"NoDataForPreview"})
    } catch (error:any) {
        setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
    }
    setLoading(true)
  }


  

  useEffect(() => {
    setData(null)
    getarchiveStructureAsync()
  }, [forceUpdate]);


  useEffect(() => {
    if(idCallback){
      setIdCallback(null)
    }
  }, [idCallback]);



    return (
     <>
        {
            showForm==1 &&
              <ModalForm mode={mode} title="File" onHide={() => setShowForm(0)} show={showForm==1}>
                  <ArchiveFolderForm 
                    showForm={() =>{ShowArchiveFolderShow(); setShowForm(0);}} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                  />
              </ModalForm>
        }
        {
            showForm==2 &&
              <ModalForm mode={mode} title="Archive"  onHide={() => setShowForm(0)} show={showForm==2}>
                  <ArchiveForm 
                    showForm={handleShowForm} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                  />
              </ModalForm>
        }
        { showAccess &&
              <ModalForm mode={mode}   modalProfileKey={"Users"}   title={trans("Users")+" : " +selectedObj.Title } onHide={() => setShowAccess(false)} show={showAccess}>
                  <BoardAccess
                    showForm={setShowAccess} 
                    accessDatahandler={accessDatahandler} 
                    accessData={accessDataList}
                    mode={mode}
                  />
              </ModalForm>
        }
        <Toolbar 
          emptyList={data?.length == 0} 
          handleClickToolbar={clickToolbarCallback} 
          id={selectedObj} 
          handleCreateArchive={selectedCreateArchiveCallback}
          create={false} 
          search={false} 
          remove={true}
          edit={true}
          assignRule={true}
          excel={false}
        />
        <div style={{ overflowY: "auto", 
                height: "78vh",
                 scrollbarWidth: "thin"}}>
            {
              loading ? (
                data ?
                <div>
                  <TableTree
                    selectedRowObjectCallback={setselecetrow}
                    selectedRowObject={idCallback}
                    getChildrenUrl={ENDPOINTS.archive.organizationalArchive.Tree}
                    theadData={["Id" , "ParentId", "DetailList" , "Title" ]}
                    startIndex={2}
                    Titleparams={"Title"}
                    Childrenparams={'DetailList'}
                    defultShow={false}
                    Data={data}
                    parentName={"organizationalArchive"}
                  /> 
                </div>: <Message message={catchMessage.msg} type={catchMessage.type} />)
                : <Loading />
            }
        </div>
      </>
    )
}




