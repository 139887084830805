import { Button } from "components/Button/Button";

import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import {
  TREASURY_AND_ACCOUNTING_URL
} from "utils/constants";
import {
  ENDPOINTSTREASURYANDACCOUNTING
} from "utils/endpoints";
import { trans } from "utils/helpers";

import Datepicker from "components/DatePicker/DatePicker";
import { Person } from "components/Person/Person";
import { CurrencyDictionary } from "pages/InventoryAndSales/Sales/PriceList/components/CurrencyDictionary/CurrencyDictionary";
import { getBankAccountById, postBankAccount } from "./api";
import { DocsClassification } from "./components/DocsClassification/DocsClassification";

interface BankAccountsFormPropsType {
  selectedObj;
  showForm: (e: boolean) => void;
  mode;
  relod: () => void;
  handleCopyItem?: any;
}
export const BankAccountsForm = ({
  selectedObj,
  showForm,
  mode,
  relod,
  handleCopyItem,
}: BankAccountsFormPropsType) => {
  const [resetForm, setResetForm] = useState(false);
  const [response, setResponse] = useState<any>();

  const [loading, setLoading] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});
  const [bankAccountTypeId, setBankAccountTypeId] = useState<any>();
  const [bankId, setBankId] = useState<any>();
  const [customer, setCustomer] = useState<any>();
  const [registerDate, setRegisterDate] = useState<any>();
  const [docsClassification, setDocsClassification] = useState<any>();

  async function getBankAccountByIdAsync() {
    setLoading(false);

    try {
      if (selectedObj.id) {
        const res = await getBankAccountById(selectedObj.id);
        setResponse(res.Data);
      } else {
        setResponse(null);
      }
    } catch (err: any) {}
    setLoading(true);
  }

  const handleBankAccountsFormSubmit = (event) => {
    event.preventDefault();

    let data = {
      AccDetailId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      BankAccountTypeId: event.target.BankAccountTypeId?.value,
      OwnerPersonId: customer,
      CurrencyId: event.target.CurrencyId?.value,
      AccBranchId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      DocClassificationId: docsClassification,
      BankId: event.target.BankId?.value,
      AccountId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      BankAccountNo: event.target.BankAccountNo?.value,
      ShabaCode: event.target.ShabaCode?.value,
      BranchCode: event.target.BranchCode?.value,
      OwnerPersonName: event.target.OwnerPersonName?.value,
      OwnerPersonNameEN: event.target.OwnerPersonNameEN?.value,
      RegisterDate: registerDate,
      BankTransactionFeePolicyKey:
        event.target.BankTransactionFeePolicyKey?.value,
      HasChequeBook: event.target.HasChequeBook?.checked,
      HasPos: event.target.HasPos?.checked,
      FirstPeriodRemain: +event.target.FirstPeriodRemain?.value,
    };
    try {
      if (mode == "edit") {
        postBankAccount("put", {
          ...data,
          id: selectedObj.id,
          ActivityStatus: +event.target.ActivityStatus?.value,
        });
      } else {
        postBankAccount("post", data);
      }
      relod();
    } catch (error) {}
    cancel();
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        showForm(false);
      });
    } else {
      showForm(false);
    }
  }

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (selectedObj && mode != "create") {
      getBankAccountByIdAsync();
    } else {
      setLoading(true);
    }
  }, [selectedObj]);

  return (
    <div className="person-contact-form card">
      <div className="card-body">
        {loading ? (
          <div className="container-fluid">
            <form onSubmit={handleBankAccountsFormSubmit}>
              <div className="row ">
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="BankAccountNo"
                    labelText="BankAccountNo"
                    required
                    defaultValue={
                      response?.BankAccountNo ? response?.BankAccountNo : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="AccDetailId"
                    labelText="AccDetail"
                    defaultValue={
                      response?.AccDetailId ? response?.AccDetailId : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3">
                  {/* state */}
                  <DictionarySelectBox
                    onChange={(e) => {
                      setBankAccountTypeId(e);
                    }}
                    baseUrl={TREASURY_AND_ACCOUNTING_URL}
                    endPoint={
                      ENDPOINTSTREASURYANDACCOUNTING.Treasury
                        .BaseDefinitionAndSettingApi.BankAccountType.dictionary
                    }
                    label="BankAccountType"
                    name="BankAccountTypeId"
                    value={
                      response?.BankAccountTypeId
                        ? response?.BankAccountTypeId
                        : ""
                    }
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3">
                  {/* state customer */}
                  <Person
                    name={"OwnerPersonId"}
                    label="OwnerPerson"
                    setPerson={setCustomer}
                    resetForm={resetForm}
                    value={
                      response?.OwnerPersonId ? response?.OwnerPersonId : ""
                    }
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="OwnerPersonName"
                    labelText="OwnerPersonName"
                    defaultValue={
                      response?.OwnerPersonName ? response?.OwnerPersonName : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="OwnerPersonNameEN"
                    labelText="OwnerPersonNameEN"
                    defaultValue={
                      response?.OwnerPersonNameEN
                        ? response?.OwnerPersonNameEN
                        : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>


                <div className="col-lg-2 col-sm-3">
                  <CurrencyDictionary
                    name="CurrencyId"
                    label="Currency"
                    resetForm={resetForm}
                    value={response?.CurrencyId ? response?.CurrencyId : ""}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3">
                  {/* state bankId*/}
                  <DictionarySelectBox
                    onChange={(e) => {
                      setBankId(e);
                    }}
                    baseUrl={TREASURY_AND_ACCOUNTING_URL}
                    endPoint={
                      ENDPOINTSTREASURYANDACCOUNTING.Treasury.BaseInformationApi
                        .Bank.dictionary
                    }
                    label="Bank"
                    name="BankId"
                    value={response?.BankId ? response?.BankId : ""}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="ShabaCode"
                    labelText="ShabaCode"
                    required
                    defaultValue={
                      response?.ShabaCode ? response?.ShabaCode : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="BranchCode"
                    labelText="BranchCode"
                    defaultValue={
                      response?.BranchCode ? response?.BranchCode : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
             
                <div className="col-lg-2 col-sm-3 ">
                  {/* stateRegisterDate */}
                  <Datepicker
                    lable="Date"
                    setIsoTime={(isoTime: string) => {
                      setRegisterDate(isoTime);
                    }}
                    val={response?.RegisterDate ? response?.RegisterDate : null}
                    errorFlag={false}
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="BankTransactionFeePolicyKey"
                    labelText="BankTransactionFeePolicyKey"
                    defaultValue={
                      response?.BankTransactionFeePolicyKey
                        ? response?.BankTransactionFeePolicyKey
                        : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="number"
                    textboxName="FirstPeriodRemain"
                    labelText="FirstPeriodRemain"
                    defaultValue={
                      response?.FirstPeriodRemain
                        ? response?.FirstPeriodRemain
                        : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3">
                  {/* state docsClassification */}
                  <DocsClassification
                    label="Classification"
                    name="docsClassificationId"
                    baseUrl={TREASURY_AND_ACCOUNTING_URL}
                    endPoint={
                      ENDPOINTSTREASURYANDACCOUNTING.Treasury
                        .BaseDefinitionAndSettingApi.DocsClassification
                        .dictionary
                    }
                    resetForm={resetForm}
                    value={
                      response?.docsClassificationId
                        ? response?.docsClassificationId
                        : ""
                    }
                    onChange={(e) => {
                      setDocsClassification(e);
                    }}
                    mode={mode}
                  />
                </div>
                {mode !== "create" && (
                  <div className="col-sm-3 col-lg-2">
                    <EnumerationTypeSelect
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      name="ActivityStatus"
                      enumTypeSelect="ActivityStatus"
                      mode={mode}
                      label="Status"
                      value={response?.Status}
                    />
                  </div>
                )}
                <div className="">
                  <CheckableButton
                    defaultChecked={
                      response?.HasChequeBook ? response?.HasChequeBook : false
                    }
                    defaultValue={
                      response?.HasChequeBook ? response?.HasChequeBook : false
                    }
                    labelText={"HasChequeBook"}
                    inputName={"HasChequeBook"}
                    type="checkbox"
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 ">
                  <CheckableButton
                    defaultChecked={response?.HasPos ? response?.HasPos : false}
                    defaultValue={response?.HasPos ? response?.HasPos : false}
                    labelText={"HasPos"}
                    inputName={"HasPos"}
                    type="checkbox"
                    mode={mode}
                  />
                </div>
              </div>

              {mode != "view" ? (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button value="Save" btnType="submit" />
                  <Button
                    btnType="cancel"
                    value="Cancel"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              ) : (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button
                    btnType="primary"
                    value="close"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              )}
            </form>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};
