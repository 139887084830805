import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getAllItemTaxes = (id) => http({
    baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemTax.browse,
   method: "get",
   params: {
       id: id,
       
   }
}).then((res) => {
   return res.data
}).catch((err) => {
   return Promise.reject(err.response  );
});

export const postTax = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemTax.browse,
   method: 'post',
   data: data
}).then((res) => {
   return res.data
}).catch((err) => {
   return Promise.reject(err.response  );
});

export const removeItemTaxes = (list) => http({
    baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemTax.browse,
   method: "delete",
   data:list
 }).then((res) => {
   return res.data
 }).catch((err) => {
   return Promise.reject(err.response  );
 });