
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useContext, useEffect, useState } from "react";

import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm, UserContext } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { getRelatedList } from "pages/Secretariats/LettersPage/api/api";
import { downloadCSV, trans } from "utils/helpers";
import { RelatedForm } from "./component/RelatedForm";
import { GUID_EMPTY } from "utils/constants";

interface LetterRelatedPropsType {
    onHide?: () => void;
    getRelatedLetters: (data) => void;
    letterId:string;
    mode:any;
    inTab:any;
}

export const  LetterRelated = ({getRelatedLetters ,letterId ,mode,inTab }:LetterRelatedPropsType) => {


    let [response, setResponse] = useState<Response | any>(null);
    const [LetterRelatedId, setLetterRelatedId] = useState<string | any>(null);
    const [Total_records, setTotal_records] = useState<any>();
    const [relatedmode, setRelatedMode] = useState<string>();
    const [orderFormat, setOrderFormat] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [showrelated, setShowrelated] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [relatedlist, setRelatedlist] = useState<any>([]);
    const [selectList , setSelectList] = useState<any>([])
    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)

    const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);

    /* pagination area*/


    const addLettertoList = (newList) => {
        setLoading(false)
       
        setRelatedlist([...relatedlist ,...newList ])
        
        let List:any =[] ;
         [...relatedlist ,...newList ].forEach(item=>{
            if( item.Id !=GUID_EMPTY){
                List.push(
                      {
                        LetterRelatedId : item.Id ,
                        LetterRelationType : item.LetterRelationTypeEnum,
              
                    }
                )
            }
        })
        setTotal_records(List.length)
        getRelatedLetters(List)
        setLoading(true)


    }

    const getWithOrder = (order: string[]) => {
        setOrderFormat(order.join(","))
    }



    const selectedRowCallback = (obj, index) => {
        setLetterRelatedId(obj?.Id);
    };
    const selectListCallBack =(list)=>{
          
        setSelectList(list[1])
      }
    const handleRemove =()=>{
            setLoading(false)
            let removeListId=selectList.map(item=>{return item.Id})
            let newList:any = []
            let list:any = []
            relatedlist.map(item=>{
                if(!removeListId.includes(item.Id)){
                    newList.push(item)
                    list.push(                      {
                        LetterRelatedId : item.Id ,
                        LetterRelationType : item.LetterRelationTypeEnum,
              
                    })
                }
            })
            setResetMultiSelect(!resetMultiSelect)
            setSelectList([])
            setRelatedlist(newList)
            setTotal_records(newList.length)
            getRelatedLetters(list)
            setLoading(true)

          
       
      }

    const clickToolbarCallback = (clickedButtonName: string) => {

        switch (clickedButtonName.toLowerCase()) {
            case "finish": alert("call api finish " + letterId); break;
            case "current": alert("call api current " + letterId); break;
            case "archive": alert("call api archive " + letterId); break;
            case "view": if (LetterRelatedId) { setRelatedMode("view");  } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "print": alert("print ");break;
            case "remove":if(selectList.length >0){Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleRemove)}else{Toast("Msg_SelectRow" , "warning")}  ;break;
            case "excel":downloadCSV(relatedlist ,2 ,trans("LetterRelated"));break;
            case "related": setRelatedMode("create") ; setShowrelated(true); break;
        }
    };

    async function getRelatedlistAsync(letterId) {
        setLoading(false)

        try {                                                     
            const res = await getRelatedList(1, 20, letterId);
            setTotal_records(res.Data.Total_records)
            addLettertoList(res.Data.Data)
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)
    }
    useEffect(() => {
        setResponse([])
        if((letterId && mode=="view" && inTab =="LetterRelated" )||(letterId && mode=="edit"&& inTab =="LetterRelated" )){
            if(relatedlist.length ==0){
                getRelatedlistAsync(letterId)
            }
        }else{
            if(inTab =="LetterRelated"){
                setLoading(true)
            }
        }
    }, [letterId ,inTab]);


    return (

        <>{inTab == "LetterRelated" &&
        <div className="p-2">
        {mode != "view" && 
            <Toolbar  search={false} refresh={false} view={false} remove={mode!= "view"}   related={mode != "view"} emptyList={relatedlist?.length == 0} id={LetterRelatedId}    handleClickToolbar={clickToolbarCallback} />
        }
        {loading ?
            <div className="col-lg-12">
                <div className="list-card card">
                    <div className="list-card__body">
                        {
                        (relatedlist.length > 0 ?
                            <DataGrid
                                selectItem={(e) => {   }}
                                orderFormat={getWithOrder}
                                multiSelect={true}
                                selectList={selectListCallBack}
                                resetMultiSelect={resetMultiSelect}
                                parentName={"LetterRelated" }
                                startIndex={2}
                                theadData={Object.keys(relatedlist[0])}
                                scrollHeight={"350px"}
                                tbodyData={relatedlist}
                                minCellWidth={80}
                                selectedRowObjectCallback={selectedRowCallback}
                                totalRecords={Total_records}
                            />
                            : <Message message={catchMessage.msg} type={catchMessage.type} />)
                        }
                    </div>
                </div>
            </div>
             : <Loading />
         }
             {showrelated &&
             <ModalForm checkClose={false} mode={relatedmode} title="RelatedForm" onHide={() => setShowrelated(false)} show={showrelated}>
                 <RelatedForm letter={letterId} Relatedlistcallback={(e)=>{ setUserMadeChanges(true) ;addLettertoList(e)}} onHide={() => setShowrelated(false)} />
             </ModalForm>
             }
        </div>  
      }
        </>
    )
}