
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";

import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { downloadCSV, trans } from "utils/helpers";
import { ItemPropertiesList } from "./ItemPropertiesList";
import { removeItemProperties } from "./api";

interface ItemPropertiPropsType {
    onHide?: () => void;
    getPropertiItems?: (data) => void;
    mode:any;
    inTab?:any;
    selectedObj?:any;
    IsIndependent?:any;
}

export const  ItemProperties = ({getPropertiItems,selectedObj ,IsIndependent=false ,mode,inTab,onHide }:ItemPropertiPropsType) => {

    let keys, cartable = [];
    let  pages;
    let [response, setResponse] = useState<Response | any>(null);
    const [ItemPropertiId, setItemPropertiId] = useState<string | any>(null);
    const [Propertimode, setPropertiMode] = useState<string>();
    const [orderFormat, setOrderFormat] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [showitemPropertiesForm, setShowitemPropertiesForm] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [Propertilist, setPropertilist] = useState<any>([]);
    const [selectList , setSelectList] = useState<any>([])
    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)
    const [forceUpdate, setForceUpdate] = useState(false);

    let customFuction: any = []

    /* pagination area*/
    
    const addPropertitoList = (newItems) => {
        setLoading(false)     
        if(IsIndependent){
            let newPropertiList:any = []

            newItems.map(item =>{
                let checkList= Propertilist.find(p => p.Id == item.Id)
                    if(!checkList){
                        newPropertiList.push({ItemOtherPropertyId:item.Id , ItemId:selectedObj.Id}) 
                    }else{
                        Toast("msg_delete_useddata","error")
                    }
                })
            sendItemPropertiesAsync(newPropertiList)

        }else{
            let newPropertiList = Propertilist
            
            
            if(Propertilist.length == 0){
                setPropertilist(newItems)
                setResetMultiSelect(!resetMultiSelect)
                setSelectList([])
        
                getPropertiItems &&  getPropertiItems(newItems)
            }else{
                newItems.map(item =>{
                    let checkList= Propertilist.find(p => p.Id == item.Id)
                        if(!checkList){
                            newPropertiList = [...newPropertiList,item]
                        }else{
                            Toast("msg_delete_useddata","error")
                        }
                    })
                    setPropertilist(newPropertiList)
                
    
                     setResetMultiSelect(!resetMultiSelect)
                     setSelectList([])
                
                     getPropertiItems &&     getPropertiItems(newPropertiList)
    
            } 

            setLoading(true)
        }
    }

    const sendItemPropertiesAsync =async (list) => {
        setLoading(false)
        try {
        //  const res = await sendItemProperties(list)
          setShowitemPropertiesForm(false)
          setForceUpdate(!forceUpdate)
        } catch (error) {
          
        }
        setLoading(true)
    }
    const getItemPropertiesAsync =async () => {
        setLoading(false)
        try {
        //  const res = await getItemProperties(selectedObj.Id)
      //    setPropertilist(res.Data)
        } catch (error) {
          
        }
        setLoading(true)
    }

    const getWithOrder = (order: string[]) => {
        setOrderFormat(order.join(","))
    }
    const selectedRowCallback = (obj, index) => {
        setItemPropertiId(obj?.Id);
    };
    const selectListCallBack =(list)=>{
          
        setSelectList(list[1])
      }
    const handleRemove =async()=>{
        let removeListId=selectList.map(item=>{return item.Id})
        if(IsIndependent){
            
            setLoading(false)
            try {
                const res = await removeItemProperties(removeListId)
                setForceUpdate(!forceUpdate)
            } catch (error) {
              
            }
            setLoading(true)
            
        }else{
            setLoading(false)
            let newList:any = []
            Propertilist.map(item=>{
                if(!removeListId.includes(item.Id)){
                    newList.push(item)
                }
            })
            setResetMultiSelect(!resetMultiSelect)
            setSelectList([])
            setPropertilist(newList)
            getPropertiItems&& getPropertiItems(newList)
            setLoading(true)
        }

      
   
  }
    const clickToolbarCallback = (clickedButtonName: string) => {

        switch (clickedButtonName.toLowerCase()) {
            case "finish": alert("call api finish " ); break;
            case "current": alert("call api current " ); break;
            case "archive": alert("call api archive " ); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "view": if (ItemPropertiId) { setPropertiMode("view");  } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "print": alert("print ");break;
            case "excel": downloadCSV(Propertilist ,1 ,trans("ItemProperti"));break;
            case "create": setShowitemPropertiesForm(true);break;
            case "remove":if(selectList.length >0){Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleRemove)}else{Toast("Msg_SelectRow" , "warning")}  ;break;

        }
    };



    useEffect(() => {
        if(inTab ==="ItemProperties"||IsIndependent){
            getItemPropertiesAsync();

        }
    }, [forceUpdate ,inTab  ]);

    useEffect(() => {
        if(inTab ==="ItemProperties"){
        if(Propertilist?.length >0){ 
          let newList:any = []          
          Propertilist.map(item=>{
                    if(item != undefined){
                        newList.push(item) 

                    }
            })
            setPropertilist(newList)
            getPropertiItems&&  getPropertiItems(newList)
        }
        setLoading(true)
        }
    }, [ inTab]);
    

    if (Propertilist && Propertilist.length > 0 && Propertilist[0].Id!== undefined && Propertilist[0].Id.length >0 ) {
        keys = Object.keys(Propertilist[0]);
        cartable = Propertilist
    } else if (response && response?.length === 0) {
        keys = [];
        cartable = [];
    }
   


    return (
        < >
        {(inTab == "ItemProperties"||IsIndependent) && 
        <div className="p-2">

        {mode != "view" &&
          <Toolbar warehouse={false}  create={mode != "view"} remove={mode!= "view"}  emptyList={cartable?.length == 0} id={ItemPropertiId}   search={false} edit={false} refresh={IsIndependent}  view={false}  handleClickToolbar={clickToolbarCallback} />
        }   
         {loading ?
            <div className="col-lg-12">
                <div className="list-card card">
                    <div className="list-card__body">
                        {
                            (cartable.length > 0 && keys ?
                                <DataGrid
                                    customFuction={customFuction}
                                    selectItem={(e) => {   }}
                                    orderFormat={getWithOrder}
                                    multiSelect={mode != "view" ?true:false}
                                    selectList={selectListCallBack}
                                    resetMultiSelect={resetMultiSelect}
                                    parentName={"ItemProperties" }
                                    startIndex={1}
                                    theadData={keys}
                                    scrollHeight={"350px"}
                                    tbodyData={cartable}
                                    minCellWidth={80}
                                    selectedRowObjectCallback={selectedRowCallback}
                                
                                />
                                : <Message message={"NoDataForPreview"} type={messageType} />)
                        }
                    </div>
                </div>
            </div>
             : <Loading />
         }
             {showitemPropertiesForm &&
             <ModalForm modalHeight={70} mode={Propertimode} title="itemPropertiesForm" onHide={() => setShowitemPropertiesForm(false)} show={showitemPropertiesForm}>
                 <ItemPropertiesList  PropertiesListcallback={(e)=>{addPropertitoList(e)}}  onHide={() => setShowitemPropertiesForm(false)} />
             </ModalForm>
             }
        </div>
        }
        </>
    )
}