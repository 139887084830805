
import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { trans } from "utils/helpers";
import { http } from "utils/http";





export const postJobSendData = (data, processId) => http({
    method: "post",
    url: ENDPOINTS.cartable.JobSend.browse,
    data: data,
    params:{
        PRJ_UID : processId
    }
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});
export const putJobSendData = (data) => http({
    method: "put",
    url: ENDPOINTS.cartable.JobSend.browse,
    data: data,
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});


export const getJobSendData = ( Id) => http({
    url: ENDPOINTS.cartable.JobSend.load,
    method: "get",
    headers: { "Accept-Language": "en" },
    params:{
        JobId:Id
    }
    
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});