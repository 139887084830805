import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS } from "utils/endpoints";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getItemStoreListApi = (async (rootId) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.store,
    method: 'get',
    params:{
        rootId:rootId
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getItemGroupLabels = (async () => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.itemGroupDictionary,
    method: 'get',
    params:{

        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

export const loadCardImage = (async (attachId) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.attach,
    method: 'get',
    params:{
        attachId:attachId
        },
        responseType: "blob"
}).then((res) => {

    return  blobToBase64(res.data)
}).catch((err) => {
 
    return Promise.reject( err);
})

)