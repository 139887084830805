import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { REGEX } from "utils/regex";
import "./style.scss";

interface PriceProps {
  name: string;
  resetForm?: boolean;
  limited?: boolean;
  defaultValue?: number;
  label?: string;
  mode?: string;
  useTrans?: boolean;
  required?: boolean;
  displayLetters?: boolean;
  placeholder?: string | null;
  onChange?: any;
}
export const Price = ({
  placeholder,
  name,
  displayLetters = true,
  limited,
  useTrans = true,
  defaultValue,
  label,
  mode,
  resetForm,
  required,
  onChange,
}: PriceProps) => {
  const [PriceInputValue, setPriceInputValue] = useState<string>("");
  const [PriceValue, setPriceValue] = useState<any>();
  let options: any = [
    { text: "دلار", id: 2 },
    { text: "تومان", id: 0 },
    { text: "ریال", id: 1 },
  ];

  const [MsgShow, setMsgShow] = useState<boolean>(false);
  let [MsgText, setMsgText] = useState<any>(false);
  const [currency, setCurrency] = useState<any>(0);
  const [textPrice, setTextPrice] = useState<any>();
  const inputPriceRef = useRef<any>(null);
  const textPricesource = {
    0: null,
    1: "یک",
    2: "دو",
    3: "سه",
    4: "چهار",
    5: "پنج",
    6: "شش",
    7: "هفت",
    8: "هشت",
    9: "نه",
    10: "ده",
    11: "یازده",
    12: "دوازده",
    13: "سیزده",
    14: "چهارده",
    15: "پانزده",
    16: "شانزده",
    17: "هفده",
    18: "هجده",
    19: "نوزده",
    20: "بیست",
    30: "سی",
    40: "چهل",
    50: "پنجاه",
    60: "شصت",
    70: "هفتاد",
    80: "هشتاد",
    90: "نود",
    100: "صد",
    200: "دویست",
    300: "سیصد",
    400: "چهارصد",
    500: "پانصد",
    600: "ششصد",
    700: "هفتصد",
    800: "هشتصد",
    900: "نهصد",
  };

  const currencySource = {
    0: "",
    1: "هزار",
    2: "میلیون",
    3: "میلیارد",
    4: "تریلیارد",
  };

  function fixNumber(number: number) {
    if (number >= 0 && number < 10) return `0${number}`;
    return number;
  }

  function reverseString(str) {
    var splitString = str.split("");
    var reverseArray = splitString.reverse();
    var joinArray = reverseArray.join("");
    return joinArray;
  }

  function updatePriceInput(numvalue) {
    let value: any = numvalue + "";
    let text: any = [];
    let Price: any = reverseString(value.replaceAll(",", ""));
    if (Price.length <= 15) {
      if (
        value.replaceAll(",", "")[0] != "0" &&
        !Number.isNaN(+value.replaceAll(",", ""))
      ) {
        setMsgText(null);
        setMsgShow(false);
        let newPrice: any = "";
        let priceArray: any = [];
        let threeBox: any = [];
        Price.split("").forEach((item, index) => {
          if (index % 3 == 0 && index > 0) {
            priceArray.push(threeBox);
            threeBox = [];
            threeBox.push(item);
            newPrice = `${item},${newPrice}`;
          } else {
            threeBox.push(item);
            newPrice = `${item}` + newPrice;
          }
        });
        if (threeBox.length > 0) {
          priceArray.push(threeBox);
        }
        priceArray.reverse().forEach((box, indexBox) => {
          let boxText: any = [];
          let flag = false;
          box.reverse().forEach((item, itemIndex) => {
            if (flag) {
              boxText.push(null);
            } else {
              if (box.length - (itemIndex + 1) == 1 && +item == 1) {
                boxText.push(
                  textPricesource[
                    +item * Math.pow(10, box.length - (itemIndex + 1)) +
                      +box.reverse()[0]
                  ]
                );
                flag = true;
              } else {
                boxText.push(
                  textPricesource[
                    +item * Math.pow(10, box.length - (itemIndex + 1))
                  ]
                );
              }
            }
          });

          if (boxText.filter((el) => el != null).length == 0) {
          } else {
            text.push(
              `${boxText.filter((el) => el != null).join(" و ")} ${
                currencySource[priceArray.length - (indexBox + 1)]
              }`
            );
          }
        });
        setTextPrice(text.length > 0 ? text.join(" و ") + " ریال " : null);
        setPriceInputValue(newPrice);
        let MPrice: any;
      }
    } else {
      //setMsgText("Input value exceeds the limit")
      //setMsgShow(true)
    }
  }

  /*     function cheakPrice(event) {
        //let result = actualPriceRegex.test(event.target.value);
        if ( true) {
            setPriceInputValue(event.target.value);    
        } else {
            if(event.target.value.includes(":")){
                let Price:any = PriceInputValue.split(":")
                setPriceInputValue(`${fixNumber(+Price[0])}:${fixNumber(+Price[1])}`)
            }else{
                if(/^[0-9]+$/.test(event.target.value)){
                    setPriceValue(event.target.value)      
                    setPriceInputValue( minuteToHour(event.target.value))
    
                }else{
    
                    event.target.classList.add("shake");
                
                    event.target.value = PriceInputValue;
                }
            }
        }
    } */

  useEffect(() => {
    if (defaultValue) {
      updatePriceInput(defaultValue);
    }
  }, [defaultValue]);
  useEffect(() => {
    if (resetForm) {
      setPriceInputValue("");
      setPriceValue(0);
    }
  }, [resetForm]);
  useEffect(() => {
    /*   let actualPriceInput = (document.querySelector(`[name=${name}]`) as HTMLInputElement);
        actualPriceInput.value = PriceInputValue; */
    let Price: any = PriceInputValue.split(":");
    let MPrice: any;
    if (Price[1]) {
      MPrice = Price[0] * 60 + +Price[1];
    } else {
      MPrice = +Price[0];
    }

    setPriceValue(MPrice);
  }, [PriceInputValue]);
  return (
    <div className="Price">
      <OverlayTrigger
        key={label}
        placement="top"
        delay={{ show: 100, hide: 0 }}
        overlay={
          <Tooltip className="tooltip" id={`button-tooltip-${"checkable"}`}>
            {trans(label + "")}
          </Tooltip>
        }
      >
        <div>
          {label && (
            <label className="select-box__label showDots" htmlFor={name}>
              {useTrans ? trans(label) : label}
            </label>
          )}
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        key={label + "value"}
        placement="bottom"
        delay={{ show: 100, hide: 0 }}
        overlay={
          textPrice && textPrice != "" ? (
            <Tooltip className="tooltip" id={`button-tooltip-${"checkable"}`}>
              {textPrice}
            </Tooltip>
          ) : (
            <></>
          )
        }
      >
        <div>
          <input
            name={name}
            style={{ fontFamily: "revert" }}
            ref={inputPriceRef}
            required={required}
            //onMouseDown={(e)=>e.stopPropagation() }
            disabled={mode == "view" ? true : false}
            onInput={(event: any) => {
              updatePriceInput(event.target?.value);
            }}
            type="text"
            className={`action-form__Price-input form-control-custom  ${
              mode == "view" ? " disabled" : ""
            }`}
            id={name}
            value={PriceInputValue}
            placeholder={placeholder ? placeholder : ""}
            onChange={onChange}
          />
          {displayLetters && <div className="showDots">{textPrice}</div>}
        </div>
      </OverlayTrigger>

      <div className="text-box__msg-wrapper">
        <span
          className={`${
            MsgShow ? "show-msg" : "hide-msg"
          } text-box__regex-msg text-danger`}
        >
          {MsgText}
        </span>
      </div>
    </div>
  );
};
