import React from "react";
import { Link } from "react-router-dom";

export const UnAuthorizedPage = () => {
  //TODO ما باید یه اررور لیوت برای کل اررور ها داشته باشیم و این رو از اون استفاده بکنیم
  return (<div>
    <div>
      دسترسی غیر مجاز
    </div>
    
  </div>)
}