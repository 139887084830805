import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { REGEX } from "utils/regex";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface TimeProps {
    name: string,
    resetForm?: boolean,
    limited?: boolean,
    defaultValue?: number,
    max?: number,
    label?: string,
    IdString?: string,
    mode?: string,
    type?:"dateTime"|"Time" ,
    useTrans?:boolean,
    required?: boolean,
    Change?: (e) => void,
    onChange?: (e) => void,
}
export const Time = ({type ="Time" , Change ,onChange,  name,useTrans=true,IdString="", limited,max, defaultValue, label,mode, resetForm , required}: TimeProps) => {
    let actualTimeRegex = new RegExp(REGEX.actualTime.regex);
    const [timeInputValue, setTimeInputValue] = useState<string>(defaultValue ? minuteToHour(defaultValue) : "" );
    const [timeValue, setTimeValue] = useState<any>(0);
    const [minutesValue, setMinutesValue] = useState<any>(0);
    const [hourValue, setHourValue] = useState<any>(0);
    const [textTime, setTextTime] = useState<any>();


    let [MsgShow, setMsgShow] = useState<boolean>(false);
    let [MsgText , setMsgText] = useState<any>(false);

    
    function fixNumber(number: number) {
        if (number >= 0 && number < 10)
            return `0${number}`
        return number;
    }

    function minuteToHour(minute: number) {
        
        let hour = Math.floor(minute / 60);
        minute = minute % 60;
        return `${fixNumber(hour)}:${fixNumber(minute)}`;
    }

    function updateMinutesInput(event) {  
        let time:any = event.target.value
    if(  !Number.isNaN(+time)){
        if(time){
            if(time>60){
                event.target.setCustomValidity(trans(`Msg_Validation_ValidMaxMinutes`));
                setMsgText( trans(`Msg_Validation_ValidMaxMinutes`));
                setMsgShow(true);
                event.target.reportValidity();

            }else{
                if(hourValue ==="00" &&minutesValue ==="00"  ){
                }else{
                    
                  event.target.setCustomValidity("");
                  setMsgText("");
                  setMsgShow(false);

                }
            }

        }else{
        }
        setTextTime(((+time+ (hourValue ? +hourValue*60 : 0))+"دقیقه"))
      setMinutesValue(+time)
    }
    }
    function updateHourInput(event) {  
        let time:any = event.target.value
        if(  !Number.isNaN(+time)){

        if(type === "Time"){
            setHourValue(time)
            setTextTime(((+minutesValue+ +time*60)+"دقیقه"))


        }else{
            if(time>24){
                event.target.setCustomValidity(trans("Msg_Validation_ValueIsIncorrect"));
                setMsgText(trans("Msg_Validation_ValueIsIncorrect"));
                setMsgShow(true);
                event.target.reportValidity();

            }else{
                event.target.setCustomValidity("");
                setMsgText("");
                setMsgShow(false);

                setHourValue(time)
                setTextTime(((+minutesValue+ +time*60)+"دقیقه"))

            }


        }
    }
    }
    function cheakTime(event) {
        let check = false ;
        if(hourValue >=0 || minutesValue >=0){
            if(hourValue>=0){
                if(minutesValue!== undefined && minutesValue>=0){
                    if(max&&(+hourValue * 60 + +minutesValue>max)){
                        check = true
                        event.target.classList.add("shake");
                        setTimeout(() => {
                            event.target.classList.remove("shake");
                        }, 350);
                        event.target.value = timeInputValue;
                    }else{

                        setTimeValue(+hourValue * 60 + +minutesValue)      
                    }
                }else{
                    if(max&&(+hourValue * 60 >max)){
                        check = true

                        event.target.classList.add("shake");
                        setTimeout(() => {
                            event.target.classList.remove("shake");
                        }, 350);
                        event.target.value = timeInputValue;
                    }else{

                        setTimeValue(+hourValue * 60 )      
                    }
                }
                
            }else{
                if(max&&( +minutesValue>max)){
                    check = true

                    event.target.classList.add("shake");
                    setTimeout(() => {
                        event.target.classList.remove("shake");
                    }, 350);
                    event.target.value = timeInputValue;
                }else{

                    setTimeValue( +minutesValue)      
                }
                
            }
        }else{
            
            event.target.classList.add("shake");
            setTimeout(() => {
                event.target.classList.remove("shake");
            }, 350);
            event.target.value = timeInputValue;
            
        }
        if(check&&max){
            setHourValue((max-(max %60)) /60);
            setMinutesValue(max %60);
            setTextTime(((max)+"دقیقه"))
            setTimeValue(max);
        }else{
            defaultValue != hourValue*60 + minutesValue ? Change&&Change({[name] :timeValue}):Change&&Change({[name]:null});
        }
    }

    useEffect(() => {
        if (defaultValue){  
            setHourValue((defaultValue-(defaultValue %60)) /60);
            setMinutesValue(defaultValue %60);
            setTextTime(((defaultValue)+"دقیقه"))
            setTimeValue(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (resetForm){
            setTimeInputValue("")
            setTimeValue(0)
        }
    }, [resetForm])
    useEffect(() => {

        let time:any = timeInputValue.split(":")
        let Mtime:any
        if(time[1]){
         Mtime = (time[0]*60)+(+time[1])
        }else{
            Mtime = +time[0]
        }
        setTimeValue(Mtime)  
    }, [timeInputValue])
    useEffect(() => {
        onChange&&onChange(timeValue)
    }, [timeValue])
    return (
        <div className="time">
                  <OverlayTrigger
        key={label}
        placement="top"
        delay={{"show" : 300 , "hide":0}}
        overlay={
            <Tooltip className="tooltip" id={`button-tooltip-${"checkable"}`}>
                {trans(label+"")}
            </Tooltip>
        }
        >
      <div>
        {label ? (
          <div  className="d-flex align-items-start" style={{height:'22px'}}>
            <label htmlFor={name} className="select-box__label showDots">
             {useTrans ? trans(label) : label}
            </label>
             {required ? <span className="text-box__required-sign-for-label text-danger">*</span> : ""}
          </div>
        ) : null}
      </div>
      </OverlayTrigger>
            <input style={{display:"none"}} required={required} name={name} value={timeValue}/>
            <div style={{display : "flex"}}>
            <input
                defaultValue={0}
                style={{direction:"ltr" , fontSize:"18px"}}
                required={required}
                onMouseDown={(e)=>e.stopPropagation() }
                disabled={mode ==="view" ? true : false}
                onChange={updateMinutesInput}
                onBlur={cheakTime}
                type="text"
                name=""
                id={IdString}
                className={`action-form__time-input form-control-custom w-25  ${mode ==="view" ?  " disabled" : ""}` }
                value={minutesValue < 10 ? `0${minutesValue}`: minutesValue}
              //autoComplete={"off"}
            />
            <span style={{fontSize :" 36px" , margin:"-16px 4px"}}> : </span>
            <input
                defaultValue={0}
                style={{ fontSize:"18px"}}
                required={required}
                onMouseDown={(e)=>e.stopPropagation() }
                disabled={mode ==="view" ? true : false}
                onChange={updateHourInput}
                onBlur={cheakTime}
                type="text"
                name=""
                id={IdString}
                className={`action-form__time-input form-control-custom w-25  ${mode ==="view" ?  " disabled" : ""}` }
                value={hourValue == 0 ? `0${0}`: +hourValue}
               // autoComplete={"off"}
            />
            </div>
            <div>
              {type === "Time" && textTime}
            </div>   
            <div className="text-box__msg-wrapper">
               <span className={`${MsgShow ? "show-msg" : "hide-msg"} text-box__regex-msg text-danger`}>
                  {MsgText}
               </span>
            </div>
        </div>
 
    );
}
 