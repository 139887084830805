import { Button } from "components/Button/Button";

import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import {
  TREASURY_AND_ACCOUNTING_URL
} from "utils/constants";
import {
  ENDPOINTSTREASURYANDACCOUNTING
} from "utils/endpoints";
import { trans } from "utils/helpers";

import { ModalForm } from "components/ModalForm/ModalForm";
import { DocsClassification } from "../BankAccounts/components/DocsClassification/DocsClassification";
import { getCashAccountById, postCashAccount } from "./api";
import { CashAccountAccess } from "./components/CashAccountAccess";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";

interface CashAccountFormPropsType {
  selectedObj;
  showForm: (e: boolean) => void;
  mode;
  relod: () => void;
}
export const CashAccountForm = ({
  selectedObj,
  showForm,
  mode,
  relod,
}: CashAccountFormPropsType) => {
  const [resetForm, setResetForm] = useState(false);
  const [response, setResponse] = useState<any>();

  const [loading, setLoading] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});
  const [docsClassification, setDocsClassification] = useState<any>();
  const [showAccess, setShowAccess] = useState<any>(false);
  const [boardData, setBoardData] = useState<any>(false);

  async function getCashAccountByIdAsync() {
    setLoading(false);

    try {
      if (selectedObj.id) {
        const res = await getCashAccountById(selectedObj.id);
        setResponse(res.Data);
      } else {
        setResponse(null);
      }
    } catch (err: any) {}
    setLoading(true);
  }

  const handleCashAccountFormSubmit = async (event) => {
    event.preventDefault();
    let data = {
      CashName: event.target.CashName?.value,
      CashNameEN: event.target.CashNameEN?.value,
      AccDetailId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      AccountId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      FirstPeriodRemain: +event.target.FirstPeriodRemain?.value,
      AccBranchId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      DocClassificationId: docsClassification,
    };
    if (mode === "create") {
      setBoardData(data);
      setShowAccess(true);
    } else {
      try {
        await postCashAccount("put", { ...data, Id: selectedObj.Id,ActivityStatus: +event.target.ActivityStatus?.value });

        relod();
      } catch (error) {}
    }
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        showForm(false);
      });
    } else {
      showForm(false);
    }
  }
  async function accessDatahandler(data) {
    if (mode == "create") {
      let formdata: any = {};
      formdata.CashAccount = boardData;
      formdata.CashAccountAccesses = data;
      try {
        const res = await postCashAccount("post", formdata);
        relod()
        setResetForm(true);
        sessionStorage.setItem("changingforms", "false");
        showForm(true);
      } catch (error) {
        sessionStorage.setItem("changingforms", "false");
      }
    }
  }

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (selectedObj && mode != "create") {
      getCashAccountByIdAsync();
    } else {
      setLoading(true);
    }
  }, [selectedObj]);

  return (
    <div className="person-contact-form card">
      {showAccess && (
        <ModalForm
          modalHeight={60}
          mode={mode}
          modalProfileKey={"Users"}
          title={trans("Users") + " : " + boardData?.Name}
          onHide={() => setShowAccess(false)}
          show={showAccess}
        >
          <CashAccountAccess
            ownerId={selectedObj}
            showForm={setShowAccess}
            accessDatahandler={accessDatahandler}
            accessData={[]}
            mode={mode}
          />
        </ModalForm>
      )}
      <div className="card-body">
        {loading ? (
          <div className="container-fluid">
            <form onSubmit={handleCashAccountFormSubmit}>
              <div className="row ">
                <div className="col-lg-2 col-sm-3">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="CashName"
                    labelText="CashName"
                    required={true}
                    defaultValue={response?.CashName ? response?.CashName : ""}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className=" col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="CashNameEN"
                    labelText="CashNameEN"
                    required={true}
                    defaultValue={
                      response?.CashNameEN ? response?.CashNameEN : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="AccDetailId"
                    labelText="AccDetail"
                    required={true}
                    defaultValue={
                      response?.AccDetailId ? response?.AccDetailId : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="number"
                    textboxName="FirstPeriodRemain"
                    labelText="FirstPeriodRemain"
                    required={true}
                    defaultValue={
                      response?.FirstPeriodRemain
                        ? response?.FirstPeriodRemain
                        : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3">
                  <DocsClassification
                    label="Classification"
                    name="docsClassificationId"
                    baseUrl={TREASURY_AND_ACCOUNTING_URL}
                    endPoint={
                      ENDPOINTSTREASURYANDACCOUNTING.Treasury
                        .BaseDefinitionAndSettingApi.DocsClassification
                        .dictionary
                    }
                    resetForm={resetForm}
                    value={""}
                    onChange={(e) => {
                      setDocsClassification(e);
                    }}
                    mode={mode}
                  />
                </div>
              </div>
              {mode !== "create" && (
                <div className="col-sm-3 col-lg-2">
                  <EnumerationTypeSelect
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    name="ActivityStatus"
                    enumTypeSelect="ActivityStatus"
                    mode={mode}
                    label="Status"
                    value={response?.Status}
                  />
                </div>
              )}

              {mode != "view" ? (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button value="Save" btnType="submit" />
                  <Button
                    btnType="cancel"
                    value="Cancel"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              ) : (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button
                    btnType="primary"
                    value="close"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              )}
            </form>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};
