import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { getSalesReturnInvoice,getSalesReturnInvoiceById } from "./api";
import { ModalForm } from "components/ModalForm/ModalForm";
import { SalesReturnInvoiceForm } from "./SalesReturnInvoiceForm";

export const SalesReturnInvoices = () => {
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState<any>("info");
  const [response, setResponse] = useState<any>([]);
  const [selectedObj, setSelectedObj] = useState<string | any>(null);
  const [mode, setMode] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [showSalesInvoiceForm, setShowSalesInvoiceForm] = useState(false);
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [search, setsearch] = useState(null);
  const [filterAndSort, setFilterAndSort] = useState<any>(null);
  const layoutContext = useContext(LayoutContext);
  const [pages, setPages] = useState<number>(0);

  const searchHandler = (str) => {
    setPageNum(1);
    setSelectedObj(null);
    if (str === "") {
      setsearch(null);
      getSalesInvoicesAsync(1, null);
    } else {
      setsearch(str);
      getSalesInvoicesAsync(1, str);
    }
  };
  const handleChangePageSize = (e: any) => {
    setPageNum(1);
    setPageSize(e.currentTarget.value);
    getPageSize("SalesReturnInvoices", e.currentTarget.value);
    getSalesInvoicesAsync(1);
  };
  const handleGoPage = (value) => {
    if (value) {
      setPageNum(+value);
      getSalesInvoicesAsync(+value);
    } else {
    }
  };
  const handleNextPage = () => {
    if (response.TotalRecords && response.TotalRecords / pageSize > pageNum) {
      setPageNum((pageNum) => {
        return +pageNum + 1;
      });
      getSalesInvoicesAsync(pageNum + 1);
    }
  };
  const handlePrevPage = () => {
    if (pageNum > 1) {
      setPageNum((pageNum) => {
        return +pageNum - 1;
      });
      getSalesInvoicesAsync(pageNum - 1);
    }
  };

  const selectedObjCallback = (obj, index) => {
    getSalesReturnInvoiceById(obj.Id)
    setSelectedObj(obj);
  };

  const handleCreateSalesInvoice = (id) => {
    setMode("create");
    setShowSalesInvoiceForm(true);
  };

  /* #region toolbarActions */
  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "print":
        alert("print ");
        break;
      case "excel":
        downloadCSV(response?.Data, 6, trans("SalesReturnInvoices"));
        break;
      case "refresh":
        setForceUpdate(!forceUpdate);
        break;
      case "create":
        handleCreateSalesInvoice("");
        break;
      case "view":
        if (selectedObj) {
          setMode("view");
          setShowSalesInvoiceForm(true);
        } else {
          Toast(trans("Msg_SelectRow"), "warning");
        }
        break;
      case "edit":
        if (selectedObj) {
          setMode("edit");
        }
        break;
    
      case "help":
        alert("call api help");
        break;
    }
  };
  /* #endregion */

  async function getSalesInvoicesAsync(pagNum = pageNum, searchSTR = search) {
    setLoading(false);
    try {
      let pageSizeChangedByUser = getPageSize("SalesReturnInvoices");
      setPageSize(pageSizeChangedByUser);
      if (layoutContext.currentApplication != undefined) {
        const res = await getSalesReturnInvoice(
          pagNum,
          pageSizeChangedByUser,
          searchSTR,
          filterAndSort?.OrderFormat?.join(","),
          filterAndSort?.Filterlist
        );
        if (res.Data.Data.length > 0) {
          setResponse(res.Data);

          setPages(
            res.Data.TotalRecords > pageSizeChangedByUser
              ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser)
              : 1
          );
        }
      }
    } catch (error) {
      setMessageType("error");
    }
    setLoading(true);
  }


  useEffect(() => {
    setSelectedObj(null);
    setResponse(null);
    if (
      filterAndSort != null &&
      layoutContext.currentApplication != undefined
    ) {
      getSalesInvoicesAsync();
    }
  }, [forceUpdate, filterAndSort, layoutContext.currentApplication]);
  useEffect(() => {
    let settings = getGridSetting("SalesInvoiceList");
    setFilterAndSort({ Filterlist: settings[0], OrderFormat: settings[1] });
  }, []);

  return (
    <>
      {showSalesInvoiceForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowSalesInvoiceForm(false);
          }}
          show={showSalesInvoiceForm}
          title={"SalesReturnInvoiceForm"}
        >
          <SalesReturnInvoiceForm
            step={1}
            mode={mode}
            relod={() => {
              setForceUpdate(!forceUpdate);
            }}
            rowObject={mode == "create" ? null : selectedObj}
            onHide={() => {
              setShowSalesInvoiceForm(false);
            }}
          />
        </ModalForm>
      ) : null}
      <Toolbar

        create={true}
        edit={false}
        view={true}
        handleSearch={searchHandler}
        id={selectedObj}
        emptyList={response?.TotalRecords == 0}
        search={true}
        handleClickToolbar={clickToolbarCallback}
      />
      <div
        style={{overflowY: "auto", 
          height: "78vh",
           scrollbarWidth: "thin" }}
        className="col-lg-12 mt-0"
      >
        {loading ? (
          <>
            {response?.Data?.length > 0 ? (
              <div className="card">
                <div className="card-body p-0">
                  <>
                    <DataGrid
                      DoubleClick={(obj) => {
                        setSelectedObj(obj);
                        clickToolbarCallback("view");
                      }}
                      View={true}
                      ViewParametr={"NotSeen"}
                      handlefiltering={(str: any) => {
                        str.Filterlist != undefined
                          ? setFilterAndSort(str)
                          : setFilterAndSort({
                              ...filterAndSort,
                              Filterlist: str,
                            });
                      }}
                      filterStr={filterAndSort.Filterlist}
                      orderFormatList={filterAndSort.OrderFormat}
                      orderFormat={(order) => {
                        setFilterAndSort({
                          ...filterAndSort,
                          OrderFormat: order,
                        });
                      }}
                      parentName={"SalesInvoiceList"}
                      theadData={Object.keys(response.Data[0])}
                      tbodyData={response.Data}
                      minCellWidth={80}
                      selectedRowObjectCallback={selectedObjCallback}
                      rowSelected={selectedObj}
                      startIndex={3}
                      handleView={(e) => {
                        setSelectedObj({ Id: e });
                        setMode("view");
                        setShowSalesInvoiceForm(true);
                      }}
                      totalRecords={response.TotalRecords}
                      pageNum={pageNum}
                      pageSize={pageSize}
                      HandleNextPage={handleNextPage}
                      HandlePrevPage={handlePrevPage}
                      handlechangePageSize={handleChangePageSize}
                      first={() => {
                        getSalesInvoicesAsync(pages);
                        setPageNum(pages);
                      }}
                      end={() => {
                        getSalesInvoicesAsync(1);
                        setPageNum(1);
                      }}
                      handleGoPage={handleGoPage}
                    />
                  </>
                </div>
              </div>
            ) : (
              <Message message={"NoDataForPreview"} type={messageType} />
            )}
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};
