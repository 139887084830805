import { Button } from "components/Button/Button"
import { CheckableButton } from "components/CheckableButton/CheckableButton"
import { Toast } from "components/ToastShow/ToastShow"
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext"
import React, { useContext, useEffect, useState } from "react"
import { OrganizationType } from "types/enum"
import { getWidgetList } from "../../profile/api"

interface props {
    setShowWidgetForm: React.Dispatch<React.SetStateAction<boolean>>
}

export const WidgetForm = ({setShowWidgetForm}: props) => {
    const layoutContext = useContext(LayoutContext);
    const savedWidgets = JSON.parse(localStorage.getItem('widgetList') as string) 
    const [checkboxList, setCheckboxList] = useState<any>();
    

   

    const handleSubmit = (event) => {
        event.preventDefault()
        const data:any = [ ]
        checkboxList.map(item=>{
            if(event.target[item]?.checked ){
                data.push(item)            
            }
            

        })
        let obj = {}
        checkboxList.map(item=>{
            if(item!=undefined){
                obj[item] = data.includes(item)

            }
        })

        localStorage.setItem('widgetList', JSON.stringify(obj))
        Toast('ویجت ها ثبت شد', 'success')
        setShowWidgetForm(false)
        layoutContext.setWidgetFormFlag(!layoutContext.widgetFormFlag)
    }


    async function getWidgetListAsync() {
        try {
          let obj = {}
          const res = await  getWidgetList(layoutContext?.currentUserPosition?.OrganizationType == OrganizationType.Customers)
          let list:any = []
          setCheckboxList(res.Data)

        } catch (error) {
          
        }
      }
    
    useEffect(() => {    
          getWidgetListAsync()
    }, [ ]);
    
    

    return (
        <form onSubmit={handleSubmit}>
            <div className="card p-2">
                <div className="row mt-3">
                    {
                       checkboxList && checkboxList.map(item => (
                            <div className="col-2">
                                <CheckableButton 
                                    labelText={item}
                                    type="checkbox"
                                    inputName={item}
                                    defaultValue={item}
                                    defaultChecked={savedWidgets[item]}
                                />
                            </div>
                        ))
                    }
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <Button btnType="submit" value={'submit'} />
                    </div>
                </div>
            </div>
        </form>
    )
}