import { Textbox } from "components/Textbox/Textbox";
import { Button } from "components/Button/Button";
import { SelectBox } from "components/Select/Select";
import { useEffect, useState } from "react";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { InputTextType } from "types/enum";
import Datepicker from "components/DatePicker/DatePicker";
import { KeyWord } from "components/KeyWord/KeyWord";
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";
import { Time } from "components/Time/Time";
import { Price } from "components/Price/Price";


interface InputSettingProps {
    object:any;
    systemKeys:any;
    filledSystemKey:"create" | "duplicate" | "edit";
    handleNewSetting: (e:any)=>void;
    onHide: (e:boolean)=>void;
}

export const InputSetting = ({object,filledSystemKey,systemKeys,handleNewSetting,onHide}:InputSettingProps) => {
  const [keyWords, setKeyWords] = useState<any>(object?.Keywords ? object?.Keywords : []);
  const [indexTextBox,setIndexTextBox] = useState<string>(object?.InputType ? InputTextType[object.InputType] : 'text')
  const [minLengthBox,setMinLengthBox] = useState<any>(null)
  const [maxLengthBox,setMaxLengthBox] = useState<any>(null)
  const [resetForm,setresetForm] = useState<any>(false)
  const [modeTextBox,setModeTextBox] = useState<"create" | "view">("create")
  const [textType,setTextType] = useState<"text" | "number" | "other">("text")
  const [dateDefaultValue,setDateDefaultValue] = useState<string | null>(null)
  const [datePickerState,setDatePickerState] = useState<"withTime"|"normal">(object?.DatePickerState ? object.DatePickerState : "normal")
  const [defaultValueDateTimeNow,setDefaultValueDateTimeNow] = useState<boolean>(object?.defaultValueDateTimeNow)
  const [listTypeText,setListTypeText] = useState<any>()
  const checkTypeText = (e)=>{
    setIndexTextBox(e)
    setresetForm(true)
  }
  
    // try catch
    const handleSubmit = async(event) => {  
        event.preventDefault()
        let data = {
            "size":keyWords.length > 4 ? 12 : event.target.size?.value,

            ...((object.typeName != 'Message' && object.typeName != 'emptySpace') &&{
                "label": event.target.label?.value,
                "systemKey":event.target.systemKey?.value,
                "required": event.target.required?.checked,
                "disable": event.target.disable?.checked,
                "defaultValue":
                (!defaultValueDateTimeNow ? 
                (object?.typeName == "checkBox") ? event.target.defaultValue?.checked : 
                   (object?.typeName == "date" || object?.typeName == "time") ? dateDefaultValue : 
                     (object?.typeName == "ProgressBar") ? (event.target.defaultValue?.value == "" ? 0 : +event.target.defaultValue?.value):
                       (object?.typeName == "textArea" ? event.target.defaultValue?.value.slice(0,500) : event.target.defaultValue?.value.slice(0,200)):null),
            }),
            
            // message inputs
            ...(object.typeName == 'Message' && {
            "messageType" : event.target?.messageType?.value,
            "message" : event.target?.message?.value
            }),
            
            // radio inputs && dropDown inputs
            ...((object.typeName == 'selectBox' || object.typeName == 'RadioButton')&& {"Keywords":keyWords}),
            
            // Requester PositionDictionary
            ...((object?.typeName == 'PositionDictionary' && object?.systemKey == "RequesterPositionId") && {"hidden": event.target.hidden?.checked}),
           
            // text inputs
            ...((object.typeName == 'text' || object.typeName == 'number' || object.typeName == 'textArea' )&& {
                "placeholder": event.target.placeholder?.value,
            }),
            ...((object.typeName == 'text')&& {
            "InputType": event.target.InputType?.value
            }),
                // text Type
            ...(((object.typeName == 'text' &&  textType == "text" )|| (object?.typeName == 'textArea')) && {"maxLength": event.target.maxLength?.value}),
                // textArea Type
            ...((object.typeName == 'textArea') && {"minHeight": +event.target.minHeight?.value}),
                // number Type
            ...(((object.typeName == 'text' && textType == "other") || object.typeName == 'number') && {
                "min": +event.target.min?.value,
                "max": +event.target.max?.value > 0 ? +event.target.max?.value : null,
                "minLengthBox": event.target.minLengthBox?.value,
                "maxLengthBox": event.target.maxLengthBox?.value > 0 ? +event.target.maxLengthBox?.value : null,
                "setMaxLengthBox": event.target.LengthTextBox?.value,
            }),
                // date inputs && time inputs
            ...((object.typeName == 'date')&& {
                "DatePickerState" : datePickerState,
                "defaultValueDateTimeNow" : event.target?.defaultValueDateTimeNow?.checked,
            }),
                // Step 
            ...((object.typeName == 'Step') && {
                "isOpen" : event.target.isOpen?.checked,
            }) 

            // label type
            // "color": event.target.color?.value,
            // "labelSize": event.target.labelSize?.value,
            // "labelWeight": event.target.labelWeight?.value,
            // "labelStyle": event.target.labelStyle?.value,
            // "labelText": event.target.labelText?.value,

        }
        // control maximum length value (text=500 || textArea=900)
        if(data.maxLength && object.typeName == 'text'){
            if(indexTextBox == "text" && data?.maxLength > 500){
                return Toast(trans("maxLengthText"),"error")
            } 
            if(object?.typeName == "textArea" && data?.maxLength > 900){
                return Toast(trans("maxLengthTextArea"),"error")
            }
        }
        // check maxLengthBox & minLengthBox
        if(data.maxLengthBox && data?.maxLengthBox < data?.minLengthBox){
            return Toast(trans("maxLengthBoxmaxLengthBox"), "warning")
        }
        // check min & max
        if(data.max && data.min && +data?.max < +data?.min){
            return Toast(trans("maxMin"), "error")
        }
        // check keyword length
        if(keyWords.length == 0 &&( object.typeName == 'selectBox' || object.typeName == 'RadioButton')){
           return Toast(trans("keyword"), "error")
        }

        // unique label and systemKey
        if(object.typeName != "emptySpace" && object.typeName != "Message" && (systemKeys.find(item => {return((item.systemKey == data.systemKey )&&(object?.systemKey!=item.systemKey))}))){
            Toast(trans("systemKey"), "error")
        }else if(object.typeName != "emptySpace" && object.typeName != "Message" && (systemKeys.find(item => {return((item.label == data.label )&&(object?.label!=item.label))}))){
            Toast(trans("label"), "error")
        }else{
            handleNewSetting(data)
            onHide(false) 
        }
    }

    function cancel() {
        onHide(false)    
    }

    useEffect(()=>{
        setMinLengthBox(null)
        setMaxLengthBox(null)
        setModeTextBox("create")
        if(indexTextBox == "number"){
            setTextType("number")
        }else if(indexTextBox == "tel"){
            setMinLengthBox(1)
            setMaxLengthBox(15)
            setModeTextBox("view")
            setTextType("other")
        }else if(indexTextBox == "mobile" || indexTextBox == "nationalCode" || indexTextBox == "nationalIdentifier" || indexTextBox == "PostalCode"){
            setMinLengthBox(10)
            setMaxLengthBox(10)
            setModeTextBox("view")
            setTextType("other")
        }else if(indexTextBox == "birthCertNo"){
            setMinLengthBox(1)
            setMaxLengthBox(10)
            setModeTextBox("view")
            setTextType("other")
        }else if(indexTextBox == "econNo"){
            setMinLengthBox(10)
            setMaxLengthBox(14)
            setModeTextBox("view")
            setTextType("other")
        }else{
            setTextType("text")
        }
    },[indexTextBox])
    useEffect(()=>{
        if(resetForm){
            setresetForm(false)
        }
    },[resetForm])

    useEffect(()=>{
        let newInputTextType:any = JSON.parse(JSON.stringify(InputTextType)) 
        delete newInputTextType[1]
        delete newInputTextType[6]
        setListTypeText(newInputTextType)
    },[])
    return ( 
        <form className="h-100" onSubmit={handleSubmit}>
            <div className="h-100">
                <div className="card-body row ">
                    {((object?.typeName != 'Message' && object?.typeName != 'emptySpace') && 
                    <>
                    {/* label */}
                        <div className="col-12">
                            <div>
                                <Textbox
                                textboxType="text" 
                                textboxName="label"
                                labelText={trans("label")} 
                                required 
                                defaultValue={object?.typeName == "emptySpace"  ? "emptySpace" :object?.label} 
                                readonly={object?.typeName == "emptySpace" ? true :false}
                                />
                            </div>
                        </div>
                        {/* systemkey */}
                        <div className="col-12">
                            <div>
                                <Textbox
                                textboxType="systemKey" 
                                textboxName="systemKey" 
                                labelText={trans("field")} 
                                required 
                                defaultValue={object?.systemKey} 
                                readonly={object?.systemKey == "Description" || object?.systemKey == "RequesterPositionId" ? true:false}
                                />
                            </div>
                        </div>
                        {/*             "required": object.typeName == "date" ? (defaultValueDateTimeNow ? false:true) : event.target.required?.checked,
                "disable": object.typeName == "date" ? (defaultValueDateTimeNow ? true:false) : event.target.disable?.checked, */}
                        {/* required */}
                        {object?.typeName != 'Step' && 
                        <>
                        <div className="col-6">
                            <div className="d-flex align-items-end w-100 h-100">
                                <CheckableButton
                                type="checkbox"
                                labelText="required"
                                inputName="required" 
                                checked={defaultValueDateTimeNow} 
                                defaultChecked={object?.required ? object?.required : false} 
                                defaultValue={object?.required ? object?.required : false}
                                readOnly={object?.systemKey == "Description" || object?.systemKey == "RequesterPositionId" ? true : false}
                                />
                            </div>
                        </div>
                        {/* disable */}
                        <div className="col-6">
                            <div className="d-flex align-items-end w-100 h-100">
                                <CheckableButton 
                                type="checkbox" 
                                labelText="disable"
                                inputName="disable"
                                checked={defaultValueDateTimeNow} 
                                defaultChecked={object?.disable ? object?.disable : false} 
                                defaultValue={object?.disable ? object?.disable : false}
                                readOnly={object?.systemKey == "Description" || object?.systemKey == "RequesterPositionId" ? true : false}
                                />
                            </div>
                        </div>
                        </>
                        }
                    </>
                    )}
   
                    {/* size */}
                    <div className="col-12">
                        <div>
                             <SelectBox
                              lable="InputSize"
                              selectType="select" 
                              name="size" 
                              value={object?.typeName != 'Step' ? object?.size : 12} 
                              val={[2,3,4,6,8,9,10,12]} 
                              options={["15%","25%","33%","50%","66%","75%","85%","100%"]} 
                              mode={object?.typeName == 'Step' ? 'view' : 'create'}
                            />
                        </div>
                    </div>
                    {/* checkBox inputs */}
                    {
                        object?.typeName == "checkBox" && (
                        <div className="col-12">
                            <div className="d-flex align-items-end w-100 h-100">
                                <CheckableButton 
                                 type="checkbox" 
                                 labelText="defaultValue"
                                 inputName="defaultValue" 
                                 defaultChecked={object?.defaultValue ? object?.defaultValue : false} 
                                 defaultValue={object?.defaultValue ? object?.defaultValue : false} 
                                />
                            </div>
                        </div>
                        )
                    }
                    {/* ProgressBar inputs */}
                    {
                        object?.typeName == "ProgressBar" && (
                        <div className="col-12">
                            <div className="d-flex align-items-end w-100 h-100">
                            <Textbox
                                 resetForm={resetForm}
                                 textboxType="number"
                                 textboxName="defaultValue"
                                 labelText="defaultValue"
                                 defaultValue={object?.defaultValue == '' || !object?.defaultValue ? 0 :object?.defaultValue}
                                 min={0}
                                 max={100}
                                />
                            </div>
                        </div>
                        )
                    }
                    {/* price inputs */}
                    {
                        object?.typeName == "price" && (
                            <div className="col-12">
                                <div className="d-flex align-items-end w-100 h-100">
                                    <Price
                                     label="defaultValue"
                                     name="defaultValue" 
                                     defaultValue={object?.defaultValue}
                                     displayLetters={false} 
                                    />
                                </div>
                            </div>
                        )
                    }
                    {/* message inputs */}
                    {
                        object?.typeName == "Message" && (
                            <>
                                <div>
                                    <Textbox
                                     textboxType="text" 
                                     textboxName="message"
                                     labelText="message" 
                                     defaultValue={!object?.message ? null : object?.message} 
                                     required 
                                    />
                                </div>
                                <div>
                                    <SelectBox
                                     selectType="select"
                                     name="messageType" 
                                     lable="messageType"
                                     value={object?.labelStyle} 
                                     val={["info","instruction","error","alert"]} 
                                     options={["info","instruction","error","alert"]} 
                                    />
                                </div>
                            </>
                        )
                    }
                    {/* radio inputs && dropDown inputs */}
                    {
                        (object?.typeName == 'RadioButton' || object?.typeName == 'selectBox' )&&
                            <div className="col-12">
                                <div className="w-100 h-100">
                                    <KeyWord
                                    name="KeyWords" 
                                    label="options" 
                                    setKeyWords={setKeyWords} 
                                    keyWords={keyWords} 
                                    required={true} 
                                    defaultValue={object?.KeyWords}
                                    />
                                </div>
                            </div>
                    }
                    {/* PositionDictionary */}
                    {(object?.typeName == 'PositionDictionary' && object?.systemKey == "RequesterPositionId")&&
                     (
                     <>
                        <div className="col-12">
                            <div className="d-flex align-items-end w-100 h-100">
                                <CheckableButton 
                                 type="checkbox" 
                                 labelText="hidden"
                                 inputName="hidden" 
                                 defaultChecked={object?.hidden ? object?.hidden : false} 
                                 defaultValue={object?.hidden ? object?.hidden : false} 
                                />
                            </div>
                        </div>
                     </>
                     )
                    }
                    {/* text inputs && number inputs && textArea inputs*/}
                    {
                        ((object?.typeName == 'text' && object?.systemKey != "RequesterPositionId" )||(object?.typeName == 'textArea')||(object?.typeName == 'number'))&& 
                        <>
                            <div className="col-12">
                                <Textbox
                                    textboxType={object?.typeName == "text"?"text" :object?.typeName == "textArea" ? 'textarea':'number'} 
                                    textboxName={"defaultValue"} 
                                    labelText="defaultValue" 
                                    defaultValue={object?.defaultValue}
                                />
                            </div>
                            <div className="col-12">
                                {listTypeText && (object?.typeName != 'textArea' && object?.typeName != 'number') &&

                                <div>
                                    <SelectBox
                                     onChange={(e)=>checkTypeText(InputTextType[e.target.value])} 
                                     value={object.InputType ? object.InputType : 1}
                                     val={Object.keys(listTypeText)} 
                                     options={Object.values(listTypeText)} 
                                     selectType={"select"} 
                                     name={"InputType"} 
                                     lable="InputType"
                                     />
                                </div>
                                    }
                            </div>
                            <div className="col-12">
                                <div>
                                    <Textbox
                                     textboxType={"text"} 
                                     textboxName={"placeholder"} 
                                     labelText="placeholder" 
                                     defaultValue={object?.placeholder}
                                    />
                                </div>
                            </div>
                            {/* text Type */}
                            { ( textType == "text" ) &&
                            <div className="col-12">
                                <div>
                                    <Textbox
                                     labelText="maxLength" 
                                     textboxName="maxLength" 
                                     textboxType="number" 
                                     defaultValue={object?.maxLength}
                                    />
                                </div>
                            </div> 
                            }
                            {/* textArea Type */}
                            {( object?.typeName == "textArea" ) &&
                                <Textbox
                                  minHeight={object?.minHeight}  
                                  textboxType="text" 
                                  textboxName="minHeight"
                                  labelText="minHeight" 
                                  defaultValue={object?.minHeight}
                                />
                            }
                            {/* number Type */}
                            {(textType == "other" || textType == "number") &&
                                <>
                            {maxLengthBox != minLengthBox || minLengthBox == null ? 
                            <>
                                <div className="col-12">
                                    <div>
                                      <Textbox
                                       textboxType="number" 
                                       textboxName={textType == "other" ? "minLengthBox":"min"} 
                                       labelText={textType == "other" ? "minLengthBox":"min"} 
                                       defaultValue={textType == "other" ?minLengthBox ? minLengthBox:object.minLengthBox:object.min} 
                                       mode={modeTextBox}
                                       />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div>
                                        <Textbox
                                         textboxType="number" 
                                         textboxName={textType == "other" ? "maxLengthBox":"max"} 
                                         labelText={textType == "other" ? "maxLengthBox":"max"}  
                                         defaultValue={textType == "other" ?maxLengthBox ? maxLengthBox:object.maxLengthBox:object.max} 
                                         mode={modeTextBox}
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="col-12">
                                    <div>
                                        <Textbox
                                         textboxType="number" 
                                         textboxName="setMaxLengthBox"
                                         labelText="setMaxLengthBox"
                                         defaultValue={maxLengthBox} 
                                         mode={modeTextBox}
                                         />
                                    </div>
                                </div>
                            </>
                            }
                            </>
                            }
                        </>
                    }
                    {/* date Type and Time Type */}
                    
                    {(object?.typeName == 'date' || object?.typeName == 'time') && 
                     <>
                        {object?.typeName == 'date' && (
                        <>
                            <div className="col-6">
                                <div className="d-flex align-items-center w-100 h-100">
                                    <CheckableButton
                                    type={"radio"} 
                                    labelText={"withTime"} 
                                    inputName="DatePickerState" 
                                    onchange={()=>setDatePickerState("withTime")} 
                                    defaultChecked={datePickerState == "withTime" ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex align-items-center w-100 h-100">
                                    <CheckableButton
                                    type={"radio"} 
                                    labelText={"normal"} 
                                    inputName="DatePickerState" 
                                    onchange={()=>setDatePickerState("normal")} 
                                    defaultChecked={datePickerState == "normal" ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="d-flex align-items-center w-100 h-100">
                                    <CheckableButton
                                    onclick={()=>setresetForm(true)}  
                                    type={"checkbox"} 
                                    labelText={"Msg_DefaultValue_DateTimeNow"} 
                                    inputName="defaultValueDateTimeNow" 
                                    onchange={(e)=>setDefaultValueDateTimeNow(e)} 
                                    defaultChecked={defaultValueDateTimeNow}
                                    />
                                </div>
                            </div>
                        </>
                        )}
                        <div className="col-12">
                            <div>
                            <Datepicker
                              mode={!defaultValueDateTimeNow ? "edit" :"view"}
                              resetForm={resetForm}
                              lable="defaultValue"
                              setIsoTime={(isoTime: string) => {setDateDefaultValue(isoTime)}}
                              val={object?.dateDefaultValue ? object?.dateDefaultValue  : null} //resetForm ? new Date().toISOString() : 
                              withTime ={object?.typeName == "time" ? true : datePickerState == "withTime" ? true : false}
                              disableDayPicker={object?.typeName == "time" ? true: false}
                              required ={false}
                              calendarPosition={"right"}
                              format={object?.typeName == "time" ? "۱۳:۲۶" : datePickerState == "withTime" ? "۱۴۰۳/۰۴/۰۴ ۱۳:۲۶":"۱۴۰۳/۰۴/۰۴"}
                              onOpenPickNewDate={true}
                            />  
                            </div>
                        
                        </div>
                    </>
                    }
                    {/* timeLength Type */}
                    {object?.typeName == "timeLength" && (
                        <div className="col-12">
                            <Time
                             mode="edit"
                             name="defaultValue"
                             label="defaultValue"
                             type="Time"
                             defaultValue={object?.defaultValue} 
                            />
                        </div>
                    )}

                    {/* {
                        object?.typeName == "label" && (
                            <>
                        <div>
                            <Textbox textboxType={"text"} textboxName={"labelText"} labelText={"labelText"} required defaultValue={!object?.labelText ? null : object?.labelText} readonly={object?.systemKey == "Description" ? true:false}/>
                        </div>
                        <div>
                             <SelectBox val={["normal","italic","oblique"]} value={object?.labelStyle} options={["normal","italic","oblique"]} selectType={"select"} name={"labelStyle"} lable="labelStyle"/>
                        </div>
                        <div>
                             <SelectBox val={["normal","bold",900]} value={object?.labelWeight} options={["normal","bold","900"]} selectType={"select"} name={"labelWeight"} lable="labelWeight"/>
                        </div>
                        <div>
                            <Textbox textboxType={"text"} textboxName={"labelSize"} labelText={"labelSize"} required defaultValue={!object?.labelSize ? null : object?.labelSize} readonly={object?.systemKey == "Description" ? true:false}/>
                        </div>
                        <div>
                            <Textbox textboxType={"text"} textboxName={"color"} labelText={"color"} required defaultValue={!object?.color ? null : object?.color} readonly={object?.systemKey == "Description" ? true:false}/>
                        </div>
                            </>
                        )
                    } */}
                    <div className="col-lg-12 mt-1 btns_form">
                        <Button btnType="submit" value="confirm" />
                        <Button btnType="cancel" value="cancel" onClick={()=>{cancel()}} />
                    </div>
                </div>
            </div>
        </form>
     );
}
 