import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import DatePicker from "components/DatePicker/DatePicker";
import { Toast } from "components/ToastShow/ToastShow";
import { UserContext } from "components/ModalForm/ModalForm";
import { RangeSlider } from "components/RangeSlider/RangeSlider";
import { Textbox } from "components/Textbox/Textbox";
import { Time } from "components/Time/Time";
import { Action, Invoice } from "pages/OfficeAutomation/Cartable/types";
import { useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getInvoiceData, sendInvoiceData } from "../../api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_TYPE } from "utils/constants";
import { log } from "console";
import { Loading } from "components/Loading/loading";
import { Price } from "components/Price/Price";
interface JobInvoiceFormPropsType {
    mode?: any,
    jobId?: any,
    InvoiceId?: any,
    processId?: any,
    taskId,
    Id?: any,
    step:any,
    onHide: () => void,
    inDynamicForms?: any
}

export const JobInvoiceForm = ({
   
    mode,
    jobId,
    InvoiceId,
    processId,
    taskId,
    Id,
    step=1,
    onHide,
    inDynamicForms=false
    
}: JobInvoiceFormPropsType) => {
    let data, method;
    const [action, setAction] = useState<Action | any>();
    const [fromDate, setFromDate] = useState<string>(new Date().toISOString());
    const [untilDate, setUntilDate] = useState<string>(new Date().toISOString());
    const [response, setResponse] = useState<any>();
    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invoicePricedisabl, setInvoicePricedisabl] = useState(true);
    const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);
    const [dataChange, setDataChange] = useContext(UserContext);
    const [showstep, setShowstep] = useState<any>({});
    const [localMode, setLocalMode] = useState<any>("create");

    async function formSubmit(e) {
        e.preventDefault()
        let data:any
        let method
        if(step > 1){
            method = "put"
            if(step > 2){
                data = {
                    Id :  response?.Id,
                    JobId : jobId,
                    InvoiceNumber : e.target.InvoiceNumber.value,
                    HasPayment : e.target.HasPayment.checked,
                    InvoiceDescription :  e.target.InvoiceDescription.value,
                    InvoiceId : response.InvoiceId,
                    HasInvoice : response.HasInvoice,   
                    InvoicePrice :  response.InvoicePrice,  
                    TAS_UID:  taskId,    
                    IsPaid :  e.target.IsPaid.checked   ,
                    PayDescription:  e.target.PayDescription.value  ,
                  }
            }else{
                data = {
                   Id :  response.Id,
                   JobId : jobId,
                   InvoiceNumber : e.target.InvoiceNumber.value,
                   HasPayment : e.target.HasPayment.checked,
                   InvoiceDescription :  e.target.InvoiceDescription.value,
                   InvoiceId : response.InvoiceId,
                   HasInvoice : response.HasInvoice,   
                   InvoicePrice :  response.InvoicePrice,  
                   TAS_UID:  taskId,     
                 }
            }

        }else{
            if(response?.Id){          
              method = "put"
                data = {  
                  Id :  response.Id,
                  JobId : jobId,
                  HasInvoice :  !invoicePricedisabl,   
                  InvoicePrice :   +e.target.InvoicePrice.value.replaceAll("," ,""),
                  InvoiceDescription :  e.target.InvoiceDescription.value,
                  TAS_UID : taskId
              }
            }else{

                method = "post"
                  data = {              
                    JobId : jobId,
                    HasInvoice :  !invoicePricedisabl,   
                    InvoiceDescription :  e.target.InvoiceDescription.value,
                    InvoicePrice :   +e.target.InvoicePrice.value.replaceAll("," ,""),
                    TAS_UID : taskId
                }

            }
        }
        try {
            const res = await sendInvoiceData(data, method)
            setResetForm(true)
         onHide()

        } catch (error) {

        }




}

    async function getInvoiceAsync() {
        setLoading(false)
         try {
          const res = await getInvoiceData(jobId )
          setResponse(res.Data)

        } catch (error) {
            setResponse({})
        }

        setLoading(true)



}


function change(checked) {
    if(checked){
        setInvoicePricedisabl(false)
    }else{
        setInvoicePricedisabl(true)
    }
}



    useEffect(() => {
        if (resetForm) {  

            setResetForm(false);
        }

    }, [resetForm]);

    useEffect(() => {
        if (mode == "edit" && step ==1) {
            setLocalMode("create")
        }else{
            setLocalMode(mode)
   
        }

    }, [mode]);

    useEffect(() => {
        if( step >=1){
            getInvoiceAsync()
        }
    }, []);


    return (
        <form className="action-form py-2" onSubmit={formSubmit}>
           {loading ?

            <div>

            <div className={`${step >= 1 && "card"}  `}>
                {step > 1 && 
                    <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px" , justifyContent:"space-between" }} className="d-flex  py-1 col-12 pr-4"> 
                    <div>{trans("step")}-1 </div>      
                    <div  style={{marginLeft:"18px"}} onClick={()=>{showstep[1]?setShowstep({...showstep , 1:false}):setShowstep({...showstep , 1:true}) }} >{showstep[1] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" />:<FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                    </div>
                    }
                <div style={{display: !showstep[1] ?"block" :"none"}} className="card-body">                      
                        <div className="row mb-2">
                         <div className=" col-lg-4 col-sm-4 mt-4" >
                            <CheckableButton
                               onchange={change}
                               readOnly={step != 1}
                               defaultValue={response?.HasInvoice}
                               defaultChecked={response?.HasInvoice}
                               labelText={trans("HasInvoice")}
                               labelFalseText={trans("HasNotInvoice")}
                               inputName={"HasInvoice"}
                               type="checkradio"
                               mode={step != 1 ? "view" :localMode}
                               />                                
                        </div>
                         <div className="col-lg-4 col-sm-4 mt-1">
                         <Price
                             mode={step != 1 ? "view" :(invoicePricedisabl ?"view" : "edit")}
                             name="InvoicePrice"
                             resetForm={resetForm}
                             defaultValue={response?.InvoicePrice}
                             label="InvoicePrice"
                         />
                         </div>
                        </div> 
                        <div  className="row mb-2" >
                        <div className="col-lg-8 col-sm-4 mt-1">
                              <Textbox
                                readonly={!(mode != "view")}
                                Change={setDataChange}
                                mode={mode}
                                resetForm={resetForm}
                                textboxType="textarea"
                                textboxName="InvoiceDescription"
                                labelText="InvoiceDescription"
                                maxLength={900}
                                defaultValue={response?.InvoiceDescription}
                              />
                            </div>  
                            
                        </div>                
                </div>
            </div>

            {step > 1 &&
            <div className="card">
                     <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px" , justifyContent:"space-between" }} className="d-flex  py-1 col-12 pr-4"> 
                        <div>{trans("step")}-2 </div> 
                        <div style={{marginLeft:"18px"}}  onClick={()=>{showstep[2]?setShowstep({...showstep , 2:false}):setShowstep({...showstep , 2:true}) }}>{showstep[2] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" />:<FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                    </div>
                <div style={{display: !showstep[2] ?"block" :"none"}} className="card-body">
                    <div className="container">
                        <div className="row mb-2">
                          <div className="col-lg-4 col-sm-4 mt-1">
                           <Textbox
                             readonly={step != 2}
                             Change={setDataChange}
                             mode={localMode}
                             resetForm={resetForm}
                             textboxType="text"
                             textboxName="InvoiceNumber"
                             labelText="InvoiceNumber"
                             maxLength={50}
                             defaultValue={response?.InvoiceNumber}
                           />
                        </div>
                        <div className=" col-lg-5 col-sm-5 mt-4" >
                            <CheckableButton
                               readOnly={step != 2}
                               defaultValue={response?.HasPayment}
                               defaultChecked={response?.HasPayment}
                               labelText={trans("HasPayment")}
                               inputName={"HasPayment"}
                               type="checkbox"
                               mode={localMode}
                               />                                
                        </div>
                        </div>           
                    </div>
                </div>
                
            </div>
            }
            {(step ==3) && 
               <div className="card">
                <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px" , justifyContent:"space-between" }} className="d-flex  py-1 col-12 pr-4"> 
                   <div>{trans("step")}-3 </div> 
                   <div style={{marginLeft:"18px"}}  onClick={()=>{showstep[3]?setShowstep({...showstep , 3:false}):setShowstep({...showstep , 3:true}) }}>{showstep[3] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" />:<FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                </div>
                <div style={{display: !showstep[3] ?"block" :"none"}} className="card-body">
                    <div className="container">
                         <div className="row mb-2">
                        <div className=" col-lg-5 col-sm-5 mt-4" >
                            <CheckableButton
                               readOnly={step != 3}
                               defaultChecked={response?.IsPaid}
                               defaultValue={response?.IsPaid}
                               labelText={"Paid"}
                               labelFalseText={"UnPaid"}
                               inputName={"IsPaid"}
                               type="checkradio"
                               mode={localMode}
                            />                                
                        </div>                            
                         <div className="col-lg-7 col-sm-4 mt-1">
                           <Textbox
                             readonly={step != 3}
                             Change={setDataChange}
                             mode={localMode}
                             resetForm={resetForm}
                             textboxType="textarea"
                             textboxName="PayDescription"
                             labelText="PayDescription"
                             maxLength={900}
                             defaultValue={response?.PayDescription}
                           />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <div className="d-flex justify-content-end">
                {localMode !== "view" ?
                    <>
                        <Button value="Save" btnType="submit" />
                        <Button
                            btnType="cancel"
                            value="Cancel"
                            onClick={() => { Toast(trans("modalclosemessage"), "warning", "alert", onHide) }}
                        />
                    </> 
                    :
                     <Button
                        btnType="primary"
                        value="Close"
                        onClick={onHide}
                      />
                }
            </div>               
            </div>
            : <Loading/>
           }

        </form>
    );
}