import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {  useEffect, useRef, useState } from "react"
import { trans } from "utils/helpers"

interface ButtonToolbarCreateWarehouse {
    onClick?: () => void,
    Callback: (id: any) => void,
    id?,


    
}
export const ButtonToolbarCreateWarehouse: React.FC<ButtonToolbarCreateWarehouse> = ({ id, onClick, Callback }) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    const buttonToolbarProcessStarting = useRef(null);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [options, setOptions] = useState<any>([{Id:2  , Name :`${trans("Warehouse")}`},{Id:1  , Name :`${trans("WarehouseDetail")}` }])
    const [selectoptions, setSelectOptions] = useState<any>()
    const [count, setCount] = useState<any>();
    const showSubMenuRef = useRef<any>(false);

    function showSubMenuFunction() {
        setShowSubMenu(true)
        showSubMenuRef.current = true
    }
    function hideSubMenuFunction() {
        setShowSubMenu(false)
        showSubMenuRef.current = true

    }
    function selectboxValSetter(event) {
        setSelectOptions(event.currentTarget.getAttribute('data-value'))
        Callback && Callback(event.currentTarget.getAttribute('data-value'));
        setShowSubMenu(false)
        if (buttonToolbarProcessStarting.current) (buttonToolbarProcessStarting.current as HTMLButtonElement).blur();
    }
    useEffect(() => {
        
        let flag = true
        const callback = (event: KeyboardEvent) => {
             event.stopPropagation()
            if("F4" == event.key  ){              
                showSubMenuRef.current = !showSubMenuRef.current 
                setShowSubMenu(showSubMenuRef.current)
            }  
            flag = true
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, [id]);

    return (
        <> {options && <div className="toolbar-btn-wrapper">
            <button ref={buttonToolbarProcessStarting} onClick={() => { }} onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction} className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
                <div className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>F4</div>
                <FontAwesomeIcon icon={['fas', 'plus']} width="20px" height="20px" />
                <small>{trans("create")}</small>
                <div className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`}>
                    {options && options.map((option, index) => (
                        selectoptions && option.Id ==selectoptions  ?
                        <div key={index} className="toolbar-btn-sub-menu__option "  data-value={`${option.Id}`} onClick={selectboxValSetter}>{option.Name}</div>
                       :
                        <div key={index} className="toolbar-btn-sub-menu__option" data-value={`${option.Id}`} onClick={selectboxValSetter}>{option.Name}</div>
                    ))}

                </div>
            </button>
        </div>
        }
        </>
    )
    }