import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Address } from "components/Address/Address";
import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
import { Headerbar } from "components/Headerbar/Headerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Person } from "components/Person/Person";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { AddressBox } from "pages/InventoryAndSales/Sales/SalesInvoice/components/AddressBox/AddressBox";
import { CustomerPhoneDictionary } from "pages/InventoryAndSales/Sales/SalesInvoice/components/CustomerPhoneDictionary/CustomerPhoneDictionary";
import { CustomerPhoneList } from "pages/InventoryAndSales/Sales/SalesInvoice/components/CustomerPhones/CustomerPhoneList";
import {
  getOrderConstraint,
} from "pages/InventoryAndSales/Sales/SalesOrder/api";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ICON_TYPE, INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { convertAddress, trans, uuidv4 } from "utils/helpers";
import { getDefaultPersonContact, getSalesOrderById, sendBasketAsync } from "./api";
import { CustomerSalesOrderDetailForm } from "./CustomerSalesOrderDetailForm";
import { ContactType } from "types/enum";

interface CustomerSalesOrderProps {
  onHide: () => void;
  mode?: any;
  jobId?: any;
  basketData: any;
  processId?: any;
  useClient?: any;
}
let defualtObj = {
  Id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  SalesOrderId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  ItemId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  UnitId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  SourceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  SourceData: "string",
  ItemEN: "string",
  UnitEN: "string",
  DescriptionEN: "string",
  ItemCode: "string",
  Item: "string",
  ItemTechnicalCode: "string",
  Qty: 0,
  Unit: "string",
  UnitPrice: 0,
  SubTotalPrice: 0,
  Discount: 0,
  DiscountPercent: 0,
  Price: 0,
  Tax: 0,
  Description: "string",
};
export const CustomerSalesOrder = ({
  basketData,
  onHide,
  mode,
  jobId,
  processId,
  useClient,
}: CustomerSalesOrderProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [response, setResponse] = useState<any>();
  const [todayDate, settodayDate] = useState<string | null>(
    new Date().toISOString()
  );
  const [customer, setCustomer] = useState<any>(null);

  const [customerInfo, setCustomerInfo] = useState<any>(null);
  const [showCustomerPhoneForm, setShowCustomerPhoneForm] = useState(false);

  const [showAddressBox, setShowAddressBox] = useState<
    "createMode" | "selectMode" | null
  >(null);
  const [detailMode, setDetailMode] = useState<"create" | "edit" | "view">(
    mode
  );
  const [showForm, setShowForm] = useState(false);
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [salesOrderDetail, setSalesOrderDetail] = useState<any>([]);
  const [dataGridLoading, setDataGridLoading] = useState<any>(true);
  const [totalRows, setTotalRows] = useState<any>({
    ItemCount: 0,
    TotalTax: 0,
    TotalDiscount: 0,
    SubTotalPrice: 0,
  });
  const layoutContext = useContext(LayoutContext);
  const [OrderConstraint, setOrderConstraint] = useState<any>({});
  const [payment, setPayment] = useState<any>({ totalprice: 0, Prepayment: 0 });
  const [typePhoneContact, setTypePhonContact] = useState<any>();
  const [receive, setReceive] = useState<any>({
    GrandTotalPrice: 0,
    Prepayment: 0,
  });
  const [lastWarehouse, setLastWarehouse] = useState<any>(null);
  const [changeAddress, setChangeAddress] = useState<any>();
  const [person, setPerson] = useState<any>(null);
  const formRef=useRef<any>(null)

  const selectedObjCallback = (obj, index) => {
    setSelectedObj(obj);
  };
  async function getOrderConstraintAsync(withLoading = false) {
    if (withLoading) setLoading(false);
    try {
      const res = await getOrderConstraint();
      setOrderConstraint(res.Data);
    } catch (error) {}
    if (withLoading) setLoading(true);
  }

  function setSalesOrderDetailHandler(data) {
    setDataGridLoading(false);
    setSalesOrderDetail(data);
    setDataGridLoading(true);
  }

  const handlesetPriceListDetail = (newObj) => {
    setTotalRows({
      ItemCount:
        detailMode == "create"
          ? totalRows.ItemCount + +newObj.Qty
          : totalRows.ItemCount + (+newObj.Qty - +selectedObj.Qty),
      TotalTax:
        detailMode == "create"
          ? totalRows.TotalTax + +newObj.Tax
          : totalRows.TotalTax + (+newObj.Tax - +selectedObj.Tax),
      TotalDiscount:
        detailMode == "create"
          ? totalRows.TotalDiscount + +newObj.Discount
          : totalRows.TotalDiscount +
            (+newObj.Discount - +selectedObj.Discount),
      SubTotalPrice:
        detailMode == "create"
          ? totalRows.SubTotalPrice + +newObj.SubTotalPrice
          : totalRows.SubTotalPrice +
            (+newObj.SubTotalPrice - +selectedObj.SubTotalPrice),
    });
    if (detailMode == "create") {
      setSalesOrderDetailHandler([...salesOrderDetail, newObj]);
    } else {
      let newItemes = salesOrderDetail?.map((item: any) => {
        if (item.Id == newObj.Id) {
          return newObj;
        } else return item;
      });
      setSalesOrderDetailHandler(newItemes);
    }
  };
  async function getDefaultPersonContactAsync() {
    try {
      // const res2 = await getPersonCustomer(personId)
      const res = await getDefaultPersonContact(
        layoutContext.UserPerson.PersonId
      );
      setCustomerInfo({ ...res.Data });
    } catch (error) {}
  }
  const navigate = useNavigate();
  const handleCustomerSalesOrderFormSubmit = async (event) => {
    event.preventDefault();
    let SalesOrderDetails: any = [];
    if (processId || useClient) {
    salesOrderDetail.map((item) => {
    SalesOrderDetails.push({
          ItemId: item.ItemId,
          Qty: item.Qty,
          UnitId: item.UnitId,
          Description: item.Item,
        });
      });
    } else {
      salesOrderDetail.map((item) => {
        SalesOrderDetails.push({
          ItemId: item.ItemId,
          Qty: item.Qty,
          UnitId: item.UnitId,
          Description: item.Item,
        });
      });
    }

    let data = {
      CustomerName: customer?.Name
        ? customer?.Name
        : layoutContext.currentUserPosition.Description.split("-")[0],
        CustomerPhone: customer?.CustomerPhone?.Name.split(":")[1]?customer?.CustomerPhone?.Name.split(":")[1] :customer?.CustomerPhone?.Name??null,
        CustomerMobilePhone: customer?.CustomerMobilePhone?.Name.split(":")[1]?customer?.CustomerMobilePhone?.Name.split(":")[1] :customer?.CustomerMobilePhone?.Name??null,
        CustomerAddress: formRef.current.Address?.value??null,
      CustomerEconNo: formRef.current.CustomerEconNo?.value,
      CustomerNationalCode: formRef.current.CustomerNationalCode?.value,
      SalesOrderDate: todayDate,
      Description: formRef.current.Description.value,
      PaymentMethodId: formRef.current.PaymentMethodId?.value,
      SalesOrderDetails: SalesOrderDetails,
      RecipientPersonId: person?.Id ? person.Id : null,
      RecipientPersonDescription: person?.Name ? person.Name : null,
    };

    try {
      await sendBasketAsync(data, "post");
      onHide();
      if (!(processId || useClient)) {
        localStorage.removeItem("Basket");
        navigate("/e-commerce/Items");
      }
    } catch (error) {}
  };




  async function makeOrderFromBasket() {
    setLoading(false);
    try {
      //  await getOrderConstraintAsync();
      let SalesOrderDetails: any = [];
      let priceData = {
        TotalTax: 0,
        TotalDiscount: 0,
        SubTotalPrice: 0,
        totalprice: 0,
        Prepayment: 0,
        GrandTotalPrice: 0,
      };
      let count = 0;
      basketData.map((items) => {
        Object.values(items.order).map((orders: any) => {
          count += +orders.count;
          priceData.TotalDiscount +=
            orders.OrderItemId.SalesUnitDiscount * +orders.count;
          priceData.SubTotalPrice +=
            orders.OrderItemId.SalesUnitPrice * +orders.count;
          priceData.totalprice +=
            (orders.OrderItemId.SalesUnitPrice -
              orders.OrderItemId.SalesUnitDiscount) *
            +orders.count;
          SalesOrderDetails.push({
            Id: uuidv4(),
            SalesOrderId: uuidv4(),
            ItemId: orders.OrderItemId.ItemId,
            UnitId: orders.OrderItemId.SalesUnitId,
            SourceId: null,
            SourceData: null,
            ItemEN: null,
            UnitEN: null,
            DescriptionEN: null,
            ItemCode: orders.OrderItemId.ItemCode,
            Item: orders.OrderItemId.ItemDescription,
            ItemTechnicalCode: null,
            Qty: +orders.count,
            Unit: orders.OrderItemId.SalesUnit,
            UnitPrice: orders.OrderItemId.SalesUnitPrice,
            SubTotalPrice: orders.OrderItemId.SalesUnitPrice * +orders.count,
            Discount: orders.OrderItemId.SalesUnitDiscount * +orders.count,
            DiscountPercent: orders.OrderItemId.DiscountPercent,
            Price:
              (orders.OrderItemId.SalesUnitPrice -
                orders.OrderItemId.SalesUnitDiscount) *
              +orders.count,
            Tax: null,
            Description: null,
          });
        });
      });

      setSalesOrderDetail(SalesOrderDetails);
      setTotalRows({
        ItemCount: count,
        TotalTax: priceData?.TotalTax,
        TotalDiscount: priceData?.TotalDiscount,
        SubTotalPrice: priceData?.SubTotalPrice,
      });
      setPayment({
        totalprice: priceData?.totalprice,
        Prepayment: priceData?.Prepayment,
      });
      setReceive({
        GrandTotalPrice: priceData?.GrandTotalPrice,
        Prepayment: priceData?.Prepayment,
      });
    } catch (error) {}
    setLoading(true);
  }

  async function getOrderAsync() {
    setLoading(false);
    try {
      await getOrderConstraintAsync();
      const res = await getSalesOrderById(jobId);
      // setSalesOrderNo(res.Data?.SalesOrderNo)
      setResponse(res.Data);
      let count = 0;
      res.Data.CustomerSalesOrderDetailViewDTO.map((item) => {
        count += +item?.Qty;
      });
      setSalesOrderDetail(res.Data.CustomerSalesOrderDetailViewDTO);

      setTotalRows({
        ItemCount: count,
        TotalTax: res.Data?.TotalTax,
        TotalDiscount: res.Data?.TotalDiscount,
        SubTotalPrice: res.Data?.SubTotalPrice,
      });
      setPayment({
        totalprice: res.Data?.totalprice,
        Prepayment: res.Data?.Prepayment,
      });
      setReceive({
        GrandTotalPrice: res.Data?.GrandTotalPrice,
        Prepayment: res.Data?.Prepayment,
      });
    } catch (error) {}
    setLoading(true);
  }
const handleDeleteRow = async (id: string = selectedObj.Id) => {
    let newPriceListDetail: any = [...salesOrderDetail];
    let obj = newPriceListDetail.filter((e) => e.Id == id)[0];

    setTotalRows({
      ItemCount: totalRows.ItemCount - +obj.Qty,
      TotalTax: totalRows.TotalTax - +obj.Tax,
      TotalDiscount: totalRows.TotalDiscount - +obj.Discount,
      SubTotalPrice: totalRows.SubTotalPrice - +obj.SubTotalPrice,
    });
    setSalesOrderDetailHandler(newPriceListDetail.filter((e) => e.Id != id));
  };

  useEffect(() => {
    getDefaultPersonContactAsync();
  }, []);
  useEffect(() => {
    if (basketData && !(processId || useClient)) makeOrderFromBasket();
    if (mode != "create") {
      getOrderAsync();
    } else {
      getOrderConstraintAsync(true);
    }
  }, []);

  useEffect(() => {
    setPayment({
      ...payment,
      totalprice:
        +totalRows?.SubTotalPrice -
        +totalRows?.TotalDiscount +
        totalRows.TotalTax,
    });
  }, [totalRows]);



  return (
    <>
      {showForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowForm(false);
          }}
          show={showForm}
          title={"SalesOrderDetailForm"}
        >
          <CustomerSalesOrderDetailForm
            CustomerSalesWarehouse={OrderConstraint?.EcommerceWarehouseId}
            setLastWarehouse={setLastWarehouse}
            lastWarehouse={lastWarehouse}
            mode={detailMode}
            onHide={() => {
              setShowForm(false);
            }}
            priceList={OrderConstraint?.EcommercePriceListId}
            OrderConstraint={OrderConstraint}
            selectedObj={selectedObj}
            CustomerSalesOrderDetailCallBack={(e) =>
              handlesetPriceListDetail(e)
            }
            personId={layoutContext.currentUserPosition.PersonId}
          />
        </ModalForm>
      ) : (
        <></>
      )}
      {showCustomerPhoneForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowCustomerPhoneForm(false);
          }}
          show={showCustomerPhoneForm}
          title={"CustomerPhoneForm"}
        >
          <CustomerPhoneList
            Current={true}
            personId={customer?.Id}
            onHide={() => {
              setShowCustomerPhoneForm(false);
            }}
          />
        </ModalForm>
      ) : (
        <></>
      )}
      {showAddressBox ? (
        <ModalForm
          modalHeight={49}
          onHide={() => {
            setShowAddressBox(null);
          }}
          mode={mode}
          show={showAddressBox && true}
          title={"Address"}
        >
          <AddressBox
            Current={true}
            personId={customer?.Id}
            onHide={() => {
              setShowAddressBox(null);
            }}
            status={showAddressBox}
            AddressHandeler={(e) => {
              setCustomerInfo({
                ...customerInfo,
                Address: JSON.stringify(e).toLowerCase(),
              });
              setShowAddressBox(null);
            }}
          />
        </ModalForm>
      ) : (
        <></>
      )}
      <div className="person-contact-form card">
        <div className="card-body">
          {loading ? (
            <div className="container-fluid">
              <form onSubmit={handleCustomerSalesOrderFormSubmit} ref={formRef}>

                <div className="row ">
                  <div className="col-lg-2">
                    <Textbox
                      textboxType="text"
                      textboxName="salesorderno"
                      labelText="salesorderno"
                      defaultValue={
                        mode==='create'?OrderConstraint?.DocNo:
                        response?.SalesOrderNo ? response?.SalesOrderNo : ""
                      }
                      resetForm={resetForm}
                      mode={"view"}
                    />
                  </div>
                  <div className="col-lg-2">
                    <Textbox
                      textboxType="text"
                      textboxName="CustomerName"
                      labelText="CustomerName"
                      defaultValue={
                        response?.CustomerName
                          ? response?.CustomerName
                          : layoutContext.currentUserPosition.Description.split(
                              "-"
                            )[0]
                      }
                      resetForm={resetForm}
                      mode={"view"}
                    />
                  </div>
                  <div className="col-lg-2 ">
                    <Textbox
                      textboxType="text"
                      textboxName="CustomerEconNo"
                      labelText="CustomerEconNo"
                      defaultValue={
                        response?.CustomerEconNo
                          ? response?.CustomerEconNo
                          : customerInfo?.EconNo
                      }
                      resetForm={resetForm}
                      mode={mode}
                      required={true}
                    />
                  </div>
                  <div className="col-lg-2">
                    <Textbox
                      textboxName="CustomerNationalCode"
                      textboxType="nationalCode"
                      labelText="CustomerNationalCode"
                      defaultValue={
                        response?.CustomerNationalCode
                          ? response?.CustomerNationalCode
                          : customerInfo?.NationalId
                      }
                      resetForm={resetForm}
                      mode={mode == "view" ? "view" : mode}
                    />
                  </div>
                  <div className="col-lg-1">
                    <DictionarySelectBox
                      label="PaymentMethod"
                      name="PaymentMethodId"
                      baseUrl={INVENTORYANDSALES_URL}
                      endPoint={
                        ENDPOINTSINVENTORYANDSALES.Sales
                          .BaseDefinitionAndSetting.PaymentMethod.dictionary
                      }
                      resetForm={resetForm}
                      value={response?.PaymentMethodId}
                      mode={mode}
                    />
                  </div>
                  <div className="col-11 col-lg-2 d-flex">
                  <div className="flex-grow-1">
                  <CustomerPhoneDictionary
                    type={ContactType.Phone}
                    name="CustomerPhone"
                    label="CustomerPhone"
                    resetForm={resetForm}
                    valueString={
                      response?.CustomerPhone || mode!='create'
                        ? response?.CustomerPhone
                        : customerInfo?.PhoneNumber
                    }
                    mode={mode == "view" ? "view" : mode}
                    returnObj={true}
                    personId={
                      response?.CustomerPersonId
                        ? response?.CustomerPersonId
                        : layoutContext.UserPerson.PersonId
                    }
                    onChange={(e) => {
                      setCustomer((prev)=>{return{ ...prev, CustomerPhone: e }});
                    }}
                  />
                  </div>
               
                {mode != "view" && (
                  <div
                    style={{
                      marginTop: "25px",
                      width: "1%",
                      // marginRight: "-25px",
                    }}
                    className="ms-4"
                  >
                    <Button
                      Icon={true}
                      value={
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setShowCustomerPhoneForm(true);
                            setTypePhonContact(ContactType.Phone);
                          }}
                          className="checkButton d-flex align-items-center justify-content-center"
                        >
                          <FontAwesomeIcon
                            icon={[ICON_TYPE, "plus"]}
                            size="2x"
                          />
                        </button>
                      }
                      btnType="primary"
                    />
                  </div>
                )}
                </div>
                <div className="col-11 col-lg-2 d-flex">
                  <div className="flex-grow-1">

                  <CustomerPhoneDictionary
                    type={ContactType.Mobile}
                    name="CustomerMobilePhone"
                    label="CustomerMobilePhone"
                    resetForm={resetForm}
                    valueString={ response?.CustomerMobilePhone || mode!='create'
                      ? response?.CustomerMobilePhone 
                      : customerInfo?.MobilePhone}
                    mode={mode == "view" ? "view" : mode}
                    returnObj={true}
                    personId={
                      response?.CustomerPersonId
                        ? response?.CustomerPersonId
                        : layoutContext.UserPerson.PersonId
                    }
                    onChange={(e) => {
                      setCustomer((prev)=>{return{ ...prev, CustomerMobilePhone: e }});

                    }}
                  />
                  </div>
                {mode != "view" && (
                  <div
                    style={{
                      marginTop: "25px",
                      width: "1%",
                      // marginRight: "-25px",
                    }}
                    className="ms-4"
                  >
                    <Button
                      Icon={true}
                      value={
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setShowCustomerPhoneForm(true);
                            setTypePhonContact(ContactType.Mobile);
                          }}
                          className="checkButton d-flex align-items-center justify-content-center"
                        >
                          <FontAwesomeIcon
                            icon={[ICON_TYPE, "plus"]}
                            size="2x"
                          />
                        </button>
                      }
                      btnType="primary"
                    />
                  </div>
                )}
                </div>
                  <div
                    style={{
                      marginTop: "25px",
                      width: "1%",
                      marginRight: "-25px",
                    }}
                    className="ms-3"
                  >
                
                  </div>
                  <div className="col-lg-2">
                    <Person
                      value={response?.Recipient}
                      resetForm={resetForm}
                      mode={mode}
                      WithSearch={false}
                      setPerson={setPerson}
                      returnObj={true}
                      name={"RecipientPersonDescription"}
                      label="receiverperson"
                    />
                  </div>
                  {/* <div className="w-100"></div>
                  <div className="col-lg-5 col-11 row">
                  <Address onChange={(e)=>{setShowAddressBox('selectMode');return false}} withMap={false} hideDetail={true} personContact={
                             response?.CustomerAddress?response?.CustomerAddress:''}mode={"view"}/>   

                  </div> */}

                  <div className="col-lg-5 col-11 d-flex ">
                    <div className="flex-grow-1">
                      <Address
                        onChange={(e) => {
                          setShowAddressBox("selectMode");
                          return false;
                        }}
                        withMap={false}
                        hideDetail={true}
                        personContact={
                          changeAddress
                            ? convertAddress(changeAddress)
                            : response?.CustomerAddress
                            ? response?.CustomerAddress
                            : convertAddress(customerInfo?.Address)
                        }
                        mode={"view"}
                      />
                    </div>

                    {mode != "view" && (
                      <>
                        <div
                          style={{
                            marginTop: "25px",
                            width: "1%",
                            // marginRight: "-25px",
                          }}
                          className="ms-4"
                        >
                          <Button
                            Icon={true}
                            value={
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowAddressBox("createMode");
                                }}
                                className="checkButton d-flex align-items-center justify-content-center"
                              >
                                {" "}
                                <FontAwesomeIcon
                                  icon={[ICON_TYPE, "plus"]}
                                  size="2x"
                                />
                              </button>
                            }
                            btnType="primary"
                          />
                        </div>
                      { mode!=='view' && <div
                          style={{
                            marginTop: "25px",
                            width: "1%",
                            // marginRight: "5px",
                            marginLeft: "20px",
                          }}
                          className="ms-4"
                        >
                          <Button
                            Icon={true}
                            value={
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowAddressBox("selectMode");
                                }}
                                className="checkButton d-flex align-items-center justify-content-center"
                              >
                                {" "}
                                <FontAwesomeIcon
                                  icon={[ICON_TYPE, "search"]}
                                  size="2x"
                                />
                              </button>
                            }
                            btnType="primary"
                          />
                        </div>}
                      </>
                    )}
                  </div>

                  <div className="col-lg-6 ">
                    <Textbox
                      textboxType="textarea"
                      textboxName="Description"
                      labelText="Description"
                      defaultValue={response?.Description}
                      maxLength={900}
                      resetForm={resetForm}
                      mode={mode}
                    />
                  </div>
                </div>
          
              </form>
                <>
                  <div className="mt-3">
                    <FormWithTitle title={trans("Details")}>
                      <div>
                        <div className="row justify-content-between">
                          {mode != "view" && (
                            <div
                              onClick={() => {
                                setDetailMode("create");
                                setShowForm(true);
                              }}
                              className="col-2 mb-1 btn btn-success btn-sm d-flex align-items-center justify-content-between "
                              style={{
                                width: "70px",
                                maxHeight: "30px",
                                marginRight: "20px",
                                minWidth: "70px",
                              }}
                            >
                              <>
                                <span className=" font-size-md ms-2 ">
                                  {trans("add")}
                                </span>
                                <FontAwesomeIcon
                                  size="xs"
                                  icon={["fas", "plus"]}
                                  className="color_text_light d-flex Requests__chatbox-reply-btn mx-1 p-1"
                                  color="white"
                                />
                              </>
                            </div>
                          )}
                          <div
                            className={`${
                              mode != "view" ? "col-10" : "col-12 "
                            } row `}
                          >
                            <div className="d-flex justify-content-end align-items-center">
                              <div className="col-12 col-lg-8 row justify-content-end mb-1">
                                <Headerbar
                                  withPading={false}
                                  headerContents={totalRows}
                                  hasBorder={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {dataGridLoading ? (
                          <>
                            {salesOrderDetail?.length >= 0 ? (
                              <>
                                <DataGrid
                                  convertDateColNames={["ExpireDate"]}
                                  DoubleClick={(e) => {
                                    setSelectedObj(e);
                                    setShowForm(true);
                                  }}
                                  parentName={"SalesOrderDetailList"}
                                  startIndex={9}
                                  theadData={Object.keys(defualtObj)}
                                  tbodyData={
                                    salesOrderDetail?.length > 0
                                      ? salesOrderDetail
                                      : [defualtObj]
                                  }
                                  totalRecords={
                                    salesOrderDetail?.length > 0 ? undefined : 0
                                  }
                                  scrollHeight={"67vh"}
                                  minCellWidth={80}
                                  selectedRowObjectCallback={
                                    selectedObjCallback
                                  }
                                  handleEdit={
                                    mode != "view"
                                      ? (e) => {
                                          setSelectedObj(e);
                                          setDetailMode("edit");
                                          setShowForm(true);
                                        }
                                      : undefined
                                  }
                                  handleDelete={
                                    mode != "view"
                                      ? (e) => {
                                          Toast(
                                            trans("msg_deleteconfirm"),
                                            "warning",
                                            "alert",
                                            () => handleDeleteRow(e)
                                          );
                                        }
                                      : undefined
                                  }
                                  rowSelected={selectedObj}
                                  handleView={(e) => {
                                    setSelectedObj(e);
                                    setDetailMode("view");
                                    setShowForm(true);
                                  }}
                                />
                              </>
                            ) : (
                              <Message
                                message={"NoDataForPreview"}
                                type={"info"}
                              />
                            )}
                          </>
                        ) : (
                          <Loading />
                        )}
                      </div>
                    </FormWithTitle>
                  
                  </div>

                  <div className="card">
                    <div className="card-body ">
                      <div className="row">
                        <div className="col-lg-2">
                          <Textbox
                            useGroupingPrice={true}
                            textboxName="GrandTotalPrice"
                            round={OrderConstraint.FloorTaxDuty}
                            textboxType="text"
                            labelText="GrandTotalPrice"
                            defaultValue={
                              payment.totalprice != 0
                                ? payment.totalprice + ""
                                : response?.TotalPrice
                                ? response?.TotalPrice + ""
                                : payment.totalprice
                            }
                            resetForm={resetForm}
                            mode={"view"}
                          />
                        </div>
            
                      </div>
                    </div>
                    <div className="col-lg-12  my-2 btns_form">
                  {mode != "view" && (
                    <>
                      <Button value="Save" btnType="submit" onClick={handleCustomerSalesOrderFormSubmit} />
                      <Button
                        btnType="cancel"
                        value="Cancel"
                        onClick={() => {
                          onHide();
                        }}
                      />
                    </>
                  )}
                </div>
                  </div>
                </>
               
         
            
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </>
  );
};
