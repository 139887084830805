import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS } from "utils/endpoints"
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http"





export const sendNode = (data: any , method) => http({
     baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseDetail.browse,
    method: method,
    data: data,
    params:{}
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});




export const getFirstNode = (id: any) => http({
     baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.load,
    method: "get",
    params: {
        Id:id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});

export const getSubNode = (id: any) => http({
     baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseDetail.load,
    method: "get",
    params: {
        Id:id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});
