

import { DataGrid } from "components/DataGrid/DataGrid";
import { useEffect, useState } from "react";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { getPageSize } from "utils/helpers";
import { getPersonGrids } from "./api";
import { Button } from "components/Button/Button";
import { Search } from "components/Search/Search";




interface Form {
    serch: string,
    personIdCallBack: (id:string) => void,
}
export const PersonGrid = ({personIdCallBack, serch}: Form) => {

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pages, setPages] = useState<number>(0);
    const [filterlist, setFilterlist] = useState<any>("");



    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("PersonGrid" ,e.currentTarget.value )
        getReferenceActionsAsync(1)
      };

    const handleGoPage = (value) => {
      if (value) {
        if (value < 1 || value > pages) { }
        else setPageNum(+value);getReferenceActionsAsync(+value);
        } else {

      }
    };
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getReferenceActionsAsync(pageNum + 1);
          };
        };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getReferenceActionsAsync(pageNum - 1);
        };
    };

    const submit = () => {
        personIdCallBack(selectedObj.Id)
    }



    const selectedObjCallback = (obj, index) => {
        setSelectedObj(obj);
    }
    const searchHandler = (str) => {
        setPageNum(1);
        setSelectedObj(null)
        if (str === "") {
            setSearch(null)
        } else {
            setSearch(str)
           
        }
    }

    async function getReferenceActionsAsync(PageNum = pageNum) {
        setLoading(false)
        setResponse(null)
        let pageSizeChangedByUser = getPageSize("PersonGrid" )
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getPersonGrids(PageNum,  pageSizeChangedByUser,search?search: serch ,orderBy.length == 0 ?null:  orderBy.join(",") ,filterlist);
            setResponse(res.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }

    useEffect(() => {
        getReferenceActionsAsync();
    }, [forceUpdate,search,orderBy,filterlist]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
            getReferenceActionsAsync();
        }
    }, [resetForm]);


    return (
        <>   
            <div className="cartable-action">
            <div className=" row justify-content-between align-items-centermt-1 btns_form">
                <div className="col-3 d-flex justify-content-between align-items-center mb-1 " >
                    <Search onChange={searchHandler} defultSerach={serch} />
                </div>
                <div className="col-3">
                    <Button value="select" btnType="primary" onClick={submit}/>
                </div>
            </div>
                <div className="card" >
                    <div className="">
                        {loading ?
                            <>
                                {
                                    response?.Data?.length > 0 ?
                                        <>
                                            <DataGrid
                                                parentName={"PersonGrid"}
                                                handlefiltering={(str)=>{setFilterlist(str)  }}
                                                filterStr={filterlist}
                                                orderFormat={setOrderBy}
                                                orderFormatList={orderBy}
                                                startIndex={1}
                                                theadData={Object.keys(response.Data[0])}
                                                tbodyData={response.Data}
                                                minCellWidth={80}
                                                selectedRowObjectCallback={selectedObjCallback}
                                                totalRecords={response.TotalRecords}
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                HandleNextPage={handleNextPage}
                                                HandlePrevPage={handlePrevPage}
                                                handlechangePageSize={handleChangePageSize}
                                                first={() =>{getReferenceActionsAsync(pages); setPageNum(pages)}}
                                                end={() =>{getReferenceActionsAsync(1); setPageNum(1)}}
                                                handleGoPage={handleGoPage}
                                                DoubleClick={submit}
                                            />
                                        </>
                                        : <Message message={"NoDataForPreview"} type={messageType} />
                                }
                            </>
                            : <Loading />
                        }
                    </div>
                </div>
            </div>
        </>

    );
}