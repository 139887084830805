
import { useEffect, useState } from "react";


import { Button } from "components/Button/Button";
import { Textbox } from "components/Textbox/Textbox";
import { PostIndicatorPattern } from "./api";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";


interface Form {
    Title?: string,
    Status?: number,
    onHide: () => void,
    setNewId: (id) => void,

}
export const IndicatorPatternForm = ({onHide ,setNewId}:Form) => {


    const [mode, setmode] = useState("create");
    const [indicatorStatic, setIndicatorStatic] = useState("create");
    const [pattern, setPattern] = useState("create");
    const [resetForm, setResetForm] = useState(false);
    const [EditForm, setEditForm] = useState<any>();
    const [IndicatorPatternModal, setIndicatorPatternModal] = useState(true);






    const submit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (EditForm == null) {
            PostIndicatorPattern({ IndicatorStatic: indicatorStatic ,Pattern: null ,ActivityStatus:1}).then((response) => {setNewId(response.Data) ; onHide() });
        }
    }




    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);
    useEffect(() => {
    }, [IndicatorPatternModal])




    /* #endregion */

    /* #region toolbarActions */



    return (
        <>
              <div className="card mb-4">
              <form id="IndicatorPatternForm" className="TypeSettingForm row card-body " onSubmit={submit}>
                  {/* <div className="col-md-4 col-sm-12">
                      <Textbox onChange={(e)=>{setPattern(e.target.value)}} textboxName={"Pattern"} textboxType="text" resetForm={resetForm} labelText={"Pattern"} mode={mode}  defaultValue={EditForm?.Pattern} />
                  </div>  */}    
                  <div className="col-md-4 col-sm-12">
                      <Textbox onChange={(e)=>{setIndicatorStatic(e.target.value)}} textboxName={"IndicatorStatic"} textboxType="text" resetForm={resetForm} labelText={"IndicatorStatic"} mode={mode}  defaultValue={EditForm?.IndicatorStatic} />
                  </div> 
                  {mode != "create" &&     
                  <div className="col-lg-4 mt-1">
                    {/* DV */}
                      <EnumerationTypeSelect
                       name="GeneralRequestSelect"  
                       mode={mode} 
                       label="Status"
                       enumTypeSelect="ActivityStatus"
                       />
                  </div>                            
                   }
                  {  mode !="view" &&
                        <div className="col-lg-12 mt-1 btns_form">
                        <Button form={"IndicatorPatternForm"} onClick={(e)=>{e.preventDefault();submit(e) }} value="Save" btnType="submit" />
                        <Button btnType="cancel" value="Cancel" onClick={()=>{onHide()}} />
                        </div>
                    }                        
              </form>
            </div >
        </>

    );
}