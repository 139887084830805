import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";


export const getEvets = () => http({
    url: ENDPOINTS.Widget.weekEvents,
    method: 'get'
}).then((res) => {
    
    return res.data
}).catch((err) => {
   
    return Promise.reject( err);
});

export const getpersonalTask = () => http({
    url: ENDPOINTS.Widget.personalTask,
    method: 'get'
}).then((res) => {
    
    return res.data
}).catch((err) => {
   
    return Promise.reject( err);
});

export const getDayInfo = () => http({
    url: ENDPOINTS.Widget.dayInfo,
    method: 'get'
}).then((res) => {
    
    return res.data
}).catch((err) => {
    
    return Promise.reject( err);
});
export const getBords = (pageSize ,pageNum) => http({
    url: ENDPOINTS.Widget.bords,
    method: 'get',
    params:{
        PageSize: pageSize  ,
        PageNumber:  pageNum
    }
}).then((res) => {
    
    return res.data
}).catch((err) => {
    
    return Promise.reject( err);
});
export const dayMessageAsync = () => http({
    url: ENDPOINTS.Widget.dayMessage,
    method: 'get',
}).then((res) => {
    
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const getWidgetList = (IsPublic) => http({
    url: ENDPOINTS.Widget.widgetListName,
    method: 'get',
    params:{
        IsPublic:IsPublic
    }
}).then((res) => {
    
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const getSalesInfo = () => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Widget.SaleWidget,
    method: 'get',

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
