
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Toolbar } from "components/Toolbar/Toolbar";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { SetActivePersonSignatureApi, SetInactivePersonSignatureApi, deletePersonSignatureApi, getPersonSignatureIdApi, setDefaultPersonSignatureApi } from "./api";
import { PersonSignatureForm } from "./componenet/PersonSignatureForm";
import "./style.scss";



interface Form {
    Title: string,
    Status: number
}
export const PersonSignaturePage = () => {


    const layoutContext = useContext(LayoutContext);
    const [response, setResponse] = useState<Response | any>([]);
    const [selected, setSelected] = useState<object | any>(null);
    const [mode, setmode] = useState<string | any>();
    const [forceUpdate, setForceUpdate] = useState(false);
    const [showForm, setshowForm] = useState(false);
    const [showOperations, setShowOperations] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");

    const [currentOperation, setCurrentOperation] = useState(-1);



    
    function toggleShowOperations(operationIndex) {
        setCurrentOperation(operationIndex);
        if (operationIndex == currentOperation || currentOperation == -1) {
            setShowOperations(!showOperations)
        }
        else
            setShowOperations(true)
    }




    async function handleDelete(item) {
        try {
        const res = await deletePersonSignatureApi(item.Id )      
        setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }


    }


    async function handleActivity(item) {
        try {
            if(item.ActivityStatus == 1 ){
                const res = await SetInactivePersonSignatureApi(item.Id)      
                
            }else{
                
                const res = await SetActivePersonSignatureApi(item.Id)      

            }
        setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }


    }
    async function handleChangeDefault(item) {
        try {
        const res = await setDefaultPersonSignatureApi(item.Id)      
        setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }

    }
















    async function getPersonSignatureAsync() {
        setLoading(false)
        try {
        const res = await getPersonSignatureIdApi(layoutContext.UserPerson.PersonId);
        
        setResponse(res); 
                   
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }


    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            // case "excel": alert("excel "); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": setmode("create");setSelected(null); setshowForm(!showForm); break;
            case "view": if (selected) { setmode("view"); setshowForm(!showForm) }; break;
            case "edit": if (selected) { setmode("edit"); setshowForm(!showForm) }; break;
            case "help": alert("call api help"); break;
        }
    };
    /* #endregion */

    
    useEffect(() => {
        setResponse(null)
        getPersonSignatureAsync();
        setShowOperations(false)
        setCurrentOperation(-1);
    }, [forceUpdate]);



    return (
        <>
            <Toolbar excel={false} emptyList={response?.length == 0} id={selected} create={true} view={false} search={true} handleClickToolbar={clickToolbarCallback} />
            <div className="card" >
                
            <div className="card-body">
                    { showForm &&
                        <PersonSignatureForm reset={()=>{setForceUpdate(!forceUpdate)}} Cancelcallback={setshowForm} />
                    }
            </div>

            <div className="card-body">
                    {loading ?
                    <div  className="card"> 
                        {response?.length >0 ? 
                        <div style={{display:"flex" , flexDirection:"row" ,flexWrap :"wrap"}} className="card-body">
                            {
                                response.map((item , index)=>(
                                 <div className={`col-lg-2 SignCard   ${item.IsDefault ? "Signactive": "" }`}  >
                                    {
                                        !item.IsDefault && 
                                    <div key={index} style={{top:"0px" , width :"100%" ,position:"absolute"}} className="operations">
                                       <button className="operations__trigger-btn" onFocus={() => {toggleShowOperations(index) }} onBlur={() => {toggleShowOperations(index) ; setShowOperations(false)}}>
                                           <FontAwesomeIcon icon={['fas', "ellipsis-v"]} />
                                           <div style={{ top: `2px` , right:"0px" , width:"180px" }} className={`operations__operations-icons pt-1 ${showOperations && index == currentOperation? "" : "d-none"}`}>
                                               
                                                   <div className={`operations__operations-icon-wrapper`} >
                                                       <div className="operations__operations-icon-wrapper" onClick={() => handleDelete(item)}>
                                                           <p>{trans("Delete")}</p>
                                                       </div>
                                                   </div>
                                                   <div className={`operations__operations-icon-wrapper`} >
                                                       <div className="operations__operations-icon-wrapper" onClick={() => handleChangeDefault(item)}>
                                                           <p>{trans("setasdefault")}</p>
                                                       </div>
                                                    </div>   
                                                    <div className={`operations__operations-icon-wrapper`} >       
                                                       <div className="operations__operations-icon-wrapper" onClick={() => handleActivity(item)}>
                                                           <p>{item.ActivityStatus ==2 ?trans("active") :trans("Inactive") }</p>
                                                       </div>
                                                   </div>
                                           </div>
                                       </button>
                                    </div>
                                    }
                                    
                                    <object width={"100%"} height={"100%"} data={'data:image/png;base64,'+item.Sign } >
                                    </object>  
                                    {
                                        item.IsDefault && 
                                         <div className="SignCardDefault" >
                                            {trans("default")}
                                         </div>
                                    }
                                    {
                                        item.ActivityStatus ==2 && 
                                         <div className="SignCardDefault" >
                                           {trans("Inactive") }
                                         </div>
                                    }

                                 </div>
                                ))
                            }
                        </div>  
                        : <Message message={"NoDataForPreview"} type={messageType} />     
                         }  
                    </div>
                         : <Loading/>                      
                    }
            </div>                
                
                
             
             
             
             
             </div>


        </>

    );
}