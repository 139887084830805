import { DataGrid } from "components/DataGrid/DataGrid";
import { useEffect, useState } from "react";
import { getReverseList } from "./api";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";

interface searchPropsType {
    referenceId?: string

}
export const ReverseHistory = ({referenceId }: searchPropsType) => {

    const [TotalRecords, setTotalRecords] = useState<any>();
    const [reverseResponse, setReverseResponse] = useState<any>();
    const [reverseLoading, setReverseLoading] = useState<boolean>(false);
    let gridColumns, gridData = [];

    
    async function getReverseListAsync(){
        setReverseLoading(false);
        try {
            const res = await getReverseList( referenceId); 
            setTotalRecords(res.Data.TotalRecords)           
            setReverseResponse(res)       
            
        } catch (error) { }
        setReverseLoading(true);
    }
    
    useEffect(() => {
      getReverseListAsync()
  }, []);



    if (reverseResponse) {
        gridColumns = Object.keys(reverseResponse.Data.Data[0]);
        gridData = reverseResponse.Data.Data;
    }


return (          
  <>       
      {reverseLoading ?
      <>
    {((reverseResponse?.Data?.Data?.length <=TotalRecords) )&&
    <div className="list-card mb-2">
    <FormWithTitle title="ReversedReferenceList">
      <div className="mb-3">
        <DataGrid
          disabled={true}
          rowSelected=""
          totalRecords={TotalRecords}
          parentName={"reverseList"}
          theadData={gridColumns}
          tbodyData={gridData}
          minCellWidth={80}
          startIndex={2}
          selectedRowObjectCallback={()=>{}}
          scrollHeight={"150px"}
          />
          </div>
        </FormWithTitle>
          </div>
          }
          </>
        :<></>}
      </>   
    )
}