import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { getDefaultGroups } from "./api/api";
import { DefaultGroupMembers } from "./DefaultGroupMembers";

export const DefaultGroups = () => {
  const [forceUpdate, setForceUpdate] = useState(true); // integer state
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<Response | any>();
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [showFormData, setShowFormData] = useState<boolean>(false);
  const [showFormMembers, setShowFormMembers] = useState<boolean>(false);
  const [selectedObj, setSelectedObj] = useState<any>();
  const [formCancleBtn, setFormCancleBtn] = useState<boolean>();
  const [mode, setMode] = useState<any>()
  const [messageType, setMessageType] = useState<any>("info")
  const [orderBy, setOrderBy] = useState<any>([]);
  const [filterlist, setFilterlist] = useState<any>("");
  const [search, setSearch] = useState(null);
  const [pages, setPages] = useState<number>(0);


  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "reference":alert("call api reference with Id :");break;
      case "view": if (selectedObj?.Id) handleViewRow(); break;
      case "excel":downloadCSV(response.Data ,1 ,trans("DefaultGroups"));break;
      case "refresh":setForceUpdate(!forceUpdate);break;
      case "members": if (selectedObj?.Id){setShowFormData(false); setShowFormMembers(!showFormMembers); setMode("members")}; break;
    }
  };


  

  const searchHandler = (str) => {
    setPageNum(1);
    if(str =="" ){
        setSearch(null)
    }else{
        setSearch(str)
    }
  }
  async function getDefaultGroupsAsync(PageNum = pageNum) {
    setLoading(false);
    setResponse(null);
    let pageSizeChangedByUser = getPageSize("DefaultGroups" );
    setPageSize(pageSizeChangedByUser)
    try {
      const res = await getDefaultGroups(PageNum,  pageSizeChangedByUser,orderBy?.join(",") ,search ,filterlist);
      setResponse(res.Data);
      setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
      setMessageType("info")
    } catch (error) {
      setMessageType("error")
    }
    setLoading(true);
  }


  const handleChangePageSize = (e: any) => {
    setPageNum(1);
    setPageSize(e.currentTarget.value);
    getPageSize("DefaultGroups" ,e.currentTarget.value )
    getDefaultGroupsAsync(1)
  };
  const handleGoPage = (value) => {
    if (value) {
      if (value < 1 || value > pages) { }
      else setPageNum(+value);getDefaultGroupsAsync(+value);
    } else {

    }
  };
  const handleNextPage = () => {
    if(pageNum < pages){
      setPageNum(pageNum + 1);
      getDefaultGroupsAsync(pageNum + 1);
    }
  };
  const handlePrevPage = () => {
    if(pageNum > 1){
      setPageNum((pageNum)=>{return +pageNum - 1});
      getDefaultGroupsAsync(pageNum - 1);
    }
  };

  const groupPositionIdCallback = (obj, index) => {
    if(sessionStorage.getItem("changingforms")+""=="true"){
      Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false")});
  }else{
      setSelectedObj(obj)         
  };
  }

  const handleViewRow = () => {
    setMode("view");
    setShowFormMembers(!showFormMembers)
    setShowFormData(false); 
  }

  const handleViewModalForm = () => {
    setShowFormMembers(false)

  }

  useEffect(() => {
    getDefaultGroupsAsync();
    setForceUpdate(true)
  }, [forceUpdate,search,orderBy,filterlist]);

  return (
    <>

      <Toolbar
        id={selectedObj?.Id} handleSearch={searchHandler} handleClickToolbar={clickToolbarCallback} emptyList={response?.TotalRecords == 0}
        search
        members
      />
      {showFormData && !formCancleBtn ?
          <>
          </>

        : null}
      {showFormMembers ? <ModalForm modalHeight={63} mode={mode} onHide={handleViewModalForm} modalWidth={mode == "view" ? 75 : ""} show={showFormMembers} modalProfileKey={"Users"}    title={trans("Users")+ " : " +selectedObj?.Title } > <DefaultGroupMembers  selectedObj={selectedObj}   /></ModalForm> : null}
      <div className="col-lg-12 mt-0" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
        <div className="card">
            {loading ? (
              <>
                { response?.Data?.length > 0 ? (
                  <DataGrid
                    orderFormat={setOrderBy}
                    orderFormatList={orderBy}
                    handlefiltering={setFilterlist}
                    filterStr={filterlist}
                    parentName={"DefaultGroups"}
                    theadData={Object.keys(response.Data[0])}
                    minCellWidth={80}
                    tbodyData={ response.Data}
                    selectedRowObjectCallback={groupPositionIdCallback}
                    handleView={handleViewRow}
                    totalRecords={response.TotalRecords}
                    pageNum={pageNum}
                    pageSize={pageSize}
                    HandleNextPage={handleNextPage}
                    HandlePrevPage={handlePrevPage}
                    handlechangePageSize={handleChangePageSize}
                    first={() =>{ getDefaultGroupsAsync(pages);setPageNum(pages)}}
                    end={() => {getDefaultGroupsAsync(1);setPageNum(1)}}
                    handleGoPage={handleGoPage}
                  />
                ) : <Message message={"NoDataForPreview"} type={messageType} />}
              </>
            ) : (
              <Loading />
            )}
          </div>
          <Message message={"DefaultGroupsDescription"} type={"info"} />
      </div>
    </>
  );
};
