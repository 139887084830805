import { Button } from 'components/Button/Button';
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { Response } from "types/types";
import { getBusinessActivityType, postBusinessActivityTypeApi, putBusinessActivityTypeApi } from "./api";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
interface Prop {
    resetForm?: boolean,
    onHide: () => void,
    selectedObj?: any,
    mode:string,
    reload:()=>void,
}
interface Form{
    Name:string,
    Code:string,
}
export const BusinessActivityTypeForm = ({ reload,onHide, mode ,selectedObj}: Prop) => {
    const [resetForm, setResetForm] = useState(false);
    const [response, setResponse] = useState<Response | any>();
    const [userChanges, setUserChanges] = useState({});
    const [loading,setLoading] = useState<any>()

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    async function getBusinessActivityTypeId() {
        setLoading(false)
        try {
            const data = await getBusinessActivityType(selectedObj?.Id);
            setResponse(data.Data);
        } catch (error) {
            
        }
        setLoading(true)
    }

    const submit = async(e) => {
        e.preventDefault();
        try {
            if (response == null) {
                postBusinessActivityTypeApi({
                    IsDefault: e.target.checkbox.checked,
                    Title: e.target.BusinessActivityType.value,
                    TitleEN: e.target.TitleEN.value,
                    SystemKey: e.target.systemKey.value, 
                 }).then(() => {sessionStorage.setItem("changingforms" , "false");setUserChanges({});reload() });
            } else {
                putBusinessActivityTypeApi({              
                    Title: e.target.BusinessActivityType.value,
                    TitleEN: e.target.TitleEN.value,
                    ActivityStatus: +e.target.BusinessActivityTypeSelect.value , 
                    systemKey: e.target.systemKey.value,
                    IsDefault: e.target.checkbox.checked,
                    Id: selectedObj?.Id, 
                }).then(() => { sessionStorage.setItem("changingforms" , "false"); setResponse(null);setUserChanges({});reload() })
            }
            
        } catch (error) {
            
        }
    }

    useEffect(() => {
        if(selectedObj && mode != "create"){
        getBusinessActivityTypeId()
        }else{
            setResponse(null)
            setLoading(true)
        }
    }, [selectedObj ,mode])

    
    useEffect(()=>{
        if(resetForm){
            setUserChanges({})
            setResponse(null)
        }
    },[resetForm])


    return (
        <>    
            <div className="card mb-4">
        {loading ?                        
                <form className="TypeSettingForm row card-body " onSubmit={submit}>
                  <div className="col-md-4 col-sm-12">
                      <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"BusinessActivityType"} textboxType="text" resetForm={resetForm} labelText={"Description"} mode={mode}  defaultValue={response?.Title} />
                  </div>     
                  <div className="col-md-4 col-sm-12">
                      <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}}  textboxName={"TitleEN"} textboxType="text" resetForm={resetForm} labelText={"TitleEN"} mode={mode}  defaultValue={response?.TitleEN} />
                  </div>     
                  <div className="col-md-4 col-sm-12">
                      <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"systemKey"} textboxType="systemKey" resetForm={resetForm} labelText={"systemKey"} mode={mode}  defaultValue={response?.SystemKey} />
                  </div> 
                  {mode != "create" &&          
                   <div className="col-lg-4 mt-1">
                    <EnumerationTypeSelect
                     Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                     name="BusinessActivityTypeSelect"
                     mode={mode}
                     label="Status"
                     enumTypeSelect="ActivityStatus"
                     value={response?.ActivityStatus}
                    />
                   </div>                            
                   }
                  <div className=" mt-4 mb-4" >
                  <CheckableButton
                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                    defaultChecked={response?.IsDefault}
                    defaultValue={response?.IsDefault}
                    labelText={"IsDefault"}
                    inputName={"checkbox"}
                    type="checkbox"
                    mode={mode}
                    />                       
                    </div>     
                    {mode !="view" ?
                        <div className="col-lg-12 mt-1 btns_form">
                             <Button value="Save" btnType="submit" />
                             <Button btnType="cancel" value="Cancel" onClick={()=>{onHide()}} />
                        </div>
                       :
                        <div className="col-lg-12 mt-1 btns_form">
                             <Button btnType="primary" value="close" onClick={()=>{onHide()}} />
                        </div>
                    }                         
                </form>
                :
                <Loading/>
                }         
            </div >   
        </>
    )
}