import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { NotificationConnection } from "utils/notificationHub";


export const BoardNotification = () => {
  
  const [newBoard, setNewBoard] = useState<any>(null);
  const [NotificationConnectionState, setNotificationConnectionState] = useState<any>(null);
  const navigate = useNavigate();

  const handleNavigate = (rout) => {
    
    if(sessionStorage.getItem("changingforms")+""=="true"){
      Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{navigate(rout);sessionStorage.setItem("changingforms" , "false");localStorage.removeItem("formsInChang")  });
    }else{
      navigate(rout)
    }
  }

  

  useEffect(() => {

    setNotificationConnectionState(NotificationConnection);
  }, []);


  useEffect(() => {
    if (NotificationConnectionState ) {
            NotificationConnectionState.on('NewEvent',(text) => {  
                if(text=="BulletinBoard") {
                    setNewBoard(true)

                }  
          });
    }
  }, [NotificationConnectionState]);





  return (
    <>   
    <OverlayTrigger
        placement="bottom"
        delay={{"show" : 300 , "hide":0}}
        overlay={
            <Tooltip className="tooltip" id={`button-tooltip-${"chalkboardg"}`}>
                {trans('BulletinBoard')}
            </Tooltip>
        }
        >
       <Link className="position-relative"  onClick={(e)=>{e.preventDefault();setNewBoard(false);handleNavigate("/")}} to={"/"}>
        <FontAwesomeIcon icon={[ICON_TYPE, "chalkboard"]} />
        {newBoard? <div className="badgeChat"></div>  :<></> }
      </Link> 
      </OverlayTrigger>
    </>
  );
};
