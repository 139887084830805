import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {   useRef, useState } from "react"
import { trans } from "utils/helpers"

interface ButtonToolbarOrganizationType {
    onClick?: () => void,
    Callback: (string: any) => void,

}
export const ButtonToolbarPersonType: React.FC<ButtonToolbarOrganizationType> = ({ onClick, Callback }) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    const buttonToolbarProcessStarting = useRef(null);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [selectoptions, setSelectOptions] = useState<string>("PersonLegalTitle")
    

    function showSubMenuFunction() {
        setShowSubMenu(true)
    }
    function hideSubMenuFunction() {
        setShowSubMenu(false)
    }
    function selectboxValSetter(event) {
        setSelectOptions(event.currentTarget.getAttribute('data-value'))
        Callback && Callback(event.currentTarget.getAttribute('data-value'));
        setShowSubMenu(false)
        if (buttonToolbarProcessStarting.current) (buttonToolbarProcessStarting.current as HTMLButtonElement).blur();
    }


  

    return (
        <> <div className="toolbar-btn-wrapper" >
            <button  ref={buttonToolbarProcessStarting} onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction} className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
                <div className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>F12</div>
                <FontAwesomeIcon icon={['fas', 'filter']} width="20px" height="20px" />
                <small>{trans("Filter")}</small>
                <div className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`}  >
                     {selectoptions == "PersonLegalTitle" ? 
                     <div style={{cursor:"pointer"}}  className="toolbar-btn-sub-menu__option selected"  data-value={"PersonLegalTitle"} onClick={selectboxValSetter}>{trans("PersonLegal")}</div>
                     :
                     <div style={{cursor:"pointer"}}  className="toolbar-btn-sub-menu__option "  data-value={"PersonLegalTitle"} onClick={selectboxValSetter}>{trans("PersonLegal")}</div>
                     }
                     {selectoptions == "PersonRealTitle" ? 
                     <div style={{cursor:"pointer"}} className="toolbar-btn-sub-menu__option selected"  data-value={"PersonRealTitle"} onClick={selectboxValSetter}>{trans("PersonReal")}</div>  
                      :
                      <div style={{cursor:"pointer"}} className="toolbar-btn-sub-menu__option"  data-value={"PersonRealTitle"} onClick={selectboxValSetter}>{trans("PersonReal")}</div>  
                     }
                     </div>
            </button>
        </div>
        
        </>
    )
    }