import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { UserContext } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useContext, useEffect, useState } from "react";
import { downloadCSV, trans } from "utils/helpers";
import { IndicatorPatternsDictionary } from "./IndicatorPatternsDictionary/IndicatorPatternsDictionary";
import { AddNewPattern, deletePattern, editPattern, getPattern, getSecretariatIndicatorPatternList } from "./SecretariatMembers/api/api";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";



interface PatternFormPropsType {
    SecretariatId;
    showForm;

}
export const PatternForm = ({ 
    SecretariatId,
    showForm

}: PatternFormPropsType) => {
     
    const [resetForm, setResetForm] = useState(false);
    const [Patterns , setPatterns ] = useState< any>(null);
    const [Pattern , setPattern ] = useState< any>();
    const [selectedPattern , setSelectedPattern ] = useState< any>();
    const [mode , setmode ] = useState< any>();
    const [showPatterns , setShowPatterns ] = useState< any>(false);
    const [loading , setLoading] = useState(false)
    const [loading2 , setLoading2] = useState(false)
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);
    const [userChanges, setUserChanges] = useState({});


  
    let HeadPatterns:any=[] , BodyPatterns
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
          case "reference":alert("call api reference with Id :");break;
          case "view":if(selectedPattern){getNewPattern();setmode("view") ;mode != "view" ?setShowPatterns(true) : setShowPatterns(!showPatterns)}  break;
          case "edit":if(selectedPattern){getNewPattern();setmode("edit") ;mode != "edit" ?setShowPatterns(true) : setShowPatterns(!showPatterns)}  break;
          case "print":alert("print ");break;
          case "remove":if(!showPatterns){if (selectedPattern)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow);} break;
          case "excel":downloadCSV(Patterns ,3 ,trans("PatternForm"));break;
          case "refresh":setResetForm(true);getPatternorganizationIdAsync(SecretariatId);break;
          case "create":setPattern(null);setResetForm(true);mode != "create" ?setShowPatterns(true) : setShowPatterns(!showPatterns);setmode( "create");break;
        }
      };
    
      const handleDeleteRow = async() => {
        try {
            await deletePattern(selectedPattern.Id)
            getPatternorganizationIdAsync(SecretariatId)
            setResetForm(true)
        } catch (error) {
            
        }
    }
      

    async function sendNodeAsync(data) {
        try{
           await AddNewPattern(data);
           setResetForm(true);
           setUserMadeChanges(false);
           setShowPatterns(false);
           getPatternorganizationIdAsync(SecretariatId);
           sessionStorage.setItem("changingforms" , "false");
           setUserChanges({});
        }catch(err:any){

        }

    }


    async function putNodeAsync(data) {
        try{
           await editPattern(data);
           setResetForm(true);
           setUserMadeChanges(false);
           setShowPatterns(false);
           getPatternorganizationIdAsync(SecretariatId);
           sessionStorage.setItem("changingforms" , "false");
           setUserChanges({});
        }catch(err:any){

        }
    }

    async function getPatternorganizationIdAsync(id) {
      setLoading(false)
        try{
           const res = await getSecretariatIndicatorPatternList(id );
           setPatterns(res.Data)
           setCatchMessage({type:"info",msg:"NoDataForPreview"})
          } catch (error:any) {
              setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
      setLoading(true)
    }



    const getNewPattern = async ()=>{
      setLoading2(false)
      try{
      const res = await getPattern(selectedPattern.Id); 
      setPattern(res.Data)  
      setUserMadeChanges(false)       
     }catch(err:any){

       }
        setLoading2(true)
    }
    const handlePersonContactFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation()
        if(mode=="edit"){
          let data={
            "Id" : selectedPattern.Id,
            "IndicatorPatternId":  event.target.IndicatorPatternId.value,
            "SecretariatId": SecretariatId,
            "LetterType": +event.target.LetterType.value   ,
            "StartSerial":  event.target.StartSerial.value ,
            "ActivityStatus": +event.target.ActivityStatus.value ,
        }
        putNodeAsync(data)
        }else{
        let data={
            "IndicatorPatternId":  event.target.IndicatorPatternId.value,
            "SecretariatId": SecretariatId,
            "LetterType": +event.target.LetterType.value   ,
            "StartSerial":  event.target.StartSerial.value ,
            "ActivityStatus": 1 


            
        }
        sendNodeAsync(data)
        }
    }

    function cancel() {
      if(sessionStorage.getItem("changingforms")+""=="true"){
          Toast(trans("modalclosemessage"),"warning","alert", ()=>{
              sessionStorage.setItem("changingforms" , "false");
              setUserChanges({});
              setShowPatterns(false);
          });
      }else{
        setShowPatterns(false);
      };


  }
  

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };


useEffect(() => {
    if(Object.values(userChanges).filter((item)=>item!=null).length>0){

        sessionStorage.setItem("changingforms" , "true")
          window.addEventListener('beforeunload', handleBeforeUnload);
          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
    }else{
        sessionStorage.setItem("changingforms" , "false")
        window.removeEventListener('beforeunload', handleBeforeUnload);

    }
}, [userChanges]);
    
     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);

    
     useEffect(() => {
      setPattern(null)
      }, [showPatterns]);

     useEffect(() => {
        getPatternorganizationIdAsync(SecretariatId)
      }, [ SecretariatId  ]);

     useEffect(() => {

      if(mode != "create" ){
        if(userMadeChanges){
          Toast(trans("modalclosemessage"),"warning","alertWithoutClose",getNewPattern)
        }else{
          getNewPattern()
        }
      }
    }, [selectedPattern]);



    if(Patterns){
      if(Patterns[0]){
      HeadPatterns = Object.keys(Patterns[0]);
      BodyPatterns = JSON.parse(JSON.stringify(Patterns))   
      }

    }
    return (
        <div className="person-contact-form card">
            <div className="">
            <Toolbar  emptyList={HeadPatterns?.length == 0} id={selectedPattern+showPatterns} create={true} remove={true} edit={true} search={true}   handleClickToolbar={clickToolbarCallback} />
                <div className="card">
                    { 
                        showPatterns &&
                        <>
                        {loading2 ?
                    <form id="PatternForm" className="card-body " onSubmit={handlePersonContactFormSubmit}>
                        <div className="row ">
                        <div className="col-md-4 col-sm-12">
                            <IndicatorPatternsDictionary
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              type= {"SelectBox"}
                              name={"IndicatorPatternId"}
                              required={true}
                              value={Pattern?.IndicatorPatternId ? Pattern?.IndicatorPatternId  : null}
                              mode={mode}
                            />
                        </div>    
                        <div className="col-md-4 col-sm-12">
                            <EnumerationTypeSelect
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              type= "SelectBox"
                              name="LetterType"
                              label="LetterType"
                              enumTypeSelect='LetterType'
                              required={true}
                              value={Pattern?.LetterType ? Pattern?.LetterType  : null}
                              mode={mode}
                            />
                        </div>    
                        <div className="col-md-4 col-sm-12">
                            <Textbox
                               Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                               required={true}
                               defaultValue={Pattern?.StartSerial ? Pattern?.StartSerial  : null}
                               textboxType="text"
                               textboxName="StartSerial"
                               labelText="StartSerial"
                               resetForm={resetForm}
                               mode={mode}
                               />
                        </div>
                            {mode != "create" && 
                                <div className="col-md-4 col-sm-12">
                                    <EnumerationTypeSelect
                                      Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                      required={true}
                                      label="Status"
                                      name="ActivityStatus"
                                      enumTypeSelect="ActivityStatus"
                                      value ={Pattern?.ActivityStatus}
                                      mode={mode}
                                    />
                                </div>                      
                        
                               }
                        </div>
                        {  mode !=="view" &&
                            <div className="col-lg-12 mt-1 btns_form">
                            <Button form={"PatternForm"} value="Save" btnType="submit" />
                            <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                        </div>
                        }   
                    </form>
                     : <div className="row card"> <Loading/>  </div>  }
                    </>
                    }
                    {loading ?
                          (Patterns && Patterns?.length>0 ?
                          <div className="mt-4">
                         <DataGrid
                            parentName={"PatternForm"}
                            multiSelect={false}
                            theadData={HeadPatterns}
                            minCellWidth={80}
                            tbodyData={BodyPatterns}
                            selectedRowObjectCallback={setSelectedPattern}   
                            startIndex={3}   
                            scrollHeight={"300px"}  
                          /> </div> 
                          : <Message message={catchMessage.msg} type={catchMessage.type} />                         
                          )
                      : <Loading/>
                    }
                </div>
            </div>
        </div>
    );
}