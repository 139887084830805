
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { Toast } from "components/ToastShow/ToastShow";
import { downloadCSV, trans } from "utils/helpers";
import { useEffect, useState } from "react";
import { deleteLetterSubject, getLetterSubject, getListLetterSubject, postLetterSubject, putLetterSubject } from "./api/api";
import { Message } from "components/Message/Message";
import { Loading } from "components/Loading/loading";
import { TypeSettingForm } from "components/TypeSettingForm/TypeSettingForm";


interface Form {
    Title: string,
    Status: number
}
export const LetterSubjectTypeSetting = () => {

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [flag, setFlag] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<Form | null>(null);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [search, setSearch] = useState(null);
    const [orderBy, setOrderBy] = useState<any>([]);



    const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }


    const handleCreateAction = () => {
        setEditForm(null);
        setMode("create")
        setShowForm(true)
    }
    const handleEditRow = () => {
        setMode("edit");
        setShowForm(true)
    }
    const handleDeleteRow = async() => {
        try {
            const res = await deleteLetterSubject(selectedObj.Id)
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }
    }
    const handleViewRow = () => {
        setMode("view")
        setShowForm(true)

    }

    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false");});
        }else{
            setSelectedObj(obj)         
        };
    }

    const submit = (e) => {
        e.preventDefault();
        if (editForm == null) {
            postLetterSubject({ Title: e.target.LetterSubject.value }).then(() => {sessionStorage.setItem("changingforms" , "false"); getListLetterSubjectAsync(); setResetForm(true) });
        } else {
            putLetterSubject({ Title: e.target.LetterSubject.value, ActivityStatus: +e.target.LetterSubjectSelect.value , Id: selectedObj.Id }).then(() => {sessionStorage.setItem("changingforms" , "false"); getListLetterSubjectAsync(); setResetForm(true); setEditForm(null) })
        }
        setShowForm(false)
    }

    function cancel() {
        if (editForm != null) {
            setEditForm(null);
            setResetForm(true);
        }
        setShowForm(false)
    }
    async function getListLetterSubjectAsync() {
        setLoading(false)
        try {
            const res = await getListLetterSubject(search ,orderBy.length == 0 ?null:  orderBy.join(","));
            setResponse(res.Data);
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)
    }
    async function getLetterSubjectt() {
        const data = await getLetterSubject(selectedObj?.Id);
        setEditForm({ Title: data.Data.Title, Status: data.Data.ActivityStatus  });

    }
    useEffect(() => {
        getListLetterSubjectAsync();
    }, [forceUpdate, flag , search , orderBy]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);

    if (flag) {
        getListLetterSubjectAsync()
        setFlag(false)

    }

    useEffect(() => {
        if(showForm&&selectedObj && mode != "create"){
            getLetterSubjectt()
        }
    }, [selectedObj ,showForm,mode])

    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,1 ,trans("LetterSubjectType"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id) handleEditRow(); break;
            case "help": alert("call api help"); break;
        }
    };
    /* #endregion */
    return (
        <>
            <Toolbar handleSearch={searchHandler} emptyList={response == null || response.length == 0} id={selectedObj?.Id} create={true} edit={true} remove={true} search={true} handleClickToolbar={clickToolbarCallback} />
            <div className="card">
                <div className="">
                    {loading ?
                        <div className="cartable-action">
                            {showForm&& 
                            <TypeSettingForm  mode={mode} cancel={cancel} handleSubmit={submit} TextboxValue={editForm?.Title} resetForm={resetForm}
                                SelectBoxValue={editForm?.Status} TextBoxName="LetterSubject" SelectBoxName="LetterSubjectSelect" />
                            }
                            {
                                response?.length > 0 ?
                                    <>
                                        <DataGrid
                                            parentName={"LetterSubjectType"}
                                            orderFormat={setOrderBy}
                                            orderFormatList={orderBy}
                                            startIndex={1}
                                            theadData={Object.keys(response[0])}
                                            tbodyData={response}
                                            minCellWidth={80}
                                            scrollHeight={showForm? "25vh": "67vh"}
                                            selectedRowObjectCallback={selectedIdCallback}
                                            handleEdit={handleEditRow}
                                            handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                            handleView={handleViewRow}
                                        />
                                    </>
                                    : <Message message={catchMessage.msg} type={catchMessage.type} />
                            }
                        </div>
                        : <Loading />
                    }
                </div>
            </div>
        </>

    );
}