import { useEffect, useRef, useState } from "react";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import Datepicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { Textbox } from "components/Textbox/Textbox";
import { ICON_TYPE, REPORT_URL } from "utils/constants";
import { ENDPOINTSREPORT } from "utils/endpoints";
import { Button } from "components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trans } from "utils/helpers";

interface OperatorOptionsPropsType {
    item: any,
    setDatesValue: (e) => void,
    index?:number

}
export const OperatorOptions = ({ item  ,setDatesValue,index}: OperatorOptionsPropsType) => {

    const [resetForm, setResetForm] = useState(false);
    const [operatorType, setOperatorType] = useState<any>(1);
    const [dateDefaultValue, setDateDefaultValue] = useState<any>({});
    const [selectedItem, setSelectedItem] = useState<any>([]);

    const [value, setValue] = useState<any>({});
    const inputRef = useRef<any>();
    const inputIdRef = useRef<any>();

    function addOption(e) {
        e.stopPropagation()
        e.preventDefault()
        let selectList = JSON.parse(JSON.stringify(selectedItem))
        if(item.ParameterDataType.includes("Time")|| item.ParameterDataType.includes("Date")){
            setSelectedItem(selectList.concat(dateDefaultValue["value"+item.ParameterNameEN][0]))
            setDatesValue({["value"+item.ParameterNameEN]: selectList.concat(dateDefaultValue["value"+item.ParameterNameEN][0])})
            setDateDefaultValue({})
        }else{
            setSelectedItem(selectList.concat(value))
            setDatesValue({["value"+item.ParameterNameEN]: selectList.concat(value)})
            setValue(null)
        }
        setResetForm(true)
    }

   function DatepickerDataHandler(isoTime){
      if(isoTime){
         if(item.ParameterDataType == "System.TimeOnly"){
            setDateDefaultValue({["value"+item.ParameterNameEN]:[ isoTime.split("T")[1].replaceAll("Z" , "") ]})
            
         }else if(item.ParameterDataType ==  "System.DateOnly"){
            
            setDateDefaultValue({["value"+item.ParameterNameEN]:[ isoTime.split("T")[0].replaceAll("Z" , "") ]})
         }else{

         setDateDefaultValue({["value"+item.ParameterNameEN]:[ isoTime ]})
      }
   }else{
      setDateDefaultValue({["value"+item.ParameterNameEN]:[  ]})
   }
   }
   function DatepickerDataHandler2(isoTime){
      if(item.ParameterDataType == "System.TimeOnly"){
         setDateDefaultValue({["value"+item.ParameterNameEN]:[ dateDefaultValue["value"+item.ParameterNameEN]?.[0],isoTime.split("T")[1].replaceAll("Z" , "") ]})

      }else if(item.ParameterDataType ==  "System.DateOnly"){

         setDateDefaultValue({["value"+item.ParameterNameEN]:[ dateDefaultValue["value"+item.ParameterNameEN]?.[0],isoTime.split("T")[0].replaceAll("Z" , "") ]})
      }else{

         setDateDefaultValue({["value"+item.ParameterNameEN]:[ dateDefaultValue["value"+item.ParameterNameEN]?.[0],isoTime ]})

      }
   }
    useEffect(() => {
        setDatesValue(dateDefaultValue)
    }, [ dateDefaultValue]);
    useEffect(() => {
        if(resetForm){
            setResetForm(false)
        }
    }, [ resetForm]);


    return (
        <div className="row">
            <div className="col-4" style={{height:'35px'}}>
            {index==0 && <small className="d-block mb-1">{trans('operatortype')}</small>}

                <DictionarySelectBox 
                      label= {''}
                      onChange={setOperatorType}
                      name={"OperatorType"+item.ParameterNameEN}
                      endPoint={ENDPOINTSREPORT.ReportFeed.operatorTypes}
                      baseUrl={REPORT_URL}
                      params={{parameterType:item.ParameterDataType}}
                      mode={"create"}                            
                    />
            </div>
            <div style={{height:'35px'}} className={(operatorType==12||operatorType==13)?"col-3 ":"col-6"}>
            {index==0 && <small className="d-block mb-1">{trans('parameterdefaultvalue')}</small>}

                        {
                 {
                     "System.String": (
                        <Textbox
                        withLabel={false}
                        placeholder={(operatorType==12||operatorType==13)?trans('from'):''}
                        defaultValue={item.ParameterDefaultValue}     
                        onChange={(e)=>{if(operatorType==10||operatorType==11){setValue(e.target.value)}}} 
                        textboxType="text"
                        textboxName={"value"+item.ParameterNameEN}
                        labelText={''}
                        resetForm={resetForm}
                        mode={"create"}
            
                    />
                     ),
                     "System.TimeOnly": (
                        <Datepicker
                        mode={"create"}
                        resetForm={resetForm}
                        lable={''}
                        placeholder={(operatorType==12||operatorType==13)?trans('from'):''}
                        setIsoTime={(isoTime: string) => {DatepickerDataHandler(isoTime)}}
                        val={ item.ParameterDefaultValue} //resetForm ? new Date().toISOString() : 
                        withTime ={true}
                        format={"HH:mm"}
                        disableDayPicker={true}
                        onOpenPickNewDate={true}
                      />  

                     ),
                     "System.DateOnly": (
                        <Datepicker
                        mode={"create"}
                        resetForm={resetForm}
                        lable={''}
                        placeholder={(operatorType==12||operatorType==13)?trans('from'):''}
                        setIsoTime={(isoTime: string) => {DatepickerDataHandler(isoTime)}}
                        val={ item.ParameterDefaultValue} //resetForm ? new Date().toISOString() : 
                        format={"YYYY/MM/DD"}
                        onOpenPickNewDate={true}
                      />  
                     ),
                     "System.DateTime": (
                        <Datepicker
                        mode={"create"}
                        resetForm={resetForm}
                        lable={''}
                        placeholder={(operatorType==12||operatorType==13)?trans('from'):''}
                        setIsoTime={(isoTime: string) => {DatepickerDataHandler(isoTime)}}
                        val={ item.ParameterDefaultValue} //resetForm ? new Date().toISOString() : 
                        withTime ={true}
                        onOpenPickNewDate={true}
                      />  
                     ),
                     "System.Char": (
                        <Textbox
                        withLabel={false}
                        placeholder={(operatorType==12||operatorType==13)?trans('from'):''}
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="text"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                     ),
                     "System.Double": (                   
                        <Textbox
                        withLabel={false}
                        placeholder={(operatorType==12||operatorType==13)?trans('from'):''}
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.UInt64": (                   
                        <Textbox
                        withLabel={false}
                        placeholder={(operatorType==12||operatorType==13)?trans('from'):''}
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                         textboxName={"value"+item.ParameterNameEN}
                        labelText={''}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.UInt32": (                   
                        <Textbox
                        withLabel={false}
                        placeholder={(operatorType==12||operatorType==13)?trans('from'):''}
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.UInt16": (                   
                        <Textbox
                        withLabel={false}
                        placeholder={(operatorType==12||operatorType==13)?trans('from'):''}
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.Decimal": (                   
                        <Textbox
                        withLabel={false}
                        placeholder={(operatorType==12||operatorType==13)?trans('from'):''}
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="text"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.Int64": (                   
                        <Textbox
                        withLabel={false}
                        placeholder={(operatorType==12||operatorType==13)?trans('from'):''}
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.Int32": (                   
                        <Textbox
                        withLabel={false}
                        placeholder={(operatorType==12||operatorType==13)?trans('from'):''}
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.Int16": (                   
                        <Textbox
                        withLabel={false}
                        placeholder={(operatorType==12||operatorType==13)?trans('from'):''}
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.Boolean": (
                         <>
                           
                                <CheckableButton
                                defaultChecked={item.ParameterDefaultValue}
                                defaultValue={item.ParameterDefaultValue}
                                labelText={''}
                                inputName={"value"+item.ParameterNameEN}
                                type="checkbox"
                                mode={"create"}
                             />   
                           
                         </>
                     )
                 }[item.ParameterDataType]
                 
             }
            </div>

             {(operatorType==10||operatorType==11)&&
                <div  style={{height:'35px',marginTop:"3px" , width:"10%" ,marginRight:"-27px"}}>
                  <Button Icon={true}  value={<button onClick={addOption} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "check"]}    size="2x"  /></button>} btnType="submit"  />
                </div>
             }



            {(operatorType&&(operatorType==12||operatorType==13))&&
            <div className="col-3 " style={{height:'35px'}}>
                        {
                 {
                     "System.String": (
                        <Textbox
                        withLabel={false}
                        placeholder={(operatorType==12||operatorType==13)?trans('to'):''}
                        defaultValue={item.ParameterDefaultValue	}      
                         textboxType="text"
                        textboxName={"value"+item.ParameterNameEN}
                        labelText={''}
                        resetForm={resetForm}
                        mode={"create"}
            
                    />
                     ),
                     "System.TimeOnly": (
                        <Datepicker
                        mode={"create"}
                        resetForm={resetForm}
                        lable={''}
                        placeholder={(operatorType==12||operatorType==13)?trans('to'):''}
                        setIsoTime={(isoTime: string) => {DatepickerDataHandler2(isoTime)}}
                        val={ item.ParameterDefaultValue} //resetForm ? new Date().toISOString() : 
                        withTime ={true}
                        format={"HH:mm"}
                        disableDayPicker={true}
                        onOpenPickNewDate={true}
                      />  

                     ),
                     "System.DateOnly": (
                        <Datepicker
                        mode={"create"}
                        resetForm={resetForm}
                        lable={''}
                        placeholder={(operatorType==12||operatorType==13)?trans('to'):''}
                        setIsoTime={(isoTime: string) => {DatepickerDataHandler2(isoTime)}}
                        val={ item.ParameterDefaultValue} //resetForm ? new Date().toISOString() : 
                        format={"YYYY/MM/DD"}
                        onOpenPickNewDate={true}
                      />  
                     ),
                     "System.DateTime": (
                        <Datepicker
                        mode={"create"}
                        resetForm={resetForm}
                        lable={''}
                        placeholder={(operatorType==12||operatorType==13)?trans('to'):''}
                        setIsoTime={(isoTime: string) => {DatepickerDataHandler2(isoTime)}}
                        val={ item.ParameterDefaultValue} //resetForm ? new Date().toISOString() : 
                        withTime ={true}
                        onOpenPickNewDate={true}
                      />  
                     ),
                     "System.Char": (
                        <Textbox
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="text"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                         withLabel={false}
                         placeholder={(operatorType==12||operatorType==13)?trans('to'):''}

                        resetForm={resetForm}
                        mode={"create"}
                        />
                     ),
                     "System.Double": (                   
                        <Textbox
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                         withLabel={false}
                         placeholder={(operatorType==12||operatorType==13)?trans('to'):''}

                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.UInt64": (                   
                        <Textbox
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                         withLabel={false}
                         placeholder={(operatorType==12||operatorType==13)?trans('to'):''}

                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.UInt32": (                   
                        <Textbox
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                         withLabel={false}
                         placeholder={(operatorType==12||operatorType==13)?trans('to'):''}

                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.UInt16": (                   
                        <Textbox
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                        placeholder={(operatorType==12||operatorType==13)?trans('to'):''}
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={"to"}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.Decimal": (                   
                        <Textbox
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="text"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                         withLabel={false}
                         placeholder={(operatorType==12||operatorType==13)?trans('to'):''}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.Int64": (                   
                        <Textbox
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                         withLabel={false}
                         placeholder={(operatorType==12||operatorType==13)?trans('to'):''}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.Int32": (                   
                        <Textbox
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                         textboxName={"value"+item.ParameterNameEN}
                         labelText={''}
                         withLabel={false}
                         placeholder={(operatorType==12||operatorType==13)?trans('to'):''}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.Int16": (                   
                        <Textbox
                        defaultValue={item.ParameterDefaultValue}      
                         textboxType="number"
                         textboxName={"value"+item.ParameterNameEN}
                         withLabel={false}
                         labelText={''}
                        placeholder={(operatorType==12||operatorType==13)?trans('to'):''}
                        resetForm={resetForm}
                        mode={"create"}
                        />
                         
                     ),
                     "System.Boolean": (
                         <>
                           
                                <CheckableButton
                                defaultChecked={item.ParameterDefaultValue}
                                defaultValue={item.ParameterDefaultValue}
                                labelText={''}

                                
                                inputName={"value"+item.ParameterNameEN}
                                type="checkbox"
                                mode={"create"}
                             />   
                           
                         </>
                     )
                 }[item.ParameterDataType]
                 
             }
            </div>
            }    
           <div className="group-person__selected-options-wrapper">
              { selectedItem.map((item, index) => (
            <div className="group-person__selected-option form-control-custom" key={item + index}>
                <div className="group-person__defult-selected-option" data-key={item}>
                   
                </div>
                <div className="showDots">
                 {item } 
                </div>
                
        {/*         <div className="group-person__delete-selected-option" data-key={item.Id} onClick={()=>{deleteaOption(item, index)}}>
                    <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                </div> */}
            </div>
             ))}
           </div>  
        </div>
    );
}