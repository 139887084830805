import { TabBar } from "components/Tabbar/Tabbar";
import { createContext, useEffect, useRef, useState } from "react";
import { PersonLegalList } from "./components/PersonLegalList/PersonLegalList";
import { PersonRealList } from "./components/PersonRealList/PersonRealList";
import { Toolbar } from "components/Toolbar/Toolbar";
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";




export const PersonsPage = () => {

  const [curentTab, setCurentTab] = useState<any>("PersonReal")
  const refcurentTab = useRef(curentTab);
  const [user, setUser] = useState(false);
  const refuser = useRef(user);


  const tabChangeHandle = (tab) => {
   refcurentTab.current = tab ;
   setCurentTab(tab)
  }


  let content = [
    { title: "PersonReal", body: (<PersonRealList  curentTab={curentTab}    />), },
    { title: "PersonLegal", body: (<PersonLegalList curentTab={curentTab} />) },
    // { title: "PersonRealTest", body: (<PersonRealListTest />), },
  ];



  return (
    <>
    <div className="mt-1">
      <TabBar Click={(e)=>{Toast(trans("modalclosemessage") , "warning" , "alert" , ()=>{setUser(false); refuser.current = false})} }  tabChange={tabChangeHandle} content={content} defaultTab="PersonReal" />
    </div>
    </>
  );
};
