import { loadCardImage } from "pages/ClientPortal/ECommerce/api";
import "./style.scss";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ImgBoxProps {
  attchId?: any;
  showGallery?: any;
  i?: any;
  active?: any;
  onClick?: () => void;
}

export const ImgBox = ({
  attchId,
  showGallery,
  i,
  active,
  onClick,
}: ImgBoxProps) => {
  const [src, setSrc] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  async function loadImageAsync() {
    try {
      if (attchId) {
        const res: any = await loadCardImage(attchId);
        setLoading(true);

        setSrc(res);
      }
    } catch (error) {}
  }
  useEffect(() => {
    if (!attchId?.includes("0000") && attchId) {
      loadImageAsync();
    }
  }, []);

  return (
    <>
      {loading ? (
        showGallery ? (
          <div
            key={i + "Gallery__imgBx"}
            onClick={() => {
              onClick && onClick();
            }}
            className={`m-1 rounded-1 Gallery__imgBx  ${
              active ? "Gallery__active" : ""
            }`}
          >
            <img className="w-100 h-100" src={src} />
          </div>
        ) : (
          <img height="80%" src={src + ""} />
        )
      ) : (
        <FontAwesomeIcon
          icon={"file-circle-exclamation"}
          style={{ color: "#e2e8f0", width: "80%", height: "80%" }}
          className="card-icon "
        />
      )}
    </>
  );
};
