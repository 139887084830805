import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss"
import { ItemCountSeter } from "./ItemCountSeter";
import { getBasketData, trans } from "utils/helpers";
import { ItemCardImg } from "./ItemCardImg";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {DataImg} from '../data/dataImg';
import { GroupingPrice } from "components/GroupingPrice/GroupingPrice";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";

interface ItemCardProps {
    ItemData?: any,
    cardStyle?:boolean,
}
export const  ItemCard = ({ItemData,cardStyle}:ItemCardProps) => {
    const [properties,setProperties] = useState<any>({})
    const [propertiesId,setPropertiesId] = useState<any>(null)
    const [offerIndex,setOfferIndex] = useState<any>(0)
    const [orderItems,setOrderItems] = useState<any>()
    const [openBadge,setOpenBadge] = useState<boolean>(false)
    const [locking,setLocking] = useState<boolean>(true)
    const [sumCount,setSumCount] = useState(0)
    const layoutContext = useContext(LayoutContext);


//  change 
    useEffect(()=>{
        let defaultValue = {}
        Object.keys(ItemData?.properties).map((pkey:any)=>{
            defaultValue = {...defaultValue, [pkey]:ItemData?.properties[pkey][0]?.['value']}
        })
        if(Object.keys(ItemData.properties).length == 0){
            setLocking(false)
        }
        setProperties(defaultValue)
    },[])

// change
    useEffect(()=>{
        let basketData = getBasketData()
        let item = {}
        Object.keys(ItemData?.properties).map((pValue:any)=>{
            item = {...item, [pValue]:ItemData?.properties[pValue][0]?.['value']}
        })

        let propertiesId = (ItemData.Id + (Object.values(ItemData?.properties).map((p:any)=>p[0]?.Id))).replaceAll(',','')
        setProperties(item)
        setPropertiesId(propertiesId)
        setOrderItems( basketData.find((item )=> item.Id == ItemData.Id))


        setOpenBadge(!openBadge)
    },[layoutContext.basket])

    useEffect(()=>{
        // change
        if(+orderItems?.order[propertiesId]?.count > +orderItems?.order[propertiesId]?.max && orderItems?.order[propertiesId]?.max != null){
            setOfferIndex(offerIndex + 1)
        }

        if(+orderItems?.order[propertiesId]?.count < +orderItems?.order[propertiesId]?.min && +orderItems?.order[propertiesId]?.count != 0){
            setOfferIndex(offerIndex - 1)
        }
        if(orderItems == undefined){
            setOfferIndex(0)
        }

    },[orderItems?.order[propertiesId]?.count])


    {
        return (
        cardStyle ? 
         <>
         <div className="position-relative overflow-hidden cardBox p-0 col-md-3 col-sm-12 userSelectNone">
            <Link to={"/ECommerce/Item?item="+ItemData?.Id} className={`${locking ? 'd-block':'d-none'} position-absolute w-100 h-100 cardLink`}></Link>
            <div className="card h-100 flex-row flex-md-column position-relative">
                {(ItemData?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.off != null) &&
                <span className="d-flex align-items-center justify-content-center position-absolute cardBox__offButton">{`off`}</span>
                }
                <div className="d-flex justify-content-center mt-1">
                <div className="d-flex flex-column justify-content-between me-3 mt-1">
                    <div className="d-flex flex-column">
                        <div className="d-flex cardBox__polat flex-column mt-5 me-1" >
                        {ItemData?.properties?.color && ItemData?.properties?.color?.map((c,index) =>{
                            if(index < 3){
                               return <div key={index+'cardBox__polat'} style={{margin:"1px 0",backgroundColor:c.value}}></div>
                            }else{
                                if(index == 4){
                                    return <div className='my-1 border-0 position-relative'> <FontAwesomeIcon icon={["fas", "plus"]} className="position-absolute start-0" size="sm" /></div>
                                }
                            }
                        })}


                        </div>
                        <div className="cardBox__btngroup h-100 position-absolute">
                            <ItemCountSeter showSumCount={locking} multiCounter={false}  properties={properties} propertiesId={propertiesId} stock={+ItemData?.Stock} priceIndex={offerIndex} min={ItemData.ItemPriceListDetails[0].PriceDetails[offerIndex]?.min} max={ItemData.ItemPriceListDetails[0].PriceDetails[offerIndex]?.max} ItemData={ItemData} positionRelative={false}/>
                        </div>
                    </div>
                        {
                            (ItemData?.rate != null || ItemData?.rate != '') &&
                        <div>
                            <FontAwesomeIcon icon={["fas", "star"]} color="gold"/>
                            <span className="me-1">{ItemData?.rate}</span>
                        </div>
                        }
                </div>
                <Link to={"/ECommerce/Item?item="+ItemData?.Id} className="cardBox__offButton__imgBx col-2 col-md-12">
                <ItemCardImg attchId={Object.values(DataImg[ItemData?.Id])[0]}/>
                
                {/* <ItemCardImg attchId={ItemData?.DefaultAttachmentId}/> */}
                        
                </Link>
                </div>
                <div className="card-body cardBox__textBx position-relative col-10 col-md-12">
                    <div style={{height:"42px"}} className="mt-1">
                        <span className="cardBox__textBx__title showDotsTowLine">{ItemData?.nameProductFa}</span>
                    </div>
                    
                    <div className={`d-md-flex ${ItemData?.Stock == null ? 'justify-content-end':'justify-content-between'} mt-1`}>
              
                        {
                        ItemData?.Stock < 50 ?
                            <p className="m-0">{`${trans('Stock')} : ${ItemData?.Stock}`}</p>
                            :
                        <p className="text-success m-0" style={{fontSize:"12px",fontWeight:"500"}}>{` ${trans('Stock')} : ${ItemData?.Stock} ${ItemData?.Unit != null ?ItemData?.Unit:""}`}</p>
                        }
                        {(ItemData?.ItemPriceListDetails[0]?.PriceDetails?.[offerIndex]?.off != null ) &&
                            <div className="d-none d-md-block text-decoration-line-through text-muted mt-1"><GroupingPrice value={ItemData?.ItemPriceListDetails[0]?.UnitPrice}/></div>
                        }
                    </div>
                    <div className="d-md-flex flex-column ">
                        <div className='d-md-flex justify-content-between mt-2'>
                        <div className="cardBox__priceBx mt-1"><GroupingPrice description="PackingPrice" value={ItemData?.ItemPriceListDetails[0]?.CoverPrice}/></div>              
                        {ItemData?.ItemPriceListDetails[0]?.PriceDetails?.[offerIndex]?.off != null  &&
                            <div className="d-block d-md-none text-decoration-line-through text-muted mt-1"><GroupingPrice value={ItemData?.ItemPriceListDetails[0]?.UnitPrice}/></div>
                        }
                        <div className="cardBox__priceBx mt-1 fw-bolder">{ItemData?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.off != null ? <div className="d-flex"><span className="text-customerPrimaryColor">(%{ItemData?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.off})</span><GroupingPrice value={ItemData?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.discountPrice}/></div>  : <><GroupingPrice value={ItemData?.ItemPriceListDetails[0]?.UnitPrice}/></>}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>    
        :
        <div className="d-flex flex-column flex-sm-row position-relative justify-content-between glassBox my-1 list">
            <Link to={"/ECommerce/Item?item="+ItemData?.Id} className={`${locking ? 'd-block':'d-none'} position-absolute w-100 h-100 cardLink`}></Link>
            <div className=" showDotsTowLine list__title mb-3 p-2">{ItemData?.nameProductFa}</div>
            <div className="row list__price mb-1">
                <div className="col-12">
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="d-md-block text-muted ">{`${trans("PackingPrice")}:${ItemData?.ItemPriceListDetails[0]?.CoverPrice}`}</div>
                        </div>
                        {ItemData?.ItemPriceListDetails[0]?.PriceDetails[offerIndex].off != null  &&
                        <div>
                            <div className="d-flex text-decoration-line-through text-muted"><div>{ItemData?.ItemPriceListDetails[0]?.UnitPrice}</div></div>
                        </div>
                        }
                    </div>
                </div>
                <div className="col-12">
                    <div className="d-flex justify-content-between">
                    <div>
                        {
                        ItemData?.Stock < 10 ?
                        <p className="m-0 mt-3">{`${trans('Stock')} : ${ItemData?.Stock}`}</p>
                        :
                        <p className="d-flex text-success mt-1" style={{fontSize:"12px",fontWeight:"500"}}> <div>{trans('Stock')} :</div> <div>{ItemData?.Stock}</div> <div>{ItemData?.Unit != null ?ItemData?.Unit:""}</div></p>
                        }
                        </div>

                        <div>
                            <div className="cardBox__priceBx mt-3">{(ItemData?.ItemPriceListDetails[0].PriceDetails[offerIndex].off != null ) ? <><span className="text-customerPrimaryColor">(%{ItemData?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.off})</span>{ItemData?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.discountPrice}</>  : <>{ItemData?.ItemPriceListDetails[0]?.UnitPrice}</>}</div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center list__btns">
                <div className=" w-75">
                    {/* <ItemCountSeter  properties={properties} propertiesId={propertiesId} priceIndex={orderItems?.order?.[propertiesId]?.min} min={orderItems?.order?.[propertiesId]?.min} max={orderItems?.order?.[propertiesId]?.max} stock={ItemData?.Stock} ItemData={ItemData} positionRelative={true} pluseInRight={false}/> */}


                    <ItemCountSeter showSumCount={locking} positionRelative={true} pluseInRight={false} multiCounter={false}  properties={properties} propertiesId={propertiesId} stock={+ItemData?.Stock} priceIndex={offerIndex} min={ItemData.ItemPriceListDetails[0].PriceDetails[offerIndex]?.min} max={ItemData.ItemPriceListDetails[0].PriceDetails[offerIndex]?.max} ItemData={ItemData}/>

                </div>
            </div>
       </div>
        );
    }
}



