import { CheckableButtonList } from "components/CheckableButtonList/CheckableButtonList";
import { InputLoad } from "components/InputLoading/inputLoading";
import { SelectBox } from "components/Select/Select";
import { Textbox } from "components/Textbox/Textbox";
import { InputHTMLAttributes, useEffect, useState } from "react";
import { Enumeration } from "types/types";
import { trans } from "utils/helpers";
import { getSerialsApi } from "./api";

interface SerialsProp extends InputHTMLAttributes<HTMLInputElement> {
  type: "SelectBox" | "Radio" | "CheckBox";
  label?: string;
  name: string;
  value?: any;
  CorrectedSerial?: any;
  customerPositionId?: any;
  mode?: string;
  required?: boolean;
  setSerialId?: (Id: any) => void,
  setSerialName?: (Id: any) => void,
  Change?: (e) => void,


}
export const Serials = ({ type, label="Serial", name,customerPositionId=null, value,CorrectedSerial, mode, required,Change, setSerialId, setSerialName }: SerialsProp) => {
  const [optionsSerials, setOptionsSerials] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errcode, setErrcode] = useState<any>();

  let options_Serials_values: any = [];
  let options_Serials_keys: any = [];
  const getSerialsAsync = async () => {
    setLoading(false)
    try {
      if(customerPositionId){
        const res = await getSerialsApi(customerPositionId)
        setOptionsSerials(res?.Data)
        if(value==null){
          setSerialId && setSerialId(Object.keys(res?.Data)[0])

        }else{
          
          setSerialId && setSerialId(value)

        }
        setSerialName && setSerialName(Object.values(res?.Data)[0])
      }
    } catch (error: any) {
      setOptionsSerials(null)
      setSerialId && setSerialId(null)
      setSerialName && setSerialName(null)
      setErrcode(error?.response?.status)
    }
    setLoading(true)
  }
  const ChangeValue = (e) => {
    if (optionsSerials) {
      setSerialId && setSerialId(e.target.value)
      setSerialName && setSerialName(optionsSerials[e.target.value])

    }
  }
  useEffect(() => {
    if (mode != "view") {
      getSerialsAsync()
    } else {
      setOptionsSerials({})
      setLoading(true)
    }
    return () => {

      setErrcode("")
    };


  }, [customerPositionId]);
  if (optionsSerials) options_Serials_values = Object.keys(optionsSerials as Enumeration);
  if (optionsSerials) options_Serials_keys = Object.keys(optionsSerials as Enumeration);
  return (
    <>

      {optionsSerials && loading ?
        {
          Radio: (
            <>
              {options_Serials_values && (
                <CheckableButtonList
                  checkableBtnLabelList={options_Serials_values}
                  checkbaleType="radio"
                  verticalDirection={false}
                  checkableButtonName="Serials"
                  checkableListLabel="Serials"
                />
              )}
            </>
          ),
          SelectBox: (
            <>
              {mode == "view" ?
                <Textbox
                  textboxType="text"
                  textboxName="Serials"
                  labelText={label}
                  readonly={true}
                  ExtraDescription={CorrectedSerial?true:false}
                  recordedData={CorrectedSerial ? value:null}
                  defaultValue={CorrectedSerial ? CorrectedSerial:value }
                  mode={"view"}
                />


                :
                <SelectBox
                  onChange={(e)=>{ChangeValue(e);e.target.value !=(value?value:options_Serials_keys[0]) ?Change&&Change({[name] :e.target.value}):Change&&Change({[name] :null}) }}
                  required={required}
                  mode={mode}
                  lable={label}
                  selectType="select"
                  value={value}
                  options={options_Serials_values}
                  val={options_Serials_keys}
                  name={name}
                ></SelectBox>


              }

            </>
          ),
          CheckBox: (
            <>
              {options_Serials_values && (
                <CheckableButtonList
                  checkableBtnLabelList={options_Serials_values}
                  checkbaleType="checkbox"
                  verticalDirection={false}
                  checkableListLabel={trans("Serials")}
                />
              )}
            </>
          ),
        }[type as string]
        : <>

          <InputLoad msg={errcode} required={required} labelText={"Serials"} onClick={getSerialsAsync} />

        </>
      }
    </>
  );
};
