import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useRef, useState } from 'react'
import { Loading } from 'components/Loading/loading'
import { Toast } from 'components/ToastShow/ToastShow'
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext"
import ContactList from './components/ContactList'
import { MessageBox } from './components/MessageBox'
import { ModalForm } from 'components/ModalForm/ModalForm'
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { DateObject } from 'react-multi-date-picker'
import { trans, uuidv4 } from 'utils/helpers'
import { getCustomersUsersasync, getFile, getUsersasync, sendFileList } from './api/api'
import { DisplayableFormats, EMOJIS } from 'utils/constants'
import { GroupMembersAssign } from './components/GroupMembers'
import { Carousel } from 'react-bootstrap'
import { Fileviewer } from 'components/Fileviewer/Fileviewer'
import { OrganizationType } from "types/enum";
import img from '../../../assets/image/logo.png'
import word from '../../../assets/image/word.png'
import excel from '../../../assets/image/excel.png'
import pdf from '../../../assets/image/pdf.png'
import powerpoint from '../../../assets/image/powerpoint.png'
import './style.scss'
import { GeneralRequestForm } from '../Cartable/components/GeneralRequestForm/GeneralRequestForm'
import { ReferenceStepper } from '../Cartable/components/ReferenceStepper/ReferenceStepper'
import { ChatConnection } from 'utils/chatHub'



export const Chat = () => {
    const [allchats, setAllChats] = useState<any>({  })
    const allchatsRef = useRef<any>(allchats);
    const formButton = useRef<any>();
    const sendFileref = useRef<any>();
    const formTextbox = useRef<any>(null);
    const [chats, setChats] = useState<any>([])
    const chatsRef = useRef<any>(chats);
    const [loading, setLoading] = useState<boolean>(false)
    const [contactName, setContactName] = useState<any>()
    const contactNameRef = useRef<any>(contactName);
    const [replyList, setReplyList] = useState<any>()
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [user, setUser] = useState<any>()
    const [file, setFile] = useState<any>([])
    const [showModalFile, setShowModalFile] = useState<any>(false)
    const [sendFileLoading, setSendFileLoading] = useState<any>(false)
    const [loadingContactList, setLoadingContactList] = useState<any>(false)
    const [corentFile, setCorentFile] = useState<any>()
    const [choiceUser, setChoiceUser] = useState<any>(false);
    const [showEmoji, setShowEmoji] = useState<any>(false);
    const [getFirst, setGetFirst] = useState<any>(true);
    //const getFirstRef = useRef<any>(true);
    //getFirstRef.current = true
    const [userListsearch, setUserListsearch] = useState<any>();
    const [search, setsearch] = useState<any>();
    const [HistoryUserList, setHistoryUserList] = useState<any>([]);
    const [contactList, setContactList] = useState<any>();
    const [groupList, setGroupList] = useState<any>();
    const [groupMembers, setgroupMembers] = useState<any>([]);
    const formboxHeight = useRef<any>(50);
    const [formboxHeightState, setFormboxHeightState] = useState<any>(50);
    const [showArrowDown, setShowArrowDown] = useState<any>(false);
    const [showRecordVoice, setShowRecordVoice] = useState<any>(false);
    const [startRecordVoice, setStartRecordVoice] = useState<any>(false);
    const [forwardMsg, setForwardMsg] = useState<any>();
    const [forwardMsgList, setForwardMsgList] = useState<any>([]);
    const [contactSelectList, setContactSelectList] = useState<any>([]);
    const [onlinUserList, setOnlinUserList] = useState<any>([]);
    const [pageNum, setPageNum] = useState<any>(null);
    const [mode, setmode] = useState<any>();
    const selectListContactRef = useRef<any>([]);
    const recordB = useRef<any>();
    const corentContactMsg = useRef<any>(false);
    const HistoryUserListRef = useRef<any>(HistoryUserList);
    const contactListRef = useRef<any>(contactList);
    const latestChat = useRef<any>(null);
    const layoutContext = useContext(LayoutContext);
    const userName = JSON.parse(JSON.stringify(layoutContext?.currentUserPosition?.Description))
    const userNameRef = useRef<any>(null);
    userNameRef.current = userName
    latestChat.current = chats;
    const uploadRef = useRef<HTMLInputElement>(null)
    const messagesEndRef = useRef<any>(null)
    const messagesnotSeeRef = useRef<any>(null)
    const listInnerRef = useRef(null);
    const CarouselRef = useRef(null);
    let audioCtx;

    const [showModalCarousel, setshowModalCarousel] = useState(false);
    const [CarouselFileList, setCarouselFileList] = useState<any>([]);
    const [index, setIndex] = useState<number | any>();
    const [viewerData, setViewerData] = useState<any>();
    const [carouselFlag, setCarouselFlag] = useState<any>(false);
    const carouselFlagRef = useRef(carouselFlag);
    
    const [showGeneralRequestForm, setShowGeneralRequestForm] = useState<boolean>(false);
    const [message, setMessage] = useState<any>(false);
    const [referenceId, setReferenceId] = useState<string | any>(null);
    const [showReference, setShowReference] = useState(false);









interface Contact {
    latestMessagePersonOrGroupName : string ,
    latestMessageUserIdOrGroupId : string ,
    isDirect:boolean ,
    id?:string,
    isGroupOwner?:boolean,
    latestMessage?:string,
    userId?:string,
    sent_Date?:string,
    unreadCount?:number,

}

class ContactAdapter {
    async getGroupList() {
      const list = await ChatConnection.invoke("GetUserGroups");
      const contactList:Contact[] = list.map(item=>{
        return{
            latestMessagePersonOrGroupName : item.Title  ,
            latestMessageUserIdOrGroupId : item.Id  ,
            isGroupOwner : item.IsGroupOwner  ,
            isDirect : false ,
        }
      })
      return contactList;
    }
    
    async getContactsList(str) {
        getonlinUser()
        if(layoutContext?.currentUserPosition?.OrganizationType){
            let list:any = []
            if(layoutContext?.currentUserPosition?.OrganizationType == OrganizationType.Customers)
                list = await getCustomersUsersasync(str)
            else
                list = await getUsersasync(str)
            const contactList:Contact[] = list.Data.Data.map(item => {
                return { latestMessageUserIdOrGroupId: item.Id, latestMessagePersonOrGroupName: item.Description, isDirect: true};
            })      
            contactListRef.current =contactList
            return contactList;
        }
    }
  }
  
    function fileIconFormat(item) {
        let format = item.file.name.split('.').pop().toLowerCase();
        if(format == "xlsx" ||format == "csv"){
            return  <img  src={excel} alt={` `} onClick={() => { setCorentFile(item) }}  style={{ cursor: 'pointer', height: "40px" }}/>
        }else if(format == "docx") {
            return  <img  src={word} alt={` `} onClick={() => { setCorentFile(item) }}  style={{ cursor: 'pointer', height: "40px" }}/>
        } else if (format == "pdf") {
            return  <img  src={pdf} alt={` `} onClick={() => { setCorentFile(item) }}  style={{ cursor: 'pointer', height: "40px" }}/>
        } else if (format == "txt") {
        } else if (format == "pptx") {
            return  <img  src={powerpoint} alt={` `} onClick={() => { setCorentFile(item) }}  style={{ cursor: 'pointer', height: "40px" }}/>
        } else if (format == "mp3") {

        }else{
            return <FontAwesomeIcon icon={['fas', 'file']} color='grey' onClick={() => { setCorentFile(item) }}  style={{ cursor: 'pointer', height: "40px" }} />
        }
    }

    function selectCarousel(I){
        loadAttachmentAsync(CarouselFileList[I].id  );
        setIndex(I)
    }

    async function loadAttachmentAsync(id ) {
        try {
            
            const res = await getFile(id  );  
            setViewerData(res);
            return res;
        } catch (error) {
            
        }
    }
    async function showCarouselFile(e , index) {  
        setIndex(index);
        setCarouselFileList(e)
        loadAttachmentAsync(e[index].id ? e[index].id  : e[index].Id  );
        setshowModalCarousel(true)
    }
    async function downloadFileAsync(obj , name) {
        setLoading(true)
        try {   
              const list = await getFile(obj[0].id  );  
           } catch (error) { 
            Toast("msg_errorreceivingdata" ,"error" )

            setshowModalCarousel(false)
           }
           setLoading(false)
       }

    function visualize(stream) {
      if(!audioCtx) {
        audioCtx = new AudioContext();
      }   
      const source = audioCtx.createMediaStreamSource(stream);
      const analyser = audioCtx.createAnalyser();
      analyser.fftSize = 2048;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      source.connect(analyser);
      }
    function vioceRecord(e) {
        const constraints = { audio: true };
        let chunks:any = [];
        setStartRecordVoice(true)
        let onSuccess = function(stream) {
            const mediaRecorder = new MediaRecorder(stream);
            visualize(stream);
            mediaRecorder.start();
            document.getElementById("recordB")?.addEventListener("touchend",() => {
                setStartRecordVoice(false)
                mediaRecorder.stop();
            })    

            document.getElementById("recordB")?.addEventListener("mousedown",() => {
                mediaRecorder.start();
            })
      
            document.getElementById("recordB")?.addEventListener("mouseup",() => {
                setStartRecordVoice(false)
                mediaRecorder.stop();
            })
               
        
            mediaRecorder.onstop =async function(e) {
              const blob = new Blob(chunks, { 'type' : 'audio/mpeg' });
              const audioURL = window.URL.createObjectURL(blob);
              var _objData = new FormData();            
              let file = new File([blob], "AppVoice.mp3",{
                type: "audio/mpeg",
              });

              
              _objData.append("files", file);

              
              chunks = [];
              navigator.mediaDevices.getUserMedia({audio: false})
              try {
                  const list = await sendFileList(_objData);
                  sendMessage(userName, contactName, "", list.Data)
                  const tracks = stream.getTracks();
                  tracks[0].stop();
              }catch (error) {
                  
              }

            }
        
            mediaRecorder.ondataavailable = function(e) {
              chunks.push(e.data);
            }
          }
        
          let onError = function(err) {
            document.getElementById("recordB")?.addEventListener("mouseup",() => {
            setStartRecordVoice(false)
              })    
          }
        navigator.mediaDevices.getUserMedia(constraints).then(onSuccess, onError);
      }
    
      useEffect(() => {
        if (navigator.mediaDevices && document.getElementById("recordB")) {
            const constraints = { audio: true };
            let chunks:any = [];
            setShowRecordVoice(true)
          }

    }, [contactName]);

    const addContactToSelectList = (e) => {
        let list:any = []
        let flag = true
        if(e==null){
            selectListContactRef.current = []
            setContactSelectList([])
        }else{
            selectListContactRef.current.forEach(item=>{
                if(item.latestMessageUserIdOrGroupId == e.latestMessageUserIdOrGroupId){
                    flag = false
                }else{
                    list.push(item)
                }
            })
            if(flag){
                list.push(e)
            }
            selectListContactRef.current = list
            setContactSelectList(JSON.parse(JSON.stringify(selectListContactRef.current)) )
        }
    }
    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView(true)
    }
    const scrollTonotSee = () => {
        messagesnotSeeRef?.current?.scrollIntoView(true)
        setShowArrowDown(true)
    }
    const ScrollData =async (e) => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight }:any = listInnerRef.current;
            if(scrollTop ==0 && document.getElementById("chatMassgeList")?.scrollTop ==0){
                await  getMassagessWithScroll()
                let ref:any = listInnerRef.current
                if(ref){
                    setTimeout(() => {
                    let ElementscrollHeight :any = document.getElementById("chatMassgeList")?.scrollHeight
                      document.getElementById("chatMassgeList")?.scrollTo({top: ElementscrollHeight - scrollHeight})
                    }, 200)
                }
            }
            if (scrollTop + clientHeight+10 > scrollHeight) {
             setShowArrowDown(false)
            }else{
             setShowArrowDown(true)
            }
        }
    }

    const handleUploadOnclick = () => {
        uploadRef.current!.click()
    }
    const handleAddEmoji = (item) => {
        let toastEl = document.getElementById("EmojiInput")
        formTextbox.current.value = formTextbox.current.value + String.fromCodePoint(item)       
        //toastEl?.insertAdjacentText("afterbegin", String.fromCodePoint(item))
    }

    const cancleSendFile = () => {
        setShowModalFile(false)
        setFile([])
        setCorentFile({})
        setTimeout(() => {
            scrollToBottom()    
          }, 1)
          scrollToBottom()
    }
    const backToContactList = () => {
        setContactName(null)
        contactNameRef.current = null
        setForwardMsg(null);
        setForwardMsgList([])

    }

    const handleUploadOnchange = (event) => {
        if(+((event.target.files[0].size/1024)/1024) >25){
            Toast("file is too large" , "error"  )
        }else{
            URL.createObjectURL(event.target.files[0]) 
         setCorentFile({ file: event.target.files[0], Tu: URL.createObjectURL(event.target.files[0]) })
          let fileList:any =[] ;
           [...event.target.files].map(item => {
              if(((item.size/1024)/1024) >25){
                  Toast(`${item.name} is too large` , "error"  )
              }else{
                  fileList.push({ file: item, Tu: URL.createObjectURL(item) }) 
              }
          })
          setFile([...fileList, ...file])
         setShowModalFile(true)
        }
    
        
    }

    function Uploadonpaste(pasteEvent) {
        // consider the first item (can be easily extended for multiple items)
        
        if(pasteEvent.clipboardData.items[0]){

            var item = pasteEvent.clipboardData.items[0];

/*             if (items[i].kind === 'file') {
                const file = items[i].getAsFile();
                const reader = new FileReader();
                reader.onload = (e) => {
                    setFileContent(e.target.result);
                };
                reader.readAsText(file); // You can change this to readAsDataURL, readAsArrayBuffer, etc., depending on the file type
            } */

            if (item.type.indexOf("image") === 0 ||item.kind === 'file'){
                try {
            var blob = item.getAsFile();
            
            setCorentFile({ file: blob, Tu: URL.createObjectURL(blob) })
            setFile([...[{ file: blob, Tu: URL.createObjectURL(blob) }], ...file])
    
            }catch(error){
    
            }
            setShowModalFile(true)  
            }else{
                if(pasteEvent.clipboardData.getData('text/plain').indexOf('\n') != -1){
                    formboxHeight.current = 100
                    setFormboxHeightState(formboxHeight.current)
                }
            }
    }
}

    const delay = (duration) =>
             new Promise(resolve => setTimeout(resolve, duration));


    const sendContactToShowChat = async (item2: any) => {
        setPageNum(null)
        setgroupMembers([])
        let flag= true
        if (!item2.isDirect) {
            setLoading(true)
            try {
                const res = await ChatConnection.invoke("GetMessages" , 0, "" , item2.latestMessageUserIdOrGroupId);
                setChats(res)
                setContactName(JSON.parse(JSON.stringify(item2)))
                corentContactMsg.current = false
                chatsRef.current = res
                const res2 = await ChatConnection.invoke("GetGroupUsers", item2.latestMessageUserIdOrGroupId);
                setgroupMembers(res2)   
                flag =true
            } catch (error) {
                setChats([])
                Toast("msg_errorreceivingdata" ,"error" )
                setContactName(null)
                contactNameRef.current = null
                setLoading(false)
            }
            setLoading(false)
        } else {
                setLoading(true)
            try {
                if(ChatConnection.connection._connectionState != "Connected")await delay(400);

                const res = await ChatConnection.invoke("GetMessages",0 , item2.latestMessageUserIdOrGroupId, null);
                setContactName(JSON.parse(JSON.stringify(item2)))
                corentContactMsg.current = false
                chatsRef.current = res
                setChats(res)
            }catch (error) {
                if(ChatConnection.connection._connectionState == "Connected" ){
                    alert("GetMessages not ")
                    try {
                        const res = await ChatConnection.invoke("GetMessages",0 , item2.latestMessageUserIdOrGroupId, null);
                        setContactName(JSON.parse(JSON.stringify(item2)))
                        corentContactMsg.current = false
                        chatsRef.current = res
                        setChats(res)       
                        
                    } catch (error) {
                        setChats([])
                        Toast("msg_errorreceivingdata" ,"error" )
                        setContactName(null)
                        contactNameRef.current = null
                    }
                }else{
                    setChats([])
                    Toast("msg_errorreceivingdata" ,"error" )
                    setContactName(null)
                    contactNameRef.current = null
                    //ChatConnection.start()
                }
            }
            setLoading(false)
        }
        contactNameRef.current = item2
        const list: any = [...HistoryUserListRef.current]
        list.forEach(e => {
            if (e.latestMessageUserIdOrGroupId == item2.latestMessageUserIdOrGroupId) {
                e.haveMsg = false
                e.unreadCount = 0
            }
        })
        HistoryUserListRef.current = list
        setHistoryUserList(list)
        if(flag){
            scrollToBottom()
        }else{
            scrollTonotSee()
        }
        setPageNum(0)
    }

    const getMassagessWithScroll = async ()=>{
        if(pageNum != null){
            let flag= true
            if (!contactName.isDirect) {
                try {
                    const res = await ChatConnection.invoke("GetMessages" , pageNum+1, "" , contactName.latestMessageUserIdOrGroupId);
                    chatsRef.current = [...res , ...chatsRef.current ]
                    setChats( [...res ,...chats ])
                    setPageNum(pageNum+1)
                    flag =true
                } catch (error) {
                }
            } else {
                try {
                const res = await ChatConnection.invoke("GetMessages",pageNum+1 , contactName.latestMessageUserIdOrGroupId, null);
                chatsRef.current = [...res , ...chatsRef.current ]
                setChats( [...res ,...chats ])
                setPageNum(pageNum+1)
                } catch (error) {
                }
            }
        }
    }

    const sendRepliesUp = (data) => {
        formTextbox.current.focus()
        setReplyList(data)
    }

    const isFocused = () =>{ 
        return typeof document.hidden !== `undefined` ? !document.hidden : null
    }
     
     
     
    function notifyMe(title, mas ,onClick) {
    
    
        if (!("Notification" in window)) {
          // Check if the browser supports notifications
          alert("This browser does not  support desktop notification");
        } else if (Notification.permission === "granted") {
          // Check whether notification permissions have already been granted;
          // if so, create a notification
          let tabs = JSON.parse(localStorage.getItem('tabs')|| "");
          let index = tabs.indexOf(layoutContext.tabId);
          if (index == 0){
            const notification = new Notification(title , {body:mas});
            notification.onclick =onClick
          }

        } else if (Notification.permission !== "denied") {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                let tabs = JSON.parse(localStorage.getItem('tabs')|| "");
                let index = tabs.indexOf(layoutContext.tabId);
                if (index == 0){
                  const notification = new Notification(title , {body:mas});
                  notification.onclick =onClick

        
                }

            }
          });
        }
      }

    async function getGroupList(str=null) {
        try {
            const productAdapter = new ContactAdapter();
            const list = await productAdapter.getGroupList()
            setGroupList(list)
            
        } catch (error) {

        }
    }
    async function getContacts(str=null) {
        try {
            const productAdapter = new ContactAdapter();
            const list = await productAdapter.getContactsList(str)
            setContactList(list)
            contactListRef.current = list
            return list
        } catch (error) {

        }

      
    }
    async function getHistoryUserList(str= "") {
        setLoadingContactList(false)
        try {
            const res:Contact[] = await ChatConnection.invoke("GetHistory",str==null ?"" :str,1,50);
        let Customerlist:any = []
        if(layoutContext.currentUserPosition.OrganizationType == OrganizationType.Customers){
            try {    
                const CustomerRes = await getCustomersUsersasync("") ;
                Customerlist = CustomerRes.Data.Data.map(item => {
                    return item.Id
                })
            } catch (error) {
                
            }
        }
        let list: any = []
        if(layoutContext.currentUserPosition.OrganizationType == OrganizationType.Customers || Customerlist.length >0  ){
            res.forEach(item => {
                if (item.isDirect) {
                    if(Customerlist.includes(item.latestMessageUserIdOrGroupId)){
                        list.push(item)
                    }
                } else {
                    list.push(item)
                }
            })
        }else{
            list = res
        }
        HistoryUserListRef.current = [...list]
        setHistoryUserList([...list])
        } catch (error) {
            alert("GetHistory error")
        }
        setLoadingContactList(true)
    }

    async function handleSendFiles() {   
        setSendFileLoading(true)
        try {       
                let size = 0
                let fileList:any = [ ];
                let fileSizeList:any = []
                let flag = false
                 file.forEach(item => {
                    size += item.file.size
                    if(((size/1024)/1024) <25){
                        fileSizeList.push(item.file)
                       
                    }else{
                        flag= true
                        fileList.push(fileSizeList)
                        fileSizeList = [item.file]
                        size =item.file.size
                    }
                    
                })
                if(!flag){
                    flag= true
                    fileList.push(fileSizeList)
                    fileSizeList = []
                    size =0
                }    
           await Promise.all( fileList.map(async(item) => {
                var _objData = new FormData();
                let chatMessage: any = {}
                chatMessage = {
                   "chatMessage": {
                     "recieverId":contactName.isDirect ?  contactName.latestMessageUserIdOrGroupId:null,
                     "isDirect": contactName.isDirect,
                     "groupId": !contactName.isDirect ?  contactName.latestMessageUserIdOrGroupId:null,
                     "message": "",
                     "replyToMessageId": replyList ? replyList?.chatMessage.id : null,
                   },
                   "replyToMessageText": replyList?.chatMessage.message,
                   "replyToTime": null,
                   "replyToUser": replyList?.sendUser,
                   "SendUser": layoutContext.currentUserPosition.Description.split("-")[0],
                   "recieveUser": contactName.latestMessagePersonOrGroupName,
                   "groupName": contactName.latestMessagePersonOrGroupName,
                   "fileInformations": [] ,
                 }

                item.forEach(async(item) =>{
                    _objData.append("files", item);
                })
                _objData.append("chatMessage",JSON.stringify(chatMessage) );
                
                const list = await sendFileList(_objData);
                var newlist:any = sendMessage(userName, contactName, "", list.Data)
            }));
            setFile([])
            setShowModalFile(false)
        } catch (error) {
            
        }
        scrollToBottom()
        setSendFileLoading(false)

    }

    async function addLineGroupe(list, groupeName) {
        try {
            if (groupMembers&& contactName && contactName.latestMessageUserIdOrGroupId && groupMembers?.length > 0) {
                const res = await ChatConnection.invoke("AddUserToGroup", contactName.latestMessageUserIdOrGroupId, list);
            } else if (groupeName == null) {
                const res = await ChatConnection.invoke("AddToGroup", null, true, list);
       
            } else {
                const res = await ChatConnection.invoke("AddToGroup", groupeName,false , list);
            }
            setChoiceUser(false)
            const res2 = await ChatConnection.invoke("GetGroupUsers",contactName.latestMessageUserIdOrGroupId);
            setgroupMembers(res2)
        } catch (error) {

        }
        getHistoryUserList()
    }
    async function leftFromGroup(item, self = true) {
        try {
            const ch = contactNameRef?.current?.latestMessagePersonOrGroupName
            if (self) {
                Toast(trans("Msg_ConfirmLeaveFromGroup"), "warning", "alert", (async(e)=>{
                    const res1 = await ChatConnection.invoke("RemoveFromGroup" ,  item.latestMessageUserIdOrGroupId, null);
                    getHistoryUserList()
                    getGroupList()
                }))
            } else {
                Toast(trans("Msg_ConfirmRemoveMemberFromGroup"), "warning", "alert", (async(e)=>{
                    await ChatConnection.invoke( "RemoveFromGroup" , !contactNameRef?.current?.isDirect && contactNameRef?.current?.latestMessageUserIdOrGroupId , [item]);
                    const res2 = await ChatConnection.invoke("GetGroupUsers", contactNameRef?.current?.latestMessageUserIdOrGroupId);
                    setgroupMembers(res2)
                }))
            }
        } catch (error) {

        }
    }
    
    async function removeGroup(item) {
        try {
            const ch = contactNameRef?.current?.latestMessagePersonOrGroupName        
                Toast(trans("Msg_ConfirmDeleteGroup"), "warning", "alert", (async(e)=>{
                    const res1 = await ChatConnection.invoke("DeleteGroup" ,  item.latestMessageUserIdOrGroupId);
                    getGroupList()
        
                }))
        } catch (error) {

        }
    }
    
    async function deleteChat(item) {
        try {
            const ch = contactNameRef?.current?.latestMessagePersonOrGroupName
                Toast(trans("Msg_DeleteChatFromRecents"), "warning", "alert", (async(e)=>{
                    const res1 = await ChatConnection.invoke("DeleteHistoryItem" ,  item.id);
                    getHistoryUserList()
        
                }))
        } catch (error) {

        }
    }

    async function addUser(e ,list) {
        e.preventDefault()
        let UserList: any = []
        list.map(item => {
            UserList.push(item.Id)  
        })
        addLineGroupe(UserList, null)
    }
    async function getonlinUser() {
        if (ChatConnection) {
          try {   
            
            const list = await ChatConnection.invoke("GetOnlineUsers");
            setOnlinUserList(list)
            } catch (error) {

            }
        }



    }


    const sendMessage = async (user, contactName, message, Dictionary:any = null , forwardFile = false) => {
        formboxHeight.current = 50
        setFormboxHeightState(50)
        let chatMessage: any = {}
         chatMessage = {
            "chatMessage": {
              "recieverId":contactName.isDirect ?  contactName.latestMessageUserIdOrGroupId:null,
              "isDirect": contactName.isDirect,
              "groupId": !contactName.isDirect ?  contactName.latestMessageUserIdOrGroupId:null,
              "message": message,
              "replyToMessageId": replyList ? replyList?.chatMessage.id : null,
            },
            "replyToMessageText": replyList?.chatMessage.message,
            "replyToTime": null,
            "replyToUser": replyList?.sendUser,
            "SendUser": layoutContext.currentUserPosition.Description.split("-")[0],
            "recieveUser": contactName.latestMessagePersonOrGroupName,
            "groupName": contactName.latestMessagePersonOrGroupName,
            "fileInformations": Dictionary?.FileInformations ?Dictionary?.FileInformations :null ,
          }
          

        if (ChatConnection._connectionStarted) {
            let responseId:any = null
               let msgId = uuidv4()
               let updatedChat: any = JSON.parse(JSON.stringify(allchats));
              if(formTextbox.current){
                formTextbox.current.value = null
                }
                const date = new DateObject({  format: "YYYY/MM/DD hh:mm" })
                if (contactName.latestMessagePersonOrGroupName != "broadCast") {
                    if (!contactName.isDirect) {
                        let mas = {
                            "chatMessage": {
                            "addTime": date.toString(),
                            'id':"sending"+msgId,
                            "senderId":layoutContext?.currentUserPosition?.UserId,
                              "recieverId":contactName.isDirect ?  contactName.latestMessageUserIdOrGroupId:null,
                              "isDirect": contactName.isDirect,
                              "groupId": !contactName.isDirect ?  contactName.latestMessageUserIdOrGroupId:null,
                              "message": message,
                              "replyToMessageId": replyList ? replyList?.chatMessage.id : null,
                            },
                            "replyToMessageText": replyList?.fileInformations?.length>0 ?  trans("فایل")+" 📄"  :replyList?.chatMessage.message ,
                            "replyToTime": replyList?.chatMessage.addTime,
                            "replyToUser": replyList?.sendUser,
                            "SendUser": layoutContext.currentUserPosition.Description.split("-")[0],
                            "recieveUser": contactName.latestMessagePersonOrGroupName,
                            "groupName": contactName.latestMessagePersonOrGroupName,
                            "fileInformations": Dictionary?.FileInformations ?Dictionary?.FileInformations :null ,
                          }
                        let UserMsg: any = updatedChat[contactName.latestMessageUserIdOrGroupId] ? updatedChat[contactName.latestMessageUserIdOrGroupId] : []
                        UserMsg.push(mas)
                        updatedChat = Object.assign(updatedChat, { [contactName.latestMessageUserIdOrGroupId]: UserMsg })
                        allchatsRef.current = updatedChat
                        setAllChats(allchatsRef.current);
                        chatsRef.current = [...chatsRef.current, mas]
                        setChats([...chatsRef.current])
                        scrollToBottom()
                    } else {
                        let mas = {
                            "chatMessage": {
                              'id':"sending"+msgId,
                              "senderId":layoutContext?.currentUserPosition?.UserId,
                              "addTime": date.toString(),
                              "recieverId":contactName.isDirect ?  contactName.latestMessageUserIdOrGroupId:null,
                              "isDirect": contactName.isDirect,
                              "groupId": !contactName.isDirect ?  contactName.latestMessageUserIdOrGroupId:null,
                              "message": message,
                              "replyToMessageId": replyList ? replyList?.chatMessage.id : null,
                            },
                            "replyToMessageText": replyList?.fileInformations?.length>0 ?  trans("فایل")+" 📄"  :replyList?.chatMessage.message ,
                            "replyToTime": replyList?.chatMessage.addTime,
                            "replyToUser": replyList?.sendUser,
                            "SendUser": layoutContext.currentUserPosition.Description.split("-")[0],
                            "recieveUser": contactName.latestMessagePersonOrGroupName,
                            "groupName": contactName.latestMessagePersonOrGroupName,
                            "fileInformations": Dictionary?.FileInformations ?Dictionary?.FileInformations :null ,
                          }
                        let UserMsg: any = updatedChat[contactName.latestMessageUserIdOrGroupId] ? updatedChat[contactName.latestMessageUserIdOrGroupId] : []
                        UserMsg.push(mas)
                        updatedChat = Object.assign(updatedChat, { [contactName.latestMessageUserIdOrGroupId]: UserMsg })
                        allchatsRef.current = updatedChat
                        setAllChats(allchatsRef.current);
                        chatsRef.current = [...chatsRef.current, mas]
                        setChats([...chatsRef.current])
                        scrollToBottom()
                        // setChats(UserMsg)
                    }
                    
                    const list: any = [...HistoryUserListRef.current]
                    list.forEach((item , index) => {
                        if (item.latestMessageUserIdOrGroupId == contactName.latestMessageUserIdOrGroupId) {
                            item.latestMessage = chatMessage.chatMessage.message ? chatMessage.chatMessage.message :(chatMessage.fileInformations? chatMessage.fileInformations[0]?.FileName: "")
                            list.splice(index, 1);        
                            list.unshift(item)
                        } 
                    })
                    HistoryUserListRef.current = list
                    setHistoryUserList(list)

                }
                if(Dictionary==null ||forwardFile){
                    responseId = await ChatConnection.invoke("MessageDispatcher" ,chatMessage );
                }

            try {

            } catch (e) {
                

            }

            if(responseId ||Dictionary!=null ||forwardFile){

                let UserMsg: any = updatedChat[contactName.latestMessageUserIdOrGroupId] ? updatedChat[contactName.latestMessageUserIdOrGroupId] : []
                //UserMsg.push(mas)
                updatedChat = Object.assign(updatedChat, { [contactName.latestMessageUserIdOrGroupId]: UserMsg })
                allchatsRef.current = updatedChat
                setAllChats(allchatsRef.current);
               let listt =  chatsRef.current.map(item => {
                    if(item.chatMessage.id.includes(msgId)){
                        item.chatMessage.id = responseId ? responseId : Dictionary?.ChatMessage.Id
                        return item                       
                    }else{
                        return item
                    }      
                });
                setChats([...listt])
            }else{
                
                    let UserMsg: any = updatedChat[contactName.latestMessageUserIdOrGroupId] ? updatedChat[contactName.latestMessageUserIdOrGroupId] : []
                    //UserMsg.push(mas)
                    updatedChat = Object.assign(updatedChat, { [contactName.latestMessageUserIdOrGroupId]: UserMsg })
                    allchatsRef.current = updatedChat
                    setAllChats(allchatsRef.current);
                    chatsRef.current.forEach(item => {
                        if(item.chatMessage.id.includes(msgId)){
                            item.chatMessage.id = "notSend"
                            return false
                        }
                    });
                    setChats([...chatsRef.current])

                
            }
            scrollToBottom()
        } else {
            alert('No connection to server yet.');
        }
        return  chatsRef.current
    };

    window.onfocus = function() {
        if(contactName && contactNameRef?.current && corentContactMsg.current){

            sendContactToShowChat(contactName)
        }
    };
    const handleSubmit = async (event ) => {
        event.preventDefault()
        setLoading(true)
        const data = event?.target?.message?.value.replace(/^\n|\n$/g, '')
        if ((data || forwardMsg) ) {
            // setChats(state => [...state, {message: data, attached_file: file ? file : null, received_message: false, sent_Date: 'اکنون', reply_data: {}}])
            if(forwardMsg){
                if(contactSelectList.length >0){
                    contactSelectList.forEach(item => {
                        if(forwardMsgList?.length >0){
                            forwardMsgList.forEach(msg => {
                                if(msg.message.length > 0){
                                    sendMessage(userName, item, msg.chatMessage.message, null)
                                }else{
                                    sendMessage(userName, item,"",{...msg ,FileInformations :  msg.fileInformations}   )
                                }
                            });
                        }else{
                            if(forwardMsg?.fileInformations && Object.keys(forwardMsg?.fileInformations).length >0 ){
                                sendMessage(userName, item,"", {...forwardMsg ,FileInformations :  forwardMsg.fileInformations},true)
                            }else{
                                sendMessage(userName, item, forwardMsg.chatMessage.message, null)
                            }
                        }
                    });
                    Toast(`${trans("MessageForwardedTo")} ${contactSelectList.length}  ${trans("chats")}.` , "success")
                }else{


                    if(forwardMsgList?.length >0){
                        forwardMsgList.forEach(msg => {
                            if(msg.chatMessage.message.length > 0){
                                sendMessage(userName, contactName, msg.chatMessage.message, null)
                            }else{
                                sendMessage(userName, contactName,"",{...msg ,FileInformations :  msg.fileInformations},true)
                            }
                        });
                    }else{


                    if(forwardMsg.chatMessage.message.length >0 ){
        
                        sendMessage(userName, contactName, forwardMsg.chatMessage.message, null)
                    }else{
                        sendMessage(userName, contactName,"", {...forwardMsg ,FileInformations :  forwardMsg.fileInformations},true)
    
                    }
                }

                }
                selectListContactRef.current=[]
                setContactSelectList([])
                setForwardMsg(null)
                setForwardMsgList([])
                

            }
            if(data && data.trim().length>0){
                
                sendMessage(userName, contactName, data, null)
            }
            
        }else Toast(trans("Msg_EnterMessage"), 'warning')
        if(formTextbox.current){
            event.target.reset();
            formTextbox.current.value =  formTextbox.current.value.replace(/\n/g,'\\n')
            
            
        }
        setLoading(false)
        setFile([])
        setReplyList(null)
    }




    useEffect(() => {
        document.title = trans("message")
        if (ChatConnection ) {
            if(ChatConnection.connection._connectionState == "Connected" ){
                getHistoryUserList()
            }
            ChatConnection.on('SendMessage', async(messageItem:any) => {
                let scrollFlag = false ;
                if (listInnerRef.current) {
                    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
                    if (scrollTop + clientHeight === scrollHeight) {
                        scrollFlag = true   
                    }
                  }
                const date = new DateObject({ calendar: persian, locale: persian_fa, format: "YYYY/MM/DD hh:mm" })
                if (contactNameRef.current!=undefined && window.location.href.includes("message")) {
                    if(contactNameRef?.current.latestMessageUserIdOrGroupId == messageItem?.chatMessage.senderId&& messageItem?.chatMessage.isDirect &&  isFocused()){
                            
                        await ChatConnection.invoke("SetMessagesAsRead" , [messageItem.chatMessage.id ],contactNameRef?.current.latestMessageUserIdOrGroupId );
                            chatsRef.current = [...chatsRef.current , messageItem]
                            setChats([...chatsRef.current ])
                            let list: any = [...HistoryUserListRef.current]
                            let flag = false
                            list.forEach((item , index) => {
                                if (!messageItem.chatMessage.isDirect  && messageItem.chatMessage.groupId != null) {
                                    if (item.latestMessageUserIdOrGroupId == messageItem.chatMessage.groupId ) {
                                        item.message =messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[0]?.fileName: "")
                                        list.splice(index, 1); 
                                        list.unshift(item)
                                        flag = true
                                    }
                                } else if (item.latestMessageUserIdOrGroupId == messageItem.chatMessage.senderId) {
                                    item.message =messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[0]?.fileName: "")
                                    list.splice(index, 1); 
                                    list.unshift(item)
                                    flag = true
                                }
                            })
                            HistoryUserListRef.current = list
                            setHistoryUserList(list)
                    }else{
                        if ( !messageItem?.chatMessage.isDirect &&(contactNameRef?.current?.latestMessageUserIdOrGroupId === messageItem?.chatMessage.groupId &&  isFocused())) {
                            chatsRef.current = [...chatsRef.current , messageItem]
                            

                            const seen =   await ChatConnection.invoke("SetMessagesAsRead" , [messageItem.chatMessage.id ],contactNameRef?.current.latestMessageUserIdOrGroupId );
                              setChats([...chatsRef.current ])
                              const list: any = [...HistoryUserListRef.current]
                              list.forEach((item , index) => {
                                  if (!messageItem.chatMessage.isDirect  && messageItem.chatMessage.groupId != null) {
                                      if (item.latestMessageUserIdOrGroupId == messageItem.chatMessage.groupId ) {
                                        item.message =messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[Object.keys(messageItem.fileInformations)[0]]?.fileName: "")
                                        list.splice(index, 1); 
                                        list.unshift(item)
                                      }
                                  } else if (item.latestMessageUserIdOrGroupId == messageItem.chatMessage.senderId) {
                                    item.message =messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[Object.keys(messageItem.fileInformations)[0]]?.fileName: "")
                                    list.splice(index, 1); 
                                    list.unshift(item)
                                  }
                              })
                              HistoryUserListRef.current = list
                              setHistoryUserList(list)
                        } else {
                            if((!messageItem?.chatMessage.isDirect &&(contactNameRef?.current?.latestMessageUserIdOrGroupId === messageItem?.chatMessage.groupId)||(contactNameRef?.current?.latestMessageUserIdOrGroupId == messageItem?.chatMessage.senderId&& messageItem?.chatMessage.isDirect) )){
                                corentContactMsg.current =true
                            }
                            layoutContext.setChatNotificount(true)
                            notifyMe(messageItem.sendUser ,messageItem.chatMessage.message , ()=>{
                            })
                            
                            let list: any = [...HistoryUserListRef.current]
                            let flag = false
                            list.forEach((item , index) => {
                                if (!messageItem.chatMessage.isDirect && messageItem.chatMessage.groupId != null) {
                                    if (item.latestMessageUserIdOrGroupId == messageItem.chatMessage.groupId) {
                                        item.haveMsg = true
                                        item.unreadCount += 1
                                        item.latestMessage =messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[Object.keys(messageItem.fileInformations)[0]]?.fileName: "")
                                        flag = true
                                        list.splice(index, 1); 
                                        list.unshift(item)
                                    }
                                    //   notifyMe(messageItem.message)
                                } else if (item.latestMessageUserIdOrGroupId == messageItem.chatMessage.senderId) {
                                    item.haveMsg = true
                                    item.latestMessage =messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[Object.keys(messageItem.fileInformations)[0]]?.fileName: "")
                                    item.unreadCount += 1
                                    flag = true
                                    list.splice(index, 1); 
                                    list.unshift(item)
                                    // notifyMe(messageItem.message)
                                }
                            })
                                if(!flag){
                                    if(messageItem.chatMessage.isDirect){
                                        list.unshift( { latestMessageUserIdOrGroupId: messageItem.chatMessage.senderId, latestMessagePersonOrGroupName: messageItem?.sendUser,unreadCount :0, latestMessage:messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[Object.keys(messageItem.fileInformations)[0]]?.fileName: ""),isDirect: true })
                                    }else{
                                        list.unshift( { latestMessageUserIdOrGroupId: messageItem.chatMessage.groupId, latestMessagePersonOrGroupName: messageItem?.groupName, unreadCount :0, latestMessage:messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[Object.keys(messageItem.fileInformations)[0]]?.fileName: ""),isDirect: false})
                                    }
                                }
                                HistoryUserListRef.current = list
                                setHistoryUserList(list)
                            }
                    }
                } else if(window.location.href.includes("message")) {
                    if ( !messageItem?.chatMessage.isDirect &&(contactNameRef?.current?.latestMessageUserIdOrGroupId === messageItem?.chatMessage.groupId &&  isFocused())) {
                        chatsRef.current = [...chatsRef.current , messageItem]
                    

                        const seen =   await ChatConnection.invoke("SetMessagesAsRead" , [messageItem.chatMessage.id ],contactNameRef?.current.latestMessageUserIdOrGroupId );
                          setChats([...chatsRef.current ])
                          const list: any = [...HistoryUserListRef.current]
                          list.forEach((item , index) => {
                              if (!messageItem.chatMessage.isDirect  && messageItem.chatMessage.groupId != null) {
                                  if (item.latestMessageUserIdOrGroupId == messageItem.chatMessage.groupId ) {
                                    item.message =messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[Object.keys(messageItem.fileInformations)[0]]?.fileName: "")
                                    list.splice(index, 1); 
                                    list.unshift(item)
                                  }
                              } else if (item.latestMessageUserIdOrGroupId == messageItem.chatMessage.senderId) {
                                item.message =messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[Object.keys(messageItem.fileInformations)[0]]?.fileName: "")
                                list.splice(index, 1); 
                                list.unshift(item)
                              }
                          })
                          HistoryUserListRef.current = list
                          setHistoryUserList(list)
                    } else {
                        if((!messageItem?.chatMessage.isDirect &&(contactNameRef?.current?.latestMessageUserIdOrGroupId === messageItem?.chatMessage.groupId)||(contactNameRef?.current?.latestMessageUserIdOrGroupId == messageItem?.chatMessage.senderId&& messageItem?.chatMessage.isDirect) )){
                            corentContactMsg.current =true
                        }
                        layoutContext.setChatNotificount(true )
                        notifyMe(messageItem.sendUser ,messageItem.chatMessage.message , ()=>{
                        })
                        
                        let list: any = [...HistoryUserListRef.current]
                        let flag = false
                        list.forEach((item , index) => {
                            if (!messageItem.chatMessage.isDirect && messageItem.chatMessage.groupId != null) {
                                if (item.latestMessageUserIdOrGroupId == messageItem.chatMessage.groupId) {
                                    item.haveMsg = true
                                    item.unreadCount += 1
                                    item.latestMessage =messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[Object.keys(messageItem.fileInformations)[0]]?.fileName: "")
                                    flag = true
                                    list.splice(index, 1); 
                                    list.unshift(item)
                                }
                                //   notifyMe(messageItem.message)
                            } else if (item.latestMessageUserIdOrGroupId == messageItem.chatMessage.senderId) {
                                item.haveMsg = true
                                item.latestMessage =messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[Object.keys(messageItem.fileInformations)[0]]?.fileName: "")
                                item.unreadCount += 1
                                flag = true
                                list.splice(index, 1); 
                                list.unshift(item)
                                // notifyMe(messageItem.message)
                            }
                        })
                            if(!flag){
                                if(messageItem.chatMessage.isDirect){
                                    list.unshift( { latestMessageUserIdOrGroupId: messageItem.chatMessage.senderId, latestMessagePersonOrGroupName: messageItem.sendUser,unreadCount :0, latestMessage:messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[Object.keys(messageItem.fileInformations)[0]]?.fileName: ""),isDirect: true })
                                }else{
                                    list.unshift( { latestMessageUserIdOrGroupId: messageItem.chatMessage.groupId, latestMessagePersonOrGroupName: messageItem?.groupName, unreadCount :0, latestMessage:messageItem.chatMessage.message ? messageItem.chatMessage.message :(messageItem.fileInformations? messageItem.fileInformations[Object.keys(messageItem.fileInformations)[0]]?.fileName: ""),isDirect: false})
                                }
                            }
                            HistoryUserListRef.current = list
                            setHistoryUserList(list)
                        }
                }
                if (scrollFlag) {
                   scrollToBottom()
                }
            });
            ChatConnection.on('InformAsync', async (method, message1="") => {
               let message = message1 ? message1 :""
                console.log("InformAsync ", method, "**", message ,"**");                
                try {
                    if(method.includes("Connected")||message.includes("Connected")){
                        //getonlinUser()
                        getHistoryUserList()
                    }
                    if("RemoveFromGroup" === method ){
                        if((contactNameRef?.current?.latestMessageUserIdOrGroupId === message || layoutContext.UserPerson.UserId === message )){
                            setContactName(null)
                            contactNameRef.current = null
                        }else{
                        }
                        getHistoryUserList()
                    }else if(method.includes("ReadReport") && message.includes(contactNameRef?.current?.latestMessageUserIdOrGroupId) ){
                        let updatedChat: any = JSON.parse(JSON.stringify(allchats));
                        let UserMsg: any = updatedChat[contactNameRef?.current?.latestMessageUserIdOrGroupId] ? updatedChat[contactNameRef?.current?.latestMessageUserIdOrGroupId] : []
                        updatedChat = Object.assign(updatedChat, { [contactNameRef?.current?.latestMessageUserIdOrGroupId]: UserMsg })
                        allchatsRef.current = updatedChat
                        setAllChats(allchatsRef.current);
                        chatsRef.current.forEach(item => {                            
                            if(!item.chatMessage.isRead){
                                item.chatMessage.isRead = true
                                return false    
                            }  
                        });
                        setChats([...chatsRef.current])
                    }else if (method.includes("HasUnread") ) {
                       // getFirstRef.current = false
                       // setGetFirst(false)                     
                        if(message !="False"){
                          layoutContext.setChatNotificount(true )
                        }else{
                          layoutContext.setChatNotificount(false )
                        }
                    }else if(method.includes("Error") ) {
                        Toast(message , "error")
                    }

            } catch (error) {

                }

                //setLatestonline(online);
            });
/*             if ( getFirstRef.current ) {
                //getContacts()
               // getGroupList()
               if(ChatConnection.connection._connectionState == "Connected" ){
                    getHistoryUserList()

                }
                //getonlinUser()
                setGetFirst(false)
                getFirstRef.current = false
            } */
        } 
    }, []);

    useEffect(() => {
        if(ChatConnection.connection._connectionState == "Connected" ){
            getHistoryUserList()
            
            let prfList = layoutContext?.currentUserPosition?.Description.split("-")
            setUser({ Name: prfList[0], Title: prfList[2] })
            let requesterStorage =localStorage.getItem("JobRequester")
            
            let JobRequester:any  = {}
            if(requesterStorage == null) {
                sendContactToShowChat(contactName)
            } else {
                JobRequester = JSON.parse(requesterStorage)
                if(JobRequester?.Id?.length > 0){
                    let item ={
                        latestMessageUserIdOrGroupId:JobRequester.Id,
                        latestMessagePersonOrGroupName:JobRequester.name,
                        isDirect: true
                    }
                    sendContactToShowChat(item)
                }
                localStorage.setItem("JobRequester", "null")
            }
        }

    }, [ChatConnection ,layoutContext] )

    useEffect(() => {
        let flag = true
        const callback = (event: KeyboardEvent) => {
            if (((event.metaKey || event.ctrlKey) && event.code === 'Enter')||(window.screen.width < 768 && event.keyCode ==66 ) ||event.code =="NumpadEnter") {
                if (formboxHeight.current < 150) {
                    formboxHeight.current += 50
                    setFormboxHeightState(formboxHeight.current)
                }
                if(event.code !="NumpadEnter")
                  formTextbox.current.value += "\n"
                flag = false    
            }
            if (event.code === 'Enter' && flag&& !document.activeElement?.className.includes("search-bar") ) {
                event.preventDefault()
                if(formButton.current)formButton.current.click()
                if(sendFileref.current)sendFileref.current.click()
                return false
            }
            if (event.code === 'Escape' ) {
                event.preventDefault()
                setContactName(null)
                contactNameRef.current = null
            }
            flag = true
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);




   

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
    };

    const removeSendFile = function (e) {
        let newLidt = file
        setCorentFile(file[e - 1] ? file[e - 1] : file[e + 1])
        newLidt.splice(e, 1)
        setFile(newLidt)
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setCorentFile({ file: e.dataTransfer.files[0], Tu: URL.createObjectURL(e.dataTransfer.files[0]) })
        let fileList = [...e.dataTransfer.files].map(item => {
            return { file: item, Tu: URL.createObjectURL(item) }
        })
        setFile([...fileList, ...file])
        setShowModalFile(true)
    };

    const UserListSearchhandleer = (str) => {
        if(str =="" ){
            setUserListsearch(null)
        }else{
            setUserListsearch(str)
        }
        getContacts(str)
      }
    const addToForwardList = (massage) => {
        let list:any = []
        let flag = true
        forwardMsgList.forEach(item => {
            if(item.chatMessage.id !=massage.chatMessage.id){
                list.push(item)
            }else{
                flag = false
            }
        });
        if(flag){
            list.push(massage)
        }
        setForwardMsgList(list)
      }

    const createGroup = (str) => {
        setmode("create")
        setContactName(null) ; 
        contactNameRef.current = null
      }

    const handleCreateGroup = (e) => {
        e.preventDefault()
        let listId = contactSelectList.map(item=>{return item.latestMessageUserIdOrGroupId})
        addLineGroupe(listId ,e.target.GroupName.value.length>0 ? e.target.GroupName.value : null)
        selectListContactRef.current=[]
        setContactSelectList([])
        getHistoryUserList()
        setmode(null)
      }
    const DeleteMessage = async(e) => {
        try {
            let list = e.map(item=>{return item.chatMessage.id})          
            Toast(trans("msg_deleteconfirm"), "warning", "alert", (async(item)=>{
                const response = await ChatConnection.invoke("DeleteMessage" , list);
                sendContactToShowChat(contactName)
                setForwardMsgList([])
            }))
        } catch (error) {
            
        }
      }

    //#cartable--------------------------------------------------------
    

    const showReferenceStepperCallback = (referenceId2: string) => {
        setReferenceId(referenceId2);
        setShowReference(true);
        setShowGeneralRequestForm(false);
    };
    return (

        <>
            {showGeneralRequestForm &&
                <ModalForm mode={mode} title={("GeneralRequestForm")} onHide={() => setShowGeneralRequestForm(false)} show={showGeneralRequestForm}>
                    <GeneralRequestForm  handleShowReferenceStepper={showReferenceStepperCallback}  Description={message?.chatMessage?.message} mode={"create"} onHide={() => setShowGeneralRequestForm(false)} handleCancelForm={() => setShowGeneralRequestForm(false)} />
                </ModalForm>
            }
            {showReference && 
                <ModalForm modalHeight={74} mode={mode} title={"ReferenceStepper"} onHide={() => { setShowReference(false) }} show={showReference} >
                    <ReferenceStepper  onHide={() => { setShowReference(false)}} referenceId={referenceId} styleCondition={70} />
                </ModalForm>
            }
            <div className="cardContainer">
                <div className="card">
                    <div className="chat d-flex" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
                        <div className={`  ${contactName == null ? 'col-12 ' : 'd-none'} col-md-3 d-md-block chat__contact position-relative border-start border-end p-0 p-md-2`}>
                            {(loadingContactList ) ?
                                <ContactList handleSearch={setsearch} searchString={search} onlinUserList={onlinUserList} handleCreateGroup={handleCreateGroup} mode={mode} setmode={setmode} createGroup={createGroup} cancelForward={()=>{selectListContactRef.current =[]; setContactSelectList([]) ; setForwardMsg(null);setForwardMsgList([])}}  handleSubmit={handleSubmit} contactSelectList={JSON.parse(JSON.stringify(selectListContactRef.current))} setContactSelectList={(e)=>{addContactToSelectList(e)} } forwardMsg={forwardMsg} HistoryUserList={HistoryUserList} getContacts={getContacts}getGroupList={getGroupList} getHistoryUserList={getHistoryUserList} leftFromGroup={leftFromGroup}removeGroup={removeGroup} deleteChat={deleteChat} UserListSearch={UserListSearchhandleer}  UserList={contactList}groupList={groupList}  sendContactToShowChat={sendContactToShowChat} user={user} contactName={contactName} />
                                : <Loading />
                            }
                        </div>
                        <div className={` ${contactName == null ? ' ' : 'd-flex'} flex-column chat__container  `}>
                            <>
                                <div  className='chat__header border-bottom d-flex align-items-center justify-content-space-between py-3 px-1'>
                                    {contactName != null ?
                                        <>  
                                            <div className="memberTitle d-flex  col-8">
                                            <FontAwesomeIcon icon={['fas', 'user-circle']} color='grey' className="mx-3 chat__contact-list-avatar" />
                                            {trans("ChattingWith")}
                                            {contactName == null ?
                                                <span className='text-secondary fw-lighter me-1' style={{ fontSize: '0.5rem' }}>(مخاطب را انتخاب کنید !)</span>
                                                :
                                                <>{
                                                    typeof contactName == "object" ?
                                                        contactName.isDirect ?
                                                            <>
                                                                <span className='me-1 text-primary'> , {contactName.latestMessagePersonOrGroupName}</span>
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    groupMembers.map((item, index) => {
                                                                        if(index<3){
                                                                        return <>
                                                                            <div style={{ width: "15px" }} className="UserLi UserLi__delete-selected-option mr-2 pr-2" data-key={item.userId} onClick={() => { leftFromGroup(item.userId, false) }}>
                                                                                <FontAwesomeIcon icon={["fas", "times"]} style={{ cursor: 'pointer', height: "17px", float: "left" }} />
                                                                            </div>
                                                                            <span style={{ whiteSpace: "nowrap" }}   className='me-1 text-primary'> {item.userName}, </span>
                                                                        </>
                                                                        }

                                                                    })
                                                                    
                                                                }
                                                                <>
                                                                {groupMembers.length > 3 && <div className='me-1 text-primary'>...</div>}
                                                                </>
                                                            </>
                                                        :
                                                        <>
                                                            {
                                                                contactName.map(item => {
                                                                    return <span className='me-1 text-primary'> , {item.name}</span>

                                                                })
                                                            }

                                                        </>
                                                }
                                                {contactName.latestMessagePersonOrGroupName != "broadCast" &&
                                                    <span onClick={() => { setChoiceUser(true) }} className='me-1 text-primary'>
                                                        <FontAwesomeIcon icon={['fas', 'plus']} className="text-primary" style={{ cursor: 'pointer', height: "17px" }} />
                                                    </span>
                                                }

                                                </>
                                            }

                                            </div  >
                                            <div style={{width : forwardMsgList.length >0 || window.innerWidth < 770 ?"17%" :"2%" ,flexDirection:forwardMsgList.length >0 ?"row":"row-reverse" }} className='headerIconBox '>
                                            { forwardMsgList.length >0 &&
                                            <>                                       
                                            <div className='me-1 ml-4  backIcon'>
                                            {forwardMsgList.length}
                                            </div>
                                            <div onClick={(e)=>{ setContactName(null) ; contactNameRef.current = null ; setForwardMsg({})}} className='me-1 ml-4  backIcon'>
                                                <FontAwesomeIcon  className="chat__chatbox-reply-btn"   color={"grey"}  icon={['fas', 'share']} />
                                            </div>                                            
                                            <div onClick={(e)=>{ DeleteMessage(forwardMsgList)}} className='me-1 ml-4  backIcon'>
                                                <FontAwesomeIcon  className="chat__chatbox-reply-btn"   color={"grey"}  icon={['fas', 'trash']} />
                                            </div>
                                            <div onClick={(e)=>{selectListContactRef.current =[]; setContactSelectList([]) ; setForwardMsg(null);setForwardMsgList([])}} className='me-1 ml-2  backIcon'>
                                                <FontAwesomeIcon className="chat__chatbox-reply-btn"   color={"grey"}   icon={['fas', 'xmark']} />
                                            </div>
                                            </>
                                            }
                                            {!contactName.isDirect &&
                                                <div onClick={(e)=>{leftFromGroup(contactNameRef.current)}} className='me-1 ml-4  backIcon'>
                                                    <FontAwesomeIcon style={{transform: "rotate(180deg)"}} className="chat__chatbox-reply-btn"   color={"grey"}  icon={['fas', 'arrow-right-from-bracket']} />
                                                </div>
                                            }
                                            <div onClick={(e)=>{backToContactList()}} className='me-1 d-md-none  backIcon'>
                                                <FontAwesomeIcon className="chat__chatbox-reply-btn"   color={"grey"}  icon={['fas', 'arrow-left']} />
                                            </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                </div>
                                <div onScroll={ScrollData} id={"chatMassgeList"} ref={listInnerRef} className={`chat__message   flex-column ${contactName != null ? "   pb-4" : "messageBkImg"}`}>
                                    {contactName != null ?
                                        <>
                                            {showModalFile ?
                                                <>
                                                  <div className='fileTitle' style={{height:'6%'}}>
                                                      <div className='fileName mt-1'>
                                                          {corentFile?.file?.name}
                                                      </div>
                                                      <div className='fileRemove' onClick={cancleSendFile}>
                                                          <FontAwesomeIcon icon={['fas', 'times']} color='grey' style={{ cursor: 'pointer' }} />
                                                      </div>
                                                  </div>
                                                  <div className='w-100 d-flex justify-content-center align-items-center' style={{height:'94%'}}>
                                                  {corentFile.file.type.includes("image") ?
                                                  <div style={{display: "flex" , maxHeight: "92%"}}>
                                                        <img src={corentFile?.Tu} className='ModalFile mt-3' />
                                                  </div>
                                                      :
                                                      <>
                                                      {(DisplayableFormats.includes(corentFile.file.type.replace("application/",""))|| DisplayableFormats.includes(corentFile.file.name.split(".")[1])) ?
                                                        <object data={corentFile?.Tu+""} className='ModalFile mt-3'>
                                                        </object>
                                                        :
                                                        <><div style={{height:"46vh"}} className={`xlsx      showArchive `}>{trans("msg_cannotshowfile")}</div></>

                                                      }
                                                      </>
                                                  }
                                                  </div>          
                                                </>
                                                :
                                                <>
                                                    {loading ? <Loading /> :
                                                        chats.map((item, index) => (
                                                            <>
                                                            {
                                                            ((!item.chatMessage.isRead &&chats[index-1 >=0 ?index-1:0 ].chatMessage.isRead )&& (layoutContext?.currentUserPosition?.UserId  != undefined ?  item.chatMessage.senderId!= layoutContext?.currentUserPosition?.UserId : item.chatMessage.senderId== contactName.latestMessageUserIdOrGroupId )) &&
                                                                <div style={{ scrollMargin:"60px"}} ref={messagesnotSeeRef} className="unreadLine mb-2 pb-2">{trans("unreadMessages")}</div>
                                                            }
                                                                <>
                                                                    <MessageBox
                                                                        showCarouselFile={showCarouselFile}
                                                                        DeleteMessage={()=>{DeleteMessage([item])}} 
                                                                        CreateRequest={()=>{setMessage(item) ; setShowGeneralRequestForm(true)}} 
                                                                        forwardMsgList={forwardMsgList}
                                                                        setForwardMsgList={(e)=>{addToForwardList(item)}}
                                                                        setForwardMsg={()=>{ setContactName(null) ; contactNameRef.current = null ; setForwardMsg(item)}}
                                                                        key={index}
                                                                        index={index}
                                                                        user={user}
                                                                        submitted={submitted}
                                                                        replyList={replyList}
                                                                        sendRepliesUp={sendRepliesUp}
                                                                        MessageData={item}
                                                                        attached_file={item.fileInformations }
                                                                        received_message={(layoutContext?.currentUserPosition?.UserId  != undefined ?  item.chatMessage.senderId!= layoutContext?.currentUserPosition?.UserId : item.chatMessage.senderId== contactName.latestMessageUserIdOrGroupId )}
                                                                    />
                                                                </>
                                                            </>
                                                        ))
                                                    }
                                                    <div ref={messagesEndRef}  />
                                                    <div className='d-md-none' style={{marginTop: "30px"}} />
                                                    {showArrowDown && 
                                                    <div className='arrowDown ' onClick={(e)=>{scrollToBottom()}}  >  <FontAwesomeIcon icon={['fas', 'arrow-down']} color='#0d6efd' /></div>
                                                    }
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                        {loading ? <Loading /> :
                                            <img style={{ width: "200px" }} src={img} alt={` `} />
                                        }
                                        </>
                                    }
                                </div>                      
                                <div className='chat__input container-fluid'>
                                    {contactName != null ?
                                        <>
                                            {replyList  &&
                                                <div className='chat__input-preview w-100 d-flex align-items-center justify-content-center px-4 pt-3'>
                                                    <div className='chat__input-preview-box w-100 p-2 rounded border bg-white d-flex flex-column'>
                                                        <div className='w-100 d-flex justify-content-between'>
                                                            <div className='d-flex align-items-center'>
                                                                <FontAwesomeIcon icon={['fas', 'reply']} color='#0d6efd' />
                                                                <div className='d-flex flex-column me-2'>
                                                                    <span className='text-primary'>
                                                                        {replyList?.sendUser}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <FontAwesomeIcon icon={['fas', 'times']} color='grey' style={{ cursor: 'pointer' }} onClick={() => { setReplyList(null) }} />
                                                        </div>
                                                        <div className='mt-2 text-truncate text-muted w-100'>
                                                            {replyList?.fileInformations?.length>0 ?  trans("فایل")+" 📄"  :replyList?.chatMessage.message}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {forwardMsg  &&
                                                <div className='chat__input-preview w-100 d-flex align-items-center justify-content-center px-4 pt-3'>
                                                    <div className='chat__input-preview-box p-2 rounded border bg-white d-flex flex-column'>
                                                        <div className='w-100 d-flex justify-content-between'>
                                                            <div className='d-flex align-items-center'>
                                                                <FontAwesomeIcon style={{height:"12px" }} icon={['fas', 'share']} color='#0d6efd' />
                                                                <div style={{fontSize: "12px" ,fontWeight: "bold",letterSpacing: "0.5px"}} className='d-flex flex-column me-2'>
                                                                     {trans("Forwardmessage")}
                                                                </div>
                                                            </div>
                                                            <FontAwesomeIcon icon={['fas', 'times']} color='grey' style={{ cursor: 'pointer' ,height:"14px" }} onClick={() => { setForwardMsg(null) }} />
                                                        </div>
                                                        <div style={{fontSize: "11px" }} className='mt-2 text-truncate text-muted w-100'>
                                                            {forwardMsgList?.length >0 ?
                                                                `Forward ${forwardMsgList.length} massage`
                                                                :
                                                                <>
                                                                    {(forwardMsg.fileInformations && Object.values(forwardMsg.fileInformations).length >0) ?
                                                                             `Forward ${Object.values(forwardMsg.fileInformations).length} files`
                                                                    : forwardMsg.chatMessage.message}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {showModalFile ?
                                                <div className='fileT' >
                                                    <div className='fileT' >
                                                        <div style={{ display: "flex", flexDirection: "row" }} >
                                                            {
                                                                sendFileLoading ?
                                                                    <div className='FileOption'  >
                                                                        <FontAwesomeIcon icon={["fas", "sync"]} className={`text-primary  ${sendFileLoading ? "Rotate" : ""} `} />
                                                                    </div>
                                                                    :
                                                                    <div ref={sendFileref} className='FileOption' onClick={handleSendFiles} >
                                                                        <FontAwesomeIcon icon={['fas', 'paper-plane']} className="text-primary" style={{ cursor: 'pointer', height: "18px" }} />
                                                                    </div>
                                                            }
                                                            <div className='FileOption' onClick={handleUploadOnclick} >
                                                                <FontAwesomeIcon icon={['fas', 'plus']} className="text-primary" style={{ cursor: 'pointer', height: "18px" }} />
                                                                <input
                                                                    multiple
                                                                    disabled={contactName == null}
                                                                    type='file'
                                                                    name='upload'
                                                                    ref={uploadRef}
                                                                    onChange={handleUploadOnchange}
                                                                    hidden
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fileTscroll' style={{ overflowX: "scroll" }}>
                                                            {file.map((item, index) => (
                                                                item?.file?.type?.includes("image") ?
                                                                    <div className={`FileOption  ${corentFile.Tu == item.Tu ? "activFile" : ""}`} >
                                                                        <img src={item.Tu} style={{ height: "50px", width: "50px" }} onClick={() => { setCorentFile(item) }} />
                                                                        {corentFile.Tu == item.Tu && <div style={{ width: "16px", height: "16px", position: "absolute", left: "-8px", top: "-8px", }}>
                                                                            <FontAwesomeIcon onClick={() => { removeSendFile(index) }} icon={['fas', 'times']} className="text-primary" style={{ cursor: 'pointer', height: "16px", width: "15px" }} />
                                                                        </div>}                                </div>
                                                                    :
                                                                    <object className={`FileOption  ${corentFile.Tu == item.Tu ? "activFile" : ""}`}   >
                                                                        {corentFile.Tu == item.Tu && <div style={{ width: "16px", height: "16px", position: "absolute", left: "-8px", top: "-8px", }}>
                                                                            <FontAwesomeIcon onClick={() => { removeSendFile(index) }} icon={['fas', 'times']} className="text-primary" style={{ cursor: 'pointer', height: "16px", width: "15px" }} />
                                                                        </div>}
                                                                        {fileIconFormat(item)}
                                                                    </object>
                                                            ))}
                                                        </div>
                                                    </div>

                                                </div>
                                                :
                                                <>
                                                <form onSubmit={handleSubmit}>
                                                    <div style={{ height: formboxHeightState + "px" }} className='d-flex align-items-center justify-content-center px-1'>
                                                        <div style={{ height: "60%", flexDirection: "column" }} className=' position-relative  d-flex justify-content-end ' >
                                                            <button type='submit' ref={formButton} style={{ height: "33px", borderRadius: "50%", backgroundColor: '#0d6efd' }} onClick={() => {
                                                            }} className={`chat__input-btn mx-1 border border-primary d-flex align-items-center justify-content-center `}>
                                                                <FontAwesomeIcon icon={['fas', 'paper-plane']} color="white" className='chat__input-btn-icon' />
                                                            </button>

                                                        </div>
                                                        <div style={{ height: "60%" }} className='w-100 position-relative'>
                                                            <textarea
                                                                onPaste={Uploadonpaste}
                                                                autoFocus={window.innerWidth > 770}
                                                                style={{ height: "100%" }}
                                                                onChange={(e)=>{if(!(e.target.value?.length>0)){formboxHeight.current=50; setFormboxHeightState(50)}}}
                                                                ref={formTextbox}
                                                                id='EmojiInput'
                                                                disabled={contactName == null}
                                                                name='message'
                                                                className={`p-1 `}
                                                                placeholder={contactName == null ? "مخاطب را انتخاب کنید" : trans("Msg_EnterMessage")}
                                                            >
                                                            </textarea>
                                                            <div className='chat__input-upload position-absolute'>
                                                                <input
                                                                    disabled={contactName == null}
                                                                    type='file'
                                                                    name='upload'
                                                                    ref={uploadRef}
                                                                    onChange={handleUploadOnchange}
                                                                    multiple
                                                                    hidden
                                                                />
                                                                <FontAwesomeIcon icon={['fas', 'paperclip']} onClick={handleUploadOnclick} className='chat__input-upload-icon' />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                style={{ left: "40px", border: "none", backgroundColor: "transparent", bottom: "8px" }}
                                                                className="profile-btn position-absolute"
                                                                onFocus={() => setShowEmoji(true)}
                                                                onBlur={() => setShowEmoji(false)}>
                                                                <FontAwesomeIcon icon={['fas', 'grin']} className='chat__input-upload-icon' />
                                                                {
                                                                    showEmoji && <div className='emojiBox'>
                                                                        {EMOJIS.map((item: any) => (
                                                                            <p style={{ fontSize: "18px" }} onClick={() => { handleAddEmoji(item) }}> {String.fromCodePoint(item)}</p>
                                                                        ))}
                                                                    </div>
                                                                }
                                                            </button>
                                                        </div>

                                                        <div  style={{ height: "60%", flexDirection: "column" }} className=' position-relative  d-flex justify-content-end ' >
                                                             <button type={'button'} onTouchStart={vioceRecord} onMouseDown={vioceRecord} id='recordB' ref={recordB}  style={{ height: "33px", borderRadius: "50%", backgroundColor: '#0d6efd' }} className={`chat__input-btn mx-1 border border-primary d-flex align-items-center justify-content-center `}>
                                                                 <FontAwesomeIcon icon={['fas', `${startRecordVoice? 'microphone-slash' : 'microphone'  }`]} color="white" className='chat__input-btn-icon' />
                                                             </button>
                                                         </div>
                                                    </div>
                                                </form>
                                                </>
                                            }
                                        </>
                                        :
                                        <div className='fileT' style={{ height: "10px" }}></div>
                                    }
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
            {(CarouselFileList?.[0] && showModalCarousel && carouselFlag) && 
            <div style={{zIndex:'150'}} className={`modalCarousel ${showModalCarousel ? "d-flex" : "d-none"} `} >
                 <div className="modalCarousel__close" onClick={() => { setshowModalCarousel(false); setViewerData("") }}>&#x2715;</div>
                 <div className="container">
                     <Carousel data-ride={"Carousel"}  indicators={false} interval={null} className={"modalCarousel__slide "} touch={true} onSelect={selectCarousel} defaultActiveIndex={index} activeIndex={index}>
                         {CarouselFileList?.[0] && 
                         CarouselFileList.map((item , i) => (
                            <>                           
                                 <Carousel.Item className={`attachCarousel`}>
                                     {viewerData ? <Fileviewer downloadFileAsync={()=>{downloadFileAsync(CarouselFileList ,item.fileName ? item.fileName : item.FileName)}}  File={item.fileName ? item.fileName : item.FileName} src={viewerData} /> :
                                         <Loading />
                                     }
                                 </Carousel.Item>
                            </>
                             ))
                         }
                     </Carousel>
                 </div>
             </div>
            }
            {(CarouselFileList?.[0] && showModalCarousel && !carouselFlag) && 
            <div style={{zIndex:'150'}} className={`modalCarousel ${showModalCarousel ? "d-flex" : "d-none"} `} >
                 <div className="modalCarousel__close" onClick={() => { setshowModalCarousel(false); setViewerData("") }}>&#x2715;</div>
                 <div className="container">
                     <Carousel ref={CarouselRef} data-ride={"Carousel"}  indicators={false} interval={null} className={"modalCarousel__slide "} touch={true}  onSelect={selectCarousel} defaultActiveIndex={index} activeIndex={index}>
                         {CarouselFileList?.[0] && 
                         CarouselFileList.map((item , i) => (
                                 <Carousel.Item className={`attachCarousel`}>
                                     {viewerData ? <Fileviewer  downloadFileAsync={()=>{downloadFileAsync(CarouselFileList ,item.fileName ? item.fileName : item.FileName)}} File={item.fileName ? item.fileName : item.FileName} src={viewerData} /> :
                                         <Loading />
                                     }
                                 </Carousel.Item>
                             ))
                            }
                     </Carousel>
                 </div>
             </div>
            }
            {
                choiceUser &&
                <ModalForm modalWidth={10} title={"chooseuser"} onHide={() => setChoiceUser(false)} show={choiceUser}>      
                 <GroupMembersAssign addUser={addUser} userList={contactList} GroupMembers={groupMembers} contactName={contactName} showForm={() => setChoiceUser(false)}  />
                </ModalForm>
            }
        </>
    )
}