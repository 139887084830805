import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import DatePicker from "components/DatePicker/DatePicker";
import { Loading } from "components/Loading/loading";
import { UserContext } from "components/ModalForm/ModalForm";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getJobSendData, postJobSendData, putJobSendData } from "./api";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { ENDPOINTS } from "utils/endpoints";

interface JobSendFormPropsType {
    mode?: any,
    processId?: any,
    Id?: any,
    step:any,
    onHide: () => void,
    inDynamicForms?: any;

}

export const JobSendForm = ({
    mode,
    processId,
    Id,
    step=1,
    onHide,
    inDynamicForms=false

}: JobSendFormPropsType) => {
    const [SentTime, setSentTime] = useState<any>();
    const [untilDate, setUntilDate] = useState<any>();

    const [response, setResponse] = useState<any>();
    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);
    const [localMode, setLocalMode] = useState<any>("create");
    const layoutContext = useContext(LayoutContext);


    async function formSubmit(e) {
        e.preventDefault()
        let data
        let method
        if(response.Id ){
            method = "put"
            if(step > 1){
                data = {
                    "Id" : response.Id ,
                    "RequesterPositionId": response.RequesterPositionId,
                    "RequestDate":response.RequestDate,
                    "JobSendHistoryRequestClassificationId":response.JobSendHistoryRequestClassificationId,
                    "StartDate": SentTime,
                    "EndDate":untilDate,
                    "Description": response.Description,
                    "SubstitutePersonId": response.SubstitutePersonId,
                    "RemainingDayCount" :  e.target.RemainingDayCount.value,
                    "LeaveDaysNo" :  e.target.LeaveDaysNo.value,
                }
            }else{
                data = {
                    "Id" : response.Id ,
                    "RequesterPositionId": response.RequesterPositionId,
                    "RequestDate":response.RequestDate,
                    "JobSendHistoryRequestClassificationId":response.JobSendHistoryRequestClassificationId,
                    "StartDate": SentTime,
                    "EndDate":untilDate,
                    "Description": response.Description,
                    "SubstitutePersonId": response.SubstitutePersonId,
                    "LeaveDaysNo" :  e.target.LeaveDaysNo.value,
                }
            }
        try {
            const res = await putJobSendData(data)
            setResetForm(true)
            onHide()
        }catch (error) {

        }

        }else{
            method = "post"
              data = {

                "JobId": Id,
                "OperatorPositionId": e.target.OperatorPositionId.value,
                "JobSendMethodId": e.target.JobSendMethodId.value,
                "SentTime": SentTime,
                "SentDescription":  e.target.SentDescription.value,
                "ReceiverPerson":  e.target.ReceiverPerson.value,
                "IsSent":  e.target.IsSent.value=="true",
              }
            try {
                const res = await postJobSendData(data,processId)
                    setResetForm(true)
                    onHide()
            }catch (error) {
  
          }
        }
    }

    async function getJobSendHistoryAsync() {
        setLoading(false)
         try {
          const res = await getJobSendData(Id )
          if(res.Data){
              setSentTime(res.Data.SentTime)
              setResponse(res.Data)
          }else{
              setResponse({})

          }
        } catch (error) {
           setResponse({})
        }
        setLoading(true)
    }
    
    useEffect(() => {
        if (resetForm) {  
            setResetForm(false);
        }
    }, [resetForm]);

    useEffect(() => {
        if ((mode == "edit" && step ==1)  ) {
            setLocalMode("create")
        }else if(step ==3 ||mode == "edit"){
            setLocalMode("edit")    
        }else{
            setLocalMode(mode)
        }
    }, [mode]);

    useEffect(() => {
        if( step >=1 || mode != "create"){
            getJobSendHistoryAsync()
        }else{
            setLoading(true)
            setResponse({})
        }
    }, []);



    return (
        <div>
           {loading ?
        <div>
         <form className="action-form py-2" onSubmit={formSubmit}>
            <div className={`card  `}>
                <div className="card-body">                      
                        <div className="row mb-2">
                         <div className="col-lg-4 col-sm-4 mt-1">
                         <Position
                            name="OperatorPositionId"
                            label="OperatorPosition"
                            mode={"edit"}
                            value={mode == "create" ? layoutContext.currentUserPosition.PositionId : response.OperatorPositionId }
                            resetForm={resetForm}
                        />
                         </div>
                         <div className="col-lg-4 col-sm-4 mt-1">
                            <DictionarySelectBox
                             label="SendMethod"
                             endPoint={ENDPOINTS.cartable.sendMethod.dictionary}
                             name="JobSendMethodId"
                             value={response.JobSendMethodId}
                             mode={step >1 ? "view" : mode}
                             />
                         </div>
                            <div className="col-12 col-lg-4">
                                <DatePicker
                                    Change={setUserMadeChanges}
                                    required={true}
                                    mode={step >1 ? "view" : mode}
                                    resetForm={resetForm}
                                    lable="SentTime"
                                    setIsoTime={(isoTime: string) => { setSentTime(isoTime) }}
                                    val={response?.StartDate ? response?.StartDate : SentTime} //resetForm ? new Date().toISOString() : 
                                />
                            </div>
                        </div>   
                        <div className="row mb-2">
                            <div className="col-12 col-lg-4">
                             <Textbox
                                mode={step >1 ? "view" : mode}
                                resetForm={resetForm}
                                textboxType="text"
                                textboxName="ReceiverPerson"
                                labelText="ReceiverPerson"
                                maxLength={50}                        
                                defaultValue={response.ReceiverPerson ?response.ReceiverPerson :""}
                             />
                            </div>
                            <div className="col-12 col-lg-4 mt-4 mb-4" >
                                <CheckableButton
                                   defaultChecked={mode == "create" ? true :  (response?.IsSent)}
                                   defaultValue={mode == "create" ? true :  (response?.IsSent)}
                                   labelText={"IsSent"}
                                   inputName={"IsSent"}
                                   type="checkbox"
                                   mode={mode}
                                />                                
                            </div>
                         </div>              
                        <div className="row">
                            <div className="col-lg-8 col-sm-6 ">
                               <Textbox
                                  readonly={(step >1 || mode=="view") ? true: false}
                                  mode={step >1 ? "view" : mode}
                                  resetForm={resetForm}
                                  textboxType="textarea"
                                  textboxName="SentDescription"
                                  labelText="Description"
                                  maxLength={900}
                                  defaultValue={response.SentDescription}
                               />
                            </div>
                        </div>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                {localMode !== "view" ?
                    <>
                        <Button value="Save" btnType="submit" />
                        <Button
                            btnType="cancel"
                            value="Cancel"
                            onClick={() => { Toast(trans("modalclosemessage"), "warning", "alert", onHide) }}
                        />
                    </> 
                    :
                     <Button
                        btnType="primary"
                        value="Close"
                        onClick={onHide}
                      />
                }
            </div>               
        </form>
            </div>
            : <Loading/>   
           }
        </div>
    );
}