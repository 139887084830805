
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { TabBar } from "components/Tabbar/Tabbar";
import { getUserPositions } from "pages/SystemManagement/Settings/Users/api";
import { useEffect, useRef, useState } from "react";
import { getPageSize } from "utils/helpers";
import { PositionTree } from "./PositionTree";


export const TreeUserRole = ({ personId  }) => {


    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState<string | any>([]);
    const [curentTab, setCurentTab] = useState<any>("")
    const [defaultTab, setDefaultTab] = useState<any>("")
    const [response, setResponse] = useState<any>()
    const [error, setError] = useState<any>()

     

    async function getUserPositionsAsync() {
        setLoading(false)
    
        let sizePage = getPageSize("UserList" )
           try {
            const res = await getUserPositions(personId)
            setResponse(res.Data)
            setDefaultTab(res.Data[0].Title)
            setCurentTab(res.Data[0].Title)
            let tabs = res.Data.map(item=>{
                return { title: item.Title, body: (<PositionTree  inTab={curentTab }   position={item}  />), }
            })
            setContent(tabs)
           } catch (error:any) {
            setError(error.response.data.Message)
            
           }
           setLoading(true)
        }
        const tabChangeHandle = (tab) => {
            setCurentTab(tab)
        }
    


    useEffect(() => {
        if(response){
            let tabs = response.map(item=>{
                return { title: item.Title, body: (<PositionTree  inTab={curentTab }   position={item}  />), }
            })
            setContent(tabs)
        }
    }, [curentTab]);

    useEffect(() => {
        setError(null)
        getUserPositionsAsync();
    }, [personId]);


    return (
        <>
        {loading ?
            <>
            {error ?
             <Message message={error} type={"error"} />
             : 
            <div className="cartable-action">
                <TabBar tabChange={tabChangeHandle} content={content} curentTab={curentTab} defaultTab={defaultTab} />
            </div>

        }
            </>
            :<Loading/>
        }
        </>

    );
}