import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getInvitedPeopleListById } from "./api";


export const InvitedPeopleList = ({meetingId, onHide,mode,JobId  }: any) => {

    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedId, setSelectedId] = useState<string | any>(null);
    const [showActionForm, setShowActionForm] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [filterlist, setFilterlist] = useState<any>("");
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);



    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true" && showActionForm){
            Toast(trans("modalclosemessage"),"warning","alert", ()=>{setSelectedId(obj.Id) ;sessionStorage.setItem("changingforms" , "false");});
        }else{
            setSelectedId(obj.Id);
        };
    }

 
    async function getInvitedPeopleListAsync() {
        setLoading(false)
        try {
            const res = await getInvitedPeopleListById( search ,JobId?JobId :meetingId);     
            setResponse(res);    
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
  
        setLoading(true)
    }
    useEffect(() => {
   getInvitedPeopleListAsync()

    }, [ forceUpdate ,filterlist,search ]);



 
    return (
        <>
            <div className="cartable-action">
                {loading ?
                    (response?.Data.length > 0 ?
                        <div className="card ">
                            <div className="p-0">
                                <>
                                    <DataGrid
                                        handlefiltering={(str)=>{setFilterlist(str)}}
                                        filterStr={filterlist}
                                        orderFormat={setOrderBy}
                                        orderFormatList={orderBy}
                                        parentName={"InvitedPeopleList"}
                                        theadData={Object.keys(response?.Data[0])}
                                        tbodyData={response?.Data}
                                        minCellWidth={80}
                                        startIndex={4}
                                        selectedRowObjectCallback={selectedIdCallback}                                        
                                    />
                                </>
                            </div>
                        </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    )
                    : <Loading />
                }

            </div>
        </>

    );
}