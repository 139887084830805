

import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, trans } from "utils/helpers";

import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { deleteCustomerGeneralRequestClassification, getAllCustomerGeneralRequestClassification, getCustomerGeneralRequestClassificationn, postCustomerGeneralRequestClassification, putCustomerGeneralRequestClassification } from "./api/api";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";


interface Form {
    Title: string,
    Status: number
}
export const CustomerGeneralRequestClassificationSetting = ({ }: Form) => {

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [flag, setFlag] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [formLoading , setFormLoading] = useState<boolean>(false)
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showForm, setShowForm] = useState(false);




    const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }
    const handleCreateAction = () => {
        setEditForm(null)
        setMode("create")
        setShowForm(true)

    }
    const handleEditRow = () => {
        setMode("edit");
        setShowForm(true)

       
    }
    const handleDeleteRow = async(id: string =selectedObj.Id) => {
        
        try {
            
            const res = await deleteCustomerGeneralRequestClassification(id)
            setForceUpdate(!forceUpdate)

        } catch (error) {
            
        }
    }
    const handleViewRow = () => {
        setMode("view")
        setShowForm(true)

      
    }

    const selectedIdCallback = (obj, index) => {
        setSelectedObj(obj);
    }

    const submit = (e) => {
        e.preventDefault();
        if (editForm == null) {
            postCustomerGeneralRequestClassification({                     
                IsDefault: e.target.checkbox.checked,

                Title: e.target.CustomerGeneralRequestClassification.value, 
                SystemKey: e.target.systemKey.value, 
            
            }).then(() => { getCustomerGeneralRequestClassificationAsync();cancel() });
        } else {
            putCustomerGeneralRequestClassification({
                  Title: e.target.CustomerGeneralRequestClassification.value,
                  ActivityStatus: +e.target.CustomerGeneralRequestClassificationSelect.value , 
                  systemKey: e.target.systemKey.value,
                  IsDefault: e.target.checkbox.checked,

                  Id: selectedObj.Id,
             }).then(() => { getCustomerGeneralRequestClassificationAsync();cancel() })
        }
    }

    function cancel() {
        if (editForm != null) {
            setEditForm(null);
            setResetForm(true);
        } 
        setShowForm(false)


    }
    async function getCustomerGeneralRequestClassificationAsync() {
        setLoading(false)
        try {
            const res = await getAllCustomerGeneralRequestClassification(search ,orderBy.length == 0 ?null:  orderBy.join(","));
            setResponse(res.Data);
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)
    }

    async function getCustomerGeneralRequestClassification() {
        setFormLoading(false)
        try {
            const data = await getCustomerGeneralRequestClassificationn(selectedObj.Id);
            setEditForm({ Title: data.Data.Title, Status: data.Data.ActivityStatus,systemKey: data.Data.SystemKey,  IsDefault: data.Data.IsDefault,
            });      
        } catch (error) {
            
        }
        setFormLoading(true)
    }

    useEffect(() => {
        getCustomerGeneralRequestClassificationAsync();
    }, [forceUpdate, flag  ,search , orderBy]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);
    useEffect(() => {
        if(showForm &&selectedObj && mode != "create"){
           getCustomerGeneralRequestClassification()
        }else{
            setFormLoading(true)
        }
       
    }, [selectedObj ,showForm ,mode]);




    if (flag) {
        getCustomerGeneralRequestClassificationAsync()
        setFlag(false)

    }

    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,1 ,trans("CustomerGeneralRequestClassificationSetting"));break;;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id)selectedObj.SystemReserved ? Toast(trans("Msg_CanNotEditSystemReservedData") , "error") : handleEditRow(); break;
            case "help": alert("call api help"); break;
            case "remove":if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow ); break;

        }
    };
    /* #endregion */

    return (
        <>
            <div className="cartable-action">

                <Toolbar handleSearch={searchHandler} remove={true}  id={selectedObj?.Id} emptyList={response == null || response?.length == 0} create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card">
                    <div className="">
                            <>
                                {showForm&& 
                                <>
                                  <div className="card mb-4">
                                {formLoading?
                                    <form className="TypeSettingForm  card-body " onSubmit={submit}>
                                        <div className="row">

                                        <div className="col-md-4 col-sm-12">
                                            <Textbox required={true} textboxName={"CustomerGeneralRequestClassification"} textboxType="text" resetForm={resetForm} labelText={"Description"} mode={mode}  defaultValue={editForm?.Title} />
                                        </div>     
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox required={true} textboxName={"systemKey"} textboxType="systemKey" resetForm={resetForm} labelText={"systemKey"} mode={mode}  defaultValue={editForm?.systemKey} />
                                        </div> 
                                        {mode != "create" &&          
                                         <div className="col-lg-4 ">
                                            <EnumerationTypeSelect
                                             label="ActivityStatus"
                                             name="CustomerGeneralRequestClassificationSelect" 
                                             mode={mode}
                                             enumTypeSelect="ActivityStatus"
                                             value={editForm?.Status}
                                            />
                                         </div>                            
                                         }
                                     </div>
                                       <div className="row">
                                        <div className="col-lg-2 mt-4 mb-4" >
                                       <CheckableButton
                                          defaultChecked={editForm?.IsDefault}
                                          defaultValue={editForm?.IsDefault}
                                          labelText={"isdefault"}
                                          inputName={"checkbox"}
                                          type="checkbox"
                                          mode={mode}
                                       />                                
                                       </div>    
                                        </div>    
                                        {  mode !="view" ?
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button value="Save" btnType="submit" />
                                              <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                                              </div>
                                              :
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                                              </div>
                                          }                        
                                    </form>
                                  :
                                  <Loading/>
                                }                           
                                  </div >
                                </>
                                }
                                {loading ? 
                                <>
                                {
                                response?.length > 0 ?
                                <>
                                    <DataGrid
                                     parentName={"CustomerGeneralRequestClassificationSetting"}
                                     orderFormat={setOrderBy}
                                     orderFormatList={orderBy}
                                     startIndex={1}
                                     theadData={Object.keys(response[0])}
                                     tbodyData={response}
                                     scrollHeight={showForm? "25vh": "67vh"}
                                     minCellWidth={80}
                                     selectedRowObjectCallback={selectedIdCallback}
                                     handleEdit={handleEditRow}
                                     handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,()=> handleDeleteRow(e))}}
                                     handleView={handleViewRow}
                                    />
                                </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                                }</>: <Loading />}

                            </>


                        
                    </div>
                </div>
            </div>
        </>

    );
}