import { DataGridEditable } from "components/DataGridEditable/DataGridEditable";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import {
  deleteCustomer,
  getCustomers,
  getEditablecolumns,
  putEditableList,
} from "./api";
import { CustomerTabform } from "./CustomerTabForm";

export const Customers = () => {
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState<number>(1);
  const [messageType, setMessageType] = useState<any>("info");
  const [response, setResponse] = useState<any>(null);
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [mode, setMode] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [processFilter, setProcessFilter] = useState<any>(0);
  const [search, setsearch] = useState(null);

  const [userChanges, setUserChanges] = useState({});

  const [filterAndSort, setFilterAndSort] = useState<any>(null);
  const [changedDataObj, setChangedDataObj] = useState<any>(null);
  const [editAllGrid, setEditAllGrid] = useState<any>(false);
  const [editablecolumns, setEditablecolumns] = useState(false);
  const [selectList, setSelectList] = useState<any>([]);
  const [resetMultiSelect, setResetMultiSelect] = useState<any>(false);
  let settings = getGridSetting("CustomersList");


  const searchHandler = (str) => {
    setPageNum(1);
    setSelectedObj(null);
    if (str === "") {
      setsearch(null);
      getCustomerssAsync(1, null);
    } else {
      setsearch(str);
      getCustomerssAsync(1, str);
    }
  };
  const handleChangePageSize = (e: any) => {
    setPageNum(1);
    setPageSize(e.currentTarget.value);
    getPageSize("CustomersList", e.currentTarget.value);
    getCustomerssAsync(1);
    // getDataAsync();
  };
  const handleGoPage = (value) => {
    if (value) {
      if (value < 1 || value > pages) {
      } else setPageNum(+value);
      getCustomerssAsync(+value);
    } else {
    }
  };
  const handleNextPage = () => {
    if (pageNum < pages) {
      setPageNum(pageNum + 1);
      getCustomerssAsync(pageNum + 1);
    }
  };
  const handlePrevPage = () => {
    if (pageNum > 1) {
      setPageNum(pageNum - 1);
      getCustomerssAsync(pageNum - 1);
    }
  };
  /* #endregion */
  /* #region dataGridActions */

  const selectedIdCallback = (obj, index) => {
    setSelectedObj(obj);
  };
  /* #endregion */

  const handleCreate = () => {
    setMode("create");
    setShowForm(true);
  };

  const handleEditRow = () => {
    setMode("edit");
    setShowForm(true);
  };

  const handleViewRow = () => {
    setMode("view");
    setShowForm(true);
  };

  async function handleDeleteRow() {
    try {
      await deleteCustomer(selectedObj.Id);
      setForceUpdate(!forceUpdate);
    } catch (err: any) {}
  }

  /* #region toolbarActions */
  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "print":
        alert("print ");
        break;
      case "excel":
        downloadCSV(response?.Data, 1, trans("Customers"));
        break;
      case "refresh":
        setForceUpdate(!forceUpdate);
        break;
      case "create":
        handleCreate();
        break;
      case "view":
        if (selectedObj.Id) {
          handleViewRow();
        } else {
          Toast(trans("Msg_SelectRow"), "warning");
        }
        break;
      case "edit":
        if (selectedObj.Id) handleEditRow();
        break;
      case "remove":
        if (selectedObj)
          Toast(
            trans("msg_deleteconfirm"),
            "warning",
            "alert",
            handleDeleteRow
          );
        break;
      case "help":
        alert("call api help");
        break;
      case "save":
        saveChangeingData();

    setFilterAndSort({ Filterlist: settings[0], OrderFormat: settings[1] });

        break;
      case "cancel":
        setEditAllGrid(false);
        setChangedDataObj(null)

    setFilterAndSort({ Filterlist: settings[0], OrderFormat: settings[1] });


        break;
    }
  };
  /* #endregion */

  async function getCustomerssAsync(PageNum = pageNum, searchSTR = search) {
    setLoading(false);
    setResponse(null);
    let pageSizeChangedByUser = getPageSize("CustomersList");
    setPageSize(pageSizeChangedByUser);
    try {
      await getEditablecolumnsAsync();
      const res = await getCustomers(
        PageNum,
        pageSizeChangedByUser,
        searchSTR,
        filterAndSort.OrderFormat?.join(","),
        filterAndSort.Filterlist
      );
      if (res.Data.Data.length > 0) {
        setResponse(res.Data);
        setPages(
          res.Data.TotalRecords > pageSizeChangedByUser
            ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser)
            : 1
        );
      }
    } catch (error) {}
    setLoading(true);
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };
  const selectListCallBack = (list) => {
    setSelectList(list[1]);
  };

  const changeingData = (data) => {
    if (data && Object.keys(data).length > 0) {
      setChangedDataObj(data);
    } else {
      setChangedDataObj(null);
    }
  };

  const getEditablecolumnsAsync = async () => {
    try {
      const res = await getEditablecolumns();
      setEditablecolumns(res);
    } catch (error) {}
  };



  const saveChangeingData = async () => {
    if (Object.keys(changedDataObj).length > 0) {
      let PutlistData: any = [];

      Object.keys(changedDataObj).map((item) => {
        let data = {
          Id: changedDataObj[item].Id,
          PriceListDiscountPercent:
            +changedDataObj[item].PriceListDiscountPercent,
          YearlySaleTarget: +changedDataObj[item].YearlySaleTarget,
        };
        PutlistData.push(data);
      });
      try {
        await putEditableList(PutlistData);

        setForceUpdate(!forceUpdate);
        setChangedDataObj(null);

        setEditAllGrid(false);
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (Object.values(userChanges).filter((item) => item != null).length > 0) {
      sessionStorage.setItem("changingforms", "true");
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    } else {
      sessionStorage.setItem("changingforms", "false");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [userChanges]);

  useEffect(() => {
    setSelectedObj(null);
    getCustomerssAsync();
  }, [forceUpdate, processFilter, filterAndSort]);

  useEffect(() => {
    setResponse(null);

    setFilterAndSort({ Filterlist: settings[0], OrderFormat: settings[1] });
  }, []);
  return (
    <>
      {showForm && (
        <ModalForm
          modalHeight={70}
          mode={mode}
          title={"Customer"}
          onHide={() => {
            setShowForm(false);
          }}
          show={showForm}
        >
          <CustomerTabform
            reload={() => setForceUpdate(!forceUpdate)}
            onHide={() => {
              setShowForm(false);
            }}
            selectedObj={selectedObj}
            mode={mode}
          />
        </ModalForm>
      )}
      <Toolbar
       
        create={true}
        save={changedDataObj != null? true : false}
        cancel={editAllGrid}
        remove={true}
        edit={true}
        view={true}
        handleSearch={searchHandler}
        id={selectedObj?.Id}
        emptyList={response?.TotalRecords == 0}
        search={true}
        handleClickToolbar={clickToolbarCallback}
      />
      <div
        style={{ overflowY: "auto", 
          height: "78vh",
           scrollbarWidth: "thin"}}
        className="col-lg-12 mt-0"
      >
        {loading ? (
          !!response?.Data?.length ? (
            <div className="card">
              <div className="card-body p-0">
                <DataGridEditable
                DoubleClick={()=>{handleViewRow()}}
                  RowKey={"Id"}
                  orderFormat={changedDataObj==null ? (order)=>{setFilterAndSort({...filterAndSort, OrderFormat:order})  }:undefined}
                  orderFormatList={filterAndSort.OrderFormat}
                  rowSelected={selectedObj}
                  changeingDataCallback={changeingData}
                  editAllGrid={editAllGrid}
                  editablecolumns={editablecolumns}
                  setEditAllGrid={setEditAllGrid}
                  multiSelect={true}
                  selectList={selectListCallBack}
                  resetMultiSelect={resetMultiSelect}
                  mode={mode}
                  handleDelete={handleDeleteRow}
                  showForm={showForm}
                  columnsWithOption={["Price"]}
                  parentName={"customer"}
                  startIndex={1}
                  theadData={Object.keys(response.Data[0])}
                  tbodyData={response.Data}
                  minCellWidth={80}
                  selectedRowObjectCallback={selectedIdCallback}
                  totalRecords={response.TotalRecords}
                  pageNum={pageNum}
                  pageSize={pageSize}
                  HandleNextPage={handleNextPage}
                  HandlePrevPage={handlePrevPage}
                  handlechangePageSize={handleChangePageSize}
                  first={() => {
                    getCustomerssAsync(pages);
                    setPageNum(pages);
                  }}
                  end={() => {
                    getCustomerssAsync(1);
                    setPageNum(1);
                  }}
                  handleGoPage={handleGoPage}
                  dependsValue={["OrderPoint", "HasOrderPoint"]}
                />
              </div>
            </div>
          ) : (
            <Message message={"NoDataForPreview"} type={messageType} />
          )
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};
