import { InputHTMLAttributes, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { Textbox } from "components/Textbox/Textbox";
import { ICON_TYPE } from "utils/constants";


interface KeyWordProp extends InputHTMLAttributes<HTMLInputElement> {
  setKeyWords: (list) => void;
  label?: string;
  name: string;
  value?: any;
  mode?: any;
  keyWords?: any;
  required?: boolean;
  Change?: (e) => void;
  recordedData?: any;

  
}
export const KeyWord = ({ setKeyWords,Change, label="keywords", name, value,keyWords, mode ,required,recordedData=[] }: KeyWordProp) => {
  
  const [resetForm, setResetForm] = useState<any>(false);
  const [change, setChange] = useState<any>(false);
  const [selectedItem, setSelectedItem] = useState<any>(keyWords ?keyWords : []);


  function addOption(e) {
    e.stopPropagation()
    e.preventDefault()
    let event:any = document.getElementById("KeyWordForm")
    if(event["Keyword"].value.length > 0) {
    setSelectedItem(selectedItem.concat([event["Keyword"].value])) 
    setKeyWords(selectedItem.concat([event["Keyword"].value])) 
    setResetForm(true)
    setChange(false)
    }
  }
  function DelOption(e) {
    let list:any=[] 
    selectedItem.forEach(item => {
        if(item != e){
            list.push(item)
        }
    });    
    setSelectedItem(list) 
    setKeyWords(list) 
  }





  useEffect(() => {
    if(resetForm){
        setResetForm(false)
    }
   
  }, [resetForm]);
  
  useEffect(() => {
    if(keyWords  && recordedData){
      setSelectedItem(keyWords)
      if(recordedData.length == selectedItem.length){
        let flag = true
        recordedData.forEach(element => {
          if(!selectedItem.includes(element))
            flag=false 
        });
        !flag? Change&&Change({[name] :selectedItem}):Change&&Change({[name] :null});
      }else{
        Change&&Change({[name] :selectedItem})
      }
    }
  }, [keyWords]);
  return (
    <>
            <>
            { 
              mode != "view" ? 
            <form id="KeyWordForm" onSubmit={addOption} style={{display:"flex", alignItems:"center" , flexDirection:"row",width:"100%"}}  >
              <div style={{width:"90%"}} >
              <input  value={selectedItem.join(",")} name="KeyWords" className="d-none" onChange={(e)=>{}} />

              <Textbox
                 required={required}
                  Change={setChange}
                  textboxType="text"
                  textboxName="Keyword"
                  labelText={label}
                  maxLength={900}
                  resetForm={resetForm}
                  mode={mode}
               />              
              </div>
              <div  style={{marginTop: label.length >1 ?"4px" :"-7px" , width:"10%"}}>
               <Button Icon={true} value={<button onClick={addOption} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "check"]}  size="2x"  /></button>}  btnType="submit" onClick={addOption}   />
              </div>             
             </form>
             :
             <Textbox
              textboxType="text"
              textboxName="Keyword"
              labelText={label}
              maxLength={900}
              defaultValue={selectedItem.join(",")}
              resetForm={resetForm}
              mode={mode}
           />   



            }
            {
              mode !== "view" &&
              <div className="group-person__selected-options-wrapper pt-0 ">
              { selectedItem.map((item, index) => (
                item.length >0 &&
                <div className="group-person__selected-option form-control-custom" >
                {item}
                <div className="group-person__delete-selected-option" onClick={()=>DelOption(item)} >
                    <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                </div>

                
            </div>
             ))}
           </div>

            }
            </>
    </>
  );
};
