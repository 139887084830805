import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getListBusinessActivityType = async (
  searchString,
  orderByString,
  pageNumber,
  pageSize
) =>
  await http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
      .BusinessActivityType.browse,
    method: "get",
    params: {
      OrderByString: orderByString,
      SearchString: searchString,
      PageNumber: pageNumber,
      PageSize: pageSize,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getBusinessActivityType = async (Id) =>
  await http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
      .BusinessActivityType.load,
    method: "get",
    params: { Id: Id },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const postBusinessActivityTypeApi = (data) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
      .BusinessActivityType.browse,
    method: "post",
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const putBusinessActivityTypeApi = (data) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
      .BusinessActivityType.browse,
    method: "put",
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const deleteListBusinessActivityType = (id) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
      .BusinessActivityType.browse,
    method: "delete",
    params: {
      id: id,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
