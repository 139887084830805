import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";


export const getAllDeviceProperties = (id,withValue) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.Properties  ,
    method: "get",
   params:{
   id:id
   }
}).then((res) => {
   return res.data
}).catch((err) => {
   return Promise.reject(err.response  );
});
export const sendDevicePropertiesValue = (list,method) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemOtherPropertyValue.browse,
    method: method,
    data:list
}).then((res) => {
   return res.data
}).catch((err) => {
   return Promise.reject(err.response  );
});
