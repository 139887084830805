import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { TableTree } from "components/TableTree/TableTree";
import { useEffect, useState } from "react";

import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { Button } from "components/Button/Button";
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";
import { getWarehouseTree } from "./api";
interface Prop {
  WarehouseIdHandeler: (e) => void,
  onHide: () => void,
  
}



export const WarehouseTreeForm = ({ WarehouseIdHandeler }: Prop) => {
  const [data, setData] = useState<any>()
  const [forceUpdate, setForceUpdate] = useState<boolean>(false)
  const [selectedObj, setSelectedObj] = useState<any>()
  const [idCallback, setIdCallback] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<any>("NoDataForPreview");


 
  const setselecetrow = (e) => {
    setSelectedObj(e)


  }
  const sendTreeCode = (e:any=null , obj= selectedObj) => {
    e?.preventDefault()
    e?.stopPropagation()
      if(obj){
        let parentObj:any=null
        if(obj.RootId!=null){
            data.map(item=>{
                if(item.Id ==obj.RootId ){
                    parentObj = item
                }
            })

        }
        if(parentObj){
            WarehouseIdHandeler({...obj , Title: parentObj.Title +","+obj.Title })
        }else{
            WarehouseIdHandeler(obj)

        }
      }else{
        Toast(trans("Msg_SelectRow"), "warning")
      }


  }



  async function getWarehouseTreeFormAsync() {
    setLoading(false)
    try{

      const respons = await getWarehouseTree()
      setData(respons.Data)
    }catch(err:any){
      setMessage(err?.data?.Message)
    }
    setLoading(true)
  }


  

  useEffect(() => {
    setData(null)
    getWarehouseTreeFormAsync()
  }, [forceUpdate]);


  useEffect(() => {
    if(idCallback){
      setIdCallback(null)
    }
  }, [idCallback]);



    return (
     <>


        <div style={{overflowY: "auto", 
          height: "70vh",
           scrollbarWidth: "thin"}}>
            <div className="col-lg-12 mt-1 btns_form">
            <Button value="select" btnType="submit" onClick={(e)=>{sendTreeCode(e)}}/>
            <Button btnType="cancel" value="Cancel"  />
            </div>
            {
              loading ? (
                data ?
                <div>
                  <TableTree
                    baseUrl={INVENTORYANDSALES_URL}
                    selectedRowObjectCallback={setselecetrow}
                    DoubleClick={(e)=>{sendTreeCode(null,e)}}
                    selectedRowObject={idCallback}
                    getChildrenUrl={ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.getNode}
                    theadData={Object.keys(data[0])}
                    startIndex={4}
                    maxLevel={1}
                    Titleparams={"Title"}
                    Childrenparams={'DetailList'}
                    defultShow={false}
                    Data={data}
                    parentName={"WarehouseTreeForm"}
                  /> 
                </div>: <Message message={message} type={"error"} />)
                : <Loading />
            }
        </div>



      </>
    )
}




