
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toast } from "components/ToastShow/ToastShow";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toolbar } from "components/Toolbar/Toolbar";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { getUsers, removeUser, resetLockoutAsync, resetPasswordAsync } from "pages/SystemManagement/Settings/Users/api";
import { useContext, useEffect, useState } from "react";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { TreeUserRole } from "./TreeUserRole/TreeUserRole";
import { UserForm } from "./User/UserForm";

export const UserList = () => {

    const [pages ,setPages] = useState<any>(1)
    let customFuction: any = []
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [creatType, setCreatType] = useState<any>();
    const [response, setResponse] = useState<any>([]);
    const [selectedId, setSelectedId] = useState<string | any>(null);
    const [personId, setpersonId] = useState<string | any>(null);
    const [showForm, setShowForm] = useState(false);
    const [showUserRoleForm, setShowUserRoleForm] = useState(false);
    const [mode, setMode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const [organizationTypeFilter, setOrganizationTypeFilter] = useState<number>(0);
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [filterlist, setFilterlist] = useState<any>("");
    const layoutContext = useContext(LayoutContext);

    customFuction.push([(e) => { resetPassword(e.Id) }, "undo", "ResetPassword"])
    customFuction.push([(e) => { ResetLockout(e.Id) }, "lock-open", "ResetLockout"])
    
    /* #endregion */
    /* #region pagination */

    const resetPassword = async(str) => {
        try {
            const res = await resetPasswordAsync(str)
            
        } catch (error) {
            
        }
      }
    const ResetLockout = async(str) => {
        try {
            const res = await resetLockoutAsync(str)
            
        } catch (error) {
            
        }
      }
      const searchHandler = (str) => {
        setPageNum(1);
        if (str == "") {
            setSearch(null)
            getUsersAsync(1, null )

        } else {
            setSearch(str)
            getUsersAsync(1 ,str)
        }
    }

    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(+e.currentTarget.value)
        getPageSize("UserList" , e.currentTarget.value)
        getUsersAsync(1);
    };
    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else {setPageNum(+value);getUsersAsync(+value )}
        } else {
        }
    };
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getUsersAsync(pageNum + 1);
          }

    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum(pageNum - 1);
            getUsersAsync(pageNum - 1);
          }  };
    /* #endregion */
    /* #region dataGridActions */

    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedId(obj) ; setpersonId(obj.Id);sessionStorage.setItem("changingforms" , "false");});
        }else{
            setSelectedId(obj);
            setpersonId(obj.Id)     
        };

    }
    /* #endregion */


    const handleEditRow = () => {
        setShowUserRoleForm(true);
    }
    const handleDeleteRow = async () => {
        try {
            const res = await removeUser(selectedId.Id)
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }

    }
    const handleViewRow = () => {
        setMode("view");
        setShowForm(true)
    }
    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel": downloadCSV(response?.Data ,2,trans("UserList")); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "view": if (selectedId) handleViewRow(); break;
            case "edit": if (selectedId) handleEditRow(); break;
            case "remove": if (selectedId)Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow); break;
            case "help": alert("call api help"); break;
            case "assignrule":if(selectedId)handleEditRow();break;
        }
    };
    /* #endregion */
   async function getUsersAsync(PageNum = pageNum ,searchSTR=search) {
    setLoading(false)
    setResponse(null)
    let pageSizeChangedByUser = getPageSize("UserList" )
    setPageSize(pageSizeChangedByUser)
        try {
            const res = await getUsers(PageNum ,pageSizeChangedByUser, organizationTypeFilter ==0 ? null :organizationTypeFilter ,searchSTR  ,orderBy.length == 0 ?null:  orderBy.join(",") ,filterlist)    
            if(res.Data.Data.length > 0){
                setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) 
                setResponse(res.Data)
                setMessageType("info")
                }
       } catch (error) {
        
       }
       setLoading(true)

    }
   async function handleSelectedcreateUser(e) {
    if(e=="create"){
        setCreatType(1)
    }else{
        setCreatType(2)
    }
    setMode("create");
    setShowForm(true);
    }

    useEffect(() => {
        let settings = getGridSetting("UserList" )
        setOrderBy(settings[1])
        setFilterlist(settings[0])
        
      }, []);
    useEffect(() => {
        setResponse(null)
        getUsersAsync();
    }, [forceUpdate,search,orderBy,filterlist,organizationTypeFilter]);

    return (
        <>
            <div  className="cartable-action p-2">
                <Toolbar handleSelectedcreateUser={handleSelectedcreateUser} remove={true} view={false}  handleSearch={searchHandler} assignRule={true}  handleSelectedFilterProcess={setOrganizationTypeFilter}  id={selectedId} emptyList={response?.TotalRecords == 0}  search={true} handleClickToolbar={clickToolbarCallback} />
                {showForm &&
                <ModalForm mode={mode}  title={trans("UserForm") } onHide={() => { setShowForm(false) }} show={showForm}>
                    <UserForm creatType={creatType} hide={()=>{setShowForm(false)}} reload={()=>{setForceUpdate(!forceUpdate)}} personId={personId} userId={selectedId?.UserId} mode={mode} />
                </ModalForm>
                }
                {
                    showUserRoleForm &&
                    <ModalForm mode={mode} modalProfileKey={"Users"} title={trans("AssignedAccessRoles") } onHide={() => { setShowUserRoleForm(false) }} show={showUserRoleForm}>
                        <TreeUserRole  personId={selectedId.PersonId} />
                    </ModalForm>

                }
                {loading ?
                    (response?.Data?.length > 0 ?
                        <div className="card" style={{overflowY: "auto",
                             height: "74vh",
                            scrollbarWidth: "thin",}}>
                            <div className=" p-0">
                                <>  
                                    <DataGrid
                                        customFuction={customFuction}
                                        handlefiltering={(str)=>{setFilterlist(str)  }}
                                        filterStr={filterlist}
                                        orderFormat={setOrderBy}
                                        orderFormatList={orderBy}
                                        parentName={"UserList"}
                                        theadData={Object.keys(response.Data[0])}
                                        tbodyData={response.Data}
                                        minCellWidth={80}
                                        selectedRowObjectCallback={selectedIdCallback}
                                        startIndex={2}
                                        handleDelete={handleDeleteRow}
                                        totalRecords={response.TotalRecords}
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        HandleNextPage={handleNextPage}
                                        HandlePrevPage={handlePrevPage}
                                        handlechangePageSize={handleChangePageSize}
                                        first={() => { getUsersAsync(pages); setPageNum(pages) }}
                                        end={() => { getUsersAsync(1); setPageNum(1) }}
                                        handleGoPage={handleGoPage}
                                    />

                                </>
                            </div>
                        </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />

                    )

                   : <Loading />
                }

            </div>
        </>

    );
}