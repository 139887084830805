

import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { DynamicFormDataEntry } from "../DynamicFormDataEntry/DynamicFormDataEntry";
import { deleteInformationForm, getFormInformationCompleted, getFormInformationFormkey } from "./api";


interface FormProps {
    formObj?: any,
}
export const DynamicFormData = ({formObj }: FormProps) => {
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showEventForm , setShowEventForm] = useState(false)
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pages, setPages] = useState<number>(0);
    const [filterlist, setFilterlist] = useState<any>("");
    const [mode, setMode] = useState<'create' | 'view' | 'edit'>("create");
    const [inputs,setInputs] = useState<any>([])
    const [informationForm,setInformationForm] = useState<any>({})
    const [startIndex,setStartIndex] = useState<any>({})
    const [translatedHeader,setTranslatedHeader]=useState<any>(null)




    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("DynamicFormData"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "help": alert("call api help"); break;
            case "remove": if (selectedObj?.Id) Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "create": handleCreateAction(); break;
            case "edit": if (selectedObj?.Id) handleEditRow(); break;


        }
    };

    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("DynamicForm" ,e.currentTarget.value )
        getFormInformationCompletedAsync(1)
      };

    const handleGoPage = (value) => {
      if (value) {
        if (value < 1 || value > pages) { }
        else setPageNum(+value);getFormInformationCompletedAsync(+value);
              } else {

      }
    };
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getFormInformationCompletedAsync(pageNum + 1);
          };
        };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getFormInformationCompletedAsync(pageNum - 1);
          };
          };

    const handleDeleteRow =async () => {
        await deleteInformationForm(selectedObj.Id,formObj.FormKey)
        try {
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }

    }


    const handleCreateAction = () => {
        setMode("create")
        setShowEventForm(true)
    }

    const handleViewRow = () => {
        setMode("view")
        setShowEventForm(true)
      
    }

        const handleEditRow = () => {
        setMode("edit");
        setShowEventForm(true)
       
    }


    const cansel = ()=>{
        setShowEventForm(false)
        
    }

    const selectedObjCallback = (obj, index) => {
        setSelectedObj(obj);
    }
    const SearchHandler = (str) => {
        setPageNum(1);
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }


    async function getFormInformationCompletedAsync(PageNum = pageNum) {
        setLoading(false)
        setResponse(null)
        let pageSizeChangedByUser = getPageSize("DynamicForm" )
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getFormInformationCompleted(PageNum,  pageSizeChangedByUser,search ,orderBy.length == 0 ?null:  orderBy.join(",")  , filterlist,formObj.FormKey);
            let  start = 1
            Object.keys(res.Data.Data[0]).map(item=>{
                if(item.includes("Id")||item.includes("UID")){
                    start +=1
                }
            })
            setStartIndex(start)
            setResponse(res.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }
    
    async function getFormInformationFormkeydAsync(){
        setLoading(false)
        try {
            const res=await getFormInformationFormkey(formObj.FormKey)
            setTranslatedHeader(JSON.parse(res.Data.ObjectLabels))
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }

    useEffect(() => {
        getFormInformationCompletedAsync();
        getFormInformationFormkeydAsync()
    }, [forceUpdate]);
    return (
        <>   

            {showEventForm ? <ModalForm modalHeight={50} mode={mode} onHide={()=>cansel()} show={showEventForm} title={selectedObj?.FormName} >
                <DynamicFormDataEntry formKey={formObj.FormKey} id={selectedObj?.Id} mode={mode} onHide={()=>{setShowEventForm(false);setForceUpdate(!forceUpdate)}}/> </ModalForm> : null}
            <div className="cartable-action">
            <Toolbar emptyList={response?.TotalRecords==0} remove={true} id={selectedObj?.Id} create={false} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                    <div className="">
                        {loading ?
                            <>
                                {
                                    response?.Data.length > 0 ?
                                    <DataGrid
                                    translatedHeader={translatedHeader}
                                    handlefiltering={(str)=>{setFilterlist(str)  }}
                                    filterStr={filterlist}
                                    parentName={"CustomerFormInformation"}
                                    orderFormat={setOrderBy}
                                    orderFormatList={orderBy}
                                    startIndex={startIndex}
                                    theadData={Object.keys(response?.Data[0])}
                                    tbodyData={response.Data}
                                    minCellWidth={80}
                                    selectedRowObjectCallback={selectedObjCallback}
                                    handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                    totalRecords={response?.TotalRecords}
                                    pageNum={pageNum}
                                    pageSize={pageSize}
                                    HandleNextPage={handleNextPage}
                                    HandlePrevPage={handlePrevPage}
                                    handlechangePageSize={handleChangePageSize}
                                    first={() => {getFormInformationCompletedAsync(pages) ; setPageNum(pages)}}
                                    end={() => {getFormInformationCompletedAsync(1) ; setPageNum(1)}}
                                    handleGoPage={handleGoPage}
                                />
                                        : <Message message={"NoDataForPreview"} type={messageType} />
                                }
                            </>
                            : <Loading />
                        }
                    </div>
                </div>
            </div>
        </>

    );
}



