import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { TreeView } from "components/TreeView/TreeView";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { ReferenceHistory } from "pages/SystemManagement/Jobs/components/RefrenceHistory/ReferenceHistory";
import { useContext, useEffect, useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { deleteJobFileArchive, getJobFileArchive, getPersonArchive, getViewForm } from "./api";
import { DynamicComponentHOC } from "components/DynamicComponentHOC/DynamicComponentHOC";
import { AttachmentViewer } from "pages/Secretariats/Archive/ArchiveTree/components/AttachmentViewer/AttachmentViewer";
import { DYNAMICFORMS } from "utils/dynamicForms";
import { JobList } from "./components/JobList/JobList";
import { GUID_EMPTY } from "utils/constants";

  

export const PersonalArchiveTree = () => {

  const [data, setData] = useState<any>()
  const [forceUpdate, setForceUpdate] = useState<boolean>(false)
  const [selectedObj, setSelectedObj] = useState<any>()
  const [idCallback, setIdCallback] = useState<any>(null)
  const [mode, setmode] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false);
  const [gridLoading,setGridLoading] = useState<boolean>(false);
  const [PersonArchiveForm, setPersonArchiveForm] = useState<boolean>(false);
  const [archiveFiles, setArchiveFiles] = useState<any>();
  const [selectedList, setSelectedList] = useState<any>([])
  const [resetCheckBox, setResetCheckBox] = useState<any>(false);
  const [jobSelected, setJobSelected] = useState<any>(null);
  const [showHistory, setShowHistory] = useState(false);  
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [pages, setPages] = useState<number>(0);
  let [showDynamicFormView, setShowDynamicFormView] = useState<boolean>   (false);
  const [ShowDynamicFormViewLoading, setShowDynamicFormViewLoading] = useState(false);

  let [dynamicForm, setDynamicForm] = useState< any>("GeneralRequestForm");

  const layoutContext = useContext(LayoutContext);    


  let customFuction: any = [
    [(e) => { setJobSelected(e); setShowHistory(true) }, "clipboard", "History"],
    [(e) => { handleViewRow(e) }, "eye", "View"],
]

const handlechangePageSize = (e: any) => {
  setPageNum(1);
  setPageSize(e.currentTarget.value);
  getPageSize("PersonalArchiveStructure", e.currentTarget.value)
  getJobFileArchiveAsync(selectedObj,1);
};
const handleGoPage = (value) => {
  if (value) {
    if (value < 1 || value > pages) { }
    else setPageNum(+value);getJobFileArchiveAsync(selectedObj,+value);
  } else {
  }
};
const HandleNextPage = () => {
  if(pageNum < pages){
      setPageNum(pageNum + 1);
      getJobFileArchiveAsync(selectedObj,pageNum + 1);

  }
};
const HandlePrevPage = () => {
  if (pageNum > 1){
      setPageNum((pageNum) => { return +pageNum - 1 });
      getJobFileArchiveAsync(selectedObj,pageNum - 1);


  }
};

async function getFormViewAsync(id: string) {
  setShowDynamicFormView(true);
  setShowDynamicFormViewLoading(false)
  try {
      const res = await getViewForm(id, layoutContext.currentUserPosition.PositionId);
      if (res.Data)
          setDynamicForm(res.Data);
      else
          setDynamicForm(undefined);
  } catch (error) {
      setDynamicForm(undefined);
      setShowDynamicFormView(false)
  }
  setShowDynamicFormViewLoading(true)

}

const showDynamicFormViewCallback = (ans: boolean) => {
  setShowDynamicFormView(ans);
}

  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "reference":alert("call api reference with Id :");break;
      case "print":alert("print ");break;
      case "remove":break;
      case "excel":downloadCSV(archiveFiles?.Data ,5 ,trans("PersonalArchiveTree"));break;
      case "refresh":setForceUpdate(!forceUpdate);break;
      case "view": if(selectedObj){setmode("view");setPersonArchiveForm(!PersonArchiveForm)}else Toast(trans("Msg_SelectRow"), "warning"); break;
      case "archive": if(selectedObj){setmode("create");setPersonArchiveForm(!PersonArchiveForm);}else Toast(trans("Msg_SelectRow"), "warning"); break;
      case "edit": if (selectedObj ){setmode("edit");setPersonArchiveForm(!PersonArchiveForm);}else Toast(trans("Msg_SelectRow"), "warning");  break;
    }
  };

  async function DeleteRow(id){
    try {
        await  deleteJobFileArchive(id)
        getJobFileArchiveAsync(selectedObj);
    } catch (err: any) {
     
    } 
  }
 
  const handleDeleteRow = (id: string) => {
    Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{DeleteRow(id)}) 
  }

  const handleViewRow = (obj) => {
    getFormViewAsync(obj.PRJ_UID)

  }

  const setselecetrow = (e) => {
    getJobFileArchiveAsync(e)
    setSelectedObj(e)
  }



  async function getPersonalArchiveTreeAsync() {
    setLoading(false)
    const respons = await getPersonArchive(null)
    setData(respons.Data)
    getJobFileArchiveAsync(respons.Data[0])
    setLoading(true)
  }

  async function getJobFileArchiveAsync(e ,pagNum = pageNum) {
    setGridLoading(false);
    if(e?.RootId !=null){
      let pageSizeChangedByUser = getPageSize("PersonalArchiveStructure")
      setPageSize(pageSizeChangedByUser)
      try {
        const respons = await getJobFileArchive(e.Id ,pagNum,pageSizeChangedByUser)
        setArchiveFiles(respons.Data)
        if(respons.Data.Data[0].JobId !=GUID_EMPTY){
          setJobSelected(respons.Data.Data[0])
      }
      setPages(respons.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(respons.Data.TotalRecords / pageSizeChangedByUser) : 1) ;     
    } catch (error) {

    }
  }
  setGridLoading(true);
}

  async function reloadHandler() {
   let list =  selectedList.length > 0 ?selectedList :[ selectedObj]
   let flag = false
   list.forEach(item => {
     if(item.Id == selectedObj.Id){
       flag = true
      }
   });
   if(flag){
    let pageSizeChangedByUser = getPageSize("PersonalArchiveStructure")
    setPageSize(pageSizeChangedByUser)
    const respons = await getJobFileArchive(selectedObj.Id,pageNum,pageSizeChangedByUser)
    setArchiveFiles(respons.Data)
    setPages(respons.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(respons.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
  }
   setResetCheckBox(!resetCheckBox)
  }


  useEffect(() => {
    setData(null)
    setArchiveFiles(null)
    getPersonalArchiveTreeAsync()
  }, [forceUpdate]);

  useEffect(() => {
    if(idCallback){
      setIdCallback(null)
    }
  }, [idCallback]);

    return (
     <>
        <Toolbar 
          emptyList={data?.length == 0} 
          handleClickToolbar={clickToolbarCallback} 
          archive={true}
          id={selectedObj} 
          view={false}
          create={false} 
          search={false} 
        />
      <div>
        <div style={{display:"flex" , flexDirection:"row" , maxHeight:"50vh" , height:"50vh" }}  className="">
            {
              loading ? (
                data ?
                <div style={{maxWidth:archiveFiles?.Data ? "30% ":"unset"  }} >
                  <TreeView
                    maxWidth={true}
                    scrollHeight={"51vh"}
                    resetCheckBox={resetCheckBox}
                    IsArchive={false}
                    multiselect={true}
                    selectedRowObjectCallback={setselecetrow}
                    selectedRowObject={idCallback}
                    getChildrenUrl={ENDPOINTS.archive.personalArchive.archives}
                    theadData={["Id" , "ParentId", "DetailList" , "archivetree" ]}
                    startIndex={2}
                    Titleparams={"Title"}
                    Childrenparams={'DetailList'}
                    defultShow={false}
                    Data={data} 
                    parentName={"PersonalArchive"}
                    selectedRowsCallback={setSelectedList}

                  />
                </div>: <Message message={"NoDataForPreview"} type={"error"} />)
                : <Loading />
            }
            {gridLoading ?
            <>
              {archiveFiles &&
              <div style={{overflowX: "auto" , backgroundColor:"white" , height:"54vh"}}>
                <DataGrid 
                  parentName={"jobArchive"}
                  customFuction={customFuction}
                  startIndex={5}
                  totalRecords={archiveFiles?.TotalRecords}
                  theadData={Object.keys(archiveFiles?.Data[0])}
                  tbodyData={archiveFiles?.Data}
                  scrollHeight={"46vh"}
                  handleDelete={handleDeleteRow}
                  minCellWidth={90}
                  selectedRowObjectCallback={(obj)=>{setJobSelected(obj)}}
                  pageNum={pageNum}
                  pageSize={pageSize}
                  HandleNextPage={HandleNextPage}
                  HandlePrevPage={HandlePrevPage}
                  handlechangePageSize={handlechangePageSize}
                  first={() => { getJobFileArchiveAsync(selectedObj,pages); setPageNum(pages) }}
                  end={() =>{getJobFileArchiveAsync(selectedObj,1); setPageNum(1) }}
                  handleGoPage={handleGoPage}
                 />
              </div>
             }
            </> 
            : 
            <Loading />
            }

        </div>
          {jobSelected?.JobId && 
          <AttachmentViewer ownerId={jobSelected?.JobId} inPersonal={true} />
          }
          {showDynamicFormView &&
                  <ModalForm mode={mode} modalHeight={70} title={ShowDynamicFormViewLoading ?(dynamicForm.Name) :""} onHide={() => { setShowDynamicFormView(false); }} show={showDynamicFormView}>                       
                      {ShowDynamicFormViewLoading ? 
                          <>
                      
                       <DynamicComponentHOC  onHide={() => { setShowDynamicFormView(false) }} 
                        componentName={DYNAMICFORMS[dynamicForm.Name] ?null:dynamicForm.Name} component={DYNAMICFORMS[dynamicForm.Name] ?DYNAMICFORMS[dynamicForm.Name].component:null}
                           reload={(e)=>{setForceUpdate(!forceUpdate)}} Name={dynamicForm.Name} modeParams={"view"} componentParams={dynamicForm.Parameters} processId={jobSelected.Prg}
                           Id={jobSelected.SourceId}handleCancelForm={() => {showDynamicFormViewCallback(false)}} />
                        
                           </>
                        : <Loading/>
                      }
                  </ModalForm>
          }
                  {showHistory &&
            <ModalForm mode={mode} modalHeight={55} title={("ReferenceHistory")} onHide={() => {setJobSelected(null); setShowHistory(false) }} show={showHistory}>
                <ReferenceHistory jobId={jobSelected?.JobId} />
            </ModalForm>
        }

        {
          PersonArchiveForm &&
            <ModalForm mode={mode} modalHeight={75} title="JobList" onHide={() => setPersonArchiveForm(false)} show={PersonArchiveForm}>
                <JobList
                  ArchiveList ={selectedList.length > 0 ?selectedList :[ selectedObj] }
                  onHide={()=>{reloadHandler(); setPersonArchiveForm(false)}} 
                />
            </ModalForm>
        }

      </div>


        
      </>
    )
}




