import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { trans } from "utils/helpers";
import './style.scss'

interface props {
  content: any[];
  defaultTab: string;
  curentTab?: string;
  disabled?: boolean;
  disableTab?: any;
  disableIndex?: any;
  icon?:any;
  showWithIcon?:boolean;
  addDisable?:boolean;
  tabChange: (ans: any) => void,
  Click?: (ans: any) => void,
}
export const TabBar: React.FC<props> = ({addDisable,content,defaultTab,curentTab,disabled,disableTab,disableIndex,icon,showWithIcon=false,tabChange,Click}: props) => {


  
  return (
    <Tabs
      
      defaultActiveKey={defaultTab}
      activeKey={curentTab}
      id="uncontrolled-tab-example"
      // style={{marginTop:"-12px"}}
      className="tabs"
      
      onSelect={(e ) => {
        tabChange(e);
      }}

    >
        {content.map((item, index) => {
            if (disabled) {
              if (index <= 1) {
                return (<Tab
                  onClick={(e) => {return false }}
                  disabled={disabled ? index > 1 : false}
                  key={index}
                  eventKey={item.title}
                  title={trans(item.title)}
                  className=""

                >
                  {item.body}
                </Tab>)
              }else{
                return (<Tab
                  onClick={(e)=>{if(disableTab ?disableTab === item.title : false){Click && Click(e)} }}
                  disabled={disabled ? (disableIndex ? index > disableIndex :  index > 1  ) :(disableTab ?disableTab ==item.title : false )}
                  key={index}
                  eventKey={item.title}
                  title={trans(item.title)}
                  className=""
                >
                  {item.body}
                </Tab>)
              }

            } else {
              return (<Tab
                onClick={(e)=>{if(disableTab ?disableTab ==item.title : false){Click && Click(e)} }}
                disabled={ addDisable ? item.disable:(disabled ? index > 1 :(disableTab ?disableTab ==item.title : false ))} 
                key={index}
                eventKey={item.title}
                title={showWithIcon  ? curentTab != item.title ? item.icon : trans(item.title) :trans(item.title)}
                className=''
              >
                {item.body}
              </Tab>)
            }
          }
        )}
    </Tabs>
  );
};
