import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { ICON_TYPE } from "utils/constants"
import { trans } from "utils/helpers"
import { getPersonnelCartableDictionaryApi } from "./api"
import "./style.scss"

interface ButtonToolbarProps {
    onClick?:()=>void,
    handleChangePersonnelCartable?:(e)=>void,
    disable?:any,

}
export const ButtonToolbarPersonnelCartable: React.FC<ButtonToolbarProps> = ({

    onClick,
    handleChangePersonnelCartable,
    disable,

}) => {
    let profile = { isBeginner: true };
  const buttonToolbar = useRef(null);
   
    const [options, setOptions] = useState<any>(null)
    const [loading, setLoading] = useState<any>()
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [rotate , setRotate] = useState<boolean>(false);

    const [Personnel, setPersonnel] = useState< any>();




      const getPersonnelCartableDictionaryAsync = async ()=>{
        setLoading(false)
        try {
        const res = await getPersonnelCartableDictionaryApi()
        let list:any =[]
        Object.keys(res.Data).map(item=>{
            list.push({
                Id:item,
                Name:res.Data[item],
            })
        })
        setOptions(list)
        handleChangePersonnelCartable&& handleChangePersonnelCartable({Id: list[0].Id , Name: list[0].Name})
        setPersonnel({Id: list[0].Id , Name: list[0].Name})
        } catch (error:any) {
            handleChangePersonnelCartable&& handleChangePersonnelCartable(" ")
          
            setOptions(null)
        }
        setLoading(true)
      }

      function selectboxValSetter(obj) {
        setPersonnel(obj)
        handleChangePersonnelCartable && handleChangePersonnelCartable(obj);
        setShowSubMenu(false)
        if (buttonToolbar.current) (buttonToolbar.current as HTMLButtonElement).blur();
    }
      useEffect(() => {
        if(Personnel ==null){
            getPersonnelCartableDictionaryAsync()
        }

        },[])

    return (
        <>
        {options!=null&&
        <div   className={"toolbar-btn-wrapper"}>
            <button ref={buttonToolbar} onFocus={(e)=>{setShowSubMenu(true)}} onBlur={()=>{setShowSubMenu(false)}}  className={`btn ${disable ? 'btn-outline-secondary' : 'btn-outline-primary'}  toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center bigBtn`} role="button" aria-disabled={disable} disabled={disable} >
                <div style={{fontWeight:"600"}} className={`toolbar-btn__hotkey ${disable ? "text-secondary" : ""} ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")} mb-0`}>کارتابل جاری</div>
                <FontAwesomeIcon icon={[ICON_TYPE , "angle-down"]} />   
                <div className="showDots bigBtn">
                    <small className="m-2 mt-0">{Personnel?.Name}</small>
                </div>
                <div 
                style={{
                    width: "max-content",
                    maxWidth: "inherit",
                    maxHeight: "62vh",
                    overflowY: "scroll"
                }}
                className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`}>
                    {loading?
                    <>
                        {options && options.map((option, index) => (
                            Personnel && option.Id ==Personnel.Id  ?
                            <div key={index} className="toolbar-btn-sub-menu__option selected"   onClick={()=>{selectboxValSetter(option)}}>{trans(option.Name)}</div>
                           :
                            <div key={index} className="toolbar-btn-sub-menu__option"  onClick={()=>{selectboxValSetter(option)}}>{trans(option.Name)}</div>
                        ))}
                    </>
                        :
                        <div  className="sync-box-icon mt-4" onClick={()=>{setRotate(true);  setTimeout(()=>{ setRotate(false) }, 1500)}}   >       
                           <FontAwesomeIcon icon={[ICON_TYPE , "sync"] } className={`text-primary  ${rotate ? "Rotate" : "" } `}  />  
                        </div>  
                    }

                </div>
            </button>
        </div>
        }
        </>
    )
}