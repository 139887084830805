import { Button } from "components/Button/Button";

import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";

import { getBankById, postBank } from "./api";

interface BankFormPropsType {
  selectedObj;
  showForm: (e: boolean) => void;
  mode;
  relod: () => void;
}
export const BankForm = ({
  selectedObj,
  showForm,
  mode,
  relod,
}: BankFormPropsType) => {
  const [resetForm, setResetForm] = useState(false);
  const [response, setResponse] = useState<any>();

  const [loading, setLoading] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});

  async function getBankByIdAsync() {
    setLoading(false);

    try {
      if (selectedObj.Id) {
        const res = await getBankById(selectedObj.Id);
        setResponse(res.Data);
      } else {
        setResponse(null);
      }
    } catch (err: any) {}
    setLoading(true);
  }

  const handleBankFormSubmit = async (event) => {
    event.preventDefault();
    let data = {
      IsDefault: event.target.checkbox?.checked,
      SystemKey: event.target.SystemKey?.value,
      BankName: event.target.BankName?.value,
      BankNameEN: event.target.BankNameEN?.value,
    };

    try {
      if (mode == "edit") {
        await postBank("put", {
          ...data,
          Id: selectedObj.Id,
          ActivityStatus: +event.target.ActivityStatus?.value,
        });
      } else {
        await postBank("post", data);
      }
      relod();
    } catch (error) {}
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        showForm(false);
      });
    } else {
      showForm(false);
    }
  }

  const pasteHandler = async () => {
    const text = JSON.parse(await navigator.clipboard.readText());
    setResponse(text);
    try {
    } catch (error) {}
  };

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (selectedObj && mode != "create") {
      getBankByIdAsync();
    } else {
      setLoading(true);
    }
  }, [selectedObj]);

  return (
    <div className="person-contact-form card">
      <div className="card-body">
        {loading ? (
          <div className="container-fluid">
            <form onSubmit={handleBankFormSubmit}>
              <div className="row ">
                <div className="col-lg-2 col-sm-3 mt-1">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="BankName"
                    labelText="BankName"
                    required
                    defaultValue={response?.BankName ? response?.BankName : ""}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className=" col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="BankNameEN"
                    labelText="BankNameEN"
                    defaultValue={
                      response?.BankNameEN ? response?.BankNameEN : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="SystemKey"
                    labelText="SystemKey"
                    defaultValue={
                      response?.SystemKey ? response?.SystemKey : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                {mode !== "create" && (
                  <div className="col-sm-3 col-lg-2">
                    <EnumerationTypeSelect
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      name="ActivityStatus"
                      enumTypeSelect="ActivityStatus"
                      mode={mode}
                      label="Status"
                      value={response?.Status}
                    />
                  </div>
                )}
                <div className=" mt-4 mb-4">
                  <CheckableButton
                    defaultChecked={
                      response?.IsDefault ? response?.IsDefault : false
                    }
                    defaultValue={
                      response?.IsDefault ? response?.IsDefault : false
                    }
                    labelText={"Isdefault"}
                    inputName={"checkbox"}
                    type="checkbox"
                    mode={mode}
                  />
                </div>
              </div>

              {mode != "view" ? (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button value="Save" btnType="submit" />
                  <Button
                    btnType="cancel"
                    value="Cancel"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              ) : (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button
                    btnType="primary"
                    value="close"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              )}
            </form>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};
