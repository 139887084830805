import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {  useEffect, useRef, useState } from "react"
import { trans } from "utils/helpers"

interface ButtonToolbarCreateUser {
    onClick?: () => void,
    Callback: (id: any) => void,

}
export const ButtonToolbarCreateUser: React.FC<ButtonToolbarCreateUser> = ({ onClick, Callback }) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    const buttonToolbarProcessStarting = useRef(null);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [options, setOptions] = useState<any>([{Id:"create"  , Name :"create" },{Id:"createGrouply"  , Name :"createusergrouply" }])
    const [selectoptions, setSelectOptions] = useState<any>()

    function showSubMenuFunction() {
        setShowSubMenu(true)
    }
    function hideSubMenuFunction() {
        setShowSubMenu(false)
    }
    function selectboxValSetter(event) {
        setSelectOptions(event.currentTarget.getAttribute('data-value'))
        Callback && Callback(event.currentTarget.getAttribute('data-value'));
        setShowSubMenu(false)
        if (buttonToolbarProcessStarting.current) (buttonToolbarProcessStarting.current as HTMLButtonElement).blur();
    }


    return (
        <> {options && <div className="toolbar-btn-wrapper">
            <button ref={buttonToolbarProcessStarting} onClick={() => { }} onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction} className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
                <div className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>F4</div>
                <FontAwesomeIcon icon={['fas', 'plus']} width="20px" height="20px" />
                <small>{trans("create")}</small>
                <div className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`}>
                    {options && options.map((option, index) => (
                        selectoptions && option.Id ==selectoptions  ?
                        <div key={index} className="toolbar-btn-sub-menu__option "  data-value={`${option.Id}`} onClick={selectboxValSetter}>{trans(option.Name)}</div>
                       :
                        <div key={index} className="toolbar-btn-sub-menu__option" data-value={`${option.Id}`} onClick={selectboxValSetter}>{trans(option.Name)}</div>
                    ))}

                </div>
            </button>
        </div>
        }
        </>
    )
    }