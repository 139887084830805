import { ENDPOINTS } from "utils/endpoints"
import { http } from "utils/http"





export const sendNode = (data: any , method) => http({
    url: ENDPOINTS.archive.organizationalArchiveDetail.browse,
    method: method,
    data: data,
    params:{}
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});




export const getFirstNode = (id: any) => http({
    url: ENDPOINTS.archive.organizationalArchive.load,
    method: "get",
    params: {
        Id:id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});

export const getSubNode = (id: any) => http({
    url: ENDPOINTS.archive.organizationalArchiveDetail.load,
    method: "get",
    params: {
        Id:id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});
