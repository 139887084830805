import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { checkForm, deleteForm, getAllForms, getFormByKey, postForm, putForm, CheckExist, getDictionaryAvailable} from "./api";
import { CreateInputForm } from "./components/CreateForm";
import { DynamicFormDataEntry } from "./components/DynamicFormDataEntry/DynamicFormDataEntry";
import { DictionaryAvailable, InputAvailable } from "./components/InputAvailable";
import { InputDetector } from "./components/InputDetector";
import { InputSetting } from "./components/InputSetting";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./style.scss";
import { DefaultSetting } from "./components/DefaultSetting";
import { DataCollectionForm } from "./components/DataCollection/DataCollectionForm";
import { InputTypes } from "types/enum";


export const FormBuilder = () => {
    const [showCreateForm,setShowCreateForm] = useState<boolean>(false)
    const [showDefaultSetting,setShowDefaultSetting] = useState<boolean>(false)
    const [defaultSettingObj,setDefaultSettingObj] = useState<any>({})
    const [listToolBar,setListToolBar] = useState<any>(0)
    const [dragItem,setDragItem] = useState<any>(null)
    const [inputs,setInputs] = useState<any>([])
    const [positionOnPage,setPositionOnPage] = useState<any>([])
    const [selectInputForm,setSelectInputForm] = useState<boolean>(false)
    const [showSetting,setShowSetting] =useState<boolean>(false)
    const [editInput,setEditInput] = useState<any>()
    const [indexEditInput,setIndexEditInput] = useState<any>()
    const [informationForm,setInformationForm] = useState<any>(null)
    const [keywords,setKeywords] = useState<any>(null)
    const [forms,setForms] = useState<any>()
    const [editInputStatus,setEditInputStatus] = useState<boolean>(false)
    const [colSelectMove,setColSelectMove] = useState<any>(null)
    const [loading,setLoading] = useState<boolean>(true)
    const [loadingAfterSetting,setLoadingAfterSetting] = useState<boolean>(true)
    const [formsLoading,setFormLoading] = useState <boolean>(true)
    const [forceUpdate,setForceUpdate] = useState <boolean>(false)
    const [showFormInViewMode,setShowFormInViewMode] = useState<boolean>(false)
    const [FormInViewMode,setFormInViewMode] = useState<any>()
    const [preview,setPreview] = useState<boolean>(false)
    const [inputMode,setInputMode] = useState<'create' | 'edit' | 'duplicate'>('create')
    const [formMode,setFormMode] = useState<'create' | 'edit' | 'view'>('view')
    const [countList,setCountList] = useState<any>({text:0,textArea:0,number:0,date:0,time:0,timeLength:0,selectBox:0,RadioButton:0,checkBox:0,ProgressBar:0,price:0,Message:0,Step:0,Position:0,Person:0,Sex:0})
    const [objectStep,setObjectStep] = useState<any>({})
    const [userChanges,setUserChanges] = useState<boolean>(false)
    const [stateBoxInput,setStateBoxInput] = useState<'edit'|'delete'|'clone'|null>(null)
    const [showResetBtn,setShowResetBtn] = useState<boolean>(false)
    const [selectingItemForDelete,setSelectingItemForDelete] = useState<any>(null)
    const [deleteTimerMode,setDeleteTimerMode] = useState<boolean>(false)
    const [dynamicFormApplications,setDynamicFormApplications]=useState<any>()
    const [isWorkflowEnable,setIsWorkflowEnable]=useState<any>()
    const spaceIndexRef = useRef<any>(null)
    const deleteTimer = useRef<any>();
    const [showDataCollectForm,setShowDataCollectForm]=useState(false)
    const [dataDictionary,setDataDictionary]=useState<any>([])

    
    let checkClickHold = false
    let checkTimeHold=false
    let flag = true

    const Requester ={
        "label": "درخواست کننده",
        "systemKey": "RequesterPositionId",
        "disable": true,
        "typeName": "PositionDictionary",
        "defaultValue": "",
        "required": true,
        "size": "4",
    }
    const defaultDescription = {
        "typeName": "textArea",
        "backendType": "String",
        "label": "شرح",
        "size": "12",
        "InputType": "1",
        "systemKey": "Description",
        "defaultValue": "",
        "required": true,
        "disable": false,
        "maxLength": "",
        "placeholder": "",
      }

    let layoutContext = useContext(LayoutContext);
    const formBx = useRef<any>()
    const selectInput = (e) =>{
        setDragItem(e);
    }

    const moveDragInput = (e) =>{
        e.stopPropagation();
        setPositionOnPage([e.pageX,e.pageY])  
    }

    const showspace = (Obj, id) =>{
        if(dragItem != null){

            if((document.getElementById("ViewBox")==null)){
                if(spaceIndexRef ==id){
                    document.getElementById(id)?.insertAdjacentHTML("afterend", `<div id="ViewBox" class="col-${colSelectMove ? colSelectMove:'6'} formBoxDr"><div class="formBuilder__formBx__viewBox color_highlightPrimary" style="height:100px; border:2px dashed green;"></div></div>`);
                    spaceIndexRef.current = null
                }else{
                    document.getElementById(id)?.insertAdjacentHTML("beforebegin", `<div id="ViewBox" class="col-${colSelectMove ? colSelectMove:'6'} formBoxDr"><div class="formBuilder__formBx__viewBox color_highlightPrimary" style="height:100px; border:2px dashed green;"></div></div>`);

                    spaceIndexRef.current = id
                }
            }            
        }
        document.getElementById("ViewBox")?.addEventListener("mouseleave",()=>{
            document.getElementById("ViewBox")?.remove()
        })
        document.getElementById("ViewBox")?.addEventListener("mouseup",(event)=>{
       
            addNewInput(dragItem,+id.replace("inputsId",""))
            document.getElementById("ViewBox")?.remove()
        })
       
        

    }
    const DropInput = (e)=>{
        if(e.target?.className?.includes('formBuilder__formBx')){
            
            document.removeEventListener('mouseup',DropInput)       
        }else{
            let newInput = [...inputs]
            newInput.splice(indexEditInput,0,e)
            setInputs(newInput)
        }
        setDragItem(null)
        setIndexEditInput(null)
    }
    const closeSetting = ()=>{
        if(inputMode != 'edit'){
            if(indexEditInput != -1){
               // inputs.splice(indexEditInput,1)
            }else{
                inputs.pop()
            }
        }
            setIndexEditInput(null)
            setShowSetting(false)
    }

    const CheckStepper =(state,indexInList,stepInfo?)=>{
        let newInputs = inputs
        const fristStep={
            type:dragItem?.type ,
            typeName: dragItem?.typeName ,
            backendType: dragItem?.backendType ,
            size: 12,
            label: dragItem?.label,
            isOpen: true,
            systemKey:dragItem?.typeName + 1,
        }
        if(state == 'create'){
            if( inputs[0].typeName != 'Step'){
                if(indexInList != 0){
                    newInputs =[fristStep,...newInputs]
        
                    newInputs.splice(indexInList,0,{...fristStep ,  
                        systemKey:dragItem.typeName + 2
                    })
        
                    setCountList({...countList,Step : 2})
                }else{
                    newInputs =[fristStep,...newInputs]
                    setCountList({...countList,Step : 1})
                }
            }else{
                newInputs.splice(indexInList,0,{...fristStep ,  
                    systemKey:dragItem.typeName + (+countList.Step +1)
                })
                setCountList({...countList,Step : +countList.Step +1})

            }
        }else if(state == 'move'){
            newInputs.splice(indexInList,0,{...fristStep ,  
                stepInfo
            })
        }else{
            if(indexInList == 0 && countList.Step > 1){

            }else{
                newInputs.splice(indexInList,1)
            }
        }
        setInputs(newInputs)
    }


    const addNewInput = (e,inpo)=>{
        let newInputs = [...inputs]
        if(dragItem != null){
            if( indexEditInput == null){
                setInputMode('create')
                setShowSetting(false);
                const newInput ={
                    type:dragItem.type ,
                    typeName: dragItem.typeName ,
                    backendType: dragItem.backendType ,
                    label: dragItem.typeName + (countList[dragItem.typeName] + 1),
                    size: defaultSettingObj?.size ? defaultSettingObj?.size:12,
                    systemKey:dragItem.typeName + (countList[dragItem.typeName] + 1),
                    icon: dragItem.icon ,
                    isOpen: true ,
                    InputType:dragItem.typeName == 'textArea' ? '1':dragItem.typeName == 'number'? '6': '0',
                    Keywords: [],
                    ...(dragItem.typeName == 'RadioButton' && {Keywords : ['بله','خیر']}),
                    ...dragItem
                }
                if(dragItem.typeName == 'Step'){
                    CheckStepper('create',inpo)
                }else{
                    setCountList({...countList,[dragItem.typeName] : countList[dragItem.typeName] + 1})
                    if(inpo == -1){
                        newInputs.push(newInput)
                    }else{
                        newInputs.splice(inpo,0,newInput)
                    } 
                    setInputs(newInputs)
                }

              

            }else{

                if(e.typeName == 'Step'){
                    CheckStepper('move',inpo,e)
                }else{
                    let newInputs = [...inputs]
                    if(inpo !== null){
                        if(inpo == -1){
                            newInputs.splice(inputs.length,0,{...dragItem,...e})
                        }else{
                            newInputs.splice(inpo,0,{...dragItem,...e})

                        }
                    }else{

                    }
                    setInputs(newInputs)  
                    setIndexEditInput(null)
                }
            
            }
        
        }else {
            const newInput ={
                label:inputs[inpo]?.typeName + (countList[inputs[inpo].typeName] + 1),
                type: +inputs[inpo]?.type ,
                typeName: inputs[inpo]?.typeName,
                backendType: inputs[inpo]?.backendType,
                size: inputs[inpo]?.size,
                InputType: +inputs[inpo]?.InputType,
                Keywords: inputs[inpo]?.Keywords,
                icon:inputs[inpo].icon,
                systemKey:inputs[inpo]?.typeName + (countList[inputs[inpo].typeName] + 1),
                
            }
            setCountList({...countList,[inputs[inpo].typeName] : countList[inputs[inpo].typeName] + 1})

            if(inpo == -1){
                newInputs.push(newInput)
            }else{
                newInputs.splice(inpo,0,newInput)
            }
            setIndexEditInput(null)
            setInputs(newInputs)  
            setDragItem(null)
        }
        setUserChanges(true)
    }


    const removeInput = (e,inputsIndex,cancel:'delete'|'confirmDelete'|'cancelDelete')=>{
        if(cancel == 'delete'){
            setDeleteTimerMode(true)
            setSelectingItemForDelete(inputsIndex)
            setShowResetBtn(true)
            deleteTimer.current = setTimeout(() => {
                if(inputs[inputsIndex].systemKey != "Description" && inputs[inputsIndex].systemKey != "RequesterPositionId"){
                    if(inputs[inputsIndex].typeName == 'Step'){
                        CheckStepper('remove',inputsIndex)
                    }else{
                        e.stopPropagation();
                        let newInputs=[...inputs];
                        newInputs.splice(inputsIndex,1);
                        setInputs(newInputs)
                    }
                }
                setDeleteTimerMode(false)
                setUserChanges(true)
                setShowResetBtn(false)
              }, 4500)
        }else if(cancel == 'confirmDelete'){
            if(inputs[inputsIndex].systemKey != "Description" && inputs[inputsIndex].systemKey != "RequesterPositionId"){
                if(inputs[inputsIndex].typeName == 'Step'){
                    CheckStepper('remove',inputsIndex)
                }else{
                    e.stopPropagation();
                    let newInputs=[...inputs];
                    newInputs.splice(inputsIndex,1);
                    setInputs(newInputs)
                }
                setDeleteTimerMode(false)
            }
            setUserChanges(true)
            setShowResetBtn(false)
        }else if(cancel == 'cancelDelete'){
            setDeleteTimerMode(false)
            setSelectingItemForDelete(null)
            clearTimeout(deleteTimer.current)
            setShowResetBtn(false)
        }
    }


    const settingChange = (e)=>{
        let newInputs = [...inputs]
        if(indexEditInput == -1){   
            newInputs.pop()
            newInputs.push({...editInput,...e})
        }else{
            // drag and dublecate
            if(editInputStatus){
                newInputs.splice(indexEditInput+1,1,{...editInput,...e})
            }else{
                if(inputMode == 'duplicate'){
                    newInputs.splice(indexEditInput+2,0,{...editInput,...e})
                    newInputs.splice(indexEditInput,1)
                }else{
                    newInputs.splice(indexEditInput,0,{...editInput,...e})
                    newInputs.splice(indexEditInput+1,1)
                }
            }
        }
        setLoadingAfterSetting(false)
        setInputs(newInputs)  
        setEditInputStatus(false)
        setDragItem(null)
        setUserChanges(true)
    }

    async function getFormsAsync(){
        setFormLoading(false)
        try{
    
            const respons = await getAllForms()
            setForms(respons.Data)
          }catch(err:any){
          }
        setFormLoading(true)
    }

    async function getFormByNameAsync(formKey,mode?) {
        setLoading(false)
        try{
            if(formMode != 'view' && mode != 'copy'){
                setShowFormInViewMode(true)
                setFormInViewMode(formKey)
            }else{
              const respons = await getFormByKey(formKey)
              if(mode == 'copy'){
                navigator.clipboard.writeText( respons.Data?.DynamicForms.FormFields)
                Toast(trans("copy"), "success")
              }else{
                  updateCountForEditMode(JSON.parse( respons.Data?.DynamicForms.FormFields))
                  setInputs(JSON.parse( respons.Data?.DynamicForms.FormFields))
                  setInformationForm({formTitle : respons.Data?.DynamicForms.FormTitle ,systemKey : respons.Data?.DynamicForms.FormKey ,
                    IsWorkflowEnable:respons.Data?.DynamicForms.IsWorkflowEnable,
                    FormTitleEN:respons.Data?.DynamicForms.FormTitleEN,
                    HasSignature:respons.Data?.DynamicForms.HasSignature,
                    FormPriority:respons.Data?.DynamicForms.FormPriority,
                    DynamicFormApplications:respons.Data?.DynamicFormApplications,
                    Id: respons.Data?.DynamicForms.Id ? respons.Data?.DynamicForms.Id : null})
              }
          }
        }catch(err:any){
        }
        setLoading(true)
        setShowCreateForm(true)
      }

      const updateCountForEditMode = (inputs)=>{
        let newCountList = countList
        inputs.map((input)=>{
            if(input.systemKey != 'Description' && input.systemKey != 'RequesterPositionId'){
                newCountList = {...newCountList,[input.typeName] : +newCountList[input.typeName] + 1}
            }
    
        })
        setCountList(newCountList)
      }

    const sendData =async (e)=>{
        e.preventDefault()
        let inputList =inputs
        let FormObject:any={}
        let stepIndex = 0
        let isStepUnvalued = false
        let kyW:any=[]
        let objectLabels:any=[]
        inputList.map((item,index)=>{
            if( item.typeName == "Step"){
                stepIndex +=1
                if(inputList[index+1].typeName == "Step"){
                  
                    isStepUnvalued = true

                }
                if(stepIndex != 0){
                    if((inputList[0].typeName &&  inputList[0].typeName != 'Step') && inputList[inputList.length-1].typeName == 'Step'){
                       
                        isStepUnvalued = true
                     }
                }
            }
         
            if(item.typeName == "RadioButton"||item.typeName == "selectBox"){
                kyW.push({
                    [item.systemKey]:item.Keywords
                })
                objectLabels={...objectLabels ,[item.systemKey]:item.label }
            }
            if(item.typeName == "Position"||item.typeName == "Person"){
                FormObject[item.systemKey+"Description"] = "string"
            }
            item.StepIndex= stepIndex
            if(item.backendType !=""){
                objectLabels={...objectLabels , [item.systemKey]:item.label}
                FormObject[item.systemKey] = (item?.InputType == 6 && item?.typeName == 'text') ? 'int': item.backendType;
            }
            delete item.icon
        }) 
        
        if(isStepUnvalued){
            return Toast(trans("duplicatekey"), "error")
        }
        const  DynamicForms ={
            FormTitle:informationForm.DynamicForms.formTitle,
            FormKey: informationForm.DynamicForms.systemKey,
            FormTitleEN:informationForm.DynamicForms.FormTitleEN,
            FormFields : JSON.stringify(inputList) ,
            FormCards : '',
            FormObject: JSON.stringify(FormObject),
            IsWorkflowEnable:informationForm.DynamicForms.IsWorkflowEnable,
            HasSignature:informationForm.DynamicForms.HasSignature,
            FormPriority:+informationForm.DynamicForms.FormPriority
            
        }
        
        try {
            if(formMode == 'create'){
                const DynamicFormsAccesses =[{
                        PositionId: layoutContext.currentUserPosition.PositionId
                    }]
                await
                 postForm({DynamicForms:DynamicForms,DynamicFormsAccesses:DynamicFormsAccesses,DynamicFormApplications:dynamicFormApplications,Keywords : kyW ,ObjectLabels:JSON.stringify(objectLabels)

                })

            }else if(formMode == 'edit'){
                await putForm({...DynamicForms,DynamicFormApplications:dynamicFormApplications,ObjectLabels:JSON.stringify(objectLabels)})

            }
            localStorage.removeItem("formBuilderInputs")
            sessionStorage.setItem("changingforms" , "false")

            setCountList({text:0,textArea:0,number:0,date:0,time:0,timeLength:0,selectBox:0,RadioButton:0,checkBox:0,ProgressBar:0,price:0,Message:0,Step:0,Position:0,Person:0,Sex:0})
            setListToolBar(0);
            setFormMode('view')
            setInformationForm(null)
            setInputs([])
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }
    }
    
    const handleDeleteForms = async(formKey) =>{
        try{
            const res = await checkForm(formKey);
            if(res){
                await deleteForm(formKey)
                setForceUpdate(!forceUpdate)
                setInformationForm(null)
                setInputs([])
            }
        }catch(err:any){

        }

    }

    const swapElements = ( index1, index2) => {
        let newArray:any = [] ;
        if((index1 == 0 && index2 == -1 )||(index1+1 == inputs.length && index2+1 == inputs.length+1 )){

        }else{
            newArray = [...inputs]
            let temp = newArray[index1];
            newArray[index1] = newArray[index2];
            newArray[index2] = temp;
            setInputs(newArray)
        }
    };

    const handlePaste =async (event)=>{

        if(formMode != 'view'){
            const text =JSON.parse( await navigator.clipboard.readText());
            
            if(text.length != 0&&(( text[0].systemKey && text[0].backendType)||text[0] )){

            let newInputs:any = []
            let newCountList = countList
            text.map((inputCopy,index)=>{
                let newInput:any= null
                inputs.map((input,newIndex) =>{
                        if(inputCopy['systemKey'] != 'Description' && inputCopy['systemKey'] != 'RequesterPositionId'){
                            let ischanage = false
                            if(input['label'] == inputCopy['label']){
                                newInput =  {...inputCopy, label : input.typeName + (+newCountList[input.typeName] + 1)}
                                ischanage =true
                            }
    
                            if(input['systemKey'] == inputCopy['systemKey']){
                                ischanage =true
                                if(newInput == null){
                                    newInput = {...inputCopy, systemKey  :input.typeName +(+newCountList[input.typeName] + 1) }
                                }else{
                                    newInput = {...newInput, systemKey : input.typeName + (+newCountList[input.typeName] + 1)}
                                }
                            }
                            if(ischanage){
                                newCountList ={...newCountList, [input.typeName] : newCountList[input.typeName] + 1}
                            }
                        }
                    })
                    if(inputCopy['systemKey'] != 'Description' && inputCopy['systemKey'] != 'RequesterPositionId')newInputs = [...newInputs, newInput==null ?inputCopy  : newInput ]
                }
            )
            if(newInputs != null){
             setInputs([...inputs,...newInputs])
             setCountList(newCountList)
            setUserChanges(true)
            }
            }
        }
    }





    const handleEditForm = async(formKey) =>{
       try{
        const res = await checkForm(formKey);
        if(res){
            getFormByNameAsync(formKey);
            setFormMode('edit')
        }

      }catch(err:any){

      }
    }



    const checkDrag = async(event,Obj,index)=>{
        await setTimeout( ()=>{checkTimeHold=true;if(checkTimeHold && checkClickHold){if(formMode != 'view')handleSelectMove(Obj,index)}else{if(formMode != 'view')setInputMode('edit');setEditInput(Obj);setIndexEditInput(index);setShowSetting(true)}} , 500 )
    }

    const handleSelectMove = (e,index) =>{
        let newInputs = [...inputs]
        setDragItem(e);
        setIndexEditInput(index)
        setColSelectMove(e.size)
        newInputs.splice(index,1)
        setInputs(newInputs)
    }

    const CheckExistAsync = async(e) =>{
        try {
        const respons =await CheckExist(e.systemKey,e.formTitle,e.Id)
        if(!respons.Data){
            if(formMode == 'create'){
                setInputs([Requester,defaultDescription])
                setShowCreateForm(false);
                setListToolBar(1)
                setInformationForm(e) 
            }else if(formMode == 'edit'){
    
                setShowCreateForm(false);
                setListToolBar(1)
                setInformationForm(e) 
            }
        }else{
            Toast(trans(respons?.Message	), "error")
        }
        } catch (error) {
            
        }
    }
    const getDictionaryAvailableAsync = async() =>{
        try {
        const respons =await getDictionaryAvailable()
        setDataDictionary([...DictionaryAvailable , ...respons.Data.map(item=>{
            return       {
                typeName:item.Title,
                KeyId:item.Id,
                typeNameEn:item.TitleEN,
                type:InputTypes.dropDown,
                backendType:"guid",
            }
        })])

        } catch (error) {
            
        }


    }

    const handleCloseCreateForm = () =>{
        if(formMode == 'create'){
            if(inputs.length <= 0){
                setCountList({text:0,textArea:0,number:0,date:0,time:0,timeLength:0,selectBox:0,RadioButton:0,checkBox:0,ProgressBar:0,price:0,Message:0,Step:0,Position:0,Person:0,Sex:0});
                setInformationForm(null);
                setListToolBar(0);
                setFormMode('view');
                setInputs([]);
                localStorage.removeItem("formBuilderInputs")
                sessionStorage.setItem("changingforms" , "false")

            }else{
            setFormMode('create');
            }
        }else{
          //  setListToolBar(0);
          //  setFormMode('view');
        }
        setShowCreateForm(false)

    }

    useEffect(() =>{
        let newInputList = JSON.parse(localStorage.getItem("formBuilderInputs")+"");
        if(localStorage.getItem("formBuilderInputs") && newInputList.inputs.length > 0 && newInputList?.detailForm != null && newInputList?.countList != null){
            Toast(trans("areyousuretorecoverunsaveddata"),"warning","alertWithoutClose", ()=>{
                setInputs(newInputList?.inputs)
                setCountList(newInputList?.countList)
                setInformationForm(newInputList?.detailForm)
                if(newInputList?.detailForm?.Id){
                    setFormMode('edit')
                }else{
                    setFormMode('create')
                }
            },null,null,()=>{
                localStorage.removeItem("formBuilderInputs")
                sessionStorage.setItem("changingforms" , "false")
            });
        }
        getDictionaryAvailableAsync()
    },[])
    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };

      useEffect(()=>{
        if(userChanges){
            sessionStorage.setItem("changingforms" , "true")
            window.addEventListener('beforeunload', handleBeforeUnload);

        }
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };   
      },[userChanges])

    useEffect(() => {
        if(userChanges){
            localStorage.setItem('formBuilderInputs',JSON.stringify({detailForm:informationForm,inputs:inputs,countList:countList}))
        }

        setLoading(true)
    }, [inputs]);

    useEffect(()=>{
        if(dragItem != null){
            document.addEventListener('mouseup',DropInput)
            document.addEventListener('mousemove',moveDragInput)
        }else{
        }
        return ()=>{
            document.removeEventListener('mousemove',moveDragInput)
            document.removeEventListener('mouseup',DropInput)
        }
    },[dragItem])

    useEffect(()=>{
        getFormsAsync()
    },[forceUpdate])
    
    useEffect(() =>{
        setLoadingAfterSetting(true)
    },[loadingAfterSetting])

    return ( 
        <>
        {showFormInViewMode &&
        <ModalForm  title={trans('viewForm')} show={showFormInViewMode} onHide={() => {setShowFormInViewMode(false);setFormMode('create');setPreview(false);setFormInViewMode(null)}}>
            <DynamicFormDataEntry defaultInputList={FormInViewMode? null: inputs} firstAutoValue={false} formBuilderMode={!preview} EnableDisplacement={false}  mode='view' handleCopy={(formKey)=>{getFormByNameAsync(formKey,'copy');setShowFormInViewMode(false);setFormMode('create');setFormInViewMode(null)}} onHide={()=>{setShowFormInViewMode(false);setFormMode('create');setPreview(false);setFormInViewMode(null)}} copyPermission={true} formKey={FormInViewMode} />
        </ModalForm>

        }

        {showCreateForm &&
        <ModalForm  title={ trans(formMode+'Form') +(formMode!="create"? " : "+informationForm?.formTitle:"") } show={showCreateForm} onHide={() => {handleCloseCreateForm()}}>
            <CreateInputForm mode={formMode} onHide={()=>{handleCloseCreateForm()}} handleNewForm={(e)=>{
               setDynamicFormApplications(e.DynamicFormApplications)
               setIsWorkflowEnable(e.IsWorkflowEnable)
                
                CheckExistAsync(e)}} obj={informationForm}/>
        </ModalForm>

        }

        {showDefaultSetting &&
        <ModalForm  title={trans('DefaultSetting')} show={showDefaultSetting} onHide={() => {setShowDefaultSetting(false)}}>
            <DefaultSetting mode={'create'} onHide={()=>{setShowDefaultSetting(false)}} handleSettingForm={(e)=>{setDefaultSettingObj(e)}} obj={informationForm}/>
        </ModalForm>
        }
        {showDataCollectForm &&
        <ModalForm  title={trans('DefaultSetting')} show={showDataCollectForm} onHide={() => {setShowDataCollectForm(false)}}>
            <DataCollectionForm mode={'create'} onHide={()=>{setShowDataCollectForm(false)}} />
        </ModalForm>
        }

        <div className="row m-0 p-0 position-relative formBuilder userSelectNone">
            <div className={`d-flex flex-column justify-content-between position-relative p-0 col-2 glassBox formBuilder__toolBar border-0 ${(showSetting || deleteTimerMode) && 'disableMode'} `}>
                <div className="">

                <div className={`d-flex formBuilder__toolBar__header `}>
                    <div onClick={()=>{setListToolBar(0);getFormsAsync()}} className={`formBuilder__toolBar__header__title text-center p-2 w-100 ${!listToolBar ? ' color_text_black':formMode != 'view' ? 'color_text_primary formBuilder__toolBar__header__disableTitle': 'color_text_primary formBuilder__toolBar__header__disableTitle   ' }`}>{trans('forms')}</div>
                    <div onClick={()=>{setListToolBar(2);if(informationForm != null){if(formMode != 'view'){ }}}} className={`formBuilder__toolBar__header__title text-center p-2 w-100 ${listToolBar==2 ? 'color_text_black':formMode != 'view' ? 'color_text_primary formBuilder__toolBar__header__disableTitle': ' color_text_primary formBuilder__toolBar__header__disableTitle' }`}>{trans('databox')}</div>
                    <div onClick={()=>{setListToolBar(1);if(informationForm != null){if(formMode != 'view'){ }}}} className={`formBuilder__toolBar__header__title text-center p-2 w-100 ${listToolBar==1 ? 'color_text_black':formMode != 'view' ? 'color_text_primary formBuilder__toolBar__header__disableTitle': ' color_text_primary formBuilder__toolBar__header__disableTitle' }`}>{trans('toolBox')}</div>
                </div>
                <ul className={` posiion-relative p-1 formBuilder__toolBar__body position-relative`}>
                    {listToolBar ?
                    <>{
                        listToolBar==1?
                        InputAvailable.map((e:any)=>(
                            e.typeName != 'PositionDictionary' &&(
                            <li className="" onMouseDown={()=>{selectInput(e)}}>
                                <div className=" glassBox p-2 border border-1 m-1 d-flex justify-content-between align-items-center formBuilder__toolBar__body__items">
                                    <div className="showDots">{trans(e.typeName)}</div>
                                    <FontAwesomeIcon icon={e.icon}/>
                                </div>
                            </li>)
                        )):
                        dataDictionary.map((e:any)=>(
                            e.typeName != 'PositionDictionary' &&(
                            <li className="" onMouseDown={()=>{selectInput(e)}}>
                                <div className=" glassBox p-2 border border-1 m-1 d-flex justify-content-between align-items-center formBuilder__toolBar__body__items">
                                    <div className="showDots">{trans(e.typeName)}</div>
                                </div>
                            </li>)
                        ))
                    }</>
                        :
                        <>
                        {
                            formsLoading ? 
                            <ul className="">
                             {forms?.map((form,index) => (
                                 <li className={`m-2 p-2 glassBox formBuilder__toolBar__body__form ${informationForm?.systemKey == form.FormKey ? ' formBuilder__toolBar__body__form__active' : ''}`} onClick={()=>{getFormByNameAsync(form.FormKey);setFormMode('view')}}>
                                    <div>
                                        <div className="showDots formBuilder__toolBar__body__form__title">{form?.FormTitle}</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-muted showDots formBuilder__toolBar__body__form__sub">{form?.FormKey}</div>
                                        <div className="d-flex">
                                            <button onClick={(e)=>{e.stopPropagation();getFormByNameAsync(form.FormKey,'copy')}} className="btn btn-sm ms-1 btn-warning d-flex justify-content-center align-items-center customBtnSize"><FontAwesomeIcon icon={["fas", "copy"]}/></button>
                                            <button onClick={(e)=>{e.stopPropagation();getFormByNameAsync(form.FormKey);setFormMode('view')}} className="btn btn-sm ms-1 btn-success d-flex justify-content-center align-items-center customBtnSize"><FontAwesomeIcon icon={["fas", "eye"]} /></button>
                                            {formMode == 'view' &&
                                            <>
                                                <button onClick={(e)=>{e.stopPropagation();getFormByNameAsync(form.FormKey);setFormMode("edit")}} className="btn btn-sm ms-1 btn-primary d-flex justify-content-center align-items-center customBtnSize"><FontAwesomeIcon icon={["fas", "edit"]} /></button>
                                                <button onClick={(e)=>{e.stopPropagation();handleDeleteForms(form.FormKey)}} className="btn btn-sm ms-1 btn-danger d-flex justify-content-center align-items-center customBtnSize"><FontAwesomeIcon icon={["fas", "trash"]}/></button>
                                            </>
                                            }
                                        </div>
                                    </div>
                                </li>
                             ))}
                            </ul>

                            :
                            <Loading />
                        }
                        </>
                    }
                </ul>
                </div>
                {
                !listToolBar &&
                <div className="d-flex">
                    <button disabled={formMode != 'view' ? true : false} onClick={()=>{setShowDefaultSetting(true)}} className="d-flex align-items-center justify-content-center btn btn-primary w-25 ms-1 "><FontAwesomeIcon icon={["fas", "gear"]}/></button>
                    <button disabled={formMode != 'view' ? true : false} onClick={()=>{setInformationForm(null);setShowCreateForm(true);setFormMode("create")}} className="btn btn-primary w-75 ">{trans("createform")} </button>
                </div>
                }


                {
                listToolBar==2 &&
              
                <div className="d-flex justify-content-center">
                    <button  onClick={()=>{setShowDataCollectForm(true);setFormMode("create")}} className="btn btn-primary w-75 ">{trans("creation")+" " +trans("databox")} </button>
                </div>
                }
            </div>
            
            <div className={`${ showSetting? 'col-8': 'col-10' } `}>
                    
                    <div onMouseUp={(e)=>{checkClickHold = false;dragItem && addNewInput(dragItem,-1)}} ref={formBx} className={` formBoxDr glassBox border-0 position-relative`} style={{height:'85vh'}}>
                        {(showSetting || deleteTimerMode)&& <div className="position-absolute w-100 h-100 top-0 left-0" style={{zIndex:'5',backgroundColor:'#495057',opacity: 0.5,borderRadius:'0.25rem'}}></div>}
                        
                       <>
                       {loading  && loadingAfterSetting?
                        inputs.length > 0 &&
                       <>
                        <div className="modal-form__content-header modal-header alert-primary" style={{cursor:'unset'}}>
                            <strong onClick={()=>{setShowCreateForm(true)}} className="Modaltitle" id="modal-form__title" style={{cursor:'pointer'}}>
                                {informationForm?.formTitle}
                            </strong>
                            <div className="d-flex">
                                    <FontAwesomeIcon icon={["fas", "paste"]} onClick={(e)=>{if(informationForm != null && formMode != 'view')handlePaste(e)}} className={informationForm == null  || formMode == 'view' ? 'text-muted' : '' } style={{cursor:'pointer',marginLeft:'10px'}}/>
                                    <FontAwesomeIcon icon={["fas", "edit"]} onClick={()=>{if(informationForm != null){setFormMode("edit");setListToolBar(1)}}} className={inputs.length <=   0 && informationForm != null? 'text-muted' : '' } style={{cursor:'pointer',marginLeft:'10px'}}/>
                                    <FontAwesomeIcon icon={["fas", "eye"]} onClick={()=>{if(informationForm != null && inputs.length > 0){setShowFormInViewMode(true);setPreview(true)}}} className={inputs.length <=   0 && informationForm != null? 'text-muted' : '' } style={{cursor:'pointer'}}/>
                            </div>
                       </div>
                       <div className="card mx-2 mt-1 formBuilder__formBx" >
                        <div className="row">
                            {inputs.map((Obj,index)=>{
                                if(Obj.typeName == "Step"){
                                    flag = Obj.isOpen
                                }

                               return <div id={"inputsId"+index}   onMouseDown={(event)=>{checkClickHold=true;checkDrag(event,Obj,index)}} onMouseEnter={(e)=>{document.getElementById("ViewBox")?.remove(); showspace(Obj,"inputsId"+index);if(!dragItem){setSelectInputForm(true)}}} onMouseLeave={(e)=>{if(!dragItem){setSelectInputForm(false)}}} className={`d-flex position-relative align-items-start formBuilder__formBx__active col-${Obj.size} formBoxDr in`} >
                                    {showResetBtn && selectingItemForDelete == index &&
                                         <div onClick={(e)=>{e.stopPropagation()}} className="position-absolute p-1  top-0 right-0 text-white w-100 h-100 d-flex align-items-center justify-content-center blockBox " style={{zIndex:'6'}}>
                                            <div className="timerCircleBox m-2">
                                            <svg>
                                                <circle r="13" cx="15" cy="15"></circle>
                                            </svg>
                                            </div>
                                            <OverlayTrigger
                                                key={"delete"}
                                                placement="top"
                                                delay={{"show" : 300 , "hide":0}}
                                                overlay={
                                                    <Tooltip className="tooltip" id={`button-tooltip-${"checkable"}`}>
                                                        {trans('delete')}
                                                    </Tooltip>
                                                }
                                            >
                                            <div style={{height:'30px',width:'30px'}} onMouseDown={(e)=>{e.stopPropagation()}} className="d-flex align-items-center justify-content-center position-relative btn btn-danger p-1 m-2" onClick={(e)=>{e.stopPropagation();removeInput(e,index,'confirmDelete')}}><FontAwesomeIcon size='sm' color={formMode == 'view'? 'gray':''} icon={["fas", "trash"]}/></div>
                                            </OverlayTrigger> 
                                            <OverlayTrigger
                                                key={"undo"}
                                                placement="top"
                                                delay={{"show" : 300 , "hide":0}}
                                                overlay={
                                                    <Tooltip className="tooltip" id={`button-tooltip-${"checkable"}`}>
                                                        {trans('undo')}
                                                    </Tooltip>
                                                }
                                            >
                                            <div style={{height:'30px',width:'30px'}} onMouseDown={(e)=>{e.stopPropagation()}} className="d-flex align-items-center justify-content-center position-relative btn btn-success p-1 m-2" onClick={(e)=>{e.stopPropagation();removeInput(e,index,'cancelDelete')}}><FontAwesomeIcon size='sm' color={formMode == 'view'? 'gray':''} icon={["fas", "rotate-right"]}/></div>
                                            </OverlayTrigger> 
                                         </div>
                                    }
                                    <div  onMouseDown={(event)=>{checkClickHold=true;checkDrag(event,Obj,index)}} className={` align-items-start h-100 justify-content-between w-100 formBoxDr iwn ${!dragItem && formMode != 'view' && "formBuilder__formBx__items"}`} id={index}>
                                        <InputDetector hidden={!flag} stepperIndex={objectStep[inputs[index].systemKey]?objectStep[inputs[index].systemKey].label : Object.keys(objectStep).length +1 } firstAutoValue={false} formBuilderMode={true} EnableDisplacement={formMode != 'view' ? true:false} typeName={Obj.typeName} property={Obj}/>
                                        <div className="d-flex" onMouseDown={(e)=>{e.stopPropagation();return false}} >
                                            <div  onClick={(e)=>{e.stopPropagation();swapElements(index,index-1)}} className={`${showResetBtn && selectingItemForDelete == index ? 'd-flex':'d-none'} text-white align-items-center justify-content-center m-1  p-1 formBuilder__formBx__items__btn btn btn-info `}><FontAwesomeIcon size='xs' color={formMode == 'view'? 'gray':''} icon={["fas", "arrow-up"]}/></div>
                                            <div  onClick={(e)=>{e.stopPropagation();swapElements(index,index+1)}} className={`${showResetBtn && selectingItemForDelete == index ? 'd-flex':'d-none'} text-white align-items-center justify-content-center m-1  p-1 formBuilder__formBx__items__btn btn btn-info `}><FontAwesomeIcon size='xs' color={formMode == 'view'? 'gray':''} icon={["fas", "arrow-down"]}/></div>
                                            <div onMouseMove={(e)=>{e.stopPropagation();setStateBoxInput('edit')}} onMouseLeave={(e)=>{setStateBoxInput(null)}} onClick={(p)=>{if(formMode != 'view'){setInputMode('edit');setShowSetting(true);setEditInput(Obj);setIndexEditInput(index)}}}className={`${showResetBtn && selectingItemForDelete == index ? 'd-flex':'d-none'} btn btn-primary text-white align-items-center justify-content-center p-1 m-1 formBuilder__formBx__items__btn`}><FontAwesomeIcon size='xs' color={formMode == 'view'? 'gray':''} icon={["fas", "gear"]}/></div>
                                            {Obj.typeName != 'Step' && (<div onMouseMove={(e)=>{e.stopPropagation();setStateBoxInput('clone')}} onMouseLeave={(e)=>{setStateBoxInput(null)}} onMouseUp={(e)=>{e.stopPropagation();return false}}  onClick={(p)=>{if(formMode != 'view' && (inputs[index].systemKey != "Description" && inputs[index].systemKey != "RequesterPositionId")){setInputMode('duplicate');setEditInput(Obj);setShowSetting(false);addNewInput(Obj,index)}}}className={`${showResetBtn && selectingItemForDelete == index ? 'd-flex':'d-none'} btn btn-warning text-white align-items-center justify-content-center p-1 m-1 formBuilder__formBx__items__btn`}><FontAwesomeIcon size="xs" color={formMode == 'view'? 'gray':''} icon={["fas", "clone"]}/></div>)}
                                            <div onMouseMove={(e)=>{e.stopPropagation();setStateBoxInput('delete')}} onMouseLeave={(e)=>{setStateBoxInput(null)}} onClick={(p)=>{formMode != 'view' &&( inputs[index].systemKey != "Description" && inputs[index].systemKey != "RequesterPositionId")&& removeInput(p,index,'delete')}} className={`${showResetBtn && selectingItemForDelete == index ? 'd-flex':'d-none'} btn btn-danger text-white  align-items-center justify-content-center  p-1 m-1 formBuilder__formBx__items__btn`}><FontAwesomeIcon size="xs" color={formMode == 'view'? 'gray':''} icon={["fas", "trash"]}/></div>
                                        </div>
                                    </div>
                                </div>
                            })}
                            </div>
                            {
                            inputs.length > 0 && formMode != 'view' &&
                                <div className="col-lg-12 mt-1 btns_form">
                                    <div className="m-1">
                                        <Button btnType="submit" value="Save" onClick={(e)=>{sendData(e)}}/>
                                        <Button btnType="cancel" value="cancel" onClick={()=>{
                                                  Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{localStorage.removeItem("formBuilderInputs");sessionStorage.setItem("changingforms" , "false");setCountList({text:0,textArea:0,number:0,date:0,time:0,timeLength:0,selectBox:0,RadioButton:0,checkBox:0,ProgressBar:0,price:0,Message:0,Step:0,Position:0,Person:0,Sex:0});setInformationForm(null);setListToolBar(0);setFormMode('view');setInputs([]);});
                                            }}/> 
                                    </div>
                                </div> 
                            }
                       </div>
                       </>
                       :
                       <Loading />
                            }
                       </>

                    </div>
            </div>
            {showSetting && 
                <div className="col-2" style={{padding:'0'}}>
                    <div className="glassBox border" style={{overflowY:'auto',
                        height:'85vh',
                        overflowX:'hidden'}}>
                        <InputSetting onHide={closeSetting} object={editInput} handleNewSetting={(e)=>{settingChange(e)}} systemKeys={inputs} filledSystemKey={inputMode}/>
                    </div>
                </div>
            }
            {dragItem &&
                <div className="position-absolute p-2 border border-1 m-1 d-flex justify-content-between align-items-center glassBox" style={{left: `${positionOnPage[0] }px`, top: `${positionOnPage[1]-50}px` ,cursor:'move',userSelect:'none',width:'200px'}}>
                    <div className="showDots">{!dragItem.systemKey ?  dragItem.typeName : dragItem.label }</div>
                    {dragItem?.icon && <FontAwesomeIcon icon={dragItem.icon}/>}
                    
                </div>
            }
  
        </div>
        </>
     );
}
 