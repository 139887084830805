import { DynamicComponentHOC } from "components/DynamicComponentHOC/DynamicComponentHOC";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment";
import { useContext, useEffect, useRef, useState } from "react";
import { DynamicForm } from "types/types";
import { GUID_EMPTY } from "utils/constants";
import { DYNAMICFORMS } from "utils/dynamicForms";
import { ENDPOINTS } from "utils/endpoints";
import { getPageSize } from "utils/helpers";
import { getRequestDetails, getStartForm } from "../api";
import { ReplyForm } from "./ReplyForm";
import { RequestsMessageBox } from "./RequestsMessageBox";



export const RequestDetails = ({ jobId, processId,JobNo , handleShowReferenceStepper, styleCondition ,Id }: any) => {


    let [dynamicForm, setDynamicForm] = useState<DynamicForm | any>("GeneralRequestForm");
    let [showDynamicForm, setShowDynamicForm] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedId, setSelectedId] = useState<string | any>(null);
    const [mode, setmode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [referenceId, setReferenceId] = useState<any>()
    const [showattachment, setshowAttachment] = useState(false);
    const layoutContext = useContext(LayoutContext);
    const listInnerRef = useRef(null);
    const [search, setsearch] = useState(null);
    const [hasReply, setHasReply] = useState(false);
    const messagesEndRef = useRef<any>(null)
    const lang = localStorage.getItem("lang");

    /* #endregion */
    /* #region pagination */
    const handleReplyOnclick = (id) => {
        setReferenceId(id)
        setShowReplyForm(true)
    }
    async function getStartFormAsync(id: string) {
        document.body.classList.add("loading-indicator-"+lang);
        try {
            const res = await getStartForm(id);
            if (res.Data){
                setDynamicForm(res.Data);
                setShowDynamicForm(true);
            }
            else{
                setDynamicForm(undefined);
                setShowDynamicForm(false) 
            } 
        } catch (error) {
            setDynamicForm(undefined)
            setShowDynamicForm(false)
        }
        document.body.classList.remove("loading-indicator-"+lang);  

    }
    
    const sendRepliesUp = (id) => {

    }


    const handleAttachment = (id, modeFlag = false) => {
        setSelectedId(id)
        setmode(modeFlag ? "view" : "allatach")
        setshowAttachment(true)
    }


    async function getReferenceRequestsDetailsAsync(Size = pageSize) {
        setReferenceId(null)
        setLoading(false)
        let sizePage = getPageSize("RequestsDetails")
        setPageSize(sizePage)
        try {
            const res = await getRequestDetails(pageNum, Size ? Size : sizePage, search, jobId);
            if (res.Data.Data[res.Data.Data.length - 1].PerformerPositionId == layoutContext.currentUserPosition.PositionId) {
                setHasReply(true)
                setReferenceId(res.Data.Data[res.Data.Data.length - 1].Id)
            }
            setResponse(res);
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setTimeout(() => {
            messagesEndRef?.current?.scrollIntoView(false)
        }, 1)
        setLoading(true)
    }


    const ScrollData = (e) => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
            }
        }

    }
    useEffect(() => {
        getReferenceRequestsDetailsAsync()

    }, [pageNum, forceUpdate]);
    useEffect(() => {
        if(processId){
            getStartFormAsync(processId)
        }
    }, [processId]);

    if (response && response?.Data ) {
        messagesEndRef?.current?.scrollIntoView(false)
    }

    return (
        <>
            <div className="cartable-RequestsDetail">
             {loading ?
                    (response?.Data?.Data.length > 0 ?
                        <>
                            <div className="card ">
                                <>
                                    <div className='d-flex flex-column '>
                                    {showDynamicForm &&
                                        <DynamicComponentHOC onHide={() => { setShowDynamicForm(false);  }}JobNo={JobNo} modeParams={"view"} 
                                        componentName={DYNAMICFORMS[dynamicForm.Name] ?null:dynamicForm.Name} component={DYNAMICFORMS[dynamicForm.Name] ?DYNAMICFORMS[dynamicForm.Name].component:null}
                                            reload={() => { setForceUpdate(!forceUpdate) }}showInDetail={true} componentParams={dynamicForm.Parameters} processId={processId}
                                            handleCancelForm={() => setShowDynamicForm(false)} referenceId={null} jobId={jobId} Id={Id} />
                                    }

                                        <div ref={listInnerRef} onScroll={ScrollData} style={{ overflowY: "auto" }} className={`mx-2 my-1 py-1 px-3 card Requests__message position-relative d-flex flex-column text-align-center `}>
                                            {response.Data.Data[0].Id != GUID_EMPTY &&
                                                <>
                                                    {
                                                        response.Data.Data.map((item, index) => (
                                                            (item.ReferrerPositionId === layoutContext.currentUserPosition.PositionId) ?
                                                                <>
                                                                    <RequestsMessageBox
                                                                        handleAttachmentOnclick={() => { handleAttachment(item.Id, true) }}
                                                                        item={item}
                                                                        key={index}
                                                                        index={index}
                                                                        user={item.Performer}
                                                                        //submitted={submitted}
                                                                        reply={false}
                                                                        sendRepliesUp={sendRepliesUp}
                                                                        contactName={item.Referrer}
                                                                        messageValue={item.Description}
                                                                        message={item.Description}
                                                                        sent_Date={item?.ReferenceDate}
                                                                        reply_data={item?.reply_data}
                                                                        attached_file={item?.attached_file ? [Object.keys(item.attached_file), Object.values(item.attached_file)] : [[], []]}
                                                                        received_message={true}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    <RequestsMessageBox
                                                                        handleAttachmentOnclick={() => { handleAttachment(item.Id, true) }}
                                                                        item={item}
                                                                        key={index}
                                                                        index={index}
                                                                        user={item.Referrer}
                                                                        //submitted={submitted}
                                                                        reply={index == response.Data.Data.length - 1 ? true : false}
                                                                        handleReplyOnclick={() => { handleReplyOnclick(item.Id) }}
                                                                        sendRepliesUp={sendRepliesUp}
                                                                       
                                                                        contactName={item.Performer}
                                                                        messageValue={item.Description}
                                                                        message={item.Description}
                                                                        sent_Date={item?.ReferenceDate}
                                                                        reply_data={item?.reply_data}
                                                                        attached_file={item?.attached_file ? [Object.keys(item.attached_file), Object.values(item.attached_file)] : [[], []]}
                                                                        received_message={false}
                                                                    />
                                                                </>
                                                        ))
                                                    }
                                                </>
                                            }
                                            <div ref={messagesEndRef} />
                                        </div>
                                    </div>
                                </>
                            </div></>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    )
                    : <Loading />
                }
            </div>
            {showattachment &&
                <ModalForm title="Attachment" onHide={() => setshowAttachment(false)} show={showattachment}>
                    <Attachment mood={mode} onHide={() => setshowAttachment(false)} endpoint={ENDPOINTS.clientPortal.clientPortal.attachment} ownerId={selectedId} />
                </ModalForm>
            }
            {showReplyForm &&
                <ModalForm title="ReplyForm" onHide={() => setShowReplyForm(false)} show={showReplyForm}>
                    <ReplyForm referenceId={referenceId} jobid={jobId} reload={(e) => { setForceUpdate(!forceUpdate); }} mode={mode} onHide={() => setShowReplyForm(false)} />
                </ModalForm>
            }
        </>

    );
}