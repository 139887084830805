import { Button } from "components/Button/Button";
import { SelectBox } from "components/Select/Select";

interface DefaultSettingPropsType {
    onHide: (e:boolean)=>void;
    handleSettingForm: (e:any)=>void;
    obj?:any;
    mode:'create'|'view'|'edit';
}

export const DefaultSetting = ({onHide,handleSettingForm,obj,mode}:DefaultSettingPropsType) => {

    const handleSubmit = async(event) => {  
        event.preventDefault()
        let data = {
            "size": event.target.size?.value,
        }
        handleSettingForm(data)
        onHide(false)    

    }

    function cancel() {
        onHide(false)    
    }

    return ( 
        <form onSubmit={handleSubmit}>
            <div className="card">
                <div className="card-body row ">
                <div className="col-3">
                        <div>
                             <SelectBox
                              lable="InputSize"
                              selectType="select" 
                              name="size" 
                              value={obj?.size? obj?.size : 12} 
                              val={[2,3,4,6,8,9,10,12]} 
                              options={["15%","25%","33%","50%","66%","75%","85%","100%"]} 
                              mode={mode}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 mt-1 btns_form">
                        <Button btnType="submit" value="Save" />
                        <Button btnType="cancel" value="cancel" onClick={()=>{cancel()}} />
                    </div>
                </div>
            </div>
        </form>
     );
}
