import { trans } from "utils/helpers";
import "./style.scss";
import { useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface FormWithTitleProps {
    children: React.ReactNode | any;
    title:string;
    cardClass?:string;
    titleClass?:string;
    showTitle?:boolean;
    textAnimation?:string;
    
    closeOption?:boolean;
    transActive?:boolean;
    defualtClose?:boolean;
    dynamicTitle?:any;
    showFristRow?:any;
    positionTitle?:'rightTitle'|'centerTitle'|'leftTitle'
}
export const FormWithTitle = ({children,showFristRow=false,title,cardClass='mt-2',dynamicTitle,defualtClose=false,titleClass,positionTitle='rightTitle',transActive=true,showTitle=true,closeOption=false,textAnimation}:FormWithTitleProps) => {
    const [show, setShow] = useState<any>(!defualtClose );

    return ( 
        <div className={`formWithTitle position-relative borderRadiusDefault p-1 mt-2 pt-3 border-primary ${cardClass}`} style={{border:'1px solid'}}>
            {showTitle &&
            <div style={{backgroundColor:"transparent"}} className={`position-absolute px-2 font-size-md text-primary ${titleClass} ${positionTitle} titleBox ${closeOption&&"w-100  d-flex justify-content-between"}  `}>
                <div style={{backgroundColor:"white"}} className={`${textAnimation}`}>{ dynamicTitle != null && Object.keys(dynamicTitle).length > 1 ? Object.keys(dynamicTitle).map((e,index)=>(dynamicTitle[e] != undefined ? <span>{trans(e)+` : `+dynamicTitle[e]+( Object.keys(dynamicTitle).length != index+1 ? ` _ `:``)}</span>:<></>)) :transActive ? trans(title):title}</div>
                {closeOption&&
                
                <div  style={{marginLeft:"25px" , backgroundColor:"white"}} onClick={()=>{show?setShow(false):setShow(true) }} >{!show ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" />:<FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                }

            </div>
            }
            <div className={show || showFristRow ?"" :"d-none"} style={{overflow:(showFristRow&&!show)?'hidden':'',maxHeight:(showFristRow && !show)? '55px':'' }}>
            {children}
      </div>
    </div>
  );
};
