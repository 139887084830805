import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { trans } from "utils/helpers"
import "./style.scss"
import { NotificationConnection } from "utils/notificationHub"
import { KeyCodesObject } from "types/enum"

interface ButtonToolbarProps {
    faIcon: [IconPrefix, IconName], // اجباری یا اختیاری؟
    text: "help" | "edit" | "view" | "create" | "refresh" | "delete" | string,
    hotKey?: "F8" | "F9" | "F10" | "F11" | "F12"|"F",
    onClick?: () => void,
    options?: any, // تایپ آپشنز مشخص شود
    id?: any, // تایپ آپشنز مشخص شود
    isFocos?: any,
    RefreshWithNotif?: any,
    disable?:any,
    editInGrid?:any,
    Updated?:any,
    alarme?:boolean,
    createCallback?: (ans: any) => void
}
export const ButtonToolbar: React.FC<ButtonToolbarProps> = ({
    faIcon,
    isFocos =false,
    text,
    onClick,
    options = [{ val: 1, text: "Create" }],
    id,
    hotKey,
    RefreshWithNotif=false,
    disable,
    editInGrid,
    alarme=false,
    Updated=null,
    createCallback
}) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    let hotKeyText: string | undefined = hotKey;
    const buttonToolbar = useRef(null);
    const buttonKey = useRef(hotKey);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [connection, setConnection] = useState<any>(NotificationConnection);
    const [count, setCount] = useState<any>();
    const connectionRef = useRef<any>(NotificationConnection);
    const countRef = useRef<any>(0);
    const timeID = useRef<any>(new Date().getTime() + "ButtonToolbar");
    useEffect(() => {
        if (localStorage.Shortcut != null) {
            Object.keys(JSON.parse(localStorage.Shortcut)).forEach(item=>{
                if(item == text.toLowerCase()){
                    buttonKey.current = JSON.parse(localStorage.Shortcut)[item]
                }
            })
        }
    }, [localStorage.Shortcut]);
   
   useEffect(() => {
       let flag = true
       const callback = (event: KeyboardEvent) => {
           if((( event.ctrlKey && buttonKey.current?.includes("+") && event.keyCode == KeyCodesObject[buttonKey?.current?.replace("Ctrl+" , "").toUpperCase()])) ){
               const elements = Array.from(document.getElementsByClassName('toolbar-btn-wrapper '+text));
               let stop= false
                elements.map((element, index) => {
                   if((+element?.id.replace("ButtonToolbar" , ""))>+timeID.current.replace("ButtonToolbar" , "")){
                       stop = true
                   }
               })
               if(!stop){
                   if(document.getElementsByClassName("fullscreen-bg-modal").length>elements.length ){
                       onClick && onClick();
                       countRef.current =0;setCount(0)
                       event.returnValue = false;
                }else{
                    onClick && onClick();
                    countRef.current =0;setCount(0)
                    event.returnValue = false;
                }
            }
            }else if((buttonKey.current != undefined && buttonKey.current == event.key && isFocos ==false)||(text.toLowerCase()=="create"&&event.key=="Insert") ){              
                const elements = Array.from(document.getElementsByClassName('toolbar-btn-wrapper '+text));
                let stop= false
                elements.map((element, index) => {
                    if((+element?.id.replace("ButtonToolbar" , ""))>+timeID.current.replace("ButtonToolbar" , "")){
                        stop = true
                    }
                })
                if(!stop){
                    if(document.getElementsByClassName("fullscreen-bg-modal").length> elements.length){
                        onClick && onClick();
                        countRef.current =0;setCount(0)
                        event.returnValue = false;
                    }else{
                        onClick && onClick();
                        countRef.current =0;setCount(0)
                        event.returnValue = false;
                    }
                }
            }  
       };
       if(!disable){
           document.addEventListener('keydown', callback);
       }
       return () => {
           document.removeEventListener('keydown', callback);
       };
   }, [id ,disable]);

   useEffect(() => {
    if( RefreshWithNotif&&text == "Refresh" && window.location.href.includes("cartable/current")){
        connectionRef.current = NotificationConnection
        setConnection(NotificationConnection);
        if (connection || NotificationConnection ||connectionRef.current) {
            NotificationConnection.on('SendNewCartableNotification', async (method, message) => {
                countRef.current = countRef.current+ 1
                setCount(countRef.current)
            });
        } 
    }else{
        connectionRef.current = null
        setConnection(null);
    }
    }, []);
   useEffect(() => {
    if( RefreshWithNotif&&text == "Refresh" && window.location.href.includes("cartable/current")&&Updated!=null){
        countRef.current =0;
        setCount(0) 
    }
    }, [Updated]);

    function showSubMenuFunction() {
        setShowSubMenu(true)
    }
    function hideSubMenuFunction() {
        setShowSubMenu(false)
    }
    function selectboxValSetter(event) {
        createCallback && createCallback(event.currentTarget.getAttribute('data-value'));
        setShowSubMenu(false)
        if (buttonToolbar.current) (buttonToolbar.current as HTMLButtonElement).blur();
    }
    return (
        <div id={timeID.current} className={"toolbar-btn-wrapper "+text}>
            <button ref={buttonToolbar} onClick={(e)=>{countRef.current =0;setCount(0) ;onClick && onClick()}} onFocus={(onClick&&!editInGrid) ? () => { } : showSubMenuFunction} onBlur={(onClick&&!editInGrid) ? () => { } : hideSubMenuFunction} className={`btn ${disable ? 'btn-outline-secondary' : 'btn-outline-primary'} ${alarme && 'color_change_btn' }  toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center`}   role="button" aria-disabled={disable} disabled={disable}>
                <div  className={`toolbar-btn__hotkey ${disable ? "text-secondary" : ""} ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>{hotKeyText=="Delete" ?"Del": hotKeyText}</div>
                {((count >0 || countRef.current >0)&&window.location.href.includes("cartable/current") )&& <div className={`toolbar-btn__count blink ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}></div>}
                <FontAwesomeIcon icon={faIcon} width="20px" height="20px" />
                <small className={`${disable? 'text-secondary':''}`}>{trans(`${text=="Attach"&&window.location.href.includes("item") ? "picture" : text }`)}</small>
                <div className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`}>
                    {options && options.map((option, index) => (
                        <div key={index} className="toolbar-btn-sub-menu__option" data-value={option.val} onClick={selectboxValSetter}>{option.text}</div>
                    ))}
                </div>
            </button>
        </div>
    )
}