
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";

import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { getItemListId ,deleteItemList } from "./api";
import { ItemList } from "./ItemsList";
import { Button } from "components/Button/Button";


interface WarehouseItemGroupPropsType {
    onHide: () => void;
    mode:any;
    warehouseId?:any,
    
}

export const  WarehouseItemGroup = ({ onHide, mode,warehouseId }:WarehouseItemGroupPropsType) => {


    const [response, setResponse] = useState<Response | any>(null);
    const [WarehouseItemGroupId, setWarehouseItemGroupId] = useState<string | any>(null);
    const [WarehouseItemGroupmode, setWarehouseItemGroupMode] = useState<string>();
    const [orderFormat, setOrderFormat] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [showWarehouseItemGroupForm, setShowWarehouseItemGroupForm] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [selectList , setSelectList] = useState<any>([])
    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pages, setPages] = useState<number>(0);
    const [filterlist, setFilterlist] = useState<any>("");
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(false);

    let customFuction: any = []

    /* pagination area*/
    
    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("WarehouseItemGroup" ,e.currentTarget.value )
        getItemListAsync(1)
      };

      const SearchHandler = (str) => {
        setPageNum(1);
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }

    const handleGoPage = (value) => {
      if (value) {
        if (value < 1 || value > pages) { }
        else setPageNum(+value);getItemListAsync(+value);
              } else {

      }
    };
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getItemListAsync(pageNum + 1);
          };
        };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getItemListAsync(pageNum - 1);
          };
          };


    const selectedRowCallback = (obj, index) => {
        setWarehouseItemGroupId(obj?.Id);
    };
    const selectListCallBack =(list)=>{
          
        setSelectList(list[1])
      }

    


    const clickToolbarCallback = (clickedButtonName: string) => {

        switch (clickedButtonName.toLowerCase()) {
            case "finish": alert("call api finish " ); break;
            case "current": alert("call api current " ); break;
            case "archive": alert("call api archive " ); break;
            case "view": if (WarehouseItemGroupId) { setWarehouseItemGroupMode("view");  } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "print": alert("print ");break;
            case "refresh": setForceUpdate(!forceUpdate);setSelectList(null); break;
            case "excel": downloadCSV(response?.Data ,1 ,trans("WarehouseItemGroup"));break;
            case "create": setShowWarehouseItemGroupForm(true);break;
            case "remove":if(selectList?.length >0){Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleRemove)}else{Toast("Msg_SelectRow" , "warning")}  ;break;

        }
    };


    async function getItemListAsync(PageNum = pageNum) {
        setLoading(false)
        setResponse(null)
        let pageSizeChangedByUser = getPageSize("WarehouseItemGroup" )
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getItemListId(PageNum,  pageSizeChangedByUser,orderBy.length == 0 ?null:  orderBy.join(","),search  ,filterlist,warehouseId);
            setResponse(res.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }

    async function handleRemove(){
        setLoading(false)
        try {
            let removeListId=selectList.map(item=>{return item.Id})
           const res = await deleteItemList(removeListId,warehouseId);
        } catch (error) {
            
        }
        setSelectList(null)
        setForceUpdate(!forceUpdate)
        setLoading(true)
    }
    
    
    useEffect(()=>{
        getItemListAsync()
    }, [forceUpdate,search,orderBy,filterlist]);
    

   
    /* #endregion */

    return (
        < >
        
        <div className="p-2">

        {mode != "view" &&
          <Toolbar handleSearch={SearchHandler} warehouse={false} create={mode != "view"} remove={mode!= "view"}  emptyList={response?.TotalRecords == 0} id={WarehouseItemGroupId}   search={true} edit={false} refresh={true}  view={false}  handleClickToolbar={clickToolbarCallback} />
        }   
         {loading ?
            <div className="col-lg-12">
                <div className="list-card card">
                    <div className="list-card__body">
                        {
                            (response?.Data?.length > 0 ?
                                <DataGrid
                                    customFuction={customFuction}
                                    handlefiltering={(str)=>{setFilterlist(str)  }}
                                    filterStr={filterlist}
                                    orderFormat={setOrderBy}
                                    orderFormatList={orderBy}
                                    selectItem={(e) => {   }}
                                    multiSelect={true}
                                    selectList={selectListCallBack}
                                    resetMultiSelect={resetMultiSelect}
                                    parentName={"WarehouseItemGroup" }
                                    startIndex={1}
                                    theadData={Object.keys(response.Data[0])}
                                    tbodyData={response.Data} 
                                    scrollHeight={"30vh"}
                                    minCellWidth={80}
                                    selectedRowObjectCallback={selectedRowCallback}
                                    totalRecords={response.TotalRecords}
                                    pageNum={pageNum}
                                    pageSize={pageSize}
                                    HandleNextPage={handleNextPage}
                                    HandlePrevPage={handlePrevPage}
                                    handlechangePageSize={handleChangePageSize}
                                    first={() =>{getItemListAsync(pages); setPageNum(pages)}}
                                    end={() =>{getItemListAsync(1); setPageNum(1)}}
                                    handleGoPage={handleGoPage}
                                
                                />
                                : <Message message={"NoDataForPreview"} type={messageType} />)
                        }
                    </div>
                </div>
            </div>
             : <Loading />
         }
             {showWarehouseItemGroupForm &&
             <ModalForm mode={WarehouseItemGroupmode} modalHeight={60} title="ItemList" onHide={() => setShowWarehouseItemGroupForm(false)} show={showWarehouseItemGroupForm}>
                  <ItemList warehouseId={warehouseId} reload={()=>{setForceUpdate(!forceUpdate)}} onHide={() => setShowWarehouseItemGroupForm(false)} />
             </ModalForm>
             }
             { 
      <div className="col-lg-12 mt-1 btns_form">
      <Button btnType="primary" value="close" onClick={onHide} />
      </div>

  }   
        </div>
        
        </>
    )
}