import { Button } from "components/Button/Button";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useRef, useState } from "react";
import { ResetCache, sendSettings } from "./api";
import { trans } from "utils/helpers";

interface ChatSettingFormPropsType {

}

export const ChatSettingForm = ({
  
}: ChatSettingFormPropsType) => {


    const [resetForm, setResetForm] = useState(false);
    const [settings, setSettings] = useState<any>();
  
    async function formSubmit(event) {
        event.preventDefault();       
        try {
        let data = {
            KeepFilesDuration:+event.target.keepfilesduration.value,
            TotalFilesSizeThreshold:+event.target.totalfilessizethreshold.value
            }
                await sendSettings(data)
                setResetForm(true)
            } catch (error) {
            }
    }
    
    async function getDataAsync() {
        try {
       //    return await getChatSetting(id);   
        } catch (error) {
            
        }
    }
    async function ResetCacheAsync() {
        try {
          return await ResetCache();   
        } catch (error) {
            
        }
    }
    useEffect(() => {        
            getDataAsync()
    }, [])

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);
    return (

        <form className="ChatSetting-form py-2"  onSubmit={formSubmit}>
            <div className="card">
                <div className="card-body">
                    <div className="card">
                    <div className="card-body">
                        <div className="row aalign-items-end">
                            <div className="col-6 col-lg-5">
                                <Textbox
                                    required={true}
                                    textboxType="number"
                                    min={1}
                                    textboxName="keepfilesduration"
                                    resetForm={resetForm}
                                    defaultValue={settings?.keepfilesduration}
                                    labelText={`${trans("keepfilesduration")} (${trans("calendareventday")})`}
                                    mode={"edit" }
                                />
                            </div>
                            <div className="col-6 col-lg-5">
                                <Textbox
                                    required={true}
                                    textboxType="number"
                                    textboxName="totalfilessizethreshold"
                                    min={1}
                                    resetForm={resetForm}
                                    defaultValue={settings?.totalfilessizethreshold}
                                    labelText={`${trans("totalfilessizethreshold")} (${trans("byte")})`}
                                    mode={"edit" }
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="d-flex  mt-4">
                                   <Button onClick={ResetCacheAsync} value="clearcache" btnType="primary" />
                                </div>
                                <div className="d-flex  mt-4">
                                   <Button  value="clearoldfilesaccordingabovesettings" btnType="primary" />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                           <Button  value="Save" btnType="submit" />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </form>
    );
}