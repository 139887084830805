import { useEffect, useState } from "react";
import { trans, uuidv4 } from "utils/helpers";

import { Button } from "components/Button/Button";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";

import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { DateObject } from "react-multi-date-picker";
import { TaxTypeItem } from "types/enum";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
interface Form {
    onHide: () => void;
    ItemTaxeslistcallback?: (list) => void;
    reload?: () => void;
}
export const ItemTaxesForm = ({ItemTaxeslistcallback, onHide ,reload}: Form) => {



    const [mode, setMode] = useState("create");
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<any>();
    const [ItemTaxesType, setItemTaxesType] = useState(1);
    const [title, setTitle] = useState<any>();
    const [userChanges, setUserChanges] = useState({});


    const submit = (e) => {
        e.preventDefault();
        
        let data ={
            Id:uuidv4(),
            TaxTypeItemEnum:e.target.TaxTypeItem.value ,
            TaxTypeItem:trans(TaxTypeItem[e.target.TaxTypeItem.value]) ,
            TaxRate: +e.target.TaxRate.value, 
        }
            
            ItemTaxeslistcallback&& ItemTaxeslistcallback(data)
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                setEditForm(null);
                onHide()
            });
        }else{
            setEditForm(null);
            onHide()    
        };

    }


    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);



    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        
        }
    }, [resetForm]);


    return (
        <>
            <div className="cartable-action">
                 <>
                                 <div className="card mb-4">

                                    <form className="TypeSettingForm row card-body " onSubmit={submit}>
                                         <div className="col-lg-4 ">
                                         <EnumerationTypeSelect
                                            required={true}
                                            value={editForm?.TaxTypeItem}
                                            Change={(e) => { setUserChanges({ ...userChanges, ...e }); } }
                                            type="SelectBox"
                                            name="TaxTypeItem"
                                            mode={mode}
                                            label="TaxTypeItem" 
                                            enumTypeSelect="TaxTypeItem"                                       />
                                         </div>
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox min={0} max={100} Change={(e)=>{setUserChanges({...userChanges , ...e})}} onChange={(e)=>{setTitle(e.target.value) }} required={true} textboxName={"TaxRate"} textboxType="number" resetForm={resetForm} labelText={"TaxRate"} mode={mode}  defaultValue={editForm?.ItemTaxesName} />
                                        </div>     
                                        <div className="col-md-4 col-sm-12">

                                        </div>   

                                        {  mode !="view"?
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button value="Save" btnType="submit" />
                                              <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                                              </div>
                                              :
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                                              </div>

                                          }                        
                                    </form>

                                </div >
                    </>

            </div>
        </>

    );
}