import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const  getPersonSignatureApi =( async () =>  await http({
    url: ENDPOINTS.BaseInformation.PersonSignature.browse,
    method: 'get',

})
.then((respons) => (respons.data))
)

export const sendPersonSignatureApi = (data ,method ) => http({
    url: ENDPOINTS.BaseInformation.PersonSignature.browse,
    method: method,
    data:data
}).then((res) => {
    
    return res.data
}).catch((err) => {
   
    return Promise.reject(err);
});



export const setDefaultPersonSignatureApi = (id ) => http({
    url: ENDPOINTS.BaseInformation.PersonSignature.default,
    method: "put",
    params:{
       Id:id
    }
}).then((res) => {
    
    return res.data
}).catch((err) => {
   
    return Promise.reject(err);
});


export const SetActivePersonSignatureApi = (id ) => http({
    url: ENDPOINTS.BaseInformation.PersonSignature.active,
    method: "put",
    params:{
        id:id
    }
}).then((res) => {
    
    return res.data
}).catch((err) => {
   
    return Promise.reject(err);
});
export const SetInactivePersonSignatureApi = (id ) => http({
    url: ENDPOINTS.BaseInformation.PersonSignature.inactive,
    method: "put",
    params:{
        id:id
    }
}).then((res) => {
    
    return res.data
}).catch((err) => {
   
    return Promise.reject(err);
});


export const deletePersonSignatureApi = (id) => http({
    url: ENDPOINTS.BaseInformation.PersonSignature.delete,
    method: "delete",
    params:{
        id:id
    }
}).then((res) => {
    
    return res.data
}).catch((err) => {
   
    return Promise.reject(err);
});






export const  getPersonSignatureIdApi =( async ( Id ) =>  await http({
    url: ENDPOINTS.BaseInformation.PersonSignature.list,
    method: "get",
    params:{
        personId:Id
    }

})
.then((respons) => (respons.data.Data))
)
