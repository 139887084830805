import { DataGrid } from "components/DataGrid/DataGrid";
import { useEffect, useState } from "react";
import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { getChooseRelatedList } from "pages/Secretariats/LettersPage/api/api";
import { getPageSize, trans } from "utils/helpers";
import { LetterRelationType } from "types/enum";
import { Search } from "components/Search/Search";


interface RelatedFormProps {
    letter: any;
    onHide: () => void;
    Relatedlistcallback: (list) => void;
  }

export const  RelatedForm = ({letter,onHide ,Relatedlistcallback }:RelatedFormProps) => {

    let keys, cartable = [];
    const [response, setResponse] = useState<Response | any>(null);
    const [letterId, setLetterId] = useState<string | any>(null);
    const [searchString, setSearchString] = useState<string | any>(null);
    const [flgWithAttachments, setFlgWithAttachments] = useState<string | any>(null);
    const [forceUpdate, setForceUpdate] = useState(false); // integer state
    const [reload, setReload] = useState(false); // integer state
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [mode, setMode] = useState<string>();
    const [orderFormat, setOrderFormat] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [selectList, setselectList] = useState<any>([]);
    const [pages, setPages] = useState<number>(0);
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [filterlist, setFilterlist] = useState<any>("");

    let customFuction: any = []


    /* pagination area*/


    const makeRelatedList = (event) => {
        event.preventDefault();
        let RelatedLetters:any =[]
        if(selectList[1]!=undefined &&  selectList[1].length >0){
            RelatedLetters = selectList[1].map(item=>{  
              return  {
                    Id: item.LetterId,
                    LetterRelationTypeEnum: +event.target.letterMode.value ,
                    LetterRelationType: trans(LetterRelationType[+event.target.letterMode.value] )  ,
                    LetterType:item.LetterType,
                    IndicatorNo: item.IndicatorNo ,
                    RegisterTime: item.RegisterTime ,
                    LetterSubject: item.LetterSubject ,
                    LetterNo: item.LetterNo ,
                    LetterDate: item.LetterDate,
                    Sender: item.Sender ,
                    Secretariat: item.Secretariat 
    
    
                    
                  }
            })
    
            Relatedlistcallback(RelatedLetters)
            onHide()
        } 
    }



    const getWithOrder = (order: string[]) => {
        setOrderFormat(order.join(","))
    }

    const handlechangePageSize = (e: any) => {
        setPageSize(e.currentTarget.value);
        setPageNum(1);
        getPageSize("RelatedForm" , e.currentTarget.value )
        getLetterListAsync()
    };

    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else setPageNum(value);
        } else {

        }
    };

    const HandleNextPage = () => {
        if (pageNum < pages) setPageNum((pageNum) => { return +pageNum + 1 });
    };
    const HandlePrevPage = () => {
        if (pageNum > 1) setPageNum((pageNum) => { return +pageNum - 1 });
    };
    /* pagination area */
    const selectedRowCallback = (obj, index) => {
        setLetterId(obj?.Id);
    };
    async function getLetterListAsync(PageNum = pageNum) {
        setLoading(false);
        setResponse(null);
        let pageSizeChangedByUser = getPageSize("RelatedForm" )
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getChooseRelatedList (PageNum,  pageSizeChangedByUser ,search ,orderBy.length == 0 ?null:  orderBy.join(","),letter,filterlist);
            setResponse(res.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }
    const searchHandler = (str) => {
        setPageNum(1);
        setLetterId(null);

        if (str === "") {
            setSearchString(null)
            getLetterListAsync(1)
    
        } else {
            setSearchString(str)
            getLetterListAsync(1)
        }
    }
    useEffect(() => {
        setResponse([])
        getLetterListAsync();
    }, [ reload, forceUpdate,pageNum, searchString, orderFormat ,flgWithAttachments  ]);

    /* #region manage server data */
    //with pagination 
    if (response && response?.Data?.length > 0) {
        keys = Object.keys(response?.Data[0]);
        cartable = response?.Data
    } else if (response && response?.Data?.length == 0) {
        keys = [];
        cartable = [];
    }
    /* #endregion */




    return (
        <>    
        <div className="d-flex justify-content-between align-items-center mb-1">
        <Search   onChange={searchHandler }/>    
        </div> 
         <form  onSubmit={makeRelatedList} >
                 <div className="list-card card">
                    <div className="row p-1">
                        <div className="col-12 my-1 my-lg-0 col-lg-4">
                            <CheckableButton
                                defaultValue={1}
                                type='radio'
                                labelText='Reply'
                                defaultChecked={ true}
                                inputName='letterMode'
                                mode={mode}
                            />
                        </div>
                        <div className="col-12 my-1 my-lg-0 col-lg-4">
                            <CheckableButton
                                defaultValue={2}
                                type='radio'
                                labelText='Following'
                                defaultChecked={ false}
                                inputName='letterMode'
                                mode={mode}
                            />
                        </div>
                        <div className="col-12 my-1 my-lg-0 col-lg-4">
                            <CheckableButton
                                defaultValue={3}
                                type='radio'
                                labelText='According'
                                defaultChecked={ false}
                                inputName='letterMode'
                                mode={mode}
                            />
                        </div>
                    </div>
                    <div className="list-card__body">
                        {loading ?
                            (cartable.length > 0 && keys ?
                                <DataGrid
                                    selectList={setselectList}
                                    orderFormat={setOrderBy}
                                    orderFormatList={orderBy}
                                    customFuction={customFuction}
                                    selectItem={(e) => {   }}
                                    multiSelect={true}
                                    parentName={"RelatedForm" }
                                    theadData={keys}
                                    tbodyData={cartable}
                                    startIndex={3}
                                    minCellWidth={80}
                                    selectedRowObjectCallback={selectedRowCallback}
                                    totalRecords={response?.TotalRecords}
                                    pageNum={pageNum}
                                    pageSize={pageSize}
                                    HandleNextPage={HandleNextPage}
                                    HandlePrevPage={HandlePrevPage}
                                    handlechangePageSize={handlechangePageSize}
                                    first={() => {getLetterListAsync(pages);setPageNum(pages)}}
                                    end={() => {getLetterListAsync(1);setPageNum(1)}}
                                    handleGoPage={handleGoPage}
                                />
                                : <Message message={"NoDataForPreview"} type={messageType} />)
                            : <Loading />
                        }
                    </div>
                 </div>
                 <div className="col-12 d-flex justify-content-end mt-2">
                    <Button btnType="submit" value="save" />
                 </div>

        </form>

        </>
    )
}