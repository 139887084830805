
import { DataGrid } from "components/DataGrid/DataGrid";
import { Footerbar } from "components/Footerbar/Footerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toolbar } from "components/Toolbar/Toolbar";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { DeviceDeliveryForm } from "./DeviceDeliveryForm";
import { getDeviceDeliverie } from "./api";



export const  DeviceDeliveries  = () => {

    let footerData;
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<any>(null);
    const [selectedId, setSelectedId] = useState<string | any>(null);
    const [mode, setMode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const [processFilter, setProcessFilter] = useState<any>(0);
    const [step, setStep] = useState<any>(1);
    const [search, setSearch] = useState(null);
    const [pages, setPages] = useState<number>(0);
    const [filterAndSort, setFilterAndSort] = useState<any>(null);
    const [showDeviceDeliveryForm, setShowDeviceDeliveryForm] = useState<any>(false);
    const layoutContext = useContext(LayoutContext);



    let customFuction: any = [[(e) => { setSelectedId(e);setMode("edit") ; setShowDeviceDeliveryForm(true);setStep(2);}, "file-export", "delivery"]]





    const Searchhandleer = (str) => {
        setPageNum(1);
        setSelectedId(null)
        if (str === "") {
            setSearch(null)
            getDeviceDeliveriesAsync(1, null);
        } else {
            setSearch(str)
            getDeviceDeliveriesAsync(1, str);
        }
    }
    const handlechangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("DeviceDeliverieList", e.currentTarget.value)
        getDeviceDeliveriesAsync(1);
    };
    const handleGoPage = (value) => {
        if (value) {
            setPageNum(value);
            getDeviceDeliveriesAsync(value);
        } else {
        }
    };
    const HandleNextPage = () => {
        if (response.TotalRecords && ((response.TotalRecords / pageSize) > pageNum)) {
            setPageNum((pageNum) => { return +pageNum + 1 });
            getDeviceDeliveriesAsync(pageNum + 1);

        }
    };
    const HandlePrevPage = () => {
        if (pageNum > 1) {
            setPageNum((pageNum) => { return +pageNum - 1 });
            getDeviceDeliveriesAsync(pageNum - 1);
        }
    };

    /* #endregion */
    /* #region dataGridActions */




    const selectedIdCallback = (obj, index) => {
        setSelectedId(obj);
    }
    /* #endregion */


    const handleEditRow = () => {
        setShowDeviceDeliveryForm(true)
        setMode("edit");
    }
    const handleViewRow = () => {
        setShowDeviceDeliveryForm(true)
        setMode("view");
        setStep(2);
    }

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel": downloadCSV(response ,6 ,trans(" DeviceDeliveries ")); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "import":setMode("create") ; setShowDeviceDeliveryForm(true);setStep(1); break;
            case "delivery":if (selectedId){setMode("edit") ; setShowDeviceDeliveryForm(true);setStep(2);}; break;
            case "edit": if (selectedId) handleEditRow(); break;
            case "view": if (selectedId) handleViewRow(); break;
            case "help": alert("call api help"); break;

        }
    };
    /* #endregion */



    async function getDeviceDeliveriesAsync(pagNum = pageNum, searchSTR = search) {
        setLoading(false)
        let pageSizeChangedByUser = getPageSize("DeviceDeliverieList")
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getDeviceDeliverie(pagNum, pageSizeChangedByUser, searchSTR, filterAndSort?.OrderFormat?.join(","), filterAndSort?.Filterlist)
            if (res.Data.Data.length > 0) {
                setResponse(res.Data)
                setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            } 
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)

    }


    useEffect(() => {
        let settings = getGridSetting("DeviceDeliverieList")
        setFilterAndSort({ Filterlist: settings[0], OrderFormat: settings[1] })

    }, []);
    useEffect(() => {
        setSelectedId(null)
        setResponse(null)
        getDeviceDeliveriesAsync();
    }, [forceUpdate,processFilter, filterAndSort]);


    return (
        <>
            <Toolbar Import={true} Delivery={true}  create={false} edit={false} view={true} remove={false} handleSearch={Searchhandleer}  id={selectedId} emptyList={response?.TotalRecords == 0} search={true} handleClickToolbar={clickToolbarCallback} />
            <div style={{  overflowY: "auto", 
                height: "78vh",
                 scrollbarWidth: "thin" }} className="col-lg-12 mt-0">
                {loading ? <>{
                    response?.Data?.length > 0 ?
                        <div className="card">
                            <div className="card-body p-0">
                                <>
                                    <DataGrid
                                        DoubleClick={(obj) => { setSelectedId(obj); clickToolbarCallback("view") }}
                                        View={true}
                                        ViewParametr={"NotSeen"}
                                        customFuction={customFuction}
                                        handlefiltering={(str:any) => {str.Filterlist != undefined  ? setFilterAndSort(str) : setFilterAndSort({...filterAndSort, Filterlist:str})}}
                                        filterStr={filterAndSort.Filterlist}
                                        orderFormatList={filterAndSort.OrderFormat}
                                        orderFormat={(order) => { setFilterAndSort({ ...filterAndSort, OrderFormat: order }) }}
                                        parentName={"DeviceDeliverieList"}
                                        theadData={Object.keys(response.Data[0])}
                                        tbodyData={response.Data}
                                        minCellWidth={80}
                                        selectedRowObjectCallback={selectedIdCallback}
                                        rowSelected={selectedId}
                                        startIndex={1}
                                        totalRecords={response.TotalRecords}
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        HandleNextPage={HandleNextPage}
                                        HandlePrevPage={HandlePrevPage}
                                        handlechangePageSize={handlechangePageSize}
                                        first={() => { getDeviceDeliveriesAsync(pages); setPageNum(pages) }}
                                        end={() => { getDeviceDeliveriesAsync(1); setPageNum(1) }}
                                        handleGoPage={handleGoPage}
                                    />
                                    {footerData && <Footerbar footerContents={footerData} hasBorder={false} />}

                                </>
                            </div>
                        </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                }</>
                    : <Loading />
                }

            </div>


            {showDeviceDeliveryForm &&
                <ModalForm mode={"create"} modalHeight={step == 1 ?55 :120} title={step == 1 ? "DeviceReception":"DeviceDeliveryForm"  } onHide={() => {setShowDeviceDeliveryForm(false)}} show={showDeviceDeliveryForm}>
                    <DeviceDeliveryForm Id={selectedId?.Id} step={step}  mode={mode}  onHide={()=>{setShowDeviceDeliveryForm(false)}} reload={()=>{setForceUpdate(!forceUpdate);}}   />
                </ModalForm>
            }


        </>

    );
}