import { ENDPOINTS } from "utils/endpoints"
import { http } from "utils/http"


export const getOrganizationArchive = (id: any) => http({
    url: ENDPOINTS.archive.organizationalArchive.Tree,
    method: "get",
    params: {
        ParentId: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});



