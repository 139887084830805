import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GroupingPrice } from "components/GroupingPrice/GroupingPrice";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GroupingPriceFunction, roundOrTruncate, trans } from "utils/helpers";
import { ItemCardImg } from "./ItemCardImg";
import { ItemCountSeter } from "./ItemCountSeter";
import "./style.scss";

interface ItemCardProps {
  ItemData?: any;
  cardStyle?: boolean;
  itemGroupId?: any;
}
export const ItemCard = ({
  ItemData,
  cardStyle,
  itemGroupId,
}: ItemCardProps) => {
  const [properties, setProperties] = useState<any>({});
  const [propertiesId, setPropertiesId] = useState<any>(null);
  const [offerIndex, setOfferIndex] = useState<any>(0);
  const [orderItems, setOrderItems] = useState<any>();

  const [locking, setLocking] = useState<boolean>(true);

  const layoutContext = useContext(LayoutContext);

  //  change
  useEffect(() => {
    // let defaultValue = {}
    // Object.keys(ItemData?.properties).map((pkey:any)=>{
    //     defaultValue = {...defaultValue, [pkey]:ItemData?.properties[pkey][0]?.['value']}
    // })
    // if(Object.keys(ItemData.properties).length == 0){
    //     setLocking(false)
    // }
    // setProperties(defaultValue)
  }, []);

  // change
  useEffect(() => {
    //  let basketData = getBasketData()
    //  let item = {}
    //  Object.keys(ItemData?.properties).map((pValue:any)=>{
    //      item = {...item, [pValue]:ItemData?.properties[pValue][0]?.['value']}
    //  })
    //
    //  let propertiesId = (ItemData.Id + (Object.values(ItemData?.properties).map((p:any)=>p[0]?.Id))).replaceAll(',','')
    //  setProperties(item)
    //  setPropertiesId(propertiesId)
    //  setOrderItems( basketData.find((item )=> item.Id == ItemData.Id))
    //
    //
    //  setOpenBadge(!openBadge)
  }, [layoutContext.basket]);

  useEffect(() => {
    // change
    if (
      +orderItems?.order[propertiesId]?.count >
        +orderItems?.order[propertiesId]?.max &&
      orderItems?.order[propertiesId]?.max != null
    ) {
      setOfferIndex(offerIndex + 1);
    }

    if (
      +orderItems?.order[propertiesId]?.count <
        +orderItems?.order[propertiesId]?.min &&
      +orderItems?.order[propertiesId]?.count != 0
    ) {
      setOfferIndex(offerIndex - 1);
    }
    if (orderItems == undefined) {
      setOfferIndex(0);
    }
  }, [orderItems?.order[propertiesId]?.count]);

  {
    return cardStyle ? (
      <>
        <div
          className={`card-parent position-relative overflow-hidden cardBox p-0   userSelectNone `}
        >
          <Link
            to={"/e-commerce/Item?item=" + ItemData?.Id}
            state={{ itemGroupId }}
            className={`${
              locking ? "d-block" : "d-none"
            } position-absolute w-100 h-100 cardLink`}
          ></Link>
          <div className="card h-100 flex-row flex-md-column position-relative">
            {ItemData?.ItemPriceListDetails?.[0]?.PriceDetails[offerIndex]
              ?.DiscountPercent != null || ItemData?.DiscountPercent ? (
              <span className="d-flex align-items-center justify-content-center position-absolute cardBox__offButton">{`off`}</span>
            ) : (
              <></>
            )}
            <div className="d-flex justify-content-center mt-1">
              <div
                className="d-flex flex-column justify-content-between  mt-1"
                style={{ marginRight: "1rem!important" }}
              >
                <div className="d-flex flex-column">
                  <div className="d-flex cardBox__polat flex-column mt-5 me-1">
                    {ItemData?.properties?.color &&
                      ItemData?.properties?.color?.map((c, index) => {
                        if (index < 3) {
                          return (
                            <div
                              key={index + "cardBox__polat"}
                              style={{
                                margin: "1px 0",
                                backgroundColor: c.value,
                              }}
                            ></div>
                          );
                        } else {
                          if (index == 4) {
                            return (
                              <div className="my-1 border-0 position-relative">
                                {" "}
                                <FontAwesomeIcon
                                  icon={["fas", "plus"]}
                                  className="position-absolute start-0"
                                  size="sm"
                                />
                              </div>
                            );
                          }
                        }
                      })}
                  </div>
                  <div className="cardBox__btngroup h-100 position-absolute">
                    <ItemCountSeter
                      cardsListPage={"cardsPage"}
                      showSumCount={locking}
                      multiCounter={false}
                      properties={properties}
                      propertiesId={propertiesId}
                      stock={100000000000}
                      priceIndex={offerIndex}
                      min={
                        ItemData.ItemPriceListDetails?.[0].PriceDetails[
                          offerIndex
                        ]?.min
                      }
                      max={
                        ItemData.ItemPriceListDetails?.[0].PriceDetails[
                          offerIndex
                        ]?.max
                      }
                      ItemData={ItemData}
                      positionRelative={false}
                    />
                  </div>
                </div>
                {(ItemData?.rate != null || ItemData?.rate != "") &&
                  ItemData?.rate != undefined && (
                    <div>
                      <FontAwesomeIcon icon={["fas", "star"]} color="gold" />
                      <span className="me-1">{ItemData?.rate}</span>
                    </div>
                  )}
              </div>
              <Link
                to={"/e-commerce/Item?item=" + ItemData?.Id}
                className="cardBox__offButton__imgBx col-2 col-md-12 d-flex justify-content-center align-items-center"
              >
                <ItemCardImg attchId={ItemData?.DefaultAttachmentId} />
              </Link>
            </div>
            <div className="card-body  cardBox__textBx position-relative col-10 col-md-12">
              <div className="mt-1">
                <span className="cardBox__textBx__title showDotsTowLine">
                  {ItemData?.ItemName}
                </span>
              </div>

              <div
                className={`d-md-flex ${
                  ItemData?.Stock == null
                    ? "justify-content-end"
                    : "justify-content-between"
                } mt-1`}
              >
                <>
                  {ItemData?.Stock != undefined ? (
                    <>
                      {/* {
                        ItemData?.Stock < 50 ?
                            <p className="m-0">{`${trans('Stock')} : ${ItemData?.Stock}`}</p>
                            :
                        <p className="text-success m-0" style={{fontSize:"12px",fontWeight:"500"}}>{` ${trans('Stock')} : ${ItemData?.Stock} ${ItemData?.Unit != null ?ItemData?.Unit:""}`}</p>
                        } */}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              </div>
              <div className=" d-md-flex flex-md-column">
                <div className="row d-md-flex justify-content-between mt-2">
                  {ItemData?.ItemPriceListDetails?.[0]?.PriceDetails?.[
                    offerIndex
                  ]?.DiscountPercent != null || !!ItemData?.DiscountPercent ? (
                    <div
                      style={{ fontSize: "13px" }}
                      className="d-none d-md-flex w-100 fw-bold  mt-1"
                    >
                      <div className="col-8 ps-0">
                        {trans("UnitPrice")} {`(${ItemData?.PriceListUnit})`} :
                      </div>

                      <div className="text-decoration-line-through pe-0 ps-0 col-4">
                      {ItemData?.Price!='-1' ? GroupingPriceFunction(
                    ItemData?.ItemPriceListDetails?.[0].PriceDetails[offerIndex]
                      ?.SalesUnitPrice
                      ? ItemData?.ItemPriceListDetails?.[0].PriceDetails[
                          offerIndex
                        ]?.SalesUnitPrice
                      : ItemData?.Price
                  )
                 : <small style={{fontSize:'10px',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{trans('callforprice')}</small>}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{ fontSize: "13px" }}
                      className="d-none d-md-flex  mt-1 fw-bold"
                    >
                      <div className="col-8 ps-0">
                        {" "}
                        {trans("UnitPrice")} {`(${ItemData?.PriceListUnit})`}:{" "}
                      </div>
                      <div className="col-4 pe-0 ps-0">
                      {ItemData?.Price!='-1' ? GroupingPriceFunction(
                    ItemData?.ItemPriceListDetails?.[0].PriceDetails[offerIndex]
                      ?.SalesUnitPrice
                      ? ItemData?.ItemPriceListDetails?.[0].PriceDetails[
                          offerIndex
                        ]?.SalesUnitPrice
                      : ItemData?.Price
                  )
                 : <small>{trans('callforprice')}</small>}
                      </div>
                    </div>
                  )}
                  {/* {ItemData?.ItemPriceListDetails?.[0]?.PriceDetails?.[offerIndex]?.DiscountPercent == null  &&
                            <div style={{fontSize:'10px'}} className="d-block d-md-none text-decoration-line-through text-muted mt-1">
                  
                                <GroupingPrice value={ItemData?.ItemPriceListDetails?.[0].PriceDetails[offerIndex]?.UnitPrice? ItemData?.ItemPriceListDetails?.[0].PriceDetails[offerIndex]?.UnitPrice :ItemData?.PriceAfterDiscount	 }/></div>
                        } */}

                  <div className="  mt-1 fw-bolder">
                    {ItemData?.ItemPriceListDetails?.[0]?.PriceDetails[
                      offerIndex
                    ]?.DiscountPercent != null || ItemData?.DiscountPercent ? (
                      <div
                        className="d-flex"
                        style={{ fontSize: "12px", height: "15px" }}
                      >
                        <div className=" col-8 ps-0">
                          {trans("PriceAfterDiscount")}&nbsp;{" "}
                          <span className="text-customerPrimaryColor">
                            (%
                            {roundOrTruncate(
                              ItemData?.ItemPriceListDetails?.[0]?.PriceDetails[
                                offerIndex
                              ]?.discountPrice
                                ? ItemData?.ItemPriceListDetails?.[0]
                                    ?.PriceDetails[offerIndex]?.discountPrice
                                : ItemData?.DiscountPercent,
                              2,
                              true
                            )}
                            ) :
                          </span>
                        </div>{" "}
                        <div className="col-4 px-0">
                          <GroupingPrice
                            value={
                              ItemData?.ItemPriceListDetails?.[0].PriceDetails[
                                offerIndex
                              ]?.SalesUnitPrice
                                ? ItemData?.ItemPriceListDetails?.[0]
                                    .PriceDetails[offerIndex]?.SalesUnitPrice
                                : ItemData?.PriceAfterDiscount
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div style={{ height: "15px", width: "100%" }}></div>
                    )}
                    <div
                      style={{ fontSize: "10px!important" }}
                      className=" mt-1 text-muted"
                    >
                      <small className="row d-flex w-100">
                        <div className="col-8 ">{trans("PackingPrice")}</div>
                        <div className="col-4 ps-0 ">
                          {GroupingPriceFunction(
                            ItemData?.ItemPriceListDetails?.[0].PriceDetails[
                              offerIndex
                            ]?.CoverPrice
                              ? ItemData?.ItemPriceListDetails?.[0]
                                  .PriceDetails[offerIndex]?.CoverPrice
                              : ItemData?.PackingPrice
                          )}
                        </div>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <div className="d-flex flex-column justify-content-start  position-relative  glassBox my-1 list">
        <Link
          to={"/e-commerce/Item?item=" + ItemData?.Id}
          state={{ itemGroupId }}
          className={`${
            locking ? "d-block" : "d-none"
          } position-absolute w-100 h-100 cardLink`}
        ></Link>
        <div className="row  mb-1">
          <div className=" showDotsTowLine col-md   p-2">{ItemData?.ItemName}</div>
        
            {ItemData?.ItemPriceListDetails?.[0]?.PriceDetails?.[offerIndex]
              ?.DiscountPercent != null || !!ItemData?.DiscountPercent ? (
              <div
                style={{ fontSize: "12px" }}
                className="col-md  align-items-center w-100 fw-bold row  mt-1"
              >
                <div className="col-8 d-md-none ps-0">
                  {trans("UnitPrice")} {`(${ItemData?.PriceListUnit})`} :
                </div>

                <div className="text-decoration-line-through d-md-flex pe-0 ps-0 col-4 col-md-12">
                <span className="d-none d-md-block">{`(${ItemData?.PriceListUnit})`} :</span>
                  {ItemData?.Price!='-1' ? GroupingPriceFunction(
                    ItemData?.ItemPriceListDetails?.[0].PriceDetails[offerIndex]
                      ?.SalesUnitPrice
                      ? ItemData?.ItemPriceListDetails?.[0].PriceDetails[
                          offerIndex
                        ]?.SalesUnitPrice
                      : ItemData?.Price
                  )
                 : <small>{trans('callforprice')}</small>}
                </div>
              </div>
            ) : (
              <div style={{ fontSize: "12px" }} className="row col-md    align-items-center  mt-1 fw-bold">
                <div className="col-7 d-md-none ps-0">
                  {" "}
                  {trans("UnitPrice")} {`(${ItemData?.PriceListUnit})`}:{" "}
                </div>
                <div className="col-5  d-md-flex col-md pe-0 ps-0">
                <span className="d-none d-md-block">{`(${ItemData?.PriceListUnit})`} :</span>&nbsp;
                {ItemData?.Price!='-1' ? GroupingPriceFunction(
                    ItemData?.ItemPriceListDetails?.[0].PriceDetails[offerIndex]
                      ?.SalesUnitPrice
                      ? ItemData?.ItemPriceListDetails?.[0].PriceDetails[
                          offerIndex
                        ]?.SalesUnitPrice
                      : ItemData?.Price
                  )
                 : <small style={{fontSize:'10px',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{trans('callforprice')}</small>}
                </div>
              </div>
            )}
       
          {/* {ItemData?.ItemPriceListDetails?.[0]?.PriceDetails?.[offerIndex]?.DiscountPercent == null  &&
                                       <div style={{fontSize:'10px'}} className="d-block d-md-none text-decoration-line-through text-muted mt-1">
                                          
                                           <GroupingPrice value={ItemData?.ItemPriceListDetails?.[0].PriceDetails[offerIndex]?.UnitPrice? ItemData?.ItemPriceListDetails?.[0].PriceDetails[offerIndex]?.UnitPrice :ItemData?.PriceAfterDiscount	 }/></div>
                                   } */}

          {/* {ItemData?.ItemPriceListDetails?.[0]?.PriceDetails[offerIndex]
            ?.DiscountPercent != null || ItemData?.DiscountPercent ? ( */}
               
                <div className="col-md row align-items-center"
                 style={{ fontSize: "12px" }}
                >
           
                <div className=" col-7 d-md-none ps-0">
                  {trans("PriceAfterDiscount")}&nbsp;{" "}
                  <span className="text-customerPrimaryColor">
                    (%
                    {roundOrTruncate(
                      ItemData?.ItemPriceListDetails?.[0]?.PriceDetails[
                        offerIndex
                      ]?.discountPrice
                        ? ItemData?.ItemPriceListDetails?.[0]?.PriceDetails[
                            offerIndex
                          ]?.discountPrice
                        : ItemData?.DiscountPercent,
                      2,
                      true
                    )}
                    ) :
                  </span>
                </div>{" "}
                <div className="col-5 d-md-flex col-md-12 px-0">
                <span className="text-customerPrimaryColor d-none d-md-block">
                    (%
                    {roundOrTruncate(
                      ItemData?.ItemPriceListDetails?.[0]?.PriceDetails[
                        offerIndex
                      ]?.discountPrice
                        ? ItemData?.ItemPriceListDetails?.[0]?.PriceDetails[
                            offerIndex
                          ]?.discountPrice
                        : ItemData?.DiscountPercent,
                      2,
                      true
                    )}
                    ) :
                  </span>&nbsp;
                  <GroupingPrice
                    value={
                      ItemData?.ItemPriceListDetails?.[0].PriceDetails[
                        offerIndex
                      ]?.SalesUnitPrice
                        ? ItemData?.ItemPriceListDetails?.[0].PriceDetails[
                            offerIndex
                          ]?.SalesUnitPrice
                        : ItemData?.PriceAfterDiscount
                    }
                  />
                </div>
             
            </div>
          {/* ) : (
            <></>
          )} */}
          <div
            style={{ fontSize: "10px!important" }}
            className=" col-md small row mt-2 mt-md-0 d-flex align-items-center text-muted"
          >
         
              <div className="col-7 d-md-none ">{trans("PackingPrice")}</div>
              <div className="col-5 col-md-12 p-0 ">
                {GroupingPriceFunction(
                  ItemData?.ItemPriceListDetails?.[0].PriceDetails[offerIndex]
                    ?.CoverPrice
                    ? ItemData?.ItemPriceListDetails?.[0].PriceDetails[
                        offerIndex
                      ]?.CoverPrice
                    : ItemData?.PackingPrice
                )}
              </div>
        
          </div>

          <div className="d-flex col-md text-end justify-content-start col-12  align-items-center mt-md-2 mt-1 mt-md-0 list__btns">
            <div className=" w-100 p-relative   ">
              {/* <ItemCountSeter  properties={properties} propertiesId={propertiesId} priceIndex={orderItems?.order?.[propertiesId]?.min} min={orderItems?.order?.[propertiesId]?.min} max={orderItems?.order?.[propertiesId]?.max} stock={ItemData?.Stock} ItemData={ItemData} positionRelative={true} pluseInRight={false}/> */}

              <ItemCountSeter
                cardsListPage={"listsPage"}
                showSumCount={locking}
                positionRelative={true}
                pluseInRight={false}
                multiCounter={false}
                properties={properties}
                propertiesId={propertiesId}
                stock={100000000000}
                priceIndex={offerIndex}
                min={
                  ItemData.ItemPriceListDetails?.[0].PriceDetails[offerIndex]
                    ?.min
                }
                max={
                  ItemData.ItemPriceListDetails?.[0].PriceDetails[offerIndex]
                    ?.max
                }
                ItemData={ItemData}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};
