import { Toast } from "components/ToastShow/ToastShow";
import { REPORT_URL } from "utils/constants";
import { ENDPOINTSREPORT } from "utils/endpoints";
import { http } from "utils/http";



export const getWithFeedApi = (id: any) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.feedApi,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        Id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});



export const sendWithFeedMethod = (data: string, method) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.tree,
    method: method,
    data: data
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});
export const getWithFeedMethod = (id: any) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.tree,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        Id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const sendWithFeed = (data: string, method , Id = null) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.withFeed,
    method: method,
    params:{
        Id:Id
    },
    data: data
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});
export const getWithFeed = (id: any) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.report,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});



export const sendWithFeedApi = (data: string, method) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.feedApi,
    method: method,
    data: data
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});


export const getFeedApis = (data: string, method) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.feedApi,
    method: method,
    data: data
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});

export const getFeedApiDictionaryApi = (str: string, id) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.loadApi,
    method: "get",
    params: {
        searchString: str
    }
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});
export const getFeedMethodsDictionaryApi = (str: string, id) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.loadMethod,
    method: "get",
    params: {
        searchString: str
    }
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});


export const sendFeedApi = (data, method) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.browseApi,
    method:method,
    data:data
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});


export const sendFeedMethodsForm = (data, method) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.browseMethod,
    method:method,
    data:data
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});

export const getParameterDataTypeApi = () => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.parameterTypes,
    method:"get"
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});


