import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getListJobSend = (async (searchString , orderBy) => await http({
    url: ENDPOINTS.cartable.sendMethod.browse,
    method: 'get',
    params:{
        searchString: searchString,
        orderBy: orderBy
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const deleteListJobSend = (id) => http({
    url: ENDPOINTS.cartable.sendMethod.browse,
    method: 'delete',
    params:{
        Id : id
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const getJobSend = (async (id) => await http({
    url: ENDPOINTS.cartable.sendMethod.load,
    method: 'get',
    params: {Id : id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const postJobSend = (data) => http({
    url: ENDPOINTS.cartable.sendMethod.browse,
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const putJobSend = (data) => http({
    url: ENDPOINTS.cartable.sendMethod.browse,
    method: 'put',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

