
import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { DynamicComponentHOC } from "components/DynamicComponentHOC/DynamicComponentHOC";
import { Footerbar } from "components/Footerbar/Footerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { TabBar } from "components/Tabbar/Tabbar";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment";
import { useContext, useEffect, useState } from "react";
import { DynamicForm } from "types/types";
import { DYNAMICFORMS } from "utils/dynamicForms";
import { ENDPOINTS } from "utils/endpoints";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { forceStop, getJobs, getViewForm, setJobInProgress } from "./api";
import { ReferenceHistory } from "./components/RefrenceHistory/ReferenceHistory";
import { SpecialReference } from "./components/SpecialReference/SpecialReference";



export const JobsList = () => {


    let footerData;
    const [loading, setLoading] = useState(false);
    const [pages, setPages] = useState<number>(1);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [mode, setMode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const layoutContext = useContext(LayoutContext);
    const [search, setsearch] = useState(null);
    const [selectedStartingProcessId, setSelectedStartingProcessId] = useState<string | any>(null);
    let [dynamicForm, setDynamicForm] = useState<DynamicForm | any>("GeneralRequestForm");
    const [showDynamicForm, setShowDynamicForm] = useState<boolean>(false);

    const [showAttachment, setShowAttachment] = useState(false);
    const [showSpecialReference, setShowSpecialReference] = useState(false);
    const [ShowDynamicFormLoading, setShowDynamicFormLoading] = useState(false);
    const [userChanges, setUserChanges] = useState({});

    const [jobId, setJobId] = useState<string | any>(null);
    const [showHistory, setShowHistory] = useState(false);
    const [ShowStopForm, setShowStopForm] = useState(false);
    const [filterAndSort, setFilterAndSort] = useState<any>(null);
    const [selectedProcessFilterId, setSelectedProcessFilterId] = useState<string | any>(null);

    const [curentTab, setCurentTab] = useState<any>("request")

    const tabChangeHandle = (tab) => {
        setCurentTab(tab)
      } 
    let customFuction: any = [
        [(e) => { setJobId(e.Id); setShowHistory(true) }, "clipboard", "History"],
        [(e) => {setSelectedObj(e) ; setShowStopForm(true) }, "stop-circle", "ForceStop"],
    ]
    const processViewCallback = (id: string) => {
        setMode("view");
        setSelectedStartingProcessId(id);
        getFormViewAsync(id);
    };

    async function getFormViewAsync(id: string) {
        setShowDynamicForm(true);
        setShowDynamicFormLoading(false)
        try {
            setMode("create");
            const res = await getViewForm(id, layoutContext.currentUserPosition.PositionId);
            if (res.Data)
                setDynamicForm(res.Data);
            else
                setDynamicForm(undefined);
            if (dynamicForm) {
              
            }
            else { setShowDynamicForm(false) }

        } catch (error) {
            setDynamicForm(undefined);
            setShowDynamicForm(false)

        }
        setShowDynamicFormLoading(true)

    }


    const searchHandler = (str) => {
        setPageNum(1);
        setSelectedObj(null)
        if (str === "") {
            setsearch(null)
            getJobssAsync(1 ,null)
        } else {
            setsearch(str)
            getJobssAsync(1 ,str)
        }
    }
    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("JobsList", e.currentTarget.value)
        getJobssAsync(1);
        // getDataAsync();
    };
    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else setPageNum(+value);getJobssAsync(+value);
        } else {
        }
    };
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getJobssAsync(pageNum + 1);
          }
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum(pageNum - 1);
            getJobssAsync(pageNum - 1);
          }
    };
    /* #endregion */
    /* #region dataGridActions */

    const selectedObjCallback = (obj, index) => {
        setSelectedObj(obj);
    }
    const handleViewRow = () => {
        setMode("view");
        setShowDynamicForm(true)
    }
    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel": downloadCSV(response?.Data ,6 ,trans("JobsList")); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create":  break;
            case "view": if (selectedObj.Id) { processViewCallback(selectedObj?.PRJ_UID);} else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "history": if (selectedObj.Id){ setJobId(selectedObj.Id); setShowHistory(true);}; break;
            case "help": alert("call api help"); break;
            case "forcestop": if (selectedObj.Id)setShowStopForm(true); break;
            case "specialreference": if (selectedObj.Id)setShowSpecialReference(true); break;
            case "current": if (selectedObj?.Id)setJobInProgressAsync();else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "attach": if (selectedObj.Id) {
                setMode("edit")
                setShowAttachment(true)
            } else {
                Toast(trans("Msg_SelectRow") , "warning")
            } break;
        }
    };
    /* #endregion */

    async function getJobssAsync(PageNum = pageNum ,searchSTR = search) {
        setLoading(false);
        setResponse(null);
        let pageSizeChangedByUser = getPageSize("JobsList");
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getJobs(PageNum, pageSizeChangedByUser , searchSTR,  filterAndSort.OrderFormat?.join(","),  filterAndSort.Filterlist ,selectedProcessFilterId)
            if (res.Data.Data.length > 0) {
                setResponse(res.Data)
                setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            }  
        } catch (error) {
        }
        setLoading(true)
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                setShowStopForm(false)
            });
        }else{
            setShowStopForm(false)     
        };


    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);


    useEffect(() => {
        setSelectedObj(null)
        getJobssAsync();
    }, [forceUpdate,  selectedProcessFilterId,filterAndSort]);
    
    useEffect(() => {
        setResponse(null)
        let settings = getGridSetting("JobsList" )
        setFilterAndSort({Filterlist:settings[0],OrderFormat:settings[1] })

        
      }, []);

    
    async function stopAsync(e) {
        e.preventDefault()
        let forceStopDescription = e.target.forceStopDescription.value
        if (forceStopDescription.length > 0) {
            try {
                await forceStop(selectedObj?.Id, forceStopDescription);
                setShowStopForm(false);
                setForceUpdate(!forceUpdate);
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
            } catch (error) {
            }
            setShowStopForm(false)
        }
    }
    async function setJobInProgressAsync() {
      
            try {
                await setJobInProgress(selectedObj?.Id);
                setForceUpdate(!forceUpdate);
            } catch (error) {
            }
      
        }
    
        const selectedProcessFilterCallback = (id: string) => {
            if (id === "00000000000000000000000000000000") {
                setSelectedProcessFilterId(null);
            } else {
                setSelectedProcessFilterId(id);
            }
        };

        let tabContent = [

            { title: "request" ,body: (<>
                <DynamicComponentHOC stepParams={2} onHide={() => { setShowDynamicForm(false);   }} 
                 componentName={DYNAMICFORMS[dynamicForm.Name] ?null:dynamicForm.Name} component={DYNAMICFORMS[dynamicForm.Name] ?DYNAMICFORMS[dynamicForm.Name].component:null}
                     Name={dynamicForm.Name} modeParams="view" componentParams={dynamicForm.Parameters} processId={selectedStartingProcessId}
                     jobId={selectedObj?.Id}
                    Id={selectedObj?.SourceId} handleCancelForm={() => { setShowDynamicForm(false);  }}  />
            </>), },
            { title: "ReferencesTree" ,body: (<ReferenceHistory jobId={selectedObj?.Id} />), },
            ];

    return (
        <>
            <Toolbar handleSelectedProcessWithIdFilterToolbar={selectedProcessFilterCallback} current={true} specialreference={true} history={true} info={false} attach={true} forceStop={true}  view={true} handleSearch={searchHandler}    id={selectedObj?.Id} emptyList={response?.TotalRecords ==0}  search={true} handleClickToolbar={clickToolbarCallback} />
            <div  style={{ overflowY: "auto", 
                height: "78vh",
                 scrollbarWidth: "thin"}} className="col-lg-12 mt-0">
                {loading ?
                    (response?.Data?.length > 0 ?
                        <div className="card">
                            <div className="card-body p-0">
                                <>
                                    <DataGrid 
                                        customFuction={customFuction}
                                        DoubleClick={(obj)=>{setSelectedObj(obj) ;clickToolbarCallback("view")}}
                                        View={true}
                                        ViewParametr={"NotSeen"}
                                        handlefiltering={(str:any) => {str.Filterlist != undefined  ? setFilterAndSort(str) : setFilterAndSort({...filterAndSort, Filterlist:str})}}
                                        filterStr={filterAndSort.Filterlist}
                                        orderFormatList={filterAndSort.OrderFormat}
                                        orderFormat={(order)=>{setFilterAndSort({...filterAndSort, OrderFormat:order})  }}
                                        parentName={"JobsList"}
                                        theadData={Object.keys(response.Data[0])}
                                        tbodyData={response.Data}
                                        minCellWidth={80}
                                        handleAttachment={(e)=>{setSelectedObj({Id:e}) ;setMode("edit"); setShowAttachment(true)}}
                                        selectedRowObjectCallback={selectedObjCallback}
                                        rowSelected={selectedObj}
                                        startIndex={6}
                                        handleView={handleViewRow}
                                        totalRecords={response.TotalRecords}
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        HandleNextPage={handleNextPage}
                                        HandlePrevPage={handlePrevPage}
                                        handlechangePageSize={handleChangePageSize}
                                        first={() => { getJobssAsync(pages); setPageNum(pages) }}
                                        end={() => { getJobssAsync(1); setPageNum(1) }}
                                        handleGoPage={handleGoPage}
                                    />
                                    {footerData && <Footerbar footerContents={footerData} hasBorder={false} />}
                                </>
                            </div>
                        </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    )
                    : <Loading />
                }
            </div>
             {showAttachment &&
                    <ModalForm mode={mode} title={("Attachment")} onHide={() => { setShowAttachment(false) }} show={showAttachment}>
                       <Attachment haveVersion={true}  onHide={() => { setShowAttachment(false); setForceUpdate(!forceUpdate) }} mood={"edit"} endpoint={ENDPOINTS.Management.JobManagement.attachment} ownerId={selectedObj?.Id} />
                    </ModalForm>
                }
             {showSpecialReference &&
                    <ModalForm mode={mode} title={("specialreference")} onHide={() => { setShowSpecialReference(false) }} show={showSpecialReference}>
                       <SpecialReference endpoint={ENDPOINTS.Management.JobManagement} processId={selectedObj?.PRJ_UID}   referenceId={selectedObj?.ReferenceId} onHide={() => {setJobId(null); setShowSpecialReference(false);setSelectedObj(null); setForceUpdate(!forceUpdate) }} selectedJob={selectedObj} jobId={selectedObj?.Id} />
                    </ModalForm>
                }
          {showDynamicForm &&
                    <ModalForm modalHeight={63} mode={mode} title={ShowDynamicFormLoading ?(dynamicForm.Name) :""} onHide={() => { setShowDynamicForm(false);  }} show={showDynamicForm}>
                        {ShowDynamicFormLoading ? 
                            <>
                               <TabBar  tabChange={tabChangeHandle} content={tabContent} curentTab={curentTab} defaultTab={"request"} />
                            </>
                          : <Loading/>
                        }
                    </ModalForm>
                }

                {showHistory &&
                    <ModalForm mode={mode} title={("ReferenceHistory")} onHide={() => {setJobId(null); setShowHistory(false) }} show={showHistory}>
                        <ReferenceHistory   jobId={jobId} />
                    </ModalForm>
                }
                {ShowStopForm &&
                    <ModalForm mode={mode} title={"ForceStop"} onHide={() => setShowStopForm(false)} show={ShowStopForm}>
                        <form onSubmit={stopAsync}>
                            <div className="row mb-1">
                                <div className="col-12">
                                    <Textbox
                                        minHeight={4}
                                        textboxType="textarea"
                                        textboxName="forceStopDescription"
                                        labelText="Description"
                                        mode={"create"}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12 mt-1 btns_form">
                                <Button value="Send" btnType="submit" />
                                <Button
                                    btnType="cancel"
                                    value="Cancel"
                                    onClick={() => { cancel()}}
                                />
                            </div>
                        </form>
                    </ModalForm>
                }

        </>

    );
}