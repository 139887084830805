import { useEffect, useState, useRef } from "react";
import { trans } from "utils/helpers";
import { getIndicatorNoDictionaryApi } from "./api";
import { setTimeout } from "timers";
import { SubMenu } from "pages/SystemManagement/Jobs/components/SpecialReference/components/SubMenu/SubMenu";
interface IndicatorNoDictionaryPropsType {
    label?: string,
    letterType?: any,
    hasBorder?: boolean,
    required?: boolean,
    value?:string,
    secretariatId?:string,
    mode?:string
    resetForm?:any,
    defaultObj?:any,
    Change?: (e) => void,
    setIndicatorNoDictionary?: (e) => void,
}

export const IndicatorNoDictionary = ({ defaultObj , letterType ,secretariatId,value,mode ,required ,Change , setIndicatorNoDictionary}: IndicatorNoDictionaryPropsType) => {
    const [IndicatorNoDictionary, setIndicatorNoDictionarys] = useState<any>();
    const [valueName, setValueName] = useState<any>();
    const [flag , setFlag] = useState(false);
    const [show , setShow] = useState(false);
    const [validat , setValidat] = useState(false);
    const [rquird , setRquird] = useState(false);
    const inputRef = useRef<any>();
    const inputIdRef = useRef<any>();
    const IndicatorNoDictionaryRef = useRef<any>(IndicatorNoDictionary);
    const ShowRef = useRef<any>(show);
    const validRef = useRef<any>(false);
  

    async function getIndicatorNoDictionaryListAsync(search,id) {
        try{
           const res = await getIndicatorNoDictionaryApi(letterType,secretariatId ,search)
           if(id){
            setValueName(res[id])
           }else{
            setIndicatorNoDictionarys({...defaultObj ,...res})     
            IndicatorNoDictionaryRef.current = {...defaultObj ,...res}
           }
           if(Object.keys(res).length ==0){  
            setValeu(Object.values(defaultObj)[0] , Object.keys(defaultObj)[0]  )
          }
           setFlag(!flag)
        }catch(err:any){
        }
    }
    
    const Auto = (e)=>{
      validRef.current = false
      setValidat(false)
      setRquird(false)
      getIndicatorNoDictionaryListAsync(e.target.value,"")
      setFlag(!flag)

    }
    const CheckIndicatorNoDictionary =async (e)=>{
      setTimeout(() => {
        if(!validRef.current){
          e.target.setCustomValidity(trans("Msg_Validation_ValueIsIncorrect"));
          setValidat(true)
          setIndicatorNoDictionary && setIndicatorNoDictionary(null)
          e.target.reportValidity();
        }
      }, 200);
    }

    const setValeu = (value , id, checkChange = true)=>{
      if(inputIdRef != null && inputIdRef.current != null){
        validRef.current = true;
        inputRef.current.value=value;
        inputIdRef.current.value=id;
        IndicatorNoDictionaryRef.current = null;
        if(checkChange){
          value !="" ? Change&&Change({"secretariatNo" :value}):Change&&Change({"secretariatNo" :null});
        }
        if(mode != "view"){
          setIndicatorNoDictionarys(null)
          setFlag(!flag)
          inputRef.current.setCustomValidity("")
          setValidat(false)
          setIndicatorNoDictionary && setIndicatorNoDictionary({Value : inputRef.current.value , Id : id})
      }
    }
    }
    useEffect(() => {
       if(value){
        getIndicatorNoDictionaryListAsync(null,value)
       }else if(value == null){
        setValueName(null)
       }
      }, [value]);
    useEffect(() => {
       if(defaultObj ){
        setValeu(Object.values(defaultObj)[0] , Object.keys(defaultObj)[0] ,false )
       }else if(value == null){
        setValueName(null)
       }
      }, [defaultObj ]);

      useEffect(() =>{
        if(show ){
          document.body.addEventListener("click", clickToclose)
        } 
        return () => { document.body.removeEventListener('click', clickToclose) }
      },[show ])
    
      function clickToclose(e){
        let input = document.querySelector("#browserIndicatorNo");
        if (e.target.id !== input?.id) { 
          setShow(false);
        }
      }
    return (
     <>
     <div className="text-box">
     <label htmlFor="browser" className="text-box__label">{trans("IndicatorNo")}</label>
        {required && <span className="text-box__required-sign-for-label text-danger">*</span>}
        <input required={required} defaultValue={value && value} name="IndicatorNoDictionaryId" className="d-none" ref={inputIdRef} />
        <input  required={required} onBlur={CheckIndicatorNoDictionary} defaultValue={valueName} autoComplete="off" onFocus={(e)=>{  ShowRef.current = true ; setShow(true);}}  ref={inputRef} list="IndicatorNoDictionaryList" name="browser" className="text-box__box form-control "    id={"browserIndicatorNo"} onMouseDown={(e)=>e.stopPropagation() } onChange={Auto} disabled={mode =="view" ? true : false} />
        {
          show && 
          <SubMenu  onClick={setValeu} options={IndicatorNoDictionary} onHide={()=>{setShow(false)}} />
        }  
       <div className="text-box__msg-wrapper">
        {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
        {required && <span className={`${rquird ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsRequired", [""])}</span>}
        </div>    
     </div>
     </>
    )
}