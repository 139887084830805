
import { DataGrid } from "components/DataGrid/DataGrid";
import { DynamicComponentHOC } from "components/DynamicComponentHOC/DynamicComponentHOC";
import { Toast } from "components/ToastShow/ToastShow";
import { Footerbar } from "components/Footerbar/Footerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toolbar } from "components/Toolbar/Toolbar";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { DynamicForm } from "types/types";
import { DYNAMICFORMS } from "utils/dynamicForms";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { cancelServiceRequest, ForceStop, getAfterSale, getRequesterUserId, getStartForm, getViewForm, insertNote, jobTransfer, resendReference, setJobInProgress } from "./api";
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment";
import { ENDPOINTS } from "utils/endpoints";
import { ReferenceHistory } from "./component/RefrenceHistory/ReferenceHistory";
import { Textbox } from "components/Textbox/Textbox";
import { Button } from "components/Button/Button";
import { CustomerInfo } from "./component/CustomerInfo/CustomerInfo";
import { SpecialReference } from "pages/SystemManagement/Jobs/components/SpecialReference/SpecialReference";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { GUID_EMPTY } from "utils/constants";
import { TabBar } from "components/Tabbar/Tabbar";
import { JobStatus } from "types/enum";



export const CustomerRequests = () => {
    let footerData;
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<any>([]);
    const [selectedObj, setSelectedObj] = useState<string | any>(null);
    const [mode, setMode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const [search, setsearch] = useState(null);
    const [selectedStartingProcessId, setSelectedStartingProcessId] = useState<string | any>(null);
    const [dynamicForm, setDynamicForm] = useState<DynamicForm | any>("GeneralRequestForm");
    const [showDynamicForm, setShowDynamicForm] = useState<boolean>(false);
    const [ShowStopForm, setShowStopForm] = useState(false);
    const [showInsertNote, setShowInsertNote] = useState(false);
    const [showSpecialReference, setShowSpecialReference] = useState(false);
    const [showAttachment, setShowAttachment] = useState(false);
    const [serviceRequestId, setServiceRequestId] = useState<string | any>(null);
    const [showHistory, setShowHistory] = useState(false);
    const [showCustomerInfo, setShowCustomerInfo] = useState(false);
    const [filterAndSort, setFilterAndSort] = useState<any>(null);
    const [curentTab, setCurentTab] = useState<any>("request")
    
    const [pages, setPages] = useState<number>(0);
    const [showDynamicFormView, setShowDynamicFormView] = useState<boolean>   (false);
    const [ShowDynamicFormViewLoading, setShowDynamicFormViewLoading] = useState(false);
    
    const layoutContext = useContext(LayoutContext);

    let customFuction: any = [
        [(e) => { setServiceRequestId(e.Id); setShowHistory(true) }, "clipboard", "ReferencesTree"],
        [(e) => { setSelectedObj(e); setShowStopForm(true) }, "stop-circle", "ForceStop"],
        [(e) => { resendReferenceAsync(e.Id) }, "sync", "resend", "ExclamationIcon", "circle-exclamation,red"],
        [(e) => { chatWithRequester(e.Id) }, "paper-plane", "Chat"]

    ]

    const tabChangeHandle = (tab) => {
        setCurentTab(tab)
      } 
    async function resendReferenceAsync(id: string) {
        try {
            await resendReference(id);
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }
    }
    async function chatWithRequester(e) {

        try {
            const res = await getRequesterUserId(e);
            if(res?.Data.Id && res?.Data.Id != GUID_EMPTY){
            let item = {
                Id: res?.Data.Id,
                name: res?.Data.Description
            }
            localStorage.setItem("JobRequester", JSON.stringify(item));
            window.open(`#/message`)
        }
        } catch (error) {

        }
    }



    async function getStartFormAsync(id: string) {
        try {
            
            setMode("create");
            const res = await getStartForm(id);
            if (res.Data)
                setDynamicForm(res.Data);
            else
                setDynamicForm(undefined);
            if (dynamicForm) {
                setShowDynamicForm(true);
            }
            else { setShowDynamicForm(false) }
        } catch (error) {
            
        }
    }

    const searchHandler = (str) => {
        setPageNum(1);
        setSelectedObj(null)
        if (str === "") {
            setsearch(null)
            getAfterSalesAsync(1, null);
        } else {
            setsearch(str)
            getAfterSalesAsync(1, str);
        }
    }
    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("AfterSaleList", e.currentTarget.value)
        getAfterSalesAsync(1);
    };
    const handleGoPage = (value) => {
        if (value) {
            setPageNum(+value);
            getAfterSalesAsync(+value);
        } else {
        }
    };
    const handleNextPage = () => {
        if (response.TotalRecords && ((response.TotalRecords / pageSize) > pageNum)) {
            setPageNum((pageNum) => { return +pageNum + 1 });
            getAfterSalesAsync(pageNum + 1);

        }
    };
    const handlePrevPage = () => {
        if (pageNum > 1) {
            setPageNum((pageNum) => { return +pageNum - 1 });
            getAfterSalesAsync(pageNum - 1);
        }
    };

    /* #endregion */
    /* #region dataGridActions */

    const processViewCallback = (id: string) => {
       
        setSelectedStartingProcessId(id);
        getFormViewAsync(id);
    };

    async function getFormViewAsync(id: string) {
        setShowDynamicFormView(true);
        setShowDynamicFormViewLoading(false)
        try {
          
            const res = await getViewForm(id);
            if (res.Data)
                setDynamicForm(res.Data);
            else
                setDynamicForm(undefined);
            if (dynamicForm) {
              
            }
            else { setShowDynamicFormView(false) }

        } catch (error) {
            setDynamicForm(undefined);
            setShowDynamicFormView(false)
        }
        setShowDynamicFormViewLoading(true)
    }

    const selectedObjCallback = (obj, index) => {
        setSelectedObj(obj);
    }
    /* #endregion */

    const handleCreateServiceRequest = (id) => {
        setMode("create");
        setSelectedStartingProcessId(id);
        getStartFormAsync(id);
    }
    const handleCancelServiceRequest = async (id) => {
        try {
            const res = await cancelServiceRequest(selectedObj.Id)
            setForceUpdate(!forceUpdate)
        } catch (error) {

        }
    }
    const handleEditRow = () => {
        setMode("edit");
    }
    const specialReferenceHandler = (obj) => {
        if(obj.JobStatusEnum !=JobStatus.UnderReview && obj.JobStatusEnum !=JobStatus.InProgress ){
             Toast("Msg_FirstJobStatusMustBeSetToCurrent" ,"error")
        }else{
            setShowSpecialReference(true);
        }


    }
    const transferObject = async(obj) => {
     
        try {
           const res =  await jobTransfer(obj.Id)
           Toast(res.Message, "success") 
        } catch (error) {
            
        }
    }
    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel": downloadCSV(response?.Data ,6 ,trans("CustomerRequests")); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateServiceRequest(""); break;
            case "cancel": if (selectedObj) Toast(trans("Msg_CancelConfirm"), "warning", "alert", handleCancelServiceRequest); break;
            case "view": if (selectedObj) {  setMode("view");processViewCallback(selectedObj.PRJ_UID); } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "specialreference": if (selectedObj)specialReferenceHandler(selectedObj);  break;
            case "edit": if (selectedObj){setMode("edit") ;processViewCallback(selectedObj.PRJ_UID);}; break;
            case "info": if (selectedObj) setShowCustomerInfo(true); break;
            case "current": if (selectedObj?.Id)setJobInProgressAsync();else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "help": alert("call api help"); break;
            case "forcestop": if (selectedObj) setShowStopForm(true); break;
            case "insertnote": if (selectedObj) setShowInsertNote(true); break;
            case "assignrule": if (selectedObj) handleEditRow(); break;
            case "chat": if (selectedObj) chatWithRequester(selectedObj.Id); break;
            case "transfer": if (selectedObj) transferObject(selectedObj); break;
            case "attach": if (selectedObj) {
                setMode("edit")
                setShowAttachment(true)
            } else {
                Toast(trans("Msg_SelectRow"), "warning")
            } break;
        }
    };
    /* #endregion */

    async function stopAsync(e) {
        e.preventDefault()
        let forceStopDescription = e.target.forceStopDescription.value
        if (forceStopDescription.length > 0) {
            try {
                const res = await ForceStop(selectedObj.Id, forceStopDescription ,e.target.sendMessageToRequester.checked);
                setShowStopForm(false)
                setForceUpdate(!forceUpdate)
            } catch (error) {
            }
            setShowStopForm(false)
        }
    }
    async function insertNoteAsync(e) {
        e.preventDefault()
        let Note = e.target.insertNote.value
        if (Note.length > 0) {
            try {
                const res = await insertNote(selectedObj.Id, Note );
                setShowInsertNote(false)
            } catch (error) {
            }
            setShowInsertNote(false)
        }
    }

    async function getAfterSalesAsync(pagNum = pageNum, searchSTR = search) {
        setLoading(false)
        try {
        let pageSizeChangedByUser = getPageSize("AfterSaleList")
        setPageSize(pageSizeChangedByUser)
        if(layoutContext.currentApplication!=undefined){

            const res = await getAfterSale(pagNum, pageSizeChangedByUser, searchSTR, filterAndSort?.OrderFormat?.join(","), filterAndSort?.Filterlist,layoutContext.currentApplication.Id)
            
            if (res.Data.Data.length > 0) {
                setResponse(res.Data)
                setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            }
        }
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)

    }
    async function setJobInProgressAsync() {
      
        try {
            await setJobInProgress(selectedObj?.Id);
            setForceUpdate(!forceUpdate);
        } catch (error) {
        }
  
    }
    useEffect(() => {
        setSelectedObj(null)
        setResponse(null)
        if(filterAndSort!=null&&layoutContext.currentApplication!=undefined){
            getAfterSalesAsync();
        }
    }, [forceUpdate, filterAndSort ,layoutContext.currentApplication]);
    useEffect(() => {
        let settings = getGridSetting("AfterSaleList")
        setFilterAndSort({ Filterlist: settings[0], OrderFormat: settings[1] })

    }, []);


    let tabContent = [

        { title: "request" ,body: (<>
            <DynamicComponentHOC  onHide={() => { setShowDynamicFormView(false)  }} 
                 componentName={DYNAMICFORMS[dynamicForm?.Name] ?null:dynamicForm?.Name} component={DYNAMICFORMS[dynamicForm?.Name] ?DYNAMICFORMS[dynamicForm?.Name].component:null}
                 stepParams={2} Name={dynamicForm?.Name} modeParams={mode} componentParams={dynamicForm?.Parameters} processId={selectedObj?.PRJ_UID}
                referenceId={null} jobId={selectedObj?.Id}   Id={selectedObj?.SourceId} handleCancelForm={() => setShowDynamicFormView(false)} />
        </>), },
        { title: "ReferencesTree" ,body: (<ReferenceHistory  onHide={() => { setShowHistory(false) }} serviceRequestId={selectedObj?.Id} />), },
        ];

    return (
        <>
       

            <Toolbar current={true} transfer={true} insertnote={true} edit={true} specialreference={true} handleSelectedStartProcess={handleCreateServiceRequest} info={true} chat={false} forceStop={true} attach={true} view={true} handleSearch={searchHandler} id={selectedObj} emptyList={response?.TotalRecords == 0} search={true} handleClickToolbar={clickToolbarCallback} />
            <div style={{
                 overflowY: "auto", 
                height: "78vh",
                 scrollbarWidth: "thin" }} className="col-lg-12 mt-0">
                {loading ? <>{
                    response?.Data?.length > 0 ?
                        <div className="card">
                            <div className="card-body p-0">
                                <>
                                    <DataGrid
                                        customFuction={customFuction}
                                        DoubleClick={(obj) => { setSelectedObj(obj); clickToolbarCallback("view") }}
                                        View={true}
                                        ViewParametr={"NotSeen"}
                                        handlefiltering={(str:any) => {str.Filterlist != undefined  ? setFilterAndSort(str) : setFilterAndSort({...filterAndSort, Filterlist:str})}}
                                        filterStr={filterAndSort.Filterlist}
                                        orderFormatList={filterAndSort.OrderFormat}
                                        orderFormat={(order) => { setFilterAndSort({ ...filterAndSort, OrderFormat: order }) }}
                                        parentName={"AfterSaleList"}
                                        theadData={Object.keys(response.Data[0])}
                                        tbodyData={response.Data}
                                        minCellWidth={80}
                                        handleAttachment={(e) => { setSelectedObj({ Id: e }); setMode("edit"); setShowAttachment(true) }}
                                        selectedRowObjectCallback={selectedObjCallback}
                                        rowSelected={selectedObj}
                                        startIndex={7}
                                        totalRecords={response.TotalRecords}
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        HandleNextPage={handleNextPage}
                                        HandlePrevPage={handlePrevPage}
                                        handlechangePageSize={handleChangePageSize}
                                        first={() => { getAfterSalesAsync(pages); setPageNum(pages) }}
                                        end={() => { getAfterSalesAsync(1); setPageNum(1) }}
                                        handleGoPage={handleGoPage}
                                    />
                                    {footerData && <Footerbar footerContents={footerData} hasBorder={false} />}

                                </>
                            </div>
                        </div>
                        :<Message message={"NoDataForPreview"} type={messageType} />
                        
                }</>
                    : <Loading />
                }

            </div>
            {showAttachment &&
                <ModalForm mode={mode} title={("Attachment")} onHide={() => { setShowAttachment(false) }} show={showAttachment}>
                    <Attachment onHide={() => { setShowAttachment(false)}} mood={mode} endpoint={ENDPOINTS.clientPortal.clientPortal.attachment} ownerId={selectedObj.Id} />
                </ModalForm>
            }

            {showSpecialReference &&
                <ModalForm mode={mode} title={("specialreference")} onHide={() => { setShowSpecialReference(false) }} show={showSpecialReference}>
                   <SpecialReference endpoint={ENDPOINTS.afterSalesApi} processId={selectedObj.PRJ_UID}   referenceId={selectedObj?.ReferenceId} onHide={() => { setShowSpecialReference(false);setForceUpdate(!forceUpdate) }} selectedJob={selectedObj} jobId={selectedObj?.Id} />
                </ModalForm>
            }
            {showDynamicForm &&
                <ModalForm modalHeight={63} mode={mode} title={dynamicForm?.Name} onHide={() => { setShowDynamicForm(false); }} show={showDynamicForm}>
                    <DynamicComponentHOC onHide={() => { setShowDynamicForm(false); }} 
                    componentName={DYNAMICFORMS[dynamicForm?.Name] ?null:dynamicForm?.Name} component={DYNAMICFORMS[dynamicForm?.Name] ?DYNAMICFORMS[dynamicForm?.Name].component:null}
                        reload={() => { setForceUpdate(!forceUpdate) }} componentParams={dynamicForm?.Parameters} processId={selectedStartingProcessId}
                        handleCancelForm={() => setShowDynamicForm(false)} referenceId={undefined} />
                </ModalForm>
            }
            {showHistory &&
                <ModalForm mode={mode} title={("ReferencesTree")} onHide={() => { setShowHistory(false) }} show={showHistory}>
                    <ReferenceHistory onHide={() => { setShowHistory(false) }} serviceRequestId={serviceRequestId} />
                </ModalForm>
            }
            {showCustomerInfo &&
                <ModalForm mode={mode} title={("CustomerInfo")} onHide={() => { setShowCustomerInfo(false) }} show={showCustomerInfo}>
                    <CustomerInfo  customerPositionId={selectedObj.RequesterPositionId} />
                </ModalForm>
            }
            {ShowStopForm &&
                <ModalForm mode={"create"} title={"ForceStop"} onHide={() => setShowStopForm(false)} show={ShowStopForm}>
                    <form onSubmit={stopAsync}>
                        <div className="row mb-1">
                            <div className="col-12">
                                <Textbox
                                    minHeight={3}
                                    textboxType="textarea"
                                    textboxName="forceStopDescription"
                                    labelText="Description"
                                    mode={"create"}
                                    required={true}
                                />
                            </div>
                            <div className="col-4 my-1 my-lg-0 col-lg-4 pt-2">
                                 <CheckableButton
                                    defaultValue={false}
                                    labelText={"sendMessageToRequester"}
                                    inputName={"sendMessageToRequester"}
                                    type="checkbox"
                                    mode={"edit"}
                                />    
                             </div>
                        </div>
                        <div className="col-lg-12 mt-1 btns_form">
                            <Button value="Send" btnType="submit" />
                            <Button
                                btnType="cancel"
                                value="Cancel"
                                onClick={() => { Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => setShowStopForm(false)) }}
                            />
                        </div>
                    </form>
                </ModalForm>
            }
            {showInsertNote &&
                <ModalForm  mode={"create"} title={"insertNote"} onHide={() => setShowInsertNote(false)} show={showInsertNote}>
                    <form onSubmit={insertNoteAsync}>
                        <div className="row mb-1">
                            <div className="col-12">
                                <Textbox
                                    minHeight={3}
                                    textboxType="textarea"
                                    textboxName="insertNote"
                                    labelText="Description"
                                    mode={"create"}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 mt-1 btns_form">
                            <Button value="Send" btnType="submit" />
                            <Button
                                btnType="cancel"
                                value="Cancel"
                                onClick={() => { Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => setShowInsertNote(false)) }}
                            />
                        </div>
                    </form>
                </ModalForm>
            }
            {showDynamicFormView &&
                <ModalForm mode={mode} title={ShowDynamicFormViewLoading ?(dynamicForm?.Name) :""} onHide={() => { setShowDynamicFormView(false); }} show={showDynamicFormView}>
                    {ShowDynamicFormViewLoading ? 
                    <>
                    {dynamicForm ?
                    <TabBar  tabChange={tabChangeHandle} content={tabContent} curentTab={curentTab} defaultTab={"request"} />
                        :
                        <Message message={"NoDataForPreview"} type={"info"} />
                     }
                    </>
                    : <Loading/>
                    }
                </ModalForm>
            }

        </>

    );
}