// import { trans } from "../utils/helpers"
import { useEffect, useState, useRef } from "react";
import { trans } from "utils/helpers"
import "./style.scss"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
interface checkableButtonPropsType {
    labelText: any,
    labelFalseText?: any,
    defaultValue?:any,
    inputName?: string,
    mode?: string,
    width?: string,
    checked?: any,
    title?: any,
    value?:any,
    labelNowrap?: any,
    readOnly?: boolean,
    defaultChecked? : boolean,
    useTrans? : boolean,
    required?: boolean,
    type: "checkbox" | "radio" | "checkradio",
    onclick?: (e:any) => void;
    Change?: (e:any) => void;
    onchange?: (e:any ,event?:any) => void
}
export const CheckableButton = ({width="100%" ,value ,required, useTrans=true, labelText,labelNowrap=false, defaultValue, inputName, type, mode,title, checked =false, defaultChecked,Change, onchange ,onclick ,readOnly ,labelFalseText}: checkableButtonPropsType) => {
    const [check, setCheck] = useState<any>(checked);
    const [loading, setloading] = useState<any>(false);
    useEffect(() => {
        
        setloading(true)
        setCheck(checked)
      }, [checked]);
      useEffect(() => {
        if(loading){
            setloading(false)

        }
        
      }, [loading]);


    return (
        <>
        <div  style={{width:width}} className="checkable-btn my-1">
            <label  className="checkable-btn__label" onClick={()=>{if(!readOnly&&mode!="view"){onclick && onclick(defaultValue)}}}  >
                {type != "checkradio" ?
                <>
            <OverlayTrigger
                  key={"checkable"}
                  placement="top"
                  delay={{"show" : 300 , "hide":0}}
                  overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${"checkable"}`}>
                          {useTrans ? trans(`${labelText}`) : labelText}
                      </Tooltip>
                  }
              >
            <>
            {!loading?
               <>
                <input value={value}  readOnly={readOnly} onChange={(e)=>{Change && Change({[inputName+""]:defaultChecked!=e.target.checked?e.target.checked:null})   ; onchange && onchange(e.target.checked)}}  id={inputName} name={inputName} className={`checkable-btn__input ${mode =="view"? "checkable-btn__disabled" :""}`} type={type}  defaultChecked={defaultChecked} disabled={mode =="view" ? true : (readOnly ? readOnly :false)} defaultValue={defaultValue}  />
                 <span style={mode == "view" ? {boxShadow:"none"}:{}} className={`${type == "radio" ? "checkable-btn__radio-checker" : "checkable-btn__checkbox-checker"} ${mode =="view"? "checkable-btn__disabled" :""}  ${readOnly? "checkable-btn__disabled" :""}`}></span>
                <span className={labelNowrap ?"labelNowrap":""}>{useTrans ? trans(labelText) : labelText}</span></>
            : <></>    
            }
</>
             </OverlayTrigger>  
                </>
                :
                    <>
                    {title && <span style={{minWidth:"36%"}}>{title}: </span>}
                    <CheckableButton  
                        mode={mode}
                        onclick={(e)=>{if(mode != "view"){onchange && onchange(true ,e);Change&&Change({[inputName+""]:defaultChecked!=true ? true:null})}}}
                        readOnly={readOnly}  
                        labelText={labelText } 
                        defaultValue={true}
                        defaultChecked={defaultChecked} 
                        checked={!checked}
                        inputName={inputName} 
                        type={"radio"}
                      />
                    <CheckableButton  
                        mode={mode}
                        onclick={(e)=>{if(mode != "view"){onchange && onchange(false,e);Change&&Change({[inputName+""]:defaultChecked!=false?false:null})}}}
                        readOnly={readOnly}  
                        labelText={labelFalseText} 
                        defaultValue={false}
                        defaultChecked={!defaultChecked} 
                        checked={checked} 
                        inputName={inputName} 
                        type={"radio"}
                      />        
                    </>
                }
            </label>
        </div>
        
        
        </>
    )
}