import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const  getPersonTitlesApi =( async (url ,searchString ,orderBy) =>  await http({
    url: ENDPOINTS.BaseInformation[url].browse,
    method: 'get',
    params:{
        searchString : searchString ,
        orderBy:orderBy
        }
})
.then((response) => (response.data))
.catch((err) => {
    return Promise.reject( err);
})
)
export const  deletePersonTitlesApi =( async (url ,id) =>  await http({
    url: ENDPOINTS.BaseInformation[url].browse,
    method: 'delete',
    params:{
        Id : id 
        }
})
.then((response) => (response.data))
.catch((err) => {
    return Promise.reject( err);
})
)


export const  getTitleId =( async (url ,id) =>  await http({
    url: ENDPOINTS.BaseInformation[url].load,
    method: 'get',
    params:{
        Id:id
    }

})
.then((response) => (response.data))
.catch((err) => {
    return Promise.reject( err);
})
)
export const  sendPersonTitlesApi =( async (url ,data,method  ) =>  await http({
    url: ENDPOINTS.BaseInformation[url].browse,
    method: method,
    data:data

})
.then((response) => (response.data))
.catch((err) => {
    return Promise.reject( err);
})
)
