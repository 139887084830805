import { RoleOperationType } from "types/enum";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";



export const getEditSalesForms = (formName ) => http({
    url: ENDPOINTS.UserAuthorize.assignmentRole,
    method: 'get',
    params:{
        roleOperationType: RoleOperationType.Edit,
        formName:formName
    }

}).then((response) => (response.data))
    .catch(err => {
        return Promise.reject(err)
    })

