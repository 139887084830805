import {  useRef, useState } from "react"
import "./style.scss"
import { ModalForm } from "components/ModalForm/ModalForm"
import { Button } from "components/Button/Button"
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect"
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints"
import { ICON_TYPE, INVENTORYANDSALES_URL } from "utils/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { WarehouseDictionary } from "pages/InventoryAndSales/Sales/SalesInvoice/components/WarehouseDictionary/WarehouseDictionary"
import { trans } from "utils/helpers"

interface ButtonToolbarProps {
    onClick?:()=>void,
    disable?:any,

}
export const ButtonToolbarWarehouse: React.FC<ButtonToolbarProps> = ({

    onClick,
    disable,

}) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
  const buttonToolbar = useRef(null);
   
    const [showInventoryForm, setShowInventoryForm] = useState< any>();

    const [inventory, setInventory] = useState< any>();


    function Submit(e){
        e.preventDefault() 
        if(e.target.Warehouse.value){
          if(e.target.Warehouse.value !=undefined && inventory !=undefined){
              localStorage.setItem("Inventory" ,JSON.stringify({Id:inventory.Id , Name:inventory.Name.Description}) )
              onClick&& onClick()
          }
          setShowInventoryForm(false)
        }
    
      }





    return (
        <>
        {showInventoryForm&& 
        <ModalForm mode={"edit"}  title={"currentinventoryselection" } onHide={() => setShowInventoryForm(false)} show={showInventoryForm}>
          <div className="card">
            <div className="card-body">
              <form onSubmit={Submit}>
              <div className="row col-3">
                <WarehouseDictionary
                  name="Warehouse"
                  label="Warehouse"
                  value={JSON.parse(localStorage.getItem("Inventory")+"")?.Id}
                  onChange={(e ,obj)=>setInventory(obj)}                          
                  mode={"create"}
                  required={true}
                  />
              </div>

              <div className="col-lg-12 mt-1 btns_form">
                  <Button btnType="submit" value="Save"  />
              </div>
              </form>
            </div>
          </div>
        </ModalForm>   
      }
        
        
        <div   className={"toolbar-btn-wrapper  ButtonToolbarWarehouse"}>
            <button ref={buttonToolbar} onClick={(e)=>{setShowInventoryForm(true)}}  className={`btn ${disable ? 'btn-outline-secondary' : 'btn-outline-primary'}  toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center`}   role="button" aria-disabled={disable} disabled={disable}  style={{width:"100px"}}>
                {localStorage.getItem("Inventory")&&
                <div style={{fontWeight:"600"}} className={`toolbar-btn__hotkey ${disable ? "text-secondary" : ""} ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")} mb-0`}>انبارجاری</div>
                }
                <div className="d-flex justify-content-between align-items-center" style={{direction:'ltr'}}>
                  <small className="small">{JSON.parse(localStorage.getItem("Inventory")?localStorage.getItem("Inventory")+"":JSON.stringify({Name:trans("currentinventoryselection")}))?.Name}</small>
                  <div className="small" style={{marginLeft:'0'}}><FontAwesomeIcon icon={[ICON_TYPE , "angle-down"] }/></div>
                </div>
            </button>
        </div>
        </>
    )
}