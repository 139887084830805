import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useRef, useState } from "react";
import { sendFeedMethodsForm } from "./api";

interface FeedMethodsFormPropsType {
    id?: any,
    mode?: any,
    onHide: () => void
    reset: (ans: boolean) => void
}

export const FeedMethodsForm = ({
    id,
    mode,
    onHide,
    reset,
}: FeedMethodsFormPropsType) => {

    let data, method;
    const [FeedMethodsForm, setFeedMethodsForm] = useState<any>(undefined);

    const [resetForm, setResetForm] = useState(false);
    const formRef = useRef<any>("")
    const buttonRef = useRef()
    const [loading , setLoading] = useState(false)  



    async function formSubmit(event) {
        event.preventDefault();
            if (id && mode !== "create") {
                data = {
                    Id: id,
                    Title: formRef.current.Title.value,
                    SystemKey: formRef.current.SystemKey.value,
                    ClassName: formRef.current.ClassName.value,
                    MethodName: formRef.current.MethodName.value,
                };
                method = "put";
            } else {
                data = {

                    Title: formRef.current.Title.value,
                    SystemKey: formRef.current.SystemKey.value,
                    ClassName: formRef.current.ClassName.value,
                    MethodName: formRef.current.MethodName.value,
                };
                method = "post";
            }        
            try {
                await sendFeedMethodsForm(data, method);
                setResetForm(true);
                reset(true);
                onHide();

            } catch (error) {

            }
        
    
    }
    
    async function getDataAsync() {
        setLoading(false)
        try {
         //  const res  = await getFeedMethodsForm(id); 
         //  setFeedMethodsForm(res.Data);  
         //  setleaveHours( res.Data.ActualDuration)
            } catch (error) {
            // setFeedMethodsForm(null); 
        }
        setLoading(true)

    }

    function cancel() {
            onHide()
        
    }


    useEffect(() => {
        if (id && mode !== "create"){
            getDataAsync()
        }else{
            setFeedMethodsForm(null)
            setLoading(true)
            
        }
    }, [id , mode]);

    useEffect(() => {
        if ( mode === "create"){
            setResetForm(true);
        }
    }, [ mode]);

    useEffect(() => {
        if (resetForm) {
          
            setResetForm(false);
        }

    }, [resetForm]);


    return (
        <form  className="" ref={formRef} >
            {loading ? 
            <div className="card">
                <div className="card-body">
                    <div className="container">
                        <div  className="row">
                            <div className="col-4">
                                <Textbox
                                    autoFocus={true}
                                    textboxType="text"
                                    textboxName="Title"
                                    defaultValue={FeedMethodsForm?.Title}
                                    resetForm={resetForm}
                                    labelText="Title"
                                    required={true}
                                    mode={mode}
                                />
                            </div>
                            <div className="col-4">
                                <Textbox
                                    autoFocus={true}
                                    textboxType="systemKey"
                                    textboxName="SystemKey"
                                    defaultValue={FeedMethodsForm?.SystemKey}
                                    resetForm={resetForm}
                                    labelText="SystemKey"
                                    required={true}
                                    mode={mode}
                                />
                            </div>
                            <div className="col-4">
                                <Textbox
                                    autoFocus={true}
                                    textboxType="text"
                                    textboxName="MethodName"
                                    defaultValue={FeedMethodsForm?.MethodName}
                                    resetForm={resetForm}
                                    labelText="MethodName"
                                    required={true}
                                    mode={mode}
                                />
                            </div>
                            <div className="col-4">
                                <Textbox
                                    autoFocus={true}
                                    textboxType="text"
                                    textboxName="ClassName"
                                    defaultValue={FeedMethodsForm?.ClassName}
                                    resetForm={resetForm}
                                    labelText="ClassName"
                                    required={true}
                                    mode={mode}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-end mt-4 pt-2">
                            {mode !== "view" ?
                                <>
                                    <Button ref={buttonRef} onClick={formSubmit}  value="Save" btnType="submit" />
                                    <Button
                                        btnType="cancel"
                                        value="Cancel"
                                        onClick={() => {cancel()}}
                                    />
                                </>
                                :
                                 <Button
                                    btnType="primary"
                                    value="Close"
                                    onClick={() =>{cancel()}}
                                  />
                            }
                        </div>
                    </div>
                </div>
            </div>     
                :<Loading/>
            }
        </form>
    );
}