import { http } from "utils/http";

export const getChildren1 = (Url ,defultparam, params ,id) => http({
    url: Url,
    method: "get",
    params: {
      [params]: id,
      ...defultparam
    }
}).then((res) => {
    
    return res
}).catch((err) => {
    return Promise.reject( err);
});
