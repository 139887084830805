import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { findRoutesTitle, trans } from "utils/helpers";
import { ROUTES } from "utils/routes";
import "./BreadCrumb.scss";

export function BreadCrumb(props) {
  const [focus,setFocus] = useState<boolean>(false)

  const indexRef = useRef<any>(null)


  const PATH_SNIPPETS = props.pathSnippets
  let parentRoute = "";
  let links
  const navigate = useNavigate();

  const handleNavigate = (rout) => {
    
    if(sessionStorage.getItem("changingforms")+""=="true"){
      Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{navigate(rout);sessionStorage.setItem("changingforms" , "false");localStorage.removeItem("formsInChang") });
    }else{
      navigate(rout)
    }
  }
  const extraBreadCrumbItems = PATH_SNIPPETS.map((_, index) => {
    const url = `/${PATH_SNIPPETS.slice(0, index + 1).join("/")}`;


    let foundRoute:any ={}
    for (let item in ROUTES) {
      if(!foundRoute.foundRouteTitle ){
          foundRoute = findRoutesTitle(ROUTES[item], url);
      }else{
        break
      }
    }
    
    
    
    let breadCrumbsItem;
    if (foundRoute.hasComponent) {
      //   document.title = trans(foundRoute.foundRouteTitle)
      breadCrumbsItem = <Link className="bread-crumb__item" key={url} id={`${index}`} onClick={(e)=>{e.preventDefault();handleNavigate(url)}} to={url} onFocus={()=>{setFocus(true);indexRef.current = index}} onBlur={()=>{setFocus(false);indexRef.current = null}}>
        {parentRoute.concat(trans(foundRoute.foundRouteTitle))}
      </Link>
    } else {
      parentRoute = trans(foundRoute.foundRouteTitle) + " - ";
    }
    document.title = parentRoute + trans(foundRoute.foundRouteTitle)
    return breadCrumbsItem;
  });

  const breadCrumbsItems: JSX.Element[] = [
    <Link onClick={(e)=>{e.preventDefault();handleNavigate("/")}} id="-1" className="bread-crumb__item" key="/" to="/" onFocus={()=>{setFocus(true);indexRef.current = -1}} onBlur={()=>{setFocus(false);indexRef.current = null}}>
      {trans(ROUTES.noneApplication.dashboard.title)}
    </Link>,
  ].concat(extraBreadCrumbItems).filter(i => i);



const handleEnter= (event)=>{
  if(event.key == "Enter" && indexRef != null){
    if(indexRef?.current == -1){
      handleNavigate("/")
      handleNavigate("")
    }
    if(indexRef?.current > 0){
      let linkLength =links[indexRef?.current]?.href?.indexOf("#");
      handleNavigate(links[indexRef?.current]?.href?.slice(linkLength));
    }
  }
}

useEffect(()=>{
  if(focus == true){
    document.addEventListener("keydown",handleEnter)
    links = document.querySelectorAll(".bread-crumb__item")
  }
  return () =>{document.removeEventListener("keydown",handleEnter)}
},[focus])


  return (
    <>{ !window.location.href.includes("e-commerce") &&
    
    

    <div className="bread-crumb">

      {breadCrumbsItems.map((breadCrumbsItem, index) => (
        <span key={index}>
          {breadCrumbsItem}
          {index < breadCrumbsItems.length - 1 ? <span key={index} className="bread-crumb__separator">/</span> : null}
        </span>
      ))}

    </div>
        }
    </>
  )
}
