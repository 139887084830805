import { Button } from "components/Button/Button";
import { ModalForm, UserContext } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { useContext, useState } from "react";
import { postReply } from "../api";
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment";
import { ENDPOINTS } from "utils/endpoints";

interface ReplyFormPropsType {
  onHide: () => void;
  handleShowReferenceStepper?: (taskId: string) => void;
  reload: (ans: boolean) => void;
  mode?;
  referenceId;
  jobid;

}
export const ReplyForm = ({
  mode,
  referenceId,
  jobid,
  reload,
  onHide,
  handleShowReferenceStepper,
}: ReplyFormPropsType) => {

  const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);
  const [showattachment, setshowAttachment] = useState(false);

  const handleReplyFormSubmit = (event) => {
    event.preventDefault();
    let data= event.target.Description.value
    sendDataAsync( data, referenceId);
    
  };

  async function sendDataAsync(data ,id) {
    try {
      let res= await postReply(data ,id);
      setUserMadeChanges(false)
      reload(true)
      onHide();
    } catch (err: any) { }
  }
  return (
  <div className="card">  
              {showattachment &&
                <ModalForm title="Attachment" onHide={() => setshowAttachment(false)} show={showattachment}>
                    <Attachment mood={mode} onHide={() => setshowAttachment(false)} endpoint={ENDPOINTS.clientPortal.clientPortal.attachment} ownerId={jobid} />
                </ModalForm>
            }
    <div className="card-body">
     <form action="row" onSubmit={handleReplyFormSubmit} className="">
        <div className="col-12 ">
          <Textbox
            textboxType="textarea"
            minHeight={3}
            textboxName="Description"
            labelText="Description"
          />
        </div>
       <div className="d-flex justify-content-end">
         <Button btnType="primary" value="Attachment" onClick={(e)=>setshowAttachment(true)} />
         <Button btnType="submit" value="send" />
         {handleShowReferenceStepper && (
           <Button
             btnType="submit"
             value="saveAndReference"
             
           />
         )}
         <Button
           btnType="cancel"
           value="cancel"
           onClick={() => { setUserMadeChanges(false)
            onHide()


           }}
         />
       </div>

     </form>
    </div>
   </div>   
  );
};
