import { DataGrid } from "components/DataGrid/DataGrid";
import { Footerbar } from "components/Footerbar/Footerbar";
import { Headerbar } from "components/Headerbar/Headerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { CancelFinishedReference, FinishedReference, getJobRequester, RejectReference } from "pages/OfficeAutomation/Cartable/api/api";
import { useEffect, useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { TerminatedForm } from "../../../Terminated/Terminated";
import { DeleteAction, getActions } from "../../api/api";
import { ActionForm } from "../ActionForm/ActionForm";
import { Attachment } from "../Attachment/Attachment";
import { ReferencesActions } from "../RefrenceAndActionsForm/RefrenceAndActions";


export const Actions = ({rowObject, referenceId, onHide, handleShowReferenceStepper=()=>{},Reload , styleCondition ,showForm=false }: any) => {

    let gridColumns, gridData = [];
    let headerData, footerData;
    let Total_records, pages;
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedId, setSelectedId] = useState<string | any>(null);
    const [showActionForm, setShowActionForm] = useState(false);
    const [showReferenceAndActions, setShowReferenceAndActions] = useState(false);
    const [mode, setmode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [showattachment, setshowAttachment] = useState(false);
    const [endpoint, setEndpoint] = useState<any>();
    const [showTerminatedForm, setShowTerminatedForm] = useState<boolean>(false);
    const [filterlist, setFilterlist] = useState<any>("");
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);

    /* #endregion */
    /* #region pagination */
    const searchHandler = (str) => {
        setPageNum(1);
        if (str == "") {
            setSearch(null)
        } else {
            setSearch(str)
        }
    }
    const handlechangePageSize = (e: any) => {
        setPageSize(e.currentTarget.value);
        setPageNum(1);
        getPageSize("Actions" ,e.currentTarget.value)
        getReferenceActionsAsync();
    };
    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else setPageNum(value);
        } else {
        }
    };
    const HandleNextPage = () => {
        if (pageNum < pages) setPageNum((pageNum) => { return +pageNum + 1 });
    };
    const HandlePrevPage = () => {
        if (pageNum > 1) setPageNum((pageNum) => { return +pageNum - 1 });
    };
    /* #endregion */
    /* #region dataGridActions */
    const handleCreateAction = () => {
        setmode("create")
        setShowActionForm(true);
    }
    const handleEditRow = () => {
        setmode("edit")
        setShowActionForm(true);
    }
    const handleDeleteRow =async (id: string) => {
        try {
            await DeleteAction(id);
            getReferenceActionsAsync()
        } catch (error) {

        }
    }
    const handleViewRow = () => {

        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alert", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setmode("view")
                setShowActionForm(true);
            });
        }else{
            setmode("view")
            setShowActionForm(true);
        };

    }
    const handleAttachmentRow = (id) => {
        setSelectedId(id)
        setEndpoint( ENDPOINTS.cartable.action.attachments)
        setmode("attach")    
        setshowAttachment(true)
    }

    const handleAttachment = () => {
        setEndpoint(ENDPOINTS.cartable.reference.attachments)
        setSelectedId(referenceId)
        setmode("allattach")
        setshowAttachment(true)
    }
        
    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true" && showActionForm){
            Toast(trans("modalclosemessage"),"warning","alert", ()=>{setSelectedId(obj.Id) ;sessionStorage.setItem("changingforms" , "false");});
        }else{
            setSelectedId(obj.Id);
        };
    }
    /* #endregion */   
     async function chatWithRequester(e) {
            try {
                const res = await getJobRequester(e);
                let item = {
                    Id:res?.Data.Id ,
                    name: res?.Data.Description
                }
                localStorage.setItem("JobRequester",JSON.stringify(item) );
                window.open(`#/message`)
            } catch (error) {

            }
         
    }

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel": downloadCSV(response?.Data?.Data ,1 ,trans("Actions")); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "view": if (selectedId) handleViewRow(); break;
            case "edit": if (selectedId) handleEditRow(); break;
            case "finish": if (referenceId) { setShowTerminatedForm(true) } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "chat": if (referenceId) { chatWithRequester(rowObject.JobId) } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "help": alert("call api help"); break;
            case "remove": if (selectedId) {handleDeleteRow(selectedId) } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "attach": handleAttachment(); break;
            case "history": if (referenceId) setShowReferenceAndActions(true); break;
            case "reference":
                if (referenceId) {handleShowReferenceStepper && handleShowReferenceStepper(referenceId); onHide() }
                break;
        }
    };
    /* #endregion */ 
       async function handleFinishedReference(type ,text="" ,sendMsg=false) {
        try {
            if(type==="Terminated"){
                await FinishedReference(referenceId,sendMsg ,text);        
            }else if(type==="CancelTerminate"){
                await CancelFinishedReference(referenceId);
            }else{
                await RejectReference(referenceId ,text,sendMsg);
            }
            setShowTerminatedForm(false)
            onHide()
            Reload&&  Reload()
        } catch (error) {
            
        }
        
    }

    async function getReferenceActionsAsync() {
        setLoading(false)
        let sizePage = getPageSize("Actions")
        setPageSize(sizePage)
        try {
            const res = await getActions(pageNum, sizePage, referenceId ,orderBy.length == 0 ?null:  orderBy.join(",")  ,filterlist ,search);     
            setResponse(res);
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
  
        setLoading(true)
    }
    useEffect(() => {
   getReferenceActionsAsync()

    }, [pageNum, forceUpdate,orderBy ,filterlist,search ]);
    useEffect(() => {
       if(showForm){
        handleCreateAction()
       }
    }, []);


 

    if (response &&
        response?.Data && response.Data?.Data?.length>0) {
        gridColumns = Object.keys(response.Data.Data[0]);
        gridData = response.Data.Data;
        headerData = response.Data.Header;
        footerData = response.Data.Footer;
        Total_records = response.Data.TotalRecords;
    }
    pages = Total_records > pageSize ? Math.ceil(Total_records / pageSize) : 1;
    return (
        <>
            <div className="cartable-action">
                <Toolbar  handleSearch={searchHandler} id={selectedId}finish={true} history={false} chat={true}  emptyList={response?.Data?.TotalRecords ==0||response?.Data?.Data==null} reference={true}  create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback}  />
                {headerData && <Headerbar headerContents={headerData[0]} hasBorder={true}  />}
                {showTerminatedForm &&
                    <ModalForm mode={mode} title={("Terminate")} onHide={() => { setShowTerminatedForm(false) }} show={showTerminatedForm} >
                        <TerminatedForm rowObject={rowObject} submit={handleFinishedReference}  onHide={() => setShowTerminatedForm(false)} />
                    </ModalForm>
                }   
                {showReferenceAndActions &&
                    <ModalForm mode={mode} title={("ReferenceHistory")} onHide={() => { setShowReferenceAndActions(false) }} show={showReferenceAndActions}>
                        <ReferencesActions
                            referenceId={referenceId}
                        />
                    </ModalForm>
                }
                {showActionForm &&
                    <ActionForm
                        onHide={() => setShowActionForm(false)}
                        mode={mode}
                        reset={(e)=>{getReferenceActionsAsync()}}
                        id={selectedId}
                        referenceId={referenceId}
                    />
                }
                {loading ?
                    (response?.Data?.Data.length > 0 ?
                        <div className="card ">
                            <div className="p-0">
                                <>
                                    <DataGrid
                                        handlefiltering={(str)=>{setFilterlist(str)  }}
                                        filterStr={filterlist}
                                        orderFormat={setOrderBy}
                                        orderFormatList={orderBy}
                                        parentName={"Actions"}
                                        theadData={gridColumns}
                                        tbodyData={gridData}
                                        minCellWidth={80}
                                        selectedRowObjectCallback={selectedIdCallback}
                                        handleEdit={handleEditRow}
                                        handleDelete={handleDeleteRow}
                                        handleView={handleViewRow}
                                        handleAttachment={handleAttachmentRow}
                                        totalRecords={Total_records}
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        HandleNextPage={HandleNextPage}
                                        HandlePrevPage={HandlePrevPage}
                                        handlechangePageSize={handlechangePageSize}
                                        first={() => setPageNum(pages)}
                                        end={() => setPageNum(1)}
                                        handleGoPage={handleGoPage}
                                        scrollHeight={"150px"}
                                    />
                                    {footerData && <Footerbar footerContents={footerData} hasBorder={false} />}
                                    {showattachment &&
                                     <ModalForm title="Attachment" onHide={() => setshowAttachment(false)} show={showattachment}>
                                        <Attachment haveVersion={true}  onHide={() => setshowAttachment(false)} mood={mode} endpoint={endpoint} ownerId={selectedId} />
                                    </ModalForm>
                                    }
                                </>
                            </div>
                        </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    )
                    : <Loading />
                }

            </div>
        </>

    );
}