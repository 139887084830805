import { ComponentHOC } from "components/ComponentHOC/ComponentHOC";
import { useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import { BaseRouteProps, FoundRouteType, KeyValueObject } from "types/types";
import { REPORT_URL } from "./constants";
import { http } from "./http";
import { ENDPOINTS } from "./endpoints";
import { Toast } from "components/ToastShow/ToastShow";
import { findAllByRole } from "@testing-library/react";

// export function submitForm(event): boolean { // بررسی می کند که آیا فیلد های ریکوایرد خالی هستند یا خیر
//   event.preventDefault()
//   let result = true;
//   let requiredInputs = document.querySelectorAll(".required-input");
//   if (requiredInputs.length !== 0) {
//     requiredInputs.forEach((requiredInput) => {
//
//       if ((requiredInput as HTMLInputElement).value.length == 0) {
//         (requiredInput.nextSibling?.firstChild as HTMLElement).style.display = "inline-block";
//         result = false;
//       } else {
//         (requiredInput.nextSibling?.firstChild as HTMLElement).style.display = "none";
//       }
//     })
//   }
//   return result;
// }
export function urlParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const result: { [key: string]: string } = {};
  for (let i of Array.from(urlParams.keys()))
    result[i] = urlParams.get(i) || "";

  return result;
}
/* string wordWithoutLanguage = word.Remove(word.Length - 2, 2);
string wordLanguage = word.Substring(word.Length - 2, 2);
if (wordLanguage == "EN")
    return resourceSet.GetString(wordWithoutLanguage) + resourceSet.GetString("EN");
else
    return resourceSet.GetString(word);
 */
export function trans(
  key: string,
  params?: string | (KeyValueObject | (string | number | boolean)[]),
  defaultValue?: string
) {
  let lang: any = localStorage.getItem("lang");
  let data: any = localStorage.getItem(lang);
  const paramList = params && typeof params != "string" ? params : {};
  const defaultV = defaultValue || (typeof params == "string" ? params : key);
  let val = defaultValue;
  if (data && key) {
    data = JSON.parse(data);
    if (key.slice(-2) == "EN" && key.length > 2) {
      key = key.replace("EN", "");
      if (data[key.toLowerCase()]) {
        val = data[key.toLowerCase()] + " " + trans("EN");
      } else {
        val =
          data[key.toLowerCase().replace("form", "")] + " " + trans("EN") ||
          defaultV + " " + trans("EN");
      }
    } else {
      if (data[key.toLowerCase()]) {
        val = data[key.toLowerCase()];
      } else {
        val = data[key.toLowerCase().replace("form", "")] || defaultV;
      }
    }
  }
  for (let i in paramList) {
    val = val?.replace(`{${i}}`, paramList[i]);
  }

  if (val == undefined) {
    return key as string;
  } else {
    return val as string;
  }
}
(window as any).t = trans;

export function changeDateFormat(date) {
  let newDate = date.split("T")[0].replaceAll("-", "/");
  return (
    newDate +
    " " +
    date.split("T")[1].split(":")[0] +
    ":" +
    date.split("T")[1].split(":")[1]
  );
}
export function cleanObject(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

export function findRoutesTitle(
  targetRoutes,
  wantedRoute: string
): FoundRouteType {
  let foundRoute: FoundRouteType = {
    foundRouteTitle: "",
    hasComponent: false,
  };

  Object.keys(targetRoutes).every((item) => {
    if (targetRoutes[item].route === wantedRoute) {
      foundRoute.foundRouteTitle = targetRoutes[item].title;
      if (targetRoutes[item].component !== undefined) {
        foundRoute.hasComponent = true;
      }
      return false;
    } else if (
      foundRoute.foundRouteTitle === "" &&
      targetRoutes[item].routes !== undefined
    ) {
      foundRoute = findRoutesTitle(targetRoutes[item].routes, wantedRoute);
    }
    return true;
  });

  return foundRoute;
}

export const renderRoutes = (routeList: BaseRouteProps, PositionId) => {
  const routes = (routeList || {}) as any;

  return (
    <Routes>
      {Object.keys(routes).map((app: any) =>
        routes[app].routes ? (
          <Route key={routes[app].route} path={routes[app].route}>
            routes[k]?.routes ?(
            {Object.keys(routes[app].routes).map((j: any, index) => (
              <Route
                key={routes[app].routes[j].route}
                path={routes[app].routes[j].route}
                element={<ComponentHOC {...routes[app].routes[j]} />}
              />
            ))}
            :
            <Route
              key={routes[app].route}
              path={routes[app].route}
              element={<ComponentHOC {...routes[app]} />}
            />
            )
          </Route>
        ) : (
          <>
            {routes[app] &&
              Object.keys(routes[app]).map((k: any) =>
                routes[app]?.[k]?.routes ? (
                  <Route
                    key={routes[app]?.[k]?.route}
                    path={routes[app]?.[k]?.route}
                  >
                    routes[app]?.[k]?.routes ?(
                    {Object.keys(routes[app]?.[k]?.routes).map(
                      (j: any, index) => (
                        <Route
                          key={routes[app]?.[k]?.routes[j].route}
                          path={routes[app]?.[k]?.routes[j].route}
                          element={
                            <ComponentHOC {...routes[app]?.[k]?.routes[j]} />
                          }
                        />
                      )
                    )}
                    :
                    <Route
                      key={routes[app]?.[k]?.route}
                      path={routes[app]?.[k]?.route}
                      element={<ComponentHOC {...routes[app]?.[k]} />}
                    />
                    )
                  </Route>
                ) : (
                  <>
                    <Route
                      key={routes[app]?.[k]?.route}
                      path={routes[app]?.[k]?.route}
                      element={<ComponentHOC {...routes[app]?.[k]} />}
                    />
                  </>
                )
              )}
          </>
        )
      )}
    </Routes>
  );
};

export function findValueByKey(object: Object, objKey: string) {
  let value: string = "";
  Object.keys(object).forEach((key) => {
    if (key === objKey) {
      value = object[key];
    }
  });
  return value;
}

export const useOutsideClick = (callback) => {
  const ref = useRef<any>();

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref]);

  return ref;
};

export const useInsideClick = (callback) => {
  const ref = useRef<any>();

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref]);

  return ref;
};

export const isInViewport = function (element: HTMLElement): boolean {
  const distance = element.getBoundingClientRect();

  return (
    distance.top >= 0 &&
    distance.left >= 0 &&
    distance.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    distance.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export function dateConverter(
  year: number,
  month: number,
  day: number
): number[] {
  //تبدیل تاریخ میلادی به شمسی
  var g_d_m, jy, jm, jd, gy2, days;
  g_d_m = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
  gy2 = month > 2 ? year + 1 : year;
  days =
    355666 +
    365 * year +
    ~~((gy2 + 3) / 4) -
    ~~((gy2 + 99) / 100) +
    ~~((gy2 + 399) / 400) +
    day +
    g_d_m[month - 1];
  jy = -1595 + 33 * ~~(days / 12053);
  days %= 12053;
  jy += 4 * ~~(days / 1461);
  days %= 1461;
  if (days > 365) {
    jy += ~~((days - 1) / 365);
    days = (days - 1) % 365;
  }
  if (days < 186) {
    jm = 1 + ~~(days / 31);
    jd = 1 + (days % 31);
  } else {
    jm = 7 + ~~((days - 186) / 30);
    jd = 1 + ((days - 186) % 30);
  }
  return [jy, jm, jd];
}

export function getPageSize(name, newSize?) {
  //گرفتن پیج سایز از لوکال استوریج

  let sizePage;
  let hedTables = localStorage.getItem("userHeadTable");
  let locallist;
  let flag = true;
  if (hedTables == null) {
    locallist = [];
  } else {
    locallist = JSON.parse(hedTables);
  }
  locallist.forEach((item) => {
    if (item[0] == name) {
      sizePage = item[3];
      flag = false;
      if (newSize) {
        sizePage = newSize;
        item[3] = newSize;
      }
      if (sizePage) {
      } else {
        sizePage = newSize ? newSize : 10;
      }
    }
  });
  if (flag) {
    sizePage = 10;
    locallist.push([name, [], [], 10, [], ""]);
  }
  localStorage.setItem("userHeadTable", JSON.stringify(locallist));

  return sizePage;
}
export function getGridSetting(name) {
  //گرفتن پیج سایز از لوکال استوریج

  let settings;
  let hedTables = localStorage.getItem("userHeadTable");
  let locallist;
  let flag = true;
  if (hedTables == null) {
    locallist = [];
  } else {
    locallist = JSON.parse(hedTables);
  }
  locallist.forEach((item) => {
    if (item[0] == name) {
      settings = [item[4] ? item[4] : "", item[5] ? item[5] : []];

      flag = false;
    }
  });
  if (flag) {
    settings = ["", []];
  }

  return settings;
}

export function formatMinutes(minutes) {
  return minutes < 10 ? `0${minutes}` : minutes;
}

export function formatSeconds(seconds) {
  return seconds < 10 ? `0${seconds}` : seconds;
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function convertToCSV(objArray) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";

      line += array[i][index];
    }

    str += line + "\r\n";
  }
  return str;
}

function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  let jsonObject = JSON.stringify(items);

  let csv: any = "\ufeff";
  csv += convertToCSV(jsonObject);
  let exportedFilenmae = fileTitle + ".csv" || "export.csv";

  let blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
    type: "text/csv;charset=utf-8;",
  });

  if (navigator.msSaveOrOpenBlob) {
    // IE 10+
    navigator.msSaveOrOpenBlob(blob, exportedFilenmae);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function downloadCSV(data = [], startindex, fileName) {
  let headers = {};
  if (data?.length > 0) {
    Object.keys(data[0]).map((item, index) => {
      if (index >= startindex) {
        headers[item] = trans(item);
      }
    });
    let itemsFormatted: any = [];
    data.forEach((item) => {
      let obj = {};
      Object.keys(headers).map((hed) => {
        obj[hed] =
          `${item[hed]}`.length > 0
            ? `${item[hed]}`.replaceAll(",", " ").replaceAll(/[\r\n]/gm, " ")
            : " ";
      });
      itemsFormatted.push(obj);
    });
    exportCSVFile(headers, itemsFormatted, fileName);
  } else {
  }
}

export const postDataForPrint = (repData, type) =>
  http({
    baseURL: `${process.env.REACT_APP_REPORTING_SERVICE_URL}`,
    url: ENDPOINTS.reportST[type == 1 ? "viewer" : "designer"].saveData,
    method: "post",
    data: repData,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export async function GetReportWithJsonData(id, repData, type) {
  try {
  } catch (er: any) {}

  let _objData: any = new FormData();

  _objData.append("repData", JSON.stringify(repData));

  const res = await postDataForPrint(_objData, type);
  window.open(
    `${
      `${process.env.REACT_APP_REPORTING_SERVICE_URL}`+
      ENDPOINTS.reportST[type == 1 ? "viewer" : "designer"].getReport
    }?id=${id}&dataId=${res}`,
    "_blank"
  );
}
export async function addItemToBasket(item, id) {
  let Basket: any = localStorage.getItem("Basket");
  if (Basket) {
    Basket = JSON.parse(Basket);
    if (+item.order[id].count == 0) {
      if (Basket[item.Id]) {
        delete Basket?.[item.Id].order[id];
        if (Object.values(Basket?.[item.Id].order).length == 0) {
          delete Basket?.[item.Id];
        }
      }
    } else {
      if (Basket[item.Id] != undefined) {
        Basket[item.Id].order = {
          ...Basket[item.Id].order,
          [id]: item.order[id],
        };
      } else {
        Basket = {
          ...Basket,
          [item.Id]: item,
        };
      }
    }
  } else {
    Basket = {
      [item.Id]: item,
    };
  }
  localStorage.setItem("Basket", JSON.stringify(Basket));
}

export function getBasketData() {
  let Basket: any = localStorage.getItem("Basket");
  if (Basket) {
    Basket = Object.values(JSON.parse(Basket));
  } else {
    Basket = [];
  }
  return Basket;
}

function isNumber(value) {
  return typeof value === "number" && !isNaN(value);
}
export function GroupingPriceFunction(price) {
  if (isNumber(price) || isNumber(+price)) {
    if (price && price.length > 0) {
      price = price.replaceAll(",", "");
    }
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return price;
  }
}

export function padWithZeros(num, str) {
  if (str == "null") {
    return "";
  } else {
    let difference = num - str.length;
    if (difference > 0) {
      return "0".repeat(difference) + str;
    }
    return str;
  }
}

/* export function padWithZeros(length, str) {
  str = str.toString();

  return str.padStart(length, '0');
}

 */

export function roundOrTruncate(number, RoundingPlace, floor:boolean) {
  let num = RoundingPlace > 0 ? Math.floor(number) : number;
  if (num) {
    if (floor) {
      const factor = Math.pow(10, RoundingPlace);
      return Math.floor(+num * factor) / factor;
    } else {
      const factor = Math.pow(10, RoundingPlace);
      return Math.round(+num * factor) / factor;
    }
  } else {
    return number;
  }
}

export function convertAddress(obj) {
  let str = "";
  try {
    const converData = JSON.parse(obj);
    Object.keys(converData).forEach((item, index) => {
      if (
        converData[item] &&
        item !== "extradescription" &&
        item !== "description"
      ) {
        str += `${trans(item)} : ${converData[item]}  `;
      }
      return str;
    });
  } catch (error) {
    str = obj;
  }

  return str;
}
