

import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";

import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { getListBarcodes, getBarcodes, postBarcodes, putBarcodes, deleteBarcodes } from "./api";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";


interface Form {

}
export const BarcodesSetting = ({ }: Form) => {

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [flag, setFlag] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [userChanges, setUserChanges] = useState({});
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const [pages, setPages] = useState<number>(0);


    const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }
    const handleCreateAction = () => {
        // setSelectedObj(null);
        setEditForm(null)
        setMode("create")
        setShowForm(true)
    }
    const handleEditRow = () => {
        setMode("edit");
        setShowForm(true)
    }
    const handleDeleteRow = async() => {
        try {
            const res = await deleteBarcodes(selectedObj.Id)
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }
    }
    const handleViewRow = () => {
        setMode("view")
        setShowForm(true)
    }

    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false"); setUserChanges({}) });
        }else{
            setSelectedObj(obj)         
        };
    }

    const submit = (e) => {
        e.preventDefault();
        if (editForm == null) {
            postBarcodes({                     
                IsDefault: e.target.checkbox.checked,
                Title: e.target.Barcodes.value,
                SystemKey: e.target.systemKey.value, 
                BarcodeType: +e.target.BarcodeType.value,
                BarcodeRawData: e.target.BarcodeRawData.value,
                BarcodeFormat: e.target.BarcodeFormat.value,
                Qty: +e.target.Qty.value,
            
            }).then(() => {sessionStorage.setItem("changingforms" , "false");setUserChanges({});getBarcodesAsync();cancel()  });
        } else {
            putBarcodes({
                Title: e.target.Barcodes.value,
                ActivityStatus: +e.target.BarcodesSelect.value , 
                systemKey: e.target.systemKey.value,
                BarcodeType: +e.target.BarcodeType.value,
                BarcodeRawData: e.target.BarcodeRawData.value,
                BarcodeFormat:e.target.BarcodeFormat.value,
                Qty: +e.target.Qty.value,
                IsDefault: e.target.checkbox.checked,
                Id: selectedObj.Id,


       
             }).then(() => {sessionStorage.setItem("changingforms" , "false");setUserChanges({}); getBarcodesAsync();cancel();  })
        }
        setShowForm(false)

    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                setEditForm(null);
                setShowForm(false)
            });
        }else{
            setEditForm(null);
            setUserChanges({});

            setShowForm(false)     
        };
    }
    async function getBarcodesAsync(pagNum = pageNum) {
        setLoading(false)
        try {
            let pageSizeChangedByUser = getPageSize("Barcodes");
            setPageSize(pageSizeChangedByUser);
            const res = await getListBarcodes(search ,orderBy.length == 0 ?null:  orderBy.join(","),pagNum,pageSizeChangedByUser);
            setResponse(res.Data);
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
            if (res.Data.Data.length > 0) {
                setResponse(res.Data);
        
                setPages(
                  res.Data.TotalRecords > pageSizeChangedByUser
                    ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser)
                    : 1
                );
              }
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)
    }
    async function getBarcodesasync() {
        setFormLoading(false)
        try {
            const data = await getBarcodes(selectedObj?.Id);
            setEditForm({ 
                Title: data.Data.Title,
                Status: data.Data.ActivityStatus,
                systemKey: data.Data.SystemKey,
                IsDefault: data.Data.IsDefault,
                BarcodeType: data.Data.BarcodeType,
                BarcodeRawData: data.Data.BarcodeRawData,
                BarcodeFormat: data.Data.BarcodeFormat,
                Qty: data.Data.Qty,
                Id: data.Data.Id,
            });
        } catch (error) {
            
        }
        setFormLoading(true)
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };

      const handleGoPage = (value) => {
        if (value) {
          setPageNum(+value);
          getBarcodesAsync(+value);
        } else {
        }
      };
      const handleNextPage = () => {
        if (response.TotalRecords && response.TotalRecords / pageSize > pageNum) {
          setPageNum((pageNum) => {
            return +pageNum + 1;
          });
          getBarcodesAsync(pageNum + 1);
        }
      };
      const handlePrevPage = () => {
        if (pageNum > 1) {
          setPageNum((pageNum) => {
            return +pageNum - 1;
          });
          getBarcodesAsync(pageNum - 1);
        }
      };
    
      const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("Barcodes", e.currentTarget.value);
        getBarcodesAsync(1);
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        getBarcodesAsync();
    }, [forceUpdate, flag  ,search , orderBy]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);
    useEffect(() => {
        if(showForm &&selectedObj && mode != "create"){
           getBarcodesasync()
        }else{
            setFormLoading(true)
        }
       
    }, [selectedObj ,showForm ,mode]);

    if (flag) {
        getBarcodesAsync()
        setFlag(false)

    }

    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,1 ,trans("BarcodesSetting"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id)selectedObj.SystemReserved ? Toast(trans("Msg_CanNotEditSystemReservedData") , "error") : handleEditRow(); break;
            case "help": alert("call api help"); break;
        }
    };
    /* #endregion */
    return (
        <>
            <div className="cartable-action">
                <Toolbar warehouse={false} handleSearch={searchHandler}  id={selectedObj?.Id} emptyList={response == null || response.length == 0} remove={true} create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card">
                    <div className="">

                            <>
                                {showForm&& 
                                <>                            
                                    <div className="card mb-4">
                                        {formLoading ? 
                                        <form className="TypeSettingForm row card-body " onSubmit={submit}>
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"Barcodes"} textboxType="text" resetForm={resetForm} labelText={"Title"} mode={mode}  defaultValue={editForm?.Title} />
                                        </div>     
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"systemKey"} textboxType="systemKey" resetForm={resetForm} labelText={"systemKey"} mode={mode}  defaultValue={editForm?.systemKey} />
                                        </div> 
                                                  
                                        <div className="col-lg-4 mt-1">
                                            <EnumerationTypeSelect label="BarcodeType" enumTypeSelect="BarcodeType" value={editForm?.BarcodeType} Change={(e)=>{setUserChanges({...userChanges , ...e})}} name="BarcodeType"  mode={mode} />
                                        </div>                            
                                         
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"BarcodeRawData"} textboxType="text" resetForm={resetForm} labelText={"BarcodeRawData"} mode={mode}  defaultValue={editForm?.BarcodeRawData} />
                                        </div> 

                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"BarcodeFormat"} textboxType="text" resetForm={resetForm} labelText={"BarcodeFormat"} mode={mode}  defaultValue={editForm?.BarcodeFormat} />
                                        </div> 

                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} textboxName={"Qty"} textboxType="number" resetForm={resetForm} labelText={"Qty"} mode={mode}  defaultValue={editForm?.Qty} />
                                        </div> 

                                        {mode != "create" &&          
                                        <div className="col-lg-4 mt-1">
                                            <EnumerationTypeSelect
                                             value={editForm?.Status}
                                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                             name="BarcodesSelect"
                                             mode={mode}
                                             label="Status"
                                             enumTypeSelect="ActivityStatus"
                                            />
                                        </div>                            
                                         }
                                        <div className=" mt-4 mb-4" >
                                            <CheckableButton
                                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                               defaultChecked={editForm?.IsDefault}
                                               defaultValue={editForm?.IsDefault}
                                               labelText={"IsDefault"}
                                               inputName={"checkbox"}
                                               type="checkbox"
                                               mode={mode}
                                            />                                
                                        </div>    
                                        {  mode !="view" ?
                                            <div className="col-lg-12 mt-1 btns_form">
                                              <Button value="Save" btnType="submit" />
                                              <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                                            </div>
                                            :
                                            <div className="col-lg-12 mt-1 btns_form">
                                              <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                                            </div>
                                          }                        
                                    </form> :<Loading/>}

                                  </div >
                                   </>
                                }
                                {
                                    loading ?
                                    response?.Data?.length > 0 ?
                                        <>
                                            <DataGrid
                                                parentName={"BarcodesSetting"}
                                                orderFormat={setOrderBy}
                                                orderFormatList={orderBy}
                                                startIndex={1}
                                                theadData={Object.keys(response?.Data[0])}
                                                tbodyData={response?.Data}
                                                minCellWidth={80}
                                                scrollHeight={showForm? "25vh": "67vh"}
                                                selectedRowObjectCallback={selectedIdCallback}
                                                handleEdit={handleEditRow}
                                                handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                                handleView={handleViewRow}
                                                totalRecords={response?.TotalRecords}
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                HandleNextPage={handleNextPage}
                                                HandlePrevPage={handlePrevPage}
                                                handlechangePageSize={handleChangePageSize}
                                                first={() => {
                                                    getBarcodesAsync(pages);
                                                  setPageNum(pages);
                                                }}
                                                end={() => {
                                                    getBarcodesAsync(1);
                                                  setPageNum(1);
                                                }}
                                                handleGoPage={handleGoPage}
                                            />
                                        </>
                                        : <Message message={catchMessage.msg} type={catchMessage.type} />
                                        : <Loading/>
                                }
                            </>

                    </div>
                </div>
            </div>
        </>

    );
}