import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_TYPE } from "utils/constants";

interface TabEventNotificationProps{
    data?:any
    onHide?: () => void
  }

export const TabEventNotification = ({onHide,data}:TabEventNotificationProps) => {
    return (
      <>
        <div className="Event-menu__options">
          <button className="btn btn-sm" onClick={()=>{onHide && onHide()}}>
          <FontAwesomeIcon icon={[ICON_TYPE, "xmark"]} className="text-danger fw-bold font-size-lg"/>
          </button>
          <div className="Event-menu__options__box">
          {data?.map((option,index)=>(
            <div className={`Event-menu__option`} data-value={option.Id}>
                <div>
                    <span className='font-size-lg'>{option?.Title}</span>
                    <span className='text-muted font-size-sm mx-1'>{option.Time}</span>
                </div>
            </div>
          ))}
          </div>
        </div>
      </>
      );
}
