import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getApplications = () => http({
    url: ENDPOINTS.application.browse,
    method: 'get',

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});


