import  { useEffect, useState } from "react";
import { SelectBox } from "components/Select/Select";
import { InputLoad } from "components/InputLoading/inputLoading";
import { getWarehouseDictionaryApi } from "./api";


interface DictionaryTypeSelectProps {
    value?: any;
    mode?: string;
    resetForm?: boolean;
    name: string;
    label: string;
    baseUrl?: string;
    required?: boolean;
    transe?:boolean;
    params?:any;
    recordedData?:any;
    Change?: (e) => void;
    onChange?: (e: any , obj?:any) => void,
    useTrans?:boolean;

}
export const WarehouseDictionary = ({recordedData ,params ,label ,required=false ,baseUrl ,useTrans=false  ,value ,name ,mode ,resetForm ,onChange ,Change }:DictionaryTypeSelectProps) => {
    const [WarehouseDictionary, setWarehouseDictionary] = useState<any>();
    const [defaultval, setdefault] = useState(value);
    const [errcode, setErrcode] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

  

    const getWarehouseDictionaryAsync = async ()=>{
        setLoading(false)
        try {
        const res = await getWarehouseDictionaryApi()
        if(res?.Data){
            setWarehouseDictionary(res?.Data)
            if(!value)
                onChange && onChange(Object.keys(res?.Data)[0],{Id:Object.keys(res?.Data)[0] , Name:Object.values(res?.Data)[0]	})           
            else
               onChange && onChange(value,{Id:value , Name:res?.Data[value]	})           

        }

        } catch (error:any) {
            setErrcode(error.response?.status)
            setWarehouseDictionary(null)
        }
        setLoading(true)
      }

      const handleOnChange = (e) =>{
        onChange&&onChange(e.target.value ,{Id:e.target.value , Name:WarehouseDictionary[e.target.value]});
        Change && Change(true)

        if(e.target && e.target.value !=(value ? value : Object.keys(WarehouseDictionary)[0])){
            Change&&Change({[name] :e.target.value}) 
        }else{
            Change&&Change({[name] :null}) }
        }
      

    useEffect(() => {
        getWarehouseDictionaryAsync()

    }, []);
    useEffect(() => {
        if(params){
            //getWarehouseDictionaryAsync()
    
        } 
      }, [params]);

    useEffect(() => {
        setdefault(value)
    }, [resetForm]);
    

    return (
        <>
        {WarehouseDictionary && loading ?
        <>
            <SelectBox
                onChange={(e)=>{handleOnChange(e)}}
                lable={label}
                selectType={'select'}
                options={Object.values(WarehouseDictionary).map((item:any)=>{return item.Description})}
                val={Object.keys(WarehouseDictionary) }
                name={name}
                value={value}
                resetForm={resetForm}
                mode={mode}
                required={required}
                useTrans={useTrans}
                recordedData={recordedData}
            />    
            <div className="text-box__msg-wrapper"></div>    
        </>
            :
            <InputLoad  msg={errcode} required={required}   labelText={label }  onClick={getWarehouseDictionaryAsync}/>
        }
        </>
        

    );
};