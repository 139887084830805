import { Button } from "components/Button/Button";

import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import {
  TREASURY_AND_ACCOUNTING_URL
} from "utils/constants";
import {
  ENDPOINTSTREASURYANDACCOUNTING
} from "utils/endpoints";
import { trans } from "utils/helpers";

import Datepicker from "components/DatePicker/DatePicker";
import { getReceiptById, postReceipt } from "./api";
import { DocsClassification } from "./components/DocsClassification/DocsClassification";

interface ReceiptFormPropsType {
  selectedObj;
  showForm: (e: boolean) => void;
  mode;
  relod: () => void;
  handleCopyItem?: any;
}
export const ReceiptForm = ({
  selectedObj,
  showForm,
  mode,
  relod,
  handleCopyItem,
}: ReceiptFormPropsType) => {
  const [resetForm, setResetForm] = useState(false);
  const [response, setResponse] = useState<any>();

  const [loading, setLoading] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});

  const [receiptDate, setReceiptDate] = useState<any>();

  const [docsClassification, setDocsClassification] = useState<any>();

  async function getReceiptByIdAsync() {
    setLoading(false);

    try {
      if (selectedObj.id) {
        const res = await getReceiptById(selectedObj.id);
        setResponse(res.Data);
      } else {
        setResponse(null);
      }
    } catch (err: any) {}
    setLoading(true);
  }

  const handleReceiptFormSubmit = (event) => {
    event.preventDefault();

    let data = {
      BranchId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      PeriodId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      DocClassificationId: docsClassification,
      TreasurySubjectId: event.target.TreasurySubjectId?.value,
      CashAccountId: event.target.CashAccountId?.value,
      RecipientPositionId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      PayerPositionId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      PRJ_UID: event.target.PRJ_UID?.value,
      IsWorkflowStarted: event.target.IsWorkflowStarted?.checked,
      ReceiptNo: +event.target.ReceiptNo?.value,
      ReceiptDate: receiptDate,
      PayerPositionName: event.target.PayerPositionName?.value,
      PayerPositionNameEN: event.target.PayerPositionNameEN?.value,
      IsGrouped: event.target.IsGrouped?.checked,
      TotalReceipt: +event.target.TotalReceipt?.value,
      Description: event.target.Description?.value,
      DescriptionEN: event.target.DescriptionEN?.value,
    };
    try {
      if (mode == "edit") {
        postReceipt("put", {
          ...data,
          id: selectedObj.id,
          ActivityStatus: +event.target.ActivityStatus?.value,
        });
      } else {
        postReceipt("post", data);
      }
      relod();
    } catch (error) {}
    cancel();
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        showForm(false);
      });
    } else {
      showForm(false);
    }
  }

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (selectedObj && mode != "create") {
      getReceiptByIdAsync();
    } else {
      setLoading(true);
    }
  }, [selectedObj]);

  return (
    <div className="person-contact-form card">
      <div className="card-body">
        {loading ? (
          <div className="container-fluid">
            <form onSubmit={handleReceiptFormSubmit}>
              <div className="row ">
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="PRJ_UID"
                    labelText="PRJ_UID"
                    required={true}
                    defaultValue={response?.PRJ_UID ? response?.PRJ_UID : ""}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3">
                  {/* state */}
                  <DictionarySelectBox
                    required={true}
                    baseUrl={TREASURY_AND_ACCOUNTING_URL}
                    endPoint={
                      ENDPOINTSTREASURYANDACCOUNTING.Treasury.BaseInformationApi
                        .TreasurySubject.dictionary
                    }
                    label="TreasurySubjectId"
                    name="TreasurySubjectId"
                    value={
                      response?.TreasurySubjectId
                        ? response?.TreasurySubjectId
                        : ""
                    }
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3">
                  {/* state */}
                  <DictionarySelectBox
                    required={true}
                    baseUrl={TREASURY_AND_ACCOUNTING_URL}
                    endPoint={
                      ENDPOINTSTREASURYANDACCOUNTING.Treasury.BaseInformationApi
                        .CashAccount.dictionary
                    }
                    label="CashAccountId"
                    name="CashAccountId"
                    value={
                      response?.CashAccountId ? response?.CashAccountId : ""
                    }
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="BranchId"
                    labelText="BranchId"
                    required={true}
                    defaultValue={response?.BranchId ? response?.BranchId : ""}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="OwnerPersonName"
                    labelText="OwnerPersonName"
                    required={true}
                    defaultValue={
                      response?.OwnerPersonName ? response?.OwnerPersonName : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="OwnerPersonNameEN"
                    labelText="OwnerPersonNameEN"
                    required={true}
                    defaultValue={
                      response?.OwnerPersonNameEN
                        ? response?.OwnerPersonNameEN
                        : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  {/* stateRegisterDate */}
                  <Datepicker
                    lable="Date"
                    required={true}
                    setIsoTime={(isoTime: string) => {
                      setReceiptDate(isoTime);
                    }}
                    val={response?.ReceiptDate ? response?.ReceiptDate : null}
                    errorFlag={false}
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="Description"
                    labelText="Description"
                    required={true}
                    defaultValue={
                      response?.Description ? response?.Description : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="DescriptionEN"
                    labelText="DescriptionEN"
                    required={true}
                    defaultValue={
                      response?.DescriptionEN ? response?.DescriptionEN : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="PayerPositionName"
                    labelText="PayerPositionName"
                    required={true}
                    defaultValue={
                      response?.PayerPositionName
                        ? response?.PayerPositionName
                        : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="PayerPositionNameEN"
                    labelText="PayerPositionNameEN"
                    required={true}
                    defaultValue={
                      response?.PayerPositionNameEN
                        ? response?.PayerPositionNameEN
                        : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="number"
                    textboxName="TotalReceipt"
                    labelText="TotalReceipt"
                    required={true}
                    defaultValue={
                      response?.TotalReceipt ? response?.TotalReceipt : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="number"
                    textboxName="ReceiptNo"
                    labelText="ReceiptNo"
                    required={true}
                    defaultValue={
                      response?.ReceiptNo ? response?.ReceiptNo : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3">
                  {/* state docsClassification */}
                  <DocsClassification
                    label="Classification"
                    name="docsClassificationId"
                    baseUrl={TREASURY_AND_ACCOUNTING_URL}
                    endPoint={
                      ENDPOINTSTREASURYANDACCOUNTING.Treasury
                        .BaseDefinitionAndSettingApi.DocsClassification
                        .dictionary
                    }
                    resetForm={resetForm}
                    value={
                      response?.docsClassificationId
                        ? response?.docsClassificationId
                        : ""
                    }
                    onChange={(e) => {
                      setDocsClassification(e);
                    }}
                    mode={mode}
                  />
                </div>
                {mode !== "create" && (
                  <div className="col-sm-3 col-lg-2">
                    <EnumerationTypeSelect
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      name="ActivityStatus"
                      enumTypeSelect="ActivityStatus"
                      mode={mode}
                      label="Status"
                      value={response?.Status}
                    />
                  </div>
                )}

                <div className="col-lg-2 ">
                  <CheckableButton
                    defaultChecked={
                      response?.IsWorkflowStarted
                        ? response?.IsWorkflowStarted
                        : false
                    }
                    defaultValue={
                      response?.IsWorkflowStarted
                        ? response?.IsWorkflowStarted
                        : false
                    }
                    labelText={"IsWorkflowStarted"}
                    inputName={"IsWorkflowStarted"}
                    type="checkbox"
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 ">
                  <CheckableButton
                    defaultChecked={
                      response?.IsGrouped ? response?.IsGrouped : false
                    }
                    defaultValue={
                      response?.IsGrouped ? response?.IsGrouped : false
                    }
                    labelText={"IsGrouped"}
                    inputName={"IsGrouped"}
                    type="checkbox"
                    mode={mode}
                  />
                </div>
              </div>

              {mode != "view" ? (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button value="Save" btnType="submit" />
                  <Button
                    btnType="cancel"
                    value="Cancel"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              ) : (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button
                    btnType="primary"
                    value="close"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              )}
            </form>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};
