import {
  TREASURY_AND_ACCOUNTING_URL
} from "utils/constants";
import { ENDPOINTS, ENDPOINTSTREASURYANDACCOUNTING } from "utils/endpoints";

import { http } from "utils/http";

export const getGroupPositionList = async (SearchString) =>
  await http({
    url: ENDPOINTS.BaseInformation.groupPosition.dictionary,
    method: "get",
    params: {
      SearchString: SearchString,
    },
  }).then((respons) => respons.data);
export const getPositionList = async (SearchString) =>
  await http({
    url: ENDPOINTS.BaseInformation.position.browse,
    method: "get",
    params: {
      searchString: SearchString,
    },
  }).then((respons) => respons.data);

export const sendCashAccountAccess = async (data, method, ownerId) =>
  await http({
    baseURL: TREASURY_AND_ACCOUNTING_URL,
    url: ENDPOINTSTREASURYANDACCOUNTING.Treasury.BaseInformationApi.CashAccount
      .access,
    method: "put",
    params: {
      ownerId,
    },
    data: data,
  }).then((respons) => respons.data);

export const getCashAccountAccess = async (id) =>
  await http({
    baseURL: TREASURY_AND_ACCOUNTING_URL,
    url: ENDPOINTSTREASURYANDACCOUNTING.Treasury.BaseInformationApi.CashAccount
      .access,
    method: "get",
    params: { ownerId: id },
    headers: { "Accept-Language": "en" },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
