import { ENDPOINTS } from "utils/endpoints"
import { http } from "utils/http"


export const getOrganizationArchive = (id: any) => http({
    url: ENDPOINTS.archive.organizationalArchive.archives,
    method: "get",
    params: {
        ParentId: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});



export const getJobFileArchive = (id: any ,pageNumber,pageSize) => http({
    url: ENDPOINTS.archive.JobOrganizationalArchiveDetail.browse,
    method: "get",
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        OrganizationalArchiveDetailId: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getDataWithSerch = (id: any ,pageNumber,pageSize ,search) => http({
    url: ENDPOINTS.archive.JobOrganizationalArchiveDetail.search,
    method: "get",
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        organizationalArchiveDetailIds: id,
        generalSearchString:search
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const deleteJobFileArchive = (id: any) => http({
    url: ENDPOINTS.archive.JobOrganizationalArchiveDetail.browse,
    method: "delete",
    params: {
        Id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});




//-----------------OrganizationRulesForm---------------------------------


export const  getGroupPositionList =( async ( searchString) =>  await http({
    url: ENDPOINTS.BaseInformation.groupPosition.dictionary,
    method: "get",
    params:{
        SearchString : searchString
    }

    

})
.then((respons) => (respons.data))
.catch((err) => {

    return Promise.reject(err);
})
);
export const  getPositionList =( async (searchString ) =>  await http({
    url: ENDPOINTS.BaseInformation.position.browse,
    method: "get",
    params : {
        searchString : searchString
    }

    

})
.then((respons) => (respons.data))
.catch((err) => {
    return Promise.reject(err);
})
);


export const  organizationalArchiveAccess =( async (data  ,method) =>  await http({
    url: ENDPOINTS.archive.organizationalArchiveAccess.browse,
    method: method,
    data:data,
})
.then((respons) => (respons.data))
.catch((err) => {
    return Promise.reject(err);
})
)
export const  getTaskUser =( async (OrganizationalArchiveId ) =>  await http({
    url: ENDPOINTS.archive.organizationalArchiveAccess.browse,
    method: "get",
    params:{
        OrganizationalArchiveId:OrganizationalArchiveId 
    }
   
  
})
.then((respons) => (respons.data))
.catch((err) => {
    return Promise.reject(err);
})
)


export const getViewForm = (processId: any, selectedPosition?: any) => http({
    url: ENDPOINTS.bpmnApi.process.getViewForm,
    method: 'get',
    params: {
        processId: processId,
    }
}).then((response) => (response.data))
.catch((err) => {
    return Promise.reject(err);
})


export const getArchivedJob = (id: any) => http({
    url: ENDPOINTS.archive.organizationalArchiveDetail.savedArchive,
    method: "get",
    params: {
        jobId: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
});