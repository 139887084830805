import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toast } from "components/ToastShow/ToastShow";
import { Headerbar } from "components/Headerbar/Headerbar";
import { ModalForm, UserContext } from "components/ModalForm/ModalForm";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { postReferences } from "pages/OfficeAutomation/Cartable/api/api";
import { AssignTypes, ReferenceLocalDTO } from "pages/OfficeAutomation/Cartable/types";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { ReferenceForm } from "../ReferenceForm/ReferenceForm";
import { DateObject } from "react-multi-date-picker";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Search } from "components/Search/Search";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ProgresBarTask from "components/ProgresBarTask/ProgresBarTask";
import { Message } from "components/Message/Message";


interface props {
    pageIndex: number
    jobId: string
    taskList: any[]
    passList: any[]
    taskPositionList: ReferenceLocalDTO[]
    sendDataToWizard: (fetchedData: any, contentPage: any, status) => void
    handlePassStep: (Step, page) => void
    styleCondition: boolean
    selectAtLeastOneTask: boolean
    referenceId: string,
    onHide: () => void,
    reload?: (e) => void,

}

export const ReferenceContent: React.FC<props> = ({ pageIndex, jobId, selectAtLeastOneTask,taskList, taskPositionList, sendDataToWizard, styleCondition, handlePassStep, passList, referenceId, onHide, reload }) => {
    let taskTitle, TAS_UID, assignType, description: string
    let tableData: any[] = []
    let tableHeadData: string[] = []


    const [orderFormat, setOrderFormat] = useState<string>();
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [showForm, setShowForm] = useState<boolean>(false);
    const [references, setReferences] = useState<any>(taskPositionList);
    const [activateCheckbox, setActivateCheckbox] = useState<any>([]);
    const [checkedData, setCheckedData] = useState<any>({});
    const [isAllForms, setIsAllForms] = useState<boolean>(false);
    const [pass, setPass] = useState<boolean>(true);
    const [passList2, setPassList2] = useState<any>(passList);
    const [resetMultiSelect, setresetMultiSelect] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [counter, setCounter] = useState<number>(0);
    const [search, setSearch] = useState<any>(null);
    const [page, setPage] = useState(pageIndex);
    const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);
    const layoutContext = useContext(LayoutContext);
    const ReferrerPositionId = layoutContext.currentUserPosition.PositionId

    const lastStep = (page === taskList.length - 1)
    const firstStep = (page === 0)


    if (taskList[page]) {
        taskTitle = taskList[page].TaskTitle
        assignType = taskList[page].AssignType
        tableData = taskList[page].Positions
        //TAS_UID = taskList[page].TaskId
    }

    const handleSubmission = async (event) => {
        event.preventDefault()
        //remove all empty references
        if (!references) {   // add more rules later for form completion
            Toast(trans('pleaseFillTheForms'), 'warning')
        } else {
            let fillReferences:any = []
             references.forEach(item=>{
                let obj:any={}
                if(item.ReferenceSubjectId != ""){
                    obj.ParentReferenceId = item.ParentReferenceId
                    obj.JobId = item.JobId
                    obj.TAS_UID = item.TAS_UID
                    obj.PerformerPositionId = item.PerformerPositionId
                    obj.ReferrerPositionId = item.ReferrerPositionId
                    obj.ReferenceSubjectId = item.ReferenceSubjectId
                    obj.Description = item.Description
                    obj.Priority = item.Priority
                    obj.EstimateTime = item.EstimateTime
                    obj.DeadLineDate = item.DeadLineDate
                    obj.HasFollowUp = item.HasFollowUp
                    obj.SecretReference = item.SecretReference
                    obj.AssignType = item.AssignType
                    obj.EstimateTime = item.EstimateTime

                  fillReferences.push({Reference:obj,SendMessageToPerformer:item.SendMessageToPerformer})
                }
                
            })
            try {

              const res = await postReferences(fillReferences );

              //reload && reload(true)
              onHide()
            } catch (error) {

            }



        }
    }



    function sendDataTo(fetchedData, contentPage) {

        let cheklist: any = []


        fetchedData.map(obj => {
            if (obj.TAS_UID === taskList[page].TaskId && obj.ReferenceSubjectId.trim() != "") {
                cheklist.push({ Id: obj.Id })

            }

        })
        if ((assignType == "SELF_SERVICE" && cheklist.length == tableData.length) || (assignType != "SELF_SERVICE" && cheklist.length > 0)) {

            // setPassList({...passList , [page] : true })
            sendDataToWizard(fetchedData, contentPage, true)
        } else {
            //  setPassList({...passList , [page] : false })
            sendDataToWizard(fetchedData, contentPage, false)
        }


    }

    function next() {
        setresetMultiSelect(!resetMultiSelect)
        if (counter <= taskList.length - 2) {
            let complete = taskList.length - 1;
            setProgress(progress + (100 / complete))
            setCounter(old => old + 1);
            setPage(prevPage => prevPage + 1)
        }

        if (!styleCondition) { (document.querySelector(".ProgresBarTask-scroll") as HTMLElement).scrollTop = counter * 100; }
        else { (document.querySelector(".ProgresBarTask-mb-scroll") as HTMLElement).scrollLeft = counter * 100; }


        let el = document.querySelectorAll(".referencewizard-b-icon,.referencewizard-icon")

        for (let i = 0; i < el.length; i++) {
            el[counter].classList.add("check");
        }

        sendDataTo(references, page + 1);
    }

    function prev() {
        setresetMultiSelect(!resetMultiSelect)
        if (counter >= 1) {
            let complete = taskList.length - 1;
            setProgress(progress - (100 / complete))
            setCounter(old => old - 1);
            setPage(prevPage => prevPage - 1)

            let el = document.querySelectorAll(".referencewizard-circle");
            for (let i = 0; i < el.length; i++) {
                el[counter].classList.remove("activeStep");
            }
        }

        if (!styleCondition) { (document.querySelector(".ProgresBarTask-scroll") as HTMLElement).scrollTop = ((counter - 2) * 100); }
        else { (document.querySelector(".ProgresBarTask-mb-scroll") as HTMLElement).scrollLeft = (counter - 2) * 100; }
        sendDataTo(references, page - 1);
    }

    function circleClick(index) {
        setresetMultiSelect(!resetMultiSelect)
        let icon = document.querySelectorAll(".referencewizard-b-icon,.referencewizard__icon")
        let circle = document.querySelectorAll(".referencewizard-circle");
        
        
        if (counter < index) {
       

            let complete = taskList.length - 1
            setProgress((100 / complete) * index)

            for (let i = 0; i < icon.length; i++) {
                icon[index].classList.add("check");
            }

        } else if (counter > index) {
            let complete = taskList.length - 1
            setProgress((100 / complete) * index)

            for (let i = 0; i < circle.length; i++) {
                circle[index + 1].classList.remove("activeStep");
            }
        }
        setCounter(index)
        setPage(index)

        sendDataTo(references, index);
    }
    const clearedRowData = (obj) => {
        
        return {
            AssignType: AssignTypes[assignType],
            ParentReferenceId: obj.ParentReferenceId,
            JobId: obj.JobId,
            TAS_UID: obj.TAS_UID,
            Id: obj.Id,
            PerformerPositionId: obj.Id,
            ReferrerPositionId: layoutContext.currentUserPosition.PositionId ,
            ReferenceSubjectId: "",
            Position: obj.Position,
            Description: "",
            ReferenceSubject: "",
            Priority: 0,
            EstimateTime: 0,
            DeadLineDate: "",
            HasFollowUp: false,
            SecretReference: false,
        };
    }
    const checkedRowEventCallback = (checked, obj) => {


        const isExist = references.some(item => item.Id === obj.Id && item.TAS_UID === obj.TAS_UID)

        if (!checked) {
            if (isExist) {
                let clearedData = clearedRowData(obj);

                const filteredArray = references.filter(item => item.Id !== obj.Id ||
                    (item.Id === obj.Id && item.TAS_UID !== obj.TAS_UID))
                //remove check of checkBox
                let updatedArray = [...filteredArray, clearedData]
                tableData = updatedArray
                setReferences([...updatedArray])
            }
            checkAllcheckboxes()
        } else {
            setIsAllForms(false)
            // setShowForm(true)
        }
        checkAllcheckboxes()
        sendDataTo(references, page);

    }

    const listOfCheckedRows = (list) => {


        //setChecklist(list)
    }

    const multiSelectEventCallback = (checked) => {

        if (checked) {
            setIsAllForms(true)
            setShowForm(true)
        } else {

            const filteredArray = references.map(item => {
                if (item.TAS_UID == taskList[page].TaskId) {
                    let clear = clearedRowData(item);
                    return clear
                } else {
                    return item
                }
            })
            //remove check of checkBox
            sendDataTo(filteredArray, page);
            setReferences(filteredArray)
            setresetMultiSelect(!resetMultiSelect)
        }
    }

    const onCheckboxClicked = (obj) => {
        checkAllcheckboxes()
        setCheckedData(obj)

        // setSelectedRow(obj)
    }

    const onRowClicked = (obj, index, event) => {
        if(obj!=null){
            let originalObject = JSON.parse(JSON.stringify(obj)) 
            originalObject.DeadLineDate =  originalObject.DeadLineOriginalDate
            if (event?.target?.type != "checkbox") {
                setSelectedRow(obj);
                setIsAllForms(false)
            } else {
                setresetMultiSelect(!resetMultiSelect)
                checkedRowEventCallback(false, obj)
                checkAllcheckboxes()
            }

        }
    };

    const onRowDoubleClick = (obj) => {
      // let originalObject = JSON.parse(JSON.stringify(obj)) 
      // originalObject.DeadLineDate =  originalObject.DeadLineOriginalDate
       
            setSelectedRow(obj);
            setIsAllForms(false)
            setShowForm(true)
    
        
    };


    const getWithOrder = (order: string[]) => {
        setOrderFormat(order.join(","))
    }

    const handleSubmitedData = (receivedData, taskId) => {  // handle submitted data from a reference form
        setUserMadeChanges(true)
        if (receivedData !== undefined) {
            const filteredArray = references.filter(obj => obj.PerformerPositionId !== receivedData.PerformerPositionId ||
                (obj.PerformerPositionId === receivedData.PerformerPositionId && obj.TAS_UID !== receivedData.TAS_UID))

            let updatedArray = [receivedData, ...filteredArray]

            setReferences([...updatedArray])
            setActivateCheckbox([...activateCheckbox ,{ Id: receivedData.PerformerPositionId }])
            setSelectedRow(null)
        }

        /* let cheklist: any = []
                     
                tableData.map(obj => {
                    if (obj.TAS_UID === taskList[page].TaskId && obj.ReferenceSubjectId.trim() != "") {
                        cheklist.push({ Id: obj.Id })
        
                    }
        
                })
                if((assignType == "SELF_SERVICE" && cheklist.length == tableData.length) || (assignType != "SELF_SERVICE" && cheklist.length >0)){
           
                    setPassList2({...passList , [page] : true })
                   
                }else{
                    setPassList2({...passList , [page] : false })
            
                }
        
        
         */

    }

    const handleSubmitedDataForAllForms = (receivedData, taskId) => { // handle submitted data when All forms selected
        setUserMadeChanges(true)

        if (receivedData !== undefined && isAllForms) {
            let updatedArr = tableData.map(obj => {
                return {
                    ...receivedData, Id: obj.Id, PerformerPositionId: obj.PerformerPositionId,
                    Position: obj.Position
                }
            })
            const filteredArray = references.filter(obj => obj.TAS_UID !== receivedData.TAS_UID);

            setReferences([...filteredArray, ...updatedArr])
            sendDataTo([...filteredArray, ...updatedArr], page);
            checkAllcheckboxes()
            //let updatedArray = [...filteredArray, updatedArr]
            // setReferences([...updatedArray])
            setActivateCheckbox([{ Id: "All" }])
        }


    }

    // const filterDataBasedOnCheckboxList = () => {
    //     const isSameId = references.some(obj => obj.PerformerPositionId === checkedData.Id && obj.TAS_UID === TAS_UID)

    //     if (isSameId) {
    //         const filteredArray = references.filter(obj => obj.PerformerPositionId !== checkedData.Id && obj.TAS_UID === TAS_UID)
    //         setReferences(filteredArray)
    //     } else return
    // }

    const checkAllcheckboxes = () => {

        let cheklist: any = []
        let pagedata: any = []
        tableData.map(obj => {
            if (obj.TAS_UID === taskList[page].TaskId && obj.ReferenceSubjectId.trim() != "") {
                cheklist.push({ Id: obj.Id })

            }

        })
        tableData.map(obj => {
            if (obj.TAS_UID === taskList[page].TaskId) {
                pagedata.push({ Id: obj.Id })

            }

        })

        if ((assignType == "SELF_SERVICE" && cheklist.length == tableData.length) || (assignType != "SELF_SERVICE" && cheklist.length > 0)) {

            setPassList2({ ...passList, [page]: true })

        } else {
            setPassList2({ ...passList, [page]: false })

        }

       /*  if (cheklist.length == pagedata.length) {
            setActivateCheckbox([{ Id: "All" }])
        } else {
        } */
        setActivateCheckbox(cheklist)



    }

    // useEffect(() => {
    //     checkAllcheckboxes()
    // }, [])

    useEffect(() => {
        checkAllcheckboxes()
    }, [references, taskPositionList ])
    useEffect(() => {
        let checkPassList = 0
      //  if (Object.keys(passList2).length == taskList.length) {
            for (let page in passList2) {
                if (passList2[page]) {
                    checkPassList +=1
                }
            }
       // } else { checkPassList = 0 ; setPass(true)}
        if(selectAtLeastOneTask){
            
            if(checkPassList >0){
                setPass(false)
            }else{
                setPass(true)
            }
        }else{
            if(checkPassList ==taskList.length){
                setPass(false)
            }else{
                setPass(true)

            }
        }
       

    }, [passList2])

    // useEffect(() => {
    //     filterDataBasedOnCheckboxList()
    // }, [checkedData])

    if (references && references.length > 0) {
        tableHeadData = Object.keys(references[0])
       
        if (tableData.length > 0)
            tableData = []
         references.filter((item) => item.TAS_UID === taskList[page].TaskId).map(item=>{
            if(item.DeadLineDate){
                let newItem = JSON.parse(JSON.stringify(item)) 
                newItem.DeadLineOriginalDate = JSON.parse(JSON.stringify(item.DeadLineDate)) 
                const date = new DateObject({
                    date: item.DeadLineDate,
                    calendar: persian
                })
                newItem.DeadLineDate =date.format()
                if(search){
                    let isSelect = false;
                      activateCheckbox.map(activ=>{
                          if(activ.Id == newItem.Id){
                              isSelect = true
                          }
                      })
                    if(newItem.Position.includes(search)||isSelect){
                        tableData.push(newItem)
                    }
                }else{
                    tableData.push(newItem) 
                }
            }else{
                item.DeadLineOriginalDate =undefined
                if(search){
                    let isSelect = false;
                    activateCheckbox.map(activ=>{
                        if(activ.Id == item.Id){
                            isSelect = true
                        }
                    })
                    if(item.Position.includes(search)||isSelect){
                        tableData.push(item)
                    }
                }else{
                    tableData.push(item) 

                }
               
            }
        })
        if(tableHeadData.indexOf("DeadLineOriginalDate") > 4){
            tableHeadData.pop() 
        }
        tableHeadData =  ["DeadLineOriginalDate",...tableHeadData]
    }
    return (
        <div className="card mb-0">
            <div className={`${styleCondition ? 'referencewizard-b' : 'referencewizard'}`}>
                {taskList && taskList.length > 1 ?  
                    (
                        <>
                            <div className={styleCondition ?'w-100':'w-25'}>
                                <ProgresBarTask styleCondition={styleCondition} progress={progress} passList={passList2} counter={counter} taskList={taskList} selectAtLeastOneTask={selectAtLeastOneTask} handleCircleClick={(index)=>circleClick(index)}/>
                                <div className={`d-flex justify-content-center w-100 m-1`}>
                                    <Button value={`${styleCondition ? '<' : 'next'}`} btnType="primary" onClick={styleCondition?prev:next} disabled={styleCondition?firstStep ? true : false:lastStep ? true : false} />
                                    <Button value={`${styleCondition ? '>' : 'prev'}`} btnType="primary" onClick={styleCondition?next:prev} disabled={styleCondition?lastStep ? true : false:firstStep ? true : false} />
                                </div>
                            </div>
                        </>
                    // <div className={`${styleCondition ? 'referencewizard-b__progresscontainer' : 'referencewizard__progresscontainer border-start'}`}>
                    //     <div className={`${styleCondition ? 'referencewizard-b__scroll' : 'referencewizard__scroll'}`}>
                    //         <div className={`${styleCondition ? 'referencewizard-b__progress' : 'referencewizard__progress'}`}>
                    //            {!selectAtLeastOneTask ?
                    //          <ProgressBar style={{ "width": taskList.length * 110 , marginTop :taskList.length * ((taskList.length * 11)+40)}} className={`${styleCondition ? 'referencewizard-b__progressbar' : 'referencewizard__progressbar'}`} now={progress} />   
                    //         : <div  style={{ "width": taskList.length * 110 , marginTop :taskList.length * ((taskList.length * 11)+40)}} className={`${styleCondition ? 'referencewizard-b__progressbar' : 'referencewizard__progressbar'}`} ></div>
                    //         }  
                    //             <div style={{ "width": taskList.length * 114 }} className={`${styleCondition ? 'referencewizard-b__circlecontainer' : 'referencewizard__circlecontainer'}`}>
                    //                 {taskList.map((item, index) => (
                    //                     <>
                                        
                    //                     <OverlayTrigger
                    //                     placement="top"
                    //                     delay={{ "show": 600, "hide": 0 }}
                    //                     overlay={
                    //                         <Tooltip className="tooltip"  >
                    //                             {item.TaskTitle}
                    //                         </Tooltip>
                    //                     }
                    //                 >
                    //                    {(!selectAtLeastOneTask ?  index <= counter :index == counter ) ?
                    //                         <div key={index} className={`referencewizard__circle activeStep  `} onClick={() => circleClick(index)} >
                    //                             {
                    //                                 passList2 && passList2[index] &&
                    //                                 <>
                    //                                     <FontAwesomeIcon icon={[ICON_TYPE, "check"]} className={`${styleCondition ? 'referencewizard-b__circle-icon' : 'referencewizard__circle-icon'}`} color={'white'} />
                    //                                 </>
                    //                             }
                    //                         </div>
                    //                         :
                    //                         <div key={index} className={`referencewizard__circle`} onClick={() => circleClick(index)} >
                    //                             {
                    //                                 passList2 && passList2[index] &&
                    //                                 <>
                    //                                     <FontAwesomeIcon icon={[ICON_TYPE, "check"]} className={`${styleCondition ? 'referencewizard-b__circle-icon' : 'referencewizard__circle-icon'}`} color={'white'} />
                    //                                 </>
                    //                             }
                    //                         </div>
                    //                 }
                                    
                    //                 </OverlayTrigger>
                    //                     </>

                        
                    //                 ))}
                    //             </div>
                    //         </div>

                    //         <div style={{ height: !styleCondition ? (taskList.length + 1) * 110 : '200px', width: styleCondition ? (taskList.length + 1) * 110 : 'max-content' }} className={`${styleCondition ? 'referencewizard-b__stepTitlecontainer' : 'referencewizard__stepTitlecontainer'}`}>
                    //             {taskList.map((item: any, index) => (
                    //                 <div  onClick={() => circleClick(index)} key={index} className={`${styleCondition ? 'referencewizard-b__stepTitle' : 'referencewizard__stepTitle'}  ${(!selectAtLeastOneTask ?  index <= counter :index == counter)?"shaking_animation":"" }`}>{item.TaskTitle}</div>
                    //             ))}
                    //         </div>

                    //     </div>

                    // </div>
                    ) 
                    :
                    (
                        <></>
                    )
                }
                <div className="content-card d-flex flex-column w-100">
                    <div className="content-card d-flex flex-column w-100">
                        <Headerbar shakingKey={["taskTitle" ,"assignType" ]} hasBorder={true} headerContents={{ taskTitle, "assignType" :trans(assignType) }} />
                        <div className="mt-3 mb-3">
                            {assignType=="SELF_SERVICE"&&
                            <Message message={"Help_SelfServiceTaskMustBeReferToAll"} type={"instruction"} />
                            }
                            <div className=" d-flex ">
                            <div style={{height :"20px" , width: "60%"}} className="mt-3 ">{trans("ChooseFromPositionListToRefer")}:</div>
                              <div className="w-50 d-flex flex-row-reverse p-2">
                            {pass ?  
                                <Button
                                    btnType={'submit'}
                                    value={'Reference'}
                                    disabled={pass}
                                    onClick={handleSubmission}
                                />                                
                                :
                                <Button
                                    btnType={'submit'}
                                    value={'Reference'}
                                    disabled={pass}
                                    outline={false}
                                    onClick={handleSubmission}
                                />
                            }
                        
                            <Search onChange={setSearch} autoFocus={true} />
                            
                              </div>
                             </div>
                            </div>
                            {tableData && tableData.length > 0 &&
                                <DataGrid
                                    orderFormat={getWithOrder}
                                    multiSelect={true}
                                    //parentName={"referenceTable"+taskTitle}
                                    resetMultiSelect={resetMultiSelect}
                                    parentName={"referenceTable"}
                                    theadData={tableHeadData}
                                    tbodyData={tableData}
                                    multiSelecttype={2}
                                    rowSelected={selectedRow}
                                    startIndex={9}
                                    minCellWidth={40}
                                    selectedRowObjectCallback={onRowClicked}
                                    DoubleClick={onRowDoubleClick}
                                    handleEnter={onRowDoubleClick}
                                    selectItem={onCheckboxClicked}
                                    multiSelectCallback={multiSelectEventCallback}
                                    selectedRowProp={activateCheckbox ? activateCheckbox : null}
                                    checkedRowEventCallback={checkedRowEventCallback}
                                    selectList={listOfCheckedRows}
                                    scrollHeight={!styleCondition ? "300px" : "200px"}
                                />}
                        </div>
                    </div>
                </div>
            

            {showForm&&
             <ModalForm modalHeight={65} modalProfileKey={"referenceForm"} title={trans("referenceForm") +" : " +(isAllForms ? trans("grouply") : selectedRow.Position) }onHide={() => setShowForm(false)} show={showForm}>
                <>
                {(taskList[page]&&showForm) && 
                <ReferenceForm
                    taskTitle={taskTitle}
                    assignType={assignType}
                    jobId={jobId}
                    TAS_UID={taskList[page].TaskId}
                    Id={selectedRow?.Id}
                    PerformerPositionId={selectedRow?.PerformerPositionId}
                    ParentReferenceId={referenceId}
                    // description={selectedRow?.Description}
                    ReferrerPositionId={ReferrerPositionId}
                    handleSubmitedData={handleSubmitedData}
                    handleSubmitedDataForAllForms={handleSubmitedDataForAllForms}
                    allFormData={references}
                    setShowForm={setShowForm}
                    showForm={showForm}
                    isAllForms={isAllForms}
                />
                }
                </>

            </ModalForm>

            }


        </div>

    )
}