import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import Datepicker from "components/DatePicker/DatePicker";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { updateBordEndDate, deleteBord, getBoardList, sendBordAccess, getBordAccess } from "../api";
import { BoardForm } from "./components/BoardForm";
import './components/style.scss';
import { BoardAccess } from "./components/BoardAccess";

export const Boards = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedObj, setSelectedObj] = useState<object | any>(null);
    const [mode, setMode] = useState<string | any>();
    const [forceUpdate, setForceUpdate] = useState(false);
    const [response, setResponse] = useState<Response | any>(null);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pages, setPages] = useState<any>(0)
    const [showData, setShowData] = useState<any>()
    const [orderFormat, setOrderFormat] = useState<any>([]);
    const [filterlist, setFilterlist] = useState<any>("");
    const [search, setSearch] = useState(null);
    const [showExtensionForm, setShowExtensionForm] = useState<any>(null);
    const [endDate, seteEndDate] = useState<any>(null);
    const [showAccess, setShowAccess] = useState<any>(false);
    const [accessDataList, setAccessDataList] = useState<any>();
    let customFuction: any = [[(e) => { setSelectedObj(e); setShowExtensionForm(true);setMode("edit")}, "calendar", "PostponeExpirationDate"]]

 
    async function accessDatahandler(data) {
        try {
          const accessList = data.map(item=>{
            item.OwnerId = selectedObj.Id 
              return item
          })
          await sendBordAccess(accessList , selectedObj.Id); 
          setAccessDataList(null)
          setShowAccess(false)
        
      } catch (error) {
        
      }
    }
    async function getaccessListAsync() {
        try {
            const accessList = await getBordAccess(selectedObj.Id ); 
            setAccessDataList(accessList.Data)
            setShowAccess(true)
        } catch (error) {
          
        }
    
      }
    const selectedIdCallback = (obj) => {
                
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false")});
        }else{
            setSelectedObj(obj)         
        };
    }

    const handleShowForm = (e) => {
        if(e == true)
            setForceUpdate(!forceUpdate)
        setShowForm(false)
    }

    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getBoardListAsync(pageNum + 1);
          }
    };

    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getBoardListAsync(pageNum - 1);
          }
    };
    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(+e.currentTarget.value);
        getPageSize("BulletinBoard",e.currentTarget.value)
        getBoardListAsync(1)
    };

    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else { setPageNum(+value); getBoardListAsync(+value); }
        } else {
        }
    };
    
    const getWithOrder = (order: string[]) => {
        setOrderFormat(order)
    }

    const searchHandler = (str) => {
        setPageNum(1);
        if (str == "") {
            setSearch(null)
        } else {
            setSearch(str)
        }
    }
    async function handleDeleteRow() {
        try{
           await deleteBord(selectedObj.Id);
           setForceUpdate(!forceUpdate)
        }catch(err:any){

        }
        }

        const handleViewRow = () => {
            setMode("view"); 
            setShowForm(true);
        }
        const handleEditRow =  () => {
            setMode("edit");
             setShowForm(true);
        }

    async function ExtensionOfEndDate(event) {
        event.preventDefault()
        try{
           await updateBordEndDate(selectedObj.Id  ,endDate );
           setShowExtensionForm(false)
           setForceUpdate(!forceUpdate)

        }catch(err:any){

        }
        }

    async function getBoardListAsync(PageNum = pageNum) {
        setLoading(false);
        setResponse(null);
        let pageSizeChangedByUser = getPageSize("BulletinBoard");
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getBoardList(PageNum, pageSizeChangedByUser , orderFormat?.join(","), search, filterlist);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1)
            setResponse(res.Data);
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
         
        }
        setLoading(true)
    }

    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print"); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("Boards"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": setMode("create"); setShowForm(true); break;
            case "view": if (selectedObj) handleViewRow(); break;
            case "edit": if (selectedObj) handleEditRow(); break;
            case "remove": if (selectedObj) Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "help": alert("call api help"); break;
            case "assignrule": if (selectedObj){if(!selectedObj.IsPublic){ getaccessListAsync()}else{ Toast("Msg_CanNotSetAccessForPublicBoard" ,"warning" )}}; break;

        }
    };




    useEffect(() => {
        
        getBoardListAsync();
    }, [forceUpdate, search, orderFormat, filterlist]);

    return (
        <>
            { showAccess &&
              <ModalForm mode={mode}   modalProfileKey={"Users"}   title={trans("Users")+" : " +selectedObj.Title } onHide={() => setShowAccess(false)} show={showAccess}>
                  <BoardAccess
                    showForm={setShowAccess} 
                    accessDatahandler={accessDatahandler} 
                    accessData={accessDataList}
                    mode={mode}
                  />
              </ModalForm>
            }
            {showExtensionForm &&
                    <ModalForm mode={mode} title={"PostponeExpirationDate"} onHide={() => setShowExtensionForm(false)} show={showExtensionForm}>
                        <form className="card" onSubmit={ExtensionOfEndDate}>
                            <div className="card-body row mb-1">
                            <div className="col-12 col-md-6 col-lg-3">
                            <Datepicker
                                lable="untildate"
                                setIsoTime={(isoTime: string) => { seteEndDate(isoTime) }}
                                required={true}
                                val={ null}
                                mode={mode}
                            />
                        </div>
                            </div>
                            <div className="col-lg-12 mt-1 btns_form">
                                <Button value="Send" btnType="submit" />
                                <Button
                                    btnType="cancel"
                                    value="Cancel"
                                    onClick={() => { Toast(trans("modalclosemessage"), "warning", "alert", () => setShowExtensionForm(false)) }}
                                />
                            </div>
                        </form>
                    </ModalForm>
                }
            <Toolbar assignRule={true}  emptyList={response?.TotalRecords == 0}   handleSearch={searchHandler} id={selectedObj} view={true} create={true} remove={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
            <div >
                <div className="row">
                    <div className="col-12">
                        {showForm &&
                        <ModalForm modalHeight={85} mode={mode} title={"BoardForm"} onHide={() => setShowForm(false)} show={showForm}>
                        <div className="">
                            <BoardForm getDataForShow={setShowData}   showForm={handleShowForm} selectedObj={selectedObj} mode={mode} />
                        </div>
                        </ModalForm>
}                    </div>
                </div>
                <div className="card" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                    <div className="p-0">
                        {loading ?
                            response?.Data?.length > 0 ?
                                <DataGrid
                                    handlefiltering={(str) => {
                                        setFilterlist(str);
                                    }}
                                    filterStr={filterlist}
                                    orderFormat={getWithOrder}
                                    orderFormatList={orderFormat}
                                    parentName={"BulletinBoard"}
                                    customFuction={customFuction}
                                    startIndex={1}
                                    theadData={Object.keys(response.Data[0])}
                                    tbodyData={response.Data}
                                    minCellWidth={80}
                                    selectedRowObjectCallback={selectedIdCallback}
                                    totalRecords={response.TotalRecords}
                                    pageNum={pageNum}
                                    pageSize={pageSize}
                                    handleEdit={handleEditRow}
                                    handleView={handleViewRow}
                                    handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow)}}
                                    HandleNextPage={handleNextPage}
                                    HandlePrevPage={handlePrevPage}
                                    handlechangePageSize={handleChangePageSize}
                                    first={() => { getBoardListAsync(pages); setPageNum(pages) }}
                                    end={() => { getBoardListAsync(1); setPageNum(1) }}
                                    handleGoPage={handleGoPage}
                                /> 
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                            :
                            <Loading />}
                    </div>
                </div>

            </div>
        </>

    )
}