import { Button } from "components/Button/Button"
import { ProcessIcon } from "components/ProcessIcon/ProcessIcon"
import { useState } from "react"
import { postProcessIcon } from "../../api/api"


interface props {
    showForm: () => any
    rowId: string
}

export const ProcessIconModal = ({showForm, rowId}: props) => {
   const[iconId, setIconId]= useState<string>()

    const sendOptionId = (id) =>{
        setIconId(id)
    }

    async function postProcessIconasync(data) {
        try {
           const res = await postProcessIcon(data)
        } catch (error) {
            
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        const data ={
            iconId,
            PRJ_UID : rowId
        }
        postProcessIconasync(data)
        showForm()
    }

    return (
        <div className="card ">
                <div className="card-body">
                    <div className="row col-lg-6 p-2 mb-3">
                        <ProcessIcon ProcessId={rowId} sendOptionId={sendOptionId} />
                    </div>             
                    <div className="col-lg-12 btns_form">
                            <Button value="Save" btnType="submit" onClick={handleFormSubmit} />
                            <Button
                                btnType="cancel"
                                value="Cancel"
                                onClick={()=>{showForm()}}
                            />
                    </div>
                </div>
        </div>
    )
}