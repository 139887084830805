import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import { addItemToBasket, getBasketData } from "utils/helpers";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";

interface ItemCountBtnProps {
  reload?: () => void;
  closePluse?: (e: boolean) => void;
  ItemData: any;
  defultValue?: number;
  priceIndex?: number;
  min?: number;
  max?: any;
  stock?: any;
  propertiesId?: any;
  multiCounter?: any;
  properties?: any;
  showSumCount?: boolean;
}
export const ItemCounterBtn = ({
  reload,
  closePluse,
  showSumCount,
  stock,
  multiCounter = true,
  ItemData,
  defultValue,
  min,
  max,
  priceIndex,
  propertiesId,
  properties,
}: ItemCountBtnProps) => {
  const [count, setCount] = useState(defultValue ? defultValue : 1);
  const [startDel, setStartDel] = useState(false);
  const startDelRef = useRef(false);
  const CountRef = useRef<any>(0);
  const layoutContext = useContext(LayoutContext);
  let basket = getBasketData();

  const handleDecrement = async () => {
    if (+CountRef.current.value != 1) {
      if (min && +CountRef.current.value < +min) {
        addItemToBasket(
          {
            ...ItemData,
            order: {
              ...ItemData.order,
              [propertiesId]: {
                properties: properties,
                count: +min,
                priceIndex: priceIndex,
                min: min,
                max: max ? +max : null,
              },
            },
          },
          [propertiesId]
        );
        CountRef.current.value = +min;
        setCount(+min);
      } else {
        addItemToBasket(
          {
            ...ItemData,
            order: {
              ...ItemData.order,
              [propertiesId]: {
                properties: properties,
                count: +count - 1,
                priceIndex: priceIndex,
                min: min,
                max: max ? +max : null,
              },
            },
          },
          [propertiesId]
        );
        CountRef.current.value = count - 1;

        if (count - 1 == 0) {
          setStartDel(true);
          startDelRef.current = true;
          await delay(1800);
          setCount(0);
          if (startDel || startDelRef.current) {
            await addItemToBasket({ ...ItemData, count: 0 }, [propertiesId]);
            reload && reload();
            startDelRef.current = false;

            setStartDel(false);
          } else {
            setCount(ItemData.count);
          }
        } else {
          if (count == 0) {
            setCount(0);
          } else {
            setCount(count - 1);
          }
        }
      }
    } else {
      handleDelete();
    }
    reload && reload();
  };

  const handleIncrement = () => {
    if (multiCounter) {
      if (+CountRef.current.value < +stock) {
        if (max != null && +CountRef.current.value > +max) {
          addItemToBasket(
            {
              ...ItemData,
              order: {
                ...ItemData.order,
                [propertiesId]: {
                  properties: properties,
                  count: +max,
                  priceIndex: priceIndex,
                  min: min,
                  max: +max,
                },
              },
            },
            [propertiesId]
          );
          CountRef.current.value = max != null ? +max : null;
          setCount(+max);
        } else {
          addItemToBasket(
            {
              ...ItemData,
              order: {
                ...ItemData.order,
                [propertiesId]: {
                  properties: properties,
                  count: +count + 1,
                  priceIndex: priceIndex,
                  min: min,
                  max: max != null ? +max : null,
                },
              },
            },
            [propertiesId]
          );
          CountRef.current.value = +count + 1;
          setCount(+count + 1);
        }
      }
    } else {
      if (+CountRef.current.value < +stock) {
        addItemToBasket(
          {
            ...ItemData,
            order: {
              ...ItemData.order,
              [propertiesId]: {
                properties: properties,
                count: +count + 1,
                priceIndex: priceIndex,
                min: min,
                max: max != null ? +max : null,
              },
            },
          },
          [propertiesId]
        );
        CountRef.current.value = +count + 1;
        setCount(+count + 1);
      } else {
        addItemToBasket(
          {
            ...ItemData,
            order: {
              ...ItemData.order,
              [propertiesId]: {
                properties: properties,
                count: +stock,
                priceIndex: priceIndex,
                min: min,
                max: max != null ? +max : null,
              },
            },
          },
          [propertiesId]
        );
        CountRef.current.value = +stock;
        setCount(+stock);
      }
    }

    reload && reload();
  };
  const delay = (duration) =>
    new Promise((resolve) => setTimeout(resolve, duration));

  const handleDelete = async () => {
    CountRef.current.value = 0;
    setStartDel(true);
    startDelRef.current = true;
    if (reload) {
      await delay(1800);
      setCount(0);
      if (startDel || startDelRef.current) {
        await addItemToBasket(
          {
            ...ItemData,
            order: {
              ...ItemData.order,
              [propertiesId]: {
                properties: properties,
                count: 0,
                priceIndex: priceIndex,
                min: min,
                max: max != null ? +max : null,
              },
            },
          },
          [propertiesId]
        );
        reload && reload();
        closePluse && closePluse(true);
        startDelRef.current = false;

        setStartDel(false);
      } else {
        setCount(ItemData.order[propertiesId].count);
      }
    } else {
      await addItemToBasket(
        {
          ...ItemData,
          order: {
            ...ItemData.order,
            [propertiesId]: {
              properties: properties,
              count: 0,
              priceIndex: priceIndex,
              min: min,
              max: +max,
            },
          },
        },
        [propertiesId]
      );
      setCount(0);
      closePluse && closePluse(true);
    }
  };

  const validation = (e) => {
    if (+stock && +e.target.value <= +stock) {
      // if(min && e.target.value < min){
      //     setCount(min)
      // }else if(max && e.target.value > max){
      //     setCount(max)
      // }else{
      //     setCount(e.target.value)
      // }
    } else {
      setCount(+stock);
      CountRef.current.value = +stock;
      addItemToBasket(
        {
          ...ItemData,
          order: {
            ...ItemData?.order,
            [propertiesId]: {
              properties: properties,
              priceIndex: priceIndex,
              min: min,
              max: max != null ? +max : null,
              count: stock,
            },
          },
        },
        [propertiesId]
      );
    }
  };

  const handleOnChange = (e) => {
    addItemToBasket(
      {
        ...ItemData,
        order: {
          ...ItemData?.order,
          [propertiesId]: {
            properties: properties,
            priceIndex: priceIndex,
            min: min,
            max: max != null ? +max : null,
            count:
              stock && stock >= e.target.value
                ? max
                  ? +max >= +e.target.value
                    ? +e.target.value
                    : max != null
                    ? +max
                    : null
                  : +e.target.value
                : stock,
          },
        },
      },
      [propertiesId]
    );
    setCount(
      stock && stock >= +e.target.value
        ? max != null
          ? +max >= +e.target.value
            ? +e.target.value
            : +max
          : +e.target.value
        : stock
    );
    CountRef.current.value =
      stock && stock >= +e.target.value
        ? max != null
          ? +max >= +e.target.value
            ? +e.target.value
            : +max
          : +e.target.value
        : stock;
  };

  useEffect(() => {
    // CountRef.current.value = +ItemData?.order?.[propertiesId]?.count ? ItemData?.order?.[propertiesId]?.count : 1
    if (
      ItemData?.order?.[propertiesId]?.priceIndex != priceIndex &&
      ItemData?.order?.[propertiesId]?.priceIndex != undefined
    ) {
      addItemToBasket(
        {
          ...ItemData,
          order: {
            ...ItemData?.order,
            [propertiesId]: {
              ...ItemData.order?.[propertiesId],
              priceIndex: priceIndex,
            },
          },
        },
        [propertiesId]
      );
    }
  }, [priceIndex]);
  useEffect(() => {
    layoutContext.setBasket();
  }, [count]);

  useEffect(() => {
    let Basket: any = localStorage.getItem("Basket");
    if (Basket && ItemData.order) {
      Basket = JSON.parse(Basket);
      if (Basket[ItemData.Id]?.order[propertiesId]) {
        if (ItemData.order[propertiesId] == undefined) {
          ItemData.order[propertiesId] = {
            count: Basket[ItemData.Id]?.order?.[propertiesId]?.count,
          };
        } else {
          ItemData.order[propertiesId].count =
            Basket[ItemData.Id]?.order?.[propertiesId]?.count;
        }
        CountRef.current.value =
          Basket[ItemData.Id]?.order?.[propertiesId]?.count;
        setCount(Basket[ItemData.Id].order[propertiesId].count);
      } else {
        if (ItemData.Id) {
          setCount(0);
          // addItemToBasket({...ItemData , count:0})
          //  CountRef.current.value = 0
          closePluse && closePluse(true);
          reload && reload();
        }
      }
    } else {
      // reload&&reload()
      // onHide && onHide(false)
    }
  }, [layoutContext.basket]);
  return (
    <div
      style={{ flexDirection: "row", maxWidth: "150px" }}
      className="d-flex align-items-center justify-content-around w-100"
    >
      <div className="bg-customerPrimaryColor btnGroup d-flex justify-content-between align-items-center w-100">
        <button onClick={handleIncrement}>+</button>
        <input
          className="bg-customerPrimaryColor"
          ref={CountRef}
          min={min}
          max={max}
          onBlur={(e) => validation(e)}
          onChange={(e) => {
            handleOnChange(e);
          }}
          type="text"
          value={showSumCount ? defultValue : +count}
        />
        <button onClick={handleDecrement}>-</button>
        <button className="d-flex align-items-center" onClick={handleDelete}>
          <FontAwesomeIcon icon={["far", "trash-can"]} />
        </button>
      </div>
      {reload && startDel && startDelRef.current && (
        <FontAwesomeIcon
          onClick={() => {
            setStartDel(false);
            startDelRef.current = false;
            CountRef.current.value = ItemData.count;
            setCount(ItemData.count);
          }}
          icon={["fas", "clock-rotate-left"]}
          color="blue"
          size="sm"
        />
      )}
    </div>
  );
};
