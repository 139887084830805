import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import DatePicker from "components/DatePicker/DatePicker";
import { Loading } from "components/Loading/loading";
import { UserContext } from "components/ModalForm/ModalForm";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { ENDPOINTS } from "utils/endpoints";

interface LetterSendFormPropsType {
    mode?: any,
    jobId?: any,
    Name?: any,
    LetterSendHistoryId?: any,
    processId?: any,
    selectedRow?: any,
    Id?: any,
    referenceId?: any,
    step:any,
    showForm:any,
    isAllForms:any,
    allFormData:any,
    onHide: () => void,
    setShowForm: (e) => void,
    handleShowReferenceStepper?: (referenceId: string) => void;
    handleSubmitedData?: (data: any ,flag ) => void;

}

export const LetterSendForm = ({
    mode,
    jobId,
    Name,
    LetterSendHistoryId,
    processId,
    selectedRow,
    Id,
    referenceId,
    step=1,
    showForm,
    allFormData,
    isAllForms,
    onHide,
    setShowForm,
    handleShowReferenceStepper,
    handleSubmitedData,

}: LetterSendFormPropsType) => {
    const [SentTime, setSentTime] = useState<any>();
    const [ReceiveTime, setReceiveTime] = useState<any>();
    const [untilDate, setUntilDate] = useState<any>();
    const [leaveDaysNo, setLeaveDaysNo] = useState<any>();
    const [requestDate, setRequestDate] = useState<any>();
    const [response, setResponse] = useState<any>();
    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);
    const [showstep, setShowstep] = useState<any>({});
    const [localMode, setLocalMode] = useState<any>("create");
    const layoutContext = useContext(LayoutContext);
    const [showReferenceStepper, setShowReferenceStepper] = useState<boolean>(false);











    async function formSubmit(e) {
        e.preventDefault()
        setShowstep({2:false , 1:false})
        let data={}
        let method
            method = "post"
              data = {
                "Id":selectedRow.Id,

                "JobId": Id,
                "SendOperatorPositionId": e.target.SendOperatorPositionId.value,
                "ReceiverId" :selectedRow.ReceiverId,
                "SendMethodId": e.target.LetterSendMethodId.value,
                "Receiver" : selectedRow.Receiver,
                "ReceiverPerson":  e.target.ReceiverPerson.value,
                "SentDescription":  e.target.SentDescription.value,
                "SentTime": SentTime,
                "IsSent":  e.target.IsSent.checked,
                "IsReceived": e.target.IsReceived.checked,
                "ReceiveTime": ReceiveTime,
                "ReceiveDescription":  e.target.ReceiveDescription.value,


              }
            try {
                handleSubmitedData &&   handleSubmitedData(data ,isAllForms )

            }catch (error) {
  
          }
        
    }


    
    useEffect(() => {
        if (resetForm) {  
            setResetForm(false);
        }
    }, [resetForm]);

    useEffect(() => {
        if ((mode == "edit" && step ==1)  ) {
            setLocalMode("create")
        }else if(step ==3 ||mode == "edit"){
            setLocalMode("edit")    
        }else{
            setLocalMode(mode)
        }
    }, [mode]);

    useEffect(() => {
        if( step >=1 || mode != "create"){
            setLoading(true)
        }else{
            setLoading(true)
            setResponse({})
        }
    }, []);



    return (
        <div>
           {loading ?
        <div>
         <form className="action-form py-2" onSubmit={formSubmit}>
            <div className={`card  `}>
                <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px" , justifyContent:"space-between" }} className="d-flex  py-1 col-12 pr-4"> 
                    <div>{trans("send")} </div>      
                    <div  style={{marginLeft:"18px"}} onClick={()=>{showstep[1]?setShowstep({...showstep , 1:false}):setShowstep({...showstep , 1:true}) }} >{showstep[1] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" />:<FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                </div>
                <div style={{display: !showstep[1] ?"block" :"none"}} className="card-body">                      
                        <div className="row mb-2">
                         <div className="col-lg-4 col-sm-4 mt-1">
                             <Position
                                name="SendOperatorPositionId"
                                label="SendOperatorPosition"
                                mode={selectedRow.Id!= undefined && selectedRow.Id.length > 0  ?"view"  :"edit"}
                                value={mode == "create" ? layoutContext.currentUserPosition.PositionId : selectedRow.SendOperatorPositionId }
                                resetForm={resetForm}
                            />
                         </div>
                         <div className="col-lg-4 col-sm-4 mt-1">
                          <DictionarySelectBox
                             label="SendMethod"
                             endPoint={ENDPOINTS.cartable.sendMethod.dictionary}
                             name="LetterSendMethodId"
                             value={selectedRow.LetterSendMethodId}
                             mode={selectedRow.Id!= undefined &&selectedRow.Id.length > 0  ?"view"  :"edit"}
                             />
                         </div>
                            <div className="col-12 col-lg-4">
                            {(selectedRow.Id!= undefined &&selectedRow.Id.length > 0)  ? 
                                <Textbox
                                mode={"view" }
                                resetForm={resetForm}
                                textboxType="text"
                                textboxName="SentTime"
                                labelText="SentTime"
                                maxLength={50}                        
                                defaultValue={selectedRow?.SentTime ? selectedRow?.SentTime : SentTime} //resetForm ? new Date().toISOString() : 
                             />
                            :
                            <DatePicker
                                Change={setUserMadeChanges}
                                required={true}
                                mode={selectedRow.Id!= undefined &&selectedRow.Id.length > 0  ?"view"  :"edit"}
                                resetForm={resetForm}
                                lable="SentTime"
                                setIsoTime={(isoTime: string) => { setSentTime(isoTime) }}
                                val={selectedRow?.SentTime ? selectedRow?.SentTime : null} //resetForm ? new Date().toISOString() : 
                                />                   
                            }
                            </div>

                         
                        </div>   
                        <div className="row mb-2">
                            <div className="col-12 col-lg-4">
                             <Textbox
                                mode={selectedRow.Id!= undefined &&selectedRow.Id.length > 0  ?"view"  :"edit"}
                                resetForm={resetForm}
                                textboxType="text"
                                textboxName="ReceiverPerson"
                                labelText="ReceiverPerson"
                                maxLength={50}                        
                                defaultValue={selectedRow.ReceiverPerson ?selectedRow.ReceiverPerson :""}
                             />
                            </div>
                            <div className="col-12 col-lg-4 mt-4 mb-4" >
                                <CheckableButton
                                   defaultChecked={true}
                                   defaultValue={true}
                                   labelText={"IsSent"}
                                   inputName={"IsSent"}
                                   type="checkbox"
                                   mode={"view" }
                                   />                                
                            </div>
                         </div>              
                        <div className="row">
                            <div className="col-lg-8 col-sm-6 ">
                               <Textbox
                                  readonly={(step >1 || mode=="view") ? true: false}
                                  mode={selectedRow.Id!= undefined &&selectedRow.Id.length > 0  ?"view"  :"edit"}
                                  resetForm={resetForm}
                                  textboxType="textarea"
                                  textboxName="SentDescription"
                                  labelText="SentDescription"
                                  maxLength={900}
                                  defaultValue={selectedRow.SentDescription}
                               />
                            </div>
                        </div>
              
                </div>
                   
            </div>
            <div className="card">
                <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px" , justifyContent:"space-between" }} className="d-flex  py-1 col-12 pr-4"> 
                    <div>{trans("receive")} </div> 
                    <div style={{marginLeft:"18px"}}  onClick={()=>{showstep[2]?setShowstep({...showstep , 2:false}):setShowstep({...showstep , 2:true}) }}>{showstep[2] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" />:<FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                </div>  
                <div style={{display: !showstep[2] ?"block" :"none"}} className="card-body">
                <div className="row mb-2">
                    <div className="col-12 col-lg-4">
                         <DatePicker
                             Change={setUserMadeChanges}
                             mode={step >1 ? "view" : mode}
                             resetForm={resetForm}
                             lable="ReceiveTime"
                             setIsoTime={(isoTime: string) => { setReceiveTime(isoTime) }}
                             val={selectedRow?.ReceiveTime ? selectedRow?.ReceiveTime : null} //resetForm ? new Date().toISOString() : 
                         />
                    </div>
                    <div className="col-12 col-lg-4 mt-4 mb-4" >
                        <CheckableButton
                           defaultChecked={selectedRow?.IsReceived!=null? selectedRow?.IsReceived :  false}
                           defaultValue={selectedRow?.IsReceived!=null ? selectedRow?.IsReceived :  false}
                           labelText={"IsReceived"}
                           inputName={"IsReceived"}
                           type="checkbox"
                           mode={mode}
                        />                                
                    </div>
                    <div className="col-lg-8 col-sm-6 ">
                        <Textbox
                           readonly={(step >1 || mode=="view") ? true: false}
                           mode={step >1 ? "view" : mode}
                           resetForm={resetForm}
                           textboxType="textarea"
                           textboxName="ReceiveDescription"
                           labelText="ReceiveDescription"
                           maxLength={900}
                           defaultValue={selectedRow.ReceiveDescription}
                        />
                    </div>
                </div>


                </div>
            </div>

            <div className="d-flex justify-content-end">
                {localMode !== "view" ?
                    <>
                        <Button value="Save" btnType="submit" />
                        <Button
                            btnType="cancel"
                            value="Cancel"
                            onClick={() => { Toast(trans("modalclosemessage"), "warning", "alert", onHide) }}
                        />
                    </> 
                    :
                     <Button
                        btnType="primary"
                        value="Close"
                        onClick={onHide}
                      />
                }
            </div>               

        </form>
            </div>
            : <Loading/>


           
           }

        

        </div>
    );
}