import { DYNAMICFORMS_URL } from "utils/constants";
import { ENDPOINTSDYNAMICFORMS } from "utils/endpoints";
import { http } from "utils/http";

export const getFormInformationCompleted = (async (pageNum,  pageSize  ,searchString,orderBy,filterlist, formKey ) => await http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.list,
    method: 'get',
    params:{

        PageNumber: pageNum,
        PageSize: pageSize,
        OrderByString:orderBy,
        SearchString : searchString ,
        FilterString:filterlist,
        formKey:formKey,
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)



export const getFormInformationFormkey = (async ( formKey ) => await http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.formKey,
    method: 'get',
    params:{
        formKey:formKey,
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const  deleteInformationForm =( async (id,formKey) =>  await http({
    baseURL:DYNAMICFORMS_URL ,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.data,
    method: 'delete',
    params: {
        id:id,
        formKey:formKey
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)