import { useContext, useEffect, useState } from "react";
import logo from "assets/image/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectBox } from "components/Select/Select";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { getApplications } from "../../api";
import { ApplicationDTO } from "types/types";
import { Link, useNavigate } from "react-router-dom"
import { trans } from "utils/helpers";
import { ICON_TYPE, INVENTORYANDSALES_URL } from "utils/constants";
import "./style.scss"
import { Toast } from "components/ToastShow/ToastShow";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Button } from "components/Button/Button";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { WarehouseDictionary } from "pages/InventoryAndSales/Sales/SalesInvoice/components/WarehouseDictionary/WarehouseDictionary";
interface Prop {
  className?: string;
  getDataAsync :(id) => void
}
export const TopSidebar = (props: Prop) => {
  let items: any[] = [];
  let itemsName: any[] = [];
  let itemsId: any[] = [];
  let itemIcons: any[] = []
  const layoutContext = useContext(LayoutContext);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [selectedOptionSmallSelect, setselectedOptionSmallSelect] = useState<any>();
  const [iconApp, setIconApp] = useState<any>();
  const [response, setResponse] = useState<ApplicationDTO | any>();
  const [showInventoryForm, setShowInventoryForm] = useState< any>();
  const [inventory, setInventory] = useState< any>();
  const navigate = useNavigate();

  const chechChangeApp = (e , func) => {
    
    if(sessionStorage.getItem("changingforms")+""=="true"){
      Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{sessionStorage.setItem("changingforms" , "false");localStorage.removeItem("formsInChang");func(e)});
    }else{
     
      func(e)
    }
   
  }


  function Submit(e){
    e.preventDefault() 
    if(e.target.Warehouse.value){
      localStorage.setItem("Inventory" ,JSON.stringify({Id:inventory.Id , Name:inventory.Name.Description}) )
      setShowInventoryForm(false)
    }

  }

  const handleChange = (selectedOption: any ) => { 
    
    let Id = selectedOption.value; 
    let Name:any 
    response.map(item=>{
      if (item.Id == Id){
        Name = item.Name
      }
    })
    if(Name == "TadbirAccounting"){
      window.open(process.env.REACT_APP_ACCOUNTING_URL)
    }else if(Name == "InventoryManagement"){
      if(!localStorage.getItem("Inventory")||localStorage.getItem("Inventory")=="undefined"){
    //    setShowInventoryForm(true)
      }
      setSelectedOption(selectedOption);
      setIconApp(selectedOption.label.props.children.props.children[0].props.icon);
      layoutContext.setCurrentApplication({Id:Id ,Name:Name });
      localStorage.setItem("CurrentApplication" ,JSON.stringify({Id:Id ,Name:Name })  )
      props.getDataAsync(Id)
    }else{
            setSelectedOption(selectedOption);
      setIconApp(selectedOption.label.props.children.props.children[0].props.icon);
      layoutContext.setCurrentApplication({Id:Id ,Name:Name });
      localStorage.setItem("CurrentApplication" ,JSON.stringify({Id:Id ,Name:Name })  )
      props.getDataAsync(Id)
    }
    navigate("/")
  };
 

  const handleChangeForSmallSelect = (e: any) => {
    let index = e.nativeEvent.target.selectedIndex;
    let Id = e.target.value
    let Name = e.nativeEvent.target[index].text
    setselectedOptionSmallSelect(Id)
    layoutContext.setCurrentApplication({Id:Id ,Name:Name });
    localStorage.setItem("CurrentApplication" ,JSON.stringify({Id:Id ,Name:Name })  )
    navigate("/")
  };


  async function getApplicationsAsync() {
    try {
      
      const res = await getApplications();
      const newrespons= [...res.Data  ]
     // document.title =trans(res.Data[0].Name) 
  
      setResponse(newrespons);  
  
      let Current: any = localStorage.getItem("CurrentApplication");
      Current = JSON.parse(Current)
  
      newrespons.forEach(item => {
      if( (Current?.Id && item.Id == Current?.Id) ){
          props.getDataAsync(item.Id)
          layoutContext.setCurrentApplication({Id:item.Id ,Name:item.Name });
          setselectedOptionSmallSelect(item.Id)
          setSelectedOption({
            value: (item.Id),
            label: (
              <div className= "d-flex ">
                <FontAwesomeIcon
                  icon={[
                    ICON_TYPE,
                    item.IconName,
                  ]}
                  className="ml-1 " 
                  height="20px"
                  width="20px"
                />
                <div className="px-2">
                {trans(item.Name)}                         
                </div>
              </div>
            )
          })
        }else if((item.Id == newrespons[0].Id && !Current?.Id)){
          props.getDataAsync(item.Id)
          layoutContext.setCurrentApplication({Id:item.Id ,Name:item.Name });
          setselectedOptionSmallSelect(item.Id)
          setSelectedOption({
            value: (item.Id),
            label: (
              <div className= "d-flex ">
                <FontAwesomeIcon
                  icon={[
                    ICON_TYPE,
                    item.IconName,
                  ]}
                  className="ml-1 " 
                  height="20px"
                  width="20px"
                />
                <div className="px-2">
                {trans(item.Name)}                         
                </div>
              </div>
            )
          })
  
        }
      });  
    } catch (error) {
      
    }
  }

  useEffect(() => {
    setselectedOptionSmallSelect(null)
    getApplicationsAsync();
  }, [layoutContext.currentUserPosition]);

  useEffect(() => {
    let Current: any = localStorage.getItem("CurrentApplication");
    Current = JSON.parse(Current)

    if(Current?.Id){
      layoutContext.setCurrentApplication({Id:Current.Id ,Name:Current.Name });
    }
  }, []);

  useEffect(() => {
    if(response){

      response.forEach(item => {
        if(item.Id == layoutContext.currentApplication.Id){
          setSelectedOption({
            value: (item.Id),
            label: (
              <div className= "d-flex ">
                <FontAwesomeIcon
                  icon={[
                    ICON_TYPE,
                    item.IconName,
                  ]}
                  className="ml-1 " 
                  height="20px"
                  width="20px"
                />
                <div className="px-2">
                {trans(item.Name)}                         
                </div>
              </div>
            )
          })
  
  
  
  
        }
        
      });
      props.getDataAsync(layoutContext.currentApplication.Id)
    }


  }, [layoutContext.currentApplication]);

  

  if (response) {
    response.map((item, index) => (
      items.push({
        value: (item.Id),
        label: (
          <div >
              <div className=" d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={[
                        ICON_TYPE,
                        item.IconName,
                      ]}
                      className="ml-1 " 
                      height="10px"
                      width="20px"
                    />
                <div className="px-2">
                {trans(item.Name)}                   
                </div>            
              </div>
          </div>
        )
      })
    ))

    response.map(item => (
      itemsName.push(item.Name)
    ))

    response.map(item =>
      itemIcons.push([
        ICON_TYPE,
        item.IconName,
      ]))


    response.map(item => (
      itemsId.push(item.Id)
    ))
  }
  return (
    <div className={`wrap_logo_app  ${props.className}`}>
      {showInventoryForm&& 
        <ModalForm mode={"edit"}  title={"currentinventoryselection" } onHide={() => setShowInventoryForm(false)} show={showInventoryForm}>
          <div className="card">
            <div className="card-body">
              <form onSubmit={Submit}>
              <div className="row col-3">
                <WarehouseDictionary
                  name="Warehouse"
                  label="Warehouse"
                  value={JSON.parse(localStorage.getItem("Inventory")+"")?.Id}
                  onChange={(e ,obj)=>setInventory(obj)}                          
                  mode={"create"}
                  required={true}
                  />
              </div>
              <div className="col-lg-12 mt-1 btns_form">
                  <Button btnType="submit" value="Save"  />
              </div>
              </form>
            </div>
          </div>


        </ModalForm>   
      
      }
      <div className="wrap_logo">
        <div className="d-flex justify-content-between">
          <div >
           <Link to={'/'} className="d-flex"> <img src={logo} className="" />
            <strong >فناوری اطلاعات تدبیر</strong></Link>
          </div>
          <div className="icon_bar">
            <span className={`${layoutContext.sidebarIsOpen && 'mx-1' }`} style={{cursor: 'pointer'}}>
              <FontAwesomeIcon
                icon={[ICON_TYPE, "bars"]}
                onClick={layoutContext.toggleSidebar}
              />
            </span>
          </div>
        </div>
      </div>
     

      {props.className && iconApp != "hide_logo" ? (
        <div >
          <SelectBox
            selectType="selectIcon"
            onChange={(e)=>{chechChangeApp(e,handleChangeForSmallSelect)}}
            options={itemsName}
            val={itemsId}
            value={selectedOptionSmallSelect}
            name=""
            icons={itemIcons}
          />
        </div>
      ) : (
        <SelectBox
          selectType="selectImg"
          value={selectedOption}
          onChange={(e)=>{chechChangeApp(e,handleChange)}}
          options={items}
          val={items}
          name=""
        />
      )}
    </div>
  );
};
