import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";


export const FullScreanButton = () => {

  const [flag, setFlag] = useState<boolean>(false);
  const [fullscreen, setFullScreen] = useState(false);
  const fullscreenRef = useRef<any>(false)

  const handleExitFullScreen = () => {
    if(document.fullscreenElement != null){
      document.exitFullscreen();
    }
    setFullScreen(false);
    fullscreenRef.current = false
  };

  const handleFullScreen = () => {

    document.querySelector("body")?.requestFullscreen({ navigationUI: "show" });
    setFullScreen(true);
    fullscreenRef.current = true

  };
  
  const enterNavigat = (event)=>{
    if(event.key == "Enter"){
      if(fullscreenRef.current){handleExitFullScreen()}else{handleFullScreen()}
    }

  }

  useEffect(()=>{

    if(flag == true){
      document.addEventListener("keydown",enterNavigat)
    }
    return () =>{document.removeEventListener("keydown",enterNavigat)}
  },[flag])




  return (
    <>
    <OverlayTrigger
        placement="bottom"
        delay={{"show" : 300 , "hide":0}}
        overlay={
            <Tooltip className="tooltip" id={`button-tooltip-${"expand-arrows-altg"}`}>
                {trans('FullScreen')}
            </Tooltip>
        }
        >
          <div style={{marginTop:'0.1rem'}}>
       <a href="#" onFocus={()=>setFlag(true)} onBlur={()=>setFlag(false)}>
              <FontAwesomeIcon
                icon={[ICON_TYPE, "expand-arrows-alt"]}
                className={`${fullscreen ? "d-none" : "d-block"} `}
                onClick={handleFullScreen}
                style={{marginBottom:'0.3rem'}}
              />
              <FontAwesomeIcon
                icon={[ICON_TYPE, "compress-arrows-alt"]}
                className={`${fullscreen ? "d-block" : "d-none"}`}
                onClick={handleExitFullScreen}
              />
      </a>
      </div>
      </OverlayTrigger>
  
    </>
  );
};
