import { useContext, useEffect, useState } from "react";
import { Loading } from "components/Loading/loading";
import { Response } from "types/types";
import { deletePersonAsync, getPersonLegalList } from "../api/api";
import { PersonLegalForm } from "./component/PersonLegalForm/PersonLegalForm";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Message } from "components/Message/Message";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { Toast } from "components/ToastShow/ToastShow";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toolbar } from "components/Toolbar/Toolbar";
import { ToolbarInTab } from "components/ToolbarInTab/ToolbarInTab";

interface PersonLegalListProp {

  curentTab?:string;

}
export const PersonLegalList = ({ curentTab}: PersonLegalListProp) => {
  let personLegalList = [];
  let HeadPersonLegal: string[] = []; 
  let Total_records, pages;
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedPersonId, setSelectedPersonId] = useState<string>();
  const [responsePerson, setResponsePerson] = useState<Response | any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [orderBy, setorderBy] = useState<any>([]);
  const [messageType, setMessageType] = useState<any>("info");
  const [person, setPerson] = useState();

  const [filterlist, setFilterlist] = useState<any>("");





  const [idPersonLegal, setIdPersonLegal] = useState<any>(null);
  const [showForm, setShowForm] = useState<any>(null);
  const [forceUpdate, setForceUpdate] = useState(true); // integer state
  const [mode, setMode] = useState<any>()
  const [selectedList, setSelectedList] = useState<any>()
  const [search, setSearch] = useState(null);

//  let filterlist =""
  



const clickToolbarCallback = (clickedButtonName: string) => {
  switch (clickedButtonName.toLowerCase()) {
    case "reference":alert("call api reference with Id :");break;
    case "view": if (idPersonLegal){setMode("view"); setShowForm(true)}; break;
    case "edit": if(idPersonLegal){setMode("edit"); setShowForm(true)};break;
    case "print":alert("print ");break;
    case "excel":downloadCSV(responsePerson?.Data ,1 ,trans("PersonLegalList"));break;
    case "remove": if (idPersonLegal)handleDeletePersonLegal(idPersonLegal); break;
    case "refresh":setForceUpdate(!forceUpdate);break;
    case "create":
      setMode("create")
      setShowForm(true)
      setIdPersonLegal(null);
      break;
  }
};


const Searchhandleer = (str) => {
  setPageNum(1);
  if(str =="" ){
      setSearch(null)
  }else{
      setSearch(str)
  }
}

  const getWithOrder = (order: string[]) => {
    setorderBy(order)


  }




  const handlechangePageSize = (e: any) => {
    setPageSize(e.currentTarget.value);
    setPageNum(1);
     getPageSize("PersonLegal" ,e.currentTarget.value )
        getPersonLegalListAsync(e.currentTarget.value)
  };
  const handleGoPage = (value) => {
    if (value) {
      if (value < 1 || value > pages) {}
      else setPageNum(value);
    } else {
    
    }
  };

  const HandleNextPage = () => {
    if (pageNum < pages)
      setPageNum((pageNum) => {
        return pageNum + 1;
      });
  };
  const HandlePrevPage = () => {
    if (pageNum > 1)
      setPageNum((pageNum) => {
        return pageNum - 1;
      });
  };

  const personIdCallback = (obj , index) => {

    if(sessionStorage.getItem("changingforms")+""=="true"){
      Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{  
         sessionStorage.setItem("changingforms" , "false"); 
        setSelectedPersonId(obj.Id);
        setIdPersonLegal(obj.Id);
        localStorage.removeItem("formsInChang");  });
    }else{
      setSelectedPersonId(obj.Id);
      setIdPersonLegal(obj.Id);    }

  };

  const handleEditPersonLegal = (id: string) => {
    setShowForm(true);
    setIdPersonLegal(id);
    setMode("edit")
  };
  async function deletePerson(id){

    try {
         const response = await  deletePersonAsync(id)
         getPersonLegalListAsync();


    } catch (err: any) {
     
    }
  
  }
  
  const handleDeletePersonLegal = (id: string) => {
    Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{deletePerson(id)}) 
    setIdPersonLegal(id)    


  }
  const handleViewPersonLegal = (id: string) => {
    setShowForm(true);
    setIdPersonLegal(id);
    setMode("view")

   };

  // <PersonLegalForm type="edit" personLegal={legalPerson} />

  async function getPersonLegalListAsync(size=pageSize) {
   // setResponsePerson(null)
    setLoading(false)
    let pageSizeChangedByUser = getPageSize("PersonLegal" )
    setPageSize(pageSizeChangedByUser)
    try {
      const response = await getPersonLegalList(pageNum, pageSizeChangedByUser ?pageSizeChangedByUser : size ,orderBy.join(","),filterlist ,search);
      setResponsePerson(response.Data)
    } catch (error) {
      setMessageType("error")
    }
 
    setLoading(true);
  }


  // let objPersonLegal: any = {};
  useEffect(() => {
    if(curentTab == "PersonLegal")
      getPersonLegalListAsync();
  }, [pageNum, person ,orderBy  ,filterlist ,forceUpdate, search,curentTab]);
  





  useEffect(() => {
    let settings = getGridSetting("PersonLegal" )
    setorderBy(settings[1])
    setFilterlist(settings[0])
    if((sessionStorage.getItem("changingforms")=="true"||localStorage.getItem("formsInChang")!=null)&&curentTab == "PersonLegal"){
      let forms = JSON.parse(localStorage.getItem("formsInChang")+"") 
      if(forms && forms["PersonLegal"] ){
       Toast(trans("AreYouSureToRecoverUnsavedData"),"warning","alert",()=>{
         forms["PersonLegal"]?.Id ? setMode("edit"):setMode("create")
         sessionStorage.setItem("changingforms" , "true");
           setIdPersonLegal(forms["PersonLegal"]?.Id)
           setShowForm(true)
       },"","",()=>{
           sessionStorage.setItem("changingforms" , "false");
           localStorage.removeItem("formsInChang");
       })
      }    
       
    }
    
  }, [curentTab]);

  if (responsePerson && responsePerson.Data.length > 0) {
    personLegalList = responsePerson.Data;
    HeadPersonLegal = Object.keys(personLegalList[0]);
    Total_records = responsePerson.TotalRecords;
  }
  pages = Total_records > pageSize ? Math.ceil(Total_records / pageSize) : 1;

  const handleAddPerson = (ans) => {
    setPerson(ans);
  }

  return (
    <>
          {showForm  ?
             <ModalForm mode={mode} modalHeight={100} onHide={()=>{setShowForm(false)}} show={showForm} modalProfileKey={"PersonLegalForm"}    title={trans("PersonLegalForm") } >
               {  
               !idPersonLegal ?
                  <PersonLegalForm
                    mode={mode}
                    handleCancelForm={()=>{setShowForm(false)}}
                    handleAddPerson={handleAddPerson}
                  />
                  :
                  <PersonLegalForm
                    mode={mode}
                    handleCancelForm={()=>{setShowForm(false)}}
                    handleAddPerson={handleAddPerson}
                    idPerson={idPersonLegal}
                  />
           }
             </ModalForm> 
        : null}
      <ToolbarInTab excel={false} handleSearch={Searchhandleer} id={idPersonLegal} create={true} remove={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />

      <div style={{overflowY: "auto", 
          height: "63vh",
           scrollbarWidth: "thin"}} className="col-lg-12 mt-1">

        <div
          className='person-card'>
          <div className="person-card__body">
            {loading ? (
              <>
                {responsePerson &&responsePerson?.Data.length>0 ? ( //{responsePerson != responsePersonPre ? <> //uncomment when using DataGridAg => prevent seconde render
                  <DataGrid
                    handlefiltering={(str)=>{setFilterlist(str) ; }}
                    filterStr={filterlist}
                    DoubleClick={(e)=>{setIdPersonLegal(e.Id) ;clickToolbarCallback("view")}}
                    orderFormat={getWithOrder}
                    orderFormatList={orderBy}
                    multiSelect={false}
                    selectList={setSelectedList}
                    parentName={"PersonLegal"}
                    theadData={HeadPersonLegal}
                    minCellWidth={80}
                    tbodyData={personLegalList}
                    selectedRowObjectCallback={personIdCallback}
                    handleEdit={handleEditPersonLegal}
                    handleDelete={handleDeletePersonLegal}
                    handleView={handleViewPersonLegal}
                    totalRecords={Total_records}
                    pageNum={pageNum}
                    pageSize={pageSize}
                    HandleNextPage={HandleNextPage}
                    HandlePrevPage={HandlePrevPage}
                    handlechangePageSize={handlechangePageSize}
                    first={() => setPageNum(pages)}
                    end={() => setPageNum(1)}
                    handleGoPage={handleGoPage}
                  />
                ):<Message message={"NoDataForPreview"} type={messageType} />
 
                 
                 }
              </>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
