

import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, trans, uuidv4 } from "utils/helpers";

import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { SelectBox } from "components/Select/Select";
import { ParameterDataTypeDictionary } from "./ParameterDataTypeDictionary";
import Datepicker from "components/DatePicker/DatePicker";


interface FeedParametersProps {
    FeedParametersListCallback: (e) => void,
    onHide: () => void,
    obj?: any,
}
export const FeedParametersForm = ({obj, FeedParametersListCallback ,  onHide}: FeedParametersProps) => {

  
    const [response, setResponse] = useState<Response | any>(obj?obj:[]);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [showForm, setShowForm] = useState(false);
    const [dataType, setDataType] = useState<any>("System.String");
    const [dateDefaultValue,setDateDefaultValue] = useState<string | null>(null)


    const handleCreateFeedParameter = () => {
        setFormLoading(true)
        setEditForm(null)
        setMode("create")
        setShowForm(true)
    }

    const handleDeleteRow =async (id: string = selectedObj.Id) => {
        let newData = response.filter(e => e.Id != id)
        setResponse(newData)
        setSelectedObj(null)
        setForceUpdate(!forceUpdate)
    }
    const handleViewRow = () => {
        setMode("view")
        setShowForm(true)
        setEditForm(selectedObj)
      
    }

    const selectedIdCallback = (obj, index) => {
        if(showForm && mode != 'create'){
         setEditForm(obj)
        }
        setSelectedObj(obj) 
    }

    const submit = (e) => {
        e.preventDefault();
         let data ={
             Id: uuidv4(),
             ParameterName: e.target.ParameterName.value,
             ParameterNameEN:e.target.ParameterNameEN?.value,
             ParameterDataType:dataType ,
             IsOptional:e.target.IsOptional.checked,
             ParameterDefaultValue:(dataType.includes("Date")||dataType.includes("Time") ) ?dateDefaultValue   : (dataType.includes("Boolean") ?  e.target.ParameterDefaultValue.checked :  e.target.ParameterDefaultValue.value)   ,

        }
        setResponse([...response,data])
        setEditForm(null);
        setResetForm(true)

    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
              
                setEditForm(null);
                setShowForm(false)
            });
        }else{
            setEditForm(null);
            setShowForm(false)     
        };



    }


    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };





    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);





    /* #region toolbarFeedParameters */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,1 ,trans("FeedParameters"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateFeedParameter(); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "help": alert("call api help"); break;
            case "remove":if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,()=>handleDeleteRow(selectedObj?.Id) ); break;

        }
    };
    /* #endregion */


    return (
        <>
            <div className="cartable-FeedParameter">

                <Toolbar  remove={true}  id={selectedObj?.Id} emptyList={response == null || response?.length == 0} create={true} edit={false} search={false} handleClickToolbar={clickToolbarCallback} />
                <div className="card">
                    <div className="">
                        <>
                            {showForm&& 
                            <>
                              <div className="card mb-4">
                                {formLoading ? 
                                <form className="TypeForm row card-body " onSubmit={submit}>
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox  required={true} textboxName={"ParameterName"} textboxType="text" resetForm={resetForm} labelText={"ParameterName"} mode={mode}  defaultValue={editForm?.ParameterName} />
                                       
                                        </div> 
                                        <div className="col-md-4 col-sm-12">
                                        <Textbox  required={true} textboxName={"ParameterNameEN"} textboxType="text" resetForm={resetForm} labelText={"ParameterNameEN"} mode={mode}  defaultValue={editForm?.ParameterNameEN} />
                                        </div>    
    {/*                                     <div className="col-md-4 col-sm-12">
                                            <Textbox  required={true} textboxName={"ParameterDataType"} textboxType="text" resetForm={resetForm} labelText={"ParameterDataType"} mode={mode}  defaultValue={editForm?.ParameterDataType} />
                                        </div>   */}   

                                        <div className="col-md-4 col-sm-12">
                                            <ParameterDataTypeDictionary
                                             onChange={(e)=>{setDataType(e.target.value)}}
                                             name="ParameterDataType"
                                             mode={mode}
                                            />   
                                        </div>

                            <div className="col-3 ">
                                        {
                                 {
                                     "System.String": (
                                        <Textbox
                                        defaultValue={""}      
                                         textboxType="text"
                                        textboxName={"ParameterDefaultValue"}
                                        labelText={"ParameterDefaultValue"}
                                        resetForm={resetForm}
                                        mode={mode}
                    
                                    />
                                     ),
                                     "System.TimeOnly": (
                                        <Datepicker
                                        mode={mode}
                                        resetForm={resetForm}
                                        lable="defaultValue"
                                        setIsoTime={(isoTime: string) => {setDateDefaultValue(isoTime)}}
                                        val={ null} //resetForm ? new Date().toISOString() : 
                                        withTime ={true}
                                        format={"HH:mm"}
                                        disableDayPicker={true}
                                        onOpenPickNewDate={true}
                                      />  

                                     ),
                                     "System.DateOnly": (
                                        <Datepicker
                                        mode={mode}
                                        resetForm={resetForm}
                                        lable="defaultValue"
                                        setIsoTime={(isoTime: string) => {setDateDefaultValue(isoTime)}}
                                        val={ null} //resetForm ? new Date().toISOString() : 
                                        format={"YYYY/MM/DD"}
                                        onOpenPickNewDate={true}
                                      />  
                                     ),
                                     "System.DateTime": (
                                        <Datepicker
                                        mode={mode}
                                        resetForm={resetForm}
                                        lable="defaultValue"
                                        setIsoTime={(isoTime: string) => {setDateDefaultValue(isoTime)}}
                                        val={ null} //resetForm ? new Date().toISOString() : 
                                        withTime ={true}
                                        onOpenPickNewDate={true}
                                      />  
                                     ),
                                     "System.Char": (
                                        <Textbox
                                        defaultValue={""}      
                                         textboxType="text"
                                        textboxName={"ParameterDefaultValue"}
                                        labelText={"ParameterDefaultValue"}
                                        resetForm={resetForm}
                                        mode={mode}
                                        />
                                     ),
                                     "System.Double": (                   
                                        <Textbox
                                        defaultValue={''}      
                                         textboxType="number"
                                        textboxName={"ParameterDefaultValue"}
                                        labelText={"ParameterDefaultValue"}
                                        resetForm={resetForm}
                                        mode={mode}
                                        />
                                         
                                     ),
                                     "System.UInt64": (                   
                                        <Textbox
                                        defaultValue={""}      
                                         textboxType="number"
                                        textboxName={"ParameterDefaultValue"}
                                        labelText={"ParameterDefaultValue"}
                                        resetForm={resetForm}
                                        mode={mode}
                                        />
                                         
                                     ),
                                     "System.UInt32": (                   
                                        <Textbox
                                        defaultValue={""}      
                                         textboxType="number"
                                        textboxName={"ParameterDefaultValue"}
                                        labelText={"ParameterDefaultValue"}
                                        resetForm={resetForm}
                                        mode={mode}
                                        />
                                         
                                     ),
                                     "System.UInt16": (                   
                                        <Textbox
                                        defaultValue={""}      
                                         textboxType="number"
                                        textboxName={"ParameterDefaultValue"}
                                        labelText={"ParameterDefaultValue"}
                                        resetForm={resetForm}
                                        mode={mode}
                                        />
                                         
                                     ),
                                     "System.Decimal": (                   
                                        <Textbox
                                        defaultValue={""}      
                                         textboxType="number"
                                        textboxName={"ParameterDefaultValue"}
                                        labelText={"ParameterDefaultValue"}
                                        resetForm={resetForm}
                                        mode={mode}
                                        />
                                         
                                     ),
                                     "System.Int64": (                   
                                        <Textbox
                                        defaultValue={""}      
                                         textboxType="number"
                                        textboxName={"ParameterDefaultValue"}
                                        labelText={"ParameterDefaultValue"}
                                        resetForm={resetForm}
                                        mode={mode}
                                        />
                                         
                                     ),
                                     "System.Int32": (                   
                                        <Textbox
                                        defaultValue={""}      
                                         textboxType="number"
                                        textboxName={"ParameterDefaultValue"}
                                        labelText={"ParameterDefaultValue"}
                                        resetForm={resetForm}
                                        mode={mode}
                                        />
                                         
                                     ),
                                     "System.Int16": (                   
                                        <Textbox
                                        defaultValue={""}      
                                         textboxType="number"
                                        textboxName={"ParameterDefaultValue"}
                                        labelText={"ParameterDefaultValue"}
                                        resetForm={resetForm}
                                        mode={mode}
                                        />
                                         
                                     ),
                                     "System.Boolean": (
                                         <>
                                           
                                                <CheckableButton
                                                defaultChecked={false}
                                                defaultValue={"false"}
                                                labelText={"ParameterDefaultValue"}
                                                inputName={"ParameterDefaultValue"}
                                                type="checkbox"
                                                mode={mode}
                                             />   
                                           
                                         </>
                                     )
                                 }[dataType]
                                 
                             }
                    </div>
                            {/*             <div className="col-md-4 col-sm-12">
                                            <Textbox  required={true} textboxName={"ParameterDefaultValue"} textboxType="text" resetForm={resetForm} labelText={"ParameterDefaultValue"} mode={mode}  defaultValue={editForm?.ParameterDefaultValue} />
                                        </div>      */}
                                        <div className=" mt-4 mb-4" >
                                            <CheckableButton
                                               defaultChecked={editForm?.IsOptional}
                                               defaultValue={editForm?.IsOptional}
                                               labelText={"IsOptional"}
                                               inputName={"IsOptional"}
                                               type="checkbox"
                                               mode={mode}
                                            />                                
                                        </div>    
                                        {  mode !="view" ?
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button value="Save" btnType="submit" />
                                              <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                                              </div>
                                              :
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                                              </div>
                                          }                        
                                </form> : <Loading/>}

                              </div >
                           </>
                            }
                            {
                                loading ?
                                response?.length > 0 ?
                                    <>
                                            <DataGrid
                                                parentName={"FeedParameters"}
                                                startIndex={1}
                                                theadData={Object.keys(response[0])}
                                                rowSelected={selectedObj}
                                                tbodyData={response}
                                                scrollHeight={showForm? "25vh": "67vh"}
                                                minCellWidth={80}
                                                selectedRowObjectCallback={selectedIdCallback}
                                                handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,()=> handleDeleteRow(e))}}
                                                handleView={handleViewRow}
                                            />
                                    </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                                : <Loading />
                            }
                            </>
                    </div>
                    <div className="col-lg-12 mt-1 btns_form">
                    <Button btnType="primary" value="save" onClick={()=>{FeedParametersListCallback(response);onHide()}} />
                    </div>
                </div>
            </div>
        </>

    );
}