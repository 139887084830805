
import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { deleteSecretariat, getListSecretariats, postSecretariats, putSecretariats } from "./api/api";
import { PatternForm } from "./components/PatternForm";
import { SecretariatForm } from "./components/SecretariatForm/SecretariatForm";
import { SecretariatMembers } from "./components/SecretariatMembers/SecretariatMembers";
import { BoardAccess } from "pages/OfficeAutomation/Features/BulletinBoard/Boards/components/BoardAccess";



interface Form {
    Title: string,
    Status: number
}
export const Secretariats= () => {
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [mode, setMode] = useState("false");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [flag, setFlag] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<any | null>();
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [loading, setLoading] = useState(false);
    const [referenceRul, setReferenceRules] = useState(false);
    const [reload, setReload] = useState(false)
    const [pageNum, setPageNum] = useState<number>(1);
    const [orderBy, setOrderBy] = useState<any>([]);
    const [filterlist, setFilterlist] = useState<any>("");
    const [pages, setPages] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [search, setSearch] = useState(null);
    const [showAccess, setShowAccess] = useState<any>(false);
    const [boardData, setBoardData] = useState<any>(false);
    const [showPatternForm, setShowPatternForm] = useState<any>(false);
    const [showSecretariatForm, setShowSecretariatForm] = useState<any>(false);



    const getWithOrder = (order: string[]) => {
        setOrderBy(order)
      }
    

      const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("Secretariats" , e.currentTarget.value );
        getSecretariatsAsync(1);
      };
    
      const handleGoPage = (value) => {
        if (value) {
          if (value < 1 || value > pages) {}
          else setPageNum(+value);getSecretariatsAsync(+value);
        } else {
     
        }
      };
      const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getSecretariatsAsync(pageNum + 1);
          }
      };
      
      const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getSecretariatsAsync(pageNum - 1);
          }
      };
    

      const searchHandler = (str) => {
        setPageNum(1);
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }



    const handleCreateAction = () => {
        setMode("create")
        setShowSecretariatForm(true)

    }
    const handleEditRow = () => {
        setMode("edit");
        setShowSecretariatForm(true)

      
    }

    const handleDeleteRow = async() => {
        try {
            const res  = await deleteSecretariat(selectedObj.Id)
            getSecretariatsAsync()
            setResetForm(true)
            setForceUpdate(!forceUpdate)

        } catch (error) {
            
        }
    }
    const handleViewRow = () => {
        setMode("view")
        setShowSecretariatForm(true)


    }

    const selectedIdCallback = (obj, index) => {
        setSelectedObj(obj);
    }

    const submit = (data) => {
        if (editForm == null) {
            setBoardData(data)
            setShowAccess(true)
        } else {
        }
        setShowSecretariatForm(false)

    }

    function accessDataHandler(data) {
        if(mode == "create"){
        let formdata:any ={...boardData}
         
        formdata.SecretariatAccesses = data ;
        postSecretariats(formdata).then(() => { getSecretariatsAsync(); setResetForm(true)
            setShowAccess(false)
        });
        }else{
        let Secretariat = {
            ...boardData,
            SecretariatAccesses:{
                data
            }
        }
        putSecretariats(Secretariat).then(() => { getSecretariatsAsync(); setResetForm(true); setEditForm(null) })
        }
    }

    async function getSecretariatsAsync(PageNum = pageNum) {
        setLoading(false);
        setResponse(null);       
        let pageSizeChangedByUser = getPageSize("Secretariats" )
        setPageSize(pageSizeChangedByUser)
        try {
            
            const res = await getListSecretariats(PageNum, pageSizeChangedByUser, orderBy.join(",") , filterlist ,search);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;       
            setResponse(res.Data);
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)

    }

    
    useEffect(() => {
        setResponse(null)
        getSecretariatsAsync();
    }, [forceUpdate,search , orderBy ,filterlist ,flag]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);
    
    if (flag) {
        getSecretariatsAsync()
        setFlag(false)

    }

    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("Secretariats"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); setEditForm(null);  break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id) handleEditRow(); break;
            case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "help": alert("call api help"); break;
            case "assignrule": if (selectedObj?.Id) setReferenceRules(!referenceRul); break;
            case "pattern": if (selectedObj?.Id) setShowPatternForm(true); break;

        }
    };
    /* #endregion */

    return (
        <>
            {showSecretariatForm &&
              <ModalForm modalHeight={40} title={trans("SecretariatForm")} modalProfileKey="SecretariatForm" onHide={() => setShowSecretariatForm(false)} show={showSecretariatForm}>
                <SecretariatForm resetForm={()=>{setForceUpdate(!forceUpdate);}} submit={submit} SecretariatId={selectedObj?.Id} onHide={() => { setShowSecretariatForm(false); setReload(!reload); } } mode={mode}  />
              </ModalForm>
            }
            {
              showPatternForm &&
              <ModalForm title={"PatternForm"} onHide={() => setShowPatternForm(false)} show={showPatternForm}>
                <PatternForm SecretariatId={selectedObj?.Id} showForm={()=>{setShowPatternForm(false) ; setReload(!reload) }}  />
              </ModalForm>
            }
            {
              referenceRul &&
              <ModalForm modalProfileKey={"Users"} title={trans("Users") } onHide={() => setReferenceRules(false)} show={referenceRul}>
                <SecretariatMembers SecretariatId={selectedObj?.Id} showForm={()=>{setReferenceRules(false) ; setReload(!reload) }}  />
              </ModalForm>
            }
            { showAccess &&
              <ModalForm  mode={mode} modalProfileKey={"Users"} title={trans("Users")+" : "  +boardData.Secretariat.Name } onHide={() => setShowAccess(false)} show={showAccess}>
                <BoardAccess
                  showForm={setShowAccess} 
                  accessDatahandler={accessDataHandler} 
                  accessData={response?.SecretariatAccesses}
                  mode={mode}
                />
              </ModalForm>
            }
            <Toolbar handleSearch={searchHandler} pattern={false} remove={true} assignRule={true} emptyList={response?.TotalRecords == 0} id={selectedObj?.Id} create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
            <div className="card" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                <div className="">
                    {loading ?
                        <div className="cartable-action">
                            {
                                response?.Data?.length > 0 ?
                                    <>
                                        <DataGrid
                                            orderFormat={getWithOrder}
                                            orderFormatList={orderBy}
                                            handlefiltering={(str)=>{setFilterlist(str) ; }}
                                            filterStr={filterlist}
                                            parentName={"Secretariats"}
                                            startIndex={1}
                                            theadData={Object.keys(response.Data[0])}
                                            tbodyData={response.Data}
                                            minCellWidth={80}
                                            selectedRowObjectCallback={selectedIdCallback}
                                            handleEdit={handleEditRow}
                                            //handleDelete={handleDeleteRow}
                                            handleView={handleViewRow}
                                            totalRecords={response.TotalRecords}
                                            pageNum={pageNum}
                                            pageSize={pageSize}
                                            HandleNextPage={handleNextPage}
                                            HandlePrevPage={handlePrevPage}
                                            handlechangePageSize={handleChangePageSize}
                                            first={() => {getSecretariatsAsync(pages) ; setPageNum(pages)}}
                                            end={() => {getSecretariatsAsync(1) ; setPageNum(1)}}
                                            handleGoPage={handleGoPage}  
                                        />
                                    </>
                                    : <Message message={catchMessage.msg} type={catchMessage.type} />
                            }
                        </div>
                        : <Loading />
                    }
                </div>
            </div>
        </>

    );
}





