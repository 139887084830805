import { Button } from "components/Button/Button";
import Datepicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { Time } from "components/Time/Time";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useRef, useState } from "react";
import { InvitedPersonType } from "types/enum";
import { MEETINGMANAGEMENT_URL } from "utils/constants";
import { ENDPOINTSMEETINGMANAGEMENT } from "utils/endpoints";
import { trans } from "utils/helpers";
import { AttendeesPeople } from "../AttendeesPeople/AttendeesPeople";
import { getInvitedPeopleListById } from "../InvitedPeopleList/api";
import { checkPlaceConflict, getMeetingById, updateMeetingMinutes } from "./api";

interface MeetingFormProps {
    showForm:()=>void;
    onHide?: () => void;
    reload:(e:boolean)=>void;
    handleData:(e:any)=>void;
    setListPosition:(e:any)=>void;
    setInvitaionObj:(e:any)=>void;
    setAcceptExpireTime:(e:any)=>void;
    selectedObj?:any;
    jobId?:any;
    processId?:any;
    referenceId?:any;
    mode:any;
    step?:any,
    listPosition?:any,
    formRef:any,

}

export const MeetingForm = ({selectedObj,setInvitaionObj ,setAcceptExpireTime ,processId,referenceId,setListPosition,listPosition,jobId,mode,showForm,handleData,reload,onHide ,step,formRef}:MeetingFormProps) => {
    const [resetForm, setResetForm] = useState(false);
    const [response, setResponse] = useState<any>();
    const [loading, setLoading] = useState<any>(true);
    const [userChanges, setUserChanges] = useState({}); 
    const [predictedStartTime, setPredictedStartTime] = useState<any>(null);  
    const [predictedDate, setPredictedDate] = useState<any>(null);  
    const [predictedTimeLength, setPredictedTimeLength] = useState<any>(null); 
    const [predictedEndTime, setPredictedEndTime] = useState<any>(null); 
    const [predictedload, setPredictedload] = useState<any>(null); 
    const [startTime, setStartTime] = useState<any>(null); 
    const [endTime, setEndTime] = useState<any>(null); 
    const [meetingClassification, setMeetingClassification] = useState<any>(null); 
    const invitaionDataRef = useRef<any>(null)
     const [showAttendeesPeople, setShowAttendeesPeople] = useState(false); 
    const [defaultPeopleList, setDefaultPeopleList] = useState<any>({}); 




    const handleMeetingFormSubmit =async (event) => {
        event.preventDefault();
        try {
            let placedata = {"MeetingPlaceId": event.target.MeetingPlace?.value,
                "StartTime": predictedDate.split("T")[0]+"T"+ predictedStartTime.split("T")[1],
                "PredictedTimeLength":predictedTimeLength}
                let res:boolean = false
                if((step<2&&mode!="create")){
                    res = true
                }else{
                    res = await checkPlaceConflict(placedata)
                }
                 if(res){
                     let data:any={
                       "Title":event.target.Title?.value,
                       "MeetingClassificationId": event.target.MeetingClassification?.value ? event.target.MeetingClassification.value:null,
                       "MeetingHoldingType" : +event.target.MeetingHoldingType?.value,
                       "MeetingPlaceId": event.target.MeetingPlace?.value ,
                       "NecessaryHoldingTools":event.target.NecessaryHoldingTools?.value,
                       "RequesterPositionId": listPosition?.['RequesterPositionId']?.Id ? listPosition?.['RequesterPositionId'].Id : null,
                       "RequesterDescription":listPosition?.['RequesterPositionId']?.Name ? listPosition?.['RequesterPositionId'].Name : null,
                       "SecretaryPositionId":listPosition?.['SecretaryPositionId']?.Id ? listPosition?.['SecretaryPositionId'].Id : null,
                       "SecretaryDescription":listPosition?.['SecretaryPositionId']?.Name ? listPosition?.['SecretaryPositionId'].Name : null,
                       "ExecuterPositionId":listPosition?.['ExecuterPositionId']?.Id ? listPosition?.['ExecuterPositionId'].Id : null,
                       "ExecuterDescription":listPosition?.['ExecuterPositionId']?.Name ? listPosition?.['ExecuterPositionId'].Name : null,
                       "BossPositionId": listPosition?.['BossPositionId']?.Id ? listPosition?.['BossPositionId'].Id : null,
                       "BossDescription":listPosition?.['BossPositionId']?.Name ? listPosition?.['BossPositionId'].Name : null,
                       "PredictedStartTime": predictedDate.split("T")[0]+"T"+ predictedStartTime.split("T")[1],
                       "PredictedTimeLength": predictedTimeLength? predictedTimeLength : null,
                       "InvitationText":event.target.InvitationText?.value,
                       "MeetingAgenda": event.target.MeetingAgenda?.value,    
                    }
                    if(mode!="create" )data.Id =response.Id 
                    handleData(data)
/*                      if((step<2&&mode!="create")){
                        let obj = {
                            Meeting: {
                                ...data,
                                Id: response.Id , 
                                AcceptExpireTime:invitaionDataRef.current.Meeting.AcceptExpireTime,
                                InvitationText:invitaionDataRef.current.Meeting.InvitationText,
                                MeetingNo: event.target?.MeetingNo?.value ,
                            } ,
                            InvitedPeople:invitaionDataRef.current.InvitedPeople
                        }
                     }else{
                         setMeetingFormData(data)
                         setShowInvitPeopleForm(true)
                     } */
                 }

            } catch (error) {
                
            }      
    }
    const updateMeetingMinutesAsync = async(event) => {
        event.preventDefault();
        try {
            await updateMeetingMinutes({meetingId :response.Id ,meetingMinutes: event.target.MeetingMinutes.value , startTime:startTime ,endTime:endTime})
            setUserChanges({});
            onHide&&onHide()
            showForm()
        } catch (error) {
            
        }

    }

    async function getMeetingAsync() {
        setLoading(false)
        try {
            const data = await getMeetingById(jobId ? jobId:selectedObj?.Id );
            setResponse(data.Data);
            if((step<2&&mode!="create")){
                let obj = {
                    Meeting: {
                        AcceptExpireTime:data.Data.AcceptExpireTime,
                        InvitationText:data.Data.InvitationText,
                    } 
                }
                setInvitaionObj(obj)
                invitaionDataRef.current = obj
                let newListPosition ={
                    BossPositionId:{
                        Id: data.Data.BossPositionId ,
                        Name: data.Data.BossDescription ,
                    },
                    ExecuterPositionId:{
                        Id: data.Data.ExecuterPositionId ,
                        Name: data.Data.ExecuterDescription ,
                    },
                    RequesterPositionId:{
                        Id: data.Data.RequesterPositionId ,
                        Name: data.Data.RequesterDescription ,
                    },
                    SecretaryPositionId:{
                        Id: data.Data.SecretaryPositionId ,
                        Name: data.Data.SecretaryPositionId ,
                    },
                }
                setListPosition(newListPosition)
            }
            handlePredictedTimeLength(data.Data.PredictedStartTime ,data.Data.PredictedEndTime)

        } catch (error) {
            
        }
        setLoading(true)

    }

      

    function cancel() {
      if(sessionStorage.getItem("changingforms")+""=="true"){
          Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
              sessionStorage.setItem("changingforms" , "false");
              setUserChanges({});
              showForm()
          });
      }else{
          showForm()     
      }
  }
  async function  getInvitedPeopleListAsync() {


    try {
    const res = await getInvitedPeopleListById(null ,jobId?jobId :selectedObj?.Id);  
    let obj:any = {}    
    let personList:any = []
    res.Data.map(item=>{
        let personData = {
            PositionId : item.PositionId,
            PositionDescription: item.InvitedPerson,
            InvitedPersonType: item.InvitedPersonTypeEnum
        }
        personList.push(personData)
        if(obj[InvitedPersonType[item.InvitedPersonTypeEnum]]==undefined){

            obj[InvitedPersonType[item.InvitedPersonTypeEnum]]=[{PositionId:item.PositionId,Description:item.InvitedPerson}] 
        }else{

            obj[InvitedPersonType[item.InvitedPersonTypeEnum]]=[...obj[InvitedPersonType[item.InvitedPersonTypeEnum]] , {PositionId:item.PositionId,Description:item.InvitedPerson}] 
        }
    })
    if((step<2&&mode!="create")){
        invitaionDataRef.current = { Meeting:invitaionDataRef.current?.Meeting  , InvitedPeople:obj }
        setInvitaionObj({ Meeting:invitaionDataRef.current?.Meeting  , InvitedPeople:obj ,  })   
    }

        setDefaultPeopleList(obj)   
    } catch (error) {
        
    }
   


}
  
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };
  const handlePredictedTimeLength = (fr ,en) => {
    setPredictedTimeLength( Math.abs(new Date(fr).getTime()-new Date(en).getTime())/1000 )
  };

useEffect(() => {
    if(Object.values(userChanges).filter((item)=>item!=null).length>0){
        sessionStorage.setItem("changingforms" , "true")
          window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
           window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }else{
        sessionStorage.setItem("changingforms" , "false")
        window.removeEventListener('beforeunload', handleBeforeUnload);

    }
}, [userChanges]);

    useEffect(() => {
        if(mode != 'create'){
    
            getMeetingAsync();
        }else{
            setLoading(true)
        }
        if((step<2&&mode!="create")){
            getInvitedPeopleListAsync()
        }
    }, []);

     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);
      
     useEffect(() => {
        setPredictedload(false)
        if(predictedDate&&predictedStartTime){
            setPredictedEndTime( new Date(predictedDate.split("T")[0]+"T"+ predictedStartTime.split("T")[1]).getTime()+(predictedTimeLength*60000));
        
            setAcceptExpireTime(new Date(predictedDate.split("T")[0]+"T"+ predictedStartTime.split("T")[1]).getTime()-(24*60000*60) )  
        }
        

      }, [predictedTimeLength ,predictedStartTime ,predictedDate]);
     useEffect(() => {
        setPredictedload(true)
        
      }, [predictedload]);
      

     
    return (
        <div className="person-contact-form ">


            {showAttendeesPeople && 
                <ModalForm
                  mode={step > 3 ? "view" : mode}
                  onHide={() => setShowAttendeesPeople(false)}
                  show={showAttendeesPeople}
                  title="AttendeesPeople"
                >
                  <AttendeesPeople
                    meetingId={response?.Id}
                    onHide={() => setShowAttendeesPeople(false)}
                    mode={step > 3 ? "view" : mode}
                  />
                </ModalForm>
                }
            <div className="card-body">
              {loading ? 
                <div className="container-fluid">
                    {step>3&&
                        <form className="card" onSubmit={updateMeetingMinutesAsync} >
                        <div className="card-body">
                        <div className="row">
                        <div className="col-lg-3">
                           <Datepicker
                              required={true}
                              onOpenPickNewDate={true}
                              mode={mode}
                              lable="StartTime"
                              setIsoTime={(isoTime: string) => { setStartTime(isoTime) }}
                              val={ response?.StartTime ? response?.StartTime  : null}                              withTime ={true} 
                              disableDayPicker={true}
                              format={"HH:mm"}
                        
                            />                          
                        </div>
                        <div className="col-lg-3">
                           <Datepicker
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              mode={ mode}
                              resetForm={resetForm}
                              lable="EndTime"
                              setIsoTime={(isoTime: string) => { setEndTime(isoTime) }}
                              val={ response?.EndTime ? response?.EndTime  : null} //resetForm ? new Date().toISOString() : 
                              withTime ={true}
                              disableDayPicker={true}
                              format={"HH:mm"}
                              onOpenPickNewDate={true} 
                            />                          
                        </div>   
                        </div>   
                        <div className="row">
                        <div className="col-12 mt-4">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             textboxType="textarea"
                             textboxName="MeetingMinutes"
                             labelText="MeetingMinutes"
                             maxLength={900}
                             defaultValue={response?.MeetingMinutes}
                             resetForm={resetForm}
                             mode={mode}
                            />        
                        </div>
                        
                        </div>                 
                        </div>

                        { mode !="view"  ?
                            <div className="col-lg-12 mt-1 btns_form">
                            <Button value="Save" btnType="submit" />
                            <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                            </div>
                            :
                            <div className="col-lg-12 mt-1 btns_form">
                         </div>}

                        </form>
                    } 

                    <div className="row mb-2">
                  
                        {step>2&&
                        <div className="col mt-4">
                           <Button
                            btnType="primary"
                            value="attendeespeople"
                            onClick={()=>{setShowAttendeesPeople(true)}}
                            />                    
                        </div>
                        } 
                    </div>
                    <form onSubmit={handleMeetingFormSubmit} name="MeetingForm" id="MeetingForm" ref={formRef}>
                    <div className="row">    
                        {(response?.MeetingNo!=null&& response?.MeetingNo!=undefined)&&
                        <div className="col-lg-2">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             textboxType="text"
                             textboxName="MeetingNo"
                             labelText="MeetingNo"
                             defaultValue={response?.MeetingNo + ""}
                             resetForm={resetForm}
                             mode={"view"}

                            />
                        </div>      
                        
                        }
                        <div className="col-lg-3">
                            <DictionarySelectBox
                            label="meetingtitle"
                            name="MeetingClassification"
                            onChange={(e, obj)=>{setMeetingClassification(obj)} }
                            baseUrl={MEETINGMANAGEMENT_URL}
                            endPoint={ENDPOINTSMEETINGMANAGEMENT.MeetingApis.BaseDefinitionAndSettingsApi.MeetingClassification.dictionary}
                            resetForm={resetForm}
                            value={response?.MeetingClassificationId}
                            mode={step>1?"view": mode}
                            />
                        </div>
                    <div className="col-lg-3">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             textboxType="text"
                             textboxName="Title"
                             labelText="Description"
                             defaultValue={response?.Title ? response?.Title:meetingClassification?.Name}
                             resetForm={resetForm}
                             mode={step>1?"view": mode}
                             required={true}
                            />
                        </div>
                        <div className="col">
                            <EnumerationTypeSelect
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              label="MeetingHoldingType"
                              name="MeetingHoldingType"
                              value ={response?.MeetingHoldingType}
                              mode={step>1?"view": mode}
                              enumTypeSelect='MeetingHoldingType'
                              />
                        </div>                      
                        <div className="col">
                            <DictionarySelectBox
                            name="MeetingPlace"
                            label="MeetingPlace"
                            baseUrl={MEETINGMANAGEMENT_URL}
                            endPoint={ENDPOINTSMEETINGMANAGEMENT.MeetingApis.BaseDefinitionAndSettingsApi.MeetingPlace.dictionary}
                            resetForm={resetForm}
                            value={response?.MeetingPlaceId}
                            mode={step>1?"view": mode}
                            />
                        </div>


                    </div>
                    <div className="row">
                         <div className="col-lg-3">
                            <Position
                            name="RequesterPositionId"
                            label="MeetingRequesterPosition"
                            resetForm={resetForm}
                            value={response?.RequesterPositionId}
                            mode={step>1?"view": mode}
                            returnObj={true}
                            required={true}
                            onChange={(e)=>{setListPosition({...listPosition ,"RequesterPositionId" : e })}}
                            />
                        </div>

                        <div className="col-lg-3">
                            <Position
                            name="BossPositionId"
                            label="MeetingBossPosition"
                            resetForm={resetForm}
                            value={response?.BossPositionId}
                            returnObj={true}
                            onChange={(e)=>{setListPosition({...listPosition ,"BossPositionId" : e })}}
                            mode={step>1?"view": mode}
                            required={true}
                            />
                        </div> 


                        <div className="col-lg-3">
                            <Position
                            name="SecretaryPositionId"
                            label="MeetingSecretaryPosition"
                            resetForm={resetForm}
                            value={response?.SecretaryPositionId}
                            required={true}
                            mode={step>1?"view": mode}
                            returnObj={true}
                            onChange={(e)=>{setListPosition({...listPosition ,"SecretaryPositionId" : e })}}
                            />
                        </div>
                        <div className="col-lg-3">
                            <Position
                            name="ExecuterPositionId"
                            label="MeetingExecuterPosition"
                            resetForm={resetForm}
                            value={response?.ExecuterPositionId}
                            returnObj={true}
                            onChange={(e)=>{setListPosition({...listPosition ,"ExecuterPositionId" : e })}}
                            mode={step>1?"view": mode}
                            />
                        </div>  

                        <div className="col-lg-3">  
                           <Datepicker
                              onOpenPickNewDate={true}
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              mode={step>1?"view": mode}
                              resetForm={resetForm}
                              lable="PredictedDate"
                              setIsoTime={(isoTime: string) => { setPredictedDate(isoTime) }}
                              val={ response?.PredictedStartTime ? response?.PredictedStartTime  : null} //resetForm ? new Date().toISOString() : 
                              required ={true}
                            />                          
                        </div>
                        <div className="col-lg-3">  
                           <Datepicker
                              required={true}
                              onOpenPickNewDate={true}
                              mode={step >1 ? "view" : mode}
                              lable="StartTime"
                              setIsoTime={(isoTime: string) => { setPredictedStartTime(isoTime) }}
                              val={ response?.PredictedStartTime ? response?.PredictedStartTime : null } //resetForm ? new Date().toISOString() :
                              withTime ={true} 
                              disableDayPicker={true}
                              format={"HH:mm"}
                              errorFlag={false}
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                            />                          
                        </div>
                        <div className="col-lg-3">
                            <Time
                                required={true}
                                mode={step>1?"view": mode}
                                name="PredictedTimeLength"
                                IdString="PredictedTimeLength"
                                resetForm={resetForm}
                                onChange={setPredictedTimeLength}
                                defaultValue={ predictedTimeLength ? predictedTimeLength  : 60}
                                label="PredictedTimeLength"
                            />                     
                        </div>  
                        <div className="col-lg-3">
                            {predictedload&&
                           <Datepicker
                           Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                           mode={"view"}
                           lable="PredictedEndTime"
                           onOpenPickNewDate={true}
                           setIsoTime={(isoTime: string) => {setPredictedEndTime(predictedEndTime)  }}
                           val={predictedEndTime } //resetForm ? new Date().toISOString() : 
                           withTime ={true} 
                           />                          
                        }
                        </div> 
                        <div className="col-lg-12">
                                <Textbox
                                 Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                 textboxType="textarea"
                                 minHeight={2}
                                 textboxName="NecessaryHoldingTools"
                                 labelText="NecessaryHoldingTools"
                                 defaultValue={response?.NecessaryHoldingTools}
                                 resetForm={resetForm}
                                 mode={step>1?"view": mode}
                                />
                        </div>


                    </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <Textbox
                                 Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                 textboxType="textarea"
                                 textboxName="MeetingAgenda"
                                 labelText="MeetingAgenda"
                                 maxLength={900}
                                 minHeight={10}
                                 defaultValue={response?.MeetingAgenda}
                                 resetForm={resetForm}
                                 mode={step>1?"view": mode}
                                />
                            </div>
                        </div>  
                        {/* {  step<2&&
                        <>
                       { mode !="view"  ?
                            <div className="col-lg-12 mt-1 btns_form">
                            <Button value="sendInvitat&refrence" btnType="submit" />
                            <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                            </div>
                            :
                            <div className="col-lg-12 mt-1 btns_form">
                            <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                         </div>
                         }
                        </>
                        }  */}
                                  <button  type="submit"  className="d-none" id="hidebutton" name="hidebutton" >
                                  </button>
                   </form>

                </div>  
            : 
              <Loading/>
            }
            </div>
        </div>
    )
}
