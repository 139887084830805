import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import DatePicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { ENDPOINTS } from "utils/endpoints";
import { trans } from "utils/helpers";

interface ViewSendFormPropsType {
    selectedRow?: any,
    onHide: () => void,
}

export const ViewSendForm = ({
    selectedRow,
    onHide,
}: ViewSendFormPropsType) => {
    const [SentTime, setSentTime] = useState<any>();
    const [ReceiveTime, setReceiveTime] = useState<any>();
    const [resetForm, setResetForm] = useState(false);
    const [showstep, setShowstep] = useState<any>({});

    return (
        <div>
          
        <div>
         <form className="action-form py-2">
            <div className={`card  `}>
                <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px" , justifyContent:"space-between" }} className="d-flex  py-1 col-12 pr-4"> 
                    <div>{trans("send")} </div>      
                    <div  style={{marginLeft:"18px"}} onClick={()=>{showstep[1]?setShowstep({...showstep , 1:false}):setShowstep({...showstep , 1:true}) }} >{showstep[1] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" />:<FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                </div>
                <div style={{display: !showstep[1] ?"block" :"none"}} className="card-body">                      
                        <div className="row mb-2">
                         <div className="col-lg-4 col-sm-4 mt-1">
                             <Position
                                name="SendOperatorPositionId"
                                label="SendOperatorPosition"
                                mode={selectedRow.Id!= undefined && selectedRow.Id.length > 0  ?"view"  :"edit"}
                                value={ selectedRow.SendOperatorPositionId }
                                resetForm={resetForm}
                            />
                         </div>
                         <div className="col-lg-4 col-sm-4 mt-1">
                            <DictionarySelectBox
                             endPoint={ENDPOINTS.cartable.sendMethod.dictionary}
                             label="SendMethod"
                             name="LetterSendMethodId"
                             value={selectedRow.LetterSendMethodId}
                             mode={selectedRow.Id!= undefined &&selectedRow.Id.length > 0  ?"view"  :"edit"}
                             />
                         </div>
                            <div className="col-12 col-lg-4">
                            {(selectedRow.Id!= undefined &&selectedRow.Id.length > 0)  ? 
                                <Textbox
                                mode={"view" }
                                resetForm={resetForm}
                                textboxType="text"
                                textboxName="SentTime"
                                labelText="SentTime"
                                maxLength={50}                        
                                defaultValue={selectedRow?.SentTime ? selectedRow?.SentTime : SentTime} //resetForm ? new Date().toISOString() : 
                             />
                            :
                            <DatePicker
                                required={true}
                                mode={selectedRow.Id!= undefined &&selectedRow.Id.length > 0  ?"view"  :"edit"}
                                resetForm={resetForm}
                                lable="SentTime"
                                setIsoTime={(isoTime: string) => { setSentTime(isoTime) }}
                                val={selectedRow?.SentTime ? selectedRow?.SentTime : SentTime} //resetForm ? new Date().toISOString() : 
                                />                   
                            }
                            </div>

                         
                        </div>   
                        <div className="row mb-2">
                            <div className="col-12 col-lg-4">
                             <Textbox
                                mode={selectedRow.Id!= undefined &&selectedRow.Id.length > 0  ?"view"  :"edit"}
                                resetForm={resetForm}
                                textboxType="text"
                                textboxName="ReceiverPerson"
                                labelText="ReceiverPerson"
                                maxLength={50}                        
                                defaultValue={selectedRow.ReceiverPerson ?selectedRow.ReceiverPerson :""}
                             />
                            </div>
                            <div className="col-12 col-lg-4 mt-4 mb-4" >
                                <CheckableButton
                                   defaultChecked={true}
                                   defaultValue={true}
                                   labelText={"IsSent"}
                                   inputName={"IsSent"}
                                   type="checkbox"
                                   mode={"view" }
                                   />                                
                            </div>
                         </div>              
                        <div className="row">
                            <div className="col-lg-8 col-sm-6 ">
                               <Textbox
                                  readonly={ true}
                                  mode={selectedRow.Id!= undefined &&selectedRow.Id.length > 0  ?"view"  :"edit"}
                                  resetForm={resetForm}
                                  textboxType="textarea"
                                  textboxName="SentDescription"
                                  labelText="SentDescription"
                                  maxLength={900}
                                  defaultValue={selectedRow.SentDescription}
                               />
                            </div>
                        </div>
              
                </div>
            </div>
            <div className="card">
                <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px" , justifyContent:"space-between" }} className="d-flex  py-1 col-12 pr-4"> 
                    <div>{trans("receive")} </div> 
                    <div style={{marginLeft:"18px"}}  onClick={()=>{showstep[2]?setShowstep({...showstep , 2:false}):setShowstep({...showstep , 2:true}) }}>{showstep[2] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" />:<FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                </div>  
                <div style={{display: !showstep[2] ?"block" :"none"}} className="card-body">
                <div className="row mb-2">
                    <div className="col-12 col-lg-4">
                        <DatePicker
                            mode={"view"}
                            resetForm={resetForm}
                            lable="ReceiveTime"
                            setIsoTime={(isoTime: string) => { setReceiveTime(isoTime) }}
                            val={selectedRow?.ReceiveTime ? selectedRow?.ReceiveTime : ReceiveTime} //resetForm ? new Date().toISOString() : 
                        />
                    </div>
                    <div className="col-12 col-lg-4 mt-4 mb-4" >
                        <CheckableButton
                           defaultChecked={selectedRow?.IsReceived!=null? selectedRow?.IsReceived :  false}
                           defaultValue={selectedRow?.IsReceived!=null ? selectedRow?.IsReceived :  false}
                           labelText={"IsReceived"}
                           inputName={"IsReceived"}
                           type="checkbox"
                           mode={"view"}
                           />                                
                    </div>
                    <div className="col-lg-8 col-sm-6 ">
                        <Textbox
                           readonly={true}
                           mode={"view"}
                           resetForm={resetForm}
                           textboxType="textarea"
                           textboxName="ReceiveDescription"
                           labelText="ReceiveDescription"
                           maxLength={900}
                           defaultValue={selectedRow.ReceiveDescription}
                        />
                    </div>
                </div>
                </div>
                
            </div>
            <div className="d-flex justify-content-end">
                <Button
                   btnType="primary"
                   value="Close"
                   onClick={onHide}
                 />
            </div>               
        </form>
            </div>
        </div>
    );
}