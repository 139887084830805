import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { TableTree } from "components/TableTree/TableTree";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { trans } from "utils/helpers";
import { getWarehouse } from "./api/api";
import { DeleteWarehouse, DeleteWarehouseDetail, getWarehouseAccess } from "./components/WarehouseAccesses/api";
import { WarehouseDetailForm } from "./components/WarehouseDetailForm/WarehouseDetailForm";
import { WarehouseForm } from "./components/WarehouseForm/WarehouseForm";
import { WarehouseItemGroup } from "./components/WarehouseItemGroup/WarehouseItemGroup";
import { WarehouseAccesses } from "./components/WarehouseAccesses/WarehouseAccesses";

// reload Dont work
export const WarehouseStructure = () => {
  const [data, setData] = useState<any>()
  const [forceUpdate, setForceUpdate] = useState<boolean>(false)
  const [selectedObj, setSelectedObj] = useState<any>()
  const [idCallback, setIdCallback] = useState<any>(null)
  const [mode, setmode] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false);
  const [ShowWarehouseDetail, setShowWarehouseDetail] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<any>(0);
  const [showCreate, setShowCreate] = useState<any>(true);
  const [showAccess, setShowAccess] = useState<any>(false);
  const [accessDataList, setAccessDataList] = useState<any>();
  const [message, setMessage] = useState<any>("NoDataForPreview");
  const [itemList,setItemList] = useState<any>(false)


  
  const clickToolbarCallback = (clickedButtonName: string) => {

    switch (clickedButtonName.toLowerCase()) {
      case "reference":
        alert("call api reference with Id :");
        break;
      case "print":
        alert("print ");
        break;

      case "refresh":setForceUpdate(!forceUpdate);break;
      case "boxes": if(selectedObj){if(selectedObj.ParentId==null && selectedObj.RootId == null ){setmode("edit");setItemList(true);}}else{Toast(trans("Msg_SelectRow"), "warning");};
        break;
      case "view":  if(selectedObj){if(selectedObj.ParentId==null && selectedObj.RootId == null ){setmode("view");setShowForm(2);}else{setmode("view");setShowForm(1);}}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "remove":  if(selectedObj){if(selectedObj.ParentId==null && selectedObj.RootId == null ){Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{DeleteHandler(2 )})}else{Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{DeleteHandler(1)})}}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "create": if(selectedObj){setmode("create");setShowWarehouseDetail(!ShowWarehouseDetail);}else Toast(trans("Msg_SelectRow"), "warning"); 
        break;
      case "edit": if(selectedObj){if(selectedObj.ParentId==null && selectedObj.RootId == null ){setmode("edit");setShowForm(2);}else{setmode("edit");setShowForm(1);}}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "assignrule": if (selectedObj && selectedObj?.ParentId==null && selectedObj?.RootId == null){ getaccessListAsync()}; break;

    }
  };  

  async function getaccessListAsync() {
    try {
      
          const accessList = await getWarehouseAccess(selectedObj.Id ); 
          setAccessDataList(accessList.Data)
          setShowAccess(true)
      
    } catch (error) {
      
    }

  }
  async function DeleteHandler(type) {
    try {

          if(type==1){
            const accessList = await DeleteWarehouseDetail(selectedObj.Id ); 
          }else{
            const accessList = await DeleteWarehouse(selectedObj.Id ); 
          }
          if(selectedObj.ParentId != selectedObj.Id){
            setIdCallback(selectedObj.ParentId)
          }
          setForceUpdate(!forceUpdate);

    } catch (error) {
      

    }

  }






  const selectedCreateWarehouseCallback = (e) => {
    setmode("create")
    if(e==1&& !selectedObj){
      Toast(trans("Msg_SelectRow"), "warning")
    }else{
      setShowForm(e)
    }
  }
  const setselecetrow = (e) => {
    setSelectedObj(e)
    if(e&&e.RootId!=null){
      setShowCreate(false)
    }else setShowCreate(true)

  }

  const ShowWarehouseDetailShow = () => {
    setIdCallback(selectedObj?.Id)
    setShowWarehouseDetail(false)
   
  }


  const handleShowForm = (e) => {
    if(e == true){
        setShowForm(0)
        setForceUpdate(!forceUpdate)
    }else{
        setShowForm(0)
    }
}

  async function getWarehouseStructureAsync() {
    setLoading(false)
    try{

      const respons = await getWarehouse(null)
      setData(respons.Data)
    }catch(err:any){
      setMessage(err?.data?.Message)
    }
    setLoading(true)
  }

  

  useEffect(() => {
    setSelectedObj(null)
    setData(null)
    getWarehouseStructureAsync()
  }, [forceUpdate]);


  useEffect(() => {
    if(idCallback){
      setIdCallback(null)
    }
  }, [idCallback]);


    return (
     <>
        {
            showForm==1 &&
              <ModalForm mode={mode} title="WarehouseDetailForm" onHide={() => setShowForm(0)} show={showForm==1}>
                  <WarehouseDetailForm 
                    showForm={() =>{ShowWarehouseDetailShow(); setShowForm(0);}} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                  />
              </ModalForm>
        }
        {
            showForm==2 &&
              <ModalForm mode={mode} title="Warehouse"  onHide={() => setShowForm(0)} show={showForm==2}>
                  <WarehouseForm 
                    showForm={handleShowForm} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                  />
              </ModalForm>
        }
        { itemList &&
              <ModalForm mode={mode} modalHeight={60}modalProfileKey="WarehouseItemGroup" title={`${trans("WarehouseItemGroup") } : ${selectedObj?.Title}`} onHide={() => setItemList(false)} show={itemList}>
                  <WarehouseItemGroup
                    mode={mode}
                    warehouseId={selectedObj.Id}
                    onHide={()=>{setItemList(false)}}
                  />
              </ModalForm>
        }
            {
              showAccess &&
              <ModalForm modalProfileKey={"Users"} title={trans("Users") } onHide={() => setShowAccess(false)} show={showAccess}>
                <WarehouseAccesses Warehouse={selectedObj?.Id} showForm={()=>{setShowAccess(false) }}  />
              </ModalForm>
            }
        <Toolbar 
          emptyList={data?.length == 0} 
          handleClickToolbar={clickToolbarCallback} 
          id={selectedObj} 
          handleCreateWarehouse={showCreate ? selectedCreateWarehouseCallback:undefined}
          create={false} 
          search={false} 
          remove={selectedObj?.RootId }
          edit={true }
          boxes={true}
          assignRule={true}
          excel={false}
          warehouse={false}
        />
        <div style={{ overflowY: "auto", 
                height: "78vh",
                 scrollbarWidth: "thin"}}>
            {
              loading ? (
                data ?
                <div>
                  <TableTree
                  maxLevel={1}
                    baseUrl={INVENTORYANDSALES_URL}
                    selectedRowObjectCallback={setselecetrow}
                    selectedRowObject={idCallback}
                    getChildrenUrl={ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.getNode}
                    theadData={["Id" , "ParentId", "DetailList" , "Title" ]}
                    startIndex={2}
                    defultparams={{rootId:selectedObj?.RootId ?selectedObj?.RootId :selectedObj?.Id}}
                    Titleparams={"Title"}
                    Childrenparams={'DetailList'}
                    defultShow={false}
                    Data={data}
                    parentName={" Warehouse"}
                  /> 
                </div>: <Message message={message} type={"error"} />)
                : <Loading />
            }
        </div>
      </>
    )
}




