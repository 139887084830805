import { CheckableButtonList } from "components/CheckableButtonList/CheckableButtonList";
import { InputLoad } from "components/InputLoading/inputLoading";
import { SelectBox } from "components/Select/Select";
import { Textbox } from "components/Textbox/Textbox";
import { InputHTMLAttributes, useEffect, useState } from "react";
import { Enumeration } from "types/types";
import { trans } from "utils/helpers";
import { getItemsApi } from "./api";

interface ItemsProp extends InputHTMLAttributes<HTMLInputElement> {
  type: "SelectBox" | "Radio" | "CheckBox";
  label?: string;
  name: string;
  value?: any;
  customerPositionId?: any;
  serialId?: any;
  mode?: string;
  required?:boolean ;
  itemsValid?:boolean ;
  setItemsId?: (Id:any) => void,
  Change?: (e) => void,

}
export const Items = ({ type, label="Items", name, value,customerPositionId=null ,mode ,serialId, required,Change ,setItemsId ,itemsValid}: ItemsProp) => {
  const [optionsItems, setOptionsItems] = useState<Enumeration>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errcode, setErrcode] = useState<any>();
  let [MsgShow, setMsgShow] = useState<boolean>(false);
  let [MsgText , setMsgText] = useState<any>(false);
  let options_Items_values:any =[];
  let options_Items_keys:any =[];
  const getItemsAsync = async ()=>{
    setLoading(false)
    try {
      setErrcode("")
      const res = await getItemsApi(serialId ,customerPositionId)
      setOptionsItems(res?.Data ) 
      if(res?.Data){
        setItemsId &&  setItemsId(" ")
      }else{
        setItemsId &&  setItemsId(null)
      }
    } catch (error:any) {
      setItemsId &&  setItemsId(null)
    setErrcode(error?.response?.status)
      

    }
    setLoading(true)
  }
  useEffect(() => {
    if(serialId && mode != "view"){
      getItemsAsync()
    }else{
      setOptionsItems({})

      setLoading(true)
    }
    return () => {
     
      setErrcode("")
    };
   
   
  }, [serialId ,value]);


  useEffect(() => {
    if(itemsValid){
      setMsgText("item cannot be empty ")
      setMsgShow(true)
    }else{
      setMsgText(false)
      setMsgShow(false)

    }
   
  }, [itemsValid]);


  if (optionsItems) options_Items_values =[ " ",...Object.values(optionsItems)];
  if (optionsItems) options_Items_keys = [" ",...Object.keys(optionsItems )];
  return (
    <>
        {(optionsItems ||value )&& loading  ?
        {
          Radio: (
            <>
              {options_Items_values && (
                <CheckableButtonList
                  checkableBtnLabelList={options_Items_values}
                  checkbaleType="radio"
                  verticalDirection={false}
                  checkableButtonName="Items"
                  checkableListLabel="Items"
                />
              )}
            </>
          ),
          SelectBox: (
            <>
          {mode == "view" ?
          
             <Textbox
             textboxType="text"
             textboxName="Items"
             labelText="Items"
             readonly={true}
             defaultValue={value}
             mode={"view"}
              />


              :
              <SelectBox
              onChange={(e)=>{ setItemsId && setItemsId(optionsItems?.[e.target.value])  ;e.target.value !=(value?value:options_Items_keys[0]) ?Change&&Change({[name] :e.target.value}):Change&&Change({[name] :null}) }}
                required={required}
                mode={mode}
                lable={label}
                selectType="select"
                value={options_Items_keys[options_Items_values.indexOf(value)]}
                options={options_Items_values}
                val={options_Items_keys}
                name={name}
              ></SelectBox>
            }
            </>
          ),
          CheckBox: (
            <>
              {options_Items_values && (
                <CheckableButtonList
                  checkableBtnLabelList={options_Items_values}
                  checkbaleType="checkbox"
                  verticalDirection={false}
                  checkableListLabel={trans("Items")}
                />
              )}
            </>
          ),
        }[type as string]
        :<InputLoad  msg={errcode} required={required}  labelText={"Items"}  onClick={getItemsAsync}/> 

      }
       <div className="text-box__msg-wrapper">
       <span className={`${MsgShow ? "show-msg" : "hide-msg"} text-box__regex-msg text-danger`}>
       {MsgText}
       </span>
   </div>
    </>
  );
};
