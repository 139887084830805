import { GroupingPriceFunction, trans } from "utils/helpers";
import './style.scss'
import { useEffect, useState } from "react";

interface HeaderbarPropsType {
    headerContents: any
    hasBorder?: boolean
    withPading?: boolean
    startIndex?: number
    shakingKey?: any
}
export const Headerbar = ({
    headerContents,
    hasBorder,
    shakingKey=[],
    withPading=true,
    startIndex = 0
}: HeaderbarPropsType) => {    
    const [headerContentsState , setHeaderContentsState]= useState<any>(headerContents)

    useEffect(() => {
        if(headerContentsState){
            setHeaderContentsState(null)
        }
    }, [headerContents])
    useEffect(() => {
        if(headerContentsState==null){
            setHeaderContentsState(headerContents)
        }

    }, [headerContentsState])
    return (
        <div className="header-bar">
            <div style={{backgroundColor: "#e9ecef"}} className={`card ${withPading&& "py-2"}  px-4 ${!hasBorder && "remove-border"}`}>
                <div className="d-flex justify-content-between row">
                    {
                       headerContentsState&& Object.keys(headerContentsState).map((key, i) => (
                           
                           !key.includes("Id") && startIndex <= i && (
                               (key + headerContentsState[key]).length <= 55 ?
                               <div  key={key} className={` ${shakingKey.includes(key) &&" shaking_animation"}`}  style={{width:'fit-content'}}>
                              
                                        <span className={``} onMouseDown={(e)=>{e.stopPropagation()}} >{trans(key)}: </span>
                                        <span className={ `d-inline-block mb-1 `} onMouseDown={(e)=>{e.stopPropagation()}} style={{direction:"ltr"}}  >{typeof headerContentsState[key]=="boolean" ? <input checked={headerContentsState[key]==true} readOnly type={"checkbox"} className={"checkBox"}     /> :(key.includes("Price") ||key.includes("Discount") || key.includes("Tax") ?GroupingPriceFunction(headerContentsState[key]) :  headerContentsState[key]) }</span>
                                    </div>
                                    :                                    
                                    <div  key={key}className={`col-12 w-100   ${shakingKey.includes(key) &&" shaking_animation"}`} >
                                        <span onMouseDown={(e)=>{e.stopPropagation()}} >{trans(key)}: </span>
                                        <span onMouseDown={(e)=>{e.stopPropagation()}} style={{direction:"ltr"}} className={`d-inline-block mb-1  `} >{typeof headerContentsState[key]=="boolean" ? <input checked={headerContentsState[key]==true} readOnly type={"checkbox"} className={"checkBox"}     /> : (key.includes("Price") || key.includes("Discount")|| key.includes("Tax")?GroupingPriceFunction(headerContentsState[key]) : headerContentsState[key]) }</span>
                                    </div>
                            )
                        ))
                    }
                </div>
            </div>
        </div>
    );
}