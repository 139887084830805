import { Loading } from 'components/Loading/loading';
import { useEffect, useState } from 'react';
import { getSharingCenterArticleUser } from './api';
import { AttachmentViewer } from 'pages/DashboardPage/components/Board/AttachmentViewer';
import { ENDPOINTS } from 'utils/endpoints';


interface props {
    SharingCenterArticleData: any
}


export const SharingCenterArticleUserForm = ({SharingCenterArticleData}:props) => {

    const [loading, setLoading] = useState<any>(false);
    const [response, setResponse] = useState<any>();

    async function getAttachmentViewersAsync() {
        setLoading(false)
        try {
            const res = await getSharingCenterArticleUser(SharingCenterArticleData.Id )
            setResponse(res.Data ) 
        } catch (error:any) {
            
        }
        setLoading(true)
    }

    useEffect(() => {
        if(SharingCenterArticleData?.Id){
            getAttachmentViewersAsync()
        }
    }, [ SharingCenterArticleData]);


    return (
        <>
        {loading ?
            <>
            <div key={SharingCenterArticleData.Id} className='card p-2  mb-2'>
                <h6 className='mb-3'>{SharingCenterArticleData.Title}</h6>
                <p style={{display:"flex" , flexDirection:"row",height: "75%" }} data-toggle="tooltip" data-placement="top" title={SharingCenterArticleData.Title}  >
                    <div style={{width: "100%" ,display: "flex",flexDirection: "column",justifyContent: "space-between",height: "100%"}}>
                       <div style={{height: "98%",maxHeight:'98%' , overflow: "hidden"}} dangerouslySetInnerHTML={{__html:response.Description}}> 
                       </div>
                    </div>
                </p>
               {response?.Link && 
                <a target='_blank' href={response?.Link} className='SharingCenterArticleLink' style={{cursor:"pointer" , direction : "ltr"}} >{response?.Link}</a>
               }
            </div>
                {response &&response?.Attachments?.length >0  ?
                    <>
                        <AttachmentViewer endpoint={ ENDPOINTS.SharingCenterArticleUserApi.attachment} listAttachment={response?.Attachments} ownerId={SharingCenterArticleData.Id}  />
                    </>
                    :<></> 
                 }
            </>
            : <Loading />
            }
        </>
    )
}