import { DynamicFormDataEntry } from "pages/OfficeAutomation/FormBuilder/components/DynamicFormDataEntry/DynamicFormDataEntry";
import { ComponentParams } from "types/types";
import { findValueByKey } from "utils/helpers";

export interface DynamicComponentHOCProps {
  component: any,
  componentName?: any,
  componentParams: string,
  referenceId?: any,
  processId?: any,
  RequesterPositionId?: any,
  taskTitle?: any,
  Id?: any,
  jobId?: any,
  sourceId?: any,
  SourceData?: any,
  showInDetail?: any,
  JobNo?: any,
  TaskId?: any,
  modeParams?: any,
  useGenerate?: any,
  stepParams?: any,
  Name?: any,
  handleCancelForm: () => void,
  onHide: () => void,
  reload?: (ans: boolean) => void,
  closeForm?: () => void,
  handleShowReferenceStepper?: (referenceId: string) => void
  inDynamicForms?: any;

}

export const DynamicComponentHOC = ({inDynamicForms,componentName ,useGenerate,sourceId,SourceData, component: Component,showInDetail,JobNo, componentParams,TaskId,jobId=null, referenceId, onHide, processId,RequesterPositionId,taskTitle, Id,Name , modeParams,stepParams,closeForm, handleCancelForm, reload, handleShowReferenceStepper }: DynamicComponentHOCProps) => {
  let mode: string = "create";
  let type;
  let step;
  let sourceDataParam;
  let ParentForm;
  if (componentParams && componentParams != "") {
    let paramsObject: ComponentParams = JSON.parse(componentParams.toLowerCase());
    sourceDataParam =JSON.parse(componentParams).SourceData ;
    mode = findValueByKey(paramsObject, "mode") ;
    type = findValueByKey(paramsObject, "type") ;
    step = findValueByKey(paramsObject, "step") ;
    ParentForm = findValueByKey(paramsObject, "parentform") ;
  
    }
  return (
    <>
      {componentName ? 
        <DynamicFormDataEntry   PRJ_UID={processId}   formKey={componentName} id={Id}jobId={jobId} mode={modeParams ?modeParams :mode }step={ stepParams ?stepParams   :step} onHide={onHide} handleShowReferenceStepper={handleShowReferenceStepper}/>
        :
      <Component sourceDataParam={sourceDataParam}useGenerate={useGenerate} RequesterPositionId={RequesterPositionId} closeForm={closeForm} SourceData={SourceData}  inDynamicForms={inDynamicForms} sourceId={sourceId}  taskTitle={taskTitle} ParentForm={ParentForm}showInDetail={showInDetail}JobNo={JobNo} step={ stepParams ?stepParams   :step} Name={Name} taskId={TaskId} type={type} mode={modeParams ?modeParams :mode }jobId={jobId} onHide={onHide} Id={Id} processId={processId} referenceId={referenceId} handleCancelForm={handleCancelForm} reload={reload} handleShowReferenceStepper={handleShowReferenceStepper} />

      }
    </>
  )
};
