import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getReferencesActions = (jobId ) => http({
    url: ENDPOINTS.afterSalesApi.history,
    method: 'get',
    params: {
        JobId:jobId ,
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});



    export const getActionsOfReferences = (PageNumber: number, PageSize: number, ReferenceId: any,orderBy,filterlist ,SearchString) => http({
        url: ENDPOINTS.cartable.action.browse,
        method: 'get',
        params: {
            pageNumber: PageNumber,
            pageSize: PageSize,
            referenceId: ReferenceId,
            OrderByString:orderBy,
            FilterString:filterlist,
            SearchString:SearchString,
        }
    }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });


