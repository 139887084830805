import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { DynamicComponentHOC } from "components/DynamicComponentHOC/DynamicComponentHOC";
import { Message } from "components/Message/Message";
import React, { createContext, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { ComponentParams } from "types/types";
import { ICON_TYPE } from "utils/constants";
import { DYNAMICFORMS } from "utils/dynamicForms";
import {findValueByKey, trans } from "utils/helpers";
import './style.scss';


interface props {
    formList: any,
    Id: string,
    sourceId?: string,
    onHide: () => void,
    reload?: (e) => void,
    closeForm?: () => void,
    JobNo?: any
    styleCondition?: any
    RequesterPositionId?: any
    jobId?: any
    taskTitle?: any
    inDynamicForms?: any;
    disable?:boolean;
    parentMode?: any;
    useGenerate?: any;
    SourceData?: any;

}
export const passListContext = createContext<any>([]);

export const ModalStepper: React.FC<props> = ({SourceData , RequesterPositionId, parentMode,inDynamicForms=false, JobNo,useGenerate=true,sourceId,formList, taskTitle, disable=false,styleCondition = false, Id, closeForm, onHide, reload ,jobId }) => {

    const [progress, setProgress] = useState<number>(0);
    const [counter, setCounter] = useState<any>(0);
    const [pass, setPass] = useState<any>();
    const [passList, setPassList] = useState<any>([]);

    function addNewPass(e){
        if(!passList.includes(e)){
            setPassList([...passList , e])
        }
    }
    //const [references, setReferences] = useState<any>([]);
    function circleClick(e) {
        let complete = formList.length - 1
        setProgress((100 / complete) * e)
        setCounter(e)
    }



    function next() {
        let complete = formList.length - 1;
        setProgress(progress + (100 / complete))
        setCounter(counter + 1)




    }

    function prev() {
        let complete = formList.length - 1;
        setProgress(progress - (100 / complete))
        setCounter(counter - 1)

    }



    useEffect(() => {

        const formListReverc = JSON.parse(JSON.stringify(formList)) 
        let Tindex:any
        formListReverc.reverse().forEach((item, index) => {
            if (item.Parameters && item.Parameters != "") {
                let paramsObject: ComponentParams = JSON.parse(item.Parameters.toLowerCase());
                let mode = findValueByKey(paramsObject, "mode");
                if (mode != "view") {
                    Tindex = index
                }
            }
        });
        if(Tindex != undefined){
            Tindex = Math.abs((formList.length-1)-Tindex ) 
            let complete = formList.length - 1
            setProgress((100 / complete) * Tindex)
            setCounter(Tindex)
            setPass(Tindex)

        }else{
            Tindex = Math.abs(formList.length ) 
            setProgress(100 )
            setCounter(Tindex-1)
            setPass(Tindex)


        }
    }, [formList])




    return (
        <passListContext.Provider value={[passList, addNewPass]}>
        <div className="card mb-0">
            {formList.length > 1 ?
                <div className={`${styleCondition ? 'referencewizard-b' : 'referencewizard'}`}>
                 
                        <div className={`${styleCondition ? 'referencewizard-b__progresscontainer' : 'referencewizard__progresscontainer border-start'}`}>
                            <div className={`${styleCondition ? 'referencewizard-b__scroll' : 'referencewizard__scroll'}`}>
                                <div className={`${styleCondition ? 'referencewizard-b__progress' : 'referencewizard__progress'}`}>
                                <ProgressBar style={{ "width": formList.length * 110 , marginTop :formList.length * ((formList.length * 11)+40)}} className={`${styleCondition ? 'referencewizard-b__progressbar' : 'referencewizard__progressbar'}`} now={progress} />
                                <div style={{ "width": formList.length * 114 }} className={`${styleCondition ? 'referencewizard-b__circlecontainer' : 'referencewizard__circlecontainer'}`}>
                                        {formList.map((item, index) => (
                                            <>
                                            {item.Name!="SignatureForm" &&<>
                                            {
                                                
                                            index == counter ?
                                                <div style={{width: "30px"}} key={index+"stepTitle"}>
                                                    <div style={{transform:"rotate(-90deg)"}}  className={`${styleCondition ? 'referencewizard-b__stepTitle' : 'referencewizard__stepTitle'}`}onClick={() => circleClick(index)}>{trans(item.Name.replace("Form",""))}</div>
                                                    <div  className={  `referencewizard__circle activeStep  ${(index < pass)? "enactiveStep" :"" }`} onClick={() => circleClick(index)} >
                                                        {
                                                            (index < pass ||passList.includes(item.Name)) ?
                                                            <>
                                                                <FontAwesomeIcon icon={[ICON_TYPE, "check"]} className={`${styleCondition ? 'referencewizard-b__circle-icon' : 'referencewizard__circle-icon'}`} color={'white'} />
                                                            </>
                                                            :<div onClick={() => circleClick(index)}></div>
                                                        }
                                                   </div>


                                                </div>
                                                :
                                                    <div style={{width: "30px"}} key={index+"stepTitle"} >
                                                    <div style={{transform:"rotate(-90deg)" }} className={`${styleCondition ? 'referencewizard-b__stepTitle' : 'referencewizard__stepTitle'}`}onClick={() => circleClick(index)}>{trans(item.Name.replace("Form",""))}</div>
                                                       <div className={  `referencewizard__circle  enactiveStep  ${(index < pass)? "enactiveStep" :"" }`}  onClick={() => circleClick(index)}>
                                                        {
                                                            (index < pass ||passList.includes(item.Name)) ?
                                                            <>
                                                            <FontAwesomeIcon icon={[ICON_TYPE, "check"]} className={`${styleCondition ? 'referencewizard-b__circle-icon' : 'referencewizard__circle-icon'}`} color={'white'} />
                                                            </>
                                                            :<div onClick={() => circleClick(index)}></div>
                                                        }
                                                        </div>
                                                    </div>
                                            }</> }
                                          </>
                                        ))}
                                    </div>
                                </div>


                            </div>
                            <div className={`${styleCondition ? 'referencewizard-b__btns' : 'referencewizard__btns'}`}>
                                <Button value={`${styleCondition ? '<' : 'next'}`} btnType="primary" onClick={next} disabled={(counter == formList.length - 1)} />
                                <Button value={`${styleCondition ? '>' : 'prev'}`} btnType="primary" onClick={prev} disabled={(counter == 0)} />
                            </div>
                        </div>
                    <div className="content-card d-flex flex-column w-100">
                        <div className="content-card d-flex flex-column w-100">
                               
                            <div className={disable ? "disableWindowMode" : ''}>
                                {counter >= 0 &&
                                    <>
                                    {(formList[counter]?.forms&&formList[counter]?.forms.length>0) ?
                                            <>
                                    <DynamicComponentHOC JobNo={JobNo} RequesterPositionId={RequesterPositionId} closeForm={closeForm} SourceData={SourceData}  sourceId={sourceId} modeParams={parentMode} inDynamicForms={inDynamicForms} taskTitle={taskTitle} onHide={onHide} 
                                    componentName={DYNAMICFORMS[formList[counter].Name] ?null:formList[counter].Name} component={DYNAMICFORMS[formList[counter].Name] ?DYNAMICFORMS[formList[counter].Name].component:null}
                                        reload={reload} componentParams={formList[counter].Parameters} processId={formList[counter].ProjectId}
                                        TaskId={formList[counter].TaskId} useGenerate={useGenerate}
                                        referenceId={Id} jobId={jobId} handleCancelForm={() => onHide()}  />

                                    <DynamicComponentHOC JobNo={JobNo} RequesterPositionId={RequesterPositionId} closeForm={closeForm} SourceData={SourceData}  sourceId={sourceId} modeParams={parentMode} inDynamicForms={inDynamicForms} taskTitle={taskTitle} onHide={onHide} 
                                    componentName={DYNAMICFORMS[formList[counter]?.forms[0].Name] ?null:formList[counter]?.forms[0].Name} component={DYNAMICFORMS[formList[counter]?.forms[0].Name] ?DYNAMICFORMS[formList[counter]?.forms[0].Name].component:null}
                                        reload={reload} componentParams={formList[counter]?.forms[0].Parameters} processId={formList[counter]?.forms[0].ProjectId}
                                        TaskId={formList[counter]?.forms[0].TaskId} useGenerate={useGenerate}
                                        referenceId={Id} jobId={jobId} handleCancelForm={() => onHide()}  />
                                            </>
                                    :
                                    <DynamicComponentHOC JobNo={JobNo} RequesterPositionId={RequesterPositionId} closeForm={closeForm} SourceData={SourceData}  sourceId={sourceId} modeParams={parentMode} inDynamicForms={inDynamicForms} taskTitle={taskTitle} onHide={onHide} 
                                    componentName={DYNAMICFORMS[formList[counter].Name] ?null:formList[counter].Name} component={DYNAMICFORMS[formList[counter].Name] ?DYNAMICFORMS[formList[counter].Name].component:null}
                                        reload={reload} componentParams={formList[counter].Parameters} processId={formList[counter].ProjectId}
                                        TaskId={formList[counter].TaskId} useGenerate={useGenerate}
                                        referenceId={Id} jobId={jobId} handleCancelForm={() => onHide()}  />

                                    }

                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

:
<>
                    {formList && formList[0] ?
                        <div className={disable ? "disableWindowMode" : ''}>

{(formList[0]?.forms&&formList[0]?.forms.length>0) ?<>
    
    <DynamicComponentHOC JobNo={JobNo} RequesterPositionId={RequesterPositionId} closeForm={closeForm} SourceData={SourceData} sourceId={sourceId} modeParams={parentMode} inDynamicForms={inDynamicForms} taskTitle={taskTitle} onHide={onHide} 
                                    componentName={DYNAMICFORMS[formList[0].Name] ?null:formList[0].Name} component={DYNAMICFORMS[formList[0].Name] ?DYNAMICFORMS[formList[0].Name].component:null}
                                    reload={reload}  Name={formList[0].Name}  componentParams={formList[0].Parameters} processId={formList[0].ProjectId} TaskId={formList[0].TaskId}
                                    referenceId={Id} useGenerate={useGenerate} jobId={jobId} handleCancelForm={() => onHide()}  />
                            <DynamicComponentHOC JobNo={JobNo} RequesterPositionId={RequesterPositionId} closeForm={closeForm} SourceData={SourceData} sourceId={sourceId} modeParams={parentMode} inDynamicForms={inDynamicForms} taskTitle={taskTitle} onHide={onHide} 
                                componentName={DYNAMICFORMS[formList[0]?.forms[0].Name] ?null:formList[0]?.forms[0].Name} component={DYNAMICFORMS[formList[0]?.forms[0].Name] ?DYNAMICFORMS[formList[0]?.forms[0].Name].component:null}
                                reload={reload}  Name={formList[0]?.forms[0].Name}  componentParams={formList[0]?.forms[0].Parameters} processId={formList[0]?.forms[0].ProjectId} TaskId={formList[0]?.forms[0].TaskId}
                                referenceId={Id} useGenerate={useGenerate} jobId={jobId} handleCancelForm={() => onHide()}  />
</>
                                :
                                <DynamicComponentHOC JobNo={JobNo} RequesterPositionId={RequesterPositionId} closeForm={closeForm} SourceData={SourceData} sourceId={sourceId} modeParams={parentMode} inDynamicForms={inDynamicForms} taskTitle={taskTitle} onHide={onHide} 
                                componentName={DYNAMICFORMS[formList[0].Name] ?null:formList[0].Name} component={DYNAMICFORMS[formList[0].Name] ?DYNAMICFORMS[formList[0].Name].component:null}
                                reload={reload}  Name={formList[0].Name}  componentParams={formList[0].Parameters} processId={formList[0].ProjectId} TaskId={formList[0].TaskId}
                                referenceId={Id} useGenerate={useGenerate} jobId={jobId} handleCancelForm={() => onHide()}  />
}
                        </div>
                        :
                        <Message message={"NoDataForPreview"} type={"info"} />

                    }
                </>


            }



        </div>
        </passListContext.Provider>

    )
}



















