import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";

import { useEffect, useState } from "react";
import { getcontact } from "../api";
import { Search } from "components/Search/Search";


interface contactDataPropsType {
    onHide?: () => void
    CallerNum?: string


}
export const ContactData = ({ 
    onHide,
    CallerNum,
  
}: contactDataPropsType) => {
     
    const [contact , setcontact ] = useState< any>();
    const [loading , setLoading] = useState(false)
    const [messageType , setMessageType] = useState< any>("info")
    const [search, setSearch] = useState<any>(CallerNum);

      const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }
    const getcontactAsync = async (str)=>{
      setLoading(false)
    try{
      const res = await getcontact(str); 
      setcontact(res.Data)         
     }catch(err:any){

    }
     setLoading(true)
    }

     useEffect(() => {
      if(search ){
        getcontactAsync(search)
      }
    }, [search]);
  
  
    return (
        <div className="person-contact-form card p-3">
            <div className="d-flex justify-content-between align-items-center mb-1  ">
              <Search onChange={searchHandler} defultSerach={search} />
            </div>
            <div className=" card-body">
                    <div  className="">
                    {loading ?
                        <>
                       {   contact?.length>0 ?
                          <div className="mt-4">
                         <DataGrid
                            parentName={"contactData"}
                            multiSelect={false}
                            theadData={Object.keys(contact[0])}
                            minCellWidth={80}
                            tbodyData={contact}
                            selectedRowObjectCallback={()=>{}}   
                            startIndex={1}   
                            scrollHeight={"300px"}  
                          /> </div> 
                        : <Message message={"NoDataForPreview"} type={messageType} />    }                      
                        </>
    
                        : <Loading />
                        }
                    </div>
                </div>
        </div>
    );
}