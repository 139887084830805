import { trans } from "utils/helpers"
import "./style.scss";
import { useEffect, useRef, useState } from "react";
interface ButtonPropsType {
    btnType: "submit" | "cancel" | "primary"|"primaryFill" ,
    onClick?: (e:any) => void,
    value: string | JSX.Element,
    disabled?: boolean
    Icon?: boolean
    outline?: boolean
    form?: string
    ref?: any
   
}
export const Button = ({ref, btnType, onClick, value,outline=true, disabled,Icon=false , form}: ButtonPropsType ,) => {

    const timeIDRef = useRef<any>(new Date().getTime() + "Button");


    useEffect(() => {
        
        let flag = true
        const callback = (event: KeyboardEvent) => {
            if(!Icon){

                if(( event.ctrlKey && event.keyCode == 83)&&btnType=="submit" ){
                    event.preventDefault()
                    event.stopPropagation()
                    document.getElementById(timeIDRef.current+btnType)?.click()
                    return false               
                } 
                if(event.key == "Escape"&&btnType =="cancel"){    
                    event.stopPropagation()
                    event.preventDefault()
                    document.getElementById(timeIDRef.current+btnType)?.click()
                    
                    return false
                }
            }
        };
      
            document.addEventListener('keydown', callback);

       
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);

    
    return (
        <>
        {typeof (value) == "string" ?
        <button id={timeIDRef.current+btnType} ref={ref}  form={form} disabled={disabled} type={`${btnType=="submit"? "submit":"button"}`} className={` tabEnd button-custom mx-1 btn ${btnType == "submit" ? Icon ?  "check" : ("btn" + `${outline?"-outline":""}` +"-success " ): (btnType == "cancel" ? "btn-outline-danger " : (btnType == "primaryFill" ? "btn-primary" : "btn-outline-primary " ))}`} onClick={onClick}>
            {typeof (value) == "string" ? trans(value) : value}
        </button>
           : value
        }        
        </>

    )
}