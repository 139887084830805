import { Button } from "components/Button/Button";
import { useContext, useEffect, useRef, useState } from "react";

import { PostArchive, deleteArchived, getArchivedJob, getOrganizationArchive ,getPersonalArchive } from "./api";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { TreeView } from "components/TreeView/TreeView";
import { ENDPOINTS } from "utils/endpoints";
import { ModalForm } from "components/ModalForm/ModalForm";
import { ArchiveFolderForm } from "pages/Secretariats/Archive/ArchiveStructure/components/ArchiveFolderForm/ArchiveFolderForm";
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";
import { passListContext } from "components/ModalStepper/ModalStepper";
interface GroupPositionPropsType {
    onHide: () => void
    type: any
    Id: any
    jobId: any
    inDynamicForms?: any;

}

export const ArchiveForm = ({inDynamicForms=false , Id, type=2,  onHide ,jobId}: GroupPositionPropsType) => {

    const [resetForm, setResetForm] = useState(false);
    const [data, setData] = useState<any>()
    const [archivedList, setArchivedList] = useState<any>()
    const [ListResponse, setListResponse] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [selectedList, setSelectedList] = useState<any>([])
    const [idCallback, setIdCallback] = useState<any>("2")
    const [parent, setParent] = useState<any>(null)
    const [messageType, setMessageType] = useState<any>("info")


    async function submit(e) {
        e.preventDefault();
        let addList:any = []
        selectedList.forEach((item , index )=> {
          addList.push(item.Id)
          
        });
        try {
          if(addList.length>0){
            if(type==1 ){
                let data = {
                    "JobIdList": [jobId],
                    "PersonalArchiveDetailIdList":addList
                }
                await PostArchive(data ,type );
                onHide()
                setResetForm(true);
            }else{
                let data = {
                    "JobIdList":[ jobId],
                    "OrganizationalArchiveDetailIdList": addList
                }
                await PostArchive(data ,type );
                onHide()
                setResetForm(true);
            }
          }else Toast("Msg_ChooseLocationForArchive" , "error")
        } catch (error) {
            
        }
    }
    async function deleteArchivedAsync(e){
      try {
        const respons = await deleteArchived(jobId)
        getarchiveStructureAsync(type)
      } catch (error) {
        
      }
    }

    async function getarchiveStructureAsync(type) {
        setLoading(false)
        if(type==1){
          const respons = await getPersonalArchive(null)
          if(respons.Data.length>0){
            setData(respons.Data)
          }
        }else{
          const respons = await getOrganizationArchive(null)
          if(respons.Data.length>0){
            setData(respons.Data)
          }
          try {
            const archive = await getArchivedJob(jobId)
            setArchivedList(archive.Data)          
          } catch (error) {
            
          }

        }
        setLoading(true)
      }
      
      useEffect(() => {
        getarchiveStructureAsync(type)

       // setPassList("ArchiveForm")
        
      }, []);
      useEffect(() => {
        if(idCallback){
          setIdCallback(null)
        }
      }, [idCallback]);

    


    return (
        <div className="p-2">

        {
            showForm &&
              <ModalForm mode={"create"} title="File" onHide={() => setShowForm(false)} show={showForm}>
                  <ArchiveFolderForm 
                    showForm={() =>{ setIdCallback(parent.Id)  ;setShowForm(false);}} 
                    selectedObj={parent} 
                    mode={"create"} 
                  />
              </ModalForm>
        }
          {(archivedList&&archivedList.length >0 )&& 
          <div className="card">
                      <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px", justifyContent: "space-between" }} className="d-flex  py-1 col-12 pr-4">
                   <div>{trans("ArchivedIn")} </div>
                 <div style={{ marginLeft: "18px" }} ></div>
             </div>
             <div className="card-body ">
             <div style={{display:"flex" , flexDirection:"column" , }} className="">
              {archivedList.map(item=>(
                <div>
                  {item}
                </div>
              ))}
             </div>

             <div className="col-lg-12 mt-1 btns_form">
                <Button
                  btnType="cancel"
                  value="delete"
                  onClick={() => {Toast(trans("Msg_AreYouSureRemoveJobFromAllArchives"), "warning" , "alert" ,deleteArchivedAsync)}}
                  />
                      
            </div>
              </div >
          </div>
          
          }
          <div className="card">
          <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px", justifyContent: "space-between" }} className="d-flex  py-1 col-12 pr-4">
                   <div>{trans("Msg_ChooseLocationForArchive")} </div>
                 <div style={{ marginLeft: "18px" }} ></div>
             </div>

            <div className="card-body">
              <>
                {
                  loading ? (
                    data ?
                      <TreeView
                        addNewNode={(id)=>{setParent(id) ; setShowForm(true)}}
                        IsArchive={true}
                        multiselect={true}
                        selectedRowObjectCallback={(e)=>{}}
                        selectedRowObject={idCallback}
                        getChildrenUrl={type==1?ENDPOINTS.archive.personalArchive.archives: ENDPOINTS.archive.organizationalArchive.archives}
                        theadData={["Id" , "ParentId", "DetailList" , "Title" ]}
                        startIndex={2}
                        Titleparams={"Title"}
                        Childrenparams={'DetailList'}
                        scrollHeight={"370px"}
                        defultShow={false}
                        Data={data} 
                        parentName={"ArchiveFiles"}
                        selectedRowsCallback={setSelectedList}
                        ListResponse={ListResponse}
    
                      />
                      : <Message message={"NoDataForPreview"} type={messageType} />
                  )
                    : <Loading />
                }
              </>
            </div>
            
            <div className="col-lg-12 mt-1 btns_form">
                          <Button value="archive" btnType="submit" onClick={submit} />
                          {!inDynamicForms&&
                          <Button
                          btnType="cancel"
                          value="Cancel"
                          onClick={() => {onHide()}}
                          />
                        }
    
            </div>
          </div>
        </div>
      )
    }