import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { TableTree } from "components/TableTree/TableTree";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment";
import { useEffect, useState } from "react";

import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { DeleteItemTree, DeleteItemTreeDetail, checkLevel, checkLevelItem, getItemGroupRootsFormat, getItemGroupTree } from "../ItemTree/api";
import { ItemTaxes } from "../ItemTree/components/ItemTaxes/ItemTaxes";
import { ItemGroupCodeSettingForm } from "../ItemTree/ItemGroupCodeSetting";
import { ItemBarcode } from "../ItemTree/components/ItemBarcodes/ItemBarcodes";
import { ItemPropertiesValues } from "../ItemTree/components/ItemForm/components/ItemPropertiesValues";
import { ItemProperties } from "../ItemTree/components/ItemProperties/ItemProperties";
import { ItemGroupRoot } from "../ItemTree/ItemGroup/ItemGroupRoot/ItemGroupRoot";
import { ItemGroupBranch } from "../ItemTree/ItemGroup/ItemGroupBranch/ItemGroupBranch";
import { ItemGroupLeaf } from "../ItemTree/ItemGroup/ItemGroupLeaf/ItemGroupLeaf";
import { ItemBranch } from "../ItemTree/ItemGroup/ItemBranch/ItemBranch";
import { ItemLeafForm } from "../ItemTree/ItemGroup/ItemLeaf/ItemLeaf";
import { ItemVariationBranchForm } from "../ItemTree/ItemGroup/ItemVariationBranch/ItemVariationBranch";
import { ItemVariationLeafForm } from "../ItemTree/ItemGroup/ItemVariationLeaf/ItemVariationLeaf";
import { trans } from "utils/helpers";
import { getItemGroupRoot } from "../ItemTree/ItemGroup/ItemGroupRoot/api";
import { getItemGroupBranch } from "../ItemTree/ItemGroup/ItemGroupBranch/api";
import { getItemGroupLeaf } from "../ItemTree/ItemGroup/ItemGroupLeaf/api";




export const ItemGroups = () => {
  const [data, setData] = useState<any>()
  const [forceUpdate, setForceUpdate] = useState<boolean>(false)
  const [selectedObj, setSelectedObj] = useState<any>()
  const [showProperties, setShowProperties] = useState<any>()
  const [showBarcode, setShowBarcode] = useState<any>()
  const [showTax, setShowTax] = useState<any>()
  const [idCallback, setIdCallback] = useState<any>(null)
  const [mode, setmode] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false);
  const [ShowItemTreeDetail, setShowItemTreeDetail] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<any>(0);
  const [checkData, setCheckData] = useState<any>(0);
  const [rootsFormat, setRootsFormat] = useState<any>(false);
  const [message, setMessage] = useState<any>("NoDataForPreview");
  const [showAttachment, setShowAttachment] = useState(false);
  const [levels, setLevels] = useState([]);


  



  async function copyItemHandler(obj) {
    const lang = localStorage.getItem("lang");
    document.body.classList.add("loading-indicator-"+lang);

    try {
      const res = await checkLevelItem(obj.Id)
      let response:any= {}
      switch (res.Data?.ItemLevelType) {
        case 1:
          response = await getItemGroupRoot(obj.Id)
          break;
        case 2:
          response = await getItemGroupBranch(obj.Id)
          break;
        case 3:
          response = await getItemGroupLeaf(obj.Id)
          break;

      }

      navigator.clipboard.writeText( JSON.stringify({...response.Data , Code:res.Data.Code}) )
      Toast(trans("copy"), "success")




    } catch (error) {
      
    }
    document.body.classList.remove("loading-indicator-"+lang);
    
  }






  const clickToolbarCallback = (clickedButtonName: string) => {

    switch (clickedButtonName.toLowerCase()) {
      case "reference":
        alert("call api reference with Id :");
        break;
      case "print":
        alert("print ");
        break;
      case "refresh":setForceUpdate(!forceUpdate);break;
      case "view":  if(selectedObj){setmode("view") ;handleOpenForm(selectedObj)}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "remove":  if(selectedObj){if(selectedObj.ParentId==null && selectedObj.RootId == null ){Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{DeleteHandler(2 )})}else{Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{DeleteHandler(1)})}}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "create": if(selectedObj){setmode("create");setShowItemTreeDetail(!ShowItemTreeDetail);}else Toast(trans("Msg_SelectRow"), "warning"); 
        break;
      case "edit": if(selectedObj){ setmode("edit") ;handleOpenForm(selectedObj)}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "properties": if(selectedObj){ setmode("edit") ;setShowProperties(true)}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "barcode": if(selectedObj){ setmode("edit") ;setShowBarcode(true)}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "copy": if(selectedObj){copyItemHandler(selectedObj)}else Toast(trans("Msg_SelectRow"), "warning"); 
        break;
      case "tax": if(selectedObj){ setmode("edit") ;setShowTax(true)}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
        case "attach": if (selectedObj) {
          setmode("edit")
          setShowAttachment(true)
      } else {
          Toast(trans("Msg_SelectRow"), "warning")
      } break;
    }
  };  


  async function DeleteHandler(type) {
    try {

          if(type==1){
             await DeleteItemTreeDetail(selectedObj.Id ); 
          }else{
             await DeleteItemTree(selectedObj.Id ); 
          }
          if(selectedObj.ParentId != selectedObj.Id){
            setIdCallback(selectedObj.ParentId)
          }else{

            setForceUpdate(!forceUpdate);
          }
          setSelectedObj(null)

    } catch (error) {
      

    }

  }







  const selectedCreateItemTreeCallback =async (e) => {
    setmode("create")
    const lang = localStorage.getItem("lang");
    document.body.classList.add("loading-indicator-"+lang);
    if(e==1&& !selectedObj){
      Toast(trans("Msg_SelectRow"), "warning")
    }else{
      if(e==2){
      
        try {
          const res = await checkLevel()
          if(res.Data){
            setCheckData({...res.Data , ParentCode :selectedObj?.Code})
            setShowForm(true)

          }else{
            setRootsFormat(true)
          }
        } catch (error) {
        }
        setShowForm(e)
      }else{  
        try {
          if(selectedObj.IsLeaf){
            Toast("msg_cantaddnewleveltoitem","error")
          }else{
            const res = await checkLevel(selectedObj.Id)
            console.log("*****" ,res.Data)
                if(res.Data.ItemLevelType>=4){
                  Toast("Msg_ToAddItemGotoItemsMenu","error")
                }else{
                  setCheckData(res.Data )
                  setShowForm(true)
                }

              }
      
          } catch (error) {
            
          }
      }
    }
    document.body.classList.remove("loading-indicator-"+lang);

  }
  const handleOpenForm =async (e) => {
    const lang = localStorage.getItem("lang");
    document.body.classList.add("loading-indicator-"+lang);

    try {
      const res = await checkLevelItem(e.Id)
      setCheckData(res.Data)
      setShowForm(true)
    } catch (error) {
      
    }
    document.body.classList.remove("loading-indicator-"+lang);
  }

  const setselecetrow = (e) => {
    setSelectedObj(e)
  }

  const ShowItemTreeDetailShow = () => {
    setIdCallback(selectedObj?.Id)
    setShowItemTreeDetail(false)
  }


  const handleShowForm = (e,update=false) => {
    if(!selectedObj ||update){
      setForceUpdate(!forceUpdate)
    }
    setIdCallback(selectedObj?.Id)
    setShowForm(0)
}

  async function getItemTreeAsync() {
    setLoading(false)
    try{
      const ItemGroupRoots =await  getItemGroupRootsFormat()
      if(ItemGroupRoots?.Data.length>0){
        let str = ItemGroupRoots?.Data[0];  
        let levelsdata = str.split('').filter((item, pos, self) => self.indexOf(item) === pos); 
        setLevels(levelsdata)
      }
      const respons = await getItemGroupTree(null)
      setData(respons.Data)
    }catch(err:any){
      setMessage(err?.data?.Message)
    }
    setLoading(true)
  }


  

  useEffect(() => {
    setSelectedObj(null)
    setData(null)
    getItemTreeAsync()
  }, [forceUpdate]);


  useEffect(() => {
    if(idCallback){
      setIdCallback(null)
    }
  }, [idCallback]);



    return (
     <>
        {showAttachment &&
            <ModalForm mode={mode} title={("picture")} onHide={() => { setShowAttachment(false) }} show={showAttachment}>
                <Attachment onHide={() => { setShowAttachment(false)}} baseURL={INVENTORYANDSALES_URL} mood={mode}  endpoint={ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.attachment} ownerId={selectedObj?.Id} />
            </ModalForm>
        }
        {
            showTax &&
              <ModalForm mode={mode}  title="Tax" onHide={() => setShowTax(false)} show={showTax}>
               <ItemTaxes 
                onHide={() => setShowTax(false)} 
                IsIndependent={true}
                Id={selectedObj.Id} 
                mode={mode}  />
              </ModalForm>
        }
        {
            rootsFormat &&
              <ModalForm mode={mode} title="rootsFormat" onHide={() => setRootsFormat(false)} show={rootsFormat}>
                  <ItemGroupCodeSettingForm 
                  onHide={() => setRootsFormat(false)}
                  />
              </ModalForm>
        }
        {
            showBarcode &&
              <ModalForm mode={mode} title="Barcode" onHide={() => setShowBarcode(false)} show={showBarcode}>
               <ItemBarcode
                onHide={() => setShowBarcode(false)} 
                IsIndependent={true}
                Id={selectedObj.Id} 
                mode={mode}  />
              </ModalForm>
        }
        {
            showProperties &&
              <ModalForm mode={mode} title="itemproperties" onHide={() => setShowProperties(false)} show={showProperties}>
                {selectedObj.IsLeaf?
                <ItemPropertiesValues
                onHide={() => setShowProperties(false)}
                IsIndependent={true}
                Id={selectedObj.Id}
                mode={ mode}
                />
              
                  :
                 <ItemProperties
                 onHide={() => setShowProperties(false)}
                  selectedObj={selectedObj}
                  mode={mode} 
                  IsIndependent={true}
                  />
              }
              </ModalForm>
        }
        {showForm?<>
        {
            {
                1: (
                  <ModalForm key={"ItemGroupRoot"} mode={mode} title="GroupRoot"  onHide={() => setShowForm(false)} show={checkData.ItemLevelType==1}>
                  <ItemGroupRoot 
                    showForm={(e)=>{handleShowForm(e,true)} } 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
                 </ModalForm>
                ),
                 2: (
                  <ModalForm key={"ItemGroupBranch"} mode={mode} title="GroupBranch" onHide={() => setShowForm(false)} show={checkData.ItemLevelType==2}>
                  <ItemGroupBranch 
                    showForm={() =>{ShowItemTreeDetailShow();setShowForm(false);}} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
              </ModalForm>
                ),
                 3: (
                  <ModalForm key={"ItemGroupLeaf"} mode={mode} title="GroupLeaf"  onHide={() => setShowForm(false)} show={checkData.ItemLevelType==3}>
                  <ItemGroupLeaf 
                    showForm={handleShowForm} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
              </ModalForm>
                 ),
                 4: (
                  <ModalForm key={"ItemBranch"} mode={mode} title="ItemBranch"  onHide={() => setShowForm(false)} show={checkData.ItemLevelType==4}>
                  <ItemBranch 
                    showForm={handleShowForm} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
                 </ModalForm>
                 ),
                 5: (
                  <ModalForm key={"ItemLeafForm"} mode={mode} title="Item"  onHide={() => setShowForm(false)} show={checkData.ItemLevelType==5}>
                  <ItemLeafForm 
                    showForm={handleShowForm} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
                 </ModalForm>
                 ),
                 6: (
                  <ModalForm key={"ItemVariationBranchForm"} mode={mode} title="VariationBranch"  onHide={() => setShowForm(false)} show={checkData.ItemLevelType==6}>
                  <ItemVariationBranchForm 
                    showForm={handleShowForm} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
                 </ModalForm>
                 ),
                 7: (
                  <ModalForm key={"ItemVariationLeafForm"} mode={mode} title="VariationLeaf"  onHide={() => setShowForm(false)} show={checkData.ItemLevelType==7}>
                  <ItemVariationLeafForm 
                    showForm={handleShowForm} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                    checkData={checkData}
                  />
                 </ModalForm>
                 ),
               
             }[checkData.ItemLevelType]
        
        }</>
        :<></>
      }

        <Toolbar 
          emptyList={data?.length == 0} 
          handleClickToolbar={clickToolbarCallback} 
          id={selectedObj} 
          handleCreateItem={selectedCreateItemTreeCallback}
          edit={true} 
          attach={false}
          tax={false} 
          copy={true}
          barcode={false} 
          create={false} 
          search={false} 
          remove={true}
          excel={false}
          warehouse={false}
        />
        <div style={{ overflowY: "auto", 
                height: "75vh",
                 scrollbarWidth: "thin"}}>
            {
              loading ? (
                data ?
                <div>
                  <TableTree
                    baseUrl={INVENTORYANDSALES_URL}
                    selectedRowObjectCallback={setselecetrow}
                    selectedRowObject={idCallback}
                    getChildrenUrl={ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.getChildren}
                    theadData={["Id" , "ParentId", "Children" , "Description" ]}
                    startIndex={2}
                    maxLevel={levels?.length-1}
                    defultparams={{rootId:selectedObj?.RootId ?selectedObj?.RootId :selectedObj?.Id}}
                    Titleparams={"Description"}
                    ComplementaryTitle={"Code"}
                    Childrenparams={'Children'}
                    defultShow={false}
                    Data={data}
                    parentName={"ItemTree"}
                  /> 
                </div>: <Message message={message} type={"error"} />)
                : <Loading />
            }
        </div>
      </>
    )
}




