import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { trans } from "utils/helpers"
import { getReport, postReport, removeReport, setAsDefaultReport } from "./api"
import { ModalForm } from "components/ModalForm/ModalForm"
import { Textbox } from "components/Textbox/Textbox"
import { Button } from "components/Button/Button"
import { ICON_TYPE, REPORT_URL} from "utils/constants"
import { Toast } from "components/ToastShow/ToastShow"
import "./style.scss"
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect"
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect"
import { ENDPOINTS } from "utils/endpoints"


interface ButtonToolbarprint {
    onClick?: () => void,
    Callback: (id: any) => void,
    objectTag?: string,
    reportDomain?: string,

}
export const ButtonPrintIcon=({  Callback,objectTag,reportDomain }:ButtonToolbarprint ) => {
    const buttonToolbarReportStarting = useRef(null);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [options, setOptions] = useState<any>()
    const [selectoptions, setSelectOptions] = useState<any>()
    const [showCreateReport, setShowCreateReport] = useState<any>(false)
    const [reportName, setReportName] = useState<any>()
    const [reportPath, setReportPath] = useState<any>()
    const [scope, setScope] = useState<any>()
    const [reportGroupId, setReportGroupId] = useState<any>()
    const [loading, setLoading] = useState<any>()
    const [rotate , setRotate] = useState<boolean>(false);
    const [showOperations, setShowOperations] = useState(false);
    const formButton = useRef<any>();
    const showOperationsRef = useRef<any>(false);

    function showSubMenuFunction() {
        setShowSubMenu(true)
    }
    async function sendDataAsync(data) {
        
        try {
           const res =  await postReport(data)
           Callback && Callback(res.data+"Design")
            setShowCreateReport(false)
            getDataAsync()
        } catch (error) {
            
        }


    }
   async function getDataAsync() {
       let option:any = []
       setRotate(true)
       setLoading(false)
    try {  
        if(objectTag){
            const res = await getReport(objectTag)
            Object.keys(res).map(item=>{             
                option.push({Id:item,Name :res[item] })
             })
         }
         option.push({Id:"Design" ,Name :"create" })
         setOptions(option)
         setLoading(true)
    } catch (error) {
        setOptions(null)
        setLoading(true)
     }
        
    }


    function clickToCloseSubMenu(e){
        if(e.target.type != "button")
           setShowSubMenu(false)
       document.removeEventListener("click" ,clickToCloseSubMenu)
   }
   function hideSubMenuFunction(e) {
       document.addEventListener("click",clickToCloseSubMenu)
           
   }


    function selectboxValSetter(id) {
        setSelectOptions(id)
        if(id != "Design"){
            Callback && Callback(id);
        }else{
            setShowCreateReport(true)
        }
        setShowSubMenu(false)
        if(buttonToolbarReportStarting.current) (buttonToolbarReportStarting.current as HTMLButtonElement).blur();
    
    }
    async function removeReportAsync(id) {
        try {
        await removeReport(id)
        getDataAsync()
        } catch (error) {
            
        }
    }
    async function setasdefault(id) {
        try {
        await setAsDefaultReport(id,objectTag)
        showOperationsRef.current = false
        setShowOperations(false)
        getDataAsync()
        } catch (error) {
            
        }
    }

    function formSubmit(e){
        e.preventDefault()
        let report=  {
              "reportName": reportName,
              "ReportFileName":reportPath+".mrt",
              "isUseInDashboard": false,
              "reportGroupId": reportGroupId,
              "objectTag": objectTag,
              "scope": +scope,
            }
         sendDataAsync(report);
    }
    
    useEffect(() => {
        if(showSubMenu){

            getDataAsync()
        }
    }, [objectTag,showSubMenu]);

    return (

        <>  
        {showCreateReport &&
            <ModalForm mode={"create"} title={"LetterForm"} onHide={() => { setShowCreateReport(false) }} show={showCreateReport}>
                <form className="Reserve-form py-2" >
                    <div className="card">
                <div className="card-body">
                    <div className="container">
                        <div className="row ">
                            <div className="col">
                                <Textbox  
                                    textboxType="text"
                                    textboxName="reportName"
                                    onChange={(e)=>{setReportName(e.target.value)}}
                                    defaultValue={""}
                                    labelText="reportName"
                                    mode={"edit"}
                                />
                            </div>  
                            <div className="col">
                                 <Textbox  
                                 textboxType="text"
                                 textboxName="reportPath"
                                 onChange={(e)=>{setReportPath(e.target.value)}}
                                 defaultValue={""}
                                 labelText="reportPath"
                                 mode={"edit"}
                               />
                             </div>
                            <div className="col">
                                {/* DV */}
                                <EnumerationTypeSelect
                                onChange={setScope} 
                                required={true} 
                                mode={"edit"}
                                label="scope"
                                enumTypeSelect="Scope" 
                                name="Scope"
                                value={null} />
                             </div>
                            <div className="col">

                               <DictionarySelectBox
                                Change={(e)=>{setReportGroupId(e) }}  
                                required={true}
                                mode={"view"}
                                baseUrl={REPORT_URL} 
                                endPoint={ENDPOINTS.reportST.reportGroup.dictionary}
                                label="ReportGroup"
                                name="ReportGroupDictionary" 
                                value={reportDomain}
                                params={{"reportDomain":reportDomain}}
                                 />
                            </div>

                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            <>
                                <Button  onClick={(e)=>{formSubmit(e)}}  value="Save" btnType="submit" />
                                <Button
                                    btnType="cancel"
                                    value="Cancel"
                                    onClick={() => { setShowCreateReport(false) }}
                                />
                            </>
                        </div>
                    </div>
                 </div>
                    </div>
                </form>
                                    
            </ModalForm>
        }
        {objectTag && 
        <div className="" style={{width:"10px" , marginLeft:"5%"}}>
            <button type="button" ref={buttonToolbarReportStarting} onClick={() => { }} onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction} className="btn toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
            <FontAwesomeIcon  style={{width:"20px" , float:"left" , marginLeft:"20px"}} icon={[ICON_TYPE, "print"]} color="gray" size="lg" className="icon mt-1 " />
            <div  className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`}>
                {loading ?
                <>
                   {options && options.map((option, index) => (
                    <div key={index} style={{display:"flex",cursor:"pointer" }} className="toolbar-btn-sub-menu__option" data-value={`${option.Id}`} >
                        <div className="operations__trigger-btn noHover"  ref={formButton} onFocus={()=>{setShowOperations(option.Id);showOperationsRef.current = option.Id}} onClick={(e)=>{e.stopPropagation();}} style={{position:"relative",width: "20px" , paddingLeft:0  , marginRight: "-13px"  ,marginBottom: "4px"}} tabIndex={-1}    onBlur={() => { setShowOperations(false);showOperationsRef.current = false }}>
                           {option.Name!="create" &&  
                           <FontAwesomeIcon color="grey"   icon={[ICON_TYPE, "ellipsis-v"]}/> 
                           } 
                            {(showOperations ==option.Id ||showOperationsRef.current==option.Id)&& 
                            <div onClick={e=>{e.stopPropagation()  ; selectboxValSetter(option.Id+"Design")}} style={{minWidth: "90px" ,right:"8px", top: `${9}px` ,width:"120px" }} className={`operations__operations-icons pt-1 ${(showOperations==option.Id ||showOperationsRef.current==option.Id) ? "" : "d-none"}`}>
                              <div className={`operations__operations-icon-wrapper Hover`} >
                                 <div className="operations__operations-icon-wrapper " onClick={() => {  }}>
                                 <FontAwesomeIcon  className="chat__chatbox-reply-btn"   color={"grey"}  icon={['fas', 'edit']} />
                                     <p>{trans("edit")}</p>
                                 </div>
                               </div>
                              <div onClick={e=>{e.stopPropagation()  ;Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{ removeReportAsync(option.Id)})}} className={`operations__operations-icon-wrapper `} >
                                 <div className="operations__operations-icon-wrapper" onClick={() =>{}}>
                                 <FontAwesomeIcon icon={['fas', 'trash']} className="chat__chatbox-reply-btn"   color={`${'grey'}`} />
                                     <p>{trans("Delete")}</p>
                                 </div>
                               </div>
                              <div onClick={e=>{e.stopPropagation()  ;setasdefault(option.Id)}} className={`operations__operations-icon-wrapper `} >
                                 <div className="operations__operations-icon-wrapper" onClick={() =>{}}>
                                 <FontAwesomeIcon icon={['fas', 'square-check']} className="chat__chatbox-reply-btn"   color={`${'grey'}`} />
                                     <p>{trans("setasdefault")}</p>
                                 </div>
                               </div>
                            
                            </div>
                            }
                        </div> 
                        <span onClick={()=>{selectboxValSetter(option.Id)}}> 
                        {trans(option.Name)}
                        </span> 
                    </div>
                   ))}
                </>
                :
                <div  className="sync-box-icon mt-4" onClick={()=>{setRotate(true);  setTimeout(()=>{ setRotate(false) }, 1500)}}   >       
                <FontAwesomeIcon icon={[ICON_TYPE , "sync"] } className={`text-primary  ${rotate ? "Rotate" : "" } `}  />  
                </div>  
            }
            </div>
            </button>
        </div>
        }
        </>
    )
    }