import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { Message } from "components/Message/Message";
import { Loading } from "components/Loading/loading";
import { Toast } from "components/ToastShow/ToastShow";
import { downloadCSV, getPageSize , trans } from "utils/helpers";
import { checkHasItemGroup,postWarehouseItemApi,putWarehouseItemApi, getListWarehouseItem,deleteItemGroupTree,getEditablecolumns} from "./api"
import { WarehouseItemForm } from "./WarehouseItemForm";
import { ModalForm } from "components/ModalForm/ModalForm";
import { WarehouseItemGroup } from "../Warehouse/WarehouseStructure/components/WarehouseItemGroup/WarehouseItemGroup";
import { DataGridEditable } from "components/DataGridEditable/DataGridEditable";



export const WarehouseItem = () => {

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [flag, setFlag] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [loading, setLoading] = useState(false);
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [showItemGroup, setShowItemGroup] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pages, setPages] = useState<number>(0);
    const [filterlist, setFilterlist] = useState<any>("");
    const [selectList , setSelectList] = useState<any>([]);
  const [editAllGrid, setEditAllGrid] = useState<any>(false);
  const EditType = { All: 1, SingleRow: 2,Columns:3 };
  const [editablecolumns, setEditablecolumns] = useState(false);
  const [changedDataObj, setChangedDataObj] = useState<any>(null);
  const [showGroupEdit, setShowGroupEdit] = useState(false);

  const [resetMultiSelect, setResetMultiSelect] = useState<any>(false);




  const getEditablecolumnsAsync = async () => {
    try {
      const res = await getEditablecolumns();
      setEditablecolumns(res.Data);
    } catch (error) {}
  };


    const searchHandler = (str) => {
        setPageNum(1);
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }

      const handlechangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("WarehouseItem" ,e.currentTarget.value )
        getWarehouseItemAsync(1)
      };

    const handleGoPage = (value) => {
      if (value) {
        if (value < 1 || value > pages) { }
        else setPageNum(+value);getWarehouseItemAsync(+value)
      } else {

      }
    };
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getWarehouseItemAsync(pageNum + 1);
          };
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getWarehouseItemAsync(pageNum - 1);
          };
    };




    const handleCreateWarehouseItem =async () => {
        try {
             const res= await checkHasItemGroup(JSON.parse(localStorage.getItem("Inventory")+"").Id)
             if(res.Data){
                    setMode("create")
                    setShowForm(true)
                }else{
                    Toast("WarehouseHasNoItemGroup_ConfirmAdd","warning","alertWithoutClose", ()=>{setShowItemGroup(true)  });
                }
        } catch (error) {
            
        }
    }
    
    const handleEditRow = () => {
        setMode("edit");
        setShowForm(true)
    }

    const handleDeleteRow = async(e?:any) => {
        let data
        if(e){
            data = e;
        }else if(selectList.length > 0){
            data=selectList.map((item)=>{return item.Id})
        }else{
            data = [selectedObj?.Id]
        }
        try {
            await deleteItemGroupTree(data)
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }
    }
    const handleViewRow = () => {
        setMode("view")
        setShowForm(true)
    }
    
    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{sessionStorage.setItem("changingforms" , "false");setSelectedObj(obj);setResetForm(true) });
        }else{
            setSelectedObj(obj)         
        };
    }

    const selectListCallBack =(list)=>{
        setSelectList(list[1])
      }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setShowForm(false)
            });
        }else{
            setShowForm(false)     
        };
    }
    async function getWarehouseItemAsync(PageNum = pageNum) {
        setLoading(false);
        setResponse(null);
        let pageSizeChangedByUser = getPageSize("WarehouseItem" )
        setPageSize(pageSizeChangedByUser)
        try {
        await getEditablecolumnsAsync();
            const res = await getListWarehouseItem (PageNum,  pageSizeChangedByUser ,orderBy.length == 0 ?null:  orderBy.join(",") ,search,filterlist,JSON.parse(localStorage.getItem("Inventory")+"").Id);
            setResponse(res.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
           setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }
 

    useEffect(() => {
        setResponse(null)
        getWarehouseItemAsync();
    }, [forceUpdate, search , filterlist , orderBy ,flag ,forceUpdate]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);

 

    


    /* #endregion */

    /* #region toolbarWarehouseItems */
    const clickToolbarCallback = (clickedButtonName: string) => {
   
      
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,1 ,trans("WarehouseItem"));break;
            case "refresh": setForceUpdate(!forceUpdate);setEditAllGrid(false); break;
            case "create": handleCreateWarehouseItem(); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit":
                if (selectedObj) {
                  setEditAllGrid(EditType.SingleRow);
                }
                break;
                case "editgrid":
                    setEditAllGrid(EditType.All);
                    break;
            case "groupedit":
                        setShowGroupEdit(true);
                        break;
            case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "help": alert("call api help"); break;
            case "cancel":
                setEditAllGrid(false);break;
                case "save":
                    saveChangeingData();
                    break;
        }

  


    };
    /* #endregion */



    const saveChangeingData = async () => {
        if (Object.keys(changedDataObj).length > 0) {
          let PostlistData: any = [];
          let PutlistData: any = [];
    
          Object.keys(changedDataObj).map((item) => {
            if (changedDataObj[item].Id == null) {
              let data = {
                ...changedDataObj[item],
                PriceListId: selectedObj.Id,
              };
              delete data.Operation;
              delete data.SelectAll;
              PostlistData.push(data);
            } else {
             
              
              let data = {
                ...changedDataObj[item],
               
              };
        
              
              delete data.Operation;
              delete data.SelectAll;
              PutlistData.push(data);
            }
          });
          try {
            if (PostlistData.length > 0) {
          
    
              await postWarehouseItemApi(PostlistData);
            }
          } catch (error) {}
          if (PutlistData.length > 0) {
            await putWarehouseItemApi(PutlistData);
          }
          setForceUpdate(!forceUpdate);
          setChangedDataObj(null);
    
          setEditAllGrid(false);
    
          try {
          } catch (error) {}
        }
      };



    const changeingData = (data) => {
        if (data && Object.keys(data).length > 0) {
          setChangedDataObj(data);
        } else {
          setChangedDataObj(null);
        }
      };





    return (
        <>  

            <div className="cartable-Action">
                <Toolbar handleSearch={searchHandler} emptyList={response == null || response?.length == 0} id={selectedObj?.Id} remove={true} create={true} view={false} edit={true} search={true} handleClickToolbar={clickToolbarCallback}
                editInGrid={true}
        save={changedDataObj != null ? true : false}
        cancel={editAllGrid}
                />
                <div className="card"> 
                    <div className="">
                        {showForm && 
                        <ModalForm mode={mode} title="WarehouseItems"  onHide={() => setShowForm(false)} show={showForm}>
                           <WarehouseItemForm reload={()=>{setForceUpdate(!forceUpdate);setShowForm(false)}} mode={mode} onHide={cancel}  selectedObj={selectedObj}/>
                        </ModalForm>
                        }
                        { showItemGroup &&
                            <ModalForm mode={mode} title={"WarehouseItemGroup"} onHide={() => setShowItemGroup(false)} show={showItemGroup}>
                                <WarehouseItemGroup
                                  mode={mode}
                                  warehouseId={JSON.parse(localStorage.getItem("Inventory")+"").Id}
                                  onHide={()=>{setShowItemGroup(false)}}
                                />
                            </ModalForm>
                        }

                        {loading ?
                            response?.Data?.length > 0 ?
                                <>
                   <DataGridEditable
                  RowKey={"Id"}
                  rowSelected={selectedObj}
                  changeingDataCallback={changeingData}
                  editAllGrid={editAllGrid}
                  editablecolumns={editablecolumns}
                  setEditAllGrid={setEditAllGrid}
                  multiSelect={true}
                  selectList={selectListCallBack}
                  resetMultiSelect={resetMultiSelect}
                  mode={mode}
                  handleDelete={handleDeleteRow}
                  showForm={showForm}
                  columnsWithOption={["Price"]}
                  parentName={"WarehouseItem"}
                  startIndex={2}
                  theadData={Object.keys(response.Data[0])}
                  tbodyData={response.Data}
                  minCellWidth={80}
                  selectedRowObjectCallback={selectedIdCallback}
                  totalRecords={response.TotalRecords}
                  pageNum={pageNum}
                  pageSize={pageSize}
                  HandleNextPage={handleNextPage}
                  HandlePrevPage={handlePrevPage}
                  handlechangePageSize={handlechangePageSize}
                  first={() => {
                    getWarehouseItemAsync(pages);
                    setPageNum(pages);
                  }}
                  end={() => {
                    getWarehouseItemAsync(1);
                    setPageNum(1);
                  }}
                handleGoPage={handleGoPage}
                dependsValue={['OrderPoint','HasOrderPoint']}
                />
                                </>
                                : <Message message={"NoDataForPreview"} type={messageType} />


                            : <Loading />
                        }
                    </div>
                </div>
            </div>
        </>

    );
}

 
