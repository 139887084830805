import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getAllItemBarcode = (searchString, orderBy) => http({
   baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.Barcode.browse,
   method: 'get',
   params: {
       searchString: searchString,
       orderBy: orderBy
   }
}).then((res) => {

   return res.data
}).catch((err) => {

   return Promise.reject( err);
});

export const postBarcode = (data) => http({
   baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.Barcode.browse,    
   method: 'post',
   data:data
}).then((res) => {

   return res.data
}).catch((err) => {

   return Promise.reject( err);
});
export const getItemBarcodeslist = (id) => http({
   baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemBarcode.browse,    
   method: 'get',
   params:{
      id:id
   }
}).then((res) => {

   return res.data
}).catch((err) => {

   return Promise.reject( err);
});


export const sendItemBarcodes = (list) => http({
   baseURL:INVENTORYANDSALES_URL,
  url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemBarcode.browse,
  method: "post",
  data:list
}).then((res) => {
  return res.data
}).catch((err) => {
  return Promise.reject(err.response  );
});
export const removeItemBarcodes = (list) => http({
   baseURL:INVENTORYANDSALES_URL,
  url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemBarcode.browse,
  method: "delete",
  data:list
}).then((res) => {
  return res.data
}).catch((err) => {
  return Promise.reject(err.response  );
});