import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getListProvince = (async(pageNumber,pageSize,searchString,orderByString,filterString) => await http({
      
    url : ENDPOINTS.BaseDefinitionAndSetting.Province.browse,
    method: 'get',
    params:{
        PageNumber:pageNumber,
        PageSize:pageSize,
        OrderByString:orderByString,
        SearchString:searchString,
        FilterString:filterString,
    }
}).then((res) =>{
    return res.data
}).catch((err) =>{
    return Promise.reject(err);
})

)

export const getProvince = (async (Id) => await http({   
    url: ENDPOINTS.BaseDefinitionAndSetting.Province.load,
    method: 'get',
    params: {Id : Id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})
)


export const postProvinceApi = (data) => http({
    url: ENDPOINTS.BaseDefinitionAndSetting.Province.browse,
    method: "post",
    data:data,
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

export const putProvinceApi = (data) => http({
      
    url: ENDPOINTS.BaseDefinitionAndSetting.Province.browse,
    method: "put",
    data:data,
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const getProvinceDictionary = (async (search,countryId,id) => await http({
      
    url: ENDPOINTS.BaseDefinitionAndSetting.Province.dictionary,
    method: 'get',
    params: {
        countryId:countryId,
        searchString:search,
        Id:id,
    }
})
    .then((response) => (response.data))

)

export const deleteProvinceApi = (id) => http({
      
    url : ENDPOINTS.BaseDefinitionAndSetting.Province.browse,
    method: 'delete',
    params:{
        id:id
    }
    
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
});