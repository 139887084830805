

import { ServiceRequestForm } from "pages/ClientPortal/ServiceRequestForm/ServiceRequestForm";
import { JobInvoiceForm } from "pages/OfficeAutomation/Cartable/components/JobInvoiceForm/JobInvoiceForm";
import { LetterForm } from "pages/Secretariats/LettersPage/components/LetterForm/LetterForm";
import { trans } from "./helpers";
import { CustomerGeneralRequest } from "pages/ClientPortal/CustomerGeneralRequestForm/CustomerGeneralRequestForm";
import { JobDeliveryForm } from "pages/ClientPortal/JobDeliveryForm/JobDeliveryForm";
import { ArchiveForm } from "pages/OfficeAutomation/Cartable/components/ArchiveForm/ArchiveForm";
import { DailyLeaveForm } from "pages/OfficeAutomation/Cartable/components/DailyLeaveRequestForm/DailyLeaveRequestForm";
import { GeneralRequestForm } from "pages/OfficeAutomation/Cartable/components/GeneralRequestForm/GeneralRequestForm";
import { HourlyLeaveForm } from "pages/OfficeAutomation/Cartable/components/HourlyLeaveForm/HourlyLeaveForm";
import { JobSendForm } from "pages/OfficeAutomation/Cartable/components/JobSendForm/JobSendForm";
import { LetterSendContent } from "pages/OfficeAutomation/Cartable/components/LetterSendForm/LetterSendContent";
import { Signature } from "pages/OfficeAutomation/Cartable/components/Signature/Signature";
import { DocumentArchiveForm } from "pages/Secretariats/Archive/ArchiveTree/components/DocumentsForm/DocumentArchiveForm";
import { InvitationForm } from "pages/MeetingManagement/Meeting/components/InvitationForm/InvitationForm";
import { MeetingApprovalList } from "pages/MeetingManagement/MeetingApproval/MeetingApprovalList";
import { MeetingTabForm } from "pages/MeetingManagement/Meeting/MeetingTabForm";
import { SalesOrderForm } from "pages/InventoryAndSales/Sales/SalesOrder/SalesOrderForm";
import { SalesPreInvoiceForm } from "pages/InventoryAndSales/Sales/SalesPreInvoice/SalesPreInvoiceForm";
import { SalesInvoiceForm } from "pages/InventoryAndSales/Sales/SalesInvoice/SalesInvoiceForm";
import { CustomerCreditControlForm } from "pages/InventoryAndSales/Sales/SalesPreInvoice/CustomerCreditControl/CustomerCreditControlForm";
import { CustomerSalesOrder } from "pages/ClientPortal/ECommerce/components/Cart/components/CustomerSalesOrder";
import { OrderForm } from "pages/InventoryAndSales/Sales/Order/OrderForm";

export const DYNAMICFORMS = {
    "MeetingApprovalForm":{
        title: 'MeetingApprovalForm',
        titleToShow: trans('MeetingApproval'),
        showJobInfo: false,
        route: null,
        component: MeetingApprovalList,
    },
    "InvitationForm":{
        title: 'InvitationForm',
        titleToShow: trans('Invitation'),
        showJobInfo: false,
        route: null,
        component: InvitationForm,
    },
    "MeetingForm":{
        title: 'MeetingForm',
        titleToShow: trans('Meeting'),
        showJobInfo: false,
        route: null,
        component: MeetingTabForm,
    },
    "LetterForm":{
        title: 'LetterForm',
        titleToShow: trans('Letter'),
        showJobInfo: false,
        route: null,
        component: LetterForm,
    },
    "JobInvoiceForm":{
        title: 'JobInvoiceForm',
        titleToShow: trans('JobInvoiceForm'),
        showJobInfo: false,
        route: null,
        component: JobInvoiceForm,
    },
   "JobDeliveryForm": {
        title: 'JobDeliveryForm',
        titleToShow: trans('JobDeliveryForm'),
        showJobInfo: false,
        route: null,
        component: JobDeliveryForm,
    },
  "JobSendForm":  {
        title: 'JobSendForm',
        titleToShow: trans('JobSendForm'),
        showJobInfo: false,
        route: null,
        component: JobSendForm,
    },
   "LetterSendForm": {
        title: 'LetterSendForm',
        titleToShow: trans('LetterSendForm'),
        showJobInfo: false,
        route: null,
        component: LetterSendContent,
    },
   "ServiceRequestForm": {
        title: 'ServiceRequestForm',
        titleToShow: trans('ServiceRequestForm'),
        showJobInfo: false,
        route: null,
        component: ServiceRequestForm,
    },
   "CustomerGeneralRequestForm": {
        title: 'CustomerGeneralRequestForm',
        titleToShow: trans('CustomerGeneralRequest'),
        showJobInfo: false,
        route: null,
        component: CustomerGeneralRequest,
    },
   "GeneralRequestForm": {
        title: 'GeneralRequestForm',
        titleToShow: trans('GeneralRequestForm'),
        showJobInfo: false,
        route: null,
        component: GeneralRequestForm,
    },
   "DailyLeaveForm": {
        title: 'DailyLeaveForm',
        titleToShow: trans('DailyLeaveForm'),
        showJobInfo: false,
        route: null,
        component: DailyLeaveForm,
    },
   "HourlyLeaveForm": {
        title: 'HourlyLeaveForm',
        titleToShow: trans('HourlyLeaveForm'),
        showJobInfo: false,
        route: null,
        component: HourlyLeaveForm,
    },
   "SignatureForm" : {
        title: 'SignatureForm',
        titleToShow: trans('SignatureForm'),
        showJobInfo: false,
        route: null,
        component: Signature,
    },
   "ArchiveForm": {
        title: 'ArchiveForm',
        titleToShow: trans('ArchiveForm'),
        showJobInfo: false,
        route: null,
        component: ArchiveForm,
    },
   "DocumentArchiveForm": {
        title: 'DocumentArchiveForm',
        titleToShow: trans('DocumentArchiveForm'),
        showJobInfo: false,
        route: null,
        component: DocumentArchiveForm,
    },
   "CustomerSalesOrderForm": {
        title: 'CustomerSalesOrderForm',
        titleToShow: trans('CustomerSalesOrderForm'),
        showJobInfo: false,
        route: null,
        component: SalesOrderForm,
    },
   "SalesOrderForm": {
        title: 'SalesOrderForm',
        titleToShow: trans('SalesOrderForm'),
        showJobInfo: false,
        route: null,
        component: SalesOrderForm,
    },
   "OrderForm": {
        title: 'OrderForm',
        titleToShow: trans('OrderForm'),
        showJobInfo: false,
        route: null,
        component: OrderForm,
    },
   "SalesPreInvoiceForm": {
        title: 'SalesPreInvoiceForm',
        titleToShow: trans('SalesPreInvoiceForm'),
        showJobInfo: false,
        route: null,
        component: SalesPreInvoiceForm,
    },
    "SalesInvoiceForm": {
        title: 'SalesInvoiceForm',
        titleToShow: trans('SalesInvoiceForm'),
        showJobInfo: false,
        route: null,
        component: SalesInvoiceForm,
    },
    "CustomerCreditControlForm": {
        title: 'CustomerCreditControlForm',
        titleToShow: trans('CustomerCreditControlForm'),
        showJobInfo: false,
        route: null,
        component: CustomerCreditControlForm,
    }
}
