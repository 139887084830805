import { http } from "utils/http";

export const getAttachments = (ownerId, endpoints, baseURL, params, showMode) =>
  http({
    [baseURL ? "baseURL" : ""]: baseURL,
    url: endpoints + (showMode ? "/List" : "/List/Detail"),
    method: "get",
    params: {
      ownerId: ownerId,
      returnThumbnail: showMode,
      ...params,
    },
  })
    .then((response) => response.data)
    .catch((err) => {
      return Promise.reject(err);
    });

export const DeleteAttachments = (attachId, endpoints, baseURL) =>
  http({
    [baseURL ? "baseURL" : ""]: baseURL,
    url: endpoints,
    method: "delete",
    params: {
      attachId: attachId,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const PostAttachments = (data, endpoints, baseURL) =>
  http({
    [baseURL ? "baseURL" : ""]: baseURL,
    url: endpoints,
    method: "post",

    data: data,
    headers: {
      "content-type": "multipart/form-data",
      Accept: "*/*",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
export const PutAttachments = (data, ownerId, endpoints, id, baseURL) =>
  http({
    [baseURL ? "baseURL" : ""]: baseURL,
    url: endpoints,
    method: "put",
    params: {
      fileId: id,
    },
    data: data,
    headers: {
      "content-type": "multipart/form-data",
      Accept: "*/*",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const loadAttachment = (attachId, endpoints, baseURL, params) =>
  http({
    [baseURL ? "baseURL" : ""]: baseURL,

    url: endpoints + "/File",
    method: "get",
    params: {
      attachId: attachId,
      ...params,
    },
    responseType: "blob",
  })
    .then(async (response) => {
      return blobToBase64(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getDownloadUrlAttachmentAsync = (
  attachIds,
  endpoints,
  baseURL,
) =>
  http({
    url: endpoints + "/Url",
    method: "post",
    data: attachIds,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getVersions = (endpoints, baseURL, attachId,getAllData) =>
  http({
    [baseURL ? "baseURL" : ""]: baseURL,
    url: endpoints + (getAllData?"/Version/All": "/Version"),
    method: "get",
    params: {
      attachId,
    },
  })
    .then((response) => response.data)
    .catch((err) => {
      return Promise.reject(err);
    });

export const deleteVersion = (endpoints, baseURL, attachId, versionId) =>
  http({
    [baseURL ? "baseURL" : ""]: baseURL,
    url: endpoints + "/Version",
    method: "delete",
    params: {
      attachId,
      versionId,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const putVersion = (endpoints, baseURL, attachId, versionId) =>
  http({
    [baseURL ? "baseURL" : ""]: baseURL,
    url: endpoints + "/Version/Revert",
    method: "put",
    params: {
      attachId,
      versionId,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
    export const getVersion = (endpoints, baseURL, attachId, versionId) =>
        http({
          [baseURL ? "baseURL" : ""]: baseURL,
          url: endpoints + "/Version/Revert",
          method: "put",
          params: {
            attachId,
            versionId,
          },
        })
          .then((response) => {
            return response.data;
          })
          .catch((err) => {
            return Promise.reject(err);
          });

   export const putAsDefault = (endpoints, baseURL,ownerId ,id) =>
            http({
              [baseURL ? "baseURL" : ""]: baseURL,
              url: endpoints + "/Default",
              method: "put",
              params: {
                ownerId,
                id,
              },
            })
              .then((response) => {
                return response.data;
              })
              .catch((err) => {
                return Promise.reject(err);
              });