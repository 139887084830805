import { trans } from "utils/helpers"
export const REGEX = {
    number: {
        //regex: "^[0-9]|Backspace|Delete|ArrowRight|ArrowLeft|Home|End|Tab+$",
        regex: "^[0-9]+$",
        valditionMsg: trans('Msg_Validation_InvalidCharacter'),
    },
    text: {
        regex: ".*$",
        valditionMsg: trans('Msg_Validation_InvalidCharacter'),
    },
    textarea: {
        regex: ".*$\r?\n?",
        valditionMsg: trans('Msg_Validation_InvalidCharacter'),
    },
    email: {
        regex: "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$",
        valditionMsg: trans("Msg_Validation_InvalidCharacter"),// این رجکس رو توی استک اور فلو پیدا کردم
    },
    tel: {
        regex: "^[0-9]{1,15}$",
        valditionMsg: trans("Msg_Validation_ValueIsIncorrect"),
    },
    mobile: {
        regex: "^0-?[0-9]{10}$",
        valditionMsg: trans("Msg_Validation_ValueIsIncorrect"),
    },
    PostalCode: {
        regex: "^[0-9]{10}$",
        valditionMsg: trans("Msg_Validation_ValueIsIncorrect"),
    },
    userName: {
        regex: "^[a-zA-Z0-9@_]*$",
        valditionMsg: trans("Msg_Validation_InvalidCharacter"),
    },
    systemKey: {
        regex: "^[a-zA-Z0-9]*$",
        valditionMsg: trans("Msg_Validation_InvalidFormat"),
    },
    formKey: {
        regex: "[a-zA-Z0-9_]*$",
        valditionMsg: trans("Msg_Validation_InvalidFormat"),
    },
    nationalCode: {
        regex: "^[0-9]{10}$",
        valditionMsg: trans("Msg_Validation_ValueIsIncorrect"),
    },
    nationalIdentifier: {
        regex: "^[0-9]{10,14}$",
        valditionMsg: trans("Msg_Validation_ValueIsIncorrect"),
    },
    econNo: {
        regex: "^[0-9]{10,14}$",
        valditionMsg: trans("Msg_Validation_ValueIsIncorrect"),
    },
    RegistryNumber: {
        regex: "^[0-9]{4,10}$",
        valditionMsg: trans("Msg_Validation_ValueIsIncorrect"),
    },
    birthCertNo: {
        regex: "^[0-9]{1,10}$",
        valditionMsg: trans("Msg_Validation_ValueIsIncorrect"),
    },
    actualTime: {
        regex: "^[0-9][0-9]:[0-6][0-9]$",
        valditionMsg: trans("Msg_Validation_InvalidFormat"),

    },

    password: {
        // regex: "(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,}",
        // regex: "^\w{4}$",
        regex: "^[A-Za-z0-9\s$&+,:;=?@#|'<>.^*()%!-]{4,}$",
        valditionMsg: trans("Msg_Validation_InvalidCharacter"),
    },
    datePicker: {
        regex:"^1[3-5][0-9][0-9]{1}$",
        valditionMsg: trans("Msg_Validation_InvalidFormat"),
    },
    format:{
        regex:"[A-Z]",
        ValidityState: trans("Msg_Validation_InvalidCharacter"),
    },
    unitCode:{
        regex:"[0-9]{3,}",
        ValidityState: trans("Msg_Validation_InvalidFormat"),
    },
    wareHouseCode:{
        regex:"[0-9]{1,3}",
        ValidityState: trans("msg_validation_valueisincorrect")
    },
    CodeFormat:{
        regex:"^A*B*C*D*E*F*G*H*I*J*K*L*M*N*O*P*Q*R*S*T*U*V*W*X*Y*Z*$",
        ValidityState: trans("msg_invalidcodeformat")
    },
    Condition:{
        regex:"^[a-zA-Z0-9@!=&><+*%, (, )]*$",
        ValidityState: trans("msg_invalidcodeformat")
    },
    Guid:{
        regex:"^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
        ValidityState: trans("msg_invalidcodeformat")
    }

}

/* "Msg_Validation_ErrorInvalidLength	طول مجاز رعایت نشده است	
Msg_Validation_InvalidCharacter	کاراکتر غیرمجاز	
Msg_Validation_InvalidFormat	فرمت غیرمجاز	
Msg_Validation_PasswordAndConfirmPasswordIsNotSame	رمز عبور و تکرار آن یکسان نیستند	
Msg_Validation_ValidMaxLength	حداکثر طول مجاز	
Msg_Validation_ValidMinLength	حداقل طول مجاز	
Msg_Validation_ValidMinMaxLength	حداقل و حداکثر طول مجاز	
Msg_Validation_ValueIsIncorrect	مقدار صحیح نمی باشد	
Msg_Validation_ValueIsRequired	مقدار نمی تواند خالی باشد	
" */