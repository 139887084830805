import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import "./style.scss";
interface searchPropsType {
    label?: string
    autoFocus?: any
    defultSerach?: string
    isFocos?: boolean
    checkStop?: boolean
    type?: 'search-input' | 'search-icon'  
    onChange?: (e) => void;
    setIsFocos?: (e) => void;
}
export const Search = ({defultSerach="", label,checkStop=true ,type = 'search-input',onChange ,setIsFocos ,isFocos ,autoFocus}: searchPropsType) => {
    const flipSearchIcon = localStorage.getItem("lang") == "fa" ? "horizontal" : undefined;
    const [searchInputVal, setSearchInputVal] = useState<any>(null);
    const [focos, setFocos] = useState<any>(false);
    const [isSearched, setIsSearched] = useState<any>(null);
    const refFocos = useRef<any>(focos);

    const timeID = useRef<any>(new Date().getTime() + "SearchBox");

    const changeSearchInputVal = (event) => {
    
        setSearchInputVal(event?.currentTarget.value);
    }
    useEffect(() => {
      const getData = setTimeout(() => {
        if(searchInputVal != null){
         // onChange && onChange(searchInputVal)
        }
        
      }, 800)
      if((searchInputVal=="") &&(isSearched||defultSerach)){
        setIsSearched(null)
        onChange && onChange(null)
        }
      const elements = Array.from(document.getElementsByClassName('search-bar'));
      let stop=  false
      let canWork= false
      if(checkStop){
          elements.map((element, index) => {
              if((+element?.id.replace("SearchBox" , "")) > +timeID.current.replace("SearchBox" , "")+100){
                 stop = true
            }
         })
      }
      if(!stop){
       if(document.getElementsByClassName("fullscreen-bg-modal").length<elements.length ){
            canWork = true
       }else{
            canWork= false
       }
    }
    const callback = (event: KeyboardEvent) => {
        if (  document.activeElement?.className.includes("search") &&searchInputVal?.length>0  && (event.code === 'Enter'||event.code === 'NumpadEnter'||event.code === 'Space')&&!stop &&  !document.activeElement?.className.includes("filterInput")  ) {    
            onChange && onChange(searchInputVal)
            setIsSearched(true)
            clearTimeout(getData)
          }

      };
      document.addEventListener('keydown', callback);
      return () => {
          clearTimeout(getData)
          document.removeEventListener('keydown', callback);
      };

      }, [searchInputVal])



      useEffect(() => {
        let flag = true
        const callback = (event: KeyboardEvent) => {
            if(document.getElementsByClassName("fullscreen-bg-modal").length == 0){

            if (!(event.metaKey || event.ctrlKey) ) {

                event.stopPropagation()
               
                if(!window.location.href.includes("message") && document?.activeElement?.className.indexOf("ck") == -1 &&document?.activeElement?.tagName != "TEXTAREA" && document?.activeElement?.tagName != "INPUT" && ((95<event.keyCode && event.keyCode <106 )||(64<event.keyCode && event.keyCode <91 )||(47<event.keyCode && event.keyCode <58 ) )){
                  
                    refFocos.current.focus()
                    setFocos(true)
                }

                flag = false

            }
        }

            if(event.key == "Escape"){    

               // onChange && onChange(searchInputVal)
                setSearchInputVal("")
          
              }
            flag = true
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);





    return ( type === 'search-input' ?
        (<form id={timeID.current} className="search-bar">
            {label && <label>{trans(label)}</label>}
            <div className="search-bar__input-wrapper">
                <div className="search-bar__search-btn" >
                    {<FontAwesomeIcon icon={[ICON_TYPE, "search"]} flip={flipSearchIcon} />}
                </div>
                <input autoComplete="off"   defaultValue={defultSerach} onFocus={()=>{setIsFocos && setIsFocos(true)}} onBlur={()=>{setIsFocos && setIsFocos(false)}} ref={refFocos} id="GeneralSearchBox" autoFocus={autoFocus}   onMouseDown={(e) => e.stopPropagation()} value={searchInputVal ==null ?defultSerach:searchInputVal} onChange={changeSearchInputVal} className="search-bar__input form-control" type="text" placeholder={trans(`Search`)} />
                <div onClick={() => {setSearchInputVal("") ;}} className={`search-bar__del-search-filter ${(searchInputVal == null ||searchInputVal == "") ? "d-none" : ""}`}>
                    <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                </div>
            </div>
        </form> )
        :
       ( <form id={timeID.current} className="search-bar-icon">
            <div className="search-bar-icon__input-wrapper">
                <div className="search-bar-icon__search-btn" >
                    {<FontAwesomeIcon icon={[ICON_TYPE, "search"]} flip={flipSearchIcon} />}
                </div>
                <input autoComplete="off" autoFocus={autoFocus}  onFocus={()=>{setIsFocos && setIsFocos(true)}} onBlur={()=>{setIsFocos && setIsFocos(false) ;setSearchInputVal("") ;onChange && onChange("")}} onMouseDown={(e) => e.stopPropagation()}  value={searchInputVal ==null ?"":searchInputVal} onChange={changeSearchInputVal} className="search-bar-icon__input form-control" type="text" placeholder={trans(`Search`)} />
            </div>
        </form> )
       
    )
}