import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ICON_TYPE } from "utils/constants";
import "./style.scss"

interface ButtonArrowProp {
  onClick?: () => void;
  type: "backward" | "forward" | "fastBackward" | "fastForward";
}
export const ButtonArrow = ({ onClick, type }: ButtonArrowProp) => {
  return (
    <div className="ButtonArrow">
      {
        {
          backward: (
            
             <FontAwesomeIcon  style={{cursor:"pointer"}} icon={[ICON_TYPE , "arrow-left"]} size="2x" className="text-success my-2" onClick={ onClick} />    
          
          ),
         
          fastBackward: (
            <button className="btn_arrow px-2"  onClick={ onClick}>
              <span>»</span>
            </button>
          ),
          forward: (
          
              <FontAwesomeIcon style={{cursor:"pointer"}}  icon={[ICON_TYPE , "arrow-right"]} size="2x" className="text-danger my-2" onClick={ onClick} />
           
            
          ),
          fastForward: (
            <button className="btn_arrow px-2"  onClick={ onClick}>
              <span>«</span>
            </button>
          ),
        }[type]
      }
    </div>
  );
};
