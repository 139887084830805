
import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { REGEX } from "utils/regex";
import { getConversion } from "./api";

interface ConversionProps {
    name: string,
    resetForm?: boolean,
    defaultValue?: number,
    label?: string,
    mode?: string,
    Id?:string,
    required?: boolean,
}
export const Conversion = ({ name,Id, defaultValue, label,mode, resetForm , required}: ConversionProps) => {
    const [ConversionInputValue, setConversionInputValue] = useState<string>( "" );
    const [ConversionValue, setConversionValue] = useState<any>();

    const [MsgShow, setMsgShow] = useState<boolean>(false);
    let [MsgText , setMsgText] = useState<any>(false);
    const [currency , setCurrency] = useState<any>(0);
    const [defaultUnit , setdefaultUnit] = useState<any>(null);
    const [value , setValue] = useState<any>(1);
    const [textConversion , setTextConversion] = useState<any>();
    const inputConversionRef = useRef<any>(null);





    function updateConversionInput(numvalue) { 
        setValue(numvalue)



    }

   async function getConversionAsync(id) { 
        try {
            const res = await getConversion(id)
            setdefaultUnit(res.Data)
           
        } catch (error) {
             setdefaultUnit(null)
        }



    }





    useEffect(() => {
            updateConversionInput(defaultValue)  
    }, [defaultValue]);
    useEffect(() => {
        if (resetForm){
            setConversionInputValue("")
            setConversionValue(0)
        }
            
    }, [resetForm])
    useEffect(() => {
        if(Id)
            getConversionAsync(Id)
    }, [Id])
    return (
        <div className="Conversion">

            {label && <label className="select-box__label" htmlFor={name}>  
            {trans(label) }
            {defaultUnit&& ` ( ${defaultUnit} ) `}
            </label>}
            <input 
                 name={name} 
                style={{fontFamily : "revert"}}
                ref={inputConversionRef}
                required={required}
                //onMouseDown={(e)=>e.stopPropagation() }
                disabled={mode =="view" ? true : false}
                onChange={(event:any)=>{ updateConversionInput(event.target?.value)} }
                value={value}
                type="number"
                className={`action-form__Conversion-input form-control-custom  ${mode =="view" ?  " disabled" : ""}` }
                id={name}
              
            />
            {(defaultUnit&&value)&&
            <div>
                          معادل با {value} {defaultUnit}    
             </div>   
            }


        </div>
    );
}