import React from "react";
import { ApplicationDTO, ProfileListItemDTO, UserPosition } from "types/types";

interface LayoutContext {
  lang: 'fa' | 'en' | 'ar',
  setLang: (lang: any) => void,
  sidebarIsOpen: boolean;
  toggleSidebar: () => void;
  ImgFlag: string,
  handleImgFlag: (img: string) => void;
  currentUserPosition: any,
  notificount: number,
  chatNotificount: any,
  resourceVersion: any,
  widgetFormFlag: any,
  setCurrentUserPosition: (user: any) => void,
  setUserPerson: (id)=> void,
  setTabId: (id)=> void,
  tabId:any,
  UserPerson:any,
  setWidgetFormFlag: (count: boolean) => void,
  setNotificount: (count: number) => void,
  setChatNotificount: (count: any) => void,
  setResourceVersion: (count: any) => void,
  currentApplication: ApplicationDTO | any,
  setCurrentApplication: (application: ApplicationDTO | any) => void
  dropObj: any,
  dragObj:any,
  env:any,
  setEnv: (obj: any) => void,
  setDragObj: (obj: any) => void,
  setDropObj: (obj: any) => void,
  setBasket: () => void,
  basket:any,
  emptyBasket?:boolean,
  setEmptyBasket?:(bool) => void
}

export const LAYOUT_CONTEXT_INIT_STATE: LayoutContext = {
  lang: localStorage.getItem('lang') as any || 'fa',
  setLang: (lang: any) => { },
  sidebarIsOpen: true,
  toggleSidebar: () => { },
  handleImgFlag: (img: string) => { },
  ImgFlag: "",
  currentUserPosition: {
    Description: "", Id: "",
  },
  notificount: 0,
  chatNotificount: 0,
  resourceVersion: 0,
  widgetFormFlag: false,
  setCurrentUserPosition: (user: ProfileListItemDTO) => { },
  setUserPerson: (id) => { },
  setTabId: (id) => { },
  UserPerson:"" ,
  tabId:null ,
  setNotificount: (count: any) => { },
  setWidgetFormFlag: (count: any) => { },
  setChatNotificount: (count:any) => { },
  setResourceVersion: (count:any) => { },
  currentApplication: {
    Id: "", Name: ""
  },
  setCurrentApplication: (application: ApplicationDTO | any) => { },
  dropObj: {},
  dragObj:{},
  env:{},
  setEnv: (obj: any) => {},
  setDragObj: (obj: any) => {},
  setDropObj: (obj: any) => {},
  setBasket: () => {},
  basket:false,
  emptyBasket:true,
  setEmptyBasket:(bool) => {}
};

export const LayoutContext = React.createContext<LayoutContext>(
  LAYOUT_CONTEXT_INIT_STATE
);
