import { FileInput } from "components/FileInput/FileInput";
import { PersonContact, PersonLegalDTO, Persons } from "pages/OfficeAutomation/BaseInformation/PersonsPage/types";
import { useContext, useEffect, useRef, useState } from "react";
import { putPicture, setPersonAsync } from '../../../api/api';
import { Button } from "components/Button/Button";
import { Toast } from "components/ToastShow/ToastShow";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import {  getPerson } from "pages/OfficeAutomation/BaseInformation/PersonsPage/components/api/api";
import { PersonContactList } from "pages/OfficeAutomation/BaseInformation/PersonsPage/components/PersonContactList/PersonContactList";
import { trans } from "utils/helpers";
import "./style.scss";
import { ContactType } from "types/enum";
import { Address } from "components/Address/Address";
import { GUID_EMPTY } from "utils/constants";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { ENDPOINTS } from "utils/endpoints";

interface PersonLegalFormPropsType {

  handleCancelForm: (cancelBtn: boolean) => void
  idPerson?: any;
  showSaveClose?: any;
  ButtonInTop?: boolean;

  handleAddPerson: (ans: any) => void,
  mode?: string;
}
export const PersonLegalForm = ({
  showSaveClose=true,
  handleCancelForm,
  ButtonInTop,
  idPerson,
  handleAddPerson,
  mode
}: PersonLegalFormPropsType) => {
  let personLegalDTO: PersonLegalDTO | undefined, personData: Persons, personDataForSend, method;
  let personLegalDTOAddress;
  const [personResponse, setPersonResponse] = useState<Response | any>();

  const [personId, setPersonId] = useState<any>();
  const [resetForm, setResetForm] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [showModalContact, setShowModalContact] = useState<boolean>(false);
  const [personContactDataList, setPersonContactDataList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [save_Exit, setSave_Exit] = useState<boolean>(false);
  const [dataChange, setDataChange] = useState(false);
  const [userName, setUserName] = useState<any>("");
  const [userChanges, setUserChanges] = useState({});
  const [dataRecovered, setDataRecovered] = useState<any>({});

  const [Total_records, setTotal_records] = useState<number>(5);
  const formLegalName = useRef(null);



  const changeHandlePic = (e: any) => {
    if (e?.target?.files && e?.target?.files?.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };



  async function sendPersonAsync(method , _objData) {
    try{
       const res = await setPersonAsync(method , _objData);
      handleAddPerson(res.data)
      setResetForm(true);
      sessionStorage.setItem("changingforms" , "false");
      localStorage.removeItem("formsInChang")
      setUserChanges({})
      setDataRecovered({})
      if (save_Exit) {
        handleCancelForm(true);
      }
      if( mode =="edit" ){
        handleCancelForm(true);
      }
      setDataChange(false)

    }catch(err:any){


    }
  }

  async function sendpersonPictureAsync(id ,  _objData) {
    try {
      const res = await putPicture(id ,  _objData ,true);
  

    } catch (err: any) {
    }
  }



  function sendDataToServer(event) {
    event.preventDefault();
    let data = {
      PersonLegalTitleId: event.target.Title.value,
      LegalName: event.target.LegalName.value,
      LegalNameEN: event.target.LegalNameEN.value,
      RegistryNumber: event.target.RegistryNumber.value.trim()==""?null : event.target.RegistryNumber.value ,
      NationalIdentifier: event.target.NationalIdentifier.value,
      EconNo: event.target.EconNo.value,
      Description: event.target.Description.value,
      LegalType: +event.target.LegalType.value,
      ChiefOfficer: event.target.ChiefOfficer.value,
    };
    let address = (event.target.Address.value||event.target.Plaque.value|| event.target.Unit.value||event.target.PostalCode.value)?   JSON.stringify({ address: event.target.Address.value, plaque: event.target.Plaque.value, unit: event.target.Unit.value, PostalCode: event.target.PostalCode.value, Longitude: event.target.Longitude.value,Latitude: event.target.Latitude.value  }):null;
    let Contact:any=[]
    if(personContactDataList)
    personContactDataList.forEach(item => { if (item.Id != GUID_EMPTY) { Contact.push({ Value: item.Value, ContactType: +item.ContactTypeEnum, Description: item.Description }) } })

    let personInfo: (Persons|any) = {
      PersonLegalDTO: {
        PersonLegal: data,
        LegalType: +event.target.LegalType.key,
        Email: event.target.Email.value,
        Address: address,
        Phone: event.target.Phone.value,
        Mobile: event.target.Mobile.value

      },
      PersonContacts: Contact,
      UserName:event.target.UserName.value

    };
    if (personId) {
      personInfo.Id=personId
      personDataForSend = personInfo
      method = "put";
      var _objData = new FormData();
  
      _objData.append("PersonDTO", JSON.stringify(personDataForSend));
      if(selectedImage){
          let personPicture = new FormData();
          personPicture.append("personPicture", selectedImage);
          sendpersonPictureAsync( personResponse.data.Data.PersonLegalDTO.PersonLegal.Id, personPicture)
      }
      sendPersonAsync(method ,_objData )
    } else {
      personDataForSend = personInfo;
      method = "post";
      var _objData = new FormData();
      _objData.append("PersonDTO", JSON.stringify(personDataForSend));
      if(selectedImage){
          _objData.append("PersonPicture", selectedImage);
      }
      sendPersonAsync(method ,_objData )
    }

  }
  function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }
  
    async function getDataAsync(Id) {
      setLoading(false)
      setSelectedImage(null)
      
      try {
        const response = await getPerson(Id);
        setPersonResponse(response);  
      //  setSelectedImage(response.data.Data.PersonLegalDTO.PersonLegal?.PersonLegalPicture)
      let contact = response.data.Data?.PersonContacts.map(item => {
   
        return {
          Id: item.Id,
          ContactTypeEnum: item.ContactType ,
          Description: item.Description,
          ContactType: trans(ContactType[item.ContactType]) ,
          Value: item.Value
        }
      })
      setPersonContactDataList(contact);
      if(response.data.Data.PersonLegalDTO.PersonLegal?.PersonLegalPicture){
        let file = dataURLtoFile('data:image/png;base64,'+response.data.Data.PersonLegalDTO.PersonLegal?.PersonLegalPicture , "1.jpg")
        setSelectedImage(file)
  
      }
        sessionStorage.setItem("changingforms" , "false")
        localStorage.removeItem("formsInChang")
       } catch (error) {
         
       }

       setLoading(true)
    }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };
  
  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
      setSelectedImage(null)
    }
  }, [resetForm]);


  useEffect(() => {
    if(userChanges!=undefined&& Object.values(userChanges).filter((item)=>item!=null).length>0){
        sessionStorage.setItem("changingforms" , "true")
        
        let forms:any=JSON.parse(localStorage.getItem("formsInChang" )+"")
        forms ={...forms ,  "PersonLegal" :{...userChanges , Id:idPerson } }
        localStorage.setItem("formsInChang" , JSON.stringify(forms))
          window.addEventListener('beforeunload', handleBeforeUnload);
          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
    }else{
        if(userChanges!=undefined && Object.keys(userChanges).length >0){
          sessionStorage.setItem("changingforms" , "false")
          localStorage.removeItem("formsInChang")
          window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }

}, [userChanges]);

  useEffect(() => {
    if (sessionStorage.getItem("changingforms")=="true"&&localStorage.getItem("formsInChang")!=null){
      let forms = JSON.parse(localStorage.getItem("formsInChang")+"") 
      if(forms){
        setDataRecovered(forms["PersonLegal"])
        setUserChanges(forms["PersonLegal"])

      }
    }
    if (idPerson) {
      if(mode != "create"){
        if(dataChange){
          Toast(trans("modalclosemessage") , "warning" , "alert" , ()=>{setPersonId(idPerson);getDataAsync(idPerson)})
        }else{
          setPersonId(idPerson)
          getDataAsync(idPerson)
        }
      }
    }else if(mode == "create"){
      setPersonId(null)
      setResetForm(true);

    }
  }, [idPerson , mode]);
   
  if (idPerson && personResponse ) {
    personData = Object.assign({}, personResponse.data.Data);
    personLegalDTO = personData.PersonLegalDTO;
    try {
      if (typeof (personData.PersonLegalDTO?.Address) === "string")
      personLegalDTOAddress = JSON.parse(personData?.PersonLegalDTO?.Address)
    } catch {
      personLegalDTOAddress = personData.PersonLegalDTO?.Address
    }
  } else {
    personLegalDTO = undefined;
  }

  const personContactDataListCallback = (ans: PersonContact[]) => {
    if(ans.length!=personResponse?.PersonContacts?.length){

      setUserChanges({...userChanges , ...{PersonContact: ans}})
    }else{
      setUserChanges({...userChanges , ...{PersonContact: null}})
    }
    setPersonContactDataList(ans);
  }
  const handleBtnContact = () => {
    setShowModalContact(true);
    
  }

  return (
    <div className="col-lg-12 mb-1">
      <div className=" border-top-0">
        <div className="card-body">
          {
            loading ?

          <form  id="" ref={formLegalName} onSubmit={sendDataToServer}>
                   {ButtonInTop&&
                  <div className="col-lg-12 mb-1 btns_form">
                    {mode !== "view" ? <>
                      {(!personId&&showSaveClose) && <Button
                        btnType="submit"
                        value="Save&Close"
                        onClick={() => { setSave_Exit(true) }}
                      />}
                      <Button form="personRealform" value="Save" btnType="submit" />
                      <Button
                        btnType="cancel"
                        value="Cancel"
                        onClick={() => {
                          if(sessionStorage.getItem("changingforms")+""=="true"){
                            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                                sessionStorage.setItem("changingforms" , "false");
                                setUserChanges({});
                                handleCancelForm(true)
                            });
                        }else{
                          handleCancelForm(true)
                         };
                          
                        }}
                      />
                    </>
                      :
                      <Button
                        btnType="primary"
                        value="Close"
                        onClick={() => handleCancelForm(true)}
                      />
                    }

                  </div>
                  }
            <div className="card ">
            <div className="row p-3">
              
            <div className="col-lg-3  col-sm-4  mt-1">
              <DictionarySelectBox
              recordedData={personLegalDTO?.PersonLegal?.PersonLegalTitleId} 
              mode={mode} 
              Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
              value={dataRecovered?.Title ? dataRecovered?.Title  :personLegalDTO?.PersonLegal?.PersonLegalTitleId} 
              name="Title"
              label="Title"
              endPoint={ENDPOINTS.BaseInformation.PersonLegalTitle.dictionary}
               />
            </div>
            <div className="col-lg-3 col-sm-4 mt-1">
              <Textbox
                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                textboxType="text"
                textboxName="LegalName"
                labelText="LegalName"
                maxLength={100}
                required={true}
                recordedData={personLegalDTO?.PersonLegal?.LegalName}
                defaultValue={dataRecovered?.LegalName ? dataRecovered?.LegalName  :personLegalDTO?.PersonLegal?.LegalName}
                resetForm={resetForm}
                mode={mode}
              />
            </div>
            <div className="col-lg-3 col-sm-4 mt-1">
              <Textbox
                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                textboxType="text"
                textboxName="LegalNameEN"
                labelText="LegalNameEN"
                maxLength={100}
                fixedLanguage={"en"}
                recordedData={personLegalDTO?.PersonLegal?.LegalNameEN}
                defaultValue={dataRecovered?.LegalNameEN ? dataRecovered?.LegalNameEN  :personLegalDTO?.PersonLegal?.LegalNameEN}
                resetForm={resetForm}
                mode={mode}
              />
            </div>
            <div className="col-lg-3 col-sm-4 mt-1">
              <EnumerationTypeSelect
              recordedData={personLegalDTO?.PersonLegal?.LegalType}
              label="Legaltype"
              enumTypeSelect="Legaltype" 
              Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
              mode={mode} 
              value={dataRecovered?.LegalType ? dataRecovered?.LegalType  :personLegalDTO?.PersonLegal?.LegalType} 
              name="LegalType" 
              />
            </div>
            <div className="col-lg-3 col-sm-4 mt-1">
              <Textbox
                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                textboxType="nationalIdentifier"
                textboxName="NationalIdentifier"
                labelText="NationalIdentifier"
                maxLength={14}
                recordedData={personLegalDTO?.PersonLegal?.NationalIdentifier}
                defaultValue={dataRecovered?.NationalIdentifier ? dataRecovered?.NationalIdentifier  :personLegalDTO?.PersonLegal?.NationalIdentifier}
                resetForm={resetForm}
                mode={mode}
              />
            </div>
            <div className="col-lg-3 col-sm-4 mt-1">
              <Textbox
                Change={(e)=>{setUserChanges({...userChanges , ...e})}}    
                textboxType="econNo"
                textboxName="EconNo"
                labelText="EconNo"
                maxLength={25}
                recordedData={personLegalDTO?.PersonLegal?.EconNo}
                defaultValue={dataRecovered?.EconNo ? dataRecovered?.EconNo  :personLegalDTO?.PersonLegal?.EconNo}
                resetForm={resetForm}
                  mode={mode}
              />
            </div>
            <div className="col-lg-3 col-sm-4 mt-1">
              <Textbox
              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                textboxType="text"
                textboxName="ChiefOfficer"
                labelText="ChiefOfficer"
                recordedData={personLegalDTO?.PersonLegal?.ChiefOfficer}
                defaultValue={dataRecovered?.ChiefOfficer ? dataRecovered?.ChiefOfficer  :personLegalDTO?.PersonLegal?.ChiefOfficer}
                resetForm={resetForm}
                  mode={mode}
              />
            </div>
            <div className="col-lg-3 col-sm-4 mt-1">
              <Textbox
              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                textboxType="RegistryNumber"
                textboxName="RegistryNumber"
                labelText="RegistryNumber"
                recordedData={personLegalDTO?.PersonLegal?.RegistryNumber}
                defaultValue={dataRecovered?.RegistryNumber ? dataRecovered?.RegistryNumber  :personLegalDTO?.PersonLegal?.RegistryNumber}
                resetForm={resetForm}
                  mode={mode}
              />
            </div>
            <div style={{ height: "140px" }} className="col-12 mt-1 mb-5">
              <FileInput
              
                showBox={true}
                maxWidth={1000}
                maxHeight={1000}
                maxSize={10000000000}
                hideSrc={false}
                accept=".jpg,.png"
                resetForm={resetForm}
                mode={mode}
                lable="PersonLegalPicture"
                name="PersonLegalPicture"
                defultFileName="PersonLegalPicture.jpg"
                onChange={changeHandlePic}
                src={selectedImage ? URL.createObjectURL(selectedImage) :(personLegalDTO?.PersonLegal?.PersonLegalPicture?'data:image/png;base64,'+personLegalDTO.PersonLegal.PersonLegalPicture:'')}	
                />
            </div>
            <div className="col-lg-6 mt-1 mb-4">
              <Textbox
              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                textboxType="text"
                textboxName="Description"
                labelText="Description"
                maxLength={900}
                recordedData={personLegalDTO?.PersonLegal?.Description}
                defaultValue={dataRecovered?.Description ? dataRecovered?.Description  :personLegalDTO?.PersonLegal?.Description}
                resetForm={resetForm}
                  mode={mode}
              />
            </div>
              </div>
            </div>
            <div className="card p-3">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                    <>

                    <Address personContact={mode=="create"? null : personLegalDTOAddress } mode={mode}/>


                </>
                <div className="row">
                <div className="col-lg-3 col-sm-4 mt-1">
                  <Textbox
                  required={true}
                  Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                    textboxType="text"
                    textboxName="Phone"
                    labelText="Phone"
                    recordedData={personLegalDTO?.Phone}
                    defaultValue={dataRecovered?.Phone ? dataRecovered?.Phone  :personLegalDTO?.Phone}
                    resetForm={resetForm}
                      mode={mode}
                  />
                </div> 
                <div className="col-lg-3 col-xxl-2 col-sm-6 mt-1">
                  <Textbox
                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                    mode={mode}
                    textboxType="mobile"
                    textboxName="Mobile"
                    labelText="Mobile"
                    maxLength={11}
                    recordedData={personLegalDTO?.Mobile}
                    defaultValue={dataRecovered?.Mobile ? dataRecovered?.Mobile  :   personLegalDTO?.Mobile}
                    resetForm={resetForm}
                  />
                </div>
                <div className="col-lg-3 col-sm-6 mt-1 ">
                  <Textbox
                      mode={mode}
                      recordedData={personResponse?.data?.Data?.UserName}
                      defaultValue={dataRecovered?.UserName ? dataRecovered?.UserName  :personResponse?.data?.Data?.UserName}
                      Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                      onChange={(e)=>{setUserName(e.target.value)}}
                      textboxType="userName"
                      resetForm={resetForm}
                      textboxName="UserName"
                      labelText="UserName"
                  />
                </div>  
                <div className="col-lg-3 col-sm-4 mt-1">
                  <Textbox
                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                    required={userName.length > 0}
                    recordedData={personLegalDTO?.Email}
                    textboxType="text"
                    textboxName="Email"
                    labelText="Email"
                    defaultValue={dataRecovered?.Email ? dataRecovered?.Email  :personLegalDTO?.Email}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                </div>
                <div
                  className="col-lg-2 mt-lg-1 btn_contacts"
                  style={{ paddingTop: "21px",direction:"ltr" }}
                >
                  <Button
              
                    btnType="primary"
                    value="Contacts"
                    onClick={handleBtnContact}
                  />
                </div>
                  </div>
                </div>
              </div>
             </div>
            {!ButtonInTop&&
            <div className="col-lg-12 mt-1 btns_form">
            { mode !=="view" ? <>
                                {(!personId&&showSaveClose) && <Button
                  btnType="submit"
                  value="Save&Close"
                  onClick={()=>{setSave_Exit(true)}}
                />}
                <Button value="Save" btnType="submit" />
                <Button
                  btnType="cancel"
                  value="Cancel"
                  onClick={() =>{
                    if(sessionStorage.getItem("changingforms")+""=="true"){
                      Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                          sessionStorage.setItem("changingforms" , "false");
                          setUserChanges({});
                          handleCancelForm(true)
                      });
                  }else{
                    handleCancelForm(true)
                   };
                    
                }}
                />
                </>
                :

                 <Button
                  btnType="primary"
                  value="Close"
                  onClick={() => handleCancelForm(true)}
                />
                }
            </div> 
            }    
          </form>
            :<Loading/>
          }

        </div>
      </div>
      {showModalContact && 
      <ModalForm
        checkClose={false}
        mode={mode}
        onHide={() => setShowModalContact(false)}
        show={showModalContact}
        title="PersonContact"
      >
        <PersonContactList
              mode={mode}
              personId={personId}
              TotalRecords={Total_records}
              personContactDataListProp={dataRecovered?.PersonContact ? dataRecovered?.PersonContact  :personContactDataList}
              personContactDataListCallback={personContactDataListCallback}
        />
        <div className="modal-msg__btn-wrapper mt-2"></div>
      </ModalForm>
      }

    </div>
  );
};
