import { Button } from "components/Button/Button";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { getPostLevelIdApi, sendPostLevelApi } from "../api/api";
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";



interface PostLevelFormPropsType {
    selectedObj;
    showForm;
    mode;

}
export const PostLevelForm = ({ 
    selectedObj, 
    showForm,
    mode

}: PostLevelFormPropsType) => {
   
    const [resetForm, setResetForm] = useState(false);
    const [response, setResponse] = useState<any>();
    const [userChanges, setUserChanges] = useState({});
    const [loading,setLoading] = useState<any>(false)


    async function sendNodeAsync(data ,method) {
        try{
           const res = await sendPostLevelApi(data , method);
           sessionStorage.setItem("changingforms" , "false");
           setUserChanges({});
           showForm()
        }catch(err:any){

          }
        }


        function cancel() {
            if(sessionStorage.getItem("changingforms")+""=="true"){
                Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                    sessionStorage.setItem("changingforms" , "false");
                    setUserChanges({});
                    showForm()
                });
            }else{
                showForm()     
            };
        }
  
    async function getPostLevel() {
        setLoading(false)
        try{
            if(selectedObj.Id){
              const res = await getPostLevelIdApi(selectedObj.Id);
             setResponse(res.Data)    
            }else{
                setResponse(null) 
            }    
        }catch(err:any){

          }
        setLoading(true)
        }

    const handlePersonContactFormSubmit = (event) => {
        event.preventDefault();
        if(mode=="edit"){
            let data={
                    "Id": selectedObj.Id,
                    "Name": event.target.Name.value,
                    "ActivityStatus": +event.target.ActivityStatus.value,
                    "IsDefault": event.target.checkbox.checked,

            }
  
            sendNodeAsync(data,"put")
          }else{
            let data={
                "Name": event.target.Name.value,
                "ActivityStatus": 1,
                "IsDefault": event.target.checkbox.checked,

            }
            sendNodeAsync(data,"post")

        }
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);
  
    
     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);
    
     useEffect(() => {
        if(selectedObj && mode != "create"){
         getPostLevel()
        }else{
            setResponse(null)
            setLoading(true)
        }
        
      }, [selectedObj , mode ]);


    return (
        <div className="person-contact-form card">
            <div className="card-body">
                <div className="container-fluid">
                    {loading ? 
                    <form className="row " onSubmit={handlePersonContactFormSubmit}>
                    <div className="col-lg-4 mt-1">
                        <Textbox
                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                         required={true}
                         textboxType="text"
                         textboxName="Name"
                         labelText="Description"
                         maxLength={900}
                         defaultValue={response?.Name}
                         resetForm={resetForm}
                         mode={mode}
                        />
                    </div>
                    {mode != "create" && 
                      <div className="col-lg-6 mt-1" style={{fontSize:'12px'}}>
                        <EnumerationTypeSelect
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          required={true}
                          label="Status"
                          name="ActivityStatus"
                          value ={response?.ActivityStatus}
                          mode={mode}
                          enumTypeSelect="ActivityStatus"
                        />
                    </div>                      
                    }
                    <div className="col-lg-2 mt-4 mb-4" >
                       <CheckableButton
                          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          defaultChecked={response?.IsDefault}
                          defaultValue={response?.IsDefault}
                          labelText={"isdefault"}
                          inputName={"checkbox"}
                          type="checkbox"
                          mode={mode}
                       />                                
                    </div>    

                    {  mode !="view" ?
                        <div className="col-lg-12 mt-1 btns_form">
                        <Button value="Save" btnType="submit" />
                        <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                        </div>
                        :
                        <div className="col-lg-12 mt-1 btns_form">
                        <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                      </div>
                    }                        
                </form>
                    : 
                    <Loading/>}

                </div>
            </div>
        </div>
    );
}