import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_TYPE } from "utils/constants";
import { InputTypes } from "types/enum";




export const InputAvailable = [

    {
        typeName:'text',
        icon:["fas", "font"],
        type:InputTypes.text,
        backendType:"string",
    },
    {
        typeName:'number',
        icon:["fas", "hashtag"],
        type:InputTypes.text,
        backendType:"int",
    },
    {
        typeName:'textArea',
        icon:["fas", "align-justify"],
        type:InputTypes.text,
        backendType:"string",
    },
    {
        typeName:'date',
        icon:["fas", "calendar-days"],
        type:InputTypes.date,
        backendType:"datetime",
    },
    {
        typeName:'timeLength',
        icon:["fas", "stopwatch"],
        type:InputTypes.timeLength,
        backendType:"int",
    },
    {
        typeName:'time',
        icon:["fas", "clock"],
        type:InputTypes.time,
        backendType:"datetime",
    },
    { 
        typeName:'selectBox',
        icon:["fas", "square-caret-down"],
        type:InputTypes.dropDown,
        backendType:"string",
    },
    {
        typeName:'RadioButton',
        icon:["fas", "circle-dot"],
        type:InputTypes.RadioButton,
        backendType:"string",
    },
    {
        typeName:'checkBox',
        icon:["fas", "square-check"],
        type:InputTypes.checkBox,
        backendType:"bit",
    },
    // {
    //     typeName:'fileUpload',
    //     icon:"file-arrow-up",
    //     type:InputTypes.fileUpdate,
    //     backendType:"string",
    // },
    {
        typeName:'ProgressBar',
        icon:["fas", "sliders"],
        type:InputTypes.ProgressBar,
        backendType:"int",
    },
    {
        typeName:'price',
        icon:["fas", "money-bill"],
        type:InputTypes.price,
        backendType:"money",
    },
    {
        typeName:'emptySpace',
        icon:["fas", "border-none"],
        type:InputTypes.emptySpace,
        backendType:"",
    },
    {
        typeName:'Message',
        icon:["fas", 'info-circle'],
        type:InputTypes.Message,
        backendType:"",
    },
    {
        typeName:'PositionDictionary',
        icon:["fas", 'user'],
        type:InputTypes.PositionDictionary,
        backendType:"guid",
    },
    {
        typeName:'Step',
        icon:["fas", 'object-ungroup'],
        type:InputTypes.Step,
        backendType:"",
    }
    // {
    //     typeName:'label',
    //     icon:["fas", "heading"],
    //     type:InputTypes.label,
    //     backendType:"",
    // }
]
export const DictionaryAvailable = [

    {
        typeName:'Person',
        type:InputTypes.text,
        backendType:"guid",
    },
    {
        typeName:'Position',
        type:InputTypes.text,
        backendType:"guid",
    },
    {
        typeName:'Sex',
        type:InputTypes.dropDown,
        backendType:"int",
    },


]