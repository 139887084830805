import { Button } from "components/Button/Button";
import { ButtonArrow } from "components/ButtonArrow/ButtonArrow";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Search } from "components/Search/Search";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import {
  getCashAccountAccess,
  getGroupPositionList,
  getPositionList,
  sendCashAccountAccess,
} from "./components/api";

import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";

interface Form {
  Title?: string;
  assignmentMethod?: string;
  Status?: number;

  showForm: (e) => void;
  modalWidth?: any;
  ownerId?: any;
}

export const CashAccountAccesses: React.FC<Form> = ({
  showForm,
  modalWidth,
  ownerId,
}) => {
  const [mood, setMood] = useState("users");
  const [Moodassign, setMoodassign] = useState("users");
  const [flag, setFlag] = useState(false);
  const [response, setResponse] = useState<any>([]);
  const [assignmentResponse, setAssignmentResponse] = useState<any>([]);
  const [selectList, setSelectList] = useState<any>([]);
  const [assignedList, setAssignedList] = useState<any>([]);
  const [savedData, setSavedData] = useState<any>([]);
  const [assignedSelectList, setAssignedSelectList] = useState<any>([]);
  const [resetMultiSelect, setResetMultiSelect] = useState<any>(false);
  const [positionstr, setPositionstr] = useState<any>();
  const [positionAssignmentstr, setPositionAssignmentstr] = useState<any>();
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<
    { add: boolean; Delete: boolean; view: boolean; edit: boolean } | undefined
  >();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);
  const [loading2, setLoading2] = useState<any>(false);
  const [massageType, setMassageType] = useState<any>("info");
  const [massageType2, setMassageType2] = useState<any>("info");

  const breakPoint1 = modalWidth < 50 || width < 750;
  // const breakPoint2 =  modalWidth < 80 || width < 1100
  // const breakPoint3 =  modalWidth > 90 || width > 1100

  window.onresize = () => {
    setWidth(window.innerWidth);
  };

  let keys,
    Data: any = [],
    Dataassign: any = [];
  async function getPositionsListAsync(SearchString) {
    setLoading(false);
    try {
      const Positionresponse = await getPositionList(SearchString);
      let list: any = [];
      Positionresponse.Data.forEach((item) => {
        let obj: any = new Object();
        obj.Id = item.Id;
        obj.TU_RELATION = 1;
        obj.Title = item.Description;
        list.push(obj);
      });

      setResponse(list);
    } catch (error) {
      setMassageType("error");
    }
    setLoading(true);
  }
  async function getPositionsListSearchingAsync(SearchString) {
    /*  if(Moodassign=="groups"){
         const Groupresponse = await getGroupPositionList(SearchString); 
          let list:any=[]
         for (let x in Groupresponse) {
            list.push({Id:x ,TU_RELATION:2 ,Title:Groupresponse[x] }) 
          }
         
          setAssignmentResponse(list)
                   
        }else if(Moodassign=="users"){
         const Positionresponse = await getPositionList(SearchString);
         let list:any=[]
         for (let x in Positionresponse) {
        
            let obj:any =new Object
            obj.Id=x
            obj.TU_RELATION=1
            obj.Title=Positionresponse[x]
            list.push(obj) 
          }
          setAssignmentResponse(list)    
        }else */ {
      const Groupresponse = await getGroupPositionList(SearchString);
      let list2: any = [];

      for (let x in Groupresponse.Data) {
        let obj: any = new Object();
        obj.Id = x;
        obj.TU_RELATION = 2;
        obj.Title = Groupresponse.Data[x];
        list2.push(obj);
      }

      const Positionresponse = await getPositionList(SearchString);
      let list: any = [];
      Positionresponse.Data.forEach((item) => {
        let obj: any = new Object();
        obj.Id = item.Id;
        obj.TU_RELATION = 1;
        obj.Title = item.Description;
        list.push(obj);
      });

      setAssignmentResponse(list.concat(list2));
    }
  }

  async function SetWarehouseAccessAsync(data, warehouseId) {
    try {
      const response = await sendCashAccountAccess(data, "put", warehouseId);

      showForm(false);
    } catch (error) {}
  }

  async function getTaskUserAsync() {
    setLoading2(false);

    try {
      const TaskUserresponse = await getCashAccountAccess(ownerId);
      let makeData: any = [];

      if (TaskUserresponse) {
        TaskUserresponse.Data.map((item) => {
          makeData.push({
            Id: item.GroupPositionId ? item.GroupPositionId : item.PositionId,
            TU_RELATION: item.GroupPositionId ? 2 : 1,
            Title: item.Description,
          });
        });
        setSavedData(makeData);
        setAssignedList(makeData);
      }
    } catch (error) {
      setMassageType2("error");
    }
    setLoading2(true);
  }
  function hide() {
    showForm(false);
  }

  const getStatusFromModal = (data, formSubmitted) => {
    setModalData(data);
    setSubmitted(formSubmitted);
  };

  function backward() {
    setAssignedSelectList([]);
    setResetMultiSelect(!resetMultiSelect);

    const updatedData = selectList.map((item) => {
      return { ...item, ...modalData };
    });

    setAssignedList(assignedList.concat(updatedData));
    setFlag(!flag);
    setSelectList([]);
    setSubmitted(false);
  }

  function forward() {
    setResetMultiSelect(!resetMultiSelect);
    let list: any = [];
    assignedList.forEach((item1, index1) => {
      let flag = true;

      assignedSelectList.forEach((item2, index2) => {
        if (item2.Id == item1.Id) {
          flag = false;
        }
      });
      if (flag) {
        list.push(item1);
      }
    });

    setAssignedList(list);
  }

  const selectListCallBack = (list) => {
    setSelectList(list[1]);
  };

  const assignedselectListCallBack = (list) => {
    setAssignedSelectList(list[1]);
  };

  const referenceFormSubmit = (e) => {
    e.preventDefault();

    if (assignedList.length == 0) {
      Toast("msg_assigneduserscannotempty", "error");
    } else {
      e.preventDefault();
      let Data = assignedList.map((item) => {
        let obj = {
          [item.TU_RELATION == 1 ? "PositionId" : "GroupPositionId"]: item.Id,
        };
        return obj;
      });

      SetWarehouseAccessAsync(Data.length > 0 ? Data : [], ownerId);
    }
  };

  const searching = (e) => {
    setPositionstr(e == "" ? null : e);
    getPositionsListAsync(e == "" ? null : e);
  };

  const assignmentsearching = (e) => {
    setPositionAssignmentstr(e == "" ? null : e);
    getPositionsListSearchingAsync(e == "" ? null : e);
  };

  useEffect(() => {
    getPositionsListAsync(positionstr);
    getPositionsListSearchingAsync(positionAssignmentstr);
  }, [mood]);

  useEffect(() => {
    getTaskUserAsync();
  }, []);

  if (response && assignedList) {
    let assignmentdata: any = [];

    assignedList.forEach((elm) => {
      if (Moodassign == "groups") {
        if (elm.TU_RELATION == 2) assignmentdata.push(elm);
      } else if (Moodassign == "users") {
        if (elm.TU_RELATION == 1) assignmentdata.push(elm);
      } else {
        assignmentdata.push(elm);
      }
    });

    // assignmentResponse.forEach(item1 => {
    //   let flag =false

    //   assignmentdata.forEach(item2 => {
    //     if(item1.Id == item2.Id){
    //       flag = true
    //     }
    //   });

    //   if(flag){
    //     Dataassign.push(item1)
    //   }
    // });

    assignmentResponse.map((obj) => {
      const isSameId = assignmentdata.some((item) => item.Id === obj.Id);
      const currentItem = assignmentdata.find((item) => item.Id === obj.Id);

      if (currentItem) {
        const { add, view, edit, Delete } = currentItem;

        if (isSameId) {
          Dataassign.push({ ...obj, add, view, edit, Delete });
        }
      }
    });

    response.map((item1) => {
      let flag = true;
      assignedList.forEach((item2) => {
        if (item1.Id == item2.Id) {
          flag = false;
        }
      });
      if (flag) {
        Data.push(item1);
      }
    });
  }

  return (
    <>
      <form onSubmit={referenceFormSubmit}>
        <div style={{ border: "none" }} className="card">
          <div className="body">
            <div style={{ width: "100%", paddingBottom: "39px" }} className="">
              <FormWithTitle title="AvailablePositions">
                <div className="h-100">
                  <div className="d-flex justify-content-between align-items-center mb-1  ">
                    <Search onChange={searching} />
                  </div>
                  <div className="tableBoxInAccesses">
                    {loading ? (
                      <>
                        {response ? (
                          <DataGrid
                            theadData={["Id", "Title"]}
                            multiSelect={true}
                            parentName={"referencePosition"}
                            tbodyData={Data}
                            selectList={selectListCallBack}
                            minCellWidth={80}
                            resetMultiSelect={resetMultiSelect}
                            scrollHeight={"230px"}
                          />
                        ) : (
                          <Message
                            message={"NoDataForPreview"}
                            type={massageType}
                          />
                        )}
                      </>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </FormWithTitle>
            </div>

            <div className="col-lg-1 col-md-1 d-flex  flex-lg-column flex-md-column justify-content-center align-items-center">
              <ButtonArrow type="backward" onClick={backward} />
              <ButtonArrow
                type="forward"
                onClick={() => {
                  forward();
                }}
              />
            </div>

            <div style={{ width: "100%", paddingBottom: "39px" }} className="">
              <FormWithTitle title="AssignedPositions">
                <div className="h-100">
                  <div className="d-flex justify-content-between align-items-center mb-1 ">
                    <Search onChange={assignmentsearching} />
                  </div>
                  <div className="tableBoxInAccesses">
                    {loading2 ? (
                      <>
                        {Dataassign ? (
                          <DataGrid
                            theadData={["Id", "Title"]}
                            multiSelect={true}
                            parentName={"referencePositionAssigned"}
                            tbodyData={Dataassign}
                            selectList={assignedselectListCallBack}
                            minCellWidth={80}
                            resetMultiSelect={resetMultiSelect}
                            scrollHeight={"230px"}
                          />
                        ) : (
                          <Message
                            message={"NoDataForPreview"}
                            type={massageType2}
                          />
                        )}
                      </>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </FormWithTitle>
            </div>
          </div>
        </div>
        <div className="col-lg-12 mt-3 btns_form">
          <Button value="Save" btnType="submit" />
          <Button
            btnType="cancel"
            value="Cancel"
            onClick={() =>
              Toast(trans("modalclosemessage"), "warning", "alert", hide)
            }
          />
        </div>
      </form>
    </>
  );
};
