
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";



export const getLeaveDaysRemainsApi = (PositionId) => http({
    url: ENDPOINTS.cartable.dailyLeave.leaveDaysRemains,
    method: 'get',
    params:{
        PositionId
    }
}).then((response) => (response.data))
    .catch(err => {
        return Promise.reject(err);
    })



export const postDailyLeaveData = (data, processId) => http({
    method: "post",
    url: ENDPOINTS.cartable.dailyLeave.browse,
    data: data,
    params:{
        PRJ_UID : processId
    }
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});
export const putDailyLeaveData = (data ,referenceId) => http({
    method: "put",
    url: ENDPOINTS.cartable.dailyLeave.browse,
    data: data,
    params:{
        referenceId : referenceId
    }
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});


export const getDailyLeaveData = ( Id) => http({
    url: ENDPOINTS.cartable.dailyLeave.load,
    method: "get",
    headers: { "Accept-Language": "en" },
    params:{
        Id:Id
    }
    
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
});
export const getDailyLeaveDataByreferenceId = ( Id) => http({
    url: ENDPOINTS.cartable.dailyLeave.referenceId,
    method: "get",
    headers: { "Accept-Language": "en" },
    params:{
        referenceId:Id
    }
    
}).then((res) => {
  
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});