
import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getPosition = (positionId: any) => http({
    url: ENDPOINTS.BaseInformation.position.dictionary,
    method: 'get',
    params: {
        id: positionId
    }
}).then((response) => (response.data))
    .catch(err => {
        return Promise.reject(err.response);
    })


    
export const getRequest = (Id: any) => http({
    url: ENDPOINTS.clientPortal.customerServiceRequest.reference,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        referenceId: Id
    }
}).then((response) => (response.data))
    .catch(err => {
        return Promise.reject(err.response  );
    })
export const getRequestById = (Id: any) => http({
    url: ENDPOINTS.clientPortal.customerServiceRequest.load,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        Id: Id
    }
}).then((response) => (response.data))
.catch(err => {
    return Promise.reject(err.response  );
})


export const postServiceRequest = (data: any, method, PRJ_UID) => http({
    url: ENDPOINTS.clientPortal.customerServiceRequest.browse,
    method: method,
    data: data,
    params: {
        PRJ_UID: PRJ_UID
    }

}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const getMyOwnReqvfduest = (FormName) => http({
    url: ENDPOINTS.clientPortal.startUpProcess,
    method: 'get',
    params:{
        FormName:FormName
    }

}).then((response) => (response.data))
.catch(err => {
    return Promise.reject(err)
})

export const checkValidate = () => http({
    url: ENDPOINTS.clientPortal.clientPortal.validate,
    method: 'get',


}).then((response) => (response.data))
.catch(err => {
    return Promise.reject(err)
})

