import { Toast } from "components/ToastShow/ToastShow";
import { REPORT_URL } from "utils/constants";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getReport = (objectTag) => http({
    baseURL:REPORT_URL ,
    url: ENDPOINTS.reportST.report.dictionary,
    method: 'get',
     params: {
        objectTag: objectTag
     }
}).then((response) => (response.data))





export const  postReport =( async (data) =>  await http({
    baseURL:REPORT_URL ,
    url: ENDPOINTS.reportST.report.browsTemplate,
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
    Toast(err.response.data.Message , "error")
    return Promise.reject( err);
})
)


export const  removeReport =( async (Id) =>  await http({
    baseURL:REPORT_URL ,
    url: ENDPOINTS.reportST.report.brows,
    method: 'delete',
    params: {
        id:Id
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)
export const  setAsDefaultReport =( async (Id,objectTag) =>  await http({
    baseURL:REPORT_URL ,
    url: ENDPOINTS.reportST.report.setefault,
    method: 'put',
    params: {
        id:Id,
        objectTag:objectTag
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)
export const getApplications = () => http({
    url: ENDPOINTS.application.browse,
    method: 'get',

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});


