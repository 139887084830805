import { Button } from "components/Button/Button"
import { Loading } from "components/Loading/loading"
import { Textbox } from "components/Textbox/Textbox"
import { Toast } from "components/ToastShow/ToastShow"
import { useEffect, useState } from "react"
import { trans } from "utils/helpers"
import { getSubNode, sendNode } from "./api"
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect"


interface props {
    showForm: () => void
    selectedObj: any
    mode: any

}


export const WarehouseDetailForm = ({showForm, selectedObj, mode}: props) => {
    const [nodeData, setNodeData] = useState<any>()
    const [loading, setLoading] = useState<any>(false)
    const [userChanges, setUserChanges] = useState({});

    const getNodeDataAsync = async() => {
        setLoading(false)
        try {
            const res = await getSubNode(selectedObj.Id)
            setNodeData(res.Data)           
        } catch (error) {
            
        }
        setLoading(true)

    }

    const sendNodeDataAsync = async(method , data) => {
        try {
            const res = await sendNode(data ,method )
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
            showForm()       
        } catch (error) {
            
        }  

    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){
            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    function cancel() {
        if(sessionStorage.getItem("changingforms")=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                showForm()
            });
        }else{
            showForm()     
        };
    }


    const handleSubmit = (event) => {
        event.preventDefault()
        if(mode === 'create'){

            const data = {
                RootId: selectedObj?.Id ,  
                ParentId: null, 
                Description: event.target.Description.value,
                DescriptionEN: event.target.DescriptionEN.value,
                LevelLimitation: +event.target.LevelLimitation.value,
                Code: event.target?.Code?.value, 
            }         
            sendNodeDataAsync( "post", data)

        }else if (mode === 'edit'){
            const data = {
                Id: selectedObj.Id,
                ParentId: nodeData.ParentId, 
                RootId:  nodeData.RootId,  
                ActivityStatus: parseInt(event.target.ActivityStatus.value),  
                Description: event.target.Description.value,
                DescriptionEN: event.target.DescriptionEN.value,
                 LevelLimitation: event.target.LevelLimitation.value,
                Code: event.target?.Code?.value, 
            }
            sendNodeDataAsync("put" , data)
        }
    }  
    useEffect(()=>{
        if(selectedObj && mode != "create"){
            getNodeDataAsync()
        }else{
            setLoading(true)
        }
    },[])


    return (
        <div className="card">
            <div className="card-body">
                <div className="container-fluid">
                    {loading ?
                    <form className="row" onSubmit={handleSubmit}>
                        <div className="col-lg-4 mt-1">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="text"
                                textboxName="Description"
                                labelText="Description"
                                maxLength={900}
                                mode={mode}
                                required={true}
                                defaultValue={mode === 'edit' || mode === 'view' ? nodeData &&  nodeData?.Title || nodeData?.Description : ''}
                            />
                        </div>
                        <div className="col-lg-4 mt-1">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="text"
                                textboxName="DescriptionEN"
                                labelText="DescriptionEN"
                                maxLength={900}
                                mode={mode}
                                defaultValue={mode === 'edit' || mode === 'view' ? nodeData &&  nodeData?.Title || nodeData?.DescriptionEN : ''}
                            />
                        </div>
                        <div className="col-lg-4 mt-1">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                textboxType="wareHouseCode"
                                textboxName="Code"
                                labelText="Code"
                                maxLength={3}
                                mode={mode}
                                required={true}
                                defaultValue={mode === 'edit' || mode === 'view' ? nodeData &&  nodeData?.Code : ''}
                            />
                        </div>

                        <div className="col-lg-4 mt-1">
                            <Textbox
                                textboxType="number"
                                textboxName="LevelLimitation"
                                defaultValue={mode === 'edit' || mode === 'view' ? nodeData &&  nodeData?.LevelLimitation : ''}
                                labelText="LevelLimitation"
                                mode={mode}
                                />
                        </div>
                        {mode !="create"&& 
                        <div className="col-lg-4 mt-1">
                            <EnumerationTypeSelect 
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                name="ActivityStatus"
                                label="ActivityStatus"
                                enumTypeSelect="ActivityStatus"
                                value={mode === 'edit' || mode === 'view' ? nodeData &&  nodeData?.ActivityStatus : ''}
                                mode={mode}
                            />
                        </div>
                        }
                        {mode !=="view" ?
                            <div className="col-lg-12 mt-4 btns_form">
                                <Button value="Save" btnType="submit" />
                                <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                            </div>
                             :
                             <div className="col-lg-12 mt-1 btns_form">
                                <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                             </div>
                        }
                    </form>
                    :
                    <Loading/>
                }
                </div>
            </div>

        </div>
    )
}