import { InputLoad } from "components/InputLoading/inputLoading";
import { useEffect, useState } from "react";
import { getReturnSalesInvoiceNo } from "./api";
import { Textbox } from "components/Textbox/Textbox";


interface SalesReturnInvoiceNoTypeSelectProps {
    value?: any;
    mode?: string;
    docsClassificationId?: string;
    resetForm?: boolean;
    Change?: (e) => void;
    onChange?: (e: any , obj?:any) => void,
    disable?: boolean;

}
export const SalesReturnInvoiceNo = ({value,mode,disable  ,resetForm ,onChange ,docsClassificationId,Change }:SalesReturnInvoiceNoTypeSelectProps) => {
    const [SalesInvoiceNo, setSalesInvoiceNo] = useState<any>();
    const [defaultval, setdefault] = useState(value);
    const [errcode, setErrcode] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

  

    const getSalesInvoiceNoAsync = async ()=>{
        setLoading(false)
        try {
            
            const res = await getReturnSalesInvoiceNo(docsClassificationId)

        setSalesInvoiceNo(res.Data.DocNo)
        onChange&&onChange(res.Data.DocNo)
        } catch (error:any) {
            setErrcode(error.response.status)
            setSalesInvoiceNo(null)
        }
        setLoading(true)
      }



    useEffect(() => {
     
        if(value){onChange&&onChange(value)}

    }, []);
    useEffect(() => {
        getSalesInvoiceNoAsync()
        if(docsClassificationId){
    
        }else{
            setLoading(true)

        }
      }, [docsClassificationId]);

    useEffect(() => {
        setdefault(value)
    }, [resetForm]);
    

    return (
        <>
        {SalesInvoiceNo && loading ?
        <>
            <Textbox
             Change={(e)=>{Change&&Change(e)}}
             textboxType="text"
             textboxName="salesInvoiceNo"
             labelText="number"
             defaultValue={value ?value : SalesInvoiceNo}
             resetForm={resetForm}
             mode={mode }
             readonly={disable}

            />
                         
        </>
            :
            <InputLoad  msg={errcode} required={true}   labelText={"SalesInvoiceNo" }  onClick={getSalesInvoiceNoAsync}/>
        }
        </>
        

    );
};