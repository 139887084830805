import { useEffect, useState, useRef } from "react";
import { trans } from "utils/helpers";
import { getPersonApi } from "../api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface PersonPropsType {
    label?: string,
    hasBorder?: boolean,
    required?: boolean,
    value?:string,
    name:any,
    mode?:string,
    resetForm?:any,
    Change?: (e) => void,
    setPerson?: (e) => void,
}

export const Person = ({ label, name,hasBorder,value,mode ,required , resetForm ,Change , setPerson}: PersonPropsType) => {
    const [personsList, setPersonsList] = useState<any>();
    const [valueName, setValueName] = useState<any>();
    const [flag , setFlag] = useState(false);
    const [show , setShow] = useState(false);
    const [validat , setValidat] = useState(false);
    const [rquird , setRquird] = useState(false);
    const [count,setCount] = useState<number>(0);
    const inputRef = useRef<any>();
    const inputIdRef = useRef<any>();
    const PersonRef = useRef<any>(personsList);
    const validRef = useRef<any>(false);
    const countRef = useRef<any>(0)

    let optionList :any = []


    async function getPersonListAsync(search,id) {
        try{
           const res = await getPersonApi(search,id);
           if (id){
            setValeu(res[id] , id)
            setValueName(res[id])
            }
            setPersonsList({ ...res})     
            PersonRef.current = { ...res}
            setFlag(!flag)
        }catch(err:any){
        
        }
      }
    
    const Auto = (e)=>{
      validRef.current = false
      setValidat(false)
      setRquird(false)
      setShow(true)
      getPersonListAsync(e.target.value,"")
      setFlag(!flag)
    }

    function userSelecter(){
      setValeu(optionList[countRef.current],Object.keys(personsList)[countRef.current])
      setShow(false)
    }
    const kydown = (event) => {    

      if((event.key === "ArrowDown")){
        if(countRef.current < optionList.length-1){
          countRef.current = countRef.current + 1;
        }else{
          countRef.current = 0;
        }
      }
      
      if(event.key === "ArrowUp"){
        if(countRef.current > 0){
          countRef.current = countRef.current -1;
        }else{
          countRef.current = optionList.length-1;
        }
      }
      document.getElementById("personsListIndex"+countRef.current)?.scrollIntoView({behavior:"smooth"})
      setCount(countRef.current)
      if(event.key === "Enter"){
        userSelecter()
      }
    }
  

    const CheckPerson =async (e)=>{
      setTimeout(() => {     
        if(!validRef.current){          
          if(personsList!=undefined &&  Object.values(personsList).length == 1){
            setValeu(Object.values(personsList)[0] , Object.keys(personsList)[0])
            e.target.setCustomValidity("");
          }else{
            if(inputRef.current){
           setValeu(null,null)   
          }
          if(inputRef?.current?.value.length >0&&inputRef?.current?.value!=""){
             e.target.setCustomValidity(trans("Msg_Validation_ValueIsIncorrect"));
             setValidat(true);
             e.target.reportValidity();
           }
           setPerson && setPerson(null);
        }
        }
      }, 200);
      await delay(200)
      setShow(false)
    }
    const delay = (duration) =>
    new Promise(resolve => setTimeout(resolve, duration));

    const setValeu = (newValue , id)=>{
      if(inputIdRef != null && inputIdRef.current != null){
        validRef.current = true
        inputRef.current.value=newValue;
        inputIdRef.current.value=id;
        PersonRef.current = null;
        ((value==null &&id)|| id != value) ? Change&&Change({[name] :newValue}):Change&&Change({[name]:null});
  
        setPersonsList(null)
        setFlag(!flag)
        inputRef.current.setCustomValidity("")
        setPerson && setPerson(inputIdRef.current.value)
        setValidat(false)
      }
    }
    useEffect(() => {
       if(value){
        getPersonListAsync(null,value)

       }else if(value == null){
        setValueName(null)
       }
      }, [value]);

      useEffect(() =>{
        if(show ){
          document.addEventListener('keydown', kydown);
          document.body.addEventListener("click", clickToclose)
          
        } 
     return () => { document.removeEventListener("keydown", kydown);document.body.removeEventListener('click', clickToclose) }
    
        
      },[show ,optionList])
    
      function clickToclose(e){
        let input = document.querySelector("#browser"+name);
        if (e.target.id !== input?.id) { 
          setShow(false);
        }
      }
      

      if(personsList){
        optionList = Object.values(personsList)
      }
      return (
     <>
        <div className="text-box">
      <OverlayTrigger
             key={label}
             placement="top"
             delay={{"show" : 600 , "hide":0}}
             overlay={
                <Tooltip className="tooltip " id={`button-tooltip-${"reserve"}`}>
                {trans(label ? label :"ChoosePerson")}
                </Tooltip>
                }>
                 <div className="d-flex align-items-start" style={{height:'22px'}}>
                   <label htmlFor={`browser${name}`} className="text-box__label showDots"  style={{maxWidth:'95%'}}>{trans(label ? label :"ChoosePerson")}</label>
                   {required && <span className='text-box__required-sign-for-label text-danger me-1'>*</span>}
                 </div>
          </OverlayTrigger> 
        
     <input required={required} defaultValue={value && value} name={name} className="d-none" ref={inputIdRef} />
     <input required={required} onBlur={CheckPerson} defaultValue={valueName} autoComplete="off" onFocus={(e)=>{setShow(true);Auto(e)}}  ref={inputRef} list="PersonList" name="browser" className="text-box__box form-control "    id={"browser"+name} onMouseDown={(e)=>e.stopPropagation() } onChange={Auto} disabled={mode =="view" ? true : false} />
      {
        show && 
         <div tabIndex={-1} className={`text-box__sub-menu mt-1`} >
           {optionList && optionList.map((item , index)=>{
                  return  <div id={"personsListIndex"+index} className={`${count == index ?"text-box__sub-menu-option-active" :"text-box__sub-menu-option"}`} onClick={()=>{setValeu(item,Object.keys(personsList)[index])}} key={Object.keys(personsList)[index]} >{item}</div>
            })}  
         </div>
      }  
       <div className="text-box__msg-wrapper">
        {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
        {required && <span className={`${rquird ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsRequired", [""])}</span>}
        </div>    
        </div>
     </>
    )
}