
import { getBasketData, trans } from "utils/helpers";
import './style.scss'
import { IncreaserDecreaser } from "../ItemDetail/components/IncreaserDecreaser/IncreaserDecreaser";
import { ModalForm } from "components/ModalForm/ModalForm";
import { useContext, useEffect, useState } from "react";
import { GroupingPrice } from "components/GroupingPrice/GroupingPrice";
import { CustomerSalesOrder } from "./components/CustomerSalesOrder";
import { CartItem } from "./components/CartItem";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { Loading } from "components/Loading/loading";

export const CartPage2 = () => {
    const [showIncreaseDecrease,setShowIncreaseDecrease] = useState<any>(false)
    const [selectedIncreaserDecreaser,setSelectedIncreaserDecreaser] = useState<any>(null)
    const [basketData,setBasketData] = useState<any>([])
    const [loading,setLoading] = useState<any>(false)
    const [checkBoxId,setCheckBox] = useState<any>(null)
    const [showCustomerSalesOrder,setShowCustomerSalesOrder] = useState<boolean>(false)
    const [priceInfo,setPriceInfo] = useState<any>({itemsPrice:0,cartSubtotal:0,countBadge:0,Qty:0})

    const layoutContext = useContext(LayoutContext);


    const priceUpdate = ()=>{
        setLoading(false)
        let newItemsPrice =0
        let newCartSubtotal=0

        let basketData = getBasketData()
        setBasketData(basketData)
        let Qty = 0
        basketData.map(items => {
            Object.values(items.order).map((orders:any) =>{
                newItemsPrice = newItemsPrice + (+items?.ItemPriceListDetails[0]?.PriceDetails[0]?.UnitPrice * +orders.count)
                newCartSubtotal = newCartSubtotal +(( items?.ItemPriceListDetails[0]?.PriceDetails[orders.priceIndex]?.discountPrice != null ? +items?.ItemPriceListDetails[0]?.PriceDetails[orders.priceIndex]?.discountPrice.replaceAll(",","") : +items?.ItemPriceListDetails[0]?.PriceDetails[0]?.UnitPrice ) * +orders.count)
                Qty = Qty + 1 
                setPriceInfo({itemsPrice:newItemsPrice , cartSubtotal:newCartSubtotal , countBadge:orders.count , Qty:Qty}) 
            })
        })

        setLoading(true)
    }
    
  
    useEffect(()=>{
        priceUpdate()
    },[layoutContext.basket])

    return ( <>
            {
            showCustomerSalesOrder &&
            <ModalForm modalHeight={80} title="CustomerSalesOrder" onHide={() => { setShowCustomerSalesOrder(false) }} show={showCustomerSalesOrder}>
                <CustomerSalesOrder basketData={basketData} onHide={() => { setShowCustomerSalesOrder(false) }} mode="create"/>
            </ModalForm>
            }
            {
            showIncreaseDecrease &&(
            <ModalForm modalHeight={80} title={selectedIncreaserDecreaser.title} onHide={() => { setShowIncreaseDecrease(false) }} show={showIncreaseDecrease}>
                <IncreaserDecreaser onHide={()=>{setShowIncreaseDecrease(false) }} object={selectedIncreaserDecreaser} checkBoxHandle={setCheckBox}/>
            </ModalForm>
                )
            }
            {
            loading ? 
                <div className="cart w-100 position-relative userSelectNone">
                    <ul className="d-flex border-bottom border-2 my-2 p-1 pb-0">
                        <li className="d-flex position-relative mx-1 pb-2 h-100">
                            <div className="font-size-xl fw-bold">{trans('ShoppingCart')}</div>
                            <div className="me-2 d-flex justify-content-center align-items-center cart__Badge">{priceInfo.Qty}</div>
                            <div className="cart__underBadge position-absolute bottom-0 w-100"></div>
                        </li>
                    </ul>
                    <div className="row">
                        <div className="col-md-9 col-12">
                            <div>
                                <ul className="border glassBox cart__list">
                                <li className="d-md-none d-block p-4 pt-2 cart__Factor">
                                        <div className="my-2 cart__Factor__box d-flex justify-content-between">
                                            <div className="d-flex text-muted"><div>{trans('ItemsPrice')}</div><span className="cart__Factor__percent">({priceInfo.Qty})</span></div>
                                            <div><GroupingPrice value={priceInfo.itemsPrice}/></div>
                                        </div>
                                        <div className="cart__Factor__box d-flex justify-content-between my-2 text-customerPrimaryColor">
                                            <div>{trans('cartsaving')}</div>
                                            <div className="d-flex"><div className="cart__Factor__percent">(%{((priceInfo.itemsPrice - priceInfo.cartSubtotal)*100/priceInfo.itemsPrice).toFixed(2)})</div><span><GroupingPrice value={priceInfo.itemsPrice - priceInfo.cartSubtotal}/></span></div>
                                        </div>
                                        <div className="cart__Factor__box d-flex justify-content-between align-items-center" >
                                            <div>{trans('CartSubtotal')}</div>
                                            <div><GroupingPrice value={priceInfo.cartSubtotal}/></div>
                                        </div>   
                                    </li>
                                    {basketData.map((items)=>{
                                    return Object.values(items.order).map((orders:any,index)=>(
                                        <CartItem index={index} order={orders} item={items} handlePriceUpdate={priceUpdate} handleSelectedIncreaserDecreaser={(e)=>setSelectedIncreaserDecreaser(e)} handleShowIncreaseDecrease={(e)=>setShowIncreaseDecrease(true)}/>
                                        )
                                    )
                                    })}
                                </ul> 
                            </div>
                        </div>
                        {/* desktop mode */}
                        <div className="col-3 d-md-block d-none">
                            <div className="glassBox p-4 w-100" style={{borderRadius:'4px'}}>
                                <ul>
                                    <li className="cart__Factor__box d-lg-flex justify-content-between my-2">
                                        <div className="d-flex text-muted"><div>{trans('ItemsPrice')}</div><span className="cart__Factor__percent">({priceInfo.Qty})</span></div>
                                        <div><GroupingPrice value={priceInfo.itemsPrice} styleClass="text-muted"/></div>
                                    </li>
                                    <li className="cart__Factor__box d-lg-flex justify-content-between my-2">
                                        <div>{trans('CartSubtotal')}</div>
                                        <div><GroupingPrice value={priceInfo.cartSubtotal}/></div>
                                    </li>
                                    <li className="cart__Factor__box d-lg-flex justify-content-between my-2 text-customerPrimaryColor">
                                        <div>{trans('cartsaving')}</div>
                                        <div className="d-flex"><div className="cart__Factor__percent">(%{((priceInfo.itemsPrice - priceInfo.cartSubtotal)*100/priceInfo.itemsPrice).toFixed(2)})</div><GroupingPrice value={priceInfo.itemsPrice - priceInfo.cartSubtotal}/></div>
                                    </li>
                                </ul>
                                <button onClick={()=>setShowCustomerSalesOrder(true)} className="w-100 btn btn-customerPrimaryColor bg-customerPrimaryColor mt-3">{trans('Checkout')}</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-md-none d-flex my-2 glassBox align-items-center justify-content-between px-2 py-3 border border-2 cart__footer">
                        <div className="d-flex w-50 align-items-center justify-sm-content-center ">
                            <button onClick={()=>setShowCustomerSalesOrder(true)} className="btn w-100 btn-customerPrimaryColor bg-customerPrimaryColor mt-2">{trans('Checkout')}</button>
                        </div>
                        <div className="d-flex flex-column align-items-end cart__footer__fontSize-lg">
                            <div>{trans('CartSubtotal')}</div>
                            <GroupingPrice value={priceInfo.cartSubtotal}/>
                        </div>
                    </div>
                </div>
                :
                <Loading/>               
            }

    </> );
}
