import { Button } from "components/Button/Button";
import DatePicker from "components/DatePicker/DatePicker";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { getPersonalPlanning, postPersonalPlanning, putPersonalPlanning } from "../api";
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";

interface JobFormPropsType {
  onHide: (e:boolean) => void;
  handleCancelForm?: (ans: boolean) => void;
  handleShowReferenceStepper?: (taskId: string) => void;
  taskId?: string;
  processId?: string;
  mode?;

}
export const PersonalPlaningForm = ({
  handleCancelForm,
  taskId,
  processId,
  mode,
  onHide,
  handleShowReferenceStepper,
}: JobFormPropsType) => {

  const [showReferenceStepper, setShowReferenceStepper] = useState<boolean>(false);
  const [personalTaskDate, setPersonalTaskDate] = useState<string>(new Date().toISOString());
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const layoutContext = useContext(LayoutContext);
  const [response, setResponse] = useState<any>();
  const [userChanges, setUserChanges] = useState({});


  async function getJobAsync() {
    setLoading(false);
    try {
      const res = await getPersonalPlanning(taskId);
      let response = res.Data
      setPersonalTaskDate(response.PersonalTaskDate)
      setResponse(response );
      
    } catch (error) { }
    setLoading(true);
 
  }


  useEffect(() => {
   
    if (taskId &&taskId != "create" ){ getJobAsync();}else setLoading(true)
  }, [taskId]);

  const handleJobFormSubmit = (event) => {
    event.preventDefault();


    if (mode == "edit") {
      let data = {
            Id: taskId,
            Description: event.target.Description.value,
            personalTaskDate: personalTaskDate,
            PersonId:layoutContext.UserPerson.PersonId,
            Priority: +event.target.priority.value,
            PersonalTaskStatus: +event.target.PersonalTaskStatus.value,

      };
      sendDataAsync(data, "put");
    } else {
      let data = {
            Description: event.target.Description.value,
            personalTaskDate: personalTaskDate,
            PersonId:layoutContext.UserPerson.PersonId,
            Priority: +event.target.priority.value,
 
      };
      sendDataAsync(data, "post");
    }
  };

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };


useEffect(() => {
    if(Object.values(userChanges).filter((item)=>item!=null).length>0){

        sessionStorage.setItem("changingforms" , "true")
          window.addEventListener('beforeunload', handleBeforeUnload);
          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
    }else{
        sessionStorage.setItem("changingforms" , "false")
        window.removeEventListener('beforeunload', handleBeforeUnload);

    }
}, [userChanges]);

  function cancel() {
    if(sessionStorage.getItem("changingforms")+""=="true"){
        Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
            onHide(false)
        });
    }else{
      onHide(false)    
    };


}

  async function sendDataAsync(data, method) {
    try {
      let res;
     if (method === "post") res = await postPersonalPlanning(data);
     else res = await putPersonalPlanning(data);
      sessionStorage.setItem("changingforms" , "false");
      setUserChanges({});
      onHide(true);
    } catch (err: any) { }
  }

  return (

  <div className="card">
    <div className="card-body">
      {loading ? (
    <form action="" onSubmit={handleJobFormSubmit} className="p-2">
      <div className="row my-1">
        <div className="col-12 my-1 my-sm-0 col-sm-4">
             <DatePicker
               Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                required={true}
                resetForm={resetForm}
                withTime ={true} 
                lable="PersonalTaskDate"
                setIsoTime={(isoTime: string) => {
                  setPersonalTaskDate(isoTime);
                }}
                val={ response?.PersonalTaskDate}
                mode={mode}
              />
        </div>
        <div className="col-12 my-1 my-sm-0 col-sm-4">
          <EnumerationTypeSelect
            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
            name='priority'
            label="priority"
            enumTypeSelect="priority"
            value={response?.Priority}
            mode={mode}
          />
        </div>
        {mode !="create"&&
        <div className="col-12 my-1 my-sm-0 col-sm-4">
          <EnumerationTypeSelect
            label="PersonalTaskStatus"
            name='PersonalTaskStatus'
            enumTypeSelect="personalTaskStatus"
            value={response?.PersonalTaskStatus}
            mode={mode}
          />
        
        </div>
        }
      </div>
      <div className="row my-1">

        <div className="col-12 my-1 my-sm-0 col-sm-8">
          <Textbox
            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
            textboxType="textarea"
            textboxName="Description"
            resetForm={resetForm}
            labelText="Description"
            defaultValue={response?.Description}
            mode={mode}
          />
        </div>


    </div>
          {mode != "view" ? (
            <div className="d-flex justify-content-end">
              <Button btnType="submit" value="save" />
              {handleShowReferenceStepper && (
                <Button
                  btnType="submit"
                  value="saveAndReference"
                  onClick={() => setShowReferenceStepper(true)}
                />
              )}
              <Button
                btnType="cancel"
                value="cancel"
                onClick={() => { cancel()}}
              />
            </div>
          ) : (
            <div className="col-4 d-flex justify-content-end w-100">
              <Button
                    btnType="primary"
                    value="Close"
                    onClick={() => cancel()}
                  />
            </div>
          )}
        </form>
      ) : (
        <Loading />
      )}
    </div>
  </div>

  );
};
