import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getAllDeviceProperties = (async (pageNum, pageSize , searchString, orderBy ,filterlist) => await http({
    url: ENDPOINTS.afterSalesApi.deviceProperty.browse,
    method: 'get',
    params: {
        searchString: searchString,
        OrderByString: orderBy,
        FilterString:filterlist,
        PageSize: pageSize,
        PageNumber: pageNum,
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
})
)


export const postDeviceProperties = (data) => http({
    url: ENDPOINTS.afterSalesApi.deviceProperty.browse,
    method: 'post',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})



export const putDeviceProperties = (data) => http({
    url: ENDPOINTS.afterSalesApi.deviceProperty.browse,
    method: 'put',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

export const deleteDevicePropertie = (id) => http({
    url: ENDPOINTS.afterSalesApi.deviceProperty.browse,
    method: 'delete',
    params: {
        id: id
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})


export const getProperty = (id) => http({
    url: ENDPOINTS.afterSalesApi.deviceProperty.load,
    method: 'get',
    params: {
        Id: id
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})


