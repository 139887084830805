import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getPerson = (async (search) => await http({
    url: ENDPOINTS.BaseInformation.person.dictionary,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        SearchString: search,
    }
})
    .then((respons) => (respons.data.Data))
)



export const PostReminder = (async (data) => await http({
    url: ENDPOINTS.calendar.reminder.browse,
    method: 'post',
    data: data


})
.then(response => response.data)
    .catch(err => {
        return Promise.reject(err);
    }
    ))
