import { trans } from "utils/helpers";

interface GroupingPriceProps {
    value:any,
    styleClassValue?:string,
    styleClassDescription?:string,
    styleClass?:string,
    description?:string,
}

export const GroupingPrice = ({value,styleClass,styleClassDescription,styleClassValue,description}:GroupingPriceProps) => {

    return ( 
        <div className={`d-flex ${styleClass}`}>
            {description &&
            <div className={styleClassDescription}>
                {trans(description)} :
            </div>
            }
            <div className={styleClassValue}>
                {value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
        </div>
     );
}