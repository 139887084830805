import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_TYPE } from "utils/constants";
import "./style.scss";
export const LoadingIcon = () => {
  return (
    
    <div className=" loadingIcon" >  
        <FontAwesomeIcon icon={[ICON_TYPE , "sync"] } className={` text-primary Rotate `}  />  
    </div> 

  );
};
