import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { useEffect, useState } from "react";
import { ItemMainPropertyValuesList } from "./ItemMainPropertyValuesList";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";


interface ItemMainPropertyVariationPropsType {
    ItemMainPropertyId?: any,
    ItemMainPropertyDescription?: any,
    SelectedItemMainPropertyValues?: (data) => void;
    mode?: any,
}

export const ItemMainPropertyVariation = ({
    ItemMainPropertyId,
    ItemMainPropertyDescription,
    SelectedItemMainPropertyValues,
    mode,
}: ItemMainPropertyVariationPropsType) => {


    const [reset, setReset] = useState(false);

    const [showItemMainPropertyValuesList,setShowItemMainPropertyValuesList] = useState<any>()
    const [ItemLevelSpecificationslist, setItemLevelSpecificationslist] = useState<any>([]);
    const [selectList , setSelectList] = useState<any>([])

    const [ItemItemLevelSpecificationsId, setItemItemLevelSpecificationsId] = useState<string | any>(null);

    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)

    const selectedRowCallback = (obj, index) => {
        setItemItemLevelSpecificationsId(obj?.Id);
    };
    const selectListCallBack =(list)=>{
        setSelectList(list[1])
      }


    useEffect(() => {
        if ( mode === "create"){
            setReset(true);
        }
    }, [ mode]);

    useEffect(() => {
        if (reset) {
            setReset(false);
        }

    }, [reset]);
    useEffect(() => {
        if (ItemMainPropertyId) {
            setReset(false);
        }

    }, [ItemMainPropertyId]);
    useEffect(() => {
        if (ItemLevelSpecificationslist) {
            SelectedItemMainPropertyValues&& SelectedItemMainPropertyValues(ItemLevelSpecificationslist);
        }

    }, [ItemLevelSpecificationslist]);




    return (
    <>
        {showItemMainPropertyValuesList &&
        <ModalForm modalHeight={70} mode={mode} title="ItemMainPropertyValuesList" onHide={() => setShowItemMainPropertyValuesList(null)} show={showItemMainPropertyValuesList}>
           <ItemMainPropertyValuesList ItemMainPropertyId={ItemMainPropertyId}  PropertiesListcallback={(e)=>{setItemLevelSpecificationslist(e)}}  onHide={() => setShowItemMainPropertyValuesList(null)} />
        </ModalForm>
         }
         <div className="">
            <FormWithTitle title={ItemMainPropertyDescription}>
            <div className="card-body">
            <div className="col-lg-12 mt-1 btns_form">
                <Button btnType="primary" value="select" onClick={()=>{setShowItemMainPropertyValuesList(true)}} />
             </div>   
            {
                (ItemLevelSpecificationslist?.length > 0 ?
                    <DataGrid
                        parentName={"ItemLevelSpecifications" }
                        startIndex={2}
                        theadData={Object.keys(ItemLevelSpecificationslist[0])}
                        scrollHeight={"350px"}
                        tbodyData={ItemLevelSpecificationslist}
                        minCellWidth={80}
                        selectedRowObjectCallback={selectedRowCallback}
                    />
                    : <Message message={"NoDataForPreview"} type={"info"} />)
                }
            </div> 
            </FormWithTitle>                   
        </div> 

    </>
    );
}

