import { MEETINGMANAGEMENT_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSMEETINGMANAGEMENT } from "utils/endpoints";
import { http } from "utils/http";

export const getMeetingPlaceList = (async (searchString, orderBy,pageNum ,pageSize) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.BaseDefinitionAndSettingsApi.MeetingPlace.list,
    method: 'get',
    params: {
        searchString: searchString,
        orderBy: orderBy,
        PageSize: pageSize,
        PageNumber: pageNum,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
})

)


export const getMeetingPlace = (async (id) => await http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.BaseDefinitionAndSettingsApi.MeetingPlace.load,
    method: 'get',
    params: {Id : id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)


export const deleteMeetingPlace =  (id) =>  http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.BaseDefinitionAndSettingsApi.MeetingPlace.browse,
    method: 'delete',
    params: {Id : id}

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
})


export const postMeetingPlace = (data) => http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.BaseDefinitionAndSettingsApi.MeetingPlace.browse,
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const putMeetingPlace = (data) => http({
    baseURL:MEETINGMANAGEMENT_URL,
    url: ENDPOINTSMEETINGMANAGEMENT.MeetingApis.BaseDefinitionAndSettingsApi.MeetingPlace.browse,
    method: 'put',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
    return Promise.reject( err);
})

