
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getMyRequests = (pageNumber: number, pageSize: number, searchString, orderBy, filterlist, processId,jobStatus  ) => http({
    url: ENDPOINTS.cartable.myRequests,
    method: 'get',
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString: searchString,
        OrderByString: orderBy,
        FilterString: filterlist,
        processId:processId,
        jobStatus:jobStatus
 
   
    }
}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});




export const getViewForm = (processId: any, selectedPosition?: any) => http({
    url: ENDPOINTS.bpmnApi.process.getViewForm,
    method: 'get',
    params: {
        processId: processId,
    }
}).then((response) => (response.data))
    .catch(err => {
        return Promise.reject(err);
    })