

import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";

import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { deleteDevicePropertie, getAllDeviceProperties, getProperty, postDeviceProperties, putDeviceProperties } from "./api";
import { KeyWord } from "components/KeyWord/KeyWord";
import DatePicker from "components/DatePicker/DatePicker";

import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { DateObject } from "react-multi-date-picker";
import { Time } from "components/Time/Time";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
interface Form {
    Title: string,
    Status: number
}
export const DevicePropertiesSetting = ({ }: Form) => {


    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [formLoading,setFormLoading] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [propertyType, setPropertyType] = useState(1);
    const [showForm, setShowForm] = useState(false);
    const [title, setTitle] = useState<any>();
    const [keyWords, setKeyWords] = useState<any>([]);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [filterlist, setFilterlist] = useState<any>("");
    const [fromDate, setFromDate] = useState<any>(null);
    const [pages, setPages] = useState<number>(0);
    const [userChanges, setUserChanges] = useState({});



    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("DevicePropertiesSetting" ,e.currentTarget.value )
        getAllDevicePropertiesAsync(1)
      };


      function handleGoPage(value): void {
        if (value) {
            if (value < 1 || value > pages) { }
            else {setPageNum(+value);getAllDevicePropertiesAsync(+value)}
        } else {
        }
    }
    
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getAllDevicePropertiesAsync(pageNum + 1);
          };
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getAllDevicePropertiesAsync(pageNum - 1);
          };
    };

    const searchHandler = (str) => {
        setPageNum(1);
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }
    const handleCreateAction = () => {
        setKeyWords([])
        setPropertyType(1)
        setTitle(null)
        setUserChanges({})
        setEditForm(null)
        setMode("create")
        setShowForm(true)

    }
    const handleEditRow = () => {
        getDeviceProperties()
        setMode("edit");
        setShowForm(true)

       
    }
    const handleDeleteRow =async (id: string =selectedObj.Id) => {
        try {
            const res = await deleteDevicePropertie(id)
            setForceUpdate(!forceUpdate)

        } catch (error) {
            
        }
    }

    const handleViewRow = () => {
        getDeviceProperties()
        setMode("view")
        setShowForm(true)

      
    }

    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false"); setUserChanges({}) });
        }else{
            setSelectedObj(obj)         
        };
    }



    async function getAllDevicePropertiesAsync(PageNum =pageNum) {
        setLoading(false)
        let pageSizeChangedByUser = getPageSize("DevicePropertiesSetting" )
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getAllDeviceProperties(PageNum,  pageSizeChangedByUser  ,search ,orderBy.length == 0 ?null:  orderBy.join(",") ,filterlist);
            setResponse(res.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) 
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)
    }

    const submit = (e) => {
        e.preventDefault();
        let DefaultValue:any = ""
        
        const date = new DateObject(fromDate).convert(persian, persian_fa)
        switch (+propertyType) {
            case 1 : DefaultValue= e.target.DefaultValue.value ; break;
            case 2 : DefaultValue= e.target.DefaultValue.value   ; break;
            case 3 : DefaultValue= keyWords.join(",") ; break;
            case 4 : DefaultValue= e.target.DefaultValue.checked+"" ; break;
            case 6 : DefaultValue=date.format( ) ; break;
            case 7 : DefaultValue= e.target.DefaultValue.value ; break;
        }
        if(propertyType==3 && keyWords.length ==0){
            Toast("Msg_Validation_ValueIsRequired" ,"warning")
        }else{
            if (editForm == null) {
                postDeviceProperties({   
                    PropertyName: e.target.PropertyName.value,
                    DefaultValue:DefaultValue, 
                    PropertyType: +e.target.PropertyType.value
                }).then(()=>{ sessionStorage.setItem("changingforms" , "false");setUserChanges({});getAllDevicePropertiesAsync();cancel() });
            } else {
                putDeviceProperties({
                    PropertyName: e.target.PropertyName.value,
                    DefaultValue:DefaultValue, 
                    PropertyType: +e.target.PropertyType.value, 
                    ActivityStatus: parseInt(e.target.ActivityStatus.value),
                    Id: selectedObj.Id
                }).then(() => { sessionStorage.setItem("changingforms" , "false");setUserChanges({});getAllDevicePropertiesAsync();cancel() })
            }
        }
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                setEditForm(null);
                setShowForm(false)
            });
        }else{
            setEditForm(null);
            setShowForm(false)     
        };

    }

    async function getDeviceProperties() {
        setFormLoading(false)
        try{
            const data = await getProperty(selectedObj.Id);
            setEditForm({
                PropertyName: data.Data.PropertyName,
                DefaultValue: data.Data.DefaultValue, 
                PropertyType: data.Data.PropertyType, 
                ActivityStatus: data.Data.ActivityStatus, 
                Id: data.Data.Id,
            });
            if(data.Data.PropertyType==3){
                setKeyWords(data.Data.DefaultValue.split(","))
            }
            setPropertyType(data.Data.PropertyType)
            setTitle(data.Data.PropertyName)
        }catch{
        }
        setFormLoading(true)
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };

    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){
            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        getAllDevicePropertiesAsync();
    }, [forceUpdate ,search , orderBy , filterlist , pageNum]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
            getAllDevicePropertiesAsync();
        }
    }, [resetForm]);
    useEffect(() => {
        if(showForm &&selectedObj && mode != "create"){
            setEditForm(null);
           getDeviceProperties()
        }else{
            if(showForm) setFormLoading(true)
        }
       
    }, [selectedObj ,showForm]);
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("DevicePropertiesSetting"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id) handleEditRow(); break;
            case "help": alert("call api help"); break;
            case "remove":if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow); break;
        }
    };
    return (
        <>
            <div className="cartable-action">

                <Toolbar handleSearch={searchHandler} remove={true}  id={selectedObj?.Id} emptyList={response?.TotalRecords == 0} create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                    <div className="">
                            <>
                                {showForm&& 
                                <>
                                 <div className="card mb-4">
                                {formLoading ?
                                
                                    <form className="TypeSettingForm row card-body " onSubmit={submit}>
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} onChange={(e)=>{setTitle(e.target.value) }} required={true} textboxName={"PropertyName"} textboxType="text" resetForm={resetForm} labelText={"PropertyName"} mode={mode}  defaultValue={editForm?.PropertyName} />
                                        </div>     
                                         <div className="col-lg-4 ">
                                            <EnumerationTypeSelect
                                             Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                                             required={true} 
                                             onChange={(e)=>{setPropertyType(e)}} 
                                             mode={mode} 
                                             name="PropertyType" 
                                             label="PropertyType"
                                             enumTypeSelect="PropertyType"
                                             value={editForm?.PropertyType} />
                                         </div>                            
                                        <div className="col-md-4 col-sm-12">
                                        {
                                            {
                                                1: (
                                                     <Textbox
                                                         Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                                         required={true}
                                                         defaultValue={editForm?.DefaultValue}
                                                         textboxType="text"
                                                         textboxName="DefaultValue"
                                                         labelText={ title ? title :"text"}
                                                         resetForm={resetForm}
                                                         mode={mode}
                                                         
                                                         />
                                                         ),
                                                         2: (
                                                             <Textbox
                                                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                                           required={true}
                                                           mode={mode}
                                                           defaultValue={editForm?.DefaultValue}
                                                           textboxType="number"
                                                           textboxName="DefaultValue"
                                                           labelText={ title ? title :"number"}
                                                           resetForm={resetForm}
                                                        />
                                                        ),
                                                 3: (
                                                    <KeyWord
                                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                                    required={true}
                                                    label={ title ? title :"List"}
                                                    setKeyWords={setKeyWords}
                                                    keyWords={keyWords}
                                                    recordedData={editForm?.DefaultValue.split(",")}
                                                    mode={mode}
                                                    name="DefaultValue"
                                                   />
                                                 ),
                                                 4: (
                                                    <div className="mt-4">
                                                        <CheckableButton
                                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                                        defaultChecked={editForm?.DefaultValue == "true"}
                                                        defaultValue={editForm?.DefaultValue == "true"}
                                                        labelText={ title ? title :"checkbox"}
                                                        inputName={"DefaultValue"}
                                                        type="checkbox"
                                                        mode={mode}
                                                     />   
                                                    </div>
                                                 ),
                                                 6: (
                                                    <DatePicker
                                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                                    mode={mode}
                                                    resetForm={resetForm}
                                                    lable={ title ? title :"Date"}
                                                    setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                                                    val={  null} //resetForm ? new Date().toISOString() : 
                                                  />
                                                 ),
                                                 7: (
                                                    <Time
                                                    required={true}
                                                    mode={mode}
                                                    name="DefaultValue"
                                                    IdString="DefaultValue"
                                                    resetForm={resetForm}
                                                    label={ title ? title :"time"}
                                                />
                                                 ),
                                             }[propertyType]
             
                                        }
                                        </div>   
                                        {mode !="create" &&
                                         <div className="col-md-4">
                                                <EnumerationTypeSelect
                                                 Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                                                 name="ActivityStatus"
                                                 enumTypeSelect="ActivityStatus"
                                                 label="ActivityStatus"
                                                 value={editForm?.ActivityStatus}
                                                 mode={mode}
                                                />
                                         </div>
                                        }
                                        {  mode !="view"?
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button value="Save" btnType="submit" />
                                              <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                                              </div>
                                              :
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                                              </div>

                                          }                        
                                    </form>
                                   : <Loading />
                                }
                                </div >
                                   </>
                                }
                                {loading ?
                                    response?.Data?.length > 0 ?
                                        <>
                                            <DataGrid
                                                parentName={"DevicePropertiesSetting"}
                                                handlefiltering={(str)=>{setFilterlist(str)  }}
                                                filterStr={filterlist}
                                                orderFormat={setOrderBy}
                                                orderFormatList={orderBy}
                                                startIndex={1}
                                                theadData={Object.keys(response.Data[0])}
                                                tbodyData={response.Data}
                                                minCellWidth={80}
                                                selectedRowObjectCallback={selectedIdCallback}
                                                handleEdit={handleEditRow}
                                                handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,()=> handleDeleteRow(e))}}
                                                handleView={handleViewRow}
                                                totalRecords={response.TotalRecords}
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                HandleNextPage={handleNextPage}
                                                HandlePrevPage={handlePrevPage}
                                                handlechangePageSize={handleChangePageSize}
                                                first={() =>{getAllDevicePropertiesAsync(pages); setPageNum(pages)}}
                                                end={() =>{getAllDevicePropertiesAsync(1); setPageNum(1)}}
                                                handleGoPage={handleGoPage}
                                            />
                                        </>
                                        : <Message message={catchMessage.msg} type={catchMessage.type} />
                                        :<Loading/>
                                }
                            </>
                    </div>
                </div>
            </div>
        </>

    );
}