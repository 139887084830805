import { Button } from "components/Button/Button";
import { ButtonPrintIcon } from "components/ButtonPrintIcon/ButtonPrintIcon";
import DatePicker from "components/DatePicker/DatePicker";
import { Loading } from "components/Loading/loading";
import { UserContext } from "components/ModalForm/ModalForm";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { Person } from "layouts/DashboardLayout/components/Header/component/profile/components/person";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { GetReportWithJsonData, trans } from "utils/helpers";
import { getDataForPrint, getHourlyLeaveData, getHourlyLeaveDataByReferenceId, postHourlyLeaveData, putHourlyLeaveData } from "./api";
import { MessageType } from "types/enum";


interface HourlyLeaveFormPropsType {
    mode?: any,
    jobId?: any,
    Name?: any,
    HourlyLeaveId?: any,
    processId?: any,
    taskId,
    Id?: any,
    referenceId?: any,
    step:any,
    onHide: () => void,
    handleCancelForm: () => void,
    handleShowReferenceStepper?: (referenceId: string) => void;
    inDynamicForms?: any;

}

export const  HourlyLeaveForm = ({
    mode,
    jobId,
    Name,
    HourlyLeaveId,
    processId,
    taskId,
    Id,
    referenceId,
    step=1,
    onHide,
    handleCancelForm,
    handleShowReferenceStepper,
    inDynamicForms=false,


}: HourlyLeaveFormPropsType) => {
    const [fromDate, setFromDate] = useState<any>(null);
    const [untilDate, setUntilDate] = useState<any>(null);
    const [leaveHours, setleaveHours] = useState<any>(null);

    const [requestDate, setRequestDate] = useState<any>(null);
    const [response, setResponse] = useState<any>();
    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);
    const [localMode, setLocalMode] = useState<any>("create");
    const layoutContext = useContext(LayoutContext);
    const [showReferenceStepper, setShowReferenceStepper] = useState<boolean>(false);
    const [flag,setFlag] = useState(false);
    const [todayDate, settodayDate] = useState<string | null>(new Date().toISOString());

    const validationDates = () =>{
        if(fromDate && untilDate && Date.parse(fromDate) > Date.parse(untilDate)){
            setFlag(true)
        }else{
            setFlag(false)
        }
    }  

    async function formSubmit(e) {
        e.preventDefault()
        let data
        let method
        if(flag){
            Toast(trans("InvalidEndDate"),"error");
        }else{
        if(response?.Id ){
            method = "put"
                data = {
                    "Id" : response?.Id ,
                    "RequesterPositionId": response?.RequesterPositionId,
                    "RequestDate":requestDate,
                    "StartTime": requestDate.split("T")[0]+"T"+fromDate.split("T")[1],
                    "EndTime":requestDate.split("T")[0]+"T"+untilDate.split("T")[1],
                    "Description":  e.target.Description.value,
                    "SubstitutePersonId":e.target.SubstitutePersonId.value ? e.target.SubstitutePersonId.value:null,
                    "LeaveTime": leaveHours
                }
        
        try {
            const res = await putHourlyLeaveData(data , referenceId)
            if (showReferenceStepper) {
                handleShowReferenceStepper && handleShowReferenceStepper(!(res.MessageType ==MessageType.success) ? false:referenceId);
              } else {
                setResetForm(true)
                onHide()
              }
        }catch (error) {

        }

        }else{
            method = "post"
              data = {
                "RequesterPositionId":  e.target.RequesterPositionId.value,
                "SubstitutePersonId": e.target.SubstitutePersonId.value ? e.target.SubstitutePersonId.value:null,
                "RequestDate": requestDate,
                "StartTime":requestDate.split("T")[0]+"T"+fromDate.split("T")[1],
                "EndTime":requestDate.split("T")[0]+"T"+ untilDate.split("T")[1],
                "Description":  e.target.Description.value,
                "LeaveTime": leaveHours
              }
            try {
                const res = await postHourlyLeaveData(data,processId)
                if (showReferenceStepper) {
                    handleShowReferenceStepper && handleShowReferenceStepper(!(res.MessageType ==MessageType.success) ? false:res.Data);
                  } else {
                    setResetForm(true)
                    onHide()
                  }

            }catch (error) {
  
          }
        }
    }
}
    function changeTime(time:any) {
        if(time){

            return `${Math.floor( time/60)}:${Math.floor( Math.abs(time)%60)} `
        }else{
            return ""
        }
        
    }

    async function getHourlyLeaveAsync() {
        setLoading(false)
        try {
            if(Id){
                const res = await getHourlyLeaveData(Id )
                setResponse(res.Data)
            }else if(referenceId){

                const res = await getHourlyLeaveDataByReferenceId(referenceId )
                setResponse(res.Data)
            }
       } catch (error) {
          setResponse({})
       }
       
        setLoading(true)
    }
    const getDataForPrintAsync =async (id) => {
        if(jobId){
            if(id.includes("Design")){
                const res = await getDataForPrint(jobId)
                GetReportWithJsonData( id.replace("Design" , ""), res.Data ,2)
            }else if(id.includes("Default")){
                const res = await getDataForPrint(jobId)
                GetReportWithJsonData( id.replace("Default" , ""), res.Data ,2)
                
            }else{
                const res = await getDataForPrint(jobId)
  /*               const dataStr = JSON.stringify(res.Data);
                const blob = new Blob([dataStr], { type: 'application/json' });
                const url = URL.createObjectURL(blob); */
               GetReportWithJsonData( id, res.Data ,1 )
                
            }
            
        }
        try {

        } catch (error) {
            
        }
    };
    
    
    useEffect(() => {
        if (resetForm) {  
            setResetForm(false);
        }
    }, [resetForm]);

    useEffect(() => {
        if ((mode == "edit" && step ==1)  ) {
            setLocalMode("create")
        }else if(step ==3 ||mode == "edit"){
            setLocalMode("view")    
        }else{
            setLocalMode(mode)
        }
    }, [mode]);

    useEffect(() => {
        if( (step >=1 ||referenceId != null   ) && mode != "create"||Id){
        getHourlyLeaveAsync()
        }else{
            setLoading(true)
            setResponse({})
        }
    }, [Id , referenceId]);

    useEffect(() => {
        if(untilDate && fromDate){
            let startTimeMilisecond = fromDate.split("T")[1].split(":")
            let endTimeMilisecond = untilDate.split("T")[1].split(":")
            startTimeMilisecond[2]="00"
            endTimeMilisecond[2]="00"
            let fr =fromDate.split("T")[0]+"T"+startTimeMilisecond.join(":");
            let en = untilDate.split("T")[0]+"T"+endTimeMilisecond.join(":");
            setleaveHours( Math.floor( Math.abs(new Date(fr).getTime()-new Date(en).getTime())/60000) ==0 ? 1 :Math.floor( Math.abs( Math.ceil(new Date(en).getTime()) - Math.ceil(new Date(fr).getTime()))/60000) )
          //  setleaveHours( Math.floor( Math.abs(new Date(untilDate).getTime()-new Date(fromDate).getTime())/60000))
        }
    },[untilDate ,fromDate]);

    useEffect(()=>{
        validationDates()
      },[untilDate,fromDate])

    return (
        <div>
           {loading ?
        <div>
         <form className={"action-form py-2 "+mode} onSubmit={formSubmit}>
            <div className={`${step >= 1 && "card"}  ${mode == "create" && "card"}  `}>
            <div className="row " style={{flexDirection: "row-reverse"}}>
                <ButtonPrintIcon reportDomain={"OfficeAutomation"}  objectTag={"HourlyLeaveForm"} Callback={(e) => {getDataForPrintAsync(e)}} />
                </div>
                <div className="card-body">  
                    <div className="row mb-2">
                        <div className="col-lg-4 col-sm-4 ">
                        <Position
                            name="RequesterPositionId"
                            label="RequesterPosition"
                            mode={"view"}
                            value={mode == "create" ? layoutContext.currentUserPosition.PositionId : response?.RequesterPositionId}
                            resetForm={resetForm}
                            />
                        </div>
                         <div className="col-12 col-lg-4">
                            <DatePicker
                                Change={setUserMadeChanges}
                                required={true}
                                mode={step >1 ? "view" : mode}
                                resetForm={resetForm}
                                lable="Date"
                                type={"date"}
                                setIsoTime={(isoTime: string) => { setRequestDate(isoTime) }}
                                val={ response?.RequestDate ? response?.RequestDate : todayDate }
                            />
                        </div>
                        <div className="col-lg-4 col-sm-4">
                         <Person
                            name={"SubstitutePersonId"}
                            label="Substitute"
                            Change={setUserMadeChanges}
                            resetForm={resetForm}   
                            value={response?.SubstitutePersonId ? response?.SubstitutePersonId  : null}
                            mode={step >1 ? "view" : mode}
                            />
                        </div>
                    </div>   
                    <div className="row mb-2">
                        <div className="col-12 col-lg-4">
                           <DatePicker
                           required={true}
                           onOpenPickNewDate={true}
                           mode={step >1 ? "view" : mode}
                           lable="StartTime"
                           setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                           val={ response?.StartTime ? response?.StartTime : null } //resetForm ? new Date().toISOString() :
                           withTime ={true} 
                           disableDayPicker={true}
                           format={"HH:mm"}
                           errorFlag={flag}
                         />   
                        </div>
                        <div className="col-12 col-lg-4 mt-2 mt-lg-0">
                         <DatePicker
                            required={true}
                            onOpenPickNewDate={true}
                            mode={step >1 ? "view" : mode}
                            Change={setUserMadeChanges}
                            lable="endTime"
                            setIsoTime={(isoTime: string) => { setUntilDate(isoTime) }}
                            val={ response?.EndTime ? response?.EndTime : null }
                            withTime ={true} 
                            disableDayPicker={true}
                            format={"HH:mm"}
                            errorFlag={flag}
                            />   
                        </div>
                        <div className="col-12 col-lg-4 mt-2 mt-lg-0">
                          <Textbox
                            readonly={true}
                            mode={localMode}
                            resetForm={resetForm}
                            textboxType="text"
                            textboxName="forTheDuration"
                            labelText="forTheDuration"
                            maxLength={50}
                            defaultValue={response?.leaveHours ? changeTime(response?.leaveHours): changeTime(leaveHours)}
                          />
                        </div>
                    </div>              
                    <div className="row mb-2">
                        <div className="col-lg-8 col-sm-6 ">
                            <Textbox
                              readonly={(step >1 || mode=="view") ? true: false}
                              mode={step >1 ? "view" : mode}
                              resetForm={resetForm}
                              textboxType="textarea"
                              textboxName="Description"
                              labelText="Description"
                              maxLength={500}
                              defaultValue={response?.Description}
                            />
                        </div>
                    </div>              
                </div>

            </div>
            <div className="d-flex justify-content-end mt-2">
                            {localMode !== "view" ?
                                <>
                                    <Button value="Save" btnType="submit" />
                                    {(handleShowReferenceStepper &&     (step==1 || mode == "create")) && (
                                     <Button
                                       btnType="submit"
                                       value="SaveAndReference"
                                       onClick={() => setShowReferenceStepper(true)}
                                     />
                                   )}
                                   {!inDynamicForms&&
                                    <Button
                                    btnType="cancel"
                                    value="Cancel"
                                    onClick={() => { Toast(trans("modalclosemessage"), "warning", "alert", handleCancelForm) }}
                                    />
                                }
                                </> 
                                :
                               <></>
                            }
            </div>               

        </form>
            </div>
            : <Loading/>


           
           }

        

        </div>
    );
}