import { useEffect, useState, useRef } from "react";
import { trans } from "utils/helpers";
import { setTimeout } from "timers";
import { SubMenu } from "pages/SystemManagement/Jobs/components/SpecialReference/components/SubMenu/SubMenu";
import { getUnitDictionaryApi } from "./api";
interface UnitDictionaryPropsType {
    label?: string,
    name: string,
    hasBorder?: boolean,
    required?: boolean,
    value?:string,
    mode?:string
    resetForm?:any,
    Change?: (e) => void,
    setUnitDictionary?: (e) => void,
}

export const UnitDictionary = ({ label="Unit", hasBorder,value,mode ,name,required , resetForm ,Change , setUnitDictionary}: UnitDictionaryPropsType) => {
    const [UnitDictionary, setUnitDictionarys] = useState<any>();
    const [valueName, setValueName] = useState<any>();
    const [flag , setFlag] = useState(false);
    const [show , setShow] = useState(false);
    const [validat , setValidat] = useState(false);
    const [rquird , setRquird] = useState(false);
    const [activeId , setactiveId] = useState<any>();
    const inputRef = useRef<any>();
    const inputIdRef = useRef<any>();
    const UnitDictionaryRef = useRef<any>(UnitDictionary);
   const activeIdRef = useRef<any>(activeId);
   const ShowRef = useRef<any>(show);
   const validRef = useRef<any>(false);
     

    async function getUnitDictionaryListAsync(search,id) {
        try{
           const res = await getUnitDictionaryApi(search,id );
           if(id){
            setValeu(res.Data[id] , id)
            setValueName(res.Data[id])
           }
           setUnitDictionary&&  setUnitDictionary({Value : res.Data[id] , Id : id})        
             setUnitDictionarys(res.Data)        
             UnitDictionaryRef.current = res.Data
             setFlag(!flag)
        }catch(err:any){
        
          }
      }

    const Auto = (e)=>{
      validRef.current = false
      setValidat(false)
      setRquird(false)
      getUnitDictionaryListAsync(e.target.value,"")
      setFlag(!flag)

    }

    const delay = (duration) =>
    new Promise(resolve => setTimeout(resolve, duration));
  
    const CheckUnitDictionary =async (e)=>{
        setTimeout(async() => {
          if(e.target.value.length == 0&& inputRef.current?.value?.length == 0 && e.target.value==""){
            
            inputIdRef.current.value = null
            setUnitDictionary&& setUnitDictionary(null)
            if(required)
              setRquird(true)
          }else{
          setRquird(false)
          try{
            const resp:any = await getUnitDictionaryApi(inputIdRef.current.value ? null :e.target.value,inputIdRef.current.value)
            if(Object.keys(resp.Data).length==0){
              setValidat(true)
            } if(Object.keys(resp.Data).length==1){
              setValeu(Object.values(resp.Data)[0] , Object.keys(resp.Data)[0])
            }
          }catch(err:any){
            setValidat(true)
          }
        }
        }, 200);
        await delay(200)
        setShow(false)
      }

    const setValeu = (newValue , id)=>{
      if(inputIdRef != null && inputIdRef.current != null){
      validRef.current = true
      inputRef.current.value=newValue;
      inputIdRef.current.value=id;
      UnitDictionaryRef.current = null;
      ((value==null &&id)|| id != value) ? Change&&Change({"person" :newValue}):Change&&Change({"person":null});
      setUnitDictionarys(null)
      setFlag(!flag)
      inputRef.current.setCustomValidity("")
      setUnitDictionary && setUnitDictionary({Value : inputRef.current.value , Id : id})
      setValidat(false)
      }
    }
    
    useEffect(() => {
       if(value){
        getUnitDictionaryListAsync(null,value)
       }else if(value == null){
        setValueName(null)
       }
      }, [value]);

      useEffect(() =>{
        if(show ){
          document.body.addEventListener("click", clickToclose)
        } 
     return () => { document.body.removeEventListener('click', clickToclose) }
    
        
      },[show ])
      useEffect(() =>{
        if(resetForm ){
          inputRef.current.value=null;
          inputIdRef.current.value=null;

        } 
      },[resetForm ])
    
      function clickToclose(e){
        let input = document.querySelector("#browser"+name);
        if (e.target.id !== input?.id) { 
          setShow(false);
        }
      }
        return (
     <>
     <div className="text-box">
     <label htmlFor={"browser"+name }className="text-box__label">{trans(label)}</label>
     {required && <span className="text-box__required-sign-for-label text-danger">*</span>}
     <input required={required} defaultValue={value && value} name={name} className="d-none" ref={inputIdRef} />
     <input required={required} onBlur={CheckUnitDictionary} defaultValue={valueName} autoComplete="off" onFocus={(e)=>{  Auto(e) ; setShow(true);}}  ref={inputRef} list="UnitDictionaryList" name={"browser"+name} className="text-box__box form-control "    id={"browser"+name} onMouseDown={(e)=>e.stopPropagation() } onChange={Auto} disabled={mode =="view" ? true : false} />
      {
        show && 
        <SubMenu   onClick={setValeu} options={UnitDictionary} onHide={()=>{setShow(false)}} />
      }  
       <div className="text-box__msg-wrapper">
        {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
        {required && <span className={`${rquird ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsRequired", [""])}</span>}
        </div>    
     </div>
     </>
    )
}