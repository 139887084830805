import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getReverseList = (referenceId: any) => http({
    url: ENDPOINTS.cartable.refer.reverseList,
    method: 'get',
    params: {
        referenceId: referenceId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

