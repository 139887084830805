import { ContactType, OrganizationType } from "types/enum";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";




export const getCustomerPhoneDictionaryApi = (async (id ,personId ,Current) => await http({
    url: Current ?  ENDPOINTS.BaseInformation.CurrentPersonContact.phones  :    ENDPOINTS.BaseInformation.PersonContact.phones,
    method: 'get',
    params: {
        personId:personId,
        id: id,
    }
}).then((res) => {

    return res.data.Data
}).catch((err) => {

    return Promise.reject( err);
})
)
export const getCustomerPhoneDictionaryListApi = (async (search,id ,personId ,Current,type=1) => await http({
    url: Current ?   ENDPOINTS.BaseInformation.CurrentPersonContact.phones :
    type===ContactType.Phone?
    ENDPOINTS.BaseInformation.PersonContact.landLine
    :ENDPOINTS.BaseInformation.PersonContact.mobile,
    method: 'get',
    params: {
        personId:personId,
        searchString:search
    }
}).then((res) => {

    return res.data.Data
}).catch((err) => {

    return Promise.reject( err);
})
)