import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getArchiveDataWithId = (id) => http({
    url: ENDPOINTS.archive.organizationalArchive.load,
    method: 'get',
    params:{
        Id:id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
})


export const postArchiveData = (data) => http({
    url: ENDPOINTS.archive.organizationalArchive.browse,
    method: 'post',
    data: data
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
})


export const putArchiveData = (data) => http({
    url: ENDPOINTS.archive.organizationalArchive.browse,
    method: 'put',
    data: data,
    params:{
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
})



