import { useEffect } from "react";

export function SelectBoxHandle({
  selectedSelectBox,
  setSelectedSelectBox,
  options,
  children,
  userChanges,
  setUserChanges,
  item,
}) {
  useEffect(() => {
    if (selectedSelectBox[item.SystemKey] == undefined) {
      setSelectedSelectBox({
        ...selectedSelectBox,
        [item.SystemKey]: "0",
      });
      setUserChanges({ ...userChanges, [item.SystemKey]: "0" });
    }
  }, []);

  useEffect(() => {
    const findItem = options.findIndex((option, index) => {
      return option == selectedSelectBox[item.SystemKey];
    });
    findItem > -1
      ? setUserChanges({
          ...userChanges,
          [item.SystemKey]: findItem,
        })
      : setUserChanges({
          ...userChanges,
          [item.SystemKey]: "0",
        });
  }, [selectedSelectBox[item.SystemKey]]);

  return <div className="mb-3">{children}</div>;
}
