import { TabBar } from "components/Tabbar/Tabbar";
import { useState } from "react";

//import { getCustomerForm, sendCustomer } from "./api";
import { PersonLegalForm } from "pages/OfficeAutomation/BaseInformation/PersonsPage/components/PersonLegalList/component/PersonLegalForm/PersonLegalForm";
import { PersonRealForm } from "pages/OfficeAutomation/BaseInformation/PersonsPage/components/PersonRealList/components/PersonRealForm/PersonRealForm";

interface Form {
  setShowPersonForm: (e) => void;
  setNewPerson: (e) => void;
  setResetForm: (e) => void;
  mode: any;

}
export const PersonFormInTab = ({ setShowPersonForm, mode, setNewPerson ,setResetForm  }: Form) => {

  const [curentTab, setCurentTab] = useState<any>("PersonRealForm")



  let content = [
    { title: "PersonRealForm", body: (
        <PersonRealForm
        showSaveClose={false}
        mode={mode}
        ButtonInTop={true}
        handleCancelForm={(e)=>{setShowPersonForm(false)}}
        handleAddPerson={(e)=>{ setNewPerson(e.Data);setResetForm(true);setShowPersonForm(false)}}
     />
), },
    { title: "PersonLegalForm", body: (
        <PersonLegalForm
        showSaveClose={false}
        mode={mode}
        ButtonInTop={true}
        handleCancelForm={()=>{setShowPersonForm(false)}}
        handleAddPerson={(e)=>{ setNewPerson(e.Data);setResetForm(true);setShowPersonForm(false)}}
   />
), },
  ];



  const tabChangeHandle = (tab) => {
    setCurentTab(tab)
  } 

  return (
    <>
      <TabBar  tabChange={tabChangeHandle} content={content} curentTab={curentTab} defaultTab={"PersonRealForm"} />
    </>
  )
}