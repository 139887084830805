import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const JobEntireTree = (id  ) => http({
    url: ENDPOINTS.Management.JobManagement.JobEntireTree ,
    method: 'get',
    params: {
        Id:id ,
    }

}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject( err);
});

