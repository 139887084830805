import { Button } from "components/Button/Button";

import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { trans } from "utils/helpers";
import { getUnit, sendUnitsApi } from "./api";
import { Conversion } from "./components/Conversion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface UnitFormPropsType {
  selectedObj;
  showForm: (e: boolean) => void;
  mode;
  relod: () => void;
  handleCopyItem?:any
}
export const UnitForm = ({
  selectedObj,
  showForm,
  mode,
  relod,
  handleCopyItem
}: UnitFormPropsType) => {
  const [resetForm, setResetForm] = useState(false);
  const [response, setResponse] = useState<any>();
  const [physicalQuantityId, setPhysicalQuantityId] = useState<any>();
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [untilDate, setUntilDate] = useState<string | null>(null);
  const [loading, setLoading] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});
  const [flag, setFlag] = useState(false);
  const [canPaste,setCanPaste] = useState<any>(false)

  async function sendNodeAsync(data, method) {
    try {
      const res = await sendUnitsApi(data, method);

      sessionStorage.setItem("changingforms", "false");
      setUserChanges({});
      relod();
      showForm(false);
    } catch (err: any) {}
  }

  async function getUnitAsync() {
    setLoading(false);

    try {
      if (selectedObj.Id) {
        const res = await getUnit(selectedObj.Id);
        setResponse(res.Data);
        // setPhysicalQuantityId(res.Data.Unit.PhysicalQuantityId);
      } else {
        setResponse(null);
      }
    } catch (err: any) {}
    setLoading(true);
  }

  const validationDates = () => {
    if (
      fromDate &&
      untilDate &&
      fromDate?.toString().slice(0, 10) > untilDate?.toString().slice(0, 10)
    ) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  };

  const handlePersonContactFormSubmit = (Unit) => {
    Unit.preventDefault();
    if (flag) {
      Toast(trans("InvalidEndDate"), "error");
    } else {
      if (mode == "edit") {
        let data = {
          Id: selectedObj.Id,
          IsDefault: Unit.target.checkbox.checked,
          Description: Unit.target.Description.value,
          DescriptionEN: Unit.target.DescriptionEN.value,
          TaxUnitCode: Unit.target.TaxUnitCode.value
            ? Unit.target.TaxUnitCode.value
            : null,
          Abbreviation: Unit.target.Abbreviation.value,
          PhysicalQuantityId: Unit.target.PhysicalQuantity.value,
          ActivityStatus: +Unit.target.ActivityStatus.value,
          RoundingPlace: +Unit.target.RoundingPlace.value,
          SystemKey:Unit.target.SystemKey.value?Unit.target.SystemKey.value:null,
        };
        sendNodeAsync(
          {
            Unit: data,
            ConversionRatioWithDefaultUnit: +Unit.target.Conversion.value,
          },
          "put"
        );
      } else {
        let data = {
          IsDefault: Unit.target.checkbox.checked,
          Description: Unit.target.Description.value,
          DescriptionEN: Unit.target.DescriptionEN.value,
          TaxUnitCode: Unit.target.TaxUnitCode.value
            ? Unit.target.TaxUnitCode.value
            : null,
          Abbreviation: Unit.target.Abbreviation.value,
          PhysicalQuantityId: Unit.target.PhysicalQuantity.value,
          RoundingPlace: +Unit.target.RoundingPlace.value,
          SystemKey:Unit.target.SystemKey.value?Unit.target.SystemKey.value:null,
        };
        sendNodeAsync(
          {
            Unit: data,
            ConversionRatioWithDefaultUnit: +Unit.target.Conversion.value,
          },
          "post"
        );
      }
    }
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        showForm(false);
      });
    } else {
      showForm(false);
    }
  }

  async function checkPaste() {
    try {
        let data =await navigator.clipboard.readText()

        if(data && JSON.parse(data)){
            setCanPaste(true)
        }
        
    } catch (error) {
        setCanPaste(false)

    }
}


const pasteHandler = async() => {
  const text =JSON.parse( await navigator.clipboard.readText());
     setResponse(text)
   try {
   } catch (error) {
       
   }  
}

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (selectedObj && mode != "create") {
      getUnitAsync();
    } else {
      setLoading(true);
    }
  }, [selectedObj]);
  useEffect(() => {
    validationDates();
  }, [untilDate, fromDate]);
  useEffect(()=>{
    if(mode!=="view"){
      checkPaste()
    }
  },[])

  return (
    <div className="person-contact-form card">
      <div className="card-body">
        {loading ? (
          <div className="container-fluid">
            <form onSubmit={handlePersonContactFormSubmit}>
              <div className="row ">
                <div className="col-8">
                  <Message
                    message={
                      trans("Help_RoundingPlace_Positive") +
                      "<br/>" +
                      trans("Help_RoundingPlace_Negative")
                    }
                    type={"info"}
                  />
                </div>
                <div className="col-4">
                {(canPaste&&mode!="view")&&
                        <div onClick={()=>{pasteHandler()}} className="col-2  btn btn-primary btn-sm d-flex align-items-center justify-content-between " style={{width:'89px' , maxHeight:"30px", marginRight:"20px" , position:"absolute" , left:"5px"}}>
                            <span className=" font-size-md ">{trans('paste')}</span>
                            <FontAwesomeIcon size="xs" icon={['fas', 'paste']} className="color_text_light d-flex Requests__chatbox-reply-btn mx-1"   color='white' />
                        </div>                    
                     }
                </div>
                <div className="col-lg-2 col-sm-3 mt-1">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="Description"
                    labelText="Description"
                    required={true}
                    maxLength={900}
                    defaultValue={response?.Unit?.Description}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 mt-1">
                  <DictionarySelectBox
                    onChange={setPhysicalQuantityId}
                    name="PhysicalQuantity"
                    label="PhysicalQuantity"
                    baseUrl={INVENTORYANDSALES_URL}
                    endPoint={
                      ENDPOINTSINVENTORYANDSALES.Inventory
                        .BaseDefinitionAndSettingApi.physicalQuantity.dictionary
                    }
                    value={response?.Unit?.PhysicalQuantityId}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 mt-1">
                  <Conversion
                    Id={physicalQuantityId}
                    name="Conversion"
                    label="Conversion"
                    defaultValue={response?.ConversionRatioWithDefaultUnit}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-12 col-lg-2 col-sm-3 mt-lg-1 mt-2">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="number"
                    textboxName="RoundingPlace"
                    labelText="QtyRoundingPlace"
                    maxLength={900}
                    required={true}
                    defaultValue={
                      response?.Unit?.QtyRoundingPlace
                        ? response?.Unit?.QtyRoundingPlace + ""
                        : "0"
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="w-100"></div>
                <div className="col-lg-2 col-sm-3 mt-1">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="DescriptionEN"
                    labelText="DescriptionEN"
                    maxLength={900}
                    defaultValue={response?.Unit?.DescriptionEN}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 mt-1">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="SystemKey"
                    labelText="SystemKey"
                    defaultValue={response?.Unit?.SystemKey}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 mt-1">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="unitCode"
                    textboxName="TaxUnitCode"
                    labelText="TaxUnitCode"
                    defaultValue={response?.Unit?.TaxUnitCode}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 mt-1">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="Abbreviation"
                    labelText="Abbreviation"
                    maxLength={900}
                    required={true}
                    defaultValue={response?.Unit?.Abbreviation}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                {mode != "create" && (
                  <div className="col-lg-2 col-sm-3 mt-1">
                    <EnumerationTypeSelect
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      name="ActivityStatus"
                      label="ActivityStatus"
                      enumTypeSelect="ActivityStatus"
                      value={response?.Unit?.ActivityStatus}
                      mode={mode}
                    />
                  </div>
                )}
                <div className="col-lg-2 mt-4 mb-4">
                  <CheckableButton
                    defaultChecked={response?.Unit?.IsDefault}
                    defaultValue={response?.Unit?.IsDefault}
                    labelText={"Isdefault"}
                    inputName={"checkbox"}
                    type="checkbox"
                    mode={mode}
                  />
                </div>
              </div>

              {mode != "view" ? (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button value="Save" btnType="submit" />
                  <Button
                    btnType="cancel"
                    value="Cancel"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              ) : (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button
                    btnType="primary"
                    value="close"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              )}
            </form>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};
