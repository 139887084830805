import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getAddressBox = (Id ,Current) => http({
    url:Current ?  ENDPOINTS.BaseInformation.CurrentPersonContact.addresses :  ENDPOINTS.BaseInformation.PersonContact.addresses,
    method: 'get',
    params:{
        personId:Id
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});

export const postAddressBox = (data ,Current ) => http({
    url:Current ? ENDPOINTS.BaseInformation.CurrentPersonContact.Load  :  ENDPOINTS.BaseInformation.PersonContact.Load,
    method: 'post',
    data:data
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});