import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { getPageSize, trans } from "utils/helpers";
import { getDefaultGroupDetail } from "./api/api";
import { Search } from "components/Search/Search";


interface DefaultGroupMembersPropsType {
    selectedObj;

}
export const DefaultGroupMembers = ({ 
    selectedObj,

}: DefaultGroupMembersPropsType) => {

    const [response , setResponse ] = useState< any>(null);
    const [selectedDefaultGroup , setSelectedDefaultGroup ] = useState< any>();

    const [loading , setLoading] = useState(false)
    const [messageType , setMessageType] = useState< any>("info")
    const [orderBy, setOrderBy] = useState<any>([]);
    const [filterList, setFilterList] = useState<any>("");
    const [PageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [search, setSearch] = useState(null);
    const [showDeleted, setShowDeleted] = useState(false);
    const [pages,setPages] = useState(1);



      const getWithOrder = (order: string[]) => {
            setOrderBy(order)
      }
    
      const Searching = (str)=>{   
        setSearch(str)
      }
    
      const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(+e.currentTarget.value);
        getPageSize("DefaultGroupMembers" ,+e.currentTarget.value);
        getDefaultGrouporganizationIdAsync(selectedObj.Id ,1);
      };
    
      const handleGoPage = (value) => {
        if (value) {
          if (value < 1 || value > pages) {}
          else {setPageNum(+value);getDefaultGrouporganizationIdAsync(selectedObj.Id ,value)};
        } else {
     
        }
      };
      const handleNextPage = () => {    

        if (PageNum < pages) {
          setPageNum(PageNum + 1);
          getDefaultGrouporganizationIdAsync(selectedObj.Id ,PageNum + 1);
      };
      };
      const handlePrevPage = () => {

        if (PageNum > 1) {
          setPageNum((pageNum) => { return +pageNum - 1 });
          getDefaultGrouporganizationIdAsync(selectedObj.Id ,PageNum - 1);
      };
      };
    




    function selectedDefaultGroupCallback(obj) {
      setSelectedDefaultGroup(obj)         
    
    }
      



    async function getDefaultGrouporganizationIdAsync(id,pageNum = PageNum) {      
      setLoading(false)
      setResponse(null)
      let pageSizeChangedByUser = getPageSize("DefaultGroupMembers" )
      setPageSize(pageSizeChangedByUser)
        try{
           const res = await getDefaultGroupDetail(id,pageNum, pageSizeChangedByUser , orderBy.join(",")  ,search );
           setResponse(res.Data)
           setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1)
           setMessageType("info")
          }catch(err:any){
            setMessageType("error")
          }
          setLoading(true)
    }



     useEffect(() => {
        setPageNum(1)
        getDefaultGrouporganizationIdAsync(selectedObj.Id,1);
      }, [ selectedObj,search,orderBy,filterList,showDeleted]);




    return (
        <div className="person-contact-form card">
            <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-1">
                <Search onChange={Searching} />
              </div>
                <div className="">

                    {loading ?
                          (response?.Data?.length>0 ?
                         <DataGrid
                            orderFormat={getWithOrder}
                            orderFormatList={orderBy}
                            parentName={"DefaultGroupMembers"}
                            multiSelect={false}
                            theadData={Object.keys(response?.Data[0])}
                            minCellWidth={80}
                            tbodyData={response?.Data}
                            selectedRowObjectCallback={selectedDefaultGroupCallback}   
                            startIndex={1}   
                            scrollHeight={"300px"}  
                            totalRecords={response?.TotalRecords}
                            pageNum={PageNum}
                            pageSize={pageSize}
                            HandleNextPage={handleNextPage}
                            HandlePrevPage={handlePrevPage}
                            handlechangePageSize={handleChangePageSize}
                            first={() => {getDefaultGrouporganizationIdAsync(selectedObj.Id ,pages);setPageNum(pages)}}
                            end={() => {getDefaultGrouporganizationIdAsync(selectedObj.Id,1);setPageNum(1)}}
                            handleGoPage={handleGoPage}              
                          /> 
                        : <Message message={"NoDataForPreview"} type={messageType} />                          
                          )
                      : <Loading/>
                    }
                </div>
            </div>
        </div>
    );
}