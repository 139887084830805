import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useRef } from "react"
import { CalendarEventType } from "types/enum";
import { ICON_TYPE } from "utils/constants";
import './../../style.scss';


interface Props {
    dayNum: number // day number 
    firstDay: number[] // first days of submitted events
    currentDayNumber: number // day number of today
    sendSelectedDaysUp: (selectedDay: {  day: number, Year: number,month: number, checked: boolean }) => void
    sendClickDaysUp?: (selectedDay: any) => void
    selectedDays ? :any

    eventList: { dayId: number, eventMessage: string, id: number }[]
    selectedDay?: number

    month: number
    YearOfMonth: number
    CalendarEvent?: number
    holiday: number[] | undefined | null
    formSubmitted: boolean
}

export const CalendarDay = ({ dayNum,selectedDay,selectedDays ,sendClickDaysUp, sendSelectedDaysUp,CalendarEvent , YearOfMonth , eventList, month, holiday, firstDay, currentDayNumber, formSubmitted }: Props) => {
    const [day, setDay] = useState<{  day: number, Year: number,month: number, checked: boolean }>({  checked: false,day:dayNum ,month:month , Year :YearOfMonth   });
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [currentDayEventList, setCurrentDayEventList] = useState<any>(false);
    const [defaultChecked, setDefaultChecked] = useState<any>(null);


    const isHoliday = (holiday && holiday?.some(day => day === dayNum) ||(CalendarEvent  && CalendarEvent == CalendarEventType["NationalWeekend"] ||  CalendarEvent == CalendarEventType["NationalHoliday"]  ))  // check if current day is holiday
    
    const isFirstDay = true // check if current day is first day of submitted event
   // const isFirstDay = firstDay?.some(day => day === dayNum) // check if current day is first day of submitted event





    const handleSelectedDayOnclick = () => {
      //  setDay(state => ({ ...state, checked: !state.checked }))
        sendSelectedDaysUp({day:dayNum ,month:month , Year :YearOfMonth , checked: day.checked })
    }
 

    // reset all checkbox after event-form submitted ...
    useEffect(() => {
        let filter= eventList.filter(event => event.dayId === dayNum) // current day events
        if(  filter.length >1||( CalendarEvent && CalendarEvent != CalendarEventType["NationalEvent"] && CalendarEvent != CalendarEventType["NationalHoliday"] && CalendarEvent != CalendarEventType["NationalWeekend"] &&CalendarEvent !=CalendarEventType["OrganizationalWeekend"]) ||CalendarEvent == undefined  ){

        }
        let newFilter:any =[]
         filter.forEach(item=>{
            if(item.eventMessage.length > 1){
                newFilter.push(item) 
            }
        })
        
        setCurrentDayEventList(newFilter)

        
        if (formSubmitted) {
            setDay(state => ({ ...state, checked: false }))
        }
    }, [formSubmitted , eventList])
        
    useEffect(() => {
        if(currentDayNumber == dayNum){
        sendClickDaysUp && sendClickDaysUp(day)
    }
    }, [currentDayNumber ])


    useEffect(() => {
        let flag = false
        selectedDays.map(item=>{
            if(item.day == dayNum && item.month ==month &&  item.Year ==YearOfMonth ){
                flag = true
                setDay(item)
            }
        })
        if(!flag && day.checked){
            setDay(state => ({ ...state, checked: false }))
        }
    
    }, [selectedDays ])


    return (
        <>
            <div
                className={`eventcalendar__tbody-cell  position-relative d-flex justify-content-between flex-row ${day.checked ? "activeDay" : ""} ${selectedDay == dayNum ?  "selectedDay" :"border border-1" } `}
                
            >
                <span className={`${currentDayNumber === dayNum && "currentDay"} position-absolute end-0 top-0 mx-1 ${isHoliday ? "text-danger" : (CalendarEvent ==CalendarEventType["OrganizationalHoliday"] ||CalendarEvent ==CalendarEventType["OrganizationalWeekend"]  ? "text-warning" : ""  )}`}>{dayNum}</span>
                <input
                     onClick={handleSelectedDayOnclick}
                    type={"checkbox"}
                    id={`${dayNum}`}
                    className="position-absolute start-0 top-0 m-1"
                    defaultChecked={defaultChecked}
                    checked={ day.checked}
                />
                <div onClick={()=>{sendClickDaysUp && sendClickDaysUp(day)}} className="eventcalendar__tbody-cell-content mt-4 d-flex flex-column">
                    {currentDayEventList.length > 0 &&
                        <span className={`text-nowrap text-white mb-1 text-end pe-1 ${CalendarEvent ==CalendarEventType["PersonalEvent"]  ? "bg-warning" :"text-truncate" } `} >
                            {isFirstDay && currentDayEventList[0]?.eventMessage}
                        </span>
                    }
                    {currentDayEventList.length > 1 &&
                        <span onClick={() => { setShowTooltip(true) }} className="text-primary text-end ms-0 ms-md-1">بیشتر ...</span>
                    }
                </div>
            </div>

            {showTooltip &&
                <div className="eventcalendar__tbody-cell-tooltip bg-white p-1 d-flex flex-column">
                    <div className="d-flex justify-content-between">
                        <FontAwesomeIcon icon={[ICON_TYPE, "times"]} onClick={() => { setShowTooltip(false) }} style={{ cursor: "pointer" }} />
                        <span className="text-start mb-2">{dayNum}/{month}/{YearOfMonth}</span>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        {currentDayEventList.map(event => (
                            <span key={event.dayId} className={`rounded text-nowrap text-white text-end ${event.CalendarEventType ==CalendarEventType["PersonalEvent"]  ? "bg-warning" :"text-truncate" } px-2 w-100 mb-2`}>
                                {event.eventMessage}
                            </span>
                        ))}
                    </div>
                </div>
            }
        </>
    )
}