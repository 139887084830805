import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const  getPostLevelApi =( async (searchString ,orderBy) =>  await http({
    url: ENDPOINTS.BaseInformation.postLevel.browse,
    method: 'get',
    params:{
        searchString :searchString,
        orderBy:orderBy ,
    }

})
.then((respons) => (respons.data))
.catch((err) => {
    return Promise.reject( err);
})
)
export const  sendPostLevelApi =( async (data ,method ) =>  await http({
    url: ENDPOINTS.BaseInformation.postLevel.browse,
    method: method,
    data:data

})
.then((respons) => (respons.data))
.catch((err) => {
    return Promise.reject( err);
})
)
export const  getPostLevelIdApi =( async ( id ) =>  await http({
    url: ENDPOINTS.BaseInformation.postLevel.load,
    method: "get",
    params:{
        Id:id
    }

})
.then((respons) => (respons.data))
.catch((err) => {
    return Promise.reject( err);
})
)
export const  deletePostLevel =( async ( id ) =>  await http({
    url: ENDPOINTS.BaseInformation.postLevel.browse,
    method: "delete",
    params:{
        id:id
    }

})
.then((respons) => (respons.data))
.catch((err) => {
    return Promise.reject( err);
})
)
