import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Search } from "components/Search/Search";
import React, { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getPositionList } from "../../../OtherGroups/components/api/api";


interface PositionList {
  selectList?: (e) => void;
  reset?: boolean;
  data?;

}

export const PositionList: React.FC<PositionList> = ({
  selectList ,
  reset,
  data

})=>{
    let BodyPositionList=[];
    const [searchData, setSearchData] = useState<Response | any>(data);
    const [searchString, setSearchString] = useState<Response | any>("");
    const [selectedList, setSelectedList] = useState<Response | any>();

    const selectListCallBack =(list)=>{
      setSelectedList(list[1])
      selectList && selectList(list)
  
    }
  useEffect(() => {
    selectListCallBack([])
    setSelectedList([])
    getDataAsync(searchString)

  }, [ reset]);
  useEffect(() => {
    getDataAsync(searchString)

  }, [ data]);
  
  const Searching = (str)=>{
    
    setSearchString(str)
    getDataAsync(str)
  }


  async function getDataAsync(SearchString) { 
      let PositionListFiltered:any = []
      try {
         const PositionListResponse = await getPositionList(SearchString); 
         
         PositionListResponse.Data.forEach(item1 => {
          data.forEach(item2 => {
            if(item1.Id == item2.Id){
              PositionListFiltered.push(item1)
            }
          })
         });
         setSearchData(PositionListFiltered)
        } catch (error) {
        
        }
   }  
     
  if (searchData  && searchData.length>0 ) {
    let FilteredSeleced:any = []
        if(selectedList && selectedList.length > 0){
         
         /*  searchData.forEach(item1 => {
           let flag = false
           selectedList.forEach(item2 => {
             if(item1.Id == item2.Id){
               flag = true               
             }
           });
           if(!flag ){
             FilteredSeleced.push(item1)
           }  
          });
          BodyPositionList = selectedList.concat(FilteredSeleced) */
          BodyPositionList = searchData
        }else{
          BodyPositionList = searchData
        }

}



    return(
        <>
        <div className="card">
            <div className="">
            <div className="d-flex justify-content-between align-items-center mb-1">
                <span>{trans("PositionList")}</span>
                <Search onChange={Searching} />
              </div>
              { data  ? (
                <>
                  { data?.length >0 ?(
                  <DataGrid
                    resetMultiSelect={reset}
                    selectList={selectListCallBack}
                    multiSelect={true}
                    parentName={"PositionList"}
                    theadData={ ["Id","Description"]}
                    minCellWidth={80}
                    tbodyData={BodyPositionList}
                    scrollHeight={"300px"}
                  />
                  ) : (
                    <Message message={"NoDataForPreview"} type={data?.length==0  ?"info":"error"   } />
                  )
                  }
                </>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </>
    )
}