import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { deleteSharingCenterArticle, getSharingCenterArticle, getListSharingCenterArticle, postSharingCenterArticle, putSharingCenterArticle } from "./api";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";


interface Form {      

}
export const SharingCenterArticleSetting = ({ }: Form) => {
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [flag, setFlag] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [userChanges, setUserChanges] = useState({});
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pages, setPages] = useState<number>(0);



    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("SharingCenterArticle" ,e.currentTarget.value )
        getSharingCenterArticleAsync(1)
      };


      function handleGoPage(value): void {
        if (value) {
            if (value < 1 || value > pages) { }
            else {setPageNum(+value);getSharingCenterArticleAsync(+value)}
        } else {
        }
    }
    
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getSharingCenterArticleAsync(pageNum + 1);
          };
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getSharingCenterArticleAsync(pageNum - 1);
          };
    };


    const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }
    const handleCreateAction = () => {
        setEditForm(null)
        setMode("create")
        setUserChanges({})
        setShowForm(true)
    }

    const handleEditRow = () => {
        setMode("edit");
        setShowForm(true)
    }
    const handleDeleteRow = async() => {
        try {
            await deleteSharingCenterArticle(selectedObj.Id)
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }
    }
    
    const handleViewRow = () => {
        setMode("view")
        setShowForm(true)
    }

    const selectedIdCallback = (obj, index) => {
        
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false"); setUserChanges({}) });
        }else{
            setSelectedObj(obj)         
        };
    }

    const submit =async (e) => {
        e.preventDefault();
        if (editForm == null) {
            postSharingCenterArticle({     
                IsDefault: e.target.IsDefault.checked,
                Title: e.target.Title.value,
                SystemKey: e.target.systemKey.value, 
            }).then(() => { sessionStorage.setItem("changingforms" , "false");setUserChanges({});getSharingCenterArticleAsync();cancel(); });
        } else {
            putSharingCenterArticle({
                 Title: e.target.Title.value,
                  ActivityStatus: +e.target.ActivityStatus.value , 
                  systemKey: e.target.systemKey.value,
                  IsDefault: e.target.IsDefault.checked,
                  Id: selectedObj.Id?selectedObj?.Id : editForm.Id ,
             }).then(() => { sessionStorage.setItem("changingforms" , "false");setUserChanges({});getSharingCenterArticleAsync();cancel(); })
        }
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                setEditForm(null);
                setShowForm(false)
            });
        }else{
            setEditForm(null);
            setShowForm(false)     
        };


    }
    async function getSharingCenterArticleAsync(PageNum =pageNum) {
        setLoading(false)
        let pageSizeChangedByUser = getPageSize("SharingCenterArticle" )
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getListSharingCenterArticle(search ,orderBy.length == 0 ?null:  orderBy.join(",") ,PageNum,pageSizeChangedByUser);
            setResponse(res.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) 
           
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)
    }
    async function getSharingCenterArticleasync() {
        setFormLoading(false)
        try {
            const data = await getSharingCenterArticle(selectedObj?.Id );
            setEditForm({Id: selectedObj?.Id  ,Title: data.Data.Title, Status: data.Data.ActivityStatus,systemKey: data.Data.SystemKey,  IsDefault: data.Data.IsDefault,
            });
            
        } catch (error) {
            
        }
         setFormLoading(true)

    }
    useEffect(() => {
        getSharingCenterArticleAsync();
    }, [forceUpdate, flag  ,search , orderBy]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);
    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);
    useEffect(() => {
        if(showForm &&selectedObj && mode != "create"){
           getSharingCenterArticleasync()
        }else{
            setFormLoading(true)
        }
    }, [selectedObj ,showForm ,mode]);



 

    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,1 ,trans("SharingCenterArticleSetting"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id)selectedObj.SystemReserved ? Toast(trans("Msg_CanNotEditSystemReservedData") , "error") : handleEditRow(); break;
            case "help": alert("call api help"); break;
        }
    };
    /* #endregion */
    return (
        <>
            <div className="cartable-action">
                <Toolbar handleSearch={searchHandler}  id={selectedObj?.Id} emptyList={response == null || response?.length == 0} create={true} remove={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card">
                    <div className="">
                        {loading ?
                            <>
                                {showForm&& 
                                <>               
                                    <div className="card mb-4">
                                    {formLoading?
                                        <form className="TypeSettingForm row card-body " onSubmit={submit}>
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"Title"} textboxType="text" resetForm={resetForm} labelText={"Title"} mode={mode}  defaultValue={editForm?.Title} />
                                        </div>     
                                        <div className="col-md-4 col-sm-12">
                                            <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"systemKey"} textboxType="systemKey" resetForm={resetForm} labelText={"systemKey"} mode={mode}  defaultValue={editForm?.systemKey} />
                                        </div> 
                                        
                                        {mode != "create" &&          
                                        <div className="col-lg-4 mt-1">
                                            <EnumerationTypeSelect
                                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                             name="ActivityStatus"
                                             enumTypeSelect="ActivityStatus"
                                             mode={mode}
                                             label="Status"
                                             value={editForm?.Status}
                                            />
                                        </div>                            
                                        }
                                        <div className=" mt-4 mb-4" >
                                            <CheckableButton
                                               Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                               defaultChecked={editForm?.IsDefault}
                                               defaultValue={editForm?.IsDefault}
                                               labelText={"IsDefault"}
                                               inputName={"IsDefault"}
                                               type="checkbox"
                                               mode={mode}
                                            />                                
                                        </div>    
                                        { mode !="view" ?
                                            <div className="col-lg-12 mt-1 btns_form">
                                              <Button value="Save" btnType="submit" />
                                              <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                                            </div>
                                            :
                                            <div className="col-lg-12 mt-1 btns_form">
                                              <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                                            </div>
                                        }                        
                                        </form>
                                    :
                                    <Loading />
                                    }             
                                    </div >
                                </>
                                }
                                {
                                    loading ?
                                    response?.Data?.length > 0 ?
                                        <>
                                            <DataGrid
                                                orderFormat={setOrderBy}
                                                orderFormatList={orderBy}
                                                parentName={"SharingCenterArticleSetting"}
                                                startIndex={1}
                                                theadData={Object.keys(response.Data[0])}
                                                tbodyData={response.Data}
                                                minCellWidth={80}
                                                scrollHeight={showForm? "25vh": "67vh"}
                                                selectedRowObjectCallback={selectedIdCallback}
                                                handleEdit={handleEditRow}
                                                handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                                handleView={handleViewRow}
                                                totalRecords={response.TotalRecords}
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                HandleNextPage={handleNextPage}
                                                HandlePrevPage={handlePrevPage}
                                                handlechangePageSize={handleChangePageSize}
                                                first={() =>{getSharingCenterArticleAsync(pages); setPageNum(pages)}}
                                                end={() =>{getSharingCenterArticleAsync(1); setPageNum(1)}}
                                                handleGoPage={handleGoPage}
                                            />
                                        </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                                :<Loading/>
                                }
                            </>
                            : <Loading />
                        }
                    </div>
                </div>
            </div>
        </>

    );
}