
import { Button } from "components/Button/Button";
import { ButtonArrow } from "components/ButtonArrow/ButtonArrow";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Search } from "components/Search/Search";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getGroupPositionList, getPositionList, getRadioList, getTaskUser, setTaskUser } from "./api/api";
import "./style.scss";
import { Textbox } from "components/Textbox/Textbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_TYPE } from "utils/constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";



interface Form{
    Title?:string,
    Method?:string,
    Status?:number,
    Task?:any,
    showForm:(e) => void,
    modalWidth?:any
}


export const AssignmentRules: React.FC<Form> = ({showForm,Task , Method, modalWidth}) => {
    const [radioList , setRadioList] = useState()
    const [assignmentMethod , setAssignmentMethod] = useState<any>( )
    const [mood , setMood] = useState("groups")
    const [Moodassign , setMoodassign] = useState("All")
    const [flag , setFlag] = useState(false)
    const [response , setResponse] = useState<any>([])
    const [assignmentResponse , setAssignmentResponse] = useState<any>([])
    const [selectList , setSelectList] = useState<any>([])
    const [assignedList , setAssignedList] = useState<any>([])
    const [savedData , setSavedData] = useState<any>([])
    const [assignedSelectList , setAssignedSelectList] = useState<any>([])
    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)
    const [massageType , setMassageType] = useState<any>("info")
    const [massageType2 , setMassageType2] = useState<any>("info")
    const [loading , setLoading] = useState<any>(false)
    const [loading2 , setLoading2] = useState<any>(false)
    const [loading3 , setLoading3] = useState<any>(false)
    const [positionstr , setPositionstr] = useState<any>()
    const [assignVariable , setAssignVariable] = useState<any>()
    const [positionAssignmentstr , setPositionAssignmentstr] = useState<any>()
    const [width, setWidth] = useState<number>(window.innerWidth);


    const breakPoint1 = modalWidth < 50 || width < 750
    const breakPoint2 =  modalWidth < 80 || width < 1100

    window.onresize = () => {
      setWidth(window.innerWidth)
  }

    let  Data:any = [], Dataassign:any = [];

    async function getPositionsListAsync(SearchString) {
      setLoading(false)
        try {
        if(mood=="groups"){
          const Groupresponse = await getGroupPositionList(SearchString); 
          let list:any=[]
          for (let x in Groupresponse.Data) {
              list.push({Id:x ,TU_RELATION:2 ,Title: trans(Groupresponse.Data[x])}) 
          }
          setResponse(list)
        }else if(mood=="users"){
         const Positionresponse = await getPositionList(SearchString);
         let list:any=[]
         list = Positionresponse.Data.map(item=>{
            return ({
              Id:item.Id ,
              TU_RELATION : 1 ,
              Title:item.Description,
            })
         })
         setResponse(list)    
        }else{
            const Groupresponse = await getGroupPositionList(SearchString); 
            let list2:any=[]
            for (let x in Groupresponse.Data) {
               let obj:any =new Object
               obj.Id=x
               obj.TU_RELATION=2
               obj.Title=trans(Groupresponse.Data[x])
               list2.push(obj) 
            }
            const Positionresponse = await getPositionList(SearchString); 
            let list:any=[]
            list = Positionresponse.Data.map(item=>{
               return ({
                 Id:item.Id ,
                 TU_RELATION : 1 ,
                 Title:item.Description,
               })
   
            })
            setResponse(list.concat(list2))   
        }
        } catch (error) {
          setMassageType("error")
        }

      setLoading(true)
  }
    async function getPositionsListSearchingAsync(SearchString) {
      const Groupresponse = await getGroupPositionList(SearchString); 
      let list2:any=[]
      for (let x in Groupresponse.Data) {
         let obj:any =new Object
         obj.Id=x
         obj.TU_RELATION=2
         obj.Title= trans(Groupresponse.Data[x]) 
         list2.push(obj) 
       }
      const Positionresponse = await getPositionList(SearchString); 
      let list:any=[]
      list = Positionresponse.Data.map(item=>{
        return ({
          Id:item.Id ,
          TU_RELATION : 1 ,
          Title:item.Description,
        })
     })
     setAssignmentResponse(list.concat(list2))   
  }

    async function setTaskUserAsync(data ) {
        const response = await setTaskUser(data  , Task.ParentId);    
        showForm(false)
        
      }
    async function getTaskUserAsync() {
      setLoading2(false)
      try {
      const TaskUserresponse = await getTaskUser(Task.Id); 
      setAssignVariable(TaskUserresponse.Data.AssignVariable)
      setAssignmentMethod(TaskUserresponse.Data.AssignType)
      let makeData:any =[] 
      if(TaskUserresponse.Data.BpmnTaskUserList){
          TaskUserresponse.Data.BpmnTaskUserList.map(item=>{
          makeData.push({Id:item.USR_UID , TU_RELATION:item.TU_RELATION , Title:trans(item.Description) })
        })   
        setSavedData(makeData)
        setAssignedList(makeData)
      }
      } catch (error) {
        setMassageType2("error")
      }
      setLoading2(true)
    }

    async function getRadioListAsync() {
      setLoading3(false)
      try {
        const List = await getRadioList(); 
        setRadioList(List?.Data)
      } catch (error) {
       
      }
      setLoading3(true)
      }

    function changeDataGrid(e) {
        setMood(e)
      }
    function hide() {
      showForm(false)
      }

    function changeDataGridassign(e) {
        setMoodassign(e)
      }
    function backward(newList= selectList) {
        setAssignedSelectList([])
        setResetMultiSelect(!resetMultiSelect)
        setAssignedList(assignedList.concat(newList) )
        setFlag(!flag)
        setSelectList([])
      }
    function forward() {
      setResetMultiSelect(!resetMultiSelect)
        let list:any =[]
        assignedList.forEach((item1,index1) => {
          let flag = true
          assignedSelectList.forEach((item2 , index2 ) => {
              if(item2.Id ==item1.Id ){
                flag = false
              }
          });
          if(flag){
            list.push(item1)
          }
        });
        setAssignedList(list)
      
      }
    const selectListCallBack =(list)=>{         
      setSelectList(list[1])
    }
    const assignedselectListCallBack =(list)=>{
      setAssignedSelectList(list[1])
    }

    const referenceFormSubmit =(e)=>{
        e.preventDefault()
if( !e.target.assignmentMethod.value || e.target.assignmentMethod.value ==""){ 
          Toast("Assignment Method can not be blank " , "error")
        }else{ 
           let TAS_UID =Task.Id.replace(/-/g,"")
          e.preventDefault();
          let Data:any = []
          let data={
            "TaskTitle": Task.Description,
            "TAS_UID":TAS_UID,
            "AssignType": e.target.assignmentMethod.value,
            "AssignVariable": e.target.assignmentMethod.value == "EVALUATE" ? assignVariable : "",
            "BpmnTaskUserList": []
          }
          assignedList.map(item=>{
        
          let obj =  {
               "TAS_UID":TAS_UID ,
               "USR_UID": item.Id,
               "TU_RELATION": item.TU_RELATION,
               "Description":trans(item.Title),
            }
            if(item?.Title?.length > 0){
              Data.push(obj) 
            }
          })
          data.BpmnTaskUserList = Data.length>0 ? Data : []
          setTaskUserAsync(data )
        }
      }
    const searching =(e)=>{
      setPositionstr(e == "" ? null:e)
      getPositionsListAsync(e == "" ? null:e)
    }
    const assignmentsearching =(e)=>{
      setPositionAssignmentstr(e == "" ? null:e)
      getPositionsListSearchingAsync(e == "" ? null:e)
    }
    const copySelectedList =(e)=>{
      localStorage.setItem("storageCopyPosition" ,JSON.stringify(Dataassign) )
      Toast(trans("TextCopiedToClipboard")  , "info")
    }
    function pasteSelectedList() {
      const List:any = localStorage.getItem("storageCopyPosition" )
      setSelectList(JSON.parse(List) )
      backward(JSON.parse(List))
      
     // addNewRoles(JSON.parse(List) )
   
   }

    useEffect(() => {
        getPositionsListAsync(positionstr);     
        getPositionsListSearchingAsync(positionAssignmentstr)
      }, [mood]);

    useEffect(() => {
        getTaskUserAsync();
        getRadioListAsync(); 
    }, []);

      if(response && assignedList ){
        let assignmentdata:any =[]
        assignedList.forEach(elm => {
          if(Moodassign=="groups"){
            if(elm.TU_RELATION==2)assignmentdata.push(elm)
          }else if(Moodassign=="users"){
            if(elm.TU_RELATION==1)assignmentdata.push(elm)
          }else{
            assignmentdata.push(elm)
          }
        });
        
        assignmentResponse.forEach(item1 => {
          let flag =false
          assignmentdata.forEach(item2 => {
            if(item1.Id == item2.Id){
              flag = true
            }
          });
          if(flag){
            Dataassign.push(item1)
          }         
        });
         response.map(item1=>{
          let flag = true
          assignedList.forEach(item2 => {
            if(item1.Id ==item2.Id){
              flag=false
            }
          });
          if(flag){
            Data.push(item1) 
          }
     })
    }
    return ( 
          <form onSubmit={referenceFormSubmit}>
                <div className="mb-4">
                  <FormWithTitle  title="AssignmentMethod">
                    <div className="card-body  py-0"> 
                      {
                        loading3 ?
                          <div className="row  col-lg-12 ">
                              {
                                  (radioList &&assignmentMethod) && Object.values(radioList).map((item , index)=>(
                                      <div key={index} style={{height:assignmentMethod == "EVALUATE" ? "52px"  : '20px' ,width:"20%" , padding:"0"}} className={`${(breakPoint1 && 'col-12') || (breakPoint2 && 'col-6') } my-1`}>
                                          <CheckableButton  
                                          labelNowrap={true}
                                              readOnly={false}  
                                              labelText={item } 
                                              defaultValue={item}
                                              onclick={()=>{ setAssignmentMethod(item)}}
                                              defaultChecked={item==assignmentMethod} 
                                              inputName={"assignmentMethod"} 
                                              type={"radio"}
                                              />
                                        {(assignmentMethod == "EVALUATE" && item==assignmentMethod )&& <>
                                           <div className="col-md-12 col-sm-12 mr-5">
                                                <Textbox required={true}  placeholder={trans("Condition") }  onChange={(e)=>{setAssignVariable(e.target.value)}} textboxName={"AssignVariable"} textboxType="Condition"    defaultValue={assignVariable} />
                                           </div>               
                                        </>
                                        }
                                      </div>
                                  ))
                              }  
                        </div>
                        : <Loading/>
                      }
                       </div>
                       </FormWithTitle>
                  </div>
                   <div style={{border:"none"}} className="card"> 
                    <div className="body">
                       
                       <div style={{width:"100%" ,paddingBottom: "39px"}}>
                       <FormWithTitle title="AvailablePositions">
                        <div className="">
                          <div className="d-flex justify-content-between align-items-center mb-1  ">
                            
                            <Search onChange={searching} />
                           </div>
                          <div className={`${breakPoint1 ? 'flex-column justify-content-end' : 'flex-row justify-content-between align-items-center'} d-flex mt-2 mb-2`}>
                            <CheckableButton onclick={changeDataGrid} defaultChecked={mood=="groups"} checked={mood=="groups"} defaultValue={"groups"} labelText={trans("groupPositions")} inputName={"AvailableUsersList"} type={"radio"}/>
                            <CheckableButton width={"80%"}  onclick={changeDataGrid} defaultChecked={mood== "users"} checked={mood== "users"} defaultValue={"users"} labelText={trans("Positions")} inputName={"AvailableUsersList"} type={"radio"}/>
{/*                             <CheckableButton width={"34%"} onclick={changeDataGrid} checked={mood=="All"?true : false} defaultValue={"All"}  labelText={trans("All")}  inputName={"AvailableUsersList"} type={"radio"}/>
 */}                          </div>   
                          <div className="tableBoxInAccesses">
                            {loading ?
                                 <>
                                    {response ? <DataGrid
                                          theadData={["Id" ,"Title"]}
                                          multiSelect={true}
                                          parentName={"referencePosition"}
                                          tbodyData={Data}
                                          selectList={selectListCallBack}
                                          minCellWidth={80}
                                          resetMultiSelect={resetMultiSelect}
                                          scrollHeight={"230px"}
                                          />
                                          : <Message message={"NoDataForPreview"} type={massageType} />
                                  }                               
                                 </>
                                 : <Loading/>
                            }

                          </div>
                        </div>
                        </FormWithTitle>
                       </div>
                                
                        <div className="col-lg-1 col-md-1 d-flex  flex-lg-column flex-md-column justify-content-center align-items-center">
                        <OverlayTrigger
                            placement="top"
                            delay={{ "show": 600, "hide": 0 }}
                            overlay={
                                <Tooltip className="tooltip"  >
                                    {trans("copy")}
                                </Tooltip>
                            }
                        >
                        
                        <FontAwesomeIcon onClick={copySelectedList}  style={{width:"20px" }} icon={[ICON_TYPE, "copy"]} color="gray" size="2x" className="icon mt-1 " />
                        </OverlayTrigger>
                 
                 
                            <ButtonArrow type="backward" onClick={()=>{backward()} }/>
                            <ButtonArrow type="forward" onClick={()=>{forward()}}/>
                 
                        <OverlayTrigger
                            placement="top"
                            delay={{ "show": 600, "hide": 0 }}
                            overlay={
                                <Tooltip className="tooltip"  >
                                    {trans("paste")}
                                </Tooltip>
                            }
                        >
                        
                        <FontAwesomeIcon onClick={pasteSelectedList} style={{width:"20px" }} icon={[ICON_TYPE, "paste"]} color="gray" size="2x" className="icon mt-1 " />
                        </OverlayTrigger>

                          
                        </div>

                        <div  style={{width:"100%" ,paddingBottom: "39px"}}>
                          <FormWithTitle title="AssignedPositions">
                           <div className="  ">
                        <div className="d-flex justify-content-between align-items-center mb-1 ">
                           
                          <Search onChange={assignmentsearching} />
                        </div>    
                        <div className={`${breakPoint1 ? 'flex-column justify-content-end' : 'flex-row justify-content-between align-items-center'} d-flex mt-2 mb-2`}>
                          <CheckableButton  onclick={changeDataGridassign} defaultChecked={Moodassign=="groups"} checked={Moodassign=="groups"} defaultValue={"groups"} labelText={trans("groupPositions")} inputName={"AssignedUsersList"} type={"radio"}/>
                          <CheckableButton width={"80%"} onclick={changeDataGridassign}defaultChecked={Moodassign== "users"} checked={Moodassign== "users"} defaultValue={"users"} labelText={trans("Positions")} inputName={"AssignedUsersList"} type={"radio"}/>
                         <CheckableButton width={"34%"} onclick={changeDataGridassign} defaultChecked={Moodassign=="All"}  checked={Moodassign=="All"} defaultValue={"All"}  labelText={trans("All")}  inputName={"AssignedUsersList"} type={"radio"}/>
                       </div>    
                          <div   className="tableBoxInAccesses">
                              {loading2 ?
                                     <>
                                        {Dataassign ?                         
                                            <DataGrid
                                                  theadData={["Id" ,"Title"]}
                                                  multiSelect={true}
                                                  parentName={"referencePositionAssigned"}
                                                  tbodyData={Dataassign}
                                                  selectList={assignedselectListCallBack}
                                                  minCellWidth={80}
                                                  resetMultiSelect={resetMultiSelect}
                                                  scrollHeight={"230px"}
                                              />  
                                              : <Message message={"NoDataForPreview"} type={massageType2} />
                                      }                               
                                     </>
                                     : <Loading/>
                                }
                          </div>
                           </div>  
                           </FormWithTitle>
                        </div>  
                    
                    </div>
                </div>
                <div className="col-lg-12 mt-3 btns_form">
                <Button value="Save" btnType="submit" />
                <Button
                  btnType="cancel"
                  value="Cancel"
                  onClick={()=>Toast(trans("modalclosemessage") , "warning" , "alert" , hide )}
                />
              </div>
          </form>
    );
}