import { Widget } from 'components/Widget/Widget'
import { getpersonalTask } from 'pages/DashboardPage/api/api'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'


interface props {
    id: string
}

export const MyOwnRequests = ({id}:props) => {
    const [Requests, setRequests] = useState<any>(null)
    // async function getRequestsAsync() {
    //     try {
    //     const res = await getRequests()
    //     setRequests(res)        
          
    //     } catch (error) {
    //         setRequests([])
    //     }
    // }
    // useEffect(() => {
    //     getRequestsAsync()
    // }, [])
    return (
        <>
        {(Requests&&Requests.length >0) &&
        <Widget title='PersonalTask' icon={['fas', 'swatchbook']} color='success' id={id}  >
            <div className='widget-calendar p-2'>
                <ul>
                    {Requests ?
                    <>
                    {Requests.length > 0 ?
                      <>
                         {
                             Requests.map((item, index) => (
                                 <div className={`widget-calendar__list d-flex justify-content-between `}>
                                  <Link to={'/my-own-requests'}>
                                          {/* <li  style={{fontSize: "13px"}} className='mb-1' key={index}>{item.Requests}</li> */}
                                  </Link>


                              </div>
                          ))
                        }
                        </>
                        :
                        <></>
                        
                    }
                    </>
                        :
                        <div className="d-flex justify-content-center align-items-center text-info h-100">
                           <div className="spinner-border spinner-border-md" role="status"></div>
                        </div>
                    }
                </ul>
            </div>
        </Widget>
        }
     </>
    )
}