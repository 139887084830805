import { useEffect, useState, useRef } from "react";
import { trans } from "utils/helpers";
import { getBpmnTaskApi } from "./api";
import { setTimeout } from "timers";
import { SubMenu } from "../SubMenu/SubMenu";
interface BpmnTaskPropsType {
    label?: string,
    processId?: string,
    hasBorder?: boolean,
    required?: boolean,
    value?:string,
    mode?:string
    resetForm?:any,
    Change?: (e) => void,
    setBpmnTask?: (e) => void,
}

export const BpmnTask = ({ processId ,label, hasBorder,value,mode ,required , resetForm ,Change , setBpmnTask}: BpmnTaskPropsType) => {
    const [BpmnTask, setBpmnTasks] = useState<any>();
    const [valueName, setValueName] = useState<any>();
    const [flag , setFlag] = useState(false);
    const [show , setShow] = useState(false);
    const [validat , setValidat] = useState(false);
    const [rquird , setRquird] = useState(false);
    const [activeId , setactiveId] = useState<any>();
    const inputRef = useRef<any>();
    const inputIdRef = useRef<any>();
    const BpmnTaskRef = useRef<any>(BpmnTask);
   const activeIdRef = useRef<any>(activeId);
   const ShowRef = useRef<any>(show);
   const validRef = useRef<any>(false);
     

    async function getBpmnTaskListAsync(search,id) {
        try{
           const res = await getBpmnTaskApi(search,id ,processId);
           if(id){
            setValeu(res[id] , id)
            setValueName(res[id])
           }
             setBpmnTasks({ ...res})        
             BpmnTaskRef.current = res
             setFlag(!flag)
        }catch(err:any){
        
          }
      }

    const Auto = (e)=>{
      validRef.current = false
      setValidat(false)
      setRquird(false)
      getBpmnTaskListAsync(e.target.value,"")
      setFlag(!flag)

    }
    const CheckBpmnTask =async (e)=>{
      setTimeout(() => {     
        if(!validRef.current){          
          if(BpmnTask!=undefined &&  Object.values(BpmnTask).length == 1){
            setValeu(Object.values(BpmnTask)[0] , Object.keys(BpmnTask)[0])
            e.target.setCustomValidity("");
          }else{
            if(inputRef.current){
            setValeu(null,null)        
          }
          if(inputRef?.current?.value.length >0 && inputRef?.current?.value!=""){
            e.target.setCustomValidity(trans("Msg_Validation_ValueIsIncorrect"));
            setValidat(true);
            e.target.reportValidity();
          }
          setBpmnTask && setBpmnTask(null);
        }

        }
      }, 200);
    }

    const setValeu = (newValue , id)=>{
      if(inputIdRef != null && inputIdRef.current != null){

      validRef.current = true
      inputRef.current.value=newValue;
      inputIdRef.current.value=id;
      BpmnTaskRef.current = null;
      ((value==null &&id)|| id != value) ? Change&&Change({"person" :newValue}):Change&&Change({"person":null});

      setBpmnTasks(null)
      setFlag(!flag)
      inputRef.current.setCustomValidity("")
      setBpmnTask && setBpmnTask({Value : inputRef.current.value , Id : id})
      setValidat(false)
      }
    }
    useEffect(() => {
       if(value){
        getBpmnTaskListAsync(null,value)
       }else if(value == null){
        setValueName(null)
       }
      }, [value]);

      useEffect(() =>{
        if(show ){
          document.body.addEventListener("click", clickToclose)
        } 
     return () => { document.body.removeEventListener('click', clickToclose) }
    
        
      },[show ])
    
      function clickToclose(e){
        let input = document.querySelector("#browserBpmnTaskId");
        if (e.target.id !== input?.id) { 
          setShow(false);
        }
      }
        return (
     <>
     <div className="text-box">
     <label htmlFor="browser" className="text-box__label">{trans("tasktitle")}</label>
     {required && <span className="text-box__required-sign-for-label text-danger">*</span>}
     <input required={required} defaultValue={value && value} name="BpmnTaskId" className="d-none" ref={inputIdRef} />
     <input required={required} onBlur={CheckBpmnTask} defaultValue={valueName} autoComplete="off" onFocus={(e)=>{  Auto(e) ; setShow(true);}}  ref={inputRef} list="BpmnTaskList" name="browser" className="text-box__box form-control "    id={"browserBpmnTaskId"} onMouseDown={(e)=>e.stopPropagation() } onChange={Auto} disabled={mode =="view" ? true : false} />
      {
        show && 
        <SubMenu  onClick={setValeu} options={BpmnTask} onHide={()=>{setShow(false)}} />
      }  
       <div className="text-box__msg-wrapper">
        {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
        {required && <span className={`${rquird ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsRequired", [""])}</span>}
        </div>    
     </div>
     </>
    )
}