
import { DataGrid } from "components/DataGrid/DataGrid";
import { useContext, useEffect, useState } from "react";
import { Toast } from "components/ToastShow/ToastShow";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { GetReportWithJsonData, downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { getDataForPrint, getLetterList } from "./api/api";
import { LetterForm } from "./components/LetterForm/LetterForm";
import { Toolbar } from "components/Toolbar/Toolbar";
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment";
import { ENDPOINTS } from "utils/endpoints";
import { ReserveForm } from "./components/ReserveForm/ReserveForm";




interface Form {
    Title: string,
    Status: number
}
export const  LettersPage = ({  }:Form) => {

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [forceUpdate, setForceUpdate] = useState(false); // integer state
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [mode, setMode] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [showLetterForm, setShowLetterForm] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [curentTab ,setCurentTab] = useState<any>(2)
    const [pages ,setPages] = useState<any>(1)
    const [secretariatId ,setSecretariatId] = useState<any>("")
    const [filterlist, setFilterlist] = useState<any>("");
    const [showAttachment, setShowAttachment] = useState(false);
    const [showReserveForm, setShowReserveForm] = useState(false);
    const [search, setSearch] = useState(null);
    const [filterAndSort, setFilterAndSort] = useState<any>(null);
    const layoutContext = useContext(LayoutContext);




    const searchHandler = (str) => {
        setPageNum(1);
        setSelectedObj(null);

        if (str === "") {
            setSearch(null)
            getLetterListAsync(1, null )
    
        } else {
            setSearch(str)
            getLetterListAsync(1 ,str)
        }
    }

    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("LettersPage", e.currentTarget.value)
        getLetterListAsync(1);
    };
    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else setPageNum(+value); getLetterListAsync(+value);
        } else {
        }
    };
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getLetterListAsync(pageNum + 1);
          }
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum(pageNum - 1);
            getLetterListAsync(pageNum - 1);
          }
    };
    const selectedRowCallback = (obj, index) => {
        setSelectedObj(obj);
    };
    const getDataForPrintAsync =async (id) => {

        if(selectedObj){
            if(id.includes("Design")){
                const res = await getDataForPrint(selectedObj?.LetterId)
                GetReportWithJsonData( id.replace("Design" , ""), res.Data ,2)
            }else if(id.includes("Default")){
                const res = await getDataForPrint(selectedObj?.LetterId)
                GetReportWithJsonData( id.replace("Default" , ""), res.Data ,2)
                
            }else{
                const res = await getDataForPrint(selectedObj?.LetterId)
               GetReportWithJsonData( id, res.Data ,1 )
                
            }
            
        }
        try {

        } catch (error) {
            
        }
    };

    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "finish": alert("call api finish " ); break;
            case "current": alert("call api current " ); break;
            case "archive": alert("call api archive " ); break;
            case "view": if (selectedObj?.Id) {if(selectedObj.LetterId!=null){setMode("view");setShowLetterForm(true);}else Toast(trans("Msg_ReservedLetterHasNoDataToViewOrEditPermission"), "warning")    } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "excel": downloadCSV(response?.Data ,4 ,trans("Letters")); break;
            case "edit": if (selectedObj?.Id){if(selectedObj.LetterId!=null){setMode("edit");setShowLetterForm(true);}else Toast(trans("Msg_ReservedLetterHasNoDataToViewOrEditPermission"), "warning")    } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "create": setMode("create");setSelectedObj(null) ; setShowLetterForm(true); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "reserve":setMode("edit"); setShowReserveForm(true); break;
            case "attach": if (selectedObj?.Id) {
                setMode("edit")
                setShowAttachment(true)

            } else {
                Toast(trans("Msg_SelectRow"), "warning")
            } break;
        }
    };

    async function getLetterListAsync(PageNum = pageNum ,searchSTR=search) {
        setLoading(false);
        setResponse(null);
        let secretariat = secretariatId.length ? secretariatId : localStorage.getItem("SecretariatFilter")
        let pageSizeChangedByUser = getPageSize("LettersPage" )
        setPageSize(pageSizeChangedByUser)
        try {                                 
            const res = await getLetterList(PageNum, pageSizeChangedByUser ,searchSTR, filterAndSort.OrderFormat?.join(",") , secretariat,filterAndSort.Filterlist   );
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) 
            setResponse(res.Data);     
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)           
    }
    useEffect(() => {
        let settings = getGridSetting("LettersPage")
        setFilterAndSort({ Filterlist: settings[0], OrderFormat: settings[1] })

    }, []);
    useEffect(() => {
        setResponse(null)
        if(filterAndSort != null){
            getLetterListAsync();

        }
    }, [forceUpdate ,filterAndSort  , secretariatId  ]);



 
    async function selectedProcessFilterCallback (id: string) {
        setSecretariatId(id)
    }


    //with pagination 
  
    /* #endregion */


    return (
        <>
            <Toolbar  Print={false} objectTag={"LetterForm"} handleSearch={searchHandler} handlePrint={getDataForPrintAsync}   reserve={true} id={selectedObj?.Id}  handleSelectedProcessFilterToolbar={selectedProcessFilterCallback} attach={true}   view={true} refresh={true}   emptyList={response?.TotalRecords == 0}  edit={true} search={true}   handleClickToolbar={clickToolbarCallback} />
            <div className="col-lg-12 mt-0" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                <div className="list-card card">
                    <div className="list-card__body">
                        {loading ?
                            (response?.Data?.length > 0 ?
                                <DataGrid
                                    handlefiltering={(str:any) => {str.Filterlist != undefined  ? setFilterAndSort(str) : setFilterAndSort({...filterAndSort, Filterlist:str})}}
                                    filterStr={filterAndSort.Filterlist}
                                    orderFormatList={filterAndSort.OrderFormat}
                                    orderFormat={(order) => { setFilterAndSort({ ...filterAndSort, OrderFormat: order }) }}
                                    // selectItem={(e) => {   }}
                                    parentName={"LettersPage"}
                                    theadData={Object.keys(response.Data[0])}
                                    tbodyData={response.Data}
                                    startIndex={3}
                                    minCellWidth={80}
                                    selectedRowObjectCallback={selectedRowCallback}
                                    rowSelected={selectedObj}
                                    totalRecords={response.TotalRecords}
                                    pageNum={pageNum}
                                    pageSize={pageSize}
                                    HandleNextPage={handleNextPage}
                                    HandlePrevPage={handlePrevPage}
                                    handlechangePageSize={handleChangePageSize}
                                    first={() => {getLetterListAsync(pages); setPageNum(pages)}}
                                    end={() =>{getLetterListAsync(1);  setPageNum(1)}}
                                    handleGoPage={handleGoPage}
                                    DoubleClick={()=>setShowLetterForm(true)}
                                />
                                : <Message message={catchMessage.msg} type={catchMessage.type} />)
                            : <Loading />
                        }
                    </div>
                </div>

             {showLetterForm &&
                    <ModalForm modalHeight={90} mode={mode} title={"LetterForm"} onHide={() => { setShowLetterForm(false) }} show={showLetterForm}>
                        <LetterForm onHide={() => { setShowLetterForm(false); setForceUpdate(!forceUpdate) }} 
                            reload={()=>setForceUpdate(!forceUpdate)} mode={"view"} editInIndicator={mode == "edit" } processId={curentTab}
                            Id={selectedObj?.LetterId }    />                        
                    </ModalForm>
                }

            {showAttachment &&
                <ModalForm modalHeight={60} mode={mode} title={("Attachment")} onHide={() => { setShowAttachment(false) }} show={showAttachment}>
                    <Attachment haveVersion={true} onHide={() => { setShowAttachment(false); setForceUpdate(!forceUpdate) }} mood={mode} endpoint={ENDPOINTS.secretariat.indicator.attachment} ownerId={selectedObj?.LetterId} />
                </ModalForm>
            }
            {showReserveForm &&
                <ModalForm mode={mode} modalHeight={35} title={("ReserveForm")} onHide={() => { setShowReserveForm(false) }} show={showReserveForm}>
                    <ReserveForm onHide={() => { setShowReserveForm(false); setForceUpdate(!forceUpdate) }} mode={mode} />
                </ModalForm>
            }
            </div>
        </>
    )
}