import { Button } from "components/Button/Button";
import DatePicker from "components/DatePicker/DatePicker";
import { RangeSlider } from "components/RangeSlider/RangeSlider";
import { Textbox } from "components/Textbox/Textbox";
import { Time } from "components/Time/Time";
import { Toast } from "components/ToastShow/ToastShow";
import { Action } from "pages/OfficeAutomation/Cartable/types";
import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { getAction, sendAction } from "../../api/api";
import { Loading } from "components/Loading/loading";
import { DateObject } from "react-multi-date-picker";

import persian from "react-date-object/calendars/persian";
interface ActionFormPropsType {
    id?: any,
    mode?: any,
    referenceId: any,
    onHide: () => void
    reset: (ans: boolean) => void
}

export const ActionForm = ({
    id,
    mode,
    referenceId,
    onHide,
    reset,
}: ActionFormPropsType) => {

    let data, method;
    const [action, setAction] = useState<Action | any>(undefined);
    const [fromDate, setFromDate] = useState<any>();
    const [untilDate, setUntilDate] = useState<any>();
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const [actualTimeAlert, setActualTimeAlert] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const formRef = useRef<any>("")
    const buttonRef = useRef()
    const [loading , setLoading] = useState(false)  
    const [leaveHours, setleaveHours] = useState<any>(null);
    const [userChanges, setUserChanges] = useState({});
    const [flag,setFlag] = useState(false);
    const [todayDate, settodayDate] = useState<string | null>(new Date().toISOString());
    const [startDateView, setStartDateView] = useState<Date>(new Date());

    //const buttonRef = createRef<any>()

    const validationDates = () =>{
        if(fromDate &&endTime&& untilDate &&startTime && Date.parse(fromDate.split("T")[0]+"T"+startTime.split("T")[1]) > Date.parse(untilDate.split("T")[0]+"T"+endTime.split("T")[1])){
            setFlag(true)
        }else{
            setFlag(false)
        }
    }

    async function formSubmit(event) {
        event.preventDefault();
        if(flag){
            Toast(trans("InvalidEndDate"),"error");
        }else{
        if (actualTimeAlert) { // هشدار به کاربر درباره بررسی مجدد فیلد زمان واقعی
            setActualTimeAlert(false);
        } else {
            if (id && mode !== "create") {
                data = {
                    Id: id,
                    ReferenceId: referenceId,
                    Description: event.target.description.value,
                    StartTime:  fromDate.split("T")[0]+"T"+startTime.split("T")[1],
                    EndTime: untilDate.split("T")[0]+"T"+endTime.split("T")[1],
                    ActualDuration: +event.target.ActualTimeInput[0].value,
                    Progress: Number(event.target.progressbar.value),
                };
                method = "put";
            } else {
                data = {
                    ReferenceId: referenceId,
                    Description: event.target.description.value,
                    StartTime:  fromDate.split("T")[0]+"T"+startTime.split("T")[1],
                    EndTime: untilDate.split("T")[0]+"T"+endTime.split("T")[1],
                    ActualDuration: +event.target.ActualTimeInput[0].value,
                    Progress: Number(event.target.progressbar.value),
                };
                method = "post";
            }        
            try {
                await sendAction(data, method);
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                setResetForm(true);
                reset(true);
                onHide();

            } catch (error) {

            }
        }
    }
    }
    
    async function getDataAsync() {
        setLoading(false)
        try {
           const res  = await getAction(id); 
           setAction(res.Data);  
           setleaveHours( res.Data.ActualDuration)
            } catch (error) {
            // setAction(null); 
        }
        setLoading(true)

    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alert", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                onHide()
            });
        }else{
            onHide()
        };
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        if (id && mode !== "create"){
            getDataAsync()
        }else{
            setAction(null)
            setLoading(true)
            
        }
    }, [id , mode]);

    useEffect(() => {
        if ( mode === "create"){
            setResetForm(true);
        }
    }, [ mode]);

    useEffect(() => {
        if (resetForm) {
          
            setResetForm(false);
        }

    }, [resetForm]);
    useEffect(() => {
        if (todayDate) {
            let data =  new Date(startDateView);
             let Hour =  new Date().getHours()
             let Minutes =  new Date().getMinutes()
            data.setHours(Hour,Minutes-1 )
            setStartDateView(data);
        }

    }, [todayDate]);



 
 useEffect(() => {
    if(untilDate && fromDate){
            try {
                let startTimeMilisecond = startTime.split("T")[1].split(":")
                let endTimeMilisecond = endTime.split("T")[1].split(":")
                startTimeMilisecond[2]="00"
                endTimeMilisecond[2]="00"
                let fr =fromDate.split("T")[0]+"T"+startTimeMilisecond.join(":");
                let en = untilDate.split("T")[0]+"T"+endTimeMilisecond.join(":");
                setleaveHours( Math.floor( Math.abs(new Date(fr).getTime()-new Date(en).getTime())/60000) ==0 ? 1 :Math.floor( Math.abs( Math.ceil(new Date(en).getTime()) - Math.ceil(new Date(fr).getTime()))/60000) )
            } catch (error) {
                setleaveHours( null)
            }
    }
}, [untilDate ,fromDate ,startTime ,endTime  ]);

useEffect(()=>{
    validationDates()
  },[untilDate,fromDate,startTime ,endTime  ])

    return (
        <form  className="action-form py-2" ref={formRef} onSubmit={formSubmit}>
            {loading ? 
            <div className="card">
                <div className="card-body">
                    <div className="container">
                        <div className="row ">
                            <div className="col-12 col-lg-4">
                                <DatePicker
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                    required={true}
                                    mode={mode}
                                    onOpenPickNewDate={true}
                                    lable="fromDate"
                                    setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                                    val={action !=undefined ?(action==null ? null : action?.StartTime ): todayDate } //resetForm ? new Date().toISOString() : 
                                    errorFlag={flag}
                                />
                            </div>
                            <div className="col-12 col-lg-4 mt-2 mt-lg-0">
                                <DatePicker
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                    required={true}
                                    mode={mode}
                                    onOpenPickNewDate={true}
                                    maxDate={new DateObject({ calendar: persian })}
                                    lable="untilDate"
                                    setIsoTime={(isoTime: string) => { setUntilDate(isoTime) }}
                                    val={action !=undefined ?  (action==null ? null : action?.EndTime ): todayDate } //resetForm ? new Date().toISOString() : 
                                    errorFlag={flag}
                                />
                            </div>
                        </div>
                        <div className="row ">
                        <div className="col-12 col-lg-4">
                           <DatePicker
                                required={true}
                                onOpenPickNewDate={true}
                                mode={mode}
                                lable="StartTime"
                                setIsoTime={(isoTime: string) => { setStartTime(isoTime) }}
                                val={action !=undefined ?  (action==null ? null : action?.StartTime ):  startDateView.toISOString() } //resetForm ? new Date().toISOString() : 
                                withTime ={true} 
                                disableDayPicker={true}
                                format={"HH:mm"}
                         />   
                        </div>
                        <div className="col-12 col-lg-4 mt-2 mt-lg-0">
                         <DatePicker
                            required={true}
                            onOpenPickNewDate={true}
                            mode={mode}
                            lable="endTime"
                            setIsoTime={(isoTime: string) => { setEndTime(isoTime) }}
                            val={action !=undefined ?  (action==null ? null : action?.EndTime ):todayDate } //resetForm ? new Date().toISOString() : 
                            withTime ={true} 
                            disableDayPicker={true}
                            format={"HH:mm"}
                            />   
                        </div>
                        <div className="col-12 col-lg-4 mt-2 mt-lg-0">
                            <Time
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                required={true}
                                mode={mode}
                                max={leaveHours}
                                name="ActualTimeInput"
                                IdString="ActualTimeInput"
                                resetForm={resetForm}
                                defaultValue={  leaveHours}
                                label="ActualDuration"
                            />
                        </div>
                        </div>
                        <div style={{marginTop:"-3%"}} className="row">
                            <div className="col-12">
                                <Textbox
                                    autoFocus={true}
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                    textboxType="textarea"
                                    textboxName="description"
                                    defaultValue={action?.Description}
                                    resetForm={resetForm}
                                    labelText="description"
                                    required={true}
                                    mode={mode}
                                />
                            </div>
                        </div>
                        <div  className="row mt-1 ">
                            <div className="col-12 ">
                                <RangeSlider Change={(e)=>{setUserChanges({...userChanges , ...e})}}  required={true} mode={mode} label="Progress" defaultValue={action?.Progress} name="progressbar" resetForm={resetForm} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-4 pt-2">
                            {mode !== "view" ?
                                <>
                                    <Button ref={buttonRef}   value="Save" btnType="submit" />
                                    <Button

                                        btnType="cancel"
                                        value="Cancel"
                                        onClick={() => {cancel()}}
                                    />
                                </>
                                :
                                 <Button
                                    btnType="primary"
                                    value="Close"
                                    onClick={() =>{cancel()}}
                                  />
                            }
                        </div>
                    </div>
                </div>
            </div>     
                :<Loading/>
            }
        </form>
    );
}