import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useRef, useState } from "react";
import { UnitDictionary } from "pages/InventoryAndSales/Inventory/BaseDefinitionAndSetting/Unit/components/UnitDictionary";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
import { trans } from "utils/helpers";
import { SelectBox } from "components/Select/Select";


interface UnitFormPropsType {
    id?: any,
    mode?: any,
    inTab?: any,
    formRef?: any,
    checkData?: any,
    data?:any,
    submitUnit?: (data) => void;

}

export const UnitForm = ({
    id,
    mode,
    data,
    inTab,
    formRef,
    checkData,
    submitUnit
    
 
  
}: UnitFormPropsType) => {


    const [resetForm, setResetForm] = useState(false);
    const [unites, setUnites] = useState<any>({});
    const[unitConversion,setUnitConversion] = useState<boolean>(data?.IsAbsoluteUnitConversionRatio )

    async function formSubmit(event) {
        event.preventDefault();       
        let data = {
            PurchaseUnitId: event.target.PurchaseUnitId.value=="null"?null:event.target.PurchaseUnitId.value,
            SalesUnitId:event.target.SalesUnitId.value=="null"?null:event.target.SalesUnitId.value,
            IsAbsoluteUnitConversionRatio: event.target.IsAbsoluteUnitConversionRatio.checked,
            MainUnitId: event.target.MainUnitId.value,
            QtyMainUnit: event.target.QtyMainUnit.value==""?null:event.target.QtyMainUnit.value,
            QtyStatisticalUnit : event.target.QtyStatisticalUnit.value==""?null:event.target.QtyStatisticalUnit.value, 
            StatisticalUnitId:  (event.target.StatisticalUnitId.value==""||event.target.StatisticalUnitId.value=="null")?null:event.target.StatisticalUnitId.value,
            PriceListUnitId:  (event.target.PriceListUnitId.value==""||event.target.PriceListUnitId.value=="null")?null:event.target.PriceListUnitId.value,


        }

        submitUnit&&  submitUnit(data)
    }
    




    useEffect(() => {
        if (resetForm) {
          
            setResetForm(false);
        }

    }, [resetForm]);


    return (
        <form className="Unit-form py-2" name="Unit" id={"ItemForm"} ref={formRef} onSubmit={formSubmit}>
        <div className="card">
            { (mode != "create" && data)||(mode == "create") ?
            
            <div className="card-body">
                <div className="row">
                    <div className="row">

                    
                <div className="col-3">
                    <UnitDictionary
                        value={data?.MainUnitId ? data?.MainUnitId : null}
                        label="MainUnit"
                        name="MainUnitId"
                        required={true}
                        mode={mode}
                        setUnitDictionary={(e)=>{setUnites((pr)=>{return {...pr,Main:e}})}}
                    />
                    </div>
                    <div className="col-3">
                    <UnitDictionary
                        value={data?.StatisticalUnitId ? data?.StatisticalUnitId : null}
                        label="StatisticalUnit"
                        name="StatisticalUnitId"
                        mode={mode}
                        setUnitDictionary={(e)=>{setUnites((pr)=>{return {...pr,Statistical:e}})}}
                        required={unitConversion}
                    />
                    </div>
                    </div>
                        <div className="row">
                            <div className="col-3">
                            <SelectBox
                              mode={mode}
                              lable={"PurchaseUnit"}
                              selectType="select"
                              value={data?.PurchaseUnitId ? data?.PurchaseUnitId : null}
                              options={["" ,unites?.Main?.Value,unites?.Statistical?.Value?unites?.Statistical?.Value:null ].filter(item=>item!=null)}
                              val={["null", unites?.Main?.Id , unites?.Statistical?.Id?unites?.Statistical?.Id:null].filter(item=>item!=null)}
                              name={"PurchaseUnitId"}
                            ></SelectBox>
                            </div>
                            <div className="col-3">
                            <SelectBox
                              mode={mode}
                              lable={"SalesUnit"}
                              selectType="select"
                              value={data?.SalesUnitId ? data?.SalesUnitId : null}
                              options={["" ,unites?.Main?.Value,unites?.Statistical?.Value?unites?.Statistical?.Value:null ].filter(item=>item!=null)}
                              val={["null", unites?.Main?.Id , unites?.Statistical?.Id?unites?.Statistical?.Id:null].filter(item=>item!=null)}
                              name={"SalesUnitId"}
                            ></SelectBox>
                            </div>
                            <div className="col-3">
                            <SelectBox
                              mode={mode}
                              lable={"priceListUnit"}
                              selectType="select"
                              value={data?.PriceListUnitId ? data?.PriceListUnitId : null}
                              options={["" ,unites?.Main?.Value,unites?.Statistical?.Value?unites?.Statistical?.Value:null ].filter(item=>item!=null)}
                              val={["null", unites?.Main?.Id , unites?.Statistical?.Id?unites?.Statistical?.Id:null].filter(item=>item!=null)}
                              name={"PriceListUnitId"}
                            ></SelectBox>
                            </div>
{/*                             <div className="col-3">
                                <UnitDictionary
                                    value={data?.PurchaseUnitId ? data?.PurchaseUnitId : null}
                                    label="PurchaseUnit"
                                    name="PurchaseUnitId"
                                    mode={mode}
                                />
                            </div>
                            <div className="col-3">
                                <UnitDictionary
                                    value={data?.SalesUnitId ? data?.SalesUnitId : null}
                                    label="SalesUnit"
                                    name="SalesUnitId"
                                    mode={mode}
                                />
                            </div> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mt-6 mb-2" >
                            <CheckableButton
                            defaultValue={data?.IsAbsoluteUnitConversionRatio }
                            defaultChecked={data?.IsAbsoluteUnitConversionRatio }
                            labelText={"IsAbsoluteUnitConversionRatio"}
                            inputName={"IsAbsoluteUnitConversionRatio"}
                            type="checkbox"
                            mode={mode}
                            onchange={setUnitConversion}
                            />                                
                        </div>
                    </div>
                    <FormWithTitle title={trans('unitConversion')}>
                        <div className="row">
                            <div className="d-flex align-items-center w-100">
                                <div className="d-flex align-items-center" style={{width:'140px'}}>
                                    <div style={{width:'80%'}}>
                                        <Textbox
                                            textboxType="number"
                                            textboxName="QtyMainUnit"
                                            resetForm={resetForm}
                                            defaultValue={unites?.Statistical  != null ?data?.QtyMainUnit ? data?.QtyMainUnit : null : null}
                                            labelText={trans('qty')+ " " + trans('MainUnit')}
                                            decimals={checkData?.MainUnitFloatingPoint	}
                                            fixedLanguage={"en"}
                                            mode={mode}
                                            />
                                    </div>
                                    <div className="me-2">
                                        {unites?.Main?.Value}
                                    </div>
                                </div>
                            <div className="font-size-lg mx-2" style={{fontWeight:'800'}}    >
                                =
                            </div>
                                <div className="d-flex align-items-center" style={{width:'140px'}}>
                                    <div style={{width:'80%'}}>
                                        <Textbox
                                            textboxType="number"
                                            textboxName="QtyStatisticalUnit"
                                            resetForm={resetForm}
                                            value={unites?.Statistical?(data?.QtyStatisticalUnit ? data?.QtyStatisticalUnit : null):""}
                                            defaultValue={unites?.Statistical != null ? data?.QtyStatisticalUnit ? data?.QtyStatisticalUnit : null : null}
                                            labelText={trans('qty')+ " " + trans('StatisticalUnit')}
                                            decimals={checkData?.MainUnitFloatingPoint	}
                                            fixedLanguage={"en"}
                                            mode={mode}
                                            />
                                    </div>
                                <div className="me-2">
                                    {unites?.Statistical?.Value}
                                </div>
                                </div>
                            </div>

                        </div>
                    </FormWithTitle>

            </div>
            :
            <Loading/>
            }
        </div>
        <button  type="submit"  className="d-none" id="hidebutton" name="hidebutton" >

        </button>
    </form>
    );
}