import React, { FC, InputHTMLAttributes ,useState} from "react";
import { trans } from "utils/helpers";
import './style.scss';
import { BrowseFile } from "components/BrowseFile/BrowseFile";

interface InputProps extends InputHTMLAttributes<HTMLInputElement>{
    id ?:string;
    lable?:string;
    required ?:boolean
    multiple ?:boolean
    maxLength?:number |any
    mode?:string
    type?:string
    accept?:string
    onChange?: (event) => void;
    fileClick?:any
    showBox?:any
    setFileClick?: (event) => void;
}

export const Input =React.forwardRef(({showBox, setFileClick ,fileClick , multiple ,mode , id,accept,lable,type ,onChange, required, ...rest}:InputProps, ref: any)=>{
    const disabled = (mode =="view" ? true : false);
    return(
        <div key={id} className="input-wrapper ">
            {(lable&&!showBox) ? <label className="text-box__label" htmlFor={id}>{trans(lable)} {required ? <span className='text-danger'>*</span> : ''} </label>: null}
            {type == "file" ?
                  
             <BrowseFile  disabled={disabled} showBox={showBox}setFileClick={setFileClick} fileClick={fileClick}      multiple={multiple} onChange={(e)=>{onChange && onChange(e)} }accept={accept} {...rest} />
            : 
            <input required={required}  id={id} {...rest} ref={ref}/>       
            }
            
        </div>
    )
})