import ksaFlag from "assets/image/ksaFlag.png";
import iranFlag from "assets/image/iranFlag.png";
import uflag from "assets/image/uflag.jpg";
import { getResources } from "layouts/DashboardLayout/components/Sidebar/api";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import React, { useContext, useEffect } from "react";
// import { ENDPOINTS } from "utils/endpoints";
// import { http } from "utils/http";

interface props {
  image: string;
  lang: string;
}
let language: any;
export const Image = (props: props) => {
  const layoutContext = useContext(LayoutContext);
  let language: any = localStorage.getItem("lang");
  if (language == null) {
    language = 'fa'
  }

  useEffect(() => {
    // layoutContext.setLang(language);
    if (language == "fa") {
      layoutContext.handleImgFlag(iranFlag)
    } else if (language == "en") {
      layoutContext.handleImgFlag(uflag)
    } else if (language == "ar") {
      layoutContext.handleImgFlag(ksaFlag)
    }
    else {
      layoutContext.handleImgFlag(iranFlag)
    }

    if (localStorage.getItem(language) == null) {
      // localStorage.setItem("lang","en");
      getResources().then(res => { if(res.data) localStorage.setItem(language, res.data) })
    }
    //   if (localStorage.getItem('fa') == null) {
    //    localStorage.setItem("lang","fa");
    //   getResourceApi().then(res=>{localStorage.setItem("fa", res)})
    // }
    // http.get(ENDPOINTS.resources).then((res) => {
    //   localStorage.setItem(language, res.data);
    // })
    //   .catch(err => {
    //      ;
    //     
    //   })

  }, [localStorage.getItem('lang')]);
  // 

  const getImgvDir = () => {
    layoutContext.handleImgFlag(props.image);
    localStorage.setItem("lang", props.lang);
    window.location.reload();
  };
  return (
    <div>
      <div>
        <img src={props.image} width="20" height="15" onClick={getImgvDir} />
      </div>
    </div>
  );
};

// export default withTranslation()(Image);