import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Toast } from "components/ToastShow/ToastShow";
import { getFile, getTokenFile, sendFileList } from "../api/api";
import { trans } from "utils/helpers";
import { ICON_TYPE } from "utils/constants";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { OrganizationType } from "types/enum";
import { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { ENDPOINTS } from "utils/endpoints";

interface props {
    index: any
    user: any
    replyList: any
    submitted: boolean
    received_message: boolean
    sendRepliesUp: (data: any) => void
    DeleteMessage: () => void
    CreateRequest: () => void
    setForwardMsg: (data: any) => void
    setForwardMsgList: (data: any) => void
    showCarouselFile: (data: any , name) => void
    attached_file: any
    forwardMsgList: any
    MessageData: any
}

export const MessageBox = ({index,DeleteMessage,CreateRequest,showCarouselFile,MessageData,forwardMsgList, attached_file=[], received_message, replyList,setForwardMsg,setForwardMsgList, sendRepliesUp, submitted, user }: props) => {
    const [isReplied, setIsReplied] = useState<boolean>(false)  
    const [load, setLoad] = useState<any>();
    const [playVoice, setPlayVoice] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);
    const [audioURL, setAudioURL] = useState<any>();
    const formTextbox = useRef<any>();
    const [showOperations, setShowOperations] = useState(false);
    const formButton = useRef<any>();
    const showOperationsRef = useRef<any>(false);
    const [rotate , setRotate] = useState<boolean>(false);
    let fristClick: Boolean = true;     
    const [longPressTriggered, setLongPressTriggered] = useState<any>(false);
    const timeout = useRef<any>();
    const target = useRef<any>();
    const lang = localStorage.getItem('lang')
    const layoutContext = useContext(LayoutContext);
   

    let date2 = new DateObject({date:MessageData.chatMessage.addTime.replace("T" , " ")  })
    date2.convert(persian, persian_fa)    
    let  sent_Date = date2.format("YYYY/MM/DD HH:mm")


    function  ownClick(e ,item , index){
        if(!item?.isDeleted){
            fristClick = true;
                 setTimeout(()=>{
                 if(fristClick){
                    let name:any = attached_file [index].fileName ? attached_file [index].fileName : attached_file [index].FileName
                            if(["pdf" , "txt" ,"jpeg"  , "png","jpg" ,"heic" ].includes(name.split('.').pop()) ){
                                showCarouselFile(attached_file,index)
                            }else{
                                getFileAsync(attached_file[index].id ?attached_file[index].id:attached_file[index].Id ,item?.fileName ? item?.fileName : item?.FileName )
                            }
                }
                },250)
        }
   }
     
     function  DblClick(e ,item , index){
        if(!item?.isDeleted){
              fristClick = false;
              getFileAsync(attached_file[index].id ?attached_file[index].id:attached_file[index].Id
                
                ,item?.fileName ? item?.fileName : item?.FileName )
        }
     }


     const onLongPress = (e ,item) => {
        e.stopPropagation()
        getFileAsync(attached_file.id  ,item?.fileName ? item?.fileName : item?.FileName )
        
      }
    
  
    
    const start = useCallback(
        (event , item) => {
         if(!item?.isDeleted){
            if ( event.target) {
                    event.target.addEventListener("touchend", {
                    passive: false
                });
                target.current = event.target;
            }    
            timeout.current = setTimeout(() => {
                onLongPress(event ,item);
                setLongPressTriggered(true);
            }, 1500);
        }
        },
        [onLongPress,  ]
    );

    const clear = useCallback(
        (event, item, shouldTriggerClick = true) => {
            timeout.current && clearTimeout(timeout.current);
            if(shouldTriggerClick && !longPressTriggered){
               // Press(event , item);
            }
             
            setLongPressTriggered(false);
            if ( target.current) {
                target.current.removeEventListener("touchend" ,{
                    passive: false
                });
            }
        },
        [ longPressTriggered]
    );





    function elementInViewport(el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;
      
        while(el.offsetParent) {
          el = el.offsetParent;
          top += el.offsetTop;
          left += el.offsetLeft;
        }
      
        return (
          top >= window.pageYOffset &&
          left >= window.pageXOffset &&
          (top + height) <= (window.pageYOffset + window.innerHeight) &&
          (left + width) <= (window.pageXOffset + window.innerWidth)
        );
      }

    const handleReplyOnclick = () => {
        formButton.current.blur()
        setIsReplied(!isReplied)
        sendRepliesUp({...MessageData  })
    }
    
    const copyText = (e) => {
        navigator.clipboard.writeText(e)
        Toast(trans("TextCopiedToClipboard")  , "success")
    }
    

    function _arrayBufferToBase64( buffer ) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
}



    async function getFileAsync(id , name) {

     try {   

        setLoading(true)
        
        if(name != "AppVoice.mp3" ){
              const tokenfile = await getTokenFile(id  );  
              const a = document.createElement('a');
              a.style.display = 'none';
              a.download  = name;
              a.href =  `${process.env.REACT_APP_CHAT_SERVICE_URL+ ENDPOINTS.messageHub.browse}/userToken=${tokenfile}?fileId=${id}`
              document.body.appendChild(a);
              a.click();
              
              document.body.removeChild(a);
            }else{
                const list = await getFile(id  );  
                setAudioURL( _arrayBufferToBase64(list))
                setPlayVoice(true) 
            }
        } catch (error) {
           
        }
        setLoading(false)

    }
    function isInList(e) {
        let flag = true

        forwardMsgList.forEach(item=>{
            if(item.chatMessage.id){
                if(item.chatMessage.id == e){
                    flag = false
                }
            }else{
                if(item.chatMessage.message == MessageData.chatMessage.message){
                    flag = false

                }else if (item.fileInformations){

                    if(Object.keys(item.fileInformations)  == attached_file  ){
                        flag = false
                    }
                }
            }
        })
        return !flag

    }

    function urlify(text) {
        var urlRegex = /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\%\#\.]?[\w\%\/-]+)*\/?/gi;
        if(urlRegex.test(text)){
            return text.replace(urlRegex, function(url) {
                let address = ""
                 if(/[a-z]+:\/\//.test(url)){
                    address = url
                 }else{
                    address = "http://"+url
                 }
              return  `<a style=text-decoration:underline target="_blank" href=${address}>${url}</a>`
              
            })

        }else{
            return false
        }

      }
    useEffect(()=>{
        if(( !replyList) && isReplied) setIsReplied(false);
    },[ replyList])
    useEffect(()=>{
        if(formTextbox.current ){
            elementInViewport(formTextbox.current)
        }
    },[])



    return(
        <div id={MessageData.chatMessage.id} ref={formTextbox} className={`   chat__chatbox d-flex flex-column w-100 my-2 text-end  ${received_message ? 'align-items-end' : 'align-items-start'}`}>
            <div className={`d-flex align-items-start ${received_message ? 'flex-row-reverse' : 'flex-row'}`}>
                <FontAwesomeIcon icon={['fas', 'user-circle']}  color='grey' className="mx-2 mt-1 chat__contact-list-avatar" />
                <div className="d-flex flex-column" style={{position:"relative"}}>
                    <div onClick={()=>{if(forwardMsgList.length >0)setForwardMsgList("") }} style={{whiteSpace: "pre-wrap" ,fontSize: "15px"}} className={`border pt-1 d-flex flex-column justify-content-end rounded p-2 ${ isInList(MessageData.chatMessage.id) ? 'border-primary' : ''}  ${received_message ? 'chat__chatbox-sender' : ''}`}>
                        <div  className={`d-flex ${received_message && 'flex-row-reverse'}`}>
                         <div  className={`d-flex ${received_message && 'flex-row-reverse'}`}>
                         { MessageData.chatMessage.id.includes("sending") ?
                            <FontAwesomeIcon style={{marginTop: "4px" ,width:"10px" , marginLeft: "4px"}} icon={['fas', 'clock']} flip={lang === 'fa' ? undefined : 'horizontal'} className=" text-secondary "/>
                        :
                            <>
                               {
                                MessageData.chatMessage.id.includes("notSend") ?
                                <>
                                  { <FontAwesomeIcon style={{marginTop: "2px" ,width:"10px", marginLeft: "4px"}} icon={ ['fas', 'circle-exclamation'] } flip={lang === 'fa' ? undefined : 'horizontal'} className=" text-secondary "/>}
                                </>
                                :
                                <>
                                {!received_message &&
                                   <>
                                   {(!MessageData.chatMessage.isRead) ? <FontAwesomeIcon style={{marginTop: "2px" ,width:"10px", marginLeft: "4px"}} icon={ ['fas', 'check']} flip={lang === 'fa' ? undefined : 'horizontal'} className=" text-primary "/>
                                      :   
                                        <>
                                           <FontAwesomeIcon style={{marginTop: "2px" ,width:"10px", marginLeft: "-5px"}} icon={ ['fas', 'check']} flip={lang === 'fa' ? undefined : 'horizontal'} className=" text-primary "/>
                                           <FontAwesomeIcon style={{marginTop: "2px" ,width:"10px", marginLeft: "4px"}} icon={ ['fas', 'check']} flip={lang === 'fa' ? undefined : 'horizontal'} className=" text-primary "/>  
                                        </>
                                    }
                                   </>
                                }
                                </>
                               }
                                       
                            </>
                        }                             
                         <span className="text-secondary fw-lighter mb-2 text-nowrap">{!received_message ? user?.Name : MessageData.sendUser}</span>
                        </div>


                        <button ref={formButton} onFocus={()=>{setShowOperations(true);showOperationsRef.current = true}} onClick={(e)=>{e.stopPropagation();}} style={{position:"relative",width: "20px" , paddingLeft:0  , marginRight:received_message ?"unset" : "10px" , marginLeft:received_message ?"10px" :"unset"  ,marginBottom: "4px"}} tabIndex={-1} type='button' className="operations__trigger-btn" onBlur={() => { setShowOperations(false);showOperationsRef.current = false }}>

                              <FontAwesomeIcon color="grey"   icon={[ICON_TYPE, "ellipsis-v"]}/> 
                              {(showOperations ||showOperationsRef.current)&& 
                              <div style={{minWidth: "90px" ,  right:received_message ?"unset" : "30px" , left:received_message ?"25px" :"unset"   , top: `${formTextbox.current.getBoundingClientRect().bottom-formTextbox.current.parentNode.getBoundingClientRect().top >200 ? -133 :-6 }px` ,width:"120px" }} className={`operations__operations-icons pt-1 ${(showOperations ||showOperationsRef.current) ? "" : "d-none"}`}>

                                <div className={`operations__operations-icon-wrapper `} >
                                   <div className="operations__operations-icon-wrapper" onClick={() =>{setForwardMsg("")}}>
                                   <FontAwesomeIcon icon={['fas', 'share']} className="chat__chatbox-reply-btn"   color={`${'grey'}`} />
                                       <p>{trans("forward")}</p>
                                   </div>
                                 </div>
                                <div className={`operations__operations-icon-wrapper `} >
                                   <div className="operations__operations-icon-wrapper" onClick={()=>{handleReplyOnclick() } }>
                                   <FontAwesomeIcon icon={['fas', 'reply']} className="chat__chatbox-reply-btn"  flip={lang === 'fa' ? undefined : 'horizontal'} color={`${'grey'}`} />
                                       <p>{trans("reply")}</p>
                                   </div>
                                 </div>
                                 {(!attached_file || attached_file.length ==0 ) &&

                                <div className={`operations__operations-icon-wrapper `} >
                                   <div className="operations__operations-icon-wrapper" onClick={() =>{copyText(MessageData.chatMessage.message);setShowOperations(false);showOperationsRef.current = false}}>
                                   <FontAwesomeIcon icon={['fas', 'copy']} className="chat__chatbox-reply-btn"   color={`${ 'grey'}`} />
                                       <p>{trans("copy")}</p>
                                   </div>
                                 </div>
                                 }
                                 {(attached_file && attached_file.length ==1 ) &&

                                <div className={`operations__operations-icon-wrapper `} >
                                   <div className="operations__operations-icon-wrapper" onClick={(e) =>DblClick(e ,attached_file[0], 0)}>
                                   <FontAwesomeIcon icon={['fas', 'download']} className="chat__chatbox-reply-btn"   color={`${ 'grey'}`} />
                                       <p>{trans("download")}</p>
                                   </div>
                                 </div>
                                 }

                                 {(!MessageData.chatMessage.isRead && MessageData.chatMessage.isDirect) &&
                                    <div className={`operations__operations-icon-wrapper `} >
                                       <div className="operations__operations-icon-wrapper" onClick={() =>{DeleteMessage()}}>
                                       <FontAwesomeIcon icon={['fas', 'trash']} className="chat__chatbox-reply-btn"   color={`${ 'grey'}`} />
                                           <p>{trans("Delete")}</p>
                                       </div>
                                     </div>
                                 }
                                <div className={`operations__operations-icon-wrapper `} >
                                   <div className="operations__operations-icon-wrapper" onClick={() =>{setForwardMsgList("");setShowOperations(false);showOperationsRef.current = false}}>
                                   <FontAwesomeIcon icon={['fas', 'circle-check']} className="chat__chatbox-reply-btn"   color={`${'grey'}`} />
                                       <p>{trans("selection")}</p>
                                   </div>
                                 </div>
                                 {(layoutContext.currentUserPosition.OrganizationType != OrganizationType.Customers) &&
                                    <div className={`operations__operations-icon-wrapper `} >
                                       <div className="operations__operations-icon-wrapper" onClick={() =>{CreateRequest()}}>
                                       <FontAwesomeIcon icon={['fas', 'code-pull-request']} className="chat__chatbox-reply-btn"   color={`${ 'grey'}`} />
                                           <p>{trans("Request")}</p>
                                       </div>
                                     </div>
                                 }
                              </div>
                              }
                        </button> 
                        </div>
                        {MessageData.chatMessage.replyToMessageId &&
                            <div onClick={()=>{document.getElementById(MessageData.chatMessage.replyToMessageId)?.scrollIntoView({behavior:"smooth"});document.getElementById(MessageData.chatMessage.replyToMessageId)?.classList.add("fadeInMsg"); setTimeout(()=>{ document.getElementById(MessageData.chatMessage.replyToMessageId)?.classList.remove("fadeInMsg") },1300)}} className="chat__chatbox-reply d-flex flex-column text-muted p-1 rounded mb-2  massage-answer">
                                <span className="mb-1">
                                <FontAwesomeIcon  icon={['fas', 'reply']} className="chat__chatbox-reply-btn mx-1"   color={`${'grey'}`} />
                                <span className="text-primary">{MessageData.replyToUser ?MessageData.replyToUser : MessageData.replyToUser }</span>
                                </span>
                                <span className="text-truncate">
                                    {
                                    MessageData.replyToMessageText 
                                    
                                    }
                                </span>        
                            </div>
                        }
                         {attached_file   ?
                         <div style={{display:"flex" , justifyContent:"space-between", width:"100%"}}>
                            <div>
                            <div  className={` ${attached_file .length > 4 ? 'd-flex flex-row flex-wrap' : ''}`}>
                            {attached_file?.map((item:any, index)=>(
                            <div className="d-flex flex-column " style={{width: attached_file .length > 4 ?  "30%" : "100%"}}>
                               <div onTouchEnd={ e => clear(e,item)}  onTouchStart={(e)=>start(e,item)}  onClick={(e)=>ownClick(e ,item , index)} onDoubleClick={(e)=>DblClick(e ,item, index)}      >
                                    {
                                        (item?.FileName == "AppVoice.mp3"||item?.fileName == "AppVoice.mp3"  )?
                                        <>{playVoice ? 
                                            <>
                                            <audio style={{width:"140px"}} controls >
                                                <source src={"data:audio/mpeg;base64,"+audioURL}
                                                />
                                            </audio>   
                                            </>
                                      :
                                      <FontAwesomeIcon icon={['fas', 'play-circle']}  style={{height:"30px"}}  className="text-primary" />
                                        }
                                        </>
                                        :
                                        <>    
                                         <div className={`chat__chatbox-attachments ${attached_file .length >4?" columnattachments " :" "} d-flex align-items-center mt-2`}>
                                            
                                            {(item?.fileName ? item?.fileName=="loading"  : item?.FileName=="loading")     ? 
                                            <div  className="sync-box-fileicon " onClick={()=>{setRotate(true) ;setTimeout(()=>{ setRotate(false) }, 1500)}}   >       
                                                <FontAwesomeIcon style={{marginTop: "15px"}} icon={[ICON_TYPE , "sync"] } className={`text-primary  ${rotate ? "Rotate" : "" } `}  />  
                                            </div> 
                                            :
                                            <>
                                            {item?.isDeleted &&
                                                <FontAwesomeIcon icon={['fas', "ban"]} className="text-danger mb-3 file-deleted-icon" /> 
                                            }    
                                            <img src={ 'data:image/png;base64,'+(item.Thumbnail ? item.Thumbnail :  item.thumbnail) } className={`FileOption `}  />
                                            </>

                                            }
                                            <div style={{display:"flex" , flexDirection:"column"}}>
                                            <div className="fileInfo " >
                                            <span style={{userSelect:"none"}} className="text-muted ">{item?.fileName ? item?.fileName : item?.FileName}</span>                                         
                                            {item?.isDeleted &&
                                            <span className="file-deleted ">{trans("deleted")}</span>                                         
                                        }
                                            </div>
                                            <span style={{userSelect:"none"}} className="text-muted ">{ (item?.fileSize ? +item?.fileSize: +item?.FileSize)   >1048576 ?((item?.fileSize ? +item?.fileSize: +item?.FileSize) / 1048576).toFixed(2) + " MB" :  ((item?.fileSize ? +item?.fileSize: +item?.FileSize) / 1024).toFixed(2) + " KB"  }</span>                                         
                                            </div>
                                        </div>     
                                        </>
                                    }
                               </div>
                            </div>
                            ))}
                            </div>
                            {(MessageData.chatMessage.message && !(attached_file.length > 0)) &&
                            <div style={{width:"90%"}} className='massageAnswer' contentEditable={false} {...(urlify(MessageData.chatMessage.message) ? { dangerouslySetInnerHTML: {__html:urlify(MessageData.chatMessage.message) }} : {})}  >{urlify(MessageData.chatMessage.message)?null :MessageData.chatMessage.message }</div>
                            }
                            </div>
                              <div style={{width:"10%" , position:"relative" }}>            
                                <FontAwesomeIcon onClick={()=>{handleReplyOnclick() } } style={{left: "1px" , bottom:"0" , position:"absolute" }} icon={['fas', 'reply']} className="chat__chatbox-reply-btn"  flip={lang === 'fa' ? undefined : 'horizontal'} color={`${'grey'}`} />
                              </div>
                            </div>
                            :
                            <div onDoubleClick={() =>{setForwardMsgList("")}}  style={{display:"flex" , justifyContent:"space-between", width:"100%" }}>
                               <div  className='massageAnswer' contentEditable={false} {...(urlify(MessageData.chatMessage.message) ? { dangerouslySetInnerHTML: {__html:urlify(MessageData.chatMessage.message) }} : {})}  >{urlify(MessageData.chatMessage.message)?null :MessageData.chatMessage.message }</div>
                               <div style={{width:"10%" , position:"relative" ,paddingRight:"20px" }}>  
                                 <FontAwesomeIcon onClick={()=>{handleReplyOnclick() } } style={{left: "1px" , bottom:"0" , position:"absolute" }} icon={['fas', 'reply']} className="chat__chatbox-reply-btn"  flip={lang === 'fa' ? undefined : 'horizontal'} color={`${'grey'}`} />
                                </div> 
                            
                             </div> 
                            
                        }
                      

                    </div>
                    <div style={{display:"flex" , justifyContent:"space-between"}} className="text-secondary">
                        <div>
                       {/*  <span className="ms-1">زمان:</span> */}
                        <span>{sent_Date}</span>

                        </div>

                    </div> 
                </div>
            </div>
        </div>
        
    )
}
