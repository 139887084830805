
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext"
import { useContext, useEffect, useRef, useState } from "react"
import { ICON_TYPE } from "utils/constants"
import { trans } from "utils/helpers"
import { getEditSalesForms } from "./api"
interface ButtonToolbarEditSalesFormsProps {
    onClick?: () => void,
    Callback: (ans: any) => void,
    formName:string
}
export const ButtonToolbarEditSalesForms: React.FC<ButtonToolbarEditSalesFormsProps> = ({formName, onClick, Callback }) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    const buttonToolbarEditSalesForms = useRef(null);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const layoutContext = useContext(LayoutContext);
    const [options, setOptions] = useState<any>()
    async function getDataAsync() {
        try {
            const response = await getEditSalesForms(formName);
            setOptions(response.Data);
        } catch (error) {

            setOptions(null)
        }

    }
    useEffect(() => {
        getDataAsync();

    }, [layoutContext.currentUserPosition, layoutContext.currentApplication]);
    function showSubMenuFunction() {
        setShowSubMenu(true)
    }
    function hideSubMenuFunction() {
        setShowSubMenu(false)
    }
    function selectboxValSetter(event) {
        Callback && Callback(event);
        setShowSubMenu(false)
        if (buttonToolbarEditSalesForms.current) (buttonToolbarEditSalesForms.current as HTMLButtonElement).blur();
    }
    return (
        <> {options && Object.values(options)?.length > 0 && <div className="toolbar-btn-wrapper">
            <button ref={buttonToolbarEditSalesForms} onClick={() => { }} onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction} className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
                <div className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>F2</div>
                <FontAwesomeIcon icon={[ICON_TYPE, "edit"]} width="20px" height="20px" />
                <small>{trans("edit")}</small>
                <div className={`toolbar-btn-sub-menu mt-1 ${(options && showSubMenu) ? "" : "d-none"}`}>
                    {options && options.map((option, index) => (
                        <div key={index} className="toolbar-btn-sub-menu__option" data-value={index} onClick={(e)=>{selectboxValSetter(option); } }>{ trans(option)}</div>
                    ))}
                </div>
            </button>
        </div>
        }
        </>
    )
}