import { Button } from "components/Button/Button";
import { Textbox } from "components/Textbox/Textbox";
import { Application } from "components/Application/Application";
import { useState } from "react";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { trans } from "utils/helpers";

interface CreateFormProps {
    onHide: (e:boolean)=>void;
    handleNewForm: (e:any)=>void;
    obj?:any;
    mode:'create'|'view'|'edit';
}

export const CreateInputForm = ({onHide,handleNewForm,obj,mode}:CreateFormProps) => {
    const [applicationIds , setApplicationIds] = useState< any>([])

    const handleSubmit = async(event) => {  
        event.preventDefault()



        let data = {
            DynamicForms:{
                formTitle: event.target.formTitle?.value,
                systemKey: event.target.systemKey?.value,
                Id: obj?.Id,
                FormTitleEN: event.target.FormTitleEN?.value,
                FormPriority:event.target.FormPriority?.value,
                IsWorkflowEnable:event.target.IsWorkflowEnable.checked,
                HasSignature:event.target.HasSignature.checked

            },
            DynamicFormApplications:applicationIds
           
        }
        handleNewForm(data)
    }

    function cancel() {
        onHide(false)    
    }



    

    return ( 
        <form onSubmit={handleSubmit}>
            <div className="card">
                <div className="card-body row ">
                    <div className="col-4">
                        <Textbox mode={mode} textboxType={'text'} textboxName={'formTitle'} labelText="formTitle" defaultValue={obj?.formTitle} required/>
                    </div>
                    <div className="col-4">
                        <Textbox mode={mode} textboxType={'text'} textboxName={'FormTitleEN'} labelText="FormTitleEN" defaultValue={obj?.FormTitleEN} required/>
                    </div>
                    <div className="col-4">
                        <Textbox  mode={mode} textboxType={'number'} min={0} textboxName={'FormPriority'} labelText="FormPriority" defaultValue={obj?.FormPriority} required/>
                    </div>
                  
                    <div className="col-4">
                        <Textbox textboxType={'formKey'} textboxName={'systemKey'} labelText="systemKey" defaultValue={obj?.systemKey} required mode={mode == 'create' ? 'create' : 'view'}/>
                    </div>
                    <div className="col-4">
                    <Application
                                ApplicationListCallback={setApplicationIds}
                                type="SelectBox"
                                mode={mode}
                                required={true}
                                name="ApplicationId"
                                valueList={obj?.DynamicFormApplications.length>0 ? Object.keys(obj?.DynamicFormApplications[0]):undefined }
                                returnObj={true}
                              />

                    </div>
                    <div>
                    <CheckableButton 
                    type="checkbox"
                    mode={mode}
                    defaultChecked={obj?.IsWorkflowEnable}
                     defaultValue={obj?.IsWorkflowEnable} 
                     labelText={trans("IsWorkflowEnable")}
                      inputName={"IsWorkflowEnable"} />
                    </div>
                    <div>
                    <CheckableButton 
                    type="checkbox"
                    mode={mode}
                    defaultChecked={obj?.HasSignature
                    }
                     defaultValue={obj?.HasSignature
                     } 
                     labelText={trans("HasSignatureForm")}
                      inputName={"HasSignature"} />
                    </div>
                    <div className="col-lg-12 mt-1 btns_form">
                        {mode!="view"?
                        <>
                        <Button btnType="submit" value="Save" />
                        <Button btnType="cancel" value="cancel" onClick={()=>{cancel()}} />
                        </>:
                        <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                        }
                    </div>
                </div>
            </div>
        </form>
     );
}
