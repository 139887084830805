import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";



export const putSalesInvoiceDictionary = (endPoint,data=null,queryString) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: endPoint,
    method: 'put',
    data:data?data:null,
    params: queryString
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


export const getCustomerInfo = (id) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.customerInfo,
    method: 'get',
 
    params: {
        id:id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});



export const putNationalEcoCode = (queryString) => http({
   
    url: ENDPOINTS.BaseInformation.PersonContact.nationalEcoCode,
    method: 'put',
    params: queryString
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});