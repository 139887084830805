import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { CheckableButtonList } from "components/CheckableButtonList/CheckableButtonList";
import Datepicker from "components/DatePicker/DatePicker";
import { Message } from "components/Message/Message";
import { Position } from "components/Position/Position";
import { Price } from "components/Price/Price";
import { RangeSlider } from "components/RangeSlider/RangeSlider";
import { SelectBox } from "components/Select/Select";
import { Textbox } from "components/Textbox/Textbox";
import { Time } from "components/Time/Time";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useState } from "react";
import { InputTextType,InputTextTypePlaceholder, OrganizationType } from "types/enum";
import { StepInForms } from "./DynamicFormsPage/StepInForms";
import { Person } from "components/Person/Person";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { DynamicDictionary } from "./DynamicDictionary";

interface InputDetectorPropes {
    typeName:string;
    property:any;
    handleTime?:(e:string)=>void;
    mode?:'create' | 'view' | 'edit';
    EnableDisplacement?:boolean;
    formBuilderMode?:boolean;
    firstAutoValue?:boolean;
    stepperIndex?:any;
    hidden?:boolean;
    handleStepper?:()=>void;
    handleOpenStep?:(e)=>void;
}
export const InputDetector = ({handleStepper,handleOpenStep,hidden,EnableDisplacement=false,firstAutoValue,formBuilderMode=false,typeName,property,mode,stepperIndex,handleTime}:InputDetectorPropes) => {
    const [todayDate, settodayDate] = useState<string | null>(new Date().toISOString());
    const layoutContext = useContext(LayoutContext);
    let content = {

        PositionDictionary:{type: 'PositionDictionary',body:(
        <div className={`w-100 position-relative p-1 widthControl formBuilder__formBx__PositionDictionary`} style={{display:(!formBuilderMode&&property?.hidden)?("none"):( 'block'), filter:property?.hidden ? 'blur(2px)' : ''}}>
            <div className="position-absolute w-100 h-100 formBuilder__formBx__PositionDictionary" style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>
            <Position
             label={property?.label} 
             name={property?.systemKey} 
             mode={((mode && !property?.disable)||(!property?.disable &&layoutContext.currentUserPosition.OrganizationType != OrganizationType.Customers))?  mode: 'view'} 
             resetForm={false} 
             required={property?.required}
             value={firstAutoValue &&(property?.defaultValue? property?.defaultValue: (layoutContext.currentUserPosition.OrganizationType == OrganizationType.Customers ?layoutContext.currentUserPosition.PositionId:null))}
             
            />
        </div>
        )},
        Sex:{type: 'Sex',body:(
            <div className={`w-100 position-relative p-1 widthControl formBuilder__formBx__PositionDictionary`} style={{display:(!formBuilderMode&&property?.hidden)?("none"):( 'block'), filter:property?.hidden ? 'blur(2px)' : ''}}>
                <div className="position-absolute w-100 h-100 formBuilder__formBx__PositionDictionary" style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>
               <EnumerationTypeSelect
                  required={property?.required}
                  mode={mode && !property?.disable ?  mode: 'view'} 
                  label={property?.label} 
                  name={property?.systemKey} 
                  value={firstAutoValue &&(property?.defaultValue? property?.defaultValue:null)}
                  enumTypeSelect="Sex"
                  />
            </div>
            )},
        Position:{type: 'Position',body:(
        <div className={`w-100 position-relative p-1 widthControl formBuilder__formBx__PositionDictionary`} style={{display:(!formBuilderMode&&property?.hidden)?("none"):( 'block'), filter:property?.hidden ? 'blur(2px)' : ''}}>
            <div className="position-absolute w-100 h-100 formBuilder__formBx__PositionDictionary" style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>
            <Position
             label={property?.label} 
             name={property?.systemKey} 
             DescriptionKey={property?.systemKey+"Description"} 
             mode={mode && !property?.disable ?  mode: 'view'} 
             resetForm={false} 
             required={property?.required}
             value={firstAutoValue &&(property?.defaultValue? property?.defaultValue:null)}
            />
        </div>
        )},
        Person:{type: 'Person',body:(
        <div className={`w-100 position-relative p-1 widthControl formBuilder__formBx__PositionDictionary`} style={{display:(!formBuilderMode&&property?.hidden)?("none"):( 'block'), filter:property?.hidden ? 'blur(2px)' : ''}}>
            <div className="position-absolute w-100 h-100 formBuilder__formBx__PositionDictionary" style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>
            <Person
             label={property?.label} 
             name={property?.systemKey} 
             DescriptionKey={property?.systemKey+"Description"} 
             mode={mode && !property?.disable ?  mode: 'view'} 
             resetForm={false} 
             required={property?.required}
             value={firstAutoValue &&(property?.defaultValue? property?.defaultValue:null)}
            />
        </div>
        )},
        ProgressBar: {type: 'ProgressBar',body:(
        <div className={`w-100 px-1 d-flex ${formBuilderMode ? '': 'm-2 px-0 pt-2 pb-5'} position-relative formBuilder__formBx__ProgressBar`} >
            <div className="position-absolute w-100 h-100  formBuilder__formBx__ProgressBar " style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>
            <RangeSlider
             label={property?.label} 
             name={property?.systemKey} 
             mode={mode && !property?.disable ?  mode: 'view'} 
             defaultValue={property?.defaultValue} 
             resetForm={false} 
             required={property?.required} 
             useTrans={false} 
            />
        </div>
        )},
        
        // fileUpload: {type: 'fileUpload',body:(
        // <div className="widthControl w-100 position-relative formBuilder__formBx__fileUpload">
        //     <div className="position-absolute w-100 h-100 formBuilder__formBx__fileUpload" style={{zIndex:maker ? '1' : 'none'}}></div>
        //     <FileInput useTrans={false} mode={mode && !property?.disable ?  mode: 'view'} name={property?.systemKey} lable={property.label} maxSize={25600000} hideSrc={true}/>
        // </div>
        // )},
        
        RadioButton:  {type: 'RadioButton',body:(
        <div className="widthControl p-1 w-100 position-relative formBuilder__formBx__RadioButton">
            <div className="position-absolute w-100 h-100 formBuilder__formBx__RadioButton" style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>
            <CheckableButtonList
             mode={mode && !property?.disable ?  mode: 'view'} 
             checkableListLabel={property?.label}  
             verticalDirection={false}  
             checkableButtonName={property?.systemKey}  
             checkableBtnLabelList={property?.Keywords}
             disable={property?.disable}
             value={property?.defaultValue}
             required={property?.required}
             checkbaleType='radio' 
             useTrans={false}
               
            />
        </div>
        )},
        
        checkBox:   {type: 'checkBox',body:(
        <div className="widthControl p-1 mt-3 w-100 d-flex align-items-center position-relative formBuilder__formBx__checkBox">
            <div className="position-absolute w-100 h-100 formBuilder__formBx__checkBox" style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>
            <CheckableButton
             required={property?.label}
             readOnly={property?.disable}
             labelText={property?.label} 
             inputName={property?.systemKey} 
             mode={mode && !property?.disable ?  mode: 'view'} 
             defaultChecked={property?.defaultValue}
             type="checkbox"
             useTrans={false}
             value={property?.defaultValue}              
             />
        </div>
        )},
        
        selectBox: {type: 'selectBox',body:(
        <div className="widthControl p-1 w-100 position-relative formBuilder__formBx__selectBox">
            <div className="position-absolute w-100 h-100 formBuilder__formBx__selectBox" style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>
            <SelectBox
             lable={property.label} 
             name={property?.systemKey} 
             mode={mode && !property?.disable ?  mode: 'view'} 
             val={property.defaultValue ? property.defaultValue:property?.Keywords} 
             required={property?.required}
             options={property?.Keywords} 
             selectType="select" 
             useTrans={false} 
            />
        </div>
        )},
        
        timeLength: {type: 'timeLength',body:(
        <div className="widthControl p-1 w-100 position-relative formBuilder__formBx__timeLength">
            <div className="position-absolute w-100 h-100 formBuilder__formBx__timeLength"  style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>
            <Time
             label={property.label} 
             name={property?.systemKey} 
             IdString={property?.systemKey} 
             defaultValue={property?.defaultValue} 
             mode={mode && !property?.disable ?  mode: 'view'} 
             required={property.required} 
             type="Time" 
             useTrans={false} 
            />
        </div>
        )},
        
        time: {type: 'time',body:(
            <div className="widthControl p-1 w-100 position-relative formBuilder__formBx__time">
                <div className="position-absolute w-100 h-100 formBuilder__formBx__time"  style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>
                <Datepicker
                 lable={property.label} 
                 name={property?.systemKey} 
                 mode={mode && !property?.disable ?  mode: 'view'} 
                 val={property?.defaultValue ? property.defaultValue : null} 
                 withTime={true}  
                 disableDayPicker={true} 
                 setIsoTime={(e)=>{handleTime && handleTime(e)}} 
                 required={property.required} 
                 useTrans={false}
                 format={"HH:mm"}
                 placeholder={formBuilderMode ?"۱۳:۲۶":null}
                 onOpenPickNewDate={true}
                />
                </div>
            )},
       
       date: {type: 'date',body:(
        <div className="widthControl p-1 w-100 position-relative formBuilder__formBx__date">
            <div className="position-absolute w-100 h-100 formBuilder__formBx__date"  style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>
            <Datepicker
             lable={property.label} 
             name={property?.systemKey} 
             mode={property?.disable ? 'view': mode && !property?.disable ?  mode: 'view'} 
             val={property?.defaultValueDateTimeNow ? todayDate  :  (property?.defaultValue ? property.defaultValue : null)}
             setIsoTime={(e)=>{handleTime && handleTime(e); }} 
             withTime={property?.DatePickerState != 'normal' ? true:false}  
             disableDayPicker={property?.DatePickerState == "disableDayPicker" ? true:false} 
             required={property.required} 
             onOpenPickNewDate={true} 
             useTrans={false}
             placeholder={(formBuilderMode ? (property?.DatePickerState != 'normal' ? "۱۴۰۳/۰۴/۰۴ ۱۳:۲۶": "۱۴۰۳/۰۴/۰۴" ): "")} 
            />
        </div>)},
       
       text: {type: 'text',body:(
        <div className="position-relative p-1 widthControl w-100 formBuilder__formBx__text">
           
            <div className="position-absolute w-100 h-100 formBuilder__formBx__text" style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>
            <Textbox
             labelText={property?.label} 
             textboxName={property?.systemKey}  
             textboxType={InputTextType[property?.InputType]}
             defaultValue={property?.defaultValue} 
             mode={mode && !property?.disable ?  mode: 'view'}  
             maxLength={property?.maxLength} 
             minLength={+property?.minLength} 
             min={property?.min==null? undefined  : +property?.min } 
             max={property?.max==null? undefined :+property?.max } 
             minHeight={property?.minHeight ? property?.minHeight : 2} 
             required={property?.required} 
             useTrans={false} 
             placeholder={(!formBuilderMode||property?.placeholder) ? property?.placeholder :InputTextTypePlaceholder[InputTextType[property?.InputType]]} 
            />
        </div>
        )},
        
        price:{type: 'price',body:(
        <div className="widthControl p-1 w-100 position-relative formBuilder__formBx__price"> 
            <div className="position-absolute w-100 h-100  formBuilder__formBx__price" style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>

            <Price
             label={property?.label} 
             name={property?.systemKey}  
             mode={mode && !property?.disable ?  mode: 'view'} 
             useTrans={false} 
             defaultValue={property?.defaultValue}
             placeholder={formBuilderMode ?"100.00$":null}
             required={property?.required}
            />
        </div>
        )},

        emptySpace:{type: 'emptySpace',body:(
        <div  className={`widthControl p-1 w-100 d-flex align-items-end formBuilder__formBx__emptySpace ${!formBuilderMode ? '' : 'border-bottom'}`} style={{height:'28px'}}><div style={{height:'0'}}><div className="invisible" ></div></div></div>
        )},
        
        // label:{type: 'label',body:(
        //     <div className="widthControl p-1 w-100 position-relative" style={{zIndex:maker ? '1' : 'none'}}>
        //         <div style={{fontSize:property?.labelSize +'px',fontStyle:property?.labelStyle,fontWeight:property?.labelWeight,color:property?.color}}>{property?.labelText ? property?.labelText : 'label'}</div>
        //     </div>
        //     )},
        Message:{type: 'Message',body:(
            <div className="widthControl p-1 w-100 position-relative formBuilder__formBx__Message " style={{zIndex:EnableDisplacement ? '1' : 'none'}}>
                <Message
                 title={property?.messageTitle}
                 type={property?.messageType}  
                 message={property?.message} 
                />
            </div>
        )},
        Step:{type: 'Step',body:(
            <div className="widthControl w-100 position-relative formBuilder__formBx__Step">
                <StepInForms property={property} handleOpenStep={handleOpenStep} /> 
            </div>
        )}
}


    return ( 
        <>
            {content[(typeName=="textArea"||typeName=="number") ? 'text': typeName]!=undefined?  content[(typeName=="textArea"||typeName=="number") ? 'text': typeName]?.body
            :<>
        <div className={`w-100 position-relative p-1 widthControl formBuilder__formBx__PositionDictionary`} style={{display:(!formBuilderMode&&property?.hidden)?("none"):( 'block'), filter:property?.hidden ? 'blur(2px)' : ''}}>
            <div className="position-absolute w-100 h-100 formBuilder__formBx__PositionDictionary" style={{zIndex:EnableDisplacement ? '1' : 'none'}}></div>
            <DynamicDictionary
             label={property?.label} 
             name={property?.systemKey} 
             DescriptionKey={property?.systemKey+"Description"} 
             mode={"create"} 
             resetForm={false} 
             DynamicObj={property}
             required={property?.required}
             value={firstAutoValue &&(property?.defaultValue? property?.defaultValue:null)}
            />
        </div>
            
            </>
        }
        </>
     );
}
