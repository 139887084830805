import { Button } from "components/Button/Button";

import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import {
  INVENTORYANDSALES_URL,
  TREASURY_AND_ACCOUNTING_URL,
} from "utils/constants";
import {
  ENDPOINTSINVENTORYANDSALES,
  ENDPOINTSTREASURYANDACCOUNTING,
} from "utils/endpoints";
import { trans } from "utils/helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBankPOSById, postBankPOS } from "./api";
import { SelectBox } from "components/Select/Select";

interface BankPOSFormPropsType {
  selectedObj;
  showForm: (e: boolean) => void;
  mode;
  relod: () => void;
}
export const BankPOSForm = ({
  selectedObj,
  showForm,
  mode,
  relod,
}: BankPOSFormPropsType) => {
  const [resetForm, setResetForm] = useState(false);
  const [response, setResponse] = useState<any>();

  const [loading, setLoading] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});
  const [bankAccountId, setBankAccountId] = useState<any>();
  const [connectionType, setConnectionType] = useState<any>();

  async function getBankPOSByIdAsync() {
    setLoading(false);

    try {
      if (selectedObj.Id) {
        const res = await getBankPOSById(selectedObj.Id);
        setResponse(res.Data);
      } else {
        setResponse(null);
      }
    } catch (err: any) {}
    setLoading(true);
  }

  const handleBankFormSubmit = async (event) => {
    event.preventDefault();
    let data = {
      IsDefault: event.target.checkbox?.checked,
      Description: event.target.Description?.value,
      BankAccountId: event.target.BankAccountId?.value,
      ConnectionType: +event.target.ConnectionType?.value,
      PaymentServiceProviderId: event.target.PaymentServiceProviderId?.value,
      PaymentSwitchNumber: event.target.PaymentSwitchNumber?.value,
      Port: event.target.Port?.value,
      PortSpeed: event.target.PortSpeed?.value,
      MerchantNumber: event.target.MerchantNumber?.value,
      TerminalNumber: event.target.TerminalNumber?.value,
    };

    try {
      if (mode == "edit") {
        await postBankPOS("put", { ...data, Id: selectedObj.Id,ActivityStatus: +event.target.ActivityStatus?.value });
      } else {
        await postBankPOS("post", data);
      }
      relod();
    } catch (error) {}
    cancel();
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        showForm(false);
      });
    } else {
      showForm(false);
    }
  }

  const pasteHandler = async () => {
    const text = JSON.parse(await navigator.clipboard.readText());
    setResponse(text);
    try {
    } catch (error) {}
  };

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (selectedObj && mode != "create") {
      getBankPOSByIdAsync();
    } else {
      setLoading(true);
    }
  }, [selectedObj]);

  return (
    <div className="person-contact-form card">
      <div className="card-body">
        {loading ? (
          <div className="container-fluid">
            <form onSubmit={handleBankFormSubmit}>
              <div className="row ">
                <div className="col-lg-2 col-sm-3 mt-1">
                  <DictionarySelectBox
                    onChange={(e) => {
                      setBankAccountId(e);
                    }}
                    required={true}
                    baseUrl={TREASURY_AND_ACCOUNTING_URL}
                    endPoint={
                      ENDPOINTSTREASURYANDACCOUNTING.Treasury.BaseInformationApi
                        .BankAccounts.dictionary
                    }
                    label="BankAccount"
                    name="BankAccountId"
                    value={
                      response?.BankAccountId ? response?.BankAccountId : ""
                    }
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 mt-1">
                  <DictionarySelectBox
                    onChange={(e) => {
                      setBankAccountId(e);
                    }}
                    required={true}
                    baseUrl={TREASURY_AND_ACCOUNTING_URL}
                    endPoint={
                      ENDPOINTSTREASURYANDACCOUNTING.Treasury
                        .BaseDefinitionAndSettingApi.PaymentServiceProvider
                        .dictionary
                    }
                    label="PaymentServiceProvider"
                    name="PaymentServiceProviderId"
                    value={
                      response?.PaymentServiceProviderId
                        ? response?.PaymentServiceProviderId
                        : ""
                    }
                    mode={mode}
                  />
                </div>

                <div className=" col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="PaymentSwitchNumber"
                    labelText="PaymentSwitchNumber"
                    required={true}
                    defaultValue={
                      response?.PaymentSwitchNumber
                        ? response?.PaymentSwitchNumber
                        : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="Port"
                    labelText="Port"
                    required
                    defaultValue={response?.Port ? response?.Port : ""}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="PortSpeed"
                    labelText="PortSpeed"
                    required
                    defaultValue={
                      response?.PortSpeed ? response?.PortSpeed : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="MerchantNumber"
                    labelText="MerchantNumber"
                    required
                    defaultValue={
                      response?.MerchantNumber ? response?.MerchantNumber : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="TerminalNumber"
                    labelText="TerminalNumber"
                    required
                    defaultValue={
                      response?.TerminalNumber ? response?.TerminalNumber : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                <div className="col-lg-2 col-sm-3 ">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="Description"
                    labelText="Description"
                    required
                    defaultValue={
                      response?.Description ? response?.Description : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2 col-sm-3 ">
           
                  <EnumerationTypeSelect
                    value={response?.ConnectionType}
                    name="ConnectionType"
                    mode={mode}
                    label="ConnectionType"
                    enumTypeSelect="POSConnectionType"
                  />
                </div>
                {mode !== "create" && (
                <div className="col-sm-3 col-lg-2">
                  <EnumerationTypeSelect
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    name="ActivityStatus"
                    enumTypeSelect="ActivityStatus"
                    mode={mode}
                    label="Status"
                    value={response?.Status}
                  />
                </div>
              )}
                <div className=" mb-4">
                  <CheckableButton
                    defaultChecked={
                      response?.IsDefault ? response?.IsDefault : false
                    }
                    defaultValue={
                      response?.IsDefault ? response?.IsDefault : false
                    }
                    labelText={"Isdefault"}
                    inputName={"checkbox"}
                    type="checkbox"
                    mode={mode}
                  />
                </div>
              </div>

              {mode != "view" ? (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button value="Save" btnType="submit" />
                  <Button
                    btnType="cancel"
                    value="Cancel"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              ) : (
                <div className="col-lg-12 mt-1 btns_form">
                  <Button
                    btnType="primary"
                    value="close"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              )}
            </form>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};
