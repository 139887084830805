import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "components/checkbox/checkbox";
import { LoadingIcon } from "components/LoadingIcon/LoadingIcon";
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { getChildren1 } from "./api";
import "./style.scss";

export const TreeContext = createContext<any>([]);

const createHeaders = (headers, parentName, startIndex ,Titleparams) => {

  let head = headers;
     
  let widths = []
  let setHead: any = []
  let hedTables = localStorage.getItem("userHeadTable");
  let locallist;
  if (hedTables == null) {
    locallist = [];
  } else {
    locallist = JSON.parse(hedTables)



  }
  locallist.forEach(item => {
    if (item[0] == parentName) {
      if (item[1].length == (head.length - startIndex) - 1) {
        let switchHed = false
        item[1].forEach(el => {
          if (head.indexOf(el) == -1) {
            switchHed = true
          }
        });
        if(item[1].length==1)widths = item[2];
        if (!switchHed) {
          head = item[1]
          widths = item[2]
          head.forEach((item, index) => {
            setHead.push(widths[index] ? { text: item, ref: useRef(), Id: headers.indexOf(item), width: widths[index] } : { text: item, ref: useRef(), Id: headers.indexOf(item) })
          });
        }
      }
    }
  });

  if (setHead.length == 0) {
    head.forEach((item, index) => {
      if (index >= startIndex + 1) {
        setHead.push((widths[index]||(head.length - startIndex) - 1==1) ? { text: item, ref: useRef(), Id: headers.indexOf(item), width: widths[ (head.length - startIndex) - 1==1 ?0 :index] } : { text: item, ref: useRef(), Id: headers.indexOf(item) })
      }
    });
  }
  return setHead

}



interface TreeView {
  Data;
  theadData;
  parentName;
  startIndex;
  minCellWidth?;
  selectedRowObjectCallback?: (obj: object) => void;
  selectedRowsCallback?: (obj: any) => void;
  addNewNode?: (obj: any) => void;
  moveNude?: (drag, drop) => void;
  getChildrenUrl?;
  selectedRowObject?;
  resetCheckBox?;
  params?;
  Childrenparams?;
  Titleparams?;
  defultparams?;
  defultShow?;
  multiselect?;
  dragDrop?;
  disabled?;
  IsArchive?;
  ListResponse?: any;
  selectedList?: any;
  maxLevel?: number;
  italic?:string;
  scrollHeight?:any;
  maxWidth?:boolean;
}



function Row(data) {

  const italic = data.italic
  const childrenparams = data.Childrenparams
  const Titleparams = data.Titleparams
  const [defultShow, setDefultShow] = useState(data.defultShow);
  const defultparam = data.defultparams
  const parentName = data.parentName
  const head = data.head
  const addNewNode = data.addNewNode
  const Url = data.getChildrenUrl
  const multiselect = data.multiselect
  const widthColumns = data.widthColumns
  const activeId = data.activeId
  const params = data.params
  const [row, setRow] = useState(data.row);
  const [children, setChildren] = useState(data.row[childrenparams]);
  const childrenRef = useRef(children);
  const num = JSON.parse(JSON.stringify(data.num))
  const [open, setOpen] = useState(defultShow);
  const openRef = useRef(open);
  const [flag, setFlag] = useState(true);
  const [checked, setChecked] = useState(data.checked);
  const [selectList, setSelectList] = useState(data.selectList);
  const [type, setType] = useState<any>(1);
  const [loading, setLoading] = useState<any>(false);

  const moseEnter = useRef(false);





  const [setDrag, setDrop, dragedId, dropedId ,setSelectListContext,selectListContext] = useContext(TreeContext);







  function getTh(e) {
    setDrag(e)
  }
  function setTh(e) {
    setDrop(e)
  }





  const delay = (duration) =>
    new Promise(resolve => setTimeout(resolve, duration));


  async function MouseEnter(e) {
    if (dragedId?.Id) {
      moseEnter.current = true
      try {
        await delay(1000)
      } catch (error) {

      }

      if (moseEnter.current) {
        setOpen(true);
        openRef.current = true
        if (!open) getChildren(row.Id);

      }
    }
  }




  function toggleActiveRow(obj, str = "") {

    if (typeof obj == "string") {
      let finded = false
      childrenRef.current.forEach(item => {
        if (item.Id == obj) {

          finded = true
          setFlag(!flag)
          data.selectObj(item)
        }
      });
      if (!finded) {
        setFlag(!flag)
        data.selectObj(obj)
      }
    } else {
      setFlag(!flag)
      data.selectObj(obj)
    }


  }
  function addToList(obj, e) {
    let flag = true
    let newList:any = []
    selectListContext.forEach(item => {
      if(item.Id==obj.Id){
        setChecked(false)
        flag = false
      }else{
        newList.push(item)
      }
      
    });
    if(flag){
      setChecked(true)
      newList.push(obj)

    }
    setSelectListContext(newList)


  }



  async function getChildren(id) {
    setLoading(true)
    try {
      if(Url){

        const respons = await getChildren1(Url,defultparam ,params, id)
        if (respons.data[0]) {
          setChildren([])
          setFlag(!flag)
          setRow(respons.data[0])
          setChildren(respons.data[0][childrenparams])
          childrenRef.current = respons.data[0][childrenparams]
        } else if (respons.data.Data[0]) {
          setChildren([])
          setFlag(!flag)
          setRow(respons.data.Data[0])
          setChildren(respons.data.Data[0][childrenparams])
          childrenRef.current = respons.data.Data[0][childrenparams]
        } else {
  
        }
        setFlag(!flag)
      }

      /*if (respons.data.Data[0]) {
        setChildren([])
        setFlag(!flag)
        if (respons.data.Data[0]) {
          setRow(respons.data.Data[0])
          setChildren(respons.data.Data[0][childrenparams])
          childrenRef.current = respons.data.Data[0][childrenparams]


        } else {
          setChildren([])
          childrenRef.current = []
        }

        setFlag(!flag)
        //  setOpen(!open)
        return respons.data
      } else {
        setChildren([])
        setFlag(!flag)
        if (respons.data[0]) {
          setRow(respons.data[0])
          setChildren(respons.data[0][childrenparams])
          childrenRef.current = respons.data[0][childrenparams]
        } else {
          setChildren([])
          childrenRef.current = []
        }
        setFlag(!flag)
        //  setOpen(!open)
        return respons.data
      }*/



      
    } catch (error) {

    }
    setLoading(false)

  }




  useEffect(() => {
  if(selectListContext.length == 0){
    setChecked(false)
  }

  }, [ selectListContext]);
  useEffect(() => {
    selectListContext.forEach(item => {
      if(item.Id==row.Id){
        setChecked(true)
      }
      
    });


  }, [ ]);

  useEffect(() => {

    if (data.selectId && data.selectId == row.Id) {
      getChildren(row.Id)
    }

  }, [data.selectId]);



  useEffect(() => {
    setSelectList(data.selectList)
  }, [data.selectList]);


  const kydown = (event) => {
    if (event.key == "ArrowLeft") {
      setOpen(true)
      openRef.current = true
      getChildren(row.Id)

    } else if (event.key == "ArrowRight") {
      if (!openRef.current) {
        toggleActiveRow((row.ParentId))
      }
      setOpen(false)
      openRef.current = false
    } else if (event.key == "ArrowDown") {
      toggleActiveRow(document.getElementById(row.Id)?.nextElementSibling?.id, "Down")

    } else if (event.key == "ArrowUp") {
      toggleActiveRow(document.getElementById(row.Id)?.previousElementSibling?.id, "Up")
    }
  }

  useEffect(() => {
    document.removeEventListener("keydown", kydown);
    if (activeId == row.Id) {

      document.addEventListener('keydown', kydown)
      return () => { document.removeEventListener("keydown", kydown); }
    }

  }, [activeId]);


  return (
    <>
      <tr id={row.Id} className={`table-tree__tr ${activeId == row.Id ? "active" : ""}`} style={{ maxWidth: "250px" }} onMouseLeave={() => { moseEnter.current = false }} onMouseEnter={MouseEnter} onClick={() => { toggleActiveRow(row) }}>
        <OverlayTrigger
          key={row[Titleparams]}
          placement="top"
          overlay={<></>}
        >
          <>
            {

              head.map((item, index) => (
                index == 0 ?
                  <td
                    key={item.text + index}
                    style={{border:"none" ,maxWidth:"100%"}}
                    className={`${data.IsArchive ? "" :"table-tree__body-cell"}   ${row[italic] ?"italicTd" : "" }  `}>
                    <span className={`table-tree__cell-span `} onMouseDown={() => { getTh(row) }} onMouseUp={() => { setTh(row) }}>
                      <span style={{ marginRight: `${num * 30}px` }} > </span>
                      {
                       ( multiselect && num >0) && <Checkbox disabled={data.disabled} type={type} id={row.Id} defaultChecked={checked} parentName={parentName} divClick={(e) => { e.stopPropagation(); setChecked(null); setType(1); addToList(row, true) }} onclick={(e) => { e.stopPropagation(); addToList(row, e.target.checked) }} />
                      }
                      {(data.maxLevel ==null || num<data.maxLevel) ?
                      <div style={{ width: "30px", justifyContent: "center", display: "flex", cursor: "pointer" }} onClick={() => { setOpen(!open); setDefultShow(false); openRef.current = !openRef.current; if (!open) getChildren(row.Id); }}>
                        {loading ? <div style={{ width: "10px", justifyContent: "center", display: "flex", cursor: "pointer" }}><LoadingIcon /> </div>
                          : open ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" /> :
                            <FontAwesomeIcon icon={[ICON_TYPE, "angle-left"]} size="lg" className="icon" />
                        }
                      </div>
                      :
                      <div style={{ width: "30px", justifyContent: "center", display: "flex", cursor: "pointer" }} onClick={() => { setOpen(!open); setDefultShow(false); openRef.current = !openRef.current; if (!open) getChildren(row.Id); }}>
                      </div>
                      }
                      <div style={{ width: "400px" }} className={`table-tree__cell-text me-1`} >
                        {row[Titleparams].includes(".") ? row[Titleparams].split(".").map((item, index) => (trans(item) + " _ "))
                          : trans(row[Titleparams])}
                      </div>
                    </span>
                  </td>
                  : <OverlayTrigger
                    key={item.text + index}
                    placement="top"
                    delay={{ "show": 600, "hide": 0 }}
                    overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${item.text}`} >
                        {trans(item.text)}
                      </Tooltip>
                    }
                   >
                    <td className={`table-tree__body-cell ${row[italic] ?"italicTd" : "" } `} >
                      <span className={`table-tree__cell-span `}>
                        <div className="table-tree__cell-text">
                          {row[item.text] == null ? " " : (typeof (row[item.text]) == "object" ? row[item.text].length : row[item.text])}
                        </div>
                      </span>
                    </td>
                  </OverlayTrigger>
              ))
            }
          </>
        </OverlayTrigger>
      </tr>
      {open && children.map((SubRow) => (
        <Row addNewNode={addNewNode} IsArchive={data.IsArchive} italic={italic} maxLevel={data.maxLevel} disabled={data.disabled} Titleparams={Titleparams} key={SubRow.Id} selectList={data.selectList}   parentName={parentName} multiselect={multiselect} Childrenparams={childrenparams} defultShow={defultShow} defultparams={defultparam} params={params} selectId={data.selectId} getChildrenUrl={Url} row={SubRow} num={num + 1} activeId={activeId} selectObj={toggleActiveRow} head={head} widthColumns={widthColumns} />
      ))
      
      }
            {(addNewNode && open) &&  
                <tr  className={`table-tree__tr `} style={{ maxWidth: "250px" }}   onClick={() => {addNewNode(row) }}>
                <OverlayTrigger
                  key={"create"}
                  placement="top"
                  overlay={<></>}
                >
                  <>
                  <td
                  
                    style={{border:"none" ,maxWidth:"100%"}}
                    className={`${data.IsArchive ? "" :"table-tree__body-cell"}   ${row[italic] ?"italicTd" : "" }  `}>
                    <span className={`table-tree__cell-span `} onMouseDown={() => { getTh(row) }} onMouseUp={() => { setTh(row) }}>
                      <span style={{ marginRight: `${(num+1) * 30}px` }} > </span>
                      <FontAwesomeIcon icon={[ICON_TYPE, "square-plus"]} color="green " size="lg" className="icon" />
                    </span>
                  </td>
                  </>
                </OverlayTrigger>
                </tr>
             }
    </>
  );
}






export const TreeView: React.FC<TreeView> = ({
  Data,
  theadData,
  parentName,
  startIndex,
  minCellWidth,
  selectedRowObjectCallback,
  selectedRowsCallback,
  moveNude,
  getChildrenUrl,
  resetCheckBox,
  selectedRowObject,
  params = "parentId",
  Childrenparams = "Children",
  Titleparams = "Description",
  defultparams,
  defultShow = false,
  multiselect = false,
  dragDrop = false,
  disabled = false,
  IsArchive = false,
  ListResponse = [],
  selectedList = {},
  maxLevel ,
  italic,
  scrollHeight =  null,
  maxWidth =  false,
  addNewNode ,

}) => {
  const rtlDataGridDirection = localStorage.getItem("lang") == "fa" ? true : false;
  const [selectList, setSelectList] = useState<any>(selectedList);
  const [selectedIndex, setSelectedIndex] = useState<any>();
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState<any>()
  const [hedcolums, setHedcolums]: any = useState(createHeaders(theadData, parentName, startIndex ,Titleparams));
  const [tableHeight, setTableHeight] = useState('auto');
  const [activeIndex, setActiveIndex] = useState<any>(null);
  const [widthColumns, setWidthColumns] = useState<any>([]);
  const tableElement = useRef<any>();
  const [activeRowId, setActiveRowId] = useState<any>(-1);
  const [listt, setListt] = useState<any>(selectedList);
  const [dragId, setDragId] = useState<any>();
  const [dropId, setDropId] = useState<any>();
  const [selectListContext, setSelectListContext] = useState<any>([]);
  const [dragBoxPo, setDragBoxPo] = useState<any>([]);
  const dragBox = useRef(null);
  const [err, setErr] = useState(false);

  //let listt:any ={}


  let DefaultMinCellWidth = minCellWidth ? minCellWidth : 100;
  let gridTemplateColumnsStyle: string[] = [];
  let ColumnsStyleSize: string[] = [];
  let localFlag = true
  let ummont = true



  function setUserList(e, mychilde) {




    setListt(Object.assign(listt, e))
    if (Object.keys(e)[0].length == 36 && Object.keys(e)[0].includes("-")) {


    } else {
      ListResponse && Object.values(ListResponse).forEach((item1: any, index1) => {
        if (Object.values(e)[0] != null) {
          if (item1.replaceAll(".", "").includes(Object.keys(e)[0])) {
            setListt(Object.assign(listt, { [item1]: Object.values(e)[0] }))
          }
        } else {
          if (item1.replaceAll(".", "") === (Object.keys(e)[0])) {
            setListt(Object.assign(listt, { [item1]: Object.values(e)[0] }))
          }

        }
      })
    }




    //  setitemsList({...userList ,...e })
    let filter: any = []

    Object.values(listt).forEach((item, index) => {
      if (item) {

        if ((Object.keys(listt)[index].length == 36 && Object.keys(listt)[index].includes("-")) || Object.keys(listt)[index].includes(".")) {
          filter.push(Object.keys(listt)[index])

        }
        if (Object.keys(listt)[index] == "AdminRole") {
          filter.push(Object.keys(listt)[index])

        }
      }
    })
    // setSelectList(listt)
    selectedRowsCallback && selectedRowsCallback(filter)


  }
/*   if (theadData.length < startIndex + 3) {
    gridTemplateColumnsStyle[0] = "100%";
  } else { gridTemplateColumnsStyle[0] = "250px"; }
 */
  if (hedcolums[0] && hedcolums[0].width) {
    for (let i = 0; i < hedcolums.length; i++) {
      gridTemplateColumnsStyle[i] = (`${hedcolums[i].width}`);
    }

  } else {
    for (let i = 1; i < hedcolums.length; i++) {
      gridTemplateColumnsStyle[i] = ("minmax(100px,2fr)");
    }
  }




  function getTh(e) {
    e.stopPropagation();
    setSelectedIndex(e.target.id[23])

  }


  function setTh(e) {

    let putIndex = e.target.id[23]
    if (putIndex > 0 && selectedIndex > 0) {
      let selectIndex: any = selectedIndex;
      let arryHed = hedcolums;
      let selectHed = arryHed[selectIndex]
      arryHed.splice(selectIndex, 1)
      arryHed.splice(putIndex, 0, selectHed)
      setHedcolums(arryHed)
    }
    setFlag(!flag)

  }
  const mouseDown = (index) => {
    setActiveIndex(index);
  }

  const mouseMove = useCallback((e) => {
    e.stopImmediatePropagation();
    // Return an array of px values
    const gridColumns = hedcolums.map((col, i) => {
      
      if (i == activeIndex) {

        // Calculate the column width
        if (tableElement.current !== null) {
          if (rtlDataGridDirection) {// سایز سلول در حالت راست چین

            const width = col.ref.current.getBoundingClientRect().right - e.clientX;

            if (width >= DefaultMinCellWidth) {
              if(maxWidth){
                if(tableElement.current.offsetWidth+50 <= width){
                  return `${tableElement.current.offsetWidth}px`;
                }else{
                  return `${width}px`;
                }
              }else{
                return `${width}px`;

              }
            } else {
              return `${DefaultMinCellWidth}px`;
            }
          } else { // سایز سلول در حالت چپ چین
            const width = e.clientX - (tableElement.current as HTMLElement)?.getBoundingClientRect().left - col.ref.current.offsetLeft;
            if (width >= DefaultMinCellWidth) {
              return `${width}px`;
            }
          }
        }
      }
      // Otherwise return the previous width (no changes)
      return `${col.ref.current.offsetWidth}px`;
    });



    if (gridColumns.length > 0) {
      setWidthColumns(JSON.parse(JSON.stringify(gridColumns)))

    }

    setFlag(!flag)
    // setwidthColumns(gridColumns)
    if (tableElement.current) {
      (tableElement.current as HTMLElement).style.gridTemplateColumns = gridColumns.join(' ');
      setFlag(!flag)
    }
  }, [activeIndex, hedcolums, DefaultMinCellWidth]);



  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);
  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  function toggleActiveRow(obj) {
    if (typeof obj == "string") {
      let finded = false
      Data.forEach(item => {
        if (item.Id == obj) {
          finded = true
          setActiveRowId(item.Id)
          selectedRowObjectCallback && selectedRowObjectCallback(item);
        }
      });
      if (!finded) {
        setActiveRowId(obj)
      }

    } else {
      if (obj) {
        setActiveRowId(obj.Id)
        selectedRowObjectCallback && selectedRowObjectCallback(obj);

      }
    }

    // setActiveRow(rowIndex);
    // فرستادن مقدار آیدی به والد


  }

  useEffect(() => {
    return () => {
      if (ummont) {
        let flag2 = false
        let hedlist = hedcolums.map(item => {
          return item.text
        })
        let hedTables = localStorage.getItem("userHeadTable");
        let locallist;
        if (hedTables == null) {
          locallist = [];
        } else {
          locallist = JSON.parse(hedTables)

        }
        if (localFlag) {
          locallist.forEach(item => {
            if (item[0] == parentName) {
              item[1] = hedlist
              localFlag = false
              flag2 = true
            }
          });
          if (!flag2) {
            locallist.push([parentName, hedlist, widthColumns]);
          }
          localStorage.setItem("userHeadTable", JSON.stringify(locallist));
        }
        ummont = false

      }

    }

  }, [])

  useEffect(() => {

    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    let a = true

    return () => {
      if (a) {
        let hedTables = localStorage.getItem("userHeadTable");
        let locallist;
        if (hedTables == null) {
          locallist = [];
        } else {
          locallist = JSON.parse(hedTables)

        }
        if (localFlag) {
          locallist.forEach(item => {
            if (item[0] == parentName) {
              if (ummont)
                if (widthColumns.length > 0) {
                  item[2] = widthColumns
                }
            }
          });
          localStorage.setItem("userHeadTable", JSON.stringify(locallist));
        }


      }
      removeListeners();

    }
  }, [mouseMove, mouseUp]);



  useEffect(() => {
    setData(data)
    setTableHeight(Data.length * 25 + 25 + "px"); //  عدد 25 متناسب با ارتفاع سطر ها در نظر گرفته شده است global.$height_dataGrid_tr

  }, [Data]);
  useEffect(() => {
      selectedRowsCallback &&  selectedRowsCallback(selectListContext)
  }, [selectListContext]);
  useEffect(() => {

    setSelectListContext([])
      
  }, [resetCheckBox]);

  // -------------------------------------drag & drop func----------------------------------------------------------------------


  useEffect(() => {
    if (dragDrop) {
      if (dropId?.Id && dragId?.Id && (dropId.Id != dragId.Id)) {
        moveNude && moveNude(dragId.Id, dropId.Id)
      } else {
        if (dragBox.current) {
          (dragBox.current as HTMLElement).style.display = "none"

        }
      }
    }
    setDropId(null)
    setDragId(null)

  }, [dropId]);






  const mouseDragMove = useCallback((e) => {
    e.stopImmediatePropagation();

    if (dragDrop) {

      if (e && e.target && e.target.className && e.target.className.length > 0 && !e.target.className.includes("table-tree")) {
        setErr(true)
      } else {
        setErr(false)
      }

      // Return an array of px values
 
      setDragBoxPo([e.pageX, e.pageY])

      if (dragId?.Id) {
        // dragBox?.current?.style?.display = "block"
        if (dragBox.current) {
          (dragBox.current as HTMLElement).style.display = "flex"

        }
      }





    }


  }, [dragId]);




  const removeDragListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseDragMove);
    window.removeEventListener('mouseup', removeDragListeners);
  }, [mouseDragMove]);
  const mouseDragUp = useCallback((e) => {

    e.stopPropagation();

    if (dragBox.current) {
      (dragBox.current as HTMLElement).style.display = "none"

    }

    removeDragListeners();
  }, [setDragId, removeDragListeners]);






  useEffect(() => {

    if (dragId !== null) {
      window.addEventListener('mousemove', mouseDragMove);
      window.addEventListener('mouseup', mouseDragUp);
    }



    return () => {

      removeDragListeners();

    }
  }, [mouseDragMove, mouseDragUp]);


  return (
    <>
      {Data && (
        <div className="table-tree-wrapper">
          {IsArchive&&
             <div className="mb-2">
               {selectListContext.length>0 && selectListContext.length+ ":"+trans("SelectedItems")}
             </div>
          }
          <div style={{ left: `${dragBoxPo[0] - 100}px`, top: `${dragBoxPo[1] - 135}px` }} ref={dragBox} className="dragBox" > {err ? <FontAwesomeIcon icon={['fas', "ban"]} className="text-danger mb-3" /> : <p> {dragId && dragId.Description}</p>} </div>
          <table  ref={tableElement} style={{ gridTemplateColumns: gridTemplateColumnsStyle.join(" ") , maxHeight: `${scrollHeight }` , maxWidth:"100%"}} className={`table-tree table ${scrollHeight ? "scrollable" : ""}`} >
            <thead style={{display: IsArchive ? "none" :""}} className="table-tree__thead">
              <tr className="table-tree__tr">
                {/*    <div
                  style={{ height: tableHeight }}
                  onMouseDown={() => mouseDown(0)}
                  className={`table-tree__resize-handle ${activeIndex === 0 ? 'active' : 'idle'}`}
                  />                
               */}
                {hedcolums.map(({ ref, text }, index) => (
                  <OverlayTrigger
                    key={text + index}
                    placement="top"
                    delay={{ "show": 600, "hide": 0 }}
                    overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${text}`} >
                        {trans(text)} 
                      </Tooltip>
                    }
                  >
                    <th ref={ref} key={text} style={{maxWidth :maxWidth ? "100%" : "unset"}} className={`table-tree__header-cell ${scrollHeight ? "scrollable" : ""}`} >
                      <span className="table-tree__header-cell-span" onMouseDown={getTh} onMouseUp={setTh}>
                        <div className="table-tree__cell-text" id={"table-tree__header-cell" + index}>{trans(text)}</div>
                      </span>
                      <div
                        style={{ height: tableHeight }}
                        onMouseDown={() => mouseDown(index)}
                        className={`table-tree__resize-handle ${activeIndex === index ? 'active' : ''}`}
                      />
                    </th>
                  </OverlayTrigger>

                ))}
              </tr>
            </thead>
            <TreeContext.Provider value={[setDragId, setDropId, dragId, dropId , setSelectListContext , selectListContext]}>
              <tbody className="table-tree__tbody">
                {Data.map(item => {
                  return <Row addNewNode={addNewNode} IsArchive={IsArchive} italic={italic} maxLevel={maxLevel} disabled={disabled} Titleparams={Titleparams} key={item.Id} selectList={selectList} setItemList={setUserList}  parentName={parentName} multiselect={multiselect} Childrenparams={Childrenparams} defultShow={defultShow} defultparams={defultparams} params={params} selectId={selectedRowObject} getChildrenUrl={getChildrenUrl} row={item} num={0} activeId={activeRowId} selectObj={toggleActiveRow} head={hedcolums} widthColumns={widthColumns} />
                })}

              </tbody>
            </TreeContext.Provider>
          </table>
        </div>
      )}
    </>
  );
};
























































