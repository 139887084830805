import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { ItemMainPropertyVariation } from "../../../components/ItemMainPropertyVariation/ItemMainPropertyVariation";


interface MainFormPropsType {
    id?: any,
    mode?: any,
    inTab?: any,
    formRef?: any,
    data?:any;
    checkData: any;
    submitMain?: (data) => void;

}

export const MainForm = ({
    id,
    mode,
    inTab,
    formRef,
    data,
    checkData,
    submitMain,
 
  
}: MainFormPropsType) => {


    const [resetForm, setResetForm] = useState(false);
    const [descriptions, setDescriptions] = useState<any>({});
    const [selectedItemMainPropertyValues, setSelectedItemMainPropertyValues] = useState<any>();
    async function formSubmit(event) {
        event.preventDefault();    
        let data ={
         Code: (checkData?.ParentCode ? checkData?.ParentCode+ event.target?.Code?.value :checkData?.ParentCode+ event.target?.Code?.value).replace("undefined","") ,
         SummaryDescription : event.target.SummaryDescription.value,
         DescriptionEN : event.target.DescriptionEN.value,
         SummaryDescriptionEN : event.target.SummaryDescriptionEN.value,
         TechnicalCode : event.target.TechnicalCode.value,
         Description : event.target.Description.value,
    //     DescriptionByCodeFormat : event.target.DescriptionByCodeFormat.value,  
         "SelectedItemMainProperty": checkData.ItemMainPropertyId,
         "SelectedItemMainPropertyValues": selectedItemMainPropertyValues ? selectedItemMainPropertyValues.map(item=>(item.Id)):null
        }
        submitMain&&  submitMain(data)
    }
    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }

    }, [resetForm]);
    return (
        <form className="Main-form py-2" id={"ItemForm"} name={"main"} ref={formRef} onSubmit={formSubmit}>
            <div className="card">
            { (mode != "create" && data)||(mode == "create") ?
                <div className="card-body">
                    <div className="row ">
                    {(!checkData.ItemMainPropertyId||mode=="view")?
                        <div className="col-2">
                            <Textbox
                                textboxType="text"
                                textboxName="Code"
                                resetForm={resetForm}
                                UsepadWithZeros={true}
                                maxLength={+checkData.CodeLength}
                                fixedlabel={checkData ? checkData?.ParentCode+"":checkData?.ParentCode}
                                defaultValue={data?.Code ? data.Code.replace(checkData?.ParentCode,"") : checkData?.Code}
                                labelText={trans("itemcode") + ( mode !== "create" ? "" : `  (${"طول مجاز"} : ${checkData.CodeLength})`)}
                                mode={checkData.ItemMainPropertyId?"view" :  mode}
                            />
                        </div>
                        :<></>
                    }
                    <div className="col-3">
                            <Textbox
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,summaryDescription:e })}}
                                textboxType="text"
                                textboxName="SummaryDescription"
                                resetForm={resetForm}
                                defaultValue={data?.SummaryDescription ? data.SummaryDescription : !checkData.ItemMainPropertyId ?checkData?.ItemMainPropertyDescription :null }
                                labelText="itemSummaryDescription"
                                required={!checkData.ItemMainPropertyId }
                                mode={mode}
                                />
                        </div>
                    <div className="col-4">
                            <Textbox
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,Description:e })}}
                                required={!checkData.ItemMainPropertyId }
                                textboxType="text"
                                textboxName="Description"
                                resetForm={resetForm}
                                defaultValue={ data?.Description ? data?.Description : ((descriptions.Description ? descriptions.Description: descriptions.summaryDescription))}
                                labelText="itemDescription"
                                mode={mode}
                            />
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-2">
                            <Textbox
                                textboxType="text"
                                textboxName="TechnicalCode"
                                resetForm={resetForm}
                                defaultValue={data?.TechnicalCode ? data.TechnicalCode : null}
                                labelText="TechnicalCode"
                                mode={mode}
                                />
                        </div>
                        <div className="col-3">
                            <Textbox
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,SummaryDescriptionEN:e })}}
                                textboxType="text"
                                textboxName="SummaryDescriptionEN"
                                resetForm={resetForm}
                                defaultValue={data?.SummaryDescriptionEN ? data.SummaryDescriptionEN : null}
                                labelText="itemSummaryDescriptionEN"
                                mode={mode}
                                />
                        </div>
                        <div className="col-4">
                            <Textbox
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,DescriptionEN:e })}}
                                textboxType="text"
                                textboxName="DescriptionEN"
                                resetForm={resetForm}
                                defaultValue={data?.DescriptionEN ? data?.DescriptionEN :  (descriptions.DescriptionEN ? descriptions.DescriptionEN: descriptions.SummaryDescriptionEN)}
                                labelText="itemDescriptionEN"
                                mode={mode}
                                />
                        </div>
                    </div>
                    <div className="row">

{/*                         <div className="col-3">
                            <Textbox
                                textboxType="text"
                                maxLength={checkData.codeLength}
                                textboxName="DescriptionByCodeFormat"
                                resetForm={resetForm}
                                defaultValue={data?.DescriptionByCodeFormat ? data.DescriptionByCodeFormat : null}
                                labelText="DescriptionByCodeFormat"
                                mode={mode}
                                />
                        </div> */}


                    </div>
                    

                        {checkData.ItemMainPropertyId ?
                            <ItemMainPropertyVariation SelectedItemMainPropertyValues={setSelectedItemMainPropertyValues}  ItemMainPropertyId={checkData.ItemMainPropertyId}ItemMainPropertyDescription={checkData.ItemMainPropertyDescription}  />
                            :
                            <>
                            </>
                    }
                </div>
                :
                <Loading/>
                }
            </div>
            <button  type="submit"  className="d-none" id="hidebutton" name="hidebutton" >
 
          </button>
        </form>
    );
}