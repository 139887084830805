import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { getPageSize, trans } from "utils/helpers";
import { getActionsOfReferences } from "./api/api";


export const ActionsOfReference = ({ referenceId }: any) => {

    let gridColumns, gridData = [];

    let Total_records, pages;
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedId, setSelectedId] = useState<string | any>(null);
    const [showActionForm, setShowActionForm] = useState(false);
    const [mode, setmode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [showattachment, setshowAttachment] = useState(false);
    const [endpoint, setEndpoint] = useState<any>();
    const [filterlist, setFilterlist] = useState<any>("");
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);


    const handlechangePageSize = (e: any) => {
        setPageSize(e.currentTarget.value);
        setPageNum(1);
        getPageSize("ActionsOfReference" ,e.currentTarget.value)
        getReferenceActionsOfReferenceAsync();
    };
    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else setPageNum(value);
        } else {
        }
    };
    const HandleNextPage = () => {
        if (pageNum < pages) setPageNum((pageNum) => { return +pageNum + 1 });
    };
    const HandlePrevPage = () => {
        if (pageNum > 1) setPageNum((pageNum) => { return +pageNum - 1 });
    };


    const handleAttachmentRow = (id) => {
        setSelectedId(id)
        setEndpoint( ENDPOINTS.cartable.action.attachments)
        setmode("attach")    
        setshowAttachment(true)
    }


    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true" && showActionForm){
            Toast(trans("modalclosemessage"),"warning","alert", ()=>{setSelectedId(obj.Id) ;sessionStorage.setItem("changingforms" , "false");});
        }else{
            setSelectedId(obj.Id);
        };
    }
    /* #endregion */   


    async function getReferenceActionsOfReferenceAsync() {
        setLoading(false)
        let sizePage = getPageSize("ActionsOfReference")
        setPageSize(sizePage)
        try {
            const res = await getActionsOfReferences(pageNum, sizePage, referenceId ,orderBy.length == 0 ?null:  orderBy.join(",")  ,filterlist ,search);     
            setResponse(res);
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
  
        setLoading(true)
    }
    useEffect(() => {
   getReferenceActionsOfReferenceAsync()

    }, [pageNum, forceUpdate,orderBy ,filterlist,search ]);



 

    if (response &&
        response?.Data && response.Data?.Data?.length>0) {
        gridColumns = Object.keys(response.Data.Data[0]);
        gridData = response.Data.Data;

        Total_records = response.Data.TotalRecords;
    }
    pages = Total_records > pageSize ? Math.ceil(Total_records / pageSize) : 1;
    return (
        <>
            <div className="cartable-action">
                {loading ?
                    (response?.Data?.Data.length > 0 ?
                        <div className="card ">
                            <div className="p-0">
                                <>
                                    <DataGrid
                                        handlefiltering={(str)=>{setFilterlist(str)  }}
                                        filterStr={filterlist}
                                        orderFormat={setOrderBy}
                                        orderFormatList={orderBy}
                                        parentName={"ActionsOfReference"}
                                        theadData={gridColumns}
                                        tbodyData={gridData}
                                        minCellWidth={80}
                                        selectedRowObjectCallback={selectedIdCallback}
                                        handleAttachment={handleAttachmentRow}
                                        totalRecords={Total_records}
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        HandleNextPage={HandleNextPage}
                                        HandlePrevPage={HandlePrevPage}
                                        handlechangePageSize={handlechangePageSize}
                                        first={() => setPageNum(pages)}
                                        end={() => setPageNum(1)}
                                        handleGoPage={handleGoPage}
                                        scrollHeight={"150px"}
                                    />
                                </>
                            </div>
                        </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    )
                    : <Loading />
                }

            </div>
        </>

    );
}