import { Message } from "components/Message/Message";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { getItemGroupRootsFormat, putGroupCodeFormat, sendGroupCodeFormat } from "./api";
import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { trans } from "utils/helpers";

interface ItemGroupCodeSettingFormPropsType {

    onHide: (e:boolean) => void;}

export const ItemGroupCodeSettingForm = ({
 
    onHide
  
}: ItemGroupCodeSettingFormPropsType) => {


    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [response,setResponse] = useState<any>()
    const [itemGroupFormat,setItemGroupFormat] = useState<any>()
    const [itemGrouplevels,setItemGrouplevels] = useState<any>()
    const formatLetters={
        0:"A",
        1:"B",
        2:"C",
        3:"D",
    }

    function countSpecificLetter(string, letter) {
        // Convert both the string and the letter to lowercase to make the function case-insensitive
        if(string){
            string = string.toLowerCase();
            letter = letter.toLowerCase();
        
            // Initialize a counter
            let count = 0;
        
            // Iterate through each character in the string
            for (let char of string) {
                if (char === letter) {
                    count++;
                }
            }
        
            // Return the count
            return count;
        }else return 0
    }
    async function formSubmit(e) {
        e.preventDefault()
        try {
            if(response){
                await putGroupCodeFormat(e.target.ItemGroupFormat.value); 
            }else{
                await sendGroupCodeFormat(e.target.ItemGroupFormat.value); 

            }

              onHide(false)
        } catch (error) {
          
    
        }
 
    }
    async function getItemGroupRootsFormatAsync() {
       setLoading(false)
       try {
       const res = await getItemGroupRootsFormat();
       let str = res?.Data[0] ;  
       let levels = str.split('').filter((item, pos, self) => self.indexOf(item) === pos);  
         
            setResponse(str) 
            setItemGrouplevels(levels.length)
            let itemGroupFormat ={}
             levels.map((item , index)=>{
                itemGroupFormat= {...itemGroupFormat ,  [formatLetters[index]]:  countSpecificLetter(str ,formatLetters[index] )}
            })
            setItemGroupFormat(itemGroupFormat)
       
        } catch (error) {
          
    
        }
        setLoading(true)

    }
    
    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }

    }, [resetForm]);
    useEffect(() => {
        getItemGroupRootsFormatAsync()

    }, []);




    return (
        <>

        <form className="ItemGroupCodeSetting-form py-2" id={"ItemForm"} name={"ItemGroupCodeSetting"}  onSubmit={formSubmit}>
            {loading ?
            <div className="card">

                <div className="card-body">
                    <div className="row">
                    <Message message={"Help_FirstSpecifyItemGroupLevelCount"} type={"instruction"} />
                    <Message message={"Note_ItemGroupLevelMinMaxConstraints"} type={"alert"} />
                    <div className="col-3">
                            <Textbox
                                textboxType="number"
                                textboxName="Itemgrouplevels"
                                onChange={(e)=>{setItemGrouplevels(e.target.value)}}
                                defaultValue={itemGrouplevels  }
                                min={2}
                                max={3}
                                required={true}
                                resetForm={resetForm}
                                labelText="ItemGroupLevels"
                                mode={"create"}
                                />
                        </div> 
                    </div>
                    {itemGrouplevels &&
                    <>
                    <div className="row">
                    {Array(+itemGrouplevels).fill(",").map((item,index)=>(
                        <div className="col ">
                            <Textbox
                                textboxType="number"
                                textboxName={"Itemgrouplevel" + index}
                                onChange={(e)=>{setItemGroupFormat({...itemGroupFormat , [formatLetters[index]]: +e.target.value})}}
                                defaultValue={ countSpecificLetter(response ,formatLetters[index] )+"" }
                                required={true}
                                resetForm={resetForm}
                                labelText={ trans("LevelCodeLength") +" "  + (index+1)}
                                mode={"create"}
                                />
                        </div>


                    ))}
                    
                    </div>
                    <div className="row">
{/*                        <div className="col-3 ">
                            <Textbox
                                textboxType="number"
                                textboxName={"Itemgrouplevel" + 3}
                                onChange={(e)=>{setItemGroupFormat({...itemGroupFormat , [formatLetters[3]]: +e.target.value})}}
                                defaultValue={ countSpecificLetter(response ,formatLetters[3] )+"" }
                                required={true}
                                resetForm={resetForm}
                                labelText={ trans("LevelCodeLength") +" "  + (trans("item"))}
                                mode={"create"}
                                />
                        </div> */}
                    </div>
                    <div className="row">
                        {itemGroupFormat&&
                    <div className="col-3">
                            <Textbox
                                textboxType="CodeFormat"
                                textboxName="ItemGroupFormat"
                                defaultValue={Object.keys(itemGroupFormat).map(item=>(Array(+itemGroupFormat[item]).fill(item).join(""))).join("")}
                                value={Object.keys(itemGroupFormat).map(item=>(Array(+itemGroupFormat[item]).fill(item).join(""))).join("")}
                                resetForm={resetForm}
                                capsLock={true}
                                labelText="FinalItemGroupFormat"
                                mode={"view"}
                                />
                        </div> 
                        
                        }
                    </div>
                    </>
                    }

                </div>
       
            </div>
            : 
            <Loading/>
           }
              
              <div className="col-lg-12 mt-1 btns_form">
              <Button value="Save" btnType="submit"/>
              <Button btnType="cancel" value="Cancel"  />
              </div>


          
        </form>
    </>
    );
}

