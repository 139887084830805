

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "components/checkbox/checkbox";
import { LoadingIcon } from "components/LoadingIcon/LoadingIcon";
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { getChildren1 } from "./api";
import "./style.scss";
import { Toast } from "components/ToastShow/ToastShow";

export const TreeContext = createContext<any>([]);

const createHeaders = (headers, parentName, startIndex) => {

  let head = headers;

  let widths = []
  let setHead: any = []
  let hedTables = localStorage.getItem("userHeadTable");
  let locallist;
  if (hedTables == null) {
    locallist = [];
  } else {
    locallist = JSON.parse(hedTables)



  }
  locallist.forEach(item => {
    if (item[0] === parentName) {
      if (item[1].length === (head.length - startIndex)-1 ) {
        let switchHed = false
        item[1].forEach(el => {
          if (head.indexOf(el) === -1) {
            switchHed = true
          }
        });
        if (!switchHed) {
          head = item[1]
          widths = item[2]
          head.forEach((item, index) => {
            setHead.push(widths[index] ? { text: item, ref: useRef(), Id: headers.indexOf(item), width: widths[index] } : { text: item, ref: useRef(), Id: headers.indexOf(item) })
          });
        }
      }else{

        widths = item[2]
        head.forEach((el, elindex) => {
            if (elindex >= startIndex+1) {
            if (item[1].indexOf(el)!= -1 ) {
                setHead.push((widths[item[1].indexOf(el)] && widths[item[1].indexOf(el)] != undefined) ? { text: el, ref: useRef(), Id: headers.indexOf(el), width: widths[item[1].indexOf(el)] } : { text: el, ref: useRef(), Id: headers.indexOf(el) })
            }else{
                setHead.push( { text: el, ref: useRef(), Id: headers.indexOf(el) })

            }
        }
            
        });

      }
    }
  });

  if (setHead.length === 0) {
    head.forEach((item, index) => {
      if (index >= startIndex+1 ) {
        setHead.push(widths[index] ? { text: item, ref: useRef(), Id: headers.indexOf(item), width: widths[index] } : { text: item, ref: useRef(), Id: headers.indexOf(item) })
      }
    });
  }

  return setHead

}



interface TableTree {
  Data;
  theadData;
  parentName;
  startIndex;
  minCellWidth?;
  selectedRowObjectCallback?: (obj: object) => void;
  selectedRowsCallback?: (obj: object) => void;
  DoubleClick?: (obj: object) => void;
  moveNude?: (drag, drop) => void;
  getChildrenUrl?;
  selectedRowObject?;
  params?;
  IdParams?;
  Childrenparams?;
  Titleparams?;
  ComplementaryTitle?;
  defultparams?;
  baseUrl?;
  defultShow?;
  multiselect?;
  dragDrop?;
  disabled?;
  ListResponse?: any;
  compressed?: any;
  selectedList?: any;
  maxLevel?: number|null;
  openLevel?: number;
  italic?:string;
  search?:any;
  maxWidth ?: boolean,
  makeParentsList ?: boolean,
}



function Row(data) {

  const italic = data.italic
  const childrenparams = data.Childrenparams
  const Titleparams = data.Titleparams
  const ComplementaryTitle = data.ComplementaryTitle
  const defultparam = data.defultparams
  const baseUrl = data.baseUrl
  const parentName = data.parentName
  const head = data.head
  const Url = data.getChildrenUrl
  const multiselect = data.multiselect
  const widthColumns = data.widthColumns
  const activeId = data.activeId
  const params = data.params
  const IdParams = data.IdParams
  const [row, setRow] = useState(data.row);
  const [children, setChildren] = useState(data.row[childrenparams]);
  const childrenRef = useRef(children);
  const num = JSON.parse(JSON.stringify(data.num))
  const [defultShow, setDefultShow] = useState((data.openLevel &&  num >data.openLevel) ? false : data.defultShow);
  const [open, setOpen] = useState(defultShow);
  const openRef = useRef(open);
  const [flag, setFlag] = useState(true);
  const [checked, setChecked] = useState(data.checked);
  const [localLIst, setLocalLIst] = useState({});
  const [childrenList, setChildrenLocalLIst] = useState({});
  const [selectList, setSelectList] = useState(data.selectList);
  const [type, setType] = useState<any>(1);
  const [loading, setLoading] = useState<any>(false);
  const [checkParentId, setCheckParentId] = useState<any>();
  const moseEnter = useRef(false);
  const valueToCopy = useRef(null);

  const [setDrag, setDrop, dragedId] = useContext(TreeContext);


  function getTh(e) {
    setDrag(e)
  }
  function setTh(e) {
    setDrop(e)
  }
  function DragMouseEnter(row , num , text) {
    valueToCopy.current = row[text]
    if(dragedId){
      if( (num >dragedId.num) ){
        data.setCheckParentId(num-dragedId.num)
       
      }else{
        setDrag({...dragedId , accessDrop : true})
      }

    }

  }


  useEffect(() => {

    if(dragedId   ){
        if (data.checkParentId && data.checkParentId.length >1) {
          if(dragedId.Id ===data.checkParentId){
            setDrag({...dragedId , accessDrop : false})

          }else{
            setDrag({...dragedId , accessDrop : true})

          }

      }

    }

  }, [data.checkParentId]);
  useEffect(() => {
    if(dragedId   ){
    if (checkParentId >1) {
      data.setCheckParentId(checkParentId-1)

    }else if(checkParentId === 1){
      setCheckParentId(row.Id)
    }
  }
  }, [checkParentId]);





  const delay = (duration) =>
    new Promise(resolve => setTimeout(resolve, duration));


  async function MouseEnter(e) {
   // document.addEventListener("keydown", kydownToCopy)
    if (dragedId?.Id) {
      moseEnter.current = true
      try {
        await delay(1000)
      } catch (error) {

      }
      if (moseEnter.current) {
        setOpen(true);
        openRef.current = true
        if (!open) getChildren(IdParams ?row[IdParams] : row.Id);

      }
    }
  }



  function toggleActiveRow(obj, str = "") {
    if (typeof obj == "string") {
      let finded = false
      childrenRef.current.forEach(item => {
        if (item.Id === obj) {
          finded = true
          setFlag(!flag)
          data.selectObj(item)
        }
      });
      if (!finded) {
        setFlag(!flag)
        data.selectObj(obj)
      }
    } else {
      setFlag(!flag)
      if(data.makeParentsList){
        if(obj.ParentsList&&obj.ParentsList.length>0){
          data.selectObj({...obj , ParentsList:[row[Titleparams],...obj.ParentsList]})
        }else{
          data.selectObj({...obj , ParentsList:[row[Titleparams]]})

        }
      }else data.selectObj(obj)
    }


  }

  function addToList(item, e) {

    setChecked(e)
    data.setItemList({ [item.Id]: e }, true)
    setType(1)
  }

  function setItemList(e, mychilde) {

    if (mychilde) {
      setLocalLIst(Object.assign(localLIst, e))

      let trueLength = 0
      let haveNull = false
      Object.values(localLIst).forEach(item => {
        if (item) {
          trueLength += 1
        }
        if (item == null) {
          haveNull = true
          //trueLength = 1
        }
      })

      if ((trueLength < children.length && trueLength > 0) || haveNull) {
        setType(2)
        data.setItemList({ [row.Id]: null }, true)
        setChecked(null)
      } else if (trueLength === children.length && trueLength !== 0) {
        setType(1)
        setChecked(true)
        data.setItemList({ [row.Id]: true }, true)
        setOpen(true)
        openRef.current = true
      } else if (trueLength === 0 && Object.values(e)[0] != null) {
        if (haveNull) {

        } else {
          setType(1)
          setChecked(false)
          data.setItemList({ [row.Id]: false }, true)
        }
      }
    } 
    setChildrenLocalLIst(Object.assign(childrenList, e))
    let trueLength = 0
    Object.values(childrenList).forEach(item => {
      if (item) {
        trueLength += 1
      }
    })

    if (trueLength !== 0 && trueLength > 0) {
      setType(2)
    }


    let truelocalLength = 0
    Object.values(localLIst).forEach(item => {
      if (item) {
        truelocalLength += 1
      }
    })
    if ((truelocalLength === children.length && truelocalLength !== 0)) {
      setType(1)
    }

    data.setItemList(e, false)

  }


  async function getChildren(id) {
    setLoading(true)
    try {
      if(Url){
        const respons = await getChildren1(Url,defultparam ,params, id,baseUrl)
        if (respons.data[0]) {
          setChildren([])
          setFlag(!flag)
          setRow(respons.data[0])
          setChildren(respons.data[0][childrenparams])
          childrenRef.current = respons.data[0][childrenparams]
        } else if (respons.data.Data[0]) {
          setChildren([])
          setFlag(!flag)
          setRow(respons.data.Data[0])
          setChildren(respons.data.Data[0][childrenparams])
          childrenRef.current = respons.data.Data[0][childrenparams]
        } else {
          setChildren([])
          setFlag(!flag)
        }
        setFlag(!flag)
      }
    } catch (error) {

    }
    setLoading(false)

  }




  useEffect(() => {

    if (data.selectId && data.selectId === row.Id) {
      getChildren(IdParams ?row[IdParams] : row.Id)
    }

  }, [data.selectId]);

  useEffect(() => {
    if(data.openLevel &&  num <data.openLevel){
        setDefultShow(data.defultShow)

      }

  }, [data.defultShow]);



  useEffect(() => {
    if (data.checked != null) {
      // setLocalLIst(Object.assign(localLIst,{[row.Id] : data.checked }))
      //  data.setItemList({[row.Id] : data.checked },true)
      if (selectList) {
        let Fkay: any = undefined
        let fi = false
        Object.keys(selectList).forEach((item, index) => {

          if (item === row.Id && !fi) {
            if (Object.values(selectList)[index] == null) {
              setType(2)
              setChecked(null)
              data.setItemList({ [row.Id]: Object.values(selectList)[index] }, true)
            } else {
              setType(1)
              setChecked(Object.values(selectList)[index])
              data.setItemList({ [row.Id]: Object.values(selectList)[index] }, true)
            }
          } else {
            if (item.replaceAll(".", "").includes(row.Id) && Object.values(selectList)[index] != null && !fi) {
              if (Fkay !== undefined && Fkay !== Object.values(selectList)[index]) {
                setType(2)
                setChecked(null)
                data.setItemList({ [row.Id]: null }, true)
                Fkay = null
                fi = true
              }
              if (!fi) {
                Fkay = Object.values(selectList)[index]
              }
            }
          }

        })
        if (Fkay != null) {
          setType(1)
          setChecked(Fkay)
          data.setItemList({ [row.Id]: Fkay }, true)

        }
      }
      setChecked(data.checked)
    }



  }, [data.checked]);


  useEffect(() => {

    setSelectList(data.selectList)

  }, [data.selectList]);


  useEffect(() => {

    // setLocalLIst(Object.assign(localLIst,{[row.Id] : data.checked }))
    if (selectList) {
      let Fkay: any = undefined
      let fi = false
      Object.keys(selectList).forEach((item, index) => {

        if (item === row.Id && !fi) {
          if (Object.values(selectList)[index] == null) {
            setType(2)
            setChecked(null)
            data.setItemList({ [row.Id]: Object.values(selectList)[index] }, true)
          } else {
            setType(1)
            setChecked(Object.values(selectList)[index])
            data.setItemList({ [row.Id]: Object.values(selectList)[index] }, true)
          }
        } else {
          if (item.replaceAll(".", "").includes(row.Id) && Object.values(selectList)[index] != null && !fi) {
            if (Fkay !== undefined && Fkay !== Object.values(selectList)[index]) {
              setType(2)
              setChecked(null)
              data.setItemList({ [row.Id]: null }, true)
              Fkay = null
              fi = true
            }
            if (!fi) {
              Fkay = Object.values(selectList)[index]
            }
          }
        }
      })
      if (Fkay != null) {
        setType(1)
        setChecked(Fkay)
        data.setItemList({ [row.Id]: Fkay }, true)
      }
    }
  }, [data.selectList]);
  const kydown = (event) => {
    if(document.getElementsByClassName("fullscreen-bg-modal").length === 0){
    if (event.key === "ArrowLeft") {
      setOpen(true)
      openRef.current = true
      getChildren(IdParams ?row[IdParams] : row.Id)

    } else if (event.key === "ArrowRight") {
      if (!openRef.current) {
        toggleActiveRow((row.ParentId))
      }
      setOpen(false)
      openRef.current = false
    } else if (event.key === "ArrowDown") {
      toggleActiveRow(document.getElementById(row.Id)?.nextElementSibling?.id, "Down")
    } else if (event.key === "ArrowUp") {
      toggleActiveRow(document.getElementById(row.Id)?.previousElementSibling?.id, "Up")
    }
  }
  }
  const kydownToCopy = (event) => {
    if (valueToCopy.current && event.ctrlKey && event.key == 'c') {

      navigator.clipboard.writeText( valueToCopy.current)
     // Toast(trans("TextCopiedToClipboard")  , "success")
     }
  }

  useEffect(() => {
    document.removeEventListener("keydown", kydown);
    if (activeId === row.Id) {

      document.addEventListener('keydown', kydown)
      return () => { document.removeEventListener("keydown", kydown); }
    }

  }, [activeId]);


 
 
  useEffect(() => {
    if(data?.search && data?.search.includes("..")){
      if((data.maxLevel ==null || num<data.maxLevel)&& !open &&data.search.split("..")[data.num]&&data.search.split("..")[data.num].length>0 && row[Titleparams].includes(data.search.split("..")[data.num])){
        setOpen(true)
        openRef.current = true
        getChildren(IdParams ?row[IdParams] : row.Id)
      }
    }

}, [data.search]);

  return (
    <>
      <tr id={row.Id}  onDoubleClick={() => { data.DoubleClick && data.DoubleClick(row)   }}  className={`table-tree__tr ${activeId === row.Id ? "active" : ""} `} style={{ maxWidth: "250px" }} onMouseOut={()=> document.removeEventListener("keydown", kydownToCopy) } onMouseLeave={() => { moseEnter.current = false ;document.removeEventListener("keydown", kydownToCopy) }} onMouseEnter={MouseEnter} onClick={() => { toggleActiveRow(row) }}>
        <OverlayTrigger
          key={row[Titleparams]}
          placement="top"
          overlay={<></>}
        >
          <>
            {

              head.map((item, index) => (
                index === 0 ?
                <OverlayTrigger
                    key={item.text + index}
                    placement="top"
                    delay={{ "show": 600, "hide": 0 }}
                    overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${item.text}`} >
                        { row[Titleparams]}
                      </Tooltip>
                    }
                  >
                  <td  
                    key={item.text + index}
                    className={`table-tree__body-cell  ${row[italic] ?"italicTd" : "" }  `}>
                    <span className={`table-tree__cell-span `} onMouseEnter={()=>{DragMouseEnter(row, num , item.text)}} onMouseDown={() => { getTh({...row ,num:num}) }} onMouseUp={() => { setTh(row) }}>
                      <span style={{ marginRight: `${num * (data.compressed ?15:30)}px` }} > </span>
                      {
                        multiselect && <Checkbox disabled={data.disabled} type={type} id={row.Id} defaultChecked={checked} parentName={parentName} divClick={(e) => {if(!data.disabled){ e.stopPropagation(); setChecked(null); setType(1); addToList(row, true) }}} onclick={(e) => { e.stopPropagation(); addToList(row, e.target.checked) }} />
                      }
                      {(data.maxLevel ==null || num<data.maxLevel) ?
                      <div style={{ width: "30px", justifyContent: "center", display: "flex", cursor: "pointer" }} onClick={() => { setOpen(!open); setDefultShow(false); openRef.current = !openRef.current; if (!open) getChildren(IdParams ?row[IdParams] : row.Id); }}>
                        {loading ? <div style={{ width: "10px", justifyContent: "center", display: "flex", cursor: "pointer" }}><LoadingIcon /> </div>
                          : open ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" /> :
                            <FontAwesomeIcon icon={[ICON_TYPE, "angle-left"]} size="lg" className="icon" />
                        }
                      </div>
                      :
                      <div style={{ width: "30px", justifyContent: "center", display: "flex", cursor: "pointer" }} onClick={() => { setOpen(!open); setDefultShow(false); openRef.current = !openRef.current; if (!open) getChildren(IdParams ?row[IdParams] : row.Id); }}>
                      </div>
                      }
                      <div   className={`table-tree__cell-text me-1 ${2 == row?.ActivityStatus ? "disable" : ""}`} >
                        {(row[Titleparams]&&row[Titleparams].includes(".")) ? row[Titleparams].split(".").map((item, index) => (trans(item) + " _ "))
                          :((data.search ?(row[item.text].includes(data.search.split("..")[data.search.includes("..")? data.num:0 ]) ?(<>{row[item.text].replace(data.search.split("..")[data.search.includes("..")? data.num:0 ],"*#").split("*#")[0]}<span style={{backgroundColor:"#0d6efd" , color:"white"}}>{data.search.split("..")[data.search.includes("..")? data.num:0 ]}</span>{row[item.text].replace(data.search.split("..")[data.search.includes("..")? data.num:0 ],"*#").split("*#")[1]}</>)  : trans(row[Titleparams]) ): ( ComplementaryTitle?row[ComplementaryTitle] +" - " + trans(row[Titleparams])  :  trans(row[Titleparams])) ))}
                      </div>
                    </span>
                  </td>
                  </OverlayTrigger>
                  : <OverlayTrigger
                    key={item.text + index}
                    placement="top"
                    delay={{ "show": 600, "hide": 0 }}
                    overlay={
                      <Tooltip className="tooltip" id={`button-tooltip-${item.text}`} >
                        { row[item.text] }
                      </Tooltip>
                    }
                  >
                    <td onMouseEnter={(e)=>{ valueToCopy.current = row[item.text]}} className={`table-tree__body-cell ${row[italic] ?"italicTd" : "" } `} >
                      <span className={`table-tree__cell-span ${2 == row?.ActivityStatus ? "disable" : ""} `}>
                        <div style={{color : (data.search ?(row[item.text].includes(data.search) ? "yellow"  : "unset" ): "unset")}} className="table-tree__cell-text">
                          {row[item.text] == null ? " " : (typeof (row[item.text]) == "object" ? row[item.text].length : row[item.text] )}
                        </div>
                      </span>
                    </td>
                  </OverlayTrigger>
              ))
            }
          </>
        </OverlayTrigger>
      </tr>
      {(open &&children)&& children.map((SubRow) => (
        <Row makeParentsList={data.makeParentsList} compressed={data.compressed} DoubleClick={data.DoubleClick} setCheckParentId={setCheckParentId} checkParentId={checkParentId} openLevel={data.openLevel} search={data.search} italic={italic} maxLevel={data.maxLevel} disabled={data.disabled} Titleparams={Titleparams} ComplementaryTitle={ComplementaryTitle} key={SubRow.Id} selectList={data.selectList} setItemList={setItemList} checked={checked} parentName={parentName} multiselect={multiselect} Childrenparams={childrenparams} defultShow={defultShow} defultparams={defultparam}baseUrl={baseUrl} params={params} selectId={data.selectId} getChildrenUrl={Url} row={SubRow} num={num + 1} activeId={activeId} selectObj={toggleActiveRow} head={head} widthColumns={widthColumns} />
      ))
      }
    </>
  );
}






export const TableTree: React.FC<TableTree> = ({
  Data,
  theadData,
  parentName,
  startIndex,
  minCellWidth,
  selectedRowObjectCallback,
  selectedRowsCallback,
  DoubleClick,
  moveNude,
  getChildrenUrl,
  selectedRowObject,
  params = "parentId",
  Childrenparams = "Children",
  Titleparams = "Description",
  ComplementaryTitle ,
  IdParams ,
  defultparams,
  baseUrl,
  defultShow = false,
  multiselect = false,
  dragDrop = false,
  disabled = false,
  ListResponse = [],
  selectedList = {},
  maxLevel ,
  openLevel ,
  compressed=false ,
  italic,
  search,
  maxWidth =  false,
  makeParentsList=false


}) => {
  const rtlDataGridDirection = localStorage.getItem("lang") === "fa" ? true : false;
  const [selectList, setSelectList] = useState<any>(selectedList);
  const [selectedIndex, setSelectedIndex] = useState<any>();
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState<any>()
  const [hedcolums, setHedcolums]: any = useState(createHeaders(theadData, parentName, startIndex));
  const [tableHeight, setTableHeight] = useState('auto');
  const [activeIndex, setActiveIndex] = useState<any>(null);
  const [widthColumns, setWidthColumns] = useState<any>([]);
  const tableElement = useRef(null);
  const [activeRowId, setActiveRowId] = useState<any>(-1);
  const [listt, setListt] = useState<any>(selectedList);

  const [dragId, setDragId] = useState<any>();
  const [dropId, setDropId] = useState<any>();
  const [dragBoxPo, setDragBoxPo] = useState<any>([]);
  const dragBox = useRef(null);
  const [err, setErr] = useState(false);

  //let listt:any ={}


  let DefaultMinCellWidth = minCellWidth ? minCellWidth : 100;
  let gridTemplateColumnsStyle: string[] = [];
  let localFlag = true
  let ummont = true



  function setUserList(e, mychilde) {



    setListt(Object.assign(listt, e))
    if (Object.keys(e)[0].length == 36 && Object.keys(e)[0].includes("-")) {
    } else {
   
      ListResponse && Object.values(ListResponse).forEach((item1: any, index1) => {
        if (Object.values(e)[0] != null) {
          if (item1.replaceAll(".", "").includes(Object.keys(e)[0])) {
            setListt(Object.assign(listt, { [item1]: Object.values(e)[0] }))
          }
        } else {
          if (item1.replaceAll(".", "") === (Object.keys(e)[0])) {
            setListt(Object.assign(listt, { [item1]: Object.values(e)[0] }))
          }

        }
      })
    }
    //  setitemsList({...userList ,...e })
    let filter: any = []
    Object.values(listt).forEach((item, index) => {
      if (item) {
        if ((Object.keys(listt)[index].length == 36 && Object.keys(listt)[index].includes("-")) || Object.keys(listt)[index].includes(".")) {
          filter.push(Object.keys(listt)[index])
        }
        if (Object.keys(listt)[index] == "AdminRole") {
          filter.push(Object.keys(listt)[index])
        }
      }
    })
    // setSelectList(listt)
    selectedRowsCallback && selectedRowsCallback(filter)
  }
  
  if (theadData.length < startIndex + 3) {
    gridTemplateColumnsStyle[0] = "100%";
  } else { gridTemplateColumnsStyle[0] = "250px"; }

  if (hedcolums[1] && hedcolums[1].width) {
    for (let i = 0; i < hedcolums.length; i++) {
      gridTemplateColumnsStyle[i] = (`minmax(${hedcolums[i].width},2fr)`);
    }

  } else {
    for (let i = 1; i < hedcolums.length; i++) {
      gridTemplateColumnsStyle[i] = ("minmax(100px,2fr)");
    }
  }
  function getTh(e) {
    e.stopPropagation();
    setSelectedIndex(e.target.id[23])

  }


  function setTh(e) {

    let putIndex = e.target.id[23]
    if (putIndex > 0 && selectedIndex > 0) {
      let selectIndex: any = selectedIndex;
      let arryHed = hedcolums;
      let selectHed = arryHed[selectIndex]
      arryHed.splice(selectIndex, 1)
      arryHed.splice(putIndex, 0, selectHed)
      setHedcolums(arryHed)
    }
    setFlag(!flag)

  }
  const mouseDown = (index) => {
    setActiveIndex(index);
  }

  const mouseMove = useCallback((e) => {
    e.stopImmediatePropagation();
    // Return an array of px values
    const gridColumns = hedcolums.map((col, i) => {
      let reff:any = tableElement.current

      if (i === activeIndex) {

        // Calculate the column width
        if (tableElement.current !== null) {
          if (rtlDataGridDirection) {// سایز سلول در حالت راست چین

            const width = col.ref.current.getBoundingClientRect().right - e.clientX;

            if (width >= DefaultMinCellWidth) {
              if(maxWidth){
                if(reff.offsetWidth+50 <= width){
                  return `${reff.offsetWidth}px`;
                }else{
                  return `${width}px`;
                }
              }else{
                return `${width}px`;

              }
            } else {
              return `${DefaultMinCellWidth}px`;
            }
          } else { // سایز سلول در حالت چپ چین
            const width = e.clientX - (tableElement.current as HTMLElement)?.getBoundingClientRect().left - col.ref.current.offsetLeft;
            if (width >= DefaultMinCellWidth) {
              return `${width}px`;
            }
          }
        }
      }
      // Otherwise return the previous width (no changes)
      return `${col.ref.current.offsetWidth}px`;
    });



    if (gridColumns.length > 0) {
      setWidthColumns(JSON.parse(JSON.stringify(gridColumns)))

    }

    setFlag(!flag)
    // setwidthColumns(gridColumns)

    // Assign the px values to the table
    if (tableElement.current) {
      (tableElement.current as HTMLElement).style.gridTemplateColumns = gridColumns.join(' ');

      setFlag(!flag)
    }


  }, [activeIndex, hedcolums, DefaultMinCellWidth]);



  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);
  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  function toggleActiveRow(obj) {
    if (typeof obj == "string") {
      let finded = false
      Data.forEach(item => {
        if (item.Id === obj) {
          finded = true
          setActiveRowId(item.Id)
          selectedRowObjectCallback && selectedRowObjectCallback(item);
        }
      });
      if (!finded) {
        setActiveRowId(obj)
      }

    } else {
      if (obj) {
        setActiveRowId(obj.Id)
        selectedRowObjectCallback && selectedRowObjectCallback(obj);

      }
    }

    // setActiveRow(rowIndex);
    // فرستادن مقدار آیدی به والد


  }

  useEffect(() => {


    return () => {
      if (ummont) {
        let flag2 = false
        let hedlist = hedcolums.map(item => {
          return item.text
        })
        let hedTables = localStorage.getItem("userHeadTable");
        let locallist;
        if (hedTables == null) {
          locallist = [];
        } else {
          locallist = JSON.parse(hedTables)

        }
        if (localFlag) {
          locallist.forEach(item => {
            if (item[0] === parentName) {
              item[1] = hedlist
              localFlag = false
              flag2 = true
            }
          });
          if (!flag2) {
            locallist.push([parentName, hedlist, widthColumns]);
          }
          localStorage.setItem("userHeadTable", JSON.stringify(locallist));
        }
        ummont = false

      }

    }

  }, [])

  useEffect(() => {

    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    let a = true

    return () => {
      if (a) {
        let hedTables = localStorage.getItem("userHeadTable");
        let locallist;
        if (hedTables == null) {
          locallist = [];
        } else {
          locallist = JSON.parse(hedTables)

        }
        if (localFlag) {
          locallist.forEach(item => {
            if (item[0] === parentName) {
              if (ummont)
                if (widthColumns.length > 0) {
                  item[2] = widthColumns
                }


            }
          });
          localStorage.setItem("userHeadTable", JSON.stringify(locallist));
        }


      }
      removeListeners();

    }
  }, [mouseMove, mouseUp]);



  useEffect(() => {
    setData(data)
    setTableHeight(Data.length * 25 + 25 + "px"); //  عدد 25 متناسب با ارتفاع سطر ها در نظر گرفته شده است global.$height_dataGrid_tr

  }, [Data]);

  // -------------------------------------drag & drop func----------------------------------------------------------------------


  useEffect(() => {
    if (dragDrop) {
      if (dropId?.Id && dragId?.Id && (dropId.Id !== dragId.Id)) {
        moveNude && moveNude(dragId.Id, dropId.Id)
      } else {
        if (dragBox.current) {
          (dragBox.current as HTMLElement).style.display = "none"

        }
      }
    }
    setDropId(null)
    setDragId(null)
  }, [dropId]);

  const mouseDragMove = useCallback((e) => {
    e.stopImmediatePropagation();
    if (dragDrop) {
      if (e && e.target && e.target.className && e.target.className.length > 0 && !e.target.className.includes("table-tree")) {
        setErr(true)
      } else {
        setErr(false)
      }
      // Return an array of px values

      setDragBoxPo([e.pageX, e.pageY])
      if (dragId?.Id) {
        // dragBox?.current?.style?.display = "block"
        if (dragBox.current) {
          (dragBox.current as HTMLElement).style.display = "flex"

        }
      }
    }
  }, [dragId]);

  const removeDragListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseDragMove);
    window.removeEventListener('mouseup', removeDragListeners);
  }, [mouseDragMove]);
  const mouseDragUp = useCallback((e) => {
    e.stopPropagation();
    if (dragBox.current) {
      (dragBox.current as HTMLElement).style.display = "none"
      setDropId(null)
      setDragId(null)
    }

    removeDragListeners();
  }, [setDragId, removeDragListeners]);

  useEffect(() => {
    if (dragId !== null) {
      window.addEventListener('mousemove', mouseDragMove);
      window.addEventListener('mouseup', mouseDragUp);
    }
    return () => {

      removeDragListeners();

    }
  }, [mouseDragMove, mouseDragUp]);





 








  return (
    <>
      {Data && (
        <div className="table-tree-wrapper">
          {(dragId && dragId.Id)&& 
          <div style={{ left: `${dragBoxPo[0] - 100}px`, top: `${dragBoxPo[1] - 135}px` }} ref={dragBox} className="dragBox" > {(err || !dragId?.accessDrop) ? <FontAwesomeIcon icon={['fas', "ban"]} className="text-danger mb-3" /> : <p> {(dragId )&& dragId.Description}</p>} </div>
          }
          <table ref={tableElement} style={{ gridTemplateColumns: gridTemplateColumnsStyle.join(" ") }} className="table-tree table table-striped" >
            <thead className="table-tree__thead">
              <tr className="table-tree__tr">

                {/*    <div
                style={{ height: tableHeight }}
                onMouseDown={() => mouseDown(0)}
                className={`table-tree__resize-handle ${activeIndex === 0 ? 'active' : 'idle'}`}
                />                
               */}
                {hedcolums.map(({ ref, text }, index) => (


                  <OverlayTrigger
                    key={text + index}
                    placement="top"
                    delay={{ "show": 600, "hide": 0 }}
                    overlay={

                      <Tooltip className="tooltip" id={`button-tooltip-${text}`} >
                        {trans(text)}
                      </Tooltip>
                    }
                  >
                    <th ref={ref} key={text} className={`table-tree__header-cell`} >
                      <span className="table-tree__header-cell-span" onMouseDown={getTh} onMouseUp={setTh}>
                        <div className="table-tree__cell-text" id={"table-tree__header-cell" + index}>{trans(text)}</div>
                      </span>

                      <div
                        style={{ height: tableHeight }}
                        onMouseDown={() => mouseDown(index)}
                        className={`table-tree__resize-handle ${activeIndex === index ? 'active' : ''}`}
                      />

                    </th>
                  </OverlayTrigger>

                ))}
              </tr>
            </thead>
            <TreeContext.Provider value={[setDragId, setDropId, dragId, dropId]}>

              <tbody className="table-tree__tbody">
                {Data.map(item => {
                  return <Row makeParentsList={makeParentsList} compressed={compressed} DoubleClick={DoubleClick} openLevel={openLevel}  search={search} italic={italic} maxLevel={maxLevel} disabled={disabled} Titleparams={Titleparams} ComplementaryTitle={ComplementaryTitle} key={item.Id} selectList={selectList} setItemList={setUserList} checked={false} parentName={parentName} multiselect={multiselect} Childrenparams={Childrenparams} defultShow={defultShow} defultparams={defultparams}baseUrl={baseUrl} IdParams={IdParams} params={params} selectId={selectedRowObject} getChildrenUrl={getChildrenUrl} row={item} num={0} activeId={activeRowId} selectObj={toggleActiveRow} head={hedcolums} widthColumns={widthColumns} />
                })}
              </tbody>

            </TreeContext.Provider>


          </table>
        </div>
      )}
    </>
  );
};
