import { ItemBuyImg } from "../../ItemBuyBtn/ItemBuyImg";
import { ItemCounterBtn } from "../../ItemCard/ItemCounterBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GroupingPrice } from "components/GroupingPrice/GroupingPrice";
import { trans } from "utils/helpers";
import { useEffect, useState } from "react";


interface ItemCardProps {
    order?:any,
    item?: any,
    index?:number,
    handlePriceUpdate?:()=>void,
    handleSelectedIncreaserDecreaser?:(e:any)=>void,
    handleShowIncreaseDecrease?:(e:boolean)=>void,
}

export const CartItem = ({index,item,order,handlePriceUpdate,handleSelectedIncreaserDecreaser,handleShowIncreaseDecrease}:ItemCardProps) => {

    const [offerIndex,setOfferIndex] = useState<number>(order.priceIndex ? order.priceIndex : 0)
    const [minQty,setMinQty] = useState<any>(order.min)
    const [maxQty,setMaxQty] = useState<any>(order.max)

    useEffect(()=>{
        // change
        if(order.count > +order.max && order.max != null){
            setOfferIndex(offerIndex + 1)
        }

        if(order.count < +order.min && +order.min != 1){
            setOfferIndex(offerIndex - 1)
        }
    },[order.count])

    useEffect(()=>{
        setMinQty(item.ItemPriceListDetails[0].PriceDetails[offerIndex]?.min)
        setMaxQty(item.ItemPriceListDetails[0].PriceDetails[offerIndex]?.max)
    },[offerIndex])

    return ( 
        <li key={index+"cart__list"} className="row p-2 m-0 border-bottom border position-relative" style={{height:'180px'}}>
        <div className="col-3 d-flex flex-column align-items-center justify-content-around">
            <div style={{height:'130px'}}>
                <ItemBuyImg  attchId={item.Attachments[0]?.Id} />
            </div>
                    <ItemCounterBtn classStyle={'totalBasket'} propertiesId={Object.keys(item.order).find(key => item.order[key] === order)} properties={order.properties} reload={handlePriceUpdate} ItemData={item} stock={100000000} min={order.OrderItemId.SalesUnitMinOrderQty} max={order.OrderItemId.SalesUnitMaxOrderQty} priceIndex={offerIndex} multiCounter={true}/>
        </div>
        <div className="col-md-6 col-9 d-flex flex-column justify-content-between">
        <div className="font-size-xl showDotsTowLine userSelectText">{item?.ItemDescription} { Object.values(order?.properties).map(item=> <> {" "+ item+" "}</>) }</div>
            <div className="d-flex align-items-center justify-content-between">
                <div>
                {order.properties != undefined &&

                        <ul className="mt-4">
                            {/* change */}
                            {order['properties']['color'] != undefined ? 
                            <li className="d-flex align-items-center m-2 mt-0 ">
                                <FontAwesomeIcon className="text-muted ms-2 rotate180" icon={["fas", "brush"]}></FontAwesomeIcon>
                                <div className="cart__list__colorBx border" style={{backgroundColor:order['properties']['color']}}></div>
                            </li>
                                :
                                <></>}
                            {order['properties']['size'] != undefined ? 
                            <li className="m-2 mt-0">
                                <FontAwesomeIcon className="text-muted ms-2 rotate180" icon={["fas", "ruler-vertical"]}></FontAwesomeIcon>
                                <span>{order['properties']['size']}</span>
                            </li>
                                :
                            <></>}
                            {order['properties']['matrial'] ? 
                            <li className="mb-2 mx-2">
                                <FontAwesomeIcon className="text-muted ms-2" icon={["fas", "layer-group"]}></FontAwesomeIcon>
                                <span>{order['properties']['matrial']}</span>
                            </li>
                                :
                            <></>}
                        </ul>
                        }
                        {true ? 
                        <div className="m-2">
                            <FontAwesomeIcon className="text-muted ms-2 " icon={["fas", "shield-halved"]}></FontAwesomeIcon>
                            <span>{item?.properties?.WarrantyDiscription}</span>
                        </div>
                            :
                        <></>}
                    {/*highlight desktop mode */}
                        <div className="mt-5">
                            <div className="d-md-flex d-none m-2 align-items-center">
                            {
                            item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitDiscount != 0  &&
                                <div className=" ms-1" >
                                    <div className="d-flex">
                                     <div className="fw-semibold cart__list__highlight text-decoration-line-through">
                                        <GroupingPrice value={item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitPrice * +order.count }/>
                                        </div>
                                        <span className="d-flex justify-content-center align-items-center me-2 bg-customerPrimaryColor px-2 rounded-pill cart__list__badge">
                                            {item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.DiscountPercent}%
                                        </span>
                                    </div>
                                </div>
                          
                            }
                                <div className="font-size-xl"><GroupingPrice value={item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitDiscount != null ? (item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitPrice - item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex].SalesUnitDiscount)* +order.count : item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitPrice}/></div>
                            </div>
                        </div>
                </div>
            </div>

        </div>
        {item?.increaserDecreaser?.length > 0  && 
        <div className={`col-md-3 col-12 d-flex flex-column cart__list__Increasers ${item?.increaserDecreaser.length < 3 ? 'justify-content-end':''}`}>
            {
                item?.increaserDecreaser.map((e,index)=>(
                    <div key={index+'cart__list__Increasers__box'} className="border border-1 p-1 my-1 d-flex justify-content-between align-items-center  cart__list__Increasers__box glassBox">
                        <div className="d-flex align-items-center ">
                            <div className="mx-2"><input type="checkbox"/></div>
                            <div className="cart__list__Increasers__box__title">
                                <div>{e.title}</div>
                            </div>
                        </div>
                        <div className="ms-2 fw-blod cart__list__Increasers__box__detail" onClick={()=>{handleShowIncreaseDecrease && handleShowIncreaseDecrease(true);handleSelectedIncreaserDecreaser && handleSelectedIncreaserDecreaser(e)}}>{trans('details')}</div>
                    </div>
            ))
                }
            </div>
        }
        <div className="d-sm-flex d-md-none align-items-center justify-content-sm-between">

            {/*highlight mobile mode */}
                <div className="d-flex m-2 align-items-center">
                    {
                    item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitDiscount != null ?
                        <div className="ms-1">
                            <div className="d-flex">
                                <div className="fw-semibold cart__list__highlight text-decoration-line-through">
                                <GroupingPrice value={item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitPrice}/>
                                </div>
                                <span className="d-flex justify-content-center align-items-center me-2 bg-customerPrimaryColor px-2 rounded-pill cart__list__badge">
                                    {item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.DiscountPercent}%
                                </span>
                            </div>
                        </div>
                    :
                    <></>
                    }
                    <div className="font-size-xl"><GroupingPrice value={item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitDiscount != null ? item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitDiscount : item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitPrice}/></div>
                </div>
            <div className="d-block d-md-none mb-2">
                {/* <ItemCounterBtn propertiesId={Object.keys(item.order).find(key => item.order[key] === order)} properties={order.properties}  reload={handlePriceUpdate} stock={+item?.Stock} min={minQty} max={maxQty} priceIndex={offerIndex} multiCounter={false} ItemData={item} /> */}
            </div>
        </div>

</li>

     );
}
