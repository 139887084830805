import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import "./style.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface InputLoadPropsType {
    labelText?: string,
    msg?: string,
    onClick: () => void,
    required?:boolean
}
export const InputLoad = ({ labelText , onClick , required , msg}: InputLoadPropsType) => {
  const [rotate , setRotate] = useState<boolean>(false);


    return (

        <>

<OverlayTrigger
        key={labelText}
        placement="top"
        delay={{"show" : 300 , "hide":0}}
        overlay={
            <Tooltip className="tooltip" id={`button-tooltip-${"checkable"}`}>
                {trans(labelText+"")}
            </Tooltip>
        }
        >
      <div>
        {labelText ? (
          <div className="d-flex align-items-start" style={{height:'22px'}}>
            <label className="text-box__label showDots">
            {trans(labelText)}
            </label>
             {required ? <span className="text-box__required-sign-for-label text-danger">*</span> : ""}
          </div>
        ) : null}
      </div>
      </OverlayTrigger>
        <input required={required} onKeyPress={()=>{}} value={""}   onChange={()=>{}} onClick={onClick}  className="sync-box " />
          <div className="sync-box-iconLoad mt-4" onClick={()=>{setRotate(true) ; onClick() ;    setTimeout(()=>{ setRotate(false) }, 1500)}}   >       
           <FontAwesomeIcon icon={[ICON_TYPE , "sync"] } className={`text-primary  ${rotate ? "Rotate" : "" } `}  />  
           </div> 
           <div style={{position:"relative",marginTop:"-45px"}}>
             <span style={{ display: "block",position:"absolute"}} className={` text-box__required-msg text-danger `}>{msg ?   "مقادیر دریافت نشد "+msg : "   "}</span>
             </div>

        </>
       
       )
}