import { NOTIFYING_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSNOTIFYING } from "utils/endpoints";
import { http } from "utils/http";

export const getMessagePatterns = (async (pageNum, messageSettingId,pageSize  ,searchString ,orderBy,filterlist) => await http({
    baseURL:NOTIFYING_URL,
    url: ENDPOINTSNOTIFYING.MessagePatternsApis.MessagePattern.browse,
    method: 'get',
    params:{
        PageNumber: pageNum,
        PageSize: pageSize,
        OrderByString:orderBy,
        SearchString : searchString ,
        FilterString:filterlist,
        messageSettingId:messageSettingId
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const getMessagePattern = (async (id) => await http({
    baseURL:NOTIFYING_URL,
    url: ENDPOINTSNOTIFYING.MessagePatternsApis.MessagePattern.load,
    method: 'get',
    params: {Id : id},
    headers: {"Accept-Language": "en"},
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const deleteMessagePattern = (id) => http({
    baseURL:NOTIFYING_URL,
    url: ENDPOINTSNOTIFYING.MessagePatternsApis.MessagePattern.browse,
    method: 'delete',
    params:{
        id:id
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const sendMessagePatternsApi = (data , method) => http({
    baseURL:NOTIFYING_URL,
    url: ENDPOINTSNOTIFYING.MessagePatternsApis.MessagePattern.browse,
    method: method,
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

