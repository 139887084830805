import { Application } from "components/Application/Application";
import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Loading } from "components/Loading/loading";
import { TabBar } from "components/Tabbar/Tabbar";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getWithFeed, sendWithFeed } from "./api";
import { FeedApi } from "./FeedApis";
import { FeedMethods } from "./FeedMethods";
import { FeedParameters } from "./FeedParameters";
import { MessageType } from "types/enum";
//import { AddNewWithFeedForm, deleteWithFeedForm, editWithFeedForm, getWithFeedForm, getWithFeedFormOrganizationList } from "../../api/api";


interface WithFeedFormPropsType {
    selectedObj;
    mode;
    onHide: (e) => void,
    saveCallback: (e) => void,

}
export const WithFeedForm = ({ 
    selectedObj,
    mode,
    onHide,
    saveCallback,

}: WithFeedFormPropsType) => {
     
    const [resetForm, setResetForm] = useState(false);
    const [WithFeedForm , setWithFeedForm ] = useState< any>();
    const [loading2 , setLoading2] = useState(false)
    const [feedType , setFeedType] = useState("Api")
    const [feedParameters , setFeedParameters] = useState< any>([])
    const [applicationIds , setApplicationIds] = useState< any>([])
    const [feeds , setFeeds] = useState< any>({Method:[] ,Api:[] })
    const [feedList , setFeedList] = useState< any>({Method:[] ,Api:[] })
    const [curentTab, setCurentTab] = useState<any>("reportForm")

    const feedsMethodsHandler = (list) => {
         let feedList = list.map((item)=>{
          return{
           "ParametersObj":[],
           "ReportFeedApiId":null ,
           "ReportFeedMethodId":item.Id ,
           "ReportFeedName":item.Name ,
           "Parameters":[]
          }
          })
          setFeeds({Method:feedList ,Api :feeds.Api  })
    }
    const feedsApiHandler = (list) => {
         let feedList = list.map((item)=>{
          return{
           "ParametersObj":[],
           "ReportFeedApiId":item.Id ,
           "ReportFeedMethodId":null ,
           "ReportFeedName":item.Name ,
           "Parameters":[]
          }
          })
          setFeeds({Method:feeds.Method ,Api :feedList  })
    }
    const tabChangeHandle = (tab) => {
        setCurentTab(tab)
    }
  async function sendNodeAsync(data) {
        try{
          if(mode=="create"){
            const res = await sendWithFeed(data,"post");
            if(res.data.MessageType	==MessageType.success){
              saveCallback({Id:res.data.Data ,Name :data?.Report	?.ReportName	 })
            /*   window.open(
                `${process.env.REACT_APP_REPORTING_SERVICE_URL+ "/ReportDesigner/Index/"}${res.data.Data }`,"_blank" ); */
            }
            }else{
              const res = await sendWithFeed(data,"put" , WithFeedForm?.Report.Id);
            }
          setResetForm(true)
          onHide&& onHide(false)
        }catch(err:any){

        }

    }


    async function putNodeAsync(data) {
        try{
          const res = await sendWithFeed(data ,"put");
          setResetForm(true)
        }catch(err:any){

        }
    }

 

    const getNewWithFeedForm = async ()=>{
      setLoading2(false)
      try{
      const res = await getWithFeed(selectedObj.Id); 
      setWithFeedForm(res.Data)  
      let newFeeds:any = {Method:[] ,Api:[]}
      let MethodSelectedList:any = []
      let ApiSelectedList:any = []
      res.Data.Feeds.map(item=>{
          if(item.ReportFeedApi){
            let feed = {
            "ParametersObj":item.Parameters,
            "ReportFeedApiId":item.ReportFeedApi.Id ,
            "ReportFeedMethodId": null ,
            "ReportFeedName":item.ReportFeedApi.SystemKey ,
            "Parameters":JSON.stringify(item.Parameters) 
          }
          newFeeds = {...newFeeds , Api : [...newFeeds.Api , feed]}
          ApiSelectedList.push({Id:item.ReportFeedApi.Id  , Name :item.ReportFeedApi.SystemKey })
          }else{
            let feed = {
              "ParametersObj":item.Parameters,
              "ReportFeedApiId": null ,
              "ReportFeedMethodId":item.ReportFeedMethod.Id ,
              "ReportFeedName":item.ReportFeedMethod.ClassName ,
              "Parameters":JSON.stringify(item.Parameters) 
            }
            newFeeds = {...newFeeds , Method : [...newFeeds.Method , feed]}
            MethodSelectedList.push({Id:item.ReportFeedMethod.Id  , Name :item.ReportFeedMethod.ClassName })

          }
      })
      setApplicationIds(res.Data.ApplicationIds)
      setFeedParameters([...newFeeds?.Method ,...newFeeds?.Api ])
      setFeedList({MethodSelectedList:MethodSelectedList ,ApiSelectedList:ApiSelectedList })
      setFeeds(newFeeds)
      if(newFeeds.Api?.length >0){
        setFeedType("Api")
      }else{
        setFeedType("Method")
      }
     }catch(err:any){

       }
        setLoading2(true)
    }
    const handleWithFeedFormSubmit = (event) => {
        event.preventDefault();
        if(mode=="edit"){
          let data={
            "ReportName":event.target.ReportName.value,
            "ReportFileName": event.target.ReportFileName.value,
            "Scope": +event.target.Scope.value,
            "PriorityInList": event.target.PriorityInList.value,
            "IsDashboard": event.target.IsDashboard.checked,
        }
        const res =sendNodeAsync({
          Report:data,
          Feeds:feedParameters.map(item=>(
            {
              ...(item.ReportFeedApiId ? {
                ReportFeedApiId: item.ReportFeedApiId ?  item.ReportFeedApiId  : item.Id
              }:{
                ReportFeedMethodId: item.ReportFeedMethodId ? item.ReportFeedMethodId  :  item.Id
              }),
              Parameters: item.ParametersObj.map(item=>{delete item.Id ; return item})
            }
          )),
          ApplicationIds:applicationIds.map(item=>{if(item?.Id){return item?.Id}else return item })
        })
        }else{

        let data={
            "ReportName":event.target.ReportName.value,
            "ReportFileName": event.target.ReportFileName.value,
            "Scope": +event.target.Scope.value,
            "PriorityInList": event.target.PriorityInList.value,
            "IsDashboard": event.target.IsDashboard.checked,

        }

      const res =   sendNodeAsync({
          Report:data,
          Feeds:feedParameters.map(item=>(
            {
              ...(item.ReportFeedApiId ? {
                ReportFeedApiId: item.ReportFeedApiId
              }:{
                ReportFeedMethodId: item.ReportFeedMethodId
              }),
              Parameters: item.ParametersObj.map(item=>{delete item.Id ; return item})
            }
          )),
          ApplicationIds:applicationIds
        })
        
        }
    }
  
    
     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);

    


     useEffect(() => {

      if(mode != "create" ){

          getNewWithFeedForm()
        
      }else{
        setLoading2(true)
      }
    }, [selectedObj]);
    let content = [
      { title: "reportForm", body: (<>
              <div className="person-contact-form card">
            <div className="">
                <div className="container-fluid">
                        <>
                        {loading2 ?
                    <form id={"WithFeedForm"} className="p-3 " onSubmit={handleWithFeedFormSubmit}>
                        <div className="row ">
                            <div className="col-lg-3 col-sm-4">                
                            <Textbox
                               required={true}
                               defaultValue={WithFeedForm?.Report?.ReportName ? WithFeedForm?.Report?.ReportName  : null}
                               textboxType="text"
                               textboxName="ReportName"
                               labelText="ReportName"
                               resetForm={resetForm}
                               mode={mode=="create" ?mode : "view"}
                            />
                            </div>
                            <div className="col-lg-3 col-sm-4">     
                             <Textbox
                               required={true}
                               defaultValue={WithFeedForm?.Report?.ReportFileName ? WithFeedForm?.Report?.ReportFileName  : null}
                               textboxType="text"
                               textboxName="ReportFileName"
                               labelText="FileName"
                               resetForm={resetForm}
                               mode={mode=="create" ?mode : "view"}
                            />
                            </div>
                            <div className="col-lg-3 col-sm-4">     
                             <Textbox
                               required={true}
                               defaultValue={WithFeedForm?.Report?.PriorityInList ? WithFeedForm?.Report?.PriorityInList  : null}
                               textboxType="number"
                               textboxName="PriorityInList"
                               labelText="Priority"
                               resetForm={resetForm}
                               mode={mode=="create" ?mode : "view"}
                            />
                            </div>
                            <div className="col-lg-3 col-sm-4">
                              <EnumerationTypeSelect
                                required={true} 
                                mode={ "view"}
                                name="Scope"
                                label="Scope"
                                enumTypeSelect="Scope"
                                value={WithFeedForm?.Report?.Scope ? WithFeedForm?.Report?.Scope  : selectedObj?.Scope}
                                 />
                             </div>
                            <div className="col-4">
                              <Application
                                ApplicationListCallback={setApplicationIds}
                                type="SelectBox"
                                mode={mode}
                                required={true}
                                name="ApplicationId"
                                valueList={WithFeedForm?.ApplicationIds}
                              />
                            </div>  
                            <div className="col-2 mt-4 mb-4" >
                                <CheckableButton
                                   defaultChecked={WithFeedForm?.IsDashboard}
                                   defaultValue={WithFeedForm?.IsDashboard}
                                   labelText={"IsDashboard"}
                                   inputName={"IsDashboard"}
                                   type="checkbox"
                                   mode={mode}
                                />                                
                            </div> 
                          </div>
                          <div className="row">
                          <div className={`${ 'flex-row justify-content-between align-items-center'} d-flex mt-2 mb-2`}>
                              <CheckableButton mode={feeds?.Method?.length >0 ? "view":mode}  onclick={()=>setFeedType("Api")}defaultChecked={feedType=="Api"?true : false}  checked={feedType=="Api"?true : false} defaultValue={"Api"} labelText={trans("useApi")} inputName={"feedType"} type={"radio"}/>
                              <CheckableButton mode={feeds?.Api?.length >0 ? "view":mode}  onclick={()=>setFeedType("Method")}defaultChecked={feedType== "Method"?true : false} checked={feedType== "Method"?true : false} defaultValue={"Method"} labelText={trans("useMethod")} inputName={"feedType"} type={"radio"}/>
                            </div>
                            {feedType== "Api"&&

                            <div className=" col-4">
                              <FeedApi
                                disable={feeds?.Method?.length >0}
                                FeedApiListCallback={feedsApiHandler}
                                name="FeedApi"
                                ApiSelectedList={feedList?.ApiSelectedList}

                              />
                            </div>                               
                            }
                            {feedType=="Method"&&
                            
                            <div className=" col-lg-7">
                              <FeedMethods
                               disable={feeds?.Api?.length >0}
                                FeedMethodsListCallback={feedsMethodsHandler}
                                name="FeedApi"
                                FeedMethodList={feedList?.MethodSelectedList}
                              />
                            </div>
                         }
                          </div>
                    </form>
                     : <div className="row card"> <Loading/>  </div>  }
                    </>                  
                </div>
            </div>
        </div>
      
      </>) },
      { title: "FeedParameters", body: (<FeedParameters feeds={[...feeds?.Method ,...feeds?.Api ]} FeedParametersListCallback={setFeedParameters} />) },
  ];


    return (
      <>
      
        <TabBar tabChange={tabChangeHandle} content={content} curentTab={curentTab} defaultTab="UserList" />

        { mode != "view"?
        <div className="col-lg-12 mt-4 btns_form" >
            <Button   btnType="submit" value="save" form="WithFeedForm" />
            <Button btnType="cancel" value="Cancel" onClick={()=>onHide(false)} />
        </div>
        :
        <Button onClick={()=>onHide(false)} btnType="primary" value="Close"  />
        
        }

      
      </>
    );
}