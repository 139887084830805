import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getAllDailyLeave = (async (searchString, orderBy) => await http({
    url: ENDPOINTS.cartable.DailyLeaveRequestClassification.browse,
    method: 'get',
    params: {
        searchString: searchString,
        orderBy: orderBy
    }

}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)

export const getDailyLeaven = (async (id) => await http({
    url: ENDPOINTS.cartable.DailyLeaveRequestClassification.load,
    method: 'get',
    params: { Id: id }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)


export const deleteDailyLeaven =  (id) =>  http({
    url: ENDPOINTS.cartable.DailyLeaveRequestClassification.browse,
    method: 'delete',
    params: {id : id}

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
})


export const postDailyLeave = (data) => http({
    url: ENDPOINTS.cartable.DailyLeaveRequestClassification.browse,
    method: 'post',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})



export const putDailyLeave = (data) => http({
    url: ENDPOINTS.cartable.DailyLeaveRequestClassification.browse,
    method: 'put',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})


