import { TREASURY_AND_ACCOUNTING_URL } from "utils/constants";
import { ENDPOINTSTREASURYANDACCOUNTING } from "utils/endpoints";
import { http } from "utils/http";

export const getReceipts = async (
  pageNum,
  pageSize,
  searchString,
  orderBy,
  filterlist
) =>
  await http({
    baseURL: TREASURY_AND_ACCOUNTING_URL,
    url: ENDPOINTSTREASURYANDACCOUNTING.Treasury.Receipt.brows,
    method: "get",
    params: {
      PageNumber: pageNum,
      PageSize: pageSize,
      OrderByString: orderBy,
      SearchString: searchString,
      FilterString: filterlist,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getReceiptById = async (id) =>
  await http({
    baseURL: TREASURY_AND_ACCOUNTING_URL,
    url: ENDPOINTSTREASURYANDACCOUNTING.Treasury.Receipt.load,
    method: "get",
    params: { Id: id },
    headers: { "Accept-Language": "en" },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const postReceipt = async (method, data) =>
  await http({
    baseURL: TREASURY_AND_ACCOUNTING_URL,
    url: ENDPOINTSTREASURYANDACCOUNTING.Treasury.Receipt.brows,
    method: method,
    data: data,
    headers: { "Accept-Language": "en" },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const deleteReceipt = (id) =>
  http({
    baseURL: TREASURY_AND_ACCOUNTING_URL,
    url: ENDPOINTSTREASURYANDACCOUNTING.Treasury.Receipt.brows,
    method: "delete",
    params: {
      id: id,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
