import { useEffect, useState } from "react";
import { TableTree } from "components/TableTree/TableTree"
import { Toolbar } from "components/Toolbar/Toolbar";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Loading } from "components/Loading/loading"
import { ENDPOINTS } from "utils/endpoints";
import { Message } from "components/Message/Message";
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";
import { ArchiveFolderForm } from "./components/ArchiveFolderForm/ArchiveFolderForm";
import { ArchiveForm } from "./components/ArchiveForm/ArchiveForm";
import { getPersonalArchive } from "./api";
   


export const PersonalArchiveStructure = () => {
  let tbodyData: any = []; let theadData: any = [];

  const [data, setData] = useState<any>()
  const [forceUpdate, setForceUpdate] = useState<boolean>(false)
  const [selectedObj, setSelectedObj] = useState<any>(null)
  const [idCallback, setIdCallback] = useState<any>(null)
  const [mode, setmode] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false);
  const [ShowArchiveFolder, setShowArchiveFolder] = useState<boolean>(false);
  const [showRulesForm, setShowRulesForm] = useState<any>(false);
  const [showForm, setShowForm] = useState<any>(0);


  
  const clickToolbarCallback = (clickedButtonName: string) => {

    switch (clickedButtonName.toLowerCase()) {
      case "reference":
        alert("call api reference with Id :");
        break;
      case "print":
        alert("print ");
        break;
      case "remove":
        break;
      // case "excel":
      //   alert("excel ");
      //   break;
      case "refresh":setForceUpdate(!forceUpdate);break;
      case "view": if(selectedObj){setmode("view");setShowArchiveFolder(!ShowArchiveFolder)}else Toast(trans("Msg_SelectRow"), "warning"); 
        break;
      case "create": if(selectedObj){setmode("create");setShowArchiveFolder(!ShowArchiveFolder);}else Toast(trans("Msg_SelectRow"), "warning"); 
        break;
        case "edit": if(selectedObj){if(selectedObj.RootId == null ){setmode("edit");setShowForm(2);}else{setmode("edit");setShowForm(1);}}else{Toast(trans("Msg_SelectRow"), "warning");};  
        break;
      case "assignrule": if (null == selectedObj.RootId) setShowRulesForm(!showRulesForm); break;

    }
  };

 

  const selectedCreateArchiveCallback = (e) => {
    if(e == 1 && !selectedObj){
      Toast(trans("Msg_SelectRow"), "warning")
    }else{
      setmode("create")
      setShowForm(e)
    }
  }

  const setselecetrow = (e) => {
    setSelectedObj(e)
  }

  const ShowArchiveFolderShow = () => {
    setIdCallback(selectedObj?.Id) 
    setShowArchiveFolder(false) 
  }


  async function getarchiveStructureAsync() {
    setLoading(false)
    const respons = await getPersonalArchive(null)
    setData(respons.Data)
    setLoading(true)
  }


  

  useEffect(() => {
    setData(null)
    getarchiveStructureAsync()
  }, [forceUpdate]);


  useEffect(() => {
    if(idCallback){
      setIdCallback(null)
    }
  }, [idCallback]);



  if (data?.length > 0) {
    theadData = Object.keys(data[0])
    tbodyData = data;
  }  


    return (
     <>
      {
        showForm==1 &&
          <ModalForm mode={mode} title="File" modalHeight={35} onHide={() => setShowForm(0)} show={showForm==1}>
              <ArchiveFolderForm 
                showForm={() =>{ShowArchiveFolderShow(); setShowForm(0);}} 
                selectedObj={selectedObj} 
                mode={mode} 
              />
          </ModalForm>
        }
        {
            showForm==2 &&
              <ModalForm mode={mode} title="Archive" modalHeight={35} onHide={() => setShowForm(0)} show={showForm==2}>
                  <ArchiveForm 
                    showForm={() => {setShowForm(0);setForceUpdate(!forceUpdate)}} 
                    selectedObj={selectedObj} 
                    mode={mode} 
                  />
              </ModalForm>
        }

        <Toolbar 
          emptyList={data?.length == 0} 
          handleClickToolbar={clickToolbarCallback} 
          id={selectedObj} 
          handleCreateArchive={selectedCreateArchiveCallback}
          create={false} 
          view={false} 
          search={false} 
          edit={true}
          excel={false}
        />

        <div style={{ overflowY: "auto", 
                height: "78vh",
                 scrollbarWidth: "thin"}}>
            {
              loading ? (
                data ?
                <div>
                  <TableTree
                    selectedRowObjectCallback={setselecetrow}
                    selectedRowObject={idCallback}
                    getChildrenUrl={ENDPOINTS.archive.personalArchive.archives}
                    theadData={["Id" , "ParentId", "DetailList" , "Title" ]}
                    startIndex={2}
                    Titleparams={"Title"}
                    Childrenparams={'DetailList'}
                    defultShow={false}
                    Data={data}
                    parentName={"PersonalArchive"}
                  /> 
                </div>: <Message message={"NoDataForPreview"} type={"error"} />)
                : <Loading />
            }
        </div>
      </>
    )
}




