
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getDocumentsByReferenceId = (referenceId: any) => http({
    url: ENDPOINTS.archive.documentArchive.referenceId,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
        referenceId: referenceId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});

export const getReverseList = (referenceId: any) => http({
    url: ENDPOINTS.cartable.refer.reverseList,
    method: 'get',
    params: {
        referenceId: referenceId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
      //اطلاعات
    return Promise.reject(err.response  );
});

export const getDocuments = (documentsId: any) => http({
    url: ENDPOINTS.archive.documentArchive.load,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
        Id: documentsId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});

export const postDocuments = (data: any, returnReferenceId ,processId) => http({
    url: ENDPOINTS.archive.documentArchive.browse,
    method: 'post',
    data: data,
    params: { 
        PRJ_UID: processId
    }
}).then((res) => {
     
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});

export const putDocuments = (data: any) => http({
    url: ENDPOINTS.archive.documentArchive.browse,
    method: 'put',
    data: data
}).then((res) => {
     
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});
export const getDynaFormProcessId = (name: any) => http({
    url: ENDPOINTS.bpmnApi.process.getProcessId,
    method: 'get',
    params: {
        formName :name 
    }

}).then((res) => {
     
    return res.data
}).catch((err) => {
     
    return Promise.reject(err);
});
