import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import "./style.scss";
interface RangeSliderPropsType {
    resetForm: boolean,
    hasBorder?: boolean,
    label?: string,
    mode?: string,
    defaultValue?: number,
    name: string,
    type?: string,
    Range?: any,
    required?:boolean,
    useTrans?:boolean,
    Change?: (e) => void,
}
export const RangeSlider = ({ hasBorder,Change, label, useTrans=true, defaultValue, name,type="line",Range , resetForm ,mode ,required}: RangeSliderPropsType) => {
    const rangeSliderRef = useRef(null);
    const [rangeInputValue, setRangeInputValue] = useState(defaultValue ? defaultValue : "0");
    const [disabled, setDisabled] = useState(mode =="view" ? true : false);
    const [selectIndex, setSelectIndex] = useState<any>(0);

    function updateRangeInputValue(event) {
        event.stopPropagation();
        setRangeInputValue(event.currentTarget.value);
        defaultValue != event.currentTarget.value ? Change&&Change({[name] :event.currentTarget.value}):Change&&Change({[name] :null});
    }

    function MouseMove(event) {
        event.stopPropagation();
        
    }
    useEffect(() => {
        if (resetForm)
            setRangeInputValue("0")
    }, [resetForm])
    useEffect(() => {
        if (defaultValue) setRangeInputValue(`${defaultValue}`)
    }, [defaultValue])
    return (
        <>
            {type == "line" ?
            <div className={`range-slider-wrapper   ${!hasBorder && "remove-border"}`}>
            {label && <label htmlFor="customRange1" className="text-box__label form-label ">{useTrans ? trans(label) : label}</label> }
            <span>{`  %${rangeInputValue}  `}</span>

                <input required={required} disabled={disabled} defaultValue={defaultValue}  name={name} value={rangeInputValue} onMouseMove={MouseMove} onChange={updateRangeInputValue} type="range" style={{ display: "block", width: "100%" }} id="customRange1" min={0} max={100} />
            </div>
            :

             <div className={` form-control-custom  ${!hasBorder && "remove-border"}`}>
             <input  defaultValue={selectIndex+1}  name={name} value={selectIndex+1} type="number" style={{ display: "none" }}/>

                       {Range &&      
                       <>
                       <span>{` ${trans(Range[selectIndex+1])}  `}</span>
                       <div style={{ display: "block", width: "100%" }}>

                         { Object.keys(Range).map((item , index)=>{
                                  if(index>selectIndex){
                                    return  <FontAwesomeIcon style={{width:"20px" , height:"20px"}}  icon={["far", "star"]} onClick={()=>{setSelectIndex(index)}} className="text-warning" />
                                  }else{
                                    return <FontAwesomeIcon style={{width:"20px" , height:"20px"}} icon={[ICON_TYPE, "star"]} onClick={()=>{setSelectIndex(index)}} className="text-warning" />
                                  }
                              
                              
                          })
                       }
                       </div>
                       </>

                }
             </div>
            
        
        
        
        }
        </>
    );
}