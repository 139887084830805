import ListGroup from "react-bootstrap/ListGroup";
import "./systemSettingMenu.scss";
import { useEffect, useState } from "react";
import { getSystemSettingGroupTitle } from "./api";
import { trans } from "utils/helpers";
interface SystemSettingGroupItemProps{
  index:number,
  item:string,
  handleClickItemGroup:()=>void,
  selectedGroupTitle:string
}
export function SystemSettingGroupItem({
  index,
  item,
  handleClickItemGroup,
  selectedGroupTitle,
}) {
  return (
    <div className="row h-100 setting-menu">
      <div
        className={`px-2 list-item py-1 ${index === 0 ? "mt-0" : "my-1"} ${
          selectedGroupTitle === item && "list-item-active"
        }`}
        onClick={() => handleClickItemGroup(item)}
      >
        {trans(item)}
      </div>
    </div>
  );
}
