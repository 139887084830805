import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { KeysList } from "./KeysList";
import { getItemGroupRootsFormat } from "../../api";
import { getItemGroupLeaf } from "./api";


interface ItemFormatsFormPropsType {
    id?: any,
    mode?: any,
    inTab?: any,
    formRef?: any,
    data?:any;
    checkData: any;
    ItemGroupLeafData: any;
    submitItemFormats: (data) => void;

}

export const ItemFormatsForm = ({
    id,
    mode,
    inTab,
    formRef,
    data,
    checkData,
    ItemGroupLeafData,
    submitItemFormats,
 
}: ItemFormatsFormPropsType) => {
    const [resetForm, setResetForm] = useState(false);
    const [ItemFormatsCodeFormat,setItemFormatsCodeFormat] = useState<any>({})
    const [showKeysList,setShowKeysList] = useState<any>()
    const [loading, setLoading] = useState(false);
    const [response,setResponse] = useState<any>()
    const [itemGroupFormat,setItemGroupFormat] = useState<any>()
    const [ItemLayerFormatlevels,setItemLayerFormatlevels] = useState<any>(1)
    const [levels,setlevels] = useState<any>(null)
    const [responseLength,setResponseLength] = useState<any>()
    const formatLetters={
        0:"A",
        1:"B",
        2:"C",
        3:"D",
        4:"E",
        5:"F",
        6:"G",
        7:"H",
        8:"I",
        9:"J",
        10:"K",
        11:"L",
    }


    function countSpecificLetter(string, letter) {
        if(string&&letter){
            string = string.toLowerCase();
            letter = letter.toLowerCase();
            let count = 0;
            for (let char of string) {
                if (char === letter) {
                    count++;
                }
            }
            return count;
        }else return 0
    }



    async function getItemGroupRootsFormatAsync() {
       setLoading(false)
       try {
        

       const res = await getItemGroupRootsFormat();
       let str = res?.Data[0]+ (ItemGroupLeafData?.LeafCodeFormat ?ItemGroupLeafData?.LeafCodeFormat : "" ) ;  
       let levels = str.split('').filter((item, pos, self) => self.indexOf(item) === pos);  
       setResponse( res?.Data[0]) 
       setResponseLength((res.Data[0].split('').filter((item, pos, self) => self.indexOf(item) === pos)).length)
       setlevels(levels.length -  (res.Data[0].split('').filter((item, pos, self) => self.indexOf(item) === pos)).length)
      // setlevels(levels.length)
       let itemGroupFormat ={}
       levels.map((item , index)=>{
                itemGroupFormat= {...itemGroupFormat ,  [formatLetters[index]]:  countSpecificLetter(str ,formatLetters[index] )}
            })

        setItemGroupFormat(itemGroupFormat)

        if(mode!="create"){
            if(id){
                const res2 = await getItemGroupLeaf(id);
                setItemLayerFormatlevels(res2.Data.ItemLayerLevelCount)
                if(res2.Data.ItemLevelSpecificationList.length>0){
                    let obj:any={}
                    res2.Data.ItemLevelSpecificationList.map(item=>{
                        obj[item.CodeFormatLetter ] =  {
                            CodeFormatLetter:Array(char_count(ItemGroupLeafData.LeafCodeFormat ,item.CodeFormatLetter)).fill(item.CodeFormatLetter).join("") ,
                            ItemMainPropertyDescription :item.ItemMainPropertyDescription ,
                            ItemMainPropertyId:item.ItemMainPropertyId ,
                        }
                    })
                setItemFormatsCodeFormat(obj)
              }

            }else if(ItemGroupLeafData?.ItemLevelSpecificationList){
                if(ItemGroupLeafData?.ItemLevelSpecificationList.length>0){
                    let obj:any={}
                    ItemGroupLeafData?.ItemLevelSpecificationList.map(item=>{
                        obj[item.CodeFormatLetter] =  {
                            CodeFormatLetter:Array(char_count(ItemGroupLeafData.LeafCodeFormat ,item.CodeFormatLetter)).fill(item.CodeFormatLetter).join("") ,
                            ItemMainPropertyDescription :item.ItemMainPropertyDescription ,
                            ItemMainPropertyId:item.ItemMainPropertyId ,
                        }
                    })
                setItemFormatsCodeFormat(obj)
              }
            }
        }else if(ItemGroupLeafData) {
            setItemLayerFormatlevels(ItemGroupLeafData.ItemLayerLevelCount)

            if(ItemGroupLeafData?.ItemLevelSpecificationList.length>0){
                let obj:any={}
                ItemGroupLeafData?.ItemLevelSpecificationList.map(item=>{
                    obj[item.CodeFormatLetter] =  {
                        CodeFormatLetter:Array(char_count(ItemGroupLeafData.LeafCodeFormat ,item.CodeFormatLetter)).fill(item.CodeFormatLetter).join("") ,
                        ItemMainPropertyDescription :item.ItemMainPropertyDescription ,
                        ItemMainPropertyId:item.ItemMainPropertyId ,
                    }
                })
            setItemFormatsCodeFormat(obj)
          }
        }
        }
          catch (error) {
        }
        setLoading(true)

    }
    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }

    }, [resetForm]);
    useEffect(() => {
      //  if(inTab=="ItemCodeFormat" )
            getItemGroupRootsFormatAsync()

    }, [inTab]);

    function stringToUniqueArray(e:any=1, index) {
            let obj:any = ItemFormatsCodeFormat;
            if(obj[formatLetters[index+responseLength+1]]){
                obj[formatLetters[index+responseLength+1]] = {
                    ItemMainPropertyDescription:ItemFormatsCodeFormat[formatLetters[index+responseLength+1]].ItemMainPropertyDescription  ,
                    ItemMainPropertyId:ItemFormatsCodeFormat[formatLetters[index+responseLength+1]].ItemMainPropertyId  ,
                    CodeFormatLetter: formatLetters[index+responseLength+1].repeat(+e)
                }
            }else{
                obj[formatLetters[index+responseLength+1]] = {
                    CodeFormatLetter:formatLetters[index+responseLength+1],
                    ItemMainPropertyDescription :null,
                    ItemMainPropertyId:null,
                }
            }
            setItemFormatsCodeFormat(obj)
            submitItemFormats&& submitItemFormats(obj)
        //}else{
        //    submitItemFormats&& submitItemFormats(ItemFormatsCodeFormat)
        //}
        setItemGroupFormat((pre)=> ({...pre , [formatLetters[index+responseLength+1]]: +e}))
    }
    function char_count(str, letter) {
        var letter_Count = 0;
            for (var position = 0; position < str.length; position++) {
            if (str.charAt(position) == letter) {
                letter_Count += 1;
            }
        }
    
        // Return the final count of occurrences
        return letter_Count;
    }
    useEffect(() => {

            if(ItemGroupLeafData && ItemGroupLeafData.ItemLevelSpecificationList.length>0){
                let obj:any={}
                
                ItemGroupLeafData.ItemLevelSpecificationList.map(item=>{
                    obj[item.CodeFormatLetter] ={
                        CodeFormatLetter: Array(char_count(ItemGroupLeafData.LeafCodeFormat ,item.CodeFormatLetter)).fill(item.CodeFormatLetter).join("")   ,
                        ItemMainPropertyDescription :item.ItemMainPropertyDescription ,
                        ItemMainPropertyId:item.ItemMainPropertyId ,
                    }
                })
                getItemGroupRootsFormatAsync()
                setItemFormatsCodeFormat(obj)
                submitItemFormats&& submitItemFormats(obj)
            }
        
    }, [ mode ,ItemGroupLeafData]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }

    }, [resetForm]);

    return (
        <>
            {showKeysList &&
                <ModalForm modalHeight={70} mode={mode} title="itemproperties" onHide={() => setShowKeysList(null)} show={showKeysList}>
                   <KeysList mode={mode} VariationObj={showKeysList}  
                     PropertiesListcallback={(e)=>{setItemFormatsCodeFormat({...ItemFormatsCodeFormat , [showKeysList.CodeFormatLetter[0]]:{CodeFormatLetter:showKeysList.CodeFormatLetter ,ItemMainPropertyId:e.Id , ItemMainPropertyDescription : e.Title, KeyDescription: e.Title } } );      
                      submitItemFormats&& submitItemFormats({...ItemFormatsCodeFormat , [showKeysList.CodeFormatLetter[0]]:{CodeFormatLetter:showKeysList.CodeFormatLetter ,ItemMainPropertyId:e.Id , ItemMainPropertyDescription : e.Title, KeyDescription: e.Title } } )
                     ;setShowKeysList(null)}}  onHide={() => setShowKeysList(null)} />
                </ModalForm>
             }
            { (loading) ?
                <div className="card-body">
                    <div className="row">

                    <div className="col-3">
                           <Textbox
                            textboxType="number"
                            textboxName={"LayerCodeFormat"}
                            labelText="تعداد سطوح "
                            onChange={(e)=>{setlevels(e.target.value); if(true){stringToUniqueArray(1,e.target.value-responseLength) ; };    }}
                            defaultValue={levels  }
                            min={1}
                            required={true}
                            resetForm={resetForm}
                            mode={mode}
                            />
                        </div> 
                    <div className="col-3">
                           <Textbox
                            textboxType="number"
                            textboxName={"ItemLayerCodeFormat"}
                            labelText="تعداد سطوح کالا"
                            onChange={(e)=>{setItemLayerFormatlevels(e.target.value) }}
                            defaultValue={ItemLayerFormatlevels  }
                            min={1}
                            max={levels}
                            required={true}
                            resetForm={resetForm}
                            mode={mode}
                            />
                        </div> 




                    {itemGroupFormat&&
                    <div className="col-3">
                        <Textbox
                            textboxType="CodeFormat"
                            textboxName="ItemGroupFormat"
                            defaultValue={Object.keys(itemGroupFormat).slice(0, +levels+responseLength).map(item=>(Array(+itemGroupFormat[item]).fill(item).join(""))).join("").replace(response,"")}
                            value={Object.keys(itemGroupFormat).slice(0, +levels+responseLength).map(item=>(Array(+itemGroupFormat[item]).fill(item).join(""))).join("").replace(response,"")}    
                            resetForm={resetForm}
                            capsLock={true}
                            labelText="itemcodeformat"
                            mode={"view"}
                            />
                    </div> 
                    }
                    {response&&
                    <div className="col-3">
                            <Textbox
                                textboxType="CodeFormat"
                                textboxName="FinalItemGroupFormat"
                                defaultValue={response}
                                value={response}
                                resetForm={resetForm}
                                capsLock={true}
                                labelText="itemgroupcodeformat"
                                mode={"view"}
                                />
                        </div> 
                        }

                    </div>

                    {ItemFormatsCodeFormat&&
                    <div className="card">

                            {ItemFormatsCodeFormat&&Object.values(ItemFormatsCodeFormat).map((item:any,index)=>(
                                <>{+levels>index?
                            <>
                            {ItemLayerFormatlevels==index?
                            <>
                            <div className="card col-12"></div>
                            
                            <div className="col-6 row  ">
                                    <div className="d-flex align-items-center">
                                        <div className="col-4 ">
                                           <Textbox
                                            textboxType="number"
                                            textboxName={"Itemgrouplevel" + index+responseLength}
                                            onChange={(e)=>{stringToUniqueArray(e.target.value,index-1) }}
                                            defaultValue={ countSpecificLetter(Object.keys(itemGroupFormat).map(item=>(Array(+itemGroupFormat[item]).fill(item).join(""))).join("") ,responseLength? formatLetters[index+responseLength]:formatLetters[index] )+"" }
                                            required={true}
                                            resetForm={resetForm}
                                            labelText={ trans("LevelCodeLength") +" "  + (index+responseLength+1)+ `(${formatLetters[index+responseLength]})`}
                                            mode={mode}
                                            />
                                        </div>
                                        <span style={{fontSize:"16px" , marginRight:"13px" , marginTop:"5px"}} className="col-2">{item.CodeFormatLetter} : </span>
                                        <div className="col-5">
                                        <Textbox
                                            textboxType="text"
                                            textboxName={item.CodeFormatLetter +"KeyDescription"}
                                            resetForm={resetForm}
                                            value={item.ItemMainPropertyDescription }
                                            defaultValue={item.ItemMainPropertyDescription }
                                            labelText={`${trans("Description")} ${(item.KeyDescription&&item.KeyDescription!=undefined) ? ("("+item.KeyDescription +")" ):""}`}
                                            mode={mode}
                                            />
                                        </div>
                                    <div className="col-1">
                                        {mode!= "view"?
                                                <Button Icon={true} value={<button disabled={mode=="view"} type="button" onClick={(e)=>{
                                                    if(item.ItemMainPropertyId) {
                                               
                                                        setItemFormatsCodeFormat({...ItemFormatsCodeFormat ,[item.CodeFormatLetter[0]]:{CodeFormatLetter:item.CodeFormatLetter} })
                                                         submitItemFormats({...ItemFormatsCodeFormat ,[item.CodeFormatLetter[0]]:{CodeFormatLetter:item.CodeFormatLetter} })
                                                        }setShowKeysList(item)}} className={`${item.ItemMainPropertyId?"checkButtonDanger" : "checkButton"}   mt-0 d-flex align-items-center justify-content-center`} > <FontAwesomeIcon icon={[ICON_TYPE, item.ItemMainPropertyId?"xmark" : "search"]} style={{transform:"rotateY(180deg)",fontSize:"15px"}}  size="1x"  /></button>}  btnType="cancel"onClick={(e)=>{}}   />
                                                :
                                                <>
                                                {item.ItemMainPropertyId &&
                                                <Button Icon={true} value={<button disabled={mode=="view"} type="button" onClick={(e)=>{ setShowKeysList(item)}} className={ "checkButton  mt-0 d-flex align-items-center justify-content-center" } > <FontAwesomeIcon icon={[ICON_TYPE, "eye"]} style={{transform:"rotateY(180deg)",fontSize:"15px"}}  size="1x"  /></button>}  btnType="cancel"onClick={(e)=>{}}   />
                                                }
                                                </>
                                        }
                                    </div>
                                    </div>
                                </div>  
                            </>
                                :

                             <div className="col-6 row  ">
                                    <div className="d-flex align-items-center">
                                        <div className="col-4 ">
                                           <Textbox
                                            textboxType="number"
                                            textboxName={"Itemgrouplevel" + index+responseLength}
                                            onChange={(e)=>{stringToUniqueArray(e.target.value,index-1) }}
                                            defaultValue={ countSpecificLetter(Object.keys(itemGroupFormat).map(item=>(Array(+itemGroupFormat[item]).fill(item).join(""))).join("") ,responseLength? formatLetters[index+responseLength]:formatLetters[index] )+"" }
                                            required={true}
                                            resetForm={resetForm}
                                            labelText={ trans("LevelCodeLength") +" "  + (index+responseLength+1)+ `(${formatLetters[index+responseLength]})`}
                                            mode={mode}
                                            />
                                        </div>
                                        <span style={{fontSize:"16px" , marginRight:"13px" , marginTop:"5px"}} className="col-2">{item.CodeFormatLetter} : </span>
                                        <div className="col-5">
                                        <Textbox
                                            textboxType="text"
                                            textboxName={item.CodeFormatLetter +"KeyDescription"}
                                            resetForm={resetForm}
                                            value={item.ItemMainPropertyDescription }
                                            defaultValue={item.ItemMainPropertyDescription }
                                            labelText={`${trans("Description")} ${(item.KeyDescription&&item.KeyDescription!=undefined) ? ("("+item.KeyDescription +")" ):""}`}
                                            mode={mode}
                                            />
                                        </div>
                                    <div className="col-1">
                                        {mode!= "view"?
                                                <Button Icon={true} value={<button disabled={mode=="view"} type="button" onClick={(e)=>{
                                                    if(item.ItemMainPropertyId) {
                                               
                                                        setItemFormatsCodeFormat({...ItemFormatsCodeFormat ,[item.CodeFormatLetter[0]]:{CodeFormatLetter:item.CodeFormatLetter} })
                                                         submitItemFormats({...ItemFormatsCodeFormat ,[item.CodeFormatLetter[0]]:{CodeFormatLetter:item.CodeFormatLetter} })
                                                        }setShowKeysList(item)}} className={`${item.ItemMainPropertyId?"checkButtonDanger" : "checkButton"}   mt-0 d-flex align-items-center justify-content-center`} > <FontAwesomeIcon icon={[ICON_TYPE, item.ItemMainPropertyId?"xmark" : "search"]} style={{transform:"rotateY(180deg)",fontSize:"15px"}}  size="1x"  /></button>}  btnType="cancel"onClick={(e)=>{}}   />
                                                :
                                                <>
                                                {item.ItemMainPropertyId &&
                                                <Button Icon={true} value={<button disabled={mode=="view"} type="button" onClick={(e)=>{ setShowKeysList(item)}} className={ "checkButton  mt-0 d-flex align-items-center justify-content-center" } > <FontAwesomeIcon icon={[ICON_TYPE, "eye"]} style={{transform:"rotateY(180deg)",fontSize:"15px"}}  size="1x"  /></button>}  btnType="cancel"onClick={(e)=>{}}   />
                                                }
                                                </>
                                        }
                                    </div>
                                    </div>
                                </div>                            
                                 }
                            </>
                                : <></>
                                }
                                </>
                            ))}
                    </div>  
                    }
                </div>
                :
                <Loading/>
                }

    </>
    );
}










