import { TabBar } from "components/Tabbar/Tabbar";
import { Toast } from "components/ToastShow/ToastShow";
import { useRef, useState } from "react";
import { trans } from "utils/helpers";
import { MyOwnOrdersList } from "./MyOwnOrdersList";





export const MyOwnOrdersPage = () => {

  const [curentTab, setCurentTab] = useState<any>("current")
  const refcurentTab = useRef(curentTab);
  const [user, setUser] = useState(false);
  const refuser = useRef(user);
  const tabChangeHandle = (tab) => {
   refcurentTab.current = tab ;
   setCurentTab(tab)
  }


  let content = [
    { title: "درحال بررسی", body: (<MyOwnOrdersList  curentTab={curentTab} tabName={"درحال بررسی"} Type={2}    />), },
    { title: "خروج از انبار", body: (<MyOwnOrdersList curentTab={curentTab} tabName={"خروج از انبار"    } Type={3} />) },
    { title:  "انجام شده", body: (<MyOwnOrdersList curentTab={curentTab} tabName={ "انجام شده"} Type={4} />) },
    { title:  "در انتظار پرداخت", body: (<MyOwnOrdersList curentTab={curentTab} tabName={ "در انتظار پرداخت"} Type={5} />) },
    { title: "در حال آماده‌سازی", body: (<MyOwnOrdersList curentTab={curentTab} tabName={"در حال آماده‌سازی"} Type={null} />) },
    // { title: "MyOwnRequestRealTest", body: (<MyOwnRequestRealListTest />), },
  ];



  return (
    <>
      <div className="card">
        <div className="card-body">
            <MyOwnOrdersList  curentTab={curentTab} tabName={"current"} Type={2}    />
        </div>
      </div>
   
    <div className="mt-1">
{/*       <TabBar Click={(e)=>{Toast(trans("modalclosemessage") , "warning" , "alert" , ()=>{setUser(false); refuser.current = false})} }  tabChange={tabChangeHandle} content={content} defaultTab="current" />
 */}    </div>
    </>
  );
};
