
import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getActions = (PageNumber: number, PageSize: number, taskId: any) => http({
    url: ENDPOINTS.personalPlanningApi.personalAction.browse,
    method: 'get',
    params: {
        pageNumber: PageNumber,
        pageSize: PageSize,
        personalTaskId: taskId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});



export const getAction = (id: any) => http({
    url: ENDPOINTS.personalPlanningApi.personalAction.load,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        Id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});



export const sendAction = (data: string, method) => http({
    method: method,
    url: ENDPOINTS.personalPlanningApi.personalAction.browse,
    data: data
}).then((res) => {
     
    return res
}).catch((err) => {
    return Promise.reject( err);
});



export const deleteAction = (id) => http({
    method: "delete",
    url: ENDPOINTS.personalPlanningApi.personalAction.browse,
    params:{
        Id:id
    }
}).then((res) => {
     
    return res
}).catch((err) => {
    return Promise.reject( err);
});


