import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { addItemToBasket } from "utils/helpers";
import { ItemCounterBtn } from "./ItemCounterBtn";


interface ItemCountSeterProps {
    ItemData?: any,
    pluseInRight?:boolean,
    positionRelative?:boolean,
    defultValue?:any,
    min?:any,
    max?:any,
    stock?:number,
    activeBtn?:any,
    priceIndex?:any,
    propertiesId?:any,
    properties?:any,
    multiCounter?:boolean,
    showSumCount?:boolean,
    reload?:()=>void,
    setActiveBtn?:(e:any)=>void
    cardsListPage?:any,

}

export const ItemCountSeter = ({cardsListPage,showSumCount,priceIndex,properties,multiCounter=true,propertiesId,activeBtn=null,setActiveBtn,reload,min=null,max=null,stock,defultValue,ItemData,pluseInRight=true,positionRelative=true }:ItemCountSeterProps) => {
    const [countBox,setCountBox] = useState(false)
    const [sumCount,setSumCount] = useState(0)

    const [itemOrder,setItemOrder] = useState<any>({})
    const layoutContext = useContext(LayoutContext);
    function addItem(){ 
        if(itemOrder){
            let OrderItemId = ItemData.ItemPriceListDetails[0].PriceDetails.find(item=>item.ItemCode.includes(propertiesId.replaceAll(ItemData.Id , "")))	
            ItemData = {...ItemData  ,order:{...itemOrder, [propertiesId] :{properties:properties, OrderItemId:OrderItemId,count :min ? min : 1, priceIndex:priceIndex, min:min , max:max != null ?max:null }}}
            addItemToBasket(ItemData,propertiesId)
        }
        setCountBox(true)
    }
    useEffect(() => {
        let Basket:any =  localStorage.getItem("Basket")
        if(ItemData){  
            if(Basket){
                Basket = JSON.parse(Basket)
                if(showSumCount && Basket[ItemData.Id] && Object.values(Basket[ItemData.Id].order).length > 0){
                    let count = 0
                    Object.values(Basket[ItemData.Id]?.order).map((item:any) =>{
                            count = +item['count'] + count
                    })
                    setSumCount(count)
                    setCountBox(true)
                }
                if(Basket?.[ItemData.Id]){
                    setItemOrder(Basket[ItemData.Id]?.order ? Basket[ItemData.Id]?.order :{})
                }else{
                    setItemOrder({})

                }
                if(Basket[ItemData.Id]?.order?.[propertiesId] && Basket[ItemData.Id]?.order?.[propertiesId]?.count > 0){
                    if(!ItemData.order || ItemData.order[propertiesId]==undefined){
                        ItemData.order={[propertiesId]:{'properties':properties,count:0}}
                    }
                    
                    ItemData.order[propertiesId].count = Basket[ItemData.Id]?.order?.[propertiesId]?.count

                    setCountBox(true)

                    if((max == null && min == null )){
                        setCountBox(true)
                    }else{
                        if(multiCounter){
                            if((ItemData?.order[propertiesId]?.count >= min && max == null) || (ItemData?.order[propertiesId]?.count >= min && ItemData?.order[propertiesId]?.count<= max)){
                                setCountBox(true)
                            }
                        }

                    }

                }else{
                    if(ItemData?.order?.[propertiesId]?.count == 0){
                        setCountBox(false)
                    }
                }
            }
        }
    }, [layoutContext.basket]);
     useEffect(() =>{
         if(activeBtn != null){
            if(activeBtn == priceIndex){
                setCountBox(true)
            }else{           
                setCountBox(false)       
            }
        }
     },[activeBtn])

     useEffect(()=>{
        if(countBox){
            setActiveBtn && setActiveBtn(priceIndex)
        }else{
            setActiveBtn && setActiveBtn(null)
        }
     },[countBox])

     useEffect(() => {
        layoutContext.setBasket()
    }, [ItemData]);


    return ( 
    <div className={`d-flex w-100 `}  >
        {countBox?
        <>      
            <ItemCounterBtn  defultValue={sumCount} showSumCount={showSumCount} multiCounter={multiCounter} properties={properties} propertiesId={propertiesId} reload={reload} min={min}  priceIndex={priceIndex} max={max} stock={stock} ItemData={ItemData} classStyle={cardsListPage}  closePluse={()=>{setCountBox(false)}}/>
        </>
        :
        <button className="btn btn-customerPrimaryColor plusBtn" onClick={()=>{addItem();setActiveBtn && setActiveBtn(priceIndex)}}>
            <FontAwesomeIcon icon={["fas", "plus"]} style={{color:'danger'}}/>
        </button>
        }
        
    </div> 
    );
}
