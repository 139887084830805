import { GroupingPriceFunction, trans } from "utils/helpers";

export function PriceDetails({ priceListDetail }) {

  
  return (
    <div className="card-body "  style={{minWidth:'298px',width:'298px'}}>
      <div className="card"  style={{minWidth:'280px',maxWidth:'100%'}}>
        <div className="card-body" style={{ textAlign: "center" }}>
          {trans("PriceByQuantity")}
        </div>
      </div>
      <div className="card"  style={{minWidth:'280px',maxWidth:'100%'}}>
        <div className="card-body">
          {priceListDetail.map((item) => (
            <div className="row mt-2">
              <div className="col-5">
              <div
                  className="col p-0 mt-1"
                  style={{
                    direction: "ltr",
                    textDecorationLine: "line-through",
                    textAlign: "center",
                  }}
                >
                  {GroupingPriceFunction(item.SalesUnitPrice)}
                </div>
                <div
                  className="col p-0 mt-1"
                  style={{
                    fontWeight: "bold",
                    direction: "ltr",
                    textAlign: "center",
                  }}
                >
                  {item.SalesUnitDiscount?GroupingPriceFunction(
                  item.SalesUnitPrice  - item.SalesUnitDiscount):
                    GroupingPriceFunction(
                      item.SalesUnitPrice  - item.SalesUnitDiscount)
                  }
                </div>
          
              </div>
              <div
                className="col"
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#d2d2d2",
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  className="textPriceByQuantity"
                  style={{ maxHeight: "85%" }}
                >
                  {(item.SalesUnitMinOrderQty+"").slice(0,5)} الی{" "}
                  {item.SalesUnitMaxOrderQty ? item.SalesUnitMaxOrderQty : "نامحدود "}
                  {item?.SalesUnitId==item?.Unit1Id?item?.Unit1:item?.Unit2}
               
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
