import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getProcessIcons = async() => await http({
    url: ENDPOINTS.icon,
    method: 'get',
}).then((response) => response.data)


export const getProcesIcon = async(id) => await http({
    url: ENDPOINTS.bpmnApi.bpmnProcessIcon.browse,
    method: 'get',
    params:{
        PRJ_UID :id
    }
}).then((response) => response.data)
.catch((err) => {
    return Promise.reject(err);
});

