import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { getPriceListDictionary } from "../../api";
import { SelectBox } from "components/Select/Select";
import { InputLoad } from "components/InputLoading/inputLoading";
import { GUID_EMPTY } from "utils/constants";
import { Toast } from "components/ToastShow/ToastShow";
interface PriceListDictionaryPropsType {
  label: string;
  hasBorder?: boolean;
  resetForm: boolean;
  required?: boolean;
  isDisabled?: boolean;
  showOption?: boolean;
  withNullOption?: boolean;
  value?: string;
  mode?: string;
  name: string;
  Change?: (e) => void;
  onChange?: (e, obj?) => void;
}

export const PriceListDictionary = ({
  label,
  hasBorder,
  value,
  mode,
  resetForm,
  required,
  isDisabled=false,
  showOption=true,
  withNullOption=false,
  name = "",
  Change,
  onChange,

}: PriceListDictionaryPropsType) => {
  const [PriceListDictionary, setPriceListDictionary] = useState<any>();
  const [defaultval, setdefault] = useState(value);
  const [errcode, setErrcode] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getPriceListDictionaryAsync = async () => {
    setLoading(false);
    try {
      const res = await getPriceListDictionary(value as any);

      if (res?.Data) {
        if(withNullOption){
          setPriceListDictionary({[GUID_EMPTY]: {
            Id:	GUID_EMPTY,
            Title:"",
            IsManual:	false,
            IsPublic:	true,
          }  ,...res?.Data});
        }else{
          setPriceListDictionary(res?.Data);
        }
        if (value) {
      //    onChange && onChange(res?.Data[value]);
          
        } else {
          onChange && onChange( withNullOption ?  {
            Id:	GUID_EMPTY,
            Title:"",
            IsManual:	false,
            IsPublic:	true,
          }   :  Object.values(res?.Data)[0]);
        }
      }
      
    } catch (error: any) {
      setErrcode(error.response.status);
      setPriceListDictionary(null);
    }
    setLoading(true);
  }
  
  const handleOnChange = (e) => {
    onChange && onChange(PriceListDictionary[e.target.value]);
    Change && Change(true);


    if (
      e.target &&
      e.target.value != (value ? value : Object.keys(PriceListDictionary)[0])
    ) {
      Change && Change({ [name]: e.target.value });
    } else {
      Change && Change({ [name]: null });
    }
  };

  useEffect(() => {
    getPriceListDictionaryAsync();
  }, []);

  useEffect(() => {
    if (value && PriceListDictionary) {

      onChange && onChange(PriceListDictionary[value]);
    }
  }, [value, PriceListDictionary]);

  useEffect(() => {
    setdefault(value);
  }, [resetForm]);

  return (
    <>
      {PriceListDictionary && loading ? (
        <>
          <SelectBox
            onClick={(e)=>{ if(!showOption){Toast(trans("Msg_ChooseWarehouse"), "error") ; }}}
            isDisabled={isDisabled}
            showOption={showOption}
            onChange={(e) => {
              handleOnChange(e);
            }}
            lable={label}
            selectType={"select"}
            options={Object.values(PriceListDictionary).map((item: any) => {
              return item.Title;
            })}
            val={Object.keys(PriceListDictionary)}
            name={name}
            value={value}
            resetForm={resetForm}
            mode={mode}
            required={required}
          />
          <div className="text-box__msg-wrapper"></div>
        </>
      ) : (
        <InputLoad
          msg={errcode}
          required={required}
          labelText={label}
          onClick={getPriceListDictionaryAsync}
        />
      )}
    </>
  );
};
