import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getSerialsApi = (customerPositionId) => http({
    url: ENDPOINTS.clientPortal.clientPortal.serials,
    method: 'get',
    params:{
        customerPositionId :customerPositionId
    }
}).then((response) => (response.data))
    .catch(err => {
        return Promise.reject(err);
    })

