import { Button } from "components/Button/Button";
import { Textbox } from "components/Textbox/Textbox";
import { GroupPosition } from "pages/OfficeAutomation/BaseInformation/GroupPositionPage/types";
import { useEffect, useState } from "react";
import { Response } from "types/types";
import { getGroupPosition, sendGroupPosition } from "../api/api";
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";
import { Loading } from "components/Loading/loading";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";



interface GroupPositionFormProp{
    handleCancelForm: (clickedCancelBtn: boolean) => void;
    idGroupPosition?:any;
    mode?:any;
    reload:()=> void;
}
export const GroupPositionForm =({handleCancelForm,idGroupPosition,mode,reload}:GroupPositionFormProp)=>{
  let DataForSend,method;
  const [resetForm, setResetForm] = useState(false);
  const [response, setResponse] = useState<any>();
  const [userChanges, setUserChanges] = useState({});
  const [loading, setLoading] = useState<boolean>(false);


  async function sendDataToServer(event) {
    event.preventDefault();
    event.target.reportValidity();
    if (mode != "create") {
      let data :GroupPosition= {
        ActivityStatus : +event.target.GroupPositionStatus.value ,
        SystemKey:event.target.systemKey.value,
        Title: event.target.Title.value,
        SystemReserved: response.SystemReserved,
      };
      DataForSend ={...data, Id: idGroupPosition.Id }; 
      method = "put";
    } else {
      let data :GroupPosition= {
        Title: event.target.Title.value,
        SystemKey: event.target.systemKey.value  
      };
      DataForSend = data;
      method = "post";
    }

    try {
      const res = await sendGroupPosition(DataForSend , method)
      setResetForm(true);
      sessionStorage.setItem("changingforms" , "false");
      setUserChanges({});
      handleCancelForm(true)
      reload()
    } catch (error) {
      
    }
  }

  async function getDataAsync() {
    setLoading(false);

    try {
      const res = await getGroupPosition(idGroupPosition.Id)
        setResponse(res.data.Data)
    } catch (error) {
      
    }
    setLoading(true);
  }

  
  function cancel() {
    if(sessionStorage.getItem("changingforms")+""=="true"){
        Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
            handleCancelForm(true)
        });
    }else{
      handleCancelForm(true)
    };


}

const handleBeforeUnload = (event) => {
  event.preventDefault();
  event.returnValue = '';
};


useEffect(() => {
  if(Object.values(userChanges).filter((item)=>item!=null).length>0){

      sessionStorage.setItem("changingforms" , "true")
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
  }else{
      sessionStorage.setItem("changingforms" , "false")
      window.removeEventListener('beforeunload', handleBeforeUnload);

  }
}, [userChanges]);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (mode != "create") {
      setUserChanges({});
      getDataAsync()
    }else{
    setLoading(true);
    }
  }, [idGroupPosition]);

    return(<>
     <div className="col-lg-12 mb-1">
        <div className="card ">
          <div className="card-body">
            {loading ? 
            <form
            className="row d-flex flex-row"
            onSubmit={sendDataToServer}
            >
              <div className="col-lg-4 ">
                <Textbox
                  Change={(e)=>{setUserChanges({...userChanges , ...e})}}  
                  resetForm={resetForm}
                  textboxType="text"
                  textboxName="Title"
                  labelText={"Title"}
                  maxLength={50}
                  defaultValue={response?.Title}
                  mode={mode}
                  required={true}
                  // defaultValue={personReal1 ? personReal1.Title : ""}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                  <Textbox
                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}  
                    defaultValue={response?.SystemKey}
                    required={true}
                    textboxName={"systemKey"} 
                    textboxType={"systemKey"} 
                    resetForm={resetForm} 
                    labelText={"systemKey"} 
                    mode={mode}  
                     />
              </div> 
              {mode != "create" &&          
              <div className="col-lg-4 ">
                <EnumerationTypeSelect
                value={response?.ActivityStatus} 
                name="GroupPositionStatus"
                enumTypeSelect="ActivityStatus"
                mode={mode} 
                label="Status" 
                />
              </div>                            
              }

              {mode != "view" ?  
              <div className="col-lg-12 mt-1 btns_form">
                <Button value="Save" btnType="submit" />
                <Button
                  btnType="cancel"
                  value="Cancel"
                  onClick={()=>{cancel()}}
                />
              </div>
              :<></>
              }

              {/* <div className="col-lg-12 mt-1"></div> */}
            </form >
            :
            <Loading />
            }
          </div>
        </div>
      </div>
    </>)
}