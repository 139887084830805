import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { ModalStepper } from "components/ModalStepper/ModalStepper";
import { TableTree } from "components/TableTree/TableTree";
import { useEffect, useState } from "react";
import { DynamicForm } from "types/types";
import { ENDPOINTS } from "utils/endpoints";
import { GetJobAndReference } from "./api";
import { CartableType } from "types/enum";
import { getDynamicForm } from "pages/OfficeAutomation/Cartable/components/ActionList/api/api";
interface JobAndReferencePropsType {

    referenceId: any,
    rowObject?: any
    inTab?: any
}

export const JobAndReference = ({
    referenceId,
    rowObject,
    inTab,

}: JobAndReferencePropsType) => {
    const [response , setResponse]  = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [showDynamicForm, setShowDynamicForm] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [ShowDynamicFormViewLoading, setShowDynamicFormViewLoading] = useState(false);
    const [dynamicForm, setDynamicForm] = useState<DynamicForm | any>("GeneralRequestForm");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [treeRowObject, setTreeRowObject] = useState<any>();

    async function JobAndReferenceasync(referenceId) {
    setLoading(false)
    try {
        const res =  await GetJobAndReference(referenceId )
        setResponse(res.Data)
    } catch (error) {
        setMessageType("error")
        setResponse([])
    }
    setLoading(true)
    }
    
    async function getDynamicFormAsync(id: string) {
    setShowDynamicFormViewLoading(false)
    try {
    const res = await getDynamicForm(id);
    if (res.Data)
        setDynamicForm(res.Data);
    else
        setDynamicForm(undefined);
    if (dynamicForm) {
        setShowDynamicForm(true);
    }
    else { setShowDynamicForm(false) }            
    } catch (error) {
        setMessageType("error")
    }
    setShowDynamicFormViewLoading(true)
    }

    useEffect( () => {
        if(((inTab&&inTab=="referenceandactionshistory")||!inTab) ){
                JobAndReferenceasync(referenceId)   
        }
    }, [referenceId ,inTab]);

    useEffect(() => {       
        if(showDynamicForm){
            getDynamicFormAsync(treeRowObject.Id);
        }
      
    }, [ showDynamicForm]);


    return (
        <>
        {((inTab&&inTab=="referenceandactionshistory")||!inTab)&&
        
        <div className="cartable-action" >
            <Message message={"Help_DoubleClickToViewProcessForm"} type={"instruction"} />
           {showDynamicForm&&
                <ModalForm mode={"view"} title={ "Actions"} onHide={() => { setShowDynamicForm(false); }} show={showDynamicForm}>                       
                    {ShowDynamicFormViewLoading ? 
                        <>
                        {showDynamicForm &&
                            (dynamicForm ? (
                                <ModalStepper
                                    inDynamicForms={true}
                                    jobId={rowObject.JobId}
                                    reload={setForceUpdate}
                                    onHide={() => { setShowDynamicForm(false); setForceUpdate(true);setShowDynamicForm(true); }}
                                    Id={treeRowObject?.Id}
                                    taskTitle={treeRowObject?.TaskTitle}
                                    formList={dynamicForm}
                                    parentMode={"view"}
                                    
                                    />
                            ) : (<Message message={"NoFormForPreview"} type={messageType} />))
                        }                
                        </>
                      :<Loading/>      
                    }
                </ModalForm>  
            }
        {
            loading ? (
                response?.length>0 ?
                 <TableTree
                     italic={"Reversed"}
                     params={"referenceId"}
                     Data={response}
                     theadData={Object.keys(response[0])}
                     DoubleClick={(e)=>{setTreeRowObject(e); setShowDynamicForm(true)}}
                     parentName={"JobAndReferencePersonnel"}
                     startIndex={5}
                     maxLevel={1}
                     defultShow={true}
                     Childrenparams={"ActionList"}  
                   /> 
                : <Message message={"NoDataForPreview"} type={messageType} />  ) 
            : <Loading />
        }
        </div>
        }
        
        </>
    );
}