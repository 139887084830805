import React, { useState } from "react";
import { DataGrid } from "components/DataGrid/DataGrid";
import { ModalForm } from "components/ModalForm/ModalForm";
import { trans } from "utils/helpers";
import { ViewSendForm } from "./ViewSendForm";


interface props {
    inTab: string
    positionObjectList: any

}

export const LetterSend: React.FC<props> = ({ inTab ,positionObjectList }) => {
    let taskTitle, TAS_UID, assignType, description: string
    let tableData: any[] = []
    let tableHeadData: string[] = []


    const [orderFormat, setOrderFormat] = useState<string>();
    let [selectedRow, setSelectedRow] = useState<any>({});
    const [showForm, setShowForm] = useState<boolean>(false);
    //const [positionObjectList, setpositionObjectList] = useState<any>([]);
    const [activateCheckbox, setActivateCheckbox] = useState<any>([]);
    const [checkedData, setCheckedData] = useState<any>({});
    const [isAllForms, setIsAllForms] = useState<boolean>(false);
    const [resetMultiSelect, setresetMultiSelect] = useState<boolean>(false);




    const checkedRowEventCallback = (checked, obj) => {
        if (!checked) {
            checkAllcheckboxes()
        } else {
            setIsAllForms(false)
        }
        checkAllcheckboxes()
    }

    const onCheckboxClicked = (obj) => {
        checkAllcheckboxes()
        setCheckedData(obj)
    }

    const onRowClicked = (obj, index, event) => {
        let originalObject = JSON.parse(JSON.stringify(obj)) 
        originalObject.DeadLineDate =  originalObject.DeadLineOriginalDate
        if (event?.target?.type != "checkbox") {
 
        } else {
            setresetMultiSelect(!resetMultiSelect)
            checkedRowEventCallback(false, obj)
            checkAllcheckboxes()
        }
    };

    const onRowDoubleClick = (obj) => {
            setSelectedRow(obj);
            setIsAllForms(false)
            setShowForm(true)
    };

    const getWithOrder = (order: string[]) => {
        setOrderFormat(order.join(","))
    }


    const checkAllcheckboxes = () => {
        let cheklist: any = []
        positionObjectList.map(obj => {
         if (obj.SendOperatorPositionId!=undefined && obj.SendOperatorPositionId.length >0 ) {
             cheklist.push({ Id: obj.ReceiverId })
         }
     })
        if (cheklist.length == positionObjectList.length) {
            setActivateCheckbox([{ Id: "All" }])
        } else {
            setActivateCheckbox(cheklist)
        }
    }

    if (inTab =="SendInformation" && positionObjectList && positionObjectList.length > 0) {
        tableHeadData = Object.keys(positionObjectList[0])
        tableData =positionObjectList
    }

    return (
        <>
        {inTab =="SendInformation"  &&
        <div className="card mb-0">
            <div className={`LetterSendwizard`}>
                <div className="content-card d-flex flex-column w-100">
                    <div className="content-card d-flex flex-column w-100">
                     <div className="mt-3 mb-3">
                            {tableData && tableData.length > 0 &&
                                <DataGrid
                                    orderFormat={getWithOrder}
                                    //parentName={"LetterSendTable" + taskTitle}
                                    parentName={"LetterSendTable"}
                                    theadData={tableHeadData}
                                    tbodyData={tableData}
                                    multiSelecttype={2}
                                    startIndex={5}
                                    minCellWidth={40}
                                    selectedRowObjectCallback={onRowClicked}
                                    DoubleClick={onRowDoubleClick}
                                    selectItem={onCheckboxClicked}
                                    selectedRowProp={activateCheckbox ? activateCheckbox : null}
                                    checkedRowEventCallback={checkedRowEventCallback}
                                    scrollHeight={"300px" }
                            />}
                        </div>
                    </div>
                </div>
            </div>
        {showForm && 
            <ModalForm modalHeight={65} modalProfileKey={"ViewSendForm"} title={trans("ViewSendForm") +" : " +(selectedRow.Receiver == undefined  ?trans("grouply") :selectedRow.Receiver) }onHide={() => setShowForm(false)} show={showForm}>
                <ViewSendForm
                    selectedRow={selectedRow}
                    onHide={()=>{setShowForm(false)}}
                />
            </ModalForm>
        }
        </div>
        }
         </>
    )
}