import { useEffect, useState } from "react";
import { Toolbar } from "components/Toolbar/Toolbar";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { deleteListDocsClassification, getListDocsClassification } from "./api";
import { Toast } from "components/ToastShow/ToastShow";
import { DocsClassificationForm } from "./DocsClassificationForm";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Message } from "components/Message/Message";
import { Loading } from "components/Loading/loading";

export const DocsClassification = () => {
  const [search, setSearch] = useState(null);
  const [response, setResponse] = useState<Response | any>(null);
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [mode, setMode] = useState("create");
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState<any>([]);
  const [catchMessage, setCatchMessage] = useState<any>({
    type: "info",
    msg: "NoDataForPreview",
  });
  const [resetForm, setResetForm] = useState(false);
  const [filterlist, setFilterlist] = useState<any>("");
  const [flag, setFlag] = useState(false);
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pages, setPages] = useState<number>(0);
  const [formLoading, setFormLoading] = useState(false);
  const [userChanges, setUserChanges] = useState({});

  const searchHandler = (str) => {
    if (str == "") {
      setSearch(null);
    } else {
      setSearch(str);
    }
  };

  const handleCreateAction = () => {
    setMode("create");
    setShowForm(true);
  };

  const handleEditRow = () => {
    setMode("edit");
    setShowForm(true);
  };

  const handleViewRow = () => {
    setMode("view");
    setShowForm(true);
  };

  const handleDeleteRow = async () => {
    try {
      await deleteListDocsClassification(selectedObj?.Id);
      setForceUpdate(!forceUpdate);
    } catch (error) {}
  };

  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "print":
        alert("print ");
        break;
      case "excel":
        downloadCSV(response?.Data, 1, trans("CustomerClassification"));
        break;
      case "refresh":
        setForceUpdate(!forceUpdate);
        break;
      case "create":
        handleCreateAction();
        break;
      case "view":
        if (selectedObj?.Id) handleViewRow();
        break;
      case "edit":
        if (selectedObj?.Id) handleEditRow();
        break;
      case "remove":
        if (selectedObj?.Id)
          Toast(
            trans("msg_deleteconfirm"),
            "warning",
            "alert",
            handleDeleteRow
          );
        break;
      case "help":
        alert("call api help");
        break;
    }
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setShowForm(false);
      });
    } else {
      setShowForm(false);
    }
  }

  const selectedIdCallback = (obj, index) => {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        setSelectedObj(obj);
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
      });
    } else {
      setSelectedObj(obj);
    }
  };

  async function getListDocsPaginationAsync(
    PageNum = pageNum,
    ApplicationId = JSON.parse(
      localStorage?.getItem("CurrentApplication") as string
    ).Id
  ) {
    setLoading(false);
    let pageSizeChangedByUser = getPageSize("DocsClassification");
    setPageSize(pageSizeChangedByUser);
    try {
      const res = await getListDocsClassification(
        search,
        orderBy.length == 0 ? null : orderBy.join(","),
        PageNum,
        pageSizeChangedByUser,
        ApplicationId
      );
      setResponse(res);
      setPages(
        res.TotalRecords > pageSizeChangedByUser
          ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser)
          : 1
      );

      setCatchMessage({ type: "info", msg: "NoDataForPreview" });
    } catch (error: any) {
      setCatchMessage({ type: "error", msg: error?.response?.data?.Message });
    }
    setLoading(true);
  }
  const handleNextPage = () => {
    if (pageNum < pages) {
      setPageNum(pageNum + 1);
      getListDocsPaginationAsync(pageNum + 1);
    }
  };
  const handlePrevPage = () => {
    if (pageNum > 1) {
      setPageNum((pageNum) => {
        return +pageNum - 1;
      });
      getListDocsPaginationAsync(pageNum - 1);
    }
  };

  const handleChangePageSize = (e: any) => {
    setPageNum(1);
    setPageSize(e.currentTarget.value);
    getPageSize("DocsClassification", e.currentTarget.value);
    getListDocsPaginationAsync(1);
  };

  function handleGoPage(value): void {
    if (value) {
      if (value < 1 || value > pages) {
      } else {
        setPageNum(+value);
        getListDocsPaginationAsync(+value);
      }
    } else {
    }
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  useEffect(() => {
    getListDocsPaginationAsync();
  }, [forceUpdate, flag, search, orderBy]);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (Object.values(userChanges).filter((item) => item != null).length > 0) {
      sessionStorage.setItem("changingforms", "true");
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    } else {
      sessionStorage.setItem("changingforms", "false");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [userChanges]);

  useEffect(() => {
    if (showForm && selectedObj && mode != "create") {
      getListDocsPaginationAsync();
    } else {
      setFormLoading(true);
    }
  }, [showForm, mode]);

  useEffect(() => {
    setResponse(null);
  }, [forceUpdate, search, filterlist, orderBy, flag, forceUpdate]);

  return (
    <div className="cartable-action">
      <Toolbar
        handleSearch={searchHandler}
        emptyList={response == null || response?.length == 0}
        id={selectedObj?.Id}
        remove={true}
        create={true}
        edit={true}
        search={true}
        handleClickToolbar={clickToolbarCallback}
      />
      <div
        className="card"
        style={{ overflowY: "auto",
           height: "74vh",
          scrollbarWidth: "thin", }}
      >
        <div className="">
          {showForm && (
            <DocsClassificationForm
              reload={() => {
                setForceUpdate(!forceUpdate);
              }}
              mode={mode}
              onHide={cancel}
              selectedObj={selectedObj}
            />
          )}

          {loading ? (
            response?.Data?.length > 0 ? (
              <>
                <DataGrid
                  parentName={"DocsClassification"}
                  orderFormat={setOrderBy}
                  orderFormatList={orderBy}
                  startIndex={1}
                  theadData={Object.keys(response.Data[0])}
                  tbodyData={response.Data}
                  minCellWidth={80}
                  rowSelected={selectedObj}
                  selectedRowObjectCallback={selectedIdCallback}
                  handleEdit={handleEditRow}
                  handleDelete={(e) => {
                    Toast(
                      trans("msg_deleteconfirm"),
                      "warning",
                      "alert",
                      handleDeleteRow
                    );
                  }}
                  handleView={handleViewRow}
                  totalRecords={response.TotalRecords}
                  pageNum={pageNum}
                  pageSize={pageSize}
                  HandleNextPage={handleNextPage}
                  HandlePrevPage={handlePrevPage}
                  handlechangePageSize={handleChangePageSize}
                  first={() => {
                    getListDocsPaginationAsync(pages);
                    setPageNum(pages);
                  }}
                  end={() => {
                    getListDocsPaginationAsync(1);
                    setPageNum(1);
                  }}
                  handleGoPage={handleGoPage}
                />
              </>
            ) : (
              <Message message={catchMessage.msg} type={catchMessage.type} />
            )
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};
