import { Button } from "components/Button/Button";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { Person } from "components/Person/Person";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { getPersonEmail, postPerson, postUsersGrouply } from "pages/SystemManagement/Settings/Users/api";
import { useEffect, useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { trans } from "utils/helpers";

export const UserForm = ({creatType, userId, hide, mode ,personId ,reload}) => {
    const [valid, setValid] = useState<any>(true);
    const [email, setEmail] = useState<any>();
    const [userChanges, setUserChanges] = useState({});



    async function findEmail(e) {
        try {
        if(e){
            const personEmail = await getPersonEmail(e);
            setEmail(personEmail.data.Data)
        }
            } catch (error) {
            setEmail(null)
        }
    }
    const sendDataAsync = async (userData, personId ,UserName) => {
        try {
        await postPerson(personId,userData);
        sessionStorage.setItem("changingforms" , "false");
        setUserChanges({});
        hide();
        reload();
        } catch (error2) {
        }
    }
    const createGrouplyAsync = async (id) => {
        try {
            await postUsersGrouply(id)
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
            hide();
            reload();
            
        } catch (error2) {     
        }
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                hide()
            });
        }else{
            hide()
        };


    }

    const formSubmit = (event) => {

        event.preventDefault()
        if(creatType==1){
            const userData = {
                UserName: event.target.UserName.value,
                Email: event.target.Email.value ? event.target.Email.value : event.target.UserName.value + "@gmail.com",  
            }
            sendDataAsync(userData, event.target.PersonId.value , event.target.UserName.value );
        }else{
            createGrouplyAsync(event.target.GroupPositionDictionary.value)
        }
    }
    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);
 

    useEffect(() => {
        if (mode != "create") {
            setUserChanges({});
        }
      }, [personId]);

    return (
        <div className="card p-3">
            <form onSubmit={formSubmit}>
                <div className="container-fluid">
                {(creatType==1 || mode !="create") ?
                    <>
                    <div className="row my-1">
                        <div className="col-12 col-sm-6 my-1">
                           <Person
                             name="PersonId"
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             value={personId && mode !="create" ? personId : null}
                             required={true}
                             mode={mode}
                             setPerson={findEmail}
                           />
                        </div>
                        <div className="col-12 col-sm-6 my-1">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                setValid={setValid}
                                // Change={setDataChange}
                                textboxType="userName"
                                textboxName="UserName"
                                labelText="UserName"
                                defaultValue={""}
                                required={true}
                                mode={mode}
                            />
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-12 col-sm-6 my-1 ">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                defaultValue={email}
                                mode={mode== "view" ? mode  : (email ? "view" : "create")}
                                setValid={setValid}
                                required={true}
                                // Change={setDataChange}
                                textboxType="email"
                                textboxName="Email"
                                labelText="Email"
                            />
                        </div>
                    </div>         
                    </>
                    :
                    <>
                    <div className="row my-1">
                        <div className="col-12 col-sm-6 my-1">
                        <DictionarySelectBox
                         Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                          value={ null}
                          required={true}
                          mode={mode}
                          endPoint={ENDPOINTS.BaseInformation.groupPosition.dictionary}
                          label="GroupPosition"
                          name={"GroupPositionDictionary"}
                        />
                        </div>
                    </div>
                    </>
            }
                    <div className="row my-1 d-flex justify-content-end">
                        {mode != "view" ?
                            <div className="col-4 d-flex justify-content-end">
                                <Button btnType="submit" value={creatType==1?'Save':"create"} />
                                <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                            </div>
                            :
                            <div className="col-4 d-flex justify-content-end">
                                <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                            </div>
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}

