import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { deleteBord, getBordAccess, getSharingCenterList, sendBordAccess } from "../api";
import { SharingCenterAccess } from "./components/SharingCenteresAccess";
import { SharingCenterForm } from "./components/SharingCenteresForm";
import './components/style.scss';

export const SharingCenters = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedObj, setSelectedObj] = useState<object | any>(null);
    const [mode, setMode] = useState<string | any>();
    const [forceUpdate, setForceUpdate] = useState(false);
    const [response, setResponse] = useState<Response | any>(null);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pages, setPages] = useState<any>(0)
    const [orderFormat, setOrderFormat] = useState<any>([]);
    const [filterlist, setFilterlist] = useState<any>("");
    const [search, setSearch] = useState(null);
    const [endDate, seteEndDate] = useState<any>(null);
    const [showAccess, setShowAccess] = useState<any>(false);
    const [accessDataList, setAccessDataList] = useState<any>();

 
    async function accessDatahandler(data) {
        try {
          const accessList = data.map(item=>{
            item.OwnerId = selectedObj.Id 
              return item
          })
          await sendBordAccess(accessList , selectedObj.Id); 
          setAccessDataList(null)
          setShowAccess(false)
        
      } catch (error) {
        
      }
    }
    async function getaccessListAsync() {
        try {
            const accessList = await getBordAccess(selectedObj.Id ); 
            setAccessDataList(accessList.Data)
            setShowAccess(true)
        } catch (error) {
          
        }
    
      }
    const selectedIdCallback = (obj) => {
                
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false")});
        }else{
            setSelectedObj(obj)         
        };
    }

    const handleShowForm = (e) => {
        if(e == true)
            setForceUpdate(!forceUpdate)
        setShowForm(false)
    }

    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getSharingCenterListAsync(pageNum + 1);
          }
    };

    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getSharingCenterListAsync(pageNum - 1);
          }
    };
    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(+e.currentTarget.value);
        getPageSize("BulletinSharingCenter",e.currentTarget.value)
        getSharingCenterListAsync(1)
    };

    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else { setPageNum(+value); getSharingCenterListAsync(+value); }
        } else {
        }
    };
    
    const getWithOrder = (order: string[]) => {
        setOrderFormat(order)
    }

    const searchHandler = (str) => {
        setPageNum(1);
        if (str == "") {
            setSearch(null)
        } else {
            setSearch(str)
        }
    }
    async function handleDeleteRow() {
        try{
           await deleteBord(selectedObj.Id);
           setForceUpdate(!forceUpdate)
        }catch(err:any){

        }
        }

        const handleViewRow = () => {
            setMode("view"); 
            setShowForm(true);
        }
        const handleEditRow =  () => {
            setMode("edit");
             setShowForm(true);
        }



    async function getSharingCenterListAsync(PageNum = pageNum) {
        setLoading(false);
        setResponse(null);
        let pageSizeChangedByUser = getPageSize("BulletinSharingCenter");
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getSharingCenterList(PageNum, pageSizeChangedByUser , orderFormat?.join(","), search, filterlist);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1)
            setResponse(res.Data);
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
         
        }
        setLoading(true)
    }

    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print"); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("BulletinSharingCenter"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": setMode("create"); setShowForm(true); break;
            case "view": if (selectedObj) handleViewRow(); break;
            case "edit": if (selectedObj) handleEditRow(); break;
            case "remove": if (selectedObj) Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "help": alert("call api help"); break;
            case "assignrule": if (selectedObj){if(!selectedObj.IsPublic){ getaccessListAsync()}else{ Toast("Msg_CanNotSetAccessForPublicSharingCenter" ,"warning" )}}; break;

        }
    };




    useEffect(() => {
        
        getSharingCenterListAsync();
    }, [forceUpdate, search, orderFormat, filterlist]);

    return (
        <>
            { showAccess &&
              <ModalForm mode={mode}  modalProfileKey={"Users"}   title={trans("Users")+" : " +selectedObj.Title } onHide={() => setShowAccess(false)} show={showAccess}>
                  <SharingCenterAccess
                    showForm={setShowAccess} 
                    accessDatahandler={accessDatahandler} 
                    accessData={accessDataList}
                    mode={mode}
                  />
              </ModalForm>
            }

            <Toolbar assignRule={true}  emptyList={response?.TotalRecords == 0}   handleSearch={searchHandler} id={selectedObj} view={true} create={true} remove={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
            <div >
                <div className="row">
                    <div className="col-12">
                        {showForm &&
                        <ModalForm modalHeight={85} mode={mode} title={"sharingcenterarticle"} onHide={() => setShowForm(false)} show={showForm}>
                        <div className="">
                            <SharingCenterForm   showForm={handleShowForm} selectedObj={selectedObj} mode={mode} />
                        </div>
                        </ModalForm>
}                    </div>
                </div>
                <div className="card" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                    <div className="p-0">
                        {loading ?
                            response?.Data?.length > 0 ?
                                <DataGrid
                                    handlefiltering={(str) => {
                                        setFilterlist(str);
                                    }}
                                    filterStr={filterlist}
                                    orderFormat={getWithOrder}
                                    orderFormatList={orderFormat}
                                    parentName={"BulletinSharingCenter"}
                                    startIndex={1}
                                    theadData={Object.keys(response.Data[0])}
                                    tbodyData={response.Data}
                                    minCellWidth={80}
                                    selectedRowObjectCallback={selectedIdCallback}
                                    totalRecords={response.TotalRecords}
                                    pageNum={pageNum}
                                    pageSize={pageSize}
                                    handleEdit={handleEditRow}
                                    handleView={handleViewRow}
                                    handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow)}}
                                    HandleNextPage={handleNextPage}
                                    HandlePrevPage={handlePrevPage}
                                    handlechangePageSize={handleChangePageSize}
                                    first={() => { getSharingCenterListAsync(pages); setPageNum(pages) }}
                                    end={() => { getSharingCenterListAsync(1); setPageNum(1) }}
                                    handleGoPage={handleGoPage}
                                /> 
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                            :
                            <Loading />}
                    </div>
                </div>

            </div>
        </>

    )
}