import { SelectBox } from "components/Select/Select";
import { InputHTMLAttributes, useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { InputLoad } from "components/InputLoading/inputLoading";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { ICON_TYPE } from "utils/constants";
import { getgroupPositionDictionarys } from "../../api/api";
interface groupPositionDictionaryProp extends InputHTMLAttributes<HTMLInputElement> {

  label?: string;
  name: string;
  value?: any;
  mode?: any;
  required?: boolean;
  selectedList?: (e) => void,
}
export const GroupPositionDictionary = ({label, name, value, mode ,required,selectedList }: groupPositionDictionaryProp) => {
  const [optionsgroupPositionDictionary, setOptionsgroupPositionDictionary] = useState<any>();
  const [errcode, setErrcode] = useState<any>();
  const [app, setApp] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [selectedItem, setSelectedItem] = useState<any>([]);
  const layoutContext = useContext(LayoutContext);


  const groupPositionDictionaryApiAsync = async ()=>{
    setLoading(false)
    try {
      const res = await getgroupPositionDictionarys()
       setOptionsgroupPositionDictionary(res.Data)
    } catch (error:any) {
      setOptionsgroupPositionDictionary(null)
      setErrcode(error.response.status)

    }
    setLoading(true)

  }
  function addOption(e) {
    e.stopPropagation()
    e.preventDefault()
    let Name 

    if(app){
     let groupPositionId = app.target.value
     let flag = false
     selectedItem.forEach(item => {
         if(item.Id ==groupPositionId ){
             flag =true
            }
     });
     if(!flag){
        let data:any 
        Object.keys(optionsgroupPositionDictionary).map(item=>{
            if(item ==groupPositionId ){
                setSelectedItem([...selectedItem,{Id:groupPositionId ,Description:optionsgroupPositionDictionary[groupPositionId] }])
                selectedList&&  selectedList([...selectedItem,{Id:groupPositionId ,Description:optionsgroupPositionDictionary[groupPositionId] }])
            }
        })
        
     }

    }

  }

  function deleteaOption(e) {
    let newSelectedList:any =[] 
    selectedItem.forEach(item=>{
        if(item.Id !=e ){
          newSelectedList.push(item) 
        }
    })
    
     setSelectedItem(newSelectedList)
     selectedList&&  selectedList(newSelectedList)
  }





  useEffect(() => {
  groupPositionDictionaryApiAsync()

   
  }, [layoutContext.currentUserPosition.PositionId ]);


  

  return (
    <>
      {loading ?
      <>
      {optionsgroupPositionDictionary !=null ?

            <>
            <form style={{display:"flex" , alignItems:'center' ,flexDirection:"row",width:"100%"}}  >
              <div style={{width:"60%"}} >
              <SelectBox  
               onChange={setApp} 
                required={required}
                mode={Object.values(optionsgroupPositionDictionary).length >1 ? mode : "view"}
                lable={label}
                selectType="select"
                value={value}
                options={Object.values(optionsgroupPositionDictionary)}
                val={Object.keys(optionsgroupPositionDictionary)}
                name={name}
              ></SelectBox>                  
              </div>
            {(optionsgroupPositionDictionary && Object.values(optionsgroupPositionDictionary).length >1) &&
              <div  style={{marginTop:"20px" , width:"10%"}}>
             <Button Icon={true}  value={<button onClick={addOption} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "check"]}   size="2x"  /></button>} btnType="submit"  />
              </div>
            
            }
             </form>
              <div className="group-person__selected-options-wrapper">
                
              { selectedItem.map((item, index) => (
            <div className="group-person__selected-option form-control-custom" key={item.Id + index}>
                <div style={{overflow: "hidden"}}>
                 {trans(item.Description) } 
                </div>
                <div style={{left: "3px"}} className="group-person__delete-selected-option" data-key={item.Id} onClick={()=>{deleteaOption(item.Id)}}>
                    <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                </div>
            </div>
             ))}
           </div>

            </>
            :<></>


      }
      
      </>
        :<InputLoad  msg={errcode}  required={required}  labelText={label}  onClick={groupPositionDictionaryApiAsync}/> 
      }

    </>
  );
};
