import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getAllItemProperties = (pageNum, pageSize , searchString, orderBy ,filterlist) => http({
    baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemOtherProperty.browse,
   method: "get",
   params: {
       searchString: searchString,
       OrderByString: orderBy,
       FilterString:filterlist,
       PageSize: pageSize,
       PageNumber: pageNum,
   }
}).then((res) => {
   return res.data
}).catch((err) => {
   return Promise.reject(err.response  );
});

export const postProperty = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemOtherProperty.browse,
   method: 'post',
   data: data
}).then((res) => {
   return res.data
}).catch((err) => {
   return Promise.reject(err.response  );
});
export const removeItemProperties = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemItemOtherProperty.browse,
    method: 'delete',
   data: data
}).then((res) => {
   return res.data
}).catch((err) => {
   return Promise.reject(err.response  );
});
