
import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { trans } from "utils/helpers";
import { http } from "utils/http";





export const postHourlyLeaveData = (data, processId) => http({
    method: "post",
    url: ENDPOINTS.cartable.HourlyLeave.browse,
    data: data,
    params:{
        PRJ_UID : processId
    }
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});
export const putHourlyLeaveData = (data ,referenceId) => http({
    method: "put",
    url: ENDPOINTS.cartable.HourlyLeave.browse,
    data: data,
    params:{
        referenceId:referenceId
    }
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});


export const getHourlyLeaveData = ( Id) => http({
    url: ENDPOINTS.cartable.HourlyLeave.load,
    method: "get",
    headers: { "Accept-Language": "en" },
    params:{
        Id:Id
    }
    
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});
export const getHourlyLeaveDataByReferenceId = ( Id) => http({
    url: ENDPOINTS.cartable.HourlyLeave.referenceId,
    method: "get",
    headers: { "Accept-Language": "en" },
    params:{
        referenceId:Id
    }
    
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});


export const getDataForPrint = ( Id) => http({
    url: ENDPOINTS.cartable.HourlyLeave.PrintData,
    method: "get",
    params:{
        JobId:Id,
        useWarmSignature: true 
    }
    
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});
