import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getBpmnTaskUserApi = (async (search, id ,bpmnTaskId) => await http({

    url:   ENDPOINTS.bpmnApi.TaskUser.dictionary,
    method: 'get',
    params: {
        SearchString: search,
        TaskId: bpmnTaskId,
        
        Id: id
    }
})
    .then((respons) => (respons.data))
)
