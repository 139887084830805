import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { DataGrid } from "components/DataGrid/DataGrid";
import DatePicker from "components/DatePicker/DatePicker";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, trans } from "utils/helpers";
import { DeleteMeetingApproval, FinishMeetingAndStartApprovals, getMeetingApprovals, getMeetingAttendees, sendMeetingForSign } from "./api";
import { MeetingApprovalForm } from "./MeetingApprovalForm";

interface MeetingApprovalListProps {
    sourceId: string,
    mode: string,
    step: number,
    referenceId?: number,
    handleCancelForm:()=>void;
    onHide?: () => void;
    closeForm?: () => void;
}

export const MeetingApprovalList = ({sourceId ,handleCancelForm,closeForm, onHide ,step ,mode ,referenceId}: MeetingApprovalListProps) => {

    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<Response | any>(null);
    const [responseAttendees, setResponseAttendees] = useState<Response | any>(null);
    const [selectedId, setSelectedId] = useState<string | any>(null);
    const [showMeetingApprovalForm, setShowMeetingApprovalForm] = useState(false);
    const [LocalMode, setLocalMode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [filterlist, setFilterlist] = useState<any>("");
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showSendForSign, setShowSendForSign] = useState(false);
    const [fromDate, setFromDate] = useState<string>();


    const handleCreateMeetingApproval = () => {
        setLocalMode("create")
        setShowMeetingApprovalForm(true);
    }
    const handleEditRow = () => {
        setLocalMode("edit")
        setShowMeetingApprovalForm(true);
    }
    const handleDeleteRow =async (id: string) => {
        try {
            await DeleteMeetingApproval(id);
            getReferenceMeetingApprovalsAsync()
        } catch (error) {

        }
    }
    const SendMeetingForSignAsync =async (e) => {
        e.preventDefault()
        try {
        let data = {
            "ReferenceId": referenceId,
            "Id": sourceId,
            "DeadLineDate": fromDate,
            "SendMessage": e.target.SendMessage.checked
        }
        await sendMeetingForSign(data)
        handleCancelForm&& handleCancelForm()
        onHide&& onHide()
        closeForm&&closeForm()
        setShowSendForSign(false)
        } catch (error) {

        }
    }
    const handleViewRow = () => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alert", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setLocalMode("view")
                setShowMeetingApprovalForm(true);
            });
        }else{
            setLocalMode("view")
            setShowMeetingApprovalForm(true);
        };
    }
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel": downloadCSV(response?.Data?.Data ,1 ,trans("MeetingApprovals")); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateMeetingApproval(); break;
            case "view": if (selectedId) handleViewRow(); break;
            case "edit": if (selectedId) handleEditRow(); break;
            case "help": alert("call api help"); break;
            case "remove": if (selectedId) {handleDeleteRow(selectedId) } else { Toast(trans("Msg_SelectRow"), "warning") } break;
        }
    };
    async function getReferenceMeetingApprovalsAsync() {
        setLoading(false)
        try {
            const res = await getMeetingApprovals(sourceId);     
            setResponse(res);
            if(step>1){
                const res = await getMeetingAttendees(sourceId);     
                setResponseAttendees(res);   
            }
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }
    async function FinishMeetingAndStartApprovalsAsync() {
        try {
            const res = await FinishMeetingAndStartApprovals(sourceId);     
            closeForm &&closeForm()
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
   
    }
    useEffect(() => {
       getReferenceMeetingApprovalsAsync()

    }, [forceUpdate,orderBy ,filterlist,search ]);
  
    return (
        <>
            {showSendForSign &&
                <ModalForm
                mode={LocalMode}
                onHide={() => setShowSendForSign(false)}
                show={showSendForSign}
                title="SendForSign"
                > 
                <form className="card" onSubmit={SendMeetingForSignAsync}>
                    <div className="row">
                        <div className="col-4 my-1 my-lg-0 col-lg-3">
                            <DatePicker
                                onOpenPickNewDate={false}
                                lable='DeadLineDate'
                                type='date'
                                setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                                val={null}
                            />
                        </div>
                        <div className="col-4 my-1 my-lg-0 col-lg-4 pt-2">
                             <CheckableButton
                                defaultValue={false}
                                labelText={"SendMessage"}
                                inputName={"SendMessage"}
                                type="checkbox"
                                mode={"edit"}
                            />    
                         </div>
                    </div>
                    <div className="col-lg-12 mt-1 btns_form">
                        <Button value="saveandreference" btnType="submit" />
                        <Button btnType="cancel" value="Cancel" onClick={()=>{setShowSendForSign(false)}} />
                    </div>
               </form>

                </ModalForm>
            }


            <div className="cartable-MeetingApproval">
                {((!(step >1))&& mode!="view")&&
                <div className="row">
                    <div className="col mt-4">
                        <Button
                         btnType="submit"
                         value="saveandreference"
                         onClick={()=>{setShowSendForSign(true)}}
                         />                    
                    </div>
                    <div className="col-8">
                      <Toolbar   id={selectedId}   emptyList={response?.Data.length ==0||response?.Data==null} create={true} edit={true} search={false} handleClickToolbar={clickToolbarCallback}  />
                    </div>
                </div>
                }
                {(((step >1))&& mode=="view")&&
                <div className="row">
                    <div className="col mt-4">
                        <Button
                         btnType="submit"
                         value="FinishMeetingAndStartApprovals"
                         onClick={()=>{FinishMeetingAndStartApprovalsAsync()}}
                         />                    
                    </div>

                </div>
                }
                {showMeetingApprovalForm &&
                    <ModalForm
                    mode={LocalMode}
                    onHide={() => setShowMeetingApprovalForm(false)}
                    show={showMeetingApprovalForm}
                    title="MeetingApprovalForm"
                    > 
                    <MeetingApprovalForm
                        onHide={() => setShowMeetingApprovalForm(false)}
                        mode={LocalMode}
                        reset={(e) => { getReferenceMeetingApprovalsAsync(); } }
                        ApprovalId={selectedId}
                        MeetingId={sourceId}
                    />
                    </ModalForm>
                }
                {loading ?
                    <>
                    {step >1&&
                    <>
                    {((responseAttendees?.Data?.length > 0 ) ?
                        <div className="card ">
                            <div className="p-0">
                                <>
                                    <DataGrid
                                        parentName={"MeetingApprovals"}
                                        theadData={Object.keys(responseAttendees.Data[0])}
                                        tbodyData={responseAttendees.Data}
                                        startIndex={3}
                                        minCellWidth={80}
                                        selectedRowObjectCallback={(e)=>{}}

                                    />
                                </>
                            </div>
                        </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    )}
                    </>}
                    {(response?.Data.length > 0 ?
                        <div className="card mt-3">
                            <div className="p-0">
                                <>
                                    <DataGrid
                                        disabled={mode =="view" }
                                        handlefiltering={(str)=>{setFilterlist(str)  }}
                                        filterStr={filterlist}
                                        orderFormat={setOrderBy}
                                        orderFormatList={orderBy}
                                        parentName={"MeetingApprovals"}
                                        theadData={Object.keys(response.Data[0])}
                                        tbodyData={response.Data}
                                        startIndex={2}
                                        minCellWidth={80}

                                        handleEdit={mode!="view" ? handleEditRow:null}
                                        handleDelete={mode!="view" ?handleDeleteRow:null}
                                        handleView={mode!="view" ?handleViewRow:null}

                                    />
                                </>
                            </div>
                        </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    )}

                    
                    </>
                    : <Loading />
                }

            </div>
        </>

    );
}