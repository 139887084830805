import { CustomerSalesOrder } from "pages/ClientPortal/ECommerce/components/Cart/components/CustomerSalesOrder";
import { useEffect } from "react";
import { SalesOrderForm } from "../SalesOrder/SalesOrderForm";

interface OrderFormProps {
  onHide?: () => void;
  relod: () => void;
  jobId?: any;
  processId?: any;
  referenceId?: any;
  mode: any;
  step?: any;
  taskId?:any;
  Id?:any;
  rowObject?: any;
  handleShowReferenceStepper?: (referenceId: string) => void;
}



export const OrderForm = ({
  mode,
  relod,
  onHide,
  rowObject,
  handleShowReferenceStepper,
  step,
  taskId,
  jobId,
  Id,
  processId,
  referenceId,
}: OrderFormProps) => {


  useEffect(() => {

    
  }, []);

  return (
    <div className=" ">
        {window.location.href.includes("cartable")?
        
        <SalesOrderForm 
          mode={mode}
          relod={relod}
          onHide={onHide}
          rowObject={rowObject}
          handleShowReferenceStepper={handleShowReferenceStepper}
          step={step}
          taskId={taskId}
          jobId={jobId}
          Id={Id}
          processId={processId}
          referenceId={referenceId}
         />
    
    :
    <CustomerSalesOrder 
        mode={mode}
        onHide={() => { onHide && onHide(); } }
        processId={processId}
        basketData={undefined}   
        jobId={jobId}

     />

    }

    </div>
  );
};
