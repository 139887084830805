import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext"
import { useContext, useEffect, useRef, useState } from "react"
import { ICON_TYPE } from "utils/constants"
import { trans } from "utils/helpers"
import { getProcessStarting } from "./api"
import "./style.scss"
interface ButtonToolbarProcessStartingProps {
    onClick?: () => void,
    Callback: (ans: any) => void,
    positionId?: string,
}
export const ButtonToolbarProcessStarting: React.FC<ButtonToolbarProcessStartingProps> = ({ onClick, Callback, positionId }) => {
    const [posId, setPosId] = useState(positionId);
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    const buttonToolbarProcessStarting = useRef(null);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const layoutContext = useContext(LayoutContext);
    const [options, setOptions] = useState<any>()
    async function getDataAsync() {
        try {
           const response = await getProcessStarting(layoutContext.currentApplication.Id , layoutContext.currentUserPosition.PositionId);
            setOptions(response.Data);
        } catch (error) {
            setOptions(null)
        }

    }
    useEffect(() => {
        getDataAsync();

    }, [layoutContext.currentUserPosition , layoutContext.currentApplication]);
    function showSubMenuFunction() {
        setShowSubMenu(true)
    }
    function hideSubMenuFunction() {
        setShowSubMenu(false)
    }
    function selectboxValSetter(event) {
        Callback && Callback(event.currentTarget.getAttribute('data-value'));
        setShowSubMenu(false)
        if (buttonToolbarProcessStarting.current) (buttonToolbarProcessStarting.current as HTMLButtonElement).blur();
    }
    return (
        <> {options && options?.length > 0 && <div className="toolbar-btn-wrapper">
            <button ref={buttonToolbarProcessStarting} onClick={() => { }} onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction} className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
                <div className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>F4</div>
                <FontAwesomeIcon icon={[ICON_TYPE, "plus"]} width="20px" height="20px" />
                <small>{trans("Create")}</small>
                <div className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`}>
                    {options && options.map((option, index) => (
                        <div key={index} className="toolbar-btn-sub-menu__option" data-value={option.Id} onClick={selectboxValSetter}>{option.Description}</div>
                    ))}
                </div>
            </button>
        </div>
        }
        </>
    )
}