import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { Help } from "pages/SettingPage/Help/Help";
import { ModalForm } from "components/ModalForm/ModalForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toast } from "components/ToastShow/ToastShow";
import { WidgetForm } from "./components/WidgetForm";
import { ICON_TYPE } from "utils/constants";
import './style.scss'
import { NotifSettingForm } from "./components/NotifSettingForm";
import { SharingCenterArticleUser } from "./components/SharingCenterArticleUser/SharingCenterArticleUser";


export const SettingMenu = ({ setShowMenu, showMenu }) => {
    const [help, setHelp] = useState(false);
    const [showwidgetform, setShowWidgetForm] = useState(false);
    const [shownotifSettingForm, setShowNotifSettingForm] = useState(false);
    const [showSharingCenter, setShowSharingCenter] = useState(false);
    const [currentModalWidth, setCurrentModalWidth] = useState(70);
    const [activeIndex,setActiveIndex] = useState<any>()
    const activeIndexRef = useRef<any>(5)
    const showHelpRefRef = useRef<any>(false)
    const heigth_header = useRef<any>()

    function showhelp() {
        setHelp(true);
        showHelpRefRef.current = true
    }

    function showWidgetForm() {
        setShowWidgetForm(true);
        setShowMenu(false)
    }
    function showSharingCenterModal() {
        setShowSharingCenter(true);
        setShowMenu(false)
    }
    function showNotifSettingForm() {
        setShowNotifSettingForm(true);
        setShowMenu(false)
    }
 function refreshProfileSetting() {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("token_expire");
    window.location.href ="/"
    }
    
 function refreshformsSize() {
    localStorage.removeItem("modalsSize");
    localStorage.removeItem("userHeadTable");
    Toast("Msg_OperationIsDone" , "info")
}



    const getModalWidth = (modalWidth) => {
        setCurrentModalWidth(modalWidth)
    }

    const arrowInMenu = (event)=>{
        event.stopPropagation()
        if(event.key == "ArrowDown"){
          if(activeIndexRef.current > 0){
            activeIndexRef.current = activeIndexRef.current - 1;
          }else{
            activeIndexRef.current = 5;
          }
        }
        if(event.key == "ArrowUp"){
          if(activeIndexRef.current +1 > 5){
            activeIndexRef.current = 0;
          }else{
            activeIndexRef.current = activeIndexRef.current + 1;
          }
        }
        setActiveIndex(activeIndexRef.current)
        if(event.key == "Enter"){
            if(activeIndexRef.current == 5) showhelp();
            if(activeIndexRef.current == 3) refreshProfileSetting();
            if(activeIndexRef.current == 2) refreshformsSize();
            if(activeIndexRef.current == 1) setShowWidgetForm(true);
            if(activeIndexRef.current == 0) setShowNotifSettingForm(true);
            
        }
      }

    useEffect(()=>{

        if(showMenu){
            document.addEventListener("keydown",arrowInMenu,true)
        }
        if(heigth_header.current){
            heigth_header.current.style.top = '45px'
        }
        
        return () =>{document.removeEventListener("keydown",arrowInMenu,true)}
      },[showMenu])
    
      useEffect(()=>{
            setShowMenu(false)
   },[help])

    return (
        <>
            {showMenu &&
                <div id="settingMenu">
                    <section className='glassBox Setting_menu' ref={heigth_header}>
                        <ul className="Setting_menu__list" id="SettingMenuList">
                            <li id={"Setting_menu__item"+5} className={`Setting_menu__item ${activeIndexRef.current == 5?"active":""}`} onClick={showhelp}> <FontAwesomeIcon icon={[ICON_TYPE, "circle"]} className="SettingCircle" /> {trans("help")} </li>
                            <li className={`Setting_menu__item showDots ${activeIndexRef.current == 3?"active":""}`} onClick={refreshProfileSetting}> <FontAwesomeIcon icon={[ICON_TYPE, "circle"]} className="SettingCircle" /> {trans("Reset")} </li>
                            <li className={`Setting_menu__item showDots ${activeIndexRef.current == 2?"active":""}`} onClick={refreshformsSize}> <FontAwesomeIcon icon={[ICON_TYPE, "circle"]} className="SettingCircle" /> {trans("ResetCustomSettings")} </li>
                            <li className={`Setting_menu__item showDots ${activeIndexRef.current == 1?"active":""}`} onClick={showWidgetForm}> <FontAwesomeIcon icon={[ICON_TYPE, "circle"]} className="SettingCircle" /> {trans("WidgetSettings")} </li>
                            <li className={`Setting_menu__item showDots ${activeIndexRef.current == 0?"active":""}`} onClick={showNotifSettingForm}> <FontAwesomeIcon icon={[ICON_TYPE, "circle"]} className="SettingCircle" /> {trans("NotificationSettings")}</li>
                            <li className={`Setting_menu__item showDots ${activeIndexRef.current == 6?"active":""}`} onClick={showSharingCenterModal}> <FontAwesomeIcon icon={[ICON_TYPE, "circle"]} className="SettingCircle" />  {trans("sharingcenterarticle")} </li>
                        </ul>
                    </section>
                </div>}
            <>
                {(help) &&
                    <ModalForm title={"help"} onHide={() => setHelp(false)} show={help} getModalWidth={getModalWidth}>
                        <Help modalWidth={currentModalWidth} />
                    </ModalForm>
                }
                {showwidgetform &&
                    <ModalForm title={"WidgetForm"} onHide={() => setShowWidgetForm(false)} show={showwidgetform}>
                        <WidgetForm setShowWidgetForm={setShowWidgetForm} />
                    </ModalForm>
                }
                {showSharingCenter &&
                    <ModalForm title={"sharingcenterarticle"} onHide={() => setShowSharingCenter(false)} show={showSharingCenter}>
                       <SharingCenterArticleUser/>
                    </ModalForm>
                }
                {shownotifSettingForm &&
                    <ModalForm title={"WidgetForm"} onHide={() => setShowNotifSettingForm(false)} show={shownotifSettingForm}>
                        <NotifSettingForm setShowNotifSettingForm={setShowNotifSettingForm} />
                    </ModalForm>
                }



            </>
        </>

    )
}