
import Datepicker from "components/DatePicker/DatePicker";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { TabBar } from "components/Tabbar/Tabbar";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useRef, useState } from "react";
import { InvitPeopleForm } from "../InvitedPeopleForm/InvitPeopleForm";
import { getInvitedPersonType } from "./api";



export const Invitaion = ({intab="",  InvitaionFormSubmit,acceptExpireTime ,defaultPeopleList ,mode,formRef ,InvitaionData}) => {


    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState<string | any>([]);
    const [curentTab, setCurentTab] = useState<any>("main")
    const [defaultTab, setDefaultTab] = useState<any>("main")
    const [response, setResponse] = useState<any>()
    const [error, setError] = useState<any>()
    const [usedListId, setUsedListId] = useState<any>([])
    const [invitedPeople, setInvitedPeople] = useState<any>({1:[],2:[],3:[]})
    const invitedPeopleListRef = useRef<any>({1:[],2:[],3:[]});
    const acceptExpireDateRef = useRef<any>(null);

    function  onSubmit(e){
        e.preventDefault()
        let data = {
            "AcceptExpireTime":acceptExpireDateRef.current, 
            InvitationText:e.target.InvitationText.value,
            InvitedPeople:[...invitedPeopleListRef.current[1],...invitedPeopleListRef.current[2],...invitedPeopleListRef.current[3]]
        }
       InvitaionFormSubmit(data)
    }


    async function getInvitedPersonTypeAsync() {
        setLoading(false)
               try {
            const res = await getInvitedPersonType()
            setResponse(res.Data)
            setDefaultTab(Object.values(res.Data)[0])
            setCurentTab(Object.values(res.Data)[0])
            let tabs = Object.keys(res.Data).map(item=>{
                return { title: res.Data[item], body: (<InvitPeopleForm defaultPeopleList={res.Data[item]? defaultPeopleList[res.Data[item]] : []} InvitedPersonType={+item} inTab={curentTab ==res.Data[item] } usedListId={usedListId} InvitedListhandler={(e)=>{InvitedListhandler(e,+item)}}showForm={()=>{}} mode={"create"}     />), }
            })
            setContent([
                ...tabs
            ])
           } catch (error:any) {
            setError(error.response.data.Message)
            
           }
           setLoading(true)
        }
        const tabChangeHandle = (tab) => {
            setCurentTab(tab)
        }
    
        function InvitedListhandler(list , type){
            invitedPeopleListRef.current ={...invitedPeople ,[type]:list}
            setInvitedPeople({...invitedPeople, [type]:list})
            let listUsed = usedListId
            list.map(item=>{
                if(!listUsed.includes(item.PositionId)){
                    listUsed.push(item.PositionId)
                }
            })
            setUsedListId(listUsed)
        }

    useEffect(() => {
        if(response){
            let tabs = Object.keys(response).map(item=>{
                return { title: response[item], body: (<InvitPeopleForm defaultPeopleList={response[item]? defaultPeopleList[response[item]] : []} InvitedPersonType={+item} inTab={curentTab ==response[item] } usedListId={usedListId} InvitedListhandler={(e)=>{InvitedListhandler(e,+item)}}showForm={()=>{}} mode={"create"}     />), }
            })
            setContent(tabs)
        }
    }, [curentTab ,defaultPeopleList]);

    useEffect(() => {
        setError(null)
        getInvitedPersonTypeAsync();
    }, []);


    return (
        <>
        {loading ?
            <>
            {error ?
             <Message message={error} type={"error"} />
             : 
             <>
             {intab=="invitation"&&

             <form id="invitation" name="invitation" ref={formRef} onSubmit={onSubmit} className="cartable-action">
              <div className="">
        <div className="row">
            <div className="col-lg-12 ">
             <Textbox
              textboxType="textarea"
              textboxName="InvitationText"
              minHeight={3}
              labelText="InvitationText"
              defaultValue={InvitaionData?.InvitationText}
              mode={"edit"}
              />
            </div>
        </div>
        <div className="row">
            <div className="col-lg-3 ">
            <Datepicker
               mode={"edit"}
               lable="AcceptExpireTime"
               withTime={true}
               onOpenPickNewDate={true}
               setIsoTime={(isoTime: string) => {acceptExpireDateRef.current = isoTime ; }}
               val={InvitaionData?.AcceptExpireTime ? InvitaionData?.AcceptExpireTime  : acceptExpireTime} //resetForm ? new Date().toISOString() : 
               required={true}
               />                          
            </div>
   </div>
 
           </div>
             {/* <div className="col-lg-12 mt-1 btns_form btnsTabBarFixToTop">
                <Button value={mode=="edit"? "save" : "reference"} btnType="submit" form="InvitaionForm" onClick={formSubmitHandlar} />
                <Button btnType="cancel" value="Cancel" onClick={()=>{}} />
            </div> */}
            
                <TabBar tabChange={tabChangeHandle} content={content} curentTab={curentTab} defaultTab={defaultTab} />
                <button  type="submit"  className="d-none" id="hidebutton" name="hidebutton" >
                </button>
            </form>
             }

        </>
        }
            </>
            :<Loading/>
        }
        </>

    );
}