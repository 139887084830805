import { ENDPOINTS } from "utils/endpoints"
import { http } from "utils/http"





export const sendNode = (data: any , method) => http({
    url: ENDPOINTS.archive.personalArchiveDetail.browse,
    method: method,
    data: data
}).then((res) => {
    return res.data
}).catch((err) => {
});




export const getFirstNode = (Id: any) => http({
    url: ENDPOINTS.archive.personalArchive.browse,
    method: "get",
    params: {
        Id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    // return Promise.reject(err);
});

export const getSubNode = (Id: any) => http({
    url: ENDPOINTS.archive.personalArchiveDetail.load,
    method: "get",
    params: {
        Id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    // return Promise.reject(err);
});
