import { DataGrid } from "components/DataGrid/DataGrid";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";

import React, { useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";

import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { getReceivers, getSentHistory, postLetterSends } from "./api";
import { LetterSendForm } from "./LetterSendForm";


interface props {

    Id: string
    jobId: string


    onHide: () => void,
    reload?: (e) => void,

}

export const LetterSendContent: React.FC<props> = ({ jobId, Id, onHide, reload }) => {
    let taskTitle, TAS_UID, assignType, description: string
    let tableData: any[] = []
    let tableHeadData: string[] = []


    const [orderFormat, setOrderFormat] = useState<string>();
    let [selectedRow, setSelectedRow] = useState<any>({});
    const [showForm, setShowForm] = useState<boolean>(false);
    //const [positionObjectList, setpositionObjectList] = useState<any>([]);
    const [activateCheckbox, setActivateCheckbox] = useState<any>([]);
    const [checkedData, setCheckedData] = useState<any>({});
    const [positionObjectList, setPositionObjectList] = useState<any>([]);
    const [isAllForms, setIsAllForms] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [resetMultiSelect, setresetMultiSelect] = useState<boolean>(false);
    const layoutContext = useContext(LayoutContext);



  // if (taskList[page]) {
  //     taskTitle = taskList[page].TaskTitle
  //     assignType = taskList[page].AssignType
  //     tableData = taskList[page].Positions
  //     //TAS_UID = taskList[page].TaskId
  // }

    const handleSubmission = async (event) => {
        event.preventDefault()
        //remove all empty positionObjectList
        if (!positionObjectList) {   // add more rules later for form completion
            Toast(trans('pleaseFillTheForms'), 'warning')
        } else {
            let newList = positionObjectList.map(item=>{
                if(item.Id.length >0){
                    return {
                        "Id"  : item.Id,
                        "JobId": jobId,
                        "LetterReceiverId": item.ReceiverId,
                        "SendOperatorPositionId":item.SendOperatorPositionId,
                        "SendMethodId": item.SendMethodId,
                        "IsSent": item.IsSent,
                        "SentTime":item.SentTime,
                        "SentDescription": item.SentDescription,
                        "ReceiverPerson":item.ReceiverPerson,
                        "IsReceived": item.IsReceived,
                        "ReceiveTime":item.ReceiveTime,
                        "ReceiveDescription": item.ReceiveDescription,
                    }
                }else{
                    return {
                        "JobId": jobId,
                        "LetterReceiverId": item.ReceiverId,
                        "SendOperatorPositionId":item.SendOperatorPositionId,
                        "SendMethodId": item.SendMethodId,
                        "IsSent": item.IsSent,
                        "SentTime":item.SentTime,
                        "SentDescription": item.SentDescription,
                        "ReceiverPerson":item.ReceiverPerson,
                        "IsReceived": item.IsReceived,
                        "ReceiveTime":item.ReceiveTime,
                        "ReceiveDescription": item.ReceiveDescription,
                    }

                }

            })
            const res = await postLetterSends(newList  ,(newList[0].Id != undefined&&newList[0].Id.length >0)?"put" :"post" );
            try {
                onHide()
            } catch (error) {

            }
        }
    }

    const checkedRowEventCallback = (checked, obj) => {
        if (!checked) {
            checkAllcheckboxes()
        } else {
            setIsAllForms(false)
            // setShowForm(true)
        }
        checkAllcheckboxes()
     

    }

    const listOfCheckedRows = (list) => {
//setChecklist(list)
    }
    const getLetterReceiverPositionsAsync = async() => {
        setLoading(false)
        try {
            const Receivers = await getReceivers(jobId)
            const Historys = await getSentHistory(jobId)



            let positionObjList =  Object.keys(Receivers.Data).map(item=>{
                
                let itemHistory:any = []
                if(Historys.Data){
                   itemHistory =  Historys.Data.filter(obj=> obj.LetterReceiverId == item)
                }
            if(itemHistory[0]){
                    return {
                    "Id" : itemHistory[0].Id,
                     "JobId": jobId,
                     "SendOperatorPositionId": itemHistory[0].SendOperatorPositionId,
                     "ReceiverId" : item,
                     "SendMethodId":itemHistory[0].SendMethodId,
                     "Receiver" :Receivers.Data[item] ,
                     "ReceiverPerson":  itemHistory[0].ReceiverPerson,
                     "SentDescription":  itemHistory[0].SentDescription,
                     "SentTime": itemHistory[0].SentTime,
                     "IsSent": itemHistory[0].IsSent,
                     "IsReceived":itemHistory[0].IsReceived,
                     "ReceiveTime": itemHistory[0].ReceiveTime,
                     "ReceiveDescription": itemHistory[0].ReceiveDescription,
                 }
                }else{
                    return {
                     "Id" : "",
                     "JobId": jobId,
                     "SendOperatorPositionId": "",
                     "ReceiverId" : item,
                     "SendMethodId":"",
                     "Receiver" :Receivers.Data[item] ,
                     "ReceiverPerson":  "",
                     "SentDescription":  "",
                     "SentTime": "",
                     "IsSent": false,
                     "IsReceived":false,
                     "ReceiveTime": "",
                     "ReceiveDescription": "",
                 }

                }
            })
            setPositionObjectList(positionObjList)
        } catch (error) { 
            
        }
        setLoading(true)

    }

    const multiSelectEventCallback = (checked) => {

        if (checked) {
            setIsAllForms(true)
            setShowForm(true)
        } else {

            const filteredArray = positionObjectList.map(item => {
/*                 if (item.TAS_UID == taskList[page].TaskId) {
                    let clear = clearedRowData(item);
                    return clear
                } else {
                    return item
                } */
            })
            //remove check of checkBox
            setresetMultiSelect(!resetMultiSelect)
        }
    }

    const onCheckboxClicked = (obj) => {
        checkAllcheckboxes()
        setCheckedData(obj)

        // setSelectedRow(obj)
    }

    const onRowClicked = (obj, index, event) => {
        let originalObject = JSON.parse(JSON.stringify(obj)) 
        originalObject.DeadLineDate =  originalObject.DeadLineOriginalDate
        if (event?.target?.type != "checkbox") {
 
        } else {
            setresetMultiSelect(!resetMultiSelect)
            checkedRowEventCallback(false, obj)
            checkAllcheckboxes()
        }
    };

    const onRowDoubleClick = (obj) => {
      // let originalObject = JSON.parse(JSON.stringify(obj)) 
      // originalObject.DeadLineDate =  originalObject.DeadLineOriginalDate
       
            setSelectedRow(obj);
            setIsAllForms(false)
            setShowForm(true)
    
        
    };

    const getWithOrder = (order: string[]) => {
        setOrderFormat(order.join(","))
    }

    const handleSubmitedData = (receivedData ,flag) => {  // handle submitted data from a LetterSend form
        if(!flag){
            if (receivedData !== undefined) {
                const filteredArray = positionObjectList.filter(obj => obj.ReceiverId !== receivedData.ReceiverId )
                let updatedArray = [receivedData, ...filteredArray]
                let validList = updatedArray.filter(obj => obj.SendOperatorPositionId.length >0 )

                setPositionObjectList([...updatedArray])
                setActivateCheckbox([{ Id: receivedData.PerformerPositionId }])
                if(validList.length == positionObjectList.length){
                    setCanSubmit(true)

                }
            }


        }else{
            let updatedArray = positionObjectList.map(item=>{
                return {
                    "Id":receivedData.Id,
                    "JobId": receivedData.JobId,
                    "SendOperatorPositionId":receivedData.SendOperatorPositionId,
                    "ReceiverId" :item.ReceiverId,
                    "SendMethodId": receivedData.SendMethodId,
                    "Receiver" : item.Receiver,
                    "ReceiverPerson":  receivedData.ReceiverPerson,
                    "SentDescription":  receivedData.SentDescription,
                    "SentTime": receivedData.SentTime,
                    "IsSent":  receivedData.IsSent,
                    "IsReceived": receivedData.IsReceived,
                    "ReceiveTime": receivedData.ReceiveTime,
                    "ReceiveDescription":  receivedData.ReceiveDescription,
                }
            })

                setPositionObjectList([...updatedArray])
                setActivateCheckbox([{ Id: receivedData.PerformerPositionId }])
                setCanSubmit(true)

        }

        setShowForm(false)

    }
    const checkAllcheckboxes = () => {

        let cheklist: any = []
        positionObjectList.map(obj => {
         if (obj.SendOperatorPositionId!=undefined && obj.SendOperatorPositionId.length >0 ) {
             cheklist.push({ Id: obj.ReceiverId })
         }
     })
        if (cheklist.length == positionObjectList.length) {
            setActivateCheckbox([{ Id: "All" }])
        } else {
            setActivateCheckbox(cheklist)
        }
    }

    // useEffect(() => {
    //     checkAllcheckboxes()
    // }, [])

    useEffect(() => {
        checkAllcheckboxes()

    }, [positionObjectList ])
    useEffect(() => {
        getLetterReceiverPositionsAsync()

    }, [ ])





    if (positionObjectList && positionObjectList.length > 0) {
        tableHeadData = Object.keys(positionObjectList[0])
        tableData =positionObjectList
    }

    return (
        <div className="card mb-0">
            <div className={`LetterSendwizard`}>
                <div className="content-card d-flex flex-column w-100">
                    {loading ?    
                    <div className="content-card d-flex flex-column w-100">
{/*                         <Headerbar hasBorder={true} headerContents={{ taskTitle, "assignType" :trans(assignType) }} />
 */}                        <div className="mt-3 mb-3">
                            {tableData && tableData.length > 0 &&
                                <DataGrid
                                    orderFormat={getWithOrder}
                                    multiSelect={true}
                                    //parentName={"LetterSendTable" + taskTitle}
                                    resetMultiSelect={resetMultiSelect}
                                    parentName={"LetterSendTable"}
                                    theadData={tableHeadData}
                                    tbodyData={tableData}
                                    multiSelecttype={2}
                                    startIndex={5}
                                    minCellWidth={40}
                                    selectedRowObjectCallback={onRowClicked}
                                    DoubleClick={onRowDoubleClick}
                                    selectItem={onCheckboxClicked}
                                    multiSelectCallback={multiSelectEventCallback}
                                    selectedRowProp={activateCheckbox ? activateCheckbox : null}
                                    checkedRowEventCallback={checkedRowEventCallback}
                                    selectList={listOfCheckedRows}
                                    scrollHeight={"300px" }
                                />}
                        </div>
                    </div>
                    
                    :
                        <Loading/>
                    }


                    <div className="d-flex justify-content-end">
                        <Button disabled={!canSubmit} value="Save" btnType="submit" onClick={handleSubmission} />
                    </div>         
                </div>
            </div>
                {showForm && 
            <ModalForm modalHeight={65}modalProfileKey={"LetterSendForm"}  title={trans("LetterSendForm") +" : " +(selectedRow.Receiver == undefined  ?trans("grouply") :selectedRow.Receiver) }onHide={() => setShowForm(false)} show={showForm}>
                <>
                <LetterSendForm
                    selectedRow={selectedRow}
                    // description={selectedRow?.Description}
                    step={1}
                    mode={"create"}
                    handleSubmitedData={handleSubmitedData}
                    allFormData={positionObjectList}
                    setShowForm={setShowForm}
                    onHide={()=>{setShowForm(false)}}
                    showForm={showForm}
                    isAllForms={isAllForms}
                />
                </>

            </ModalForm>
                }
        </div>

    )
}