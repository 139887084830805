
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { TableTree } from "components/TableTree/TableTree";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { trans } from "utils/helpers";
import { getFully } from './api/api';
import { OrganizationRoles } from "./components/OrganizationRoles/OrganizationRoles";
import './style.scss';
interface propsOrganizationalAccesses {

}
export const OrganizationalAccesses = () => {
  const [data, setData] = useState<any>()
  const [showRoleForm, setShowRoleForm] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [defultShow, setDefultShow] = useState(true)
  const [Fullydata, setFullydata] = useState<any>()
  const [selectedObj, setSelectedObj] = useState<any>()
  const [idCallback, setIdCallback] = useState<any>("2")

  const [messageType, setMessageType] = useState<any>("info")
  const [search, setsearch] = useState(null);

  let theadData: any = []
  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "tree":setSelectedObj(null);getFullyAsync();setDefultShow(!defultShow);break;
      case "print":alert("print ");break;
      // case "excel":alert("excel ");break;
      case "refresh":setSelectedObj(null);setForceUpdate(!forceUpdate);break;
      case "assignrule": if (selectedObj) setShowRoleForm(!showRoleForm); break;
    }
  };




  async function getFullyAsync() {
    setLoading(false)
    try {
      const respons = await getFully()
      setFullydata(respons.Data)
    } catch (error) {
      setMessageType("error")
    }

    setLoading(true)
  }


  const Searchhandleer = (str) => {
    if (str == "") {
        setsearch(null)
    } else {
        setsearch(str)
    }
}

  useEffect(() => {
    setFullydata(null)
    getFullyAsync()
  }, [forceUpdate]);

  if (data || Fullydata) {
    theadData = Object.keys(Fullydata[0])
  }



  return (
    <>
      {showRoleForm ? <ModalForm mode={"edit"} modalHeight={50} onHide={() => { setShowRoleForm(!showRoleForm) }} show={showRoleForm} modalProfileKey={"Users"} title={trans("AssignedAccessRoles") + " : " + selectedObj.Description} ><OrganizationRoles selectedObj={selectedObj} /> </ModalForm> : null}
      <Toolbar excel={false} view={false} handleSearch={Searchhandleer} assignRule={true} emptyList={theadData?.length == 0} id={selectedObj} search={true} handleClickToolbar={clickToolbarCallback} />
      <div style={{ overflowY: "auto", 
                height: "78vh",
                 scrollbarWidth: "thin"}} className="col-lg-12 mt-0">
        <div className="list-card card  mt-3">
          {false ?
            <>
              {
                loading ? (
                  data ?
                    <TableTree
                      dragDrop={true}
                      selectedRowObjectCallback={setSelectedObj}
                      selectedRowObject={idCallback}
                      getChildrenUrl={ENDPOINTS.BaseInformation.organization.getNode}
                      Data={data}
                      theadData={theadData}
                      parentName={"OrganizationalAccesses"}
                      startIndex={10}
                    />
                    : <Message message={"NoDataForPreview"} type={messageType} />
                )
                  : <Loading />
              }
            </>
            :
            <>
              {
                loading ? (
                  Fullydata ?
                    <TableTree
                      search={search}
                      dragDrop={true}
                      selectedRowObjectCallback={setSelectedObj}
                      selectedRowObject={idCallback}
                      getChildrenUrl={ENDPOINTS.BaseInformation.organization.getNode}
                      Data={Fullydata}
                      theadData={theadData}
                      parentName={"OrganizationalAccesses"}
                      startIndex={9}
                      defultShow={true}
                    />
                    : <Message message={"NoDataForPreview"} type={messageType} />
                )
                  : <Loading />
              }
            </>
          }
        </div>
      </div>
    </>
  )
}