import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getReferenceDictionarys = (JobId,endpoint) => http({
    url: endpoint.CurrentReferencePositions,
    method: 'get',
    params:{
        JobId:JobId
    }

}).then((res) => {
    return res.data
}).catch((err) => {
     
    return Promise.reject( err);
});



