import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints"
import { trans } from "utils/helpers";
import { http } from "utils/http"

export const getProcessById = (id) => http({
    url: ENDPOINTS.bpmnApi.process.getProcessTasks,
    method: "get",
    params: {
        processId: id

        }
    }).then((res) => {
       
        return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });
    


export const exportProcess = (id) => http({
    url: ENDPOINTS.bpmnApi.process.exportProcessMakerFile,
    method: "get",
    params:{
        prj_uid :id
    }
    }).then((res) => {
        return res
    }).catch((err) => {
        return Promise.reject( err);
    });


    export const postProcessIcon = (query) => http({
        url: ENDPOINTS.bpmnApi.bpmnProcessIcon.load,
        method: "post",
        params: {
            iconId: query.iconId,
            PRJ_UID : query.PRJ_UID
    
        }
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return Promise.reject( err);
        });
        
    
    export const postProcessApplication = (query) => http({
        url: ENDPOINTS.bpmnApi.bpmnProcessApplication.load,
        method: "post",
        params: {
            applicationId: query.applicationId,
            PRJ_UID : query.PRJ_UID
        }
        }).then((res) => {
          
            return res.data
        }).catch((err) => {
            return Promise.reject( err);
        });
        
    
    export const getProcessApplication = (PRJ_UID) => http({
        url: ENDPOINTS.bpmnApi.bpmnProcessApplication.browse,
        method: "get",
        params: {
            PRJ_UID : PRJ_UID
        }
        }).then((res) => {
         
            return res.data
        }).catch((err) => {
            return Promise.reject( err);
        });
        
    export const deleteProcessApplication = (query) => http({
        url: ENDPOINTS.bpmnApi.bpmnProcessApplication.delete,
        method: "DELETE",
        params: {
            applicationId: query.applicationId,
            PRJ_UID : query.PRJ_UID
    
        }
        }).then((res) => {
         
            return res.data
        }).catch((err) => {
            return Promise.reject( err);
        });
        
        export const getProcessListDictionary = (async () => await http({
            url:ENDPOINTS.bpmnApi.process.getProcessDictionaryAll ,
            method: 'get',
        })
            .then((response) => (response.data))
        )