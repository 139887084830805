import { useEffect, useState } from "react";
import { PersonContactForm } from "./component/PersonContactForm/PersonContactForm";
import { Toolbar } from "components/Toolbar/Toolbar";
import { DataGrid } from "components/DataGrid/DataGrid";
import { PersonContact } from "pages/OfficeAutomation/BaseInformation/PersonsPage/types";
import { Message } from "components/Message/Message";
import { trans } from "utils/helpers";
import { Toast } from "components/ToastShow/ToastShow";
import { Loading } from "components/Loading/loading";

interface PersonContactListPropsType {
  personId: string;
  mode: any;
  TotalRecords: any;
  personContactDataListProp: PersonContact[];
  personContactDataListCallback: (ans: PersonContact[]) => void;
}
export const PersonContactList = ({
  personId,
  TotalRecords,
  mode,
  personContactDataListCallback,
  personContactDataListProp,
}: PersonContactListPropsType) => {
  const [loading, setLoading] = useState<any>(true);
  const [selectedObject, setSelectedObject] = useState<PersonContact | any>(
    null
  );
  const [editIndex, setEditIndex] = useState(0);

  const [contactmode, setContactmode] = useState<any>();
  const [showPersonContactForm, setShowPersonContactForm] = useState(false);
  const [personContactDataList, setPersonContactDataList] = useState<
    PersonContact[]
  >(personContactDataListProp); // این تایپ مشخص شود

  let HeadPersonContact: string[] = [];
  let BodyPersonContact: any = [];
  const showPersonContactFormCallback = (ans: boolean, reload?: boolean) => {
    if (reload) {
      setSelectedObject(null);
    }
    setShowPersonContactForm(ans); // برای زمانی که اگر کاربر روی دکمه انصراف کلیک کرد، فرم بسته شود
  };
  const newPersonContactDataCallback = (ans: PersonContact, exist) => {
    const reapetedItem = BodyPersonContact.find((item) => {
      return (
        item.Value == ans.Value &&
        item.Description == ans.Description &&
        item.ContactType == ans.ContactType
      );
    });

    if (!reapetedItem) {
      if (exist) {
        let personContactList: any = personContactDataList.map((item) => {
          if (item.Id == ans.Id) return ans;
          else return item;
        });
        setPersonContactDataList(personContactList);
        personContactDataListCallback(personContactList);
      } else {
        let personContactList: any = personContactDataList;
        personContactList.push(ans);
        setPersonContactDataList(personContactList);
        personContactDataListCallback(personContactList);
      }
    }
  };

  const selectedObjectCallback = (obj, index) => {
    setSelectedObject(personContactDataList[index]);
    setEditIndex(index);
  };
  const handleEditRow = (e) => {
    setContactmode("edit");
    setShowPersonContactForm(true);
  };
  const handleViewRow = (e) => {
    setContactmode("view");
    setShowPersonContactForm(true);
  };
  const handleDeleteRow = (e = selectedObject?.Id) => {
    setLoading(false);
    let personContactList: any = [];
    personContactDataList.map((item) => {
      if (item.Id != e) personContactList.push(item);
    });
    setPersonContactDataList(personContactList);
    setSelectedObject(null);
    personContactDataListCallback(personContactList);
    setLoading(true);
  };
  const handleCreateRow = () => {
    setContactmode("create");
    setShowPersonContactForm(true);
  };
  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "help":
        break;
      case "edit":
        if (selectedObject?.Id) handleEditRow(selectedObject?.Id);
        break;
      case "create":
        handleCreateRow();
        break;
      case "view":
        if (selectedObject?.Id) handleViewRow(selectedObject?.Id);
        break;
      case "remove":
        if (selectedObject?.Id)
          Toast(
            trans("msg_deleteconfirm"),
            "warning",
            "alert",
            handleDeleteRow
          );
        break;

      default:
        break;
    }
  };

  if (personContactDataList) {
    if (personContactDataList.length != 0) {
      HeadPersonContact = Object.keys(personContactDataList[0]);
      //   BodyPersonContact = JSON.parse(JSON.stringify( personContactDataList))
      BodyPersonContact = JSON.parse(JSON.stringify(personContactDataList)).map(
        (personContactData: any) => {
          if (
            (personContactData.ContactTypeEnum == 4 ||
              personContactData.ContactTypeEnum == 8) &&
            personContactData.Value.includes('"')
          ) {
            if (typeof JSON.parse(personContactData.Value) === "object") {
              let data = JSON.parse(personContactData.Value);
              if (personContactData.ContactTypeEnum == 4) {
                if (data.address !== undefined) {
                  personContactData.Value = `${data.address} ${trans(
                    "plaque"
                  )}:${data.plaque} ${trans("unit")}:${data.unit} ${trans(
                    "PostalCode"
                  )}:${data.PostalCode}`;
                }
              } else {
                if (data.Altitude !== undefined) {
                  personContactData.Value = `${trans("Altitude")}:${
                    data.Altitude
                  } ${trans("Latitude")}:${data.Latitude} ${trans(
                    "Longitude"
                  )}:${data.Longitude}`;
                }
              }
            } else {
            }
          }

          return personContactData;
        }
      );
    }
  }

  return (
    <div className="person-contact-list">
      <Toolbar
        refresh={false}
        emptyList={BodyPersonContact?.length == 0}
        id={selectedObject?.Id}
        create={mode != "view"}
        remove={mode != "view"}
        edit={mode != "view"}
        handleClickToolbar={clickToolbarCallback}
      />
      {showPersonContactForm && (
        <PersonContactForm
          mode={contactmode}
          personContact={contactmode != "create" ? selectedObject : null}
          showPersonContactFormCallback={showPersonContactFormCallback}
          newPersonContactDataCallback={newPersonContactDataCallback}
        />
      )}

      {loading ? (
        <>
          {personContactDataListProp ? (
            BodyPersonContact.length > 0 ? (
              <>
                <DataGrid
                  parentName={"PersonContact"}
                  // scrollHeight={showForm? "25vh": "67vh"}
                  theadData={HeadPersonContact}
                  minCellWidth={80}
                  rowSelected={selectedObject}
                  startIndex={2}
                  tbodyData={BodyPersonContact}
                  selectedRowObjectCallback={selectedObjectCallback}
                  //    handleEdit={handleEditRow}
                  //  handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,()=> handleDeleteRow(e))}}
                  handleView={handleViewRow}
                  //totalRecords={TotalRecords}
                />
              </>
            ) : (
              <Message message={"NoDataForPreview"} type={"info"} />
            )
          ) : (
            <Message message={"NoDataForPreview"} type={"error"} />
          )}{" "}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};
