import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getUnitDictionaryApi = (async (search, id ) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: id?  ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Unit.dictionaryId  :  ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Unit.dictionary,
    method: 'get',
    params: {
        SearchString: search,
        Id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getConversion = (async ( id ) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url:  ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Unit.defaultUnit,
    method: 'get',
    params: {
        physicalQuantityId: id
    }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)


