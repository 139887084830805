import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getDefaultPersonContact = (personId ) => http({

    url:ENDPOINTS.BaseInformation.PersonContact.default,
    method: 'get',
    params:{personId}

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const sendBasketAsync = (data , method ) => http({

    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.CustomerSalesOrderApis.CustomerSalesOrder.browse,
    method: method,
    data
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


export const getSalesOrderById = (JobId ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.CustomerSalesOrderApis.CustomerSalesOrder.load,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
       JobId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});