
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useRef, useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import "./style.scss";



interface Form{
    Title?:string,
    Method?:string,
    columns?:any,
    removedColumns?:any,
    filter?:any,
    order?:any,
    handleOrderList: (e?: any) => void;
    handleFilterList: (e?: any) => void;
    setRemovedColumns: (e?: any) => void;
    setHoverSource: (e?: any) => void;
    HoverSource?:any,
    Status?:number,
    Task?:any,
    modalWidth?:any
    startIndex?:any
    parentName?:any
}


export const GridSetting: React.FC<Form> = ({parentName,HoverSource,setHoverSource ,startIndex ,setRemovedColumns, removedColumns ,columns , filter ,order,handleOrderList,handleFilterList , Task , Method, modalWidth}) => {
    const [orderList , setOrderList] = useState<any>(order)
    const [filterList , setFilterList] = useState<string>(filter)
    const [showGridSetting, setShowGridSetting] = useState<any>(false);
    const [showOperations, setShowOperations] = useState(false);
    const [columnStatus, setColumnStatus] = useState<any>({});
    const refBox = useRef<any>()




    const [hover, setHover] = useState<any>(HoverSource);


  function toggleSortingFormat(text) {
    for (let i = 0; i < orderList.length; i++) {
        if (orderList[i].includes(text)) {
            if (orderList[i].includes("asc"))
                return ["up", i]
            else if (orderList[i].includes("desc"))
                return ["down", i]

        }
    }
    return "";
}

function createSortingFormat(text) {


  let itemExists = false;
  let Sorting: any = [];
  let newSortingFormat: string[] = [];

  for (let i = 0; i < orderList.length; i++) {
      if (orderList[i].includes(text)) { // اگر عنوان ستون داخل یکی از رشته ها بود
          itemExists = true;
          if (orderList[i].includes("asc")) {
              newSortingFormat.push(orderList[i].replace("asc", "desc"));
          }
          else if (orderList[i].includes("desc")) {
            newSortingFormat.push(orderList[i].replace("desc", "asc"));

          }

      } else {
          // یعنی اگر پیمایش آرایه به آخر رسید
          newSortingFormat.push(orderList[i]);
      }
  }
  if (itemExists) {
      Sorting = newSortingFormat
  } else {
      // Sorting = JSON.parse(JSON.stringify(sortingFormat))
      newSortingFormat.push(`${text} asc`)

      Sorting = newSortingFormat
      //  setSortingFormat((old) => { return [...old, `${text} asc`] })
  }
  // setSortingFormat(Sorting)

  setOrderList(Sorting)

}

function deleteaOrder(e) {
  let newSortingFormat: string[] = [];
  orderList.forEach(item => {
    if(!item.includes(e)){
      newSortingFormat.push(item)
    }

  });

  setOrderList(newSortingFormat)
}
function deleteafilter(e) {

    let newfilterList:any=""
    if(filterList.length > 0){
    filterList.split(",").forEach(item=>{
      if(!item.includes(e) && item.length >2){
        newfilterList+= ( item+",")
        

      }
    })

    setFilterList(newfilterList)
  }
}


function changeColumnsStatus(item) {
   let remoObject = JSON.parse(JSON.stringify(columnStatus))
   if(remoObject[item]){

     deleteafilter(item)
     deleteaOrder(item)
   }
   
   setColumnStatus({...remoObject , [item] : (remoObject[item] != null ? !remoObject[item] :false )})
 
}
function handleRemoveOrderList() {
  let hedTables = localStorage.getItem("userHeadTable");
  let locallist;
  if (hedTables == null) {
      locallist = [];
  } else {
      locallist = JSON.parse(hedTables)

  }

      locallist.forEach(item => {
          if (item[0] === parentName) {
             
              item[5] = []



          }
      });
      localStorage.setItem("userHeadTable", JSON.stringify(locallist));
      handleOrderList && handleOrderList([])

}
async function handleRemoveSettings() {
  let hedTables = localStorage.getItem("userHeadTable");
  let locallist;
  if (hedTables == null) {
    locallist = [];
  } else {
    locallist = JSON.parse(hedTables)
    
  }
  locallist.forEach(item => {
    if (item[0] === parentName) {  
      item[6] = []
      item[5] = []
      item[4] = ""
      item[2] = []
    }
  });
  localStorage.setItem("userHeadTable", JSON.stringify(locallist));
  handleOrderList && handleOrderList([])
  handleFilterList && handleFilterList("")
  setRemovedColumns([])
  setShowOperations(false)
  setShowGridSetting(false)
}


function handleRemoveFilterList(e) {
  let hedTables = localStorage.getItem("userHeadTable");
  let locallist;
  if (hedTables == null) {
      locallist = [];
  } else {
      locallist = JSON.parse(hedTables)

  }

      locallist.forEach(item => {
          if (item[0] === parentName) {
             
            item[4] = ""


          }
      });
      localStorage.setItem("userHeadTable", JSON.stringify(locallist));
      handleFilterList && handleFilterList("")

}



  function handleSaveSettings(e) {
    e.preventDefault()
    let removedlist:any = []
    document.forms["AdvancedSetting"]["columns"].forEach(item=>{
      
      if(!item.checked)
        removedlist.push(item.defaultValue)
    })

        let hedTables = localStorage.getItem("userHeadTable");
        let locallist;
        if (hedTables == null) {
            locallist = [];
        } else {
            locallist = JSON.parse(hedTables)

        }
        locallist.forEach(item => {
            if (item[0] === parentName) {
                item[4] = filterList
                item[5] = orderList
                item[6] = removedlist
            }
        });
        localStorage.setItem("userHeadTable", JSON.stringify(locallist));
        handleFilterList(filterList)
        handleOrderList(orderList)
  
        
        setRemovedColumns(removedlist)
        setShowOperations(false)
        setShowGridSetting(false)

  }
  function changeHoverSource(e) {
    let hedTables = localStorage.getItem("userHeadTable");
    let locallist;
    if (hedTables == null) {
        locallist = [];
    } else {
        locallist = JSON.parse(hedTables)

    }
    locallist.forEach(item => {
        if (item[0] === parentName) {
            item[7] = e
        }
    });
    localStorage.setItem("userHeadTable", JSON.stringify(locallist));

  }

useEffect(() => {
  if(showGridSetting){


    let remoObject:any ={}
    removedColumns.forEach(item => {
      remoObject[item] = false
    });
    setColumnStatus({...columnStatus , ...remoObject})
  }
 }, [showGridSetting]);

    useEffect(() => {

      setHover(HoverSource);
       
 }, [HoverSource]);


    

    return ( 

      <>

      

<div style={{marginTop: "5px"}}  className="operations">

              <button ref={refBox} style={{marginRight: "2px", zIndex:"6" , position: 'relative'}} tabIndex={-1} type='button' className="operations__trigger-btn" onClick={(e)=>{e.stopPropagation()}} onFocus={() => { }} 
              // onBlur={() => { setShowOperations(false); }}
              >

              <FontAwesomeIcon onClick={()=>{setShowOperations(true)}} style={{marginLeft:"10px"}} icon={[ICON_TYPE, "sliders"]}/> 
                </button>
              <div style={{ top: `${refBox.current?.getBoundingClientRect().top}px` , width:"190px",position:'fixed',right:"unset" ,left: `${refBox.current?.getBoundingClientRect().left-190}px` }} className={`operations__operations-icons pt-1 ${showOperations ? "" : "d-none"}`}>
                <div className={`operations__operations-icon-wrapper `} >
                   <div className="operations__operations-icon-wrapper" onClick={() =>{setShowGridSetting(true);setShowOperations(false);}}>
                   <FontAwesomeIcon className="text-primary" onClick={()=>{setShowGridSetting(true)}} style={{marginLeft:"10px"}} icon={[ICON_TYPE, "sliders"]}/> 
                       <p>{trans("MoreOptions")}</p>
                   </div>
                 </div>
                <div className={`operations__operations-icon-wrapper `} onClick={()=>{handleRemoveSettings()}} >
                   <div className="operations__operations-icon-wrapper" onClick={() =>{handleRemoveSettings()}}>
                   <FontAwesomeIcon className="text-primary" onClick={()=>{handleRemoveSettings()}} style={{marginLeft:"10px"}} icon={[ICON_TYPE, "arrow-rotate-left"]}/> 
                       <p>{trans("ResetCustomSettings")}</p>
                   </div>
                 </div>               
                {order.length > 0 && 
                <div className={`operations__operations-icon-wrapper `} >
                   <div className="operations__operations-icon-wrapper" onClick={() =>{ handleRemoveOrderList()}}>
                   <FontAwesomeIcon className="text-primary" onClick={()=>{ handleRemoveOrderList()}} style={{marginLeft:"10px"}} icon={[ICON_TYPE, "sort"]}/> 
                       <p>{trans("RemoveSorts")}</p>
                   </div>
                 </div>
                }
                {(filter && filter.length > 0 )&& 
                <div className={`operations__operations-icon-wrapper `} >
                   <div className="operations__operations-icon-wrapper" onClick={() =>{handleRemoveFilterList("")}}>
                      <FontAwesomeIcon className="text-primary" onClick={()=>{handleRemoveFilterList("")}} style={{marginLeft:"10px"}} icon={[ICON_TYPE, "filter"]}/> 
                      <p>{trans("removeFilters")}</p>
                   </div>
                 </div>
                }
              </div>
 </div>
  
     {
        showGridSetting &&
        <ModalForm modalHeight={80} title={"DataGridCustomization"} onHide={() => setShowGridSetting(false)} show={showGridSetting}>
            <form id={"AdvancedSetting"} >
                <div className="card">
                  {orderList.length > 0&&
                    <div className="card-body  p-3"> 
                    <p className="p-1">{trans("Sort")}</p>
                    <div className="group-person__selected-options-wrapper">
                      {orderList&&orderList.map((item ,index)=>(
                        columnStatus[item.split(" ")[0]] !== false ?

                                    <div className="group-person__selected-option form-control-custom" key={item.split(" ")[0] + index}>
                                    <div className="group-person__defult-selected-option" data-key={item.split(" ")[0]}>
                                      
                                        {toggleSortingFormat(item)[0] === "up" ?
                                        <><FontAwesomeIcon className="text-primary" size="lg" style={{  marginTop: "-2px", fontSize:"12px" }} icon={[ICON_TYPE, "arrow-up"]} onClick={() => { createSortingFormat(item) }}/>  </>:
                                         (toggleSortingFormat(item)[0] === "down" ?
                                         <> <FontAwesomeIcon className="text-primary" size="lg" style={{  marginTop: "-2px", fontSize:"12px" }}  icon={[ICON_TYPE, "arrow-down"]} onClick={() => { createSortingFormat(item) }}/> </> : null)
                                     }
                                      
                                       
                                    </div>
                                    <div style={{marginRight:"13px"}}>
                                      {
                                        item.includes('Icon') ?  <FontAwesomeIcon size="1x" icon={[ICON_TYPE,item.split(" ")[0].replace("Icon","").toLowerCase() ]} style={{ color:'#5b5b5b' }} />
                                        :
                                        trans(item.split(" ")[0])
                                      }
                                    </div>
                                    
                                    <div className="group-person__delete-selected-option" data-key={item.split(" ")[0]} onClick={()=>{deleteaOrder(item.split(" ")[0])}}>
                                        <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                                    </div>
                                </div>
                                :<></>
                      ))
                      
                      
                      }
                      </div>
                     </div>

                  }
                  {(filterList != undefined && filterList.length > 1)&&
                    <div className="card-body  p-3"> 
                    <p className="p-1">{trans("filter")}</p>
                    <div className="group-person__selected-options-wrapper">

                      {filterList.length > 1&&filterList.slice(0,filterList.length-1).split(",").map((item ,index)=>(
                                    columnStatus[item.split("=")[0]]!== false ?
                                    <div className="group-person__selected-option form-control-custom" key={item.split(" ")[0] + index}>
                                    <div style={{marginRight:"13px"}}>
                                     {trans(item.split("=")[0]) } :{item.split("=")[1]}
                                    </div>
                                    
                                    <div className="group-person__delete-selected-option" data-key={item.split(" ")[0]} onClick={()=>{deleteafilter(item.split(" ")[0])}}>
                                        <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                                    </div>
                                </div>
                                :<></>
                      ))
                      
                      
                      }
                      </div>
                     </div>

                  }
                 <div className="card-body  p-3"> 
                    <p className="p-1">{trans("columns")}</p>
                    <div className="group-person__selected-options-wrapper">

                      {columns&&columns.map((item ,index)=>(
                        <>
                        {(index >= startIndex && item!=="SelectAll" &&item!=="Operation" ) &&
                        <>
                        {removedColumns.includes(item) ? 

                              <div className="group-person__selected-option form-control-custom" key={ index}>
                              <div style={{marginRight:"13px"}}>
                              {
                                        item.includes('Icon') ?  <FontAwesomeIcon size="1x" icon={[ICON_TYPE,item.replace("Icon","").toLowerCase() ]} style={{ color:'#5b5b5b' }} />
                                        :
                                        trans(item)
                                      }
                              </div>
                              
                              <div className="group-person__delete-selected-option"  onClick={(e)=>{e.stopPropagation(); changeColumnsStatus(item) }}>
 
                              <input type="checkbox"  defaultValue={item} defaultChecked={false} name={"columns"} />
                              </div>
                          </div>
                        :
                              <div className="group-person__selected-option form-control-custom" key={index}>
                              <div style={{marginRight:"13px"}}>
                              {
                                        item.includes('Icon') ?  <FontAwesomeIcon size="1x" icon={[ICON_TYPE,item.replace("Icon","").toLowerCase() ]} style={{ color:'#5b5b5b' }} />
                                        :
                                        trans(item)
                                      }
                              </div>
                              
                              <div className="group-person__delete-selected-option"  onClick={(e)=>{e.stopPropagation(); changeColumnsStatus(item) }}>
                              <input type="checkbox"  defaultValue={item} defaultChecked={true} name={"columns"} />
                         
                              </div>
                          </div>


                        }
                        
                        
                        
                        </>



                        }
                        
                        </>
                      ))
                      
                      
                      }
                      </div>
                     </div>
                 <div className="card-body  p-3"> 
                    <p className="p-1">{trans("HoverSource")}</p>
                    <div className="row">
                      <div style={{width:"150px"}} className=" group-person__selected-options-wrapper">
                        <div className="group-person__selected-option form-control-custom" key={ "ColumnHeader"}>
                          <div style={{marginRight:"13px"}}>
                             {trans("ColumnHeader") }
                          </div>
                          
                          <div className="group-person__delete-selected-option"  onClick={(e)=>{setHoverSource("ColumnHeader" );setHover("ColumnHeader" );e.stopPropagation(); changeHoverSource("ColumnHeader") }}>
 
                            <input type="checkbox"  defaultValue={"ColumnHeader"} defaultChecked={"ColumnHeader" === hover}  checked={"ColumnHeader" === hover} name={"HoverSource"} />
                          </div>
                        </div>
                      </div>
                      <div style={{width:"150px"}} className=" group-person__selected-options-wrapper">
                        <div className="group-person__selected-option form-control-custom" key={ "ColumnData"}>
                          <div style={{marginRight:"13px"}}>
                             {trans("ColumnData") }
                          </div>
                          
                          <div className="group-person__delete-selected-option"  onClick={(e)=>{setHoverSource("ColumnData" );setHover("ColumnData" );e.stopPropagation(); changeHoverSource("ColumnData") }}>
 
                            <input type="checkbox"  defaultValue={"ColumnData"}  defaultChecked={"ColumnData" === hover} checked={"ColumnData" === hover} name={"HoverSource"} />
                          </div>
                        </div>
                      </div>

                     </div>
                    </div>
                  </div>
     
                <div className="col-lg-12 mt-3 btns_form">
                <Button value="Save" btnType="submit" onClick={handleSaveSettings} />
                <Button
                  btnType="cancel"
                  value="Cancel"
                  onClick={()=>Toast(trans("modalclosemessage") , "warning" , "alert" , () => setShowGridSetting(false) )}
                />
              </div>
              </form>        </ModalForm>
      }
      </>

    );
}