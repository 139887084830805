import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { Message } from "components/Message/Message";
import { Loading } from "components/Loading/loading";
import { Toast } from "components/ToastShow/ToastShow";
import { downloadCSV, getPageSize , trans } from "utils/helpers";
import { deleteCityApi, getListCity} from "./api"
import { CityForm } from "./CityForm";



export const City = () => {

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [flag, setFlag] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [loading, setLoading] = useState(false);
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pages, setPages] = useState<number>(0);
    const [filterlist, setFilterlist] = useState<any>("");



    const searchHandler = (str) => {
        setPageNum(1);
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }

      const handlechangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("City" ,e.currentTarget.value )
        getCityAsync(1)
      };

    const handleGoPage = (value) => {
      if (value) {
        if (value < 1 || value > pages) { }
        else setPageNum(+value);getCityAsync(+value)
      } else {

      }
    };
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getCityAsync(pageNum + 1);
          };
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getCityAsync(pageNum - 1);
          };
    };




    const handleCreateAction = () => {
        setMode("create")
        setShowForm(true)
    }
    
    const handleEditRow = () => {
        setMode("edit");
        setShowForm(true)
    }

    const handleDeleteRow = async() => {
        try {
            await deleteCityApi(selectedObj?.Id)
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }
    }
    const handleViewRow = () => {
        setMode("view")
        setShowForm(true)
    }
    
    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{sessionStorage.setItem("changingforms" , "false");setSelectedObj(obj);setResetForm(true) });
        }else{
            setSelectedObj(obj)         
        };
    }


    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setShowForm(false)
            });
        }else{
            setShowForm(false)     
        };
    }
    async function getCityAsync(PageNum = pageNum) {
        setLoading(false);
        setResponse(null);
        let pageSizeChangedByUser = getPageSize("City" )
        setPageSize(pageSizeChangedByUser)
        try {
            
            const res = await getListCity (PageNum,  pageSizeChangedByUser ,search ,orderBy.length == 0 ?null:  orderBy.join(","),filterlist);
            setResponse(res.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)
    }
 

    useEffect(() => {
        setResponse(null)
        getCityAsync();
    }, [forceUpdate, search , filterlist , orderBy ,flag ,forceUpdate]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);

 

    

    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("City"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id) handleEditRow(); break;
            case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "help": alert("call api help"); break;
        }
    };
    /* #endregion */

    return (
        <>

            <div className="cartable-action">
                <Toolbar handleSearch={searchHandler} emptyList={response == null || response?.length == 0} id={selectedObj?.Id} remove={true} create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card"  style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}> 
                    <div className="">
                        {showForm && 
                        <CityForm reload={()=>{setForceUpdate(!forceUpdate);setShowForm(false)}} mode={mode} onHide={cancel}  selectedObj={selectedObj}/>
                        
                        }

                        {loading ?
                            response?.Data?.length > 0 ?
                                <>
                                            <DataGrid
                                                parentName={"City"}
                                                orderFormat={setOrderBy}
                                                orderFormatList={orderBy}
                                                handlefiltering={(str)=>{setFilterlist(str)  }}
                                                filterStr={filterlist}
                                                startIndex={1}
                                                theadData={Object.keys(response.Data[0])}
                                                tbodyData={response.Data}
                                                minCellWidth={80}
                                                selectedRowObjectCallback={selectedIdCallback}
                                                handleEdit={handleEditRow}
                                                handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                                handleView={handleViewRow}
                                                totalRecords={response.TotalRecords}
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                HandleNextPage={handleNextPage}
                                                HandlePrevPage={handlePrevPage}
                                                handlechangePageSize={handlechangePageSize}
                                                first={() => {getCityAsync(pages);setPageNum(pages)}}
                                                end={() => {getCityAsync(1);setPageNum(1)}}
                                                handleGoPage={handleGoPage}
                                            />
                                </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />


                            : <Loading />
                        }

                    </div>
                </div>
            </div>
        </>

    );
}

 
