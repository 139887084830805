import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";





export const postReferences = (reference: any ,endpoint) => http({
    method: 'post',
    url: endpoint.postReferenceList,
    
    data: reference,
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
