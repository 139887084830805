import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { CustomerPositionDictionary } from "components/CustomerPosition/CustomerPosition";
import DatePicker from "components/DatePicker/DatePicker";
import { FollowupPositionId } from "components/FollowupPositionId/FollowupPositionId";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { DevicePropertyValueList } from "./DevicePropertyValues/DevicePropertyValues";
import { getDeviceDeliveryById, postDeviceDelivery } from "./api";



interface props {
    mode?: any,
    onHide: () => void,
    reload: () => void,
    Id?: any,
    step?: any,

}

export const DeviceDeliveryForm = ({  step = 1, mode = "create", onHide, reload,Id }: props) => {
    const layoutContext = useContext(LayoutContext);
    const [DeviceDeliveryDate, setDeviceDeliveryDate] = useState<any>();
    const [DeviceDelivery, setDeviceDelivery] = useState<any>();
    const [resetForm, setResetForm] = useState<boolean>(false);
    const [showStep, setShowStep] = useState<any>({});
    const [position, setPosition] = useState<any>(null);
    const [ReceivedTime, setReceivedTime] = useState<string | null>(null);
    const [showProperties, setShowProperties] = useState<any>(false);
    const [devicePropertyValue, setDevicePropertyValue] = useState<any>();
    const [userChanges, setUserChanges] = useState({});

    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async(event) => {  
        event.preventDefault()
            if (mode === "edit") {
                let data = {
                    "Id": DeviceDelivery.Id,
                    "CustomerPositionId": DeviceDelivery.CustomerPositionId,
                    "PhoneToFollowUp": DeviceDelivery.PhoneToFollowUp,
                    "DelivereePerson": DeviceDelivery.DelivereePerson,
                    "ReceivedByPositionId": DeviceDelivery.ReceivedByPositionId,
                    "ReceivedTime": DeviceDelivery.ReceivedTime,
                    "ReceiverDescription": DeviceDelivery.ReceiverDescription,
                    "ReceiverPerson": event.target.ReceiverPerson.value,
                    "DeliveredByPositionId": event.target.DeliveredByPositionId.value,
                    "DeliveredTime": new Date().toISOString(),
                    "DeliverDescription": event.target.DeliverDescription.value,
                    "PermissionExitPositionId": event.target.PermissionExitPositionId.value,
                }
                sendDataAsync(data, "put");
            } else {
                let data = {
                    DeviceDelivery:{
                        "CustomerPositionId": event.target.CustomerPositionId.value,
                        "PhoneToFollowUp": event.target.PhoneToFollowUp.value,
                        "DelivereePerson": event.target.DelivereePerson.value,
                        "ReceivedByPositionId": event.target.ReceivedByPositionId.value,
                        "ReceivedTime":new Date().toISOString(),
                        "ReceiverDescription": event.target.ReceiverDescription.value,
                    },
                    DevicePropertyValues:devicePropertyValue
                }

                sendDataAsync(data, "post");
            }
        
    }
    async function sendDataAsync(data, method) {
        try {
            let res = await postDeviceDelivery(data, method);
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
            onHide();
            reload();
        } catch (err: any) {

        }
    }
    async function getDeviceDeliveryAsync() {
        setLoading(false)
        try {
            if(Id){
                const res = await getDeviceDeliveryById(Id);
             //   setDeviceDeliveryDate(res.Data.DeviceDeliveryDate)
                setDeviceDelivery(res.Data)

            }
        } catch (err: any) {
        }
        setLoading(true)
    }


    function submitDevicePropertyValueList (e:any) {
        setDevicePropertyValue(e);
        setUserChanges({...userChanges , "DevicePropertyValues":e})
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                onHide();

            });
        }else{
            onHide();     
        };
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {

        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {

        if (Id &&(step!=1||mode!="create" )) {
            getDeviceDeliveryAsync();
        }else{
            setLoading(true)
        }
    }, [Id]);

    return (
        <>
      {showProperties && 
      <ModalForm
        mode={mode}
        onHide={() => setShowProperties(false)}
        show={showProperties}
        title="deviceproperties"
      >
        <DevicePropertyValueList
        DevicePropertyValueDataListCallback={(e)=>{  submitDevicePropertyValueList(e)}}
        DevicePropertyValueDataListProp={devicePropertyValue}
        onHide={() => setShowProperties(false)}
        deviceDeliveryId={(step!=1||mode!="create" )? Id:null}
        mode={step > 1 ? "view" : mode}
        />
        <div className="modal-msg__btn-wrapper mt-2"></div>
      </ModalForm>
      }


        {loading ?  
        <>
            <form onSubmit={handleSubmit} className="printForm">
            <div className="m-2">
                <div className={`${(step > 1 ) && "card"} card mb-3 `}>
                    {(step > 1 ) &&
                        <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px", justifyContent: "space-between" }} className="d-flex  py-1 col-12 pr-4">
                            <div>{trans("DeviceReception")} </div>
                            <div style={{ marginLeft: "18px" }} onClick={() => { showStep[1] ? setShowStep({ ...showStep, 1: false }) : setShowStep({ ...showStep, 1: true }) }} >{showStep[1] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" /> : <FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                        </div>
                    }
                  
                        <div style={{display: !showStep[1] ?"block" :"none"}} className="card-body" >
                            <div className="row">
                                {mode != "create" && 
                                    <div className="col">
                                        <DatePicker
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            required={true}
                                            lable="ReceivedTime"
                                            setIsoTime={(isoTime: string) => { setReceivedTime(isoTime); }}
                                            val={ mode=="create" ? new Date().toISOString()  : DeviceDelivery?.ReceivedTime } //resetForm ? new Date().toISOString() : 
                                            resetForm={resetForm}
                                            mode={"view"}
                                        />
                                    </div>
                                }
                                <div className="col">
                                    <CustomerPositionDictionary
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        onChange={setPosition}
                                        name="CustomerPositionId"
                                        required={true}
                                        label="CustomerPosition"
                                        mode={step > 1 ? "view" : mode}
                                        value={mode == "create" ?(null ): DeviceDelivery?.CustomerPositionId}
                                        resetForm={resetForm}

                                    />
                                </div>       
                                <div className="col">
                                    <Textbox
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        textboxType="text"
                                        textboxName="DelivereePerson"
                                        labelText="DelivereePerson"
                                        resetForm={resetForm}
                                        defaultValue={DeviceDelivery?.DelivereePerson}
                                        mode={step > 1 ? "view" : mode}
                                    />
                                </div>
                                <div className="col">
                                    <Textbox
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        textboxType="mobile"
                                        textboxName="PhoneToFollowUp"
                                        labelText="PhoneToFollowUp"
                                        resetForm={resetForm}
                                        defaultValue={DeviceDelivery?.PhoneToFollowUp}
                                        mode={step > 1 ? "view" : mode}
                                    />
                                </div>
                            <div className="col-12 col-sm-3 mb-2 mb-sm-0">
                                <FollowupPositionId
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                    required={true}
                                    name="ReceivedByPositionId"
                                    label="ReceivedByPosition"
                                    mode={step > 1 ? "view" : mode}
                                    value={mode == "create" ? null : DeviceDelivery?.ReceivedByPositionId}
                                    resetForm={resetForm}

                                />
                            </div>
                            </div>
                            <div className="row">
                        <div className="col-12 col-sm-6">
                            <Textbox
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                defaultValue={DeviceDelivery?.ReceiverDescription}
                                textboxName="ReceiverDescription"
                                labelText="ReceiverDescription"
                                textboxType="textarea"
                                minHeight={mode === "view" ? 2 : 3}
                                resetForm={resetForm}
                                mode={step > 1 ? "view" : mode}
                            />
                        </div>   
                        <div className="col mt-4">
                           <Button
                             btnType="primary"
                             value="deviceproperty"
                             onClick={()=>{setShowProperties(true)}}
                           />                    
                        </div>
                        </div>  
                    </div>
                    
                </div>
                {(step == 2 ) &&
                    <div className={`card   `}>
                        <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px", justifyContent: "space-between" }} className="d-flex  py-1 col-12 pr-4">
                            <div>{trans("DeviceRelease")} </div>
                            <div style={{ marginLeft: "18px" }} onClick={() => { showStep[2] ? setShowStep({ ...showStep, 2: false }) : setShowStep({ ...showStep, 2: true }) }}>{showStep[2] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" /> : <FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                        </div>
                        
                        <div style={{display: !showStep[2] ?"block" :"none"}} className="card-body" >
                                <div className="row">

                                <div className="col">
                                <Textbox
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                    textboxType="text"
                                    textboxName="ReceiverPerson"
                                    labelText="ReceiverPerson"
                                    resetForm={resetForm}
                                    defaultValue={DeviceDelivery?.ReceiverPerson}
                                    mode={mode}
                                    />
                                </div>
                                    <div className="col-12 col-sm-4 mb-2 mb-sm-0">
                                        <FollowupPositionId
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            name="DeliveredByPositionId"
                                            label="DeliveredByPosition"
                                            required={true}
                                            mode={mode}
                                            value={DeviceDelivery?.DeliveredByPositionId}
                                            resetForm={resetForm}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <FollowupPositionId
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            required={true}
                                            name="PermissionExitPositionId"
                                            label="PermissionExitPosition"
                                            mode={mode}
                                            value={mode == "create" ?( layoutContext.currentUserPosition.PositionId ): DeviceDelivery?.PermissionExitPositionId}
                                            resetForm={resetForm}
                                        />
                                    </div> 
                                </div>
                                <div className="row"> 
                                <div className="col-12 col-sm-6">
                                        <Textbox
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            defaultValue={DeviceDelivery?.DeliverDescription}
                                            textboxName="DeliverDescription"
                                            labelText="DeliverDescription"
                                            textboxType="textarea"
                                            minHeight={1}
                                            resetForm={resetForm}
                                            mode={'create'}
                                        />
                                    </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="row">
                <div className="d-flex justify-content-end">
                    {mode != "view" ? (
                        <>
                            <Button btnType="submit" value={step > 1 ? "save" : "send"} />
                            <Button
                                btnType="cancel"
                                value="cancel"
                                onClick={() => { cancel() }}
                            />
                        </>
                    ) : (<></>)
                    }
                </div>
            </div>
            </form>
        </>
        :<Loading/>}
        </>
    )
}