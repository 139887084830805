import React, { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import "./style.scss";
export const Loading = () => {

  let langueg = localStorage.getItem("lang");



  return (
    <div className="centered ">
    <div id="" className="spinner loading ">
      <div className="loading-text" style={{marginTop : langueg == "fa" ? "" : "10px"}}>{trans("Loading")}</div>
    </div>
  </div>
  );
};
