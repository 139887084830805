import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { useState } from "react";
import { trans } from "utils/helpers";


interface ItemGroupLeafFormType {
    mode?: any,
    checkData?: any,
    selectedObj:any;
    ItemGroupLeafData:any;
}

export const ItemGroupLeafForm = ({mode,selectedObj,ItemGroupLeafData,checkData}:ItemGroupLeafFormType) => {
    const [resetForm, setResetForm] = useState(false);
    const [descriptions, setDescriptions] = useState<any>({});

    return (<>
            <>
                { (mode != "create" && ItemGroupLeafData)||(mode == "create") ?
                    <div className="card-body">
                        <div className="row">
                        <div className="col-3">
                            <EnumerationTypeSelect
                                name={"ItemType"}
                                value={ItemGroupLeafData?.ItemType}
                                mode={mode}
                                label="ItemType"
                                enumTypeSelect="ItemType"
                                />
                        </div>
                            <div className="col-3">
                                <Textbox
                                    textboxType="text"
                                    textboxName="Code"
                                    UsepadWithZeros={true}
                                    maxLength={+checkData.CodeLength}
                                    defaultValue={(ItemGroupLeafData?.Code ?ItemGroupLeafData?.Code.replace(checkData?.ParentCode,"") : checkData?.Code)  }
                                    fixedlabel={checkData ? " | "+checkData?.ParentCode:checkData?.ParentCode}
                                    resetForm={resetForm}
                                    required={true}
                                    labelText={trans("itemcode") + ( mode !== "create" ? "" : `  (${"طول مجاز"} : ${checkData.CodeLength})`)}
                                    mode={mode }
                                    />
                            </div>
                          
                        </div>
                            <div className="row">

                                <div className="col-3">
                                    <Textbox
                                        required={true}
                                        onBlurHandler={(e)=>{setDescriptions({...descriptions ,summaryDescription:e })}}
                                        textboxType="text"
                                        textboxName="SummaryDescription"
                                        resetForm={resetForm}
                                        defaultValue={ItemGroupLeafData?.SummaryDescription ? ItemGroupLeafData.SummaryDescription : null}
                                        labelText="ItemGroupSummaryDescription"
                                        mode={mode}
                                        />
                                </div>
                                <div className="col-4">
                                <Textbox
                                    required={true}
                                    onBlurHandler={(e)=>{setDescriptions({...descriptions ,Description:e })}}
                                    textboxType="text"
                                    textboxName="Description"
                                    resetForm={resetForm}
                                    defaultValue={ItemGroupLeafData?.Description  ? ItemGroupLeafData?.Description : ((descriptions.Description &&descriptions.Description.length>0 )? descriptions.Description: descriptions.summaryDescription)}
                                    labelText="ItemGroupDescription"
                                    mode={mode}
                                    />
                                </div>

                            </div>
                        <div className="row">
                        <div className="col-3">
                                    <Textbox
                                        onBlurHandler={(e)=>{setDescriptions({...descriptions ,SummaryDescriptionEN:e })}}
                                        textboxType="text"
                                        textboxName="SummaryDescriptionEN"
                                        resetForm={resetForm}
                                        defaultValue={ItemGroupLeafData?.SummaryDescriptionEN ? ItemGroupLeafData.SummaryDescriptionEN : null}
                                        labelText="ItemGroupSummaryDescriptionEN"
                                        mode={mode}
                                        />
                                </div>
                        <div className="col-4">
                                <Textbox
                                    onBlurHandler={(e)=>{setDescriptions({...descriptions ,DescriptionEN:e })}}
                                    textboxType="text"
                                    textboxName="DescriptionEN"
                                    resetForm={resetForm}
                                    defaultValue={ ItemGroupLeafData?.DescriptionEN  ? ItemGroupLeafData?.DescriptionEN : (descriptions.DescriptionEN ? descriptions.DescriptionEN: descriptions.SummaryDescriptionEN)}
                                    labelText="ItemGroupDescriptionEN"
                                    mode={mode}
                                    />
                            </div>
                        </div>

                        <div className="col-3">
                            <Textbox
                                textboxType="text"
                                textboxName="DescriptionByCodeFormat"
                                fixedLanguage={"en"}
                                resetForm={resetForm}
                                defaultValue={ItemGroupLeafData?.DescriptionByCodeFormat ? ItemGroupLeafData.DescriptionByCodeFormat : null}
                                labelText="DescriptionByCodeFormat"
                                mode={mode}
                                capsLock={true}
                                />
                        </div>
                       
                    </div>
                    :
                    <Loading/>
                }
            </>

        </>
    );;
}
 