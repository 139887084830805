import React from "react";
import { Link } from "react-router-dom";

export const NotFoundPage = () => {
  //TODO ما باید یه اررور لیوت برای کل اررور ها داشته باشیم و این رو از اون استفاده بکنیم
  return (<div>
    <div>
      page not found
    </div>

    <Link to={"/"}>Home</Link>
  </div>)
}