
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { TableTree } from "components/TableTree/TableTree";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { trans } from "utils/helpers";
import { canAddChildToOrganization, deleteNode, getFully } from './api/api';
import { OrganizationForm } from "./components/OrganizationForm/OrganizationForm";
import { OrganizationRoles } from "./components/OrganizationRoles/OrganizationRoles";
import { PositionForm } from "./components/PositionForm/PositionForm";
import './style.scss';

export const OrganizationalStructure = () => {
  const [showOrganizationForm, setShowOrganizationForm] = useState(false)
  const [showPositionForm, setShowPositionForm] = useState(false)
  const [showRoleForm, setShowRoleForm] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [defultShow, setDefultShow] = useState(true)
  const [Fullydata, setFullydata] = useState<any>()
  const [selectedObj, setSelectedObj] = useState<any>(null)
  const [idCallback, setIdCallback] = useState<any>("2")
  const [mode, setmode] = useState<any>()
  const [messageType, setMessageType] = useState<any>("info")

  let theadData: any = []
  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "tree":setSelectedObj(null);getFullyAsync();setDefultShow(!defultShow);break;
      case "view": if (selectedObj){ setmode("view"); setShowOrganizationForm(!showOrganizationForm);} else { Toast(trans("Msg_SelectRow"), "warning") }; break;
      case "edit": if (selectedObj){ setmode("edit"); setShowOrganizationForm(!showOrganizationForm);} else { Toast(trans("Msg_SelectRow"), "warning") }; break;
      case "print":alert("print ");break;
      case "remove":if (selectedObj) { Toast(trans("msg_deleteconfirm"), "warning", "alert", deletListAsync) }else { Toast(trans("Msg_SelectRow"), "warning") }; break;
      // case "excel":alert("excel ");break;
      case "refresh":setForceUpdate(!forceUpdate);break;
      case "create": if (selectedObj) { setmode("create"); canAddChildTo(selectedObj) } else { Toast(trans("Msg_SelectRow"), "warning") }; break;
      case "persons": if (selectedObj){ setmode("edit");setShowPositionForm(!showOrganizationForm);}else { Toast(trans("Msg_SelectRow"), "warning") }; break;
      case "assignrule": if (selectedObj){setmode("edit"); setShowRoleForm(!showRoleForm);} else { Toast(trans("Msg_SelectRow"), "warning") }; break;
    }
  };
  const handleViewModalForm = () => {
    setIdCallback(selectedObj.Id)
    setShowOrganizationForm(false)
    setShowPositionForm(false)
    setIdCallback(" ")

  }
  async function deletListAsync() {
    try {
       await deleteNode(selectedObj.Id)
      setForceUpdate(!forceUpdate)
    } catch (err: any) {

    }

  }

  async function moveNudeAsync(drag, drop) {
    try {
     // const response = await moveNude(drag, drop)
     // setReload(!reload)
     // return true

    } catch (err: any) {

    }

  }

  const canAddChildTo = async(obj) => {
    try {
      const can: any =await canAddChildToOrganizationAsync(obj.Id)
      setShowOrganizationForm(!showOrganizationForm)
    } catch (error) {
      Toast("Msg_CanNotAddChildForThisNode", "error")
    }
  
  }
  async function getFullyAsync() {
    setLoading(false)
    try {
      const respons = await getFully()
      setFullydata(respons.Data)
    } catch (error) {
      setMessageType("error")
    }

    setLoading(true)
  }

  async function canAddChildToOrganizationAsync(id) {
    try {
      const respons = await canAddChildToOrganization(id)
      return respons
    } catch (error) {
        return false
    }
  }

  useEffect(() => {
    setShowOrganizationForm(false)
    setSelectedObj(null)
    getFullyAsync()
  }, [forceUpdate]);

  if ( Fullydata) {
    theadData = Object.keys(Fullydata[0])
  }



  return (
    <>
      {showOrganizationForm ? <ModalForm modalHeight={40} mode={mode} onHide={handleViewModalForm} show={showOrganizationForm} modalProfileKey={"Position"} title={mode!="create"?trans(mode)+" "+ selectedObj?.Description:  trans("Position") + " " + trans(mode)} ><OrganizationForm mode={mode} selectedObj={selectedObj} showForm={handleViewModalForm} /> </ModalForm> : null}
      {showPositionForm ? <ModalForm mode={mode} modalHeight={50} onHide={handleViewModalForm} show={showPositionForm} modalProfileKey={"Users"}   title={trans("Assignment") + " " + trans("Position") + " " + selectedObj?.Description} ><PositionForm selectedObj={selectedObj} /> </ModalForm> : null}
      {showRoleForm ? <ModalForm mode={mode} modalHeight={60} onHide={() => { setShowRoleForm(!showRoleForm) }} show={showRoleForm} modalProfileKey={"Users"}  title={trans("AssignedAccessRoles") + " : " + selectedObj.Description} ><OrganizationRoles selectedObj={selectedObj} /> </ModalForm> : null}
      <Toolbar excel={false}   assignRule={true} emptyList={theadData?.length == 0} id={selectedObj}paste={false} create={true} persons={true} remove={true} edit={true}  handleClickToolbar={clickToolbarCallback} />
      <div style={{overflowY: "auto", 
          height: "74vh",
           scrollbarWidth: "thin"}} className="col-lg-12 mt-0">
        <div className="list-card card  mt-3">
            <>
              {
                loading ? (
                  Fullydata ?
                    <TableTree
                      dragDrop={false}
                      selectedRowObjectCallback={setSelectedObj}
                      moveNude={moveNudeAsync}
                      selectedRowObject={idCallback}
                      getChildrenUrl={ENDPOINTS.BaseInformation.organization.getNode}
                      Data={Fullydata}
                      theadData={theadData}
                      parentName={"OrganizationalStructure"}
                      startIndex={9}
                      defultShow={true}
                    />
                    : <Message message={"NoDataForPreview"} type={messageType} />
                )
                  : <Loading />
              }
            </>
        </div>
      </div>
    </>
  )
}