
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getActions = (PageNumber: number, PageSize: number, ReferenceId: any,orderBy,filterlist ,SearchString) => http({
    url: ENDPOINTS.cartable.action.browse,
    method: 'get',
    params: {
        pageNumber: PageNumber,
        pageSize: PageSize,
        referenceId: ReferenceId,
        OrderByString:orderBy,
        FilterString:filterlist,
        SearchString:SearchString,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const DeleteAction = (id) => http({
    url: ENDPOINTS.cartable.action.browse,
    method: 'delete',
    params: {
        Id: id,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const SetExecutiveTermination = (referenceId: any) => http({
    url: ENDPOINTS.cartable.job.load,
    method: 'put',
    params: {
        referenceId: referenceId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});


export const getDynamicForm = (referenceId: any) => http({
    url: ENDPOINTS.cartable.getDynamicForms,
    method: 'get',
    params: {
        referenceId: referenceId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const getAction = (id: any) => http({
    url: ENDPOINTS.cartable.action.load,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        Id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});



export const sendAction = (data: string, method) => http({
    method: method,
    url: ENDPOINTS.cartable.action.browse,
    data: data
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});


