import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getPersonApi = (async (search, id) => await http({

    url: id ?  ENDPOINTS.BaseInformation.person.dictionaryId :  ENDPOINTS.BaseInformation.person.dictionary,
    method: 'get',
    params: {
        SearchString: search,
        Id: id
    }
})
    .then((respons) => (respons.data.Data))
)
export const getPersonGrids = (async (pageNum,  pageSize  ,searchString ,orderBy,filterlist) => await http({
    url: ENDPOINTS.BaseInformation.person.all,
    method: 'get',
    params:{
        PageNumber: pageNum,
        PageSize: pageSize,
        OrderByString:orderBy,
        SearchString : searchString ,
        FilterString:filterlist,
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)