import { Widget } from 'components/Widget/Widget';
import { LayoutContext } from 'layouts/DashboardLayout/context/LayoutContext';
import { getBords } from 'pages/DashboardPage/api/api';
import { useContext, useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { trans } from 'utils/helpers';

import { ModalForm } from 'components/ModalForm/ModalForm';
import './style.scss';
import { WidgetBoardDetail } from './WidgetBoardDetail';
import { GUID_EMPTY } from 'utils/constants';


interface props {
    id: string
}


export const WidgetBoard = ({id}:props) => {
    const [index, setIndex] = useState(0);
    const [pages, setPages] = useState(1);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(3);
    const [response, setResponse] = useState<any>();
    const [bordSelected, setBordSelected] = useState<any>();
    const [showBord, setShowBord] = useState<any>(false);
    const layoutContext = useContext(LayoutContext);




    async function getBordsAsync(pageSi = pageSize){
        try {
            const res = await getBords(pageSi ,pageNum)
            setPages( res.Data.TotalRecords > pageSi ? Math.ceil(res.Data.TotalRecords / pageSi) : 1)
            setResponse(res.Data.Data)
            
        } catch (error) {
            
        }
    }
    async function startBords(){
        try {
         const size =    await  getWidth()
          getBordsAsync(size)
        } catch (error) {
            
        }
    }

    function getWidth(){
        let size = 0
        if(!layoutContext.sidebarIsOpen){
            if(window.innerWidth > 1440 ){
                setPageSize(4)
                size = 4
            }else if(window.innerWidth > 1076){
                setPageSize(3)
                size = 3
            }else if(window.innerWidth > 753){
                setPageSize(2)
                size = 2
            }else{
                setPageSize(1)
                size = 1
            }
        }else{
            if(window.innerWidth > 1753 ){
                setPageSize(4)
                size = 4
            }else if(window.innerWidth > 1347){
                setPageSize(3)
                size = 3
            }else if(window.innerWidth > 753){
                setPageSize(2)
                size = 2
            }else{
                setPageSize(1)
                size = 1
            }
        }
        return size
    }
    
  window.onresize = () => {
    getWidth()
  } 

  useEffect(() => {
    getWidth()
}, [ layoutContext.sidebarIsOpen]);
  useEffect(() => {
    startBords()

}, [ pageNum]);


    const handleSelect = (selectedIndex) => {
        if(selectedIndex.target.className.includes("next")){
            if(pageNum>1){
                setPageNum(pageNum-1) 
            }else{
                setPageNum(pages) 
            }
        }else if(selectedIndex.target.className.includes("prev")){
            if(pageNum<pages){
                setPageNum(pageNum+1) 
            }else{
                setPageNum(1) 
            }
        }else{
            if(selectedIndex.target.getAttribute("aria-label")?.includes("Slide")){
                setPageNum(+selectedIndex.target.getAttribute("aria-label").replace("Slide ","")) 
            }
        }
      return false
    };

    return (
        <>
        {(response&&response[0]?.Id !=GUID_EMPTY)&&
        <Widget title='Board'  fullScreen={true} icon={['fas', 'object-group']} color='primary' id={id} >
            <div  className='p-2 p-md-2 h-100'>
                <Carousel nextLabel variant={"dark"} activeIndex={pageNum-1} interval={null} indicators={true} onClick={handleSelect} className="widget-carousel w-100 position-relative" style={{height:'95%'}}>
                        <Carousel.Item key={0}>
                            <div className='widget-carousel__card d-flex mx-0 mx-md-1 px-3 pb-1 '>
                                {response && response.map((i, index) => (
                                    <>
                                    {(index < pageSize && i.Id !=GUID_EMPTY) && 
                                        <div key={i.Id} className='card widget-carousel__card-items mx-1 mx-md-2 p-1 p-md-2 h-100 d-flex showDotsTowLine'>
                                            <h6 className='showDotsTowLine m-1 font-size-xl' style={{height:"fit-content"}}> {i.Title } </h6>
                                            <p style={{ overflow:"hidden",display:"flex" , flexDirection:"column",flex:1, width:"100%",margin:"0"}} data-toggle="tooltip" data-placement="top" title={i.Title}  >
                                                <div style={{width: "100%" , height: "100%", display:"inline"}}>
                                               {i.Thumbnail &&<div style={{width:"30%",maxWidth:"150px", height:"inherit",float:"left", marginRight:"5px"}}> <img style={{width:"100%", height:"100%" ,objectPosition: "0% 0%"}} src={'data:image/png;base64,' +i.Thumbnail} alt={``} /> </div>}            
                                                   <div style={{height: "98%",maxHeight:'90%',WebkitLineClamp:response.length > 1?'4':'5'}} className='widget-carousel__textBox' dangerouslySetInnerHTML={{__html:i.Description}}></div>
                                                </div>
                                            </p>
                                                <a className='font-size-md' style={{verticalAlign:"bottom", marginTop: "auto",cursor:"pointer" , float:"left", direction : "ltr",width:"100%",textDecoration:"none"}} onClick={()=>{setBordSelected(i);setShowBord(true)}}>{trans("ReadMore")}</a>
                                        </div>
                                    } 
                                    </>
                                    ))
                                }
                            </div> 
                        </Carousel.Item>
                        {pages>1&& new Array(pages-1).fill("item").map((item,CarIndex)=>(

                            <Carousel.Item key={CarIndex+1}>
                            <div className='widget-carousel__card d-flex mx-0 mx-md-1 px-3 pb-1'>
                            {response && response.map((i, index) => (
                                    <>
                                    {(index < pageSize && i.Id !=GUID_EMPTY) && 
                                        <div key={i.Id} className='card widget-carousel__card-items mx-1 mx-md-2 p-1 p-md-2'>
                                            <h6 className='showDots' style={{height:"10%"}}> {i.Title } </h6>
                                            <p style={{ overflow:"hidden",display:"flex" , flexDirection:"column",height: "75%", width:"100%",margin:"0"}} data-toggle="tooltip" data-placement="top" title={i.Title}  >
                                                <div style={{width: "100%" , height: "100%", display:"inline"}}>
                                               {i.Thumbnail &&<div style={{width:"30%",maxWidth:"150px", height:"inherit",float:"left", marginRight:"5px"}}> <img style={{width:"100%", height:"100%" ,objectPosition: "0% 0%"}} src={'data:image/png;base64,' +i.Thumbnail} alt={``} /> </div>}            
                                                   <div style={{height: "98%",maxHeight:'98%'}} dangerouslySetInnerHTML={{__html:i.Description}}></div>
                                                </div>
                                            </p>
                                            <a style={{verticalAlign:"bottom", marginTop: "auto",cursor:"pointer" , float:"left", direction : "ltr",width:"100%",textDecoration:"none"}} onClick={()=>{setBordSelected(i);setShowBord(true)}}>{trans("ReadMore")}</a>
                                        </div>
                                    }
                                    </>
                                    ))
                                }
                            </div>
                            </Carousel.Item>
                        ))}

                </Carousel>
            </div>
        </Widget>
        }
        {showBord && 
                <ModalForm modalHeight={63} mode={"view"} title="Board" onHide={() => setShowBord(false)} show={showBord}>
                        <WidgetBoardDetail bordData={bordSelected}/>                       
                </ModalForm>
        
        }
        </>
    )
}