import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { TableTree } from "components/TableTree/TableTree";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { REPORT_URL } from "utils/constants";
import { ENDPOINTSREPORT } from "utils/endpoints";
import { getReports , deleteReport} from './api';
import { WithFeedForm } from "./components/WithFeed";
import { trans } from "utils/helpers";
import { Toast } from "components/ToastShow/ToastShow";
import { FilterReportForm } from "layouts/DashboardLayout/components/Sidebar/component/FilterReportForm";

export const Reports   = () => {
  const [data, setData] = useState<any>()
  const [forceUpdate, setForceUpdate] = useState(false)
  const [selectedObj, setSelectedObj] = useState<any>()
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState<any>("info");
  const [search, setsearch] = useState(null);
  const [idCallback, setIdCallback] = useState<any>("2")
  const [mode, setMode] = useState<any>(null)
  const [showForm, setShowForm] = useState<any>(null)
  const [filterReportId, setFilterReportId] = useState<any>(null);

  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "print":alert("print ");break;
      case "refresh":setForceUpdate(!forceUpdate);break;
      case "create":setMode("create"); setShowForm(true);break;
      case "design": if (selectedObj?.Id) handleDesignRow(); break;
      case "edit": if (selectedObj?.Id) handleEditRow(); break;
      case "remove": if (selectedObj?.Id) Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
    }
  };

  const handleDesignRow = () => {
    setFilterReportId(selectedObj )
   // window.open(`${process.env.REACT_APP_REPORTING_SERVICE_URL+ "/ReportDesigner/Index/"}${selectedObj.Id }`,"_blank" );  
}
  const handleEditRow = () => {
    setMode("edit")
    setShowForm(true)

}

  const handleDeleteRow =async () => {
    try {
        await deleteReport(selectedObj.Id)
        setForceUpdate(!forceUpdate)
    } catch (error) {
        
    }

}

  async function getMenuAsync() {
    setLoading(false)
    try {
      const respons = await getReports(null)
      setData(respons.Data)
    } catch (error) {
      setMessageType("error")
    }
    setLoading(true)

  }


  useEffect(() => {
    setData(null)
    getMenuAsync()
  }, [forceUpdate]);
  useEffect(() => {
    if(idCallback){
      setIdCallback(null)
    }
  }, [idCallback]);

  return (
    <>

{
        filterReportId &&
          <ModalForm mode={"edit"} title={trans("Report")+" : " +filterReportId?.Name}onHide={() => setFilterReportId(null)} show={filterReportId} modalHeight={60}>
              <FilterReportForm Id={filterReportId?.Id}  onHide={() => setFilterReportId(null)} mode={"edit"} />
          </ModalForm>
      }
      {
        showForm &&
          <ModalForm mode={mode} title="Report" onHide={() => setShowForm(false)} show={showForm} modalHeight={60}>
              <WithFeedForm 
                onHide={() => setShowForm(false)}
                saveCallback={(e)=>{ setFilterReportId(e )  ;setIdCallback(e)}}
                selectedObj={selectedObj} 
                mode={mode} 
              />
          </ModalForm>
      }


      <Toolbar excel={false} design={(selectedObj&&selectedObj?.ReportTree==null)}  create={true}  view={false} remove={(selectedObj&&selectedObj?.ReportTree==null)} edit={(selectedObj&&selectedObj?.ReportTree==null)} emptyList={data?.length == 0} id={selectedObj} Tree={false}  search={true}  handleClickToolbar={clickToolbarCallback} />
      <div style={{overflowY: "auto", 
          height: "72vh",
           scrollbarWidth: "thin"}} className="col-lg-12 mt-3 ">
        <div className="list-card card ">
            
          {
            loading ? (
              data ?
              <>    
                <TableTree
                  search={search}
                  maxLevel={1}
                  params={"Scope"}
                  IdParams={"Scope"}
                  Childrenparams={"ReportTree"}
                  Titleparams={"Name"}
                  selectedRowObjectCallback={setSelectedObj}
                  selectedRowObject={idCallback}
                  Data={data}
                  theadData={Object.keys(data[0])}
                  parentName={"Reports "}
                  startIndex={2}
                  getChildrenUrl={ENDPOINTSREPORT.Report.tree}
                  baseUrl={REPORT_URL}

                  />
                  </>
                 : <Message message={"NoDataForPreview"} type={messageType} />)

              : <Loading />
          }
        </div>
      </div>
    </>
  )
}