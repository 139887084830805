import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {  useEffect, useRef, useState } from "react"
import { trans } from "utils/helpers"

interface ButtonToolbarCreateitem {
    onClick?: (e) => void,
    Callback?: (id: any) => void,
    id?,
    type?,


    
}
export const ButtonToolbarCreateitem: React.FC<ButtonToolbarCreateitem> = ({type, id, onClick, Callback }) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    const buttonToolbarProcessStarting = useRef(null);
 //   const [options, setOptions] = useState<any>([{Id:1  , Name :`/${trans("TreeBranch")}` },{Id:2  , Name :`/${trans("TreeRoot")}`}])
    const [selectoptions, setSelectOptions] = useState<any>()
    const [count, setCount] = useState<any>();
    const showSubMenuRef = useRef<any>(false);


    useEffect(() => {
        
        let flag = true
        const callback = (event: KeyboardEvent) => {
             event.stopPropagation()
            if("F4" == event.key && type==2  ){   
                event.preventDefault()      
                onClick && onClick(2)
            }  
            if("F6" == event.key  && type==1 ){  
                event.preventDefault()           
                onClick && onClick(1)
            }  
            flag = true
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, [id]);

    return (
        <>
          <div className="toolbar-btn-wrapper">
            <button ref={buttonToolbarProcessStarting} onClick={() => {onClick&& onClick(type) }} className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
                <div className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>{type==2 ? "F4":"F6"}</div>
                <FontAwesomeIcon icon={['fas', 'plus']} width="20px" height="20px" />
                <small>{trans("add")+ (type==1 ? ` ${trans("TreeBranch")}` : ` ${trans("TreeRoot")}`)}</small>

            </button>
        </div>
        

        </>
    )
    }