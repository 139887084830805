import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Address } from "components/Address/Address";
import { Button } from "components/Button/Button";
import Datepicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { ContactType } from "types/enum";
import { ICON_TYPE, INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { convertAddress } from "utils/helpers";

import gregorian from "react-date-object/calendars/gregorian";
import persian from "react-date-object/calendars/persian";
import { DateObject } from "react-multi-date-picker";
import {
  getDefaultPersonContact,
  getPersonCustomer,
} from "../SalesInvoice/api";
import { AddressBox } from "../SalesInvoice/components/AddressBox/AddressBox";
import { CustomerDictionary } from "../SalesInvoice/components/CustomerDictionary/CustomerDictionary";
import { CustomerPhoneDictionary } from "../SalesInvoice/components/CustomerPhoneDictionary/CustomerPhoneDictionary";
import { CustomerPhoneList } from "../SalesInvoice/components/CustomerPhones/CustomerPhoneList";
import {
  getCustomerInfo,
  putNationalEcoCode,
  putSalesInvoiceDictionary,
} from "./api";

interface SalesEditFormProps {
  onHide: (e: boolean) => void;
  relod: () => void;
  rowObject?: any;
  editType?: any;
  mode: string;
}

export const SalesEditForm = ({
  onHide,
  relod,
  editType,
  rowObject,
  mode,
}: SalesEditFormProps) => {
  const [showCustomerPhoneForm, setShowCustomerPhoneForm] = useState(false);
  const [typePhoneContact, setTypePhonContact] = useState<any>();
  const [showAddressBox, setShowAddressBox] = useState<
    "createMode" | "selectMode" | null
  >(null);
  const dateObject = new DateObject({
    date: rowObject.SalesInvoiceDate,
    format: "YYYY/MM/DD HH:mm:ss",
    calendar: persian,
  });
  const [date, setDate] = useState<any>(dateObject);
  const cottageDateObject = new DateObject({
    date: rowObject.CottageDate,
    format: "YYYY/MM/DD HH:mm:ss",
    calendar: persian,
  });
  const [cottageDate, setCottageDate] = useState<any>(cottageDateObject);

  const [salesInvoiceStatusId, setSalesInvoiceStatusId] = useState<any>();
  const [userChanges, setUserChanges] = useState<any>();
  const [customer, setCustomer] = useState<any>({});
  const [defaultCustomerInfo, setDefaultCustomerInfo] = useState<any>();
  const [changeAddress, setChangeAddress] = useState<any>();
  const [loading, setLoading] = useState<any>(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let newData = {} as any;
    let endPoint = "";
    let queryString: any = {
      id: rowObject.Id,
    };

    switch (editType) {
      case "EditInvoiceCustomerInformation":
        {
          newData = {
            CustomerName: customer.CustomerName
              ? customer.CustomerName
              : rowObject.CustomerName,
            CustomerPhone: customer.CustomerPhone
              ? customer.CustomerPhone.Name?.split(":")[1]
              : defaultCustomerInfo.CustomerPhone,
            CustomerMobilePhone: customer.CustomerMobilePhone
              ? customer.CustomerMobilePhone.Name?.split(":")[1]
              : defaultCustomerInfo.CustomerMobilePhone,
            CustomerAddress: event.target.Address?.value,
            CustomerEconNo: event.target.CustomerEconNo?.value,
            CustomerNationalCode: event.target.CustomerNationalCode?.value,
            DeliveryAddress: event.target.DeliveryAddress?.value,
          };
          endPoint =
            ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.invoiceCustomer;
        }
        break;
      case "EditInvoiceDate":
        {
          endPoint = ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.invoiceDate;
          queryString = { ...queryString, salesInvoiceDate: date };
        }
        break;
      case "EditInvoiceNo":
        {
          endPoint = ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.invoiceNo;
          queryString = {
            ...queryString,
            invoiceNo: event.target?.editinvoiceno?.value,
          };
        }
        break;
      case "EditInvoiceStatus":
        {
          endPoint = ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.EditInvoiceStatus;
          queryString = {
            ...queryString,
            SalesInvoiceStatusId: salesInvoiceStatusId,
          };
        }
        break;
      case "EditInvoiceCottage":
        {
          endPoint =
            ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.invoiceCottage;
          queryString = {
            ...queryString,
            cottageNo: event.target?.cottageno?.value,
            cottageDate: cottageDate,
          };
        }
        break;
    }

    try {
      putSalesInvoiceDictionary(endPoint, newData, queryString);
      if (editType === "EditInvoiceCustomerInformation") {
        if(event.target.CustomerEconNo?.value.length >0  ||event.target.CustomerNationalCode?.value.length >0  ){
          const nationalQuery = {
            ecoCode: event.target.CustomerEconNo?.value,
            nationalCode: event.target.CustomerNationalCode?.value,
            personId: rowObject?.CustomerPersonId,
          };
          putNationalEcoCode(nationalQuery);
        }
      }
      onHide(true);
      relod();
    } catch (error) {}
  };


  function cancel() {
    onHide(false);
  }



  const getCustomerInfoAsync = async () => {
    setLoading(false);
    try {
      const res = await getCustomerInfo(rowObject.Id);
      setDefaultCustomerInfo(res.Data);
    } catch (error) {}
    setLoading(true);
  };

  useEffect(() => {
    if (rowObject.Id && editType === "EditInvoiceCustomerInformation") {
      getCustomerInfoAsync();
    }
  }, []);

  return (
    <>
      {/* dynamic form Modals */}

      {showCustomerPhoneForm && (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowCustomerPhoneForm(false);
          }}
          show={showCustomerPhoneForm}
          title={"CustomerPhoneForm"}
        >
          <CustomerPhoneList
            personId={
              defaultCustomerInfo?.CustomerPersonId
                ? defaultCustomerInfo?.CustomerPersonId
                : rowObject.CustomerPersonId
            }
            onHide={() => {
              setShowCustomerPhoneForm(false);
            }}
            type={typePhoneContact}
          />
        </ModalForm>
      )}

      {showAddressBox && (
        <ModalForm
          modalHeight={49}
          onHide={() => {
            setShowAddressBox(null);
          }}
          mode={mode}
          show={showAddressBox && true}
          title={"Address"}
        >
          <AddressBox
            personId={
              defaultCustomerInfo?.CustomerPersonId
                ? defaultCustomerInfo?.CustomerPersonId
                : rowObject.CustomerPersonId
            }
            onHide={() => {
              setShowAddressBox(null);
            }}
            status={showAddressBox}
            AddressHandeler={(e) => {
              setChangeAddress(JSON.stringify(e).toLowerCase());
              setDefaultCustomerInfo({
                ...defaultCustomerInfo,
                Address: JSON.stringify(e).toLowerCase(),
              });
              setShowAddressBox(null);
            }}
          />
        </ModalForm>
      )}
      {/* ////////////////////////// */}
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-body  ">
            {editType == "EditInvoiceCottage" && (
              <div className="row">
                <div className="col-11 col-md-3">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="cottageno"
                    labelText="cottageno"
                    defaultValue={
                      rowObject?.CottageNo ? rowObject?.CottageNo : null
                    }
                    maxLength={15}
                    mode={mode}
                  />
                </div>
                <div className="col-11 col-md-3">
                  <Datepicker
                    type="date"
                    lable="cottageDate"
                    setIsoTime={(isoTime: string) => {
                      setCottageDate(isoTime);
                    }}
                    val={cottageDateObject.convert(gregorian).format()}
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                  />
                </div>
              </div>
            )}
            {/* customerDectionary--------------------------------------------------------------- */}
            {editType == "EditInvoiceCustomerInformation" &&
              (loading ? (
                <div className="row">

                  <div className="col-11 col-md-3">
                    <CustomerDictionary
                      name="CustomerName"
                      label="CustomerName"
                      returnObj={true}
                      required={true}
                      value={customer.Id ? customer.Id : rowObject?.CustomerId}
                      onChange={(e) => {
                        setCustomer({
                          ...customer,
                          CustomerId: e.Id,
                          CustomerName: e.Name,
                        });
                        //getDefaultPersonContactAsync(e.Id);
                      }}
                      mode="view"
                    />
                  </div>

                  {!defaultCustomerInfo?.IsSundry ?
                  <>
                  <div className="col-11 col-md-3">
                    <CustomerPhoneDictionary
                      type={ContactType.Phone}
                      name="CustomerPhone"
                      label="CustomerPhone"
                      returnObj={true}
                      personId={
                        defaultCustomerInfo?.CustomerPersonId
                          ? defaultCustomerInfo?.CustomerPersonId
                          : rowObject.CustomerPersonId
                      }
                      onChange={(e) => {
                        setCustomer({ ...customer, CustomerPhone: e });
                      }}
                      valueString={defaultCustomerInfo?.CustomerPhone}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "25px",
                      width: "17px",
                      marginRight: "-25px",
                    }}
                    className="ms-md-4"
                  >
                    <Button
                      Icon={true}
                      value={
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setShowCustomerPhoneForm(true);
                            setTypePhonContact(ContactType.Phone);
                          }}
                          className="checkButton d-flex align-items-center justify-content-center"
                        >
                          {" "}
                          <FontAwesomeIcon
                            icon={[ICON_TYPE, "plus"]}
                            size="2x"
                          />
                        </button>
                      }
                      btnType="primary"
                    />
                  </div>
                  </>
                  :
                  <div className="col-11 col-lg-2 d-flex">
                  <Textbox
                      onChange={(e) => {
                        setCustomer({ ...customer, CustomerPhone: e });
                      }}
                    textboxType="text"
                    textboxName="CustomerPhone"
                    labelText="CustomerPhone"
                    defaultValue={defaultCustomerInfo?.CustomerPhone }
                    mode={mode}
                  />
                  </div>
                  }

                  {!defaultCustomerInfo?.IsSundry ?
                  <>
                  <div className="col-11 col-md-3">
                    <CustomerPhoneDictionary
                      type={ContactType.Mobile}
                      name="CustomerMobilePhone"
                      label="CustomerMobilePhone"
                      valueString={defaultCustomerInfo?.CustomerMobilePhone}
                      returnObj={true}
                      personId={
                        defaultCustomerInfo?.CustomerPersonId
                        ? defaultCustomerInfo?.CustomerPersonId
                        : rowObject.CustomerPersonId
                      }
                      onChange={(e) => {
                        setCustomer({ ...customer, CustomerMobilePhone: e });
                      }}
                    />
                  </div>

                  <div
                    style={{
                      marginTop: "25px",
                      width: "17px",
                      marginRight: "-25px",
                    }}
                    className="ms-md-4"
                  >
                    <Button
                      Icon={true}
                      value={
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setShowCustomerPhoneForm(true);
                            setTypePhonContact(ContactType.Mobile);
                          }}
                          className="checkButton d-flex align-items-center justify-content-center"
                        >
                          {" "}
                          <FontAwesomeIcon
                            icon={[ICON_TYPE, "plus"]}
                            size="2x"
                          />
                        </button>
                      }
                      btnType="primary"
                    />
                  </div></>:
                  <div className="col-11 col-lg-2 d-flex">
                  <Textbox
                      onChange={(e) => {
                        setCustomer({ ...customer, CustomerMobilePhone: e });
                      }}
                    textboxType="text"
                    textboxName="CustomerMobilePhone"
                    labelText="CustomerMobilePhone"
                    defaultValue={defaultCustomerInfo?.CustomerMobilePhone }
                    mode={mode}
                  />
                  </div>
                    }


                  <div className="col-11 col-md-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="CustomerNationalCode"
                      textboxType="nationalCode"
                      labelText="CustomerNationalCode"
                      defaultValue={
                        defaultCustomerInfo?.CustomerNationalCode
                          ? defaultCustomerInfo?.CustomerNationalCode
                          : null
                      }
                      mode={
                        defaultCustomerInfo?.CustomerNationalCode &&
                        defaultCustomerInfo?.CustomerNationalCode != " "
                          ? "view"
                          : mode
                      }
                    />
                  </div>
                  <div className="col-11 col-md-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxType="text"
                      textboxName="CustomerEconNo"
                      labelText="CustomerEconNo"
                      defaultValue={
                        defaultCustomerInfo?.CustomerEconNo
                          ? defaultCustomerInfo?.CustomerEconNo
                          : null
                      }
                      mode={defaultCustomerInfo?.CustomerEconNo ? "view" : mode}
                    />
                  </div>

                  {!defaultCustomerInfo?.IsSundry ?
                  <>
                  <div className="col-10 col-md-3">
                    <Address
                      onChange={(e) => {
                        setShowAddressBox("selectMode");
                        return false;
                      }}
                      withMap={false}
                      hideDetail={true}
                      personContact={convertAddress(
                        defaultCustomerInfo?.CustomerAddress
                      )}
                      mode={"view"}
                    />
                  </div>
                  <div className="col-auto mt-4 text-end p-0">
                    <Button
                      Icon={true}
                      value={
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setShowAddressBox("createMode");
                          }}
                          className="checkButton d-flex align-items-center justify-content-center"
                        >
                          {" "}
                          <FontAwesomeIcon
                            icon={[ICON_TYPE, "plus"]}
                            size="2x"
                          />
                        </button>
                      }
                      btnType="primary"
                    />
                  </div>
                  <div className="col-auto mt-4 text-end p-0">
                    <Button
                      Icon={true}
                      value={
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setShowAddressBox("selectMode");
                          }}
                          className="checkButton d-flex align-items-center justify-content-center"
                        >
                          {" "}
                          <FontAwesomeIcon
                            icon={[ICON_TYPE, "search"]}
                            size="2x"
                          />
                        </button>
                      }
                      btnType="primary"
                    />
                  </div>
                  </>
                  : 
                  <div className="col-lg-5 col-11 d-flex ">
                  <Textbox
                    onChange={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxName="Address"
                    textboxType="text"
                    labelText="Address"
                    defaultValue={ defaultCustomerInfo?.CustomerAddress  }
                    mode={mode == "view" ? "view" : mode}
                  />
                </div>
                  }



                  <div className="col-11 col-md-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxType="text"
                      textboxName="DeliveryAddress"
                      labelText="DeliveryAddress"
                      defaultValue={
                        defaultCustomerInfo?.DeliveryAddress
                          ? defaultCustomerInfo?.DeliveryAddress
                          : null
                      }
                    />
                  </div>
                </div>
              ) : (
                <Loading />
              ))}
            {/* DatePicker---------------------------------------------------------------------------------- */}
            {editType == "EditInvoiceDate" && (
              <div className="col-11 col-md-4">
                <Datepicker
                  lable="Date"
                  setIsoTime={(IsoTime) => {
                    setDate(IsoTime);
                  }}
                  val={dateObject.convert(gregorian).format()}
                  Change={(e) => {
                    setUserChanges({ ...userChanges, ...e });
                  }}
                />
              </div>
            )}
            {/* invoiceNumber--------------------------------------------------------------------------- */}
            {editType == "EditInvoiceNo" && (
              <div className="col-11 col-md-4">
                <Textbox
                  Change={(e) => {
                    setUserChanges({ ...userChanges, ...e });
                  }}
                  textboxType="number"
                  textboxName="editinvoiceno"
                  labelText="editinvoiceno"
                  defaultValue={
                    rowObject.SalesInvoiceNo ? rowObject.SalesInvoiceNo : null
                  }
                  min={0}
                />
              </div>
            )}
            {/* invoiceStatus--------------------------------------------------------------------------- */}
            {editType == "EditInvoiceStatus" && (
              <div className="col-11 col-md-3">
                <DictionarySelectBox
                  Change={(e) => {
                    setSalesInvoiceStatusId(e.SalesInvoiceStatus);
                  }}
                  required={true}
                  onChange={(id, obj) => {
                    setSalesInvoiceStatusId(obj?.Id);
                  }}
                  baseUrl={INVENTORYANDSALES_URL}
                  endPoint={
                    ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
                      .SalesInvoiceStatus.dictionary
                  }
                  label="SalesInvoiceStatus"
                  name="SalesInvoiceStatus"
                  value={rowObject?.SalesInvoiceStatusId}
                />
              </div>
            )}
            {/*--------------------------------------------------------------------------- */}

            <div className="col-lg-12 mt-1 btns_form">
              <Button btnType="submit" value="Save" />
              <Button
                btnType="cancel"
                value="cancel"
                onClick={() => {
                  cancel();
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
