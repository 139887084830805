import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getListCity = (async(pageNumber,pageSize,searchString,orderByString,filterString) => await http({
    
    url : ENDPOINTS.BaseDefinitionAndSetting.City.browse,
    method: 'get',
    params:{
        PageNumber:pageNumber,
        PageSize:pageSize,
        OrderByString:orderByString,
        SearchString:searchString,
        FilterString:filterString,
    }
}).then((res) =>{

    return res.data
}).catch((err) =>{
    return Promise.reject(err);
})

)

export const getCity = (async (Id) => await http({
    
    url: ENDPOINTS.BaseDefinitionAndSetting.City.load,
    method: 'get',
    params: {Id : Id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)


export const postCityApi = (data) => http({
    
    url: ENDPOINTS.BaseDefinitionAndSetting.City.browse,
    method: "post",
    data:data,
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

export const putCityApi = (data) => http({
    
    url: ENDPOINTS.BaseDefinitionAndSetting.City.browse,
    method: "put",
    data:data,
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const getCityDictionary = (async (id,provinceId,search) => await http({
    
    url: ENDPOINTS.BaseDefinitionAndSetting.City.dictionary,
    method: 'get',
    params: {
        provinceId:provinceId,
        Id:id,
        searchString:search,
    }
})
    .then((response) => (response.data))

)

export const deleteCityApi = (id) => http({
    
    url : ENDPOINTS.BaseDefinitionAndSetting.City.browse,
    method: 'delete',
    params:{
        id:id
    }
    
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
});