export const AUTH_URL_CALL = `${process.env.REACT_APP_AUTH_URL}/authorize?response_type=code&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URL}&scope=${process.env.REACT_APP_AUTH_CLIENT_SCOPE}&code_challenge_method=S256&code_challenge=`;
export const AUTH_TOKEN_URL = `${process.env.REACT_APP_AUTH_URL}/token`;
export const APP_URL = `${process.env.REACT_APP_URL}/api/${process.env.REACT_APP_V}`;
export const INVENTORYANDSALES_URL = `${process.env.REACT_APP_INVENTORY_AND_SALES}/api/${process.env.REACT_APP_V}`;
export const DYNAMICFORMS_URL = `${process.env.REACT_APP_DYNAMIC_FORMS_URL}/api/${process.env.REACT_APP_V}`;   
export const MEETINGMANAGEMENT_URL= `${process.env.REACT_APP_MEETING_MANAGEMENT_URL}/api/${process.env.REACT_APP_V}`;
export const NOTIFYING_URL = `${process.env.REACT_APP_NOTIFYING_SERVICE_URL}/api/${process.env.REACT_APP_V}`;
export const TREASURY_AND_ACCOUNTING_URL = `${process.env.REACT_APP_TREASURY_AND_ACCOUNTING}/api/${process.env.REACT_APP_V}`;
export const CHAT_URL = `${process.env.REACT_APP_CHAT_SERVICE_URL}/hubs/chat`;
export const CALL_URL = `${process.env.REACT_APP_CALL_SERVICE_URL}/callHub`;
export const REPORT_URL = `${process.env.REACT_APP_REPORTING_APISERVICE_URL}/api`;
export const NOTIFICATION_URL = `${process.env.REACT_APP_URL}/hubs/notification`;
export const STORAGE_URL = `${process.env.REACT_APP_FILEDOWNLOAD_SERVICE_URL}/File/`;
export const ICON_TYPE = `fas`;
export const GUID_EMPTY = `00000000-0000-0000-0000-000000000000`;
export const weekdays_en = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const weekdays_fa = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
export const EMOJIS = [  0x1F64A,0x1F640,0x1F638,0x1F63C,0x1F600,0x1F601,0x1F602,0x1F603,0x1F604,0x1F605,0x1F606,0x1F607,0x1F608,0x1F609,0x1F60A,
    0x1F60B,0x1F60C,0x1F60D,0x1F60E,0x1F60F,0x1F610,0x1F611,0x1F612,0x1F613,0x1F614,0x1F615,0x1F616,0x1F617,0x1F618,
    0x1F619,0x1F61A,0x1F61B,0x1F61C,0x1F61D,0x1F61E,0x1F61F,0x1F620,0x1F621,0x1F622,0x1F623,0x1F624,0x1F625,
    0x1F626,0x1F627,0x1F628,0x1F629, 0x1F62A,0x1F62B,0x1F62C,0x1F62D,0x1F62E,0x1F62F,0x1F630,0x1F631,0x1F632,
    0x1F633,0x1F33A,0x1F33C,0x1F337,0x1F339,0x1F634,0x1F635,0x1F636,0x1F637,0x1F922,0x1F923,0x1F926,0x1F92B,0x1F92C,0x1F92E,0x1F525,0x1F44D,
    0x1F446,0x1F447,0x1F448,0x1F449,0x1F414 ,0x1F64F,0x1F44A,0x1F91D,0x1F44B,0x1F44C,0x1F389,0x1F5A4,0x1F497,0x1F494,0x1F494,0x1F493,
    0x1F381,0x1F9E1,0x1F388,0x1F38A,0x1F411,0xFE0F ];


export const BaseUrlsKey ={
    automation:  APP_URL,
    Meetings:MEETINGMANAGEMENT_URL
}

export const DisplayableFormats = ["html", "htm","jpeg", "jpg", "png", "gif", "svg","pdf","swf","mp3", "wav", "ogg","mp4",
    "webm", "ogg","pdf","txt","xml","doc", "docx", "ppt", "ppt","json","rtf","md","tex"]
export const keyboardLetterMap = {
    65: 'a',
    66: 'b',
    67: 'c',
    68: 'd',
    69: 'e',
    70: 'f',
    71: 'g',
    72: 'h',
    73: 'i',
    74: 'j',
    75: 'k',
    76: 'l',
    77: 'm',
    78: 'n',
    79: 'o',
    80: 'p',
    81: 'q',
    82: 'r',
    83: 's',
    84: 't',
    85: 'u',
    86: 'v',
    87: 'w',
    88: 'x',
    89: 'y',
    90: 'z',
};