import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { CustomerPositionDictionary } from "components/CustomerPosition/CustomerPosition";
import DatePicker from "components/DatePicker/DatePicker";
import { FileInput } from "components/FileInput/FileInput";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm, UserContext } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { checkValidate, getMyOwnRequest, getRequest, getRequestById, postGeneralRequest } from "./api";
import { GeneralRequestsClassificationId } from "./components/GeneralRequestsClassificationId/GeneralRequestsClassificationId";
import { CustomerInfo } from "pages/AfterSales/component/CustomerInfo/CustomerInfo";


interface props {
    mode?: any,
    onHide?: () => void,
    reload?: () => void,
    processId?: any,
    jobId?: any,
    referenceId?: any,
    Id?: any,
    step?: any,
    showInDetail?: any,
    JobNo?: any,
    inDynamicForms?: any;
}

export const CustomerGeneralRequest = ({inDynamicForms=false , showInDetail = false, JobNo, step = 1, mode = "create", onHide, reload, processId, referenceId, jobId ,Id }: props) => {
    const layoutContext = useContext(LayoutContext);
    const [RequestDate, setGeneralRequestDate] = useState<any>();
    const [GeneralRequest, setGeneralRequest] = useState<any>(new Date().toISOString());
    const [showStep, setShowStep] = useState<any>({});
    const [position, setPosition] = useState<any>(null);
    const [validatePosition, setValidatePosition] = useState<any>(false);
    const [CustomerPosition, setCustomerPosition] = useState<any>(null);
    const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<any>("invalid position");
    const [messageType, setMessageType] = useState<any>(null);
    const [selectedImage, setSelectedImage] = useState<any>();

    const [showCustomerInfo, setShowCustomerInfo] = useState(false);


    const changeHandlePic = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files);
        }
    };
    const handleSubmit = async(event) => {
        event.preventDefault()
        if (mode === "edit") {
            let data = {
                "Id": GeneralRequest.Id,
                "RequesterPositionId": GeneralRequest.RequesterPositionId,
                "Applicant": GeneralRequest.Applicant,
                "RequesterMobileNumber": GeneralRequest.RequesterMobileNumber,
                "RequestNo":GeneralRequest.RequestNo,
                "RequestDate": GeneralRequest.RequestDate,
                "Description": GeneralRequest.Description,
                "ExtraDescription": GeneralRequest.ExtraDescription,
                "CustomerGeneralRequestClassificationId": event.target.CustomerGeneralRequestClassificationId.value,
                "FollowupResponsiblePositionId": GeneralRequest.FollowupResponsiblePositionId,
            }
            sendDataAsync(data, "put");
        } else {
            let data = {
                "PerformerPositionId":(position && position?.Id)? position?.Id   : layoutContext.currentUserPosition.PositionId,
                "CustomerGeneralRequest": {
                    "RequesterPositionId":(position && position?.Id) ? position?.Id   : layoutContext.currentUserPosition.PositionId,
                    "Applicant": event.target.applicant.value,
                    "RequesterMobileNumber": event.target.RequesterMobileNumber.value,
                    "RequestDate": RequestDate,
                    "CustomerGeneralRequestClassificationId": event.target.CustomerGeneralRequestClassificationId.value,
                    "ItemDescription": event?.target?.ItemDescription?.value,
                    "Description": event.target.Description.value,
                    "ExtraDescription": event.target.ExtraDescription.value,
                }
            }
            let formdata: any = {}
            formdata.CustomerGeneralRequestDTO = data
            let _objData = new FormData

            _objData.append("CustomerGeneralRequestDTO", JSON.stringify(data));
            if (selectedImage) {
                const ListSelected:any = [...selectedImage]
                ListSelected.map(item=>{
                    _objData.append("Files", item);
              
                  })
            }
            sendDataAsync(_objData, "post");
        }


    }
    async function sendDataAsync(data, method) {
        try {
            await postGeneralRequest(data, method, processId);
            onHide && onHide();
            reload && reload()
        } catch (err: any) {

        }
    }
    async function getRequestAsync() {
        setLoading(false)
        try {
            if(Id){
                const res = await getRequestById(Id);
                setGeneralRequest(res.Data)
            }else if (referenceId) {
                const res = await getRequest(referenceId ? referenceId : jobId);
                setGeneralRequest(res.Data)
            } 
        } catch (err: any) {
            setMessage(err?.data?.Message)
            setMessageType("error")
        }
        setLoading(true)
    }
    async function checkValidateAsync() {
        setLoading(false)
        try {
            const res = await checkValidate();
            if(res.Data == null){
                setValidatePosition(true)
            }else{
                setCustomerPosition(res.Data)
            }
        } catch (err: any) {
            setValidatePosition(null)
        }
        setLoading(true)
    }

    useEffect(() => {
        if(mode == "create" ){
            checkValidateAsync()
        }
        if (Id || referenceId) {
            getRequestAsync();
        }
    }, [Id, referenceId]);

    return (
        <>           
          {showCustomerInfo &&
            <ModalForm mode={mode} title={("CustomerInfo")} onHide={() => { setShowCustomerInfo(false) }} show={showCustomerInfo}>
                <CustomerInfo customerPositionId={GeneralRequest?.RequesterPositionId} />
            </ModalForm>
        }
        
        {loading ?  
            <>{(validatePosition !=null && messageType ==null)?
                    <form onSubmit={handleSubmit } className={mode}>
            <div className="m-2">
                <div className={`${(step > 1 || showInDetail) && "card"} card mb-3 `}>
                    {(step > 1 || showInDetail) &&
                        <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px", justifyContent: "space-between" }} className="d-flex  py-1 col-12 pr-4">
                            {showInDetail ?
                                <div>{trans("GeneralRequest")} - {JobNo}</div>
                                :
                                <div>{trans("step")}-1 </div>
                            }
                            <div style={{ marginLeft: "18px" }} onClick={() => { showStep[1] ? setShowStep({ ...showStep, 1: false }) : setShowStep({ ...showStep, 1: true }) }} >{showStep[1] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" /> : <FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                        </div>
                    }
                        <div style={{display: !showStep[1] ?"block" :"none"}} className="card-body" >
                            <div className="row">
                                {mode != "create" &&
                                    <div className="col-12 col-sm-4 mb-2 mb-sm-0">
                                        <DatePicker
                                            required={true}
                                            lable="RequestDate"
                                            setIsoTime={(isoTime: string) => { setGeneralRequestDate(isoTime); }}
                                            val={ GeneralRequest?.RequestDate ? GeneralRequest?.RequestDate  : null} //resetForm ? new Date().toISOString() : 
                                            mode={"view"}
                                        />
                                    </div>
                                }
                                {
                               ( CustomerPosition == null )&&
                                <div style={{display:"flex"}} className="col-12 col-sm-4 mb-2 mb-sm-0">
                                    <div className={(jobId&&mode!="create")?"col-10":"col-12"}>
                                    <CustomerPositionDictionary
                                        Change={setUserMadeChanges}
                                        onChange={setPosition}
                                        name="RequesterPositionId"
                                        required={true}
                                        label="asker"
                                        mode={step > 1 ? "view" : mode}
                                        value={mode == "create" ?( layoutContext.currentUserPosition.PositionId ): GeneralRequest?.RequesterPositionId}
                                    />
                                </div>
                                    {(jobId&&mode!="create")&&
                                        <Button Icon={true} value={<button type="button" style={{marginTop:"21px"}} onClick={(e)=>{setShowCustomerInfo(true)}} className="checkButton  d-flex align-items-center justify-content-center" > <FontAwesomeIcon icon={[ICON_TYPE, "info"]} style={{fontSize:"15px"}}  size="1x"  /></button>}  btnType="primary"onClick={(e)=>{}}   />
                                    }
                                </div>
                                }

                                <div className="col-12 col-sm-4 mb-2 mb-sm-0">
                                    <Textbox
                                        textboxType="text"
                                        textboxName="applicant"
                                        required={true}
                                        labelText="applicant"
                                        defaultValue={GeneralRequest.Applicant}
                                        mode={step > 1 ? "view" : mode}
                                    />
                                </div>
                                <div className="col-12 col-sm-4 mb-2 mb-sm-0">
                                    <Textbox
                                        textboxType="mobile"
                                        textboxName="RequesterMobileNumber"
                                        labelText="RequesterMobileNumber"
                                        required={true}
                                        defaultValue={GeneralRequest.RequesterMobileNumber}
                                        mode={step > 1 ? "view" : mode}
                                    />
                                </div>
                                <div className="col-12 col-sm-4">
                                    <GeneralRequestsClassificationId
                                        name="CustomerGeneralRequestClassificationId"
                                        value={GeneralRequest.CustomerGeneralRequestClassificationId}
                                        mode={mode}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <Textbox
                                        required={true}
                                        defaultValue={GeneralRequest.Description}
                                        textboxName="Description"
                                        labelText="Description"
                                        textboxType="textarea"
                                        minHeight={mode == "view" ? 2 : 3}
                                        mode={step > 1 ? "view" : mode}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <Textbox
                                        defaultValue={GeneralRequest.ExtraDescription}
                                        textboxName="ExtraDescription"
                                        labelText="ExtraDescription"
                                        textboxType="textarea"
                                        minHeight={mode == "view" ? 2 : 3}
                                        mode={step > 1 ? "view" : mode}
                                    />
                                </div>
                            </div>
                            <div className="row mb-1">
                        {(mode=="create") &&
                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                            <FileInput
                                hideSrc={selectedImage ?false :true}
                                multiple={true}
                                mode={ mode}
                                lable="picture"
                                name="picture"
                                onChange={changeHandlePic}
                                src={selectedImage  ? (URL.createObjectURL(selectedImage[0] )) :""}
                                />
                        </div>
                        }
                    </div>
                        </div>
                </div>

            </div>
            <div className="row">
                {!showInDetail &&
                    <div className="d-flex justify-content-end">
                        {mode != "view" ? (
                            <>
                                <Button btnType="submit" value={step > 1 ? "save" : "send"} />
                                <Button
                                    btnType="cancel"
                                    value="cancel"
                                    onClick={() => { onHide && onHide() }}
                                />
                            </>

                        ) : (<Button
                            btnType="cancel"
                            value="cancel"
                            onClick={() => {
                                userMadeChanges ? Toast(trans("modalclosemessage"), "warning", "alert", onHide)
                                    : onHide && onHide()
                            }}
                        />)
                        }
                    </div>
                }
            </div>
                    </form>
                :
                <Message  message={trans(message)} type={"error"} />
                }
                </>
        :<Loading/>}
        </>
    )
}