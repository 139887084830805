import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const postLetterSendData = (data, processId) => http({
    method: "post",
    url: ENDPOINTS.secretariat.letterSent.browse,
    data: data,
    params:{
        PRJ_UID : processId
    }
}).then((res) => {
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});

export const putLetterSendData = (data) => http({
    method: "put",
    url: ENDPOINTS.secretariat.letterSent.browse,
    data: data,
}).then((res) => {
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});


export const getLetterSendData = ( Id) => http({
    url: ENDPOINTS.secretariat.letterSent.load,
    method: "get",
    headers: { "Accept-Language": "en" },
    params:{
        jobId:Id
    }
    
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});
export const getReceivers = ( Id) => http({
    url: ENDPOINTS.secretariat.letterReceiver.receiverDictionary,
    method: "get",
    params:{
        jobId:Id
    }
    
}).then((res) => {
     
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});

export const getSentHistory = ( Id) => http({
    url: ENDPOINTS.secretariat.letterSent.browse,
    method: "get",
    params:{
        jobId:Id
    }
    
}).then((res) => {
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});

export const postLetterSends = ( data , method) => http({
    url: ENDPOINTS.secretariat.letterSent.list,
    method: method,
    data:data
    
}).then((res) => {
    return res.data
}).catch((err) => {

    return Promise.reject(err);
});
