import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {  useEffect, useRef, useState } from "react"
import { trans } from "utils/helpers"
import { getToolbarEnumerationFilter } from "./api"
import { ICON_TYPE } from "utils/constants"

interface ButtonToolbarEnumerationFilterProps {
    onClick?: () => void,
    Callback: (id: any) => void,
    enumerationType: string,
}
export const ButtonToolbarEnumerationFilter: React.FC<ButtonToolbarEnumerationFilterProps> = ({enumerationType ,onClick, Callback }) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    const buttonToolbarProcessStarting = useRef<any>(null);
    const buttonToolbarlist = useRef<any>(null);

    const [showSubMenu, setShowSubMenu] = useState(false);
    const [options, setOptions] = useState<any>()
    const [selectoptions, setSelectOptions] = useState<any>(null)
    const [loading, setLoading] = useState<any>()
    const [rotate , setRotate] = useState<boolean>(false);

    function showSubMenuFunction() {
        setShowSubMenu(true)
    }
    function hideSubMenuFunction() {
        setShowSubMenu(false)
    }
    function selectboxValSetter(event) {
        setSelectOptions(event.currentTarget.getAttribute('data-value'))
        Callback && Callback(event.currentTarget.getAttribute('data-value'));
        setShowSubMenu(false)
        if (buttonToolbarProcessStarting.current) (buttonToolbarProcessStarting.current as HTMLButtonElement).blur();
    }
    async function getpersonalTaskStatusAsync() {
        setLoading(false)

        try {
            const res = await getToolbarEnumerationFilter(enumerationType)
            let list = Object.keys(res?.Data)
            let opList = list.map(item=>{
                return {
                    Id : item ,
                    Name : res?.Data[item],
                }
            })
            opList.push({Id:"0" , Name:"All"})
            setOptions(opList)
            
        } catch (error) {
            
        }
        setLoading(true)

    }
    useEffect(() => {
        if(showSubMenu &&options==null){
            getpersonalTaskStatusAsync()
        }
    }, [showSubMenu]);

    useEffect(() => {
        if(buttonToolbarlist.current && loading){
           const rect=  buttonToolbarlist.current.getBoundingClientRect();
            const windowWidth = window.innerWidth;
             if (rect.right > windowWidth){
                buttonToolbarlist.current.style.left = '0';
                 }
              else {
                buttonToolbarlist.current.style.right = '0'
             
                }
        }
    },[window,loading])

    return (
        <> <div className="toolbar-btn-wrapper">
            <button ref={buttonToolbarProcessStarting} onClick={() => { }} onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction} className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
                <div className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>F12</div>
                {(selectoptions !=null && selectoptions !=0 ) && <div className={`toolbar-btn__count  ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}></div>}
                <FontAwesomeIcon icon={['fas', 'filter']} width="20px" height="20px" />
                <small>{trans("Filter")}</small>
                <div className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`} ref={buttonToolbarlist} >
                {loading?
                    <>
                    {options && options.map((option, index) => (
                        selectoptions && option.Id ==selectoptions  ?
                        <div key={index} className="toolbar-btn-sub-menu__option selected"  data-value={`${option.Id}`} onClick={selectboxValSetter}>{trans(option.Name)}</div>
                       :
                        <div key={index} className="toolbar-btn-sub-menu__option" data-value={`${option.Id}`} onClick={selectboxValSetter}>{trans(option.Name)}</div>
                    ))}

                    </>
                        :
                        <div  className="sync-box-icon mt-4" onClick={()=>{setRotate(true);  setTimeout(()=>{ setRotate(false) }, 1500)}}   >       
                           <FontAwesomeIcon icon={[ICON_TYPE , "sync"] } className={`text-primary  ${rotate ? "Rotate" : "" } `}  />  
                        </div>  
                }
                </div>
            </button>
        </div>
        
        </>
    )
    }