import {  Priority, ReferenceStatus } from "types/enum"


export interface Action {
    Id?: string,
    ReferenceId?: string,
    Description?: string,
    StartTime?: string,
    EndTime?: string,
    ActualTime?: number,
    Progress?: number
}
export interface Invoice {
    TAS_UID?:string,
    Id?: string,
    JobId?: string,
    InvoiceId?: string,
    HasInvoice?: boolean,
    InvoiceNumber?: number,
    InvoiceDescription?: string,
    InvoicePrice?: number,

}

export interface JobDTO {
    Id?: string,
    PRJ_UID?: any,//fill by processId
    Description: string,
    PerformerPositionId: string,
    RequesterPositionId: string,//fill by current position   
    Applicant: string,
  
}
export interface GeneralRequestDTO {
    Id?: string,
    RequestNo?: any,
    RequestDate?: string,
    Description: string,
    RequesterPositionId: string,//fill by current position   
    Applicant: string,
    GeneralRequestClassificationId: string,
  
}

export interface ReferenceLocalDTO {
    AssignType: string,
    Id: string,
    TAS_UID: string,
    JobId: string,
    PerformerPositionId?: string,
    ReferrerPositionId: string,
    ReferenceSubjectId?: string,
    Position?: string,
    Description?: string,
    ReferenceSubject?: string,
    Priority?: Priority,
    EstimateTime?: number,
    DeadLineDate?: string,
    HasFollowUp?: boolean,
    SecretReference?: boolean,
    SendMessageToPerformer?: boolean,
    ParentReferenceId?: string,
}
export interface Reference {
    CompanyId?: string,
    JobId: string,
    TAS_UID: string,
    PositionId: string,
    RefereePositionId: string,
    ReferenceDate: string,
    ReferenceSubjectId: string,
    Description: string,
    Priority: Priority,
    EstimateTime?: number,
    DeadLineDate?: string,
    ReferenceStatus?: ReferenceStatus,
    ViewTime?: string,
    // FolderId?: string,
    HasFollowUp: boolean,
    SecretReference: boolean,
    OnGoing: boolean  // what is this?
}
export interface SignatureDTO {
    Id?: string,
    OwnerId: string,
    PositionId: string,
    SignatureId: string,
    TAS_UID: string,
    SignatureTime: string,
    SignatureDescription: string,
    IsSigned: boolean  
}

export interface ReferenceSubject {
    Title: string,
    ReferenceSubjectStatus: number
}
export enum AssignTypes {
    MANUAL,
    BALANCED,
    SELF_SERVICE,
    EVALUATE,
    REPORT_TO
    
}
export enum CalendarType {
    fa,
    en,
    er


}
