import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS } from "utils/endpoints";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";


export const getWarehouseDataWithId = (id) => http({
     baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.load,
    method: 'get',
    params:{
        Id:id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
})


export const postWarehouseData = (data) => http({
     baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.browse,
    method: 'post',
    data: data
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
})


export const putWarehouseData = (data) => http({
     baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.browse,
    method: 'put',
    data: data,
    params:{
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
})

