import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const sendItemGroupLeaf = (async ( data , method) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.itemGroupLeaf,
    method: method,
    data:data
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const getItemGroupLeaf = (async ( id ) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.itemGroupLeaf,
    method: "get",
    params:{
        id:id
    }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)


export const getAllKeys = (pageNum, pageSize , searchString, orderBy ,filterlist) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseDefinitionAndSettingApi.ItemMainProperty.browse,
   method: "get",
   params: {
       searchString: searchString,
       OrderByString: orderBy,
       FilterString:filterlist,
       PageSize: pageSize,
       PageNumber: pageNum,
   }
}).then((res) => {
   return res.data
}).catch((err) => {
   return Promise.reject(err.response  );
});
