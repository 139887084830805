import { ENDPOINTS } from "utils/endpoints";
import { http  } from "utils/http";


export const getProfile = () => http({
    url: ENDPOINTS.profileApi.profile,
    method: 'get',

}).then((res) => {
    
    return res.data.Data
}).catch((err) => {
    return Promise.reject( err);
});
export const getCurrentNotSeenCountDictionary = () => http({
    url: ENDPOINTS.cartable.notificationsDictionary,
    method: 'get',

}).then((res) => {
    
    return res.data.Data
}).catch((err) => {
    return Promise.reject( err);
});
export const getUserInfo = () => http({
    baseURL:process.env.REACT_APP_CHAT_SERVICE_URL,
    url: ENDPOINTS.Chatuser.userInfo,  
    method: 'get',

}).then((res) => {
    
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});




export const getAllSubstitute = (async (pageNumber: number, pageSize: number ,orderFormat:string ="" , filterlist , Search)=> await http({
    url: ENDPOINTS.BaseInformation.position.substitute,
    method: 'get',
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString:Search,
        OrderByString : orderFormat,
        FilterString:filterlist,
        }
        
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)

export const getPrint = (async (data)=> await http({
    baseURL:"http://192.168.10.209:5001/api",
    url: "/Gateway/ReportingService",
   // url: "/ReportDesigner/IndexJson",
    method: 'post',

    data:data
        
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)

export const getSubstitute = (async (id) => await http({
    url: ENDPOINTS.BaseInformation.position.substituteId,
    method: 'get',
    params: { Id: id }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)


export const postSubstitute = (data) => http({
    url: ENDPOINTS.BaseInformation.position.substitute,
    method: 'post',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})



export const putSubstitute = (data) => http({
    url: ENDPOINTS.BaseInformation.position.substitute,
    method: 'put',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

export const deleteSubstitute = (id) => http({
    url: ENDPOINTS.BaseInformation.position.substitute,
    method: 'DELETE',
    params: {
        substitutePositionId :id
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

export const getUserInfoForm = () => http({
    url: ENDPOINTS.profileApi.userInfo,
    method: 'get'

}).then((res) => {

    return res.data
}).catch((err) => {
    return Promise.reject(err);
})

export const postUserInfoForm = (data) => http({
    url: ENDPOINTS.profileApi.profile,
    method: 'put',
    data:data

}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})


export const getPersonApi = (async (search, id) => await http({

    url: id.length >1 ?  ENDPOINTS.BaseInformation.person.dictionaryId :  ENDPOINTS.BaseInformation.person.DictionaryOrganization,
    method: 'get',
    params: {
        SearchString: search,
        Id: id
    }
})  
    .then((respons) => (respons.data.Data))
)
export const getcontact = (async (num) => await http({

    url:ENDPOINTS.BaseInformation.person.searchByNumber ,
    method: 'get',
    params: {
        PhoneNumber: num,
       
    }
})  
    .then((respons) => (respons.data))
)






export const getWidgetList = (IsPublic) => http({
    url: ENDPOINTS.Widget.widgetListName,
    method: 'get',
    params:{
        IsPublic:IsPublic
    }
}).then((res) => {
    
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
