import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Position } from "components/Position/Position";
import { RangeSlider } from "components/RangeSlider/RangeSlider";
import { Textbox } from "components/Textbox/Textbox";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getDeliverForm, getRate, sendDeliverForm } from "./api";


interface props {
    mode?: any
    hide?: () => void
    reload?: () => void
    Id?:any
    jobId?:any
    taskId?:any
    step?:any
    inDynamicForms?: any;
}

export const JobDeliveryForm = ({inDynamicForms=false ,step=1,taskId, mode="create", hide,reload ,jobId , Id}:props) => {
  
    const [DeliveredTime, setDeliveredTime] = useState<any>();
    const [deliverForm, setDeliverForm] = useState<any>(new Date().toISOString());
    const [rateRange, setRateRange] = useState<any>();
    const [resetForm, setResetForm] = useState<boolean>(false);
    const layoutContext = useContext(LayoutContext);

    const handleSubmit = (event) => {
        event.preventDefault()
        if (mode == "edit") {
          let data:any
          if(step ==1){
           data  = {
            "TAS_UID" : taskId ,
            "JobId":jobId,
            "DeliveredTime": DeliveredTime,
            "ReceiverPerson":event.target.ReceiverPerson.value,
            "DeliveredByPositionId": event.target.DeliveredByPositionId.value,
            "Description": event.target.Description.value,
          }
          sendDeliverFormAsync(data, "post");
          }else if(step ==2){
            data  = {
              "JobId":jobId,
              "IsDelivered": event.target.checkbox.checked,
              "CustomerFeedBackRate": event.target.progressbar.value,
              "CustomerFeedBackDescription": event.target.CustomerFeedBackDescription.value,          
            }
            sendDeliverFormAsync(data, "put");
          }
         //notWork

          } else {
            let data:any
            data  = {
              "TAS_UID" : taskId ,
              "JobId":jobId,
              "DeliveredTime": DeliveredTime,
              "ReceiverPerson":event.target.ReceiverPerson.value,
              "DeliveredByPositionId": event.target.DeliveredByPositionId.value,
              "Description": event.target.Description.value,
            }
            sendDeliverFormAsync(data, "post");
        }
    }

    async function sendDeliverFormAsync(data, method) {
        try {
       let res = await sendDeliverForm(data ,method );
          hide && hide();
          reload && reload()
        } catch (err: any) {


        }
      }

    async function getDeliverFormAsync() {
        try {
          const res = await getDeliverForm(jobId ,taskId  );
          setDeliveredTime(res.Data.DeliveredTime)
          setDeliverForm(res.Data)
        } catch (err: any) {
     

         }
      }
    async function getRateAsync() {
        try {
          const res = await getRate();
          setRateRange(res?.Data)
          
        } catch (err: any) {


        }
      }

      useEffect(() => {
   
        getRateAsync();
        if (jobId){
          getDeliverFormAsync();
        } 
      }, [jobId]);




    return (
     <form onSubmit={handleSubmit}>
       <div className="m-2">            
             
          <div className={` card mb-3 `}>
               <>

              {step > 1 && 
                    <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px" }} className="py-1 col-12 pr-4"> {trans("step")}-1</div>
                }
             <div className="card-body" >
             <div className="row">
              <div className="col-12 col-sm-4 mb-2 mb-sm-0">
                    <Position
                       name="DeliveredByPositionId"
                       label="DeliveredByPosition"
                       mode={"view"}
                       value={ layoutContext.currentUserPosition.PositionId }
                       resetForm={resetForm}

                   />
              </div>
              <div className="col-12 col-sm-4 mb-2 mb-sm-0">
                  <Textbox
                      textboxType="text"
                      textboxName="ReceiverPerson"
                      labelText="ReceiverPerson"
                      resetForm={resetForm}
                      defaultValue={deliverForm.ReceiverPerson}
                      mode={step > 1 ? "view" : mode}

                  />
              </div>
              <div className="col-10 col-sm-10">
                        <Textbox
                            textboxType="textarea"
                            textboxName="Description"
                            labelText="Description"
                            mode={step > 1 ? "view" : mode}
                            resetForm={resetForm}
                            defaultValue={deliverForm.Description}
                        />
               </div>
             </div>
            </div>   
              </>
          </div>


              {step >1 &&
              
              <div className="card">
                <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px" }} className="py-1 col-12 pr-4"> {trans("step")}-2</div>
                <div className="card-body">

                <div className="row">
                    <div className=" mt-4 mb-4" >
                      <CheckableButton
                         defaultChecked={mode == "create" ? true :  (deliverForm?.IsDefault)}
                         defaultValue={mode == "create" ? true :  (deliverForm?.IsDefault)}
                         labelText={"OK"}
                         inputName={"checkbox"}
                         type="checkbox"
                         mode={mode}
                      />                                
                    </div>
                    <div className="  pb-4 pr-0">
                        <RangeSlider Range={rateRange} required={true} type={"icon"} mode={mode} label="Satisfaction" defaultValue={deliverForm?.Progress} name="progressbar" resetForm={resetForm} />
                    </div>
                </div> 
                 <div className="row">
                    <div className="col-10 col-sm-10">
                      <Textbox
                        textboxType="textarea"
                        textboxName="CustomerFeedBackDescription"
                        labelText="CustomerFeedBackDescription"
                        mode={mode}
                        resetForm={resetForm}
                        defaultValue={deliverForm.CustomerFeedBackDescription}
                      />
                    </div>
                 </div>
                </div>
              </div>
              }
             </div>
             <div className="row">
                 {mode != "view" ? (
                     <div className="d-flex justify-content-end">
                         <Button btnType="submit" value="save" />
                         <Button
                             btnType="cancel"
                             value="cancel"
                             onClick={() => {hide && hide()}}
                         />
                     </div>
                     ) : (<></>)
                 }
             </div>
            </form>
    )
}