import { useEffect, useState } from "react";
import {
  deleteVersion,
  getDownloadUrlAttachmentAsync,
  getVersions,
  putVersion,
} from "../api/api";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { Loading } from "components/Loading/loading";
import { trans } from "utils/helpers";
import { Toast } from "components/ToastShow/ToastShow";
import { Message } from "components/Message/Message";
import { STORAGE_URL } from "utils/constants";

export const VersionList = ({reload , endpoint, baseURL, attachFile, getAllData }) => {
  const [versionList, setVersionList] = useState<any>([]);
  const [versionLoading, setVersionLoading] = useState(false);
  const [selectedId, setSelectedId] = useState<string | any>(null);
  const [forceUpdate, setForceUpdate] = useState<any>(false);
  //   const [editId, setEditId] = useState<string>();
  const [catchMessage, setCatchMessage] = useState<any>({
    type: "info",
    msg: "NoDataForPreview",
  });

  const getVersionAsync = async () => {
    setVersionLoading(false);
    try {
      const response = await getVersions(
        endpoint,
        baseURL,
        attachFile.id,
        getAllData
      );
      setVersionList(response.Data);
      if (response.Data.length == 0) {
        setVersionList([]);
        setCatchMessage({ type: "info", msg: "NoDataForPreview" });
      }
    } catch (error: any) {
      setCatchMessage({ type: "error", msg: error });
    }
    setVersionLoading(true);
  };

  const selectedIdCallback = (obj, index) => {
    setSelectedId(obj.Id);
  };

  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "refresh":
        setForceUpdate(!forceUpdate);
        getVersionAsync();
        break;

      case "download":
        if (versionList.length > 0 || selectedId != null) {
          downloadTrigger();
        } else {
          Toast(trans("nofileselected"), "warning");
        }
        break;
      case "remove":
        {
          if (versionList.length > 0 || selectedId != null) {
            Toast(trans("msg_deleteconfirm"), "warning", "alert", () => {
              handleDeleteRow();
            });
          } else {
            Toast(trans("nofileselected"), "warning");
          }
        }
        break;
      case "recovery":
        if (versionList.length > 0 || selectedId != null) {
          handleEditRow();
        } else {
          Toast(trans("nofileselected"), "warning");
        }
    }
  };

  const handleViewRow = async () => {
    setVersionLoading(false);
    try {
      await putVersion(endpoint, baseURL, attachFile.id, selectedId);
      setForceUpdate(!forceUpdate);
    } catch (error) {}

    setVersionLoading(true);
  };

  async function downloadTrigger() {
    let tagList: any = [];
    let res = await getDownloadUrlAttachmentAsync(
      [attachFile.id],
      endpoint,
      baseURL
    );

    for (let i = 0; i < res?.Data.length; i++) {
      const result = await asyncOperation("message", 400);
      createDownloadlink(`${res?.Data[i]}`, attachFile.title);
    }

    try {
    } catch (error) {}
  }

  const asyncOperation = (message, delay) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(message);
      }, delay);
    });
  };

  function createDownloadlink(href, title) {
    const a = document.createElement("a");

    a.href = STORAGE_URL + href;
    a.download = title;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return null;
  }

  const handleEditRow = async () => {
    setVersionLoading(false);
    try {
      await putVersion(endpoint, baseURL, attachFile.id, selectedId);
      reload()
      //setForceUpdate(!forceUpdate);
    } catch (error) {}

    setVersionLoading(true);
  };

  const handleDeleteRow = async () => {
    setVersionLoading(false);
    try {
      await deleteVersion(endpoint, baseURL, attachFile.id, selectedId);
      setForceUpdate(!forceUpdate);
    } catch (error) {}

    setVersionLoading(true);
  };

  useEffect(() => {
    getVersionAsync();
  }, [forceUpdate]);

  return (
    <>
      <Toolbar
      emptyList={versionList?.length==0}
        remove={true}
        download={true}
        id={selectedId}
        recovery={true}
        handleClickToolbar={clickToolbarCallback}
        create={false}
        view={false}
      />
      {versionLoading ? (
        !!versionList.length ? (
          <>
          
          <DataGrid
            theadData={Object.keys(versionList[0])}
            tbodyData={versionList}
            minCellWidth={80}
            selectedRowObjectCallback={selectedIdCallback}
            rowSelected={selectedId}
            />
            </>
        ) : (
          <Message message={catchMessage.msg} type={catchMessage.type} />
        )
      ) : (
        <Loading />
      )}
    </>
  );
};
