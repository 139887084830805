import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GroupingPrice } from "components/GroupingPrice/GroupingPrice";
import { SwitchBtn } from "components/SwitchBtn/SwitchBtn";
import { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { trans } from "utils/helpers";
import { ItemBox } from "./ItemBox";

interface ItemBuyBtnComponentProps {
  setShow?: any;
  loading?: boolean;
  basketData?: any;
  showCartsubtotal?: any;
  priceInfo?: any;
  priceUpdate?: any;
  showCardStyle?: any;
  withClose?: boolean;
  clearBasket?: any;
  backPrevPage?:() => void
}

export const Cart = ({
  setShow,
  loading,
  basketData,
  showCartsubtotal,
  priceInfo,
  priceUpdate,
  showCardStyle,
  withClose,
  clearBasket,
  backPrevPage,
}: ItemBuyBtnComponentProps) => {
  const defaultCardStyle=localStorage.getItem('BasketcardStyle')?JSON.parse(localStorage.getItem('BasketcardStyle') as string):false
  const [cardStyle, setCardStyle] = useState<boolean>(defaultCardStyle);

  return (
    <>
      <div className="d-flex w-100 justify-content-between align-items-center buyBtn__listItem__basketHeader">
        <div>
          <span className="font-size-xl text-customerPrimaryColor">
            <FontAwesomeIcon icon={["fas", "basket-shopping"]} />
            {trans("ShoppingCart")}
          </span>
        </div>

        <div className="d-flex align-items-between">
          <div className="ms-3">
            <nav className="d-md-flex justify-content-start col-2">
              <div style={{ maxWidth: "25%", minWidth: "fit-content" }}>
                <SwitchBtn
                  cardStyle={cardStyle}
                  firstMode="کارتی"
                  secondMode="لیستی"
                  setColor="customerColor"
                  setShape="circle"
                  handleModeCard={() => {setCardStyle(!cardStyle);localStorage.setItem('BasketcardStyle',JSON.stringify(!cardStyle))}
                  }
                />
              </div>
            </nav>
          </div>
          {withClose && (
            <div className="d-flex align-items-center mt-1">
              <FontAwesomeIcon
                icon={["fas", "close"]}
                onClick={(e) => {
                  setShow(false);
                }}
                size="lg"
              />
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <>
          <ul className="w-100 buyBtn__listItem__contents">
            {basketData?.map((item) => {
              return Object.values(item.order).map((order: any, index) => (
                <ItemBox
                  styles={{
                    zIndex: "1!important",
                    display: `${cardStyle ? "block" : "none"}`,
                  }}
                  item={item}
                  order={order}
                  index={index}
                  UpdateTotal={priceUpdate}
                />
              ));
            })}
          </ul>

          <div className="d-flex justify-content-between align-items-center w-100 buyBtn__listItem__footerList">
            <div
              className={`d-flex ${
                showCartsubtotal
                  ? "justify-content-between"
                  : "justify-content-end"
              } align-items-center pt-2 w-100`}
            >
              {showCartsubtotal && (
                <div className="d-flex font-size-xl">
                  <span className="ms-2" style={{ fontSize: "16px" }}>
                    {trans("cartsubtotal")} :
                  </span>
                  <span className="text-customerPrimaryColor ">
                    <GroupingPrice value={priceInfo.cartSubtotal} />
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="text-start mb-3 gy-2 w-100 row  justify-content-evenly">
            <div className="col-2 p-0">
              <button
                disabled={!!basketData.length ? false : true}
                className="h-100 w-100   btn-customerPrimaryColor d-flex justify-content-center align-items-center btn"
                onClick={clearBasket}
              >
                <FontAwesomeIcon
                  icon={["fas", "trash"]}
                  className="chat__chatbox-rebuttonly-btn text-white fs-6"
                />
              </button>
            </div>

            <div className=" col-4 text-end px-1">
              <Button
                className=" btn-customerPrimaryColor border-0 btn-sm w-100  py-2 "
                onClick={() => {
                  backPrevPage&&backPrevPage();
                  setShow(false);
                }}
              >
                {trans("ContinueShopping")}
              </Button>
            </div>
            <button
              className=" col-5 px-1 btn btn-customerPrimaryColor "
              style={{ fontSize: "15px" }}
              onClick={() => setShow(false)}
              disabled={!!basketData.length ? false : true}
            >
              <Link
                to={"/e-commerce/CartPage"}
                style={{ cursor: !!basketData.length ? "pointer" : "no-drop" ,width:'100%',display:'block'}}
              >
                <span className="text-white">{trans("FinalizeShopping")}</span>
              </Link>
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
