
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { deletePostLevel, getPostLevelApi } from "./api/api";
import { Loading } from "components/Loading/loading";
import { PostLevelForm } from "./components/PostLevelForm";
import { downloadCSV, trans } from "utils/helpers";
import { Message } from "components/Message/Message";


export const PostLevelPage = ({  }) => {
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState<object | any>(null);
    const [mode, setMode] = useState<string | any>();
    const [forceUpdate, setForceUpdate] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});

    const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }

    const deleteRowHandler = async() => {
        try {
             await deletePostLevel(selectedObj.Id)
            getPostLevelAsync()
            setForceUpdate(!forceUpdate)
            setSelectedObj(null)
        } catch (error) {
            
        }
    }
    const viewRowHandler = () => {
        setMode("view")
        setShowForm(true)
    }

    const editRowHandler = () => {
        setMode("edit");
        setShowForm(true)
    }
    const showFormHandler = () => {
        setShowForm(false)
        setForceUpdate(!forceUpdate)
    }
    const selectedObjIdCallback = (obj) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false")});
        }else{
            setSelectedObj(obj)         
        };
    }


    async function getPostLevelAsync() {
        setLoading(false)
        try {
            const res = await getPostLevelApi(search , orderBy.length == 0 ?null:  orderBy.join(","));
            setResponse(res.Data);  
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)

    }
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,1 ,trans("PostLevelPage"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": setMode("create");setSelectedObj(null); setShowForm(true); break;
            case "view": if (selectedObj?.Id) viewRowHandler() ; break;
            case "edit": if (selectedObj?.Id) editRowHandler() ; break;
            case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , deleteRowHandler); break;
            case "help": alert("call api help"); break;
        }
    };
    useEffect(() => {
        getPostLevelAsync();
    }, [forceUpdate ,search ,orderBy]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);

    /* #endregion */
    return (
        <>
            <Toolbar handleSearch={searchHandler}  emptyList={response == null || response?.length == 0} id={selectedObj?.Id} remove={true} create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
            <div className="card">
                    { showForm &&
                        <PostLevelForm showForm={showFormHandler} mode={mode} selectedObj={selectedObj} />
                    }
                    {loading ?
                        response?.length > 0 ?
                            <>
                                <DataGrid
                                    parentName={"PostLevelPage"}
                                    orderFormatList={orderBy}                          
                                    orderFormat={setOrderBy}
                                    startIndex={1}
                                    theadData={Object.keys(response[0])}
                                    tbodyData={response}
                                    minCellWidth={80}
                                    scrollHeight={showForm? "25vh": "67vh"}
                                    selectedRowObjectCallback={selectedObjIdCallback}
                                    handleEdit={editRowHandler}
                                    handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,deleteRowHandler)}}
                                    handleView={viewRowHandler}
                                    rowSelected={selectedObj}
                                />
                            </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                        : <Loading />
                    }
            </div>
        </>

    );
}