import { Button } from "components/Button/Button";
import { Toast } from "components/ToastShow/ToastShow";
import { Textbox } from "components/Textbox/Textbox";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import {  postChangePassword } from "pages/SystemManagement/Settings/Users/api";
import { useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";

export const ChangePasswordForm = ({Id, hide, mode  }) => {
    //const [selectedUserId, setSelectedUserId] = useState<string>(userId);
    const [dataChange, setDataChange] = useState<any>(false);
    const [valid, setValid] = useState<any>(true);
    const layoutContext = useContext(LayoutContext);



    const sendDataAsync = async ( passwordData) => {

            try {
                const pass = await postChangePassword(passwordData)
                hide()
            } catch (error2) {

            }
    }


    const formSubmit = (event) => {

        event.preventDefault()

        const passwordData = {
            UserId: layoutContext.UserPerson.UserId,
            Password: event.target.NewPassword.value,
            ConfirmPassword: event.target.ConfirmNewPassword.value
        }


        sendDataAsync(passwordData);
    }
    useEffect(() => {
        //getDataAsync();
        if(mode == "view" && Id.length > 0 ){
            
   
        }
    }, [mode  ]);
    return (
        <div className="card p-3">
            <form onSubmit={formSubmit}>
                <div className="container-fluid">
                    <div className="row my-1">
                        <div className="col-12 col-sm-4 my-1 ">
                            <Textbox
                                setValid={setValid}
                                defaultValue=""
                                Change={setDataChange}
                                required={true}
                                placeholder=""
                                textboxType="password"
                                textboxName="NewPassword"
                                labelText="NewPassword"
                            />
                        </div>
                        <div className="col-12 col-sm-4 my-1 ">
                            <Textbox
                                setValid={setValid}
                                Change={setDataChange}
                                required={true}
                                textboxType="password"
                                textboxName="ConfirmNewPassword"
                                labelText="ConfirmNewPassword"
                            />
                        </div>
                    </div>
                
                    <div className="row my-1 d-flex justify-content-end">
                        {mode != "view" ?
                            <div className="col-4 d-flex justify-content-end">
                                <Button btnType="submit" value={'Save'} />
                                <Button
                                    btnType="cancel"
                                    value="Cancel"
                                    onClick={() => {
                                        dataChange ? Toast(trans("modalclosemessage"), "warning", "alert", hide)
                                            : hide()
                                    }}
                                />
                            </div>
                            :
                            <div className="col-4 d-flex justify-content-end">
                                <Button
                                    btnType="primary"
                                    value="Close"
                                    onClick={hide}
                                />
                            </div>
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}

