import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getAfterSale = (pageNumber: number, pageSize: number, searchString, orderBy, filterlist ,applicationId) => http({
    url: ENDPOINTS.afterSalesApi.browse,
    method: 'get',
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString: searchString,
        OrderByString: orderBy,
        FilterString: filterlist,
        applicationId: applicationId,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


export const setJobInProgress = (id: any) => http({
    url: ENDPOINTS.cartable.setJobInProgress,
    method: 'post',
    params: {
        jobId: id,
    }

}).then((res) => {
     
    return res.data
})
.catch((err) => {
     
    return Promise.reject(err);
});


export const getStartForm = (processId: any) => http({
    url: ENDPOINTS.bpmnApi.process.getStartForm,
    method: 'get',
    params: {
        processId: processId

    }
}).then((response) => (response.data))
.catch(err => {
    return Promise.reject(err);
})





export const cancelServiceRequest = (id) => http({
    url: ENDPOINTS.clientPortal.customerServiceRequest.cancel,
    method: 'post',
    params: {
        Id: id
    }
}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


export const ForceStop = (jobId: any, description , sendSms) => http({
    url: ENDPOINTS.afterSalesApi.stop,
    method: 'put',
    params: {
        JobId: jobId,
        forceStopDescription: description,
        sendMessageToRequester:sendSms
    }

}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const insertNote = (jobId: any, description ) => http({
    url: ENDPOINTS.afterSalesApi.insertNote,
    method: 'put',
    params: {
        jobId : jobId,
        note: description,
    }

}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getRequesterUserId = (jobId: any) => http({
    url: ENDPOINTS.afterSalesApi.userId,
    method: 'get',
    params: {
        JobId: jobId
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getViewForm = (processId: any) => http({
    url: ENDPOINTS.bpmnApi.process.getViewForm,
    method: 'get',
    params: {
        processId: processId,
    }
}).then((response) => (response.data))
.catch(err => {
    return Promise.reject(err);
})

export const resendReference = (jobId: any) => http({
    url: ENDPOINTS.clientPortal.clientPortal.resend,
    method: 'post',
    params: {
        jobId: jobId
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const jobTransfer = (jobId: any ) => http({
    url: ENDPOINTS.afterSalesApi.transfer,
    method: 'get',
    params: {
        jobId: jobId 
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});