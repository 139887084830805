import { useEffect, useState } from "react";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { INVENTORYANDSALES_URL } from "utils/constants";


interface TaxFormPropsType {
    id?: any,
    mode?: any,
    data?: any,
    inTab?: any,
    formRef?: any,
    submitTax?: (data) => void;

}

export const TaxForm = ({
    id,
    mode,
    data,
    inTab,
    formRef,
    submitTax,
 
  
}: TaxFormPropsType) => {


    const [resetForm, setResetForm] = useState(false);



    async function formSubmit(event) {
        event.preventDefault();       
        let data ={
            TaxUnitId : event.target.TaxUnitId.value==""?null:event.target.TaxUnitId.value ,
           TaxItemId :event.target.TaxItemId.value==""?null:event.target.TaxItemId.value ,
        }
        submitTax && submitTax(data)
    }
    

    useEffect(() => {
        if (resetForm) {
          
            setResetForm(false);
        }

    }, [resetForm]);


    return (

        <form className="Tax-form py-2" ref={formRef} name="Tax" onSubmit={formSubmit}>
            <div className="card">
            {( (mode != "create" && data)||(mode == "create") )?
                <div className="card-body">
                    <div className="row">
                    <div className=" col ">
                            <DictionarySelectBox 
                              required={true}
                              label= "TaxUnit"
                              name= "TaxUnitId"
                              value={data?.TaxUnitId ? data.TaxUnitId : null}
                              endPoint={ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Unit.taxdictionary}
                              resetForm={resetForm}
                              baseUrl={INVENTORYANDSALES_URL}
                              mode={mode}                            
                            />
                        </div>
                        <div className="col">
                             <Textbox
                                textboxType="text"
                                textboxName="TaxItemId"
                                resetForm={resetForm}
                                defaultValue={data?.TaxItemId ? data.TaxItemId : null}
                                labelText="TaxItem"
                                mode={mode}
                                />
                        </div>
 
                    </div>
                </div>
                :
                <Loading/>
                }      
            </div>
            <button  type="submit"  className="d-none" id="hidebutton" name="hidebutton" >
 
            </button>
        </form>
    );
}