
import { DataGrid } from "components/DataGrid/DataGrid";
import { useEffect, useState } from "react";
import { Button } from "components/Button/Button";
import { ButtonArrow } from "components/ButtonArrow/ButtonArrow";
import { Toast } from "components/ToastShow/ToastShow";
import { Search } from "components/Search/Search";
import { getAttendeesList, getInvitedList, sendAttendeesList } from "./api";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";





interface Form{
    onHide:(e) => void,

    meetingId?:any
    mode:any
}


export const AttendeesPeople: React.FC<Form> = ({onHide ,meetingId , mode}) => {
    const [mood , setMood] = useState("users")
    const [Moodassign , setMoodassign] = useState("users")
    const [flag , setFlag] = useState(false)
    const [response , setResponse] = useState<any>([])
    const [assignmentResponse , setAssignmentResponse] = useState<any>([])
    const [selectList , setSelectList] = useState<any>([])
    const [assignedList , setAssignedList] = useState<any>([])
    const [savedData , setSavedData] = useState<any>([])
    const [assignedSelectList , setAssignedSelectList] = useState<any>([])
    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)
    const [positionstr , setPositionstr] = useState<any>()
    const [positionAssignmentstr , setPositionAssignmentstr] = useState<any>()
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<{add: boolean, Delete: boolean, view: boolean, edit: boolean} | undefined>();
    const [submitted, setSubmitted] = useState<boolean>(false)



    window.onresize = () => {
      setWidth(window.innerWidth)
  }

    let keys, Data:any = [], Dataassign:any = [];

    async function getPositionsListAsync(SearchString) {

            const Positionresponse = await getInvitedList(meetingId);
            let list:any=[]
          Positionresponse.Data.forEach(item => {
            let obj:any =new Object
            obj.Id=item.PositionId
            obj.TU_RELATION=1
            obj.Title=item.InvitedPerson
            list.push(obj) 
          });
         setResponse(list)    
  }
    async function sendAttendeesListAsync(list) {

        try {   
        const res = await sendAttendeesList(meetingId ,list);
        onHide(false)
        } catch (error) {
            
        }

            
  }
    async function getPositionsListSearchingAsync(SearchString) {


            
            const Positionresponse = await getInvitedList(meetingId); 
            let list:any=[]
            Positionresponse.Data.forEach(item => {
              let obj:any =new Object
              obj.Id=item.PositionId
              obj.TU_RELATION=1
              obj.Title=item.InvitedPerson
              list.push(obj) 
            });

             setAssignmentResponse(list)   
        

  }



    async function getAttendeesListAsync() {

      let makeData:any =[] 
        try {
            const AttendeesList = await getAttendeesList(meetingId)
            if(AttendeesList){
              AttendeesList.Data.map(item=>{
                makeData.push({Id:item.GroupPositionId ? item.GroupPositionId :item.PositionId,TU_RELATION :item.GroupPositionId ? 2 :1  , Title:item.InvitedPerson })
              })
              setSavedData(makeData)
              setAssignedList(makeData)
            }
            
        } catch (error) {
            
        }
    }



    function backward() {
      setAssignedSelectList([])
      setResetMultiSelect(!resetMultiSelect)


      const updatedData = selectList.map(item => {
        return {...item, ...modalData}
      })
      
      setAssignedList(assignedList.concat(updatedData))
      setFlag(!flag)
      setSelectList([])
      setSubmitted(false)
    }



    function forward() {
      setResetMultiSelect(!resetMultiSelect)
      let list:any =[]
      assignedList.forEach((item1,index1) => {
          let flag = true

          assignedSelectList.forEach((item2 , index2 ) => {
              if(item2.Id ==item1.Id ){
                flag = false
              }
          });
          if(flag){
            list.push(item1)
          }
        });

        setAssignedList(list)
    }



    const selectListCallBack =(list)=>{
        
      setSelectList(list[1])
    }

    const assignedselectListCallBack =(list)=>{
        setAssignedSelectList(list[1])
    }

    const referenceFormSubmit =(e)=>{
      e.preventDefault()

      if(assignedList.length==0){
        Toast("msg_assigneduserscannotempty" , "error")
      }else{
          e.preventDefault();
          let Data = assignedList.map(item=>{
            return item.Id
          })

        sendAttendeesListAsync(Data.length>0 ? Data : [] )
      }
    }

      const searching =(e)=>{
        setPositionstr(e == "" ? null:e)
        getPositionsListAsync(e == "" ? null:e)
      }

      const assignmentsearching =(e)=>{
        setPositionAssignmentstr(e == "" ? null:e)
        getPositionsListSearchingAsync(e == "" ? null:e)
      }




    useEffect(() => {
        getPositionsListAsync(positionstr);     
        getPositionsListSearchingAsync(positionAssignmentstr)
    }, [mood]);

    useEffect(() => {
      if(mode != "create"){

        getAttendeesListAsync();
      }
    }, []);


    if(response && assignedList){
      let assignmentdata:any =[]

      assignedList.forEach(elm => {
        if(Moodassign=="groups"){
          if(elm.TU_RELATION==2)assignmentdata.push(elm)
          
        }else if(Moodassign=="users"){
          if(elm.TU_RELATION==1)assignmentdata.push(elm)
        }else{
          assignmentdata.push(elm)
        }
      });

      

      // assignmentResponse.forEach(item1 => {
      //   let flag =false

      //   assignmentdata.forEach(item2 => {
      //     if(item1.Id == item2.Id){
      //       flag = true
      //     }
      //   });


      //   if(flag){
      //     Dataassign.push(item1)
      //   }
      // });

      assignmentResponse.map(obj => {
        const isSameId = assignmentdata.some(item => item.Id === obj.Id)
        const currentItem = assignmentdata.find(item => item.Id === obj.Id)

        
        if(currentItem) { 
          const {add, view, edit, Delete} = currentItem;

          if(isSameId){
            Dataassign.push({...obj, add, view, edit, Delete})
          }
        }
      })



      response.map(item1=>{
        let flag = true
        assignedList.forEach(item2 => {
          if(item1.Id ==item2.Id){
            flag=false
          }
        });
        if(flag){
          Data.push(item1) 
        }
      })

  }
    
    

    return ( <>

            <form onSubmit={referenceFormSubmit}>
                
                  <div style={{border:"none"}} className="card mt-3"> 
                    <div className="body">
                       
                    {mode !="view"&&<>
                       <div style={{width:"100%" ,paddingBottom: "39px"}}  className="">
                       <FormWithTitle title="InvitedPeople">
                        <div className="">
                          <div className="d-flex justify-content-between align-items-center mb-1  ">
                            
                            <Search onChange={searching} />
                           </div>

                          <div className="tableBoxInAccesses">
                              {response &&<DataGrid
                                    theadData={["Id" ,"Title"]}
                                    multiSelect={true}
                                    selectList={selectListCallBack}
                                    resetMultiSelect={resetMultiSelect}
                                    parentName={"referencePosition"}
                                    tbodyData={Data}
                                    minCellWidth={80}
                                    scrollHeight={"230px"}
                                    />
                                  
                            }                               
                          </div>
                        </div>
                        </FormWithTitle>
                       </div>
                                
                        <div className="col-lg-1 col-md-1 d-flex  flex-lg-column flex-md-column justify-content-center align-items-center">
                          <ButtonArrow type="backward" onClick={backward}/>
                          <ButtonArrow type="forward" onClick={()=>{forward()}}/>
                          
                        </div>
                        </>}
                        <div  style={{width:"100%" ,paddingBottom: "39px"}} className="">
                          <FormWithTitle title="AttendeesPeople">
                           <div className="">
                        <div className="d-flex justify-content-between align-items-center mb-1 ">
                           
                          <Search onChange={assignmentsearching} />
                        </div>    
                          <div   className="tableBoxInAccesses">                        
                           <DataGrid
                                 theadData={["Id" ,"Title"]}
                                 multiSelect={true}
                                 parentName={"referencePositionAssigned"}
                                 tbodyData={Dataassign}
                                 selectList={assignedselectListCallBack}
                                 minCellWidth={80}
                                 resetMultiSelect={resetMultiSelect}
                                 scrollHeight={"230px"}
                             />  
    
                                
                          </div>
                           </div>  
                           </FormWithTitle>
                        </div>  
                    
                    </div>
                  
                </div>
                {mode !="view"&&
                  <div className="col-lg-12 mt-3 btns_form">
                    <Button value="Save" btnType="submit" />
                    <Button
                      btnType="cancel"
                      value="Cancel"
                      onClick={()=>{onHide(false)}}
                      />
                </div>
                    }
              </form>
            </>
    );
}