import { DYNAMICFORMS_URL } from "utils/constants";
import { ENDPOINTSDYNAMICFORMS } from "utils/endpoints";
import { http } from "utils/http";

export const  postDataForm =( async (formKey,data) =>  await http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.data,
    method: "post",
   params:{
    formKey:formKey
    },
    data:data,
})

.then((respons) => (respons.data))
)


export const getFormInformationByJobId = (async ( id,formKey ) => await http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.JobId,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params:{
        jobId:id,
        formKey:formKey,
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const getFormInformationById = (async ( id,formKey ) => await http({
    baseURL:DYNAMICFORMS_URL,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.id,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params:{
        id:id,
        formKey:formKey,
        }
    
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)

export const  putInformationForm =( async (id,formKey,data) =>  await http({
    baseURL:DYNAMICFORMS_URL ,
    url: ENDPOINTSDYNAMICFORMS.DynamicFormApis.data,
    method: 'put',
  

    params: {
        id:id,
        formKey:formKey
    },
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)