import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Toast } from "components/ToastShow/ToastShow";

import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Textbox } from "components/Textbox/Textbox";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, trans } from "utils/helpers";
import { DeleteBranch, PostBranch, PutBranch, getAllBranch, getBranch } from "./api";


interface BranchFormPropsType {
    onHide?: () => void


}
export const BranchForm = ({ 
    onHide,
  
}: BranchFormPropsType) => {
     
    const [resetForm, setResetForm] = useState(false);
    const [response , setResponse ] = useState<Response | any>(null);
    const [editForm , setEditForm ] = useState< any>();
    const [selectedObj , setSelectedObj ] = useState< any>();
    const [mode , setmode ] = useState< any>();
    const [showForm , setShowForm ] = useState< any>(false);
    const [loading , setLoading] = useState(false)
    const [formLoading , setFormLoading] = useState<boolean>(false)
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setsearch] = useState(null);
    const [userChanges, setUserChanges] = useState({});
    const [forceUpdate, setForceUpdate] = useState(false);



    const clickToolbarCallback = (clickedButtonName: string) => {

        switch (clickedButtonName.toLowerCase()) {
          case "view":if(selectedObj?.Id) handleViewRow() ;break;
          case "edit":if(selectedObj?.Id) handleEditRow() ;break;
          case "print":alert("print ");break;
          case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
          case "refresh":setForceUpdate(!forceUpdate); break;
          case "create":handleCreateAction();break;
          case "excel" : downloadCSV(response ,1 ,trans("BranchForm"));break;

        }
      };

      const searchHandleer = (str) => {
        if(str =="" ){
            setsearch(null)
        }else{
            setsearch(str)
        }
      }

      const handleViewRow = () => {
        setmode("view")
        setShowForm(true)
    }

    const handleEditRow = () => {
      setmode("edit");
      setShowForm(true)
  }

  const handleCreateAction = () => {
    setEditForm(null)
    setmode("create")
    setUserChanges({})
    setShowForm(true)
    setResetForm(true)

}

const handleDeleteRow = async(Id =selectedObj.Id) => {
  try {
      const res  = await DeleteBranch(Id)
      setForceUpdate(!forceUpdate)
  } catch (error) {
      
  }
}
      

    async function sendNodeAsync(data) {
        try{
           const res = await PostBranch(data);
           setShowForm(false)
        }catch(err:any){

        }
        setResetForm(true);
        getBranchorganizationIdAsync()

    }


    async function putNodeAsync(data) {
        try{
           const res = await PutBranch(data);
           setShowForm(false)
        }catch(err:any){

        }
        setResetForm(true);
        getBranchorganizationIdAsync()
    }

    async function getBranchorganizationIdAsync() {
      setLoading(false)
      try{
        const res = await getAllBranch(orderBy.join(",") , search);
        setResponse(res.Data)
        setCatchMessage({type:"info",msg:"NoDataForPreview"})
      } catch (error:any) {
          setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
          }
          setLoading(true)
    }

    const selectedIdCallback = (obj, index) => {
      if(sessionStorage.getItem("changingforms")+""=="true"){
          Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false"); setUserChanges({}) });
      }else{
        setSelectedObj(obj)         
      };
    }

    const defaultFormData = async ()=>{
      setFormLoading(false)
      try {
        const res = await getBranch(selectedObj.Id); 
        setEditForm({Id: res.Data.Id , Code: res.Data.Code ,Description: res.Data.Description ,Name: res.Data.Name})     
        setCatchMessage({type:"info",msg:"NoDataForPreview"})
      } catch (error:any) {
          setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
      }
      setFormLoading(true)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let data:any = {}
        if (editForm == null) {
            sendNodeAsync({
              Code:event.target?.Code?.value,
              Description: event.target.Description.value,
              Name: event.target.Name.value,
              NameEN:event.target.NameEN.value
            }).then(() => {sessionStorage.setItem("changingforms" , "false");setUserChanges({}) ; getBranchorganizationIdAsync();cancel()})
        } else {

            putNodeAsync({
              Code:event.target?.Code?.value,
              Description: event.target.Description.value,
              Name: event.target.Name.value,
              ActivityStatus:+event.target.BranchStatus.value,
              Id: editForm.Id,
              NameEN:event.target.NameEN.value
            }).then(() => {sessionStorage.setItem("changingforms" , "false");setUserChanges({}) ; getBranchorganizationIdAsync();cancel()  })

        }
    }

    function cancel() {
      if(sessionStorage.getItem("changingforms")+""=="true"){
          Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
              sessionStorage.setItem("changingforms" , "false");
              setUserChanges({});
              setEditForm(null);
              setShowForm(false)
          });
      }else{
          setEditForm(null);
          setShowForm(false)     
      };
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };
  
    
  useEffect(() => {
    if(Object.values(userChanges).filter((item)=>item!=null).length>0){

        sessionStorage.setItem("changingforms" , "true")
          window.addEventListener('beforeunload', handleBeforeUnload);
          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
    }else{
        sessionStorage.setItem("changingforms" , "false")
        window.removeEventListener('beforeunload', handleBeforeUnload);

    }
}, [userChanges]);
  
    
     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);

      useEffect(() => {
        if (resetForm) {
          setResetForm(false);
      }
    }, [resetForm]);


    useEffect(() => {
      getBranchorganizationIdAsync()
    }, [forceUpdate, orderBy  ,search]);

    useEffect(() => {

      if(mode != "create" && showForm ){
          defaultFormData()
      }else{
        setFormLoading(true)
      }
    }, [selectedObj ,showForm,mode]);


    return (
        <div className="person-contact-form card p-3">
            <div className="">
            <Toolbar handleSearch={searchHandleer} emptyList={response == null || response?.length == 0} id={selectedObj?.Id} create={true} remove={true} edit={true} search={true}   handleClickToolbar={clickToolbarCallback} />
                    <div className="">
                      {showForm && <>
                                {formLoading? 
                                <>
                                   <div className="card mb-4">
                                   <form className="TypeSettingForm row card-body " onSubmit={handleSubmit}>
                                   <div className="row my-1">
                                   <div className="col-12 col-sm-4">
                                      <Textbox
                                         defaultValue={editForm?.Name}
                                         required={true}
                                         mode={mode} 
                                         textboxType="text"
                                         textboxName="Name"
                                         labelText="Name"
                                      />
                                     </div>

                                     <div className="col-12 col-sm-4">
                                      <Textbox
                                         defaultValue={editForm?.Name}
                                         required={true}
                                         mode={mode} 
                                         textboxType="text"
                                         textboxName="NameEN"
                                         labelText="NameEN"
                                      />
                                     </div>

                                     <div className="col-12 col-sm-4">
                                   <Textbox
                                         defaultValue={editForm?.Code}
                                         required={true}
                                         mode={mode}
                                         textboxType="text"
                                         textboxName="Code"
                                         labelText="Code"
                                     />
                                     </div>
                                       {mode != "create" &&          
                                          <div className="col-lg-4 mt-1">
                                            <EnumerationTypeSelect
                                            value={editForm?.ActivityStatus ? editForm?.ActivityStatus  : null}  
                                            name="BranchStatus"  
                                            mode={mode} 
                                            label="Status"
                                            enumTypeSelect="ActivityStatus"
                                            />
                                          </div>                            
                                          }
                                     <div className="col-12 col-sm-12 mt-3">
                                   <Textbox
                                         defaultValue={editForm?.Description}
                                         required={true}
                                         mode={mode}
                                         textboxType="textarea"
                                         textboxName="Description"
                                         labelText="Description"
                                     />
                                     </div>
                                    </div>
                                       {  mode !="view" ?
                                             <div className="col-lg-12 mt-1 btns_form">
                                             <Button value="Save" btnType="submit" />
                                             <Button btnType="cancel" value="Cancel" onClick={()=>{setShowForm(false)}} />
                                             </div>
                                             :
                                             <div className="col-lg-12 mt-1 btns_form">
                                             <Button btnType="primary" value="close" onClick={()=>{setShowForm(false)}} />
                                             </div>
                                         }                        
                                   </form>
                                 </div >
                                   </>
                                 :<Loading />
                                }
                                </>
                            }
                                
                    {loading ?
                        <>
                       {   response && response?.length>0 ?
                          <div className="mt-4">
                         <DataGrid
                          parentName={"BranchForm"}
                          orderFormatList={orderBy}                          
                          orderFormat={setOrderBy}
                          startIndex={1}
                          theadData={Object.keys(response[0])}
                          tbodyData={response}
                          minCellWidth={80}
                          scrollHeight={"300px"}
                          handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow)}}
                          handleView={handleViewRow}
                          handleEdit={handleEditRow}
                          selectedRowObjectCallback={selectedIdCallback}             
                          /> </div> 
                        : <Message message={catchMessage.msg} type={catchMessage.type} />    }                      
                        </>
    
                        : <Loading />
                        }
                    </div>
                </div>
        </div>
    );
}