

import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, trans } from "utils/helpers";

import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Toast } from "components/ToastShow/ToastShow";
import { getAllItemBarcode } from "./api";
import { ItemBarcodeForm } from "./ItemBarcodesForm";
import { Button } from "components/Button/Button";

interface Form {
    onHide: () => void;
    BarcodesListcallback: (list) => void;
}
export const ItemBarcodeList = ({onHide ,BarcodesListcallback}: Form) => {

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [userChanges, setUserChanges] = useState({});
    const [selectList , setSelectList] = useState<any>()


    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false"); setUserChanges({}) });
        }else{
            setSelectedObj(obj)         
        };
    }

    const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
    }

    async function getAllItemBarcodesAsync() {
        setLoading(false)
        try {
            const res = await getAllItemBarcode(search ,orderBy.length == 0 ?null:  orderBy.join(",") );
            setResponse(res.Data);
            setMessageType("info");
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }


    const handleCreateAction = () => {
        setMode("create")
        setShowForm(true)
    }

    const selectListCallBack =(list)=>{
        setSelectList(list[1])
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };

      const makeBarcodesList = (event) => {
        if(selectList != undefined){
            event.preventDefault();    
            BarcodesListcallback([...selectList])
            onHide()
        }else{
        }
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                onHide()
            });
        }else{
            onHide()    
        };

    }

    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){
            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [userChanges]);

    useEffect(() => {
        getAllItemBarcodesAsync();
    }, [forceUpdate ,search , orderBy ]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
            getAllItemBarcodesAsync();
        }
    }, [resetForm]);

    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("ItemBarcodesList"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "help": alert("call api help"); break;
            case "create": handleCreateAction(); break;
        }
    };

    return (
        <>
            <div className="cartable-action">
                <Toolbar warehouse={false}  handleSearch={searchHandler} view={false} remove={false}  id={selectedObj?.Id} emptyList={response?.Data?.length == 0} create={false} edit={false} search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                    <div className="">
                            <>
                                {showForm&& 
                                <>
                                  <ItemBarcodeForm reload={()=>{setForceUpdate(!forceUpdate)}} onHide={()=>{setShowForm(false)}} />
                                </>
                                }
                                {loading ?
                                    response?.length > 0 ?
                                        <>
                                            <DataGrid
                                                selectItem={(e) => {}}
                                                multiSelect={true}
                                                selectList={selectListCallBack}
                                                parentName={"ItemBarcodesList"}
                                                orderFormat={setOrderBy}
                                                orderFormatList={orderBy}
                                                startIndex={1}
                                                theadData={Object.keys(response[0])}
                                                tbodyData={response}
                                                minCellWidth={80}
                                                selectedRowObjectCallback={selectedIdCallback}
                                            />
                                        </>
                                        : <Message message={"NoDataForPreview"} type={messageType} />
                                    :<Loading/>
                                }
                                {  mode !="view"?
                                      <div className="col-lg-12 mt-1 btns_form">
                                        <Button onClick={makeBarcodesList} value="Save" btnType="submit" />
                                        <Button btnType="cancel" value="Cancel" onClick={cancel} />
                                      </div>
                                      :
                                      <div className="col-lg-12 mt-1 btns_form">
                                        <Button btnType="primary" value="close" onClick={cancel} />
                                      </div>

                                }   
                            </>
                    </div>
                </div>
            </div>
        </>

    );
}