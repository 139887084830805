import { Headerbar } from "components/Headerbar/Headerbar";
import { getCustomerCredit } from "./api";
import { useEffect, useState } from "react";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Footerbar } from "components/Footerbar/Footerbar";
import { getDefaultPersonContact } from "../api";
import { GroupingPriceFunction, trans } from "utils/helpers";

interface CustomerCreditControlFormPropsType {
  RequesterPositionId?: any;
}

export function CustomerCreditControlForm({
  RequesterPositionId,
}: CustomerCreditControlFormPropsType) {
  const [positionId, setPositionId] = useState(RequesterPositionId);

  const [creditList, setCreditList] = useState<any>(null);
  const [selectedObj, setSelectedObj] = useState<any>(null);

  const [loading, setLoading] = useState(true);
  const [messageType, setMessageType] = useState<any>("info");
  const [headerItem, setHeaderItem] = useState<any>(null);

  const getCustomerCreditAsync = async () => {
    try {
      const res = await getCustomerCredit(positionId);
      setCreditList(res.Data);
    } catch (error) {
      setMessageType("error");
    }
    setLoading(false);
  };

  const selectedObjCallback = (obj, index) => {
    setSelectedObj(obj);
  };

  const getDefaultPersonContactAsync = async () => {
    try {
      let res = await getDefaultPersonContact(
        creditList.Header.CustomerPersonId
      );

      if (res.Data?.Address) {
        let newHeaderItemObj = {
          ...res.Data,
          Address: JSON.parse(res.Data?.Address),
        };

        let str = "";
        Object.keys(newHeaderItemObj.Address).map((item) => {
          if (
            newHeaderItemObj.Address[item] !== "" &&
            newHeaderItemObj.Address[item] !== null
          ) {
            if (item !== "address") {
              str += trans(item) + newHeaderItemObj.Address[item] + " ";
            } else {
              str += newHeaderItemObj.Address[item] + " ";
            }
          }
        });

        setHeaderItem({ ...newHeaderItemObj, Address: str });
      } else {
        setHeaderItem(res.Data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (creditList) {
      getDefaultPersonContactAsync();
    }
  }, [creditList]);

  useEffect(() => {
    getCustomerCreditAsync();
  }, []);

  return (
    <div className="card">
      {/* headerbar component */}
      {headerItem ? <Headerbar headerContents={headerItem} /> : <Loading />}
      {/* data grid */}
      {!loading ? (
        !!creditList.Data?.length ? (
          <div className="">
            <DataGrid
              disabled
              parentName={"PersonGrid"}
              startIndex={0}
              theadData={Object.keys(creditList.Data[0])}
              tbodyData={creditList.Data}
              minCellWidth={80}
              selectedRowObjectCallback={selectedObjCallback}
              rowSelected={selectedObj}
            />
            <Footerbar
              footerContents={
                {
                  AccountBalance:
                    GroupingPriceFunction(creditList?.Footer.AccountBalance) +
                    " " +
                    trans(creditList?.Footer.TotalTag),
                } as any
              }
              hasBorder={false}
            />
          </div>
        ) : (
          <Message message={"NoDataForPreview"} type={messageType} />
        )
      ) : (
        <Loading />
      )}
    </div>
  );
}
