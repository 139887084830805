import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import img from '../../../../../assets/image/logo.png';
import { loadCardImageWithSize } from "../../api";
import "./style.scss";





interface ItemCardImgProps {
    attchId?: any,

}

export const ItemCardImg = ({attchId}:ItemCardImgProps) => {

    const [src, setSrc] = useState<any>(null);

    async function loadImageAsync() {
        try {
            if(attchId){

                const res:any = await loadCardImageWithSize(attchId );
                 setSrc(res);
            }
      
        } catch (error) {

        }
    }
    useEffect(() => {
  
    if(!attchId?.includes("0000")){
       loadImageAsync()
    }

    }, []);

    return (
     <>
     {src?<img  className={`card-img-top mt-3 card-image`} src={src ? src:img} draggable="false" style={{width:'70%!important',maxWidth:'70%!important',marginLeft:'0.25rem'}}/>:<FontAwesomeIcon icon={'file-circle-exclamation'}  style={{color:'#e2e8f0'
        }}  className="ms-md-4 mt-md-3 card-icon "/>}
    </>
     );
}
