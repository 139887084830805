import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ItemBuyImg } from "../ItemBuyImg";
import { Link } from "react-router-dom";
import { ItemCounterBtn } from "../../ItemCard/ItemCounterBtn";
import { GroupingPrice } from "components/GroupingPrice/GroupingPrice";
import { useEffect, useState } from "react";

interface ItemBoxProps {
    order?:any,
    item?: any,
    index?:number,
    UpdateTotal?:()=>void,
    styles?:any,
    handleSelectedItem?:any,

}



export const ItemBox = ({index,item,order,UpdateTotal,styles,handleSelectedItem}:ItemBoxProps) => {


    const [offerIndex,setOfferIndex] = useState<number>(order.priceIndex ? order.priceIndex : 0)
    const [minQty,setMinQty] = useState<any>(order.OrderItemId.SalesUnitMinOrderQty)
    const [maxQty,setMaxQty] = useState<any>(order.OrderItemId.SalesUnitMaxOrderQty)


   
    

    useEffect(()=>{
        // change
        if(+order.count > +order.max && order.max != null){
            setOfferIndex(offerIndex + 1)
        }

        if(+order.count < +order.min && +order.min > 1){
            setOfferIndex(offerIndex - 1)
        }

    },[order.count])

    useEffect(()=>{
        setMinQty(item.ItemPriceListDetails[0].PriceDetails[offerIndex]?.min)
        setMaxQty(item.ItemPriceListDetails[0].PriceDetails[offerIndex]?.max)
    },[offerIndex])




    return ( 
        <li key={index+'buyBtn__listItem__contents'}  className="d-flex flex-column justify-content-between w-100" onClick={()=>{handleSelectedItem&&handleSelectedItem(item)}} >
        <Link to={`/e-commerce/Item?item=${item?.Id}`} className="imgBox col-2 col-md-12 w-80">
        <div className="row h-75 w-100 px-1">
            <div className="">
                <div className="showDots p-1 d-sm-none buyBtn__listItem__contents__title">{order?.OrderItemId?.ItemDescription}</div>
            </div>

            <div className="col-4" style={{zIndex:-1,...styles}}>
               <ItemBuyImg attchId={item.Attachments[0]?.Id}/>
            </div>
            <div className={` ${styles?.display=="none"?'col-12':'col-8'} font-size-xl fw-normal`}  >
                <div style={{width:styles?.display=="none"?'100%':"unset"}}>
                    <span className="showDots d-none d-sm-block buyBtn__listItem__contents__title" style={{width:'90%'}}>{order?.OrderItemId?.ItemDescription} { Object.values(order?.properties).map(item=> <> {" "+ item+" "}</>) } </span>
                    <span className="showDots d-none d-sm-block buyBtn__listItem__contents__title text-muted" style={{width:styles?.display=="none"?'25%':"90%"}} >{item?.Code+order?.properties?.Variation1Code+order?.properties?.Variation2Code }</span>
                </div>
                <div className="row mt-2">
                {order.properties != undefined &&
                    <>
                    {order['properties']['color'] != undefined &&
                 
                    <div className="col-6">
                        <div className="d-flex align-items-center m-2" >
                            <FontAwesomeIcon icon={["fas", "brush"]} size="sm" className="text-muted rotate180"></FontAwesomeIcon>
                           
                            <div className="me-1 border" style={{width:'10px',height:'10px',backgroundColor:order['properties']['color'],borderRadius:'50%'}}></div>
                        </div>
                    </div>
                    }
                    
                    { order['properties']['size'] != undefined && 
               
                    <div className="col-6">
                        <div className="m-2 showDots" style={{fontSize:'13px'}}>
                            <FontAwesomeIcon icon={["fas", "ruler-vertical"]} className="text-muted rotate180"></FontAwesomeIcon>
                            <span className="me-1">{order['properties']['size']}</span>  
                        </div>
                    </div>
                    }
                    {order['properties']['matrial'] &&
                     
                    <div className="col-6">
                        <div className="m-2 showDots" style={{fontSize:'13px'}}>
                            <FontAwesomeIcon icon={["fas", "layer-group"]} className="text-muted"></FontAwesomeIcon>
                        <span className="me-1">{order['properties']['matrial']}</span>
                        </div>
                    </div>
                    }
                    {item?.increaserDecreaser?.length > 0 &&
                   
                    <div className="col-6">
                        <div className="m-2" style={{fontSize:'13px'}}>
                            <FontAwesomeIcon icon={["fas", "ellipsis"]}></FontAwesomeIcon>
                        </div>
                    </div>
                    }
                    </>
                    }
                </div>
            </div>
        </div>
        </Link>
        <div className="row h-25 w-100 mt-2 p-1" style={{fontSize:'16px'}}>
            <div className="d-flex align-items-center">
                <div className="" style={{width:'115px'}}>
                    <ItemCounterBtn   propertiesId={Object.keys(item.order).find(key => item.order[key] === order)} properties={order.properties} reload={UpdateTotal} stock={10000000000} min={order.OrderItemId.SalesUnitMinOrderQty} max={order.OrderItemId.SalesUnitMaxOrderQty} priceIndex={offerIndex} multiCounter={true} ItemData={item} />
                </div>
                <div className="d-flex flex-column align-items-center me-3" style={{width:'60%'}}>
                    {item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitDiscount != 0  &&
                    <div className="d-flex">
                    
                            <span className="text-muted me-2 pt-1 text-decoration-line-through" style={{fontSize:'13px'}}>
                            <GroupingPrice value={item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitPrice * +order.count }/>
                        </span>
                        
                    
                        <span className="d-flex justify-content-center align-items-center me-2 bg-customerPrimaryColor px-2 rounded-pill" style={{color:'#fff',fontSize:'13px'}}>{item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.DiscountPercent}%</span>
                    </div>
                    }
                    <div className="me-2" style={{fontSize:'16px'}}>
                    <GroupingPrice value={item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitDiscount != null  ? ((item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitPrice - item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitDiscount)* +order.count)  : item?.ItemPriceListDetails[0]?.PriceDetails[offerIndex]?.SalesUnitPrice}/>
                    </div>
                </div>
            </div>
        </div>
    </li>
     );
}
