import { useEffect, useState } from "react";
import { loadCardImageWithSize } from "../../api";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ItemBuyImgProps {
  attchId?: any;
  size?:any
}

export const ItemBuyImg = ({ attchId,size=256 }: ItemBuyImgProps) => {
  const [src, setSrc] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);

  async function loadImageAsync() {
    try {
      if (attchId) {
        const res: any = await loadCardImageWithSize(attchId,size);
        setSrc(res);
        setLoading(true)
      }
    } catch (error) {}
  }
  useEffect(() => {
    if (!attchId?.includes("0000")) {
      loadImageAsync();
    }
  }, [attchId]);

  return (
    <>
      <div className="col-2 imgBx w-100 h-100">
        {/*    {(attchId?.includes("0000")||!src) ?
            <div className="noneImg w-100 h-100 rounded-circle"></div>
            :
            <img src={"data:application/json;base64,"+src} draggable="false" className="w-100 h-100"/>
            
        } */}
        {loading ? (
          <img
            src={src}
            draggable="false"
            className={`w-100 h-100 `}
          />
        ) : (
          <FontAwesomeIcon
            icon={"file-circle-exclamation"}
            style={{ color: "#e2e8f0" }}
            className="ms-md-4 mt-md-3 card-icon "
          />
        )}
      </div>
    </>
  );
};
