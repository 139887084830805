import { EventCalendar } from "components/EventCalendar/EventCalendar";
import { CustomerRequests } from "pages/AfterSales/CustomerRequests";
import { DeviceDeliveries } from "pages/AfterSales/DeviceDeliveries/DeviceDeliveries";
import { CustomerGeneralRequestClassificationSetting } from "pages/AfterSales/Settings/CustomerGeneralRequestClassification/CustomerGeneralRequestClassification";
import { DevicePropertiesSetting } from "pages/AfterSales/Settings/DeviceProperties/DeviceProperties";
import { PaymentCoverageTypeSetting } from "pages/AfterSales/Settings/PaymentCoverageTypeSetting/PaymentCoverageTypeSetting";
import { ServiceRequestClassificationsSetting } from "pages/AfterSales/Settings/ServiceRequestClassifications/ServiceRequestClassifications";
import { DashboardPage } from "pages/DashboardPage/DashboardPage";
import { NotFoundPage } from "pages/NotFoundPage/NotFoundPage";
import { GroupPositionList } from "pages/OfficeAutomation/BaseInformation/GroupPositionPage/GroupPositionList/GroupPositionList";
import { OrganizationalStructure } from "pages/OfficeAutomation/BaseInformation/OrganizationaPage/OrganizationalStructure";
import { PersonalArchiveStructure } from "pages/OfficeAutomation/BaseInformation/PersonalArchive/PersonalArchiveStructure/PersonalArchiveStructure";
import { PersonalArchiveTree } from "pages/OfficeAutomation/BaseInformation/PersonalArchive/PersonalArchiveTree/PersonalArchiveTree";
import { PersonsPage } from "pages/OfficeAutomation/BaseInformation/PersonsPage/PersonsPage";

import { UnitSetting } from "pages/InventoryAndSales/Inventory/BaseDefinitionAndSetting/Unit/Unit";
import { BarcodesSetting } from "pages/InventoryAndSales/Inventory/BaseDefinitionAndSetting/barcodes/barcodes";
import { ItemTree } from "pages/InventoryAndSales/Inventory/ItemTree/ItemTree";
import { WarehouseStructure } from "pages/InventoryAndSales/Inventory/Warehouse/WarehouseStructure/WarehouseStructure";
import { PeymentMethod } from "pages/InventoryAndSales/Sales/BaseDefinitionAndSetting/PeymentMethod/PeymentMethod";
import { SalesDocsClassification } from "pages/InventoryAndSales/Sales/BaseDefinitionAndSetting/SalesDocsClassification/DocsClassification";
import { PriceList } from "pages/InventoryAndSales/Sales/PriceList/PriceList";
import { CartablePage } from "pages/OfficeAutomation/Cartable/CartablePage";
import { Chat } from "pages/OfficeAutomation/Chat/Chat";
import { PersonalPlaning } from "pages/OfficeAutomation/Personal/PersonalPlaning/PersonalPlaning";
import { PersonalCalendar } from "pages/OfficeAutomation/Personal/personalCalendar/PersonalCalendar";
import { DailyLeaveClassification } from "pages/OfficeAutomation/Settings/DailyLeaveClassification/DailyLeaveClassification";
import { GeneralRequestSetting } from "pages/OfficeAutomation/Settings/GeneralRequestClassifications/GeneralRequestClassifications";
import { PersonTitlesPage } from "pages/OfficeAutomation/Settings/PersonTitles/PersonTitles";
import { PostLevelPage } from "pages/OfficeAutomation/Settings/PostLevelPage/PostLevelPage";
import { ProcessSetting } from "pages/OfficeAutomation/Settings/ProcessSetting/ProcessSetting";
import { ReferenceSubjectSetting } from "pages/OfficeAutomation/Settings/ReferenceSubjectSetting/ReferenceSubjectSetting";
import { SendMethodSetting } from "pages/OfficeAutomation/Settings/SendMethodSetting/SendMethodSetting";
import { ArchiveStructure } from "pages/Secretariats/Archive/ArchiveStructure/ArchiveStructure";
import { ArchiveTree } from "pages/Secretariats/Archive/ArchiveTree/ArchiveTree";
import { LettersPage } from "pages/Secretariats/LettersPage/LettersPage";
import { DocumnetArchiveRequestClassificationSetting } from "pages/Secretariats/Settings/DocumnetArchiveRequestClassification/DocumnetArchiveRequestClassification";
import { IndicatorPatterns } from "pages/Secretariats/Settings/IndicatorPatterns/IndicatorPatterns";
import { LetterSubjectTypeSetting } from "pages/Secretariats/Settings/LetterSubjectTypeSetting/LetterSubjectTypeSetting";
import { Secretariats } from "pages/Secretariats/secretariats/secretariats";
import { BranchForm } from "pages/SystemManagement/BranchForm/BranchForm";
import { ChatSettingForm } from "pages/SystemManagement/ChatSettingForm/ChatSettingForm";
import { JobsList } from "pages/SystemManagement/Jobs/Jobs";
import { PeriodForm } from "pages/SystemManagement/PeriodForm/PeriodForm";
import { OrganizationalAccesses } from "pages/SystemManagement/Settings/OrganizationalAccesses/OrganizationalAccesses";
import { UserList } from "pages/SystemManagement/Settings/Users/components/UserList/components/UserList";
import { UnAuthorizedPage } from "pages/UnAuthorizedPage/UnAuthorizedPage";
import { CartableType } from "types/enum";

import { WarehouseItem } from "pages/InventoryAndSales/Inventory/WarehouseItem/WarehouseItem";
import { BusinessActivityType } from "pages/InventoryAndSales/Sales/BaseDefinitionAndSetting/BusinessActivityType/BusinessActivityType";
import { CustomerClassification } from "pages/InventoryAndSales/Sales/BaseDefinitionAndSetting/CustomerClassification/CustomerClassification";
import { GeographicalClassification } from "pages/InventoryAndSales/Sales/BaseDefinitionAndSetting/GeographicalClassification/GeographicalClassification";
import { SalesOrderStatus } from "pages/InventoryAndSales/Sales/BaseDefinitionAndSetting/SalesOrderStatus/SalesOrderStatus";
import { MessageSetting } from "pages/SystemManagement/Settings/MessageSetting/MessageSetting";

import { CustomerPositionList } from "pages/AfterSales/CustomerPositionList/CustomerPositionList";
import { ECommercePage } from "pages/ClientPortal/ECommerce/ECommerce";
import { ItemDetailPage } from "pages/ClientPortal/ECommerce/ItemDetail/ItemDetail";
import { CartPage } from "pages/ClientPortal/ECommerce/components/Cart/Cart";
import { ECommerceDEMO } from "pages/ClientPortal/ECommerceDEMO/ECommerceDEMO";
import { CartPage2 } from "pages/ClientPortal/ECommerceDEMO/components/Cart/Cart";
import { ItemDetailPage2 } from "pages/ClientPortal/ECommerceDEMO/components/ItemDetail/ItemDetail";
import { MyOwnOrdersPage } from "pages/ClientPortal/MyOwnOrders/MyOwnOrdersPage";
import { MyOwnRequestsPage } from "pages/ClientPortal/MyOwnRequests/MyOwnRequestsPage";
import { InventoryDocsClassification } from "pages/InventoryAndSales/Inventory/BaseDefinitionAndSetting/InventoryDocsClassification/DocsClassification";
import { InventorySystemSetting } from "pages/InventoryAndSales/Inventory/BaseDefinitionAndSetting/InventorySystemSetting/InventorySystemSetting";
import { ItemMainProperty } from "pages/InventoryAndSales/Inventory/BaseDefinitionAndSetting/ItemMainProperty/ItemMainProperty";
import { ItemGroups } from "pages/InventoryAndSales/Inventory/ItemGroups/ItemGroups";
import { ItemGroupCodeSettingForm } from "pages/InventoryAndSales/Inventory/ItemTree/ItemGroupCodeSetting";
import { Currency } from "pages/InventoryAndSales/Sales/BaseDefinitionAndSetting/Currency/Currency";
import { Customers } from "pages/InventoryAndSales/Sales/BaseDefinitionAndSetting/Customers/Customers";
import { SalesInvoiceStatus } from "pages/InventoryAndSales/Sales/BaseDefinitionAndSetting/SalesInvoiceStatus/SalesInvoiceStatus";
import { SalesPreInvoiceStatus } from "pages/InventoryAndSales/Sales/BaseDefinitionAndSetting/SalesPreInvoiceStatus/SalesPreInvoiceStatus";
import { SalesSystemSetting } from "pages/InventoryAndSales/Sales/BaseDefinitionAndSetting/SalesSystemSetting/SalesSystemSetting";
import { SalesInvoices } from "pages/InventoryAndSales/Sales/SalesInvoice/SalesInvoice";
import { SalesOrders } from "pages/InventoryAndSales/Sales/SalesOrder/SalesOrder";
import { SalesPreInvoices } from "pages/InventoryAndSales/Sales/SalesPreInvoice/SalesPreInvoice";
import { SalesReturnInvoices } from "pages/InventoryAndSales/Sales/SalesReturnInvoice/SalesReturnInvoice";
import { Meeting } from "pages/MeetingManagement/Meeting/Meeting";
import { MeetingClassification } from "pages/MeetingManagement/Settings/MeetingClassification/MeetingClassification";
import { MeetingPlace } from "pages/MeetingManagement/Settings/MeetingPlace/MeetingPlace";
import { MyRequests } from "pages/OfficeAutomation/Cartable/MyRequests/MyRequests";
import { Boards } from "pages/OfficeAutomation/Features/BulletinBoard/Boards/Boards";
import { CalendarEvents } from "pages/OfficeAutomation/Features/Calendar/Events/CalendarEvents";
import { OrganizationEvents } from "pages/OfficeAutomation/Features/Calendar/OrganizationEvents/OrganizationEvents";
import { SharingCenters } from "pages/OfficeAutomation/Features/SharingCenterArticle/SharingCenteres/SharingCenteres";
import { FormBuilder } from "pages/OfficeAutomation/FormBuilder/FormBuilder";
import { DynamicFormsPage } from "pages/OfficeAutomation/FormBuilder/components/DynamicFormsPage/DynamicFormsPage";
import { City } from "pages/OfficeAutomation/Settings/City/City";
import { Province } from "pages/OfficeAutomation/Settings/Province/Province";
import { SharingCenterArticleSetting } from "pages/OfficeAutomation/Settings/SharingCenterArticleClassification/SharingCenterArticleClassification";
import { PersonnelCartable } from "pages/SystemManagement/PersonnelCartable/PersonnelCartable";
import { Reports } from "pages/SystemManagement/Reports/Reports";
import { BankAccountType } from "pages/TreasuryAndAccounting/Treasury/BaseDefinitionAndSetting/BankAccountType/BankAccountType";
import { BankPOS } from "pages/TreasuryAndAccounting/Treasury/BaseDefinitionAndSetting/BankPOS/BankPOS";
import { DocsClassification } from "pages/TreasuryAndAccounting/Treasury/BaseDefinitionAndSetting/DocsClassification/DocsClassification";
import { Bank } from "pages/TreasuryAndAccounting/Treasury/BaseInformation/Bank/Bank";
import { BankAccounts } from "pages/TreasuryAndAccounting/Treasury/BaseInformation/BankAccounts/BankAccounts";
import { CashAccount } from "pages/TreasuryAndAccounting/Treasury/BaseInformation/CashAccount/CashAccount";
import { TreasurySubject } from "pages/TreasuryAndAccounting/Treasury/BaseInformation/TreasurySubject/TreasurySubject";
import { Receipt } from "pages/TreasuryAndAccounting/Treasury/Receipt/Receipt";
import { MyOwnInvoice } from "pages/ClientPortal/MyOwnInvoice/MyOwnInvoice";

export const ROUTES = {
  OfficeAutomation: {
    personalArchive: {
      title: "personalArchive",
      route: "/personal-archive",
      routes: {
        archives: {
          title: "personalArchive",
          route: "/personal-archive/personal-archives",
          component: PersonalArchiveTree,
        },
        personalArchiveStructure: {
          title: "personalArchiveStructure",
          route: "/personal-archive/personal-archive-structure",
          component: PersonalArchiveStructure,
        },
      },
    },

    FormBuilder: {
      title: "formBuilder",
      route: "/form-builder",
      component: FormBuilder,
    },

    DynamicFormsPage: {
      title: "DynamicFormsPage",
      route: "/dynamic-forms",
      component: DynamicFormsPage,
    },
    Settings: {
      title: "Settings",
      route: "/settings",
      routes: {
        province: {
          title: "province",
          route: "/settings/province",
          component: Province,
        },
        city: {
          title: "city",
          route: "/settings/city",
          component: City,
        },
        dailyLeaveRequestClassificationSetting: {
          title: "dailyLeave",
          route: "/settings/daily-leave-request-classification-setting",
          component: DailyLeaveClassification,
        },

        generalRequestClassificationSetting: {
          title: "generalRequestClassification",
          route: "/settings/general-request-classification-setting",
          component: GeneralRequestSetting,
        },
        sharingCenterClassificationSetting: {
          title: "sharingCenterClassificationSetting",
          route: "/settings/sharing-center-classification-setting",
          component: SharingCenterArticleSetting,
        },
        postLevelSetting: {
          title: "PostLevel",
          route: "/settings/post-level-setting",
          component: PostLevelPage,
        },
        personTitles: {
          title: "personTitles",
          route: "/settings/person-titles",
          component: PersonTitlesPage,
        },
        referenceSubjectSetting: {
          title: "ReferenceSubject",
          route: "/settings/reference-subject-setting",
          component: ReferenceSubjectSetting,
        },
        jobSendMethod: {
          title: "SendMethod",
          route: "/settings/send-method",
          component: SendMethodSetting,
        },
      },
    },
    PersonalPlaning: {
      title: "PersonalPlaning",
      route: "/personal-planing",
      routes: {
        personalTasks: {
          title: "personalTasks",
          route: "/personal-planing/personal-tasks",
          component: PersonalPlaning,
        },
        personalCalendar: {
          title: "personalCalendar",
          route: "/personal-planing/personal-calendar",
          component: PersonalCalendar,
        },
      },
    },
    BaseInformation: {
      title: "BaseInformations",
      route: "/base-information",
      routes: {
        persons: {
          title: "Persons",
          route: "/base-information/persons",
          component: PersonsPage,
        },
        organizationalStructure: {
          title: "OrganizationalStructure",
          route: "/base-information/organizational-structure",
          component: OrganizationalStructure,
        },
        positions: {
          title: "groupPositions",
          route: "/base-information/group-positions",
          component: GroupPositionList,
        },
      },
    },

    features: {
      title: "features",
      route: "/features",
      //component: Calendar,
      routes: {
        calendarEvents: {
          title: "CalendarEvents",
          route: "/features/calendar-events",
          component: CalendarEvents,
        },
        organizationEvents: {
          title: "OrganizationEvents",
          route: "/features/organization-events",
          component: OrganizationEvents,
        },
        organizationCalendar: {
          title: "OrganizationCalendar",
          route: "/features/organization-calendar",
          component: EventCalendar,
          // component: MeetingCalendar,
        },
        bulletinBoard: {
          title: "bulletinBoard",
          route: "/features/bulletin-board",
          component: Boards,
        },
        SharingCenter: {
          title: "sharingcenterarticle",
          route: "/features/sharing-center",
          component: SharingCenters,
        },
      },
    },
    cartable: {
      title: "Cartable",
      route: "/cartable",
      //component:
      routes: {
        current: {
          title: "Current",
          route: "/cartable/current",
          type: CartableType.Current, //CartableType[0]
          component: CartablePage,
        },
        referred: {
          title: "Referred",
          route: "/cartable/referred",
          type: CartableType.Referred,
          component: CartablePage,
        },
        draft: {
          title: "Draft",
          route: "/cartable/draft",
          type: CartableType.Draft,
          component: CartablePage,
        },
        finished: {
          title: "Finished",
          route: "/cartable/finished",
          type: CartableType.Finished,
          component: CartablePage,
        },
        followUp: {
          title: "FollowUp",
          route: "/cartable/follow-up",
          type: CartableType.Followup,
          component: CartablePage,
        },
        onGoing: {
          title: "Ongoing",
          route: "/cartable/ongoing",
          type: CartableType.Ongoing,
          component: CartablePage,
        },
        advancedSearch: {
          title: "AdvancedSearch",
          route: "/cartable/advanced-search",
          type: "AdvancedSearch",
          component: CartablePage,
        },
        personnelCartable: {
          title: "personnelCartable",
          route: "/cartable/personnel-cartable",
          component: PersonnelCartable,
        },
        myRequests: {
          title: "myRequests",
          route: "/cartable/my-requests",
          component: MyRequests,
        },
      },
    },
  },
  SecretariatAndArchive: {
    secretariat: {
      title: "Secretariat",
      route: "/secretariat",
      //component:
      routes: {
        secretariatManagement: {
          title: "secretariatManagement",
          route: "/secretariat/secretariat-management",
          component: Secretariats,
        },
        letter: {
          title: "Letter",
          route: "/secretariat/letters",
          component: LettersPage,
        },
      },
    },
    archive: {
      title: "Archive",
      route: "/archive",
      routes: {
        archives: {
          title: "currentArchive",
          route: "/archive/current-archive",
          component: ArchiveTree,
        },
        archiveStructure: {
          title: "archiveStructure",
          route: "/archive/archive-structure",
          component: ArchiveStructure,
          //WarehouseStructure
        },
      },
    },
    Settings: {
      title: "Settings",
      route: "/settings",
      //component:
      routes: {
        indicatorPatterns: {
          title: "indicatorPatterns",
          route: "/settings/letter-number-patterns",
          component: IndicatorPatterns,
        },
        letterSubjectTypeSetting: {
          title: "lettersubject",
          route: "/settings/letter-subject",
          component: LetterSubjectTypeSetting,
        },
        documnetArchiveRequestClassification: {
          title: "documentarchiverequestclassification",
          route: "/settings/documnet-archive-request-classification-setting",
          component: DocumnetArchiveRequestClassificationSetting,
        },
      },
    },
  },
  MeetingManagement: {
    meeting: {
      title: "meetings",
      route: "/meetings",
      component: Meeting,
    },
    settings: {
      title: "settings",
      route: "/settings",
      //component:
      routes: {
        meetingClassification: {
          title: "meetingClassification",
          route: "/settings/meeting-classification",
          component: MeetingClassification,
        },
        meetingPlaces: {
          title: "meetingPlaces",
          route: "/settings/meeting-places",
          component: MeetingPlace,
        },
      },
    },
  },
  InventoryManagement: {
    BaseInformation: {
      title: "BaseInformations",
      route: "/base-information",
      //component:
      routes: {
        measurementUnits: {
          title: "measurementUnits",
          route: "/base-information/measurement-units",
          component: UnitSetting,
        },
        warehouses: {
          title: "warehouses",
          route: "/base-information/warehouses",
          component: WarehouseStructure,
        },
        items: {
          title: "items",
          route: "/base-information/items",

          component: ItemTree,
        },
        ItemGroups: {
          title: "ItemGroup",
          route: "/base-information/item-groups",
          component: ItemGroups,
        },

        WarehouseItem: {
          title: "warehouseitems",
          route: "/base-information/warehouse-items",

          component: WarehouseItem,
        },
      },
    },
    settings: {
      title: "settings",
      route: "/settings",
      //component:
      routes: {
        barcodes: {
          title: "barcodes",
          route: "/settings/barcodes",
          component: BarcodesSetting,
        },
        ItemMainProperty: {
          title: "itemproperties",
          route: "/settings/item-main-properties",
          component: ItemMainProperty,
        },
        ItemGroupCodeFormat: {
          title: "ItemGroupCodeFormat",
          route: "/settings/item-group-code-format",
          component: ItemGroupCodeSettingForm,
        },
        InventoryDocsClassification: {
          title: "InventoryDocsClassification",
          route: "/settings/inventory-document-classification-setting",
          component: InventoryDocsClassification,
        },
      },
    },
  },
  Sales: {
    BaseInformation: {
      title: "BaseInformations",
      route: "/base-information",
      //component:
      routes: {
        pricelist: {
          title: "pricelist",
          route: "/base-information/price-lists",
          component: PriceList,
        },
        currency: {
          title: "currency",
          route: "/base-information/currencies",
          component: Currency,
        },
        customers: {
          title: "customers",
          route: "/base-information/customers",
          component: Customers,
          //SalesPreInvoices,
          // SalesInvoices,
        },
      },
    },
    operations: {
      title: "operations",
      route: "/operations",
      //component:
      routes: {
        order: {
          title: "SalesOrder",
          route: "/operations/order",
          component: SalesOrders,
        },
        salesPreinvoice: {
          title: "SalesPreinvoice",
          route: "/operations/sales-preinvoice",
          component: SalesPreInvoices,
        },
        salesInvoice: {
          title: "Invoice",
          route: "/operations/sales-invoice",
          component: SalesInvoices,
        },
        salesReturnInvoice: {
          title: "salesReturnInvoice",
          route: "/operations/sales-return-invoice",
          component: SalesReturnInvoices,
        },
      },
    },
    settings: {
      title: "settings",
      route: "/settings",
      //component:
      routes: {
        customerclassification: {
          title: "customerclassification",
          route: "/settings/customer-classification-setting",
          component: CustomerClassification,
        },
        businessActivityTypes: {
          title: "businessactivitytype",
          route: "/settings/business-activity-types",
          component: BusinessActivityType,
        },
        geographicalClassification: {
          title: "geographicalClassification",
          route: "/settings/geographical-classification-setting",
          component: GeographicalClassification,
        },
        salesOrderStatus: {
          title: "salesOrderStatus",
          route: "/settings/sales-order-status",
          component: SalesOrderStatus,
        },
        salesInvoiceStatus: {
          title: "salesinvoicestatus",
          route: "/settings/sales-invoice-status",
          component: SalesInvoiceStatus,
        },
        salesPreInvoiceStatus: {
          title: "salespreinvoicestatus",
          route: "/settings/sales-preinvoice-status",
          component: SalesPreInvoiceStatus,
        },
        salesSystemSettings: {
          title: "systemsettings",
          route: "/settings/sales-system-settings",
          component: SalesSystemSetting,
        },
        paymentMethods: {
          title: "paymentMethods",
          route: "/settings/payment-methods",
          component: PeymentMethod,
        },
        inventorySystemSettings: {
          title: "systemsettings",
          route: "/settings/inventory-system-settings",
          component: InventorySystemSetting,
        },

        salesDocumentClassification: {
          title: "salesDocumentClassification",
          route: "/settings/sales-document-classification-setting",
          component: SalesDocsClassification,
        },
      },
    },
  },
  ManagementReports: {},
  SystemManagement: {
    users: {
      title: "usersAndAccesses",
      route: "/users-and-accesses",
      component: UserList,
    },
    accessesInOrganizationalStructure: {
      title: "accessesInOrganizationalStructure",
      route: "/accesses-in-organizational-structure",
      component: OrganizationalAccesses,
    },
    processes: {
      title: "processes",
      route: "/processes",
      //component:
      routes: {
        processSetting: {
          title: "organizationProcesses",
          route: "/processes/organization-processes",
          component: ProcessSetting,
        },
      },
    },
    Jobs: {
      title: "jobs",
      route: "/jobs",
      component: JobsList,
    },
    Reports: {
      title: "reports",
      route: "/reports",
      component: Reports,
    },

    settings: {
      title: "settings",
      route: "/settings",
      //component:
      routes: {
        messageSettings: {
          title: "messageSettings",
          route: "/settings/message-settings",
          component: MessageSetting,
        },
        branches: {
          title: "branch",
          route: "/settings/branches",
          component: BranchForm,
        },
        periods: {
          title: "period",
          route: "/settings/periods",
          component: PeriodForm,
        },
        chatSettings: {
          title: "chatSettings",
          route: "/settings/chat-settings",
          component: ChatSettingForm,
        },
      },
    },
  },
  AfterSalesServices: {
    customers: {
      title: "customers",
      route: "/customer-list",
      component: CustomerPositionList,
    },
    customerRequests: {
      title: "customerRequests",
      route: "/customer-requests",
      component: CustomerRequests,
    },
    DeviceDeliveries: {
      title: "deviceDeliveries",
      route: "/device-deliveries",
      component: DeviceDeliveries,
    },
    Settings: {
      title: "Settings",
      route: "/settings",
      //component:
      routes: {
        ServiceRequestClassificationsSetting: {
          title: "CustomerServicerequestClassifications",
          route: "/settings/customer-service-request-classification-setting",
          component: ServiceRequestClassificationsSetting,
        },
        CustomerGeneralRequestClassification: {
          title: "CustomerGeneralRequestClassifications",
          route: "/settings/customer-general-request-classification-setting",
          component: CustomerGeneralRequestClassificationSetting,
        },
        deviceProperties: {
          title: "deviceProperties",
          route: "/settings/device-properties",
          component: DevicePropertiesSetting,
        },

        PaymentCoverageTypeSetting: {
          title: "PaymentCoverageTypeSetting",
          route: "/settings/payment-coverage-type",
          component: PaymentCoverageTypeSetting,
        },
      },
    },
  },
  ClientPortal: {
    myOwnRequests: {
      title: "myOwnRequests",
      route: "/my-own-requests",
      component: MyOwnRequestsPage,
    },
    MyOwnOrdersPage: {
      title: "MyOwnOrders",
      route: "/my-own-orders",
      component: MyOwnOrdersPage,
    },
    myOwnInvoices: {
      title: "myOwnInvoices",
      route: "/my-own-invoices",
      component: MyOwnInvoice,
    },
  },
  TadbirChat: {
    message: {
      title: "Message",
      route: "/message",
      component: Chat,
    },
  },

  noneApplication: {
    dashboard: {
      title: "dashboard",
      route: "/",
      component: DashboardPage,
      auth: true,
    },
    ECommerce: {
      title: "ECommerce",
      route: "/e-commerce",
      component: ECommercePage,
      routes: {
        Items: {
          title: "ItemList",
          route: "/e-commerce/Items",
          component: ECommercePage,
        },
        Items2: {
          title: "ItemList",
          route: "/e-commerce/Items2",
          component: ECommerceDEMO,
        },
        item: {
          title: "ItemDetail",
          route: "/e-commerce/Item",
          component: ItemDetailPage,
        },
        item2: {
          title: "ItemDetail",
          route: "/e-commerce/Item2",
          component: ItemDetailPage2,
        },
        cart2: {
          title: "Cart",
          route: "/e-commerce/CartPage2",
          component: CartPage2,
        },
        cart: {
          title: "Cart",
          route: "/e-commerce/CartPage",
          component: CartPage,
        },
      },
    },

    pageNotFound: {
      title: "pageNotFound",
      route: null,
      component: NotFoundPage,
    },

    unauthorized: {
      title: "unauthorized",
      route: "/access-denied",
      component: UnAuthorizedPage,
    },
  },
  Treasury: {
    BaseInformation: {
      title: "BaseInformations",
      route: "/base-information",
      //component:
      routes: {
        bankAccounts: {
          title: "BankAccount",
          route: "/base-information/bank-accounts",
          component: BankAccounts,
        },
        bank: {
          title: "Banks",
          route: "/base-information/banks",
          component: Bank,
        },
        treasurySubject: {
          title: "TreasurySubjects",
          route: "/base-information/treasury-subjects",
          component: TreasurySubject,
        },
        CashAccount: {
          title: "CashAccounts",
          route: "/base-information/cash-accounts",
          component: CashAccount,
        },
      },
    },
    settings: {
      title: "settings",
      route: "/settings",
      //component:
      routes: {
        documentClassification: {
          title: "docsclassification",
          route: "/settings/document-classifications",
          component: DocsClassification,
        },
        bankPOS: {
          title: "BankPOS",
          route: "/settings/bank-poses",
          component: BankPOS,
        },
        BankAccountType :{
            title: "BankAccountTypes",
            route: "/settings/bank-account-types",
            component: BankAccountType,
          },
      },
    },
    operations: {
      title: "operations",
      route: "/operations",
      //component:
      routes: { 
        Receipt: {
          title: "Receipt",
          route: "/operations/receipts",
          component: Receipt,
        },
      },
    },
  },
};
