import { Button } from "components/Button/Button";
import { TabBar } from "components/Tabbar/Tabbar";
import { Attachment } from "pages/OfficeAutomation/Cartable/components/ActionList/components/Attachment/Attachment";
import { useEffect, useRef, useState } from "react";
import { ENDPOINTS } from "utils/endpoints";
import { getReceivers, getSentHistory, postLetter, putLetter } from "../../api/api";
import { LetterRelated } from "./components/LetterRelated/LetterRelated";
import { LetterInformationForm } from "./components/LetterInformationForm/LetterInformationForm";
import { LetterReceiver } from "./components/LetterReceiver/LetterReceiver";
import { LetterSend } from "./components/LetterSend/LetterSend";
import { MessageType } from "types/enum";
interface Form {
  onHide: () => void;
  reload?: (flag) => void;
  handleShowReferenceStepper?: (referenceId: string) => void;

  Id?: any;
  jobId?: any;
  mode: any;
  type?: any;
  step?:any;
  processId?: any;
  referenceId?: any;
  letterSelectedId?: any;
  editInIndicator?: any;
  inDynamicForms?: any;
}
export const LetterForm = ({ inDynamicForms=false,onHide,jobId, Id,letterSelectedId,editInIndicator=false , mode,step, reload ,handleShowReferenceStepper, type ,processId ,referenceId}: Form) => {

  const [curentTab, setCurentTab] = useState<any>("LetterForm")
  const [informationData, setInformationData] = useState<any>()
  const [relatedList, setRelatedList] = useState<any>()
  const [letterReceiverList, setLetterReceiverList] = useState<any>()
  const [letterId, setLetterId] = useState<any>(letterSelectedId)
  const [receiver, setReceiver] = useState<any>(null)
  const [showReferenceStepper, setShowReferenceStepper] = useState<boolean>(false);
  const [positionObjectList, setPositionObjectList] = useState<any>([]);

  const InformationData = async (data, image, method ,Indicator ) => {
    let ReceiverList:any = []
    if(letterReceiverList !=undefined && letterReceiverList.length>0 && type != "incoming"){
      letterReceiverList.map(item=>{
        if(item.Id.includes("GroupPosition")){
          ReceiverList.push({
            GroupPositionId  : item.Id.replace("_GroupPosition" , "")==" "? null:item.Id.replace("_GroupPosition" , "") ,
            ReceiverDescription: item.Name,
            IsCopy:item.IsCopy,
            Description:item.Description ,
            IsDefault : item.IsDefault

          })
          
        }else{
          ReceiverList.push({
            PositionId  : item.Id.replace("_Position" , "")==" "? null:item.Id.replace("_Position" , "") ,
            ReceiverDescription: item.Name,
            IsCopy:item.IsCopy,
            Description:item.Description ,
            IsDefault : item.IsDefault
          })
        }
  
      })
    }else if(receiver){
      if(receiver.Id.includes("GroupPosition")){
          ReceiverList.push({
          GroupPositionId  : receiver.Id.replace("_GroupPosition" , "")==" "? null:receiver.Id.replace("_GroupPosition" , "") ,
          ReceiverDescription: receiver.Name,
          IsCopy:receiver.IsCopy,
          Description:receiver.Description ,
          IsDefault : receiver.IsDefault

        })
        
      }else{
        ReceiverList.push({
          PositionId  : receiver.Id.replace("_Position" , "")==" "? null:receiver.Id.replace("_Position" , "") ,
          ReceiverDescription: receiver.Name,
          IsCopy:receiver.IsCopy,
          Description:receiver.Description ,
          IsDefault : receiver.IsDefault
        })
      }


    }


    let fristLetter:any = {
      "Letter": data,
      "LetterRelateds": relatedList,
      "LetterReceivers" : ReceiverList
    }
    if(Indicator){
      fristLetter.Indicator = Indicator
    }
    let _objData = new FormData

    _objData.append("LetterDTO", JSON.stringify(fristLetter));
  //  _objData.append("PRJ_UID", JSON.stringify(processId));
   // _objData.append("KeyWords", KeyWords);
    _objData.append("file", image);


    try {
      let res:any = {}
      if (letterId && method === "put") {
        res = await putDataAsync(fristLetter , referenceId)
      } else {
        res = await sendDataAsync(_objData, "post" ,referenceId ?referenceId:null ,processId)
     //   setLetterId(res)
      }
      if (showReferenceStepper) {
        handleShowReferenceStepper && handleShowReferenceStepper(!(res.MessageType ==MessageType.success) ? false:res.Data);
      } else {

      
        onHide()
      }
    } catch (error) {

    }
    setInformationData(data)
  }
  const RelatedData = (data) => {
    setRelatedList(data)
  }

  const sendDataAsync = async (letter, method ,referenceId ,processId) => {
    try {
     return await postLetter(letter, processId ,referenceId )
    } catch (error) {
    }
  }

  const putDataAsync = async (letter ,referenceId) => {
    try {
      const res = await putLetter(letter ,referenceId)
      return res.data
    } catch (error) {
    }
  }
  const getLetterReceiverPositionsAsync = async() => {
   
    try {
      if(jobId){
        const Receivers = await getReceivers(jobId)
        
        const Historys = await getSentHistory(jobId)
        let positionObjList :any= []
         Object.keys(Receivers.Data).map(item=>{
            
            let itemHistory:any = []
            if(Historys.Data){
               itemHistory =  Historys.Data.filter(obj=> obj.LetterReceiverId == item)
            }
        if(itemHistory[0]){
          positionObjList.push({
                "Id" : itemHistory[0].Id,
                 "JobId": jobId,
                 "SendOperatorPositionId": itemHistory[0].SendOperatorPositionId,
                 "ReceiverId" : item,
                 "SendMethodId":itemHistory[0].SendMethodId,
                 "Receiver" :Receivers.Data[item] ,
                 "ReceiverPerson":  itemHistory[0].ReceiverPerson,
                 "SentDescription":  itemHistory[0].SentDescription,
                 "SentTime": itemHistory[0].SentTime,
                 "IsSent": itemHistory[0].IsSent,
                 "IsReceived":itemHistory[0].IsReceived,
                 "ReceiveTime": itemHistory[0].ReceiveTime,
                 "ReceiveDescription": itemHistory[0].ReceiveDescription,
             })
            }
        })

        setPositionObjectList(positionObjList)
      }
    } catch (error) { 
      setPositionObjectList([])
    }
    

}
  useEffect(() => {
    if(mode !="create"){
     getLetterReceiverPositionsAsync()

    }

}, [ mode ,referenceId])

  let content = [
    { title: "LetterForm", body: (<LetterInformationForm  editInIndicator={editInIndicator}receiver={receiver} setReceiver={(e)=>{  setReceiver({ ...e , ...{ IsDefault:true ,Description:"" ,IsCopy:false}  })} } changeSecretariatId={(e)=>{} }Id={Id} jobId={jobId} inTab={curentTab} step={step} type={type} referenceId={referenceId} setLetterId={setLetterId} changeTab={setCurentTab} mode={mode} letterId={letterId} getInformation={InformationData} onHide={onHide} />), },
    type != "incoming" && { title: "LetterReceivers", body: (<LetterReceiver inTab={curentTab} mode={ step>1 ? "view" : mode} letterId={letterId} defaultReceiver={receiver} getReceiverLetters={setLetterReceiverList} />), },
    { title: "LetterRelated", body: (<LetterRelated inTab={curentTab} mode={mode} letterId={letterId} getRelatedLetters={RelatedData} />), },
    positionObjectList.length >0 &&{ title: "SendInformation", body: (<LetterSend inTab={curentTab} positionObjectList={positionObjectList} />), },
    false && { title: "Attachment", body: (<Attachment onHide={onHide} inTab={curentTab} mood={mode} ownerId={letterId} endpoint={ENDPOINTS.secretariat.letter.attachment} />), },
    //{ title: "LetterRelated", body: (<LetterSignature mode={mode} showForm={true} selectedObj={{}}  />), },
    //{ title: "LetterShow", body: (<Preview  mode={mode} Id={letterId} onHide={onHide}    />), },
    // { title: "PersonRealTest", body: (<PersonRealListTest />), },

  ];

  const tabChangeHandle = (tab) => {
    setCurentTab(tab)
  } 


  /* pagination area*/

  return (
    <>
      <TabBar disableIndex={2}  disabled={letterId ? false : true} tabChange={tabChangeHandle} content={content} curentTab={curentTab} defaultTab={"LetterForm"} />
      <div className="d-flex justify-content-end mt-2">
        {
          (((mode != "view"||editInIndicator ) && curentTab != "LetterShow")) &&
          <>
        <Button form={"InformationForm"}  btnType="submit" value="save"  />
        {(handleShowReferenceStepper && mode == "create") && (
          <Button
          form={"InformationForm"} 
            btnType="submit"
            value="SaveAndReference"
            onClick={() => setShowReferenceStepper(true)}
          />
        )
        }
        
        </>
        
        }
      </div>
    </>
  )
}