import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getPerson = (async (search) => await http({
    url: ENDPOINTS.BaseInformation.person.dictionary,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        SearchString: search,
    }
})
    .then((respons) => (respons.data.Data))
)



export const PostArchive = (async (data , type) => await http({
    url:type==1 ? ENDPOINTS.archive.JobPersonalArchiveDetail.browse    :ENDPOINTS.archive.JobOrganizationalArchiveDetail.browse  ,
    method: 'post',
    data: data
})
.then(response => response.data)
.catch(err => {
    return Promise.reject(err);
}
))


    
export const getOrganizationArchive = (id: any) => http({
    url: ENDPOINTS.archive.organizationalArchive.archives,
    method: "get",
    params: {
        ParentId: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
});
export const getArchivedJob = (id: any) => http({
    url: ENDPOINTS.archive.organizationalArchiveDetail.savedArchive,
    method: "get",
    params: {
        jobId: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
});

export const getPersonalArchive = (id: any) => http({
    url: ENDPOINTS.archive.personalArchive.archives,
    method: "get",
    params: {
        ParentId: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
});
export const deleteArchived = (id: any) => http({
    url: ENDPOINTS.archive.JobOrganizationalArchiveDetail.removeArchivedJob,
    method: "delete",
    params: {
        jobId: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
});