import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import DatePicker from "components/DatePicker/DatePicker";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { UserContext } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";

import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useContext, useEffect, useState } from "react";
import { getPageSize, trans } from "utils/helpers";
import { deleteSubstitute, getAllSubstitute, getSubstitute, postSubstitute, putSubstitute } from "../api";
import { Person } from "./person";


interface SubstituteFormPropsType {
    onHide?: () => void


}
export const SubstituteForm = ({ 
    onHide,
  
}: SubstituteFormPropsType) => {
     
    const [resetForm, setResetForm] = useState(false);
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [untilDate, setUntilDate] = useState<string | null>(null);
    const [response , setResponse ] = useState< any>(null);
    const [Substitute , setSubstitute ] = useState< any>();
    const [selectedSubstitute , setSelectedSubstitute ] = useState< any>();
    const [mode , setMode ] = useState< any>();
    const [showSubstitutes , setShowSubstitutes ] = useState< any>(false);
    const [loading , setLoading] = useState(false)
    const [loading2 , setLoading2] = useState(false)
    const [messageType , setMessageType] = useState< any>("info")
    const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);
    const [orderBy, setOrderBy] = useState<any>([]);
    const [filterlist, setFilterlist] = useState<any>("");
    const [pageNum, setPageNum] = useState<number>(1);
    const [pages, setpages] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [search, setSearch] = useState(null);
    const [userChanges, setUserChanges] = useState({});
    const [forceUpdate, setForceUpdate] = useState(false);
    const [flag,setFlag] = useState(false);


    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
          case "reference":
            alert("call api reference with Id :");
            break;
          case "view":if(selectedSubstitute){
            handleViewRow()
          }  break;
          case "edit":if(selectedSubstitute){
            handleEditRow()
          }  break;
          case "print":
            alert("print ");
            break;
          case "remove":if(!showSubstitutes){if (selectedSubstitute)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow);} break;
            break;
          case "refresh":
            setForceUpdate(!forceUpdate)
            break;
          case "create":
            setSubstitute(null);
            setResetForm(true)
            mode != "create" ?setShowSubstitutes(true) : setShowSubstitutes(!showSubstitutes)
            setMode( "create");
            setLoading2(true)
           
            break;
        }
      };
    

      const handleViewRow = () =>{
        getSubstituteAsync();
        mode != "view" ?setShowSubstitutes(true) : setShowSubstitutes(!showSubstitutes);
        setMode("view") ;
      }

      
      const handleEditRow = () =>{
        getSubstituteAsync();
        mode != "edit" ?setShowSubstitutes(true) : setShowSubstitutes(!showSubstitutes);
        setMode("edit") ;
      }


      const getWithOrder = (order: string[]) => {
            setOrderBy(order)
      }
    

      const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("SubstituteForm" ,e.currentTarget.value);
        getSubstituteOrganizationIdAsync(1);
      };
    


      const handleGoPage = (value) => {
        if (value) {
          if (value < 1 || value > pages) { }
          else setPageNum(+value); getSubstituteOrganizationIdAsync(+value );
        } else {
        }
    };
    const handleNextPage = () => {
      if(pageNum < pages){
        setPageNum(pageNum + 1);
        getSubstituteOrganizationIdAsync(pageNum + 1);
      }
    };
    const handlePrevPage = () => {
      if(pageNum > 1){
        setPageNum(pageNum - 1);;
        getSubstituteOrganizationIdAsync(pageNum - 1);
      }  
       };




      const searchHandler = (str) => {
        setPageNum(1)
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }





      const handleDeleteRow = async() => {
        try {
            await deleteSubstitute(selectedSubstitute.Id)
            setForceUpdate(!forceUpdate)

        } catch (error) {
            
        }
    }
      

    async function sendNodeAsync(data) {
        try{
           await postSubstitute(data);
           setShowSubstitutes(false)
           setForceUpdate(!forceUpdate)
           sessionStorage.setItem("changingforms" , "false");
           setUserChanges({});
        }catch(err:any){

        }

    }


    async function putNodeAsync(data) {
        try{
           await putSubstitute(data);
           setShowSubstitutes(false)
           setForceUpdate(!forceUpdate)
           sessionStorage.setItem("changingforms" , "false");
           setUserChanges({});
        }catch(err:any){

        }
    }

    async function getSubstituteOrganizationIdAsync(PageNum=pageNum) {
      setLoading(false)
      setResponse(null)
      let pageSizeChangedByUser = getPageSize("SubstituteForm" )
      setPageSize(pageSizeChangedByUser)
      try{
      const res = await getAllSubstitute(PageNum, pageSizeChangedByUser, orderBy.join(",") , filterlist ,search);
      setResponse(res.Data)
      setpages(res?.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1)
      setMessageType("info")
        }catch(err:any){
          
          setMessageType("error")
          }
          setLoading(true)
    }



    const getSubstituteAsync = async ()=>{
      setLoading2(false)
 
      try{
      const res = await getSubstitute(selectedSubstitute.Id); 
      setSubstitute(res.Data)         
     }catch(err:any){

       }
        setLoading2(true)
    }
    const getNewSubstitute = async ()=>{
      setLoading2(false)
      try{
      const res = await getSubstitute(selectedSubstitute.Id); 
      setSubstitute(res.Data)  
      setUserMadeChanges(false)       
     }catch(err:any){

    }
      setLoading2(true)
    }

    const validationDates = () =>{
      if(fromDate && untilDate && fromDate?.toString().slice(0,10) > untilDate?.toString().slice(0,10)){
          setFlag(true)
      }else{
          setFlag(false)
      }
  }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(flag){
          Toast(trans("InvalidEndDate"),"error");
      }else{
        let data:any = {}
        if (Substitute == null) {
            data = {
                "FromDate":fromDate? fromDate : null   ,
                "UntilDate": untilDate? untilDate : null ,
                "PersonId": event.target.PersonId.value,
            }
            sendNodeAsync(data)
        } else {

            data = {
                "FromDate":fromDate? fromDate : null   ,
                "UntilDate": untilDate? untilDate : null ,
                "PersonId": event.target.PersonId.value,
                "ActivityStatus":+event.target.SubstituteStatus.value,
                "Id": Substitute.Id,
            }
            putNodeAsync(data)

        }
      }
    }

    function cancel() {
      if(sessionStorage.getItem("changingforms")+""=="true"){
          Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
              sessionStorage.setItem("changingforms" , "false");
              setUserChanges({});
              setShowSubstitutes(false)
          });
      }else{
        setShowSubstitutes(false)    
      };


  }


  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };


useEffect(() => {
    if(Object.values(userChanges).filter((item)=>item!=null).length>0){

        sessionStorage.setItem("changingforms" , "true")
          window.addEventListener('beforeunload', handleBeforeUnload);
          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
    }else{
        sessionStorage.setItem("changingforms" , "false")
        window.removeEventListener('beforeunload', handleBeforeUnload);

    }
}, [userChanges]);
  
    
     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);

    
     useEffect(() => {
      setUntilDate(null)
      setFromDate(null)
      setSubstitute(null)

      }, [showSubstitutes]);

     useEffect(() => {
      setUserMadeChanges(false);
      getSubstituteOrganizationIdAsync()
      }, [forceUpdate , search , orderBy, filterlist]);

     useEffect(() => {

      if(mode != "create" && showSubstitutes ){
        if(userMadeChanges){
          Toast(trans("modalclosemessage"),"warning","alertWithoutClose",getNewSubstitute)
        }else{
          getNewSubstitute()
        }
      }
    }, [selectedSubstitute]);
  
    useEffect(()=>{
      validationDates()
    },[untilDate,fromDate])

    return (
        <div className="person-contact-form card p-3">
            <div className="">
            <Toolbar handleSearch={searchHandler} emptyList={response?.TotalRecords == 0} id={selectedSubstitute+showSubstitutes} create={true} remove={true} edit={true} search={true}   handleClickToolbar={clickToolbarCallback} />
                    <div  className="">
                      {showSubstitutes && <>
                                {loading2? 
                                <>
                                   <div className="card mb-4">
                                   <form className="TypeSettingForm row card-body " onSubmit={handleSubmit}>
                                   <div className="row my-1">
                                   <div className="col-12 col-sm-4">
                                     <Person
                                       name={"PersonId"}
                                       Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                       resetForm={resetForm}
                                       required={true}
                                       value={Substitute?.PersonId ? Substitute?.PersonId  : null}
                                       mode={mode}
                                     />
                                     </div>
                                     <div className="col-12 col-sm-4">
                                     <DatePicker
                                       Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                       mode={mode}
                                       resetForm={resetForm}
                                       lable="fromDate"
                                       setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                                       val={ Substitute?.FromDate ? Substitute?.FromDate  : null} //resetForm ? new Date().toISOString() : 
                                       errorFlag={flag}
                                     />
                                    </div>
                                    <div className="col-12 col-sm-4">
                                     <DatePicker
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        mode={mode}
                                        resetForm={resetForm}
                                        lable="untilDate"
                                        setIsoTime={(isoTime: string) => { setUntilDate(isoTime) }}
                                        val={Substitute?.UntilDate ? Substitute?.UntilDate : null} //resetForm ? new Date().toISOString() : 
                                        errorFlag={flag}
                                     />     
                                                                
                                    </div>
                                    {mode != "create" &&          
                                       <div className="col-lg-4 mt-1">
                                        <EnumerationTypeSelect
                                          Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                                          value={Substitute?.ActivityStatus ? Substitute?.ActivityStatus  : null}  
                                          name="SubstituteStatus"  
                                          mode={mode} 
                                          label="Status"
                                          enumTypeSelect="ActivityStatus"
                                        />
                                       </div>                            
                                       }
                                    </div>
                                       <div className="col-md-4 col-sm-12">
                                       </div>
                                       {  mode !="view" ?
                                             <div className="col-lg-12 mt-1 btns_form">
                                             <Button value="Save" btnType="submit" />
                                             <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                                             </div>
                                             :
                                             <div className="col-lg-12 mt-1 btns_form">
                                             <Button btnType="primary" value="Close" onClick={()=>{cancel()}}/>
                                             </div>
                                         }                        
                                   </form>
                                 </div >
                                   </>
                                 :<Loading />
                                }
                                </>
                            }
                                
                    {loading ?
                        <>
                       {   response?.Data?.length>0 ?
                          <div className="mt-4">
                         <DataGrid
                            orderFormat={getWithOrder}
                            orderFormatList={orderBy}
                            handlefiltering={(str)=>{setFilterlist(str) ; }}
                            filterStr={filterlist}
                            parentName={"SubstituteForm"}
                            multiSelect={false}
                            theadData={Object.keys(response?.Data[0])}
                            minCellWidth={80}
                            tbodyData={response?.Data}
                            selectedRowObjectCallback={setSelectedSubstitute}   
                            startIndex={3}   
                            scrollHeight={"300px"}  
                            totalRecords={response?.TotalRecords}
                            pageNum={pageNum}
                            pageSize={pageSize}
                            HandleNextPage={handleNextPage}
                            HandlePrevPage={handlePrevPage}
                            handlechangePageSize={handleChangePageSize}
                            first={() => {getSubstituteOrganizationIdAsync(pages) ; setPageNum(pages)}}
                            end={() => {getSubstituteOrganizationIdAsync(1) ; setPageNum(1)}}
                            handleGoPage={handleGoPage} 
                            handleEdit={handleEditRow}
                            handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,()=> handleDeleteRow())}}
                            handleView={handleViewRow}         
                          /> </div> 
                        : <Message message={"NoDataForPreview"} type={messageType} />    }                      
                        </>
    
                        : <Loading />
                        }
                    </div>
                </div>
        </div>
    );
}