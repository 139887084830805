import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import DatePicker from "components/DatePicker/DatePicker";
import { Textbox } from "components/Textbox/Textbox";
import { Time } from "components/Time/Time";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { postReferences } from "./api";
import { BpmnTask } from "./components/BpmnTaskDictionary/BpmnTaskDictionary";
import { BpmnTaskUser } from "./components/BpmnTaskUserDictionary/BpmnTaskUserDictionary";
import { ReferenceDictionary } from "./components/ReferenceDictionary/ReferenceDictionary";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { ENDPOINTS } from "utils/endpoints";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
interface SpecialReferencePropsType {
 
    mode?: any,
    jobId: any,
    selectedJob: any,
    referenceId?: any,
    endpoint?: any,
    processId?: any,
    onHide: () => void

}

export const SpecialReference = ({

    selectedJob,
    jobId,
    referenceId,
    endpoint,
    processId,
    onHide,

}: SpecialReferencePropsType) => {


    const [response , setResponse]  = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState<any>("create");
    const [bpmnTask, setBpmnTask] = useState<any>();
    const [bpmnTaskUser, setBpmnTaskUser] = useState<any>();
    const [referrerList, setReferrerList] = useState<any>([]);
    const [resetForm, setResetForm] = useState(false);
    const [fromDate, setFromDate] = useState<any>();
    const [userChanges, setUserChanges] = useState({});

async function ReferenceAndActionsasync(jobId) {
    setLoading(false)
    try {
       // const res =  await GetReferencesActions(jobId )
     //  setResponse(res.Data)
    } catch (error) {
        setResponse([])
    }
    setLoading(true)

}


const handleSubmitedData = async(event) => {
    event.preventDefault()
        let newreferrerList:any = []
        if(referrerList.length ==0){
            newreferrerList.push({
                PerformerPositionDescription :  selectedJob.Performer,
                PerformerPositionId :  selectedJob.PerformerPositionId,
                ReferenceId   :  selectedJob.ReferenceId  ,

            })
        }else{
            newreferrerList = referrerList
        }
         try {   
             if(newreferrerList.length > 0){

                 let refrencList:any =  newreferrerList.map(referrer=>{
                        let obj:any={}
                            obj.ParentReferenceId = referrer.ReferenceId  
                            obj.JobId = jobId
                            obj.TAS_UID = bpmnTask.Id
                            obj.PerformerPositionId = bpmnTaskUser.Id
                            obj.ReferrerPositionId = referrer?.PerformerPositionId
                            obj.ReferenceSubjectId = event.target?.referenceSubject.value
                            obj.Description = event.target?.description.value
                            obj.Priority = +event.target?.priority.value
                            obj.EstimateTime = +event.target?.estimateTime[0].value
                            obj.DeadLineDate = fromDate
                            obj.HasFollowUp = event.target?.hasFollowUp.checked
                            obj.SecretReference = event.target?.secretReference.checked
                            obj.AssignType = 0
        
                         return   {Reference:obj, SendMessageToPerformer:event.target?.sendMessageToPerformer.checked}
                    })   
                   
                       const res = await postReferences(refrencList ,endpoint);
            }
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
               onHide()
            } catch (error) {

            }
    
}


const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };


useEffect(() => {
    if(Object.values(userChanges).filter((item)=>item!=null).length>0){

        sessionStorage.setItem("changingforms" , "true")
          window.addEventListener('beforeunload', handleBeforeUnload);
          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
    }else{
        sessionStorage.setItem("changingforms" , "false")
        window.removeEventListener('beforeunload', handleBeforeUnload);

    }
}, [userChanges]);

    useEffect( () => {
        ReferenceAndActionsasync(jobId)
        
    }, [jobId]);


 

    return(
    <>
    <form onSubmit={handleSubmitedData}>
        <div className="card" >
        <div className="card-body" >
            <div className="row">
        <div className="col-12 col-sm-6 my-1">

        <ReferenceDictionary
          endpoint={endpoint}
          type={"SelectBox"}
          name={"referrer"}
          value={selectedJob.PerformerPositionId}
          JobId={jobId}
          required={true}
          mode={mode}
          selectedList={setReferrerList}
        />

        </div>
       </div>
       <div className="row">
        <div className="col-12 col-sm-6 my-1">

        <BpmnTask
          Change={(e)=>{setUserChanges({...userChanges , ...e})}}
          processId={processId}
          value={""}
          required={true}
          mode={mode}
          setBpmnTask={setBpmnTask}
        />
        </div>
        <div className="col-12 col-sm-6 my-1">

        <BpmnTaskUser
          value={""}
          required={true}
          mode={bpmnTask?.Id ? "create" : "view"}
          bpmnTaskId={bpmnTask?.Id}
          setBpmnTaskUser={setBpmnTaskUser}
          />
        </div>
          </div>
          </div>  
          </div>  
          <div>  
        {(bpmnTaskUser&&bpmnTask) && <>

            <div className={"list-card  mt-3"}>
                <FormWithTitle title={trans("receiver")+" " +trans("referenceForm") +" : " +bpmnTaskUser.Value}>
                <div className="card-body">
                    <div className="container">
                        <div className="row my-1">
                            <div className="col-12 my-1 my-lg-0 col-lg-3">
                                <DictionarySelectBox
                                    required={true}
                                    name='referenceSubject'
                                    value={''}
                                    endPoint={ENDPOINTS.cartable.referenceSubject.dictionary}
                                    label="ReferenceSubject"
                                />
                            </div>
                            <div className="col-12 my-1 my-lg-0 col-lg-3">
                                {/* DV */}
                                <EnumerationTypeSelect
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                    required={true}
                                    type='SelectBox'
                                    name='priority'
                                    label='priority'
                                    enumTypeSelect="priority"
                                    value={ null}
                                />
                            </div>
                            <div className="col-12 my-1 my-lg-0 col-lg-3">
                                <DatePicker
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                    onOpenPickNewDate={false}
                                    lable='DeadLineDate'
                                    resetForm={resetForm}
                                    type='date'
                                    setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                                    val={null}
                                />
                            </div>
                            <div className="col-12 my-1 my-lg-0 col-lg-3">
                                <Time
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                    name="estimateTime"
                                    IdString="estimateTime"
                                    resetForm={resetForm}
                                    defaultValue={0}
                                    label="estimateTime"
             
                                />
                            </div>


                        </div>
                        <div className="row my-1">

                        <div className="col-4 my-1 my-lg-0 col-lg-2 pt-4">
                            <CheckableButton
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                type='checkbox'
                                labelText='HasFollowUp'
                                defaultChecked={ false}
                                inputName='hasFollowUp'
                            />
                        </div>
                        <div className="col-4my-1 my-lg-0 col-lg-2 pt-4">
                            <CheckableButton
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                type='checkbox'
                                labelText='SecretReference'
                                defaultChecked={ false}
                                inputName='secretReference'
                            />
                        </div>
                        <div className="col-4 my-1 my-lg-0 col-lg-4 pt-4">
                            <CheckableButton
                                   defaultValue={false}
                                   labelText={"sendMessageToPerformer"}
                                   inputName={"sendMessageToPerformer"}
                                   type="checkbox"
                                   mode={"edit"}
                                />    
                        </div>
                        </div>
                        <div className="row mt-3 mb-1">
                            <div className="col-12">
                                <Textbox
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                    textboxType="textarea"
                                    textboxName="description"
                                    resetForm={resetForm}
                                    defaultValue={bpmnTask.Value ?bpmnTask.Value  :""}
                                    labelText="Description"
                                />
                            </div>
                        </div>
                        <div className="row my-1">
                            <div className="d-none col-12 my-1 my-lg-0 col-lg-6">
                                <Textbox
                                    Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                    required={true}
                                    textboxType="text"
                                    textboxName="position"
                                    resetForm={resetForm}
                                    defaultValue={ ""}
                                    labelText="Position"
                                    mode={'view'}
                                />
                            </div>
                        </div>

                        <div className="row mt-3 mb-4">
                        </div>
                        <div className="row mt-3 d-flex flex-row-reverse">
                            <div className="col-2 d-flex align-items-center justify-content-end">
                                <Button btnType="submit" value={"Reference"} />
                            </div>
                        </div>
                    </div>
                </div>
                </FormWithTitle>
            </div>
        
        </>
        }
        </div>        
        </form>      
    </>
    );
}