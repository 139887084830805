import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";



export const getSharingCenterArticleUserList = (pageNum , pageSize   , orderBy ,searchString ,filterlist  ) => http({
    url: ENDPOINTS.SharingCenterArticleUserApi.browse,
    method: 'get',
    params: {
        PageNumber: pageNum,
        pageSize: pageSize,
        OrderByString:orderBy,
        SearchString : searchString ,
        FilterString:filterlist
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const getSharingCenterArticleUser = (id: any) => http({
    url: ENDPOINTS.SharingCenterArticleUserApi.load,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        articleId: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const postSharingCenterArticleUser = (data: any) => http({
    url: ENDPOINTS.SharingCenterArticleUserApi.browse,
    method: 'post',
    data: data,


}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const putSharingCenterArticleUser = (data: any) => http({
    url: ENDPOINTS.SharingCenterArticleUserApi.browse,
    method: 'put',
    data: data

}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const deleteSharingCenterArticleUser = (id: any) => http({
    url: ENDPOINTS.SharingCenterArticleUserApi.browse,
    method: 'delete',
    params:{
    Id:id
   }

}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
