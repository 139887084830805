import { SelectBox } from "components/Select/Select";
import { InputHTMLAttributes, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getParameterDataTypeApi } from "./api";

import { InputLoad } from "components/InputLoading/inputLoading";

interface ParameterDataTypeProp extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: any;
  mode?: string;
  required?:boolean 
  onChange?: (e) => void,
}
export const ParameterDataTypeDictionary = ({   name, value ,mode , required ,onChange }: ParameterDataTypeProp) => {
  const [optionsParameterDataType, setOptionsParameterDataType] = useState<any>();
  const [errcode, setErrcode] = useState<any>();
  const getParameterDataTypeAsync = async ()=>{
    const res = await getParameterDataTypeApi()
    if(res?.Data ==null){
      setOptionsParameterDataType(res?.Data)
    }else{
      setOptionsParameterDataType(res?.Data)

    }
    try {
    } catch (error:any) {
      setErrcode(error.response.status)

    }
  }
  useEffect(() => {
    getParameterDataTypeAsync()
    return () => {
     
      setErrcode("")
    };
   
  }, []);

 return (
    <>
        {optionsParameterDataType ?
            <div style={{display:"flex" , }} >
            <div style={{width:"100%"}}>
            <SelectBox
              onChange={(e)=>{onChange&& onChange(e) }}
              required={required}
              mode={mode}
              lable="ParameterDataType"
              selectType="select"
              value={(optionsParameterDataType)}
              options={optionsParameterDataType}
              val={optionsParameterDataType}
              name={name}
            ></SelectBox>
            </div>
          </div>
        :<InputLoad   msg={errcode} required={required}  labelText={"ParameterDataType"}  onClick={getParameterDataTypeAsync}/> 

      }
        <div className="text-box__msg-wrapper">
        </div>
    </>
  );
};
