import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getMyOwnRequest = (    ) => http({
    url: ENDPOINTS.clientPortal.startUpProcess,
    method: 'get',

}).then((response) => (response.data))
    .catch(err => {
        return Promise.reject(err)
    })

