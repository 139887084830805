import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getAllEvents = (async () => await http({
    url: ENDPOINTS.Widget.personDayEvents,
    method: 'get',

})
    .then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err);
    }));