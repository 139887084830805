import { Loading } from "components/Loading/loading";
import { Toast } from "components/ToastShow/ToastShow";

import { useEffect, useState } from "react";
import { trans, uuidv4 } from "utils/helpers";

import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { CityDictionary } from "pages/OfficeAutomation/Settings/City/components/CityDictionary";
import { ProvinceDictionary } from "pages/OfficeAutomation/Settings/Province/components/ProvinceDictionary";
import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { Textbox } from "components/Textbox/Textbox";
import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Message } from "components/Message/Message";




interface CustomerBranchesFormPropsType {
    onHide: () => void
    CustomerBranchesCallBack: (e) => void
    mode: any,
    CustomerBranches:any
    CustomerId?: string,

}
export const CustomerBranchesForm = ({ 
    onHide,
    CustomerBranchesCallBack,
    mode,
    CustomerBranches,
    CustomerId

}: CustomerBranchesFormPropsType) => {
     
    const [resetForm, setResetForm] = useState(false);
    const [loading2 , setLoading2] = useState(true)
    const [userChanges, setUserChanges] = useState({});
    const [province, setProvince] = useState<any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [editForm,setEditForm] = useState<any>({});
    const [indexEditForm,setIndexEditForm] = useState<number>(0)
    const [branchMode,setBreanchMode] = useState<'create'|'edit'>('create')
    const [branchList, setBranchList] = useState<any>([]);



    const handleDeleteRow = async(id: string =selectedObj.Id) => {
        setBranchList(branchList.filter((branch)=>branch.Id != id))
    }

    const handleEdit = (id)=>{
        branchList.map((branch,index)=>{
            if(branch.Id == id){
                setEditForm(branch)
                setIndexEditForm(index)
            }
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let data:any = {}
        data = {
            Id : branchMode == 'create' ?  uuidv4()  :editForm.Id , 
            CustomerId:CustomerId,
            BranchCode: event.target.BranchCode.value ,
            PostalCode: event.target.PostalCode.value 
        }
            if(branchMode == 'create'){
                setBranchList([...branchList , data ])
                CustomerBranchesCallBack([...branchList , data ])
            }else{
                let newBranch =JSON.parse(JSON.stringify(branchList)) 
               newBranch.splice(indexEditForm,1,data)
                CustomerBranchesCallBack(newBranch)
                setBranchList(newBranch)
            }
            setResetForm(true);
            
        }

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);
     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);
      useEffect(() => {
        if(CustomerBranches && CustomerBranches.length > 0){

            let newBranch =  CustomerBranches?.map((branch)=>{ 
                return{
                    Id : branch.Id , 
                    CustomerId:CustomerId,
                    BranchCode:branch.BranchCode,
                    PostalCode: branch.PostalCode,
                }
            })
            setBranchList(newBranch)
        }
      },[CustomerBranches])
    return (

        <>
            {mode!="view"&&
            <div className="card">
                <form  className="TypeSettingForm  card-body" onSubmit={handleSubmit}>
                  <div className="row my-1">
                        <div className="col-12 col-sm-3 ">
                            <Textbox
                                defaultValue={editForm?.PostalCode}
                                textboxType="PostalCode"
                                textboxName="PostalCode"
                                labelText="PostalCode"
                                mode={mode}
                                resetForm={resetForm}
                                required={true}
                            />
                        </div>
                        <div className="col-12 col-sm-3 ">
                            <Textbox
                                required={true}
                                defaultValue={editForm?.BranchCode}
                                textboxType="text"
                                textboxName="BranchCode"
                                labelText="BranchCode"
                                mode={mode}
                                resetForm={resetForm}
                            />
                        </div>

                   </div>  
                   {
                    branchMode == 'create' ? 
                    <Button  value="Save" btnType="submit" />
                    :
                    <>
                    <Button  value="Save" btnType="submit" />
                    <Button  value="cancel" btnType="cancel" onClick={()=>{setEditForm(null);setBreanchMode('create')}} />
                    </>
                   }
                 
                </form>
            </div>
            }
            <div className="card card-body">

            { branchList?.length > 0 ?
                     <>
                         <DataGrid
                             parentName={"SalesInvoiceDetailList"}
                             theadData={Object.keys(branchList[0])}
                             tbodyData={branchList}
                             scrollHeight={ "67vh"}
                             startIndex={2}
                             minCellWidth={80}
                             selectedRowObjectCallback={setSelectedObj}
                             handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,()=> handleDeleteRow(e))}}
                             handleEdit={(e)=>{handleEdit(e);setBreanchMode('edit') }}
                         />
                     </>
                  : <Message message={"NoDataForPreview"} type={"info"}/>}
            </div>

        </>

    );
}