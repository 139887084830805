
import { Toast } from "components/ToastShow/ToastShow";
import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getPosition = (positionId: any) => http({
    url: ENDPOINTS.BaseInformation.position.dictionary,
    method: 'get',
    params: {
        id: positionId
    }
}).then((response) => (response.data))
    .catch(err => {

    })

export const getGeneralRequestByReferenceId = (referenceId: any) => http({
    url: ENDPOINTS.cartable.GeneralRequest.load,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
        referenceId: referenceId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getReverseList = (referenceId: any) => http({
    url: ENDPOINTS.cartable.refer.reverseList,
    method: 'get',
    params: {
        referenceId: referenceId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});





export const getGeneralRequest = (GeneralRequestId: any) => http({
    url: ENDPOINTS.cartable.GeneralRequest.GeneralRequestId,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
        Id: GeneralRequestId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const postGeneralRequest = (data: any, returnReferenceId ,processId) => http({
    url: ENDPOINTS.cartable.GeneralRequest.browse,
    method: 'post',
    data: data,
    params: {
        PRJ_UID: processId
    }

}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const putGeneralRequest = (data: any ,referenceId) => http({
    url: ENDPOINTS.cartable.GeneralRequest.browse,
    method: 'put',
    data: data,
    params:{
        referenceId:referenceId
    }

}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getProcessId = (formName) => http({
    url: ENDPOINTS.bpmnApi.process.getProcessId ,
    method: 'get',
    params:{
        formName:formName
    }

}).then((res) => {
     
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
