// import { trans } from "../utils/helpers"
import { useEffect, useState, useRef } from "react";
import { trans } from "utils/helpers"

interface checkableButtonPropsType {
    parentName?: string,
    id?: string,
    mode?: string,
    checked?: boolean
    readOnly?: boolean
    defaultChecked? : boolean
    disabled? : boolean
    type: number
    onclick?: (e:any) => void;
    divClick?: (e:any) => void;
}
export const Checkbox = ({ disabled, parentName, type, mode, checked, defaultChecked ,onclick ,divClick ,id}: checkableButtonPropsType) => {



    return (
        <div  className="checkable-btn">
            {type == 1?
           <input disabled={disabled} style={{marginLeft:"6px"}} type={"checkbox"} id={`checkbox ${id}`} checked={defaultChecked} className={`${parentName} `} onChange={()=>{}} onClick={onclick}/>
             :
                <div style={{width : "13px" , height:"13px"  ,marginLeft:"6px" ,backgroundColor :"white", border:disabled? "1px rgba(73, 80, 87, 0.61) solid":"1px black solid" , display:"flex" ,justifyContent: "center" }} onClick={divClick} >
                    <div style={{width : "9px" , height:"9px" , backgroundColor :disabled?"rgba(73, 80, 87, 0.61)" :"black" ,marginTop:"1px"  }}>

                    </div>
                </div>

            }

        </div>
    )
}