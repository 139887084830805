import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";





export const getOrganizationList = () => http({
    url: ENDPOINTS.BaseInformation.organization.getNode,
    method: "get",

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});


export const getPositionOrganizationList = (id,PageNumber: number, PageSize: number ,orderFormat:string ="" , filterlist , Search ,showDeleted) => http({
    url: ENDPOINTS.BaseInformation.position.load,
    method: "get",
    params: {
        organizationId:id,
        PageNumber: PageNumber,
        PageSize: PageSize,
        SearchString:Search,
        FilterString:filterlist,
        OrderByString : orderFormat,
        showDeleted :showDeleted
        }
    }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });
   
export const canAddChildToOrganization = (id) => http({
    url: ENDPOINTS.BaseInformation.organization.canAdd,
    method: "get",
    params: {
        parentId:id

        }
    }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });
   
export const getPosition = (id) => http({
    url: ENDPOINTS.BaseInformation.position.getId,
    method: "get",
    headers: { "Accept-Language": "en" },
    params: {
        Id:id

        }
    }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });
   
   
   
    export const AddNodeToParent = (data:any) => http({
        url: ENDPOINTS.BaseInformation.organization.load,
        method: "post",
        data:data
    }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });
   

   
       
    export const editNode = (data:any) => http({
        url: ENDPOINTS.BaseInformation.organization.edit,
        method: "put",
        data : data
    }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });
   
       
    export const getNode = (id) => http({
        url: ENDPOINTS.BaseInformation.organization.getId,
        method: "get",
        params : {
            Id : id
        }
    
    }).then((res) => {
    
           return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });

    export const getFully = () => http({
        url: ENDPOINTS.BaseInformation.organization.browse,
        method: "get",
    
    }).then((res) => {
       // Toast("Information received successfully" , "success")      
          return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });
       
    export const deleteNode = (id) => http({
        url: ENDPOINTS.BaseInformation.organization.deleteNode,
        method: "delete",
        params : {
            Id : id
        }
    
    }).then((res) => {
    
           return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });
       
    export const moveNude = (drag , drop) => http({
        url: ENDPOINTS.BaseInformation.organization.moveNode,
        method: "put",
        params : {
            currentId : drag ,
            newParentId : drop
        }
    
    }).then((res) => {
    
           return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });
       
    
    export const AddNewPosition = (data:any) => http({
        url: ENDPOINTS.BaseInformation.position.browse,
        method: "post",
        data : data
    }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });
   
       
    
    export const editPosition = (data:any) => http({
        url: ENDPOINTS.BaseInformation.position.browse,
        method: "put",
        data : data
    }).then((res) => { 
        return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });


   
       
    
    export const deletePosition = (id) => http({
        url: ENDPOINTS.BaseInformation.position.browse,
        method: "delete",
        params:{
            Id:id
        }
    }).then((res) => { 
        return res.data
    }).catch((err) => {
        return Promise.reject( err);
    });


   
       
    



    export const getRolesList = (async () => await http({
        url: ENDPOINTS.tadbirAuthorizeApi.dictionary,
        method: 'get',
    }).then((res) => {
    
        return res.data
    }).catch((err) => {
     
        return Promise.reject( err);
    })
    
    )
    



    export const getOrganizationRolesList = (async (organizationId:any ,SearchString ) => await http({
        url: ENDPOINTS.tadbirAuthorizeApi.organizationRole.dictionary,
        method: 'get',
        params:{
            SearchString: SearchString? SearchString :null,
            organizationId :organizationId
        }
    }).then((res) => {
    
        return res.data
    }).catch((err) => {
     
        return Promise.reject( err);
    })
    
    )
    
    export const getExceptOrganizationRolesList = (async (organizationId ,SearchString ) => await http({
        url: ENDPOINTS.tadbirAuthorizeApi.organizationRole.browse,
        method: 'get',
        params:{
            SearchString: SearchString? SearchString :null,
            organizationId :organizationId
        }
    }).then((res) => {
    
        return res.data
    }).catch((err) => {
     
        return Promise.reject( err);
    })
    
    )
    
    export const addNewRolesAsync = (async (organizationId ,data ) => await http({
        url: ENDPOINTS.tadbirAuthorizeApi.organizationRole.load,
        method: 'post',
        params:{
            organizationId :organizationId
        },
        data:data
    }).then((res) => {
    
        return res.data
    }).catch((err) => {
     
        return Promise.reject( err);
    })
    
    )
    export const deletRolesAsync = (async (organizationId ,data ) => await http({
        url: ENDPOINTS.tadbirAuthorizeApi.organizationRole.load,
        method: 'DELETE',
        params:{
            organizationId :organizationId
        },
        data:data
    }).then((res) => {
    
        return res.data
    }).catch((err) => {
     
        return Promise.reject( err);
    })
    
    )
    
    export const getgroupPositionDictionarys = (async () => await http({
        url: ENDPOINTS.BaseInformation.groupPosition.dictionary,
        method: 'get',
        params:{
            isReserved:false
        }
    }).then((res) => {
    
        return res.data
    }).catch((err) => {
     
        return Promise.reject( err);
    })
    
    )
    