import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getListSecretariats = (async (pageNumber: number, pageSize: number ,orderFormat:string ="" , filterlist , searchString) => await http({
    url: ENDPOINTS.secretariat.secretariat.browse,
    method: 'get',
    params:{
        PageNumber: pageNumber,
        PageSize: pageSize,
        OrderByString : orderFormat,
        SearchString:searchString,
        FilterString:filterlist,
    }

}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getsecretariat = (async (id) => await http({
    url: ENDPOINTS.secretariat.secretariat.load,
    method: 'get',
    params:{
        Id:id
    }

}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)
export const getAllowUpdate = (async (id) => await http({
    url: ENDPOINTS.secretariat.secretariat.allowUpdate,
    method: 'get',
    params:{
        Id:id
    }

}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)



export const postSecretariats = (data) => http({
    url: ENDPOINTS.secretariat.secretariat.browse,
    method: 'post',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

export const deleteSecretariat = (id) => http({
    url: ENDPOINTS.secretariat.secretariat.browse,
    method: 'delete',
   params:{
    Id :id
   }
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const putSecretariats = (data) => http({
    url: ENDPOINTS.secretariat.secretariat.browse,
    method: 'put',
    data:data
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

