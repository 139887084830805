import React, { useContext } from "react";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { Footer } from "layouts/DashboardLayout/components/Footer/Footer";
import { Header } from "layouts/DashboardLayout/components/Header/Header";
import "./main.scss";
import { Sidebar } from "../Sidebar/Sidebar";

export const Main: React.FC = ({ children }) => {
  const layoutContext = useContext(LayoutContext);
  const handleToggleSidebar = () => {
    layoutContext.toggleSidebar();
  };


  return (
    <main className={`main ${layoutContext.sidebarIsOpen ? "openSidebar" : ""}`}>
      <div className="back_fullScreen" onClick={handleToggleSidebar}></div>
      <Header handleToggleSidebar={handleToggleSidebar} />
      <section className="wrap-container">
        {children}
      </section>
      <Footer />
    </main>
  );
};
