import { useEffect, useState } from "react";
import { loadCardImage } from "../../api";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ItemBuyImgProps {
    attchId?: any,

}

export const ItemBuyImg = ({attchId}:ItemBuyImgProps) => {

    const [src, setSrc] = useState<any>(null);
    const [loading,setLoading]=useState<boolean>(false)

    async function loadImageAsync() {
        try {
            const res:any = await loadCardImage(attchId );
            setSrc(res);
            setLoading(true)
      
        } catch (error) {

        }
    }
    useEffect(() => {
    if(!attchId?.includes("0000")){
        loadImageAsync()
    }

    }, [attchId]);

    return (
     <>

        <div className="col-2 imgBx w-100 h-100">
     {/*    {(attchId?.includes("0000")||!src) ?
            <div className="noneImg w-100 h-100 rounded-circle"></div>
            :
            <img src={"data:application/json;base64,"+src} draggable="false" className="w-100 h-100"/>
            
        } */}
        {loading?
        <img src={src} draggable="false" className="w-100 h-100"/>
        :
        <FontAwesomeIcon
        icon={"file-circle-exclamation"}
        style={{ color: "#e2e8f0" }}
        className="mt-5 card-icon "
      />
        }

        </div>

    </>
     );
}
