import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { ICON_TYPE } from "utils/constants"
import { trans } from "utils/helpers"

interface ButtonToolbarSearchProps {
    faIcon: [IconPrefix, IconName], // اجباری یا اختیاری؟
    text: "help" | "edit" | "view" | "create" | "refresh" | "delete" | string,
    hotKey?: "F8" | "F9" | "F10" | "F11" | "F12"|"F"|"S",
    options?: any, // تایپ آپشنز مشخص شود
    id?: any, // تایپ آپشنز مشخص شود
    isFocos?: any,
    defultSerach?: any,
    createCallback?: (ans: any) => void
    label?: string
    type?: 'search-input' | 'search-icon'  
    onChange?: (e) => void;
    setIsFocos?: (e) => void;

}
export const ButtonToolbarSearch: React.FC<ButtonToolbarSearchProps> = ({
    faIcon,
    isFocos =false,
    defultSerach,
    text,
    options = [{ val: 1, text: "Create" }],
    id,
    hotKey,
    createCallback,
    label,
    type,
    onChange,
    setIsFocos,
}) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
    let hotKeyText: string | undefined = hotKey;
    const ButtonToolbarSearch = useRef(null);
    const buttonKey = useRef(hotKey);
    const [key, setKey] = useState(false);
    const [showSerch, setShowSerch] = useState<any>(false);
    const countRef = useRef<any>(0);
    const [searchInputVal, setSearchInputVal] = useState<any>(null);
    const [focos, setFocos] = useState<any>(false);
    const refFocos = useRef<any>(focos);
    
    const changeSearchInputVal = (event) => {
    
        setSearchInputVal(event?.currentTarget.value);
    }

    function clickClose(e){
        if(e.target.tagName != "INPUT"&&e.target.tagName != "path" &&e.target.tagName != "svg" && refFocos?.current?.value?.length == 0){
            setShowSerch(false)
        }
    }
    useEffect(() => {
        const getData = setTimeout(() => {
          if(searchInputVal != null){
            onChange && onChange(searchInputVal)
          }
        }, 800)
        const callback = (event: KeyboardEvent) => {
            if (event.code === 'Enter'&&document.getElementsByClassName("fullscreen-bg-modal").length == 0 &&  !document.activeElement?.className.includes("filterInput")  ) {    
                onChange && onChange(searchInputVal)
                clearTimeout(getData) 
            }
        };
        document.addEventListener('keydown', callback);
        return () => {
            clearTimeout(getData)
            document.removeEventListener('keydown', callback);
        };
      
        }, [searchInputVal])
    useEffect(() => {
        if (localStorage.Shortcut != null) {
            Object.keys(JSON.parse(localStorage.Shortcut)).forEach(item=>{
                if(item == text.toLowerCase()){
                    setKey(JSON.parse(localStorage.Shortcut)[item])
                    buttonKey.current = JSON.parse(localStorage.Shortcut)[item]
                    
                }
            })


        }

    }, [localStorage.Shortcut]);
   
    useEffect(() => {
        if(showSerch){
            document.addEventListener("click", clickClose)

        }else{
            document.removeEventListener("click", clickClose)
        }
        return () => document.removeEventListener("click", clickClose);

    }, [showSerch]);
   
    useEffect(() => {
        if(defultSerach){
            setShowSerch(true)
         //   setSearchInputVal(defultSerach)
        }

    }, [defultSerach]);
   





    return (
        <div onClick={(e)=>{e.stopPropagation(); setShowSerch(true);countRef.current=0}} style={{width : showSerch ? "190px" : ""}} className="toolbar-btn-wrapper ">
            <button style={{width : showSerch ? "180px" : ""}}  ref={ButtonToolbarSearch}  className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center" >
            <div style={{ zIndex: 1}} className={`toolbar-btn__hotkey ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")}`}>{hotKeyText=="Delete" ?"Del": hotKeyText}</div>
               {!showSerch ? 
               <>
                <FontAwesomeIcon icon={faIcon} width="20px" height="20px" />
                <small>{trans(`${text}`)}</small>
               </>
               :
               <>
                <div className="search-bar__input-wrapper">
                    <div className="search-bar__search-btn" >
                        {<FontAwesomeIcon icon={[ICON_TYPE, "search"]}  />}
                    </div>
                        <input  style={{height:"38px"}} onFocus={()=>{setIsFocos && setIsFocos(true)}} onBlur={()=>{setIsFocos && setIsFocos(false)}} ref={refFocos} id="GeneralSearchBox" autoFocus={true} onMouseDown={(e) => e.stopPropagation()} defaultValue={defultSerach} value={searchInputVal ==null ?defultSerach:searchInputVal} onChange={changeSearchInputVal} className="search-bar__input form-control" type="text" placeholder={trans(`Search`)} />
                    <div onClick={() => {setShowSerch(false) ;  setSearchInputVal("") ;}} className={`search-bar__del-search-filter ${(searchInputVal == null ||searchInputVal == "") ? "d-none" : ""}`}>
                        <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                    </div>
                </div>
               </>
               } 
            </button>
        </div>
    )
}