import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getRate = () => http({
    url: ENDPOINTS.enumeration,
    method: 'get',
    params: {
        enumerationType: "Rate",

    },
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


export const putCustomerFeedBack = (Id, IsDelive, CustomerFeedBackRate, CustomerFeedBackDescription) => http({
    url: ENDPOINTS.clientPortal.feedBack,
    method: 'put',
    params: {
        jobId: Id,
        isDeliver: IsDelive,
        customerFeedBackRate: CustomerFeedBackRate,
        customerFeedBackDescription: CustomerFeedBackDescription
    },
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
