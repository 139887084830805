import { useEffect, useRef, useState } from "react";
import { GroupingPriceFunction, trans } from "utils/helpers";
interface PriceInputPropsType {
  label: string,
  hasBorder?: boolean,
  resetForm: boolean,
  required?: boolean,
  value?: string,
  mode?: string,
  name: string,
  Change?: (e) => void,
  onChange?: (e) => void,
}

export const PriceInput = ({ label, hasBorder, value, mode ,resetForm,required , name="", Change ,onChange}: PriceInputPropsType) => {
  const [valueName, setValueName] = useState<any>();
  const [valueNum, setValueNum] = useState<any>('تماس بگیرید');
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [validat , setValidat] = useState(false);
  const [count,setCount] = useState<number>(0);
  const inputRef = useRef<any>();
  const inputIdRef = useRef<any>();
  const validRef = useRef<any>(false);
  const countRef = useRef<any>(0)

  let optionList : any =[{title:trans("CallForPrice") ,value:-1}]




  const Auto = (e) => {
    validRef.current = false
    inputIdRef.current.value = e.target.value
    if(isNaN(+e.target.value) && !e.target.value.includes(',') ){      
      inputRef.current.value =""
    }else{
      onChange && onChange(e.target.value.replaceAll(',',''))
      setValueNum(e.target.value)
      setValidat(false)
    }
      setShow(true)
    setFlag(!flag)

  }

  function userSelecter(){
    setValeu(optionList[countRef.current],-1)
    setShow(false)
  }

  const kydown = (event) => {    
    if((event.key === "ArrowDown")){
      if(countRef.current < optionList.length-1){
        countRef.current = countRef.current + 1;
      }else{
        countRef.current = 0;
      }
    }
    
    if(event.key === "ArrowUp"){
      if(countRef.current > 0){
        countRef.current = countRef.current -1;
      }else{
        countRef.current = optionList.length-1;
      }
    }
    document.getElementById("PriceInput"+countRef.current)?.scrollIntoView({behavior:"smooth"})
    setCount(countRef.current)

    if(event.key === "Enter"){
      userSelecter()
    }
  }
  const CheckPriceList =async (e)=>{
    
    setTimeout(() => {
       
  
    }, 200);
    await delay(200)
    setShow(false)
  }
  const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));




  const setValeu = (newValue, id) => {
    if(inputIdRef != null && inputIdRef.current != null){
      validRef.current = true
      inputRef.current.value = newValue
      inputIdRef.current.value = id;
      ((value==null &&id)|| id != value) ? Change&&Change({[name] :id}):Change&&Change({[name]:null});
      onChange && onChange({Name:newValue , Id : id})
      setFlag(!flag)
      setValidat(false)
    }
  }
  useEffect(() => {
    if(value){
      if(value=="-1"){
        inputIdRef.current.value = -1
        setValueName(trans("CallForPrice"))
        setValueNum(trans("CallForPrice"))
      }
    }else if(value == null){
      setValueName(null)
     }
  }, [value,resetForm]);


  useEffect(() =>{
    if(show ){
      document.addEventListener('keydown', kydown);
      document.body.addEventListener("click", clickToclose)
    }
     return () => { document.removeEventListener("keydown", kydown);document.body.removeEventListener('click', clickToclose) }
  },[show ,optionList])

  function clickToclose(e){
    let input = document.querySelector("#browser"+name);
    if (e.target.id !== input?.id) { 
      setShow(false);
    }
  }


  return (
    <>
    <div className="text-box w-100">

    <label htmlFor={`browser${name}`} className="text-box__label">{trans(label)}</label>
    {required && <span className="text-box__required-sign-for-label text-danger">*</span>}
    <input required={required} defaultValue={value && value} name={name} className="d-none" ref={inputIdRef} />
    <input   required={required} onBlur={CheckPriceList} defaultValue={valueName} value={GroupingPriceFunction(valueNum)} autoComplete="off" onFocus={(e)=>{  setShow(true);Auto(e)}}  ref={inputRef} list="PriceInput" name="browser" className="text-box__box form-control " type={'text'}    id={"browser"+name} onMouseDown={(e)=>e.stopPropagation() } onChange={Auto} disabled={mode =="view"  ? true : false} />
    {
       show && 
        <div tabIndex={-1} className={`text-box__sub-menu mt-1`} >
          {optionList && optionList.map((item , index)=>{
                 return  <div id={"PriceInput"+index} className={`${count == index ?"text-box__sub-menu-option-active" : "text-box__sub-menu-option"}`} onClick={()=>{setValeu(item.title,-1);setValueNum(trans("CallForPrice"))}} key={item.value} >{item.title}</div>
           })}  
        </div>
     }  
      <div className="text-box__msg-wrapper">
       {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
       </div>    
    </div>
    </>
   )
}