import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getAllPaymentCoverageType = (async (searchString, orderBy) => await http({
    url: ENDPOINTS.afterSalesApi.PaymentCoverageType.browse,
    method: 'get',
    params: {
        searchString: searchString,
        orderBy: orderBy
    }

}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)

export const getPaymentCoverageTypen = (async (id) => await http({
    url: ENDPOINTS.afterSalesApi.PaymentCoverageType.load,
    method: 'get',
    params: { Id: id }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)


export const postPaymentCoverageType = (data) => http({
    url: ENDPOINTS.afterSalesApi.PaymentCoverageType.browse,
    method: 'post',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})



export const putPaymentCoverageType = (data) => http({
    url: ENDPOINTS.afterSalesApi.PaymentCoverageType.browse,
    method: 'put',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})


export const deletePaymentCoverageType = (id) => http({
    url: ENDPOINTS.afterSalesApi.PaymentCoverageType.browse,
    method: 'delete',
    params:{
        Id:id
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})


