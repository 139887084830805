/* import React from "react";
import { trans } from "utils/helpers";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface props {
  message: string
  type: 'info' | 'alert' | 'error'
}

export const Message: React.FC<props> = ({message, type}) => {  
  return (
    <div style={{maxHeight: "38px"}} className={`message d-block rounded border p-2 my-2 ${(type === 'info' && "border-primary") || (type === 'alert' && "border-warning") || (type === 'error' && "border-danger")}`}>
      <div className="d-flex justify-content-start align-items-center">
        <div className="message__icon-container">
          {type === 'info' && <FontAwesomeIcon icon={'info-circle'} color='#0d6efd'/>}
          {type === 'alert' && <FontAwesomeIcon icon={'exclamation-triangle'} color='#ffc107'/>}
          {type === 'error' && <FontAwesomeIcon icon={'exclamation-triangle'} color='#dc3545'/>}
        </div>
        <div className="text-secondary">{trans(message)}</div>
      </div>
    </div>
  );
};
 */


 


import React from "react";
import { trans } from "utils/helpers";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface props {
  message: string
  title?: string
  times?: boolean
  type: 'info' | 'alert' | 'error'|'instruction'
}

export const Message: React.FC<props> = ({message, type,title , times = false}) => {  
  return (
<> {times ? 
    
        <div className={`message d-block rounded border p-2 my-2 ${(type === 'info' && "border-primary") || (type === 'alert' && "border-warning") || (type === 'error' && "border-danger")}`}>
          <div style={{justifyContent:"space-between"}} className="d-flex align-items-center">
            <div   className="d-flex justify-content-start align-items-center" >
              <div className="message__icon-container">
                {type === 'info' && <FontAwesomeIcon icon={'info-circle'} color='#0d6efd'/>}
                {type === 'alert' && <FontAwesomeIcon icon={'exclamation-triangle'} color='#ffc107'/>}
                {type === 'error' && <FontAwesomeIcon icon={'exclamation-triangle'} color='#dc3545'/>}
                {type === 'instruction' && <FontAwesomeIcon icon={'question'} color='#dc3545'/>}
              </div>
              <div className="text-secondary">{trans(message)}</div>
            </div>
            <div className="message__icon-container">
               <FontAwesomeIcon icon={'times'} color='#0d6efd'/>
            </div>
          </div>
        </div>
        :

        <div  className={`${title? "legendCard":"" }  ${type == 'instruction' && 'border-info'}   message d-block rounded border p-2 my-2 ${(type === 'info' && "border-primary") || (type === 'alert' && "border-warning") || (type === 'error' && "border-danger") || (type === 'instruction' && "dark-warning")}`}>
            {title&&
             <div className="legend" style={{transform:"translatey(-91%)"}}>{trans(title)}</div>
            }

        <div className="d-flex justify-content-start align-items-start">
          <div style={{position:message !=undefined&&message.includes('\n')? "relative": "unset" , top:message !=undefined&&message.includes('\n')? "-40%": "unset"}} className="message__icon-container">
            {type === 'info' && <FontAwesomeIcon icon={'info-circle'} color='#0d6efd'/>}
            {type === 'alert' && <FontAwesomeIcon icon={'exclamation-triangle'} color='#ffc107'/>}
            {type === 'error' && <FontAwesomeIcon icon={'exclamation-triangle'} color='#dc3545'/>}
            {type === 'instruction' && <FontAwesomeIcon icon={'circle-question'} className="text-info"/>}
          </div>

          <p className={`${type != 'instruction' ? 'text-secondary' : ''} mb-0`}  dangerouslySetInnerHTML={{__html:(message !=undefined&& message?.length > 1) ? trans(message).replaceAll('\n' , '<br/>'): trans("NoDataForPreview")}}></p>
        </div>
      </div>


}</>
  );
}; 