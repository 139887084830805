import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getItem } from "./api";
import "./style.scss";

import { GroupingPrice } from "components/GroupingPrice/GroupingPrice";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";

import { GUID_EMPTY } from "utils/constants";
import { getBasketData, roundOrTruncate, trans } from "utils/helpers";
import { Cart } from "../components/ItemBuyBtn/components/Cart";
import { ItemBuyImg } from "../components/ItemBuyBtn/ItemBuyImg";
import { ItemCountSeter } from "../components/ItemCard/ItemCountSeter";
import { ItemGroupLebal } from "../components/ItemGroupMenu/ItemGroupLabel";
import { ItemGroupLabelSlider } from "../components/ItemGroupMenu/ItemGroupLabelSlider";
import { Gallery } from "./components/Gallary/Gallery";
import { ImgBox } from "./components/Gallary/ImgBox";
import { IncreaserDecreaser } from "./components/IncreaserDecreaser/IncreaserDecreaser";


export const ItemDetailPage = () => {
  const location = useLocation() as any;
  const queryParams = new URLSearchParams(location.search);
  const itemId = queryParams.get("item");

  const [imgSelected, setImgSelected] = useState<any>(null);
  const [selectedPropertiesKeys, setSelectedPropertiesKeys] = useState<any>({});
  const [selectedPropertiesValue, setSelectedPropertiesValue] = useState<any>({});
  const [activeIndex, setActiveIndex] = useState<any>(null);
  const [showIncreaseDecrease, setShowIncreaseDecrease] = useState<any>(false);
  const [showGallary, setShowGallary] = useState<any>(false);
  const [selectedIncreaseDecrease, setSelectedIncreaseDecrease] =
    useState<any>(null);
  const [checkBoxId, setCheckBox] = useState<any>(null);
  const [company, setCompany] = useState<number>(0);
  const [like, setLike] = useState<boolean>(false);
  const [itemNotif, setItemNotif] = useState<number>(5);
  const [dataLocal, setDataLocal] = useState<any>();
  const [basketData, setBasketData] = useState<any>();
  const [totalBasketData, setTotalBasketData] = useState<any>();
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const detailActiveRef = useRef<any>(null);
  const [showCartsubtotal, setShowCartsubtotal] = useState<boolean>(false);
  const [show, setShow] = useState(true);
  const [priceInfo, setPriceInfo] = useState<any>({
    itemsPrice: 0,
    cartSubtotal: 0,
  });
  const [loading, setLoading] = useState(false);
  const [loadingBasketData, setLoadingBasketData] = useState(false);

  const [countBadge, setCountBadge] = useState<any>();

  const [filterItems, setFilterItems] = useState<any>([]);
  const navigate = useNavigate();
  const [showDetailModal,setShowDetailModal]=useState(false)
  const [itemReview,setItemReview]=useState('')
const [parentCountBox,setParentCountBox]=useState(true)
const {setEmptyBasket}=useContext(LayoutContext)



  const layoutContext = useContext(LayoutContext);

  const previes = () => {
    if (company > 0) {
      setCompany(company - 1);
    } else {
      setCompany(dataLocal.ItemPriceListDetails.length - 1);
    }
  };

  const next = () => {
    if (dataLocal.ItemPriceListDetails.length > company + 1) {
      setCompany(company + 1);
    } else {
      setCompany(0);
    }
  };
  const deleteItem = (pValue) => {
    let Basket: any = localStorage.getItem("Basket");
    Basket = JSON.parse(Basket);

    Object.keys(basketData.order).map((selectItemId) => {
      if (basketData.order[selectItemId].properties == pValue) {
        if (itemId) {
          delete Basket[itemId].order[selectItemId];
          localStorage.setItem("Basket", JSON.stringify(Basket));

          if (Object.values(basketData.order).length > 0) {
            let fristOrder: any;
            if (Object.values(basketData.order).length > 1) {
              fristOrder = Object.values(basketData.order);
              funSetPropertiesKeys(
                basketData.properties,
                fristOrder[
                  JSON.stringify(fristOrder[0].properties) ==
                  JSON.stringify(pValue)
                    ? 1
                    : 0
                ].properties
              );
            } else {
              setForceUpdate(!forceUpdate);
            }
          }
        }
      }
    });
  };

  const funSetPropertiesKeys = (properties, pValue) => {
    let obj;
    if (properties != undefined) {
      Object.keys(pValue).map((properteName) => {
        // properties[properteName]
        properties[properteName].map((q) => {
          if (q.value == pValue[properteName]) {
            obj = { ...obj, [properteName]: q.Id };
          }
        });
      });

      setSelectedPropertiesValue(pValue);
      setSelectedPropertiesKeys(obj);
    }
  };
  async function getItemAsync(id) {
    setLoading(false)
    try {
      const itemData = await getItem(id);
      setDataLocal(itemData.Data);
      let v1 = queryParams.get("v1");
      let v2 = queryParams.get("v2");
      let defaultProperty = {
        Variation1List: v1,
        Variation2List: v2,
      };
      let defaultPropertyValue = {
        Variation1List: itemData.Data.Variation1List[0].Description,
        Variation2List: itemData.Data.Variation2List[0].Description,
      };
      itemData.Data.VariationList.map((item) => {
        if (item.VariationCode == v1) {
          defaultPropertyValue.Variation1List = item.VariationName;
          item.ItemVariations.map((item2) => {
            if (item2.VariationCode == v2) {
              defaultPropertyValue.Variation2List = item2.VariationName;
            }
          });
        }
      });
      setSelectedPropertiesKeys(defaultProperty);
      setSelectedPropertiesValue(defaultPropertyValue);
    } catch (error) {}
    setLoading(true)

  }
  const priceUpdate = () => {
    
    setLoadingBasketData(false)
    let newItemsPrice = 0;
    let newCartSubtotal = 0;
    let basketDataTotal = getBasketData();
    if(!!basketDataTotal.length){
      setEmptyBasket&& setEmptyBasket(false)

    }else{
      setEmptyBasket&& setEmptyBasket(true)

    }
    setTotalBasketData(basketDataTotal);
    setEmptyBasket&& setEmptyBasket(false)
    setForceUpdate(false)

    basketDataTotal.map((items) => {
      Object.values(items.order).map((orders: any) => {
        newItemsPrice =
          newItemsPrice +
          +items?.ItemPriceListDetails[0]?.PriceDetails[orders.priceIndex]
            ?.SalesUnitPrice *
            +orders.count;
        newCartSubtotal =
          newCartSubtotal +
          (items?.ItemPriceListDetails[0]?.PriceDetails[orders.priceIndex]
            ?.UnitDiscount != null
            ? +items?.ItemPriceListDetails[0]?.PriceDetails[orders.priceIndex]
                ?.SalesUnitPrice -
              +items?.ItemPriceListDetails[0]?.PriceDetails[orders.priceIndex]
                ?.UnitDiscount
            : +items?.ItemPriceListDetails[0]?.PriceDetails[orders.priceIndex]
                ?.SalesUnitPrice) *
            +orders.count;
        setPriceInfo({
          itemsPrice: newItemsPrice,
          cartSubtotal: newCartSubtotal,
        });
      });
    });

    if (
      basketDataTotal == undefined ||
      Object.keys(basketDataTotal).length == 0
    ) {
      setShowCartsubtotal(false);
      setCountBadge(null);
    } else {
      setShowCartsubtotal(true);
    }
    setPriceInfo({ itemsPrice: newItemsPrice, cartSubtotal: newCartSubtotal });
   setLoadingBasketData(true)
  };

  const ClickThumbNailImg = (img) => {
    setShowGallary(true);
    setImgSelected(img);
  };

  useEffect(() => {
    if (show) {
      priceUpdate();
    } else {
      setTotalBasketData([]);
    }
  }, [show]);

  useEffect(() => {
    let badge = 0;
    let basketData = getBasketData();

    // change ?

    if (basketData && basketData.length > 0) {
      {
        basketData.map((item, index) => {
          if (item?.order) {
            Object.values(item?.order).map((e: any) => {
              badge = badge + 1;
            });
          }
        });
      }
      setCountBadge(badge);
    } else {
      setCountBadge(null);
    }
    if (show) {
      priceUpdate();
    }
  }, [layoutContext.basket]);

  useEffect(() => {
    layoutContext.setBasket();
  }, [selectedPropertiesKeys]);

  useEffect(() => {
    let Basket: any = localStorage.getItem("Basket");
    Basket = JSON.parse(Basket);
    if (Basket) {
      setBasketData(
        Object.values(Basket).find((item: any) => item.Id == itemId)
      );
    }
    detailActiveRef?.current?.scrollIntoView(false);
  }, [layoutContext.basket]);

  useEffect(() => {
    if (basketData && Object.keys(selectedPropertiesKeys).length == 0) {
      let item: any = Object.values(basketData.order)[0];
      if (item?.properties) {
        funSetPropertiesKeys(basketData.properties, item?.properties);
      }
    }
  }, [basketData]);

  useEffect(() => {
    getItemAsync(itemId);
  }, [itemId, forceUpdate]);

  useEffect(() => {
    if (!!dataLocal?.Attachments?.length) {
      setImgSelected(dataLocal?.Attachments[0]?.Id);
    }
if(dataLocal?.VariationList[0]){

  setSelectedPropertiesKeys({
    ...selectedPropertiesKeys,
    Variation1List: dataLocal?.VariationList[0]?.VariationCode,
    Variation2List:
      dataLocal?.VariationList[0].ItemVariations[0]?.VariationCode,
  });
  setSelectedPropertiesValue({
    ...selectedPropertiesValue,
    Variation1List: dataLocal?.VariationList[0]?.VariationName,
    Variation2List:
      dataLocal?.VariationList[0].ItemVariations[0]?.VariationName,
  });
}
  }
 ,[dataLocal]);


  useEffect(()=>{
    if(!!dataLocal?.ItemPriceListDetails[0].PriceDetails?.length){
    dataLocal?.ItemPriceListDetails[0]?.PriceDetails.forEach((item)=>{
    let valueCodes=Object.values(selectedPropertiesKeys).join().replaceAll(',','')
    if(item.ItemCode.endsWith(valueCodes)){
    setItemReview(item.ItemReview)
                }
})

    }
  },[selectedPropertiesKeys,dataLocal])
  


  useEffect(() => {
    let badge = 0;
    let basketData = getBasketData();

    // change ?

    if (basketData && basketData.length > 0) {

      {
        basketData.map((item, index) => {
          if (item?.order) {
            Object.values(item?.order).map((e: any) => {
              badge = badge + 1;
            });
          }
        });
      }
      setCountBadge(badge);
    } else {
      setCountBadge(null);
    }
    if (show) {
      priceUpdate();
    }
  }, [layoutContext.basket]);

  useEffect(() => {
    if (!!totalBasketData?.length) {
      setFilterItems(() =>
        totalBasketData.filter((item) => item?.Code?.includes(dataLocal?.Code))
      );
    }
  }, [dataLocal]);


  
  useEffect(() => {
    if (filterItems[0]?.order) {
      let order: any = Object.values(filterItems[0]?.order)[0];
      setSelectedPropertiesValue({
        ...selectedPropertiesValue,
        Variation1List: order?.properties?.Variation1List,
        Variation2List: order?.properties?.Variation2List,
      });
      setSelectedPropertiesKeys({
        ...selectedPropertiesKeys,
        Variation1List: dataLocal?.Variation1List.find(
          (item) => item.Description == order?.properties?.Variation1List
        ).Id,
        Variation2List: dataLocal?.Variation2List.find(
          (item) => item.Description == order?.properties?.Variation2List
        ).Id,
      });
    }
  }, [filterItems]);


  return (
    <>
      {showIncreaseDecrease && (
        <ModalForm
          modalHeight={80}
          title={selectedIncreaseDecrease.title}
          onHide={() => {
            setShowIncreaseDecrease(false);
          }}
          show={showIncreaseDecrease}
        >
          <IncreaserDecreaser
            onHide={() => {
              setShowIncreaseDecrease(false);
            }}
            object={selectedIncreaseDecrease}
            checkBoxHandle={setCheckBox}
          />
        </ModalForm>
      )}


{showDetailModal && 
                <ModalForm modalHeight={63} mode={"view"} title={dataLocal?.ItemDescription+' - '+dataLocal?.Code} onHide={() => setShowDetailModal(false)} show={showDetailModal}>
                        <div className="p-2 text-editor-parent" style={{textAlign:'justify',maxWidth:'100%'}}
                            dangerouslySetInnerHTML={{ __html:dataLocal.ItemReview ?dataLocal.ItemReview:itemReview}}
                        
                        >
                          </div>                       
                </ModalForm>
        }
    {loading?  <div className="itemDetail p-0" style={{ userSelect: "none" }}>
        <div className=" col-8 mb-1">
          <Link
            to={"/e-commerce/Items"}
            key={1}
            onClick={() => {}}
            style={{ cursor: "pointer" }}
          >
            {trans("ecommerce")}
          </Link>
          <span key={2} className="bread-crumb__separator">
            /
          </span>
          <Link
            to={
              "/e-commerce/Items" + "?itemGroup=" + dataLocal?.GroupLeafItemId
            }
            key={1}
            onClick={() => {}}
            style={{ cursor: "pointer" }}
          >
            {dataLocal?.GroupLeafItemDescription}
          </Link>
          <span key={2} className="bread-crumb__separator">
            /
          </span>
          {dataLocal?.ItemDescription}
        </div>
        <div className="row h-100">
          <div className="col-lg-8 col-xxl-9 ps-0">
            <div className="glassBox h-100 p-2 ">
              <div className="row h-100">
                {/* moblieTitleBx */}
                <div className="d-block d-md-none itemDetail__titleBx">
                  <div
                    className="itemDetail__titleBx__nameFa showDotsTowLine my-1"
                    style={{ userSelect: "text" }}
                  >
                    {dataLocal?.Description}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="itemDetail__titleBx__ratingBx ms-2 ">
                      {dataLocal?.rate && (
                        <div>
                          <FontAwesomeIcon
                            icon={["fas", "star"]}
                            style={{ color: "gold", marginLeft: "5px" }}
                          />
                          <span>{dataLocal?.rate}</span>
                        </div>
                      )}
                    </div>
                    <div
                      className="itemDetail__titleBx__nameEN text-muted showDots"
                      style={{ userSelect: "text" }}
                    >
                      {dataLocal?.ItemDescriptionEN}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div style={{ height: "45vh", maxHeight: "45vh" }}>
                    <div className="d-md-none m-auto itemDetail__mobileGallery">
                      {dataLocal?.Attachments && (
                        <Gallery
                          selectImg={
                            imgSelected
                              ? dataLocal?.Attachments.map(
                                  (item) => item.Id
                                ).indexOf(imgSelected?.value)
                              : 0
                          }
                          data={dataLocal?.Attachments.map((item) => {
                            return item.Id;
                          })}
                          showGallery={true}
                        />
                      )}
                    </div>
                    <div
                      className="itemDetail__imgSelected d-md-flex d-none w-100"
                      style={{ height: "80%" }}
                    >
                      <ul className="d-flex flex-column align-items-end justify-content-start h-100 ps-1 itemDetail__imgSelected__svgFontAs">
               
                          <li onClick={() => setLike(!like)}>
                          <Link to={`${location?.state?.itemGroupId===GUID_EMPTY?'/e-commerce/Items':'/e-commerce/Items?itemGroup='+location?.state?.itemGroupId}`} >
                            <FontAwesomeIcon icon={['fas',"arrow-right"]} className="" />
                            </Link>
                          </li>
                     
                       
                        {false ? (
                          <li onClick={() => setLike(!like)}>
                            <FontAwesomeIcon
                              icon={["fas", "heart"]}
                              className="text-customerPrimaryColor"
                            />
                          </li>
                        ) : (
                          <li onClick={() => setLike(!like)}>
                            <FontAwesomeIcon icon={["far", "heart"]} />
                          </li>
                        )}
                        {false ? (
                          <li className="position-relative">
                            <div className="position-absolute d-flex align-items-center justify-content-center bg-customerPrimaryColor itemDetail__imgSelected__itemNotif">
                              {" "}
                              {itemNotif > 99 ? "99+" : itemNotif}
                            </div>
                            <FontAwesomeIcon icon={["far", "bell"]} />
                          </li>
                        ) : (
                          <li>
                            <FontAwesomeIcon icon={["far", "bell"]} />
                          </li>
                        )}
                        <li className="mt-2">
                          <FontAwesomeIcon icon={["fas", "share-nodes"]} />
                        </li>
                      </ul>
                      <div
                        className="itemDetail__imgSelected__imgBx w-100 h-100"
                        onClick={() => {
                          setShowGallary(true);
                        }}
                      >
                        {itemId && <ItemBuyImg attchId={imgSelected} />}
                      </div>
                    </div>
                    <div
                      className="itemDetail__gallery d-none d-md-block w-100"
                      style={{ height: "20%" }}
                    >
                      <ul
                        className={`d-flex position-relative ${
                          dataLocal?.Attachments != undefined &&
                          dataLocal?.Attachments == 4
                            ? "justify-content-between"
                            : "justify-content-start"
                        } align-items-center w-100 h-100`}
                      >
                        {dataLocal?.Attachments.map((e: any, index) => {
                          if (!!dataLocal?.Attachments?.length) {
                            if (index <= 3) {
                              if (
                                dataLocal?.Attachments.length > 4 &&
                                index == 3
                              ) {
                                return (
                                  <li
                                    key={index + "itemDetail__gallery"}
                                    onClick={() => {
                                      ClickThumbNailImg(e.Id);
                                    }}
                                    className="d-flex align-items-center  justify-content-center blurClass"
                                  >
                                    <FontAwesomeIcon
                                      icon={["fas", "ellipsis"]}
                                      style={{
                                        zIndex: 2,
                                        position: "absolute",
                                        fontSize: "25px",
                                      }}
                                    ></FontAwesomeIcon>
                                    <ImgBox attchId={e.Id} />{" "}
                                  </li>
                                );
                              } else {
                                return (
                                  <li
                                    key={index + "itemDetail__gallery"}
                                    onClick={() => {
                                      ClickThumbNailImg(e.Id);
                                    }}
                                    className={`${e.Id==imgSelected&&'activethumnail'} d-flex align-items-center ms-2 justify-content-center`}
                                  >
                                    <ImgBox attchId={e.Id} />{" "}
                                  </li>
                                );
                              }
                            }
                          } else {
                            <></>;
                          }
                        })}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="mt-1"
                    style={{ height: "37vh", maxHeight: "37vh" }}
                  >
                    <div
                      className="font-size-lg fw-6 d-flex align-items-center"
                      style={{
                        padding: "15px 15px 0px 15px",
                        fontSize: "14px",
                        fontWeight: "600",
                        maxHeight:'15%'
                        ,
                      }}
                    >
                      {trans("ItemReview")} :
                    </div>

                      {(dataLocal?.ItemReview || itemReview) && (
                    <div className=" p-2  w-100 overflow-hidden "  style={{fontSize:'13px',maxHeight:'80%',height:'80%'}}>

                   
                          <div
                            className="order-1 order-md-0 mb-2 reviewBox text-secondary text-editor-parent-box overflow-hidden"
                            dangerouslySetInnerHTML={{ __html: dataLocal.ItemReview?dataLocal.ItemReview:itemReview}}
                          >
                        

                        </div> 
                     <a className="d-block text-start text-primary "  onClick={()=>setShowDetailModal(true)} style={
                      {
                        cursor: "pointer",  width: "100%", textDecoration: 'underline'
                      }
                     }>ادامه مطلب</a>    
                        </div>
                      )}
              
                  </div>
                </div>
                {/* deskTopTitleBx */}
                <div className="col-md-8 h-100 detailBx">
                  <div className="d-md-block d-none">
                    <div className="row  my-1" style={{ userSelect: "text" }}>
                      <div className="col-6">
                        <span style={{ direction: "ltr" }}>
                          {`${dataLocal?.ItemDescription} 
                                        ${dataLocal?.Code}`}
                        </span>
                      </div>
                      <div className="col-6 text-start">
                        <div
                          className=" showDots "
                          style={{ userSelect: "text" }}
                        >
                          {dataLocal?.ItemDescriptionEN}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="itemDetail__titleBx__ratingBx ms-2 ">
                        {dataLocal?.rate && (
                          <div className="font-size-sm">
                            <FontAwesomeIcon
                              icon={["fas", "star"]}
                              color="gold"
                              style={{ marginLeft: "5px" }}
                            />
                            <span>{dataLocal?.Rate}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="itemDetail__fixPropertiesBx d-flex flex-column">
                    <div className="itemDetail__fixPropertiesBx__propertiesItem glassBox my-2 p-2">
                      {dataLocal?.VariationList &&
                        dataLocal?.VariationList.length > 0 && (
                          <>
                            {dataLocal?.VariationList.length > 0 && (
                              <div
                                key={
                                  1 +
                                  "itemDetail__fixPropertiesBx__propertiesItem"
                                }
                                className="d-sm-flex align-items-center "
                              >
                                <div
                                  className="font-size-lg fw-6 showDots"
                                  style={{
                                    minWidth: "80px",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  {trans(
                                    dataLocal?.VariationNamesList[0]
                                      ?.ItemMainPropertyDescription
                                  )}{" "}
                                  :{" "}
                                </div>{" "}
                                <div style={{ overflowX: "auto" }}>
                                  <ItemGroupLabelSlider
                                    children={dataLocal?.VariationList.map(
                                      (e, i) => (
                                        <>
                                          <ItemGroupLebal
                                            IsActive={
                                              !!Object.keys(selectedPropertiesKeys)
                                                ?.length
                                                ? selectedPropertiesKeys.Variation1List ==
                                                  e.VariationCode
                                                  ? true
                                                  : false
                                                : i == 0 && true
                                            }
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedPropertiesKeys({
                                                ...selectedPropertiesKeys,
                                                Variation1List: e.VariationCode,
                                                Variation2List:
                                                  e.ItemVariations?.[0]
                                                    .VariationCode,
                                              });
                                              setSelectedPropertiesValue({
                                                ...selectedPropertiesValue,
                                                Variation1List: e.VariationName,
                                                Variation2List:
                                                  e.ItemVariations?.[0]
                                                    .VariationName,
                                              });
                                            }}
                                            id={e.VariationCode}
                                            key={i + "propertiesItemDetail"}
                                            shape={"Foursquare"}
                                            title={e.VariationName}
                                          />
                                        </>
                                      )
                                    )}
                                    isArrow={true}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      {dataLocal?.VariationList &&
                        dataLocal?.VariationList.length > 0 && (
                          <>
                            {dataLocal?.VariationList.length > 0 && (
                              <div
                                key={
                                  1 +
                                  "itemDetail__fixPropertiesBx__propertiesItem"
                                }
                                className="d-sm-flex align-items-center "
                              >
                                <div
                                  className="font-size-lg fw-6 showDots"
                                  style={{
                                    minWidth: "80px",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  {trans(
                                    dataLocal?.VariationNamesList[1]
                                      ?.ItemMainPropertyDescription
                                  )}{" "}
                                  :{" "}
                                </div>{" "}
                                <div style={{ overflowX: "auto" }}>
                                  {dataLocal?.VariationList.map((e, i) => (
                                    <>
                                      {selectedPropertiesKeys.Variation1List ==
                                        e.VariationCode && (
                                        <ItemGroupLabelSlider
                                          children={
                                            <>
                                              {e.ItemVariations.map((item) => (
                                                <ItemGroupLebal
                                                  IsActive={
                                                    Object.keys(selectedPropertiesKeys)
                                                      .length != 0
                                                      ? selectedPropertiesKeys.Variation2List ==
                                                          item.VariationCode ||
                                                        selectedPropertiesValue.Variation2List ==
                                                          item.VariationName
                                                        ? true
                                                        : false
                                                      : i == 0 && true
                                                  }
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    setSelectedPropertiesKeys({
                                                      ...selectedPropertiesKeys,
                                                      Variation2List:
                                                        item.VariationCode,
                                                    });
                                                    setSelectedPropertiesValue({
                                                      ...selectedPropertiesValue,
                                                      Variation2List:
                                                        item.VariationName,
                                                    });
                                                  }}
                                                  id={item.VariationCode}
                                                  key={
                                                    i + "propertiesItemDetail"
                                                  }
                                                  shape={"Foursquare"}
                                                  title={item.VariationName}
                                                />
                                              ))}
                                            </>
                                          }
                                          isArrow={true}
                                        />
                                      )}
                                    </>
                                  ))}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                    </div>

                    {dataLocal?.ItemPriceListDetails?.map(
                      (e, index) =>
                        company == index && (
                          <div
                            key={index + "company"}
                            className="glassBox mt-2 py-1 px-2 itemDetail__fixPropertiesBx__company"
                            style={{ maxHeight: "284px" }}
                          >
                            <div className="fw-semibold font-size-lg row justify-content-between mb-2">
                              <div className="col-7">{e.Description}</div>

                            </div>
                            <div
                              className="d-flex justify-content-between "
                              style={{ height: "80%" }}
                            >
                              {dataLocal?.ItemPriceListDetails.length > 1 && (
                                <div
                                  className="ms-2 d-flex align-items-center"
                                  onClick={next}
                                >
                                  <FontAwesomeIcon
                                    icon={["fas", "chevron-right"]}
                                    style={{ cursor: "pointer" }}
                                  ></FontAwesomeIcon>
                                </div>
                              )}
                              <div
                                className="itemDetail__fixPropertiesBx__priceBx w-100 position-relative"
                                style={{ overflowY: "auto" }}
                              >
                                <div className="row" style={{ width: "100%" }}>
                                  {e.PriceDetails.map((p, i) => (
                                    <>
                                      {p.ItemCode ==
                                      dataLocal.Code +
                                        selectedPropertiesKeys.Variation1List +
                                        selectedPropertiesKeys.Variation2List ? (
                                        <div
                                          key={i + "companyItems"}
                                          ref={
                                            activeIndex == i
                                              ? detailActiveRef
                                              : null
                                          }
                                          className={`col-12  ${
                                            e?.PriceDetails.length != 1
                                              ? "col-md-8 "
                                              : ""
                                          } mb-2`}
                                        >
                                          <div
                                            className={`border w-100 glassBox position-relative p-1 ${
                                              activeIndex == i
                                                ? "itemDetail__fixPropertiesBx__company__itemBoxActive"
                                                : ""
                                            }`}
                                          >
                                            <div className="mt-1 mx-2 font-size-md">
                                              <div className="d-flex text-muted">
                                                <div className="text-muted ">
                                                  <GroupingPrice
                                                    value={p?.ItemDescription}
                                                  />
                                                </div>
                                              </div>
                                              {p?.PackingPrice !=
                                                p?.SalesUnitPrice && (
                                                <div className="d-flex text-muted">
                                                  <div>
                                                    {" "}
                                                    {trans("SalesUnit")}:
                                                  </div>
                                                  <div className="text-muted me-1">
                                                    <GroupingPrice
                                                      value={p?.SalesUnit}
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                              <div className="text mt-1 pe-2 row">
                                                {p?.SalesUnitMaxOrderQty !=
                                                null ? (
                                                  <>
                                                    <div
                                                      style={{
                                                        fontWeight: "500",
                                                      }}
                                                      className="col-7"
                                                    >
                                                      {trans("from")}{" "}
                                                      {p.SalesUnitMinOrderQty}{" "}
                                                      {trans("to")}{" "}
                                                      {p.SalesUnitMaxOrderQty}{" "}
                                                    </div>
                                                    <div
                                                      className={`fw-bolder  ${
                                                        p?.DiscountPercent !=
                                                          null &&
                                                        p?.DiscountPercent !=
                                                          0 &&
                                                        "text-muted text-decoration-line-through"
                                                      }  col-4`}
                                                    >
                                                      <GroupingPrice
                                                        value={
                                                          p?.SalesUnitPrice
                                                        }
                                                      />
                                                    </div>
                                                  </>
                                                ) : p?.SalesUnitMinOrderQty !=
                                                  0 ? (
                                                  <>
                                                    <div className="text col-5">
                                                      <span>
                                                        {trans("greaterthan")}{" "}
                                                        {
                                                          p?.SalesUnitMinOrderQty
                                                        }{" "}
                                                      </span>
                                                    </div>
                                                    <div
                                                      className={`fw-bolder ${
                                                        p?.DiscountPercent !=
                                                          null &&
                                                        p?.DiscountPercent !=
                                                          0 &&
                                                        "text-muted text-decoration-line-through"
                                                      } col-7`}
                                                    >
                                                      <GroupingPrice
                                                        value={p.SalesUnitPrice}
                                                      />
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="row">
                                                    <div className="text col-5">
                                                      <span>
                                                        {" "}
                                                        قیمت : {dataLocal.Unit}
                                                      </span>
                                                    </div>
                                                    <div
                                                      className={`fw-bolder ${
                                                        p?.DiscountPercent !=
                                                          null &&
                                                        p?.DiscountPercent !=
                                                          0 &&
                                                        "text-muted text-decoration-line-through"
                                                      } me-2 col-7`}
                                                    >
                                                      <GroupingPrice
                                                        value={p.SalesUnitPrice}
                                                      />
                                                    </div>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                              {p?.DiscountPercent != null &&
                                                p?.DiscountPercent != 0 && (
                                                  <div className="row mt-1 pe-2">
                                                  <div className="col-5">
                                                    <span> تخفیف :</span>
                                                    <span className="text-customerPrimaryColor me-1">
                                                      {p.DiscountPercent}
                                                      <span className="font-size-xs">
                                                        %
                                                      </span>
                                                    </span>
                                                  </div>
                                            
                                                  <div className="col-7 fw-bolder">
                                                    <GroupingPrice
                                                      value={
                                                        p.SalesUnitPrice *
                                                        (p.DiscountPercent /
                                                          100)
                                                      }
                                                    />
                                                  </div>
                                            </div>
                                                )}
                                            <div className=" mt-1 pe-2 ">
                                              {p?.DiscountPercent != null &&
                                                p?.DiscountPercent != 0 && (
                                                  <div className="row">
                                                    <div className="col-5"> قیمت نهایی :</div>
                                                    <div className="col-7 fw-bolder ">
                                                      <GroupingPrice
                                                        value={roundOrTruncate(
                                                          p.SalesUnitPrice -
                                                            p.UnitDiscount,
                                                          p.PriceRoundingPlace,
                                                          true
                                                        )}
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                            </div>
                                            <div
                                              className={`${
                                                p?.DiscountPercent != null &&
                                                p?.DiscountPercent != 0
                                                  ? "mt-2"
                                                  : "mt-4"
                                              }  d-flex justify-content-start `}
                                            >
                                              <div className="itemDetail__fixPropertiesBx__priceBx__btnBx  d-flex  ">
                                                <ItemCountSeter
                                                  ItemData={dataLocal}
                                                  properties={{
                                                    ...selectedPropertiesValue,
                                                    Variation1Code:
                                                      selectedPropertiesKeys?.Variation1List,
                                                    Variation2Code:
                                                      selectedPropertiesKeys?.Variation2List,
                                                  }}
                                                  propertiesId={
                                                    dataLocal?.Id +
                                                    (Object.keys(selectedPropertiesKeys)
                                                      .length == 0
                                                      ? ""
                                                      : Object.values(
                                                          selectedPropertiesKeys
                                                        ).join(""))
                                                  }
                                                  min={p?.SalesUnitMinOrderQty}
                                                  max={p?.SalesUnitMaxOrderQty}
                                                  stock={1000000}
                                                  defultValue={
                                                    p?.SalesUnitMinOrderQty
                                                  }
                                                  priceIndex={i}
                                                  activeBtn={activeIndex}
                                                  setActiveBtn={setActiveIndex}
                                                  pluseInRight={false}
                                                  positionRelative={true}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ))}
                                </div>
                              </div>
                              {dataLocal?.ItemPriceListDetails.length > 1 && (
                                <div
                                  className="me-2 d-flex align-items-center"
                                  onClick={previes}
                                >
                                  <FontAwesomeIcon
                                    icon={["fas", "chevron-left"]}
                                    style={{ cursor: "pointer" }}
                                  ></FontAwesomeIcon>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                    )}
                    {false && (
                      <div className="itemDetail__fixPropertiesBx__increaserDecreaser mt-3">
                        <div className="row d-flex align-items-center justify-content-between px-2">
                          {dataLocal?.increaserDecreaser.map((e, i) => (
                            <div
                              key={
                                i +
                                "itemDetail__fixPropertiesBx__increaserDecreaser"
                              }
                              className={`${
                                dataLocal?.increaserDecreaser?.length > 3
                                  ? "col-md-6 px-1"
                                  : "col-md-12"
                              } col-12`}
                            >
                              <div
                                className="d-flex align-items-center justify-content-between glassBox my-1 p-2"
                                style={{ height: "50px" }}
                              >
                                <div
                                  className="d-flex"
                                  style={{ width: "80%" }}
                                >
                                  <div className="border-start d-flex align-items-center justify-content-center ps-2 ms-2">
                                    <input type="checkbox" />
                                  </div>
                                  <div
                                    style={{ maxWidth: "85%", minWidth: "85%" }}
                                  >
                                    <div
                                      className="showDots font-size-md"
                                      style={{ width: "90%" }}
                                    >
                                      {e.title}
                                    </div>
                                    {/* <div><GroupingPrice value={e.price}/></div> */}
                                  </div>
                                </div>
                                <div className="font-size-md">
                                  <a
                                    className="text-customerPrimaryColor fw-bolder"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setShowIncreaseDecrease(true);
                                      setSelectedIncreaseDecrease(e);
                                    }}
                                  >
                                    {trans("details")}
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-none d-lg-block col-lg-4 col-xxl-3 col h-100">
            {/* <div className="itemDetail__propertyBx d-flex flex-column h-100 glassBox">
                        <div className="order-1 order-md-0 h-50" style={{maxHeight:'50%',overflowY:'auto'}}>
                            <div className="font-size-lg fw-6" style={{padding:'15px 15px 0px 15px',fontSize:'14px',fontWeight:'600'}}>{trans("ItemReview")} :</div>
                         {   dataLocal?.ItemReview &&   <div className=" px-3 pt-1 fs-7 text-secondary" style={{textAlign:'justify',overflowY:'auto'}}>
                            {dataLocal?.ItemReview}
                            </div>}
                            
                            
                        </div>
                            {
                            basketData && basketData.order && Object.values(basketData?.order).length > 0 &&
                            <div className="order-0 order-md-1 mt-3" style={{padding:'0px 15px'}}>

                                <div  style={{boxSizing:'border-box',fontSize:'14px',fontWeight:'600'}}>تنوع های انتخاب شده در سبد خرید :</div>
                                <ul id="listItem" style={{overflowY:'auto'}} className="w-100">
                                    {
                                        basketData &&
                                        Object.values(basketData.order).map((item:any,index) =>(
                                        
                                        <li className={`border glassBox w-100 my-1  p-2 ${JSON.stringify(item.properties) == JSON.stringify(selectedPropertiesValue) ? ' active ' :''}`} onClick={()=>{funSetPropertiesKeys(basketData.properties,item.properties)}}>
                                            <div className="d-flex align-items-start justify-content-between">
                                        

                                                <div>
                                                    <div className="d-flex align-items-center">
                                                            <span> {trans("itemcode")} :  </span>
                                                            <span> {basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].ItemCode	}  </span>
                                                    </div>
                                                    {
                                                    Object.keys(item.properties).map((p, index1)=>(
                                                        <div className="d-flex align-items-center">
                                                            <span>{trans(dataLocal?.VariationNamesList[index1]?.ItemMainPropertyDescription)} : </span>
                                                            {p != 'color' ?
                                                            <span>{item.properties[p]}</span>
                                                            :
                                                            <div style={{backgroundColor:item.properties[p],width:'10px',height:'10px',borderRadius:'0.25rem'}}></div>
                                                            }
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                                <div onClick={(e)=>{e.stopPropagation();deleteItem(item.properties)}}>
                                                    <FontAwesomeIcon  icon={["fas", "trash-can"]}/>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <div>
                                                    <div className="d-flex">فی  :  <GroupingPrice value={basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].DiscountPercent ? (+basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].UnitPrice- basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].UnitDiscount) : +basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].UnitPrice}/></div>
                                                
                                                </div>
                                                <div className="d-flex justify-content-between">
                                           
                                                    <span>
                                                        <div>تعداد  :</div>
                                                        <div className="ms-1">{item.count}</div>   
                                                    </span>
                                                    <span>
                                                        <div>قیمت کل  :</div>
                                                        <div className="d-flex">
                                                            <span className="fw-bolder ms-1"><GroupingPrice value={basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].DiscountPercent ? (+basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].UnitPrice	-  +basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].UnitDiscount) * +item.count :+basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].UnitPrice * +item.count}/></span>
                                                            {
                                                            basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].DiscountPercent ? (<span className="ms-1 font-size-xs text-customerPrimaryColor">(<span>%</span><span>{basketData.ItemPriceListDetails[0].PriceDetails[item.priceIndex].DiscountPercent}</span>)</span>):<></>
                                                            }
                                                        </div>
                                                    </span>
                                                </div>
                                             
                                            </div>
                                        </li>
                                    ))
                                    }
                                </ul>
                            </div>
                            }
                    </div> */}
{/* 
            <div className="d-flex card flex-column justify-content-stretch align-items-center h-100 ">
              <div className="d-flex w-100 justify-content-between align-items-center buyBtn__listItem__basketHeader">
                <div>
                  <span className="font-size-xl text-customerPrimaryColor">
                    <FontAwesomeIcon icon={["fas", "basket-shopping"]} />
                    {trans("ShoppingCart")}
                  </span>
                </div>
                <div>
                  <nav className="d-md-flex justify-content-start col-2">
                    <div style={{ maxWidth: "25%", minWidth: "fit-content" }}>
                      <SwitchBtn
                        cardStyle={cardStyle}
                        firstMode="کارتی"
                        secondMode="لیستی"
                        setColor="customerColor"
                        setShape="circle"
                        handleModeCard={() => setCardStyle(!cardStyle)}
                      />
                    </div>
                  </nav>
                </div>
              </div>
              {loading ? (
                <>
                  <ul className="w-100 buyBtn__listItem__contents">
                    {totalBasketData.map((item) => {
                      return Object.values(item.order).map(
                        (order: any, index) => (
                          <ItemBox
                            styles={{
                              zIndex: "1!important",
                              display: `${cardStyle ? "block" : "none"}`,
                            }}
                            item={item}
                            order={order}
                            index={index}
                            UpdateTotal={priceUpdate}
                            handleSelectedItem={handleSelectedItem}
                          />
                        )
                      );
                    })}
                  </ul>

                  <div className="d-flex justify-content-between align-items-center w-100 buyBtn__listItem__footerList">
                    <div
                      className={`d-flex ${
                        showCartsubtotal
                          ? "justify-content-between"
                          : "justify-content-end"
                      } align-items-center pt-2 w-100`}
                    >
                      {showCartsubtotal && (
                        <div className="d-flex font-size-xl">
                          <span className="ms-2" style={{ fontSize: "16px" }}>
                            {trans("cartsubtotal")} :
                          </span>
                          <span className="text-customerPrimaryColor ">
                            <GroupingPrice value={priceInfo.cartSubtotal} />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-start mb-3 w-100 row justify-content-between px-2">
                  <div className="col-5 text-start">
                                <Link to={"/e-commerce/Items"} >
                                  <Button className=" btn-customerPrimaryColor border-0 btn-sm py-2">
                                    {trans("ContinueShopping")}
                                  </Button>
                                </Link>
                     </div>
                    <button
                      className="btn btn-customerPrimaryColor  col-7 col-sm-7"
                      style={{ fontSize: "15px" }}
                      disabled={showCartsubtotal && !!totalBasketData.length ? false : true}
                      onClick={() => setShow(false)}
                    >
                      
                      <Link
                        to={"/e-commerce/CartPage"}
                        style={{
                          cursor: showCartsubtotal ? "pointer" : "no-drop",
                        }}
                      >
                        {trans("FinalizeShopping")}
                      </Link>
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div> */}
            <div className="d-flex glassBox card flex-column justify-content-stretch align-items-center h-100 ">
            <Cart showCardStyle={false} 
            backPrevPage={()=>navigate(`${location.state.itemGroupId===GUID_EMPTY?'/e-commerce/Items':'/e-commerce/Items?itemGroup='+location.state.itemGroupId}`)}
            clearBasket={()=>{
              Toast(trans("Msg_ConfirmClearShoppingCart"), "warning", "alertWithoutClose", () => {
                localStorage.removeItem('Basket');
                 setTotalBasketData([]);
                 setForceUpdate(true);
                 setActiveIndex(null)
                 setEmptyBasket &&setEmptyBasket(true)
                //  setParentCountBox(false)
                 
              });
            }} setShow={setShow} loading={loadingBasketData} basketData={totalBasketData} showCartsubtotal={showCartsubtotal} priceInfo={priceInfo} priceUpdate={priceUpdate} withClose={false} />
          </div>
          </div>
        </div>
      </div>:<Loading/>}
    </>
  );
};
