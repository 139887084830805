import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const  DeleteItemTree =( async (id) =>  await http({
    baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.browse,
   method: "delete",
   params:{
       id : id
   }
})
.then((respons) => (respons.data))
)

export const  DeleteItemTreeDetail =( async (id) =>  await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.browse,
    method: "delete",
   params:{
       id : id
   }
})
.then((respons) => (respons.data))
)

export const getItemGroupTree = (id: any) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.getRootes,
    method: "get",
    params: {
        ParentId: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});



export const getItemGroup = (warehouseId  ,pageNumber,pageSize,orderByString,searchString,filterString) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.getLeafList,
    method: "get",
    params: {
        warehouseId: warehouseId,
        PageNumber:pageNumber,
        PageSize:pageSize,
        OrderByString:orderByString,
        SearchString:searchString,
        FilterString:filterString,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});







export const checkLevel = (id=null) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.checkLevel,
    method: "get",
    params: {
        id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});
export const checkLevelItem = (id=null) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.checkCode,
    method: "get",
    params: {
        id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});


export const sendGroupCodeFormat = (format) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.ItemGroupRootsFormat,
    method: "post",
    params: {
        codeFormat: format
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});
export const putGroupCodeFormat = (format) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.ItemGroupRootsFormat,
    method: "put",
    params: {
        codeFormat: format
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});

export const getItemGroupRootsFormat = () => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemApis.ItemTree.ItemGroupRootsFormat,
    method: "get",

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});


