import { useEffect, useState } from "react";


import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Textbox } from "components/Textbox/Textbox";
import { trans } from "utils/helpers";
import { IndicatorPatternsDictionary } from "../IndicatorPatternsDictionary/IndicatorPatternsDictionary";
import { Button } from "components/Button/Button";
import { putSecretariats, getAllowUpdate, getsecretariat } from "../../api/api";
import { Loading } from "components/Loading/loading";
import { Toast } from "components/ToastShow/ToastShow";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
interface Form {
  onHide: () => void;
  reload?: (flag) => void;
  submit?: (flag) => void;
  resetForm: () => void;
  handleShowReferenceStepper?: (referenceId: string) => void;

  Id?: any;
  jobId?: any;
  mode: any;
  type?: any;
  step?:any;
  processId?: any;
  referenceId?: any;
  SecretariatId?: any;
  editInIndicator?: any;
}
export const SecretariatForm = ({ onHide,jobId, Id,SecretariatId,editInIndicator=false , mode,step,resetForm,submit, reload ,handleShowReferenceStepper, type ,processId ,referenceId}: Form) => {

  const [curentTab, setCurentTab] = useState<any>("SecretariatForm")

  const [singleIndicator, setSingleIndicator] = useState(true);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<any>();
  const [allowUpdate, setAllowUpdate] = useState<any>();
  const [updatePatternsDictionary, setUpdatePatternsDictionary] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});




const submitHandler = (e) => {
    e.preventDefault();
    let SecretariatIndicatorPatterns:any =[]
    if(!singleIndicator){
        if(mode == "edit"){
          SecretariatIndicatorPatterns = [ 
          { 
              "Id":response?.SecretariatIndicatorPatterns?.[0].Id,
              "SecretariatId":SecretariatId,
              "IndicatorPatternId": e.target.IndicatorPatternId1.value,
              "LetterType": 1,
              "StartSerial": +e.target.StartSerial1.value,
              "ActivityStatus" : 1
          },
          {
            "Id":response?.SecretariatIndicatorPatterns?.[1].Id,
            "SecretariatId":SecretariatId,
              "IndicatorPatternId": e.target.IndicatorPatternId2.value,
              "LetterType": 2,
              "StartSerial": +e.target.StartSerial2.value,
              "ActivityStatus" : 1
  
          },
          {
            "Id":response?.SecretariatIndicatorPatterns?.[2].Id,
            "SecretariatId":SecretariatId,
              "IndicatorPatternId": e.target.IndicatorPatternId3.value,
              "LetterType": 3,
              "StartSerial": +e.target.StartSerial3.value,
              "ActivityStatus" : 1
  
          }]

        }else{
          SecretariatIndicatorPatterns = [ 
          { 
  
              "IndicatorPatternId": e.target.IndicatorPatternId1.value,
              "LetterType": 1,
              "StartSerial": +e.target.StartSerial1.value,
              "ActivityStatus" : 1
          },
          {
              "IndicatorPatternId": e.target.IndicatorPatternId2.value,
              "LetterType": 2,
              "StartSerial": +e.target.StartSerial2.value,
              "ActivityStatus" : 1
  
          },
          {
              "IndicatorPatternId": e.target.IndicatorPatternId3.value,
              "LetterType": 3,
              "StartSerial": +e.target.StartSerial3.value,
              "ActivityStatus" : 1
  
          }]

        }
    }else {
      if(mode == "edit"){

        SecretariatIndicatorPatterns = [ {
          "Id":response?.SecretariatIndicatorPatterns?.[0].Id,
          "SecretariatId":SecretariatId,
            "IndicatorPatternId": e.target.IndicatorPatternId.value,
            "LetterType": 0,
            "StartSerial": +e.target.StartSerial.value
        }]
      }else{
        SecretariatIndicatorPatterns = [ {
            "IndicatorPatternId": e.target.IndicatorPatternId.value,
            "LetterType": 0,
            "StartSerial": +e.target.StartSerial.value
        }]
      }
    }
    if(SecretariatIndicatorPatterns[0].IndicatorPatternId !="null"){
      if(mode == "edit"){
        let data = {
            "Secretariat": {
              "Id" :SecretariatId ,
              "Name": e.target.Name.value,
              "SingleIndicator": singleIndicator,
              "ActivityStatus" : +e.target.ActivityStatus.value
            },
            "SecretariatIndicatorPatterns": SecretariatIndicatorPatterns
          }
          putSecretariats(data).then(() => {resetForm() ; onHide() })
        }else{
          let data = {
              "Secretariat": {
                "Name": e.target.Name.value,
                "SingleIndicator": singleIndicator
              },
              "SecretariatIndicatorPatterns": SecretariatIndicatorPatterns
            }
            submit&& submit(data)   
      }
      sessionStorage.setItem("changingforms" , "false");
      setUserChanges({});
    }

  
}

async function getsecretariatAsync() {
  setLoading(false)
  try {
      const res = await getsecretariat(SecretariatId);
      setSingleIndicator(res.Data.Secretariat?.SingleIndicator)
      setResponse(res.Data)
  } catch (error) {
  }
  setLoading(true)
}

async function getAllowUpdateAsync() {
  try {
      const res = await getAllowUpdate(SecretariatId);
      setAllowUpdate(res)

  } catch (error) {
  }


}



  const tabChangeHandle = (tab) => {
    setCurentTab(tab)
  } 

  function cancel() {
    if(sessionStorage.getItem("changingforms")+""=="true"){
        Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
            onHide()
        });
    }else{
      onHide()
    };


}

const handleBeforeUnload = (event) => {
  event.preventDefault();
  event.returnValue = '';
};


useEffect(() => {
  if(Object.values(userChanges).filter((item)=>item!=null).length>0){

      sessionStorage.setItem("changingforms" , "true")
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
  }else{
      sessionStorage.setItem("changingforms" , "false")
      window.removeEventListener('beforeunload', handleBeforeUnload);

  }
}, [userChanges]);


  useEffect(() => {
    if(SecretariatId && mode != "create"){
      getAllowUpdateAsync()
        getsecretariatAsync()
      }else{
        setAllowUpdate(true)
      setLoading(true)

    }
}, [SecretariatId , mode])
  /* pagination area*/

  return (
    <>
    {loading ? 
     <form onSubmit={submitHandler}  id="SecretariatForm">
       <div className="mb-4" >
        <FormWithTitle title="SecretariatForm">
    <div   className="TypeSettingForm row card-body pt-0 " >
        <div className="col-md-4 col-sm-12">
            <Textbox
             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
             required={true} 
             textboxName={"Name"}
             textboxType="text" 
             labelText={"Name"} 
             mode={mode}
             defaultValue={response?.Secretariat?.Name} />
        </div>
        {mode != "create" && 
             <div className="col-md-4 col-sm-12">
                  <EnumerationTypeSelect
                   Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                   required={true}
                   label="Status"
                   name="ActivityStatus"
                   enumTypeSelect="ActivityStatus"
                   value ={response?.Secretariat?.ActivityStatus}
                   mode={mode}
                 />
             </div>                      
         }
        <div style={{width:"30%"}} className=" mt-4 mb-4" >
            <CheckableButton
               Change={(e)=>{setUserChanges({...userChanges , ...e})}}
               onchange={()=>{setSingleIndicator(!singleIndicator)}}
               defaultChecked={singleIndicator}
               defaultValue={singleIndicator}
               labelText={"SingleIndicator"}
               inputName={"checkbox"}
               type="checkbox"
               mode={allowUpdate ?mode :"view"}
            />                                
        </div>  

    </div>
    </FormWithTitle>
       </div >
       
       <div className="mb-4">
        <FormWithTitle title="IndicatorPatternAtIndicatorBook">
                <div className=" card-body ">
       {singleIndicator ?
            <div  style={{height:"10vh"}} className="row  ">
                <div className="col-md-4 col-sm-12">
                    <IndicatorPatternsDictionary
                      Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                      handleUpdate={()=>{setUpdatePatternsDictionary(!updatePatternsDictionary)} }
                      UpdateState={updatePatternsDictionary}
                      type= {"SelectBox"}
                      name={"IndicatorPatternId"}
                      required={true}
                      value={response?.SecretariatIndicatorPatterns?.[0]?.IndicatorPatternId}
                      mode={allowUpdate ?mode :"view"}
                      />
                </div>      
                <div className="col-md-4 col-sm-12">
                    <Textbox
                       Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                       required={true}
                       defaultValue={response?.SecretariatIndicatorPatterns?.[0]?.StartSerial}
                       textboxType="number"
                       textboxName="StartSerial"
                       labelText="StartSerial"
                       mode={allowUpdate ?mode :"view"}
                       />
                </div>
            </div>
        :
        <>
        <div style={{height:"10vh"}} className="row ">
        <div className="col">
            <IndicatorPatternsDictionary
              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
              handleUpdate={()=>{setUpdatePatternsDictionary(!updatePatternsDictionary)} }
              UpdateState={updatePatternsDictionary}
              type= {"SelectBox"}
              name={"IndicatorPatternId1"}
              required={true}
              value={response?.SecretariatIndicatorPatterns?.[0]?.IndicatorPatternId? response?.SecretariatIndicatorPatterns?.[0]?.IndicatorPatternId  : null}
              mode={allowUpdate ?mode :"view"}
              />
        </div>    
        <div className="col">
            <EnumerationTypeSelect
              name={"LetterType"}
              enumTypeSelect="LetterType"
              label="LetterType"
              required={true}
              value={1}
              mode={"view"}
            />
        </div>    
        <div className="col">
            <Textbox
               Change={(e)=>{setUserChanges({...userChanges , ...e})}}
               required={true}
               defaultValue={response?.SecretariatIndicatorPatterns?.[0]?.StartSerial ? response?.SecretariatIndicatorPatterns?.[0]?.StartSerial : null}
               textboxType="number"
               textboxName="StartSerial1"
               labelText="StartSerial"
               mode={allowUpdate ?mode :"view"}
               />
        </div>
            {mode != "create" && 
                <div className="col">
                    <EnumerationTypeSelect
                      Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                      required={true}
                      label="Status"
                      name="ActivityStatus1"
                      enumTypeSelect="ActivityStatus"
                      value={response?.SecretariatIndicatorPatterns?.[0]?.ActivityStatus ? response?.SecretariatIndicatorPatterns?.[0]?.ActivityStatus : null}
                      mode={allowUpdate ?mode :"view"}
                    />
                </div>                      
               }
        </div>
        <div style={{height:"10vh"}} className="row ">
        <div className="col">
            <IndicatorPatternsDictionary
              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
              handleUpdate={()=>{setUpdatePatternsDictionary(!updatePatternsDictionary)} }
              UpdateState={updatePatternsDictionary}
              type= {"SelectBox"}
              name={"IndicatorPatternId2"}
              required={true}
              value={response?.SecretariatIndicatorPatterns?.[1]?.IndicatorPatternId? response?.SecretariatIndicatorPatterns?.[1]?.IndicatorPatternId  : null}
              mode={allowUpdate ?mode :"view"}
              />
        </div>    
        <div className="col">
            <EnumerationTypeSelect
              name={"LetterType"}
              label="LetterType"
              enumTypeSelect="LetterType"
              required={true}
              value={2}
              mode={"view"}
            />
        </div>    
        <div className="col">
            <Textbox
               Change={(e)=>{setUserChanges({...userChanges , ...e})}}
               required={true}
               defaultValue={response?.SecretariatIndicatorPatterns?.[1]?.StartSerial ? response?.SecretariatIndicatorPatterns?.[1]?.StartSerial : null}
               textboxType="number"
               textboxName="StartSerial2"
               labelText="StartSerial"
               mode={allowUpdate ?mode :"view"}
               />
        </div>
            {mode != "create" && 
                <div className="col">
                    <EnumerationTypeSelect
                      Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                      required={true}
                      label="Status"
                      enumTypeSelect="ActivityStatus"
                      name="ActivityStatus2"
                      value={response?.SecretariatIndicatorPatterns?.[1]?.ActivityStatus ? response?.SecretariatIndicatorPatterns?.[1]?.ActivityStatus : null}
                      mode={allowUpdate ?mode :"view"}
                      />
                </div>                      
               }
        </div>
        <div style={{height:"10vh"}} className="row ">
        <div className="col">
            <IndicatorPatternsDictionary
              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
              handleUpdate={()=>{setUpdatePatternsDictionary(!updatePatternsDictionary)} }
              UpdateState={updatePatternsDictionary}
              type= {"SelectBox"}
              name={"IndicatorPatternId3"}
              required={true}
              value={response?.SecretariatIndicatorPatterns?.[2]?.IndicatorPatternId? response?.SecretariatIndicatorPatterns?.[2]?.IndicatorPatternId  : null}
              mode={allowUpdate ?mode :"view"}
              />
        </div>    
        <div className="col">
            <EnumerationTypeSelect
              name={"LetterType"}
              label="LetterType"
              enumTypeSelect="LetterType"
              required={true}
              value={3}
              mode={"view"}
            />
        </div>    
        <div className="col">
            <Textbox
               Change={(e)=>{setUserChanges({...userChanges , ...e})}}
               defaultValue={response?.SecretariatIndicatorPatterns?.[2]?.StartSerial ? response?.SecretariatIndicatorPatterns?.[2]?.StartSerial : null}
               textboxType="number"
               textboxName="StartSerial3"
               labelText="StartSerial"
               mode={allowUpdate ?mode :"view"}
               />
        </div>
            {mode != "create" && 
                <div className="col">
                    <EnumerationTypeSelect
                      Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                      required={true}
                      label="Status"
                      name="ActivityStatus3"
                      enumTypeSelect="ActivityStatus"
                      value={response?.SecretariatIndicatorPatterns?.[2]?.ActivityStatus ? response?.SecretariatIndicatorPatterns?.[2]?.ActivityStatus : null}
                      mode={allowUpdate ?mode :"view"}
                    />
                </div>                      
               }
        </div>
        </>
        }
       </div>
       </FormWithTitle>
       </div>
        {  mode !=="view" &&
        <div className="col-lg-12 mt-1 btns_form">
            <Button value="Save" btnType="submit" />
            <Button btnType="cancel" value="Cancel"  onClick={() => {cancel()}} />
        </div>
        } 
        </form>    
  
  
      :<Loading/>
  }

    </>
  )
}