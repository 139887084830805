import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getSalesPreInvoice = (pageNumber: number, pageSize: number, searchString, orderBy, filterlist ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.load,
    // headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString: searchString,
        OrderByString: orderBy,
        FilterString: filterlist,
      
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getSalesPreInvoiceById = (Id ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.load,
    headers: { "Accept-Language": "en" },

    method: 'get',
    params: {
        Id:Id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getSalesPreInvoiceOrGenerate = (SourceData,SourceId,ReferenceId,JobId ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.getOrGenerate,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
        SourceData,
        ReferenceId,
        JobId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const getPriceListDetail = (Id,priceListId ,code , warehouseId ,warehouseDetailId,personId ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListDetailApis.getId,
    method: 'get',
    params: {
        ItemId:Id,
        ItemCode:code , 
        PriceListId:priceListId,
        WarehouseId:warehouseId,
        CustomerPersonId:personId,
      //warehouseDetailId:warehouseDetailId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const GenerateFromSource = (SourceId, SourceData  ) => http({
    baseURL:INVENTORYANDSALES_URL,
    headers: { "Accept-Language": "en" },
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.generate,
    method: 'get',
    params:{
        SourceData ,
        SourceId
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const postSalesPreInvoice = (data ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.load,
    method: 'post',
    data:data,
    params:{Confirm:false}
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const postSalesPreInvoiceWorkFlow = (data ,referenceId ,increased) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice?.[increased ?"FromSourceWithIncrease"  :"workflowFromSource"],
    method: 'post',
    data:data,
    params:{
        Confirm:false,
        referenceId:referenceId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const putSalesPreInvoiceWorkflow = (data,referenceId  ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.workflow,
    method: 'put',
    params:{referenceId },
    data:data
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const putSalesPreInvoice = (data  ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.load,
    method: 'put',
    data:data
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getPreInvoiceConstraint = ( ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.constraint,
    method: 'get',

    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getDefaultPersonContact = (Id ) => http({

    url: ENDPOINTS.BaseInformation.PersonContact.default,
    method: 'get',
    params:{
        personId:Id
    }
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getPersonCustomer = (Id ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.load,
    method: 'get',
    params:{
        id:Id
    }
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


export const optionalDiscountHandler = (data  ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.optionalDiscount,
    method: 'post',
    data:data

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const getPreInvoiceById = (Id ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.getId,
    headers: { "Accept-Language": "en" },

    method: 'get',
    params: {
        Id:Id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const CalculateSalesPreInvoice = (data ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.Calculat,
    headers: { "Accept-Language": "en" },
    method: 'post',
    data:data,
    params:{Confirm:false}
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

