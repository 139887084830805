

import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";

import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { deleteUnit, getUnit, getUnits } from "./api";
import { UnitForm } from "./UnitForm";
import { Toast } from "components/ToastShow/ToastShow";


interface Form {
    Title: string,
    Status: number
}
export const UnitSetting = ({ }: Form) => {

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [showEventForm , setShowEventForm] = useState(false)
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pages, setPages] = useState<number>(0);
    const [filterlist, setFilterlist] = useState<any>("");

    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("Unit"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id)   selectedObj.SystemReserved ? Toast(trans("Msg_CanNotEditSystemReservedData") , "error")     :handleEditRow(); break;
            case "help": alert("call api help"); break;
            case "copy" : {if (selectedObj.Id){handleCopyItem()}else {Toast(trans("Msg_SelectRow"), "warning")}}break;
            case "remove": if (selectedObj?.Id) Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;

        }
    };

    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("Unit" ,e.currentTarget.value )
        getUnitAsync(1)
      };

    const handleGoPage = (value) => {
      if (value) {
        if (value < 1 || value > pages) { }
        else setPageNum(+value);getUnitAsync(+value);
              } else {

      }
    };
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getUnitAsync(pageNum + 1);
          };
        };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getUnitAsync(pageNum - 1);
          };
          };
    const handleCreateAction = () => {
        // setSelectedObj(null)
        setMode("create")
        setShowEventForm(true)

    }
    const handleEditRow = () => {
        setMode("edit");
        setShowEventForm(true)
       
    }
    const handleDeleteRow =async () => {
        try {
            await deleteUnit(selectedObj.Id)
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }

    }
    const handleViewRow = () => {
        setMode("view")
        setShowEventForm(true)
      
    }

    const selectedObjCallback = (obj, index) => {
        setSelectedObj(obj);
    }
    const SearchHandler = (str) => {
        setPageNum(1);
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }

    async function getUnitAsync(PageNum = pageNum) {
        setLoading(false)
        setResponse(null)
        let pageSizeChangedByUser = getPageSize("Unit" )
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getUnits(PageNum,  pageSizeChangedByUser,search ,orderBy.length == 0 ?null:  orderBy.join(",") ,filterlist);
            setResponse(res.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }


    const handleCopyItem=()=>{
        async function getUnitAsync() {
            try {
              if (selectedObj.Id) {
                const res = await getUnit(selectedObj.Id);
                navigator.clipboard.writeText( JSON.stringify(res.Data) )
              } 
            } catch (err: any) {}
          }

          getUnitAsync()
    }

    useEffect(() => {
        getUnitAsync();
    }, [forceUpdate,search,orderBy,filterlist]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
            getUnitAsync();
        }
    }, [resetForm]);


    return (
        <>   
         {showEventForm ?<ModalForm modalHeight={49} mode={mode} onHide={()=>{setShowEventForm(false)}} show={showEventForm} title={"UnitForm" } ><UnitForm handleCopyItem={handleCopyItem} mode={mode}  selectedObj={selectedObj} relod={()=>setForceUpdate(!forceUpdate)}   showForm={()=>{setShowEventForm(false)}} /> </ModalForm>  : null}
            <div className="cartable-action">
                <Toolbar copy={true} warehouse={false} remove={true} handleSearch={SearchHandler} id={selectedObj?.Id} emptyList={response?.TotalRecords == 0} create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                    <div className="">
                        {loading ?
                            <>
                                {
                                    response?.Data?.length > 0 ?
                                        <>
                                            <DataGrid
                                                parentName={"Unit"}
                                                handlefiltering={(str)=>{setFilterlist(str)  }}
                                                filterStr={filterlist}
                                                orderFormat={setOrderBy}
                                                orderFormatList={orderBy}
                                                startIndex={1}
                                                theadData={Object.keys(response.Data[0])}
                                                tbodyData={response.Data}
                                                minCellWidth={80}
                                                selectedRowObjectCallback={selectedObjCallback}
                                                handleEdit={handleEditRow}
                                                handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                                handleView={handleViewRow}
                                                totalRecords={response.TotalRecords}
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                HandleNextPage={handleNextPage}
                                                HandlePrevPage={handlePrevPage}
                                                handlechangePageSize={handleChangePageSize}
                                                first={() =>{getUnitAsync(pages); setPageNum(pages)}}
                                                end={() =>{getUnitAsync(1); setPageNum(1)}}
                                                handleGoPage={handleGoPage}
                                            />
                                        </>
                                        : <Message message={"NoDataForPreview"} type={messageType} />
                                }
                            </>
                            : <Loading />
                        }
                    </div>
                </div>
            </div>
        </>

    );
}