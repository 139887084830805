import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getBpmnTaskApi = (async (search, id ,processId) => await http({

    url: ENDPOINTS.bpmnApi.Task.dictionary,
    method: 'get',
    params: {
        processId: processId,
        SearchString: search,
        Id: id
    }
})
    .then((respons) => (respons.data.Data))
)
