import { OrganizationType } from "types/enum";
import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";




export const getCustomerDictionaryApi = (async (id) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.dictionary,
    method: 'get',
    params: {
        customerId: id,
    }
}).then((res) => {

    return res.data.Data
}).catch((err) => {

    return Promise.reject( err);
})
)
export const getCustomerDictionaryListApi = (async (search,id) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.dictionary,
    method: 'get',
    params: {
        searchString:search,
        customerId:id
    }
}).then((res) => {

    return res.data.Data
}).catch((err) => {

    return Promise.reject( err);
})
)