import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";

export const getProcessStarting = (    positionId: any  ) => http({
    url: ENDPOINTS.bpmnApi.process.getStarting,
    method: 'get',
/*      params: {
         positionId: positionId 
     } */
}).then((response) => (response.data))
    .catch(err => {
        return Promise.reject(err)
    })

