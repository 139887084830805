
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { TableTree } from "components/TableTree/TableTree";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { trans } from "utils/helpers";
import {  deleteNode, getFully } from './api';

import { GeographicalClassificationForm } from "./components/GeographicalClassificationForm";
import { INVENTORYANDSALES_URL } from "utils/constants";
import { Button } from "components/Button/Button";
interface GeographicalClassificationPropsType{
  onHide:()=>void,
  itemCodeHandeler?: (e) => void,

}
export const GeographicalClassification = ({onHide,itemCodeHandeler}:GeographicalClassificationPropsType) => {
  const [showGeographicalClassificationForm, setShowGeographicalClassificationForm] = useState(false)
  const [showPositionForm, setShowPositionForm] = useState(false)
  const [showRoleForm, setShowRoleForm] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [defultShow, setDefultShow] = useState(true)
  const [Fullydata, setFullydata] = useState<any>([])
  const [selectedObj, setSelectedObj] = useState<any>(null)
  const [idCallback, setIdCallback] = useState<any>("2")
  const [mode, setmode] = useState<any>()
  const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});

  let theadData: any = []
  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "tree":setSelectedObj(null);getFullyAsync();setDefultShow(!defultShow);break;
      case "view": if (selectedObj){ setmode("view"); setShowGeographicalClassificationForm(!showGeographicalClassificationForm);} else { Toast(trans("Msg_SelectRow"), "warning") }; break;
      case "edit": if (selectedObj){ setmode("edit"); setShowGeographicalClassificationForm(!showGeographicalClassificationForm);} else { Toast(trans("Msg_SelectRow"), "warning") }; break;
      case "print":alert("print ");break;
      case "remove":if (selectedObj) { Toast(trans("msg_deleteconfirm"), "warning", "alert", deletListAsync) }else { Toast(trans("Msg_SelectRow"), "warning") }; break;
      // case "excel":alert("excel ");break;
      case "refresh":setForceUpdate(!forceUpdate);break;
      case "create": setmode("create");setShowGeographicalClassificationForm(!showGeographicalClassificationForm) ; break;
    }
  };
  const handleViewModalForm = () => {
    setIdCallback(selectedObj?.Id)
    setShowGeographicalClassificationForm(false)
    setShowPositionForm(false)
    setIdCallback(" ")
    setForceUpdate(!forceUpdate)
  }
  async function deletListAsync() {
    try {
       await deleteNode(selectedObj.Id)
      setForceUpdate(!forceUpdate)
    } catch (err: any) {

    }

  }

const sendTreeGeographical = (e) => {
  e.preventDefault()
  e.stopPropagation()
    if(selectedObj){
      itemCodeHandeler && itemCodeHandeler(selectedObj)
    }else{
      Toast(trans("Msg_SelectRow"), "warning")
    }


}
  async function moveNudeAsync(drag, drop) {
    try {
     // const response = await moveNude(drag, drop)
     // setReload(!reload)
     // return true

    } catch (err: any) {

    }

  }

  async function getFullyAsync() {
    setLoading(false)
    try {
      
      const respons = await getFully()
      setFullydata(respons.Data)
      setCatchMessage({type:"info",msg:"NoDataForPreview"})
    } catch (error:any) {
        setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
    }

    setLoading(true)
  }



  useEffect(() => {
    setShowGeographicalClassificationForm(false)
    setSelectedObj(null)
    getFullyAsync()
  }, [forceUpdate]);



  return (
    <>
      {showGeographicalClassificationForm ? <ModalForm modalHeight={50} mode={mode} onHide={handleViewModalForm} show={showGeographicalClassificationForm} modalProfileKey={"Position"} title={mode!="create"?trans(mode)+" "+ selectedObj?.Description:  trans("geographicalclassification") + " " + trans(mode)} ><GeographicalClassificationForm mode={mode} onHide={handleViewModalForm} selectedObj={selectedObj} showForm={handleViewModalForm} /> </ModalForm> : null}
      <Toolbar excel={false}   emptyList={Fullydata?.length == 0} id={selectedObj}paste={false} create={true} remove={true} edit={true}  handleClickToolbar={clickToolbarCallback} />
      <div style={{overflowY: "auto", 
          height: "74vh",
           scrollbarWidth: "thin"}} className="col-lg-12 mt-0">
        <div className="list-card card  mt-3">
            <>
              {
                loading ? (
                  (Fullydata&&Fullydata.length>0) ?
                    <TableTree
                      dragDrop={false}
                      selectedRowObjectCallback={setSelectedObj}
                      moveNude={moveNudeAsync}
                      selectedRowObject={idCallback}
                      baseUrl={INVENTORYANDSALES_URL}
                      getChildrenUrl={ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.GeographicalClassification.getNode}
                      Data={Fullydata}
                      theadData={Object.keys(Fullydata[0])}
                      parentName={"GeographicalClassification"}
                      startIndex={9}
                      defultShow={true}
                    />
                    : <Message message={catchMessage.msg} type={catchMessage.type} />
                )
                  : <Loading />
              }
              {!window.location.href.includes("geographical")&&
              <div className="col-lg-12 mt-1 btns_form">
              <Button value="select" btnType="submit" onClick={(e)=>{sendTreeGeographical(e)}}/>
              <Button btnType="cancel" value="Cancel"  />
            </div>

              }
            </>
        </div>
      </div>
    </>
  )
}