import { Button } from 'components/Button/Button';
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getCustomerPhoneListApi, postCustomerPhoneListApi } from "./api";

export const CustomerPhoneList = ({rowObject, handlePhone,personId, Current=false , referenceId, onHide, handleShowReferenceStepper=()=>{},Reload , styleCondition ,showForm=false,type }: any) => {


    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedId, setSelectedId] = useState<string | any>(null);
    const [showCustomerPhoneForm, setShowCustomerPhoneForm] = useState(false);
    const [mode, setmode] = useState("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [typeContect,setTypeContect] = useState<'mobile'|'tel'>('tel')
    const [selectedObj,setSelectedObj]=useState<any>()



    const sendTreeCode=(e)=>{
        handlePhone(selectedObj)
        onHide()
        
    }

    const handleCreateCustomerPhone = () => {
        setmode("create")
        setShowCustomerPhoneForm(true);
    }

        
    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true" && showCustomerPhoneForm){
            Toast(trans("modalclosemessage"),"warning","alert", ()=>{setSelectedId(obj.Id) ;sessionStorage.setItem("changingforms" , "false");});
        }else{
            setSelectedId(obj.Id);
            setSelectedObj(obj)
        };
    }

    const handleSubmit = async(event)=>{
        event.preventDefault();
        try{
        setLoading(false)
        let data = {
            PersonId:personId,
            ContactType:type,
            Value:event.target.Value.value,
            Description:event.target.Description.value,
            ExtraDescription:event.target.ExtraDescription.value,
            IsDefault:event.target.IsDefault.checked,
        }
        await postCustomerPhoneListApi(data ,Current)
        setLoading(true)
        onHide()
        } catch (error) {
                    
        }      
    }



    async function getReferenceCustomerPhonesAsync() {
        setLoading(false)

        try {
            const res = await getCustomerPhoneListApi(personId ,Current,type);    
            let data:any = []
            Object.keys(res).map(resKey=>{
                data = [...data,{'Id':resKey,'value':res[resKey]}]
            })
            setResponse(data);
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
  
        setLoading(true)
    }
    useEffect(() => {
   getReferenceCustomerPhonesAsync()

    }, [ forceUpdate  ]);
    useEffect(() => {
       if(showForm){
        handleCreateCustomerPhone()
       }
    }, []);


 

    return (
        <>
            <div className="cartable-CustomerPhone">
            <div className="card mb-4">
                    <form className="TypeSettingForm row card-body " onSubmit={handleSubmit}>
                    {/* <div className="col-6 d-flex">
                        <CheckableButton labelText='phone' value={ContactType.Phone} type="radio" inputName="contact" defaultValue={true} defaultChecked={true} checked={true} onclick={()=>{setTypeContect('tel')}}/>
                        <CheckableButton labelText='mobile' value={ContactType.Mobile} type="radio" inputName="contact"  onclick={()=>{setTypeContect('mobile')}}/>
                    </div>                      */}
                    <div className="col-6">
                        <Textbox required={true} textboxName='Value'  textboxType={'text'}  labelText={"value"} mode={mode} minLength={11} maxLength={11}   />
                    </div> 
                    <div className="col-6">
                        <Textbox  textboxName='Description' textboxType="textarea"  labelText={"Description"} mode={mode}   />
                    </div>  
                    <div className="col-6">
                        <Textbox textboxName='ExtraDescription' textboxType="textarea"  labelText={"ExtraDescription"} mode={mode}   />
                    </div>  
                    <div className="col-md-4 col-sm-12">
                        <CheckableButton labelText='IsDefault' type="checkbox" inputName="IsDefault" />
                    </div>  
                    <div className="col-lg-12 mt-1 btns_form">
                        <Button value="submit" btnType="submit" />
                    </div>
                </form>

            </div >            
              {/*   {showCustomerPhoneForm &&
                    <CustomerPhoneForm
                        onHide={() => setShowCustomerPhoneForm(false)}
                        mode={mode}
                        reset={(e)=>{getReferenceCustomerPhonesAsync()}}
                        id={selectedId}
                        referenceId={referenceId}
                    />
                } */}
                {loading ?
                    (response?.length > 0 ?
                        <div className="card ">
                            <div className="p-0">
                                <>
                                    <DataGrid
                                        parentName={"CustomerPhones"}
                                        theadData={Object.keys(response[0]) }
                                        tbodyData={response}
                                        minCellWidth={80}
                                        selectedRowObjectCallback={selectedIdCallback}
                                        scrollHeight={"150px"}
                                    />
                                </>
                            </div>
                        </div>
                        : <Message message={"NoDataForPreview"} type={messageType} />
                    )
                    : <Loading />
                }
 
            <div className="col-lg-12 mt-1 btns_">
              <Button value="select" btnType="submit" onClick={(e)=>{sendTreeCode(e)}}/>
              <Button btnType="cancel" value="Cancel"  onClick={()=>onHide()}/>
            </div>
        
            </div>
        </>

    );
}