import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext"
import { useContext } from "react"
import { Link } from "react-router-dom"
import { trans } from "utils/helpers"
import './style.scss'

interface props {
    title: string
    icon: any
    id: string
    color?: 'primary' | 'danger' | 'success' | 'info' | 'secondary' | 'white' | 'customerPrimaryColor'
    children?: React.ReactNode
    removable?: boolean
    url?: string
    fullScreen?: boolean
}

export const Widget = ({title, icon, children, removable=true, id, color='white', url, fullScreen=false}: props) => {
    let savedWidgets = JSON.parse(localStorage.getItem('widgetList') as string) 
    const layoutContext = useContext(LayoutContext);


    const handleRemoveWidget = () => {
        if(savedWidgets !== null){
            savedWidgets[id] = false
            localStorage.setItem('widgetList', JSON.stringify(savedWidgets))
            layoutContext.setWidgetFormFlag(!layoutContext.widgetFormFlag)
        }
    }

    return (
        <div style={{width : title == "events" ?"32em":title == "Board" ?"": "16.5em"}} className={`widget  w-100 card widgetContainer m-2  border-${color === 'white' ? 'secondary' : color} ${fullScreen ?( layoutContext.sidebarIsOpen ? "fullscreenWithSid" : "fullscreen")  : ''}`}>
            <div className="d-flex flex-column align-items-center  h-100">
                <div className={`widget__header rounded-top d-flex align-items-center justify-content-between py-2 px-1 w-100 border-bottom border-${color === 'white' ? 'secondary' : color} bg-${color}`}>
                    <div className="d-flex align-items-center"> 
                      <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={icon} className="mx-2 " color={`${color === 'white' ? 'grey' : 'white'}`}/>
                      </div>
                        <span className={`${color === 'white' ? 'text-secondary' : 'white'}`}>{trans(title)}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        {url && 
                            <Link to={url ? url : '/'} className='d-flex align-items-center'>
                                <FontAwesomeIcon icon={['fas', 'eye']} className='mx-1' style={{cursor: "pointer"}} />
                            </Link>
                        }
                        {removable && savedWidgets !== null && <FontAwesomeIcon icon={['fas', 'xmark']} className='mx-1' onClick={handleRemoveWidget} style={{cursor: "pointer"}} />}
                    </div>
                </div>
                <div className="h-100 w-100">
                    {children}
                </div>
            </div>
        </div>
       
        
    )
}