import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Textbox } from "components/Textbox/Textbox";
import { useState } from "react";
import { trans } from "utils/helpers";


interface props {
    mode?: any
    onHide?: () => void
    submit?: (e,type,sendMsg) => void
    rowObject?: any
}

export const TerminatedForm = ({rowObject ,submit, mode = "create", onHide }: props) => {

    const [type, setType] = useState<any>(rowObject?.ExecutiveTermination ? "CancelTerminate" :"Terminated");
 
    const handleSubmit = (event) => {
        event.preventDefault()
        submit && submit(type , event?.target?.RejectDescription?.value, event?.target?.sendMessageToRequester?.checked)

    }

    return (
        <div className="card p-2">
                <form onSubmit={handleSubmit}>
            <div className={`flex-column-reverse justify-content-between align-items-center d-flex mt-2 mb-2`}>

            <CheckableButton   onclick={()=>{setType("reject")}}   defaultValue={"users"} labelText={trans("reject")} inputName={"TerminatedType"} type={"radio"}/>
            {rowObject?.ExecutiveTermination?
                <CheckableButton  onclick={()=>{setType("CancelTerminate")}} defaultChecked={true } labelText={trans("CancelTerminate")} inputName={"TerminatedType"} type={"radio"}/>
            :
                <CheckableButton  onclick={()=>{setType("Terminated")}} defaultChecked={true }  labelText={trans("Terminate")} inputName={"TerminatedType"} type={"radio"}/>
            }
            </div>
            {(type=="reject"||type=="Terminated") && 
            <div className="row"> 
            <div className="col-12 col-sm-6">
                 <Textbox
                    required={type=="reject"}
                    textboxName="RejectDescription"
                    labelText="Description"
                    textboxType="textarea"
                    minHeight={mode == "view" ? 2 : 3}
                    mode={mode}
                 />
            </div>
            </div>
            }

            {(!rowObject?.ExecutiveTermination||type=="reject") &&
               <div className="col-4 my-1 my-lg-0 col-lg-4 pt-2">
                <CheckableButton
                       defaultValue={false}
                       labelText={"sendMessageToRequester"}
                       inputName={"sendMessageToRequester"}
                       type="checkbox"
                       mode={"edit"}
                    />    
            </div>
            }


            <div className="row">
                    <div className="d-flex justify-content-end">                   
                        <>
                        <Button btnType="submit" value={"confirm"} />
                        <Button
                            btnType="cancel"  
                            value="cancel"  
                            onClick={() => { onHide && onHide() }}
                        />
                        </>
                    </div>
                 </div>
               </form>
             </div>
    )
}