import { Button } from "components/Button/Button";
import { CardBoxAttachment } from "components/CardBoxAttachment/CardBoxAttachment";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toast } from "components/ToastShow/ToastShow";
import { FileInput } from "components/FileInput/FileInput";
import { Fileviewer } from "components/Fileviewer/Fileviewer";
import { Footerbar } from "components/Footerbar/Footerbar";
import { Headerbar } from "components/Headerbar/Headerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { trans } from "utils/helpers";
import { getAttachments , loadAttachment, PostAttachments, PutAttachments , DeleteAttachments, getDownloadUrlAttachmentAsync, putAsDefault } from "./api/api";
import "./style.scss";
import { SwitchBtn } from "components/SwitchBtn/SwitchBtn";
import { STORAGE_URL } from "utils/constants";
import {VersionList} from './VersionList/VersionList'

interface props {
    onHide: () => void
    ownerId?: string
    baseURL?: any
    mood?: string
    getListEndpoint?: any
    getListOwnerId?: any
    params?: any
    inTab?: string
    endpoint?: string
    showInGrid?: boolean
    haveVersion?: boolean
}
export const Attachment = ({ haveVersion=false, showInGrid=false , ownerId,baseURL=null,inTab, mood, onHide,getListEndpoint,params, getListOwnerId , endpoint}: props) => {


    let headerData, footerData;

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedId, setSelectedId] = useState<string | any>(null);
    const [actionFormEditId, setActionFormEditId] = useState<any>();
    const [forceUpdate, setForceUpdate] = useState(false);
    const [showModalCarousel, setshowModalCarousel] = useState(false);
    const [listid, setId] = useState<any[]>([]);
    const [showVersions, setShowVersions] = useState(false);
    const [load, setLoad] = useState<any>();
    const [loading, setLoading] = useState<any>(true);
    const [index, setIndex] = useState<number | any>();
    const [showUpload, setShowUpload] = useState(false);
    const [flag, setFlag] = useState(false);
    const [exist, setExist] = useState(false);
    const [file, setFile] = useState<any>();
    const [Name, setName] = useState<any>();
    const [showMode, setShowMode] = useState<any>(true);
    const [selectedRow, setSelectedRow] = useState<any>();
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [message, setMessage] = useState<any>( "NoDataForPreview");
    const [attachFile,setAttachFile]=useState<any>()




    /* #region pagination */

    const selectedIdCallback = (obj, index) => {
        setSelectedId(obj.Id);
    }


    const handleViewRow = () => {
        setActionFormEditId(selectedId);
    }
  
  const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));

    const handleDeleteRow = async(atachId , isList = false ) => {
        setLoading(false)
        try {
            if(isList){
                try {
                await Promise.all(atachId.forEach(async(item) =>{
                        try {
                            const res = await DeleteAttachments( item.item.Id ,endpoint,baseURL );                      
                        } catch (error) {
                        }
                    })
                    )
                } catch (error) {
                }
            }else{
                const res = await DeleteAttachments( atachId ,endpoint ,baseURL); 
            }
        } catch (error) {
            
        }
        try{
            await delay(200)
            setId([])
            getAttachmentsAsync()
            setFile(null)

        }catch(err){
        }
        setLoading(true)
    }

    function handleDownload() {
        if(showMode){
            if (listid.length > 0) {
                let list:any = []
                listid.forEach(item => {
                    list.push({id:item.event.target.id, name :item.event.target.title })
                })
            downloadTrigger(list)
                setFlag(!flag)
                setId([])
            }else{
                Toast(trans("nofileselected"), "warning")
            }
        }else{
            if(selectedRow){
                downloadTrigger([{id:selectedRow.Id,title:selectedRow.FileName}])
                setFlag(!flag)
                setId([])
            }
        }
    }
  function version (e) {
        setShowVersions(true)
        setAttachFile(e)

        
    }
    function checkbox(e , item) {
        if (e.target.checked === true) {
            setId((old) => [...old, {item:item , event:e}])
        } else if (listid.length !== 0 && e.target.checked === false) {
            let deleteId = listid.filter(file => {
                return item.Id !== file.item.Id
            })
            setId(deleteId)
        }
    }

    function createDownloadlink(href, title) {
        const a = document.createElement('a');
        
        a.href = STORAGE_URL+href;
        a.download = title;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return null
    }
    

    const asyncOperation = (message, delay) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(message);
          }, delay);
        });
      };
    
    async function downloadTrigger(e) {

        let ListId = e.map(item=>{
            return item.id
            })
            if(getListOwnerId && getListEndpoint){
                let tagList:any = []
                let res = await getDownloadUrlAttachmentAsync(ListId,endpoint ,baseURL);
                
                for (let i = 0; i < res?.Data.length; i++) {
                    const result = await asyncOperation("message", 400);
                    createDownloadlink(`${res?.Data[i]}`, e[i].name)
                }
       
            }else{

                let res = await getDownloadUrlAttachmentAsync(ListId,endpoint ,baseURL);
                
                for (let i = 0; i < res?.Data.length; i++) {
                    const result = await asyncOperation("message", 400);
                    createDownloadlink(`${res?.Data[i]}`, e[i].name)
                }

            }
            try {
        } catch (error) {
  
        }
       
    }


    function changehandlefile(e) {
        setExist(false)
        let flag= false

        if(!flag){
            var file = e.target.files[0]
            var blob = file.slice(0, file.size); 
            let newFile = new File([blob],  `${new Date().getTime()}.${e.target.files[0].name.split('.').pop().toLowerCase()}`, {
                type: file.type,
                lastModified: file.lastModified,
            } );
            setFile(newFile)
            setName( e.target.files[0].name)
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(newFile);
            let input:any =  document.getElementById("fileinputIdLegal")
            if(input != undefined){
                input!.files   = dataTransfer.files;   
            }
            
        }
    }
    function uploadSubmit(e) {
        e.preventDefault();
        if (file) {
            if(actionFormEditId){
                PutAttachment();
            }else{
                postAttachment();
            }
        }
    }

    async function postAttachment() {
        try {
            var _objData = new FormData();
            _objData.append("file", file);
            _objData.append("OwnerId", ownerId? ownerId : "" );
            _objData.append("FileName", Name);
            if(response==null || response.length==0){
                _objData.append("IsDefault", "true");
            }
            const res = await PostAttachments(_objData ,endpoint  ,baseURL);
            setShowUpload(false);
            getAttachmentsAsync()
            setFile(null)
        } catch (error) {
            
        }
    }
    async function PutAttachment() {
        try {
            var _objData = new FormData();
            _objData.append("file", file);
            const res = await PutAttachments(_objData, ownerId ,endpoint ,actionFormEditId,baseURL);
            setShowUpload(false);
            getAttachmentsAsync()
            setFile(null)
        } catch (error) {
        }
    }

    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "refresh": setForceUpdate(!forceUpdate);getAttachmentsAsync(); break;
            case "create":setActionFormEditId(null); setShowUpload(true); break;
            case "view": if (selectedId) handleViewRow(); break;
            case "remove": if (listid.length > 0||selectedRow!=null){Toast(trans("msg_deleteconfirm"), "warning" , "alert" , ()=>{handleDeleteRow(showMode?listid:selectedRow.Id , showMode)})}else{ Toast(trans("nofileselected"), "warning")}; break;
            case "help": alert("call api help"); break;
            case "download": handleDownload(); break;
        }
    };
    /* #endregion */


    

    async function getAttachmentsAsync() {
        setLoading(false)
        try {
            
            if(getListOwnerId && getListEndpoint){
                const res = await getAttachments(getListOwnerId , getListEndpoint ,baseURL ,params ,showMode)
                setResponse(res.Data ) 
            }else{
                const res = await getAttachments(ownerId , endpoint ,baseURL ,params ,showMode)
                setResponse(res.Data ) 
            }
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setResponse(null)
            setMessage(error?.response?.data?.Message)
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)
    }
    
    function handleSlider(e) {
        let b = document.querySelectorAll(".CardBoxAttachment__img");
        for (let i = 0; i < b.length; i++) {
            if (e.target.id === b[i].id) {
                setIndex(i);
            }
        }
        loadAttachmentAsync(e.target.id);
        setshowModalCarousel(true);
    }


    function selectCarousel(I) {
        setIndex(I)
        let b = document.querySelectorAll(".CardBoxAttachment__img");
        for (let i = 0; i < b.length; i++) {
            loadAttachmentAsync(b[I].id);

        }
    }

  const  handleSetAsDefault=async(id)=>{


    try{
        await putAsDefault(endpoint ,baseURL,ownerId,id)
    }catch(error){

    }

    }
    async function loadAttachmentAsync(e) {
        try {
            if(getListOwnerId && getListEndpoint){
                const res:any = await loadAttachment(e ,getListEndpoint ,baseURL,params );
         
                setLoad(res);
                return res
            }else{

                const res:any = await loadAttachment(e ,endpoint ,baseURL ,params);
                setLoad(res);
                return res
            }
        } catch (error) {
            setLoad(" ")
            return " "
        }
    }
    useEffect(() => {
        if(mood !== "create"){
            if(inTab && inTab==="Attachment"){
                getAttachmentsAsync();
            }else if(inTab == null ||inTab === undefined ){
                getAttachmentsAsync();
            }
        }
    }, [ownerId ,inTab ,showMode])




    return (
        <>  
        
        {((inTab && inTab==="Attachment")||(inTab == null ||inTab === undefined))&& <>
    
            <div className={`cartable-action p-2 `}>
                <div className="row d-flex " >
                    {showInGrid&&
                <div className="col-2" >
                <SwitchBtn handleModeCard={setShowMode}setShape="circle"   firstMode="کارتی" secondMode="لیستی" cardStyle={showMode}   />
                </div>
                }
                <div className={showInGrid ? "col-10" : "col-12"}>
                    <Toolbar emptyList={response?.length==0} download={true} warehouse={false}  excel={false}  remove={true} view={false} id={selectedId} create={mood !== "view" } edit={false} search={false}  handleClickToolbar={clickToolbarCallback} attach={false}  />
                </div>
                </div>
                {headerData && <Headerbar headerContents={headerData} hasBorder={true} />}
                <div className="card p-1">
                    <div className="">
                        {loading ?
                        (response && response?.length > 0 ?
                            <>
                            <div className={`modalCarousel ${showModalCarousel ? "d-flex" : "d-none"} `}  style={{zIndex: "14"}}>
                                    <div className="modalCarousel__close" onClick={() => { setshowModalCarousel(false); setLoad("") }} style={{top:"40px",right:"60px"}}>&#x2715;</div>
                                    <div className="container d-flex justify-content-center align-items-center">
                                        <Carousel indicators={false} interval={null} className={"modalCarousel__slide "} touch={true} onSelect={selectCarousel} defaultActiveIndex={index} activeIndex={index}>
                                            {response.map((item,mapIndex) => (
                                                <Carousel.Item className="attachCarousel" style={{minWidth:'1200px'}}>
                                                    {load ? <Fileviewer File={item.FileName } src={load} /> :
                                                        <Loading />
                                                    }
                                                </Carousel.Item>

                                            ))}
                                        </Carousel>
                                    </div>
                            </div>
                            
                                {showMode ?
                                <div className="row">
                                    <div className="Attachment d-flex flex-wrap">

                                        {response.map((item,mapIndex) => (

                                            <CardBoxAttachment haveVersion={haveVersion}  handleEdit={()=>{setActionFormEditId(item.Id);setShowUpload(true)}} key={mapIndex} img={`data:image/jpg;base64,${item.Thumbnail}`} reset={flag} text={item.FileName} handleClickSlider={handleSlider} id={item.Id}
                                            handleDeleteRow={handleDeleteRow}  handleVersionTrigger={version}  handleCheckbox={checkbox} fileName={item.FileName} handleDownloadTrigger={downloadTrigger}
                                           asDefault={true} handleSetAsDefault={(e)=>handleSetAsDefault(e)}
                                            />
                                         
                                        ))}
                                    </div>

                                </div>
                                    :

                                    <>
                                    <DataGrid
                                        parentName={"attachmentList"}
                                        startIndex={2}
                                        theadData={Object.keys(response[0])}
                                        tbodyData={response}
                                        minCellWidth={80}
                                        scrollHeight={"25vh"}
                                        selectedRowObjectCallback={(e)=>{setSelectedRow(e)}}
                                        rowSelected={selectedRow}
                                    />
                                </>


                            }
                                {footerData && <Footerbar footerContents={footerData} hasBorder={false} />}

                            </>
                            :<> <Message message={catchMessage.msg} type={catchMessage.type} />  </> 
                        )                    

                            : <Loading />}

                            <div className="col-lg-12 mt-1 btns_form">
                            <Button btnType="primary" value="Close" onClick={()=>{onHide && onHide()}}/>
                            </div>
                    </div>
                </div>
            </div>
            {
                showVersions &&
            <ModalForm title="VersionManagement" onHide={() => setShowVersions(false)} show={showVersions}>
                <VersionList reload={()=>{ setShowVersions(false);getAttachmentsAsync();}} getAllData={false} endpoint={endpoint} baseURL={baseURL} attachFile={attachFile} />
            </ModalForm>


            }

           { showUpload && <ModalForm title="upload" onHide={() => {setShowUpload(false);setFile(null)}} show={showUpload}>
                
                    <form onSubmit={uploadSubmit}>
                    {exist && <div className="fullscreen-bg-modal" />}

                        <FileInput maxSize={25600000}  lable={"file"} name={"file"} src={file  ? (URL.createObjectURL(file )) :""} onChange={changehandlefile} />
                        <div className="d-flex justify-content-end">
                            <Button disabled={exist} btnType="submit" value={"submit"} />
                            <Button btnType="cancel" value={"cancel"} onClick={() => { Toast(trans("modalclosemessage"), "warning", "alert", () => {setShowUpload(false);setFile(null)}) }} />
                        </div>

                    </form>
                
            </ModalForm>
            }
                    
        </>}
        </>

    );
}




