
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, trans } from "utils/helpers";


import { deleteIndicatorPattern, getIndicatorPattern, getListIndicatorPattern, postIndicatorPattern, putIndicatorPattern } from "./api";
import { Message } from "components/Message/Message";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { Button } from "components/Button/Button";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";


interface Form {
    Title: string,
    Status: number
}
export const IndicatorPatterns = () => {

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [flag, setFlag] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<any>();
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [search, setSearch] = useState(null);
    const [orderBy, setOrderBy] = useState<any>([]);
    const [userChanges, setUserChanges] = useState({});


    const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }


    const handleCreateAction = () => {
        setEditForm(null);
        setMode("create")
        setShowForm(true)

    }
    const handleEditRow = () => {
        setMode("edit");
        setShowForm(true)

    
    }
    const handleDeleteRow = async() => {

           try {
            const res = await deleteIndicatorPattern(selectedObj.Id)
            setForceUpdate(!forceUpdate)

        } catch (error) {
            
        }
    }
    const handleViewRow = () => {
        setMode("view")
        setShowForm(true)

    }

    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedObj(obj);sessionStorage.setItem("changingforms" , "false"); setUserChanges({}) });
        }else{
            setSelectedObj(obj)         
        };
    }

    const submit = (e) => {
        e.preventDefault();
        if (editForm == null) {
            postIndicatorPattern({ IndicatorStatic: e.target.IndicatorStatic.value ,Pattern:  null ,ActivityStatus:1}).then(() => { sessionStorage.setItem("changingforms" , "false");setUserChanges({});getListIndicatorPatternAsync(); setResetForm(true)  });
        } else {
            putIndicatorPattern({ Pattern: null,IndicatorStatic: e.target.IndicatorStatic.value , ActivityStatus: +e.target.ActivityStatus.value , Id: selectedObj.Id }).then(() => { sessionStorage.setItem("changingforms" , "false");setUserChanges({});getListIndicatorPatternAsync(); setResetForm(true); setEditForm(null)  })
        }
        setShowForm(false)

    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                setEditForm(null);
                setShowForm(false)
            });
        }else{
            setEditForm(null);
            setShowForm(false)     
        };
    }
    async function getListIndicatorPatternAsync() {
        setLoading(false)
        try {
            const res = await getListIndicatorPattern(search , orderBy.length == 0 ? null : orderBy.join(","));
            setResponse(res.Data);
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})
        }
        setLoading(true)
    }
    async function getIndicatorPatternt() {
        setFormLoading(false)
        try {
            const data = await getIndicatorPattern(selectedObj?.Id);
            setEditForm({ Pattern: "" , Status: data.Data.ActivityStatus , IndicatorStatic:data.Data.IndicatorStatic  });
        } catch (error) {
            
        }
        setFormLoading(true)
    }
    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        getListIndicatorPatternAsync();
    }, [forceUpdate, flag ,search ,orderBy]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);
 

    if (flag) {
        getListIndicatorPatternAsync()
        setFlag(false)

    }

    useEffect(() => {
        if(showForm&&selectedObj && mode != "create"){
            getIndicatorPatternt()
        }else{
            setFormLoading(true)
        }
    }, [selectedObj ,showForm,mode])

    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,2 ,trans("IndicatorPatternType"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id) handleEditRow(); break;
            case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "help": alert("call api help"); break;
        }
    };
    /* #endregion */

    return (
        <>
            <Toolbar handleSearch={searchHandler} emptyList={response == null || response.length == 0} remove={true} id={selectedObj?.Id} create={true} edit={true} search={true} handleClickToolbar={clickToolbarCallback} />
            <div className="card">
                <div className="">
                    {loading ?
                        <div className="cartable-action">
                            {showForm&& 
                                    <div className="card mb-4">
                                        {formLoading ?
                                         <form className="TypeSettingForm row card-body " onSubmit={submit}>
                                         {/*     <div className="col-md-4 col-sm-12">
                                                 <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}}  textboxName={"Pattern"} textboxType="text" resetForm={resetForm} labelText={"Pattern"} mode={mode}  defaultValue={editForm?.Pattern} />
                                             </div>   */}   
                                             <div className="col-md-4 col-sm-12">
                                                 <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} textboxName={"IndicatorStatic"} textboxType="text" resetForm={resetForm} labelText={"IndicatorStatic"} mode={mode}  defaultValue={editForm?.IndicatorStatic} />
                                             </div> 
                                             {mode != "create" &&          
                                             <div className="col-lg-4 mt-1">
                                                <EnumerationTypeSelect
                                                  Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                                                  name="ActivityStatus"  
                                                  mode={mode} 
                                                  label="Status"
                                                  enumTypeSelect="ActivityStatus"
                                                  value={editForm?.Status}
                                                />
                                             </div>                            
                                              }
                                             {  mode !="view" ?
                                                   <div className="col-lg-12 mt-1 btns_form">
                                                   <Button value="Save" btnType="submit" />
                                                   <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                                                   </div>
                                                   :
                                                   <div className="col-lg-12 mt-1 btns_form">
                                                   <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                                                   </div>
                                               }                        
                                         </form>
                                         : 
                                         <Loading/>
                                        }
                                  </div > 
                        }
                            {
                                loading ?
                                response?.length > 0 ?
                                    <>
                                        <DataGrid
                                            parentName={"IndicatorPatternType"}
                                            orderFormat={setOrderBy}
                                            orderFormatList={orderBy}
                                            startIndex={2}
                                            theadData={Object.keys(response[0])}
                                            tbodyData={response}
                                            minCellWidth={80}
                                            scrollHeight={showForm? "25vh": "67vh"}
                                            selectedRowObjectCallback={selectedIdCallback}
                                            handleEdit={handleEditRow}
                                            handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                            handleView={handleViewRow}
                                        />
                                    </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                                : <Loading/>
                            }
                        </div>
                        : <Loading />
                    }
                </div>
            </div>
        </>
    );
}