import { Textbox } from "components/Textbox/Textbox";
import { Button } from "components/Button/Button";
import { useEffect, useState } from "react";
import { Response } from "types/types";
import { Loading } from "components/Loading/loading";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import {
  postDocsClassificationApi,
  putDocsClassificationApi,
  getDocsClassification,
} from "./api";
import { ListFormat } from "typescript";

export const DocsClassificationForm = ({
  reload,
  mode,
  onHide,
  selectedObj,
}) => {
  const [resetForm, setResetForm] = useState(false);
  const [response, setResponse] = useState<Response | any>();
  const [userChanges, setUserChanges] = useState({});
  const [loading, setLoading] = useState<any>(true);
  const applicationId = JSON.parse(
    localStorage?.getItem("CurrentApplication") as string
  ).Id;

  const submit = (e) => {
    e.preventDefault();
    if (mode === "create") {
      postDocsClassificationApi({
        IsDefault: e.target.IsDefault.checked,
        CalculateTax: e.target.CalculateTax.checked,
        Title: e.target.DocsClassificationTitle.value,
        TitleEN: e.target.DocsClassificationTitleEN.value,
        StartDocsNo: +e.target.StartDocsNo.value,
        ApplicationId: applicationId,
      }).then(() => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        reload();
        onHide();
      });
    } else {
      putDocsClassificationApi({
        Id: selectedObj.Id,
        IsDefault: e.target.IsDefault.checked,
        CalculateTax: e.target.CalculateTax.checked,
        Title: e.target.DocsClassificationTitle.value,
        TitleEN: e.target.DocsClassificationTitleEN.value,
        StartDocsNo: +e.target.StartDocsNo.value,
        ApplicationId: applicationId,
      }).then(() => {
        sessionStorage.setItem("changingforms", "false");
        setResponse(null);
        setUserChanges({});
        reload();
        onHide();
      });
    }
  };

  async function getDocsClassificationId() {
    setLoading(false);
    try {
      const data = await getDocsClassification(selectedObj?.Id);
      setResponse(data.Data);
    } catch (error) {}
    setLoading(true);
  }

  useEffect(() => {
    if (selectedObj && mode !== "create") {
      getDocsClassificationId();
    } else {
      setResponse(null);
      setLoading(true);
    }
  }, [mode]);

  return (
    <div className="card mb-4">
      {loading ? (
        <form
          className="TypeSettingForm row card-body align-items-center "
          onSubmit={submit}
        >
          <div className="col-md-4 col-sm-12">
            <Textbox
              Change={(e) => {
                setUserChanges({ ...userChanges, ...e });
              }}
              required={true}
              textboxName={"DocsClassificationTitle"}
              textboxType="text"
              resetForm={resetForm}
              labelText={"Description"}
              mode={mode}
              defaultValue={response?.Title}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <Textbox
              Change={(e) => {
                setUserChanges({ ...userChanges, ...e });
              }}
              required={true}
              textboxName={"DocsClassificationTitleEN"}
              textboxType="text"
              resetForm={resetForm}
              labelText={"TitleEN"}
              mode={mode}
              defaultValue={response?.TitleEN}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <Textbox
              Change={(e) => {
                setUserChanges({ ...userChanges, ...e });
              }}
              required={true}
              textboxName={"StartDocsNo"}
              textboxType="number"
              resetForm={resetForm}
              labelText={"StartDocsNo"}
              mode={mode}
              defaultValue={response?.StartDocsNo+""}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="row align-items-center mt-1">
              <div className="col-6">
                <CheckableButton
                  Change={(e) => {
                    setUserChanges({ ...userChanges, ...e });
                  }}
                  defaultChecked={response?.IsDefault}
                  defaultValue={response?.IsDefault}
                  labelText={"IsDefault"}
                  inputName={"IsDefault"}
                  type="checkbox"
                  mode={mode}
                />
              </div>

              <div className="col-6">
                <CheckableButton
                  Change={(e) => {
                    setUserChanges({ ...userChanges, ...e });
                  }}
                  defaultChecked={response?.CalculateTax}
                  defaultValue={response?.CalculateTax}
                  labelText={"CalculateTax"}
                  inputName={"CalculateTax"}
                  type="checkbox"
                  mode={mode}
                />
              </div>
            </div>
          </div>

          {mode != "view" ? (
            <div className="col-lg-12 mt-1 btns_form">
              <Button value="Save" btnType="submit" />
              <Button
                btnType="cancel"
                value="Cancel"
                onClick={() => {
                  onHide();
                }}
              />
            </div>
          ) : (
            <div className="col-lg-12 mt-1 btns_form">
              <Button
                btnType="primary"
                value="close"
                onClick={() => {
                  onHide();
                }}
              />
            </div>
          )}
        </form>
      ) : (
        <Loading />
      )}
    </div>
  );
};
