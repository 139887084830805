import { Widget } from "components/Widget/Widget";
import { getDayInfo } from "pages/DashboardPage/api/api";
import { useState, useEffect, useRef } from "react"
import { dateConverter, trans } from "utils/helpers";
import "./style.scss"

const days = ["شنبه", "یکشنبه", "دوشنبه", "سه شنبه", "چهارشنبه", "پنجشنبه", "جمعه"];
const engDays = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
const months = ["فروردین","اردیبهشت","خرداد","تیر","مرداد","شهریور","مهر","آبان","آذر","دی","بهمن","اسفند"]

interface props {
    id: string
}

export const WidgetClock = ({id}:props) => {
    const today = new Date()

    const [hours, setHours] = useState<any>(0);
    const [minutes, setMinutes] = useState<any>(0);
    const [seconds, setSeconds] =  useState<any>(0);
    const secondsRef = useRef(new Date().getSeconds());
    const minutesRef = useRef(0);
    const hoursRef = useRef(0);

    const [events, setevents] = useState<any>()
    
    const persianDate = dateConverter(today.getFullYear(), today.getMonth() + 1, today.getDate())
    const engDayName = engDays[today.getDay()+1];
    const engDayIndex = engDays.findIndex(day => day === engDayName)
    
    const [currentDay, setcurrentDay] =  useState<any>(days.find((day, index) => index === engDayIndex));
    const currentMonth = months.find((month, index) => index+1 === persianDate[1])
    const currentYear = persianDate[0]


    async function getDayInfoAsync() {
        try {
            const res = await getDayInfo()
            secondsRef.current = new Date().getSeconds()
            setHours(res.Data.HourNo)
            setMinutes(res.Data.MinuteNo)
            minutesRef.current = res.Data.MinuteNo
            hoursRef.current = res.Data.HourNo
            setcurrentDay(res.Data.Weekday)
            setevents(res.Data)
            
        } catch (error) {
            setevents({})

        }
    }


    useEffect(() => {
        getDayInfoAsync()
    }, [])





    useEffect(() => {
        setInterval(() => {
            
            if(+secondsRef.current  == 59 ||+secondsRef.current >=59 ){
                setSeconds(0)
                secondsRef.current=0
                minutesRef.current += 1
                setMinutes(+minutesRef.current)
                if(+minutesRef.current == 59 ||+minutesRef.current>=60 ){
                    setMinutes(0)
                    minutesRef.current = 0
                    hoursRef.current +=1
                    setHours(+hoursRef.current)
                    if(+hoursRef.current==23){
                        hoursRef.current = 0
                        setHours(0)
                    }  
                }
                
            }
            secondsRef.current +=1
            setSeconds(secondsRef.current )

        }, 1000);
    }, []);


    return (
        <Widget title="Date" icon={['fas' ,'clock']} id={id} color='primary'>
            {events ?
                    <>{
                        events.HourNo ?
                        <>
                      <div className="clock d-flex flex-column align-items-center">
                        <div className="clock__date my-2"> 
                            <span style={{fontSize: "13px"}}>{" "+trans("today")+ " " } {trans(currentDay)}</span>
                            <span style={{fontSize: "13px"}}>{events.DayInfo}</span>
                        
                        </div>
                        <div className="clock__timer d-flex my-2 mb-2">
                            <span>{seconds < 10 ?`0${seconds}`:  seconds}</span> <span>:</span>
                            <span>{minutes< 10 ?`0${minutes}`:  minutes}</span> <span>:</span>
                            <span>{hours< 10 ?`0${hours}`:  hours}</span> 
                        </div>
                            <span style={{fontSize: "13px"}}>{events.CalendarEvents}</span>
                       </div>         
                        </>
                        :
                        <></>
                    }
                    </>
                        :
                        <div className="d-flex justify-content-center align-items-center text-info h-100">
                           <div className="spinner-border spinner-border-md" role="status"></div>
                        </div>
                    }
        </Widget> 
    )
}