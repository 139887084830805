import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getMessage, sendMessagesApi } from "./api";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";




interface MessageFormPropsType {
    selectedObj;
    showForm: (e:boolean) => void;
    mode;
    relod: () => void,

}
export const MessageForm = ({ 
    selectedObj, 
    showForm,
    mode,
    relod

}: MessageFormPropsType) => {
   
    const [resetForm, setResetForm] = useState(false);
    const [response, setResponse] = useState<any>();
    const [physicalQuantityId, setPhysicalQuantityId] = useState<any>();
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [untilDate, setUntilDate] = useState<string | null>(null);
    const [loading, setLoading] = useState<any>(false);
    const [userChanges, setUserChanges] = useState({});
    const [flag,setFlag] = useState(false);

    async function sendNodeAsync(data ,method) {
        try{
           const res = await sendMessagesApi(data , method);

           sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
           relod()
           showForm(false)
        }catch(err:any){

          }
        }
  
    async function getMessageAsync() {
        setLoading(false)
        try{
            if(selectedObj.Id){
                const res = await getMessage(selectedObj.Id);
                /*               setUntilDate(res.Data.ValidityUntilDate)
                setFromDate(res.Data.ValidityFromDate) */
                setResponse(res.Data)    
            }else{
                setResponse(null) 
            }    
        }catch(err:any){
            
        }
        setLoading(true)
    }

    const validationDates = () =>{
        if(fromDate && untilDate && fromDate?.toString().slice(0,10) > untilDate?.toString().slice(0,10)){
            setFlag(true)
        }else{
            setFlag(false)
        }
    }



    
    const handlePersonContactFormSubmit = (Message) => {
        Message.preventDefault();
        if(flag){
            Toast(trans("InvalidEndDate"),"error")
        }else{
        if(mode=="edit"){
            let data={
                  "Id": selectedObj.Id,      
                  "Name": Message.target.Name.value,
                  "SystemKey": Message.target.SystemKey.value,
                  "ServerAddress": Message.target.ServerAddress.value,
                  "SenderAccounts": Message.target.SenderAccounts.value,
                  "Username": Message.target.Username.value,
                  "Password": Message.target.Password.value,
                  "ApiKey": Message.target.ApiKey.value,
                  "Protocol": Message.target.Protocol.value,
                  "Description": Message.target.Description.value,
                  "IsDefault": Message.target.checkbox.checked,
                  "MessageSendMethod": +Message.target.MessageSendMethod.value,
            }
            sendNodeAsync(data,"put")

          }else{
            let data={    

                
                "Name": Message.target.Name.value,
                "SystemKey": Message.target.SystemKey.value,
                "ServerAddress": Message.target.ServerAddress.value,
                "SenderAccounts": Message.target.SenderAccounts.value,
                "Username": Message.target.Username.value,
                "Password": Message.target.Password.value,
                "ApiKey": Message.target.ApiKey.value,
                "Protocol": Message.target.Protocol.value,
                "Description": Message.target.Description.value,
                "IsDefault": Message.target.checkbox.checked,

                "MessageSendMethod": +Message.target.MessageSendMethod.value,
           
        }
            sendNodeAsync(data,"post")
        }
    }
    }
    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                showForm(false)
            });
        }else{
            showForm(false)     
        }
    }
     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);
    
     useEffect(() => {
        if(selectedObj && mode != "create"){
         getMessageAsync()
        }else{      
              setLoading(true)
        }
        
      }, [selectedObj]);

      useEffect(()=>{
        validationDates()
      },[untilDate,fromDate])

    return (
        <div className="person-contact-form card">
            <div className="card-body">
                {loading  ?
                    <div className="container-fluid">
                    <form onSubmit={handlePersonContactFormSubmit}>
                       <div className="row ">
                        <div className="col-lg-3 col-sm-4 mt-1">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             textboxType="text"
                             textboxName="Name"
                             labelText="Name"
                             required={true}
                             maxLength={900}
                             defaultValue={response?.Name}
                             resetForm={resetForm}
                             mode={mode}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-4 mt-1">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             required={true}
                             textboxType="text"
                             textboxName="SystemKey"
                             labelText="SystemKey"
                             defaultValue={response?.SystemKey}
                             resetForm={resetForm}
                             mode={mode}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-4 mt-1">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             required={true}
                             textboxType="text"
                             textboxName="ServerAddress"
                             labelText="ServerAddress"
                             defaultValue={response?.ServerAddress}
                             resetForm={resetForm}
                             mode={mode}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-4 mt-1">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             required={true}
                             textboxType="text"
                             textboxName="SenderAccounts"
                             labelText="SenderAccounts"
                             defaultValue={response?.SenderAccounts}
                             resetForm={resetForm}
                             mode={mode}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-4 mt-1">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             required={true}
                             textboxType="text"
                             textboxName="Username"
                             labelText="Username"
                             defaultValue={response?.Username || ''}
                             resetForm={resetForm}
                             mode={mode}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-4 mt-1">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             required={true}
                             textboxType="password"
                             textboxName="Password"
                             labelText="Password"
                             defaultValue={response?.Password|| ''}
                             value={response?.Password|| ''}
                             resetForm={resetForm}
                             mode={mode}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-4 mt-1">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             textboxType="text"
                             textboxName="ApiKey"
                             labelText="ApiKey"
                             defaultValue={response?.ApiKey}
                             resetForm={resetForm}
                             mode={mode}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-4 mt-1">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             textboxType="text"
                             textboxName="Protocol"
                             labelText="Protocol"
                             defaultValue={response?.Protocol}
                             resetForm={resetForm}
                             mode={mode}
                            />
                        </div>

                        <div className="col-md-3 col-sm-12">
                            <EnumerationTypeSelect
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                                name="MessageSendMethod"
                                label="MessageSendMethod"
                                enumTypeSelect="MessageSendMethod"
                                value={response?.MessageSendMethod}
                                mode={mode }
                            />
                        </div>
                        <div className="col-lg-6 col-sm-4 mt-1">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             textboxType="textarea"
                             textboxName="Description"
                             labelText="Description"
                             defaultValue={response?.Description}
                             resetForm={resetForm}
                             mode={mode}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-4 mt-1">
                            <CheckableButton
                               defaultChecked={response?.IsDefault}
                               defaultValue={response?.IsDefault}
                               labelText={"isdefault"}
                               inputName={"checkbox"}
                               type="checkbox"
                               mode={mode}
                            />                                
                                       
                        </div>
                          {mode != "create" && 
                               <div className="col-md-4 col-sm-12">
                                    <EnumerationTypeSelect
                                      Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                                      name="ActivityStatus"
                                      label="ActivityStatus"
                                      enumTypeSelect="ActivityStatus"
                                      value={response?.ActivityStatus}
                                      mode={mode }
                                    />
                               </div>
                              }
                    </div>  


                        {  mode !="view" ?
                            <div className="col-lg-12 mt-1 btns_form">
                            <Button value="Save" btnType="submit" />
                            <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                            </div>
                            :
                            <div className="col-lg-12 mt-1 btns_form">
                            <Button btnType="primary" value="close" onClick={()=>{cancel()}} />
                          </div>
                        }                        
                    </form>
                    </div>
                    :
                    <Loading/>                    
            }
            </div>
        </div>
    );
}