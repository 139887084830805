import { ENDPOINTS } from "utils/endpoints";
import { http } from "utils/http";


export const getAllPeriod = (async (orderFormat:string ="" ,  search)=> await http({
    url: ENDPOINTS.Management.period.browse,
    method: 'get',
    params: {
        searchString:search,
        orderBy : orderFormat,
        }
        
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)

export const getPeriod = (async (id) => await http({
    url: ENDPOINTS.Management.period.load,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: { Id: id }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)


export const PostPeriod = (data) => http({
    url: ENDPOINTS.Management.period.browse,
    method: 'post',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})



export const PutPeriod = (data) => http({
    url: ENDPOINTS.Management.period.browse,
    method: 'put',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})


export const DeletePeriod = (id) => http({
    url: ENDPOINTS.Management.period.browse,
    method: 'DELETE',
    params: {
        id :id
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})
