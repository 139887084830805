import { useEffect, useState, useRef } from "react";
import { trans } from "utils/helpers";
import { getBpmnTaskUserApi } from "./api";
import { SubMenu } from "../SubMenu/SubMenu";
interface BpmnTaskUserPropsType {
    label?: string,
    bpmnTaskId?: string,
    hasBorder?: boolean,
    required?: boolean,
    value?:string,
    mode?:string
    resetForm?:any,
    Change?: (e) => void,
    setBpmnTaskUser?: (e) => void,
}

export const BpmnTaskUser = ({ bpmnTaskId ,label, hasBorder,value,mode ,required , resetForm ,Change , setBpmnTaskUser}: BpmnTaskUserPropsType) => {
    const [BpmnTaskUser, setBpmnTaskUsers] = useState<any>();
    const [activeId , setactiveId] = useState<any>();

    const [valueName, setValueName] = useState<any>();
    const [flag , setFlag] = useState(false);
    const [show , setShow] = useState(false);
    const [validat , setValidat] = useState(false);
    const [rquird , setRquird] = useState(false);
    const inputRef = useRef<any>();
    const inputIdRef = useRef<any>();
    const BpmnTaskUserRef = useRef<any>(BpmnTaskUser);
    const activeIdRef = useRef<any>(activeId);
    const validRef = useRef<any>(false);

    let options_PostLevel_keys , options_PostLevel_values ;

















    
    async function getBpmnTaskUserListAsync(search,id,bpmnTaskId) {
        try{
           const res = await getBpmnTaskUserApi(search,id ,bpmnTaskId);
           if(id){
            setValueName(res[id])
           }else{
             setBpmnTaskUsers(res)        
             options_PostLevel_values = Object.values(res)        
             options_PostLevel_keys = Object.keys(res)
           }
           if(Object.values(res).length ===1){
            setValeu(Object.values(res)[0],Object.keys(res)[0])
           }
           setFlag(!flag)
        }catch(err:any){
        
          }
      }
    
    const Auto = (e)=>{
      validRef.current = false
      
      Change && Change(true)
      setValidat(false)
      setRquird(false)
        getBpmnTaskUserListAsync(e.target.value,"",bpmnTaskId)
         setFlag(!flag)

    }
    const CheckBpmnTaskUser =async (e)=>{
      setTimeout(() => {
        if(!validRef.current){
          //e.target.setCustomValidity(trans("Msg_Validation_ValueIsIncorrect"));
         // setValidat(true)
          setBpmnTaskUser && setBpmnTaskUser(null)
         // e.target.reportValidity();
        }
        
      }, 200);
    }
    const setValeu = (value , id)=>{
      if(inputIdRef != null && inputIdRef.current != null){

        validRef.current = true

        inputRef.current.value=value
        inputIdRef.current.value=id

        setBpmnTaskUsers(null)
        setFlag(!flag)
        inputRef.current.setCustomValidity("")
        setValidat(false)
        setBpmnTaskUser && setBpmnTaskUser({Value : inputRef.current.value , Id : id})
    }
  }
    useEffect(() => {
       if(value){
        getBpmnTaskUserListAsync(null,value,bpmnTaskId)

       }else if(value == null){
        setValueName(null)
       }
      }, [value ]);
    useEffect(() => {
       if(bpmnTaskId == null ){
        inputRef.current.value=null
        inputIdRef.current.value=null

         setValueName(null)
         setBpmnTaskUser && setBpmnTaskUser(null)

       }else {
        getBpmnTaskUserListAsync(null,value,bpmnTaskId)

       }
      }, [bpmnTaskId]);


      if(BpmnTaskUser){
        options_PostLevel_values = Object.values(BpmnTaskUser)
        options_PostLevel_keys = Object.keys(BpmnTaskUser)
      }
  
      useEffect(() =>{
        if(show ){
          document.body.addEventListener("click", clickToclose)
        } 
     return () => { document.body.removeEventListener('click', clickToclose) }
    
        
      },[show ])
    
      function clickToclose(e){
        let input = document.querySelector("#browserBpmnTaskUserId");
        if (e.target.id !== input?.id) { 
          setShow(false);
        }
      }
    return (
     <>
     <div className="text-box">
     <label htmlFor="browser2" className="text-box__label">{trans("receiver")+" " +trans("reference")}</label>
     {required && <span className="text-box__required-sign-for-label text-danger">*</span>}
     <input required={required} defaultValue={value && value} name="BpmnTaskUserId" className="d-none" ref={inputIdRef} />
     <input required={required} onBlur={CheckBpmnTaskUser} defaultValue={valueName} autoComplete="off" onFocus={(e)=>{setShow(true);}}  ref={inputRef} list="BpmnTaskUserList" name="browser2" className="text-box__box form-control "    id={"browserBpmnTaskUserId"} onMouseDown={(e)=>e.stopPropagation() } onChange={Auto} disabled={mode =="view" ? true : bpmnTaskId==null ?true:false} />
      {
        show && 
        <SubMenu  onClick={setValeu} options={BpmnTaskUser} onHide={()=>{setShow(false)}} />

      }  
       <div className="text-box__msg-wrapper">
        {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
        {required && <span className={`${rquird ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsRequired", [""])}</span>}
        </div>    
     </div>
     </>
    )
}