import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fileviewer } from "components/Fileviewer/Fileviewer";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { useEffect, useState } from "react";
import { CardBoxAttachment } from "./CardBoxAttachment/CardBoxAttachment";
import { GetAttachmentText, GetHelp, UpdateAttachmentDetails, getAttachmentDetail, getAttachmentViewers, loadAttachmentViewer, loadAttachmentViewerBase64 } from "./api";
import { trans } from "utils/helpers";
import { ModalForm } from "components/ModalForm/ModalForm";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Textbox } from "components/Textbox/Textbox";
import { KeyWord } from "components/KeyWord/KeyWord";
import { Button } from "components/Button/Button";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";

interface props {
    ownerId?: string
    inPersonal?: boolean
}
export const AttachmentViewer = ({ ownerId ,inPersonal=false}: props) => {

    const [response, setResponse] = useState<Response | any>(null);
    const [showModalCarousel, setshowModalCarousel] = useState(false);
    const [load, setLoad] = useState<any>();
    const [loading, setLoading] = useState<any>(true);
    const [loadingText, setLoadingText] = useState<any>(true);
    const [index, setIndex] = useState<number | any>();
    const [fileName, setFileName] = useState< any>();
    const [showFile, setShowFile] = useState(false);
    const [showDetailsForm, setShowDetailsForm] = useState(false);
    const [file, setFile] = useState<any>();
    const [attachmentText, setAttachmentText] = useState<any>();
    const [help, setHelp] = useState<any>();
    const [showHelpModal, setShowHelpModal] = useState<any>(false);
    const [messageType, setMessageType] = useState<any>( "info");
    const [MessageTextType, setMessageTextType] = useState<any>( "info");
    const [message, setMessage] = useState<any>( "NoDataForPreview");
    const [keyWords, setKeyWords] = useState<any>([]);
    const [preprocessingOptions, setPreprocessingOptions] = useState<any>( {
        "PreprocessingOptions.Grayscale" : false,
        "PreprocessingOptions.Denoise" :false,
        "PreprocessingOptions.BinaryInversion" : false,
        "PreprocessingOptions.BitwiseConversion" : false,
        "PreprocessingOptions.GaussianThresholding" : false,
        "PreprocessingOptions.IncreaseBrightness" :false,
        "PreprocessingOptions.IncreaseContrast" : false,
    });

    async function getFileAsync(id , name) {
        try {   
                const list =  await loadAttachmentViewer(id );
                const blob = new Blob([list])
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href =  URL.createObjectURL(blob)
                a.download = name;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
           } catch (error) {
              
           }
       }


    async function getAttachmentViewersAsync() {
        setLoading(false)
        try {
            const res = await getAttachmentViewers(ownerId )
            setResponse(res.Data ) 
        } catch (error:any) {
            setMessageType("error")
        }
        setLoading(true)
    }
    
    async function getAttachmentDetailAsync() {
        setLoading(false)
        const res = await getAttachmentDetail(file.Id )
        
        setAttachmentText(res.Data?.AttachmentText)
        setKeyWords(res.Data?.Keywords?.replaceAll("'" , "").split(","))
        try {
        } catch (error:any) {
            setMessageTextType("error")
        }
        setLoading(true)
    }
    async function GetAttachmentTextAsync(e) {
         e.preventDefault()
         setLoadingText(false)
         try{
             let text = await GetAttachmentText(file.Id , preprocessingOptions)
             setAttachmentText(text)
         }catch(e){
             setMessageType("error")
 
         }
         setLoadingText(true)
 
     }
    async function GetHelpAsync(e) {
         e.preventDefault()
      
         try{
             let image = await GetHelp()
             setHelp(image)
             setShowHelpModal(true)
         }catch(e){
             setMessageType("error")
 
         }
       
 
     }
    
    function handleSlider(obj , i) {
        setIndex(i);
        setFileName(obj.FileName);
        setFile(obj);
        loadAttachmentViewerAsync(obj.Id);
        setshowModalCarousel(true);
    }
   async function UpdateAttachmentDetailsAsync(e) {
        e.preventDefault()
        try{
            let data = {
              "AttachId": file.Id,
              "Keywords": "'"+keyWords.join("','")+"'",
              "AttachmentText":attachmentText
            }
           await UpdateAttachmentDetails(data )
        }catch(e){
        }
    }

    async function loadAttachmentViewerAsync(e) {
        try {
            const res:any = await loadAttachmentViewerBase64(e );
            setLoad(res);
            return res
        } catch (error) {
            setLoad(" ")
            return " "
        }
    }

    function showFileHandler(e) {
        document.getElementById("DashboardLayout")?.insertAdjacentHTML("afterbegin", '<div id="backScreen" class="fullScreen" ></div>')
        setShowFile(true)
    }

    useEffect(() => {
        
        if(ownerId){
            getAttachmentViewersAsync();
        }
    }, [ownerId ])

    useEffect(() => {
        if(showDetailsForm && file?.Id){
            getAttachmentDetailAsync();
        }
        if(!showDetailsForm){
            setKeyWords([])
            setAttachmentText(null)
            setMessageTextType("info")
        }
    }, [showDetailsForm  ])

    function next() {
        setShowFile(false)
        if(index+1 ==response?.length ){
            handleSlider(response[0] , 0)
        }else{
            handleSlider(response[index+1] , index+1)
        }
        setShowFile(true)
    }

    function prev() {
        setShowFile(false)
        if(index ==0 ){
            handleSlider(response[response?.length-1] , response?.length-1)
        }else{
            handleSlider(response[index-1] , index-1)
        }
        setShowFile(true)
    }


    return (
        <>  
        {showDetailsForm &&
            <ModalForm mode={"create"} title={("details")} onHide={() => { setShowDetailsForm(false) }} show={showDetailsForm}>
                <form onSubmit={UpdateAttachmentDetailsAsync} >
                <div>
                <FormWithTitle title="KeyWords" >
                  <div className="card-body pt-0">
                  <div className="col-6">
                    <KeyWord
                      label={""}
                      setKeyWords={setKeyWords}
                      keyWords={keyWords}
                      mode={ "create"}
                      name="KeyWords"
                     />
                    </div>
                    </div>
                    </FormWithTitle>
                </div>
                <div className="mt-2" >
                <FormWithTitle title="ocr">
                    <div className="card-body pt-0">
                        <div className="" style={{display : "flex" ,flexWrap: "wrap"}}>
                        <div className=" " >
                            <CheckableButton
                                onchange={()=>{setPreprocessingOptions({...preprocessingOptions ,"PreprocessingOptions.Grayscale":!preprocessingOptions["PreprocessingOptions.Grayscale"] })}}
                               defaultChecked={false}
                               defaultValue={false}
                               labelText={"Grayscale"}
                               inputName={"Grayscale"}
                               type="checkbox"
                               mode={"create"}
                            />                                
                        </div>    
                        <div className="" >
                            <CheckableButton
                               onchange={()=>{setPreprocessingOptions({...preprocessingOptions ,"PreprocessingOptions.Denoise":!preprocessingOptions["PreprocessingOptions.Denoise"] })}}
                               defaultChecked={false}
                               defaultValue={false}
                               labelText={"Denoise"}
                               inputName={"Denoise"}
                               type="checkbox"
                               mode={"create"}
                            />                                
                        </div>    
                        <div className=" " >
                            <CheckableButton
                               onchange={()=>{setPreprocessingOptions({...preprocessingOptions ,"PreprocessingOptions.BinaryInversion":!preprocessingOptions["PreprocessingOptions.BinaryInversion"] })}}
                               defaultChecked={false}
                               defaultValue={false}
                               labelText={"BinaryInversion"}
                               inputName={"BinaryInversion"}
                               type="checkbox"
                               mode={"create"}
                            />                                
                        </div>    
                        <div className=" " >
                            <CheckableButton
                               onchange={()=>{setPreprocessingOptions({...preprocessingOptions ,"PreprocessingOptions.BitwiseConversion":!preprocessingOptions["PreprocessingOptions.BitwiseConversion"] })}}
                               defaultChecked={false}
                               defaultValue={false}
                               labelText={"BitwiseConversion"}
                               inputName={"BitwiseConversion"}
                               type="checkbox"
                               mode={"create"}
                            />                                
                        </div>    
                        <div className=" " >
                            <CheckableButton
                               onchange={()=>{setPreprocessingOptions({...preprocessingOptions ,"PreprocessingOptions.GaussianThresholding":!preprocessingOptions["PreprocessingOptions.GaussianThresholding"] })}}
                               defaultChecked={false}
                               defaultValue={false}
                               labelText={"GaussianThresholding"}
                               inputName={"GaussianThresholding"}
                               type="checkbox"
                               mode={"create"}
                            />                                
                        </div>    
                        <div className=" " >
                            <CheckableButton
                               onchange={()=>{setPreprocessingOptions({...preprocessingOptions ,"PreprocessingOptions.IncreaseBrightness":!preprocessingOptions["PreprocessingOptions.IncreaseBrightness"] })}}
                               defaultChecked={false}
                               defaultValue={false}
                               labelText={"IncreaseBrightness"}
                               inputName={"IncreaseBrightness"}
                               type="checkbox"
                               mode={"create"}
                            />                                
                        </div>    
                        <div className=" " >
                            <CheckableButton
                               onchange={()=>{setPreprocessingOptions({...preprocessingOptions ,"PreprocessingOptions.IncreaseContrast":!preprocessingOptions["PreprocessingOptions.IncreaseContrast"] })}}
                               defaultChecked={false}
                               defaultValue={false}
                               labelText={"IncreaseContrast"}
                               inputName={"IncreaseContrast"}
                               type="checkbox"
                               mode={"create"}
                            />                                
                        </div>    
                        </div>
                        <button onClick={GetAttachmentTextAsync} style={{ boxShadow: "none",width:"90px"  , height:"32px"}}  type={`button`} className={`btn-primary button-custom mx-1 btn mt-1 `} >
                         {trans("ocr") }
                        </button> 
                        <button onClick={GetHelpAsync} style={{ boxShadow: "none",width:"90px"  , height:"32px"}}  type={`button`} className={`btn-primary button-custom mx-1 btn mt-1 `} >
                         {trans("help") }
                        </button> 
                        <div className="row">
                            {loadingText ? 
                            <>
                                {attachmentText ?
                                    <div className="col-12">
                                        <Textbox
                                          minHeight={4}
                                          textboxType="textarea"
                                          labelText="text"
                                          textboxName="text"
                                          defaultValue={attachmentText}
                                          mode={"create"}
                                        />
                                    </div>
                                    :<> {MessageTextType== "info"  ?  <></> : <Message message={message} type={MessageTextType} />  }    </> 
                                }                            
                            </>
                            :<Loading/>
                            
                            }

                    </div>             
                    </div>
                    </FormWithTitle>
                </div>
                <div className="d-flex justify-content-end mt-1">
                  <Button btnType="submit" value="submit" />
                </div>
                </form>

            </ModalForm>
        }
        {showHelpModal &&
            <ModalForm mode={"create"} title={("help")} onHide={() => { setShowHelpModal(false) }} show={showHelpModal}>
                <div>
                <img width={"100%"} src={`data:image/webp;base64,${help}`} />
                </div>
            </ModalForm>
        }
        {showModalCarousel &&
        <div className="" style={{position:"fixed" , top:"65px" ,width: "100%" ,height:"65%" , zIndex:'1'}}>
          <div className='fileTitle' style={{height:"35px" , width:"80%"}} >
              <div className='fileName mt-1'>
                  <span style={{marginRight : "7%"}}> {fileName}</span>
              </div>
              <div style={{width: "9%" , marginTop:"2px"}} className='fileRemove'  onClick={()=>{setshowModalCarousel(false); }}>
                  <FontAwesomeIcon icon={['fas', 'times']} size="2x" color='grey' style={{ cursor: 'pointer' }} />
              </div>
          </div>
          <div style={{flexDirection: "column" , display: "flex" , height: "92%" , backgroundColor: "white" , justifyContent:"center"}} className='w-100'>
           <div  onClick={showFileHandler} style={{display: "flex" , height: "92%" , backgroundColor: "white" , margin:"0 14%" }}>
            <Fileviewer File={fileName } src={load} showArchive={true} />
           </div>
           <div style={{display: "flex" , width:"77%"  , justifyContent:"center"}}>
            {!inPersonal &&
            <button style={{ boxShadow: "none",width:"90px"  , height:"32px"}}  type={`button`} className={`btn-primary button-custom mx-1 btn `} onClick={()=>{setShowDetailsForm(true)}}>
                {trans("details") }
            </button>
            
            }
           </div>
          </div>          
        </div>
        }
        {showFile &&
            <div className="" style={{position:"fixed" ,right:"2%" , top:"2px" ,width: "100%",zIndex:"999999"}}>
              <FontAwesomeIcon onClick={()=>{setShowFile(false);document.getElementById("backScreen")?.remove()}}  size="3x" color='white' icon={['fas', 'times']} style={{zIndex:"9999" , cursor: 'pointer' , position:"absolute" }} />
              <FontAwesomeIcon onClick={()=>{getFileAsync(file.Id ,file.FileName )}}  size="3x" color='white' icon={['fas', 'download']} style={{zIndex:"9999" , cursor: 'pointer' , position:"absolute" ,marginRight:"50px" }} />
              <div style={{display: "flex" , height: "92vh", justifyContent:"center" ,zIndex:"999999"}} className='w-100'>
                <div onClick={()=>{next()}} style={{right:"-10px", height:"100vh", position: "absolute" , flexDirection: "column" , display:"flex" , justifyContent:"center" , margin:"10px"}}><FontAwesomeIcon icon={['fas', 'angle-right']} size="3x" color='white' style={{ cursor: 'pointer' }} /></div>
                  <Fileviewer File={fileName } showDownLoad={false} src={load} showArchive={true} showFile={true} />
                <div onClick={()=>{prev()}} style={{left:"40px", height:"100vh", position: "absolute", flexDirection: "column" , display:"flex" , justifyContent:"center" , margin:"10px"}}><FontAwesomeIcon icon={['fas', 'angle-left']} size="3x" color='white' style={{ cursor: 'pointer' }} /></div>
              </div>          
            </div>
        }
            <div className={`card-body card  w100 `} style={{height:"115px" , position:"fixed" , bottom:"33px" ,width:"96%"}}>
                <div style={{marginTop:"-13px"}} className="row">
                        {loading ?
                        (response && response?.length > 0 ?
                            <>
                                <div className="AttachmentViewer d-flex flex-nowrap">
                                    {response.map((item , index) => (
                                        <CardBoxAttachment img={`data:image/jpg;base64,${item.Thumbnail}`}  handleClickSlider={()=>{handleSlider(item , index)} } id={item.Id}
                                           fileName={item.FileName} handleDownloadTrigger={(e)=>{e.preventDefault() ; getFileAsync(item.Id ,item.FileName )} }/>
                                    ))}
                                </div>  
                            </>
                            :<> <Message message={message} type={messageType} />  </> 
                        )
                            : <Loading />
                        }
                    </div>
            </div>  
        </>
    );
}




