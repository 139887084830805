
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";

import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { downloadCSV, trans } from "utils/helpers";
import { ItemBarcodeList } from "./ItemBarcodesList";
import { getItemBarcodeslist, removeItemBarcodes, sendItemBarcodes } from "./api";


interface ItemBarcodesPropsType {
    onHide?: () => void;
    getItemBarcodes?: (data) => void;
    mode:any;
    IsIndependent?:any;
    inTab?:any;
    Id?:any;
    
}

export const  ItemBarcode = ({getItemBarcodes,IsIndependent=false,Id  ,mode,inTab }:ItemBarcodesPropsType) => {


    const [response, setResponse] = useState<Response | any>(null);
    const [ItemBarcodesId, setItemBarcodesId] = useState<string | any>(null);
    const [Barcodesmode, setBarcodesMode] = useState<string>();
    const [orderFormat, setOrderFormat] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [showitemBarcodesesForm, setShowitemBarcodesesForm] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [Barcodeslist, setBarcodeslist] = useState<any>([]);
    const [selectList , setSelectList] = useState<any>([])
    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)
    const [forceUpdate, setForceUpdate] = useState<any>();

    let customFuction: any = []

    /* pagination area*/
    const clickToolbarCallback = (clickedButtonName: string) => {

        switch (clickedButtonName.toLowerCase()) {
            case "finish": alert("call api finish " ); break;
            case "current": alert("call api current " ); break;
            case "archive": alert("call api archive " ); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "view": if (ItemBarcodesId) { setBarcodesMode("view");  } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "print": alert("print ");break;
            case "excel": downloadCSV(Barcodeslist ,1 ,trans("ItemBarcode"));break;
            case "create": setShowitemBarcodesesForm(true);break;
            case "remove":if(selectList.length >0){Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleRemove)}else{Toast("Msg_SelectRow" , "warning")}  ;break;

        }
    };
    const addBarcodestoList = (newItems) => {
        setLoading(false)  
        if(IsIndependent){
            let newbarcodeslist:any = []     
            newItems.map(item =>{
                let checkList= Barcodeslist.find(p => p.Id == item.Id)
                if(!checkList){
                    newbarcodeslist.push({BarcodeId:item.Id , ItemId:Id}) 
                }else{
                    Toast("msg_delete_useddata","error")
                }
            })
            sendItemBarcodesAsync(newbarcodeslist)
        }else{
        let newBarcodesList = Barcodeslist
        if(Barcodeslist.length == 0){
            setBarcodeslist(newItems)
            setResetMultiSelect(!resetMultiSelect)
            setSelectList([])
            getItemBarcodes&& getItemBarcodes(newItems)
        }else{
            newItems.map(item =>{
                let checkList= Barcodeslist.find(p => p.Id == item.Id)
                    if(!checkList){
                        newBarcodesList = [...newBarcodesList,item]
                    }else{
                        newBarcodesList = [...newBarcodesList,item]
                     //   Toast("msg_delete_useddata","error")
                    }
                })
                setBarcodeslist(newBarcodesList)
                setResetMultiSelect(!resetMultiSelect)
                setSelectList([])
                getItemBarcodes&& getItemBarcodes(newBarcodesList)
        } 
    }
        setLoading(true)
    }

    const getWithOrder = (order: string[]) => {
        setOrderFormat(order.join(","))
    }
    const selectedRowCallback = (obj, index) => {
        setItemBarcodesId(obj?.Id);
    };
    const selectListCallBack =(list)=>{
          
        setSelectList(list[1])
      }
    const handleRemove =async()=>{
        
        let removeListId=selectList.map(item=>{return item.Id})

        if(IsIndependent){
            setLoading(false)
            try {
              const res = await removeItemBarcodes(removeListId)
              setForceUpdate(!forceUpdate)
            } catch (error) {
              
            }
            setLoading(true)
        }else{
            setLoading(false)
            let newList:any = []
            Barcodeslist.map(item=>{
                if(!removeListId.includes(item.Id)){
                    newList.push(item)
                }
            })
            setResetMultiSelect(!resetMultiSelect)
            setSelectList([])
            setBarcodeslist(newList)
            getItemBarcodes&&getItemBarcodes(newList)

            setLoading(true)
        }
    } 
    const sendItemBarcodesAsync =async (list) => {
        setLoading(false)
        try {
          const res = await sendItemBarcodes(list)
          setShowitemBarcodesesForm(false)
          setForceUpdate(!forceUpdate)
        } catch (error) {
          
        }
        setLoading(true)
    }

    async function getItemBarcodeslistAsync() {
        setLoading(false)
        try {                                                     
           const res:any = await  getItemBarcodeslist(Id)
           setBarcodeslist( res.Data)
           setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }

    useEffect(() => {
        if((inTab ==="Barcodes"||IsIndependent)&& mode!="create" ){
        getItemBarcodeslistAsync();
        }
    }, [forceUpdate,inTab]);
    useEffect(() => {

        if(inTab ==="Barcodes"){
        if(Barcodeslist?.length >0){ 
          let newList:any = []          
          Barcodeslist.map(item=>{
                    if(item != undefined){
                        newList.push(item) 

                    }
            })
            setBarcodeslist(newList)
            getItemBarcodes&&getItemBarcodes(newList)
        }
        setLoading(true)
        }
    }, [ inTab]);
    

   
    /* #endregion */

    return (
        < >
        {(inTab == "Barcodes"||IsIndependent) && 
        <div className="p-2">

        {mode != "view" &&
          <Toolbar warehouse={false}  create={mode != "view"} remove={mode!= "view"}  emptyList={Barcodeslist == null || Barcodeslist?.length == 0} id={ItemBarcodesId}   search={false} edit={false} refresh={IsIndependent}  view={false}  handleClickToolbar={clickToolbarCallback} />
        }   
         {loading ?
            <div className="col-lg-12">
                <div className="list-card card">
                    <div className="list-card__body">
                        {
                            (Barcodeslist?.length > 0 ?
                                <DataGrid
                                    customFuction={customFuction}
                                    selectItem={(e) => {  }}
                                    orderFormat={getWithOrder}
                                    multiSelect={mode!="view"}
                                    selectList={selectListCallBack}
                                    resetMultiSelect={resetMultiSelect}
                                    parentName={"Barcodes" }
                                    startIndex={1}
                                    theadData={Object.keys(Barcodeslist[0])}
                                    scrollHeight={"350px"}
                                    tbodyData={Barcodeslist}
                                    minCellWidth={80}
                                    selectedRowObjectCallback={selectedRowCallback}
                                />
                                : <Message message={"NoDataForPreview"} type={messageType} />)
                        }
                    </div>
                </div>
            </div>
             : <Loading />
            }
             {showitemBarcodesesForm &&
             <ModalForm mode={Barcodesmode} title="Barcode" onHide={() => setShowitemBarcodesesForm(false)} show={showitemBarcodesesForm}>
                 <ItemBarcodeList BarcodesListcallback={(e)=>{addBarcodestoList(e)}}  onHide={() => setShowitemBarcodesesForm(false)} />
             </ModalForm>
             }
        </div>
        }
        </>
    )
}